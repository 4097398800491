import { useState } from "react";

const useShowModal = () => {

  // Hook state
  const [showModal, setShowModal] = useState(false);

  // Function to toggle modal
  const toggleModal = (show) => {
    setShowModal(show ||!showModal);
  }

  return {
    showModal,
    toggleModal
  }
}

export default useShowModal;
