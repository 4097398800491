import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import { useSelector } from 'react-redux';
import { Card, CardBody, Media, Button } from "reactstrap";
import { map } from 'lodash';
import Avatar from '../avatars/Avatar';
import TextEditor from "./TextEditor";
import CommentFromEditor from "./CommentFromEditor";
import { successActionCreator } from "../../../actions/action-creators/success";
import { ADD_COMMENT_REQUEST, REMOVE_NEW_COMMENT_FLAG } from "../../../actions/types";
import CollapseButton from '../../button/CollapseButton';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import { DEFAULT_COMMENT_SECTION_TYPE, ISSUE_COMMENT_SECTION_TYPE } from "../../../constants/common";
import IssueCommentHeader from "./IssueCommentHeader";
import useUserType from "../../../hooks/useUserType";
import { formatDate, getGMTOffset } from "../../../utils/helpers";

const CommentsSection = (props) => {
    const {
        comments,
        callback,
        innerRef,
        hideRemediation,
        isRemediationSection,
        linkToRedux = true,
        changeNewAdded,
        nonReduxCommentsState,
        renderCustomTools,
        disabled,
        disabledMessage,
        showPrivateTag = false,
        commentType = DEFAULT_COMMENT_SECTION_TYPE,
    } = props;

    // Getting neede info from redux store
    const commentsState = useSelector(state => state.comments);
    const authUser = useSelector(state => state.auth?.user);

    // Component State
    const [showCollapseButton, setShowCollapseButton] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [isRemediationNote, setIsRemediationNote] = useState(false);

    // Component refs
    const commentListRef = useRef();
    const textEditorRef = useRef();

    // Component Hooks
    const { dispatch } = useMemoizedDispatch();
    const userType = useUserType();

    // Component Variables
    const minHeight = "160px";
    const stateToUse = linkToRedux ? commentsState : nonReduxCommentsState;
    const { newAdded = false, isProcessing = false } = stateToUse || {};

    useEffect(() => {
        if(commentListRef.current !== null && commentListRef.current !== undefined) {
            const minHeight = 160;
            const scrollHeight = commentListRef.current.scrollHeight;
            if (scrollHeight > minHeight) {
                setShowCollapseButton(true);
            }
        }
    }, [comments]);

    //  Function to dispatch action
    const dispatchAction = (key) => {
        if (!linkToRedux) { return; }
        dispatch(successActionCreator(key));
    }

    useEffect(()=>{
        if(newAdded){
            setEditMode(false);
            setTimeout(() => {
                dispatchAction(REMOVE_NEW_COMMENT_FLAG);
                if(changeNewAdded && typeof(changeNewAdded) === 'function'){
                    changeNewAdded(false);
                }
            },500);
        }
    },[newAdded]);

    const getFormattedDate = (date) => {
        const formattedDate = new Date(date);
        return `${formatDate(date)} at ${formattedDate.toLocaleString('en-US', { hour: '2-digit',  minute: '2-digit', hour12: false })}` + getGMTOffset();
    }

    const onPostNoteClick = (value) =>{
        if (value) {
            dispatchAction(ADD_COMMENT_REQUEST);
        }
        else {
            setEditMode(!editMode);
        }
        setIsRemediationNote(false);
        callback(value);
    }

    useEffect(() => {
        if (editMode) {
            textEditorRef.current && textEditorRef.current.focus();
        }
    }, [editMode]);

    useImperativeHandle(innerRef, () => {
        return {
            postNewNote: (isRemediation) => {
                setEditMode(true);
                if (isRemediation) {
                    setIsRemediationNote(true);
                }
            },
            cancelRemediationNote: () => {
                setIsRemediationNote(false);
                setEditMode(false);
            },
        };
    });

    const onRemediationCheckboxChange = (value) => {
        setIsRemediationNote(value);
        callback(null, value);
    };

    // Getting user data for comments
    const getUserData = (userData, userId) => {
        if(userData) return userData;
        const isSameAsAuthUser = userId === authUser?.id
        const full_name = isSameAsAuthUser ? authUser?.full_name || authUser?.name : 'Deleted User'
        return {
            full_name,
            user_id: userId,
        }
    }

    // Function to toggle edit mode
    const onEditModeClick = () => {
        if(disabled) return;
        setEditMode(!editMode)
    }

    // Render functions

    // Render button to expand or collapse comments section height
    const renderCollapseButton = () => {
        return showCollapseButton && (
            <CollapseButton
                isCollapsed={isCollapsed}
                toggleCollapsed={() => setIsCollapsed(!isCollapsed)}
            />
        )
    }

    // Render text editor
    const renderTextEditor = () => {
        return (
            <div className="text-editor-container">
                <TextEditor
                    innerRef={textEditorRef}
                    callback={onPostNoteClick}
                    minHeight={minHeight}
                    isCollapsed={isCollapsed}
                    isProcessing={isProcessing}
                    isRemediationNote={isRemediationNote}
                    remediationCallback={onRemediationCheckboxChange}
                    hideRemediation={isRemediationSection || hideRemediation}
                    renderCustomTools={renderCustomTools}
                />
                {renderCollapseButton()}
            </div>
        )
    }

    // Render comments empty state
    const renderEmptyCommentsState = () => {
        return (
            <div className="no-comments d-flex align-items-center w-100 h-100">
                You don’t have any notes yet…
            </div>
        )
    }

    const renderPrivateTag = (isPrivate) => {
        if(!showPrivateTag) return null;
        const label = `${isPrivate ? 'Private' : 'Public'} note`
        return (
            <span className="notes-private-tag">
                {label}
            </span>
        )
    }

    // Get comment classname by comment type
    const getCommentClassName = () => {
        switch(commentType) {
            case ISSUE_COMMENT_SECTION_TYPE: {
                return 'issue-comment-type'
            }
            case DEFAULT_COMMENT_SECTION_TYPE:
            default: {
                return 'default-comment-type'
            }
        }
    }

    // Render header for issue comments
    const renderIssueCommentHeader = (params) => {
        const {
            user,
            updated_at,
            created_at,
            user_id,
            status,
            is_remediation,
          } = params;
        const _user = getUserData(user, user_id);
        return (
            <IssueCommentHeader
                username={_user?.full_name}
                actionDate={updated_at || created_at}
                statusName={status?.name}
                isRemediation={is_remediation}
                statusId={status?.id}
                userType={userType}
            />
        )
    };

    // Render common header for comments
    const renderCommonCommentHeader = (params) => {
      const {
        user,
        updated_at,
        created_at,
        is_remediation,
        user_id,
        is_private,
      } = params;

      const _user = getUserData(user, user_id);
      const headerClassName = `header d-flex ${is_remediation ? "remediation" : ""}`;
      return (
        <div className={headerClassName}>
          {is_remediation && (
            <div className="text-content">Remediation Note</div>
          )}
          <Avatar user={_user} customClassName="avatar-comments" />
          <span className="user-name mr-2">{_user?.full_name}</span>
          {renderPrivateTag(is_private)}
          <span className="date">
            {getFormattedDate(updated_at || created_at)}
          </span>
        </div>
      );
    };

    // Render comment header
    const renderCommentHeader = (params) => {
      switch (commentType) {
        case ISSUE_COMMENT_SECTION_TYPE: {
          return renderIssueCommentHeader(params);
        }
        case DEFAULT_COMMENT_SECTION_TYPE:
        default: {
          return renderCommonCommentHeader(params);
        }
      }
    };

    // Render comments list
    const renderComments = () => {
        return map(comments, (params, index) => {
            const { content } = params;
            const newCommentClassName = index === 0 && newAdded ? "new" : "";
            const commentClassName = `comment ${getCommentClassName()} ${newCommentClassName}`
            return (
                <Media
                    className={commentClassName}
                    key={index}
                >
                    <Media body>
                        {renderCommentHeader(params)}
                        <div className="text-content">
                            <CommentFromEditor
                                rawContent={content}
                                isRemediationSection={isRemediationSection}
                            />
                        </div>
                    </Media>
                </Media>
            );
        });
    };

    // Render post new note button
    const renderPostNewNoteButton = () => {
        const postNoteMessage = 'Post a new note…';
        const label = disabled ? disabledMessage || postNoteMessage : postNoteMessage;
        return (
            <Button
                className="btn-post-comment"
                onClick={onEditModeClick}
                block
                disabled={disabled}
            >
                <Avatar user={authUser} customClassName="avatar-comments" />
                <i className='bx bx-send mr-2' ></i>{label}
            </Button>
        )
    }

    const renderCommentsSection = () => {
        const hasComments = comments?.length > 0;
        const commentBoxStyle = {
            height: isCollapsed && hasComments ? minHeight : "unset"
        };
        return (
            <div className="comments-section-container">
                <Card className="comments-section" >
                    <CardBody className="p-0" >
                        <div
                            ref={commentListRef}
                            className="comments-box"
                            style={commentBoxStyle}
                        >
                            {hasComments ? renderComments() : renderEmptyCommentsState()}
                        </div>
                        {renderCollapseButton()}
                    </CardBody>
                </Card>
                {renderPostNewNoteButton()}
            </div>
        )
    }

    return editMode ? renderTextEditor() : renderCommentsSection()
};

export default CommentsSection;
