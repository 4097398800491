import React, { useEffect } from "react";
import _ from "lodash";
import { connectionStatusConfigById, partnerStatusConfigById } from "../../constants/common";
import { Badge } from "reactstrap";
import BasicTooltip from "../dashboard/tooltips/BasicTooltip";
import { useHistory } from "react-router-dom";
import useMemoizedDispatch from "../../hooks/useMemoizedDispatch";
import { setPartnerIdAndName } from "../../actions/partners";
import useCustomMutation from "../../hooks/useCustomMutation";
import API from "../../api";
import { onImpersonationSuccess } from "../../actions/impersonation";
import { alertTypes } from "../../constants/alerts";
import { setAlert } from "../../actions/alerts";

const ConnectionStatusBadge = (props) => {
  const {
    statusId,
    componentId="connection-status-badge",
    groupedStatus = false,
    partnerId,
    partnerName,
    companyId,
  } = props;

  //  Initializing APIs
  const { UserAPI } = API;

  // Component variables
  const statusIdToUse = !_.isNil(statusId) ? statusId : 3;
  const statusConfig = groupedStatus ? connectionStatusConfigById[statusIdToUse] : partnerStatusConfigById[statusIdToUse];

  // Component hooks
  const history = useHistory();
  const { dispatch } = useMemoizedDispatch();
  //  Post to obtain company with updated packages
  const {
    data: impersonateResponse,
    error: impersonateError,
    mutate: impersonateCompany,
  } = useCustomMutation(
    (params) => UserAPI.handlers.impersonateCompany(params),
    UserAPI.invalidators.impersonateCompany
  );

  //  Watching impersonate error
  useEffect(() => {
    if (!impersonateError) {
      return;
    }
    const impersonationErrorMessage = "Error impersonating company, please click link again";
    dispatch(setAlert(impersonationErrorMessage, alertTypes.error));
    dispatch(onImpersonationSuccess(null));
  }, [impersonateError]);

  //  Watching impersonate response
  useEffect(() => {
    if (!impersonateResponse) {
      return;
    }
    dispatch(onImpersonationSuccess(impersonateResponse));
    history.push('/solution-logs');
  }, [impersonateResponse]);

  // render tooltip
  const renderTooltip = () => {
    if(!statusConfig?.tooltip) return null;

    return (
      <BasicTooltip
        target={componentId}
        content={statusConfig?.tooltip}
      />
    )
  }

  // On badge click
  const onBadgeClick = () => {
    if(statusIdToUse !== 0 || !companyId || !partnerId) return;
    dispatch(setPartnerIdAndName(partnerId, partnerName || null));
    impersonateCompany(companyId);
  }
  return statusConfig ? (
    <>
      <Badge
        id={componentId}
        color={statusConfig.color}
        className="connection-status-badge"
        onClick={onBadgeClick}
      >
          {statusConfig.label}
      </Badge>
      {renderTooltip()}
    </>

  ) : (
    <>-</>
  );
};

export default ConnectionStatusBadge;
