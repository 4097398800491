import _ from "lodash";
//import moment from "moment";
import React, { useState, useEffect, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { getDate } from "../../../utils/helpers";
import celebrationEmoji from '../../../assets/images/emoji/celebration_emoji.svg'
import clapEmoji from '../../../assets/images/emoji/clap_emoji.svg'
import {
    getAmountOfTicks,
    getChartMaxValue,
    getChartMinValue,
    findProjectionDirection,
    getCyvalueReachedText,
    solutionGoal,
    getIcarmMarkerBySolutionAndLetter,
} from '../../../constants/cyValueData';
import CyValueDateFilters from '../cydekick-results/CyValueDateFilters';
import LoadingContent from "../../layout/LoadingContent";
import { isInsideRange } from "../../../utils/timeChartHelpers";

const SolutionLineChart = (props) => {
    const {
        data,
        colors,
        height = 210,
        width = "100%",
        goal = solutionGoal,
        timePeriod,
        hasNewSolution = false,
        filters,
        setFilters,
        hasOverallScore,
        hasZeroScore,
        isLoading,
        errorMessage,
        errorButtonCallback,
        cyvalueReachedDate,
        hasSurpassedGoal,
        chartTitle,
        colorTitle = 'fff'
    } = props;

    const hasEmptySolutions = useMemo(() => (!data || data.length === 0), [data]);
    const [options, setOptions] = useState(null);
    const [series, setSeries] = useState([{ name: '', data: '' }]);
    const [xAxisAnnotations, setXAxisAnnotations] = useState([])
    const [showProjection, setShowProjection] = useState(false);
    const [projectionDirection, setProjectionDirection] = useState(null);
    const [cyvalueReachedData, setCyvalueReachedData] = useState({});
    const [isRenderingHighScore, setIsRenderingHighScore] = useState(false);
    const [hoveringIcarmStep, setHoveringIcarmStep] = useState(null);

    const icarmMarkerMouseEnter =  (stepLetter) => {
        setHoveringIcarmStep(stepLetter)
    }
    const icarmMarkerMouseLeave = () => {
        setHoveringIcarmStep(null)
    }

    // Component did mount
    useEffect(() => {
        const classNamePrefix = 'icarm-annotation-marker-step-'
        const classSelector = `[class*='${classNamePrefix}']`;
        const icarmMarkers = document.querySelectorAll(classSelector)
        if(icarmMarkers) {
            icarmMarkers.forEach((marker) => {
                const className = `apexcharts-point-annotation-marker ${classNamePrefix}`
                const letter = marker?.getAttribute('class')?.replace(className,"")?.charAt(0)
                marker.addEventListener("mouseover", () => icarmMarkerMouseEnter(letter));
                marker.addEventListener("mouseout", icarmMarkerMouseLeave);
            });
        }

        return () => {
            const icarmMarkers = document.querySelectorAll(classSelector)
            if(icarmMarkers) {
                icarmMarkers.forEach((marker) => {
                    marker.removeEventListener("mouseover", icarmMarkerMouseEnter);
                    marker.removeEventListener("mouseout", icarmMarkerMouseLeave);
                });
            }
        };
    }, [options?.annotations]);

    const padDataArray = (length) => {
        return Array.from({ length: length || 1 }, (_, i) => ({ x: i, y: null }))
    }

    //  Function to render projection tooltip
    const renderProjectionTooltip = (index, projection_end_date, start_date, end_date, cyvalue_reached, seriesLength = 2, guarantee_date) => {
        //  If series is projection and length is just one, we need to render projected cyvalue alone
        if ((index !== 0 || seriesLength === 1) && projection_end_date) {
            return `Projected Cybersecurity Value: ${getDate(projection_end_date.getTime())}`;
        }
        if (cyvalue_reached) {
            return `<div class="d-flex align-items-center" ><img src=${celebrationEmoji} class="celebration-emoji img-fluid mr-1">${getCyvalueReachedText(cyvalueReachedDate, guarantee_date)}</div>`;
        }
        if (!start_date || !end_date) { return index ===1 ? '90 days':`0 days`}
        return `${getDate(start_date)} to ${getDate(end_date)}`;
    };

    //  Function to render normal point tooltip
    const renderNormalPointTooltip = (cyvalue_reached, start_date, end_date, guarantee_date) => {
        if (cyvalue_reached) { return `<div class="d-flex align-items-center" ><img src=${celebrationEmoji} class="celebration-emoji img-fluid mr-1">${getCyvalueReachedText(cyvalueReachedDate, guarantee_date)}</div>`; }
        return `${getDate(start_date)} to ${getDate(end_date)}`;
    };

    //  Function to build normal point payload
    const buildNormalPointPayload = (dataPoint, projection_end_date, solutionName, guarantee_date, convertToUnix) => {
        if (!dataPoint) { return; }
        const { score, label, activities, start_date, end_date, cyvalue_reached } = dataPoint
        return {
            y: score,
            x: parseInt(label),
            activities,
            start_date: convertToUnix ? start_date.getTime() : start_date,
            end_date: convertToUnix ? end_date.getTime() : end_date,
            cyvalue_reached,
            projection_end_date,
            solution_name: solutionName,
            guarantee_date,
        };
    };
    // Function to build new solution annotations
    const buildNewSolutionAnnotations = () => {
        return hasNewSolution ? [renderNewSolutionAnnotation()] : [];
    }

    // Function to build cyvalue reached annotations
    const buildCyvalueReachedAnnotations = () => {
        return !_.isNil(cyvalueReachedData ?.x) ? [renderCyvalueReachedAnnotation(cyvalueReachedData)] : [];
    }

    const buildIcarmImageStepAnnotation = (step, solutionName) => {
        const stepLetter = step?.name?.charAt(0);
        const icarmStepImage = getIcarmMarkerBySolutionAndLetter(solutionName, stepLetter)
        return {
            ...step,
            yAxisIndex: 0,
            seriesIndex: 0,
            marker: {
                size: 0,
            },
            label: {
                text: null,
            },
            image: {
                path: icarmStepImage,
                width: 20,
                height: 20,
                offsetX: 0,
                offsetY: 0,
            }
        }
    }

    const buildIcarmStepAnnotation = (step) => {
        const stepLetter = step?.name?.charAt(0);
        const isHovered = hoveringIcarmStep === stepLetter;
        const info = step?.info;
        return {
            ...step,
            yAxisIndex: 0,
            seriesIndex: 0,
            marker: {
                size: 15,
                cssClass: `icarm-annotation-marker-step-${stepLetter}`,
                fillColor: "transparent",
                strokeColor: "transparent",
                offsetY: -10,
            },
            label: {
                borderColor: 'transparent',
                borderWidth: 1,
                borderRadius: 6,
                text: isHovered && step?.info ? info : null,
                textAnchor: 'middle',
                offsetX: 15,
                offsetY: 50,
                style: {
                    background: '#E6EAF7',
                    color: '#170B45',
                    fontSize: '11px',
                    fontWeight: 400,
                    fontFamily: 'Open Sans',
                    cssClass: 'step-description-annotation-label',
                    padding: {
                        left: 3,
                        right: 3,
                        top: 3,
                        bottom: 3,
                    }
                },
            },
        }
    }

    // Function to build icarm annotations
    const buildIcarmAnnotations = () => {
        const icarmAnnotations = [];
        if(!data) return icarmAnnotations;
        data.forEach((solution) => {
            const icarmSteps = solution?.icarm?.steps;
            if(icarmSteps && Array.isArray(icarmSteps)) {
                icarmSteps.forEach((step) => {
                    if(step?.date) {
                        // Adds a image for the step
                        icarmAnnotations.push(
                            buildIcarmImageStepAnnotation(step, solution?.name)
                        )
                        // Adds a marker needed for the info tooltip
                        // TODO: add when crash is fixed
                        // icarmAnnotations.push(
                        //     buildIcarmStepAnnotation(step)
                        // )
                    }
                })
            }
        })
        return icarmAnnotations;
    }

    // Function to build annotations
    const getAnnotationPoints = () => {
        return [
            ...buildNewSolutionAnnotations(),
            ...buildCyvalueReachedAnnotations(),
            ...buildIcarmAnnotations(),
        ]
    }

    const addXAxisAnnotations = (annotations) => {
        return annotations.map(({ label, value, color, offset }) => {
            return ({
                x: value,
                strokeDashArray: 6,
                borderColor: color,
                label: {
                    borderColor: 'transparent',
                    style: {
                        color: color,
                        background: 'transparent',
                        fontSize: "10px",
                        fontFamily: 'Open Sans',
                        cssClass: 'apexcharts-yaxis-annotation-label',
                    },
                    offsetX: -15,
                    offsetY: -128 - (offset || 0),
                    text: label,
                    textAnchor: 'start',
                    position: 'left',
                }
            })
        });
    }

    const renderCyvalueReachedAnnotation = (dataPoint) => {
        //TODO: only call when it's filtered by solution
        const textLabel = getCyvalueReachedText(cyvalueReachedDate, dataPoint ?.guarantee_date);
        const reachedEarly = textLabel !== 'Cybersecurity value reached';

        return {
            ...dataPoint,
            yAxisIndex: 0,
            seriesIndex: 0,
            marker: {
                size: 0,
                fillColor: "transparent",
                strokeColor: "#333",
                strokeWidth: 3,
                shape: "circle",
                radius: 2,
                OffsetX: 0,
                OffsetY: 0,
                cssClass: 'apexcharts-cy-reached-point-annotation',
            },
            label: {
                borderColor: 'transparent',
                borderWidth: 1,
                borderRadius: 6,
                text: textLabel,
                textAnchor: 'middle',
                offsetX: 15,
                offsetY: isRenderingHighScore ? -24 : -4,
                style: {
                    background: '#E6EAF7',
                    color: '#170B45',
                    fontSize: '11px',
                    fontWeight: 400,
                    fontFamily: 'Open Sans',
                    cssClass: 'cyvalue-reached-annotation-label',
                    padding: {
                        left: reachedEarly ? 30 : 25,
                        right: 5,
                        top: 3,
                        bottom: 4,
                    }
                },
            },
            image: {
                path: celebrationEmoji,
                width: 15,
                height: 15,
                offsetX: reachedEarly ? -105 : -67,
                offsetY: isRenderingHighScore ? -29 : -9,
            }

        }
    }

    const renderNewSolutionAnnotation = () => {
        return {
            x: 0,
            y: 0,
            yAxisIndex: 0,
            seriesIndex: 0,
            marker: {
                size: 0,
                fillColor: "#fff",
                strokeColor: "#333",
                strokeWidth: 3,
                shape: "circle",
                radius: 2,
                OffsetX: 0,
                OffsetY: 0,
                cssClass: 'apexcharts-point-annotation',
            },
            label: {
                borderColor: 'transparent',
                borderWidth: 1,
                borderRadius: 2,
                text: 'new solution installed',
                textAnchor: 'end',
                offsetX: 145,
                offsetY: -10,
                style: {
                    background: 'transparent',
                    color: '#A6B0CF',
                    fontSize: '10px',
                    fontWeight: 400,
                    fontFamily: 'Open Sans',
                    cssClass: 'new-solution-installed',
                    padding: {
                        left: 5,
                        right: 5,
                        top: 0,
                        bottom: 2,
                    }
                },
            },
            image: {
                path: clapEmoji,
                width: 20,
                height: 20,
                offsetX: 25,
                offsetY: -15,
            }
        }

    }

    const getChartFillOptions = () => {
        const projectionColorConfig = ['transparent', ...colors];
        const solutionColorConfig = hasEmptySolutions ? ['transparent'] : colors;
        const fillColorsConfig = showProjection ? projectionColorConfig : solutionColorConfig;
        return {
            colors: fillColorsConfig,
            type: 'gradient',
            gradient: {
                type: 'vertical',
                opacityFrom: 0,
                opacityTo: 0,
                stops: [0, 100]
            }
        }
    }

    const getChartStrokeOptions = () => {
        const projectionDashArray = [8, 0];
        const solutionArray = hasEmptySolutions ? [8] : [0];
        const dashArray = showProjection ?  projectionDashArray : solutionArray
        return {
            width: 2,
            curve: 'straight',
            dashArray,
        }
    }

    useEffect(() => {
        setOptions({
            chart: {
                zoom: { enabled: false },
                toolbar: { show: false },
            },
            colors: colors,
            dataLabels: { enabled: false },
            stroke: getChartStrokeOptions(),
            fill: getChartFillOptions(),
            markers: {
                size: 5,
                shape: 'circle',
                strokeWidth: 0,
                radius: 0,
                hover: {
                    sizeOffset: 3
                }
            },
            annotations: {
                position: 'front',
                yaxis: [
                    {
                        y: goal,
                        strokeDashArray: 6,
                        borderColor: '#44CC03',
                        label: {
                            borderColor: 'transparent',
                            style: {
                                color: '#44CC03',
                                background: 'transparent',
                                fontSize: "10px",
                                fontFamily: 'Open Sans',
                                cssClass: 'apexcharts-yaxis-annotation-label',
                            },
                            offsetX: -29,
                            offsetY: 6.7,
                            text: 'goal',
                            textAnchor: 'start',
                            position: 'left',
                        }
                    }
                ],
                xaxis: addXAxisAnnotations(xAxisAnnotations),
                points: getAnnotationPoints(),
                texts: [{
                    x: 20,
                    y: 195,
                    yAxisIndex: 0,
                    seriesIndex: 0,
                    text: `${timePeriod}s`,
                    textAnchor: 'middle',
                    foreColor: "#605E92",
                    fontSize: '11px',
                    fontFamily: 'Open Sans',
                    fontWeight: 400,
                    backgroundColor: 'transparent',
                    borderColor: '#c2c2c2',
                    borderRadius: 0,
                    borderWidth: 0,
                    paddingLeft: 4,
                    paddingRight: 4,
                    paddingTop: 2,
                    paddingBottom: 2,
                }],
            },
            xaxis: {
                type: 'category',
                tickAmount: getAmountOfTicks(data),
                tickPlacement: 'between',
                axisBorder: {
                    show: true,
                    color: '#605E92',
                    height: 1,
                    width: '100%',
                    offsetX: -3,
                    offsetY: 0,
                },
                axisTicks: {
                    show: true,
                    borderType: 'solid',
                    color: '#605E92',
                    height: 9,
                    offsetX: 0,
                    offsetY: 0,
                },
                labels: {
                    formatter: function (value) {
                        return value < 0 ? '-' : parseInt(value)
                    }
                },
                tooltip: {
                    enabled: true,
                    formatter: function (val, opts) {
                        const { seriesIndex, dataPointIndex, w } = opts;
                        const start_date = w.config.series[seriesIndex]?.data[dataPointIndex]?.start_date;
                        const end_date = w.config.series[seriesIndex]?.data[dataPointIndex]?.end_date;
                        const seriesName = w.config.series[seriesIndex]?.name;
                        const cyvalue_reached = w.config.series[seriesIndex]?.data[dataPointIndex]?.cyvalue_reached;
                        const projection_end_date = w.config.series[seriesIndex]?.data[dataPointIndex]?.projection_end_date;
                        const guarantee_date = w.config.series[seriesIndex]?.data[dataPointIndex]?.guarantee_date;
                        const seriesLength = w.config.series[seriesIndex]?.data?.length;
                        return seriesName === 'projection' ?
                            renderProjectionTooltip(dataPointIndex, projection_end_date, start_date, end_date, cyvalue_reached, seriesLength, guarantee_date)
                            : renderNormalPointTooltip(cyvalue_reached, start_date, end_date, guarantee_date);
                    },
                    offsetY: isRenderingHighScore ? -200 : -180,
                    followCursor: true
                },
                min: getChartMinValue(data),
                max: getChartMaxValue(data),
            },
            yaxis: {
                min: 0,
                max: 100,
                tickAmount: 5
            },
            legend: {
                show: false
            },
            tooltip: {
                theme: false,
                shared: true,
                intersect: false,
                enabledOnSeries: [0],
                custom: function (opts) {
                    if (hasEmptySolutions) { return null; }
                    const { series, seriesIndex, dataPointIndex, ctx } = opts;
                    const activities = ctx.w.config.series[seriesIndex]?.data[dataPointIndex]?.activities;
                    const seriesName = ctx.w.config
                    .series[seriesIndex]?.data[dataPointIndex]?.solution_name;
                    const seriesLength = ctx.w.config.series[seriesIndex]?.data?.length;
                    const score = series[seriesIndex][dataPointIndex];
                    setIsRenderingHighScore(score >= 80);
                    //  If series is projection and length is 1, we do not need to render tooltip (cuz is projected cyvalue alone)
                    return seriesName === 'projection' && (dataPointIndex !== 0 || seriesLength === 1) ? null : (
                        `<div class="solution-data-point-tooltip">
                            <div class="title">${seriesName}</div>
                            <div> Cybersecurity Value: ${score}</div>
                            <div> Activities: ${activities}</div>
                        </div>`
                    );
                }
            },
            responsive: [
                {
                    breakpoint: 600,
                    options: { chart: { toolbar: { show: !1 } }, legend: { show: !1 } },
                },
            ],
        });
    }, [colors, xAxisAnnotations, showProjection, hasEmptySolutions, cyvalueReachedData, isRenderingHighScore, hoveringIcarmStep])

    useEffect(() => {
        let thisPeriodInterval = null;
        if (!data) { return; }
        //  Initializing projection variables
        let projectionStartDataPoint = { x: null };
        const projectionEndDataPoint = { y: solutionGoal, x: data.length };
        const projectionSeries = [{ name: 'projection', data: [projectionStartDataPoint, projectionEndDataPoint] }];
        const guaranteeSeries = [];
        const cyvalueReachedDataPoint = {};
        let shouldShowProjection = false;
        //  Creating data points and filling projections info
        const solutionsSeries = data.map((solution) => {
            let dataPoints = [];
            const emptySeries = { name: '', data: padDataArray(16), icarm: []};
            if (!solution || !solution.data || !solution.data.length) { return emptySeries }
            const { projection_end_date, guarantee_date } = solution;
            solution.data.forEach((item) => {
                //  Parsing item
                const {
                    score,
                    start_date,
                    end_date,
                    activities,
                    label,
                    cyvalue_reached,
                    isDummy,
                    start_projection,
                } = item;
                //  Verifying if guarante date is going to be visible
                const ableToShowProjection = (projection_end_date && isInsideRange(projection_end_date, start_date, end_date));
                //  Setting projection end point
                if (ableToShowProjection) {
                    shouldShowProjection = true;
                    projectionEndDataPoint.x = parseInt(label);
                    projectionEndDataPoint.projection_end_date = projection_end_date;
                    projectionEndDataPoint.solution_name = 'projection'
                }
                //  Setting projection start point
                if (start_projection && !cyvalue_reached) {
                    projectionStartDataPoint.x = parseInt(label);
                    projectionStartDataPoint.y = score;
                    projectionStartDataPoint.start_date = start_date;
                    projectionStartDataPoint.end_date = end_date;
                    projectionStartDataPoint.activities = activities;
                    projectionStartDataPoint.cyvalue_reached = cyvalue_reached;
                    projectionStartDataPoint.projection_end_date = projection_end_date;
                    projectionStartDataPoint.solution_name = solution.name;
                    projectionStartDataPoint.guarantee_date = guarantee_date;
                }

                //Adding Cyvalue Reached point
                if (cyvalue_reached) {
                    cyvalueReachedDataPoint.x = parseInt(label);
                    //cyvalueReachedDataPoint.y = score;
                    cyvalueReachedDataPoint.y = 100;//always on top
                    cyvalueReachedDataPoint.guarantee_date = guarantee_date;
                }
                //  Adding guarantee series
                const ableToShowGuarantee = (guarantee_date && isInsideRange(guarantee_date, start_date, end_date));
                if (ableToShowGuarantee) {
                    guaranteeSeries.push({ label: 'guarantee', value: parseInt(label), color: '#FF9243' });
                }
                //  Seeting the interval to know where is the current week
                if (isInsideRange(new Date(), start_date, end_date)) {
                    thisPeriodInterval = parseInt(label)
                }
                //  Returning empty point in case is dummy
                if (isDummy) { return; }
                //  Adding point to data points
                const pointToAdd = buildNormalPointPayload(item, projection_end_date, solution.name, guarantee_date, true);
                dataPoints.push(pointToAdd);

            });
            //  Setting projection direction for filters
            if (!shouldShowProjection) {
                setProjectionDirection(findProjectionDirection(projection_end_date, filters));
            }
            return {
                name: solution.name,
                data: dataPoints,
            };
        });
        //  Building the projection series data
        projectionSeries[0].data = projectionStartDataPoint.x !== null ?
            [projectionStartDataPoint, projectionEndDataPoint] : [projectionEndDataPoint];
        //  Setting X axis annotations
        const guaranteeAnnotationValue = guaranteeSeries[0]?.value;
        const annotationsOverlap = projectionEndDataPoint.x === guaranteeAnnotationValue  || thisPeriodInterval === guaranteeAnnotationValue;
        const currentPeriodConfig = {
            label: `this ${timePeriod}`,
            value: thisPeriodInterval,
            color: '#A6B0CF',
            offset: annotationsOverlap? 12 : 0
        }

        setXAxisAnnotations([
            ...guaranteeSeries,
            ...(!_.isNil(thisPeriodInterval) ? [currentPeriodConfig] : []),
        ]);
        //  Setting show projection state
        setShowProjection((shouldShowProjection && !hasSurpassedGoal));

        //  Setting cyvalue reached DataPoint
        setCyvalueReachedData(cyvalueReachedDataPoint);

        //  Setting series information
        if (!hasEmptySolutions) {
            setSeries([
                ...((shouldShowProjection && !hasSurpassedGoal) ? projectionSeries : []),
                ...solutionsSeries
            ]);
        } else {
            setSeries([
                { name: 'projection', data: [{ x: 0, y: 0, solution_name: 'projection' }, { x: 13, y: solutionGoal, solution_name: 'projection' }] }
            ])
        }
    }, [setSeries, setXAxisAnnotations, setCyvalueReachedData, data])

    // Render chart title
    const renderTitle = () => {
        return (
            <div className="dashboard-card-title mb-0" style={{color:colorTitle}}>
                {chartTitle}
            </div>
        )
    }

    // Render month and week pickers
    const renderChartControls = () => {
        return (
            <div className="ml-auto">
                <CyValueDateFilters
                    filters={filters}
                    setFilters={setFilters}
                    hasOverallScore={hasOverallScore}
                    hasZeroScore={hasZeroScore}
                    hasNewSolution={hasNewSolution}
                    isProjectionRendered={showProjection}
                    projectionDirection={projectionDirection}
                />
            </div>
        )
    }

    // Render chart title and controls
    const renderHeader = () => {
        return (
            <div className="d-flex justify-between">
                {renderTitle()}
                {renderChartControls()}
            </div>
        )
    }
    return (
        <>
            <LoadingContent
                isLoading={isLoading}
                errorMessage={errorMessage}
                errorButtonCallback={errorButtonCallback}
                iconType="solidIcon"
                errorStyle={{ minHeight: "225px" }}
                loadingStyle={{ minHeight: "225px" }}
            >
                {renderHeader()}
                <div className="d-flex align-items-center w-100 h-100">
                    <div className="w-100">
                        {(!series || !options) ? null : (
                            <ReactApexChart
                                options={options}
                                series={series}
                                type="area"
                                height={height}
                                width={width}
                            />
                        )}
                    </div>
                </div>
            </LoadingContent>
        </>
    )
}

export default SolutionLineChart;
