import React from 'react';
import CustomDatepicker from '../../calendar/CustomDatepicker';

const ActionDatePickerModal = ({ field, handleParams, params, key = null }) => {

  if (!params["risk"]) return null;

  return (
    <div className='modal-field textbox-modal' key={key}>
      <CustomDatepicker
        placeholderText={field.title}
        value={params[field.key]}
        setValue={(value) => handleParams(field.key, value)}
        disabled={params["forever"] ? true : false}
      />
    </div>
  );
};

export default ActionDatePickerModal;
