import cyvatarAxios from '../../api/axios';

const setAuthToken = (token) => {
  if (token !== "undefined" && token) {
    cyvatarAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete cyvatarAxios.defaults.headers.common['Authorization'];
  }
}
export default setAuthToken;
