import { 
    LOGOUT_SUCCESS,
    RISK_STATEMENTS_SUCCESS,
    RISK_STATEMENTS_UPDATE_SUCCESS,
    SET_HOVERED_RISK_BUBBLE,
    SET_RISK_TREATMENT_FILTER,
    SET_SELECTED_RISK_STATEMENT,
    BUSINESS_RISK_CONFIG_SUCCESS,
    OPPORTUNITIES_TO_IMPROVE_SUCCESS,
    SET_SHOULD_RELOAD_BUSINESS_RISK,
    RISK_STATEMENT_STATUS_SUCCESS
} from "../actions/types";

const initialState = {
    cyso_subscribed: false,
    shouldReload: false,
    hoveredStatement: null,
    selectedStatement: 1,
    riskTreatmentFilter: null,
    config: {},
    riskStatementsResponses: null,
    opportunitiesToImproveData : [],
    riskStatusData: {}
}

const businessRiskReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_SELECTED_RISK_STATEMENT: 
            return {
                ...state,
                selectedStatement: payload
            };
        case SET_HOVERED_RISK_BUBBLE:
            return {
                ...state,
                hoveredStatement: payload
            };
        case SET_RISK_TREATMENT_FILTER:
            return {
                ...state,
                riskTreatmentFilter: payload
            };
        case BUSINESS_RISK_CONFIG_SUCCESS:
            return {
                ...state,
                cyso_subscribed: payload?.cyso_subscribed,
                config: payload
            };
        case RISK_STATEMENTS_SUCCESS:
            return {
                ...state,
                riskStatementsResponses: payload?.statements || []
            };
        case RISK_STATEMENTS_UPDATE_SUCCESS:
            return {
                ...state,
                riskStatementsResponses: payload?.statements || [],
                shouldReload: true
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                ...initialState,
            };

        case OPPORTUNITIES_TO_IMPROVE_SUCCESS:
            return {
                ...state,
                //cyso_subscribed: payload?.cyso_subscribed, TODO: add once is added in endpoint
                opportunitiesToImproveData: payload
            }
        case RISK_STATEMENT_STATUS_SUCCESS:
            return{
                ...state,
                riskStatusData: payload
            }
        case SET_SHOULD_RELOAD_BUSINESS_RISK: 
            return {
                ...state,
                shouldReload: payload
            }
        default:
        return state;
    }
  };
  
  export default businessRiskReducer;