import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
// import InformationExpandableBar from './InformationExpandableBar';
// import { securityPoliciesFreemiumBar, securityPoliciesSubscribedBar } from '../../constants/pagesData';
import {trackPage} from '../../utils/gaTracker'
import companyIdSelector from '../../selectors/companyIdSelector';
import PoliciesGrid from './PoliciesGrid';
import ViewWrapper from '../layout/ViewWrapper';
import MainScrollableContainer from '../layout/MainScrollableContainer';

const SecurityPolicies = (props) => {
  //  Parsing props
  // const { hasVcyso } = props;

  //  Getting needed info from redux store
  const company_id = useSelector((state) => companyIdSelector(state));

  //  Component did mount
  useEffect(() => {
    trackPage('cyso-policies');
  }, []);

  //  Function to render information bar
  // const renderInfoBar = () => {
  //   const informationToRender = !hasVcyso ? securityPoliciesFreemiumBar : securityPoliciesSubscribedBar;
  //   const { title, descriptions, hasContactUs } = informationToRender;
  //   return (
  //     <InformationExpandableBar
  //       title={title}
  //       content={descriptions}
  //       isFreemium={!hasVcyso}
  //       hasHelpMessage={hasContactUs}
  //     />
  //   );
  // };

  const renderInformation = () => {
    return (
      <div className="policies-message">
        Here you can always search, consult and learn about the policies applicable to your company.
      </div>
    )
  }

  const renderPolicies = () => {
    return (
        <PoliciesGrid
          showDownloadSection={true}
          companyId={company_id}
        />
    )
  }
  const renderContent = () => {
    return (
      <MainScrollableContainer
        additionalClassName="vcyso-container"
      >
          <div className="security-policies-container">
            {renderInformation()}
            {/* {renderInfoBar()} */}
            {renderPolicies()}
          </div>
      </MainScrollableContainer>
    )
  }

  //  Rendering
  return (
    <ViewWrapper
      layoutType="vertical"
      viewName="Policies"
      hasImpersonationMode={true}
    >
      {renderContent()}
    </ViewWrapper>
  );
};

export default SecurityPolicies;
