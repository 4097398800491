import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryClientProvider } from 'react-query';
import { store, persistor, queryClient } from './store';
import App from './App';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { PersistGate } from 'redux-persist/integration/react';
import { AbilityContext } from './contexts/AbilityContext';
import ability from './config/ability';
import { PrismicProvider } from '@prismicio/react'
import { client } from './api/prismic/prismic'
import amplitude from 'amplitude-js';


amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY);
Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENV,
    release: process.env.REACT_APP_VERSION
});

ReactDOM.render(
    <AbilityContext.Provider value={ability}>
        <PrismicProvider client={client}>
            <QueryClientProvider client={queryClient}>
                        <Provider store={store}>
                            <PersistGate loading={null} persistor={persistor}>
                                <BrowserRouter>
                                    <App />
                                </BrowserRouter>
                            </PersistGate>
                        </Provider>
            </QueryClientProvider>
        </PrismicProvider>
    </AbilityContext.Provider>,
    document.querySelector('#root')
);
