import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Tabs from '../../layout/tabs/Tabs';
import CommentsSection from '../../layout/comments/CommentsSection';
import useCanAbility from '../../../hooks/useCanAbility';
import { MODIFY_ACTION, PRIVILEGE_SOURCES } from '../../../constants/privileges';
import { relabelCommentsPendingReview } from '../../../utils/issuesHelpers';
import useUserType from '../../../hooks/useUserType';
import { ISSUE_COMMENT_SECTION_TYPE } from '../../../constants/common';

const GrcIssuesComments = (props) => {
  //  Parsing props
  const {
    comments = [],
    changeIssue,
    expandedMode = false,
    statusId,
    statusName,
  } = props;

  //  Watching redux store
  const user = useSelector((state) => state.auth?.user);
  const isMemberView = useSelector((state) => state.impersonation?.isMemberView);

  //  Component state
  const [state, setState] = useState({ isProcessing: false, newAdded: false });

  // Component Hooks
  const ability = useCanAbility();
  const userType = useUserType();

  // Component variables
  const canModifyIssues = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.ISSUES.GENERAL) || isMemberView;

  //  Function to handle comment sections changes
  const handleCommentsChanges = (value) => {
    if (!value) { return; }
    setState({ ...state, isProcessing: true });
    const newComment = {
      updated_at: new Date(),
      user: {
        id: user?.id,
        full_name: user?.name,
        profile_image: user?.profileImage,
      },
      status: {
        id: statusId || 0,
        name: statusName
      },
      content: value,
    };

    const newComments = [newComment, ...comments];
    changeIssue('comments', { list: newComments });
    setState({ ...state, isProcessing: false, newAdded: true });
  };

  //  Rendering
  return (
    <div className={`w-${expandedMode ? '65' : '100'}`}>
      <Tabs tabs={[
        {
          name: "Change log",
          content: (
            <CommentsSection
              comments={relabelCommentsPendingReview(comments, userType)}
              nonReduxCommentsState={state}
              callback={handleCommentsChanges}
              linkToRedux={false}
              changeNewAdded={(flag) => setState({ ...state, newAdded: flag })}
              hideRemediation={true}
              isDisabled={!canModifyIssues}
              commentType={ISSUE_COMMENT_SECTION_TYPE}
            />
          )
        }
      ]} />
    </div>
  );
};

export default GrcIssuesComments;
