import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import TagsSection from '../tags/TagsSection';
import errorMessages from '../../constants/errorMessages';
import { issueTypes } from '../../constants/issues';
import {
  RELOAD_ISSUES_TAGS_LIST,
  ISSUE_ADD_TAG_SUCCESS,
  ISSUE_ADD_TAG_FAILURE,
  ISSUE_REMOVE_TAG_SUCCESS,
  ISSUE_REMOVE_TAG_FAILURE,
} from '../../actions/types';
import { successActionCreator } from '../../actions/action-creators/success'
import { errorActionCreator } from '../../actions/action-creators/errors';
import { setAlert } from '../../actions/alerts';
import API from '../../api';
import useCustomMutation from '../../hooks/useCustomMutation';
import useCanAbility from '../../hooks/useCanAbility';
import { MODIFY_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';
import { onReloadIssues } from '../../actions/issues';
import issuesInfoSelector from '../../selectors/issuesInfoSelector';

const IssueTags = (props) => {
  //  Parsing props
  const {
    issue,
    issueType = issueTypes.VULNERABILITIES,
    showTitle = true,
    showAdd = true,
    showList = true,
    className,
    isLimited = false,
    placeholder,
  } = props;

  //  Initializing APIs
  const { IssueAPI } = API;

  //  Watching redux store
  const selectedIssue = useSelector((state) => {
    if (issueType === issueTypes.GRC) {
      return issue;
    }
    return issuesInfoSelector(state, 'selectedIssue', issueType)
  });
  const reloadTagsList = useSelector((state) => issuesInfoSelector(state, 'reloadTagsList', issueType));
  const isMemberView = useSelector((state) => state?.impersonation?.isMemberView);

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const {
    data: addTagResponse,
    error: addTagError,
    mutate: callAddTag,
  } = useCustomMutation(
    (params) => IssueAPI.handlers.addIssueTag(params),
    IssueAPI.invalidators.addIssueTag
  );
  const {
    data: removeTagResponse,
    error: removeTagError,
    mutate: callRemoveTag,
  } = useCustomMutation(
    (params) => IssueAPI.handlers.removeIssueTag(params),
    IssueAPI.invalidators.removeIssueTag
  );
  const ability = useCanAbility();

  // Component State
  const [currentTags, setCurrentTags] = useState(selectedIssue?.taggings)

  // Component variables
  const canModifyIssues = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.ISSUES.GENERAL) || isMemberView;

  //  Function to trigger a tag reload
  const setReloadList = (value) => {
    dispatch(successActionCreator(RELOAD_ISSUES_TAGS_LIST, value))
  }

  //  Function to handle dispatch
  const handleDispatch = (action, payload, actionCreator) => {
    if (issueType === issueTypes.GRC) {
      setReloadList(true); // Trigger reload manually
      return;
    }
    dispatch(actionCreator(action, payload));
  };

  // Watch for changes in tags
  useEffect(() => {
    setCurrentTags(selectedIssue?.tags)
  }, [selectedIssue?.tags])

  //  Watching add tag response
  useEffect(() => {
    if (!addTagResponse) { return; }
    handleDispatch(ISSUE_ADD_TAG_SUCCESS, addTagResponse, successActionCreator);
    if (!addTagResponse?.is_resource_updated) { return; }
    dispatch(setAlert('Tag added', 'success'));
    dispatch(onReloadIssues(true, issueType))
  }, [addTagResponse]);

  //  Watching add tag error
  useEffect(() => {
    if (!addTagError) { return; }
    const errorMessage = addTagError?.response?.data?.errorMessage || errorMessages.defaultPost;
    dispatch(errorActionCreator(ISSUE_ADD_TAG_FAILURE, { message: errorMessage }));
    dispatch(setAlert(errorMessage, 'danger'));
  }, [addTagError]);

  //  Watching remove tag response
  useEffect(() => {
    if (!removeTagResponse) { return; }
    handleDispatch(ISSUE_REMOVE_TAG_SUCCESS, removeTagResponse, successActionCreator);
    dispatch(setAlert('Tag removed', 'success'));
    dispatch(onReloadIssues(true, issueType))
  }, [removeTagResponse]);

  //  Watching remove tag error
  useEffect(() => {
    if (!removeTagError) { return; }
    const errorMessage = removeTagError?.response?.data?.errorMessage || errorMessages.defaultPost;
    dispatch(errorActionCreator(ISSUE_REMOVE_TAG_FAILURE, { message: errorMessage }));
    dispatch(setAlert(errorMessage, 'danger'));
  }, [removeTagError]);

  //  Function to add tags
  const addTag = (params) => {
    const payload = { ...params, issue_id: selectedIssue?.id, company_id: selectedIssue?.companyId };
    callAddTag(payload);
  };

  // Function to remove tags
  const removeTag = (global_tag_id) => {
    const payload = { global_tag_id, issue_id: selectedIssue?.id, company_id: selectedIssue?.companyId };
    callRemoveTag(payload);
  }

  //  Rendering
  return (
    <TagsSection
      showTitle={showTitle }
      showAdd={showAdd}
      showList={showList}
      tagsList={currentTags}
      addTagCallback={addTag}
      removeTagCallback={removeTag}
      issueId={selectedIssue?.id}
      companyId={selectedIssue?.companyId}
      reloadTagsList={reloadTagsList}
      setReloadTagsList={setReloadList}
      disabled={!canModifyIssues}
      className={className}
      isLimited={isLimited}
      placeholder={placeholder}
    />
  );
};

export default IssueTags;
