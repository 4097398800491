import React from 'react';
import BasicModal from './BasicModal';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import { setConfirmationModal } from '../../actions/layout';

const ConfirmationModal = () => {
    // Getting needed info from redux store
    const showModal = useSelector(state => state.layout?.confirmationModal?.show);
    const message = useSelector(state => state.layout?.confirmationModal?.message);


    // Component Hooks
    const { dispatch } = useMemoizedDispatch();

    const closeModal = () => {
        dispatch(setConfirmationModal({
            show: !showModal
        }))
    }

    return (
        <BasicModal
            showModal={showModal}
            toggleModal={closeModal}
            header={"Confirmation"}
            customClassName="confirmation-modal"
            headerClassName="header-confirmation-modal"
        >
            <div
                className="confirmation-message mb-2 d-flex align-items-center"
            >
                <i className="bx bx-check-circle mr-2"/>
                {message}
            </div>
            <Button
                color="light"
                className="float-right"
                onClick={closeModal}
            >
                Close
            </Button>
        </BasicModal>
    )
}

export default ConfirmationModal;
