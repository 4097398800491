export const timeUnits = {
    month: 'MONTH',
    week: 'WEEK',
    ninety_days: 'QUARTER'
};

export const timeUnitsArray = [
    { type: timeUnits.week, label: 'Weeks' },
    { type: timeUnits.month, label: 'Months' },
    //{ type: timeUnits.ninety_days, label: '90 days' }
];

export const devicePointColorConfig = {
    'TVMAAS': '#579BFC',
    'ITAMAAS': '#FFCB00',
    'SEMAAS': '#FF158A',
    'FREEMIUM': '#4ECCC6',
};

export const deviceTooltipLabelColorConfig = {
    'tvmaas': 'tvm-trend',
    'itamaas': 'itam-trend',
    'semaas': 'sem-trend',
    'freemium': 'freemium-trend',
};

export const tendencyChartPointsByTimeUnit = {
    [timeUnits.week]: 11,
    [timeUnits.month]: 6,
    [timeUnits.quarter]: 6,
};

export const dashboardTrendMinPoints = 3;
