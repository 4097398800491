import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import TagComponent from './TagComponent';

const TagsTooltip = (props) => {
    const {
        tagsList,
        targetId,
        placement='bottom',
        className
    } = props;

    const renderTagsList = () => {
        if(!tagsList) return null;
        return tagsList.map((tag, index) => (
            <div key={`container-${targetId}-tooltip-tag-${index}`} className="text-left">
                <TagComponent
                    id={tag.id}
                    label={tag.title}
                    showRemoveButton={false}
                />
            </div>
        ))
    }
    return (
        <UncontrolledTooltip
            className={`tags-tooltip ${className || ''}`}
            placement={placement}
            target={targetId}
            arrowClassName={`tags-tooltip-arrow ${className || ''}`}
        >
            {renderTagsList()}
        </UncontrolledTooltip>
    )
}

export default TagsTooltip;
