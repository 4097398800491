import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ProfileMenuDropdown from "../../ProfileMenuDropdown";
import SubTabs from '../../navigation/SubTabs';
import Actions from '../../navigation/Actions';
import { trackEvent } from "../../../../utils/gaTracker";
import { existPathOnList, getSubtabsByPath } from '../../../../utils/helpers';
import { publicRoutesPaths } from '../../../../constants/navigation';
import _ from 'lodash';
import GrcNavigationDropdown from '../../../grc/GrcNavigationDropdown';
import useCanAbility from '../../../../hooks/useCanAbility';
import { CREATE_ACTION, PRIVILEGE_SOURCES } from '../../../../constants/privileges';

const AuthenticatedHeader = (props) => {
  //  Parsing props
  const {
    isAuthenticated,
    layoutType,
    onSaveProgressClick,
  } = props;

  //  Getting location from router
  const location = useLocation();

  //  Watching redux store
  const subtabs = useSelector((state) => state.navigation.subtabs);
  const activeImpersonation = useSelector((state) => !_.isNil(state.impersonation.companyId));

  //  Component state
  const [showSaveProgress, setShowSaveProgress] = useState(false);

  // Component Hooks
  const ability = useCanAbility();

  //  Watching location changes
  useEffect(() => {
    setShowSaveProgress(location.pathname === "/cybercheckup" || location.pathname === "/welcome");
  }, [location.pathname]);

  //  Function to get container style
  const getContainerStyle = () => {
    if (!subtabs || subtabs.length === 0) { return { marginLeft: 'auto' }; }
    return { width: '100%' }
  };

  //  Function to render authenticated content
  const renderAuthContent = () => {
    const isPublicRoute = existPathOnList(location.pathname, publicRoutesPaths);
    if (isPublicRoute) { return null; }
    return (
      <React.Fragment>
        {layoutType !== 'vertical' ? (
          <button
            className="btn btn-contact-us text-dark my-1"
            onKeyUp={() => {
              trackEvent("contact_us", "user", "contact_us")
              window.open(process.env.REACT_APP_CONTACT_SALES_URL, "_blank");
            }}
            onClick={() => {
              trackEvent("contact_us", "user", "contact_us")
              window.open(process.env.REACT_APP_CONTACT_SALES_URL, "_blank");
            }}
          >
            Contact Us
          </button>
        ) : null}
      </React.Fragment>
    );
  };

  //  Function to render unauthorized content
  const renderUnAuthContent = () => {
    return (
      <React.Fragment>
        {showSaveProgress ? (
          <button
            className="btn btn-save-progress btn-light text-dark px-2 px-md-3 py-2 mx-2 mx-md-3 my-1"
            onClick={onSaveProgressClick}
          >
            Save your progress
          </button>
        ) : null}
      </React.Fragment>
    );
  };

  //  Function to render subtabs
  const renderSubtabs = () => {
    if (!subtabs || subtabs.length === 0) { return null; }
    const subtab = getSubtabsByPath(location?.pathname);
    const dropdownOptions = subtab?.dropdownOptions;
    const canImpersonate = ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.MULTIPLE.IMPERSONATE)
    const showDropdown = (dropdownOptions?.show && !dropdownOptions?.limitToCydekicksView) || (dropdownOptions?.show && dropdownOptions?.limitToCydekicksView && canImpersonate && !activeImpersonation)
    return <SubTabs tabs={subtabs} isDynamic={subtab?.hasDynamicSubtabs} isDropdown={showDropdown} />
  };


  //  Function to render profile menu dropdown
  const renderProfileMenuDropdown = () => {
    if (!showSaveProgress) { return null; }
    if (location.pathname !== '/cybercheckup') { return <ProfileMenuDropdown type="assessment" />; }
    return isAuthenticated ? null : <ProfileMenuDropdown type="assessment" />;
  };

  // Function to render GRC dropdown
  const renderGrcNavigationDropdown = () => {
    return (
      <GrcNavigationDropdown />
    )
  }

  //  Rendering
  return (
    <>
      <div
        className={`${(!subtabs || subtabs.length === 0) ? 'float-right ' : 'cyvatar-topbar '}d-flex align-items-center normal-text-style pl-3`}
        style={getContainerStyle()}
      >
        <React.Fragment>
          {isAuthenticated ? renderAuthContent() : renderUnAuthContent()}
          {renderSubtabs()}
          {renderGrcNavigationDropdown()}
          <div className="topbar-right-items-container">
            <Actions />
          </div>
          {renderProfileMenuDropdown()}
        </React.Fragment>
      </div>
      <div>
      </div>
    </>
  );
};

export default AuthenticatedHeader;
