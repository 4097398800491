import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withRouter, Link } from 'react-router-dom';
import { validatePassword, validateConfirmPassword } from "../../../utils/validations";
import { renderInput } from "../../../utils/renderFormFields";
import { resetPassword, updatePassword } from '../../../actions/auth';

 const UpdatePasswordForm = (props) => {
    const {
        handleSubmit,
        token,
        resetPassword,
        updatePassword,
        history, 
        invalid
    } = props;

    const [resetType] = useState(token ? true : false);

    // handleValidSubmit
    const onFormSubmit = (formValues) => {
        if(resetType){
            resetPassword({ password: formValues.password }, token, history)
        } else{
            updatePassword({ password: formValues.password }, history)
        }
    }

    return (
        <div>
            <form 
                className="form-container form-horizontal"
                onSubmit={handleSubmit(onFormSubmit)} 
            >
                <div className="update-password-form">
                <div className="title text-light font-weight-bold">
                    { resetType ? 'Reset password' : 'Change password'}
                </div>

                <div className="fields-container">
                    <Field
                        component={renderInput}
                        name="password" 
                        label="New password"
                        className="form-control" 
                        placeholder="Password"
                        type="password"
                    />

                    <Field
                        component={renderInput}
                        name="confirmPassword" 
                        label="Re-enter password"
                        className="form-control" 
                        placeholder="Password"
                        type="password"
                    />
                </div>

                {resetType ? (
                <div className="re-send-link">
                    <Link 
                        className=" text-light underline-link"
                        to="/reset-password"
                    >
                        Re-send the link
                    </Link>
                    </div>
                ):null}
                <div className="btn-update-password">
                    <button
                        className={`btn btn-block btn-outline-light font-weight-bold ${invalid ? 'disabled':""}`}
                        type="submit"
                        disabled={invalid}
                    >
                        Update password
                    </button>
                </div>
                </div>
            </form>
        </div>
    );
}

const validate = (formValues) => {
    const errors = {};

    const { 
        password,
        confirmPassword
    } = formValues;

    errors.password = validatePassword(password);
    errors.confirmPassword = validateConfirmPassword(confirmPassword, password);

    return errors;
}

const mapStatetoProps = state => {
    return {};
}

const mapDispatchToProps = {
    resetPassword,
    updatePassword
};

const wrappedComponent = reduxForm({
    form: 'updatePasswordForm',
    validate
})(UpdatePasswordForm);

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(wrappedComponent));

