import React from 'react';
import PartnerDetails from '../issue-partners/details/PartnerDetails';
import { getFormattedDate } from '../../utils/helpers';

const IssuePartnerDetails = ({ issue, task = false }) => {
  // Function to render partner_unique_id field
  const renderPartnerUniqueId = () => {
    // If issue doesnt have a partner unique id, do not render component.
    if (!issue?.partner_unique_id || issue?.partner_unique_id === '') { return null; }

    return (
      <div className="info-group">
        <div className="label-container">
          <label>Partner ID</label>
        </div>
        {renderPartnerIdField()}
      </div>
    );
  }

  // TODO: update next_patch source
  const renderNextPatch = () => {
    const next_patch = issue?.entity?.device_entity?.next_patch;
    if (!next_patch) return null;

    const currentDate = new Date().getUTCDate();

    return (
      <div className="info-group">
        <div className="label-container d-inline-flex ">
          <label>{`${currentDate > next_patch ? 'Next' : 'Last'} patch`}</label>
        </div>
        <div className="info-container date-text d-inline-flex">
          {getFormattedDate(next_patch)}
        </div>
      </div>
    )
  }

  const renderPartnerIdField = () => {
    // If the issue has a partner URL assigned
    if (issue.partnerURL && issue.partnerURL !== '') {
      // Display hyperlink to partner URL with partner_unique_id
      return (
        <div className="info-container info-text">
          <a className="link-text" href={issue.partnerURL} target="_blank">
            {issue.partner_unique_id}
          </a>
        </div>
      );
    } else {
      // If it doesnt have any partner url, only display partner_unique_id string
      return (
        <div className="info-container info-text text-light-gray">
          {issue.partner_unique_id}
        </div>
      );
    }
  }

  //  Rendering
  return (
    <>
      {renderPartnerUniqueId()}
      {renderNextPatch()}
      {issue && <PartnerDetails issue={issue} task={task}/>}
    </>
  );
};

export default IssuePartnerDetails;
