import moment from 'moment-timezone';
import { timeUnits } from '../constants/tendencyChart';
import { cyvaluePointsConfig } from '../constants/cyValueData';

const defaultXAxisTickCount = 12;

//  Function to know if we should make another request
export const shouldAdjustRanges = (response, rangePoints, retryEnabled) => {
  //  Verifying if we are allowed to retry request
  if (!retryEnabled) { return false; }
  //  Verifying solutions information
  const { solutions } = response;
  if (!solutions || solutions.length === 0) { return false; }
  //  TODO: change when we handle more sulutions
  const solution = solutions[0];
  if (!solution || !solution.data) { return false; }
  const { data } = solution;
  if (!data || data.length === 0) { return false; }
  return (data.length < (rangePoints.length / 2));
};

//  Function to know if we should make another request with an older start date
export const shouldAdjustInitialRange = (elements = [], rangePoints, retryEnabled) => {
  //  Verifying if we are allowed to retry request
  if (!retryEnabled) { return false; }
  //  Verifying elements array
  if (!elements || !Array.isArray(elements) || elements.length === 0) { return false; }

  let maxLength = 0;

  elements.forEach((element) => {
    const elementLength = element?.data?.length;
    if (elementLength > maxLength) {
      maxLength = elementLength
    }
  })

  if (maxLength === 0) { return false; }

  return (maxLength < (rangePoints.length / 2));
};

//  Function to adjust filters
export const adjustFilters = (filters, totalPointsConfig) => {
  const { filter_type } = filters;
  const adjustedRanges = getRangeByFilterType(filter_type, new Date(), true, totalPointsConfig);
  return {
    ...filters,
    ...adjustedRanges,
  };
};

const _findInitialLabel = (points = []) => {
  //  Getting the point with the higher label
  const realPoints = points.filter((point) => !point?.isDummy);
  const sortedPoints = realPoints.sort((a, b) => b.label - a.label);
  const maxPoint = sortedPoints[0];
  if (!maxPoint) { return 0; }

  //  Getting index of the highest label
  const highestIndex = points?.findIndex((point) => point?.label === maxPoint?.label);
  if (highestIndex === -1) { return 0; }

  //  Finding the max possible label to start count from there
  const indexesAfterHighest = (points?.length - 1) - highestIndex;
  const maxPossibleIndex = maxPoint?.label + indexesAfterHighest + 1;
  return maxPossibleIndex;
};

export const adjustLabelsOnPoints = (pointsToAdjust = []) => {
  const pointsCopy = [...pointsToAdjust];
  const reversedPoints = pointsCopy.reverse();

  let lastLabel = _findInitialLabel(pointsToAdjust);
  const adjustedPoints = reversedPoints.map((point) => {
    const { isDummy, label } = point;
    if (isDummy || ((label > lastLabel) && lastLabel !== 0)) { // Edge case with data interruptions
      lastLabel--;
      return { ...point, label: lastLabel }
    }

    lastLabel = label;
    return point;
  });
  return adjustedPoints.reverse();
};

export const getDateFilterStepByType = (type = timeUnits.week) => {
  let step = 'weeks';

  switch (type) {
    case timeUnits.week:
    default:
      step = 'weeks';
      break;
    case timeUnits.month:
      step = "months";
      break;
    case timeUnits.ninety_days:
      step = 'days';
      break;
  }

  return step;
};

export const findPointBetweenInterval = (itemStartDate, intervalStartDate, intervalEndDate) => {
  const dateToUse = moment(itemStartDate)?.utc().tz(moment.tz.guess());
  const isAfterStartDate = dateToUse.isSameOrAfter(new Date(intervalStartDate), 'day')
  const isBeforeEndDate = dateToUse.isBefore(new Date(intervalEndDate), 'day');
  return isAfterStartDate && isBeforeEndDate;
}

export const getDateFilterStepAmountByType = (type = timeUnits.week, value = 0) => {
  let stepValue = value;

  switch (type) {
    case timeUnits.week:
    case timeUnits.month:
    default:
      stepValue = value;
      break;
    case timeUnits.ninety_days:
      stepValue = value * 90;
      break;
  }

  return stepValue;
};

export const moveDateByType = (dateToMove, stepsToMove, type, direction) => {
  const unitToMove = getDateFilterStepAmountByType(type, stepsToMove);
  const units = getDateFilterStepByType(type);
  const action = direction === 'left' ? 'subtract' : 'add';
  const movedDate = moment(dateToMove)[action](unitToMove, units).toDate();
  return movedDate;
};

export const getPointsForCurrentRange = (filter, ignoreExtraPoint = false) => {
  //  Initializing needed variables
  const { start_date, end_date, filter_type } = filter;
  const points = [];
  let initialDate = moment(start_date).toDate();

  //  Generating the default points that the chart should have
  while (!moment(initialDate).isSameOrAfter(end_date, 'day')) {
    const newEndingDate = moveDateByType(initialDate, 1, filter_type, 'right');
    const pointToAdd = {
      start_date: initialDate,
      end_date: newEndingDate,
      isDummy: true,
    };
    points.push(pointToAdd);
    initialDate = newEndingDate;
  }

  if (ignoreExtraPoint) { return points }

  //  Appending the last considered date
  const lastPoint = {
    start_date: initialDate,
    end_date: moveDateByType(initialDate, 1, filter_type, 'right'),
    isDummy: true,
  };
  points.push(lastPoint);
  return points;
};

//  Helper functions
export const getPivotDate = (type, dateToPivot) => {
  if (type === timeUnits.ninety_days) { return dateToPivot; }
  const pivotDate = moment(dateToPivot).startOf(type === timeUnits.week ? 'week' : 'month');
  return pivotDate.toDate();
};

const getStepsBack = (type, isAdjusting = false) => {
  if (isAdjusting) { return 2; }
  return type === timeUnits.week ? 7 : 3;
};

const getStepsAhead = (stepsBack, type, customTotalPointsConfig = {}) => {
  const totalPoints = customTotalPointsConfig[type] || cyvaluePointsConfig[type];
  return totalPoints - stepsBack;
};

export const getRangeByFilterType = (type = timeUnits.week, dateToPivot = new Date(), isAdjusting = false, totalPointsConfig) => {
  //  Getting amount of steps to move back and forward from current date
  const stepsBack = getStepsBack(type, isAdjusting);
  const stepsAhead = getStepsAhead(stepsBack, type, totalPointsConfig);
  //  Getting units to substract and to add from pivot date
  const unitsToSubstract = getDateFilterStepAmountByType(type, stepsBack);
  const unitsToAdd = getDateFilterStepAmountByType(type, stepsAhead);
  //  Getting time units to move
  const units = getDateFilterStepByType(type);
  //  Getting pivot date
  const pivotDate = getPivotDate(type, new Date(dateToPivot));
  //  Getting range and returning
  const start_date = moment(pivotDate).subtract(unitsToSubstract, units).toDate();
  const end_date = moment(pivotDate).add(unitsToAdd, units).toDate();
  return { start_date, end_date };
};

export const getChartXAxisMinValue = (series = []) => {
  if (!series || !Array.isArray(series)) return null;
  const firstValues = [];
  //  Getting all the first values on the array
  series.forEach((serie) => {
    const { data } = serie;
    if (!data) { return; }
    const firstElement = data[0];
    if (!firstElement) { return; }
    firstValues.push(parseInt(firstElement.x) || 0);
  });
  //  Getting the least amount
  if (firstValues.length === 0) { return 0; }
  const minValue = Math.min(...firstValues);

  //  Getting max value
  const maxValue = getChartXAxisMaxValue(series);
  if (maxValue === minValue && minValue > 0) { return -1; }  //  Edge case of single tick

  return minValue;
};
export const getChartXAxisMaxValue = (series = []) => {
  if (!series || !Array.isArray(series)) return null;
  // const solution = solutions[0];
  // if (!solution || !solution.data) { return 14; }
  // const { data } = solution;
  // const minValue = getChartMinValue(solutions);
  // const maxValue = (minValue + (data.length - 1));
  // return maxValue;
  const lastValues = [];
  //  Getting all the last values on the array
  series.forEach((serie) => {
    const { data } = serie;
    if (!data) { return; }
    const lastElement = data[data.length - 1];
    if (!lastElement) { return; }
    lastValues.push(parseInt(lastElement.x) || 0);
  });
  //  Getting the biggest amount
  if (lastValues.length === 0) { return defaultXAxisTickCount; }
  const maxValue = Math.max(...lastValues);
  return maxValue;
};

export const getXAxisAmountOfTicks = (series = []) => {
  if (!series) { return defaultXAxisTickCount; }
  const seriesLengths = [];
  series.forEach((serie) => {
    const { data } = serie;
    seriesLengths.push(data?.length - 1 || 0);
  })
  if (seriesLengths.length < 1) { return defaultXAxisTickCount; }

  const maxAmount = Math.max(...seriesLengths);
  if (maxAmount === 0) { return 1; } // Single tick edge case

  return maxAmount;
};

export const getTimePeriod = (type = timeUnits.week) => {
  let timePeriod = 'week';
  switch (type) {
    case timeUnits.week:
    default:
      timePeriod = 'week';
      break;
    case timeUnits.month:
      timePeriod = 'month';
      break;
    case timeUnits.ninety_days:
      timePeriod = 'quarter';
      break;
  }
  return timePeriod;
};

export const isInsideRange = (date = new Date(), start_date = new Date(), end_date = new Date()) => {
  return (moment(date).isSameOrAfter(start_date, 'day') && moment(date).isBefore(end_date, 'day'));
};

export const getPointBetweenCurrentPeriod = (point) => {
  const { start_date, end_date } = point;
  //  Seeting the interval to know where is the current week
  if (isInsideRange(new Date(), start_date, end_date)) {
    return point;
  }
  return null;
}

//  Function to build new filter
export const buildNewTimeChartFilter = (companyId, newFilter) => {
  return { ...newFilter, company_id: companyId };
}

export const getMinAmountOfPoints = (minPoints, filter_type, start_date, points = []) => {
  //  Getting amount of points to create
  const missingAmountOfPoints = minPoints - points?.length;
  if (missingAmountOfPoints <= 0) { return points; }

  //  Making counts to be a pair number
  const pointsToCreate = (missingAmountOfPoints % 2 !== 0 ? missingAmountOfPoints + 1 : missingAmountOfPoints) / 2;

  //  Getting new ranges
  const newInitialDate = moveDateByType(start_date, pointsToCreate, filter_type, 'left');
  const newEndingDate = moveDateByType(start_date, pointsToCreate + 1, filter_type, 'right');

  //  Building new points for range
  const temporalFilter = { start_date: newInitialDate, end_date: newEndingDate, filter_type };
  return getPointsForCurrentRange(temporalFilter, true);
};
