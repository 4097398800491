import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import CustomizedCheckbox from '../../layout/CustomizedCheckbox';
import { customTableEvents } from '../../../constants/common';
import { isValidFunction } from '../../../utils/helpers';

const SelectableTextCell = (props) => {
  //  Parsing props
  const {
    id,
    value = '',
    isSelectable = false,
    hasTooltip = false,
    allSelected = false,
    selected = [],
    excluded = [],
    handleSelectedEvent,
    customRender,
    customParams,
  } = props;

  //  Function to handle select callbacl
  const handleCheckedChange = (checked) => {
    const type = customTableEvents[checked ? 'SELECT_ELEMENT' : 'UNSELECT_ELEMENT'];
    handleSelectedEvent(type, id);
  }

  //  Function to get checked state
  const getCheckedValue = () => {
    if (allSelected) {
      return !excluded.includes(id);
    }

    return selected.includes(id) && !excluded.includes(id);
  };

  //  Function to render checkbox
  const renderCheckbox = () => {
    if (!isSelectable) { return null; }
    return (
      <CustomizedCheckbox
        checked={getCheckedValue()}
        onChangeCallback={handleCheckedChange}
      />
    )
  };

  //  Function to render tooltip
  const renderTooltip = () => {
    if (!hasTooltip) { return null; }
    return (
      <UncontrolledTooltip
        className={`custom-basic-tooltip`}
        arrowClassName={`custom-basic-tooltip-arrow`}
        placement={'top'}
        target={`text-value-${value}`}
      >
        {value}
      </UncontrolledTooltip>
    );
  }

  //  Rendering
  return (
    <div className="selectable-text-cell">
      {renderTooltip()}
      {renderCheckbox()}
      <div
        id={`text-value-${value}`}
        className={`text-value${hasTooltip ? ' hoverable' : ''}`}
      >
        {(isValidFunction(customRender)) ? customRender(customParams) : value}
      </div>
    </div>
  );
};

export default SelectableTextCell;
