import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { Button } from 'reactstrap';
import { companyPageSize } from '../../constants/company';
import API from '../../api';
import { parseCompaniesToOptions } from '../../utils/helpers';
import { setAlert } from '../../actions/alerts';
import SelectDropdownIndicator from './fields/SelectDropdownIndicator';
import useScrollingQuery from '../../hooks/useScrollingQuery';
import {onFocus} from '../../utils/helpers.js';

//  Initial filters
const initialFilters = {
    limit: companyPageSize,
    searchText: null,
    sort: ['name', 'ASC'],
};

//  Component
const CompanyInfiniteDropdown = (props) => {
    const {
        selectedValue,
        selectedLabel,
        onSelectCallback,
        className = '',
        placeholder = 'Pick a Company',
        showClearButton = true,
    } = props;

    //  Initializing APIs
    const { CompanyAPI } = API;

    //  Memoized dispatch
    const defaultDispatch = useDispatch();
    const dispatch = useCallback(defaultDispatch, []);

    // Component state
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [filters, setFilters] = useState(initialFilters);
    const [requestMore, setRequestMore] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);

    //  Getting company list
    const {
        elements: companies,
        error: impersonationListError,
        loading,
        hasNextPage,
        fetchNextPage,
        clearQueryData,
    } = useScrollingQuery({
        baseKey: CompanyAPI.keys.getImpersonationList,
        key: [CompanyAPI.keys.getImpersonationList, filters],
        queryFunction: CompanyAPI.handlers.getImpersonationList,
        parseElements: parseCompaniesToOptions,
        isDisabled,
        valueToExtract: 'rows',
        initialPageParam: 0,
    });

    // Handle clear clicked
    const clearState = (fromDisableState = false) => {
        onSelectCallback(null)
        setSearchText('');
        clearQueryData();
        if (!fromDisableState) { return; }
        setFilters(initialFilters);
    };

    //  Watching selected company id and company name from redux
    useEffect(() => {
        if (_.isNil(selectedValue)) {
            setSelectedCompany(null);
            return;
        }
        const { value: currentSelectedId } = selectedCompany || { value: null };
        if (currentSelectedId === selectedValue) { return; }
        setSelectedCompany({ value: selectedValue, label: selectedLabel });
    }, [selectedValue]);

    //  Watching company list error
    useEffect(() => {
        if (!impersonationListError) { return; }
        dispatch(setAlert('Error getting members!', 'danger'));
    }, [impersonationListError]);

    //  Watching flag to request more companies
    useEffect(() => {
        if (!requestMore) { return; }
        setRequestMore(false);
        if (!hasNextPage || loading) { return; }
        fetchNextPage();
    }, [requestMore]);

    //  Function to handle change on select
    const handleDropdownChange = (company) => {
        onSelectCallback(company)
    };

    //  Funciton to handle chanes on input (search text case)
    const handleInputChange = (searchTerm) => {
        setSearchText(searchTerm);
        if (loading || (searchTerm.length > 0 && searchTerm.length < 3)) { return; }
        setFilters({ ...filters, searchText: searchTerm });
    };

    //  Function to handle on scroll bottom
    const onScrollBottom = () => {
        setRequestMore(true);
    };

    //  Function to render clear button
    const renderClearButton = () => {
        if (!selectedCompany || !showClearButton) { return null; }
        return (
            <Button
                className="close dropdown-clear"
                onClick={() => { clearState(false); }}
                aria-label="Close dropdown"
                role="button"
            >
                <i className='bx bx-x' />
            </Button>
        );
    };

    // Select Dropdown Indicator
    const DropdownIndicator = (props) => {
        return <SelectDropdownIndicator {...props} />
    }

    //  Rendering
    return (
        <div className="company-dropdown-container">
            <Select
                ariaLiveMessages={{
                    onFocus,
                }}
                aria-live="polite"
                inputValue={searchText}
                value={selectedCompany}
                options={companies}
                onChange={handleDropdownChange}
                onInputChange={handleInputChange}
                onMenuScrollToBottom={onScrollBottom}
                className={`react-select-container company-dropdown ${className}`}
                classNamePrefix="react-select"
                placeholder={isDisabled ? 'Coming soon...' : placeholder}
                components={{ DropdownIndicator }}
                isDisabled={isDisabled}
                isLoading={loading}
                isSearchable={true}
                menuPosition={'absolute'}
                styles={{
                    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                    menu: provided => ({ ...provided, zIndex: 9999 })
                }}
                aria-label={companies?.length === 0 ? `Members combobox. Current combobox has no options` : 'Members, filter and select members'}
            />
            {renderClearButton()}
        </div>
    );
};

export default CompanyInfiniteDropdown;
