import React from 'react';
import { Button } from 'reactstrap';

const PartnerActionsButton = ({ action, onClick }) => {

  return (
    <div className="form-group">
      <div style={{ width: '100%' }}>
        <label>{action.display}</label>
      </div>
      <div
        className="partner-action-btn info-text "
        style={{ width: '100%' }}
      >
        <Button
          className="btn btn-small"
          onClick={() => onClick()}
          color="light-gray"
          outline
        >
          {action.icon &&
            <i className={`bx ${action.icon}`} style={{ marginRight: 10 }} />
          }
          {action.name}
        </Button>
      </div>
    </div>
  );
};

export default PartnerActionsButton;
