import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'reactstrap';
import RiskMatrixElement from './RiskMatrixElement';
import LoadingContent from '../../layout/LoadingContent';
import RiskTreatmentFilter from './RiskTreatmentFilter';
import { UilAngleRight } from '@iconscout/react-unicons'
import makeRiskStatmentsResponseState from '../../../selectors/business-risk/riskStatementsResponseStateSelector';
import makeErrorMessage from '../../../selectors/errorsSelector';
import makeLoadingState from '../../../selectors/loadingSelector';
import { INITIAL_RISK_ID } from '../../../constants/riskTreatment';

const RiskBubbleChart = (props) => {

    const {
        retryRequests,
        controllerRef,
        showTreatmentFilters = true,
    } = props;

    const ticksConfig = [
        {
            position: 0,
            label: 'High',
        },
        {
            position: 1,
            label: 'Medium',
        },
        {
            position: 2,
            label: 'Low',
        },
    ];
    //make Selectors
    const getRiskStatementsResponseState = makeRiskStatmentsResponseState();
    const getRiskStatementsLoadingState = makeLoadingState(['RISK_STATEMENTS']);
    const getRiskStatementsErrorMessage = makeErrorMessage(['RISK_STATEMENTS']);

    //  Getting needed info from redux store
    const riskStatementsLoading = useSelector((state) => getRiskStatementsLoadingState(state));
    const riskStatementsErrorMessage = useSelector((state) => getRiskStatementsErrorMessage(state));
    const responsesState = useSelector((state) => getRiskStatementsResponseState(state));
    const businessRiskConfig = useSelector((state) => state.businessRisk?.config);

    const padMatrix = (length=1) => {
        return Array.from({ length: length }, (_, i) => ([]))
    }

    const getMatrixPosition = (position) => {
        const initialPosition = 6;
        return _.isNil(position) ? initialPosition : position;
    }

    const formatStatement = (statement, is_initial, show_initial = false) => {
        return {
            ...statement,
            is_initial,
            show_initial
        }
    }

    const renderMatrix = () => {
        const matrixLength = 9;
        const riskScoreMatrix = padMatrix(matrixLength);

        if(!responsesState?.statements) return null;
        responsesState.statements.forEach((statement) => {
            if(!_.isNil(statement?.residual_risk_score?.position)) {
                riskScoreMatrix[getMatrixPosition(statement?.residual_risk_score?.position)].push(formatStatement(statement, false));
            }

            if(!_.isNil(statement?.initial_risk_score?.position)) {
                // show Initial Risk bubble if there's still isn't a resulting risk score
                const showInitial = _.isNil(statement?.residual_risk_score?.position);
                riskScoreMatrix[getMatrixPosition(statement?.initial_risk_score?.position)].push(formatStatement(statement, true, showInitial));
            }
        })
        return riskScoreMatrix.map((element, index) => {
            return (
                <RiskMatrixElement
                    key={`risk-matrix-element-${index}`}
                    position={index}
                    element={element}
                    controllerRef={controllerRef}
                />
            )
        })
    }

    const renderEmptyMessage = () => {
        return (
            <div className="fillup-risk-statement text-center">
                <p className="text-title">
                    Define your company risks and how to manage it
                </p>
                <p className="description">
                    Your CySO will help you map the right solutions to mitigate the Risk based on the statements you’ll provide.    
                </p>
            </div>
        )
    }

    const renderRiskLegends = () => {
        if(!businessRiskConfig?.risk_treatment) return null;
        return (
            <>
                {businessRiskConfig.risk_treatment.map((riskTreatment, index) => {
                    return (
                        <RiskTreatmentFilter
                            key={`risk-treatment-filter-${index}`}
                            riskTreatment={riskTreatment}
                        />
                    )
                })}
                <RiskTreatmentFilter
                    riskTreatment={{
                        label: 'Initial Risk',
                        description: 'Based on the severity and likelihood of the Risk Stament',
                        id: INITIAL_RISK_ID
                    }}
                    className="initial-risk-legend"
                />
            </>
        )
    }

    const renderXAxisTicks = () =>{
        return (
            <div className="x-axis-wrapper w-100 d-flex justify-content-between">
                {ticksConfig.map((tick, index) => {
                    return (
                        <div
                            key={`x-axis-tick-${index}`}
                            className={`x-axis-tick d-flex flex-column tick-${tick.position}`}
                        >
                            <div className="x-tick mb-1"/>
                            <div className="axis-tick-label">{tick.label}</div>
                        </div>
                    )
                })}
            </div>
        )
    }

    const renderYAxisTicks = () =>{
        return (
            <div className="y-axis-wrapper h-100 d-flex flex-column-reverse justify-content-between">
                {ticksConfig.map((tick, index) => {
                    return (
                        <div
                            key={`y-axis-tick-${index}`}
                            className={`y-axis-tick d-flex flex-row-reverse align-items-center tick-${tick.position}`}
                        >
                            <div className="y-tick ml-1"/>
                            <div className="axis-tick-label">{tick.label}</div>
                        </div>
                    )
                })}
            </div>
        )
    }

    const renderAxisLabelTag = (label, className) => {
        return (
            <div className={`axis-label-tag ${className || ''}`}>
                {label}
                <i className='bx bx-right-arrow-alt'/>
            </div>
        )
    }

    const renderAxisArrow = (className) => {
        return (
            <div className={`axis-arrow ${className || ''}`}>
                <div className="axis-arrow-body" />
                <div className={`axis-arrow-head`}>
                    <UilAngleRight size={13}/>
                </div>
            </div>
        )
    }

    return (
        <div className="risk-bubble-chart">
            <div className="business-risk-chart-grid-wrapper">
                <div className="business-risk-chart-grid mx-auto">
                    <div className="overlay flex-wrap">
                        <LoadingContent
                            errorMessage={riskStatementsErrorMessage}
                            isLoading={riskStatementsLoading}
                            iconType="solidIcon"
                            errorStyle={{ minHeight: "225px" }}
                            loadingStyle={{ minHeight: "225px" }}
                            errorButtonCallback={retryRequests}
                        >
                            {responsesState?.hasProgress ? renderMatrix(): renderEmptyMessage()}
                        </LoadingContent>
                        {renderXAxisTicks()}
                        {renderYAxisTicks()}
                        {renderAxisLabelTag('Impact', 'severity-tag')}
                        {renderAxisLabelTag('Likelihood', 'likelihood-tag')}
                        {renderAxisArrow('risk-chart-x-axis-arrow')}
                        {renderAxisArrow('risk-chart-y-axis-arrow')}
                    </div>
                </div>
            </div>
            <div className="risk-chart-legends mx-auto d-flex flex-wrap justify-content-center">
                {showTreatmentFilters && renderRiskLegends()}
            </div>
        </div>
    );
}
export default RiskBubbleChart;
