import React from 'react';
import { FormGroup, InputGroup, Input, Label } from 'reactstrap';
import { cyvatarTeamRoles } from '../../constants/cyvatarTeam';
import { getSafeArray, isValidFunction } from '../../utils/helpers';

const TeamRolesButtonGroup = (props) => {
    const {
        selectedRole,
        callback,
        label
    } = props;

    const onRoleClick = (roleValue) => {
        if(isValidFunction(callback)) {
            callback(roleValue)
        }
    }

    const renderRoles = () => {
        return getSafeArray(cyvatarTeamRoles).map((role, index) => {
            const {
                label,
                value
            } = role;
            return (
                <div
                    key={`option-${index}`}
                    className="option-container"
                >

                    <FormGroup
                        check
                        className="custom-radio custom-radio-purple"
                        onClick={() => onRoleClick(value)}

                    >
                        <Input
                            id={`option-${value}`}
                            type="radio"
                            checked={selectedRole == value}
                            readOnly
                        />
                        <Label className="mb-0">
                            {label}
                        </Label>
                    </FormGroup>
                </div>
            )
        })
    }
  return (
    <FormGroup className="team-roles-button-group">
        <Label className="button-group-label">{label}</Label>
        <InputGroup className="buttons-container negative-mx-2">
                {renderRoles()}
        </InputGroup>
    </FormGroup>
  );
};

export default TeamRolesButtonGroup;
