import { obsidianStates } from "./partnerHelpers";

export const fieldTypes = {
  TEXTFIELD: "textfield",
  TEXTBOX: "textbox",
  DROPDOWN: "dropdown",
  CHECKBOX: "checkbox",
  NUMBERFIELD: "numberfield",
  SWITCH: "switch",
  TIME_PICKER: "time_picker",
  TABLE: "table",
  MULTI_SELECT_LIST: "multi_select_list",
  RADIO: "radio_button"
};

export const modalSizes = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
  FULLSCREEN: "fullscreen",
}

export const listOptionsFormats = {
  DROPDOWN: "dropdown",
  TABLE: "table",
}

export const checkRequiredKeys = (object, inputValues) => {
  const { required_keys, type } = object;
  // Check required keys
  if (required_keys) {
    if (!inputValues) {
      return false;
    }
    for (let key in required_keys) {
      const valuesArray = required_keys[key];
      if (inputValues.hasOwnProperty(key)) {
        let inputValue = inputValues[key];
        if (inputValue instanceof Object) { // For dropdowns
          inputValue = inputValue.value;
        }
        if (!valuesArray.includes(inputValue)) {
          return false;
        }
      } else {
        return false;
      }
    }
  }
  return true;
};

export const generateCleanupMap = (action) => {
  if (!action) return null;

  let cleanupMap = new Map();

  const { screens } = action;
  if (!screens) return null;

  if (Array.isArray(screens)) {
    screens.forEach((screen) => {
      const { components } = screen;
      if (!components) { return }

      if (Array.isArray(components)) {
        components.forEach((component) => {
          const { fields } = component;
          if (!fields) return;

          if (Array.isArray(fields)) {
            fields.forEach((field) => {
              const { key: fieldKey, required_keys } = field;
              if (!required_keys) return;

              for (let key in required_keys) {
                if (cleanupMap.has(key)) {
                  const keyArray = cleanupMap.get(key);
                  if (!keyArray.includes(fieldKey)) {
                    keyArray.push(fieldKey);
                  }
                } else {
                  let keyArray = [fieldKey];
                  cleanupMap.set(key, keyArray);
                }
              }
            });
          }
        });
      }
    });
  }
  return cleanupMap;
}

export const cleanupFieldsFromValuesForKey = (key, actionValues, cleanupMap) => {
  if (cleanupMap.has(key)) {
    const valuesArray = cleanupMap.get(key);
    valuesArray.forEach((element) => {
      if (actionValues[element]) {
        delete actionValues[element];
        cleanupFieldsFromValuesForKey(element, actionValues, cleanupMap);
      }
    });
  }
}

export const getObsidianState = (state) => {
  switch (state) {
    case 0:
    case 1:
    case 7:
      return obsidianStates.OPEN;
    case 2:
    case 3:
    case 5:
    case 10:
      return obsidianStates.IN_PROGRESS;
    case 4:
      return obsidianStates.CLOSED;
    default:
      return obsidianStates.OPEN
  }
}

export const getObsidianOutcome = {
  MALICIOUS: 'Malicious',
  BENIGN: 'Benign',
  INCONCLUSIVE: 'Inconslusive',
}
