import {
	CHANGE_LAYOUT,
	CHANGE_SIDEBAR_TYPE,
	CHANGE_TOPBAR_THEME,
	SHOW_RIGHT_SIDEBAR,
	SHOW_SIDEBAR,
	CHANGE_PRELOADER,
	TOGGLE_LEFTMENU,
	TOGGLE_REGISTER_MODAL,
	CHANGE_REGISTER_MODAL,
	TOGGLE_ASSSESSMENT_COMMMENTS_MODAL,
	SHOW_TAKE_ASSESSMENT,
	SHOW_COMMENT_BUTTON,
	SHOW_NEXT_BUTTON,
	TOGGLE_UPDATE_COMPANY_MODAL,
	ON_SHOW_ISSUE_EXPANDED_VIEW,
	SET_CONFIRMATION_MODAL,
} from './types';
import { changeBodyAttribute, manageBodyClass } from '../utils/helpers';

export const changeLayout = (layout) => {
	return async (dispatch) => {
		try {
			dispatch({ type: CHANGE_LAYOUT, payload: layout });
			dispatch(changeTopbarTheme('light'));
			if (layout === 'horizontal') {
				document.body.removeAttribute('data-sidebar');
				document.body.removeAttribute('data-sidebar-size');
				document.body.removeAttribute('vertical-collpsed');
			}
			changeBodyAttribute('data-layout', layout);
		} catch (error) {
			console.log(error);
		}
	};
}

export const changePreloader = (show, text = "", autoHide = true) => ({
	type: CHANGE_PRELOADER,
	payload: {
		show,
		text,
		autoHide
	}
});
// { sidebarType, isMobile }
export const changeSidebarType = (sidebarType, isMobile) => {
	return async (dispatch) => {
		try {
			dispatch({ type: CHANGE_SIDEBAR_TYPE, payload: { sidebarType, isMobile } });
			switch (sidebarType) {
				case 'compact':
					changeBodyAttribute("data-sidebar-size", "small");
					manageBodyClass("sidebar-enable", "remove");
					manageBodyClass("vertical-collpsed", "remove");
					break
				case 'icon':
					changeBodyAttribute("data-keep-enlarged", "true");
					manageBodyClass("vertical-collpsed", "add");
					break;
				case 'condensed':
					manageBodyClass("sidebar-enable", "add");
					if (!isMobile) { manageBodyClass("vertical-collpsed", "add"); }
					break;
				default:
					changeBodyAttribute("data-sidebar-size", "");
					manageBodyClass("sidebar-enable", "remove");
					if (!isMobile) { manageBodyClass("vertical-collpsed", "remove"); }
					break;
			}
		} catch (error) {
			console.log(error);
		}
	};
};

export const changeTopbarTheme = (topbarTheme) => {
	return async (dispatch) => {
		try {
			dispatch({ type: CHANGE_TOPBAR_THEME, payload: topbarTheme });
			changeBodyAttribute('data-topbar', topbarTheme);
		} catch (error) {
			console.log(error);
		}
	}
}


export const showRightSidebarAction = (isopen) => () => {
	return async (dispatch) => {
		try {
			dispatch({ type: SHOW_RIGHT_SIDEBAR, payload: isopen });
			manageBodyClass("right-bar-enabled", "add");
		} catch (error) {
			console.log(error);
		}
	};
};

export const showSidebar = (isopen) => ({
	type: SHOW_SIDEBAR,
	payload: isopen
});

export const toggleLeftmenu = (isopen) => ({
	type: TOGGLE_LEFTMENU,
	payload: isopen
});

export const toggleRegisterModal = (show) => ({
	type: TOGGLE_REGISTER_MODAL,
	payload: show
});

export const changeRegisterModal = (registerText) => ({
	type: CHANGE_REGISTER_MODAL,
	payload: registerText
});


export const toggleAssessmentCommentModal = (show) => ({
	type: TOGGLE_ASSSESSMENT_COMMMENTS_MODAL,
	payload: show
});

export const showTakeAssessmentAction = (show) => ({
	type: SHOW_TAKE_ASSESSMENT,
	payload: show
});

export const showCommentButtonAction = (show) => ({
	type: SHOW_COMMENT_BUTTON,
	payload: show
});

export const showNextButtonAction = (show) => ({
	type: SHOW_NEXT_BUTTON,
	payload: show
});

export const toggleUpdateCompanyModal = (show = null) => ({
	type: TOGGLE_UPDATE_COMPANY_MODAL,
	payload: show
});

export const onShowIssueExpandedView = (showing = false) => {
	return {
		type: ON_SHOW_ISSUE_EXPANDED_VIEW,
		payload: showing,
	};
};

export const setConfirmationModal = (payload = { show: false, message: null }) => {
	return {
		type: SET_CONFIRMATION_MODAL,
		payload
	}
}
