import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Button, Input, Row } from "reactstrap";
import { trackEvent } from "../../utils/gaTracker";
import { cancelAccountRequest } from "../../actions/auth";
import BasicModal from "../layout/BasicModal";
import amplitude from 'amplitude-js';

const CancelAccount = (props) => {

  const {
    cancelAccountRequest,
    company: { teamMembers },
    user: { active },
    history,
    location
  } = props;

  const [showCancelModal, setCancelModal] = useState(false);
  const [showConfirmation, setConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');

  const handleCancelClick = () => {
    trackEvent('cancel-account-click', 'account', 'cancel-account-click');
    setCancelModal(true);
  }

  const toggleModal = () => {
    trackEvent('cancel-account-modal', 'account', `cancel-account-${!showCancelModal ? 'close':'open'}`);
    setCancelModal(!showCancelModal);
    setConfirmation(false);
    setConfirmationMessage('');
  }

  const handleConfirmCancelClick = () => {
    setConfirmation(true);
    trackEvent('cancel-account-confirmation', 'account', `cancel-account-confirmation`);
    amplitude.getInstance().logEvent('My Account View - Notification Settings - Cancel Account');
    cancelAccountRequest(history, location?.pathname);
  }

  const handleMessageChange = (e) => {
    let message = e?.target?.value || '';
    setConfirmationMessage(message.toUpperCase());
  }

  const lastAccountMessage = <div> Since you are the only user in your company, request Membership cancellation.</div>;
  const confirmationText = 'DELETE ACCOUNT';

  const renderCancelButton = () => {
    return (
      <Button className="btn-cancel" onClick={handleCancelClick}>
        <i className='bx bxs-trash' style={{ fontSize: "18px" }} />
        <span style={{ marginLeft: "4px" }}>Cancel my account</span>
      </Button>
    )
  }

  const renderCancelCTA = () => {
    return (
      <div className="d-flex justify-content-center">
        {(showConfirmation || teamMembers.length === 1) ? (<Button className='action-btn' color='light' onClick={toggleModal} > Ok </Button>) : (
          <React.Fragment>
            <Button color="danger" className="mr-2" onClick={handleConfirmCancelClick} disabled={confirmationMessage !== confirmationText} >Yes, cancel it</Button>
            <Button color="light-gray" outline onClick={toggleModal}> No, I’m staying </Button>
          </React.Fragment>
        )}
      </div>
    );
  }

  const renderConfirmMessage = () => {
    return (
      <div className='d-flex flex-column align-items-center confirm-message'>
        <div className='label'>Type DELETE ACCOUNT to confirm</div>
        <Input
          type="text"
          className="form-control input"
          placeholder={confirmationText}
          value={confirmationMessage}
          onChange={handleMessageChange}
        />
      </div>
    )
  }

  const renderModal = () => {
    const title = teamMembers.length === 1 ? 'You are the last one' : (showConfirmation ? 'Farewell friend' : 'Are you sure?');

    return(
      <BasicModal
        title={title}
        showModal={showCancelModal}
        toggleModal={() => toggleModal()}
        customClassName='cancel-membership'
      >
        <div className='message d-flex justify-content-center text-center'>
          { teamMembers.length === 1 ? lastAccountMessage :
            ( showConfirmation ? <div>We are sorry to see you go. To re-enable your account please contact your company account administrator.</div> : <div> We’ll miss you. <br/> Are you sure you want to cancel your account?</div> )
          }
        </div>
        {(!showConfirmation && teamMembers.length !== 1) && renderConfirmMessage()}
        {renderCancelCTA()}
      </BasicModal>
    );
  }

  return (
    <React.Fragment>
      {renderCancelButton()}
      {renderModal()}
    </React.Fragment>
  );
}

const mapStatetoProps = state => {
  const { error, isAuthenticated, company, user } = state.auth;
  return { error, isAuthenticated, company, user };
}

export default withRouter(connect(mapStatetoProps, { cancelAccountRequest })(CancelAccount));

