import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { changeLayout } from '../../actions/layout';
import { CREATE_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';
import useCanAbility from '../../hooks/useCanAbility';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import companyIdSelector from '../../selectors/companyIdSelector';
import ImpersonationEmptyView from './ImpersonationEmptyView';

const ViewWrapper = (props) => {
	const {
		children,
		layoutType,
		hasImpersonationMode,
		viewName
	} = props;

	// Getting needed info from redux store
	const companyId = useSelector((state) => companyIdSelector(state));

	// Component hooks
    const { dispatch } = useMemoizedDispatch();
	const ability = useCanAbility();

    //	Watching state changes
	useEffect(() => {
		dispatch(changeLayout(layoutType));
	}, [changeLayout]);

	// Component variable
	const canImpersonate = ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.MULTIPLE.IMPERSONATE);

	return hasImpersonationMode && (canImpersonate && !companyId) ? (
		<ImpersonationEmptyView viewName={viewName} />
	): (
		<>
			{children}
		</>
	)
}

export default ViewWrapper;
