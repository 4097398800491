import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  MFA_CODE_SUCCESS,
  MFA_VERIFICATION_SUCCESS,
  MFA_DISABLE_SUCCESS,
  USER_AUTH_SUCCESS,
  AUTH_ERROR,
  REGISTER_SUCCESS,
  REGISTER_FREEMIUM_SUCCESS,
  CLEAR_RESET_PASSWORD,
  SEND_RESET_PASSWORD_TOKEN_SUCCESS,
  VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS,
  VALIDATE_RESET_PASSWORD_TOKEN_FAILURE,
  CONFIRM_PASSWORD_SUCCESS,
  CLEAN_AUTH_ERROR_MESSAGE,
  CREATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_ROI_COMPANY_SUCCESS,
  CANCEL_ACCOUNT_SUCCESS,
  UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
  SHOW_FREEMIUM_ALERT,
  REGISTER_FAILURE,
  REGISTER_FREEMIUM_FAILURE,
  UPDATE_COMPANY_FAILURE,
  VALIDATE_RESET_PASSWORD_TOKEN_REQUEST,
} from './types';
import { trackEvent, updateUserID } from '../utils/gaTracker';
import { saveAnswers } from '../actions/answers';
import {
  authAndLoginUser,
  handleRegistersErrors,
  getCompletedAnswers,
  buildUsertoRegister,
  handleUserLogout,
} from '../utils/authHelper';
import { initializeMFACookie, clearUserCookies } from '../utils/manageCookies';
import { setAlert, removeAllAlerts } from './alerts';
import { errorActionCreator } from './action-creators/errors';
import { toggleRegisterModal, changePreloader } from './layout';
import { defaultIndexPath } from '../constants/navigation';
import { invalidateQueriesByKeys } from '../utils/queryHelpers';
import { successActionCreator } from './action-creators/success';
import setAuthToken from '../utils/axios/setAuthToken';
import amplitude from 'amplitude-js';
import { UserAPI } from '../api/services/UserAPI';


export const login = (formValues, history, location) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      dispatch(removeAllAlerts());
      //  Calling API to login
      const { AuthAPI } = API;
      const response = await AuthAPI.handlers.login(formValues);
      invalidateQueriesByKeys(AuthAPI.invalidators.login, queryClient);
      trackEvent("login", "user", "login");
      //  Login and authenticating user
      if (response?.data?.user) {
        authAndLoginUser(response?.data, dispatch, history, true);
        amplitude.getInstance().setUserId(response?.data?.user?.public_id);
      }
      //  Updating MFA information
      if (response?.data?.mfa) {
        dispatch(mfaSuccess(response?.data?.mfa));
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message || 'Oops! Something went wrong. Try again!';
      dispatch(authError(errorMessage));
    }
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user
  }
}

export const validateMFALogin = (formValues, history, location) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      const { AuthAPI } = API;
      const response = await AuthAPI.handlers.validateMFACode(formValues);
      invalidateQueriesByKeys(AuthAPI.invalidators.validateMFACode, queryClient);
      trackEvent("login", "user-mfa", "validate-mfa");
      if (response?.data?.user && formValues.remind_device) {
        trackEvent("login", "remind-device", "validate-mfa");
        initializeMFACookie(formValues?.email);
      }
      if (response?.data?.user) {
        authAndLoginUser(response?.data, dispatch, history, true);
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message || 'Oops! Something went wrong. Try again!';
      dispatch(setAlert(errorMessage, 'danger'));
    }
  };
}

export const requestMFACode = (formValues, history, location) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      const { AuthAPI } = API;
      const response = await AuthAPI.handlers.requestMFACode(formValues);
      invalidateQueriesByKeys(AuthAPI.invalidators.requestMFACode, queryClient);
      trackEvent("security", "request-token", "request-token");
      dispatch(mfaSuccess(response?.data?.mfa));
      if (response?.data?.mfa?.status === "pending") {
        dispatch(setAlert("Code sent", 'success', true, 7000));
      } else {
        dispatch(setAlert("Oops! We didn’t catch that. Try again", 'danger'));
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message || 'Oops! Something went wrong. Try again!';
      dispatch(setAlert(errorMessage, 'danger'));
    }
  };
}

export const validateMFACode = (formValues, history, location) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      const { AuthAPI } = API;
      const response = await AuthAPI.handlers.validateMFACode(formValues);
      invalidateQueriesByKeys(AuthAPI.invalidators.validateMFACode, queryClient);
      trackEvent("security", "user-mfa", "validate-mfa");
      dispatch(mfaVerificationSuccess(response?.data));
      if (formValues?.isFirstTime) { history.push('/settings'); }
      dispatch(setAlert("Verification success", 'success', true, 7000));
    } catch (error) {
      const errorMessage = error?.response?.data?.message || 'Oops! Something went wrong. Try again!';
      dispatch(setAlert(errorMessage, 'danger'));
    }
  };
}

/**
 * It makes a request to the API to disable MFA, and if successful, it dispatches an action to update
 * the state with the new MFA status
 * @param history - The history object from react-router-dom.
 * @param location - The location object from the router.
 * @returns An object with a type of DISABLE_MFA_SUCCESS and a payload of the response data.
 */
export const disableMFARequest = (history, location) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      const { AuthAPI } = API;
      const response = await AuthAPI.handlers.disableMFARequest();
      trackEvent("security", "disable-mfa", "disable-mfa");
      dispatch(disableMFASuccess(response?.data?.mfa));
      dispatch(setAlert("MFA disabled", 'success', true, 7000));
    } catch (error) {
      const errorMessage = error?.response?.data?.message || 'Oops! Something went wrong. Try again!';
      dispatch(setAlert(errorMessage, 'danger'));
    }
  };
}

export const disableMFASuccess = (data) => {
  return {
    type: MFA_DISABLE_SUCCESS,
    payload: data
  }
}

export const mfaSuccess = (mfaConfig) => {
  return {
    type: MFA_CODE_SUCCESS,
    payload: mfaConfig
  }
}

export const mfaVerificationSuccess = (data) => {
  return {
    type: MFA_VERIFICATION_SUCCESS,
    payload: data
  }
}


export const register = (formValues, history, location, currentQuestion) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      dispatch(removeAllAlerts());
      const { AuthAPI } = API;
      //  Initializing needed flags
      const isMemberRegister = !!formValues?.user?.token_invite
      const completed = localStorage.getItem("assessmentExecutionStatus") === '1';
      //  Registering user
      const user = buildUsertoRegister(formValues, completed, false);
      const response = await AuthAPI.handlers.register(user);
      invalidateQueriesByKeys(AuthAPI.invalidators.register, queryClient);

      //  Tracking analytics
      if (!isMemberRegister) {
        trackEvent("save_progress", "assessment_abandonment", "register_save", currentQuestion);
        trackEvent("register", "qualified_leads", "register");
      }

      //  Handling register success for members
      if (isMemberRegister) {
        updateUserID(response?.data?.user?.public_id || undefined);
        clearUserCookies();
        dispatch(registerSuccess(response?.data?.user));
        dispatch(authUser(history, '/'));
      } else {
        //  Setting auth token just to update assessment (token removed on assessment completed)
        localStorage.setItem('token', response?.data?.user?.token);
        setAuthToken(response?.data?.user?.token);

        //  Saving assessment answers
        const answers = getCompletedAnswers(formValues.assessment_id, completed);
        dispatch(toggleRegisterModal(false));
        if (response.data.isOwner) {
          dispatch(saveAnswers(answers, history, true));
        } else {
          //  Unauthorizing user, auth just used to save assessment
          localStorage.removeItem('token');
          setAuthToken(null);
        }

        //  Redirecting to regiser success
        history.push({
          pathname: '/register-success',
          state: {
            public_id: response?.data?.user?.public_id,
            email: response?.data?.user?.email,
            is_owner: response?.data?.isOwner,
          },
        });
      }
    } catch (error) {
      //  Extracing error message
      const message = error.response.data.errorMessage;
      dispatch(errorActionCreator(REGISTER_FAILURE, { message }));
      //  Handling registers errors
      handleRegistersErrors(error, null, dispatch);
    }
  };
}

export const freemiumRegister = (payload) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      const { AuthAPI } = API;
      const user = buildUsertoRegister(payload, false, true);
      const response = await AuthAPI.handlers.register(user);
      invalidateQueriesByKeys(AuthAPI.invalidators.register, queryClient);
      dispatch(changePreloader(true, "Creating your account", false));
      trackEvent("register", "qualified_leads", "freemium_register");
      updateUserID(response?.data?.public_id || undefined);
      clearUserCookies();
      dispatch(freemiumRegisterSuccess(response?.data?.user));
      dispatch(authUser());
      dispatch(changePreloader(false));
    } catch (error) {
      const message = error.response.data.errorMessage
      dispatch(errorActionCreator(REGISTER_FREEMIUM_FAILURE, { message }))
      //  Handling registers errors
      handleRegistersErrors(error, null, dispatch);
    }
  };
}

export const createCompanySuccess = (data) => {
  return {
    type: CREATE_COMPANY_SUCCESS,
    payload: data
  }
}

export const updateCompanyRequest = (payload, callback) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      const { CompanyAPI } = API;
      const { formValues, companyId } = payload;
      const response = await CompanyAPI.handlers.updateCompany(formValues, companyId);
      invalidateQueriesByKeys(CompanyAPI.invalidators.updateCompany, queryClient);
      dispatch(updateCompanySuccess(response?.data));
      dispatch(setAlert("Profile updated", 'success', true, 3000));
      if (callback && typeof (callback) === 'function') { callback(); }
    } catch (error) {
      const message = error?.response?.data?.message || 'Oops! Something went wrong. Try again please';
      dispatch(errorActionCreator(UPDATE_COMPANY_FAILURE, { message }));
      dispatch(setAlert(message, 'danger'));
    }
  };
}

export const updateCompanySuccess = (data) => {
  return {
    type: UPDATE_COMPANY_SUCCESS,
    payload: data
  }
}
export const updateROICompanySuccess = (data) => {
  return {
    type: UPDATE_ROI_COMPANY_SUCCESS,
    payload: data
  }
}

export const registerSuccess = (data) => {
  return {
    type: REGISTER_SUCCESS,
    payload: data
  }
}

export const freemiumRegisterSuccess = (data) => {
  return {
    type: REGISTER_FREEMIUM_SUCCESS,
    payload: data
  }
}

export const logout = (history, pathname) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      const { AuthAPI } = API;
      const response = await AuthAPI.handlers.logout();
      invalidateQueriesByKeys(AuthAPI.invalidators.logout, queryClient);
      const { data = {} } = response;
      const { success } = data;
      if (!success) { throw new Error('Error on log out'); }
      trackEvent("logout", "user", "logout");
      amplitude.getInstance().setUserId(null);
      handleUserLogout(pathname, history, dispatch);
    } catch (error) {
      console.log('Error: ', error);
    }
  }
}

export const logoutSuccess = () => {
  return {
    type: LOGOUT_SUCCESS,
    payload: {}
  }
}

export const authError = (error) => {
  return {
    type: AUTH_ERROR,
    payload: error
  }
}

export const authUser = (history, pathname) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      const { AuthAPI } = API;
      if (localStorage.getItem("token")) {
        const response = await AuthAPI.handlers.authUser();
        const roleName = response?.data?.roles[0]?.name;
        const isCyvatarMember = roleName.indexOf('squad_') !== -1;
        const forceMFA = (response.data.packages.length > 0 && !response.data.mfa_type && response.data?.company?.freemium === 0);
        updateUserID(response?.data?.public_id || undefined);
        dispatch(authenticateUserSuccess(response.data));
        if (!isCyvatarMember && response.data?.company?.freemium !== 0) { dispatch(showFreemiumAlertAction(true)); }
        if (isCyvatarMember) { pathname = defaultIndexPath; }
        if (!isCyvatarMember && forceMFA) { pathname = '/mfa-config'; }
        if (history && pathname) { history.push(pathname); }
      } else {
        dispatch(authenticateUserSuccess({}));
      }
    } catch (error) {
      dispatch(authError(''));
      console.log(error);
    }
  }
}

export const authenticateUserSuccess = (success) => {
  return {
    type: USER_AUTH_SUCCESS,
    payload: success
  }
}

export const sendResetPasswordToken = (email) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      const { AuthAPI } = API;
      const response = await AuthAPI.handlers.sendResetPasswordToken(email);
      invalidateQueriesByKeys(AuthAPI.invalidators.sendResetPasswordToken, queryClient);
      dispatch(sendResetPasswordTokenSuccess(response.data));
    } catch (error) {
      const message = error?.response?.data?.message || "Oops! Something went wrong. Try again please"
      dispatch(setAlert(message, 'danger'));
      dispatch(sendResetPasswordTokenSuccess(false));
    }
  };
}

export const sendResetPasswordTokenSuccess = (data) => {
  return {
    type: SEND_RESET_PASSWORD_TOKEN_SUCCESS,
    payload: data
  }
}

export const validateResetPasswordToken = (token) => {
  return async (dispatch, getState, { API }) => {
    try {
      const { AuthAPI } = API;
      // FIXME: find a better way to handle loading state
      dispatch(successActionCreator(VALIDATE_RESET_PASSWORD_TOKEN_REQUEST));
      const response = await AuthAPI.handlers.validateResetPasswordToken(token);
      dispatch(validateResetPasswordTokenSuccess(response?.data))
    } catch (error) {
      const status = error?.response?.status;
      if (status === 402) {
        dispatch(validateResetPasswordTokenSuccess({ success: false }));
      } else {
        dispatch(validateResetPasswordError(error));
      }
    }
  }
}

export const validateResetPasswordTokenSuccess = (data) => {
  return {
    type: VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS,
    payload: data
  }
}

export const validateResetPasswordError = (message) => {
  return {
    type: VALIDATE_RESET_PASSWORD_TOKEN_FAILURE,
    payload: message
  }
}


export const resetPassword = (formValues, token, history) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      const { AuthAPI } = API;
      const response = await AuthAPI.handlers.resetPassword(formValues, token);
      invalidateQueriesByKeys(AuthAPI.invalidators.resetPassword, queryClient);
      dispatch(loginSuccess({ user: response.data }));
      dispatch(authUser(history, "/"));
      setTimeout(() => {
        dispatch(setAlert("Password updated", 'success', true, 7000));
      }, 3000);
    } catch (error) {
      const message = error?.response?.data?.message || "Oops! Something went wrong. Try again please";
      dispatch(setAlert(message, 'danger'));
    }
  };
};

export const clearResetPassword = () => {
  return {
    type: CLEAR_RESET_PASSWORD
  }
}

export const updatePassword = (formValues, history) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      const { AuthAPI } = API;
      const response = await AuthAPI.handlers.updatePassword(formValues);
      invalidateQueriesByKeys(AuthAPI.invalidators.updatePassword, queryClient);
      dispatch(loginSuccess({ user: response.data }));
      dispatch(authUser(history, "/"));
      setTimeout(() => {
        dispatch(setAlert("Password updated", 'success', true, 7000));
      }, 3000);
    } catch (error) {
      const message = error?.response?.data?.message || "Oops! Something went wrong. Try again please";
      dispatch(setAlert(message, 'danger'));
    }
  }
}

export const confirmPassword = (formValues) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      const { AuthAPI } = API;
      const response = await AuthAPI.handlers.validatePassword(formValues);
      invalidateQueriesByKeys(AuthAPI.invalidators.validatePassword, queryClient);
      dispatch(loginSuccess(response.data));
      dispatch(confirmPasswordSuccess({ success: true }));
    } catch (error) {
      const message = error?.response?.data?.message || "Oops! Something went wrong. Try again please";
      dispatch(setAlert(message, 'danger'));
    }
  };
};

export const confirmPasswordSuccess = (user) => {
  return {
    type: CONFIRM_PASSWORD_SUCCESS,
    payload: user
  }
}


export const cleanAuthErrorMessage = () => {
  return {
    type: CLEAN_AUTH_ERROR_MESSAGE
  }
}

export const cancelMembershipRequest = (history, pathname, body) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      const { CompanyAPI } = API;
      const response = await CompanyAPI.handlers.postCancelMembership(body);
      invalidateQueriesByKeys(CompanyAPI.invalidators.postCancelMembership, queryClient);
      if (response?.isFreemium && !body) {
        trackEvent('cancel-membership', 'membership', 'canceled-membership-success');
        handleUserLogout(pathname, history, dispatch);
      }
    } catch (error) {
      const message = error?.response?.data?.message || "Oops! Something went wrong. Try again please";
      dispatch(setAlert(message, 'danger'));
    }
  };
}

export const cancelAccountRequest = (history, pathname) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      const { UserAPI } = API;
      const response = await UserAPI.handlers.postCancelUserAccount();
      invalidateQueriesByKeys(UserAPI.invalidators.postCancelUserAccount, queryClient);
      trackEvent('cancel-account', 'account', `canceled-user-account-success`);
      dispatch(cancelAccountSuccess({}));
      if (response) {
        handleUserLogout(pathname, history, dispatch);
      }
    } catch (error) {
      const message = error?.response?.data?.message || "Oops! Something went wrong. Try again please";
      dispatch(setAlert(message, 'danger'));
    }
  };
};

export const cancelAccountSuccess = (data) => {
  return {
    type: CANCEL_ACCOUNT_SUCCESS,
    payload: data
  }
}

export const updateNotificationSettingsRequest = (formValues) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      const { SettingsAPI } = API;
      const response = await SettingsAPI.handlers.postUpdateNotificationsSettings(formValues);
      invalidateQueriesByKeys(SettingsAPI.invalidators.postUpdateNotificationsSettings, queryClient);
      dispatch(updateNotificationSettingsSuccess(response?.data));
    } catch (error) {
      const message = error?.response?.data?.message || "Oops! Something went wrong. Try again please";
      dispatch(setAlert(message, 'danger'));
    }
  };
}

export const updateNotificationSettingsSuccess = (data) => {
  return {
    type: UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
    payload: data
  }
}

export const showFreemiumAlertAction = (show) => {
  return {
    type: SHOW_FREEMIUM_ALERT,
    payload: show
  }
}

export const triggerRegisterSuccess = (payload) => {
  return async (dispatch, getState,{ API }) => {
    const { history, user } = payload;
    updateUserID(user.public_id || undefined);
    clearUserCookies();
    dispatch(registerSuccess(user));
    dispatch(authUser(history, '/'));
  };
}
