const errorsReducer = (state = {}, action) => {
    const { type, payload } = action;
    const matches = /(.*)_(REQUEST|FAILURE|SUCCESS)/.exec(type);
    if (!matches) return state;

    const [, requestName, requestState] = matches;
    return {
      ...state,
      [requestName]: requestState === 'FAILURE' ? payload?.message : '',
    };
};

export default errorsReducer;
