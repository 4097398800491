import React from 'react';
import { getListText, getSafeArray } from '../../utils/helpers';
import { getActors } from '../../utils/partnerHelpers';
import CriticalityBadge from '../dashboard/CriticalityBadge';
import AddressesDetailModal from './AddressesDetailModal';
import { issueTypes } from '../../constants/issues';

const IssueDeviceInfo = ({ issue, devicePath }) => {
  const {
    entity: issueDevice,
    issue_type,
    name: issueName,
    criticality,
    extra_fields
  } = issue;

  // Render data item
  const renderItem = (label, value) => {
    return (
      <div className='mb-2'>
        <div className="issue-information-label">{label}</div>
        <div className='issue-information-value'>{value || '_'}</div>
      </div>
    )
  }

  // render device path
  const renderDevicePath = () => {
    return renderItem("Devices path", devicePath)
  }

  const renderAddressesModal = (addresses, title) => {
    return (
      <AddressesDetailModal
        title={title}
        addresses={addresses}
      />
    )
  }

  // Render ip addresses
  const renderAddresses = (title, object_name) => {
    // TOOD render ip
    const partnerInfo = issueDevice?.partners_by_entities?.length > 0 ? issueDevice?.partners_by_entities[0] : {};
    const addresses = getSafeArray(partnerInfo[object_name]);

    return renderItem(title, addresses?.length > 3 ? (
      renderAddressesModal(addresses, title)
    ) : (
      getListText(addresses)
    ))
  }

  const renderCriticalityBadge = () => {
    return (
      <>
        <CriticalityBadge severity={criticality} className="mr-3" />
        (Manual)
      </>
    )
  }

  if (issue_type === issueTypes.ALERT) {
    return (
      <>
        <div className="issue-information-title">
          Alert Information
        </div>
        {renderItem("Name", issueName)}
        {renderItem("Severity", renderCriticalityBadge())}
        {renderItem("Services", issueDevice?.name)}
        {renderItem("Actor", getActors(extra_fields.actors))}
      </>
    );
  }

  //  Rendering
  return (
    <>
      <div className="issue-information-title">
        Device Information
      </div>
      {renderItem("Devices amount", 1)}
      {renderDevicePath()}
      {renderAddresses("Ip Address", 'ip_address')}
      {renderItem("Host name", issueDevice?.name)}
      {renderAddresses("Mac Address", 'mac_address')}
    </>
  );
};

export default IssueDeviceInfo;
