import _ from 'lodash';
import cyvatarAxios from '../axios';
import { cyvalueEnabledSolutions } from '../../constants/cyValueData';
import {
  GET_COMPANY,
  LIST_COMPANY,
  GET_CONFIRM_COMPANY,
  FETCH_RESULTS,
  GET_CYVALUE_TABS,
  GET_IMPERSONATION_LIST,
  GET_ISSUE,
  GET_ISSUES_LIST,
  GET_ROLLUP_DATA,
  GET_ISSUE_MODAL_OPTIONS,
  GET_ACTIVITY_REPORT,
  GET_OPPORTUNITIES_TO_IMPROVE,
  FETCH_COMPLIANCE_SCORE,
  GET_COMPLIANCE_STATUS,
  GET_COMPLIANCE,
  GET_CYVALUE_DATA,
  GET_CYVALUE_BY_WEEK,
  GET_GRC_ISSUE,
  GET_GRC_ISSUES_LIST,
  LIST_COMPANY_NOTES,
  GET_COMPANY_ATTACHMENTS,
  GET_DOMAINS_LIST,
  GET_SCAN_TIMEZONES,
  GET_COMPANY_USERS,
  GET_COMPANY_LOGO,
} from '../ApiKeys';

const createCompany = (formValues) => {
  const body = JSON.stringify({
    name: formValues.companyName,
    number_employees: parseInt(formValues.numberEmployees),
    annual_revenue: parseInt(formValues.annualRevenue),
    company_email: formValues.companyEmail,
    generate_token: true
  });
  return cyvatarAxios.post('/api/company', body);
};

const getCompany = (companyId) => {
  return cyvatarAxios.get(`/api/company${companyId ? `/${companyId}` : ''}`);
};

const listCompany = (payload) => {
  return cyvatarAxios.get('/api/company/list', { params: payload });
};

const updateCompany = (formValues, companyId) => {
  const body = JSON.stringify({
    name: formValues.companyName,
    number_employees: parseInt(formValues.numberEmployees || 0) || 0,
    annual_revenue: parseInt(formValues.annualRevenue || 0) || 0,
    code: formValues.customerCode,
    note: formValues.companyNote,
    expected_devices: parseInt(formValues?.expected_devices || 0) || 0,
    expected_subnets: parseInt(formValues?.expected_subnets || 0) || 0,
    expected_hosts: parseInt(formValues?.expected_hosts || 0) || 0,
  });
  return cyvatarAxios.post(`/api/company/${companyId}`, body);
};

const getConfirmCompany = (token) => {
  return cyvatarAxios.get(`api/company/confirm_membership/${token}`);
};

const postCancelMembership = async (body) => {
  const response = await cyvatarAxios.post('api/company/cancel-membership', body);
  return response?.data;
};

const loadIssueDemo = () => {
  return cyvatarAxios.post('/api/company/load-demo', {});
};

const requestFreeScan = (companyId) => {
  return cyvatarAxios.post('/api/company/request-month-scan', { companyId });
};

const fetchResults = (params) => {
  return cyvatarAxios.get('/api/company/calculate', { params: params });
};

const getCyValueTabs = async (company_id) => {
  if (!company_id) { return null; }
  const response = await cyvatarAxios.get(`/api/company/${company_id}/subscriptions`);
  // Adds only solutions that support cyvalue
  const isEnabled = (solution) => (cyvalueEnabledSolutions.includes(solution.name))
  const data = _.filter(response.data, isEnabled)
  return data;
};

//  Impersonation endpoint
const getImpersonationList = async (filters = {}) => {
  const response = await cyvatarAxios.get('/api/company/impersonation-list', { params: { filters } });
  const { data } = response;
  return data;
};

const postRestoreCompany = async (body) => {
  const response = await cyvatarAxios.post('api/company/restore-company', body);
  return response?.data;
};

const createCompanyAndInviteUsers = async (params) => {
  const body = JSON.stringify(params);
  const response = await cyvatarAxios.post('/api/company/create-invite', body);
  const { data } = response;
  return data;
}

const createCompanyNote = async (body) => {
  const response = await cyvatarAxios.post('api/company/company_note/create', body);
  const { data } = response;
  return data;
}

const listCompanyNotes = async (params) => {
  const response = await cyvatarAxios.get('/api/company/company_note/list', { params });
  const { data } = response;
  return data;
}

//  Function to get company attachments
const getCompanyAttachments = async (params) => {
  const {filters, company_id} = params;
  const response = await cyvatarAxios.get(`/api/company/attachment/${company_id}`, {params : filters});
  const { data } = response;
  return data;
};

const getCompanyAttachmentById = async ({ companyPublicId, attachmentId }) => {
  const url = `/api/company/attachment/${companyPublicId}/${attachmentId}`;
  const response = await cyvatarAxios.get(url);
  return response.data;
};

//  Function to upload company attachment
const uploadCompanyAttachment = async ({ company_public_id, files = [] }) => {
  const response = await cyvatarAxios.put(`/api/company/attachment/${company_public_id}`, { files });
  const { data } = response;
  return data;
};

//  Function to delete company attachment
const deleteCompanyAttachment = async (attachment_id) => {
  const response = await cyvatarAxios.delete('/api/company/attachment/', { data: { attachment_id } });
  const { data } = response;
  return data;
};

const getDomainsList = async (params) => {
  const apiResponse = await cyvatarAxios.get('/api/company/domains/list', { params });
  const { data } = apiResponse;
  return data;
};

const addCompanyDomains = async (body) => {
  const response = await cyvatarAxios.post('/api/company/domains/add', body);
  const { data } = response;
  return data;
}

const validateCompanyDomains = async (body) => {
  const response = await cyvatarAxios.post('/api/company/domains/validate', body);
  const { data } = response;
  return data;
}

const deleteCompanyDomains = async (body) => {
  const response = await cyvatarAxios.delete('/api/company/domains', { data: body });
  const { data } = response;
  return data;
}

const enableDomainScan = async (payload) => {
  const response = await cyvatarAxios.put('api/company/domains/change-enabled ', payload);
  const { data } = response;
  return data;
}

const getScanTimezones = async (params) => {
  const response = await cyvatarAxios.get(`api/settings/options/timezones`, { params });
  const { count, rows } = response.data;
  const pages = {
    count: count[0]?.count,
    rows: rows
  }

  return pages;
};

const addScheduledScan = async (body) => {
  const response = await cyvatarAxios.put('/api/company/domains/schedule-scan', body);
  const { data } = response;
  return data;
};

const getCompanyUsers = async ( params ) => {
  const { company_id, ...filters} = params;
  const apiResponse = await cyvatarAxios.get(`/api/company/users/${company_id}`, {params : filters});
  const { data } = apiResponse;
  return data;
};

// Update number of times an attachment has been downloaded
const updateAttachmentDownload = async (body) => {
  const response = await cyvatarAxios.put('/api/company/attachment/track-download', body);
  const { data } = response;
  return data;
};

// Get the member profile company logo
const getCompanyProfileLogo = async ({companyId}) => {
  const response = await cyvatarAxios.get(`/api/company/logo/${companyId}`);
  const { data } = response;
  return data;
};

// Uploads the member profile company logo
const uploadCompanyProfileLogo = async ({companyId, file}) => {
  const response = await cyvatarAxios.post(`/api/company/logo/${companyId}`, {file});
  const { data } = response;
  return data;
};

// Delete the member profile company logo
const deleteCompanyProfileLogo = async ({companyId}) => {
  const response = await cyvatarAxios.delete(`/api/company/logo/${companyId}`);
  const { data } = response;
  return data;
};

//  Array of queries to invalidate on issues changes
const issuesChangesInvalidators = [
  GET_ISSUE,
  GET_ISSUES_LIST,
  GET_ROLLUP_DATA,
  GET_ISSUE_MODAL_OPTIONS,
  GET_ACTIVITY_REPORT,
  GET_OPPORTUNITIES_TO_IMPROVE,
  FETCH_COMPLIANCE_SCORE,
  GET_COMPLIANCE_STATUS,
  GET_COMPLIANCE,
  GET_COMPANY_USERS,
];

export const CompanyAPI = {
  handlers: {
    createCompany,
    getCompany,
    listCompany,
    updateCompany,
    getConfirmCompany,
    postCancelMembership,
    loadIssueDemo,
    requestFreeScan,
    fetchResults,
    getCyValueTabs,
    getImpersonationList,
    postRestoreCompany,
    createCompanyAndInviteUsers,
    createCompanyNote,
    listCompanyNotes,
    uploadCompanyAttachment,
    deleteCompanyAttachment,
    getCompanyAttachments,
    getCompanyAttachmentById,
    getDomainsList,
    addCompanyDomains,
    validateCompanyDomains,
    deleteCompanyDomains,
    enableDomainScan,
    getScanTimezones,
    addScheduledScan,
    getCompanyUsers,
    updateAttachmentDownload,
    getCompanyProfileLogo,
    uploadCompanyProfileLogo,
    deleteCompanyProfileLogo,
  },
  keys: {
    getCompany: GET_COMPANY,
    listCompany: LIST_COMPANY,
    getConfirmCompany: GET_CONFIRM_COMPANY,
    fetchResults: FETCH_RESULTS,
    getCyValueTabs: GET_CYVALUE_TABS,
    getImpersonationList: GET_IMPERSONATION_LIST,
    listCompanyNotes: LIST_COMPANY_NOTES,
    getCompanyAttachments: GET_COMPANY_ATTACHMENTS,
    getDomainsList: GET_DOMAINS_LIST,
    getScanTimezones: GET_SCAN_TIMEZONES,
    getCompanyUsers: GET_COMPANY_USERS,
    getCompanyProfileLogo : GET_COMPANY_LOGO,
  },
  invalidators: {
    createCompany: [LIST_COMPANY, GET_IMPERSONATION_LIST],
    updateCompany: [LIST_COMPANY, GET_IMPERSONATION_LIST],
    postCancelMembership: [LIST_COMPANY, GET_IMPERSONATION_LIST, GET_ISSUES_LIST, GET_ISSUE, GET_GRC_ISSUE, GET_GRC_ISSUES_LIST],
    loadIssueDemo: issuesChangesInvalidators,
    requestFreeScan: [
      LIST_COMPANY,
      GET_CYVALUE_TABS,
      GET_CYVALUE_DATA,
      GET_CYVALUE_BY_WEEK,
      ...issuesChangesInvalidators,
    ],
    postRestoreCompany: [LIST_COMPANY, GET_IMPERSONATION_LIST, GET_ISSUES_LIST, GET_GRC_ISSUES_LIST],
    createCompanyAndInviteUsers: [LIST_COMPANY, GET_IMPERSONATION_LIST],
    createCompanyNote: [LIST_COMPANY_NOTES],
    uploadCompanyAttachment: [GET_COMPANY_ATTACHMENTS],
    deleteCompanyAttachment: [GET_COMPANY_ATTACHMENTS],
    addCompanyDomains: [GET_DOMAINS_LIST],
    deleteCompanyDomains: [GET_DOMAINS_LIST],
    enableDomainScan: [GET_DOMAINS_LIST],
    addScheduledScan: [GET_DOMAINS_LIST],
    updateAttachmentDownload: [GET_COMPANY_ATTACHMENTS],
    uploadCompanyProfileLogo: [GET_COMPANY_LOGO],
    deleteCompanyProfileLogo: [GET_COMPANY_LOGO],
  }
};
