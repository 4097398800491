import React from 'react';
import ContextMenu from '../../context-menu/ContextMenu';
import ContextMenuOption from '../../context-menu/ContextMenuOption';
import { setAlert } from '../../../actions/alerts';
import { setIdsToDelete, setIdsToRestore } from '../../../actions/recycleBin';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import useCanHardDeleteElements from '../../../hooks/useCanHardDeleteElements';

const RecycleBinContextMenu = (props) => {
  //  Parsing props
  const {
    entity,
    parentRef,
    selected = [],
  } = props;

  //  Component hook
  const { dispatch } = useMemoizedDispatch();
  const { canDelete } = useCanHardDeleteElements({ entity });

  //  Function to delete elements
  const onDelete = () => {
    if (selected.length === 0) {
      dispatch(setAlert('No elements selected', 'danger'));
      return;
    }
    dispatch(setIdsToDelete(selected));
  };

  //  Function to restore elements
  const onRestore = () => {
    if (selected.length === 0) {
      dispatch(setAlert('No elements selected', 'danger'));
      return;
    }
    dispatch(setIdsToRestore(selected));
  };

  //  Function to handle select
  const handleSelect = (element) => { };

  //  Function to render hard delete option
  const renderHardDeleteOption = () => {
    if (!canDelete) { return null; }
    return (
      <ContextMenuOption
        label="Delete"
        callback={onDelete}
        className="rounded"
        disabled={selected.length === 0}
      />
    );
  };

  //  Rendering
  return (
    <ContextMenu
      parentRef={parentRef}
      elementType="tr"
      onElementSelectCallback={handleSelect}
      minMenuWidth={100}
      minMenuHeight={60}
    >
      <ContextMenuOption
        label="Restore"
        callback={onRestore}
        className="rounded"
        disabled={selected.length === 0}
      />
      {renderHardDeleteOption()}
    </ContextMenu>
  );
};

export default RecycleBinContextMenu;
