import React from 'react';
import { removeItemFromArray } from '../../../utils/helpers';
import { removeValueFromFilter } from '../../../utils/groupHelpers';

const GroupsManagerActiveFilters = (props) => {
  //  Parsing props
  const {
    filters = {},
    activeFilters = [], // Array of { key, value, label }
    initialFilters = {},
    setFilters,
    setActiveFilters,
  } = props;

  //  Function to remove filter
  const removeFilter = (key, value, index) => {
    //  Removing item from active filters
    const newActiveFilters = removeItemFromArray(activeFilters, index);
    setActiveFilters(newActiveFilters);

    //  Removing items from filters
    const newFilters = removeValueFromFilter(key, value, filters);
    setFilters(newFilters, true);
  };

  //  Function to clear all filters
  const clearAllFilters = () => {
    setFilters(initialFilters, true);
    setActiveFilters([]);
  };

  //  Function to render active filters
  const renderActiveFilters = () => {
    const filters = activeFilters?.map((filter, index) => {
      return (
        <div
          className="filter-item"
          key={index}
          onClick={() => removeFilter(filter?.key, filter?.value, index)}
        >
          <div className="inner-container">
            <div className="bx bx-x" />
            <div className="filter-value">
              {filter?.label}
            </div>
          </div >
        </div >
      );
    });

    if (filters?.length === 0) { return filters; }

    const clearItem = (
      <div
        className="clear-item"
        onClick={() => clearAllFilters()}
      >
        <div className="inner-container">
          <div className="bx bx-x" />
          <div className="filter-value">
            Clear all
          </div>
        </div>
      </div>
    );

    return [...filters, clearItem];
  };

  //  Function to render title
  const renderTitle = () => {
    if (activeFilters?.length === 0) { return null; }
    return (
      <div className="active-filters-title">
        Filtered by
      </div>
    );
  };

  //  Rendering
  return (
    <div className="active-filters-container">
      <div className="filter-list-container">
        {renderActiveFilters()}
      </div>
    </div>
  );
};

export default GroupsManagerActiveFilters;
