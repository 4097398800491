import React, { useRef, useState } from 'react';
import _ from 'lodash';
import NotificationBox from './NotificationBox';
import DismissButton from '../../layout/buttons/DismissButton';
import useOutsideClickCallback from '../../../hooks/useOutsideClickCallback';
import LoadingContent from '../../layout/LoadingContent';
import IssuesNotification from './IssuesNotification';
import NotificationTabs from './NotificationTabs';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

const NotificationSidebar = (props) => {
  const {
    isLoading,
    errorMessage,
    toggleSidebar,
    extraClassName = "",
    notifications,
    buttonRef,
    renderLandingModal,
    setShowSidebar
  } = props;
  const [selectedTab, setSelectedTab] = useState(1);
  const history = useHistory();

  //Component ref
  const sidebarRef = useRef(null)

  //Component hooks
  useOutsideClickCallback(sidebarRef, toggleSidebar, buttonRef);

  const renderDismissButton = () => {
    return (
      <DismissButton
        className="notifications-sidebar-dismiss"
        callback={toggleSidebar}
      />
    )
  }

  const renderHeader = () => {
    return (
      <div className='sidebar-notifications-header sticky-element'>
        <div className='sidebar-notifications-title'>
          What's new
        </div>
        {renderDismissButton()}
      </div>
    )
  }

  const renderNotifications = () => {
    if (!notifications || !Array.isArray(notifications) || notifications?.length < 1) return null;
    return notifications.map((notification, index) => {
      const {
        title,
        external_link,
        video_url,
        image,
        date,
        description,
        id
      } = notification;
      return (
        <div
          key={`notification-box-${index}`}
          className="sidebar-notifications-container"
        >
          <NotificationBox
            title={title}
            date={date}
            externalLink={external_link}
            videoLink={video_url}
            image={image}
            description={description}
            backdrop={false}
            renderLandingModal={() => {
              renderLandingModal(notification);
            }}
          />
        </div>
      )
    })

  }

  // Render empty state when there are no notifications
  const renderEmptyState = () => {
    if (notifications?.length > 0) return null;
    return (
      <div className="sidebar-notifications-empty-state">
        <i className='notifications-coffee-icon bx bx-coffee' />
        <div>No new notifications</div>
      </div>
    )
  }

  const renderContent = () => {
    if (selectedTab === 1) {
      return (
        <div className='notification-body-list-container'>
          {renderNotifications()}
          {renderEmptyState()}
        </div>
      )
    }

    return <IssuesNotification setShowSidebar={setShowSidebar} />
  }

  const renderFooter = () => {
    return (
      <div className='notification-footer-container'>
        <Button
          color="light-gray"
          className="btn-small"
          outline
          onClick={() => history.push('/profile/settings')}
        >
          <i className="bx bx-bell" />
          Notifications settings
        </Button>
      </div>
    );
  }

  return (
    <div
      ref={sidebarRef}
      className={`sidebar-notifications ${extraClassName}`}
    >
      <div className='notifications-scrollable-container'>
        {renderHeader()}
        <NotificationTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        <LoadingContent
          isLoading={isLoading}
          errorMessage={errorMessage}
          showErrorButton={false}
          loadingClassName="h-75"
        >
          {renderContent()}
        </LoadingContent>
      </div>
      {renderFooter()}
    </div>
  );
};

export default NotificationSidebar;
