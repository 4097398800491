import React, { useEffect } from 'react';
import { setAlert } from '../../actions/alerts';
import { alertTypes } from '../../constants/alerts';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import { getApiErrorMessage, isValidFunction } from '../../utils/helpers';
import ConfirmActionModal from '../layout/ConfirmActionModal';
import API from '../../api';
import useCustomMutation from '../../hooks/useCustomMutation';
import { useState } from 'react';
import IssuesAssigneeFilter from '../issue-manager/issue-filters/IssuesAssigneesFilter';
import { useSelector } from 'react-redux';
import companyIdSelector from '../../selectors/companyIdSelector';
import { useMemo } from 'react';
import { setConfirmationModal } from '../../actions/layout';
const DeleteTeamMember = (props) => {
    const {
        memberName,
        userId,
        toggleModal,
        reloadCallback,
        userRole
    } = props;

    //  Initializing API
    const { UserAPI } = API;

    // Getting needed info from redux store
    const selectedCompany = useSelector((state) => companyIdSelector(state));

    // Component state
    const [assigneeId, setAssigneeId] = useState(null);

    // Component's variables
    const isSalesAccount = useMemo(() => {
        const salesRole = 'squad_sales'
        return (userRole === salesRole);
    }, [userRole]);

    // Component Hooks
    const { dispatch } = useMemoizedDispatch();

    //  Put to remove member's account
    const {
        data: removeMemberResponse,
        error: removeMemberError,
        mutate: removeTeamMember,
        isLoading: removeMemberLoading
    } = useCustomMutation(
        (params) => UserAPI.handlers.removeTeamMember(params),
        UserAPI.invalidators.removeTeamMember,
    );

    // Watching response to close modal and set success alert
    useEffect(() => {
        if(!removeMemberResponse) return;
        toggleModal();
        const message = (
            <span>
                {'Removed '}<span className="font-weight-bolder">{`${memberName}'s`}</span>{' account'}
            </span>
        );
        dispatch(setConfirmationModal({
            show: true,
            message: message
        }));
        if(isValidFunction(reloadCallback)) {
            reloadCallback(true)
        }
    },[removeMemberResponse]);

    // Watching error to set alert
    useEffect(() => {
        if(!removeMemberError) return;
        const errorMessage = getApiErrorMessage(removeMemberError)
        dispatch(setAlert(errorMessage, alertTypes.error));
    },[removeMemberError])

    const cancelCallback = () => {
        toggleModal()
    }

    const onSelectAssignee = (option) => {
        setAssigneeId(option?.value)
    }

    const renderAssigneeDropdown = () => {
        if(isSalesAccount) return null;
        return (
            <div className="dropdown-container">
                <div className='dropdown-instructions'>Please select a new assignee: </div>
                <IssuesAssigneeFilter
                    selectedCompany={selectedCompany}
                    filterValue={assigneeId}
                    setFilterCallback={onSelectAssignee}
                    showAllMembersOption={false}
                    wrapperClassName="issues-assignee-bulk-dropdown mx-auto"
                    excludedUserIds={[userId]}
                />
            </div>
        )
    }

    const confirmCallback = () => {
        const params = {
            user_id: userId,
            assignee_id: assigneeId,
        }
        removeTeamMember(params);
    }
    return (
        <ConfirmActionModal
            title="Remove account"
            subtitle={`Are you sure you want to permanently delete ${memberName}'s account? This action can’t be undone`}
            confirmLabel={removeMemberLoading ? "Processing..." : "Delete account"}
            disableConfirm={removeMemberLoading || (!assigneeId && !isSalesAccount)}
            cancelCallback={cancelCallback}
            confirmCallback={confirmCallback}
        >
            {renderAssigneeDropdown()}
        </ConfirmActionModal>
    );
};

export default DeleteTeamMember;
