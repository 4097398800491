import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { getSafeArray } from '../utils/helpers';
import { getUpdatedSearchParams } from '../utils/helpers';

const useURLSearchParams = (params) => {
  //  Parsing params
  const {
    paramsEntries = [],
  } = params;

  //  Hook state
  const [value, setValue] = useState(null);

  //  Getting location and history
  const location = useLocation();
  const history = useHistory();

  //  Watching search changes
  useEffect(() => {
    //  Building query params
    const queryParams = new URLSearchParams(location.search);
    // if (queryParams || !(queryParams instanceof Object)) { return null; }
    //  Building safe empty array of keys
    const entries = getSafeArray(paramsEntries);
    if (entries.length === 0) { setValue(queryParams); return; } //  Return all params

    //  Getting needed entries from query params
    const newValue = entries.reduce((val, currentEntry) => {
      return {
        ...val,
        [currentEntry]: queryParams.get(currentEntry),
      }
    }, {});
    setValue(newValue);
  }, [location?.search]);

  //  Function to remove params from URL
  const removeParamsFromURL = (entriesToRemove) => {
    //  Getting params and params to remove
    const queryParams = new URLSearchParams(location.search);
    const safeEntries = getSafeArray(entriesToRemove || queryParams.keys);

    //  Removing params from query object
    safeEntries.forEach((entry) => {
      if (queryParams.has(entry)) {
        queryParams.delete(entry);
      }
    });

    //  Replacing params on history
    history.replace({
      search: queryParams.toString(),
    });
  };

  //  Function to replace query params
  const replaceQueryParams = (newParams, attributues) => {
    history.push({
      search: getUpdatedSearchParams(
        location?.search,
        attributues,
        newParams,
      ),
    });
  };

  //  Exporting assets
  return {
    params: value,
    removeParamsFromURL,
    replaceQueryParams,
  };
};

export default useURLSearchParams;
