import _ from 'lodash'
import { omitSingle, getSafeArray } from './helpers';
import { filterOptions } from '../constants/filterTypes';
import { parseTagsToOptions } from './tagsHelper';
import { cachedQueriesMap, notCachedQueriesMap } from '../constants/queries';
import { USER_TYPES } from '../constants/common';
import { statusesConfig } from '../constants/status';

//  Function to get the next offset for infinite query validation
export const getNextPageParam = (lastPage, allPages, valueToExtract = 'rows', totalValue = 'count') => {
  const currentCount = allPages.reduce((itemsCount, currentPage) => {
    const { [valueToExtract]: rows = [] } = currentPage;
    return itemsCount + (rows?.length || 0);
  }, 0);
  const { [totalValue]: count } = lastPage;
  return currentCount >= count ? undefined : currentCount;
};

//  Function to get next page param
export const getNextPageParamByPage = (lastPage, allPages) => {
  const { pagination = {} } = lastPage;
  const { page = "1", total_pages = 1 } = pagination;
  const numericPage = parseInt(page);
  return numericPage >= total_pages ? undefined : (numericPage + 1)
};

// Function to initialize device list filters
export const initializeDeviceListFilters = (page = 1, per_page = 20, text_to_search = '', filters, sort = {}) => {
  return { page, per_page, text_to_search, filters, sort };
};
const getMultipleValueFilter = (filter) => {
  return getSafeArray(filter)?.length > 0 ? filter : null
}
//  Function to initialize issues config
export const initializeIssuesConfig = (page, per_page, text_to_search, sortField, filters, userType, notification_filter) => {
  const filter_by_tags = [];
  const filter_out_tags = []
  const cleanFilters = omitSingle('tags', filters);

  if (filters?.tags) {
    filters.tags.forEach((tag) => {
      if (tag?.filterOption === filterOptions.FILTER_BY) {
        filter_by_tags.push(tag.value);
      } else {
        filter_out_tags.push(tag.value);
      }
    })
  }

  const safeSeverity = getSafeArray(filters.severity);
  const safeStatus = [...getSafeArray(filters.status)]

  if (safeStatus?.length > 0) {
    const toDoStatus = statusesConfig.find((element) => element?.label === 'To Do');
    if (userType === USER_TYPES.MEMBER && toDoStatus && safeStatus.includes(toDoStatus?.id)) {
      const pendingStatus = statusesConfig.find((element) => element?.label === 'Pending Review');
      if (pendingStatus && filters.status) {
        safeStatus.push(pendingStatus?.id)
      }
    }
  }

  const sourceIds = getSafeArray(filters?.source).map((source) => source?.value);
  const solutionFilter = filters?.group_id ? getMultipleValueFilter(filters?.multiple_solution_id) : filters?.solution_id;
  const companyFilter = filters?.group_id ? getMultipleValueFilter(filters?.multiple_company_id) : filters?.company_id
  return {
    page,
    per_page,
    notification_filter,
    filters: {
      ...cleanFilters,
      severity: safeSeverity?.length > 0 ? filters.severity : null,
      status: safeStatus?.length > 0 ? safeStatus : null,
      filter_by_tags,
      filter_out_tags,
      time_since_creation: filters?.time_since_creation?.milliseconds,
      due_date: filters?.due_date?.milliseconds,
      source: sourceIds?.length > 0 ? sourceIds : null,
      company_id: companyFilter,
      solution_id: solutionFilter,
    },
    text_to_search,
    sort: sortField.name ? {
      [sortField.name]: sortField.asc ? 'ASC' : 'DESC'
    } : null
  }
};

//  Function to parse tags infinite response
export const parseTagsInfiniteResponse = (rows, company_id, isCydekick = false) => {
  const newTags = parseTagsToOptions(rows, company_id, isCydekick);
  const cleanArray = _.uniqBy(newTags, 'value');
  return cleanArray;
};

//  Function to invalidate queries
export const invalidateQueriesByKeys = (invalidators, queryClient) => {
  if (!invalidators || !Array.isArray(invalidators)) { return; }
  //  Invalidating all queries (logout case)
  if (invalidators.length === 0) {
    queryClient.clear();
    return;
  }
  //  Invalidating queries
  invalidators.forEach((key) => {
    //  Invalidating queries, just the ones that are cached are refetched even inactive
    const refetchInactive = cachedQueriesMap.has(key);
    queryClient.invalidateQueries(key, { refetchInactive });
  });
}

//  Function to get query config
export const getQueryConfigByKey = (queryKey) => {
  if (!queryKey || !Array.isArray(queryKey)) { return {}; }
  //  Getting key, the first element of the array
  const key = queryKey[0];
  if (!key) { return {}; }
  //  Getting default config for key
  const isCached = cachedQueriesMap.has(key);
  const queryConfig = isCached ? cachedQueriesMap.get(key) : notCachedQueriesMap.get(key);
  return queryConfig || {};
};

