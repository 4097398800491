import React from 'react';

const TrustBuilderSection = (props) => {
    const {
        title,
        preview,
        children,
        sectionName = null
    } = props;

    const renderSubscribeMessage = () => {
        return (
            <div className="overlay">
                <div className="vcyso-subscribe-message">
                    {`Subscribe CySO to unlock Trust Builder ${sectionName}`} 
                </div>
            </div>
        )
    }
    return (
        <div className="trust-builder-section">
            <div className="title pl-3 mb-4">{title}</div>
            <div>
                {preview && sectionName && renderSubscribeMessage()}
                <div className={`section-content ${preview ? 'preview-filter':''}`}>
                    {preview && (
                        <div className={'preview-block'}></div>
                    )}
                    <div>{children}</div>
                </div>
            </div>
        </div>
    )
}

export default TrustBuilderSection;