import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { changeLayout } from '../actions/layout';
import MFAOption from "../components/settings/MFAOption";
import mfaSMS from "../assets/images/mfa-sms.png";
import { setAlert, removeAlert } from "../actions/alerts"
import amplitude from 'amplitude-js';

const MFAConfig = (props) => {
  const {
    changeLayout,
    packages,
    mfaConfig,
    company,
    setAlert,
    removeAlert,
  } = props;

  useEffect(() => {
    amplitude.getInstance().logEvent('Page View - 2FA');
  }, []);

  useEffect(() => {
    changeLayout('vertical');
  }, [changeLayout]);

  const [activeAlertId, setActiveAlertId] = useState(null);

  useEffect(() => {
    const forceMFAPath = packages.length > 0 && !mfaConfig.mfa_type && company.freemium === 0

    if (!activeAlertId && forceMFAPath) {
      const alertId = uuidv4();
      const message = 'To continue from this point on, you need to enable Two-factor Authentication for security reasons. Follow the instructions below.';
      setAlert(message, 'important', false, 5000, '', alertId);
      setActiveAlertId(alertId);
    }
    return () => {
      if (activeAlertId) {
        removeAlert(activeAlertId);
      }
    };
  }, [activeAlertId])

  return (
    <div className="bg-gradient-dark-purple-v3 h-minus-header vw-100">
      <div className="mfa-config">
        {!mfaConfig?.mfa_type &&
          <React.Fragment>
            <div className="title">
              Verify Login
              <p className="title-description">Two-step Authentication is inactive for your account.</p>
              <p className="title-description">Protect your Cyvatar account with an extra layer of security by requiring more than just a password to log in.</p>
            </div>
            <MFAOption
              title="Get a verification code via SMS"
              description="Enter your password and a unique verification code that’s
              sent to your phone."
              image={mfaSMS}
              route="/mfa-sms"
            />
          </React.Fragment>
        }
      </div>
    </div>
  );
}


const makeMapStateToProps = () => {
  const mapStateToProps = (state) => {
    const { mfaConfig, company, user: { packages } } = state.auth;
    return {
      mfaConfig,
      company,
      packages,
    }
  }
  return mapStateToProps;
}

const mapDispatchToProps = {
  changeLayout,
  setAlert,
  removeAlert,
};

export default withRouter(connect(makeMapStateToProps, mapDispatchToProps)(MFAConfig));
