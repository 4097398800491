import React from "react";

const AttachmentDragBrowseArea = (props) => {
  const {
    isDragOver,
    onFileDrop,
    onInputClick,
    disabled,
    wrapperClassName = "",
  } = props;
  const getContainerClassName = () => {
    const draggedClassName = isDragOver && !disabled ? " dragged-over" : "";
    return `attachment-drag-browse-area ${wrapperClassName}${draggedClassName}`
  }

  return (
    <div
      className={getContainerClassName()}
      onDrop={onFileDrop}
    >
      <div className="company-attachments-field-placeholder">Drop files or</div>
      <div
        className="company-attachments-field-browse"
        onClick={onInputClick}
      >
        <i className="attachments-download-icon bx bx-download"/>
        Browse
      </div>
    </div>
  );
};

export default AttachmentDragBrowseArea;
