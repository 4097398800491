import React, { useEffect } from 'react';
import { Button, Row} from 'reactstrap';
import _ from 'lodash';
import { connect } from 'react-redux';
import makeFreemiumValidations from '../../selectors/freemiumStatusSelector';
import { trackEvent } from '../../utils/gaTracker';

const IssueDemoReminder = (props) => {
  const {
    lastScan,
    validations: {
      lastScanIsValid,
    }
  } = props;

  useEffect(() => {
    let label = 'sample-data';
    if (lastScan && lastScanIsValid) {
      label = 'free-scan';
    } else if (lastScan && !lastScanIsValid) {
      label = 'expired-scan';
    } 
    trackEvent(`show-demo-${label}`, 'freemium', 'display demo data');
  },[lastScanIsValid, lastScan])
  
  const handleUpgradeMembership = () => {
    trackEvent('upgrade-membership', 'freemium', 'upgrade from expired widget');
    window.open(process.env.REACT_APP_CONTACT_SALES_URL, "_blank");
  }
  const sampleDataAlert = <div className="demo-reminder">This is sample data, request a scan to populate the Issue Manager with real issues from your company.</div>
  const scanDataAlert = <div className="demo-valid-scan">These issues will expire 14 days after we provide a remediation plan for all of them.  To get these issues solved, Upgrade your membership.</div>
  const scanIvalidAlert = <div className="demo-invalid-scan">These issues have expired, You get 1 monthly scan. To get this issues solved and get CyValue, Upgrade your membership.</div>
  const upgradeMembershipButton =<Button type="button" color="light" className="btn small-btn" onClick={handleUpgradeMembership} onKeyUp={handleUpgradeMembership} > Upgrade your membership </Button>

  const renderIvalidAlertModal = () => {
    return (
      <div style={{width: '100%', height:'100%', position: 'absolute', zIndex: 1, top: '10%', minHeight: '500px'}}>
        <div className="demo-invalid-scan-title">Uh Oh...</div>
        <div className='demo-invalid-scan-description'>
          These issues have expired,<br />We can solve them for you if you like...
        </div>
        <div className="d-flex justify-content-center">{upgradeMembershipButton}</div>
      </div>
    )
  }
  return (
    <Row>
      {!lastScan && sampleDataAlert}
      {lastScan && lastScanIsValid && scanDataAlert}
      {lastScan && !lastScanIsValid && scanIvalidAlert}
      {lastScan && !lastScanIsValid && renderIvalidAlertModal()}
    </Row>
  )
}

const mapStateToProps = (state) => {
  const getFreemiumValidations = makeFreemiumValidations();
  const { showDemoData, requestedScan, lastRequestScan, lastScan } = state.auth.company;
  return {
    freemiumStatus: state.auth.company?.freemium,
    showDemoData,
    requestedScan,
    lastRequestScan,
    lastScan,
    validations: getFreemiumValidations(state)
  }
}

const dispatchMapToProps = {}
export default connect(mapStateToProps, dispatchMapToProps)(IssueDemoReminder);