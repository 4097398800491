import React, { useState, useEffect } from "react";
import SimpleDropdown from "../layout/SimpleDropdown";
import API from "../../api";
import useCustomQuery from "../../hooks/useCustomQuery";
import { useSelector } from "react-redux";
import companyIdSelector from "../../selectors/companyIdSelector";
import { listOptionsFormats } from "../../utils/partnerActionHelpers";
import useMemoizedDispatch from "../../hooks/useMemoizedDispatch";
import { setAlert } from "../../actions/alerts";

const PartnerActionDropdown = (props) => {

  // Redux
  const companyId = useSelector((state) => companyIdSelector(state));
  const partnerId = useSelector((state) => state.partners.partnerId);

  // Hooks
  const { dispatch } = useMemoizedDispatch();

  // Props
  const {
    field,
    fieldTitle,
    onDropdownChange,
    selectedElement,
    extraClassName,
  } = props;

  // Init vars
  const {
    source_url,
    placeholder,
  } = field;

  //  Initializing APIs
  const { PartnerAPI } = API;

  // State
  const [dropdownOptions, setDropdownOptions] = useState(null)

  // Call api to get dropdown options
  const {
    data: dropdownOptionsResponse,
    error: dropdownOptionsError,
    isLoading: dropdownOptionsLoading,
    refetch: refetchDropdownOptions,
  } = useCustomQuery(
    [
      PartnerAPI.keys.getPartnerActionsListOptions,
      { company: companyId, partner: partnerId, source: source_url, format: listOptionsFormats.DROPDOWN },
    ],
    ({ queryKey }) =>
      PartnerAPI.handlers.getPartnerActionsListOptions(queryKey[1]),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  useEffect(() => {
    if (!dropdownOptionsResponse) return;
    setDropdownOptions(dropdownOptionsResponse.rows);
  }, [dropdownOptionsResponse]);

  useEffect(() => {
    if (!dropdownOptionsError) return;
    dispatch(setAlert('An error occurred when loading dropdown. Please try again.', 'danger'));
  }, [dropdownOptionsError]);

  const renderDropdown = () => {
    const elements = dropdownOptions?.map((option) => {
      return {
        value: option.key,
        label: option.display_name,
      };
    });

    return (
      <SimpleDropdown
        extraClassName={extraClassName}
        placeholder={placeholder}
        elements={elements || []}
        onChangeCallback={onDropdownChange}
        selectedElement={selectedElement}
        isLoading={dropdownOptionsLoading}
        customMenuPosition='absolute'
      />
    );
  };

  return (
    <div className="field dropdown">
      {fieldTitle()}
      {renderDropdown()}
    </div>
  );
}

export default PartnerActionDropdown;
