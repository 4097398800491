import React from 'react';
import _ from 'lodash';
import { Col, Row } from 'reactstrap';
import VcysoSolution from './VcysoSolution';
import LoadingContent from '../layout/LoadingContent';
import TrustBuilderSection from './TrustBuilderSection';

const VcysoSolutionsControls = (props) => {
    const {
        title,
        controls,
        solutions,
        preview=false,
        isLoading,
        errorMessage,
        retryRequests,
        sectionName
    } = props;

    const dummyDescription = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed volutpat odio orci, ac posuere velit vehicula at. Nunc malesuada vel ex nec commodo. Maecenas semper sapien a tempor malesuada. Mauris posuere purus non augue ";

    const renderSolutions = () => {
        if(!solutions) return null;
        return solutions.map((solution, index) => {
            return (
                <Col key={`vcyso-solution-${index}`} className="solution-wrapper px-2 mb-3" sm={6}>
                    <VcysoSolution 
                        name={solution?.name}
                        title={solution?.title}
                        info={preview? dummyDescription : solution?.info}
                        icon={`${process.env.REACT_APP_PUBLIC_RESOURCES}/solutions/mobile/${solution?.name?.toLowerCase()}-base.png`}
                    />
                </Col>
            )
        })
    }
    return (
        <div className="vcyso-controls-solutions">
            <TrustBuilderSection
                title={title}
                preview={preview && !isLoading && !errorMessage}
                sectionName={sectionName}
            >
                <LoadingContent
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    iconType="solidIcon"
                    errorStyle={{ minHeight: "225px" }}
                    loadingStyle={{ minHeight: "225px" }}
                    errorButtonCallback={retryRequests}
                >
                    <div>
                        <div className="controls-list pl-3 mb-3">
                            {_.join(controls,', ')}
                        </div>
                        <Row className="solutions-wrapper">
                            {renderSolutions()}
                        </Row>
                    </div>
                </LoadingContent>
            </TrustBuilderSection>
        </div>
    )
}

export default VcysoSolutionsControls;