export const assessmentCriticalityConfigColor = {
    'HIGH': "#F1A04C",
    'MEDIUM': "#F5D302",
    'LOW': "#0EB082",
    'CRITICAL': "#FF5E54"
};

export const riskCriticalityConfigColor = {
    'HIGH': "#FF5E54",
    'MEDIUM': "#F5D302",
    'LOW': "#0EB082",
    'AVOID': "#FF5AC4",
};

export const criticalityByGrade = {
    "A":"LOW",
    "B":"MEDIUM",
    "C":"HIGH",
    "D" : "CRITICAL"
}

export const gradeByCriticality = {
    "LOW": "A",
    "MEDIUM": "B",
    "HIGH": "C",
    "CRITICAL": "D"
}

export const arrowPositionByGrade = {
    "A": 4,
    "B": 3,
    "C": 2,
    "D": 1
};

export default {
    100: {
        className: "critical",
        label: "Critical",
        value: 100
    },
    200: {
        className: "warning",
        label: "High",
        value: 200
    },
    300: {
        className:"good",
        label: "Medium",
        value: 300
    },
    400: {
        className: "great",
        label: "Low",
        value: 400
    },
    500: {
        className: "info",
        label: "Info",
        value: 500
    },
};

export const issuesCriticalityConfigColor = {
    'HIGH': "#F1A04C",
    'MEDIUM': "#F5D302",
    'LOW': "#0EB082",
    'CRITICAL': "#FF5E54"
};
