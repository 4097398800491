import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import useValueDebounce from '../../../hooks/useValueDebounce';

const TimeoutUpdateInput = (props) => {
  const {
    type,
    callback,
    placeholder,
    value,
    field,
    className,
    timeoutTime = 500,
    disabled = true,
  } = props;

  const [updatedValue, setUpdatedValue] = useState(value);

  const { debouncedValue: debouncedValuefromHook } = useValueDebounce({
    valueToDebounce: updatedValue,
    defaultValue: value,
    debounceTime: timeoutTime,
  });

  useEffect(() => {
    if(debouncedValuefromHook !== null && debouncedValuefromHook !== value ) callback(debouncedValuefromHook, field);
  },[debouncedValuefromHook]);

  //  Function to render input
  const renderInput = () => {
    return (
      <Input
        type={type}
        className={className}
        placeholder={placeholder}
        value={updatedValue}
        onChange={(e) => {setUpdatedValue(e.target.value)}}
        disabled = {disabled}
      />
    );
  };

  return (
    renderInput()
  );
};

export default TimeoutUpdateInput;
