import React, { useEffect, useState } from 'react';
import { Row, Col } from "reactstrap";
import { connect } from 'react-redux';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import FormCard from '../layout/FormCard';
import MFACard from "./MFACard";
import Cookies from "universal-cookie";
import amplitude from 'amplitude-js';
import HookFormField from '../hookform/HookFormField';
import useHookValidationByParams from '../../hooks/useHookValidationByParams';
import { login, authError } from '../../actions/auth';
import { changeLayout } from '../../actions/layout';
import {
  validateEmail
} from '../../utils/validations';
// import images
import logo from "../../assets/images/logos/cyvatar-beta-logo.svg";
import loginImage from "../../assets/images/isometric-images-2.png";
import { resetAssessmentNavigation } from '../../actions/assessment';


const Login = (props) => {
  const {
    isAuthenticated,
    mfaConfig,
    changeLayout,
    login,
    history,
    location,
    error,
    resetAssessmentNavigation
  } = props;

  const [shouldRedirect, setShouldRedirect] = useState(true)

  //react hook form
  const { register, watch, handleSubmit, formState: { errors, touchedFields, dirtyFields } } = useForm({
    defaultValues: { email: '', password: '' }
  });

  //Validations
  function validate(inputData, fieldName) {
    let error = '';
    if (fieldName === 'email' && validateEmail(inputData)) {
      error = validateEmail(inputData);
    }
    return error;
  }

  const getHookParams = (nameValue) => {
    return ({
      name: nameValue,
      required: { state: true, message: "Please complete this required field" },
      validate,
      watch,
      errors,
      touchedFields,
      dirtyFields
    });
  }

  const emailValidationData = useHookValidationByParams('email', getHookParams);
  const passwordValidationData = useHookValidationByParams('password', getHookParams);

  useEffect(() => {
    amplitude.getInstance().logEvent('Page View - Sign In');
  }, []);

  useEffect(() => {
    changeLayout('horizontal');
  }, [changeLayout]);

  if (isAuthenticated && shouldRedirect) {
    return <Redirect to="/" />
  }

  // handleValidSubmit
  function handleValidSubmit(event, values) {
    const { email, password } = values;
    const cookies = new Cookies();
    const oldDate = new Date(cookies.get(email) || null);
    const newDate = new Date();
    const formValues = {
      user: {
        email,
        password
      },
      trustMFA: newDate < oldDate,
    }
    setShouldRedirect(false);
    login(formValues, history, location);
  }

  const onSubmitForm = handleSubmit((data, e) => {
    handleValidSubmit(e, data);
  });

  const renderEmailField = () => {
    const {
      isInvalid,
      errorMsgClassName,
      errorIconClassName,
      requirements,
      errorMsg
    } = emailValidationData;

    return (
      <HookFormField
        name="email"
        label="Email"
        ariaLabel="Email edit text"
        className="form-control hook-form-control"
        wrapperClassName="form-group"
        type="email"
        style={{ marginBottom: "5px" }}
        ariaRequired="true"
        register={register}
        isInvalid={isInvalid}
        errorMsgClassName={errorMsgClassName}
        errorIconClassName={errorIconClassName}
        requirements={requirements}
        errorMsg={errorMsg}
      />
    );
  }

  const renderPasswordField = () => {
    const {
      isInvalid,
      errorMsgClassName,
      errorIconClassName,
      requirements,
      errorMsg
    } = passwordValidationData;

    return (
      <HookFormField
        name="password"
        label="Password"
        ariaLabel="Password edit text"
        className="form-control hook-form-control"
        wrapperClassName="form-group"
        type="password"
        style={{ marginBottom: "5px" }}
        ariaRequired="true"
        register={register}
        isInvalid={isInvalid}
        errorMsgClassName={errorMsgClassName}
        errorIconClassName={errorIconClassName}
        requirements={requirements}
        errorMsg={errorMsg}
      />
    );
  }

  //  Function to handle logo click
  const onCyvatarLogoClicked = () => {
    window.open(process.env.REACT_APP_CYVATAR_WEBSITE, '_blank');
  };

  return (
    <React.Fragment>
      <div className="w-100">
        <Row className="justify-content-around align-items-start align-items-md-center form-card-container flex-grow-1 h-md-100 p-4">
          <Col
            className="isometric-images-2-container"
            md={5}
            sm={10}
          >
            <Row className="justify-content-center">
              <img
                src={logo}
                alt="cyvatar"
                className="img-fluid"
                style={{ height: "42px", marginBottom: "31px", cursor: 'pointer' }}
                //style={{ height: "50px", marginBottom: "31px", cursor: 'pointer' }}
                onClick={() => onCyvatarLogoClicked()}
              />
            </Row>
            <Row className="justify-content-end d-none d-lg-block">
              <img
                src={loginImage} alt=""
                className="img-fluid"
                style={{ maxHeight: "501px" }}
              />
            </Row>
          </Col>
          <Col
            md={12}
            lg={4}
          >
            <Row className="justify-content-center align-items-start align-items-sm-center align-items-lg-start flex-grow-1 h-100">
              {!mfaConfig?.token &&
                <FormCard
                  title="Login"
                  error={error}
                >
                  <div>
                    <form
                      className="form-horizontal"
                      onSubmit={onSubmitForm}
                    >
                      {renderEmailField()}
                      {renderPasswordField()}

                      {/*login btn*/}
                      <div style={{ marginBottom: "15px" }} >
                        <button
                          className="btn btn-light text-dark btn-block"
                          type="submit"
                        >
                          Login
                        </button>
                      </div>
                      {/*forgot password*/}
                      <div className="text-center" style={{ marginBottom: "32px" }}>
                        <Link
                          to="/reset-password"
                          className="forgot-password text-muted d-flex align-items-center justify-content-center focus-visible-dark"
                        >
                          <i className="mdi mdi-lock mr-1" style={{ fontSize: "22px" }}></i>
                          Forgot your password?
                        </Link>
                      </div>
                      {/*not a client, take checkup*/}
                      <div className="text-center not-a-client-yet" style={{ marginBottom: "16px" }} tabIndex="-1">
                        <p aria-hidden="true">Not a client yet?
                          <Link
                            to="/my-cyvatar"
                            className="focus-visible-dark"
                            onClick={() => {
                              resetAssessmentNavigation();
                              amplitude.getInstance().logEvent('Take Cyber Checkup - Sign In View');
                            }}
                            onKeyUp={resetAssessmentNavigation}
                            aria-label="Not a client yet? Sign up here!"
                            aria-hidden="false"
                          >
                            Sign up here!
                          </Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </FormCard>
              }
              {mfaConfig?.token && <MFACard />}
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

const mapStatetoProps = state => {
  const { error, isAuthenticated, mfaConfig } = state.auth;
  return { error, isAuthenticated, mfaConfig };
}

export default withRouter(connect(mapStatetoProps, { login, authError, changeLayout, resetAssessmentNavigation })(Login));