import React, {useState, useEffect} from "react";
import { useSelector } from 'react-redux';
import DashboardRollup from '../../../../components/app-v4/my-cyvatar/dashboard/DashboardRollup';
import companyIdSelector from '../../../../selectors/companyIdSelector';
import { rollUpWidgetsIds } from '../../../../constants/common';
import { issueTypes } from '../../../../constants/issues';
import {useHistory,useLocation} from 'react-router-dom';
import { arrangeStatusData } from "../../../../utils/dashboardHelpers";
import useMemoizedDispatch from '../../../../hooks/useMemoizedDispatch';
import { setIssuesMultipleFilterGroup } from '../../../../actions/issues';
import filterTypes from '../../../../constants/filterTypes';
import { createMultipleFiltersGroupPayload } from '../../../../utils/filterHelpers';
import useUserType from "../../../../hooks/useUserType";

const CurrentStatusDashboardRollup = (props) => {
  const {
    customEmptyLabel,
    customEmptyLabelClassName,
  } = props

  //  Component state
  const [moveToIssues, setMoveToIssues] = useState(false);

  // Getting needed info from redux store
  const companyId = useSelector(state => companyIdSelector(state));
  const selectedSubTabValue = useSelector((state) => state?.navigation?.selectedSubtab?.value);
  //  Watching redux store
  const selectedSubtabSearch = useSelector((state) => state?.navigation?.selectedSubtab?.search);

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const history = useHistory();
  const location = useLocation();
  const userType = useUserType();

  //  Watching move to dashboard changes
  useEffect(() => {
    if (!moveToIssues) { return; }
    setMoveToIssues(false);
    const issuesPath = `/dashboard/issues/${selectedSubtabSearch}`;
    history.push(issuesPath);
  }, [moveToIssues]);

  //  Function to handle roll up click
  const onRollUpClick = (filter, widgetType, isTotal = false) => {
    if(!isTotal) {
      // Clicking a specific legend filter on dashboard
      const filterLabel = widgetType === rollUpWidgetsIds.STATUS ? 'status' : 'severity';
      const label = widgetType === rollUpWidgetsIds.STATUS ? filter?.label : `Open, ${filter?.label}`;
      const idAsInt = parseInt(filter?.id);
      const values = [idAsInt];
      const activeValues = [
        {
          filterName: filterLabel,
          value: idAsInt,
          label,
          type: filterTypes.multiple,
        }
      ];
      dispatch(setIssuesMultipleFilterGroup(filterLabel, values, activeValues, true, issueTypes.VULNERABILITIES));
    } else if (filter) {
      // Clicking the total number
      const filterName = widgetType === rollUpWidgetsIds.STATUS ? 'status' : 'severity';
      const addOpenLabel = widgetType === rollUpWidgetsIds.STATUS ? false : true;
      const payload = createMultipleFiltersGroupPayload(filter, filterName, addOpenLabel)
      dispatch(setIssuesMultipleFilterGroup(filterName, payload?.values, payload?.activeValues, true, issueTypes.VULNERABILITIES));
    }
    //  Using this flag to move to issues to avoid APEX charts crashing
    setMoveToIssues(true);
  };

  const callArrangeStatusData = (rollupData, sumAll) => {
    return arrangeStatusData(rollupData, sumAll, false,issueTypes.VULNERABILITIES, userType, null);
  };

  //  Rendering
  return (
    <>
      <DashboardRollup
        sm={12}
        lg={12}
        sumAll={true}
        allowAllCompanies={true}
        company_id={companyId}
        solution_id={selectedSubTabValue}
        widgetType={rollUpWidgetsIds.STATUS}
        containerClassName={`dashboard-rollup-container`}
        cardClassName="h-100 mb-3 mb-md-0"
        callback={callArrangeStatusData}
        handleRollupClick={onRollUpClick}
        addTotalFilter={!location?.pathname?.includes("/issues")}
        issueType={issueTypes.VULNERABILITIES}
        customEmptyLabel={customEmptyLabel || "The Cydekicks are getting everything in order."}
        customEmptyLabelClassName={customEmptyLabelClassName}
      />
    </>
  );
};

export default CurrentStatusDashboardRollup;
