import React from 'react';

const CustomProgressBar = (props) => {

  const { 
    themeColor,
    progressWidthPercentage,
    maxProgressWidthPercentage,

    initialMarkerMessage = '',
    initialMarkerIcon,
    
    showGoalMarker = false,
    goalMarkerMessage = '',
    goalMarkerIcon,
    markers = [],
    customClassName = '',

    backgroundBarColor
  } = props;

  const renderMarkers = () => {
    return markers?.map((marker) => {
      //const position = (marker.position / 120) * 100;
      return (
        <div className='marker' style={{[marker?.direction]: marker?.position}}>
          {marker?.icon ? (marker?.icon) : null}
        </div>
      );
    });
  };

  const progressBarWidth = (progressWidthPercentage / 100) * maxProgressWidthPercentage;


  return (
    <div className={`custom-progress-bar-container ${customClassName}`} style={{backgroundColor: backgroundBarColor}}>
      <div
        className="custom-progress-bar"
        style={{
          backgroundColor: themeColor,
          width: progressBarWidth + "%",
        }}
      >
        <div className="markers">
          <div className="marker initial-marker">
            {initialMarkerIcon ? initialMarkerIcon : null}
            <p className="initial-marker-message marker-message">
              {initialMarkerMessage}
            </p>
          </div>
          {showGoalMarker ? (
            <>
              {goalMarkerIcon ? goalMarkerIcon : null}
              <div
                className="marker goal-marker"
                style={{ right: 100 - progressBarWidth + "%" }}
              >
                <p className="goal-marker-message marker-message">
                  {goalMarkerMessage}
                </p>
              </div>
            </>
          ) : null}
          {renderMarkers()}
        </div>
      </div>
    </div>
  );
}

export default CustomProgressBar;