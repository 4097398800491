import { isCyvatarMember } from '../utils/helpers';

const companyIdSelector = (state) => {
  const user = state.auth.user;
  const isSquadMember = isCyvatarMember(user);
  if (!isSquadMember) { return state.auth.company?.realId; }
  return state.impersonation.companyId;
};

export default companyIdSelector;
