import React from 'react';
import SimpleDropdown from '../../layout/SimpleDropdown';
import { grcOverdueFilters } from '../../../constants/issues';

const IssueOverdueFilters = (props) => {
  //  Parsing props
  const {
    filterValue,
    getFilterState,
    setState,
    filterType,
  } = props;

  //  Function to handle dropdown on change
  const onChange = (option) => {
    const params = {
      filterName: 'overdue',
      value: option?.value,
      label: option?.label,
      type: filterType,
    }
    const newFilters = getFilterState(params);
    setState(newFilters);
  };

  //  Rendering
  return (
    <div className="filters-row">
      <div className="filter-label pr-3">Due Date</div>
      <div className="filters-container">
        <SimpleDropdown
          extraClassName="filter-dropdown overdue-filter"
          placeholder="Select state"
          elements={grcOverdueFilters}
          selectedElement={grcOverdueFilters.find(({ value }) => value === filterValue)}
          onChangeCallback={onChange}
          ariaLabel="Filter by Due Date"
        />
      </div>
    </div>
  );
};

export default IssueOverdueFilters;
