import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomTable from '../../CustomTable/CustomTable';
import SearchBar from '../../layout/filters/SearchBar';
import CustomPagination from '../../layout/CustomPagination';
import RecycleBinActionModal from './RecycleBinActionModal';
import RecycleBinBulkMenu from './RecycleBinBulkMenu';
import RecycleBinContextMenu from './RecycleBinContextMenu';
import {
  infiniteTableLimit,
  recycleBinEntities,
  customTableEvents,
  tablePaginationTypes,
} from '../../../constants/common';
import {
  archivedDevicesTemplate,
  archivedIssuesTemplate,
} from '../../../constants/tableTemplates';
import { setIdsToDelete, setIdsToRestore } from '../../../actions/recycleBin';
import { setAlert } from '../../../actions/alerts';
import { parseRecycleBinElements, getSelectedIds } from '../../../utils/recycleBinHelpers';
import companyIdSelector from '../../../selectors/companyIdSelector';
import useRecycleBinApiCall from '../../../hooks/useRecycleBinApiCall';
import useSelectedList from '../../../hooks/useSelectedList';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';

//  Initial filters
const initialFilters = {
  offset: 0,
  sort: [],
  text_to_search: '',
  limit: infiniteTableLimit,
};

const RecycleBinTable = (props) => {
  //  Parsing props
  const { entity = recycleBinEntities.devices } = props;

  //  Component refs
  const tableContainerRef = useRef();

  //  Watching redux store
  const company_id = useSelector((state) => companyIdSelector(state));
  const idsToDelete = useSelector((state) => state?.recycleBin?.idsToDelete);
  const idsToRestore = useSelector((state) => state?.recycleBin?.idsToRestore);

  //  Component state
  const [filters, setFilters] = useState(initialFilters);
  const [elements, setElements] = useState([]);

  //  Component hooks
  const {
    data: recycledElementsData,
    error: recycledElementsError,
    isLoading: recycledElementsLoading,
  } = useRecycleBinApiCall({
    entity,
    params: { ...filters, company_id },
  });

  const {
    selected,
    excluded,
    allSelected,
    eventHandler: handleSelectedEvent,
  } = useSelectedList({
    eventMap: {
      changeAll: customTableEvents.CHANGE_SELECT_ALL,
      add: customTableEvents.SELECT_ELEMENT,
      remove: customTableEvents.UNSELECT_ELEMENT,
    }
  });

  const { dispatch } = useMemoizedDispatch();

  //  Watching API response
  useEffect(() => {
    if (!recycledElementsData) { return; }
    setElements(parseRecycleBinElements(entity, recycledElementsData?.rows || []));
  }, [recycledElementsData]);

  //  Watching API error
  useEffect(() => {
    if (!recycledElementsError) { return; }
    dispatch(setAlert(`Error getting ${entity}`));
  }, [recycledElementsError]);

  //  Watching entity changes to reset filters
  useEffect(() => {
    setFilters(initialFilters);
  }, [entity]);

  //  Function to request more elements
  const requestMore = ({ selected }) => {
    const newOffset = selected * filters?.limit;
    setFilters({ ...filters, offset: newOffset });
  };

  //  Function to change sort
  const changeSort = (key, value) => {
    setFilters({ ...filters, sort: [key, value] });
  };

  //  Function to change filters
  const changeFilters = (key, value) => {
    setFilters({ ...filters, [key]: value });
  }

  //  Function to get column templates
  const getColumnTemplate = () => {
    if (entity === recycleBinEntities.devices) {
      return archivedDevicesTemplate;
    }

    return archivedIssuesTemplate;
  };

  //  Function to close action modals
  const closeActionModals = () => {
    dispatch(setIdsToDelete([]));
    dispatch(setIdsToRestore([]));
  };

  //  Function to render top section
  const renderTableTopBar = () => {
    return (
      <div className="searchable-table-topbar">
        <SearchBar
          useSimpleContainer
          customClassName={`search-box mr-2 mb-2 d-inline-block rule-logs-search-bar`}
          placeholder="Search"
          value={filters?.text_to_search}
          callback={(value) => changeFilters('text_to_search', value)}
        />
        <CustomPagination
          currentPage={(filters.offset / filters.limit)}
          pageCount={Math.ceil((recycledElementsData?.count || 0) / filters?.limit)}
          onPageChange={requestMore}
        />
      </div>
    );
  };

  //  Function to render title
  const renderTitle = () => {
    return (
      <div className="title-container">
        <div className="title">Listing all items</div>
        <div className="count">{`${recycledElementsData?.count || 0} items`}</div>
      </div>
    );
  };

  //  Function to render action modals
  const renderActionModals = () => {
    if (idsToDelete.length === 0 && idsToRestore.length === 0) { return null; }

    return (
      <RecycleBinActionModal
        entity={entity}
        idsToDelete={idsToDelete}
        idsToRestore={idsToRestore}
        closeModal={closeActionModals}
      />
    );
  };

  //  Function to render bulk actions menu
  const renderBulkActionsMenu = () => {
    if (!allSelected && selected.length === 0) { return null; }
    return (
      <RecycleBinBulkMenu
        entity={entity}
        selected={getSelectedIds(allSelected, selected, excluded, elements)}
      />
    );
  }

  //  Rendering
  return (
    <div className="recycle-bin-container">
      {renderTitle()}
      <div className="topbar-container">
        {renderTableTopBar()}
      </div>
      {renderBulkActionsMenu()}
      <div
        className="table-container"
        ref={tableContainerRef}
      >
        <CustomTable
          tableClassName="recycle-bin-table"
          headerClassName="recycle-bin-table-header"
          rowClassName="recycle-bin-table-row"
          columnsTemplate={getColumnTemplate()}
          data={elements || []}
          isScrollable={true}
          requestMore={requestMore}
          changeSort={changeSort}
          sort={filters?.sort || []}
          allSelected={allSelected}
          selected={selected}
          excluded={excluded}
          handleSelectedEvent={handleSelectedEvent}
          loading={recycledElementsLoading}
          emptyConfig={{
            message: `Archived ${entity} will appear here`,
            messageClassName: 'empty-message'
          }}
          paginationType={tablePaginationTypes.PAGES}
        />
      </div>
      {renderActionModals()}
      <RecycleBinContextMenu
        entity={entity}
        parentRef={tableContainerRef}
        selected={getSelectedIds(allSelected, selected, excluded, elements)}
      />
    </div>
  );
};

export default RecycleBinTable;
