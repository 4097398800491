import React, { useEffect, useRef, useState } from 'react';
import AutomationRulesFilters from './AutomationRulesFilters';
import CustomTable from '../CustomTable/CustomTable';
import ActiveFilters from '../layout/ActiveFilters';
import AutomationRulesContextMenu from './AutomationRulesContextMenu';
import DeleteAutomationRulesModal from './DeleteAutomationRulesModal';
import API from '../../api';
import { automationRuleTemplate } from '../../constants/tableTemplates';
import { automationRulesLimit } from '../../constants/automation';
import { customTableEvents } from '../../constants/common';
import { getNextPageParam } from '../../utils/queryHelpers';
import { setAlert } from '../../actions/alerts';
import { requestDeleteRules, onRuleSetsMounted } from '../../actions/automation';
import { parseAutomationRulesList, getUpdatedActiveFilters } from '../../utils/automationHelper';
import useSelectedList from '../../hooks/useSelectedList';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useCustomInfiniteQuery from '../../hooks/useCustomInfiniteQuery';
import { DELETE_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';
import useCanAbility from '../../hooks/useCanAbility';

//  Initial filters
const initialFilters = {
  company_id: null,
  partner_id: null,
  sort: [],
  offset: 0,
  searchTerm: null,
  limit: automationRulesLimit,
};

//  Component
//  TODO: handle filters, offset, limit
const AutomationRules = (props) => {
  //  Initializing APIs
  const { RuleAPI } = API;

  //  Component refs
  const tableContainerRef = useRef(null);

  //  Component state
  const [ruleSets, setRuleSets] = useState([]);
  const [ruleSetsTotal, setRuleSetsTotal] = useState(0);
  const [filters, setFilters] = useState(initialFilters);
  const [activeFilters, setActiveFilters] = useState([]);
  const [requestMore, setRequestMore] = useState(false);
  const [deleteLimit, setDeleteLimit] = useState(null); // Used to request things on delete success

  //  Dispatch hook
  const { dispatch } = useMemoizedDispatch();
  const ability = useCanAbility();

  //  Selected list hook
  const {
    selected,
    excluded,
    allSelected,
    eventHandler: handleSelectedEvent,
  } = useSelectedList({
    eventMap: {
      changeAll: customTableEvents.CHANGE_SELECT_ALL,
      add: customTableEvents.SELECT_ELEMENT,
      remove: customTableEvents.UNSELECT_ELEMENT,
    }
  });

  // Component variables
  const canDeleteRuleSets = ability.can(DELETE_ACTION, PRIVILEGE_SOURCES.AUTOMATION.RULE_SET)

  //  Watching filter changes
  useEffect(() => {
    if (filters.offset === 0) {
      setRuleSets([]);
      handleSelectedEvent(customTableEvents.CHANGE_SELECT_ALL, false);
    }
  }, [filters]);

  //  API Hooks
  const {
    data: pagesData,
    error: ruleSetsError,
    isLoading: ruleSetsLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useCustomInfiniteQuery(
    [RuleAPI.keys.getRuleSets, filters],
    ({ queryKey, pageParam = 0 }) => RuleAPI.handlers.getRuleSets({ ...queryKey[1], offset: pageParam }),
    {
      getNextPageParam: (lastPage, allPages) => getNextPageParam(lastPage, allPages, 'rule_sets', 'total'),
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  //  Component did mount
  useEffect(() => {
    dispatch(onRuleSetsMounted());
  }, []);

  //  Watching request more flag
  useEffect(() => {
    if (!requestMore) { return; }
    setRequestMore(false);
    if (!hasNextPage || ruleSetsLoading || isFetchingNextPage) { return; }
    fetchNextPage();
  }, [requestMore]);

  //  Watching rule sets response
  useEffect(() => {
    if (!pagesData) { return; }
    const { pages = [] } = pagesData;
    let totalElements = 0;
    //  Getting and parsing all elements
    const newElements = pages.reduce((list, currentPage) => {
      const { rule_sets, total = 0 } = currentPage;
      totalElements = total;
      const parsedRows = parseAutomationRulesList(rule_sets);
      return [...list, ...parsedRows];
    }, []);

    //  Setting new rule sets
    setRuleSetsTotal(totalElements);
    setRuleSets(newElements);
    if (deleteLimit !== null) {
      handleSelectedEvent(customTableEvents.CHANGE_SELECT_ALL, false);
      setDeleteLimit(null);
    }
  }, [pagesData]);

  //  Watching rule sets response
  useEffect(() => {
    if (!ruleSetsError) { return; }
    dispatch(setAlert('Error getting rule sets', 'danger'));
  }, [ruleSetsError]);

  //  Function to update active filters
  const updateActiveFilters = (fieldName, value) => {
    const newActiveFilters = getUpdatedActiveFilters(fieldName, value, activeFilters);
    setActiveFilters(newActiveFilters);
  };

  //  Function to change sort
  const changeSort = (fieldName, sorting, addToActive = false) => {
    //  Updating sort
    const newSort = [fieldName, sorting];
    setFilters({ ...filters, offset: 0, sort: newSort });
    if (!addToActive) { updateActiveFilters('sort', null); return; }
    //  Updating active filters
    updateActiveFilters('sort', 'Recently updated');
  };

  //  Function handle elements delete
  //  TODO: delete elements
  const onDeleteElements = () => {
    dispatch(requestDeleteRules({ selected, excluded, allSelected }));
  };

  //  Function to handle scroll
  const requestMoreRules = () => {
    setRequestMore(true);
  };

  //  Rendering
  return (
    <div className="automation-rules">
      <AutomationRulesFilters
        initialFilters={initialFilters}
        filters={filters}
        setFilters={setFilters}
        updateActiveFilters={updateActiveFilters}
        changeSort={changeSort}
      />
      <ActiveFilters
        activeFilters={activeFilters}
        currentFilters={filters}
        initialFilters={initialFilters}
        setFilters={setFilters}
        setActiveFilters={setActiveFilters}
        updateActiveFilters={updateActiveFilters}
        title="Listing all rules"
        totalMessage={`${ruleSetsTotal} Rule${ruleSetsTotal !== 1 ? 's' : ''}`}
      />
      <div
        ref={tableContainerRef}
        className="automation-rules-table-container"
      >
        <CustomTable
          tableClassName="automation-rules-table"
          headerClassName="automation-rules-table-header"
          rowClassName="automation-rules-table-row"
          columnsTemplate={automationRuleTemplate}
          data={ruleSets}
          isScrollable={true}
          requestMore={requestMoreRules}
          changeSort={changeSort}
          sort={filters?.sort || []}
          allSelected={allSelected}
          selected={selected}
          excluded={excluded}
          handleSelectedEvent={handleSelectedEvent}
          loading={ruleSetsLoading || isFetchingNextPage}
          emptyConfig={{
            message: 'The created automation rules will appear here',
            messageClassName: 'empty-message'
          }}
        />
      </div>
      <AutomationRulesContextMenu
        parentRef={tableContainerRef}
        onDeleteElements={onDeleteElements}
        handleSelectedEvent={handleSelectedEvent}
        deleteDisabled={!canDeleteRuleSets}
      />
      <DeleteAutomationRulesModal
        onSuccessCallback={() => setDeleteLimit(filters.offset + automationRulesLimit)}
        filters={filters}
      />
    </div>
  );
};

export default AutomationRules;
