import React from 'react';
import CustomActionModal from '../../layout/CustomActionModal';
import API from "../../../api";
import useCustomMutation from '../../../hooks/useCustomMutation';

const CompanyAttachmentsActionsModal = (props) => {
  //  Parsing props
  const {
    company_id,
    attachments = [],
    showDeleteModal = false,
    toggleDeleteModal,
  } = props;

  //  Initializing API
  const { CompanyAPI } = API;

  // Component Hooks
  const {
    data: deleteData,
    error: deleteError,
    mutate: deleteFile,
    isLoading: deleteLoading,
  } = useCustomMutation(
    (params) => CompanyAPI.handlers.deleteCompanyAttachment(params),
    CompanyAPI.invalidators.deleteCompanyAttachment
  );

  //  Function to delete file
  const onDelete = () => {
    deleteFile(attachments);
    toggleDeleteModal(false);
  };

  const renderDeleteModal = () => {
    if (!showDeleteModal) { return null; }
    return (
      <CustomActionModal
        closeModal={() => toggleDeleteModal(false)}
        title='Delete Files'
        subtitle={`Are you sure you want to PERMANENTLY delete this files?`}
        //disabled={deleteLoading}
        onActionClick={() => onDelete()}
        actionText="Delete"
        actionClassName="btn btn-submit btn-red text-dark font-weight-bold delete-rule-button"
      />
    );
  };

  //  Rendering
  return (
    <>
      {renderDeleteModal()}
    </>
  );
};

export default CompanyAttachmentsActionsModal;