import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import AssessmenteWelcomeContent from '../components/assessment/AssessmentWelcomeContent';
import ErrorMessage from '../components/layout/ErrorMessage';
import CustomSpinner from '../components/layout/CustomSpinner';
import ClarityHeader from '../components/layout/VerticalLayout/headers/ClarityHeader';
import HalfScreenImageContainer from '../components/layout/HalfScreenImageContainer';
import { changeLayout, showTakeAssessmentAction } from '../actions/layout';
import { fetchAnswers, fetchAssessmentExecution } from '../actions/answers';
import { fetchAssessment } from '../actions/assessment';
import makeLoadingState from '../selectors/loadingSelector';
import makeErrorMessage from '../selectors/errorsSelector';
import makeTotalAnswersByCategory from '../selectors/assessment/totalAnswersByCategorySelector';
import BasicAssessmentOverview from '../components/assessment/Basic/BasicAssessmentOverview';
import Register from '../components/auth/Register';
import { trackEvent } from '../utils/gaTracker';
import image from '../assets/images/isometric-images-1.png'
import { setUserCookies } from "../utils/manageCookies";

const AssessmentWelcomePage = (props) => {

    const {
        isAuthenticated,
        changeLayout,
        fetchAssessment,
        fetchAnswers,
        totalAnswers,
        isLoading,
        errorMessage,
        showTakeAssessmentAction,
        assessmentId,
        fetchAssessmentExecution,
        location
    } = props;

    const [isInProgress, setIsInProgress] = useState(null);
    const [answersRequested, setAnswersRequested] = useState(false);


    const onTryAgainButtonClick = () => {
        fetchAssessmentExecution(isAuthenticated)
    }

    const tryAgainButton = (
        <button
            className="btn btn-light"
            onClick={onTryAgainButtonClick}
        >
            Try again
        </button>
    );

    useEffect(() => {
        if (location?.search) setUserCookies(location?.search);
    }, []);

    useEffect(() => {
        changeLayout('horizontal');
        showTakeAssessmentAction(null);
    }, [changeLayout, showTakeAssessmentAction]);

    useEffect(() => {
        setAnswersRequested(false);
        fetchAssessment();
    }, [fetchAssessment, setAnswersRequested]);

    useEffect(() => {
        if (assessmentId) {
            fetchAnswers(assessmentId, isAuthenticated);
            setAnswersRequested(true);
        }
    }, [fetchAnswers, isAuthenticated, assessmentId, setAnswersRequested]);

    useEffect(() => {
        if (isLoading || !answersRequested) {
            return;
        }
        setIsInProgress(totalAnswers.total > 0 ? true : false);
    }, [setIsInProgress, totalAnswers, answersRequested, isLoading]);

    useEffect(() => {
        if (isInProgress !== null) {
            showTakeAssessmentAction(!isInProgress);
        }
    }, [showTakeAssessmentAction, isInProgress])

    const skipNowButton = (
        <a
            style={{ fontWeight: "600", width: "180px" }}
            className="btn btn-outline-light"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
                trackEvent("contact_us", "user", "contact_us");
                window.open(process.env.REACT_APP_CONTACT_SALES_URL, "_blank");
            }}
        >
            Contact Us
        </a>
    );

    return (
        <React.Fragment>
            <Register />
            <ClarityHeader
                logosHeight="50px"
                allowBetaVersion={true}
            />
            {errorMessage ? (
                <Row className="assessment-welcome-error h-minus-header bg-gradient-dark-purple-v2 justify-content-center justify-content-md-end align-content-center p-4">
                    <Col sm={8} md={5} lg={5} className="">
                        <ErrorMessage
                            text={errorMessage}
                            button={tryAgainButton}
                            customStyle={{ paddingTop: "25.5%" }}
                            theme="dark"
                        />
                    </Col>
                    <HalfScreenImageContainer columnNumber={{ lg: 6, md: 6 }} image={image} />
                </Row>
            ) : (
                <React.Fragment>
                    {isLoading || isInProgress === null ? (
                        <div>
                            <CustomSpinner customClassName="h-minus-header" />
                        </div>
                    ) : (
                        <React.Fragment>
                            {isInProgress === false ? (
                                <AssessmenteWelcomeContent
                                    leftColumnNumber={{ md: 5, lg: 5 }}
                                    rightColumnNumber={{ md: 5, lg: 6 }}
                                    customClassName="justify-content-center pl-5 pr-4 px-md-4 pt-1 pb-4 pb-md-5 justify-content-md-end align-items-center flex-grow-1"
                                    skipNowButton={skipNowButton}
                                />
                            ) : (
                                <BasicAssessmentOverview />
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        const getTotalAnswers = makeTotalAnswersByCategory();
        const getLoadingState = makeLoadingState(['ASSESSMENT', 'ANSWERS']);
        const getErrorMessage = makeErrorMessage(['ASSESSMENT', 'ANSWERS']);

        return {
            isAuthenticated: state.auth.isAuthenticated,
            totalAnswers: getTotalAnswers(state),
            isLoading: getLoadingState(state) || state.assessment.isLoading,
            errorMessage: getErrorMessage(state),
            assessmentId: state.assessment.assessment.id
        }
    };
    return mapStateToProps;
};

const mapDispatchToProps = {
    fetchAssessment,
    fetchAnswers,
    changeLayout,
    showTakeAssessmentAction,
    fetchAssessmentExecution
};

export default withRouter(connect(makeMapStateToProps, mapDispatchToProps)(AssessmentWelcomePage));
