import React from 'react';
import { Button } from 'reactstrap';

const NavigationDots = (props) => {
    const {
        currentIndex,
        selectCallback,
        wrapperClassName = "",
        count = 0
    } = props;

    const onDotClick = (index) => {
        if(!selectCallback && typeof(selectCallback) !== 'function') return null;
        selectCallback(index)
    }
    const renderDot = (index) => {
        const selectedClassName = index === currentIndex ? 'selected-dot' : '';
        return (
            <Button
                key={`navigation-dot-${index}`}
                className={`navigation-dot rounded-circle ${selectedClassName}`}
                onClick={() => onDotClick(index)}
            />
        )
    }
    const renderDots = () => {
        const indixesArray = Array.from(Array(count).keys());
        return indixesArray.map((index) => {
            return renderDot(index)
        })
    }

    if(count < 2) return null;
    return (
        <div
            className={`navigation-dots ${wrapperClassName}`}
        >
            {renderDots()}
        </div>
    );
};

export default NavigationDots;
