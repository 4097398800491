import { useEffect, useCallback, useState } from "react";

const useContextMenu = (outerRef, elementType = null, applyOffset = false, xOffset = 0, yOffset=0, scrollableRef) => {
    const [xPos, setXPos] = useState(0);
    const [yPos, setYPos] = useState(0);
    const [xScreenPosition, setXScreenPosition] = useState(0);
    const [yScreenPosition, setYScreenPosition] = useState(0);

    const [showMenu, setShowMenu] = useState(false);
    const [elementId, setElementId] = useState(null);

    const handleContextMenu = useCallback( event => {
        if (outerRef && outerRef.current?.contains(event.target)) {
            event.preventDefault();

            const positionX = event.pageX;
            const positionY = event.pageY;

            setXScreenPosition(positionX);
            setYScreenPosition(positionY);

            if(applyOffset) {
                const { offsetTop, offsetLeft } = outerRef.current
                setXPos(positionX-offsetLeft-xOffset);
                setYPos(positionY-offsetTop-yOffset);
            } else {
                setXPos(positionX);
                setYPos(positionY);
            }

            if(elementType) {
                const target = event.target?.closest(elementType);
                if (target?.id) {
                    setElementId(target.id);
                    setShowMenu(true);
                }
            } else {
                setShowMenu(true);
            }

        } else {
            setShowMenu(false);
            setElementId(null);
        }
    },[showMenu, outerRef, setXPos, setYPos]);

    const handleClick = useCallback(() => {
        setShowMenu(false);
        setElementId(null);
    }, [showMenu]);

    const handleScroll = useCallback(() => {
        setShowMenu(false);
        setElementId(null);
    }, [showMenu]);

    // Component did mount
    useEffect(() => {
        document.addEventListener("click", handleClick);
        document.addEventListener("contextmenu", handleContextMenu);
        if (scrollableRef && scrollableRef.current) {
            scrollableRef.current.addEventListener("scroll", handleScroll, false);
        }

        return () => {
            document.removeEventListener("click", handleClick);
            document.removeEventListener("contextmenu", handleContextMenu);
            if(scrollableRef && scrollableRef.current) {
                scrollableRef.current.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

  return { xPos, yPos, showMenu, elementId, xScreenPosition, yScreenPosition};
};

export default useContextMenu;
