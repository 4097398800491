import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Dashboard from '../components/dashboard/Dashboard';
import CompanyMainProfile from '../components/profile/CompanyMainProfile';
import PersonalInfo from '../components/settings/PersonalInfo';
import Subscriptions from '../components/settings/Subscriptions';
import { getDevicesCount } from '../actions/devices';
import { changeLayout } from '../actions/layout';
import { fetchCustomerProfile } from "../actions/customerManagement";
import { onSidebarTabSelected } from '../actions/navigation';
import useMemoizedDispatch from '../hooks/useMemoizedDispatch';
import companyInfoSelector from '../selectors/companyInfoSelector';
import companyIdSelector from '../selectors/companyIdSelector';
import PageNotFound from './PageNotFound';
import { useRouteMatch, useLocation,Redirect, Route, Switch  } from 'react-router-dom';
import _ from 'lodash';
import MainScrollableContainer from '../components/layout/MainScrollableContainer';
import useCanAbility from '../hooks/useCanAbility';
import { CREATE_ACTION, PRIVILEGE_SOURCES } from '../constants/privileges';
import MemberDomains from '../components/profile/MemberDomains';
import ProfileSettings from '../components/settings/ProfileSettings';
import UserManagement from '../components/profile/userManagement/UserManagement';

const Profile = () => {
  //  Memoized dispatch
  const { dispatch } = useMemoizedDispatch();

  //  Watching redux store
  const userRole = useSelector((state) => state.auth.user?.roles[0]?.name);
  const freemiumStatus = useSelector((state) => companyInfoSelector(state, 'freemium', false));
  const companyId = useSelector((state) => companyIdSelector(state));
  const impersonatedCompanyId = useSelector((state) => state.impersonation.companyId);

  // Component Hooks
  const { path } = useRouteMatch()
  const location = useLocation();
  const ability = useCanAbility();

  // Component variables
  const canImpersonate = ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.MULTIPLE.IMPERSONATE);

  useEffect(() => {
    dispatch(changeLayout('vertical'));
  }, [changeLayout]);

  useEffect(() => {
    dispatch(getDevicesCount(companyId));
  }, [getDevicesCount]);

  useEffect(() => {
    if (companyId) {
      dispatch(onSidebarTabSelected(location?.pathname, userRole, false, freemiumStatus, false, true));
      dispatch(fetchCustomerProfile(companyId));
    } else {
      dispatch(onSidebarTabSelected(location?.pathname, userRole, true, freemiumStatus, false, false));
    }
  }, [companyId]);

  const renderDefaultRedirect = (path, defaultSubroute) => {
    return <Redirect to={`${path}${defaultSubroute}`}/>
  }

  //  Function to render content
  const renderContent = () => {
    const activeImpersonation = !_.isNil(impersonatedCompanyId);
    const memberDefaultSubroute = "/company"
    const cyvatarMemberDefaultRoute = activeImpersonation ? memberDefaultSubroute : "/security-settings"
    const defaultSubroute = canImpersonate ? cyvatarMemberDefaultRoute : memberDefaultSubroute;
    return (
			<MainScrollableContainer>
				<Switch>
            <Route path={`${path}/company/domains`} exact >
              {canImpersonate && !activeImpersonation && renderDefaultRedirect(path, defaultSubroute)}
              <MemberDomains />
					  </Route>
            <Route path={`${path}/company`} exact >
              {canImpersonate && !activeImpersonation && renderDefaultRedirect(path, defaultSubroute)}
              <CompanyMainProfile />
					  </Route>
            <Route path={`${path}/settings`} exact >
              {canImpersonate && activeImpersonation && renderDefaultRedirect(path, defaultSubroute)}
              <ProfileSettings title="Settings" />
            </Route>
            <Route path={`${path}/security-settings`} exact >
              {canImpersonate && activeImpersonation && renderDefaultRedirect(path, defaultSubroute)}
              <PersonalInfo />
					  </Route>
            <Route path={`${path}/notification-settings`} exact >
              {canImpersonate && renderDefaultRedirect(path, defaultSubroute)}
              <Subscriptions title="Opt-in notifications" />
					  </Route>
            <Route path={`${path}/user-management`} exact >
              {canImpersonate && !activeImpersonation && renderDefaultRedirect(path, defaultSubroute)}
              <UserManagement/>
            </Route>
					<Redirect from={'/profile'} to={`${path}${defaultSubroute}`} exact/>
					<Route component={PageNotFound} />
				</Switch>
      </MainScrollableContainer>
		)
  };

  //  Function to get container class name
  const getContainerClassName = () => {
    let className = "dashboard mx-2 px-0 py-4 text-normal-style page-content";
    /*if (!identifiers) { return className; }
    if (selectedIdentifier !== identifiers.COMPANY_PROFILE) { return className; }*/
    return `${className} contained-view-width`;
  };

  //  Rendering
  return (
    <Dashboard
      title="Member Profile"
      containerClassName={getContainerClassName()}
    >
      {renderContent()}
    </Dashboard>
  );
};

export default Profile;
