import {
    COMPLIANCE_SCORE_SUCCESS,
    CLEAR_SCORES,
    ON_JOURNEY_PATH_CHANGE_SUCCESS,
    LOGOUT_SUCCESS,
} from '../actions/types';

const initialState = {
    complianceScore: {}
}

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case COMPLIANCE_SCORE_SUCCESS: {
            return {
                ...state,
                complianceScore: payload,
                isLoading: false,
            };
        }
        case CLEAR_SCORES:
        case LOGOUT_SUCCESS:
            return initialState;
        case ON_JOURNEY_PATH_CHANGE_SUCCESS:
            const { solutions, opportunities, grow } = payload;
            return {
                ...state,
                complianceScore: {
                    ...state.complianceScore,
                    journey_path: solutions,
                    opportunities,
                    grow: grow || state.complianceScore.grow,
                },
            };
        default:
            return state;
    }
}
