import { Ability, AbilityBuilder } from '@casl/ability';
import store from '../store/index';

function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item;
  }
  return item.__type;
}

const ability = new Ability([], { subjectName });

let currentAuth;
let currentCheckup;
store.subscribe(() => {
  const prevAuth = currentAuth;
  const prevCheckup = currentCheckup;
  currentAuth = store.getState().auth.user;
  const rules = getRules(currentAuth.roles);
  // TODO: nasty fix for adding read pofile permission when checkup finished
  currentCheckup = store.getState().answers.showReportCard;
  if (currentCheckup) {
    rules.push({ action: 'READ', subject: 'PROFILE' });
  }
  // end nasty fix
  if (prevAuth !== currentAuth || prevCheckup !== currentCheckup) {
    ability.update(defineRulesFor(rules));
  }
});

function getRules(roles) {
  let rules = [];
  const isRolesNotEmpty = (roles && roles.length !== 0);
  if (isRolesNotEmpty) {
    roles.forEach(role => {
      role.privileges.forEach(privilege => {
        const splitted = privilege.name.split('::');
        const action = splitted[0];
        const subject = splitted.slice(1).join('::');
        rules.push({ action, subject });
      });
    });
  }
  return rules;
}

function defineRulesFor(userRules) {
  const { can, rules } = AbilityBuilder.extract();
  userRules.forEach(rule => {
    can(rule.action, rule.subject);
  });
  return rules;
}

export default ability;
