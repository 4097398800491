import { useEffect, useState, createRef } from 'react';

const useStickyGroupHeight = (props) => {
  //  Parsing hook props
  const { elementsIds = [] } = props;

  //  Hook state
  const [refs, setRefs] = useState([]);

  //  Watching divsIds changes
  useEffect(() => {
    if (!Array.isArray(elementsIds)) { return; }
    //  Building refs
    const newRefs = elementsIds.map((div, index) => {
      return refs[index] || createRef();
    });
    setRefs(newRefs);
  }, [elementsIds?.length]);

  //  Function to get height of the sticky elements above the received index
  const getCurrentAccumulatedHeight = (endingIndex = 0, offset = 0) => {
    const accumulatedHeight = refs.reduce((acc, ref, index) => {
      if (index >= endingIndex) { return acc; }
      return acc + (ref?.current?.clientHeight || 0);
    }, 0);
    return `${accumulatedHeight + offset}px`;
  };

  //  Returning assets
  return { refs, getCurrentAccumulatedHeight };
};

export default useStickyGroupHeight;
