import React, { useEffect } from 'react';
import { Row} from 'reactstrap';
import _ from 'lodash';
import { connect } from 'react-redux';
import IssueDemoPreviewImage from '../../assets/images/issue-manager-preview.png'
import { trackPage } from "../../utils/gaTracker";

const IssueDemoPreview = (props) => {

  useEffect(() => {
    trackPage('issue-demo-preview');
  }, []);
  
  return (
    <Row>
      <div>
          <img
              className="img-fluid"
              src={IssueDemoPreviewImage}
              alt={'Issue table preview'}
          />
      </div>
    </Row>
  )
}

const mapStateToProps = (state) => {
  return {}
}

const dispatchMapToProps = {}
export default connect(mapStateToProps, dispatchMapToProps)(IssueDemoPreview);