import React, { useState } from 'react';
import BasicModal from '../layout/BasicModal';
import { Button } from 'reactstrap';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import DevicesTags from './DevicesTags';
import { parseTaggingsList } from '../../utils/tagsHelper';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import { successActionCreator } from '../../actions/action-creators/success';
import { RELOAD_DEVICES_LIST } from '../../actions/types';

const DevicesTagsModal = (props) => {

    const {
        showModal,
        toggleModal,
        device
    } = props;

    //Component Hooks
    const { dispatch } = useMemoizedDispatch();

    // Component State
    const [reloadDevicesList, setReloadDevicesList] = useState(false);

    const closeModal = () => {
        if(reloadDevicesList) {
            dispatch(successActionCreator(RELOAD_DEVICES_LIST,true))
            setReloadDevicesList(false)
        }
        toggleModal()
    }

    const renderFooter = () => {
        return (
            <ModalFooter>
                <div className="text-center" >
                    <Button
                        className={`btn btn-light text-dark font-weight-bold }`}
                        type="submit"
                        onClick={closeModal}
                    >
                        {'Done'}
                    </Button>
                </div>
            </ModalFooter>
        )
    }

    return (
        <BasicModal
            header={device?.name}
            ipAddress={device?.ip_address}
            headerClassName={`modal-header-status`}
            title={
                <div className="d-inline-block d-md-flex align-items-center">
                </div>
            }
            showModal={showModal}
            toggleModal={closeModal}
            customClassName="device-tags-modal"
            scrollable={true}
        >
            <DevicesTags
                deviceId={device?.id}
                companyId={device?.company_id}
                tagsList={parseTaggingsList(device?.taggings)}
                setReloadDevicesList={(value) => setReloadDevicesList(value)}
            />
            {renderFooter()}
        </BasicModal>
    )

}

export default DevicesTagsModal;
