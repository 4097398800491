import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Badge } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import makeLoadingState from '../../selectors/loadingSelector';
import makeErrorMessage from '../../selectors/errorsSelector';
import CydekickInvite from './CydekickInvite';
import Avatar from '../layout/avatars/Avatar';
import TableList from '../layout/TableList';
import { clearCydekicksFilters, fetchCydekicks, reloadCydekicks, setCydekicksFilter, setCydekicksSort } from '../../actions/cydekicks';
import CyvatarTeamActions from './CyvatarTeamActions';
import { CREATE_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';
import useCanAbility from '../../hooks/useCanAbility';
let CydekickList = (props) => {

    const {
        isLoading,
        errorMessage,
        cydekicks,
        fetchCydekicks,
        reloadCydekicks,
        setCydekicksFilter,
        clearCydekicksFilters,
        setCydekicksSort
    } = props;

    // Component state
    const [showCydekickInviteModal, setShowCydekickInviteModal] = useState(false);

    // Component hooks
    const ability = useCanAbility();

    const renderNameComponent = (row) => {
        return (
            <React.Fragment>
                {row?.full_name && (
                    <Avatar
                        key={`cydekick-name-${row?.id}`}
                        id={`user-tooltip-${row.full_name}`}
                        user={row}
                    />
                )}
                {row?.full_name}
            </React.Fragment>
        )
    }

    const renderRoleComponent = (row) => {
        return (
            <Badge
                color={row['roles.description']?.toLowerCase() || 'light'}
            >
                {row['roles.description']}
            </Badge>
        )
    }

    // Functions to render team member's actions: enable/disable account, remove account, change role
    const renderCyvatarTeamActions = (row) => {
        return (
            <CyvatarTeamActions
                teamMemberData={row}
                reloadCallback={reloadCydekicks}
            />
        )

    }

    // Functions to render team member's account status
    const renderStatusComponent = (row) => {
        return row?.active ? 'active' : 'disabled';
    }

    const columns = [
        {
            displayName: 'Cydekick name',
            fieldName: 'full_name',
            sortFieldName: 'full_name',
            headerClassNameWrapper: 'name-header',
            renderCustomComponent: renderNameComponent
        },
        {
            displayName: 'Email',
            fieldName: 'email',
            sortFieldName: 'email',
            headerClassNameWrapper: 'email-header',
        },
        {
            displayName: 'Role',
            fieldName: 'roles.description',
            sortFieldName: 'roles.description',
            classNameWrapper: 'text-center',
            headerClassNameWrapper: 'role-header d-flex justify-content-center',
            renderCustomComponent: renderRoleComponent
        },
        {
            displayName: 'Status',
            fieldName: 'active',
            sortFieldName: 'active',
            renderCustomComponent: renderStatusComponent
        },
        {
            displayName: 'Assigned issues',
            fieldName: 'assigned_issues',
            sortFieldName: 'assigned_issues'
        },
        {   displayName: 'Completed issues',
            fieldName: 'completed_issues',
            sortFieldName: 'completed_issues'
        },
        {
            displayName: 'Actions',
            fieldName: null,
            sortFieldName: null,
            renderCustomComponent: renderCyvatarTeamActions
        }
    ];

    const togModal = () => {
        setShowCydekickInviteModal(!showCydekickInviteModal);
    };

    const renderInviteButton = () => {
        const canInviteSquadMembers = ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.CYVATAR_TEAM.USER_INVITE);
        if(!canInviteSquadMembers) return null;
        return (
            <div className="text-sm-right">
                <Button
                    type="button"
                    color="light"
                    className="btn waves-effect waves-light ml-0 ml-md-2 mb-2"
                    onClick={togModal}>
                    <i className='bx bx-plus-circle mr-2 align-middle'></i>
                    Add new user
                </Button>
            </div>
        )
    }

    return (
        <React.Fragment>
            <CydekickInvite
                toggleModal={togModal}
                showModal={showCydekickInviteModal}
            />
            <TableList
                fetchList={fetchCydekicks}
                reloadList={reloadCydekicks}
                data={cydekicks}
                columns={columns}
                customClassName="team-manager-list"
                renderButton={renderInviteButton}
                setFilter={setCydekicksFilter}
                clearAllFilters={clearCydekicksFilters}
                setSort={setCydekicksSort}
                activeFiltersDisplayInfo={{
                    display: true,
                    labels:{
                        noFilters: 'Listing all users',
                        filteringBy: 'Listing Users filtered by:',
                        total: {
                            singular: 'user',
                            plural:'users'
                        }
                    }
                }}
                isLoading={isLoading}
                errorMessage={errorMessage}
                searchPlaceholder="Search by name"
            />
        </React.Fragment>
    );
};

const makeMapStateToProps = () => {
	const getLoadingState = makeLoadingState(['CYDEKICKS']);
    const getErrorMessage = makeErrorMessage(['CYDEKICKS']);
    const mapStateToProps = (state) => {
      return {
		isLoading: getLoadingState(state),
        errorMessage: getErrorMessage(state),
        cydekicks: state.cydekicks
      }
    }
    return mapStateToProps;
}
const mapDispatchToProps = {
    fetchCydekicks,
    reloadCydekicks,
    setCydekicksSort,
    setCydekicksFilter,
    clearCydekicksFilters
};

export default withRouter(connect(
    makeMapStateToProps,
    mapDispatchToProps
)(CydekickList));

