import { getSafeArray } from "./helpers";

export const mapDeviceStatusToRollUpData = (deviceStatus = {}) => {
  const rollUpData = [];

  for (const [key, value] of Object.entries(deviceStatus)) {
    const { label, hidden, total } = value;
    if (!hidden) {
      rollUpData.push({
        label,
        value: parseInt(total),
        id: key,
      });
    }
  }

  return rollUpData;
};

export const getRollUpTotal = (rollUpData = []) => {
  const safeData = getSafeArray(rollUpData);
  return safeData.reduce((total, element) => {
    return total + (element?.value || 0);
  }, 0);
};
