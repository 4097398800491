import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams, Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import CustomSpinner from '../components/layout/CustomSpinner';
import ErrorMessage from '../components/layout/ErrorMessage';
import FormCard from '../components/layout/FormCard';
import UpdatePasswordForm from '../components/auth/forms/UpdatePasswordForm';
import SendResetPasswordTokenForm from '../components/auth/forms/SendResetPasswordTokenForm';
import { trackEvent } from '../utils/gaTracker';
import logo from '../assets/images/logos/cyvatar-beta-logo.svg';
import isometricImage from '../assets/images/isometric-images-2.png'
import userEvent from '@testing-library/user-event';
import { validateResetPasswordToken } from '../actions/auth';
import { changeLayout } from '../actions/layout';
import makeErrorMessage from '../selectors/errorsSelector';
import makeLoadingState from '../selectors/loadingSelector';

 const ResetPassword = (props) => {
    const {
          changeLayout,
          errorMessage,
          isValid,
          isLoading,
          validateResetPasswordToken,
          emailSent
    } = props;

    const { token } = useParams();

    useEffect(()=>{
        changeLayout('horizontal');
    },[changeLayout]);

    useEffect(()=>{
        if(token) {
            validateResetPasswordToken(token);
        }
    },[token, validateResetPasswordToken]);

    const onTryAgainButtonClick = () => {
        if(token) {
            validateResetPasswordToken(token);
        }
    }

    const tryAgainButton = (
        <button
            className="btn btn-light"
            onClick={onTryAgainButtonClick}
        >
            Try again
        </button>
    );

    const resetPasswordLinks = (
        <div className="d-inline-flex flex-wrap reset-password-links">
            <Link className="btn btn-light text-dark" to="/reset-password">Re-send the link</Link>
            <Link className="btn btn-outline-light" to="/reset-password">Back to login</Link>
        </div>
    );

    return (
        <div>
            { token ? (
                <div className="update-password bg-gradient-dark-purple-v3 h-minus-header vw-100">
                    { errorMessage  ? (
                        <div className="invalid-link-screen">
                            <ErrorMessage
                                text={errorMessage}
                                button={tryAgainButton}
                                customStyle={{ paddingTop: "4%"}}
                                theme="dark"
                            />
                        </div>
                    ):(
                        <React.Fragment>
                            { isLoading ? (
                                <CustomSpinner
                                    customStyle={{ minHeight: "330px"}}
                                />
                            ):(
                                <React.Fragment>
                                    { isValid ? (
                                        <UpdatePasswordForm token={token}/>
                                    ):(
                                        <div className="invalid-link-screen">
                                            <ErrorMessage
                                                text="This link is no longer valid, Do you want to try again?"
                                                button={resetPasswordLinks}
                                            />
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </div>
            ):(
                <div className="vh-100 w-100">
                    <Row className="justify-content-around align-items-start align-items-md-center form-card-container flex-grow-1 h-md-100 p-4">
                        <Col
                            className="isometric-images-2-container "
                            md={5}
                            sm={10}
                        >
                            <Row
                                className="justify-content-center"
                            >
                                <img
                                    src={logo}
                                    alt="cyvatar"
                                    className="img-fluid"
                                    style={{ height:"42px", marginBottom:"31px"}}
                                />
                            </Row>
                            <Row className="justify-content-end d-none d-lg-block">
                                <img
                                    src={isometricImage} alt=""
                                    className="img-fluid"
                                    style={{ maxHeight:"501px"}}
                                />
                            </Row>
                        </Col>
                        <Col
                            md={12}
                            lg={4}
                        >
                            <Row className="justify-content-center align-items-start align-items-sm-center align-items-lg-start flex-grow-1 h-100">
                                <FormCard
                                    title="Reset Password"
                                    customClassName={`reset-password-card ${`feedback ${emailSent ? 'email-sent': '' }`}`}
                                >
                                    <SendResetPasswordTokenForm token={token}/>
                                </FormCard>
                            </Row>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
}


const makeMapStateToProps = () => {
	const getLoadingState = makeLoadingState(['VALIDATE_RESET_PASSWORD_TOKEN']);
    const getErrorMessage = makeErrorMessage(['VALIDATE_RESET_PASSWORD_TOKEN']);
    const mapStateToProps = (state) => {
        const { isValid, emailSent } = state.auth.resetPassword;
        return {
            isLoading: getLoadingState(state),
            errorMessage: getErrorMessage(state),
            isValid,
            emailSent
        }
    }
    return mapStateToProps;
}

const mapDispatchToProps = {
    changeLayout,
    validateResetPasswordToken
};

export default withRouter(connect(makeMapStateToProps, mapDispatchToProps)(ResetPassword));

