import React, { useEffect } from 'react';
import { Row, Col } from "reactstrap";
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { disableMFARequest } from "../actions/auth";

import { changeLayout } from '../actions/layout';

// import images
import logo from "../assets/images/logos/cyvatar-beta-logo.svg";
import loginImage from "../assets/images/isometric-images-2.png";
import ConfirmPassword from '../components/auth/ConfirmPassword';
import SMSCard from "../components/settings/SMSCard";


const MFASMS = (props) => {
  const {
    changeLayout,
    isValid,
    // mfaConfig,
    // error,
    history,
    location,
    disableMFARequest
  } = props;

  useEffect(() => {
    changeLayout('vertical');
  }, [changeLayout]);

  function handleDisable() {
    disableMFARequest(history, location);
    return <Redirect to="/settings" />
  }

  return (
    <div className="bg-gradient-dark-purple-v3 h-minus-header vw-100">
      <Row className="align-items-start align-items-md-center">
        <Col
          className="isometric-images-2-container d-none d-lg-block"
          lg={4}
        >
          <Row className="justify-content-center">
            <img
              src={logo}
              alt="cyvatar"
              className="img-fluid"
              style={{ height: "42px", marginBottom: "31px" }}
            />
          </Row>
          <Row className="justify-content-end">
            <img
              src={loginImage} alt=""
              className="img-fluid"
              style={{ maxHeight: "501px" }}
            />
          </Row>
        </Col>
        <Col lg={6} md={12}>
          <Row className="justify-content-center">
            {isValid && location?.state?.disabled && handleDisable()}
            {isValid ? <SMSCard /> : <ConfirmPassword disableGradient={true} />}
          </Row>
        </Col>
      </Row>
    </div>
  );
}


const makeMapStateToProps = () => {
  const mapStateToProps = (state) => {
    return {
      mfaConfig: state.auth.mfaConfig,
      isValid: state.auth.mfaConfig.isValid
    }
  }
  return mapStateToProps;
}

const mapDispatchToProps = {
  changeLayout,
  disableMFARequest
};

export default withRouter(connect(makeMapStateToProps, mapDispatchToProps)(MFASMS));

