import React from "react";
import { Button } from "reactstrap";
import { connectionQuickActionsConfig, CONNECTION_DELETE_ACTION } from "../../constants/common";
import { getSafeArray } from "../../utils/helpers";
import BasicModal from "../layout/BasicModal";

const ConnectionBulkActionModal = (props) => {
  const {
    showModal,
    toggleModal,
    actionName,
    elementsCount = 0,
    confirmCallback,
    confirmDisabled,
  } = props;

  // Component variables
  const actionConfig = connectionQuickActionsConfig[actionName];

  // Get confirmation message
  const getMessage = () => {
    const countLabel = elementsCount ? ` (${elementsCount})` : "";
    return (
        <>
          <div className="text-center">{`${actionConfig?.bulkMessage}`}</div>
          <div className="text-center">{`for selected connections ${countLabel}?`}</div>
        </>
      );
  };

  // Get confirmation button label
  const getConfirmLabel = () => {
    const array = getSafeArray(actionConfig?.bulkMessage?.split(" "));
    const actionLabel = array?.length > 0 ? array[0] : "";
    return `Yes${actionLabel ? `, ${actionLabel}` : ""}`;
  };

  // Get confirm button color
  const getConfirmButtonColor = () => {
    return actionConfig?.actionColorClassName || "light";
  };

  // Get subtitle message
  const getSubtitleMessage = () => {
    const isDeleteAction = actionName === CONNECTION_DELETE_ACTION;
    return isDeleteAction ? `This action can't be undone` : '';
  };

  // Const get modal title
  return (
    <BasicModal
      showModal={showModal}
      toggleModal={toggleModal}
      title={getMessage()}
      subtitle={getSubtitleMessage()}
      customClassName="connections-bulk-actions-modal"
    >
      <div className="row-container">
        <Button
          color="light-gray mr-3"
          className="btn-large mr-3"
          outline
          onClick={() => toggleModal()}
        >
          Cancel
        </Button>
        <Button
          className="btn-large text-dark"
          onClick={confirmCallback}
          disabled={confirmDisabled}
          color={getConfirmButtonColor()}
        >
          {getConfirmLabel()}
        </Button>
      </div>
    </BasicModal>
  );
};

export default ConnectionBulkActionModal;
