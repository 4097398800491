import { Button, Card, CardBody } from 'reactstrap'
import React from 'react';
import { trackEvent } from '../../../utils/gaTracker';

const InformationCard = (props) => {
    const {
        title,
        content,
        buttonLabel,
        buttonCallback,
        className="",
        customLink,
        eventName,
        eventSection
    } = props;


    //  Function to render content
    const renderContent = () => {
        return content.map((item, index) => {
            return (
                <div key={`information-card-content-${index}`} className="information-card-message mb-3">{item}</div>
            )
        })
    };

    const onButtonClick = () => {
        const event = eventName || "information-card";
        trackEvent(event, eventSection, event);
        window.open(customLink || process.env.REACT_APP_CONTACT_SALES_URL);
    }

    const renderButton = () => {
        return (
            <Button
                color="light"
                onClick={buttonCallback || onButtonClick}
            >
                {buttonLabel}
            </Button>
        )

    }
    return (
        <Card className={`information-card ${className}`}>
            <CardBody>
                <div className="information-card-title mb-3">{title}</div>
                {renderContent()}
                {renderButton()}
            </CardBody>
        </Card>
    )
}

export default InformationCard;
