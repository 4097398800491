import React from 'react';
import { Col, Row } from 'reactstrap';
import { formatDate, getDaysBetween } from '../../../../../utils/helpers';
import IcarmSolutionProgressBar from './IcarmSolutionProgressBar';

const IcarmSolutionsCyvalueProgress = (props) => {

  const {
    solutionName,
    icarmDataSteps,
    hasSurpassedGoal,
    isFreemium,
    icarmSolutions = [],
    hasSolutionData,
    lastNumberDays
  } = props;

  const getFormattedDate = (date) => {
    return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
  }

  const renderProgressBars = () => {
    const sortedSolutions = icarmSolutions.sort((a, b) => a?.name.localeCompare(b?.name));
    return sortedSolutions?.map((solution) => {

      const daysRemainingUntilGuarantee = getDaysBetween(new Date(), new Date( solution?.projection_end_date));
      const installationDate = new Date(solution?.installation_date);
      const daysSoFar = getDaysBetween(installationDate, new Date());
      const cyvalueReachedDays = getDaysBetween(installationDate,new Date(solution?.cyvalue_reached_date));

      const progress = (((90 - daysRemainingUntilGuarantee) < 2 ? 2 : (90 - daysRemainingUntilGuarantee))/ 90) * 100;
      const formattedInstallationDate = formatDate(installationDate);

      let cyvalueDate = solution?.cyvalue_reached_date !== null ? solution?.cyvalue_reached_date : solution?.projection_end_date;
      cyvalueDate = formatDate(new Date(cyvalueDate));

      return (
        <Col lg={6} md={6}>
          <IcarmSolutionProgressBar
            solution={solution}
            progress={progress}
            formattedInstallationDate={formattedInstallationDate}
            daysRemainingUntilGuarantee={daysRemainingUntilGuarantee}
            daysSoFar={daysSoFar}
            cyvalueReachedDays={cyvalueReachedDays && solution?.cyvalue_reached_date ? cyvalueReachedDays : 0}
            cyvalueDate={cyvalueDate}
            lastNumberDays={lastNumberDays}
          />
        </Col>
      );
    });
  };

  return (
    <Row className={`solution-progress-bars-container ${icarmSolutions?.length < 2 ? 'justified' : '' }`}>
      {renderProgressBars()}
    </Row>
  )
}

export default IcarmSolutionsCyvalueProgress;