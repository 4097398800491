import React, { useEffect } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { useSelector } from 'react-redux';
import { setAlert } from '../../../actions/alerts';
import { successActionCreator } from "../../../actions/action-creators/success";
import { ON_PARTNER_MANUAL_ACTION_FINISH } from "../../../actions/types";
import { MANUAL_ACTIONS_COMPONENTS, MANUAL_ACTIONS_TARGETS } from '../../../constants/common';

import API from '../../../api';
import partnerActionsSelector from '../../../selectors/partnerActionsSelector';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import useCustomMutation from '../../../hooks/useCustomMutation';
import SetupDropdown from '../../common/SetupDropdown';

const GroupPartnerActions = (props) => {
  //  Parsing props
  const { group, group_id, elementId = 'group-actions-action' } = props;
  const partnerActions = useSelector(state => partnerActionsSelector(state, group.partner_id));

  //  Initializing APIs
  const { PartnerAPI } = API;

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();

  const {
    data: actionResponse,
    error: actionError,
    mutate: sendPartnerAction,
  } = useCustomMutation(
    (params) => PartnerAPI.handlers.callPartnerAction(params),
    PartnerAPI.invalidators.callPartnerAction
  );

  // Action execution response
  useEffect(() => {
    if (!actionResponse) { return; }
    dispatch(setAlert("Action executed on partner.", "success"));
    dispatch(successActionCreator(ON_PARTNER_MANUAL_ACTION_FINISH, true));
  }, [actionResponse]);

  // Action execution error 
  useEffect(() => {
    if (!actionError) { return; }
    dispatch(setAlert("Error executing action on partner.", "danger"));
    dispatch(successActionCreator(ON_PARTNER_MANUAL_ACTION_FINISH, true));
  }, [actionError]);

  //  Function to call partner action
  const callPartnerAction = (action, option = null) => {
    // Preparing data for API call
    const data = {
      target: MANUAL_ACTIONS_TARGETS.GROUP,
      partner_id: group.partner_id,
      params: {
        action,
        option,
        groups: [group_id],
      }
    }
    sendPartnerAction({ data });
  };

  const getOptions = () => {
    const actions = []
    const actionsToDisplay = partnerActions?.issues;
    if (actionsToDisplay) {
      actionsToDisplay.forEach(action => {
        const { key, name, type, options } = action;
        if (type === MANUAL_ACTIONS_COMPONENTS.DROPDOWN) {
          options.forEach(option => {
            actions.push({
              label: option,
              isSelected: false,
              isDisabled: false,
              icon: null,
              callback: () => callPartnerAction(key, option)
            });
          })
        } else if (type === MANUAL_ACTIONS_COMPONENTS.BUTTON) {
          actions.push({
            label: name,
            isSelected: false,
            isDisabled: false,
            icon: null,
            callback: () => callPartnerAction(key)
          })
        }
      });
    }

    return actions;
  }

  //  Rendering empty state
  if (!partnerActions?.issues || partnerActions?.issues?.length === 0) { return null; }

  //  Rendering
  return (
    <>
      <UncontrolledTooltip
        className={`custom-basic-tooltip`}
        arrowClassName={`custom-basic-tooltip-arrow`}
        placement={'top'}
        target={elementId}
      >
        Trigger Actions
      </UncontrolledTooltip>
      <SetupDropdown
        id={elementId}
        customIcon='bx bx-play-circle'
        buttonClassName='action-icon'
        options={getOptions()}
      />
    </>
  );
};

export default GroupPartnerActions;
