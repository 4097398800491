import { useEffect } from 'react';
import API from '../api';
import { setAlert } from '../actions/alerts';
import useMemoizedDispatch from './useMemoizedDispatch';
import useCustomQuery from './useCustomQuery';
import { PARTNERS_MANUAL_ACTION_LIST } from '../actions/types'
import { successActionCreator } from '../actions/action-creators/success';

const usePartnerManualActions = () => {
  //  Initializing API
  const { PartnerAPI } = API;

  //  Hooks
  const { dispatch } = useMemoizedDispatch();

  //  Making query
  const {
    data: manualActions,
    error: manualActionsError,
  } = useCustomQuery(
    [PartnerAPI.keys.getPartnerManualActions],
    () => PartnerAPI.handlers.getPartnerManualActions(),
  );

  //  Watching API response
  useEffect(() => {
    if (!manualActions) { return; }
    dispatch(successActionCreator(PARTNERS_MANUAL_ACTION_LIST, manualActions));
  }, [manualActions]);

  //  Watching api errors
  useEffect(() => {
    if (!manualActionsError) return; 
    
    dispatch(setAlert('Error getting partners manual actions', 'danger'));
  }, [manualActionsError]);

  //  Exporting actions
  return {
    manual_actions: manualActions,
  };
};

export default usePartnerManualActions;
