import React from 'react';
import { useSelector } from 'react-redux';
import { issueTypes } from '../../../constants/issues';
import issuesInfoSelector from '../../../selectors/issuesInfoSelector';
import ContextMenuDropdown from '../../context-menu/ContextMenuDropdown';
import { getStatusFilterByIssuesType } from '../../../utils/issuesHelpers'

const IssuesStatusMenu = (props) => {

    const {
        onStatusSelected,
        issueType = issueTypes.VULNERABILITIES,
        isDisabled,
    } = props;

    //  Getting needed info from redux store
    const statuses = useSelector(state => issuesInfoSelector(state,'modalOptions', issueType)?.statuses);

    const onOptionSelected = (statusId, statusLabel) => {
        onStatusSelected(statusId, statusLabel)
    }

    const isValidStatus = (element) =>{
        return element?.label !== 'Re Opened' && getStatusFilterByIssuesType(element,issueType);
    }

    //gets Status options for menu
    const getOptions = () => {
        if(!statuses) return;
        return statuses?.filter(isValidStatus).map((status)=> {
            return {
                label: status?.label,
                callback: () => onOptionSelected(status?.id, status?.label),
                isDisabled
            }
        })
    }
    return (
        <ContextMenuDropdown
            label="Move to..."
            className="rounded-top"
            options={getOptions()}
            isDisabled={isDisabled}
        />
    )
}

export default IssuesStatusMenu
