import { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import API from '../api';
import { tagsPageSize } from '../constants/tags';
import { getNextPageParam, parseTagsInfiniteResponse } from '../utils/queryHelpers';
import useCustomInfiniteQuery from './useCustomInfiniteQuery';

//  Initial filters
const initialFilters = {
  limit: tagsPageSize,
  searchText: '',
  sort: ['name', 'ASC'],
}
//  Hook
const useTagsQuery = (params) => {
  //  Parsing params
  const {
    company_id,
    device_id,
    issue_id,
    textToSearch,
    isCydekick = false,
  } = params;

  //  Initializing API
  const { TagAPI } = API;

  //  Query client
  const queryClient = useQueryClient();

  //  Hook state
  const [filters, setFilters] = useState({ ...initialFilters, device_id, issue_id });
  const [tags, setTags] = useState([]);
  const [requestMore, setRequestMore] = useState(false);

  //  Calling API for tags
  const {
    data,
    error,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useCustomInfiniteQuery(
    [TagAPI.keys.getTagsList, { filters, company_id }],
    ({ queryKey, pageParam = 0 }) => TagAPI.handlers.getTagsList({ ...queryKey[1], filters: { ...queryKey[1]?.filters, offset: pageParam } }),
    {
      getNextPageParam: (lastPage, allPages) => getNextPageParam(lastPage, allPages, 'rows'),
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  //  Watching text to search to trigger changes
  useEffect(() => {
    setFilters({ ...filters, searchText: textToSearch });
  }, [textToSearch]);

  //  Watching request more changes to fetch new page
  useEffect(() => {
    if (!requestMore) { return; }
    setRequestMore(false);
    if (!hasNextPage || (isLoading || isFetchingNextPage)) { return; }
    fetchNextPage();
  }, [requestMore]);

  //  Watching API response
  useEffect(() => {
    if (!data) { return; }
    const { pages = [] } = data;

    const newElements = pages.reduce((list, currentPage) => {
      const { rows = [] } = currentPage;
      const parsedRows = parseTagsInfiniteResponse(rows, company_id, isCydekick);
      return [...list, ...parsedRows];
    }, []);

    setTags(newElements);
  }, [data]);

  //  Function to get next page
  const getNextPage = () => {
    setRequestMore(true);
  };

  //  Function to clear query data
  const clearQueryData = () => {
    setFilters({ ...filters, ...initialFilters });
    queryClient.invalidateQueries(TagAPI.keys.getTagsList);
  }

  //  Hook assets
  return {
    tags,
    error,
    loading: isLoading || isFetchingNextPage,
    getNextPage,
    clearQueryData,
  }
};

export default useTagsQuery;
