import React from 'react';
import AutomationStatusCheckbox from '../components/automation/AutomationStatusCheckbox';
import AutomationExecutionReport from '../components/automation/AutomationExecutionReport';
import AutomationActionsCell from '../components/automation/AutomationActionsCell';
import AutomationTagsCell from '../components/automation/AutomationTagsCell';
import LogResultCell from '../components/automation/Logs/LogResultCell';
import AutomationScheduleCell from '../components/automation/AutomationScheduleCell';
import RecycleBinActionsCell from '../components/app-v4/recycle-bin/RecycleBinActionsCell';
import CriticalityCell from '../components/CustomTable/TableCells/CriticalityCell';
import CustomTextCell from '../components/CustomTable/TableCells/CustomTextCell';
import GroupsActionsCell from '../components/app-v4/groups/GroupsActionsCell';
import IssueExploitable from '../components/issue-manager/IssueExploitable';
import IssueCodeCopy from '../components/issue-manager/IssueCodeCopy';
import { formatDate } from '../utils/helpers';
import PartnersIconList from '../components/devices/PartnersIconList';
import BusinessContext from '../components/devices/BusinessContext';


//  Table header types
export const headerTypes = {
  TEXT: 'TEXT',
  SUBHEADER: 'SUBHEADER',
};

//  Table cell types
export const cellTypes = {
  SELECTABLE: 'SELECTABLE',
  TEXT: 'TEXT',
  TOGGLE: 'TOGGLE',
  TAGS: 'TAGS',
  ACTIONS: 'ACTIONS',
  CUSTOM: 'CUSTOM',
  SUBTABLE: 'SUBTABLE',
  SELECTABLE_TOOLTIP: 'SELECTABLE_TOOLTIP',
  SELECTABLE_CUSTOM: 'SELECTABLE_CUSTOM',
};

//  Sub template for actions section on automation rules table
export const actionsAutomationRulesSubtemplate = [
  {
    label: 'Action',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: ' header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell',
    entry: 'name',
  },
  {
    label: 'Scheduled for',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: ' header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell',
    entry: 'schedule',
    customRender: (params) => <AutomationScheduleCell {...params} />, // Function to call if cell is custom
    customParams: { objectName: 'rule', valueName: 'status' }
  },
  {
    label: 'Status',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: ' header-cell header-text-cell small-cell',
    cellClassName: 'toggle-cell small-cell',
    entry: 'status',
    customRender: (params) => <AutomationStatusCheckbox {...params} />, // Function to call if cell is custom
    customParams: { objectName: 'rule', valueName: 'status' }
  },
  {
    label: 'Execution report',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: ' header-cell large-cell',
    cellClassName: 'execution-report-cell large-cell',
    entry: 'errors_count',
    customRender: (params) => <AutomationExecutionReport {...params} />, // Function to call if cell is custom
    customParams: { objectName: 'rule', valueName: 'errors_count' }
  },
];

//  Sub template for partners section on automation rules table
export const partnersAutomationRulesSubtemplate = [
  {
    label: 'Partner',
    allowSorting: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell small-cell',
    cellClassName: 'regular-cell small-cell',
    entry: 'name',
  },
  {
    label: '',
    allowSorting: false,
    headerType: headerTypes.SUBHEADER,
    cellType: cellTypes.SUBTABLE,
    headerClassName: 'header-cell header-text-cell',
    cellClassName: 'action-row',
    containerClassName: 'action-subtable',
    subtemplate: actionsAutomationRulesSubtemplate,
    entry: 'rules',
  },
];

//  Template for automation rules table
export const automationRuleTemplate = [
  {
    label: 'Rule name',
    allowSorting: true,
    allowSelect: true,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.SELECTABLE,
    headerClassName: 'header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell bottom-padded-cell',
    entry: 'name' // Name of the attribute to extract from objects
  },
  {
    label: 'If tagged',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: 'header-cell header-text-cell medium-cell',
    cellClassName: 'tags-cell medium-cell',
    entry: 'tags_searchable',
    customRender: (params) => <AutomationTagsCell {...params} />, // Function to call if cell is custom
    customParams: { objectName: 'ruleSet', valueName: 'tags' }
  },
  {
    label: 'Members',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell bottom-padded-cell scrollable-cell',
    entry: 'members',
  },
  {
    label: '',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.SUBHEADER,
    cellType: cellTypes.SUBTABLE,
    headerClassName: '',
    cellClassName: 'partner-row',
    containerClassName: 'partner-subtable',
    subtemplate: partnersAutomationRulesSubtemplate,
    entry: 'partners'
  },
  {
    label: 'Actions',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: 'header-cell medium-cell left-padded-header',
    cellClassName: 'automation-actions-cell',
    customRender: (params) => <AutomationActionsCell {...params} />, // Function to call if cell is custom
    customParams: { objectName: 'ruleSet', valueName: 'rule' }
  },
];

//  Template for rule logs table
export const ruleLogsTemplate = [
  {
    label: 'Execution date',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell',
    entry: 'start_time'
  },
  /* {
    label: 'Devices affected',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell medium-cell',
    cellClassName: 'regular-cell medium-cell padded-cell',
    entry: 'devices_count'
  },*/
  {
    label: 'Result',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: 'header-cell header-text-cell small-cell',
    cellClassName: 'regular-cell small-cell padded-cell',
    entry: 'status',
    customRender: (params) => <LogResultCell {...params} />, // Function to call if cell is custom
    customParams: { objectName: 'ruleLog', valueName: 'result' }
  }
];

//  Template for log messages table
export const logMessageTemplate = [
  {
    label: 'Timestamp',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell medium-cell',
    cellClassName: 'regular-cell medium-cell',
    entry: 'timestamp'
  },
  {
    label: 'Message',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell extra-large-cell padded-header',
    cellClassName: 'regular-cell extra-large-cell message-cell',
    entry: 'message'
  },
];

//  Template for archived devices
export const archivedDevicesTemplate = [
  {
    label: 'Name',
    allowSorting: false,
    allowSelect: true,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.SELECTABLE,
    headerClassName: 'header-cell header-text-cell extra-large-cell',
    cellClassName: 'regular-cell extra-large-cell bottom-padded-cell ellipsed-cell',
    entry: 'name',
  },
  {
    label: 'IP Address',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell extra-large-cell',
    cellClassName: 'regular-cell extra-large-cell bottom-padded-cell ellipsed-cell',
    entry: 'ip_address',
  },
  {
    label: 'MAC Address',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell extra-large-cell',
    cellClassName: 'regular-cell extra-large-cell bottom-padded-cell ellipsed-cell',
    entry: 'mac_address',
  },
  {
    label: 'Company',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell bottom-padded-cell',
    entry: 'company',
  },
  {
    label: 'Erased on',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell bottom-padded-cell',
    entry: 'deleted_at',
  },
  {
    label: 'Erased by',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell bottom-padded-cell',
    entry: 'deleter',
  },
  {
    label: 'Actions',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: 'header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell bottom-padded-cell',
    entry: 'id',
    customRender: (params) => <RecycleBinActionsCell {...params} />, // Function to call if cell is custom
    customParams: { objectName: 'element', valueName: 'element_id' }
  },
];

//  Template for archived issues
export const archivedIssuesTemplate = [
  {
    label: 'Issue ID',
    allowSorting: false,
    allowSelect: true,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.SELECTABLE,
    headerClassName: 'header-cell header-text-cell extra-large-cell',
    cellClassName: 'regular-cell extra-large-cell bottom-padded-cell',
    entry: 'code',
  },
  {
    label: 'Name',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell extra-large-cell',
    cellClassName: 'regular-cell extra-large-cell bottom-padded-cell ellipsed-cell',
    entry: 'name',
  },
  {
    label: 'Criticality',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: 'header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell bottom-padded-cell criticality-cell',
    entry: 'severity',
    customRender: (params) => <CriticalityCell {...params} />, // Function to call if cell is custom
    customParams: { objectName: 'issue', valueName: 'criticality' }
  },
  {
    label: 'Company',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell bottom-padded-cell',
    entry: 'company',
  },
  {
    label: 'Erased on',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell bottom-padded-cell',
    entry: 'deleted_at',
  },
  {
    label: 'Erased by',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell bottom-padded-cell',
    entry: 'deleter',
  },
  {
    label: 'Actions',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: 'header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell bottom-padded-cell',
    entry: 'id',
    customRender: (params) => <RecycleBinActionsCell {...params} />, // Function to call if cell is custom
    customParams: { objectName: 'element', valueName: 'element_id' }
  },
];

export const groupsTableTemplate = [
  {
    label: 'ID',
    allowSorting: true,
    allowSelect: true,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.SELECTABLE_CUSTOM,
    headerClassName: 'header-cell header-text-cell id-cell',
    cellClassName: 'regular-cell id-cell bottom-padded-cell ellipsed-cell',
    entry: 'issue_external_id',
    customRender: ({ issue_external_id, group }) => {
      return (
        <IssueCodeCopy
          issue={group}
          code={issue_external_id}
          itemLabel="Group ID"
          className="group-id-table-cell"
          showTooltip={true}
        />
      )
    },
    customParams: { objectName: 'group', valueName: 'issue_external_id' }
  },
  {
    label: "Exp.",
    allowSorting: true,
    entry: "exploit_available",
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: 'header-cell header-text-cell exp-cell',
    cellClassName: 'regular-cell exp-cell bottom-padded-cell top-padded-cell',
    customRender: ({ group, exploit_available }) => {
      return (
        <IssueExploitable
          isExploitable={exploit_available}
          targetId={`exploitable-issue-${group?.id}`}
        />
      );
    }, // Function to call if cell is custom
    customParams: { objectName: 'group', valueName: 'exploit_available' }
  },
  {
    label: 'Issue name',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell name-cell collapsable-cell',
    cellClassName: 'regular-cell name-cell bottom-padded-cell collapsable-cell',
    entry: 'name',
  },
  {
    label: 'Affected',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: 'header-cell header-text-cell large-cell affected-header',
    cellClassName: 'regular-cell large-cell bottom-padded-cell affected-column',
    entry: 'affected_companies',
    customRender: (params) => <CustomTextCell {...params} suffix=' Members' />, // Function to call if cell is custom
    customParams: { objectName: 'group', valueName: 'value' }
  },
  {
    label: 'Criticality',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: 'header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell bottom-padded-cell criticality-cell',
    entry: 'severity',
    customRender: (params) => <CriticalityCell {...params} />, // Function to call if cell is custom
    customParams: { objectName: 'issue', valueName: 'criticality' }
  },
  {
    label: 'Open',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: 'header-cell header-text-cell large-cell ',
    cellClassName: 'regular-cell large-cell bottom-padded-cell open-column',
    entry: 'open_issues',
    customRender: (params) => <CustomTextCell {...params} suffix=' issues' />, // Function to call if cell is custom
    customParams: { objectName: 'group', valueName: 'value' }
  },
  {
    label: 'Closed',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: 'header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell bottom-padded-cell close-column',
    entry: 'closed_issues',
    customRender: (params) => <CustomTextCell {...params} suffix=' issues' />, // Function to call if cell is custom
    customParams: { objectName: 'group', valueName: 'value' }
  },
  {
    label: 'Published',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: 'header-cell header-text-cell large-cell published-header',
    cellClassName: 'regular-cell large-cell bottom-padded-cell',
    entry: 'publication_date',
    customRender: (params) => <CustomTextCell {...params} />, // Function to call if cell is custom
    customParams: { objectName: 'group', valueName: 'value' }
  }, // TODO: add word suffix
  {
    label: 'Solution',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell large-cell solution-header',
    cellClassName: 'regular-cell large-cell bottom-padded-cell solution-column',
    entry: 'solution_name',
  },
  {
    label: 'Partner',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell partner-cell partner-header',
    cellClassName: 'regular-cell partner-cell bottom-padded-cell',
    entry: 'partner_name',
  },
  {
    label: 'Actions',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: 'header-cell header-text-cell actions-cell',
    cellClassName: 'regular-cell actions-cell bottom-padded-cell',
    entry: 'id',
    customRender: (params) => <GroupsActionsCell {...params} />, // Function to call if cell is custom
    customParams: { objectName: 'group', valueName: 'group_id' }
  },
]

export const devicesTableTemplate = [
  {
    label: '',
    allowSorting: false,
    allowSelect: true,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.SELECTABLE_CUSTOM,
    cellClassName: 'td-ellipsis',
    entry: 'id',
    headerContentClassName: 'header-cell',
    customCellCallback: true
  },
  {
    label: 'Device name',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    cellClassName: 'td-ellipsis regular-cell td-nt-name',
    entry: 'name',
    headerContentClassName: 'header-cell'
  },
  {
    label: "Site name",
    allowSorting: true,
    entry: "site_name",
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerContentClassName: 'header-cell',
    cellClassName: 'regular-cell td-site-name td-ellipsis',
  },
  {
    label: 'MAC address',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerContentClassName: 'header-cell',
    cellClassName: 'regular-cell td-mac-address td-ellipsis',
    entry: 'mac_address',
  },
  {
    label: 'IP address',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerContentClassName: 'header-cell',
    cellClassName: 'regular-cell td-ip-address td-ellipsis',
    entry: 'ip_address',
  },
  {
    label: 'Solution',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerContentClassName: 'header-cell',
    cellClassName: 'regular-cell',
    entry: 'solution',
    customRender: ({ device, id }) => (
      <PartnersIconList
        wrapperClassName="partners-list-cell"
        partners={device.device_partners}
        id={`partners-icon-list-${id}`}
        previewLength={3}
      />
    ), // Function to call if cell is custom
    customParams: { objectName: 'device', valueName: 'id' }
  },
  {
    label: 'NT domain',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerContentClassName: 'header-cell',
    cellClassName: 'regular-cell td-nt-domain td-ellipsis',
    entry: 'domain',
  },
  {
    label: 'OS type',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerContentClassName: 'header-cell',
    cellClassName: 'regular-cell',
    entry: 'os_type',
  },
  {
    label: 'Last Seen',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerContentClassName: 'header-cell',
    cellClassName: 'regular-cell',
    entry: 'last_seen',
    customParams: { valueName: 'last_seen' },
    customRender: ({ last_seen }) => <>{last_seen ? formatDate(last_seen) : '-'}</>
  },
  {
    label: 'Business Tags',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerContentClassName: 'header-cell',
    cellClassName: 'regular-cell td-ellipsis',
    // entry: 'partner_name',
    customParams: { objectName: 'device' },
    customCellCallback: true,
    customRender: ({ device, handleCellAction }) => (
      <BusinessContext
        device={device}
        openDeviceTagsModal={handleCellAction}
      />
    )
  },
]