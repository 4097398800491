import React from 'react';
import { Button } from 'reactstrap'
import { getFormattedDate, isValidFunction } from '../../utils/helpers';
import CriticalityBadge from '../dashboard/CriticalityBadge';
import IssueStatusBage from '../issue-manager/IssueStatusBage';

const IssuesSubtaskButton = (props) => {
  const {
    code,
    name,
    status,
    assignee,
    createdAt,
    criticality,
    callback,
  } = props;

  const onClick = () => {
    if(isValidFunction(callback)) callback();
  }

  return (
    <Button
      outline
      color="light-gray"
      key={`subtask-${code}`}
      className="btn btn-issue-subtask"
      onClick={onClick}
    >
      <i className='issue-subtask-icon bx bx-list-ol'/>
      <span className='issue-subtask-name'>
        {`${code} - ${name}`}
      </span>
      <span className="issue-subtask-assignee">
        <span className='subtask-assignee-label'>Assignee</span>
        {` ${assignee?.full_name || 'N/A'}`}
      </span>
      <div className='ml-auto d-flex justify-content-end'>
        <CriticalityBadge className="ml-3" severity={criticality}/>
        <span className='issue-subtask-creation-date'>
          {`Creation date: ${createdAt ? getFormattedDate(createdAt, false) : ''}`}
        </span>
        <IssueStatusBage className="ml-3" status={status}/>
      </div>
    </Button>
  )
}

export default IssuesSubtaskButton
