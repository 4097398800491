import React from "react";
import { Card, CardBody, Button, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import { ReactComponent as ComingSoonIcon } from "../../../../assets/images/icons/coming-soon-sign.svg";
import LoadingContent from "../../../layout/LoadingContent";

const AppLauncherWidget = (props) => {
  const {
    buttonMessage = "",
    widgetData,
    icon,
    comingSoonColorClass = "",
    buttonOnClick,
    linkPath = "",
    comingSoon = false,
    comingSoonMessageType = 'light',
    buttonComponent,
    error,
    loading,
    refetch,
    wrapperClassName = "",
  } = props;

  // Component Hooks
  const history = useHistory();

  const getTitle = () => {
    return widgetData?.title;
  };

  const getMessage = () => {
    return widgetData?.message;
  };

  const buttonElement = comingSoon ? null : buttonComponent ? (
    buttonComponent
  ) : (
    <Button
      type="button"
      color="light"
      className="float-right applauncher-btn"
      onClick={() => {
        if (buttonOnClick) buttonOnClick();
        if (linkPath) history.push(linkPath);
      }}
    >
      {buttonMessage}
    </Button>
  );

  return (
    <div className={`widget-card-container ${wrapperClassName}`}>
      <div className="applauncher-widget-container h-100">
        <Col
          sm={12}
          lg={12}
          className="align-self-start align-self-lg-stretch mb-2"
        >
          <Card className="h-100 mb-3 mb-md-0">
            <CardBody className="d-flex flex-column">
              <div className="row applauncher-widget-header">
                <div className="col-lg-10 col-sm-10">
                  <span className="title d-inline-flex align-items-center">
                    {getTitle()}
                  </span>
                </div>
                {comingSoon ? (
                  <div className="col-lg-2 col-sm-2 icon-container">
                    <ComingSoonIcon
                      className={`coming-soon-icon ${comingSoonColorClass}`}
                    />
                    <span className={`message-color-${comingSoonMessageType}`}>Coming Soon</span>
                  </div>
                ) : null}
              </div>
              <LoadingContent
                isLoading={loading}
                errorMessage={
                  error ? "Oops! our fault, reload again." : null
                }
                iconType="solidIcon"
                errorStyle={{ minHeight: "50px", paddingTop: "0px" }}
                loadingStyle={{ minHeight: "50px", paddingTop: "0px" }}
                errorButtonCallback={refetch}
              >
                <div className="applauncher-widget-content-container">
                  <div className="applauncher-widget-icon">
                    <img src={icon} alt="icon" />
                  </div>
                  <div className="applauncher-widget-content col-sm-8 col-lg-8">
                    <span className='applauncher-widget-message'>
                      {getMessage()}
                    </span>
                  </div>
                </div>
                <div className="applauncher-widget-content-container">
                  <div className="applauncher-widget-placeholder"/>
                  <div className="applauncher-btn-container col-sm-8 col-lg-8">
                    {buttonElement}
                  </div>
                </div>
              </LoadingContent>
            </CardBody>
          </Card>
        </Col>
      </div>
    </div>
  );
};

export default AppLauncherWidget;
