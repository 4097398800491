import React from 'react'
import { actual_date } from './vcyso-utils/utils';
import hands from '../../assets/images/trust-builder-icon.svg';
import cyvatar_icon from '../../assets/images/cyvatar.svg';

const TrustBuilderHeader = (props) => {
  //  Parsing props
  const { companyName } = props;

  //  Rendering
  return (
    <div className='trust-builder-title d-flex flex-wrap pt-3'>
      <div className='d-inline-block px-3' >
        <img
          src={hands}
          alt="Trust builder Icon"
          width="73px"
          className="img-fluid"
        />
      </div>
      <div className="trust-builder-label text-white d-inline-block">
        <div>
          <p className='mb-2 font-weight-bolder'>{`${companyName} Cybersecurity Confidence `}</p>
        </div>
        <div className="flex flex-wrap">
          <div className='d-inline-block pr-3'>
            <p className='font-weight-bold'>Provided By</p>
          </div>
          <div className='d-inline-block'>
            <img
              src={cyvatar_icon}
              alt="Cyvatar logo"
              width="135px"
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      <div className='ml-auto px-3' >
        <p className='text-white font-weight-bolder align-rigth' >{actual_date}</p>
      </div>
    </div>
  )
}

export default TrustBuilderHeader;