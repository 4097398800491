import cyvatarAxios from '../axios';
import { GET_MFA_COUNTRY_CODES } from '../ApiKeys';

//  Requests
const postUpdateNotificationsSettings = async (formValues) => {
  const body = JSON.stringify(formValues);
  const response = await cyvatarAxios.post('api/user/notifications', body);
  return response?.data;
};

const getMFACountryCodes = async () => {
  const response = await cyvatarAxios.get('/api/settings/options/mfa-codes');
  const { data } = response;
  return data;
}

//  Building API object
export const SettingsAPI = {
  handlers: {
    postUpdateNotificationsSettings,
    getMFACountryCodes,
  },
  keys: {
    getMFACountryCodes: GET_MFA_COUNTRY_CODES,
  },
  invalidators: {
    postUpdateNotificationsSettings: null,
  },
};
