import React from "react";
import projectProgressComingSoon from "../../../../assets/images/preview-images/project-progress-coming-soon.svg";
import { Card, CardBody, Col } from "reactstrap";
import InfoTooltip from "../../../dashboard/tooltips/InfoTooltip";

const ProjectProgressComingSoonWidget = () => {
  return (
    <Col
      key={`coming-soon-widget`}
      sm={12}
      lg={12}
      className={`h-100 align-self-start align-self-lg-stretch mb-2 coming-soon-column`}
    >
      <Card className="h-100 mb-3 mb-md-0">
        <CardBody className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <InfoTooltip
              label={"Project Progress"}
              wrapperClassName="dashboard-widget-title"
              showTooltip={false}
            />
          </div>
          <div className="project-progress-preview-container">
            <img
              className="project-progress-preview"
              src={projectProgressComingSoon}
              alt={"project progress coming soon preview"}
            />
            <div className="chart-coming-soon-state-background">
              <div className="coming-soon-content">
                <div className="coming-soon-subtitle text-center">
                  Check the progress of every of your solutions
                </div>
                <div className="coming-soon-title text-center">Coming soon</div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ProjectProgressComingSoonWidget;
