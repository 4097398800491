import _ from 'lodash';
import { createSelector } from 'reselect';

const getSelectedCategoryId = state => state.assessment.selectedCategoryId;
const getSelectedSectionId = state => state.assessment.selectedSectionId;
const getSelectedQuestionId = state => state.assessment.selectedQuestionId;
const getCategories = state => _.mapKeys(state.assessment.assessment.categories,'id');
const getAnswers = state => state.answers.questionAnswers

const makeSelectedQuestion = () => {
  return createSelector(
    [
      getSelectedCategoryId,
      getSelectedSectionId, 
      getSelectedQuestionId,
      getCategories,
      getAnswers
    ],
    (categoryId, sectionId, questionId, categories, answers) => {
      if(categories[categoryId]){
        const section = _.find(categories[categoryId].sections, ['id',sectionId]);
        if(section){
          const question = _.find(section.questions, ['id',questionId]);
          if(question) {
            return {
              ...question,
              isAnswered: answers[question.id] ? true : false
            }
          }
        }
      }
      return null
    }
  )
}
export default makeSelectedQuestion;