import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { successActionCreator } from '../../actions/action-creators/success';
import { setAlert } from '../../actions/alerts';
import { RELOAD_DEVICES_LIST } from '../../actions/types';
import API from '../../api';
import { alertTypes } from '../../constants/alerts';
import errorMessages from '../../constants/errorMessages';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import companyIdSelector from '../../selectors/companyIdSelector';
import AddRemoveTagsModal from '../tags/AddRemoveTagsModal'
import useCustomMutation from '../../hooks/useCustomMutation';
import useCanAbility from '../../hooks/useCanAbility';
import { MODIFY_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';

const DevicesAddRemoveTagsModal = (props) => {
    const {
        showModal,
        toggleModal,
        selectedDevices
    } = props;

    //  Initializing APIs
    const { DeviceAPI } = API;

    // Component Hooks
    const { dispatch } = useMemoizedDispatch();
    const ability = useCanAbility();

    // Geting needed info from redux store
    const companyId = useSelector((state) => companyIdSelector(state));

    // Component variables
    const canModifyDevices = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.DEVICES.GENERAL);

    const getDevicesCountLabel = (count) => {
        return `${count} device${parseInt(count) === 1 ? '' : 's'}`
    }

    //  Add tags to several devices
    const {
        data: addDeviceTagBulkResponse,
        error: addDeviceTagBulkError,
        isLoading: loadingAddDeviceTagBulk,
        mutate: addDeviceTagBulk,
    } = useCustomMutation(
        (params) => DeviceAPI.handlers.addDeviceTagBulk(params),
        DeviceAPI.invalidators.addDeviceTagBulk
    );

    //  Remove tags from several devices
    const {
        data: removeDeviceTagBulkResponse,
        error: removeDeviceTagBulkError,
        isLoading: loadingRemoveDeviceTagBulk,
        mutate: removeDeviceTagBulk,
    } = useCustomMutation(
        (params) => DeviceAPI.handlers.removeDeviceTagBulk(params),
        DeviceAPI.invalidators.removeDeviceTagBulk
    );

    // Watch add tag response
    useEffect(() => {
        if (!addDeviceTagBulkResponse) return;
        dispatch(setAlert('Tags added', alertTypes.success));
        toggleModal(false);
        dispatch(successActionCreator(RELOAD_DEVICES_LIST, true));
    }, [addDeviceTagBulkResponse])

    // Watch remove tag response
    useEffect(() => {
        if (!removeDeviceTagBulkResponse) return;
        dispatch(setAlert('Tags removed', alertTypes.success));
        toggleModal(false);
        dispatch(successActionCreator(RELOAD_DEVICES_LIST, true));
    }, [removeDeviceTagBulkResponse])

    // Watch add or remove error response
    useEffect(() => {
        if (!addDeviceTagBulkError && !removeDeviceTagBulkError) return;
        const errorMessage = addDeviceTagBulkError?.data?.errorMessage || removeDeviceTagBulkError?.data?.errorMessage || errorMessages.defaultPost;
        dispatch(setAlert(errorMessage, alertTypes.error));
    }, [addDeviceTagBulkError, removeDeviceTagBulkError])

    const getTitle = () => {
        const count = selectedDevices?.length || 0
        return `Bulk Tag (${getDevicesCountLabel(count)} selected)`
    }

    const addTagsCallback = (tags) => {
        const tagsTitlesArray = tags.map(tag => tag?.label)
        addDeviceTagBulk({ device_ids: selectedDevices, titles: tagsTitlesArray })
    }

    const removeTagsCallback = (tags) => {
        const tagsIdsArray = tags.map(tag => tag?.value)
        removeDeviceTagBulk({ device_ids: selectedDevices, tag_ids: tagsIdsArray })
    }

    return (
        <AddRemoveTagsModal
            title={getTitle()}
            showModal={showModal}
            toggleModal={toggleModal}
            addTagsCallback={addTagsCallback}
            removeTagsCallback={removeTagsCallback}
            processing={loadingAddDeviceTagBulk || loadingRemoveDeviceTagBulk}
            companyId={companyId}
            isDisabled={!canModifyDevices}
        />
    )
}

export default DevicesAddRemoveTagsModal
