import { GET_USER_NOTIFICATIONS } from '../ApiKeys';
import cyvatarAxios from '../axios';

const getUserNotifications = async (offset, limit) => {
    const response = await cyvatarAxios.get('/api/user/notifications', {
        params: {
            offset,
            limit
        }
    })
    return response.data;
}

const readNotification = (notificationId) => {
    return cyvatarAxios.put('/api/user/read-notification', {
        id: notificationId
    })
}

export const NotificationAPI = {
    handlers: {
        getUserNotifications,
        readNotification
    },
    keys: {
        getUserNotifications: GET_USER_NOTIFICATIONS
    },
    invalidators: {
        readNotification: null
    }
}