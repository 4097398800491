import React , { useState, useEffect  } from 'react'
import SetupDropdown from '../common/SetupDropdown';
import BasicTooltip from '../dashboard/tooltips/BasicTooltip';
import API from '../../api';
import useCustomMutation from '../../hooks/useCustomMutation';
import { alertTypes } from '../../constants/alerts';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import { setAlert } from '../../actions/alerts';
import BasicModal from '../layout/BasicModal';
import { Button } from 'reactstrap';
import { getQuickActionSuccessMessage } from '../../utils/connectionStatusHelpers';
import { CONNECTION_DELETE_ACTION, CONNECTION_FORCE_SYNC_ACTION } from '../../constants/common';
import errorMessages from '../../constants/errorMessages';


const ConnectionQuickActions = (props) => {
  const {
    connectionId,
    partnerName,
    companyName,
    isActive = true,
    handleSuccessfulAction
  } = props;

  const {PartnerAPI} = API;

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationModalParams, setConfirmationModalParams] = useState(null);
  const [selectedActionName, setSelectedActionName] = useState('');

  // Component Hooks
  const { dispatch } = useMemoizedDispatch();

  const {
    data: executeQuickActionResponse,
    error: executeQuickActionError,
    mutate: executeQuickAction,
    isLoading: executeQuickActionLoading,
  } = useCustomMutation(
    (params) => PartnerAPI.handlers.executeQuickAction(params),
    PartnerAPI.invalidators.executeQuickAction,
  );

  // Watching error to set alert
  useEffect(() => {
    if(!executeQuickActionError) return;
    const errorMessage = executeQuickActionError?.response?.data?.errorMessage || errorMessages.defaultPost;
    dispatch(setAlert(errorMessage, alertTypes.error));
  },[executeQuickActionError]);

  useEffect(() => {
    if (!executeQuickActionResponse) {return;};
    if (handleSuccessfulAction) handleSuccessfulAction();
    const message = getQuickActionSuccessMessage(selectedActionName, 1);
    dispatch(setAlert(message, alertTypes.success));
  },[executeQuickActionResponse]);

  //Toggles the confirmation modal
  const toggleShowConfirmationModal = () => {
    setShowConfirmationModal( (currentState) => {
      return !currentState;
    });
  };

  // Set the modal's params
  const handleConfirmationModal = (modalParams, actionName) => {
    setConfirmationModalParams(modalParams);
    toggleShowConfirmationModal();
    setSelectedActionName(actionName)
  }

  const confirmButtonExecution = (body) => {
    executeQuickAction(body);
    toggleShowConfirmationModal();
  };

  //Actions when there's a confirmation
  const forceSyncConfirmation = () => {
    const body = {
      connections: [connectionId],
      action: CONNECTION_FORCE_SYNC_ACTION,
    }
    confirmButtonExecution(body);
  };

  const deleteConfirmation = () => {
    const body = {
      connections: [connectionId],
      action: CONNECTION_DELETE_ACTION,
    }
    confirmButtonExecution(body);
  };

  //Params for the data to display on the modal depending of the option clicked on the dropdown
  const modalParams = [
    {
      title:`Force Sync for ${partnerName}`,
      subtitle: '',
      confirmLabel:'Yes, Force',
      cancelCallback: toggleShowConfirmationModal,
      confirmCallback: forceSyncConfirmation,
      disabledAction: false,
      buttonColor: 'light'
    },
    {
      title:`Delete ${partnerName} Configuration for ${companyName}`,
      subtitle: 'This action can’t be undone',
      confirmLabel:'Yes, Delete',
      cancelCallback: toggleShowConfirmationModal,
      confirmCallback: deleteConfirmation,
      disabledAction: false,
      buttonColor : 'critical'
    },
  ]

  //Dropdown Options
  const getForceSyncOption = () => {
    return [
      {
        label: 'Force Sync',
        callback: () => handleConfirmationModal(modalParams[0], CONNECTION_FORCE_SYNC_ACTION),
        isSelected: false,
        isDisabled: !isActive,
        icon: 'bx bx-loader',
      }
    ];
  };

  const getDeleteConfigOption = () => {
    return [
      {
        label: 'Delete Configuration',
        callback: () => handleConfirmationModal(modalParams[1], CONNECTION_DELETE_ACTION),
        isSelected: false,
        isDisabled: false,
        icon: 'bx bx-trash',
      }
    ];
  };

  const getOptions = () => {
    return [
      ...getForceSyncOption(),
      ...getDeleteConfigOption(),
    ];
  };

  const renderQuickActionIcon = () => {
    return (
     <div className='quick-actions-icon-container'>
      <SetupDropdown
        options={getOptions()}
        customIcon = 'bx bx-bolt-circle bx-xs'
        id = {`actions-tooltip-${connectionId}`}
      />
      <BasicTooltip
        target={`actions-tooltip-${connectionId}`}
        placement='bottom'
        content={'Quick Actions'}
      />
     </div>
    )
  }

  const renderConfirmationModal = () => {
    if (!showConfirmationModal) return null;
    const {title, subtitle, confirmLabel, cancelCallback, confirmCallback, disabledAction, buttonColor} = confirmationModalParams;
    return (
      <BasicModal
        showModal={true}
        toggleModal={cancelCallback}
        title={title}
        subtitle={subtitle}
        customClassName='quick-actions-confirmation-modal'
      >
        <div className="row-container quick-actions-confirmation-buttons">
          <Button
            color="light-gray left-modal-button"
            outline
            onClick={cancelCallback}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-submit text-dark font-weight-bold"
            onClick={confirmCallback}
            disabled={executeQuickActionLoading || disabledAction}
            color={buttonColor}
          >
            {confirmLabel}
          </Button>
        </div>
      </BasicModal>
    )
  }

  return (
    <div className={'quick-actions-container'}>
      {renderConfirmationModal()}
      {renderQuickActionIcon()}
    </div>
  )
}

export default ConnectionQuickActions;
