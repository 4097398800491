import moment from 'moment-timezone';
import { RRule } from 'rrule';
import {
  replaceItemOnArray,
  removeItemFromArray,
} from './helpers';
import { timezoneAbbreviations } from './dateFormatter';
import { ruleSetsStatus } from '../constants/automation';

//  Function to group rules by partner
const groupRulesByPartner = (rules = []) => {
  const groupedRules = rules.reduce((groupedRules, currentRule) => {
    //  Parsing params
    const { id, status, schedule = '', action, partner = {}, repeat } = currentRule;
    const { name } = partner;
    if (!name) { return groupedRules; }
    //  Building new rule
    const newRule = {
      id,
      status,
      repeat: repeat || '',
      schedule: schedule || '',
      name: action?.name || '',
      errors_count: 0, //  TODO: get error count per rule
    };
    //  Looking for an existing partner on array
    const matchingPartnerIndex = groupedRules.findIndex((rule) => rule.name === name);
    const matchingPartner = matchingPartnerIndex !== -1 ? groupedRules[matchingPartnerIndex] : null;
    //  Building and adding new payload
    const basePayload = matchingPartner ? { ...matchingPartner } : { name, rules: [] };
    const newPayload = { ...basePayload, rules: [...basePayload.rules, newRule] };
    //  Returning modified grouped rules
    if (matchingPartner) {
      const newGroupedRules = replaceItemOnArray(groupedRules, matchingPartnerIndex, newPayload);
      return newGroupedRules;
    }
    //  Returning grouped rules with new payload added
    return [...groupedRules, newPayload];
  }, []);

  return groupedRules;
};

//  Function to get string value of the companies of a rule set
const getRuleSetCompanies = (all_members = false, companies = []) => {
  let companiesArray = [];
  if (companies.length > 0) {
    companiesArray = companies.map((company) => company.name);
  } else {
    companiesArray = all_members ? ['All members with solution installed'] : [];
  }
  return companiesArray.join(', ');
};

const parseRuleSet = (ruleSet) => {
  //  Getting needed information
  const {
    id,
    name = '',
    tags = [],
    tags_searchable = [],
    companies = [],
    all_members = false,
    rules = [],
  } = ruleSet;

  //  Converting to needed structures
  const members = getRuleSetCompanies(all_members, companies);
  const partners = groupRulesByPartner(rules);

  //  Returned parsed rule set
  return {
    id,
    name,
    tags: tags || [],
    tags_searchable: tags_searchable || [],
    members,
    partners,
  }
}

export const parseAutomationRulesList = (rule_sets = []) => {
  return rule_sets.map((currentRuleSet) => parseRuleSet(currentRuleSet));
};

export const getUpdatedActiveFilters = (fieldName, value, activeFilters = []) => {
  const shouldRemoveActiveFilter = !value;
  const index = activeFilters.findIndex((filter) => filter.fieldName === fieldName);
  if (index === -1) {
    if (shouldRemoveActiveFilter) { return activeFilters; }
    return [...activeFilters, { fieldName, value }];
  }

  const newElement = { ...activeFilters[index], value };

  const newActiveFilters = shouldRemoveActiveFilter ?
    removeItemFromArray(activeFilters, index) :
    replaceItemOnArray(activeFilters, index, newElement);

  return newActiveFilters;
};

export const buildLogsTextRow = (labels = [], callbacks = []) => {
  return labels.reduce((row, currentLabel, currentIndex) => {
    const callback = callbacks[currentIndex];
    const newItem = callback ? { label: currentLabel, callback } : { label: currentLabel };
    return [...row, newItem];
  }, []);
};

export const mapPartnerToOption = (partnerToMap) => {
  if (!partnerToMap) { return null; }
  const { id, name, action_rules } = partnerToMap;
  return { label: name, value: id, actions: action_rules };
};

export const mapPartnersToOptions = (partners = []) => {
  return partners.map(mapPartnerToOption);
};

export const mapActionsToOptions = (actions = []) => {
  return actions.map((action) => {
    return { label: action?.name, value: action?.id };
  });
};

const getFrequencyAndInterval = (rrule = '') => {
  if (!rrule) { return null; }
  const splittedRule = rrule.split(';');
  if (splittedRule?.length < 2) { return null; }
  const frequency = splittedRule[0].split('=')[1];
  const interval = splittedRule[1].split('=')[1];
  if (!frequency || !interval) { return null; }
  return { frequency, interval: parseInt(interval) };
};

const getUntilTime = (repeat = '') => {
  const splittedRule = repeat.split(';');
  const untilRule = splittedRule[2];
  if (!untilRule) { return null; }
  const untilValue = untilRule.split('=')[1];
  return untilValue;
};

export const mapScheduleToString = (schedule, repeat) => {
  if (!schedule && !repeat) { return ''; }
  let scheduleDate = null;
  if (schedule) { scheduleDate = moment(schedule); }
  //  Getting frequency and interval
  const frequencyAndInterval = getFrequencyAndInterval(repeat);
  if (!frequencyAndInterval) { return `Runs on ${scheduleDate.format("dddd, MMMM Do YYYY, h:mm:ss a")}`; }
  const { frequency, interval } = frequencyAndInterval;
  const rruleFrequency = RRule[frequency];
  if (rruleFrequency === null || rruleFrequency === undefined) {
    return `Runs on ${scheduleDate.format("dddd, MMMM Do YYYY, h:mm:ss a")}`;
  }
  //  Getting unti date
  const untilDate = getUntilTime(repeat);
  const ruleParams = {
    freq: rruleFrequency,
    interval,
  };
  if (scheduleDate) { ruleParams.dtstart = scheduleDate.toDate(); }
  if (untilDate) { ruleParams.until = moment(untilDate).toDate(); }
  //  Creating rule
  const rule = new RRule(ruleParams);
  return `Repeats ${rule.toText()}`;
};

const buildRuleSetPayload = (id, name, tagOptions = [], all_members) => {
  const ruleSet = { name };
  if (id) { ruleSet.id = id; }
  const tags = tagOptions.map((option) => option.value);
  const tags_searchable = tagOptions.map((option) => option.label);
  return { ...ruleSet, tags, tags_searchable, all_members };
};

const buildCompaniesPayload = (companyOptions = []) => {
  return companyOptions.map((option) => option.value);
}

const buildActionPayload = (partner_id, action, cleanRules) => {
  const {
    id,
    action_id,
    schedule,
    repeat,
    run_now,
    status,
  } = action;
  if (cleanRules && action_id === null) { return null; }
  const basePayload = id ? { id } : {};
  if (status !== null && status !== undefined) { basePayload.status = status; }
  return {
    ...basePayload,
    action_id,
    schedule,
    repeat,
    run_now,
    partner_id,
  }
};

const buildRulePayload = (rules, cleanRules) => {
  return rules.reduce((payloads, currentRule) => {
    //  Getting needed info
    const { partner, actions } = currentRule;
    if (!partner) { return payloads; }
    const { id: partner_id } = partner;
    const newRules = actions.reduce((newActions, currentAction) => {
      const newPayload = buildActionPayload(partner_id, currentAction, cleanRules);
      return newPayload ? [...newActions, newPayload] : newActions;
    }, []);
    return [...payloads, ...newRules];
  }, []);
};

export const buildRuleSetGeneralPayload = (id, name, tagOptions, companyOptions, ruleOptions, all_members, publish = false, cleanRules = false) => {
  const rule_set = buildRuleSetPayload(id, name, tagOptions, all_members);
  const companies = buildCompaniesPayload(companyOptions);
  const rules = buildRulePayload(ruleOptions, cleanRules);
  return {
    rule_set,
    companies,
    rules,
    publish,
  };
};

//  TODO: consider execute now and run now flags
export const groupRulesAsActionsByPartners = (rules = []) => {
  let isPublished = true;

  const groupedRules = rules.reduce((groupedRules, currentRule) => {
    const { partner, action, ...mainData } = currentRule;
    const { id, status, schedule, repeat } = mainData;
    const { id: action_id, name } = action;
    //  Building new payload
    const newPayload = {
      id,
      status,
      schedule,
      repeat,
      action_id,
      name,
      run_now: false,
    };
    //  Changing is published flag
    if (isPublished && status === ruleSetsStatus.DRAFT) { isPublished = false; }
    //  Finding matching element
    const matchingElementIndex = groupedRules.findIndex((rule) => rule?.partner?.id === partner?.id);
    const matchingElement = matchingElementIndex !== -1 ? groupedRules[matchingElementIndex] : null;
    //  Buildging new payload
    const basePayload = matchingElement ? { ...matchingElement, actions: [...matchingElement.actions, newPayload] } :
      { partner: { id: partner?.id, name: partner?.name, actions: partner?.action_rules }, actions: [newPayload] };
    //  Creating
    if (!matchingElement) { return [...groupedRules, basePayload]; }
    //  Updating
    const newGroupedRules = replaceItemOnArray(groupedRules, matchingElementIndex, basePayload);
    return newGroupedRules;
  }, []);

  if (groupedRules?.length === 0) { isPublished = false; }

  return { rules: groupedRules, isPublished };
};

const getCurrentTimezone = () => {
  moment.fn.zoneName = function () {
    var abbr = this.zoneAbbr();
    return timezoneAbbreviations[abbr] || abbr;
  }
  const currentDate = moment.tz(new Date().toString(), moment.tz.guess());
  const offset = currentDate.format('ZZ');
  const zoneName = currentDate.format('zz');
  return `(GMT${offset}) ${zoneName}`;
};

//  Function to split schedule into filter object
export const mapScheduleToDate = (schedule) => {
  const timezone = getCurrentTimezone();
  if (!schedule) { return { date: null, hour: null, timezone }; }
  const momentDate = moment(schedule);
  const date = momentDate.toDate();
  const hour = momentDate.format('h:mm a');
  return { date, hour, timezone };
};

//  Function to split schedule into filter object, schedule receives a custom timezone already
export const mapScheduleToDateWithCustomTimezone = (schedule) => { 
  if (!schedule) { return { date: null, hour: null, timezone: null }; }
  const { timezone } = schedule; 
  const momentDate = moment(schedule);
  const date = momentDate.toDate();
  const hour = momentDate.format('h:mm a');
  return { date, hour, timezone };
};

export const mapRepeatToState = (repeat) => {
  if (!repeat) { return { freq: 'DAILY', interval: 1, until: null }; }
  const splittedRepeat = repeat.split(';');
  return splittedRepeat.reduce((state, rule) => {
    const splittedRule = rule.split('=');
    const label = splittedRule[0].toLowerCase();
    const value = splittedRule[1];
    let modifiedValue = value;
    if (label === 'freq') { modifiedValue = value?.toUpperCase(); }
    if (label === 'interval') { modifiedValue = parseInt(value); }
    if (label === 'until') { modifiedValue = moment(value).toDate(); }
    return { ...state, [label]: modifiedValue };
  }, {});
};

export const generatePossibleIntervals = () => {
  const intervals = [];
  for (let i = 1; i <= 100; i++) { intervals.push({ value: i, label: i }); }
  return intervals;
};

export const mapRepeatStateToRRule = (repeatState) => {
  const params = {};
  if (repeatState?.freq) { params.freq = RRule[repeatState?.freq]; }
  if (repeatState?.interval) { params.interval = repeatState?.interval; }
  if (repeatState?.until) { params.until = repeatState?.until; }
  const rrule = new RRule(params);
  return rrule.toString().replace('RRULE:', '');
}

export const getUntilFromRRule = (rrule) => {
  if (!rrule) { return null; }
  const repeatState = RRule.fromString(`RRULE:${rrule}`);
  return repeatState?.options?.until;
}
