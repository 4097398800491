import React, {useState} from 'react';
import { Button, TabContent, TabPane, NavItem, NavLink, Row, Col, Nav } from 'reactstrap';
import classnames from "classnames";

const Tabs = (props) => {
    const { tabs } = props;
    const [customActiveTab, setcustomActiveTab] = useState(0);

    function toggleCustom(tab) {
		if (customActiveTab !== tab) {
			setcustomActiveTab(tab);
		}
	}
    return (
        <div>
            <Nav tabs className="nav-tabs-custom">
                {tabs?.map((tab, index)=> {
                    return (
                        <NavItem key={`nav-tab-${index}`}>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === index
                                })}
                                onClick={() => {
                                    toggleCustom(index);
                                }}
                            >
                                <span className="d-none d-sm-block">{tab.name}</span>
                            </NavLink>
                        </NavItem>
                    )
                })}
            </Nav>
            <TabContent activeTab={customActiveTab}>
                {tabs?.map((tab, index)=> {
                    return (
                        <TabPane key={`tab-${index}`}tabId={index}>
                            <Row>
                                <Col sm="12">
                                    {tab.content}
                                </Col>
                            </Row>
                        </TabPane>
                    )
                })}
            </TabContent>
        </div>
    )
}

export default Tabs;