import React from 'react';
import { Button } from 'reactstrap';
import { abbreviateNumber } from '../../../utils/helpers';

const FiltersButtonGroup = (props) => {
    const {
        data,
        filterValue,
        callback,
        wrapperClassName,
        showNumber = true
    } = props;
    return data.map(({ className, label, value, number })=>{
        return (
            <div
                className={wrapperClassName}
                key={`filters-btn-${label}-${value}`}
            >
                <Button
                    color={className}
                    className="btn-filter mb-2"
                    block outline
                    active={filterValue.includes(value)}
                    onClick={() => callback({ label, value})}
                    aria-label={label}
                >
                    {showNumber && <div className="issue-count d-inline-block">{abbreviateNumber(number)}</div>}  {label}
                </Button>
            </div>
        )
    })
}
export default FiltersButtonGroup;
