import { useQuery } from 'react-query';
import { getQueryConfigByKey } from '../utils/queryHelpers';

const useCustomQuery = (queryKey, queryFunction, extraConfig = {}) => {
  return useQuery(
    queryKey,
    queryFunction,
    {
      ...getQueryConfigByKey(queryKey),
      ...extraConfig,
    }
  );
};

export default useCustomQuery;
