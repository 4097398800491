import React from 'react';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import ClickableLabel from '../layout/ClickableLabel';
import { onDeleteModalConfigUpdate, onUpdateCurrentPage } from '../../actions/issues';
import { issueTypes } from '../../constants/issues';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import { DELETE_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';
import useCanAbility from '../../hooks/useCanAbility';
import IssueNavigationButton from './IssueNavigationButton';
import useIssueInfoByGroupParam from '../../hooks/useIssueInfoByGroupParam';
import { isIssueVulType } from '../../utils/issuesHelpers';
import useElementModalNavigation from '../../hooks/useElementModalNavigation';
import IssueCodeCopy from './IssueCodeCopy';

const IssueHeader = (props) => {
  //  Parsing props
  const {
    issue_id,
    code,
    companyName = '',
    closeModal,
    expandedMode,
    setExpandedMode,
    issue_type = issueTypes.VULNERABILITIES,
    parent,
    linkToParent,
    overdue = false,
    updateIssue,
    showIssuesLink = true,
    showNavigationButtons = true,
  } = props;

  //  Watching redux store
  const currentPageTasks = useSelector((state) => state.grcIssues.currentPage);
  const pageCountTasks = useSelector((state) => state.grcIssues.pageCount);

  const updateCurrentPageCallback = (page, issueType) => {
    dispatch(onUpdateCurrentPage(page, issueType))
  }

  //  Component hooks
  const {
    currentPage: currentPageIssues,
    pageCount: pageCountIssues,
  } = useIssueInfoByGroupParam()
  const { dispatch } = useMemoizedDispatch();
  const ability = useCanAbility();
  const {
    currentPage,
    maxPage,
    currentElementIndex,
    maxIndex,
    updateIndex,
  } = useElementModalNavigation({
    issueType: issue_type,
    currentPageIndex: isIssueVulType(issue_type) ? currentPageIssues : currentPageTasks,
    maxPageCount: isIssueVulType(issue_type) ? pageCountIssues - 1 : pageCountTasks,
    updateCurrentPageCallback,
    updateElementCallback: updateIssue
  });

  //  Function to render issue link
  const renderIssueLink = () => {
    if (!expandedMode || !showIssuesLink) { return null; }
    const isGRC = issue_type === issueTypes.GRC;
    return (
      <Button
        color="link"
        className="ml-2 subtask-link p-0 issues-link"
        onClick={() => closeModal()}
      >
        {isGRC ? 'Tasks' : 'Issues'} /
      </Button>
    );
  };

  //  Function to render issue name
  const renderIssueName = () => {
    //  Returning linkable parent link
    if (parent) {
      return (
        <>
          <ClickableLabel
            containerClassName="grc-modal-title-container"
            clickableClassname="grc-title-link"
            normalTextClassName="grc-modal-title"
            normalText={`${code} subtask of`}
            clickableText={parent?.code}
            onClickCallback={linkToParent}
          />
          <IssueCodeCopy
            code={code}
            showCode={false}
            itemLabel="Subtask ID"
          />
        </>
      );
    }

    return (
      <>
        {renderIssueLink()}
        <div className='issue-details-name-header'>
          {code}
          <span className='issue-details-company-name'>
            {companyName && ` ${companyName}`}
          </span>
          <IssueCodeCopy
            code={code}
            showCode={false}
            itemLabel={issue_type === issueTypes.GRC ? 'Task ID' : "Issue ID"}
          />
        </div>
      </>
    );
  };

    // Function to render button of next and previous issue
    const renderHeaderButtons = () => {
      if (!showNavigationButtons) return null;
      return(
        <div className="d-flex flex-grow-1 btn-header">
          <IssueNavigationButton
            moveForward={false}
            currentElementIndex={currentElementIndex}
            elementsMaxIndex={maxIndex}
            currentPage={currentPage}
            maxPage={maxPage}
            callback={() => updateIndex(false)}
          />
          <IssueNavigationButton
            moveForward={true}
            currentElementIndex={currentElementIndex}
            elementsMaxIndex={maxIndex}
            currentPage={currentPage}
            maxPage={maxPage}
            callback={() => updateIndex(true)}
          />
        </div>
      )
    }

  const collapseOrExpand = () => {
    setExpandedMode(!expandedMode);
  };

  //  Function to handle issue delete
  const deleteIssue = () => {
    dispatch(onDeleteModalConfigUpdate(true, [issue_id], false, issue_type));
  };

  const renderDeleteButton = () => {
    const canDeleteIssues = ability.can(DELETE_ACTION, PRIVILEGE_SOURCES.ISSUES.GENERAL);
    if(!canDeleteIssues) return null;
    return (
      <div
        className="bx bx-trash trash-icon"
        onClick={() => { deleteIssue() }}
      />
    )
  }

  //  Function to render header icons
  const renderHeaderIcons = () => {
    return (
      <div className="details-header-icons">
        {renderDeleteButton()}
        <button
          type="button"
          onClick={() => { closeModal() }}
          className="close close-icon"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  };

  //  Function to render overdue label
  //  TODO: change to more scalable implementation once other states are defined
  const renderOverdueLabel = () => {
    if (issue_type !== issueTypes.GRC || !overdue) { return null; }
    return (
      <div className="overdue-label">Overdue</div>
    );
  };

  //  Rendering
  return (
    <div className="details-header">
      <div className='d-flex w-100'>
          {renderHeaderButtons()}
          {renderHeaderIcons()}
      </div>
      <div className="w-100 d-flex flex-row">
        <div className="details-header-title">
          {renderIssueName()}
          {renderOverdueLabel()}
        </div>
      </div>
    </div>
  )
};

export default IssueHeader;
