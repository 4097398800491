import React, { useState } from 'react';
import _ from 'lodash';
import AddSubtaskButton from '../issue-manager/AddSubtaskButton';
import IssuesSubtaskButton from './IssuesSubtaskButton';
import useIssueVulTypeByGroupParam from '../../hooks/useIssueVulTypeByGroupParam';
import GrcIssuesDetails from '../grc/GrcIssuesDetails';
import { getSafeArray, isValidFunction } from '../../utils/helpers';

const IssueParentInfo = (props) => {
  //  Parsing props
  const {
    issue,
    formValues,
    resetState,
    addDisabled,
    showCreateModal,
    showSubtaskModal,
    toggleModal,
  } = props;

  const { subtasks } = issue;
  const safeSubtasks = getSafeArray(subtasks);

  //  Component state
  const [subTaskCode, setSubTaskCode] = useState(null);

  //  Function to handle subtask click
  const toggleSubtaskDetails = (code = null, refresh = true) => {
    setSubTaskCode(code);
    if (isValidFunction(resetState)) resetState();
    if (isValidFunction(toggleModal)) toggleModal(refresh, false);
  }

  // Toggle create subtask via callback
  const closeAddSubTaskModal = () => {
    if (isValidFunction(resetState)) resetState();
    if (isValidFunction(toggleModal)) toggleModal(true);
  }

  //  Function to render add subtask modal
  const renderAddSubtaskModal = () => {
    if (!showCreateModal) { return null; }
    return (
      <GrcIssuesDetails
        code={null}
        parent_issue={issue}
        closeSubTaskModal={closeAddSubTaskModal}
      />
    );
  };

  //  Function to render subtask modal
  const renderSubtaskModal = () => {
    if (!showSubtaskModal && !subTaskCode) { return null; }
    return (
      <GrcIssuesDetails
        code={subTaskCode}
        parent_issue={issue}
        closeSubTaskModal={toggleSubtaskDetails}
        parentIsTask={false}
        showNavigationButtons={false}
      />
    );
  };

  //  Function to render subtasks individual item
  const renderSubtasks = () => {
    return safeSubtasks.map((subtask) => {
      const {
        code,
        name,
        status,
        assigned_user,
        severity,
        created_at,
      } = subtask;

      // Display subtask info
      return (
        <IssuesSubtaskButton
          code={code}
          name={name}
          createdAt={created_at}
          status={status}
          criticality={severity}
          assignee={assigned_user}
          callback={() => toggleSubtaskDetails(code, false)}
        />
      );
    });
  };

  //  Render substasks section
  const renderSubtasksSection = () => {
    if (safeSubtasks.length === 0) { return null; }
    return (
      <div className="subtasks-container">
        {safeSubtasks.length > 4 && <div className="fade-gradient" />}
        {renderSubtasks()}
        {safeSubtasks.length > 4 && <div style={{ minHeight: "38px" }} className="extra-space" />}
      </div>
    );
  };

  //  Rendering empty state
  if (issue?.parent_issue_id) { return null; }

  //  Rendering
  return (
    <>
      {renderAddSubtaskModal()}
      {renderSubtaskModal()}
      {safeSubtasks.length > 0 && (
        <div className="subtasks-section issues-subtask-section">
          <label className="subtasks-section-label d-block mb-2">
            Subtasks{safeSubtasks.length > 0 && ` (${safeSubtasks.length})`}
          </label>
          {renderSubtasksSection()}
          <AddSubtaskButton
            isDisabled={addDisabled}
            callback={toggleModal}
          />
        </div>
      )}
    </>
  );
};

export default IssueParentInfo;
