import React from 'react';
import { useSelector } from 'react-redux';
import { onSubtabSelected } from '../../../actions/navigation';
import { trackEvent } from '../../../utils/gaTracker';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import { useHistory } from 'react-router-dom';
import SimpleDropdown from '../SimpleDropdown';

const SubTabsDropdown = (props) => {
  //  Parsing props
  const { subtabs } = props;

  //  Memoized dipsatch
  const { dispatch } = useMemoizedDispatch();

  //  Watching redux store
  const selectedSubtab = useSelector((state) => state.navigation.selectedSubtab);

  // Component Hooks
  const history = useHistory();

  //  Function to handle tab change
  const onChange = (tab) => {
    if (tab?.identifier === selectedSubtab?.identifier && tab?.label === selectedSubtab?.label) { return; }
    trackEvent('topbar-tab-clicked', 'topbar', (tab?.label || '').toLowerCase().split(' ').join('-'));
    if(tab?.searchOnly) {
      history.replace( { search: tab?.search })
    } else if(tab?.path) {
      history.push(tab?.path)
    }
    dispatch(onSubtabSelected(tab));
  };

  //  Rendering
  return (
      <div className="subtabs-dropdown-container">
        <SimpleDropdown
            extraClassName={`dropdown-filter subtabs-dropdown`}
            placeholder="Select a tab"
            elements={subtabs}
            onChangeCallback={onChange}
            selectedElement={selectedSubtab}
        />
      </div>
  );
};

export default SubTabsDropdown;
