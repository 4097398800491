import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, NavItem, NavLink, Row, Col, Nav } from 'reactstrap';
import classnames from "classnames";

//  TODO: move tabs logic to CustomCardtabs component
const CardTabs = (props) => {
    const { 
        tabs,
        selectedTab = 0,
        alignment = 'right',
        callback,
        className = ''
    } = props;
    const [customActiveTab, setcustomActiveTab] = useState(0);

    useEffect(() => {
        setcustomActiveTab(selectedTab)
    },[selectedTab, setcustomActiveTab])

    function toggleCustom(tab) {
		if (customActiveTab !== tab) {
			setcustomActiveTab(tab);
            if(callback) callback(tab)
		}
	}
    return (
        <div className={`card-tabs ${className} ${alignment === 'right' ? 'clearfix right-tabs' :''}`}>
            <Nav tabs className={`nav-tabs ${alignment === 'center' ? 'justify-content-center' :''}`}>
                {tabs?.map((tab, index)=> {
                    if (!tab.name) { return null; }
                    return (
                        <NavItem key={`nav-tab-${index}`} className={`${tab.className || ''} ${index !== 0 ? 'left-divider': ''}`}>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === index
                                })}
                                onClick={() => {
                                    toggleCustom(index);
                                    if(tab.callback) tab.callback()
                                }}
                            >
                                <span>{tab.name}</span>
                            </NavLink>
                        </NavItem>
                    )
                })}
            </Nav>
            <div>
                <TabContent activeTab={customActiveTab} className="w-100">
                    {tabs?.map((tab, index)=> {
                        return (
                            <TabPane key={`tab-${index}`}tabId={index}>
                                {tab.content}
                            </TabPane>
                        )
                    })}
                </TabContent>
            </div>
            
        </div>
    )
}

export default CardTabs;