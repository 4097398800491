import React from 'react'
import { Badge } from 'reactstrap';

const RiskScoreLabel = (props) => {
  const {
    scoreLabel
  } = props;

  return (
    <div>
      <Badge
        className="risk-score-label"
      >
        {scoreLabel?.toLowerCase() || '-'}
      </Badge>
    </div>
  )
}

export default RiskScoreLabel
