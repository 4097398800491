export function trackEvent(action, category, label, value) {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value
  });
}

export function trackPage(screen_name, appName) {
  window.gtag('event', 'screen_view', {
    app_name: appName || 'Cyvatar',
    screen_name : screen_name
  });
}

export function updateUserID(userID) {
  window.gtag('config', process.env.REACT_APP_GA_ID, {
    'user_id': userID
  });
  window.gtag("set", {
    'user_id': userID
  });
}