import React from 'react';
import TextHeader from './TextHeader';
import CustomTableHeader from '../CustomTableHeader';
import CustomizedCheckbox from '../../layout/CustomizedCheckbox';
import SortIcon from '../SortIcon';
import { headerTypes } from '../../../constants/tableTemplates';
import { customTableEvents } from '../../../constants/common';

const Header = (props) => {
  //  Parsing props
  const {
    headTemplate = {},
    changeSort,
    sort = [],
    handleSelectedEvent,
    allSelected = false,
    allowMultipleSort = false,
  } = props;
  const {
    headerType,
    headerClassName,
    allowSorting,
    allowSelect,
    label,
    entry,
    headerContentClassName = ''
  } = headTemplate;

  //  Function to handle select all event
  const changeSelectAll = (checked) => {
    handleSelectedEvent(customTableEvents.CHANGE_SELECT_ALL, checked);
  };

  //  Function to render sort icon
  const renderSortIcon = () => {
    if (!allowSorting) { return null; }
    return (
      <SortIcon
        containerClassName="sort-button-container"
        entry={entry}
        changeSort={changeSort}
        sort={sort}
        allowMultipleSort={allowMultipleSort}
      />
    );
  };

  //  Function to render selectable checkbox
  const renderCheckbox = () => {
    if (!allowSelect) { return null; }
    return (
      <CustomizedCheckbox
        checked={allSelected}
        onChangeCallback={changeSelectAll}
      />
    );
  };

  //  Function to render the different types of headers
  const renderHeaderContent = () => {
    if (headerType === headerTypes.TEXT) {
      return <TextHeader label={label} />;
    }

    if (headerType === headerTypes.SUBHEADER) {
      const { subtemplate } = headTemplate;
      return (
        <CustomTableHeader
          headerClassName=""
          columnsTemplate={subtemplate}
          isSubtemplate={true}
        />
      );
    }

    return null;
  };

  const renderContent = () => {
    return (
      <>
        {renderCheckbox()}
        {renderHeaderContent()}
        {renderSortIcon()}
      </>
    );
  }

  //  Rendering
  return headerType === headerTypes.SUBHEADER ? renderHeaderContent() : (
    <th className={headerClassName}>
      {headerContentClassName ? (
        <div className={headerContentClassName}>
          {renderContent()}
        </div>
      ) : renderContent()}

    </th>
  );
};

export default Header;
