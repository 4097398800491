import React from 'react';
import { Button } from 'reactstrap'

const DismissButton = (props) => {
    const {
        callback,
        className=""
    } = props;
    const onDismissClick = () => {
        if(callback && typeof(callback) === 'function') {
            callback();
        }
    }
    return (
        <Button
            color="link"
            onClick={onDismissClick}
            className={`btn-close ${className}`}
            aria-label="Close"
        >
            <i className='bx bx-x'></i>
        </Button>
    );
};

export default DismissButton;
