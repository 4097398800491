import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { setAlert } from '../../actions/alerts';
import { alertTypes } from '../../constants/alerts';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useValueDebounce from '../../hooks/useValueDebounce';
import { filterOutAddedTags } from '../../utils/tagsHelper';
import SelectDropdownIndicator from '../layout/fields/SelectDropdownIndicator';
import useTagsQuery from '../../hooks/useTagsQuery';
import { onFocus } from '../../utils/helpers.js';
import { LIST_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';
import useCanAbility from '../../hooks/useCanAbility';

const TagsInifiniteDropdown = (props) => {
    const {
        callback,
        issueId,
        deviceId,
        placeholder = "Select Tag",
        companyId,
        className,
        filterOption,
        filteredTags,
        customMenuPosition = 'fixed'
    } = props;

    // Component state
    const [filteredTagsOptions, setFilteredTagsOptions] = useState([]);
    const [selectedTag, setSelectedTag] = useState(null);
    const [searchText, setSearchText] = useState(undefined);

    // Component Hooks
    const { dispatch } = useMemoizedDispatch();
    const { debouncedValue: textToSearch } = useValueDebounce({
        debounceTime: 250,
        defaultValue: undefined,
        valueToDebounce: searchText?.trim(),
    });
    const ability = useCanAbility();

    //  Getting tags list
    const {
        tags,
        error: tagsListError,
        loading,
        getNextPage,
        clearQueryData,
    } = useTagsQuery({
        company_id: companyId,
        device_id: deviceId,
        issue_id: issueId,
        textToSearch,
        isCydekick: ability.can(LIST_ACTION, PRIVILEGE_SOURCES.MEMBERS.GENERAL),
    });

    // Handle clear clicked
    const clearState = () => {
        setSelectedTag(null);
        setSearchText('');
        clearQueryData();
    };

    //  Watching tags error
    useEffect(() => {
        if (!tagsListError) { return; }
        dispatch(setAlert('Error selecting tag', alertTypes.error));
        setSelectedTag(null);
        clearQueryData();
    }, [tagsListError]);

    // Watch changes to filteredTags, filterOption and tags to filter out the added tags
    useEffect(() => {
        const filtered = filterOutAddedTags(tags, filteredTags, filterOption)
        setFilteredTagsOptions(filtered)
    }, [filteredTags, tags, filterOption])

    //  Function to handle change on select
    const handleDropdownChange = (tag) => {
        if (filterOption && tag?.is_filtered && tag?.filter_option === filterOption) return;
        callback(tag)
        setSelectedTag(tag);
        clearState()
    };

    //  Funciton to handle chanes on input (search text case)
    const handleInputChange = (searchTerm) => {
        setSearchText(searchTerm);
    };

    //  Function to handle on scroll bottom
    const onScrollBottom = () => {
        getNextPage();
    };

    const DropdownIndicator = (props) => {
        return <SelectDropdownIndicator {...props} />
    }

    const customStyle = {
        option: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: data?.is_filtered ? '#361f93!important' : 'transparent',
                color: data?.is_filtered ? '#fff' : '#a6b0cf'
            };
        }
    }

    return (
        <div className={`search-box ${className || ''}`} >
            <div className="business-context-tags-dropdown-container">
                <Select
                    ariaLiveMessages={{
                        onFocus,
                    }}
                    aria-live="polite"
                    inputValue={searchText}
                    value={selectedTag}
                    options={filteredTagsOptions}
                    onChange={handleDropdownChange}
                    onInputChange={handleInputChange}
                    onMenuScrollToBottom={onScrollBottom}
                    isLoading={loading}
                    isSearchable={true}
                    menuPosition={customMenuPosition}
                    placeholder={placeholder}
                    noOptionsMessage={() => null}
                    components={{ DropdownIndicator }}
                    className="react-select-container business-context-tags-dropdown"
                    classNamePrefix="react-select"
                    styles={customStyle}
                    aria-label={filteredTagsOptions?.length === 0 ? 'Tags combobox. Current combobox has no options' : 'Select a tag.'}
                />
            </div>
        </div>
    )
}
export default TagsInifiniteDropdown;
