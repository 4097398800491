import React from 'react'

const EditModeButtons = (props) => {
    const {
        editMode,
        cancelCallback,
        saveCallback,
        editCallback,
        className='',
        submitting,
        processingLabel="Processing...",
        saveLabel="Save",
        cancelLabel="Cancel",
        editLabel="Edit info"
    } = props;
    return (
        <div className={`edit-buttons-container ${className}`}>
            {editMode? (
                <React.Fragment>
                    <button 
                        className={`btn btn-save btn-light float-right text-dark py-2 px-3 d-flex ${submitting ? 'disabled': ''}`}
                        type="submit"
                        disabled={submitting}
                        onClick={saveCallback}
                    >
                        <i className='bx bx-save mr-2 my-auto'></i>
                        {submitting ? processingLabel : saveLabel}
                    </button>
                    <button 
                        className="btn btn-cancel-mode btn-outline-light-gray float-right mr-3 py-2 px-4"
                        onClick={cancelCallback}
                        disabled={submitting}
                    >
                        {cancelLabel}
                    </button>
                </React.Fragment>

            ):(
                <div>
                    <button 
                        className="btn btn-edit-info d-flex btn-outline-light-gray float-right px-3 py-2"
                        onClick={editCallback}
                    >
                        <i className='bx bx-edit-alt mr-2 my-auto'></i>
                        {editLabel}
                    </button>
                </div>
                
            )}
        </div>
    )
}

export default EditModeButtons;