import React, { useState } from 'react';
import { excludedKeyCodes, LEFT_ARROW_KEY_CODE, RIGHT_ARROW_KEY_CODE } from '../../constants/keyCodes';

const useSSNFields = (callback) => {
  const [ssnValues, setValue] = useState({
    ssn0: "",
    ssn1: "",
    ssn2: "",
    ssn3: "",
    ssn4: "",
    ssn5: ""
  });

  return {
    handleChange: e => {
      const { value, name } = e.target;
      const [_, fieldIndex] = name.split("-");

      // Index of code input that is being changed
      const inputIndex = parseInt(fieldIndex, 10);

      let newCode = "";

      const currentValues = Object.keys(ssnValues).sort()
      currentValues.map((key, index) => {
        if (index === inputIndex ) {
          newCode += value;
        } else {
          newCode += ssnValues[key];
        }
      })

      callback(newCode);
      setValue({
        ...ssnValues,
        [`ssn${fieldIndex}`]: value
      });
    }
  };
};

export default function CodeInput(props) {
  const {
    maxDigitLength = 1,
    callback
  } = props;

  const { handleChange } = useSSNFields(callback, maxDigitLength);

  const handleKeyUp = (e) => {
    const { value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");
    const inputIndex = parseInt(fieldIndex, 10);

    let newValue = value;

    // If value's length is equal to maxDigitLength then replace with new key value
    if(value.length === maxDigitLength) {
      // Check if it's a valid key to replace
      const isInvalidKeyCode = excludedKeyCodes.find((keyCode) => keyCode === e?.keyCode);
      if(!isInvalidKeyCode) {
        newValue = e.key
      }
    }

    // Replace characters that aren't digits
    newValue = newValue?.replace(/[^0-9]/g,"");

    e.target.value = newValue;

    // Move cursor to right
    if (e.target.value?.length === 1 || e?.keyCode === RIGHT_ARROW_KEY_CODE) {
      if (inputIndex < 5) {
        const nextSibling = document.querySelector(
          `input[name=ssn-${inputIndex + 1}]`
        );

        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }

    // Move cursor to left
    if (value?.length === 0 || e?.keyCode === LEFT_ARROW_KEY_CODE) {
      const prevSibling = document.querySelector(
        `input[name=ssn-${inputIndex - 1}]`
      );

      if (prevSibling !== null) {
        prevSibling.focus();
      }
    }
  }

  const renderInputs = () => {
    var inputs = [];
    for (let index = 0; index < 6; index++) {
      inputs.push(
        <input
          className="code-input code-input-box "
          type="text"
          placeholder="0"
          name={`ssn-${index}`}
          key={`ssn-${index}`}
          maxLength={maxDigitLength}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          onFocus={e => e.target.select()}
        />
      )
    }
    return inputs;
  }

  return (
    <React.Fragment>
      {renderInputs()}
    </React.Fragment>
  );
};
