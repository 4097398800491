import React from 'react'
import { Badge } from 'reactstrap';
import useUserType from '../../hooks/useUserType';
import { getStatusClassNameByLabel, getStatusLabelById } from '../../utils/issuesHelpers';

const IssueStatusBage = (props) => {
  const {
    status,
    className = ""
  } = props;

  // Component hooks
  const userType = useUserType();
  const statusLabel = getStatusLabelById(status, userType);
  return (
    <Badge
      color={`outline-${getStatusClassNameByLabel(statusLabel)} ${className}`}
      className="status-badge"
    >
      {statusLabel}
    </Badge>
  )
}

export default IssueStatusBage
