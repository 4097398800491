import React from 'react'
import useCustomMutation from '../../../hooks/useCustomMutation';
import useHandleApiResponse from '../../../hooks/useHandleApiResponse';
import CustomToggleSwitch from '../../layout/CustomToggleSwitch';
import API from '../../../api';
import { useSelector } from 'react-redux';
import companyIdSelector from '../../../selectors/companyIdSelector';
import useCanAbility from '../../../hooks/useCanAbility';
import { PRIVILEGE_SOURCES, UPDATE_ACTION } from '../../../constants/privileges';
import useShowModal from '../../../hooks/useShowModal';
import ScheduleScanModal from './ScheduleScanModal';
import { Button } from 'reactstrap';

const ScanSwitch = (props) => {
  const {
    isEnabled,
    domainPublicId,
    startTime,
  } = props;

  // Initializing API
  const { CompanyAPI } = API;

  // Getting needed info from redux store
  const companyId = useSelector((state) => companyIdSelector(state));

  // Component Hooks
  const {
    data: enableDomainScanResponse,
    error: enableDomainScanError,
    mutate: enableDomainScan,
    isLoading: enableDomainScanLoading,
  } = useCustomMutation(
    (params) => CompanyAPI.handlers.enableDomainScan(params),
    CompanyAPI.invalidators.enableDomainScan
  );
  useHandleApiResponse({
    data: enableDomainScanResponse,
    errorData: enableDomainScanError,
    successMessage: `Scan ${isEnabled ? 'disabled' : 'enabled'}`,
  });
  const ability = useCanAbility();
  const {
    showModal: showScanModal,
    toggleModal: toggleScanModal,
  } = useShowModal()

  // Function to enable or disable scan
  const handleChange = () => {
    enableDomainScan({
      enabled: !isEnabled,
      domain_id: [domainPublicId],
      company_id: companyId
    });
  }

  const canModifyScan = ability.can(UPDATE_ACTION, PRIVILEGE_SOURCES.MEMBERS.DOMAIN);

  // Function to render schedule scan button
  const renderScheduleScanButton = () => {
    return (
      <Button
        color="link"
        className="btn-schedule-scan"
        onClick={toggleScanModal}
      >
        Schedule scan
      </Button>
    )
  }

  const renderScanModal = () => {
    if(!showScanModal) return null;
    return (
      <ScheduleScanModal
        showModal={showScanModal}
        toggleModal={toggleScanModal}
        domainPublicIds={[domainPublicId]}
        companyId={companyId}
      />
    )
  }

  return !startTime ? (
    <>
    {renderScheduleScanButton()}
    {renderScanModal()}
    </>
  ) : (
    <CustomToggleSwitch
      containerClassName="switch-container small-switch"
      spanClassName="connection-slider"
      checkedLabelClassName="checked-toggle-cell"
      unCheckedLabelClassName="unchecked-toggle-cell"
      includeLabel={false}
      checked={isEnabled}
      setChecked={handleChange}
      disabled={!canModifyScan}
    />
  );
}

export default ScanSwitch
