import React from 'react';
import ContextMenu from '../context-menu/ContextMenu';
import ContextMenuOption from '../context-menu/ContextMenuOption';
import { customTableEvents } from '../../constants/common';

const AutomationRulesContextMenu = (props) => {
  //  Parsing props
  const {
    parentRef,
    handleSelectedEvent,
    onDeleteElements,
    deleteDisabled,
  } = props;

  //  Function to handle select
  const handleSelect = (element) => {
    // if (element === null || element === undefined) { return; }
    // handleSelectedEvent(customTableEvents.SELECT_ELEMENT, parseInt(element));
  };

  //  Rendering
  return (
    <ContextMenu
      parentRef={parentRef}
      elementType="tr"
      onElementSelectCallback={handleSelect}
      minMenuWidth={100}
      minMenuHeight={60}
    >
      <ContextMenuOption
        label="Delete"
        callback={onDeleteElements}
        className="rounded"
        disabled={deleteDisabled}
      />
    </ContextMenu>
  );
};

export default AutomationRulesContextMenu;
