export default {
    "A": {
        className: 'great',
        hex: '#0EB082'
    },
    "B": {
        className: 'good',
        hex: '#e9c700'
    },
    "C": {
        className: 'warning',
        hex: '#f1a04c'
    },
    "D": {
        className: 'critical',
        hex: '#ff5e54'
        
    },
    "DEFAULT": {
        className: 'critical',
        hex:'#ff5e54'
    },
    "UNSELECTED": {
        className: 'unselected',
        hex:'#74788d'
    }
};