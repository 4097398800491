import { ON_GROUPS_FILTERS_CHANGE, SET_GROUP_PAGINATION } from '../actions/types';
import { initialFilters } from '../constants/groups';

const initialState = {
  filters: initialFilters,
  active_filters: [],
  pageCount: 1,
  currentPage: 0,
  groupsCount: 0,
};

const groupsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ON_GROUPS_FILTERS_CHANGE:
      return {
        ...state,
        filters: payload?.filters || initialFilters,
        active_filters: payload?.active_filters || [],
      }
    case SET_GROUP_PAGINATION: {
      return {
        ...state,
        currentPage: payload?.currentPage,
        pageCount: payload?.pageCount,
        groupsCount: payload?.groupsCount
      }
    }
    default:
      return state;
  }

};

export default groupsReducer;
