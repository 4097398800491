import React from "react";
import _ from "lodash";
import { Card, CardBody, Col } from "reactstrap";
import LoadingContent from "../../../layout/LoadingContent";
import InfoTooltip from "../../../dashboard/tooltips/InfoTooltip";
import BarChart from '../../../dashboard/charts/BarChart';
import { renderEmptyStateMessage } from '../../../../utils/dashboardHelpers';


const DashboardBarChart = (props) => {
  const {
    title,
    chartLoading,
    chartError,
    retryRequest,
    optionsChart,
    series,
    renderEmpty,
    widgetId,
    barClassName = '',
  } = props;

  // Function to render bar chart
  const renderBarChart = () => {

    const extraClassName = `${barClassName} ${renderEmpty ? 'preview-filter' : ''}`;

    return (
      <BarChart
        extraClassName={extraClassName}
        optionsChart={optionsChart}
        series={series}
      />
    );
  };

  return (
    <div className="dashboard-chart-bart-container">
      <Col
        key={`bar-chart-widget-${widgetId}`}
        sm={12}
        lg={12}
        className={`rollup-widget-container align-self-start align-self-lg-stretch mb-2`}
      >
        <Card className="h-100 mb-3 mb-md-0 task-criticality-rollup">
          <CardBody className="rollup-widget d-flex flex-column">
            <div className="d-flex align-items-center">
              <InfoTooltip
                label={title}
                wrapperClassName="dashboard-widget-title"
                showTooltip={false}
              />
            </div>
            <div className="d-flex flex-grow-1 align-items-center doughnut-chart-container">
              <LoadingContent
                isLoading={chartLoading}
                errorMessage={
                  chartError ? "Oops! our fault, reload again." : null
                }
                iconType="solidIcon"
                errorStyle={{ minHeight: "150px", paddingTop: "16px" }}
                loadingStyle={{ minHeight: "150px", paddingTop: "16px" }}
                errorButtonCallback={retryRequest}
              >
                <div className="chart-bar-container">
                  {renderBarChart()}
                  {renderEmpty && renderEmptyStateMessage(chartLoading)}
                </div>
              </LoadingContent>
            </div>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

export default DashboardBarChart;
