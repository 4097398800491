import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import smallLogo from '../../../assets/images/cyvatar-icon-logo.svg';
import cyvatarLogo from '../../../assets/images/cyvatar.svg';
import cyvatarBetaLogo from '../../../assets/images/logos/cyvatar-beta-logo.svg';
import { changeSidebarType } from '../../../actions/layout';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import { defaultIndexPath } from '../../../constants/navigation';

const CyvatarLogo = (props) => {

  const {
    redirectToHome = true,
    showBetaVersion = false
  } = props;
  //  Compnent hooks
  const { dispatch } = useMemoizedDispatch();
  const history = useHistory();

  //  Watching redux state
  const layoutType = useSelector((state) => state.layout.layoutType);
  const leftSideBarType = useSelector((state) => state.layout.leftSideBarType);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  // Initial states
  const betaVerticalLogoHeight = "26px";
  const normalVerticalLogoHeight = "33px";
  const normalHorizontalHeight = "44px";
  const betaHorizontalHeight = "36px";
  const initialVerticalLogoHeight = showBetaVersion ? betaVerticalLogoHeight : normalVerticalLogoHeight;
  const initialHorizontalHeight = showBetaVersion ? betaHorizontalHeight : normalHorizontalHeight;

  //  Component state
  const [logosHeight, setLogosHeight] = useState(layoutType === "horizontal" ? initialHorizontalHeight : initialVerticalLogoHeight );
  const [isMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
  const [logo, setLogo] = useState(cyvatarLogo);

  //  Function to toggle menu
  const toggleMenu = () => {
    if (leftSideBarType !== 'default' && leftSideBarType !== 'condensed') { return; }
    const type = leftSideBarType === 'default' ? 'condensed' : 'default';
    dispatch(changeSidebarType(type, isMobile));
  };

  //  Watching layout types changes
  useEffect(() => {
    const verticalLogo = showBetaVersion ? cyvatarBetaLogo : cyvatarLogo;
    const layoutLogo = layoutType === ' horizontal' ? cyvatarLogo : verticalLogo;
    const verticalLogoHeight = showBetaVersion ? betaVerticalLogoHeight : normalVerticalLogoHeight;
    const horizontalLogoHeight = showBetaVersion ? betaHorizontalHeight : normalHorizontalHeight;
    setLogosHeight(layoutType === "horizontal" ? horizontalLogoHeight : verticalLogoHeight)
    setLogo(layoutLogo);
  }, [layoutType, setLogosHeight, showBetaVersion]);

  //  Function to redirect the user
  const onLogoClicked = () => {
    if (isAuthenticated && redirectToHome) {
      history.push(defaultIndexPath);
      return;
    }
    window.open(process.env.REACT_APP_CYVATAR_WEBSITE, '_blank');
  };

  //  Render arrow
  const renderMenuToggle = () => {
    if (layoutType !== 'vertical') { return null; }
    return (
      <React.Fragment>
        <div className="d-none d-lg-flex align-items-center justify-content-center">
          <button type="button" aria-label={`Main menu ${leftSideBarType === 'default' ? 'collapse':'expand'} button`} onClick={toggleMenu} className="btn btn-collapse-menu rounded-circle mr-2" id="vertical-menu-btn">
            <i className="bx bx-menu"/>
              {/* {leftSideBarType === "condensed" ? <UisArrowCircleRight size="30px" /> : <UisArrowCircleLeft size="30px" />} */}
          </button>
        </div>
      </React.Fragment>
    );
  };

  //  Rendering
  return (
    <div className="d-flex">
      <div className='navbar-brand-box' >
        {renderMenuToggle()}
        <a
          className="logo"
          aria-label="Home"
          onClick={() => onLogoClicked()}
        >
          <span className="logo-sm">
            <img src={smallLogo} alt="" height={logosHeight} />
          </span>
          <span className="logo-lg">
            <img src={logo} alt="" height={logosHeight} />
          </span>
        </a>
      </div>
    </div>
  );
};

export default CyvatarLogo;
