import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NavigationTextRow from '../layout/NavigationTextRow';
import SearchableInfiniteTable from '../CustomTable/SearchableInfiniteTable';
import { buildLogsTextRow } from '../../utils/automationHelper';
import { onLogDetailUnmounted, setLogDetail, setRuleTitles } from '../../actions/automation';
import API from '../../api';
import { logMessageTemplate } from '../../constants/tableTemplates';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import { useHistory, useParams } from 'react-router-dom';
import { setAlert } from '../../actions/alerts';
import useCustomQuery from '../../hooks/useCustomQuery';
import companyInfoSelector from "../../selectors/companyInfoSelector";

//  Component
const SolutionLogDetail = (props) => {
  //  Initializing APIs
  const { RuleAPI } = API;

  //  Watching redux store
  const selectedRuleId = useSelector((state) => state.automation.selectedRuleId);
  const logId = useSelector((state) => state.automation.selectedLog.id);
  const logName = useSelector((state) => state.automation.selectedLog.name);
  const subtitleArray = useSelector((state) => state.automation.selectedLog.subtitleArray);
  const partnerName = useSelector((state) => state.partners.partnerName);
  const companyName = useSelector((state) =>
    companyInfoSelector(state, "name")
  );
  const companyId = useSelector((state) =>
    companyInfoSelector(state, "companyId")
  );

  //  Component state
  const [logTitleArray, setLogTitleArray] = useState([]);
  const [logSubtitleArray, setLogSubtitleArray] = useState([]);
  const [logGroup, setLogGroup] = useState(null);

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const history = useHistory();
  const { rule_id, log_id } = useParams();

  const {
    data: logGroupResponse,
    error: logGroupError,
  } = useCustomQuery(
    [RuleAPI.keys.getLogGroup, log_id, logName],
    ({ queryKey }) => RuleAPI.handlers.getLogGroup(queryKey[1]),
  );

    //  Function to move to automation rules
  const moveToManageSolutions = () => {
    history.push("/manage-solutions");
  };

  //  Component did mount
  useEffect(() => {
    const newTitleArray = buildLogsTextRow([companyName, partnerName, `Log`], [moveToManageSolutions]);
    setLogTitleArray(newTitleArray);
    return () => { dispatch(onLogDetailUnmounted()); }
  }, []);

  //  Watching subtitle array changes
  useEffect(() => {
    if (!subtitleArray || !logName) { return; }
    const newArray = [...subtitleArray, logName];
    const arrayToSet = buildLogsTextRow(newArray, []);
    setLogSubtitleArray(arrayToSet);
  }, [subtitleArray, logName]);

  //  Watching log group api response
  useEffect(() => {
    if (!logGroupResponse) {
      return;
    }
    dispatch(setLogDetail(logGroupResponse.log_group));
    setLogGroup(logGroupResponse.log_group.id);

    // Title array
    const newTitleArray = buildLogsTextRow(
      [companyName, partnerName, `Log[${logGroup}]`],
      [moveToManageSolutions]
    );
    setLogTitleArray(newTitleArray);

    // Set subtitle Array
    const newSubtitleArray = buildLogsTextRow([`${partnerName} Log [${logGroup}]`]);
    setLogSubtitleArray(newSubtitleArray);
  }, [logGroupResponse]);

  //  Wacthing api error
  useEffect(() => {
    if (!logGroupError) { return; }
    dispatch(setAlert('Error getting log details', 'danger'));
    //moveToAutomationRules();
  }, [logGroupError]);

  //  Function to get log messages
  const getLogMessages = async (filters) => {
    const ruleId = selectedRuleId || rule_id;
    const selectedLogId = logId || log_id;
    const response = await RuleAPI.handlers.getLogMessages(ruleId, selectedLogId, filters, companyId);
    return response;
  };

  //  Function to renderTableTitle
  const renderTableTitle = (totalMessages) => {
    return (
      <div className="last-exec-container">
        <div className="bx bx-cog last-exec-icon" />
        <div className="last-exec-label">
          {`${totalMessages} events`}
        </div>
      </div>
    );
  };

  //  Rendering header
  const renderHeader = () => {
    return (
      <div className="rule-logs-header">
        <NavigationTextRow
          elements={logTitleArray}
          delimiter=">"
          rowExtraClassName="title-row"
        />
        <NavigationTextRow
          elements={logSubtitleArray}
          delimiter=":"
          rowExtraClassName="subtitle-row"
        />
      </div>
    );
  };

  //  Rendering
  return (
    <div className="rule-logs-container">
      {renderHeader()}
      <SearchableInfiniteTable
        tableContainerClassName="rule-logs-table-container"
        tableClassName="rule-logs-table"
        headerClassName="rule-logs-table-header"
        rowClassName="rule-logs-table-row"
        searchbarClassName="rule-logs-search-bar"
        columnsTemplate={logMessageTemplate}
        apiFunction={getLogMessages}
        apiKey={RuleAPI.keys.getLogMessages}
        extraKeys={[selectedRuleId || rule_id, logId || log_id, companyId]}
        renderTitle={renderTableTitle}
        expandableRow={true}
        emptyConfig={{
          message: 'There are no messages from the API',
          messageClassName: 'empty-message',
        }}
      />
    </div>
  );
};

export default SolutionLogDetail;
