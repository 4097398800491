import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import BulkActionsMenu from '../../layout/BulkActionsMenu';
import GroupAssigneeModal from './GroupAssigneeModal';
import GroupsRemediationNote from './GroupsRemediationNote';
import GroupTagsModal from './GroupTagsModal';
import GroupContextMenu from './GroupContextMenu';
import { issueTypes, RESOLVED_STATUS } from '../../../constants/issues';
import { isValidStatus } from '../../../utils/issuesHelpers';
import issuesSimpleOptionsSelector from '../../../selectors/IssuesSimpleOptionsSelector';
import useBulkUpdateGroups from '../../../hooks/useBulkUpdateGroups';

const GroupsBulkMenu = (props) => {
  //  Parsing props
  const {
    parentRef,
    onRightClick,
    groups = [],
    clearSelected,
  } = props;

  //  Watching redux store
  const statuses = useSelector((state) => issuesSimpleOptionsSelector(state, issueTypes.VULNERABILITIES, 'statuses', true));

  //  Component state
  const [showAssignee, setShowAssignee] = useState(false);
  const [showRemediation, setShowRemediation] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);

  //  Component hooks
  const { bulkUpdate } = useBulkUpdateGroups({
    messageAction: 'moved',
    messagePlaceholder: selectedStatus?.label,
    onSuccess: () => { clearSelected(); },
  });

  //  Togglers ----
  const toggleAssigneeModal = (value = false) => {
    setShowAssignee(value)
    if (!value) { clearSelected(); }
  };

  const toggleRemediationModal = (value = false) => {
    setShowRemediation(value);
    if (!value) { clearSelected(); }
  };

  const toggleTagsModal = (value = false) => {
    setShowTags(value);
    if (!value) { clearSelected(); }
  };
  // ----

  //  Function to handle status change
  const onStatusChange = (status_id, status_label) => {
    setSelectedStatus({ id: status_id, label: status_label });

    //  Handling status change (resolved)
    if (status_id === RESOLVED_STATUS) {
      toggleRemediationModal(true);
      return;
    }

    //  Handle other status
    const queryParams = {
      group_ids: groups,
      body: { status: status_id },
    }
    bulkUpdate(queryParams);
  };

  const getStatusOptions = () => {
    return statuses?.filter((status) => isValidStatus(status, issueTypes?.VULNERABILITIES)).map((status) => {
      return {
        label: status?.label,
        callback: () => { onStatusChange(status?.id, status?.label) },
        isDisabled: false,
      }
    })
  }

  //  Bulk actions options
  const bulkActions = [
    {
      label: "Move To",
      iconClassName: "bx bx-transfer",
      options: getStatusOptions()
    },
    {
      label: "Assign",
      iconClassName: "bx bx-download",
      callback: () => toggleAssigneeModal(true),
    },
    {
      label: "Tags",
      iconClassName: "bx bx-bookmark",
      callback: () => toggleTagsModal(true),
    },
  ];

  //  Rendering assignee modal
  const renderAssigneeModal = () => {
    if (!showAssignee) { return null; }
    return (
      <GroupAssigneeModal
        groups={groups}
        closeModal={() => toggleAssigneeModal(false)}
      />
    );
  };

  //  Rendering remediation modal
  const renderRemediationModal = () => {
    if (!showRemediation) { return null; }
    return (
      <GroupsRemediationNote
        groups={groups}
        closeModal={() => toggleRemediationModal(false)}
      />
    );
  };

  const renderTagsModal = () => {
    if (!showTags) { return null; }
    return (
      <GroupTagsModal
        groups={groups}
        countLabel={`${groups?.length} groups selected`}
        closeModal={() => toggleTagsModal(false)}
      />
    );
  }

  const renderBulkActions = () => {
    if (groups?.length === 0) { return null; }
    return (
      <div className="groups-bulk-menu-container">
        {renderAssigneeModal()}
        {renderRemediationModal()}
        {renderTagsModal()}
        <BulkActionsMenu
          options={bulkActions}
          show={true}
          isSticky={false}
          className="pl-3"
          isDisabled={false}
        />
      </div>
    );
  };

  //  Rendering
  return (
    <>
      <GroupContextMenu
        parentRef={parentRef}
        onRightClick={onRightClick}
        toggleAssigneeModal={() => toggleAssigneeModal(true)}
        toggleTagsModal={() => toggleTagsModal(true)}
        onSelectStatus={onStatusChange}
      />
      {renderBulkActions()}
    </>

  );
};

export default GroupsBulkMenu;
