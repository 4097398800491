import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import { setIssuesFilter } from '../../actions/issues';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import SearchBar from '../layout/filters/SearchBar';
import filterTypes from '../../constants/filterTypes';
import makeFreemiumValidations from '../../selectors/freemiumStatusSelector';
import IssuesAdvancedFiltering from './IssuesAdvancedFiltering';
import IssuesAssigneeFilter from './issue-filters/IssuesAssigneesFilter';
import DeleteIssuesModal from '../layout/DeleteIssuesModal';
import Pagination from '../layout/CustomPagination';
import { issueTypes } from '../../constants/issues';
import { successActionCreator } from '../../actions/action-creators/success';
import { SET_OPEN_OVERVIEW } from '../../actions/types';
import { dashboardOverviewsIds } from '../../constants/dashboardOverview';
import useIssueInfoByGroupParam from '../../hooks/useIssueInfoByGroupParam';
import InfiniteDropdown from '../layout/InfiniteDropdown';
import API from '../../api';
import { getSafeArray, parseCompaniesToOptions } from '../../utils/helpers';

const IssuesListToolbar = (props) => {
    //  Parsing props
    const {
        containerId = '',
        containerExtraClassName = '',
        containerRef,
        containerStyle = {},
        onPageChange,
        currentPage,
    } = props;

    //  Initializing API
    const { CompanyAPI } = API;

    //   Component State
    const [showAdvanceFilteringModal, setShowAdvanceFilteringModal] = useState(false);

    // Selectors
    const getFreemiumValidations = makeFreemiumValidations();

    // Component Hooks
    const { dispatch } = useMemoizedDispatch();

    //  Getting needed info from redux store
    const lastScan = useSelector(state => state.auth.company?.lastScan);
    const showDemoData = useSelector(state => state.auth.company?.showDemoData);
    const freemiumStatus = useSelector(state => state.auth.company?.freemium);
    const { lastScanIsValid } = useSelector(state => getFreemiumValidations(state));
    const open_overview = useSelector(state => state.dashboards?.open_overview);

    // Component hooks
    const {
        searchTerm,
        filters: { assigned_to: assignedToFilter, company_id: selectedCompany },
        filters,
        deleteModalConfig,
        issueType,
        pageCount,
    } = useIssueInfoByGroupParam();

    //  Function to change filters
    const changeMemberFilter = (option) => {
        if (!option) { return; }
        const filterName = 'multiple_company_id';
        dispatch(setIssuesFilter(filterName, option?.value, option?.label, filterTypes.multiple, null, false, issueType))
    };
    // Render multiple member filter
    const renderMultipleMemberFilter = () => {
        if(issueType !== issueTypes.GROUP_INDIVIDUAL) return null;
        return (
            <div className='assignee-filter'>
                <InfiniteDropdown
                    emptyLabel="Members"
                    extraClassName="dropdown-filter"
                    errorMessage="Error getting members"
                    apiFunction={CompanyAPI.handlers.getImpersonationList}
                    apiKey={CompanyAPI.keys.getImpersonationList}
                    parseElementsToOptions={parseCompaniesToOptions}
                    onChange={changeMemberFilter}
                    isMultiSelect={true}
                    selectedElements={getSafeArray(filters?.company_id).map((id) => ({ value: id }))}
                    addMenuPortalStyle={true}
                />
            </div>
        )
    }

    const renderAssigneeFilter = () => {
        return (
            <IssuesAssigneeFilter
                selectedCompany={selectedCompany}
                filterValue={assignedToFilter}
                placeholder="Assigned to"
                setFilterCallback={(option) => dispatch(setIssuesFilter('assigned_to', option.value, option.label, filterTypes.single, null, false, issueType))}
                wrapperClassName="assignee-filter"
            />
        )
    }

    const toggleAdvancedFilteringModal = () => {
        setShowAdvanceFilteringModal(!showAdvanceFilteringModal);
    }

    const renderMoreFiltersButton = () => {
        return (
            <Button
                color="light-gray"
                className="btn-filter d-inline-flex align-items-center"
                outline
                onClick={toggleAdvancedFilteringModal}
                style={{ marginRight: "11px", marginBottom: "4px" }}
            >
                <i className='bx bx-filter-alt mr-2' />
                More Filters
            </Button>
        )
    }

    const renderAdvancedFilteringModal = () => {
        if (!showAdvanceFilteringModal) { return null; }
        return (
            <IssuesAdvancedFiltering
                showModal={showAdvanceFilteringModal}
                toggleModal={toggleAdvancedFilteringModal}
                issueType={issueType}
            />
        )
    }

    const handleSearch = (termToSearch) => {
        if (open_overview===dashboardOverviewsIds.TOP_FIVE_ISSUES){
            dispatch(successActionCreator(SET_OPEN_OVERVIEW,''));
            return;
        }
        dispatch(setIssuesFilter('text_to_search', termToSearch, `Search: ${termToSearch}`, filterTypes.search, null, false, issueType))
    }

    const renderSearchbar = () => {
        return (
            <SearchBar
                placeholder="Search for an issue"
                value={searchTerm}
                callback={handleSearch}
            />
        )
    }

    //  Function to render delete issues modal
    const renderDeleteIssuesModal = () => {
        if (!deleteModalConfig?.show) { return null; }
        return <DeleteIssuesModal issueType={issueType} />;
    };

    //  Function to get div className
    const getContainerClassName = () => {
        let className = `issues-list-toolbar px-3 ${containerExtraClassName}`;
        if (freemiumStatus !== 0 && showDemoData && lastScan && !lastScanIsValid) {
            className += ' grayout-div';
        }
        return className;
    };

    // Function to render pagination
    const renderPagination = () => {
        return (
            <Pagination
                currentPage={currentPage}
                pageCount={pageCount}
                onPageChange={onPageChange}
            />
        )
    }

    return (
        <div
            id={containerId}
            className={getContainerClassName()}
            ref={containerRef}
            style={containerStyle}
        >
            {renderDeleteIssuesModal()}
            {renderAdvancedFilteringModal()}
            <Row className="justify-content-between px-1">
                <Col sm={9}>
                    {renderAssigneeFilter()}
                    {renderMultipleMemberFilter()}
                    {renderMoreFiltersButton()}
                    {renderSearchbar()}
                </Col>
                <Col sm={3}>
                    {renderPagination()}
                </Col>
            </Row>
        </div>
    )
}

export default IssuesListToolbar;
