import React from "react";
import { Button } from "reactstrap";
import { parseTaggingsList } from "../../utils/tagsHelper";
import TagsPreview from "../tags/TagsPreview";

const BusinessContext = ({ device, openDeviceTagsModal }) => {
  const target = `business-context-tag-tooltip-${device?.public_id}`;
  const tagsList = parseTaggingsList(device?.taggings);
  return (
    <div className="d-flex align-items-center justify-content-between">
      <TagsPreview
        className="mr-1"
        tagsList={tagsList}
        targetId={target}
      />
      <Button
        color="link"
        className="btn-add-tag p-0"
        onClick={() => openDeviceTagsModal(device)}
      >
        {tagsList?.length < 1 && (
          <div
            className="add-label d-inline-block mr-1"
            style={{ whiteSpace: 'nowrap' }}
          >
            Add tags
          </div>
        )}
        <i className='bx bx-plus-circle' color="#7D78A1" />
      </Button>
    </div>
  );
}

export default BusinessContext;