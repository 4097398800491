import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { showTakeAssessmentAction } from "../../actions/layout";
import { clearResetPassword } from "../../actions/auth";
import VerticalLayout from "./VerticalLayout/";
import Alert from './Alert';
import SessionTimeout from "../auth/SessionTimeout";
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import { Helmet } from 'react-helmet';
import ConfirmationModal from "./ConfirmationModal";
import { getPathTitle } from "../../utils/helpers";
import { setAlert } from "../../actions/alerts";
import PendingAdminInviteAlert from "../auth/PendingAdminInviteAlert";
import companyIdSelector from "../../selectors/companyIdSelector";
import useCanAbility from "../../hooks/useCanAbility";
import { CREATE_ACTION, PRIVILEGE_SOURCES } from "../../constants/privileges";

const Layout = (props) => {
  //  Parsing props
  const { children } = props;

  //  Watching redux state
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const showConfirmationModal = useSelector(state => state.layout?.confirmationModal?.show);
  const pendingAdmin = useSelector((state) => state.impersonation?.pendingAdmin);
  const companyId = useSelector((state) => companyIdSelector(state));

  //  Memoized dispatch
  const { dispatch } = useMemoizedDispatch();
  const ability = useCanAbility();

  //  Getting router location
  const location = useLocation();

  //  Needed references
  const topRef = useRef();
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  //  Watching location changes
  useEffect(() => {
    if (!location.search) {
      scrollToRef(topRef)
    }

    let pathTitle = getPathTitle(location?.pathname)
    document.title = pathTitle ? `${pathTitle} | Cyvatar` : "Dashboard | Cyvatar";

    if (location.pathname !== "/welcome") {
      dispatch(showTakeAssessmentAction(false));
    }

    dispatch(clearResetPassword());

  }, [location]);

  useEffect(() => {
    const canImpersonate = ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.MULTIPLE.IMPERSONATE);
    if(canImpersonate) {
      if(pendingAdmin) {
        dispatch(setAlert(<PendingAdminInviteAlert />, 'important', false, null, 'dashboard-content behind-modal'));
      }
    }
  }, [pendingAdmin, companyId])

  //Add meta tags
  const renderMetaTags = () => {
    return (
      <Helmet>
        <meta property="og:title" content="Cyvatar"/>
        <meta property="og:url" content={window?.location?.href}/>
      </Helmet>
    )
  }

  //  Rendering
  return (
    <React.Fragment>
      {renderMetaTags()}
      <Alert />
      {showConfirmationModal && <ConfirmationModal />}
      {isAuthenticated && <SessionTimeout />}
      <div style={{ position: "fixed", top: "0" }} ref={topRef}></div>
      <VerticalLayout>
        {children}
      </VerticalLayout>
    </React.Fragment>
  );
};

export default Layout;
