import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingContent from '../../layout/LoadingContent';
import RuleSetDetailTopBar from './RuleSetDetailTopBar';
import SelectableOptionsRow from './SelectableOptionsRow';
import TagsInfiniteDropdown from '../../tags/TagsInfiniteDropdown';
import InfiniteDropdown from '../../layout/InfiniteDropdown';
import RuleSetManager from './RuleSetManager';
import DeleteAutomationRulesModal from '../DeleteAutomationRulesModal';
import { newRuleSetPayload } from '../../../constants/automation';
import { tagsActions } from '../../../constants/tags';
import API from '../../../api';
import { parseCompaniesToOptions } from '../../../utils/helpers';
import {
  onRuleSetLoaded,
  onCompanyArrayChanges,
  onTagsArrayChanges,
  onRuleSetDetailUnmount,
} from '../../../actions/ruleSet';
import { onUpsertRuleSet } from '../../../actions/automation';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import { setAlert } from '../../../actions/alerts';
import MainScrollableContainer from '../../layout/MainScrollableContainer';
import { useHistory, useParams } from 'react-router-dom';
import useCustomQuery from '../../../hooks/useCustomQuery';


const RuleSetDetail = (props) => {
  //  Initializing APIs
  const { CompanyAPI, RuleAPI } = API;
  //  Watching redux store info
  const selectedRuleSetId = useSelector((state) => state.automation.selectedRuleSetId);

  //  Component state
  const [ruleSet, setRuleSet] = useState(null);
  const [ruleSetName, setRuleSetName] = useState('');

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const history = useHistory();
  const { rule_set_id } = useParams();
  const {
    data: ruleSetResponse,
    error: ruleSetError,
    isLoading: ruleSetLoading,
    isFetching: ruleSetFetching,
  } = useCustomQuery(
    [RuleAPI.keys.getRuleSet, selectedRuleSetId],
    ({ queryKey }) => RuleAPI.handlers.getRuleSet(queryKey[1]),
    { cacheTime: 0 }
  );

  //  Component did mount
  useEffect(() => {
    return () => dispatch(onRuleSetDetailUnmount());
  }, []);

  //  Watching API response
  useEffect(() => {
    if (!ruleSetResponse) { return; }
    const { rule_set } = ruleSetResponse;
    setRuleSet(rule_set);
  }, [ruleSetResponse]);

  //  Watching api error
  useEffect(() => {
    if (!ruleSetError) { return; }
    dispatch(setAlert('Error getting rule set. ', 'danger'));
    history.push('/automation');
  }, [ruleSetError]);

  //  Watching rule set id changes
  useEffect(() => {
    if (selectedRuleSetId !== null) { return; }
    setRuleSet(newRuleSetPayload);
  }, [selectedRuleSetId]);

  //  Watching rule set changes
  useEffect(() => {
    if (!ruleSet) { return; }
    const { name = '' } = ruleSet;
    setRuleSetName(name);
    dispatch(onRuleSetLoaded(ruleSet));
  }, [ruleSet]);

  //  Function to handle delete success
  const onDeleteSuccess = () => {
    history.push('/automation');
  };

  //Watching rule_set_id changes
  useEffect(() => {
    const id = rule_set_id === 'create' ? null : rule_set_id;
    if (id !== selectedRuleSetId) {
      dispatch(onUpsertRuleSet(id));
    }
  }, [rule_set_id])

  //  Function to render company infinite dropdown
  const renderCompanyInfiniteDropdown = (elements, onChange, allSelected = false) => {
    return (
      <InfiniteDropdown
        extraClassName="filter-dropdown"
        emptyLabel="Select members"
        errorMessage="Error getting companies"
        parseElementsToOptions={parseCompaniesToOptions}
        apiFunction={CompanyAPI.handlers.getImpersonationList}
        apiKey={CompanyAPI.keys.getImpersonationList}
        onChange={onChange}
        isMultiSelect={true}
        selectedElements={elements}
        addAllElementsOption={true}
        allElementsLabel="All Members"
        allElementsSelected={ruleSet?.all_members}
      />
    );
  };

  //  Function to render tag infinite dropdown
  const renderTagInfiniteDropdown = (elements, onChange) => {
    return (
      <TagsInfiniteDropdown
        callback={onChange}
        className="filter-dropdown"
        placeholder="Search or select tags"
        filterOption={tagsActions.ADD}
        filteredTags={elements}
      />
    );
  };

  //  Function to render loading state
  //  TODO: consider loading flag
  const renderLoading = () => {
    if (ruleSet) { return null; }
    return (
      <LoadingContent
        isLoading={ruleSetLoading || ruleSetFetching}
        errorMessage={ruleSet ? '' : 'Oops! our fault, reload again.'}
        iconType="solidIcon"
        errorStyle={{ minHeight: "225px" }}
        loadingStyle={{ minHeight: "225px" }}
        errorButtonCallback={() => { }}
        showErrorButton={false}
      />
    );
  };

  //  Function to render content
  const renderContent = () => {
    if (!ruleSet) { return null; }
    return (
      <>
        <RuleSetDetailTopBar
          ruleSetId={ruleSet?.id}
          ruleSetName={ruleSetName}
          setRuleSetName={setRuleSetName}
        />
        <MainScrollableContainer
          additionalClassName="selectable-options-container pb-3"
        >
          <SelectableOptionsRow
            containerClassName='selectable-options-row'
            iconClassName='bx bxs-user-pin company-icon'
            labelClassName='row-label'
            elementClassName='company-element'
            label='For'
            renderDropdown={renderCompanyInfiniteDropdown}
            elementSelector="companies"
            canSelectAll={true}
            changeAction={(...params) => { dispatch(onCompanyArrayChanges(...params)) }}
          />
          <SelectableOptionsRow
            containerClassName='selectable-options-row'
            iconClassName='bx bxs-purchase-tag-alt tag-icon'
            labelClassName='row-label'
            elementClassName='tag-element'
            label='Tagged by'
            renderDropdown={renderTagInfiniteDropdown}
            elementSelector="tags"
            changeAction={(...params) => { dispatch(onTagsArrayChanges(...params)) }}
          />
          <RuleSetManager />
        </MainScrollableContainer>
      </>
    );
  };

  //  Rendering
  return (
    <div className="rule-set-detail-container">
      {renderLoading()}
      {renderContent()}
      <DeleteAutomationRulesModal
        onSuccessCallback={onDeleteSuccess}
        filters={{}}
      />
    </div>
  );
};

export default RuleSetDetail;
