export const additionalWidgetsIds = {
  ADD_TEAM_MEMBERS: 'ADD_TEAM_MEMBERS',
  POLICIES_LIBRARY: 'POLICIES_LIBRARY',
  MORE_WIDGETS: 'MORE_WIDGETS'
};

export const additionalWidgetsTitles = {
  ADD_TEAM_MEMBERS: 'Add Team Members',
  POLICIES_LIBRARY: 'Policies Library',
  MORE_WIDGETS: 'More widgets coming soon!'
};

export const severityColorMap = {
  500: "#9AADBD",
  400: "#0EB082",
  300: "#F5D302",
  200: "#F1A04C",
  100: "#FF5E54"
}

// App Launcher
export const appLauncherWidgetsTitles = {
  ASSESSMENT: 'Assessment',
  POLICIES_LIBRARY: 'Policies Library',
  RISK_MANAGER: 'Risk Manager',
  TASK_MANAGER: 'Risk Manager',
  VULNERABILITY_MANAGER: 'Vulnerability Manager',
  VENDOR_RISK_MANAGER: 'Vendor Risk Manager',
  WORKFLOW_BUILDER: 'Workflow Builder',
  BUSINESS_CONTINUITY_MANAGER: 'Business Continuity Manager',
  SECURITY_AWARENESS_AND_TRAINING: 'Security Awareness and Training'
};

export const appLauncherWidgetsData = [
  {title: 'Assessment', message: 'Conduct an assessment of your Cybersecurity controls.'},
  {title: 'Policies Library', message: 'Download Security Policy Templates from our policy library.'},
  {title: 'Risk Manager', message: 'Build your risk register, assign risk ownership and track remediation tasks.'},
  {title: 'Task Manager', message: 'Assign tasks to your Security team and keep track of priorities and due dates.'},
  {title: 'Issues Management', message: 'Manage, assign and follow up your company issues.'},
  {title: 'Vendor Risk Manager', message: 'Manage of your Vendors, carry out assessments to understand their Security Controls.'},
  {title: 'Workflow Builder', message: 'An easy to use interface to build complex workflow rules, alerts and actions.'},
  {title: 'Business Continuity Manager', message: 'Conduct a Business Impact Analysis (BIA) and build your Business Continuity Plan (BCP).'},
  {title: 'Security Awareness and Training', message: 'Track your Security Training statistics with simple dashboards and widgets.'}
];


// Colors
export const criticalityColors = [
  '#FF5E54',
  '#F1A04C',
  '#F5D302',
  '#0EB082'
];

export const colorsAssignedTo = {
  MEMBER: '#66CCFF',
  CYVATAR: '#7549DF',
  UNASSIGNED: '#9AADBD',
};

export const colorsSolutions = {
  'TVM': '#FF158A',
  'ITAM': '#787BEF',
  'SEM': '#4ECCC6',
  'MSOAR': '#FF7575',
  'CSM': '#ADCFFF',
};

// Chart Config
export const chartColumnNoGridConfig = {
  stroke: {
    show: false,
  },
  dataLabels: {
    enabled: false
  },
  yaxis: {
    show: false,
    showAlways: false,
    axisTicks: {
      show: false
    }
  },
  xaxis: {
    show: false,
    showAlways: false,
    labels:{
      show:false
    },
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false,
    },
  },
  grid: {
    show: false,
    padding: {
      bottom: 50,
    }
  }
};

export const chartColumnBasicConfig = (xAxisCategories,renderTooltip) => {
  return ({
    chart: {
      zoom: {
        enabled: false
      },
      background: 'transparent',
      height: 210,
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 4,
      colors: ['transparent']
    },
    grid: {
      show: true,
      borderColor: '#382A71',
      padding: {
        bottom: 35,
      }
    },
    yaxis: {
      show: true,
      showAlways: true,
      labels:{
        show:true
      },
      axisBorder: {
        show: true,
        color: '#7549DF',
      },
      axisTicks: {
        show: false,
      },
      max: 100,
      tickAmount: 5,
    },
    plotOptions: {
      bar: {
        columnWidth: (xAxisCategories.length>3)? '50%' : '30px' ,
        distributed: true,
      }
    },
    xaxis: {
      categories: xAxisCategories,
      show: true,
      showAlways: true,
      labels:{
        show:true,
      },
      axisBorder: {
        show: true,
        color: '#7549DF',
      },
      axisTicks: {
        show: false,
    },
  },
  tooltip: {
    custom: renderTooltip
  },
  })
};

export const chartBarBasicConfig = (xAxisCategories,renderTooltip) => {
  return({
    chart: {
      height: 210,
      zoom: {
        enabled: false
      },
      background: 'transparent',
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#fff']
      },
      formatter: function (val) {
        if (val>0) return val
      },
      offsetX: 3
    },
    plotOptions: {
      bar: {
        columnWidth: '20px',
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: 'bottom'
        }
      }
    },
    yaxis: {
      show: true,
      showAlways: true,
      labels:{
        show: true
      },
      axisBorder: {
        show: true,
        color: '#7549DF',
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      show: true,
      borderColor: '#382A71',
      padding: {
        bottom: 35,
      }
    },
    xaxis: {
      categories: xAxisCategories,
      show: true,
      labels: {
        show: true
      },
      axisBorder: {
        show: false,
        color: '#605E92',
        height: 3,
        width: '100%',
        offsetX: 0,
        offsetY: 0
      },
      axisTicks: {
        show: true,
        color: '#605E92',
      },
    },
    tooltip: {
      float: true,
      custom: renderTooltip
    },
  })
};
