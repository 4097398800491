import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import companyInfoSelector from "../selectors/companyInfoSelector";
import makeErrorMessage from "../selectors/errorsSelector";
import makeLoadingState from "../selectors/loadingSelector";
import { initializeDeviceListFilters } from "../utils/queryHelpers";
import useCustomQuery from "./useCustomQuery";
import useMemoizedDispatch from "./useMemoizedDispatch";
import API from '../api';
import useStickyGroupHeight from "./useStickyGroupHeight";
import { customTableEvents, devicesTableStickyDivsIds } from "../constants/common";
import useUserType from "./useUserType";
import useSelectedList from "./useSelectedList";
import { successActionCreator } from "../actions/action-creators/success";
import { DEVICES_LIST_FAILURE, DEVICES_LIST_SUCCESS, ISSUE_STATUSES_SUCCESS, RELOAD_DEVICES_LIST } from "../actions/types";
import { errorActionCreator } from "../actions/action-creators/errors";
import { clearDevicesFilters, onDeleteDeviceChange, onMergeDevicesChange, setDevicesFilter } from "../actions/devices";
import { getAccumulatedHeights } from "../utils/scrollHelpers";
import filterTypes from "../constants/filterTypes";


const useDeviceList = ({ companyId, scrollableRef, partnerId, partnerName }) => {
    //  Initializing APIs
    const { DeviceAPI } = API;

    //  Watching redux store
    const getLoadingState = makeLoadingState(['DEVICES_LIST']);
    const getErrorMessage = makeErrorMessage(['DEVICES_LIST']);
    const isLoading = useSelector((state) => getLoadingState(state))
    const errorMessage = useSelector((state) => getErrorMessage(state))
    const devices = useSelector((state) => state.devices.list)
    const pageCount = useSelector((state) => state.devices.pageCount)
    const reloadDevicesList = useSelector((state) => state.devices.reloadDevicesList)
    const freemiumStatus = useSelector((state) => companyInfoSelector(state, 'freemium', false));
    const showDemoData = useSelector((state) => companyInfoSelector(state, 'renderSampleData', true));
    const filters = useSelector(state => state.devices.filters);
    const activeFilters = useSelector(state => state.devices.activeFilters)
    const devicesCount = useSelector(state => state?.devices?.count?.devices)
    const searchTerm = useSelector(state => state.devices.searchTerm);
    const showDeleteDevicesModal = useSelector((state) => state?.devices?.deleteModalConfig?.show);
    const deleteDeviceSuccess = useSelector((state) => state?.devices?.deleteModalConfig?.success);
    const showMergeDevicesModal = useSelector((state) => state?.devices?.mergeModalConfig?.show);
    const mergeDeviceSuccess = useSelector((state) => state?.devices?.mergeModalConfig?.success);
    const hasConnection = useSelector((state) => companyInfoSelector(state, 'hasConnection', true));

    //  Component state
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage] = useState(100);
    const [showDeviceTagsModal, setShowDeviceTagsModal] = useState(false);
    const [showDeviceDetails, setShowDeviceDetails] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [tagsDevice, setTagsDevice] = useState(null);
    const [previousScrollPosition, setPreviousScrollPosition] = useState(null);
    const [devicesIds, setDevicesIds] = useState([]);
    const [devicesConfig, setDevicesConfig] = useState(
        initializeDeviceListFilters(currentPage + 1, perPage, searchTerm, filters)
    );
    const [divTopPositions, setDivTopPositions] = useState([]);

    //  Component variables
    const deviceRowPrefix = "device-row-";
    const tableRef = useRef();

    // Component Hooks
    const { dispatch } = useMemoizedDispatch();
    const {
        data: deviceListResponse,
        error: deviceListError,
        isLoading: deviceListLoading,
        isPreviousData,
    } = useCustomQuery(
        [DeviceAPI.keys.getDevicesList, { companyId, devicesConfig }],
        ({ queryKey }) => DeviceAPI.handlers.getDevicesList(queryKey[1])
    );
    const { refs, getCurrentAccumulatedHeight } = useStickyGroupHeight({ elementsIds: devicesTableStickyDivsIds });
    const userType = useUserType();

    // handle selected devices
    const {
        selected: selectedDevicesIds,
        excluded,
        allSelected,
        eventHandler: handleSelectedEvent,
        setSelected: setSelectedDevicesIds,
        setAllSelected,
        clearSelected: clearSelectedDevicesIds
    } = useSelectedList({
        eventMap: {
            changeAll: customTableEvents.CHANGE_SELECT_ALL,
            add: customTableEvents.SELECT_ELEMENT,
            remove: customTableEvents.UNSELECT_ELEMENT,
        }
    });

    //  Watching api response
    useEffect(() => {
        if (!deviceListResponse) { return; }
        dispatch(successActionCreator(DEVICES_LIST_SUCCESS, deviceListResponse?.data));
        dispatch(successActionCreator(ISSUE_STATUSES_SUCCESS, {
            statuses: deviceListResponse?.data?.statuses,
            userType
        }));
    }, [deviceListResponse]);

    //  Watching API error
    useEffect(() => {
        if (!deviceListError) { return; }
        const message = 'Error getting devices';
        dispatch(errorActionCreator(DEVICES_LIST_FAILURE, { message }));
    }, [deviceListError]);

    //  Watching delete device success
    useEffect(() => {
        if (!deleteDeviceSuccess || (selectedDevice && showDeviceDetails)) { return; }
        clearSelectedDevicesIds();
        dispatch(onDeleteDeviceChange(false, [], false));
    }, [deleteDeviceSuccess, selectedDevice, showDeviceDetails, devicesConfig.sort]);

    //  Watching merge device success
    useEffect(() => {
        if (!mergeDeviceSuccess) { return; }
        clearSelectedDevicesIds();

        dispatch(onMergeDevicesChange(false, [], false));
    }, [mergeDeviceSuccess]);

    //  Watching search term changes
    useEffect(() => {
        setCurrentPage(0);
        setDevicesConfig({ ...devicesConfig, page: 1, text_to_search: searchTerm, filters });
    }, [searchTerm, filters]);

    //  Watching DOM changes to update header height, needed for bulk actions
    useEffect(() => {
        setDivTopPositions(getAccumulatedHeights(0, 3, getCurrentAccumulatedHeight));
    }, [allSelected, selectedDevicesIds?.length, getCurrentAccumulatedHeight(3)]);


    const fetchDevicesList = (selectedPage = currentPage) => {
        if (isPreviousData) { return; }
        setCurrentPage(selectedPage);
        setDevicesConfig({ ...devicesConfig, page: selectedPage + 1 });
    }

    useEffect(() => {
        // Reset filters when selected company changes
        dispatch(clearDevicesFilters())
        setSelectedDevicesIds([]);
        setAllSelected(false);
    }, [companyId]);

    //  Watching partnerId changes
    useEffect(() => {
        if (!partnerId) return;
        dispatch(setDevicesFilter('partner_id', partnerId, null, filterTypes.multiple));
    }, [partnerId]);

    useEffect(() => {
        if (reloadDevicesList) {
            fetchDevicesList()
            dispatch(successActionCreator(RELOAD_DEVICES_LIST, false));
            const currentPosition = window.pageYOffset;
            setPreviousScrollPosition(currentPosition)
        }
    }, [reloadDevicesList])

    // watch changes in devices to update array of devicesIds
    useEffect(() => {
        if (!devices || !Array.isArray(devices)) { return; }
        const ids = devices.map((device) => (device.id))
        setDevicesIds(ids)
    }, [devices])

    // watch selectAll changes to update selectedDevicesIds array
    useEffect(() => {
        if (allSelected) {
            setSelectedDevicesIds(devicesIds)
        } else {
            setSelectedDevicesIds([])
        }
    }, [allSelected]);

    // watch for previousScrollPosition and loading changes to restore scroll position
    useEffect(() => {
        if (previousScrollPosition && !isLoading) {
            window.scrollTo(0, previousScrollPosition);
            setPreviousScrollPosition(null);
        }
    }, [previousScrollPosition, !isLoading]);

    const onSearchCallback = (value) => {
        dispatch(setDevicesFilter('text_to_search', value, `Search: ${value}`, filterTypes.search))
    }

    return {
        devicesConfig,
        setDevicesConfig,
        fetchDevicesList,
        setSelectedDevice,
        setTagsDevice,
        setShowDeviceTagsModal,
        onSearchCallback,
        setShowDeviceDetails,
        showDeviceDetails,
        tagsDevice,
        showDeviceTagsModal,
        deviceRowPrefix,
        selectedDevicesIds,
        setSelectedDevicesIds,
        refs,
        divTopPositions,
        activeFilters,
        devicesCount,
        searchTerm,
        currentPage,
        pageCount,
        tableRef,
        freemiumStatus,
        showDemoData,
        devices,
        isLoading,
        deviceListLoading,
        allSelected,
        excluded,
        handleSelectedEvent,
        deviceListError,
        showDeleteDevicesModal,
        showMergeDevicesModal,
        selectedDevice,
        errorMessage,
        hasConnection
    }
}

export default useDeviceList;