import React from 'react';
import { useHistory, useLocation} from 'react-router-dom';
import Dashboard from '../components/dashboard/Dashboard';
import RecycleBinTable from '../components/app-v4/recycle-bin/RecycleBinTable';
import { useEffect } from 'react';

const RecycleBin = () => {

  //  Component hooks
  	const location = useLocation()
	const history=useHistory()

  // URLparams
	const solutionParamName = 'entity'
	const queryParams = new URLSearchParams(location?.search)
	const queryValue = queryParams?.get(solutionParamName)

	useEffect(() => {
		if(queryValue==null){
			queryParams?.append('entity','devices')
			history?.replace({search:queryParams?.toString()})
		}
	}, [queryParams, queryValue, history])

  //  Rendering
  return (
    <Dashboard
      title="Recycle Bin"
      containerClassName="dashboard mx-2 px-0 py-4 text-normal-style page-content contained-view-width"
    >
      <RecycleBinTable
        entity={queryValue}
      />
    </Dashboard>
  );
};

export default RecycleBin;
