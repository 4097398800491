import * as keys from '../api/ApiKeys';
import { getMinutesInMilliSeconds } from '../utils/helpers';

//  Cached keys
const {
  GET_ACTIVITY_REPORT,
  GET_CYVALUE_DATA,
  GET_ROLLUP_DATA,
  GET_DEVICES_LIST,
  GET_ISSUES_LIST,
  GET_PLANS,
  GET_COMPANY_BADGES,
  GET_PLAN_SUMMARY,
  LIST_ALL_PLANS,
  LIST_PARTNERS_DEFAULTS,
  GET_CYVALUE_BY_WEEK,
  GET_IMPERSONATION_LIST,
  GET_ACTIVE_PARTNERS,
  GET_LOG_MESSAGES,
  GET_RULE_LOGS,
  GET_RULE_SETS,
  GET_TAGS_LIST,
  GET_GRC_ISSUES_LIST,
  GET_GRC_ISSUE,
  GET_MFA_COUNTRY_CODES,
  GET_GROUPS_LIBRARY,
  LIST_PARTNER_MANUAL_ACTIONS,
  GET_IN_APP_NOTIFICATION_SETTINGS,
  GET_USER_NOTIFICATIONS,
} = keys;

//  Map containing the configs for queries thar cached
const cachedQueriesMap = new Map([
  [GET_ACTIVITY_REPORT, { staleTime: getMinutesInMilliSeconds(2) }],
  //[GET_CYVALUE_DATA, { staleTime: getMinutesInMilliSeconds(3) }],
  [GET_ROLLUP_DATA, { staleTime: getMinutesInMilliSeconds(2) }],
  [GET_DEVICES_LIST, { staleTime: getMinutesInMilliSeconds(2), keepPreviousData: true }],
  [GET_ISSUES_LIST, { staleTime: getMinutesInMilliSeconds(2) }],
  [GET_PLANS, { staleTime: getMinutesInMilliSeconds(3) }],
  [GET_COMPANY_BADGES, { staleTime: getMinutesInMilliSeconds(5), cacheTime: getMinutesInMilliSeconds(5) }],
  [GET_PLAN_SUMMARY, { staleTime: getMinutesInMilliSeconds(5) }],
  [LIST_ALL_PLANS, { staleTime: getMinutesInMilliSeconds(5) }],
  //[GET_CYVALUE_BY_WEEK, { staleTime: getMinutesInMilliSeconds(2) }],
  [GET_IMPERSONATION_LIST, { staleTime: getMinutesInMilliSeconds(2) }],
  [GET_ACTIVE_PARTNERS, { staleTime: getMinutesInMilliSeconds(2) }],
  [GET_LOG_MESSAGES, { staleTime: getMinutesInMilliSeconds(2) }],
  [GET_RULE_LOGS, { staleTime: getMinutesInMilliSeconds(2) }],
  [GET_RULE_SETS, { staleTime: getMinutesInMilliSeconds(2) }],
  [GET_TAGS_LIST, { staleTime: getMinutesInMilliSeconds(0) }],
  [GET_GRC_ISSUES_LIST, { staleTime: getMinutesInMilliSeconds(1) }],
  [GET_GRC_ISSUE, { staleTime: getMinutesInMilliSeconds(1) }],
  [LIST_PARTNERS_DEFAULTS, { staleTime: getMinutesInMilliSeconds(5), cacheTime: getMinutesInMilliSeconds(5) }],
  [GET_MFA_COUNTRY_CODES, { staleTime: getMinutesInMilliSeconds(5), cacheTime: getMinutesInMilliSeconds(5) }],
  [GET_GROUPS_LIBRARY, { staleTime: getMinutesInMilliSeconds(10), cacheTime: getMinutesInMilliSeconds(10) }],
  [LIST_PARTNER_MANUAL_ACTIONS, { staleTime: getMinutesInMilliSeconds(60), cacheTime: getMinutesInMilliSeconds(60) }],
  [GET_IN_APP_NOTIFICATION_SETTINGS, { staleTime: getMinutesInMilliSeconds(5), cacheTime: getMinutesInMilliSeconds(5) }],
  [LIST_PARTNER_MANUAL_ACTIONS, { staleTime: getMinutesInMilliSeconds(60), cacheTime: getMinutesInMilliSeconds(60) }],
  [GET_USER_NOTIFICATIONS, { staleTime: getMinutesInMilliSeconds(25), cacheTime: getMinutesInMilliSeconds(25) }],
]);

//  Generating NO CACHED map
const notCachedQueriesMap = new Map();
for (const key in keys) {
  if (!cachedQueriesMap.has(key)) {
    notCachedQueriesMap.set(key, {});
  }
}

export { cachedQueriesMap, notCachedQueriesMap };