import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { defaultIndexPath } from '../../../constants/navigation';
import { getParentPath } from '../../../utils/helpers';

const ParentBackLink = (props) => {
    const {
        label="Go back",
        className,
        customPath,
        includeSearchParams
    } = props;

    // Component Hooks
    const location = useLocation();

    const getPath = () => {
        const searchQuery = includeSearchParams ? location?.search : ''
        const path = customPath || (getParentPath(location?.pathname) || defaultIndexPath)
        return  `${path}${searchQuery}`;
    }

    return (
        <div className="position-relative">
            <Link
                to={getPath()}
                className={`back-link ${className || ''}`}
                aria-label="Check my activity report"
                role="link"
            >
                {`< ${label}`}
             </Link>
        </div>
    )
}

export default ParentBackLink;
