import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { setAlert } from '../../actions/alerts';
import API from '../../api';
import { alertTypes } from '../../constants/alerts';
import useCustomMutation from '../../hooks/useCustomMutation';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import companyIdSelector from '../../selectors/companyIdSelector';
import { getApiErrorMessage, isValidFunction } from '../../utils/helpers';
import IssuesAssigneeFilter from '../issue-manager/issue-filters/IssuesAssigneesFilter';
import TeamRolesButtonGroup from './TeamRolesButtonGroup';
import ConfirmActionModal from '../layout/ConfirmActionModal';
import { setConfirmationModal } from '../../actions/layout';

const ChangeTeamMemberRoleModal = (props) => {
    // Parsing props
    const {
        memberName,
        userId,
        currentRole,
        toggleModal,
        reloadCallback,
    } = props;

    //  Initializing API
    const { UserAPI } = API;

    // Getting needed info from redux store
    const selectedCompany = useSelector((state) => companyIdSelector(state));

    // Component's state
    const [selectedRole, setSelectedRole] = useState(currentRole);
    const [assigneeId, setAssigneeId] = useState(null);

    // Component's variables
    const salesRole = "squad_sales"
    const shouldUpdateAssignee = useMemo(() => {
        return (currentRole !== salesRole && selectedRole === salesRole);
    }, [currentRole, selectedRole]);

    // Component Hooks
    const { dispatch } = useMemoizedDispatch();

    //  Put to change member's role
    const {
        data: changeRoleResponse,
        error: changeRoleError,
        mutate: changeMemberRole,
        isLoading: changeRoleLoading
    } = useCustomMutation(
        (params) => UserAPI.handlers.changeMemberRole(params),
        UserAPI.invalidators.changeMemberRole,
    );

    // Watching response to close modal and set success alert
    useEffect(() => {
        if(!changeRoleResponse) return;
        toggleModal();
        const message = (
            <span>
                {`Updated `}<span className="font-weight-bolder">{`${memberName}'s`}</span>{' account'}
            </span>
        );
        dispatch(setConfirmationModal({
            show: true,
            message: message
        }));
        if(isValidFunction(reloadCallback)) {
            reloadCallback(true)
        }
    },[changeRoleResponse]);

    // Watching error to set alert
    useEffect(() => {
        if(!changeRoleError) return;
        const errorMessage = getApiErrorMessage(changeRoleError)
        dispatch(setAlert(errorMessage, alertTypes.error));
    },[changeRoleError])

    // Callback function to set role
    const onRoleSelect = (newRole) => {
        setSelectedRole(newRole)
    }

    // Render buttons to select role
    const renderRoles = () => {
        return (
            <TeamRolesButtonGroup
                selectedRole={selectedRole}
                callback={onRoleSelect}
                label ={"Select a role:"}
            />
        )
    }

    // Callback function when an options is selected
    const onSelectAssignee = (option) => {
        setAssigneeId(option?.value)
    }

    // Render assignee dropdown
    const renderAssigneeDropdown = () => {
        if(currentRole === salesRole) return null;
        return (
            <div className="dropdown-container">
                <div className='dropdown-instructions'>Please select a new assignee:</div>
                    <IssuesAssigneeFilter
                        selectedCompany={selectedCompany}
                        filterValue={assigneeId}
                        setFilterCallback={onSelectAssignee}
                        showAllMembersOption={false}
                        wrapperClassName="issues-assignee-bulk-dropdown mx-auto pr-2"
                        excludedUserIds={[userId]}
                    />
                    {shouldUpdateAssignee && (
                        <div className='required-label'>
                            A new assignee is required when changing to a sales account.
                            This action can’t be undone.
                        </div>
                    )}
            </div>
        )
    }

    // Function to call when canceling action
    const onCancelClick = () => {
        const isValid = isValidFunction(toggleModal);
        if(isValid) {
            toggleModal()
        }
    };

    // Function to call when confirming action
    const onConfirmClick = () => {
        const params = {
            role_name: selectedRole,
            user_id: userId,
            assignee_id: assigneeId,
        };
        changeMemberRole(params)
    }

    // Flag to disable update button
    const isUpdateDisabled = () => {
        return !selectedRole || currentRole === selectedRole || changeRoleLoading || (shouldUpdateAssignee && !assigneeId);
    }

    //  Rendering
    return (
        <ConfirmActionModal
            showModal={true}
            toggleModal={onCancelClick}
            title={`Role Update`}
            confirmButtonColor="light"
            confirmLabel={changeRoleLoading ? "Updating": "Update"}
            confirmCallback={onConfirmClick}
            cancelCallback={onCancelClick}
            disableConfirm={isUpdateDisabled()}
        >
            {renderRoles()}
            {renderAssigneeDropdown()}
        </ConfirmActionModal>
    );
};

export default ChangeTeamMemberRoleModal;
