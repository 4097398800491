import React from 'react'
import DashboardRow from '../components/app-v4/common/dashboard/DashboardRow';
import MainScrollableContainer from '../components/layout/MainScrollableContainer';

import ViewWrapper from '../components/layout/ViewWrapper';
import AppLauncherWidget from '../components/app-v4/common/dashboard/AppLauncherWidget';
import {
  appLauncherWidgetsData
} from "../constants/dashboard";
import AssessmentIcon from '../assets/images/icons/circle-assessment.svg';
import PolicyLibraryIcon from '../assets/images/icons/thin-circle-policy-library.svg';
import RiskManagerIcon from '../assets/images/icons/risk-manager.svg';
import TaskManagerIcon from '../assets/images/icons/task-manager.svg';
import IssuesManagement from '../assets/images/icons/issues-management.svg';
import AppLauncherAssessmentWidget from '../components/app-v4/common/dashboard/AppLauncherAssessmentWidget';
import FreeScanRequest from '../components/app-v4/my-cyvatar/dashboard/FreeScanRequest';
import useShowModal from '../hooks/useShowModal';
import ScheduleScanModal from '../components/profile/domains/ScheduleScanModal';
import { useSelector } from 'react-redux';
import companyIdSelector from '../selectors/companyIdSelector';

const AppLauncherView = () => {

  // Getting needed info from redux store
	const companyId = useSelector((state) => companyIdSelector(state));

  const {
    showModal: showScheduleScanModal,
    toggleModal: toggleScheduleScanModal
  } = useShowModal();

  const renderFreeScanModal = () => {
    if (!showScheduleScanModal) return null;
    return (
      <ScheduleScanModal
        domainPublicIds={[]}
        showModal={showScheduleScanModal}
        toggleModal={toggleScheduleScanModal}
        companyId={companyId}
        isMasterScan={true}
      />
    );
  };

  return (
    <ViewWrapper
      layoutType="vertical"
      viewName="App Launcher"
      hasImpersonationMode={true}
    >
      <MainScrollableContainer
        additionalClassName="contained-view-width"
      >
        <DashboardRow
          title = "Apps"
        >
          <AppLauncherAssessmentWidget
            buttonMessage = {'Start my assessment'}
            widgetData = {appLauncherWidgetsData[0]}
            icon = {AssessmentIcon}
            linkPath=''
          />
          <AppLauncherWidget
            buttonMessage = {'View my policies'}
            widgetData = {appLauncherWidgetsData[1]}
            icon = {PolicyLibraryIcon}
            linkPath='/dashboard/policies'
          />
          <AppLauncherWidget
            buttonMessage = {'View my risks'}
            widgetData = {appLauncherWidgetsData[2]}
            icon = {RiskManagerIcon}
            linkPath='/dashboard/risk-manager'
          />
          <AppLauncherWidget
            buttonMessage = {'View my tasks'}
            widgetData = {appLauncherWidgetsData[3]}
            icon = {TaskManagerIcon}
            linkPath='/dashboard/task-manager'
          />
          <FreeScanRequest 
            isAppLauncherWidget={true}
            toggleModal={toggleScheduleScanModal}
          />
          <AppLauncherWidget
            buttonMessage = {'View my issues'}
            widgetData = {appLauncherWidgetsData[4]}
            icon = {IssuesManagement}
            linkPath='dashboard/issues'
          />
        </DashboardRow>
        {renderFreeScanModal()}
      </MainScrollableContainer>
    </ViewWrapper>
  )
}

export default AppLauncherView;
