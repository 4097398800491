import React, { useEffect, useState } from 'react';
import IssueTags from '../../issue-detail/IssueTags';
import { issueTypes } from '../../../constants/issues';
import { parseTaggingsList } from '../../../utils/tagsHelper';

const GRCTags = (props) => {
  //  Parsing props
  const { issue } = props;

  //  Component state
  const [tags, setTags] = useState(parseTaggingsList(issue?.taggings));

  //  Watching taggings changes
  useEffect(() => {
    setTags(parseTaggingsList(issue?.taggings));
  }, [issue?.taggings?.length]);

  //  Rendering
  return (
    <IssueTags
      issue={{ ...issue, companyId: issue?.company_id, tags }}
      issueType={issueTypes.GRC}
    />
  );
};

export default GRCTags;
