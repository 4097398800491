import React from 'react';
import { useSelector } from 'react-redux';
import ReferUserLink from '../../auth/ReferUserLink';
import NotificationButton from '../../notifications/NotificationButton';
import Action from './Action';
import { Can } from '../../../contexts/AbilityContext';
import { CREATE_ACTION, PRIVILEGE_SOURCES } from '../../../constants/privileges';

const Actions = (props) => {
  //  Watching redux state
  const actions = useSelector((state) => state.navigation.actions);
  const selectedSubtab = useSelector((state) => state.navigation.selectedSubtab);

  // Function to render notification button and modal
  const renderNotificationButton = () => {
    return (
      <NotificationButton />
    )
  }

  //  Function to render referrer link
  const renderReferrerLink = () => {
    return (
      <Can
        I={CREATE_ACTION}
        a={PRIVILEGE_SOURCES.MULTIPLE.REFER_USER}
      >
        <ReferUserLink />
      </Can>
    );
  };

  //  Function to render actions
  const renderActions = () => {
    if (!actions || actions.length === 0) { return null; }
    //  Getting actions justo for selected subtab
    const actionsToRender = actions.filter(({ allowedTabs }) => allowedTabs.includes(selectedSubtab?.identifier));
    if (!actionsToRender) { return null; }
    return actionsToRender.map((action, index) => {
      return <Action key={index} action={action} />
    });
  };

  //  Rendering
  return (
    <div className="actions-container">
      {renderReferrerLink()}
      {renderNotificationButton()}
      {renderActions()}
    </div>
  );
};

export default Actions;
