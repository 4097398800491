import React, { useEffect, useState, useRef } from 'react';
import { Row, Modal, UncontrolledTooltip } from "reactstrap";
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners';
import { cleanAuthErrorMessage, register } from '../../actions/auth';
import { toggleRegisterModal } from '../../actions/layout';
import { trackEvent } from "../../utils/gaTracker";
import { setAlert } from "../../actions/alerts"
import {
  validateEmail,
  validatePassword,
  validateEmptyTextField,
  validateNames,
  validateCompanyName,
} from '../../utils/validations';
import { renderInput, renderPasswordField } from '../../utils/renderFormFields';
import Cookies from "universal-cookie";
import amplitude from 'amplitude-js';

// import images
import image from "../../assets/images/isometric-images-1.png";

const Register = (props) => {
  const {
    history,
    location,
    showRegister,
    toggleRegisterModal,
    assessment,
    category,
    section,
    question,
    registerText: { title, buttonText, type },
    setAlert,
    authError,
    cleanAuthErrorMessage,
    register,
  } = props;

  const closeModalRef = useRef();
  const [defaultFormValues, setDefaultFormValues] = useState({ first_name: '', last_name: '', email: '' });

  const passwordTooltip = (
    <Link to="#">
      <i className="bx bx-info-circle info-tooltip" id="password-tooltip"></i>
      <UncontrolledTooltip className="password-requirements-tooltip" placement="right" target="password-tooltip">
        <p>Your password must be at least 12 characters long, include:</p>
        <ul>
          <li>1 uppercase and lowercase letter</li>
          <li>1 number</li>
          <li>1 special character</li>
        </ul>
      </UncontrolledTooltip >
    </Link>
  );

  useEffect(() => {
    const cookies = new Cookies();
    setDefaultFormValues({
      first_name: location?.state?.first_name || cookies.get('first_name') || '',
      last_name: location?.state?.last_name || cookies.get('last_name') || '',
      email: location?.state?.email || cookies.get('email') || ''
    })
  }, [showRegister]);

  useEffect(() => {
    cleanAuthErrorMessage();
    if (showRegister) {
      document.body.classList.remove("no_padding");
    } else {
      document.body.classList.add("no_padding");
    }
    let focusTimer = setTimeout(() => { if (closeModalRef.current && showRegister) closeModalRef.current.focus() }, 1000)
    return () => {
      clearTimeout(focusTimer);
    }
  }, [showRegister, cleanAuthErrorMessage]);

  const togModal = () => {
    toggleRegisterModal(!showRegister)
    trackEvent("close_register", "register_abandonment", "close_register");
  }

  const cleanErrors = () => {
    cleanAuthErrorMessage()
  }

  const setRegisterPayload = (values) => {
    const {
      first_name,
      last_name,
      email,
      password,
      company
    } = values;
    const cookies = new Cookies();
    const leadSource = cookies.get('utm_campaign') || cookies.get('utm_source') || document.referrer || 'None';

    const formValues = {
      user: {
        first_name,
        last_name,
        email,
        password
        //position_id: parseInt(position)
      },
      company: {
        name: company,
        company_email: email
      },
      assessment_id: assessment.id,
      leadSource
    }

    return {
      formValues,
      history,
      location,
      currentQuestion: `q${question}_s_${section}_c_${category}`
    }
  }

  //  Function to handle submit
  const onSubmit = (values) => {
    const builtPayload = setRegisterPayload(values);
    const {
      formValues: valuesToSend,
      history: historyToSend,
      location: locationToSend,
      currentQuestion: currentQuestionToSend,
    } = builtPayload;
    register(valuesToSend, historyToSend, locationToSend, currentQuestionToSend);
    if (location?.pathname.includes('cybercheckup')) {
      amplitude.getInstance().logEvent('Sign Up - Cyber Checkup View');
    }

  }

  return (
    <Modal
      isOpen={showRegister}
      toggle={() => { togModal() }}
      centered={true}
      size="xl"
      className="register"
    >
      <div role="dialog" aria-modal="true">
        <div className="modal-header pb-0">
          <button
            type="button"
            onClick={() => { togModal() }}
            className="close text-white pt-3 pr-0"
            data-dismiss="modal"
            aria-label="Close"
            ref={closeModalRef}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="content modal-custom-container">
          <Row className="align-items-start flex-grow-1 h-100 pt-0 px-3 pb-4">
            <div
              className="image-container"
            >
              <Row className="justify-content-start">
                <div className="title-container">
                  <div className={`title ${type}`}>{title}</div>
                </div>
                <img
                  src={image} alt=""
                  className="img-fluid pr-4 d-none d-lg-inline-block align-self-start"
                  style={{ maxHeight: "379px" }}
                />
              </Row>
            </div>
            <div className="form-container align-self-end">
              <Form
                onSubmit={onSubmit}
                validate={validate}
                shouldValidate={true}
                render={({ handleSubmit, submitting, invalid }) => (
                  <form onSubmit={event => {
                    if (invalid) {
                      setAlert('One or more fields have an error. Please check and try again', 'danger');
                    }
                    handleSubmit(event);
                  }}>
                    <div className="row-container">
                      <div className="half-width-field left-field">
                        <Field
                          render={renderInput}
                          name="first_name"
                          label="First name"
                          placeholder="enter first name"
                          customClassName="light"
                          defaultValue={defaultFormValues.first_name}
                        />
                      </div>
                      <div className="half-width-field">
                        <Field
                          render={renderInput}
                          name="last_name"
                          label="Last name"
                          placeholder="enter last name"
                          customClassName="light"
                          defaultValue={defaultFormValues.last_name}
                        />
                      </div>
                    </div>
                    <Field
                      render={renderInput}
                      name="email"
                      label="Email"
                      placeholder="enter email"
                      customClassName="light"
                      type="email"
                      customError={authError}
                      defaultValue={defaultFormValues.email}
                    />
                    <OnChange name="email">
                      {cleanErrors}
                    </OnChange>
                    <Field
                      render={renderPasswordField}
                      name="password"
                      label="Set a secure password"
                      placeholder="enter password"
                      customClassName="light"
                      tooltip={passwordTooltip}
                    />
                    <Field
                      render={renderInput}
                      name="company"
                      label="Company name"
                      placeholder="enter company name"
                      customClassName="light"
                    />
                    <div style={{ marginBottom: "28px" }}>
                      <button
                        className={`btn btn-light btn-block text-dark btn-register mt-3 mb-4 ${type} font-weight-bold ${submitting ? 'disabled' : ''}`}
                        type="submit"
                        disabled={submitting}
                      >
                        {submitting ? 'PROCESSING...' : buttonText}
                      </button>
                      <div className="legal-info mb-1 text-center">
                        {'By signing up, you agree to the '}
                        <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_TERMS_SERVICE} className="link-light underline-link" aria-label="open Terms of Service">Terms of Service</a>
                        {' and '}
                        <a target="_blank" rel="noopener noreferrer" href="https://cyvatar.ai/privacy-policy/" className="link-light underline-link" aria-label="open privacy policy">Privacy Policy</a>.
                      </div>
                      <div className="already-member text-center text-light-gray">
                        Already a member?
                        <Link
                          to="/login"
                          onClick={() => toggleRegisterModal(false)}
                          className="link-light underline-link ml-1">Sign in</Link>
                      </div>
                    </div>
                  </form>
                )}
              />
            </div>

          </Row>
        </div>
      </div>
    </Modal>
  );
}

const validate = (formValues) => {
  const errors = {};
  const {
    first_name,
    last_name,
    email,
    password,
    company
  } = formValues;

  if (validateEmptyTextField(first_name)) {
    errors.first_name = validateEmptyTextField(first_name);
  }

  if (!errors.first_name && validateNames('first_name', first_name)) {
    errors.first_name = validateNames('first_name', first_name);
  }

  if (validateEmptyTextField(last_name)) {
    errors.last_name = validateEmptyTextField(last_name);
  }

  if (!errors.last_name && validateNames('last_name', last_name)) {
    errors.last_name = validateNames('last_name', last_name);
  }

  if (validateEmail(email)) {
    errors.email = validateEmail(email);
  }

  if (validatePassword(password)) {
    errors.password = validatePassword(password);
  }

  if (validateEmptyTextField(company) || validateCompanyName(company)) {
    errors.company = validateEmptyTextField(company) || validateCompanyName(company);
  }
  return errors;
}

const mapStatetoProps = state => {
  const { selectedCategoryId, selectedSectionId, selectedQuestionId } = state.assessment;
  const { assessmentComment } = state.answers;
  return {
    showRegister: state.layout.showRegister,
    registerText: state.layout.registerText,
    assessment: state.assessment.assessment,
    category: selectedCategoryId,
    section: selectedSectionId,
    question: selectedQuestionId,
    assessmentComment: assessmentComment,
    authError: state.auth.error
  };
}
export default withRouter(connect(mapStatetoProps, { cleanAuthErrorMessage, toggleRegisterModal, setAlert, register })(Register));

