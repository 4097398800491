import React from 'react'
import { getSafeArray } from '../../../../utils/helpers';
import CriticalityBadge from '../../../dashboard/CriticalityBadge';
import { Badge } from 'reactstrap';

const GroupRollup = (props) => {
  const {
    severity,
    statusRollup
  } = props;

  // Render issue status rollup
  const renderStatusRollup = () => {
    return getSafeArray(statusRollup).map((status) => {
      if(status?.hidden || !status?.total) return null;
      const statusLabel = status?.label === 'Pending Review' ? 'Vendor Review' : (
        status?.label === 'Customer Review' ? 'Member Review' : status?.label
      )
      return (
        <Badge
          color={`outline-light-gray`}
          className="status-badge group-rollup-badge mr-2 mb-2"
        >
          {`${statusLabel} ${status?.total}`}
        </Badge>
      )
    })
  }
  return (
    <div className='group-details-rollup'>
      <CriticalityBadge
        severity={severity}
        className="group-rollup-badge mr-3"
      />
      {renderStatusRollup()}
    </div>
  )
}

export default GroupRollup;
