import React, { useState, useEffect } from "react";
import PartnerActionComponent from "./PartnerActionComponent";

const PartnerActionScreen = (props) => {
  // Props
  const { screen } = props;

  // Rendering components
  const renderComponents = () => {
    const { components } = screen;
    if (!components) { return null } // TODO: Return error?
    return components.map((component) => {
      // TODO: Check required keys first before displaying component
      return (
        <PartnerActionComponent component={component}></PartnerActionComponent>
      );
    })
  }

  // Rendering
  return (
    <div className="partner-action-screen-container">
      <div>{screen.title}</div>
      <div className="partner-action-component-container">
        {renderComponents()}
      </div>
    </div>
  );

};

export default PartnerActionScreen;
