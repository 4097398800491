import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SimpleDropdown from '../../layout/SimpleDropdown';
import { issueTypes } from '../../../constants/issues';
import {
  mapIssuesOptionsToSelectOptions,
  findIssuSelectedOption,
} from '../../../utils/issuesHelpers';
import issuesSimpleOptionsSelector from '../../../selectors/IssuesSimpleOptionsSelector';
import {getStatusFilterByIssuesType} from '../../../utils/issuesHelpers'

const GrcSimpleSelect = (props) => {
  //  Parsing props
  const {
    label,
    placeholder,
    extraClassName = '', // Class name to add to select container
    configEntry, // Name of the options array stored on redux
    selectedId, // Id of the current selected option
    changeIssue, // Function to change the issue, params = new option id
    error,
  } = props;

  //  Watching redux store
  const values = useSelector((state) => issuesSimpleOptionsSelector(state, issueTypes.GRC, configEntry, true));

  //  Component state
  const [options, setOptions] = useState([]);

  //  Watching values changes
  useEffect(() => {
    if(configEntry === "statuses"){
      values.map((element) => {
        element.hidden = !getStatusFilterByIssuesType(element,issueTypes.GRC);
      });
    }
    setOptions(mapIssuesOptionsToSelectOptions(values));
  }, [values?.length]);

  //  Function to handle select change
  const onSelectChange = (option) => {
    changeIssue(option?.value);
  };

  // Function to render error message
  const renderError = (error) => {
    if(!error) return null;
    return (
      <div className="grc-input-error">
          {error?.message}
      </div>
    )
  }

  //  Rendering
  return (
    <div className="dropdown-parent-container">
      <div className="dropdown-container">
        <div className="dropdown-label">{label}</div>
        <SimpleDropdown
          extraClassName={`dropdown-select ${extraClassName}`}
          placeholder={placeholder}
          elements={options}
          onChangeCallback={onSelectChange}
          selectedElement={findIssuSelectedOption(selectedId, options)}
        />
        {renderError(error)}
      </div>
    </div>
  );
};

export default GrcSimpleSelect;
