import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import InfiniteDropdown from "../../layout/InfiniteDropdown";
import TagComponent from "../../tags/TagComponent";
import {
  mapIssueSourceTypesToOptions,
  removeItemFromArray,
} from "../../../utils/helpers";
import API from "../../../api";
import companyIdSelector from "../../../selectors/companyIdSelector";
import filterTypes from "../../../constants/filterTypes";

const IssueSourceTypesFilter = (props) => {
  //  Parsing props
  const {
    isImpersonating,
    filter_company_id,
    selectedTypes = [],
    activeFilters = [],
    setSelectedTypes,
  } = props;
  //  Initializing API
  const { IssueAPI } = API;

  //  Component refs
  const didMountRef = useRef(null);

  //  Watching redux store
  const impersonatedCompanyId = useSelector((state) => companyIdSelector(state));

  //  Component state
  const [companyId, setCompanyId] = useState(
    isImpersonating ? filter_company_id : impersonatedCompanyId
  );
  const [shouldReloadItems, setShouldReloadItems] = useState(false);

  //  Watching state to change company id state
  useEffect(() => {
    if (!didMountRef.current) {
      return;
    }
    setCompanyId(isImpersonating ? filter_company_id : impersonatedCompanyId);
  }, [
    isImpersonating,
    filter_company_id,
    impersonatedCompanyId,
    didMountRef.current,
  ]);

  //  Watching companyId changes to reload items
  useEffect(() => {
    if (shouldReloadItems || !didMountRef.current) {
      return;
    }
    setShouldReloadItems(true);
  }, [companyId]);

  //  Watching should reload items changes
  useEffect(() => {
    if (!shouldReloadItems) {
      return;
    }
    //  Getting new active filters and overriding filters and active filters
    const newActiveFilters = activeFilters?.filter((active) => active?.filterName !== "source") || [];
    setSelectedTypes([], newActiveFilters);
    setShouldReloadItems(false);
  }, [shouldReloadItems]);

  useEffect(() => {
    didMountRef.current = true;
  }, []);

  //  Function to get issue source types
  const getIssueSources = async (filters) => {
    const response = await IssueAPI.handlers.getIssueSources({
      company_id: companyId,
      filters,
    });
    return response;
  };

  //  Function to handle dropdown change
  const onDropdownChange = (option) => {
    if (!option) {
      return;
    }
    const matchingElement = selectedTypes?.find(
      (type) => type?.value === option?.value
    );
    if (matchingElement) {
      return;
    }
    //  Adding option to filters and to active filters
    const newActiveFilter = {
      filterName: "source",
      value: option?.value,
      label: option?.label,
      type: filterTypes?.multipleObjectsType,
    };
    setSelectedTypes(
      [...selectedTypes, option],
      [...activeFilters, newActiveFilter]
    );
  };

  //  Function to handle type removal
  const onRemoveType = (filter) => {
    //  Getting array withouy filter
    const matchingIndex = selectedTypes.findIndex(
      (type) => type.value === filter.value
    );
    if (matchingIndex === -1) {
      return;
    }
    const arrayWithoutElement = removeItemFromArray(
      selectedTypes,
      matchingIndex
    );
    //  Getting active filter without filter
    const matchingActiveIndex = activeFilters?.findIndex((active) => {
      return active?.filterName === "source" && active.value === filter.value;
    });
    const newActiveFilters =
      matchingActiveIndex === -1
        ? activeFilters
        : removeItemFromArray(activeFilters, matchingActiveIndex);
    //  Overriding filters and active filters
    setSelectedTypes(arrayWithoutElement, newActiveFilters);
  };

  //  Function to render selected elements
  const renderSelectedTypes = () => {
    return selectedTypes.map((type, index) => {
      return (
        <TagComponent
          key={`source-type-${index}`}
          className="mr-2 mb-2"
          label={type.label}
          id={type.value}
          shouldCapitalize={false}
          removeTagCallback={() => onRemoveType(type)}
        />
      );
    });
  };

  //  Rendering
  return (
    <div className="types-filter issue-types-filter">
      <div className="filter-label">Source</div>
      <div className="filters-container">
        <div className="types-dropdown-container">
          <InfiniteDropdown
            emptyLabel="Select Issue Source Types"
            errorMessage="Error getting issue source types."
            apiFunction={getIssueSources}
            apiKey={IssueAPI.keys.getIssueSources}
            extraApiKeys={[companyId]}
            parseElementsToOptions={mapIssueSourceTypesToOptions}
            onChange={onDropdownChange}
            isMultiSelect={true}
            selectedElements={selectedTypes}
            customFilters={{ sort: ["name", "ASC"] }}
            restoreToDefault={shouldReloadItems}
            customMenuPosition="absolute"
            ariaLabel="Select issue source types"
          />
        </div>
        <div className="selected-types">{renderSelectedTypes()}</div>
      </div>
    </div>
  );
};

export default IssueSourceTypesFilter;
