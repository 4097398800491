import React, { useState } from 'react';
import { Button, ModalFooter } from 'reactstrap';
import BasicModal from '../../layout/BasicModal';
import IssuesAssigneeFilter from '../../issue-manager/issue-filters/IssuesAssigneesFilter';
import useBulkUpdateGroups from '../../../hooks/useBulkUpdateGroups';

const GroupAssigneeModal = (props) => {
  //  Parsing props
  const {
    groups = [],
    closeModal,
  } = props;

  //  Component state
  const [selectedAssignee, setSelectedAssignee] = useState(null);

  //  Component hooks
  const {
    bulkUpdate: bulkAssign,
    updateLoading: assigneesLoading,
  } = useBulkUpdateGroups({
    messageAction: 'assigned',
    messagePlaceholder: selectedAssignee?.label,
    onSuccess: () => closeModal(),
  });

  const getTitle = () => {
    const count = groups?.length || 0
    return `Bulk Assign (${count}) selected`
  };

  const onSelecAssignee = (option) => {
    setSelectedAssignee(option)
  };

  const onBulkAssign = () => {
    if (!selectedAssignee?.value) { return; }

    const queryParams = {
      group_ids: groups,
      body: { assigned_to: selectedAssignee?.value },
    };
    bulkAssign(queryParams);
  }

  const renderAssigneeDropdown = () => {
    return (
      <div className="mb-4">
        <IssuesAssigneeFilter
          selectedCompany={null}
          filterValue={selectedAssignee?.value}
          setFilterCallback={onSelecAssignee}
          showAllMembersOption={false}
          wrapperClassName="issues-assignee-bulk-dropdown mx-auto"
        />
      </div>
    )
  };

  const renderFooter = () => {
    return (
      <ModalFooter>
        <div className="text-center" >
          <Button
            className={`btn btn-light text-dark font-weight-bold }`}
            type="submit"
            onClick={onBulkAssign}
            disabled={assigneesLoading || !selectedAssignee?.value}
          >
            {!assigneesLoading ? 'Assign ' : 'Processing...'}
          </Button>
        </div>
      </ModalFooter>
    );
  };

  //  Rendering
  return (
    <BasicModal
      header={getTitle()}
      headerClassName={`modal-header-status`}
      showModal={true}
      toggleModal={closeModal}
      customClassName="issues-assignee-modal"
      scrollable={true}
    >
      {renderAssigneeDropdown()}
      {renderFooter()}
    </BasicModal>
  );
};

export default GroupAssigneeModal;
