import React from "react";
import { useSelector } from "react-redux";
import { Row } from "reactstrap";
import AssessmentHeader from './AssessmentHeader';
import AuthenticatedHeader from './AuthenticatedHeader';
import CyvatarLogo from '../CyvatarLogo';
import { toggleRegisterModal, changeRegisterModal } from "../../../../actions/layout";
import { trackEvent } from "../../../../utils/gaTracker";
import useMemoizedDispatch from "../../../../hooks/useMemoizedDispatch";
import amplitude from 'amplitude-js';


const ClarityHeader = (props) => {
  //  Parsing props
  const {
    customClassName = '',
    layoutType = "horizontal",
    toggleMenuCallback,
    allowBetaVersion = false
  } = props;

  //  Memoized dispatch
  const { dispatch } = useMemoizedDispatch();

  //  Watching redux store
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const userLoading = useSelector((state) => state.auth.loading);
  const showTakeAssessment = useSelector((state) => state.layout.showTakeAssessment);
  const category = useSelector((state) => state.assessment.selectedCategoryId);
  const section = useSelector((state) => state.assessment.selectedSectionId);
  const question = useSelector((state) => state.assessment.selectedQuestionId);

  //  Function for toggling menu
  const toggleMenu = () => {
    toggleMenuCallback();
  };

  //  Function to show assessment progress
  const onSaveProgressClick = () => {
    if (!isAuthenticated) {
      // const buttonText = "SAVE PROGRESS";
      const buttonText = "Continue";
      const redirectPath = null;
      const title = (
        <div aria-label="Take a moment to enjoy a good coffee and reflect…" role="text">
          <div aria-hidden="true">
            Take a moment to enjoy a good
            <br />
            coffee and reflect…
          </div>
        </div>
      );
      const type = "save-progress";
      dispatch(changeRegisterModal({ title, buttonText, redirectPath, type }));
      dispatch(toggleRegisterModal(true));
      amplitude.getInstance().logEvent('Cyber Checkup View - Header - Save Your Progress');
    }
    trackEvent("save_progress_click", "assessment_abandonment", "save_progress_click", `q${question}_s_${section}_c_${category}`);
  };

  //  Function to render mobile menu icon
  const renderMobileMenuIcon = () => {
    if (layoutType !== 'vertical') { return null; }
    return (
      <React.Fragment>
        <div className="d-flex d-lg-none mr-auto">
          <button type="button" onClick={toggleMenu} className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
            <i className="fa fa-fw fa-bars"></i>
          </button>
        </div>
      </React.Fragment>
    );
  };

  //  Function to render right header
  const renderHeader = () => {
    if (userLoading || showTakeAssessment === null) return null;

    if (showTakeAssessment) {
      return (
        <AssessmentHeader isAuthenticated={isAuthenticated} />
      );
    }

    return (
      <AuthenticatedHeader
        isAuthenticated={isAuthenticated}
        layoutType={layoutType}
        onSaveProgressClick={onSaveProgressClick}
      />
    );
  };

  //  Rendering
  return (
    <Row>
      <header id="cyvatar-topbar" className={customClassName}>
        <div className="navbar-header">
          <CyvatarLogo showBetaVersion={allowBetaVersion} />
          {renderMobileMenuIcon()}
          {renderHeader()}
        </div>
      </header>
    </Row>
  );
};

export default ClarityHeader;
