import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InfiniteDropdown from '../../layout/InfiniteDropdown';
import SimpleDropdown from '../../layout/SimpleDropdown';
import RuleSetScheduleSummary from './RuleSetScheduleSummary';
import {
  mapPartnerToOption,
  mapPartnersToOptions,
  mapActionsToOptions
} from '../../../utils/automationHelper';
import API from '../../../api';
import {
  onRulePartnerChange,
  onRuleActionChange,
  onAddAction,
  onRemoveAction,
} from '../../../actions/ruleSet';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch'

const RuleSetCreationRow = (props) => {
  //  Parsing props
  const { ruleIndex } = props;

  //  Initializing APIs
  const { PartnerAPI } = API;

  //  Watching redux store
  const rule = useSelector((state) => state.ruleSet.rules[ruleIndex]);
  const { partner, actions } = rule

  //  Component state
  const [selectableActions, setSelectableActions] = useState([]);

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();

  //  Watching partner changes
  useEffect(() => {
    if (!partner) { return; }
    const { actions: action_rules } = partner;
    const parsedActions = mapActionsToOptions(action_rules);
    setSelectableActions(parsedActions);
  }, [partner]);

  //  Function to get partners
  const getPartners = async (filters) => {
    const response = await PartnerAPI.handlers.getActivePartners({ company_id: null, filters: { ...filters } });
    return response;
  };

  //  Function to handle partner change
  const handlePartnerChange = (option) => {
    dispatch(onRulePartnerChange(ruleIndex, option));
  };

  //  Function to handle action change
  const handleActionChange = (index, action) => {
    dispatch(onRuleActionChange(ruleIndex, index, action));
  };

  //  Function to handle action removal
  const removeActionFromRule = (actionIndex) => {
    dispatch(onRemoveAction(ruleIndex, actionIndex));
  }

  //  Function to handle action addition
  const addActionToRule = () => {
    dispatch(onAddAction(ruleIndex));
  };

  //  Function to render the add action button
  const renderAddActionButton = () => {
    if (!partner || selectableActions?.length == 0) { return null; }
    return (
      <div className="creation-row add-action-row">
        <div
          className="add-action-container addition-container"
          onClick={() => addActionToRule()}
        >
          <div className="bx bx-plus-circle add-action-icon" />
          <div className="add-action-label">Add Action</div>
        </div>
      </div>
    );
  };

  //  Function to render partner section
  const renderPartnerSection = (index) => {
    if (index !== 0) { return null; }
    return (
      <>
        <div className="row-index">{ruleIndex + 1}</div>
        <InfiniteDropdown
          emptyLabel="Choose Partner"
          extraClassName="dropdown-filter partner-dropdown"
          errorMessage="Error getting partners"
          apiFunction={getPartners}
          apiKey={PartnerAPI.keys.getActivePartners}
          parseElementsToOptions={mapPartnersToOptions}
          onChange={handlePartnerChange}
          selectedElements={[]}
          initialElement={mapPartnerToOption(partner)}
          customFilters={{ actions: true }}
        />
      </>
    );
  }

  //  Function to render actions dropdown
  const renderActionsDropdown = (action, index) => {
    if (!partner || selectableActions?.length == 0) { return null; }
    const { action_id, name } = action;
    const selectedAction = action_id === null ? null : { label: name, value: action_id };
    return (
      <SimpleDropdown
        extraClassName="dropdown-filter action-dropdown"
        placeholder="Choose Action"
        elements={selectableActions}
        selectedElement={selectedAction}
        onChangeCallback={(option) => handleActionChange(index, option)}
      />
    );
  };

  //  Function to render remove icon
  const renderRemoveIcon = (schedule, repeat, index) => {
    if (!partner || selectableActions?.length == 0 || (!schedule && !repeat)) { return null; }
    return (
      <div
        className="bx bxs-x-circle delete-icon"
        onClick={() => removeActionFromRule(index)}
      />
    );
  }

  //  Function to render actions
  const renderActions = () => {
    return actions.map((action, index) => {
      const { schedule, repeat, run_now = false } = action;
      return (
        <div className="creation-row" key={index}>
          <div className="partners-section">
            {renderPartnerSection(index)}
            {renderActionsDropdown(action, index)}
          </div>
          <RuleSetScheduleSummary
            schedule={schedule}
            repeat={repeat}
            ruleSetIndex={ruleIndex}
            actionIndex={index}
            run_now={run_now}
          />
          {renderRemoveIcon(schedule, repeat, index)}
        </div>
      );
    });
  };

  //  Rendering 
  return (
    <>
      {renderActions()}
      {renderAddActionButton()}
    </>
  );
};

export default RuleSetCreationRow;
