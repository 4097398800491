import cyvatarAxios from '../axios';
import {
  GET_PLANS,
  GET_PLAN_SUMMARY,
  LIST_ALL_PLANS,
  GET_NEXT_PLAN_UPGRADE,
} from '../ApiKeys';

//  Function to get company plan summary
const getPlanSummary = async (company_id) => {
  if (!company_id) { return null; }
  const response = await cyvatarAxios.get('/api/workbench/solution/plan_summary', { params: { company_id } });
  const { data } = response;
  return data;
};

//  Params = { company_id, interval }
const getPlans = async (params) => {
  const response = await cyvatarAxios.get('/api/subscription/plans', { params });
  const { data } = response;
  return data;
};

//  Function to request a plan change params { price_id, message }
const requestPlanChange = async (params) => {
  const response = await cyvatarAxios.post('/api/subscription/plans/request_plan_change', params);
  const { data } = response;
  return data;
};

//  Function to get all plans
const listAllPlans = async () => {
  const response = await cyvatarAxios.get('/api/subscription/plans/list');
  const { data } = response;
  return data?.plans || [];
};

//  Function to change plan for company params { company_id, price_id }
const changePlanForCompany = async (params) => {
  const response = await cyvatarAxios.post('/api/subscription/plans/change_plan', params);
  const { data } = response;
  return data;
};

// Function to get company's next plan upgrade
const getNextPlanUpgrade = async (company_id) => {
  const response = await cyvatarAxios.get('/api/subscription/plans/next_upgrade', { params: { company_id } });
  const { data } = response;
  return data;
}

export const PlanAPI = {
  handlers: {
    getPlanSummary,
    getPlans,
    requestPlanChange,
    listAllPlans,
    changePlanForCompany,
    getNextPlanUpgrade,
  },
  keys: {
    getPlanSummary: GET_PLAN_SUMMARY,
    getPlans: GET_PLANS,
    listAllPlans: LIST_ALL_PLANS,
    getNextPlanUpgrade: GET_NEXT_PLAN_UPGRADE,
  },
  invalidators: {
    requestPlanChange: null,
    changePlanForCompany: [
      GET_PLAN_SUMMARY,
      GET_PLANS,
      LIST_ALL_PLANS,
      GET_NEXT_PLAN_UPGRADE,
    ]
  }
};
