import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'reactstrap';
import CompanyBasicProfile from './CompanyBasicProfile';
import Accomplishments from './accomplishments/Accomplishments';
import { trackPage } from '../../utils/gaTracker';
import companyInfoSelector from '../../selectors/companyInfoSelector';
import MemberNotes from './MemberNotes';
import { CREATE_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';
import useCanAbility from '../../hooks/useCanAbility';
import CompanyAttachments from './attachments/CompanyAttachments';
import companyIdSelector from '../../selectors/companyIdSelector';
import useDragOver from '../../hooks/useDragOver';

const CompanyMainProfile = () => {

  //  Watching redux store
  const companyName = useSelector((state) => companyInfoSelector(state, 'name', false));
  const companyId = useSelector(state => companyIdSelector(state));
  const companyPublicId = useSelector(state => state.auth?.company?.id);
  const currentCustomerPublicId = useSelector(state => state.customers?.currentCustomer?.public_id);

  // Component Hooks
  const ability = useCanAbility();
  const {
    isDragOver,
    clearState: clearDraggedState,
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop: onFileDrop,
  } = useDragOver();

  // Component variables
  const canImpersonate = ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.MULTIPLE.IMPERSONATE);

  //  Component did mount
  useEffect(() => {
    trackPage('company-profile');
  }, []);

  // Function to get company public id
  const getCompanyPublicId = () => {
    return currentCustomerPublicId || companyPublicId;
  }

  const renderProfile = () => {
    return (
      <div
        className="company-attachments-section w-100"
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={onFileDrop}
      >
          <CompanyBasicProfile lg={12}/>
          <CompanyAttachments
            companyPublicId={getCompanyPublicId()}
            companyId={companyId}
            isDragOver={isDragOver}
            clearDraggedState={clearDraggedState}
            lg={12}
          />
          <MemberNotes lg={12}/>
          <Accomplishments lg={12}/>
      </div>
    )
  }

  //  Rendering
  return (
    <>
      {canImpersonate && !companyName ? null : renderProfile()}
    </>
  );
};

export default CompanyMainProfile;
