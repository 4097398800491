import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

const IconCard = (props) => {
    const {
        title,
        titleClassName="",
        children,
        icon,
        cardWrapperClassName="",
        iconClassName="",
        iconWrapperClassName=""
    } = props;
    return (
        <div className={`icon-card ${cardWrapperClassName}`}>
            <Card>
                <CardBody>
                    <CardTitle className={`text-light-gray ${titleClassName}`}>
                        <div className={`header-icon rounded-circle mr-3 text-white bg-secondary ${iconWrapperClassName}`}>
                            {icon || <i className={iconClassName}/>}
                        </div>
                        {title}
                    </CardTitle>
                    {children}
                </CardBody>
            </Card>
        </div>
    )
}
export default IconCard;
