import React, { useEffect } from "react";
import DashboardWidgetContainer from '../../common/dashboard/DashboardWidgetContainer';
import DashboardRow from '../../common/dashboard/DashboardRow';
import VulnerabilityTrend from './VulnerabilityTrend';
import CurrentStatusDashboardRollup from "./CurrentStatusDashboardRollup";
import IssuesByCriticalityBar from "./IssuesByCriticalityBar";
import { useSelector } from "react-redux";
import companyInfoSelector from "../../../../selectors/companyInfoSelector";
import useScanValidation from "../../../../hooks/useScanValidation";
import { scanStatusMap } from "../../../../constants/common";
import companyIdSelector from "../../../../selectors/companyIdSelector";
import { getScanRollupClassName, getScanRollupEmptyLabel } from "../../../../utils/issuesHelpers";
import { useHistory } from "react-router-dom";

const IssueManagerRow = (props) => {

  const {toggleModal} = props;

  // Component hooks
  const { scanStatus } = useScanValidation();
  const history = useHistory();

  //  Watching redux store
  const selectedSubtabSearch = useSelector((state) => state?.navigation?.selectedSubtab?.search);
  const isFreemium = useSelector((state) => companyInfoSelector(state, "freemium", false) !== 0);
  const companyId = useSelector((state) => companyIdSelector(state));
  const amountOfDomains = useSelector((state) => companyInfoSelector(state, "domainsCount", false));
  const hasMultipleDomains = amountOfDomains > 1 ? true : false;


  // Returns variable to determin to show Issues link
  const getShowIssuesLink = () => {
    const scanNotRequested = scanStatusMap.NOT_REQUESTED_SCAN === scanStatus
    return !isFreemium || !scanNotRequested
  }

  // Get link url
  const getIssuesUrl = () => {
    const showIssuesLink = getShowIssuesLink()
    return showIssuesLink ? `/dashboard/issues/${selectedSubtabSearch}` : null
  }

  const handleLinkClick = () => {
    if(getShowIssuesLink()) return;
    if(!getShowIssuesLink() && hasMultipleDomains) {
      history.push("/profile/company/domains");
      return;
    }
    toggleModal(true);
  }

  const getLinkLabel = () => {
    const showIssuesLink = getShowIssuesLink()
    const requestLabel = `Request free monthly vulnerability scan`
    return !showIssuesLink && hasMultipleDomains ? "Run your first scan" : showIssuesLink ? "See all" : requestLabel;
  }

  //  Rendering
  return (
    <DashboardRow
      title="Issues Management"
      linkText={getLinkLabel()}
      linkUrl={getIssuesUrl()}
      linkOnClick={handleLinkClick}
    >
      <DashboardWidgetContainer
        hasMessage
        message="All your open issues by criticality"
        containerClassName="dashboard-widget-container"
      >
        <IssuesByCriticalityBar containerClassName='w-100'/>
      </DashboardWidgetContainer>
      <DashboardWidgetContainer
        hasMessage
        message="The current status of your issues"
        containerClassName="dashboard-widget-container"
      >
        <CurrentStatusDashboardRollup
          customEmptyLabel={getScanRollupEmptyLabel(scanStatus, isFreemium)}
          customEmptyLabelClassName={getScanRollupClassName(scanStatus, isFreemium)}
        />
      </DashboardWidgetContainer>

      <VulnerabilityTrend
        useLocalTimeFilter={isFreemium}
      />
    </DashboardRow>
  );
};

export default IssueManagerRow;
