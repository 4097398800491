import React, { useEffect, useState } from 'react'
import { GroupAPI } from '../../../../api/services/GroupAPI';
import { issuesGroupDevicesTableTemplate } from '../../../../constants/app-v4/issuesGroupsTableTemplate';
import { cellTypes, headerTypes } from '../../../../constants/tableTemplates';
import { getSafeArray, isValidFunction } from '../../../../utils/helpers';
import GroupDetailsTable from './GroupDetailsTable';
import EmptyBoxIcon from '../../../../assets/images/empty-state-box.svg';

const GroupDevices = (props) => {

  const {
    group = {},
    allSelected = false,
    selected = [],
    excluded = [],
    handleSelectedEvent,
    setSelected,
    clearSelected,
  } = props;

  const [devices, setDevices] = useState([]);

  // watch selectAll changes to update selectedDevicesIds array
  useEffect(() => {
    if (allSelected) {
      setSelected(getSafeArray(devices).map(device => device?.id));
    } else {
      setSelected([]);
    }
  }, [allSelected]);

  // Clear selected devices if the devices list changes when using search bar
  useEffect(() => {
    if (isValidFunction(clearSelected)) { clearSelected() };
  }, [devices]);

  //  Function to get affected devices of the group
  const getIssueGroupDevices = async (filters) => {
    const sort = filters?.sort?.length > 0 ? Object.fromEntries([filters?.sort]) : null;
    const payload = {
      group_id: group?.id,
      text_to_search: filters?.searchTerm,
      offset: filters?.offset,
      limit: filters?.limit,
      sort: sort,
    }
    return await GroupAPI.handlers.getIssuesGroupDevices(payload);
  };

  //  Function to renderTableTitle
  const renderTableTitle = () => {
    return (
      <div className="issues-group-table-title">
        Devices affected
      </div>
    );
  };

  const getColumnsTemplate = () => {
    return [
      {
        label: '',
        allowSorting: false,
        allowSelect: true,
        headerType: headerTypes.TEXT,
        cellType: cellTypes.SELECTABLE,
        headerClassName: 'header-cell header-text-cell tiny-cell',
        cellClassName: 'regular-cell tiny-cell',
        entry: '',
      },
      ...issuesGroupDevicesTableTemplate
    ];
  };

  return (
    <GroupDetailsTable
      tableClassName="issues-group-table group-devices-table"
      headerClassName="issues-group-table-header"
      rowClassName="issues-group-table-row"
      columnsTemplate={getColumnsTemplate()}
      renderTitle={renderTableTitle}
      searchbarClassName={"issues-group-searchbar"}
      searchbarPlaceholder="Search devices"
      apiFunction={getIssueGroupDevices}
      apiKey={GroupAPI.keys.getIssuesGroupDevices}
      extraKeys={[group?.id]}
      emptyStateConfig={{
        title: "Hey, nothing came up",
        message: "Your search had no results, please refine your search and try again",
        titleClassName: "issues-group-empty-title",
        messageClassName: "issues-group-empty-message",
        customImage: EmptyBoxIcon
      }}
      selected={selected}
      excluded={excluded}
      allSelected={allSelected}
      handleSelectedEvent={handleSelectedEvent}
      setElements={setDevices}
    />
  )
}

export default GroupDevices
