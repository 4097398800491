import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Button } from "reactstrap";
import { updateNotificationSettingsRequest } from "../../actions/auth";
import { trackEvent } from "../../utils/gaTracker";
import CancelAccount from "./CancelAccount";
import { useSelector } from 'react-redux';
import CancelMembership from '../profile/CancelMembership';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import amplitude from 'amplitude-js';
import useCanAbility from '../../hooks/useCanAbility';
import { MODIFY_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';

const Subscriptions = (props) => {
  const {
    title = "Subscriptions"
  } = props;

  // Getting needed info from redux store
  const sendNotification = useSelector((state) => state.auth.userSettings.receive_notifications)

  //Component State
  const [notificationEnabled, setNotificationEnabled] = useState(sendNotification);
  const [updatedSettings, setUpdatedSettings] = useState(false);

  //Component Hooks
  const { dispatch } = useMemoizedDispatch();
  const ability = useCanAbility();

  useEffect(() => {
    setNotificationEnabled(sendNotification);
  }, [sendNotification]);

  const toggleNotificationClick = () => {
    trackEvent(`${notificationEnabled ? 'disable' : 'enable'}-notifications`, 'user-settings', 'update-notification-settings');
    amplitude.getInstance().logEvent(`My Account View - Notification Settings - ${notificationEnabled ? 'Unsubscribe' : 'Subscribe'}`);
    if (notificationEnabled) setUpdatedSettings(true);
    dispatch(updateNotificationSettingsRequest( { receive_notifications: !notificationEnabled } ));
  }

  const renderManageNotifications = () => {
    const description = notificationEnabled ? 'Want to stop receiving our App notifications' : 'Want to subscribe and receive our notifications';
    return (
      <Row>
        <Col md={8} sm={8} xs={7} style={{ padding: '0px' }}>
          <p className="option-action">{description}</p>
        </Col>
        <Col md={4} sm={4} xs={5} style={{ padding: '0px' }}>
          {notificationEnabled ?
            <Button
              className="option-button"
              color="link"
              onClick={toggleNotificationClick}
              onKeyUp={toggleNotificationClick}
            >
              Unsubscribe from this list
            </Button> :
            <Button
              type="button"
              color="light"
              className="btn small-btn"
              onClick={toggleNotificationClick}
              onKeyUp={toggleNotificationClick}
            >
              Yes, Subscribe
            </Button>
          }
        </Col>
      </Row>
    );
  }

  const renderCancelMembership = () => {
    const canCancelMembership = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.MEMBERS.GENERAL)
    return canCancelMembership && <CancelMembership />
  }

  const renderDisabledNotifications = () => {
    return (
      <div className='d-flex flex-column justify-content-center'>
        <p className="option-notification-title">Opt Out Successful!</p>
        <p className="option-notification-description">You'll no longer receive App notifications when you have new messages from Cyvatar.ai</p>
      </div>
    );
  }

  return (
    <div className="settings">
      <div className="title">
        {title}
        <p className="title-description">Your recurring subscriptions like App notifications.</p>
      </div>
      <div className="option-container">
        {!notificationEnabled && updatedSettings && renderDisabledNotifications()}
        {renderManageNotifications()}
      </div>
      <CancelAccount />
      {renderCancelMembership()}
    </div>
  );
}

export default withRouter(Subscriptions);

