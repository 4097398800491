import React, { useState, useEffect } from "react"
import { useLocation, useHistory } from "react-router-dom";
import BasicModal from "./BasicModal";

const RedirectPrompt = (props) => {

    const {
        children
    } = props;

    // Component Hooks
    const location = useLocation();
    const history = useHistory();

    // Component state
    const [showRedirectPrompt, setShowRedirectPrompt] = useState(false);
    const [title, setTitle] = useState('');
    const [messsage, setMessage] = useState('');

    // Function to toggle modal
    const toggleModal = () => {
        setShowRedirectPrompt(!showRedirectPrompt);
    }

    // Check for redirect changes
    useEffect(() => {

        const {
            redirected,
            title,
            message,
        } = location?.state || {};

        if(redirected) {
            setShowRedirectPrompt(true);
            setTitle(title);
            setMessage(message);
            // Clean history state
            history.replace({ state: {} })
        }
    }, [location?.state])

    return showRedirectPrompt && (
        <BasicModal
            showModal={showRedirectPrompt}
            toggleModal={toggleModal}
            customClassName="redirect-prompt-modal"
            title={title}
            subtitle={messsage}
        >
            {children}
        </BasicModal>
    )
}

export default RedirectPrompt;
