import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import BasicModal from './BasicModal';
import { onDeleteModalConfigUpdate, onDeleteIssuesSuccess } from '../../actions/issues';
import { setAlert } from '../../actions/alerts';
import API from '../../api';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useCustomMutation from '../../hooks/useCustomMutation';
import { issueTypes } from '../../constants/issues';
import issuesInfoSelector from '../../selectors/issuesInfoSelector';
import useCanAbility from '../../hooks/useCanAbility';
import { DELETE_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';

//  Component
const DeleteIssuesModal = (props) => {
  const {
    issueType = issueTypes.VULNERABILITIES
  } = props;

  //  Watching redux store
  const issue_ids = useSelector((state) => issuesInfoSelector(state, 'deleteModalConfig', issueType)?.issue_ids);
  const isMemberView = useSelector(state => state?.impersonation?.isMemberView);
  //  Initializing API
  const { IssueAPI } = API;

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const {
    data: deleteIssuesResponse,
    error: deleteIssuesError,
    isLoading: deleteIssuesLoading,
    mutate: callDeleteIssues,
  } = useCustomMutation(
    (params) => IssueAPI.handlers.deleteIssues(params),
    IssueAPI.invalidators.deleteIssues,
  );
  const ability = useCanAbility();

  // Component variables
  const canDeleteIssues = ability.can(DELETE_ACTION, PRIVILEGE_SOURCES.ISSUES.GENERAL) || isMemberView;

  //  Function to close modal
  const closeModal = () => {
    dispatch(onDeleteModalConfigUpdate(false, [], false, issueType));
  };

  //  Watching API response
  useEffect(() => {
    if (!deleteIssuesResponse) { return; }
    const { success } = deleteIssuesResponse;
    //  Handling error
    if (!success) {
      dispatch(setAlert(`Error deleting ${issueType === issueTypes.GRC ? 'tasks' : 'issues'}.`, 'danger'));
      return;
    }
    //  Handling success
    dispatch(setAlert(`${issueType === issueTypes.GRC ? 'Tasks' : 'Issues'} deleted successsfully.`, 'success'));
    dispatch(onDeleteIssuesSuccess(issueType));
  }, [deleteIssuesResponse]);

  //  Watching API error
  useEffect(() => {
    if (!deleteIssuesError) { return; }
    const errorMessage = deleteIssuesError?.response?.data?.message || `Error deleting ${issueType === issueTypes.GRC ? 'tasks' : 'issues'}.`;
    dispatch(setAlert(errorMessage, 'danger'));
  }, [deleteIssuesError]);

  //  Function to delete issues
  const deleteIssues = () => {
    callDeleteIssues({ issue_ids, issue_type: issueType });
  };

  //  Rendering
  return (
    <BasicModal
      showModal={true}
      toggleModal={closeModal}
      title={`Delete ${issueType === issueTypes.GRC ? 'Tasks' : 'Issues'}`}
      subtitle={`${issueType === issueTypes.GRC ? 'Tasks' : 'Issues'} in the recycle bin will be deleted after 30 days`}
      customClassName='logout-modal'
    >
      <div className="row-container logout-modal">
        <Button
          color="light-gray left-modal-button"
          outline
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          color="critical"
          disabled={deleteIssuesLoading || !canDeleteIssues}
          className="btn btn-submit font-weight-bold delete-rule-button"
          onClick={() => deleteIssues()}
        >
          Delete
        </Button>
      </div>
    </BasicModal>
  );
};

export default DeleteIssuesModal;
