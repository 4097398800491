import React, { useState, useEffect } from 'react';
import BasicModal from '../layout/BasicModal';
import { Button } from 'reactstrap';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { tagsActions } from '../../constants/tags';
import FiltersButtonGroup from '../layout/filters/FiltersButtonGroup';
import TagComponent from './TagComponent';
import TagsInifiniteDropdown from './TagsInfiniteDropdown';
import _ from 'lodash';
import TagsBulkAddComponent from './TagsBulkAddComponent';

const AddRemoveTagsModal = (props) => {

    const {
        title,
        showModal,
        toggleModal,
        addTagsCallback,
        removeTagsCallback,
        companyId,
        deviceId,
        issueId,
        processing,
        isDisabled,
    } = props;

    // Component state
    const [actionOption, setActionOption] = useState([tagsActions.ADD]);
    const [tags, setTags] = useState([]);

    // Watch selected action option changes to reset tags
    useEffect(() => {
        setTags([])
    }, [actionOption]);

    // Watch show Modal state to reset state
    useEffect(() => {
        // reset state
        if(!showModal) {
            setTags([])
            setActionOption([tagsActions.ADD])
        }
    }, [showModal])

    // Render button to select Add or Remove tag action
    const renderActionOptions = () => {
        const options = [
            { label: "Add tag", className: "light-gray border border-light-gray border-right-0 rounded-left", value: tagsActions.ADD },
            { label: "Remove tag", className: "light-gray border border-light-gray rounded-right", value: tagsActions.REMOVE}
        ];
        return (
            <div className="btn-filter-options btn-add-remove-actions d-inline-block mr-2 mb-4">
                <FiltersButtonGroup
                    data={options}
                    filterValue={actionOption}
                    showNumber={false}
                    callback={({ value }) => setActionOption([value])}
                    wrapperClassName="d-inline-block"
                />
            </div>
        )
    }

    // add selected tag to tags component state
    const addTagtoList = (tag) => {
        setTags([...tags, { label: tag?.label, value: tag?.value , filterOption: tagsActions.ADD, recent: tag?.isNew }])
    }

    // remove selected tag from tags component state
    const removeTagFromList = (tag) => {
        const isTagAdded = (a,b) => ( a.value === b.value && a.label === b.label);
        const updatedTags = [..._.pullAllWith(tags, [tag], isTagAdded)];
        setTags(updatedTags)
    }

    // Render Tags List
    const renderTagsList = () => {
        if(!tags) return;
        const className = actionOption[0] !== tagsActions.ADD ? 'filter-out-tag' : '';
        return tags.map((tag,index) => {
            return (
                <TagComponent
                    key={`tag-${index}`}
                    className={`mr-2 mb-2 ${className}`}
                    label={tag?.label }
                    id={tag?.value}
                    removeTagCallback={() => removeTagFromList(tag)}
                />
            )
        })
    }

    const renderAddTagsSection = () => {
        return (
            <TagsBulkAddComponent
                callback={addTagtoList}
                issueId={issueId}
                deviceId={deviceId}
                companyId={companyId}
                addedTags={tags}
            />
        )
    }

    const onApplyChanges = () => {
        if(actionOption[0] === tagsActions.ADD) {
            addTagsCallback(tags)
        } else {
            removeTagsCallback(tags)
        }
    }

    const renderRemoveTagsSection = () => {
        return (
            <TagsInifiniteDropdown
                companyId={companyId}
                deviceId={deviceId}
                issueId={issueId}
                callback={addTagtoList}
                placeholder="Select or search Tag"
                className="filter-dropdown"
                filteredTags={tags}
                filterOption={tagsActions.ADD}
            />
        )
    }

    const renderFooter = () => {
        const submitLabel = actionOption[0] === tagsActions.ADD ? 'Add tags' : 'Remove tags';
        return (
            <ModalFooter>
                <div className="text-center" >
                    <Button
                        className={`btn btn-light text-dark font-weight-bold }`}
                        type="submit"
                        onClick={onApplyChanges}
                        disabled={processing || isDisabled}
                    >
                        {!processing? submitLabel: 'Processing...'}
                    </Button>
                </div>
            </ModalFooter>
        )
    }

    return (
        <BasicModal
            header={title}
            headerClassName={`modal-header-status`}
            showModal={showModal}
            toggleModal={toggleModal}
            customClassName="add-remove-tags-modal"
            scrollable={true}
        >
            {renderActionOptions()}
            <div className="business-context-tags-section mt-0" >
                <div className="tags-section-title">Tags</div>
                {actionOption[0] === tagsActions.ADD ? renderAddTagsSection() : renderRemoveTagsSection()}
                <div className='tag-components-container'>
                    {renderTagsList()}
                </div>
            </div>
            {renderFooter()}
        </BasicModal>
    )

}

export default AddRemoveTagsModal;
