import React from 'react';
import _ from 'lodash';
import { Card, CardBody } from 'reactstrap';
import ComplianceScoreChart from '../dashboard/charts/ComplianceScoreChart';
import { orderAlphaNumeric } from '../../utils/helpers';

export const ScoreDistributionWidget = (props) => {
    const {
        compliance : { config, distribution },
        showNowState, 
        isInitialState, 
        screenWidth, 
    } = props;

    const renderComplianceScoreChart = () => {
        return (
            <div className="mt-2">
                <ComplianceScoreChart
                    nowSeries={distribution?.current}
                    isInitialState={isInitialState}
                    thenSeries={distribution?.previous}
                    showNowState={isInitialState ? true : showNowState}
                    xAxisCategories={orderAlphaNumeric(_.map(config?.controls, 'code'))}
                    baseline={config?.baseline?.score}
                    isMobile={screenWidth < 577}
                    screenWidth={screenWidth}
                />
            </div>
        );
    };

    return (
        <div className='row align-items-center text-center'>
            <div className='col-sm-12'>
                <Card color="dark" className="mb-0">
                    <CardBody className="score-distribution-vcyso-card" >
                        {renderComplianceScoreChart()}
                    </CardBody>
                </Card>
            </div>
        </div>
    )
};

