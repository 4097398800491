

export default (state = {}, action) => {
    const { type } = action;
    const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);
    
    if (!matches) return state;  
    
    const [, requestName, requestState] = matches;
    
    return {
      ...state,
      // Stores whether a request is happening at the moment or not
      [requestName]: requestState === 'REQUEST',
    };
  };
