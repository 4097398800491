import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import SelectDropdownIndicator from '../../layout/fields/SelectDropdownIndicator';
import PriorityArrow from '../../dashboard/PriorityArrow';
import {
  mapIssuesOptionsToSelectOptions,
  findIssuSelectedOption,
} from '../../../utils/issuesHelpers';
import { issueTypes } from '../../../constants/issues';
import { getSafeArray } from '../../../utils/helpers';
import issuesSimpleOptionsSelector from '../../../selectors/IssuesSimpleOptionsSelector';

const GrcIssuesPrioritySelect = (props) => {
  //  Parsing props
  const {
    selectedPriority,
    extraClassName,
    onChange,
    error
  } = props;

  //  Watching redux store
  const priorities = useSelector((state) => issuesSimpleOptionsSelector(state, issueTypes?.GRC, 'priorities', true));

  //  Component state
  const [options, setOptions] = useState([]);

  //  Watching priorities changes
  useEffect(() => {
    setOptions(mapIssuesOptionsToSelectOptions(priorities));
  }, [priorities?.length]);

  //  Function to find priority from option
  const findPriority = (option) => {
    const safePriorities = getSafeArray(priorities);
    const priority = safePriorities.find((item) => item?.id === option?.value);
    return priority
  }

  // Select Dropdown Indicator
  const DropdownIndicator = (props) => {
    return <SelectDropdownIndicator {...props} />
  };

  //  Function to render dropdown options
  const Option = (props) => {
    const { data, ...restOfProps } = props;
    const priority = findPriority(data);
    if (!priority) { return null; }
    return (
      <components.Option {...restOfProps}>
        <PriorityArrow
          priority={priority}
          showLabel={true}
          labelExtraClassName="dropdown-priority-label"
          className="modal-priority-arrow"
        />
      </components.Option>
    );
  };

  //  Function to render value container
  const ValueContainer = (props) => {
    const { children, ...restOfProps } = props;
    //  Returning if there is no value
    if (!props.hasValue) {
      return (
        <components.ValueContainer {...restOfProps}>
          {children}
        </components.ValueContainer>
      );
    }

    //  Returning if no priority was found
    const option = props?.getValue();
    const safeOptions = getSafeArray(option);
    const priority = findPriority(safeOptions[0]);
    if (!priority) {
      return (
        <components.ValueContainer {...restOfProps}>
          {children}
        </components.ValueContainer>
      );
    }

    //  Returning priority
    return (
      <components.ValueContainer {...restOfProps}>
        <PriorityArrow
          priority={priority}
          showLabel={true}
          labelExtraClassName="dropdown-priority-label"
          className="modal-priority-arrow"
        />
      </components.ValueContainer>
    );
  };

  // Function to render error message
  const renderError = (error) => {
    if(!error) return null;
    return (
      <div className="grc-input-error">
          {error?.message}
      </div>
    )
  }

  //  Rendering
  return (
    <div className={`${extraClassName}`}>
      <Select
        value={findIssuSelectedOption(selectedPriority, options)}
        options={options}
        placeholder="Priority"
        onChange={onChange}
        className="react-select-container"
        classNamePrefix="react-select"
        menuPosition={'fixed'}
        components={{ DropdownIndicator, Option, ValueContainer }}
      />
      {renderError(error)}
    </div>
  );
};

export default GrcIssuesPrioritySelect;
