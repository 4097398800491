import React from 'react';
import { useSelector } from 'react-redux';
import { removeItemFromArray } from '../../../utils/helpers';

const SelectableOptionsRow = (props) => {
  //  Parsing props
  const {
    containerClassName = '',
    iconClassName = '',
    labelClassName = '',
    elementClassName = '',
    label = '',
    renderDropdown,
    elementSelector = '',
    changeAction,
    canSelectAll = false,
  } = props;

  //  Getting elements from redux
  const elements = useSelector((state) => state.ruleSet?.[elementSelector]);
  const allSelected = useSelector((state) => state.ruleSet.all_members);

  //  Function to handle options change
  const handleOptionsChange = (element) => {
    //  Verifying existence
    const matchingElement = elements.find((item) => item?.value === element?.value);
    if (matchingElement) { return; }
    //  -1 is the value for all selected elements
    const newElements = element?.value === -1 ? [] : [...elements, element];
    const newFlag = (element?.value === -1);
    changeAction(newElements, newFlag);
  };

  //  Function to remove element from list
  const removeElement = (index) => {
    const newSelectedElements = removeItemFromArray(elements, index);
    changeAction(newSelectedElements);
  };

  // Function to render elements
  const renderElements = () => {
    if (canSelectAll && allSelected) {
      return (
        <div className={elementClassName}>
          <div className="all-label">
            {'* All current and future members who have the partner installed'}
          </div>
        </div>
      );
    }
    return elements.map((element, index) => {
      return (
        <div className={elementClassName} key={index}>
          <div className="element-label">{element?.label}</div>
          <div
            className="bx bx-x remove-icon"
            onClick={() => removeElement(index)}
          />
        </div>
      );
    });
  };

  //  Rendering
  return (
    <div className={containerClassName}>
      <div className={iconClassName} />
      <div className={labelClassName}>{label}</div>
      <div className="options-container">
        {renderDropdown(elements, handleOptionsChange, allSelected)}
      </div>
      <div className="elements-container">
        {renderElements()}
      </div>
    </div>
  );
};

export default SelectableOptionsRow;
