import React from 'react';
import useCustomQuery from '../../../hooks/useCustomQuery';
import API from '../../../api';
import PARTNERS from '../../../constants/partners';
import CustomSpinner from '../../layout/CustomSpinner';
import { formatDate } from '../../../utils/helpers';

// TODO: adapt the component in case any partner requires information directly from the partner. At the moment only Obsidian works, divide in files
const GRCDetailFromPartner = ({ issue }) => {
  const { PartnerAPI } = API;

  const {
    data: issueDetailsResponse,
    isLoading: issueDetailsLoading,
  } = useCustomQuery(
    [PartnerAPI.keys.getPartnerIssue, issue?.code],
    ({ queryKey }) => PartnerAPI.handlers.getPartnerIssue(queryKey[1]),
  );

  const renderCurrentAndRec = (current, rec, compliant) => {
    return (
      <>
        <span style={{ color: compliant ? '#0EB082' : '#ff5e54' }}>
          {current}
        </span>
        <span> / {rec}</span>
      </>
    )
  }

  const renderConfigurationStatus = () => {
    if (!issueDetailsResponse) return null;

    const {
      value,
      benchmark: { value: benchmarkValue, compliant }
    } = issueDetailsResponse;

    switch (value.__typename) {
      case 'ConfigurationControlValuePercent':
        return renderCurrentAndRec(
          `${value.percent}%`,
          `${benchmarkValue.percent}%`,
          compliant
        );

      case 'ConfigurationControlValueBoolean':
        const currentText = value.boolean ? 'True' : 'False';
        const recText = benchmarkValue.boolean ? 'True' : 'False';
        return renderCurrentAndRec(currentText, recText, compliant);

      case 'ConfigurationControlValueNumber':
        return renderCurrentAndRec(value.number, benchmarkValue.number, compliant);
      default:
        break;
    }
  }

  const renderRiskManagement = () => {
    const { accepted, settings } = issueDetailsResponse;
    const { acceptForever, acceptUntil } = settings;
    const until = !acceptForever ? formatDate(acceptUntil) : 'Forever';

    return (
      <div className='obsidian-configuration-setting'>
        <p>Risk Management</p>
        <p style={{ color: accepted ? '#0EB082' : '' }}>
          Accept
        </p>
        {accepted && <p>Until: {until}</p>}
      </div>
    )
  }

  return (
    <div className='obsidian-configuration-management'>
      {issueDetailsLoading ?
        (<CustomSpinner />) :
        (<>
          <div className='obsidian-configuration-setting'>
            <p>Status</p>
            <p>{renderConfigurationStatus()}</p>
          </div>
          {renderRiskManagement()}
        </>)
      }
    </div>
  );
};

export default GRCDetailFromPartner;
