import {
  SET_HOVERED_RISK_BUBBLE,
  SET_RISK_TREATMENT_FILTER,
  SET_SELECTED_RISK_STATEMENT,
  BUSINESS_RISK_CONFIG_FAILURE,
  BUSINESS_RISK_CONFIG_SUCCESS,
  RISK_STATEMENTS_FAILURE,
  RISK_STATEMENTS_SUCCESS,
  RISK_STATEMENTS_UPDATE_SUCCESS,
  OPPORTUNITIES_TO_IMPROVE_FAILURE,
  OPPORTUNITIES_TO_IMPROVE_SUCCESS,
  RISK_STATEMENT_STATUS_FAILURE,
  RISK_STATEMENT_STATUS_SUCCESS,
} from './types';
import { errorActionCreator } from './action-creators/errors';
import { successActionCreator } from './action-creators/success';
import { setAlert } from './alerts';
import errorMessages from '../constants/errorMessages';
import { alertTypes } from '../constants/alerts';
import { invalidateQueriesByKeys } from '../utils/queryHelpers';

export const getBusinessRiskConfig = () => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      const { VcysoAPI } = API;
      const response = await VcysoAPI.handlers.getBusinessRiskConfig();
      dispatch(successActionCreator(BUSINESS_RISK_CONFIG_SUCCESS, response?.data));
    } catch (error) {
      const message = error?.response?.data?.errorMessage || errorMessages.defaultGet
      dispatch(errorActionCreator(BUSINESS_RISK_CONFIG_FAILURE, { message }))
      console.log(error);
    }
  };
};

export const getRiskStatements = (companyId) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      const { VcysoAPI } = API;
      const response = await VcysoAPI.handlers.getRiskStatements(companyId);
      dispatch(successActionCreator(RISK_STATEMENTS_SUCCESS, response?.data));
    } catch (error) {
      const message = error?.response?.data?.errorMessage || errorMessages.defaultGet
      dispatch(errorActionCreator(RISK_STATEMENTS_FAILURE, { message }));
    }
  };
};

//params: { statement_id, field, value, company_id }
export const updateRiskStatements = (params) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      const { VcysoAPI } = API;
      const response = await VcysoAPI.handlers.updateRiskStatement(params);
      invalidateQueriesByKeys(VcysoAPI.invalidators.updateRiskStatement, queryClient);
      dispatch(successActionCreator(RISK_STATEMENTS_UPDATE_SUCCESS, response?.data));
      dispatch(setAlert("Statement updated", alertTypes?.success));
    } catch (error) {
      dispatch(setAlert(error?.response?.data?.errorMessage || errorMessages?.defaultPost, alertTypes.error));
      console.log(error);
    }
  };
};

export const setHoveredRiskBubble = (code) => {
  return {
    type: SET_HOVERED_RISK_BUBBLE,
    payload: code,
  };
};

export const selectRiskStatement = (code) => {
  return {
    type: SET_SELECTED_RISK_STATEMENT,
    payload: code,
  };
};

export const setRiskTreatmentFilter = (code) => {
  return {
    type: SET_RISK_TREATMENT_FILTER,
    payload: code,
  };
};

// CHANGES TO GET DATA FROM OPPORTUNITIES TO IMPROVE
export const getOpportunitiesToImprove = (companyId) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      const { VcysoAPI } = API;
      const response = await VcysoAPI.handlers.getOpportunitiesToImprove(companyId);
      dispatch(successActionCreator(OPPORTUNITIES_TO_IMPROVE_SUCCESS, response?.data));
    } catch (error) {
      const message = error.response.data.errorMessage || errorMessages.defaultGet
      dispatch(errorActionCreator(OPPORTUNITIES_TO_IMPROVE_FAILURE, { message }));
      console.log(error);
    }
  };
}

export const riskStatementStatusData = (company_id) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      const { VcysoAPI } = API;
      const response = await VcysoAPI.handlers.riskStatementStatusData(company_id);
      dispatch(successActionCreator(RISK_STATEMENT_STATUS_SUCCESS, response?.data));
    } catch (error) {
      const message = error?.response?.data?.message || errorMessages?.defaultGet
      dispatch(errorActionCreator(RISK_STATEMENT_STATUS_FAILURE, { message }));
      console.log(error);
    }
  };
}