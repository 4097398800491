import React from 'react';
import ContextMenuOption from './ContextMenuOption';
const ContextMenuDropdown = (props) => {
    const {
        label,
        options,
        className="",
    } = props;

    const renderOptions = () => {
        if(!options) return null;
        return options.map((option, index) => {
            const isFirst = index === 0;
            const isLast = index === options.length - 1;
            const borderClassName = `${isFirst ? 'rounded-top': ''} ${isLast ? 'rounded-bottom': ''}`
            return (
                <ContextMenuOption
                    key={`context-menu-opt-dropdown-${index}`}
                    label={option?.label}
                    callback={option?.callback}
                    className={`${borderClassName} ${option?.className}`}
                    isDisabled={option?.isDisabled}
                />
            )
        })
    }
    return (
        <div
            className="context-menu-dropdown"
        >
            <ContextMenuOption label={label} className={`context-menu-dropdown-option ${className}`}/>
            <div className="context-menu-dropdown-content">
                {renderOptions()}
            </div>
        </div>
    )
}

export default ContextMenuDropdown
