import {
  ON_RULE_SET_LOADED,
  ON_TAGS_ARRAY_CHANGES,
  ON_COMPANY_ARRAY_CHANGES,
  ON_RULE_PARTNER_CHANGE,
  ON_RULE_ACTION_CHANGE,
  ON_RULE_SCHEDULE_CHANGE,
  ON_ADD_ACTION,
  ON_ADD_PARTNER,
  ON_REMOVE_ACTION,
  ON_SAVE_RULE_SET_SUCCESS,
  ON_RULE_SET_DETAIL_UNMOUNT,
} from './types';

export const onRuleSetLoaded = (ruleSet) => {
  return {
    type: ON_RULE_SET_LOADED,
    payload: { ruleSet },
  }
};

export const onCompanyArrayChanges = (companies, all_members = false) => {
  return {
    type: ON_COMPANY_ARRAY_CHANGES,
    payload: { companies, all_members },
  };
};

export const onTagsArrayChanges = (tags) => {
  return {
    type: ON_TAGS_ARRAY_CHANGES,
    payload: { tags }
  }
};

export const onRulePartnerChange = (ruleIndex, partnerOption) => {
  return {
    type: ON_RULE_PARTNER_CHANGE,
    payload: { ruleIndex, partnerOption },
  };
};

export const onRuleActionChange = (ruleIndex, actionIndex, actionOption) => {
  return {
    type: ON_RULE_ACTION_CHANGE,
    payload: { ruleIndex, actionIndex, actionOption },
  };
};

export const onRuleScheduleChange = (ruleIndex, actionIndex, schedule, repeat, run_now) => {
  return {
    type: ON_RULE_SCHEDULE_CHANGE,
    payload: { schedule, repeat, ruleIndex, actionIndex, run_now }
  };
};

export const onAddAction = (ruleIndex) => {
  return {
    type: ON_ADD_ACTION,
    payload: ruleIndex
  };
};

export const onAddPartner = () => {
  return {
    type: ON_ADD_PARTNER,
    payload: null,
  };
};

export const onRemoveAction = (ruleIndex, actionIndex) => {
  return {
    type: ON_REMOVE_ACTION,
    payload: { ruleIndex, actionIndex },
  };
};

export const onSaveRuleSetSuccess = () => {
  return {
    type: ON_SAVE_RULE_SET_SUCCESS,
    payload: null,
  }
};

export const onRuleSetDetailUnmount = () => {
  return {
    type: ON_RULE_SET_DETAIL_UNMOUNT,
    payload: null,
  };
};
