import React from 'react';
import { LIST_ACTION, PRIVILEGE_SOURCES } from '../constants/privileges';
import useCanAbility from './useCanAbility';
import useShowModal from './useShowModal';

const useUserManagerActions = (props) => {

  const {
    currentUser, 
    ids = [], 
    isActive = false, 
    isVerified = false
  } = props;

  // Hooks
  const { // Delete modal
    showModal: showDeleteModal,
    toggleModal: toggleDeleteModal,
  } = useShowModal();
  const { // Accept modal
    showModal: showAcceptModal,
    toggleModal: toggleAcceptModal,
  } = useShowModal();
  const { // Discard/deny modal
    showModal: showDiscardModal,
    toggleModal: toggleDiscardModal,
  } = useShowModal();
  const { // Resend modal
    showModal: showResendModal,
    toggleModal: toggleResendModal,
  } = useShowModal();
  const { // Disable modal
    showModal: showDisableModal,
    toggleModal: toggleDisableModal,
  } = useShowModal();

  // Component Hooks
  const ability = useCanAbility();
  const canUserManage = ability.can(LIST_ACTION, PRIVILEGE_SOURCES.PROFILE.USER_MANAGEMENT);

  //  Hook variables
  const actions = [
    {
      iconClassName: 'bx bx-trash',
      callback: toggleDeleteModal,
      tooltipText: 'Delete member',
      disabled: !canUserManage || ids.includes(currentUser?.id),
      show: !ids.includes(currentUser?.id) && isVerified,
      ifDisabledTooltipText: 'Action not permited'
    },
    {
      iconClassName: 'bx bx-check-circle',
      callback: toggleAcceptModal,
      tooltipText: 'Accept request',
      disabled: !canUserManage,
      show: (isActive && !isVerified),
      ifDisabledTooltipText: 'Action not permited'
    },
    {
      iconClassName: 'bx bx-x-circle',
      callback: toggleDiscardModal,
      tooltipText: 'Discard request',
      disabled: !canUserManage,
      show: (isActive && !isVerified),
      ifDisabledTooltipText: 'Action not permited'
    },
    {
      iconClassName: 'bx bx-share',
      callback: toggleResendModal,
      tooltipText: 'Resend invitation',
      disabled: !canUserManage,
      show: !isVerified,
      ifDisabledTooltipText: 'Action not permited'
    },
  ];

  // Exporting hook assets
  return {
    actions,
    showDeleteModal,
    toggleDeleteModal,
    showAcceptModal,
    toggleAcceptModal,
    showDiscardModal,
    toggleDiscardModal,
    showResendModal,
    toggleResendModal,
    showDisableModal,
    toggleDisableModal
  };
}

export default useUserManagerActions;