import React from 'react';
import { connect } from 'react-redux';
import { UisRocket, UisCommentDots } from '@iconscout/react-unicons-solid';
import { Link } from 'react-router-dom';
import { Row, Col } from "reactstrap";
import HalfScreenImageContainer from '../layout/HalfScreenImageContainer';
import welcomeImage from "../../assets/images/assessment-cyvatar-2x.png";
import amplitude from 'amplitude-js';
import { trackEvent } from '../../utils/gaTracker';
import 'boxicons';

const AssessmentIcon = ({ text, icon, customStyle }) => {
    return (
        <div className="justify-content-start" aria-hidden="true">
            <div
                className="info-icon normal-text-style d-inline-flex flex-row justify-content-center"
                style={customStyle}
            >
                <div className=" mx-auto justify-content-center">
                    {icon}
                </div>
                <div className="justify-content-center text">
                    {text}
                </div>
            </div>
        </div>
    );
}

const AssessmenteWelcomeContent = ({ leftColumnNumber, rightColumnNumber, customClassName, skipNowButton }) => {
    const mainTitle = "Cybersecurity-as-a-Service,";
    const complementTitle = "at Your Service";
    const message = "Revolutionary subscription based cybersecurity.";
    const assessmentInfo = "Let us build, implement, and manage your cybersecurity strategy for an all-in-one monthly cost. Success is just a few clicks away";

    return (
        <Row className={customClassName} >
            <Col
                sm={10}
                md={leftColumnNumber.md}
                lg={leftColumnNumber.lg}
                className="assessment-welcome normal-text-style text-white"
            >
                <div
                    className="outline-none"
                    tabIndex="0"
                    role="text"
                    aria-label={`${mainTitle} ${complementTitle}. ${message}. ${assessmentInfo}`}
                >
                    <div className="greeting mb-2" aria-hidden="true">{mainTitle}</div>
                    <div className="subtitle mb-2" aria-hidden="true">{complementTitle}</div>
                    <div className="message mb-3 mb-md-4" aria-hidden="true">{message}</div>
                    <div className="assessment-info mb-3 mb-md-4" aria-hidden="true">{assessmentInfo}</div>
                </div>
                <div className="text-light">
                    <Row
                        className="justify-content-start"
                        style={{ marginBottom: "28px" }}
                        aria-label="Take free 5 minute Cyber Checkup. From there, you’ll get your company’s roadmap to success"
                        role="text"
                    >
                        <AssessmentIcon
                            icon={<UisCommentDots />}
                            text="Take free 5 minute Cyber Checkup"
                            customStyle={{ paddingLeft: "0.625rem" }}
                        />
                        <AssessmentIcon
                            icon={<box-icon type='solid' name='rocket' color="#6de0f6" />}
                            text="From there, you’ll get your company’s roadmap to success"
                            customStyle={{ paddingLeft: "0.625rem" }}
                        />
                    </Row>
                    <div className="d-flex align-items-center" style={{ fontWeight: "600" }} >
                        <Link
                            to="/cybercheckup"
                            style={{ marginRight: "24px", width: "180px" }}
                            className="btn btn-light text-dark start-assessment-btn"
                            onClick={() => {
                                trackEvent("take_free_assessment", "assessment", "take_free_assessment");
                                console.log('te iban a pagar', amplitude.getInstance())
                                amplitude.getInstance().logEvent('Welcome View - Cyber Checkup - Try it');
                            }}
                        >
                            Try it
                        </Link>
                        {skipNowButton}
                    </div>
                </div>
            </Col>
            <HalfScreenImageContainer
                columnNumber={{ md: rightColumnNumber.md, lg: rightColumnNumber.lg }}
                image={welcomeImage}
                customClassName=" justify-content-start px-5"
                imageMaxHeight={"540px"}
            />
        </Row>
    );
};

export default connect(null)(AssessmenteWelcomeContent);
