import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Badge } from 'reactstrap';
import moment from 'moment';
import makeErrorMessage from '../../selectors/errorsSelector';
import makeLoadingState from '../../selectors/loadingSelector';
import { fetchPartners, reloadPartners, setPartnersSort, setPartnersFilter, clearPartnersFilters, setPartnerIdAndName } from "../../actions/partners";
import ConfigPartner from "./ConfigPartner";
import TableList from '../layout/TableList';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import companyIdSelector from '../../selectors/companyIdSelector';
import companyInfoSelector from '../../selectors/companyInfoSelector';
import useCanAbility from '../../hooks/useCanAbility';
import { CREATE_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';
import { useHistory } from "react-router-dom";
import ConnectionStatusBadge from '../connection-status/ConnectionStatusBadge';

const CustomerSolutionList = (props) => {
  //  Component hooks
  const { dispatch } = useMemoizedDispatch();

  //  Watching redux store
  const getLoadingState = makeLoadingState(['PARTNERS_LIST']);
  const getErrorMessage = makeErrorMessage(['PARTNERS_LIST']);
  const isLoading = useSelector((state) => getLoadingState(state));
  const errorMessage = useSelector((state) => getErrorMessage(state));
  const partners = useSelector((state) => state.partners);
  const shouldReload = useSelector((state) => state.partners?.shouldReload);
  const roleName = useSelector((state) => state.auth.user.roles[0]?.name);
  const companyId = useSelector((state) => companyIdSelector(state));
  const companyName = useSelector((state) => companyInfoSelector(state, 'name', false));

  //  Component state
  const [modalConfig, setModalConfig] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // Component Hooks
  const ability = useCanAbility();
  const history = useHistory();

  // Component Variables
  const canSetupPartners = ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.COMPANY_SOLUTIONS.PARTNER);

  const partnerStatus = {
    0: { label: "Error", color: "critical" },
    1: { label: "Installed", color: "success" },
    2: { label: "Warning", color: "warning" },
    3: { label: "Not Subscribed yet…", color: "gray" },
    4: { label: "Setting up...", color: "light" },
    5: { label: "Waiting for scan", color: "light" },
  }

  useEffect(() => {
    if (shouldReload) setShowSuccessMessage(true);
  }, [shouldReload]);

  const handleShowModal = () => {
    setShowModal(!showModal);
    setShowSuccessMessage(false);
  }

  const handleManagePartner = (partner_id, partner_name, currentConfig, fields, hasEnabled) => {
    if (hasEnabled) {
      dispatch(setPartnerIdAndName(partner_id, partner_name));
      history.push({
        pathname: "/solution-settings",
        search: `?partnerId=${partner_id}`,
      });
    } else {
      setModalConfig({
      partner_id,
      partner_name,
      currentConfig,
      fields,
      company_id: companyId,
      company_name: companyName
    });
    handleShowModal();
    }
  }

  const handleOpenLogs = (partner_id, partner_name) => {
    dispatch(setPartnerIdAndName(partner_id, partner_name));
    history.push({
      pathname: "/solution-logs",
    });
  }

  const renderPartnerStatus = ({ id, company_partners }) => {
    const status = company_partners?.length > 0 ? company_partners[0].status : null
    return (
      <ConnectionStatusBadge
        componentId={`connection-status-badge-${id}`}
        statusId={status}
      />
    );
  }

  const renderPartnerActions = ({ id, name, company_partners, fields }) => {
    const hasEnabled = company_partners?.length > 0
    if (hasEnabled) {
      return (
        <React.Fragment>
        <div
          className="d-flex solution-actions
          align-items-center setup-link"
        >
        <i
          onClick={() => { handleManagePartner(id, name, company_partners?.length > 0 ? company_partners[0] : null, fields, false); }}
          className='bx bx-cog bx-xs'>
          </i>
          <i
          onClick={() => { handleOpenLogs(id, name); }}
          className='bx bx-spreadsheet bx-xs'>
          </i>
          <div className="d-flex
          align-items-center setup-link enabled"
          onClick={() => { handleManagePartner(id, name, company_partners?.length > 0 ? company_partners[0] : null, fields, true); }}>
             <i className='bx bx-wrench bx-xs'></i>
          <span className="label">Manage</span>
          </div>
        </div>
        </React.Fragment>
      )
    } else {
      return (<div
        className="d-flex
        align-items-center setup-link disabled"
        onClick={() => { handleManagePartner(id, name, company_partners?.length > 0 ? company_partners[0] : null, fields, false); }}
      >
        <i className={'bx bx-plus-circle bx-xs'}></i>
        <span className="label">Set up solution</span>
      </div>);
    }
  }

  const renderSolution = (row) => {
    return <div> {row?.solutions?.length > 0 ? row?.solutions[0].name : '-'} </div>
  }

  const renderLatestSync = ({ company_partners }) => {
    const syncInfo = company_partners?.length > 0 ? company_partners[0]?.last_sync : null;
    const syncDate = syncInfo?.created_at;
    const formattedDate = moment(new Date(syncDate)).format('LLL');
    return (
      <div> {syncDate && formattedDate ? formattedDate : ' - '} </div>
    )
  }

  const renderOpenIssues = ({ open_issues }) => {
    return (
      <div>{`${open_issues} open issues`}</div>
    )
  }

  const columns = [
    {
      displayName: "Partner name",
      fieldName: 'name',
      headerClassNameWrapper: 'name-header',
      sortFieldName: 'name',
    },
    {
      displayName: "Solution",
      fieldName: 'solution',
      sortFieldName: 'solution_name',
      headerClassNameWrapper: 'solution-header',
      renderCustomComponent: renderSolution
    },
    {
      displayName: "Status",
      fieldName: 'status',
      sortFieldName: 'status',
      headerClassNameWrapper: 'status-header',
      renderCustomComponent: renderPartnerStatus
    },
    ...canSetupPartners ? [
      {
        displayName: "Actions",
        fieldName: null,
        sortFieldName: null,
        headerClassNameWrapper: 'actions-header',
        renderCustomComponent: renderPartnerActions
      }
    ] : [],
    {
      displayName: "Latest Sync",
      fieldName: 'last_sync',
      sortFieldName: 'last_sync',
      headerClassNameWrapper: 'last-sync-header',
      renderCustomComponent: renderLatestSync
    },
    {
      displayName: "Open Issues",
      fieldName: 'open_issues',
      sortFieldName: 'open_issues',
      headerClassNameWrapper: 'open-issues-header',
      renderCustomComponent: renderOpenIssues
    },
  ]

  //  Function to get view title
  const getPageTitle = () => {
    if (!companyName) { return 'Listing all partners'; }
    return `${companyName}'s solutions`;
  };

  //  Rendering
  return (
    <React.Fragment>
      {modalConfig &&
        <ConfigPartner
          toggleModal={handleShowModal}
          showModal={showModal}
          showSuccessMessage={showSuccessMessage}
          modalConfig={modalConfig}
        />
      }
      <TableList
        fetchList={(params) => dispatch(fetchPartners(params))}
        reloadList={(params) => dispatch(reloadPartners(params))}
        data={partners}
        columns={columns}
        customClassName="customer-partners-list"
        setFilter={(...params) => dispatch(setPartnersFilter(...params))}
        clearAllFilters={() => dispatch(clearPartnersFilters())}
        setSort={(params) => dispatch(setPartnersSort(params))}
        activeFiltersDisplayInfo={{
          display: true,
          labels: {
            noFilters: getPageTitle(),
            filteringBy: `${getPageTitle()} filtered by:`,
            total: {
              singular: 'partner',
              plural: 'partners'
            }
          }
        }}
        isLoading={isLoading}
        errorMessage={errorMessage}
        searchPlaceholder="Search by name"
        extraParams={{ company_id: companyId }}
        companyId={companyId}
      />
    </React.Fragment>
  )
};

export default CustomerSolutionList;
