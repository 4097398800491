import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { Form } from 'react-final-form'
import { setAlert } from '../../actions/alerts';
import { alertTypes } from '../../constants/alerts';
import errorMessages from '../../constants/errorMessages';
import { referUserForm } from '../../constants/formTemplates';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import companyIdSelector from '../../selectors/companyIdSelector';
import CustomForm from '../CustomForm/CustomForm';
import BasicModal from '../layout/BasicModal';
import { validateEmail, validateEmptyTextField } from '../../utils/validations';
import API from '../../api';
import useCustomMutation from '../../hooks/useCustomMutation';

const ReferUserModal = (props) => {
    const {
        showModal,
        toggleModal
    } = props;

    //  Initializing APIs
    const { UserAPI } = API;

    //Component hooks
    const { dispatch } = useMemoizedDispatch()

    //Component state
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    //  Getting needed info from redux store
    const company_id = useSelector(state => companyIdSelector(state));

    //  Calling API for tabs
    const {
        data: referUserResponse,
        error: referUserError,
        isLoading: referUserProcessing,
        mutate: postReferUser,
    } = useCustomMutation(
        (params) => UserAPI.handlers.postReferUser(params),
        UserAPI.invalidators.postReferUser
    );

    //Check for errors to show alerts
    useEffect(() => {
        if (referUserError) {
            dispatch(setAlert(referUserError?.response?.data?.errorMessage || errorMessages?.defaultPost, alertTypes.error));
        }
    }, [referUserError])

    //Check for success response
    useEffect(() => {
        if (referUserResponse) {
            //window.open(process.env.REACT_APP_REFERRER_URL, '_blank');
            setShowSuccessMessage(true)
        }
    }, [referUserResponse, setShowSuccessMessage])

    //Reset state once modal closes
    useEffect(() => {
        if (showModal) {
            setShowSuccessMessage(false)
        }
    }, [showModal, setShowSuccessMessage])

    const onSubmit = (values) => {
        const params = {
            ...values,
            company_id
        }
        postReferUser(params);
    }

    const validateForm = (formValues) => {
        const { first_name, last_name, email, company_name } = formValues;
        const errors = {};
        const validName = new RegExp('^[A-Za-z]+$');

        const invalidNameError = 'Please enter a valid name';

        if (!validName.test(first_name) || !first_name) {
            errors.first_name = invalidNameError;
        }

        if (!validName.test(last_name) || !last_name) {
            errors.last_name = invalidNameError;
        }

        const companyError = validateEmptyTextField(company_name);
        if (companyError) {
            errors.company_name = companyError;
        }

        if (validateEmail(email)) {
            errors.email = validateEmail(email);
        }

        return errors;
    }

    const renderForm = (props) => {
        //  Parsing params
        const { handleSubmit, invalid } = props;
        return (
            <CustomForm
                formTemplate={referUserForm}
                onSubmit={(event) => {
                    handleSubmit(event);
                    if (invalid) dispatch(setAlert(errorMessages.MISSING_FIELDS, alertTypes.error));
                }}
            >
                <div className="d-flex mb-3" >
                    <Button
                        color="light"
                        type="submit"
                        className="btn-send-invite mx-auto"
                        disabled={referUserProcessing}
                    >
                        {referUserProcessing ? 'Processing...' : 'Send invite'}
                    </Button>
                </div>
            </CustomForm>
        )
    }

    const renderReferForm = () => {
        return (
            <>
                <div className="form-message mb-3 text-center"> Leave us your friend’s info below.</div>
                <Form
                    onSubmit={onSubmit}
                    validate={validateForm}
                    shouldValidate={true}
                    render={renderForm}
                />
            </>
        )

    }

    const renderSuccessMessage = () => {
        return (
            <div className="success-message">
                <div className="title mx-auto">Thank you!</div>
                <div className="message mx-auto">
                    We’ll be in touch to let you know when your friend joins Cyvatar.
                </div>
                <div className="btn-container text-center">
                    <Button
                        color="light"
                        onClick={() => toggleModal()}
                        style={{ minWidth: "115px" }}
                    >
                        OK
                    </Button>
                </div>
            </div>
        )

    }

    return (
        <div>
            <BasicModal
                title="Refer a friend"
                subtitle="Earn 20% commissions from Cyvatar CSaaS sales. Secure More Companies. Make a Difference."
                showModal={showModal}
                toggleModal={toggleModal}
                customClassName={`refer-user-modal ${showSuccessMessage ? 'success-message-modal' : ''}`}
            >
                {showSuccessMessage ? renderSuccessMessage() : renderReferForm()}
            </BasicModal>
        </div>
    )
}

export default ReferUserModal;
