import React, { useEffect, useState } from 'react';
import ScheduleEdit from './ScheduleEdit';
import RepeatEdit from './RepeatEdit';
import CustomizedCheckbox from '../../layout/CustomizedCheckbox';
import { mapScheduleToString } from '../../../utils/automationHelper';

const ScheduleManager = (props) => {
  //  Parsing props
  const {
    schedule,
    repeat,
    runNow,
    changeSchedule,
    changeRepeat,
    setRunNow
  } = props;

  //  Component state
  const [showRepeat, setShowRepeat] = useState(!!repeat);

  useEffect(() => {
    if (!showRepeat) { changeRepeat(null); }
  }, [showRepeat]);

  //  Function to render repeat
  const renderRepeat = () => {
    if (!showRepeat) { return null; }
    return (
      <RepeatEdit
        schedule={schedule}
        repeat={repeat}
        changeRepeat={changeRepeat}
      />
    );
  };

  //  Function to render checkbox row
  const renderCheckboxRow = (label, value, editValue) => {
    return (
      <div className="checkbox-row">
        <CustomizedCheckbox
          checked={value}
          onChangeCallback={editValue}
        />
        <div className="checkbox-label">{label}</div>
      </div>
    );
  };

  //  Rendering
  return (
    <div className="schedule-tab-content">
      <ScheduleEdit
        repeat={repeat}
        schedule={schedule}
        changeSchedule={changeSchedule}
      />
      {renderCheckboxRow('Repeat', showRepeat, setShowRepeat)}
      {renderCheckboxRow('Also Run now', runNow, setRunNow)}
      {renderRepeat()}
      <div className="schedule-summary">
        {`Summary: ${mapScheduleToString(schedule, repeat)}`}
      </div>
    </div>
  );
};

export default ScheduleManager;
