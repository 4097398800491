import cyvatarAxios from '../axios';
import { GET_SOLUTIONS, GET_SOLUTION_STATUS, GET_SOLUTIONS_BY_COMPANY_ID } from '../ApiKeys';

const getSolutions = async (company_id) => {
  const response = await cyvatarAxios.get('/api/workbench/solution', { params: { company_id } })
  const { data = {} } = response;
  const { solutions = [] } = data;
  return solutions || [];
};

const changeSolutions = async (params) => {
  const { company_id, solutionsIndexMap } = params
  const response = await cyvatarAxios.post('/api/workbench/solution/change_solutions', { solutionsIndexMap }, { params: { company_id } });
  const { data } = response;
  return data;
};

const revertSolutionsToDefault = async (company_id) => {
  const response = await cyvatarAxios.post('/api/workbench/solution/revert_solutions', {}, { params: { company_id } });
  const { data } = response;
  return data;
};

const getSolutionStatus = async (company_id) => {
  const response = await cyvatarAxios.get('/api/workbench/solution/solution_status', { params: { company_id } });
  const { data } = response;
  return data;
};

const getSolutionsbyCompanyId = async (company_id) => {
  const response = await cyvatarAxios.get(`/api/company/solutions/${company_id}`);
  const { data } = response;
  return data;
};

export const SolutionAPI = {
  handlers: {
    getSolutions,
    changeSolutions,
    revertSolutionsToDefault,
    getSolutionStatus,
    getSolutionsbyCompanyId
  },
  keys: {
    getSolutions: GET_SOLUTIONS,
    getSolutionStatus: GET_SOLUTION_STATUS,
    getSolutionsbyCompanyId: GET_SOLUTIONS_BY_COMPANY_ID
  },
  invalidators: {
    changeSolutions: [GET_SOLUTIONS],
    revertSolutionsToDefault: [GET_SOLUTIONS],
  }
};
