import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import API from '../api';
import ErrorMessage from '../components/layout/ErrorMessage';
import LoadingPage from '../components/layout/LoadingPage';

const CompanyAttachment = () => {
  const history = useHistory();
  const {
    company_public_id: companyPublicId,
    attachment_id: attachmentId,
    issue_public_id: issuePublicId
  } = useParams();
  const { isAuthenticated, loading, company } = useSelector((state) => state.auth);
  const { CompanyAPI } = API;
  const [errorMessage, setErrorMessage] = useState(null);

  if (!loading && !isAuthenticated) history.push('/login');

  const fetchData = async () => {
    try {
      const urlCompanyOrIssue = `${companyPublicId}${issuePublicId ? '/' + issuePublicId : ''}`
      const response = await CompanyAPI.handlers.getCompanyAttachmentById(
        { companyPublicId: urlCompanyOrIssue, attachmentId }
      );

      if (response.errorMessage) return setErrorMessage(response.errorMessage);

      if (company.id && company.id !== companyPublicId) {
        return setErrorMessage('You are not part of this company.');
      }

      window.location = await response.url;
      window.setTimeout(() => { window.close() }, 2000);
    } catch (error) {
      return setErrorMessage(error.message);
    }
  }

  useEffect(() => {
    if (loading) return;
    fetchData();
  }, [loading]);

  return (
    <React.Fragment>
      {errorMessage ? (
        <ErrorMessage
          customStyle={{ height: '100vh' }}
          text={errorMessage}
        />
      ) : (
        <LoadingPage />
      )}
    </React.Fragment>
  );
};

export default CompanyAttachment;