import React from 'react'

const MultilineTextCell = (props) => {
  const {
    extraClassName = '',
    text,
    width
  } = props;
  // Get wrapper div style
  const getStyle = () => {
    const style = {};
    if(width) {
      style.width = width;
    }
    return style
  }

  return (
    <div className="d-flex align-items-center" style={getStyle()}>
      <div
      className={`td-multiline-ellipsis ${extraClassName}`}
      >
        {text}
      </div>
    </div>

  )
}

export default MultilineTextCell
