// Actions
export const READ_ACTION = "READ";
export const LIST_ACTION = "LIST";
export const CREATE_ACTION = "CREATE";
export const MODIFY_ACTION = "MODIFY";
export const EDIT_ACTION = "EDIT";
export const UPDATE_ACTION = "UPDATE";
export const MERGE_ACTION = "MERGE";
export const DELETE_ACTION = "DELETE";
export const HARD_DELETE_ACTION = 'HARD_DELETE';


// PRIVILEGE SOURCES BY VIEW
export const PRIVILEGE_SOURCES = {
    HOME: {
        GENERAL: "HOMEPAGE::VIEW"
    },
    ISSUES: {
        GENERAL: "WORKBENCH::ISSUE",
        NAME_DESCRIPTION_FIELDS: "ISSUES::TEXT_FIELD::NAME_DESCRIPTION",
        PARTNER_FIELD: "ISSUES::TEXT_FIELD::PARTNER",
        COMPANY_DROPDOWN: "ISSUES::DROPDOWN::COMPANY",
        DEVICE_OS_DROPDOWN: "ISSUES::DROPDOWN::DEVICE_OS",
        REOPEN_STATUS_DROPDOWN: "ISSUES::DROPDOWN::REOPEN_STATUS",
        CRITICALITY_DROPDOWN: "ISSUES::DROPDOWN::CRITICALITY",
        GROUPS: 'WORKBENCH::ISSUE_GROUP',
    },
    DEVICES: {
        GENERAL: "WORKBENCH::DEVICE",
    },
    MY_CYSO: {
        GENERAL: "USER_MANAGEMENT::CYDEKICKS_RESULTS",
        CYVALUE: "USER_MANAGEMENT::CYVALUE"
    },
    MULTIPLE: {
        REQUEST_SCAN: "MULTIPLE::REQUEST_VULNERABILITIES_SCAN",
        IMPERSONATE: "USER_MANAGEMENT::IMPERSONATION",
        REFER_USER: "SALESFORCE::REFERRAL",
    },
    PROFILE: {
        GENERAL: "USER_MANAGEMENT::USER",
        COMPANY_SOLUTIONS_LINK: "PROFILE::LINK::COMPANY_SOLUTIONS",
        COMPANY_CYDEKICK_NOTES: "PROFILE::EDITOR::CYDEKICK_NOTES",
        NOTIFICATIONS_SETTINGS_SUBTAB: "PROFILE::SUBTAB::NOTIFICATIONS_SETTINGS",
        USER_INVITE: "USER_MANAGEMENT::MEMBER_INVITE",
        USER_MANAGEMENT: "USER_MANAGEMENT::MEMBER_USERS"
    },
    COMPLIANCE: {
        JOURNEY: "COMPLIANCE::JOURNEY"
    },
    SIDEBAR: {
        ASSESSMENT_TAB: "SIDEBAR::TAB::ASSESSMENT",
        ISSUES_TAB: "SIDEBAR::TAB::ISSUE",
        DEVICES_TAB: "SIDEBAR::TAB::DEVICE",
        FREEMIUM_LABEL: "SIDEBAR::LABEL::FREEMIUM",
    },
    MEMBERS: {
        GENERAL: "COMPANY_MANAGEMENT::COMPANY",
        PRIVATE_COMPANY_NOTES: "COMPANY_MANAGEMENT::PRIVATE_COMPANY_NOTE",
        DOMAIN: "COMPANY_MANAGEMENT::DOMAIN",
    },
    CYVATAR_TEAM: {
        GENERAL: "SQUAD::USER",
        USER_INVITE: "USER_MANAGEMENT::INVITE",
    },
    ASSESSMENT: {
        GENERAL: "ASSESSMENT::ASSESSMENT_EXECUTION"
    },
    BUSINESS_RISK: {
        GENERAL: "VCYSO::BUSINESS_RISK"
    },
    POLICIES: {
        GENERAL: "VCYSO::POLICIES"
    },
    AUTOMATION: {
        INTEGRATION_RULE: "INTEGRATION::RULE",
        RULE_SET: "INTEGRATION::RULE_SET",
        LOG: "INTEGRATION::LOG",
        LOG_GROUP: "INTEGRATION::LOG_GROUP",
    },
    COMPANY_SOLUTIONS: {
        GENERAL: "COMPANY_MANAGEMENT::COMPANY_SOLUTIONS",
        PARTNER: "COMPANY_MANAGEMENT::COMPANY_HAS_PARTNERS",
    },
    PARTNERS: {
        GENERAL: "WORKBENCH::PARTNER",
    },
    SUBSCRIPTION: {
        GENERAL: "SUBSCRIPTION::PLAN"
    },
    USER: {
        DASHBOARD_FEEDBACK: "USER_MANAGEMENT::DASHBOARD_FEEDBACK"
    },
}

//ACTION::VIEW_LOCATION::COMPONENT_TYPE::ENTITY
