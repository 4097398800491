import React, { useEffect, useState, createRef } from 'react';
import CustomTableCell from './CustomTableCell';

const CustomTableRow = (props) => {
  //  Parsing props
  const {
    columnsTemplate = [],
    item = {},
    rowClassName = '',
    allSelected = false,
    selected = [],
    excluded = [],
    handleSelectedEvent,
    clickableRow = false,
    onRowClicked,
    expandableRow = false,
    expandAll = false,
    index: rowIndex = 0,
    handleCellAction,
    onHoverEnter
  } = props;

  //  Component state
  const [expanded, setExpanded] = useState(expandAll);
  const rowRef = createRef();


  //  Watching expand all changes
  useEffect(() => {
    setExpanded(expandAll);
  }, [expandAll]);

  //  Function to handle row click
  const handleRowClick = (event) => {
    if (!clickableRow || !onRowClicked) { return; }
    if (item.customHandleContext) return event.stopPropagation();
    onRowClicked(item);
  };

  const handleMouseEnter = () => {
    if (!onHoverEnter) return;
    onHoverEnter(item, rowIndex, rowRef);
  }

  //  Function to handle expand click
  const onExpandClick = () => {
    setExpanded(!expanded);
  };

  //  Function to get className
  const getClassName = () => {
    let className = rowClassName;
    if (clickableRow) { className += ' clickable'; }
    className += expanded ? ' expanded' : ' collapsed';
    return className;
  }

  //  Function to render table cells
  const renderTableCells = () => {
    return columnsTemplate.map((column, columnIndex) => {
      const {
        cellClassName,
        entry,
        cellType,
        subtemplate,
        containerClassName,
        customRender,
        customParams,
        customCellCallback
      } = column;
      return (
        <CustomTableCell
          key={columnIndex}
          id={item?.id || rowIndex}
          object={item}
          entry={entry}
          cellClassName={cellClassName}
          cellType={cellType}
          subtemplate={subtemplate}
          containerClassName={containerClassName}
          customRender={customRender}
          customParams={customParams}
          allSelected={allSelected}
          selected={selected}
          excluded={excluded}
          handleSelectedEvent={handleSelectedEvent}
          rowIndex={rowIndex}
          handleCellAction={handleCellAction}
          customCellCallback={customCellCallback}
        />
      );
    });
  };

  //  Function to render expandable icon
  const renderExpandableIcon = () => {
    if (!expandableRow) { return null; }
    return (
      <div
        className={`bx bxs-${expanded ? 'down' : 'right'}-arrow expandable-icon`}
        onClick={() => onExpandClick()}
      />
    );
  };

  //  Rendering
  return (
    <tr
      id={item?.id}
      className={getClassName()}
      onClick={handleRowClick}
      onMouseEnter={() => handleMouseEnter()}
      ref={rowRef}
    >
      {renderExpandableIcon()}
      {renderTableCells()}
    </tr >
  );
};

export default CustomTableRow;
