import { ON_GROUPS_FILTERS_CHANGE, SET_GROUP_PAGINATION } from './types';

export const onGroupsFiltersChange = (filters, active_filters = []) => {
  return {
    type: ON_GROUPS_FILTERS_CHANGE,
    payload: { filters, active_filters },
  };
};

export const setGroupPagination = (payload) => {
  return {
    type: SET_GROUP_PAGINATION,
    payload
  }
}
