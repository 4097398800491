import React, { useEffect, useState } from 'react';
import { setAlert } from '../../actions/alerts';
import { alertTypes } from '../../constants/alerts';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import { getApiErrorMessage, isValidFunction } from '../../utils/helpers';
import ConfirmActionModal from '../layout/ConfirmActionModal';
import API from '../../api';
import useCustomMutation from '../../hooks/useCustomMutation';
import companyIdSelector from '../../selectors/companyIdSelector';
import { useSelector } from 'react-redux';
import IssuesAssigneeFilter from '../issue-manager/issue-filters/IssuesAssigneesFilter';
import { useMemo } from 'react';
import { setConfirmationModal } from '../../actions/layout';

const DisableTeamMember = (props) => {
    const {
        memberName,
        userId,
        toggleModal,
        isActive,
        reloadCallback,
        userRole
    } = props;

    //  Initializing API
    const { UserAPI } = API;

    // Getting needed info from redux store
    const selectedCompany = useSelector((state) => companyIdSelector(state));

    // Component state
    const [assigneeId, setAssigneeId] = useState(null);

    // Component's variables
    const isSalesAccount = useMemo(() => {
        const salesRole = 'squad_sales'
        return (userRole === salesRole);
    }, [userRole]);

    // Component Hooks
    const { dispatch } = useMemoizedDispatch();

    //  Put to remove member's account
    const {
        data: disableMemberResponse,
        error: disableMemberError,
        mutate: disableTeamMember,
        isLoading: disableMemberLoading
    } = useCustomMutation(
        (params) => UserAPI.handlers.disableTeamMember(params),
        UserAPI.invalidators.removeTeamMember,
    );

    // Watching response to close modal and set success alert
    useEffect(() => {
        if(!disableMemberResponse) return;
        toggleModal();
        const message = (
            <span>
                {`${getActionLabel()}d `}<span className="font-weight-bolder">{`${memberName}'s`}</span>{' account'}
            </span>
        );
        dispatch(setConfirmationModal({
            show: true,
            message: message
        }));
        if(isValidFunction(reloadCallback)) {
            reloadCallback(true)
        }
    },[disableMemberResponse]);

    // Watching error to set alert
    useEffect(() => {
        if(!disableMemberError) return;
        const errorMessage = getApiErrorMessage(disableMemberError)
        dispatch(setAlert(errorMessage, alertTypes.error));
    },[disableMemberError])

    const cancelCallback = () => {
        toggleModal()
    }

    const confirmCallback = () => {
        const params = {
            user_id: userId,
            active: !isActive,
            assignee_id: assigneeId,
        }
        disableTeamMember(params);
    }

    const getActionLabel = () => {
        return isActive ? 'Disable' : 'Enable';
    }

    const getSubtitle = () => {
        return `Are you sure you want to ${getActionLabel()?.toLowerCase()} ${memberName}'s account?${isActive && !isSalesAccount? ' This action can’t be undone.' :''}`
    }

    // Callback function when an options is selected
    const onSelectAssignee = (option) => {
        setAssigneeId(option?.value)
    }

    // Render assignee dropdown
    const renderAssigneeDropdown = () => {
        if(isSalesAccount) return null;
        return (
            <div className="dropdown-container">
                <div className='dropdown-instructions'>Please select a new assignee: </div>
                <IssuesAssigneeFilter
                    selectedCompany={selectedCompany}
                    filterValue={assigneeId}
                    setFilterCallback={onSelectAssignee}
                    showAllMembersOption={false}
                    wrapperClassName="issues-assignee-bulk-dropdown mx-auto"
                    excludedUserIds={[userId]}
                />
            </div>
        )
    }

    return (
        <ConfirmActionModal
            title={`${getActionLabel()} account`}
            subtitle={getSubtitle()}
            confirmLabel={disableMemberLoading ? "Processing": getActionLabel()}
            cancelCallback={cancelCallback}
            confirmCallback={confirmCallback}
            disableConfirm={disableMemberLoading || (isActive && !assigneeId && !isSalesAccount)}
            confirmButtonColor={isActive ? 'critical' : 'light'}
        >
            {isActive && renderAssigneeDropdown()}
        </ConfirmActionModal>
    );
};

export default DisableTeamMember;
