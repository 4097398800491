export const getAssessmentFeedbackMessage = (answersCount, questionsCount, unsaved, fromAssessment) => {
    let label;
    if (answersCount < 20 ){
        label = fromAssessment ? 'You’re on your way to know your Cyvatar Score.': 'Awesome! You are on your way to know your Cyvatar score';
    } else if(answersCount < 30 ){
        label = 'Yay! you’re are half way done to know your Cyvatar Score'
    } else {
        switch(questionsCount - answersCount) {
            case 0:
                label = unsaved ? 'Save your changes to update Cyvatar Score.': 'Yay! You’re done, go check your Cyvatar Score.';
                break;
            case 1:
                label = 'Almost done. You’re an answer away from getting your Cyvatar Score.';
                break;
            default: label = 'Almost done. You’re a few answers away from getting your Cyvatar Score.'
        }
    }

    return label;
}
