import React from "react";
import MultilineTextCell from "../../components/common/MultilineTextCell";
import ConnectionActions from "../../components/connection-status/ConnectionActions";
import ConnectionStatusBadge from "../../components/connection-status/ConnectionStatusBadge";
import PartnerConnectionStatus from "../../components/connection-status/PartnerConnectionStatus";
import InfiniteDropdown from "../../components/layout/InfiniteDropdown";
import { getLatestSyncDate } from "../../utils/connectionStatusHelpers";
import { getSafeArray, getTableText, parseCompaniesToOptions } from "../../utils/helpers";
import { connectionStateFilters, connectionStatusFilters } from "../common";
import API from "../../api";
import { filterTypes } from "../cyValueData";

//  Initializing APIs
const { CompanyAPI, PartnerAPI } = API;

//  Function to get partners
const getPartners = async (filters) => {
  const response = await PartnerAPI.handlers.getActivePartners({
    company_id: null,
    filters,
    include_freemium: true,
  });
  return { ...response };
};

// Render partner status
const renderPartnerStatus = (row) => {
  const { id, status, partner_id, partner_name, company_id } = row;

  return (
    <ConnectionStatusBadge
      componentId={`connection-status-badge-${id}`}
      statusId={status}
      groupedStatus={true}
      partnerId={partner_id}
      partnerName={partner_name}
      companyId={company_id}
    />
  );
};

// Render partner connection status
const renderPartnerConnectionStatus = (row) => {
  const { active, id } = row;

  return <PartnerConnectionStatus connectionId={id} isActive={active} />;
};

// Render connection actions
const renderActions = (row) => {
  const { id, partner_name, partner_id, company_name, company_id, active } = row;
  return (
    <ConnectionActions
      connectionId={id}
      partnerId={partner_id}
      partnerName={partner_name}
      companyName={company_name}
      companyId={company_id}
      isActive={active}
    />
  );
};

// Render connection error message
const renderConnectionErrorMessage = (message) => {
  return <MultilineTextCell text={message} width="338px" />;
};

export const connectionsTableTemplate = [
  {
    displayName: "Company",
    fieldName: "company_name",
    sortFieldName: "company_name",
    headerClassNameWrapper: "th-company-name",
    renderCustomComponent: (row) => getTableText(row?.company_name),
  },
  {
    displayName: "Partner name",
    field_name: "partner_name",
    sortFieldName: "partner_name",
    headerClassNameWrapper: "th-partner-name",
    renderCustomComponent: (row) => getTableText(row?.partner_name),
  },
  {
    displayName: "Solution",
    fieldName: "solution_name",
    sortFieldName: "solution_name",
    headerClassNameWrapper: "th-solution",
    renderCustomComponent: (row) => getTableText(row?.solution_name),
  },
  {
    displayName: "Status",
    fieldName: "status",
    sortFieldName: "status",
    headerClassNameWrapper: "th-status",
    renderCustomComponent: renderPartnerStatus,
  },
  {
    displayName: "Connection",
    fieldName: "active",
    sortFieldName: "active",
    renderCustomComponent: renderPartnerConnectionStatus,
  },
  {
    displayName: "Actions",
    fieldName: null,
    sortFieldName: null,
    headerClassNameWrapper: "th-actions",
    renderCustomComponent: renderActions,
  },
  {
    displayName: "Latest Sync",
    fieldName: "latest_sync",
    sortFieldName: "latest_sync",
    headerClassNameWrapper: "latest-sync-header",
    renderCustomComponent: (row) => getLatestSyncDate(row?.latest_sync),
  },
  {
    displayName: "Error",
    fieldName: "error_message",
    sortFieldName: null,
    headerClassNameWrapper: "th-error-message",
    renderCustomComponent: (row) =>
      renderConnectionErrorMessage(row?.error_message),
  },
];

// Get partner filter
const getPartnerFilter = (initialFilters, filters, setFilters, activeFilters) => {
  const selectedElements = [];
  let option = undefined;
  if(filters?.partner_id ) {
    const activeFilter = getSafeArray(activeFilters)?.find((element) => element?.filterName === 'partner_id')
    const partner = { value: filters?.partner_id, selected: false, label: activeFilter?.label}
    selectedElements.push(option);
    option = partner;
  }
  return (
    <InfiniteDropdown
      emptyLabel="Partner name"
      extraClassName="dashboard-dropdown-filter partner-filter"
      errorMessage="Error getting partners"
      apiFunction={getPartners}
      apiKey={PartnerAPI.keys.getActivePartners}
      parseElementsToOptions={parseCompaniesToOptions}
      onChange={(option) => handleOptionFilter(option, 'partner_id', selectedElements, setFilters)}
      restoreToDefault={initialFilters?.partner_id === filters?.partner_id}
      selectedElements={[]}
      initialElement={option}
    />
  );
};

// Get solution filter
const getSolutionsFilter = (solutions) => {
  if (!solutions || solutions?.length === 0) return [];
  const solutionsOptions = solutions.map((_solution) => {
    return { label: _solution?.name, value: _solution?.id };
  });
  const solutionFilter = {
    fieldName: "solution_id",
    type: "select",
    className: "dashboard-dropdown-filter solution-filter",
    placeholder: "Solution",
    options: [{ options: solutionsOptions }],
  };
  return [solutionFilter];
};

// Get status filter
const getStatusFilter = () => {
  const statusFilter = {
    fieldName: "status",
    type: "select",
    className: "dashboard-dropdown-filter connection-status-filter",
    placeholder: "Status",
    options: [{ options: connectionStatusFilters }],
  };
  return [statusFilter];
};

// Get state filter
const getStateFilter = () => {
  const stateFilter = {
    fieldName: "active",
    type: "select",
    className: "dashboard-dropdown-filter connection-state-filter",
    placeholder: "Connection Status",
    options: [{ options: connectionStateFilters }],
  };
  return [stateFilter];
};

const handleOptionFilter = (option, fieldName, selectedElements, setFilters) => {
  if(!option) return;
  const matchingElement = selectedElements?.find((type) => type?.value === option?.value);
  if (matchingElement) { return; }
  setFilters(fieldName, option?.value || null, option?.label, filterTypes.single, null);
}

// Get company filter
const getCompanyFilter = (initialFilters, filters, setFilters, activeFilters) => {
  const selectedElements = [];
  let option = undefined;
  if(filters?.company_id ) {
    const activeFilter = getSafeArray(activeFilters)?.find((element) => element?.filterName === 'company_id')
    const partner = { value: filters?.company_id, selected: false, label: activeFilter?.label}
    selectedElements.push(option);
    option = partner;
  }
  return (
    <InfiniteDropdown
      emptyLabel="Pick a Company"
      extraClassName="dashboard-dropdown-filter member-filter"
      errorMessage="Error getting members"
      apiFunction={CompanyAPI.handlers.getImpersonationList}
      apiKey={CompanyAPI.keys.getImpersonationList}
      parseElementsToOptions={parseCompaniesToOptions}
      onChange={(option) => handleOptionFilter(option, 'company_id', selectedElements, setFilters)}
      restoreToDefault={initialFilters.company_id === filters.company_id}
      selectedElements={selectedElements}
      initialElement={option}
    />
  );
};

// connection table filters
export const getConnectionTableFiltersTemplate = (
  initialFilters = {},
  filters = {},
  activeFilters,
  solutions,
) => {
  return [
    {
      renderCustomFilter: (setFilter) =>
        getPartnerFilter(initialFilters, filters, setFilter, activeFilters),
    },
    ...getSolutionsFilter(solutions),
    ...getStatusFilter(),
    {
      renderCustomFilter: (setFilter) =>
        getCompanyFilter(initialFilters, filters, setFilter, activeFilters),
    },
    ...getStateFilter()
  ];
};

export const connectionActiveFiltersDisplayInfo = {
  display: true,
  labels: {
      noFilters: 'Viewing all connections',
      filteringBy: 'Viewing connections for:',
      total: {
          singular: 'Partner',
          plural: 'Partners'
      }
  }
}
