import React, { useState } from 'react';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import UserManagerActionModal from './actionModals/UserManagerActionModal';
import API from '../../../api';
import useCustomMutation from '../../../hooks/useCustomMutation';
import useHandleApiResponse from '../../../hooks/useHandleApiResponse';
import UserManagerAssigneesDropdown from './UserManagerAssigneesDropdown';

const UserManagerActionsModals = (props) => {

  const {
    company_id,
    users = [],
    selectedIds = [],
    // Shows
    showDisableModal,
    showDeleteModal,
    showAcceptModal,
    showDiscardModal,
    showResendModal,
    //Toggles
    toggleDisableModal,
    toggleDeleteModal,
    toggleAcceptModal,
    toggleDiscardModal,
    toggleResendModal,
  } = props;

  //  Initializing API
  const { UserAPI } = API;

  const { dispatch } = useMemoizedDispatch();

  const [currentAction, setCurrentAction] = useState(null);
  const [assignee, setAssignee] = useState(null);

  //  Component hooks
  const {
    data: userActionResponse,
    error: userActionError,
    isLoading: userActionLoading,
    mutate: callUserAction,
  } = useCustomMutation(
    (params) => UserAPI.handlers.executeUserAction(params),
    UserAPI.invalidators.executeUserAction,
  );

  const getCurrentToggleModal = (action) => {
    switch (action) {
      case 'delete':
        return toggleDeleteModal;
      case 'accept':
        return toggleAcceptModal;
      case 'deny':
        return toggleDiscardModal;
      case 'resend':
        return toggleResendModal;
      case 'disable':
        return toggleDisableModal;
      default:
        return () => {};
    }
  };

  useHandleApiResponse({
    data: userActionResponse,
    errorData: userActionError,
    successMessage: `Action performed successfully.`,
    successCallback: () => {
      getCurrentToggleModal(currentAction)(false);
      setCurrentAction(null);
    }
  });

  const confirmCallback = (action) => {
    const payload = {
      action: action,
      users: selectedIds,
      assignee_id: assignee?.value,
      company: company_id
    };
    setCurrentAction(action);
    callUserAction(payload);
  };

  const configs = [
    {
      title: `Delete member${users.length > 1 ? 's' : ''}?`,
      subtitle: users.length > 1 ? `By deleting this role you eliminate all privileges 
      for these (${users?.length}) members, please select to who you want to assign the tasks and issues of all (${users?.length}) members` : <p>By deleting this role you eliminate all privileges 
      for this member, please select to who you want to assign the tasks and issues of <a className='bold-text'>{users[0]?.full_name}</a></p>,
      customClassName: 'user-manager-modal delete-modal',
      buttonClassName: 'modal-buttons delete-modal-buttons',
      actionButtonLabel: 'Delete',
      disableButton: assignee ? false : true,
      confirmButtonClassName: 'critical',
      content: <UserManagerAssigneesDropdown selectedIds={selectedIds} setAssignee={setAssignee}/>,
      confirmCallback: () => {confirmCallback('delete')},
      isLoading: userActionLoading,
    },
    {
      title: `Accept member${users.length > 1 ? 's' : ''}?`,
      subtitle: users.length > 1 ? `${users?.length} new members will be part of your company
      and will have a member access.` : `${users[0]?.full_name} will be part of your company
      and will have a member access.`,
      customClassName: 'user-manager-modal accept-modal',
      buttonClassName: 'modal-buttons accept-modal-buttons',
      confirmButtonClassName: 'success',
      actionButtonLabel: 'Accept',
      content: <></>,
      confirmCallback: () => {
        const action = 'accept';
        const payload = {
          action: action,
          users: selectedIds,
          company: company_id
        };
        setCurrentAction(action);
        callUserAction(payload);
      },
      isLoading: userActionLoading,
    },
    {
      title: `Discard request${users.length > 1 ? 's' : ''}`,
      subtitle: users.length > 1 ? `${users?.length} new member requests will be discarded.
      This access can be requested again, 
      or you can send an email invitation to your company.` : `${users[0]?.full_name}'s request will be discarded.
      This access can be requested again, 
      or you can send an email invitation to your company. `,
      customClassName: 'user-manager-modal discard-modal',
      buttonClassName: 'modal-buttons discard-modal-buttons',
      confirmButtonClassName: 'critical',
      actionButtonLabel: 'Discard',
      content: <></>,
      confirmCallback: () => {
        const action = 'deny';
        const payload = {
          action: action,
          users: selectedIds,
          company: company_id
        };
        setCurrentAction(action);
        callUserAction(payload);
      },
      isLoading: userActionLoading,
    },
    {
      title: `Resend invite${users.length > 1 ? 's' : ''}`,
      subtitle: users.length > 1 ? `You will send another invitation 
      to ${users?.length} members
      to join your company.` : `You will send another invitation 
      to ${users[0]?.email}
      to join your company.`,
      customClassName: 'user-manager-modal delete-modal',
      buttonClassName: 'modal-buttons delete-modal-buttons',
      confirmButtonClassName: 'success',
      actionButtonLabel: 'Resend',
      content: <></>,
      confirmCallback: () => {
        const action = 'resend';
        const payload = {
          action: action,
          users: selectedIds,
          company: company_id
        };
        setCurrentAction(action);
        callUserAction(payload);
      },
      isLoading: userActionLoading,
    },
    {
      title: `Disable member${users.length > 1 ? 's' : ''}?`,
      subtitle: users.length > 1 ? `Please select to who you want to assign the tasks and issues of all (${users?.length}) members` : <p>Please select to who you want to assign the tasks and issues of <a className='bold-text'>{users[0]?.full_name}</a></p>,
      customClassName: 'user-manager-modal delete-modal',
      buttonClassName: 'modal-buttons delete-modal-buttons',
      actionButtonLabel: 'Disable',
      confirmButtonClassName: 'critical',
      disableButton: assignee ? false : true,
      content: <UserManagerAssigneesDropdown selectedIds={selectedIds} setAssignee={setAssignee}/>,
      confirmCallback: () => {confirmCallback('disable')},
      isLoading: userActionLoading,
    },
  ];

  const renderDisableModal = () => {
    if (!showDisableModal) return null;
    return (
      <UserManagerActionModal
        showModal={showDisableModal}
        toggleModal={toggleDisableModal}
        modalConfig={configs[4]}
      />
    );
  };

  const renderDeleteModal = () => {
    if (!showDeleteModal) return null;
    return (
      <UserManagerActionModal
        showModal={showDeleteModal}
        toggleModal={toggleDeleteModal}
        modalConfig={configs[0]}
      />
    );
  };
  
  const renderAcceptModal = () => {
    if (!showAcceptModal) return null;
    return (
      <UserManagerActionModal
        showModal={showAcceptModal}
        toggleModal={toggleAcceptModal}
        modalConfig={configs[1]}
      />
    );
  };

  const renderDiscardModal = () => {
    if (!showDiscardModal) return null;
    return (
      <UserManagerActionModal
        showModal={showDiscardModal}
        toggleModal={toggleDiscardModal}
        modalConfig={configs[2]}
      />
    );
  };

  const renderResendModal = () => {
    if (!showResendModal) return null;
    return (
      <UserManagerActionModal
        showModal={showResendModal}
        toggleModal={toggleResendModal}
        modalConfig={configs[3]}
      />
    );
  };

  return (
    <>
      {renderDisableModal()}
      {renderDeleteModal()}
      {renderAcceptModal()}
      {renderDiscardModal()}
      {renderResendModal()}
    </>
  )
}

export default UserManagerActionsModals;