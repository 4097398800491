import API from '../api';
import { recycleBinEntities } from '../constants/common';
import useCustomMutation from './useCustomMutation';

const useRecycleBinMutations = (params = {}) => {
  //  Parsing params
  const { entity = recycleBinEntities.devices } = params;

  //  Initializing APIs
  const { IssueAPI, GrcIssueAPI, DeviceAPI } = API;

  //  Function to get restore invalidators
  const getRestoreInvalidators = () => {
    if (entity === recycleBinEntities.devices) {
      return DeviceAPI.invalidators.restoreDevices;
    }

    if (entity === recycleBinEntities.issues) {
      return IssueAPI.invalidators.restoreIssues;
    }

    if (entity === recycleBinEntities.tasks) {
      return GrcIssueAPI.invalidators.restoreTasks;
    }

    return DeviceAPI.invalidators.restoreDevices;
  };

  //  Function to get hard delete invalidators
  const getHardDeleteInvalidators = () => {
    if (entity === recycleBinEntities.devices) {
      return DeviceAPI.invalidators.hardDeleteDevices;
    }
    return IssueAPI.invalidators.hardDeleteIssues;
  };

  //  Function to get restore function
  const getRestoreFunction = () => {
    if (entity === recycleBinEntities.devices) {
      return DeviceAPI.handlers.restoreDevices;
    }

    if (entity === recycleBinEntities.issues) {
      return IssueAPI.handlers.restoreIssues;
    }

    if (entity === recycleBinEntities.tasks) {
      return GrcIssueAPI.handlers.restoreTasks;
    }

    return DeviceAPI.handlers.restoreDevices;
  };

  //  Function to get hard delete function
  const getHardDeleteFunction = () => {
    if (entity === recycleBinEntities.devices) {
      return DeviceAPI.handlers.hardDeleteDevices;
    }
    return IssueAPI.handlers.hardDeleteIssues;
  };

  //  Exporting assets
  return {
    restore: useCustomMutation(getRestoreFunction(), getRestoreInvalidators()),
    delete: useCustomMutation(getHardDeleteFunction(), getHardDeleteInvalidators()),
  };
};

export default useRecycleBinMutations;
