import React from 'react';
import { Row } from 'reactstrap';
import CustomSpinner from './CustomSpinner';

const LoadingPage = ({ text, customClassName }) => {
    return (
        <Row 
            className={`w-100 vh-100 justify-content-center align-items-center 
                        bg-dirty-white no-gutters ${customClassName}`}
        >
            <div className="d-inline-block" style={{ minHeight: "300px" }} >
                <CustomSpinner text={text}/>    
            </div>
        </Row>
    )
}
export default LoadingPage;
