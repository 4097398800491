import cyvatarAxios from '../axios';
import {
  GET_COMPLIANCE,
  GET_COMPLIANCE_STATUS,
  FETCH_COMPLIANCE_SCORE,
  GET_CYVALUE_DATA,
  GET_CYVALUE_BY_WEEK,
  GET_ACTIVITY_REPORT,
  GET_DEMO_DATA,
  GET_TOP_FIVE_ISSUES,
  GET_SOLUTION_STATUS,
  GET_ROI,
  GET_ROLLUP_DATA,
  GET_VULNERABILITY_TREND,
  GET_CURRENT_PROTECTION,
  GET_DEVICES_BY_SOLUTION,
  GET_ICARM_MAINTENANCE_SOLUTIONS,
  GET_ICARM_ONBOARDING_SOLUTIONS,
  GET_COMPANY_RISK_SCORE,
} from '../ApiKeys';

//  async function for compliance score
const getCompliance = async (params) => {
  const { company_id, ...restOfParams } = params;
  const apiResponse = await cyvatarAxios.get(`/api/dashboard/compliance-score/${company_id}`, { params: restOfParams });
  const { data } = apiResponse;
  return data;
};

//  async function for compliance score
const getComplianceStatus = async (params) => {
  const { company_id, ...restOfParams } = params;
  if (!company_id) { return null; }
  const apiResponse = await cyvatarAxios.get(`/api/dashboard/compliance-status/${company_id}`, { params: restOfParams });
  const { data } = apiResponse;
  return data;
};

const fetchComplianceScore = (companyId) => {
  return cyvatarAxios.get(`/api/dashboard/compliance-score/${companyId}`);
};

//  Params include {company_id, solution_id, filter_type, start_date, end_date}
const getCyValueData = async (params) => {
  const { company_id } = params;
  if (!company_id) { return null; }
  const apiResponse = await cyvatarAxios.get(`/api/dashboard/risk_score/history/${company_id}`, { params });
  const { data } = apiResponse;
  return data;
}

const getIcarmOnboardingSolutions = async (params) => {
  const { company_id, ...restOfParams } = params;
  if (!company_id) { return null; }
  const apiResponse = await cyvatarAxios.get(`/api/dashboard/icarm/${company_id}/onboarding`, { params: restOfParams });
  const { data } = apiResponse;
  return data;
};

const getIcarmMaintenanceSolutions = async (params) => {
  const { company_id, ...restOfParams } = params;
  if (!company_id) { return null; }
  const apiResponse = await cyvatarAxios.get(`/api/dashboard/icarm/${company_id}/maintenance`, { params: restOfParams });
  const { data } = apiResponse;
  return data;
};

// Get cyvalue by week
const getCyvalueByWeek = async ({ company_id, params }) => {
  const apiResponse = await cyvatarAxios.get(`/api/dashboard/risk_score/cyvalue/${company_id}`, { params })
  const { data } = apiResponse;
  return data;
};

// Update cyvalue by weeks
const postUpdateCyvalue = async (payload) => {
  const body = JSON.stringify(payload);
  const apiResponse = await cyvatarAxios.post(`/api/dashboard/risk_score/save_cyvalue`, body);
  const { data } = apiResponse;
  return data;
};

//activity report  Params include {company_id, filter_type }
const getActivityReport = async (params) => {
  const { company_id } = params;
  if (!company_id) { return null; }
  const apiResponse = await cyvatarAxios.get(`/api/dashboard/activity_report`, { params });
  const { data } = apiResponse;
  return data;
};

//reload Demo Data for sales 
const reloadDemoDataSales = async () => {
  const apiResponse = await cyvatarAxios.put(`/api/sales_generator/values`);
  return apiResponse;
};

// Risk Recon data
const getCompanyRiskScore = async ({ company_id }) => {
  const apiResponse = await cyvatarAxios.get(`/api/partner_actions/riskscore/${company_id}`);
  const { data } = apiResponse;
  return data;
}

export const DashboardAPI = {
  handlers: {
    getCompliance,
    getComplianceStatus,
    fetchComplianceScore,
    getCyValueData,
    getIcarmMaintenanceSolutions,
    getIcarmOnboardingSolutions,
    getCyvalueByWeek,
    postUpdateCyvalue,
    getActivityReport,
    reloadDemoDataSales,
    getCompanyRiskScore
  },
  keys: {
    getCompliance: GET_COMPLIANCE,
    getComplianceStatus: GET_COMPLIANCE_STATUS,
    fetchComplianceScore: FETCH_COMPLIANCE_SCORE,
    getCyValueData: GET_CYVALUE_DATA,
    getIcarmMaintenanceSolutions: GET_ICARM_MAINTENANCE_SOLUTIONS,
    getIcarmOnboardingSolutions: GET_ICARM_ONBOARDING_SOLUTIONS,
    getCyvalueByWeek: GET_CYVALUE_BY_WEEK,
    getActivityReport: GET_ACTIVITY_REPORT,
    reloadDemoDataSales: GET_DEMO_DATA,
    getCompanyRiskScore: GET_COMPANY_RISK_SCORE,
  },
  invalidators: {
    postUpdateCyvalue: [
      GET_CYVALUE_DATA,
      GET_CYVALUE_BY_WEEK,
      GET_ACTIVITY_REPORT,
      GET_COMPLIANCE,
      GET_COMPLIANCE_STATUS,
      FETCH_COMPLIANCE_SCORE,
      GET_ICARM_MAINTENANCE_SOLUTIONS,
      GET_ICARM_ONBOARDING_SOLUTIONS
    ],
    reloadPostValue : [
      GET_TOP_FIVE_ISSUES,
      GET_SOLUTION_STATUS,
      GET_ROI,
      GET_CYVALUE_DATA,
      GET_ROLLUP_DATA,
      GET_VULNERABILITY_TREND,
      GET_CURRENT_PROTECTION,
      GET_DEVICES_BY_SOLUTION,
      GET_ICARM_MAINTENANCE_SOLUTIONS,
      GET_ICARM_ONBOARDING_SOLUTIONS
    ]
  }
};
