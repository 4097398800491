import {
    CALCULATE_ROI_SUCCESS,
    CALCULATE_ROI_RESET,
    LOGOUT_SUCCESS
} from '../actions/types';

const initialState = {
    roiResults: null
}

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case CALCULATE_ROI_SUCCESS: {
            return {
                ...state,
                roiResults: payload?.results
            }
        }
        case CALCULATE_ROI_RESET:
        case LOGOUT_SUCCESS: {
            return {
                ...state,
                roiResults: null
            }
        }
        default:
            return state
    }
}
