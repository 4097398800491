import React from 'react';
import { Button } from 'reactstrap';
import API from '../../../api';
import { DELETE_ACTION, PRIVILEGE_SOURCES } from '../../../constants/privileges';
import useCanAbility from '../../../hooks/useCanAbility';
import useCustomMutation from '../../../hooks/useCustomMutation';
import useHandleApiResponse from '../../../hooks/useHandleApiResponse';
import useImpersonation from '../../../hooks/useImpersonation';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import { isValidFunction } from '../../../utils/helpers';
import BasicModal from '../../layout/BasicModal';

const DeleteDomainModal = (props) => {
  const {
    domainPublicIds,
    showModal,
    toggleModal,
    companyId,
    onSuccessDispatch
  } = props;

  //  Initializing API
  const { CompanyAPI } = API;

  const { dispatch } = useMemoizedDispatch();

  //  Component hooks
  const {
    data: deleteDomainsResponse,
    error: deleteDomainsError,
    isLoading: deleteDomainsLoading,
    mutate: callDeleteDomains,
  } = useCustomMutation(
    (params) => CompanyAPI.handlers.deleteCompanyDomains(params),
    CompanyAPI.invalidators.deleteCompanyDomains,
  );
  useHandleApiResponse({
    data: deleteDomainsResponse,
    errorData: deleteDomainsError,
    successMessage: `Domain${domainPublicIds?.length > 1 ? 's' :''} deleted`,
    successCallback: () => { 
      if (!isValidFunction(toggleModal)) return;
      if (!isValidFunction(onSuccessDispatch)) return;
      dispatch(onSuccessDispatch(deleteDomainsResponse?.domains_count));
      toggleModal(false);
    }
  });
  const ability = useCanAbility();

  // Component variables
  const canDeleteDomains = ability.can(DELETE_ACTION, PRIVILEGE_SOURCES.MEMBERS.DOMAIN);

  // Function to validate if one or multiple domains are selected
  const areMultipleDomains = () => {
    return domainPublicIds?.length > 1
  }

  //  Function to delete domains
  const confirmCallback = () => {
    const payload = {
      company_id: companyId,
      domain_id: domainPublicIds
    };
    callDeleteDomains(payload);
  };

  //  Rendering
  return (
    <BasicModal
        showModal={showModal}
        toggleModal={toggleModal}
        title={`Delete domain${areMultipleDomains()? 's': ''} `}
        subtitle={"This action can't be undone"}
        customClassName='delete-domain-confirmation-modal'
      >
        <div className="row-container quick-actions-confirmation-buttons">
          <Button
            color="light-gray left-modal-button"
            outline
            onClick={() => toggleModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-submit text-dark font-weight-bold"
            onClick={confirmCallback}
            disabled={!canDeleteDomains || deleteDomainsLoading}
            color="critical"
          >
            Yes, delete
          </Button>
        </div>
    </BasicModal>
  );
};

export default DeleteDomainModal;
