import React, { useEffect, useState } from "react";
import API from "../../api";
import useCustomQuery from "../../hooks/useCustomQuery";
import CustomTable from "../CustomTable/CustomTable";
import { useSelector } from "react-redux";
import companyIdSelector from "../../selectors/companyIdSelector";
import { listOptionsFormats } from "../../utils/partnerActionHelpers";
import { fieldTypes } from "../../utils/partnerActionHelpers";
import { cellTypes } from "../../constants/tableTemplates";
import useSelectedList from "../../hooks/useSelectedList";
import { customTableEvents } from "../../constants/common";
import { getSafeArray } from "../../utils/helpers";
import { setPartnerActionValue } from "../../actions/partners";
import useMemoizedDispatch from "../../hooks/useMemoizedDispatch";

const PartnerActionTable = (props) => {
  // Props
  const { field } = props;
  const { source_url, type, display_name, required } = field;

  //  Initializing APIs
  const { PartnerAPI } = API;

  // Redux
  const companyId = useSelector((state) => companyIdSelector(state));
  const partnerId = useSelector((state) => state.partners.partnerId);
  const partnerActionValues = useSelector(
    (state) => state.partners.partnerActionValues
  );

  // State
  const [elements, setElements] = useState(null);
  const [columnsTemplate, setColumnsTemplate] = useState(null);

  const { dispatch } = useMemoizedDispatch();

  //  Selected list hook
  const {
    selected,
    excluded,
    allSelected,
    eventHandler: handleSelectedEvent,
  } = useSelectedList({
    eventMap: {
      changeAll: customTableEvents.CHANGE_SELECT_ALL,
      add: customTableEvents.SELECT_ELEMENT,
      remove: customTableEvents.UNSELECT_ELEMENT,
    },
  });

  // Call api to get dropdown options
  const {
    data: tableOptionsResponse,
    error: tableOptionsError,
    isLoading: tableOptionsLoading,
    refetch: refetchTableOptions,
  } = useCustomQuery(
    [
      PartnerAPI.keys.getPartnerActionsListOptions,
      {
        company: companyId,
        partner: partnerId,
        source: source_url,
        format: listOptionsFormats.TABLE,
      },
    ],
    ({ queryKey }) =>
      PartnerAPI.handlers.getPartnerActionsListOptions(queryKey[1]),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const processOptionsData = (data) => {
    const { columns, rows } = data;
    let columnsTemplate = columns;
    columnsTemplate.forEach((element) => {
      element["cellClassName"] = "regular-cell";
      element["headerClassName"] = "text-header";
    });

    if (type == fieldTypes.MULTI_SELECT_LIST) {
      columnsTemplate.unshift({
        label: "Select",
        headerType: "TEXT",
        cellType: cellTypes.SELECTABLE,
        cellClassName: "selectable-cell",
      });
    }
    setColumnsTemplate(columnsTemplate);
    setElements(rows);
  };

  useEffect(() => {
    if (!tableOptionsResponse) return;
    processOptionsData(tableOptionsResponse);
  }, [tableOptionsResponse]);

  useEffect(() => {
    if (!tableOptionsError) return;
    console.log("tableOptionsError: ", tableOptionsError);
  }, [tableOptionsError]);

  const onSelectedEventChange = (type, params) => {
    handleSelectedEvent(type, params);

    // Params is row index, get object from elements
    const element = elements[params];

    let fieldValues = getSafeArray(partnerActionValues?.[field.key]);
    let shouldUpdateStore = false;

    switch (type) {
      case customTableEvents.CHANGE_SELECT_ALL: {
        if (fieldValues.length > 0) {
          // Deselect everything if something in array
          fieldValues = [];
        } else {
          // Include everything in the array
          elements.forEach((element) => {
            fieldValues.push(element.key);
          });
        }
        shouldUpdateStore = true;
        break;
      }
      case customTableEvents.SELECT_ELEMENT: {
        if (!fieldValues.includes(element.key)) {
          fieldValues.push(element.key);
          shouldUpdateStore = true;
        }
        break;
      }
      case customTableEvents.UNSELECT_ELEMENT: {
        if (fieldValues.includes(element.key)) {
          const index = fieldValues.indexOf(element.key);
          fieldValues.splice(index, 1);
          shouldUpdateStore = true;
        }
        break;
      }
      default:
        break;
    }

    if (shouldUpdateStore) {
      dispatch(setPartnerActionValue(field.key, fieldValues));
    }
  };

  const renderFieldTitle = () => {
    return (
      <div className="field-title">{`${display_name} ${
        required ? "*" : ""
      }`}</div>
    );
  };

  const renderTable = () => {
    return (
      <CustomTable
        tableClassName="partner-action-table"
        headerClassName="partner-action-table-header"
        rowClassName="partner-action-table-row"
        columnsTemplate={columnsTemplate || []}
        data={elements || []}
        isScrollable={true}
        allSelected={allSelected}
        selected={selected}
        excluded={excluded}
        handleSelectedEvent={onSelectedEventChange}
        loading={tableOptionsLoading}
        emptyConfig={{
          message: "Nothing to display here... for now.",
          messageClassName: "empty-message",
        }}
      ></CustomTable>
    );
  };

  // Rendering
  return (
    <div className="partner-table">
      {renderFieldTitle()}
      {renderTable()}
    </div>
  );
}

export default PartnerActionTable;
