
import React, { useEffect, useState } from 'react';
import {renderToString} from 'react-dom/server'
import { useSelector } from 'react-redux';
import _ from 'lodash';
import BarChart from './BarChart';
import gradesConfig from '../../../constants/grades';
import SolutionCardTooltip from '../tooltips/SolutionCardTooltip';
import ScoreDistributionSolutionButtons from '../ScoreDistributionSolutionButtons';
import useCustomQuery from '../../../hooks/useCustomQuery';
import { SolutionAPI } from '../../../api/services/SolutionAPI';
import companyIdSelector from '../../../selectors/companyIdSelector';
import { removeItemFromArray } from '../../../utils/helpers';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import { setAlert } from '../../../actions/alerts';

const ComplianceScoreChart = (props) => {
    const {
        baseline,
        nowSeries,
        thenSeries,
        solutionsInfo,
        opotunitiesSeries,
        showNowState,
        isMobile,
        screenWidth,
        xAxisCategories,
        isInitialState,
        showNowThen = true,
        height
    } = props;

    //  Watching redux store
    const company_id = useSelector((state) => companyIdSelector(state));

    //  Component hooks
    const { dispatch } = useMemoizedDispatch();

    const [solution, setSolution] = useState([]);
    const [selectedControls, setSelectedControls] = useState([]);

    const {
        data: solutionsResponse,
        error: getSolutionsError,
    } = useCustomQuery(
        [SolutionAPI.keys.getSolutionsbyCompanyId, company_id],
        ({ queryKey }) => SolutionAPI.handlers.getSolutionsbyCompanyId(queryKey[1]),
    );

    //  Watching solutions errors
    useEffect(() => {
        if (!getSolutionsError) { return; }
        dispatch(setAlert('Error getting solutions.', 'danger'));
    }, [getSolutionsError]);

    const handleSelectedSolution = (solutionValue) => {
        if ( !solution.includes(solutionValue) ) {
            setSolution([...solution, solutionValue]);
        } else {
            const newSolutions = solution.filter((val) => val !== solutionValue);
            setSolution(newSolutions);
        }
    };

    const handleSelectedBar = (config) => {
        const solutionData = config?.config?.series[1]?.data[config?.dataPointIndex];
        const solutionValue = solutionData?.solution 
        if (solutionValue !== undefined && solutionValue !== null) {
            if (Array.isArray(solutionValue) && solutionValue?.length > 0 ) {
                handleSelectedSolution(solutionValue[0]);
                // Logic for highlighting the clicked controls and showing their potential growth
                const isSelected = selectedControls?.includes(solutionData?.x);
                if (!isSelected) {
                    setSelectedControls([...selectedControls, solutionData?.x]);
                } else {
                    const positionInArray = selectedControls.indexOf(solutionData?.x);
                    setSelectedControls(removeItemFromArray(selectedControls, positionInArray));
                }
            } 
        }
    };

    const renderButtons = () => {
        // Spliting the companies solutions by purchased and not purchased (or available)
        let splitedSolutions = [[],[]];
        solutionsResponse?.solutions.forEach((solution) => {
            const index = solution?.companySolutions?.enabled ? 0 : 1;
            splitedSolutions[index] = [...splitedSolutions[index], solution?.name];
        });

        // Previous version....
        //First we take the solutions of the oportunities
        //const solutionControlOportunities = opotunitiesSeries.reduce((pV, cV) => {
        //    pV.push(...cV.solutions); return pV;
        //}, []);
        //Remove all repeated solutions to compare it with the already installed packages(solutions)
        //const filteredOportunities = _.uniq(solutionControlOportunities);
        //const buttonsArray = filteredOportunities.filter(val => !packages.includes(val));

        return (
            <ScoreDistributionSolutionButtons
                purchasedSolutions={splitedSolutions[0]}
                availableSolutions={splitedSolutions[1]}
                handleButtonSelect={handleSelectedSolution}
                selectedSolutions={solution}
                clearSelections={() => {setSolution([])}}
            />
        )
    };

    const formatData = (data, isOff = false) => {
        if (!showNowThen && isOff) return ([])
        return xAxisCategories.map(label => {
            const section = _.find(data, ['code', label]);
            const solutionSerie = _.find(opotunitiesSeries, ['code', label]);
            //We look if this Bar has the actual solution of the state.
            //We avoid the solution when score_grade is A.
            //arr1.some(item => arr2.includes(item))
            const solutionSelected = solutionSerie?.solutions?.some((item) => solution.includes(item));
            return {
                x: label,
                y: section?.score || (isOff ? null : 1),
                score_grade: section?.score_grade || (isOff ? null : 'D'),
                color: (solution.length !== 0 && !solutionSelected) 
                    ? gradesConfig['UNSELECTED'].hex
                    : gradesConfig[section?.score_grade]?.hex || '#605E92',
                control_label: section?.control_label,
                solution: solutionSerie?.solutions || [],
            }
        })
    }

    // Used for formating the stacked bar behind each control when the control has growth potential
    const formatControlPotentialData = () => {
        const formatData = xAxisCategories.map(label => {
            const solutionSerie = _.find(opotunitiesSeries, ['code', label]);
            const potentialGrowth = (4 - solutionSerie?.score);
            // Only selected solution's controls are going to show potential growth
            const isControlsSolutionSelected = solution.find((solution) => solution === solutionSerie?.solutions[0]);
            return {
                x: label,
                y: isControlsSolutionSelected && potentialGrowth > 1 ? potentialGrowth : null,
                score_grade: 'A',
                color: 'rgba(14,176,130,0.4)',
                control_label: solutionSerie?.control_label,
                solution: solutionSerie?.solutions || [],
            }
        })
        return formatData;
    }

    return (
        <div className="compliance-score-chart-wrapper">
            <div style={{ minWidth: isMobile ? "576px" : "unset" }}>
                <BarChart
                    stacked={true}
                    width="92%"
                    height={height}
                    series={[{
                        name: 'Now',
                        data: formatData(nowSeries)
                    },{
                        name: 'Then', //Its more 'future' than 'then'
                        data: formatControlPotentialData()
                    }]}
                    xAxisCategories={xAxisCategories}
                    annotations={
                        {
                            position: 'back',
                            yaxis: [{
                                y: baseline,
                                strokeDashArray: 6,
                                borderColor: '#A17AFF',
                                label: {
                                    borderColor: 'transparent',
                                    style: {
                                        color: '#A17AFF',
                                        background: 'transparent',
                                        fontSize: "10px",
                                        fontFamily: 'Open Sans',
                                        cssClass: 'apexcharts-yaxis-annotation-label',
                                    },
                                    offsetX: isMobile ? 6 : 8 * (screenWidth / 1440),
                                    offsetY: 6.5,
                                    text:  isMobile ? "Baseline" : "Baseline standard",
                                    textAnchor: 'start',
                                    position: 'right'
                                }
                            }]
                        }
                    }
                    dataColors={[function (opts) {
                        const { w, seriesIndex, dataPointIndex } = opts;
                        const seriesName = w.config.series[seriesIndex].name;
                        return w.config.series[seriesIndex].data[dataPointIndex]?.color || '#fff';
                    }]}
                    yLabelFormatter={function (value, index) {
                        const tickIndex = {
                            1: 'D',
                            2: 'C',
                            3: 'B',
                            4: 'A'
                        };
                        return tickIndex[index]
                    }}
                    yAxisTickAmount={5}
                    tooltip={
                        {
                            theme: false,
                            custom: function ({ seriesIndex, dataPointIndex, w }) {
                                const solutionData = w.config.series[seriesIndex].data[dataPointIndex];

                                const label = solutionData?.control_label;
                                const x = solutionData?.x;
                                const solutionValue = solutionData?.solution;
                                const solutionAbbreviation = solutionValue.length > 0 ? solutionValue[0] : '';

                                const solutionInfo = solutionsInfo[solutionAbbreviation];

                                const title = solutionAbbreviation !== '' ? solutionAbbreviation : solutionInfo?.title;

                                //return (`<div class="controls-tooltip p-1">
                                //            <span>${label ? `${x}: ${label}` : 'Please complete the assessment'}</span>
                                //        </div>`);
                                return (renderToString(
                                    <SolutionCardTooltip
                                        customClassName='solution-card-tooltip-container'
                                        solutionData={{
                                            name : x + ' ' +label || '',
                                            title: title.replace('aaS', ''),
                                            info: solutionInfo?.info || '',
                                            icon: solutionData?.score_grade,
                                            color: solutionData?.color || '',
                                        }}
                                    />
                                  ));
                            }
                            // y: {
                            //     formatter: function (val, opts) {
                            //         const { w, seriesIndex, dataPointIndex } = opts;
                            //         return w.config.series[seriesIndex].data[dataPointIndex]?.score_grade;
                            //     }
                            // },
                            // x: {
                            //     formatter: function (val) {
                            //         return `<span class="font-weight-bold">${val}</span>`;
                            //     }
                            // }
                        }
                    }
                    eventClick = {handleSelectedBar}
                    states = {{
                        active: {
                            allowMultipleDataPointsSelection: false,
                            filter: {
                                type: 'none'
                            }
                        },
                    }}
                />
            </div>
            <div className='container-solution-buttons'>
            {/*<h5 className='mb-2 text-white'>Purchase our other solutions, solve all your cybersecurity needs </h5>*/}
                {renderButtons()}
            </div>
        </div>
    )
}

export default ComplianceScoreChart