import { createStore, applyMiddleware, compose } from 'redux';
import { QueryClient } from 'react-query';
import rootReducer from '../reducers';
import logger from 'redux-logger'
import Raven from "raven-js";
import thunk from 'redux-thunk';
import * as Sentry from "@sentry/react";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import API from '../api';
import { getMinutesInMilliSeconds } from '../utils/helpers';

Raven.config(process.env.REACT_APP_SENTRY_KEY).install();

//  Initializing sentry config
const sentryEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => { return null; },
  stateTransformer: (state) => { return null; },
  configureScopeWithState: (scope, state) => { }
});

//  Initializing enhancers and middleware array
const composeEnhancers = (process.env.NODE_ENV !== 'production' && window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
let middleware = process.env.NODE_ENV === 'development' ? [logger] : [];

//  React query config
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchInterval: getMinutesInMilliSeconds(5),
      cacheTime: getMinutesInMilliSeconds(5),
    },
    mutations: {
      retry: 3,
    },
  }
});

//  Adding thunk middleware with API as argument
middleware.push(thunk.withExtraArgument({ API, queryClient }));

//  Initializing redux persist config
const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['impersonation'] // reducers to be persisted
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

//  Creating store and persistor
const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middleware), sentryEnhancer));
const persistor = persistStore(store)

export default store;

export { store, persistor, queryClient };
