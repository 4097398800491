import React from 'react';
import ContextMenu from '../../context-menu/ContextMenu';
import ContextMenuOption from '../../context-menu/ContextMenuOption';
import IssuesStatusMenu from '../../issue-manager/issues-context-menu/IssuesStatusMenu';
import { issueTypes } from '../../../constants/issues';

const GroupContextMenu = (props) => {
  //  Parsing props
  const {
    parentRef,
    scrollableRef,
    onRightClick,
    toggleAssigneeModal,
    toggleTagsModal,
    onSelectStatus,
  } = props;

  //  Rendering
  return (
    <ContextMenu
      parentRef={parentRef}
      elementType="tr"
      onElementSelectCallback={onRightClick}
      minMenuWidth={150}
      minMenuHeight={60}
      scrollableRef={scrollableRef}
    >
      <IssuesStatusMenu
        onStatusSelected={onSelectStatus}
        issueType={issueTypes.VULNERABILITIES}
        isDisabled={false}
      />
      <ContextMenuOption
        label="Assign to..."
        callback={toggleAssigneeModal}
      />
      <ContextMenuOption
        label="Tags"
        callback={toggleTagsModal}
      />
    </ContextMenu>
  );
};

export default GroupContextMenu;
