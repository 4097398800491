import React, { useEffect } from 'react';
import AutomationContainer from '../components/automation/AutomationContainer';
import { changeLayout } from '../actions/layout';
import { onAutomationUnmount } from '../actions/automation';
import useMemoizedDispatch from '../hooks/useMemoizedDispatch';

const Automation = (props) => {
  //  Component hooks
  const { dispatch } = useMemoizedDispatch();

  //  Component did mount
  useEffect(() => {
    dispatch(changeLayout('vertical'));
    return () => dispatch(onAutomationUnmount());
  }, []);

  //  Function to render component content
  const renderContent = () => {
    return <AutomationContainer />;
  };

  //  Rendering
  return (
    <div className="automation-container">
      {renderContent()}
    </div>
  );
};

export default Automation;
