import React, { useEffect } from 'react';
import Dashboard from '../components/dashboard/Dashboard';
import CustomersList from '../components/Members/CustomersList';
import { setAlert } from '../actions/alerts';
import API from '../api';
import useMemoizedDispatch from '../hooks/useMemoizedDispatch';
import useCustomQuery from '../hooks/useCustomQuery';

//  Component
const Members = () => {
  //  Initializing APIs
  const { PlanAPI, PartnerAPI } = API;

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const {
    data: plans,
    error: plansError,
  } = useCustomQuery(
    [PlanAPI.keys.listAllPlans],
    () => PlanAPI.handlers.listAllPlans(),
  );

  const {
    data: partnerDefaults,
    error: partnerDefaultsError,
  } = useCustomQuery(
    [PartnerAPI.keys.listPartnersDefaults],
    () => PartnerAPI.handlers.listPartnersDefaults(),
  );

  //  Watching API error
  useEffect(() => {
    if (!plansError) { return; }
    dispatch(setAlert('Error getting plans.', 'danger'));
  }, [plansError]);

  useEffect(() => {
    if (!partnerDefaultsError) { return; }
    dispatch(setAlert('Error getting partners defaults.', 'danger'));
  }, [partnerDefaultsError]);
  //  Rendering
  return (
    <Dashboard
      title="Members"
      containerClassName="dashboard mx-2 px-0 py-4 text-normal-style page-content contained-view-width"
    >
      <CustomersList plans={plans} partnerDefaults={partnerDefaults} />
    </Dashboard>
  )
};

export default Members;
