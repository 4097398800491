import {
  PARTNERS_LIST_SUCCESS,
  PARTNERS_LIST_FAILURE,
  PARTNERS_SET_SUCCESS,
  PARTNERS_SET_FAILURE,
  PARTNERS_UPDATE_EXCLUSIONS,
  FURTIM_ENABLE_SUCCESS,
  FURTIM_ENABLE_FAILURE,
  PARTNERS_CLEAR,
  PARTNERS_RELOAD,
  SET_PARTNERS_FILTER,
  SET_PARTNERS_SORT,
  CLEAR_ALL_PARTNERS_FILTERS,
  PARTNERS_LIST_REQUEST,
  SET_PARTNER_ID_AND_NAME,
  SET_PARTNER_ACTION_VALUE,
  CLEAR_PARTNER_ACTION_VALUES,
  SET_PARTNER_ACTION_CLEANUP_MAP,
  SET_MULTIPLE_PARTNER_ACTION_VALUES,
} from "./types";
import { setAlert } from './alerts';

import filterTypes from '../constants/filterTypes';

export const fetchPartners = (results) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      const { PartnerAPI } = API;
      dispatch({ type: PARTNERS_LIST_REQUEST });
      const response = results?.company_id ? await PartnerAPI.handlers.fetchPartners(results?.company_id, results) :
        await PartnerAPI.handlers.listPartners(results);
      dispatch(fetchPartnersSuccess(response?.data));
    } catch (error) {
      dispatch(setAlert("Oops! We didn’t catch that. Try again", 'danger'));
      dispatch(fetchPartnersError(error));
    }
  };
}

export const fetchPartnersSuccess = (results) => {
  return {
    type: PARTNERS_LIST_SUCCESS,
    payload: results
  }
}

export const updatePartnerExclusions = (partner) => {
  return {
    type: PARTNERS_UPDATE_EXCLUSIONS,
    payload: partner
  }
}

export const fetchPartnersError = (message) => {
  return {
    type: PARTNERS_LIST_FAILURE,
    payload: message
  }
}

export const reloadPartners = (data) => ({
  type: PARTNERS_RELOAD,
  payload: data
});

export const setPartnersFilter = (filterName, value, label, type = filterTypes.single) => {
  return {
    type: SET_PARTNERS_FILTER,
    payload: { filterName, value, label, type }
  }
}

export const clearPartnersFilters = () => {
  return { type: CLEAR_ALL_PARTNERS_FILTERS }
}

export const setPartnersSort = (value) => {
  return {
    type: SET_PARTNERS_SORT,
    payload: value
  }
}

export const setPartnerSuccess = (results) => {
  return {
    type: PARTNERS_SET_SUCCESS,
    payload: results
  }
}

export const setPartnerError = (message) => {
  return {
    type: PARTNERS_SET_FAILURE,
    payload: message
  }
}

export const enableFurtimSuccess = (results) => {
  return {
    type: FURTIM_ENABLE_SUCCESS,
    payload: results
  }
}

export const enableFurtimError = (message) => {
  return {
    type: FURTIM_ENABLE_FAILURE,
    payload: message
  }
}

export const resetPartners = () => {
  return {
    type: PARTNERS_CLEAR,
    payload: null
  }
}

export const setPartnerIdAndName = (partnerId, partnerName) => {
  return {
    type: SET_PARTNER_ID_AND_NAME,
    payload: { partnerId, partnerName }
  }
}

export const setPartnerActionValue = (key, value) => {
  return {
    type: SET_PARTNER_ACTION_VALUE,
    payload: { key, value }
  }
}

export const setMultiplePartnerActionValues = (obj) => {
  return {
    type: SET_MULTIPLE_PARTNER_ACTION_VALUES,
    payload: obj
  };
} 

export const clearPartnerActionValues = () => {
  return {
    type: CLEAR_PARTNER_ACTION_VALUES,
    payload: null,
  };
}

export const setPartnerActionCleanupMap = (map) => {
  return {
    type: SET_PARTNER_ACTION_CLEANUP_MAP,
    payload: map,
  }
}