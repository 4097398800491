import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Button, Row } from 'reactstrap';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import CommentFromEditor from '../layout/comments/CommentFromEditor';
import { TextEditorToolbarDown } from './TextEditorToolbarDown';
import API from '../../api';
import { setAlert } from '../../actions/alerts';
import { trackEvent } from '../../utils/gaTracker';
import errorMessages from '../../constants/errorMessages'
import useCustomMutation from '../../hooks/useCustomMutation';

const VcysoEditor = (props) => {
    const {
        defaultData,
        currentData,
        fieldName,
        preview = false,
        exportContent = false,
        showSubscriptionLink = false,
        companyId,
        placeholder,
        dummyText
    } = props;

    //  Initializing APIs
    const { VcysoAPI } = API;

    //  Memoized dispatch
    const defaultDispatch = useDispatch();
    const dispatch = useCallback(defaultDispatch, []);

    //  Calling API for saving changes
    const {
        data: updateContentResponse,
        error: updateContentError,
        isLoading: updateContentLoading,
        mutate: updateTrustBuilderContent,
    } = useCustomMutation(
        (params) => VcysoAPI.handlers.updateTrustBuilderContent(params),
        VcysoAPI.invalidators.updateTrustBuilderContent
    );

    const [showEditor, setShowEditor] = useState(false);
    const [updatedState, setUpdatedState] = useState({});
    const [savedState, setSavedState] = useState({});

    useEffect(() => {
        if (!currentData) return;
        setSavedState({
            content: currentData[fieldName]?.content,
            hide: currentData[fieldName]?.hide
        })
    }, [currentData])

    useEffect(() => {
        if (updateContentResponse) {
            dispatch(setAlert('Trust Builder updated', 'success'));
            setShowEditor(false)
            setSavedState(updatedState);
        }
        if (updateContentError) {
            dispatch(setAlert(errorMessages?.defaultPost, 'danger'));
            if (!currentData) return;
        }
    }, [updateContentResponse, updateContentError])

    const cancelCallback = () => {
        setShowEditor(!showEditor)
    }

    const saveCallback = (content, hide = false) => {
        updateTrustBuilderContent({
            field: fieldName,
            content: {
                content,
                hide
            },
            company_id: companyId
        })
        setUpdatedState({
            content,
            hide
        })
    }

    //  On sales button clicked
    const onSalesButtonClicked = () => {
        trackEvent('subscribe-cyso-info-bar', 'cyso', 'subscribe-cyso-info-bar');
        window.open(process.env.REACT_APP_CONTACT_SALES_URL);
    };

    if (!currentData || !defaultData) return null;
    if (exportContent && currentData[fieldName]?.hide) return null;

    return (
        <Card className={`vcyso-editor mb-0 ${(!preview) ? 'hoverable-content' : ''}`} color="dark">
            <CardBody className={showEditor ? 'vcyso-text-editor-container p-0' : ' p-3'}>
                <Row className="no-gutters justify-content-between">
                    {showEditor && (
                        <Col sm={12} className="vcyso-text-editor-c">
                            <TextEditorToolbarDown
                                saveCallback={saveCallback}
                                cancelCallback={cancelCallback}
                                processing={updateContentLoading}
                                content={savedState?.content}
                                defaultContent={defaultData[fieldName]?.content}
                                hidden={savedState?.hide}
                                placeholder={placeholder}
                            />
                        </Col>
                    )}

                    {!showEditor && (
                        <Col xs={9} >
                            <div>
                                <CommentFromEditor
                                    rawContent={savedState?.content}
                                    text={dummyText}
                                    hidden={savedState?.hide}
                                    placeholder={placeholder}
                                    fromText={preview && !savedState?.content}
                                />
                            </div>
                        </Col>
                    )}
                    <div>
                        {preview && !exportContent && showSubscriptionLink && (
                            <a
                                className="btn btn-outline-light-gray ml-auto"
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => onSalesButtonClicked()}
                            >
                                Subscribe CySO for full version
                            </a>
                        )}
                        {(!preview && !showEditor && !exportContent) && (
                            <>
                                <Button
                                    color="light-gray"
                                    className="btn-edit-section d-flex py-2 px-3 ml-auto"
                                    outline
                                    onClick={() => { setShowEditor(!showEditor) }}
                                >
                                    <i className='bx bx-edit-alt mr-2 my-auto'></i> Edit
                                </Button>
                                {savedState?.hide && (
                                    <div className="hidden-label mt-3">This section is hidden on the PDF export </div>
                                )}
                            </>
                        )}
                    </div>
                </Row>
            </CardBody>
        </Card>
    )
}

export default VcysoEditor;