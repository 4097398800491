import React, {useState} from "react";
import {
  additionalWidgetsIds,
} from "../../../../constants/dashboard";
import AdditionalWidget from '../dashboard/AdditionalWidget';
import CompanyMemberInvite from "../../../../components/profile/CompanyMemberInvite";

const AddMembersWidget = (props) => {

  //Component State
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);

  const {
    className = "",
    message = "",
    buttonMessage = "",
    widgetID = additionalWidgetsIds.ADD_TEAM_MEMBER,
    icon,
    companyId,
  } = props;

  //  Function for opening and closing add members modal
  const openAddMembersModal = () => setShowAddMemberModal(true);
  const closeAddMembersModal = () => setShowAddMemberModal(false);

  //  Function to render add members modal
  const renderAddMembersModal = () => {
    if (!showAddMemberModal) { return null; };
    return (
      <CompanyMemberInvite
        closeModal={closeAddMembersModal}
        customerId={companyId}
      />
    );
  };

  return (
    <>
      <AdditionalWidget
        className={className}
        message={message}
        buttonMessage={buttonMessage}
        widgetID={widgetID}
        icon={icon}
        companyId={companyId}
        buttonOnClick={openAddMembersModal}
      />
      {renderAddMembersModal()}
    </>
  );
};

export default AddMembersWidget;
