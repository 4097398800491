import React, { useMemo } from 'react';
import SelectableTextCell from './TableCells/SelectableTextCell';
import CustomTable from './CustomTable';
import { cellTypes } from '../../constants/tableTemplates';

const CustomTableCell = (props) => {
  //  Parsing props
  const {
    id,
    object,
    entry,
    cellType,
    cellClassName = '',
    subtemplate = [],
    containerClassName,
    customRender = () => null,
    customParams = { objectName: 'object', valueName: 'value' },
    allSelected = false,
    selected = [],
    excluded = [],
    handleSelectedEvent,
    rowIndex,
    handleCellAction,
    customCellCallback
  } = props;

  const value = useMemo(() => object?.[entry], [object, entry]);

  //  Function to render cell
  const renderCell = () => {
    //  Rendering text cell
    if (cellType === cellTypes.SELECTABLE || cellType === cellTypes.TEXT || cellType === cellTypes.SELECTABLE_TOOLTIP) {
      return (
        <SelectableTextCell
          id={id}
          value={value}
          isSelectable={cellType === cellTypes.SELECTABLE || cellType === cellTypes.SELECTABLE_TOOLTIP}
          hasTooltip={cellType === cellTypes.SELECTABLE_TOOLTIP}
          allSelected={allSelected}
          selected={selected}
          excluded={excluded}
          handleSelectedEvent={handleSelectedEvent}
        />
      );
    }

    //  Rendering subtable cell (currently just used in automation rules)
    if (cellType === cellTypes.SUBTABLE) {
      return (
        <CustomTable
          tableClassName={containerClassName}
          rowClassName={cellClassName}
          columnsTemplate={subtemplate}
          data={value}
          isSubtable={true}
        />
      );
    }

    //  Rendering custom cell
    if (cellType === cellTypes.CUSTOM || cellType === cellTypes.SELECTABLE_CUSTOM) {
      const paramsForComponent = {
        [customParams['objectName']]: object,
        [customParams['valueName']]: value,
        rowIndex: rowIndex,
        handleCellAction
      };
      return cellType === cellTypes.SELECTABLE_CUSTOM ? (
        <SelectableTextCell
          id={id}
          value={value}
          isSelectable={true}
          hasTooltip={false}
          allSelected={allSelected}
          selected={selected}
          excluded={excluded}
          handleSelectedEvent={handleSelectedEvent}
          customRender={customRender}
          customParams={paramsForComponent}
        />
      ) : customRender(paramsForComponent)
    }
    return null;
  }

  const handleClickCell = (event) => {
    if (customCellCallback) event.stopPropagation();
  }

  //  Rendering
  return cellType === cellTypes.SUBTABLE ? renderCell() : (
    <td className={cellClassName} onClick={(event) => handleClickCell(event)}>
      {renderCell()}
    </td>
  );
};

export default CustomTableCell;
