import _ from 'lodash';

class CSVSchemaValidator {
  constructor() {
    if (CSVSchemaValidator._instance) {
      return CSVSchemaValidator._instance
    }
    CSVSchemaValidator._instance = this;
    this._config = furtimConfig; // default for the moment
  }

  updatePartnerConfig(partnerName) {
    this._config = partnersConfig[partnerName] || furtimConfig;
  }

  validate(data) {
    let isHeadersChecked = false;
    let invalidMessages = [];

    data.some((row, rowIndex) => {
      if (!isHeadersChecked) {
        let missingHeaders = [];
        this._config.headers.map(key => {
          const includeHeader = row.meta.fields.includes(key.name);
          includeHeader || missingHeaders.push(key.name);
        });
        isHeadersChecked = true;
        const numOfColumnsMissing = missingHeaders.length;
        if (numOfColumnsMissing > 0) {
          const colsName = missingHeaders.join(', ');
          if (numOfColumnsMissing === 1) {
            invalidMessages.push(`Please refer to the header, column "${colsName}" is missing`);
          }
          else {
            invalidMessages.push(`Please refer to the header, columns "${colsName}" are missing`);
          }

          return true;
        }
      }

      let rowError = false;

      this._config.headers.some((col, colIndex) => {
        const field = row.data[col.name];
        const allowNull = col.hasOwnProperty('allowNull') ? col.allowNull !== false : true;
        const fieldIsNull = _.isNull(field);

        if (!allowNull) {
          if (fieldIsNull) {
            rowError = true;
          }
        }

        switch (col.type) {
          case 'STRING':
            const fieldIsString = _.isString(field);
            const fieldIsStringOrNull = (allowNull) ? fieldIsNull || fieldIsString : fieldIsString;
            if (!fieldIsStringOrNull) rowError = true;
            break;
          case 'NUMBER':
            const fieldIsNumber = _.isNumber(field);
            const fieldIsNumberOrNull = (allowNull) ? fieldIsNull || fieldIsNumber: fieldIsNumber;
            if (!fieldIsNumberOrNull) rowError = true;
            break;
          case 'BOOLEAN':
            const fieldIsBoolean = _.isBoolean(field) || (typeof(field) === 'string' && (field.toLowerCase() === 'true' || field.toLowerCase() === 'false'));
            const fieldIsBooleanOrNull= (allowNull) ? fieldIsNull || fieldIsBoolean : fieldIsBoolean;
            if (!fieldIsBooleanOrNull) rowError = true;
            break;
          default:
        };

        return rowError;
      });

      if (rowError) invalidMessages.push(`Please refer to line "${(rowIndex + 1)}" and make all the necessary changes.`);
    });

    return invalidMessages;
  }
}

export default CSVSchemaValidator;

const furtimConfig = {
  headers: [
    {
      name: 'name',
      allowNull: false,
      type: 'STRING',
    },
    {
      name: 'site_name',
      type: 'STRING',
    },
    {
      name: 'ip_address',
      allowNull: false,
      type: 'STRING',
    },
    {
      name: 'port',
      type: 'NUMBER',
    },
    {
      name: 'protocol',
      type: 'STRING',
    },
    {
      name: 'service_name',
      type: 'STRING',
    },
    {
      name: 'nt_name',
      type: 'STRING',
    },
    {
      name: 'nt_domain',
      type: 'STRING',
    },
    {
      name: 'severity',
      type: 'STRING',
      allowNull: false,
    },
    {
      name: 'cvssv2_score',
      type: 'NUMBER',
    },
    {
      name: 'synopsis',
      allowNull: false,
      type: 'STRING',
    },
    {
      name: 'remediation',
      type: 'STRING',
    },
    {
      name: 'exploits_available',
      allowNull: false,
      type: 'BOOLEAN',
    },
    {
      name: 'client_id',
      allowNull: false,
      type: 'NUMBER',
    },
    {
      name: 'client_slug',
      allowNull: false,
      type: 'STRING',
    },
  ],
};

const tenableConfig = {
  headers: [
    {
      name: 'name',
      allowNull: false,
      type: 'STRING',
    },
    {
      name: 'site_name',
      type: 'STRING',
    },
    {
      name: 'ip_address',
      allowNull: false,
      type: 'STRING',
    },
    {
      name: 'port',
      type: 'NUMBER',
    },
    {
      name: 'protocol',
      type: 'STRING',
    },
    {
      name: 'service_name',
      type: 'STRING',
    },
    {
      name: 'nt_name',
      type: 'STRING',
    },
    {
      name: 'nt_domain',
      type: 'STRING',
    },
    {
      name: 'severity',
      type: 'STRING',
      allowNull: false,
    },
    {
      name: 'cvssv2_score',
      type: 'NUMBER',
    },
    {
      name: 'synopsis',
      allowNull: false,
      type: 'STRING',
    },
    {
      name: 'remediation',
      type: 'STRING',
    },
    {
      name: 'exploits_available',
      allowNull: false,
      type: 'BOOLEAN',
    },
    {
      name: 'client_id',
      allowNull: true,
      type: 'NUMBER',
    },
    {
      name: 'client_slug',
      allowNull: true,
      type: 'STRING',
    },
  ],
};

const partnersConfig = {
  Furtim: furtimConfig,
  Tenable: tenableConfig
}
