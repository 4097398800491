import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import SelectDropdownIndicator from './fields/SelectDropdownIndicator';
import { markSelectedOptions } from '../../utils/helpers';
import { onFocus } from '../../utils/helpers.js';

//  Component
const SimpleMultipleDropdown = (props) => {
  // Parsing props
  const {
    isDisabled = false,
    extraClassName = '',
    emptyLabel = '',
    restoreToDefault = false,
    elements = [],
    onChange, // Callback to call when selected element changes
    //  Props to manage previously selected elements
    isMultiSelect = false,
    selectedElements = [], // IMPORTANT: always send this parameter from parent, even if it is []
    allElementsSelected = false,
    //  Props to manage default all members element
    addAllElementsOption = false,
    allElementsLabel = 'All Elements',
    initialElement = null,
    customMenuPosition = 'fixed',
    ariaLabel = ''
  } = props;

  //  Component ref
  const didMountRef = useRef(null);

  //  Variable to add all elements option
  const allElementsOption = { label: allElementsLabel, value: -1 };

  //  Component state
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedElement, setSelectedElement] = useState(initialElement);
  const [searchTerm, setSearchTerm] = useState('');

  //  Function to clean component
  const cleanState = () => {
    setSearchTerm('');
    setSelectedElement(null);
  };

  //  Watching restore to default flag
  useEffect(() => {
    if (!restoreToDefault || !didMountRef.current) { return; }
    cleanState();
  }, [restoreToDefault]);

  //  Watching elected element changes
  useEffect(() => {
    if (selectedElement?.selected) { return; }
    onChange(selectedElement);
  }, [selectedElement]);

  //  Watching elements changes
  useEffect(() => {
    const markedElements = isMultiSelect ? markSelectedOptions(selectedElements, elements) : elements;
    setFilteredOptions(markedElements);
  }, [elements, selectedElements]);

  //  Watching initial element changes
  useEffect(() => {
    if (initialElement) { return; }
    setSelectedElement(null);
  }, [initialElement]);

  //  Watching all elements selected options
  useEffect(() => {
    if (!addAllElementsOption) { return; }
    if (allElementsSelected && selectedElements.length === 0 && !selectedElement) {
      setSelectedElement(allElementsOption);
    }
  }, [allElementsSelected]);

  //  Component did mount
  useEffect(() => {
    didMountRef.current = true;
  }, []);

  //  Function to handle dropdown change
  const handleDropdownChange = (element) => {
    setSelectedElement(element);
  };

  //  Function to get current value
  const getCurrentValue = () => {
    if (!isMultiSelect) { return selectedElement; }
    if (addAllElementsOption && selectedElement?.value === -1) { return selectedElement; }
    return null;
  }

  // Select Dropdown Indicator
  const DropdownIndicator = (props) => {
    return <SelectDropdownIndicator {...props} />
  };

  //  Custom styles
  const customStyle = {
    option: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data?.selected ? '#361f93!important' : 'transparent',
        color: data?.selected ? '#fff' : '#a6b0cf'
      };
    }
  };

  //  Rendering
  return (
    <Select
      ariaLiveMessages={{
        onFocus,
      }}
      aria-live="polite"
      inputValue={searchTerm}
      value={getCurrentValue()}
      options={filteredOptions}
      onChange={handleDropdownChange}
      onInputChange={setSearchTerm}
      className={`react-select-container ${extraClassName}`}
      classNamePrefix="react-select"
      placeholder={emptyLabel}
      components={{ DropdownIndicator }}
      isDisabled={isDisabled}
      isSearchable={true}
      menuPosition={customMenuPosition}
      styles={customStyle}
      aria-label={filteredOptions?.length === 0 ? `${ariaLabel} combobox. Current combobox has no options` : ariaLabel}
    />
  );
};

export default SimpleMultipleDropdown;
