import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { getSafeArray, isValidFunction } from '../../utils/helpers';


const SetupDropdown = (props) => {
    const {
        buttonClassName="",
        menuClassName,
        customLabel,
        customIcon = 'bx bx-wrench',
        options = [],
        direction="left",
        id,
    } = props;

    // Component's state
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    }

    const renderSetupIcon = () => {
        return (
            <i className={customIcon} id = {id}/>
        )
    }

    const renderDropdownToggle = () => {
        return (
            <DropdownToggle
                className={`setup-dropdown-button ${buttonClassName}`}
                color="link"
                caret
            >
                {customLabel || renderSetupIcon()}
            </DropdownToggle>
        )
    }

    const renderDropdownMenu = () => {
        return (
            <DropdownMenu
                className={`settings-dropdown-menu ${menuClassName}`}
            >
                {renderOptions()}
            </DropdownMenu>
        )
    }

    const onDropdownItemClick = (callback) => {
        if(isValidFunction(callback)) {
            callback();
        }
    }

    const renderOptions = () => {
        const safeOptions = getSafeArray(options)
        return safeOptions.map((option, index) => {
            const { label, callback, isSelected, isDisabled, icon } = option;
            return (
                <DropdownItem
                    onClick={() => onDropdownItemClick(callback)}
                    disabled={isDisabled}
                    key={`dropdown-option-${index}`}
                >
                    {icon ? 
                        <div className="check-container">
                            <i className={icon}></i>
                        </div>
                    :
                        <div className="check-container">
                            {isSelected && (
                                <i className='option-check bx bx-check'/>
                            )}
                        </div>
                    }
                    {label}
                </DropdownItem>
            )
        })
    }

    return (
        <Dropdown
            isOpen={isOpen}
            toggle={toggleDropdown}
            direction={direction}
        >
            {renderDropdownToggle()}
            {renderDropdownMenu()}
        </Dropdown>
    );
};

export default SetupDropdown;
