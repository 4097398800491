import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { subTabsIdentifiers } from '../constants/navigation';

const useSelectedSubtab = (props) => {
  //  Parsing props
  const { entry = '' } = props;
  //  Getting the identifiers for the current page
  const identifiers = useMemo(() => subTabsIdentifiers[entry], [entry]);
  //  Watching selected sub tab
  const selectedSubtab = useSelector((state) => state.navigation.selectedSubtab);
  //  Hook state
  const [selectedIdentifier, setSelectedIdentifier] = useState(null);

  //  Watching subtab changes
  useEffect(() => {
    setSelectedIdentifier(selectedSubtab?.identifier);
  }, [selectedSubtab]);
  //  Returning needed assets
  return {
    identifiers,
    selectedIdentifier,
  }
};

export default useSelectedSubtab;
