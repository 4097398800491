import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { getCheckboxesState } from '../../utils/profileHelpers';
import CustomCheckBox from '../layout/CustomCheckBox';

const NotificationSettingsCheckboxCard = (props) => {

  const {
    items,
    setSelectedCheckboxes,
    selectedCheckboxes
  } = props;

  const renderCheckboxes = () => {
    return items.map(( value, index ) => {
      return (
        <>
          <Row>
            <Col>
              <CustomCheckBox
                className={value.className}
                isSelected={selectedCheckboxes?.includes(value?.id)}
                targetId={value.targetId}
                callback={() => {setSelectedCheckboxes(getCheckboxesState(selectedCheckboxes,value))}}
                label={value.label}
                isDisabled={false}
              />
            </Col>
          </Row>
        </>
      );
    });
  };

  return (
    <>
      {renderCheckboxes()}
    </>
  )
}

export default NotificationSettingsCheckboxCard