import React, { useEffect, useState } from 'react';
import SimpleDropdown from '../../layout/SimpleDropdown';
import { frequencyOptions } from '../../../constants/automation';

const FrequencyDropdown = (props) => {
  //  Parsing props
  const { freq, changeFreq } = props;

  //  Component state
  const [selectedFrequency, setSelectedFrequency] = useState(null);

  //  Watching frequency changes
  useEffect(() => {
    if (!freq) { setSelectedFrequency(null); }
    const elementToSelect = frequencyOptions.find((option) => option.label.toUpperCase() === freq);
    setSelectedFrequency(elementToSelect);
  }, [freq]);

  //  Function to handle dropdown change
  const onDropdownChange = (option) => {
    if (!option) { return; }
    changeFreq(option?.label?.toUpperCase());
  };

  //  Rendering
  return (
    <div className="frequency-container">
      <SimpleDropdown
        extraClassName={`dropdown-filter action-dropdown freq-picker${selectedFrequency ? ' active' : ''}`}
        placeholder="Select frequency"
        elements={frequencyOptions}
        onChangeCallback={onDropdownChange}
        selectedElement={selectedFrequency}
      />
    </div>
  );
};

export default FrequencyDropdown;
