import React from 'react';

export default function NotificationTabs({ selectedTab, setSelectedTab }) {
	const OPTIONS = [
		{ name: 'General notifications', value: 1 },
		{ name: 'Events notifications', value: 2 }
	]

	return (
		<div className='notification-tabs-container'>
			{OPTIONS.map((option, index) => (
				<p
					className={option.value === selectedTab ? 'notification-tab-selected' : ''}
					onClick={() => setSelectedTab(option.value)}
				>
					{option.name}
				</p>
			))}
		</div>
	);
}