import React, { useEffect, useMemo, useState } from 'react';
import { UilAngleUp, UilAngleDown } from '@iconscout/react-unicons'
import { getSafeArray } from '../../utils/helpers';

const SortIcon = (props) => {
  //  Parsing props
  const {
    containerClassName = '',
    entry = '',
    changeSort,
    sort = [],
    allowMultipleSort = false,
  } = props;

  //  Component variables
  const isActiveSort = useMemo(() => {
    const safeSort = getSafeArray(sort);
    if (!allowMultipleSort) { return (safeSort[0] === entry); }

    //  Finding a sort that fits the entry
    const matchingSort = safeSort.find((currentSort = []) => currentSort[0] === entry);
    return !!matchingSort;
  }, [sort, allowMultipleSort]);

  //  Component state
  const [orientation, setOrientation] = useState(null);

  //  Watching original sort states
  useEffect(() => {
    if (sort.length > 0) { return; }
    setOrientation(null);
  }, [sort]);

  //  Watching orientation changes
  useEffect(() => {
    if ((!orientation && !allowMultipleSort) || !changeSort) { return; }
    changeSort(entry, orientation, false);
  }, [orientation]);

  //  Function to handle sorting click
  const onIconClick = () => {
    if (!orientation) { setOrientation('ASC'); return; }
    if (orientation === 'ASC') { setOrientation('DESC'); return; }
    setOrientation(allowMultipleSort ? null : 'ASC');
  };

  const renderIcon = () => {
    let iconClassname = 'bx bxs-sort-alt';

    if (isActiveSort) {
      iconClassname = orientation === 'ASC' ? 'bx bxs-up-arrow sort-arrow' : 'bx bxs-down-arrow sort-arrow'
    }
    return (<i className={iconClassname} />);
  };

  //  Rendering
  return (
    <div
      className={`ml-1 ${containerClassName}`}
      onClick={onIconClick}
    >
      {renderIcon()}
    </div >
  );
};

export default SortIcon;
