import moment from 'moment-timezone';
import { recycleBinEntities } from '../constants/common';
import { getDeviceEntityIPAddresses, getDeviceEntityMACAddress } from './deviceEntityHelper';

export const parseRecycleBinElements = (type = recycleBinEntities.devices, elements = []) => {
  return elements.map((element) => {
    const {
      deleter,
      company,
      device_entity = {},
      ...restOfElement
    } = element;

    //  Building new parsed element
    const parsedElement = {
      ...restOfElement,
      deleted_at: moment(restOfElement?.deleted_at).format('D MMM, YY'),
      company: company?.name,
      deleter: deleter?.full_name,
      type,
    };

    //  Adding ip address for devices
    if (type === recycleBinEntities.devices) {
      parsedElement.ip_address = getDeviceEntityIPAddresses({ device_entity });
      parsedElement.mac_address = getDeviceEntityMACAddress({ device_entity });
    }

    return parsedElement;
  })
};

export const getSelectedIds = (allSelected = false, selected = [], excluded = [], elements = []) => {
  const initialArray = allSelected ? elements.map((element) => element.id) : selected;
  return initialArray.filter((element) => !excluded?.includes(element));
}
