import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ClarityHeader from "./ClarityHeader";
import { publicRoutesPaths } from '../../../../constants/navigation';
import { existPathOnList } from '../../../../utils/helpers';

const Header = (props) => {

  const {
    toggleMenuCallback,
    layoutType,
    showFixedHeader = true
  } = props;

  //  Watching redux state
  const isShowingIssueExpandedView = useSelector((state) => state.layout.isShowingIssueExpandedView);

  const location = useLocation();
  const isPublicRoute = useMemo(() => {
    return existPathOnList(location?.pathname, publicRoutesPaths);
  }, [location])

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    setScrollPosition(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  //  Function to get shown classname
  const getVisibilityClassname = () => {
    if (isShowingIssueExpandedView) { return ''; }
    return (showFixedHeader || (!showFixedHeader && scrollPosition > 100)) ? 'isShown' : 'd-none';
  };

  return (
    <ClarityHeader
      customClassName={`fixed-topbar top-${layoutType} ${layoutType === "vertical" || isPublicRoute ? "" : "img-bg"} ${getVisibilityClassname()}`}
      layoutType={layoutType}
      toggleMenuCallback={toggleMenuCallback}
      allowBetaVersion={true}
    />
  );
};

export default Header;
