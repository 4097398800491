import React, { useState, useEffect, useRef } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import { Button } from 'reactstrap';
import BasicModal from "../layout/BasicModal";
import { authUser } from '../../actions/auth';

const SessionTimeout = (props) => {
    const idleTimer = useRef();
    const warningTimeout = 1000 * 10;
    const allowedIdleTime = 1000 * 60 * 60; //Has to be higher than warningTimeout
    const timeout =  (allowedIdleTime - warningTimeout)/2;

    const [isTimedOut,setIsTimedOut] = useState(false);
    const [showWarning,setShowWarning] = useState(false);
    const [reachedLimit,setReachedLimit] = useState(false);
    const [confirm,setConfirm] = useState(false);
    const [warningRemainingSeconds,setWarningRemainingSeconds] = useState(0)

    useEffect(() => {
        if(showWarning) {
            setTimeout(() => {
                setReachedLimit(true);
            }, warningTimeout);
        }   
     },[showWarning]);

    useEffect(() => {
        if(reachedLimit) {
            setShowWarning(false);
            if(!confirm) props.history.push('/logout');
            setConfirm(false);
            setReachedLimit(false);
        }
    },[reachedLimit]);

    useEffect(() => {
        const intervalCountdown = setInterval(() => {
            setWarningRemainingSeconds(warningRemainingSeconds => warningRemainingSeconds - 1);
        }, 1000);
        if(warningRemainingSeconds === 0) clearInterval(intervalCountdown);
        return () => clearInterval(intervalCountdown);

    }, [warningRemainingSeconds]);

    const toggleModal = () => {
        setShowWarning(!showWarning);
    }

    const onAction = (e) => {
        setIsTimedOut(false);
    }
     
    const onActive = (e) => {
        setIsTimedOut(false); 
    }
    
    const onIdle = (e) => {
        if (isTimedOut) {
            setShowWarning(true);
            setWarningRemainingSeconds(warningTimeout/1000)
        } else {
            idleTimer.current.reset();
            setIsTimedOut(true);  
        }
    }

    return  (
        <React.Fragment>
            <IdleTimer
                ref={idleTimer}
                element={document}
                onActive={onActive}
                onIdle={onIdle}
                onAction={onAction}
                debounce={250}
                timeout={timeout}
            />
            {showWarning ? (
                <BasicModal
                    title="Your session is about to expire!"
                    subtitle={`Redirecting in ${warningRemainingSeconds} seconds`}
                    showModal={showWarning}
                    toggleModal={() => toggleModal()}   
                    customClassName="company-upgrade"
                >
                    <div className="d-flex justify-content-center">
                        <Button 
                            color="danger" 
                            className="mr-2"
                            onClick={() => { 
                                toggleModal();
                                setConfirm(false);
                                setReachedLimit(false);
                                props.history.push('/logout');
                            }}
                        >
                            Logout
                        </Button>
                        <Button 
                            color="light"
                            onClick={() => {
                                toggleModal();
                                setConfirm(true);
                                props.authUser();
                            }}
                        >
                            Stay connected
                        </Button>
                    </div>
                </BasicModal>
            ) : null}
        </React.Fragment>
    )
}
const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
      return {
        user: state.auth.user
      }
    }
    return mapStateToProps;
}

export default withRouter(connect(makeMapStateToProps, { authUser })(SessionTimeout));