import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import defaultIconImg from '../../assets/images/icons/default-partner-icon.svg'

const PartnerIconsListTooltip = (props) => {
    const {
        partners,
        targetId,
        placement='bottom',
        className = ""
    } = props;

    const addDefaultSrc = (ev) => {
        ev.target.src = defaultIconImg
    }

    const renderPartners = (previewList = []) => {
        return previewList.map((partner, index) => {
            const { name } = partner;
            const iconSrc = `${process.env.REACT_APP_PUBLIC_RESOURCES}/partners/${name?.replace(" ","-")?.toLowerCase()}.svg`;
            return (
                <div
                    key={`partner-icon-container-${index}`}
                    className="partner-icon-container"
                >
                    <div className="partner-icon-wrapper d-inline-block mr-2">
                        <img
                            className="img-fluid"
                            src={iconSrc}
                            alt={name}
                            onError={addDefaultSrc}
                        />
                    </div>
                    {name}
                </div>
            )
        })
    }

    const renderRemainingCount = (count) => {
        if(count < 1) return null;
        return (
            <div className="d-inline-block mr-auto">
                {`(+${count}...)`}
            </div>
        )
    }

    const renderContent = () => {
        if(!partners || !Array.isArray(partners) || (partners?.length < 1)) return null;
        const previewList = partners.slice(0,5);
        const remainingCount = partners?.length - previewList?.length
        return (
            <>
                {renderPartners(previewList)}
                {renderRemainingCount(remainingCount)}
            </>
        )
    }

    return (
        <UncontrolledTooltip
            className={`partners-icons-list-tooltip ${className}`}
            placement={placement}
            target={targetId}
            arrowClassName={`partners-icons-list-tooltip-arrow ${className || ''}`}
        >
            {renderContent()}
        </UncontrolledTooltip>
    )
}

export default PartnerIconsListTooltip
