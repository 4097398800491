import React from 'react'
import { Redirect } from 'react-router-dom'
import { PRIVILEGE_SOURCES, READ_ACTION } from '../../constants/privileges';
import useCanAbility from '../../hooks/useCanAbility';
import FullAssessmentView from '../../views/FullAssessmentView';

const AssessmentRoutes = () => {

    // Component Hooks
    const ability = useCanAbility();

    return ability.can(READ_ACTION, PRIVILEGE_SOURCES.SIDEBAR.ASSESSMENT_TAB) ? (
        <FullAssessmentView />
    ) : (
        <Redirect to="/dashboard/assessment"/>
    )
}

export default AssessmentRoutes;
