import React, { useEffect, useState } from 'react';
import LoadingContent from '../../layout/LoadingContent';
import CustomActionModal from '../../layout/CustomActionModal';
import API from '../../../api';
import { setAlert } from '../../../actions/alerts';
import { getAttachmentFileClassName, isValidFunction } from '../../../utils/helpers';
import useCustomMutation from '../../../hooks/useCustomMutation';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import { useHistory } from 'react-router-dom';

const AttachmentPreview = (props) => {
  //  Parsing props
  const {
    file,
    deleteFileCallback,
    callbackLoading,
    company_public_id: companyPublicId
  } = props;

  //  Initializing API
  const { GrcIssueAPI } = API;

  //  Component state
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const {
    data: deleteData,
    error: deleteError,
    mutate: deleteFile,
    isLoading: deleteLoading,
  } = useCustomMutation(
    (params) => GrcIssueAPI.handlers.deleteIssueAttachment(params),
    GrcIssueAPI.invalidators.deleteIssueAttachment,
  );

  //  Watching delete success
  useEffect(() => {
    if (!deleteData) { return; }
    dispatch(setAlert('File deleted successfully', 'success'));
  }, [deleteData]);

  //  Watching delete error
  useEffect(() => {
    if (!deleteError) { return; }
    dispatch(setAlert('Error deleting file', 'danger'));
  }, [deleteError]);

  //  Function to handle delete click
  const onShowDelete = () => {
    setShowDeleteModal(true);
  };

  //  Function to delete file
  const onDelete = () => {
    if (isValidFunction(deleteFileCallback)) {
      deleteFileCallback(file?.id);
    } else {
      deleteFile(file?.id);
    }
    setShowDeleteModal(false);
  };
/**
 * It opens a new tab with the url of the file
 */

  const openUrl = () => {
    const url = `/profile/company/${companyPublicId}/attachments/${file?.id}`;
    const newWin = window.open(url);
    newWin.focus();
  }

  //  Function to render header
  const renderHeader = () => {
    return (
      <div className="file-header">
        <div className="file-options">
          <span
            onClick={() => openUrl()}
            className="download-icon"
            name={`/profile/company/${companyPublicId}/attachments/${file?.id}`}
          >
            <div className="bx bx-download download-icon" />
          </span>
          <div
            className="bx bxs-trash trash-icon"
            onClick={onShowDelete}
          />
        </div>
      </div>
    );
  };

  //  Function to render file name
  const renderFileName = () => {
    return (
      <div className="file-name-container">
        <div className="file-name">
          {file?.name}
        </div>
      </div>
    );
  };

  //  Function to render delete modal
  const renderDeleteModal = () => {
    if (!showDeleteModal) { return null; }
    return (
      <CustomActionModal
        closeModal={() => setShowDeleteModal(false)}
        title='Delete File'
        subtitle={`Are you sure you want to PERMANENTLY delete ${file?.name}?`}
        disabled={deleteLoading || callbackLoading}
        onActionClick={() => onDelete()}
        actionText="Delete"
        actionClassName="btn btn-submit btn-red text-dark font-weight-bold delete-rule-button"
      />
    );
  }

  //  Function to render preview
  const renderPreview = () => {
    const { type } = file;
    if (!type?.includes('image')) {
      let fileClassName = getAttachmentFileClassName(type);

      return (
        <div className="file-icon-container">
          <i className={`file-icon ${fileClassName}`} />
        </div>
      );
    }

    return (
      <img
        className="file-image"
        src={file?.url}
      />
    );
  };

  //  Rendering
  return (
    <>
      <div className="file-item">
        <div className="file-content">
          <LoadingContent
            isLoading={file?.isLoading || deleteLoading || callbackLoading}
            loadingStyle={{ width: '225px', height: '152px' }}
            loadingClassName="attachment-preview-spinner"
          >
            {renderHeader()}
            {renderPreview()}
            <div className="file-hover-layer" />
          </LoadingContent>
          {renderFileName()}
        </div>
      </div>
      {renderDeleteModal()}
    </>
  );
};

export default AttachmentPreview;
