import React, { useEffect, useState } from 'react';
import { Col } from 'reactstrap';
import IconCard from '../../layout/cards/IconCard';
import API from '../../../api';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import { useQueryClient } from 'react-query';
import useFilesUploader from '../../../hooks/useFilesUploader';
import { customTableEvents, grcAttachmentsExtensions, tablePaginationTypes } from '../../../constants/common';
import { getApiErrorMessage, getSafeArray, mapObjSortToArray } from '../../../utils/helpers';
import { setAlert } from '../../../actions/alerts';
import { alertTypes } from '../../../constants/alerts';
import useCustomQuery from '../../../hooks/useCustomQuery';
import LoadingContent from '../../layout/LoadingContent';
import CompanyAttachmentPreview from './CompanyAttachmentPreview';
import useCanAbility from '../../../hooks/useCanAbility';
import { MODIFY_ACTION, PRIVILEGE_SOURCES } from '../../../constants/privileges';
import AttachmentOverlay from '../../layout/attachments/AttachmentOverlay';
import AttachmentDragBrowseArea from '../../layout/attachments/AttachmentDragBrowseArea';
import CustomTable from '../../CustomTable/CustomTable';
import { attachmentsTableTemplate } from '../../../constants/app-v4/attachmentsTableTemplate';
import SearchBar from '../../layout/filters/SearchBar';
import useSelectedList from '../../../hooks/useSelectedList';
import CompanyAttachmentsBulkActions from './CompanyAttachmentsBulkActions';

//  Initial filters
const initialFilters = {
  offset: 0,
  sort: [],
  text_to_search: '',
  //limit: infiniteTableLimit,
};

const CompanyAttachments = (props) => {

  const {
    companyPublicId: company_public_id,
    companyId,
    isDragOver,
    clearDraggedState,
    lg = 4,
  } = props;

  //  Initializing API
  const { CompanyAPI } = API;

  //  Component state
  const [filters, setFilters] = useState(initialFilters);
  const [elements, setElements] = useState([]);

  //  Function to handle file upload
  const handleFileUpload = async (files) => {
    const params = { company_public_id, files };
    return await CompanyAPI.handlers.uploadCompanyAttachment(params);
  };

  //  Component hooks
  const ability = useCanAbility();
  const { dispatch } = useMemoizedDispatch();
  const queryClient = useQueryClient();

  const {
    selected,
    excluded,
    allSelected,
    clearSelected,
    eventHandler: handleSelectedEvent,
  } = useSelectedList({
    eventMap: {
      changeAll: customTableEvents.CHANGE_SELECT_ALL,
      add: customTableEvents.SELECT_ELEMENT,
      remove: customTableEvents.UNSELECT_ELEMENT,
    },
  });

  // get files
  const {
    data: filesResponse,
    error: filesError,
    isLoading: filesLoading,
    refetch: refetchFiles,
  } = useCustomQuery(
    [CompanyAPI.keys.getCompanyAttachments, { filters, company_id: companyId }],
    ({ queryKey }) => CompanyAPI.handlers.getCompanyAttachments(queryKey[1])
  );

  //upload file
  const {
    uploadedFiles,
    uploaderRef,
    error: uploadError,
    onChange,
    handleDrop,
    removeLoadingFiles,
  } = useFilesUploader({
    directoryPath: `${company_public_id}/profile`,
    bucketName: process.env.REACT_APP_AWS_ATTACHMENTS_BUCKET,
    files: filesResponse?.files,
    handler: handleFileUpload,
    invalidators: CompanyAPI.invalidators.uploadCompanyAttachment,
    extraConfig: {},
  });

  // Component variables
  const canModifyCompany = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.MEMBERS.GENERAL)
  const disabled = !canModifyCompany;

  //  Watching upload error
  useEffect(() => {
    if (!filesResponse) { return; }
    setElements(filesResponse?.rows);
  }, [filesResponse]);

  //  Watching upload error
  useEffect(() => {
    if (!uploadError) { return; }
    const errorMessage = uploadError?.response?.data?.errorMessage || 'Error uploading files';
    removeLoadingFiles();
    queryClient.invalidateQueries(CompanyAPI.invalidators.uploadCompanyAttachment);
    dispatch(setAlert(errorMessage, alertTypes.error));
  }, [uploadError]);

  //  Function to handle input click
  const onInputClick = () => {
    uploaderRef?.current?.click();
  };

  //  Function to handle drop
  const onDrop = (event) => {
    if(disabled) return ;
    handleDrop(event);
    clearDraggedState();
  };

  //  Function to request more elements
  const requestMore = ({ selected }) => {
    const newOffset = selected * filters?.limit;
    setFilters({ ...filters, offset: newOffset });
  };

  //  Function to change filters
  const changeFilters = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  //  Function to change sort
  const changeSort = (key, value) => {
    setFilters({ ...filters, sort: { [key]: value } });
  };

  // Function for setting attachments container classname
  const getContainerClassName = () => {
    const disabledClassName = disabled ? 'pe-none opacity-50' : '';
    return `company-attachments-container ${disabledClassName}`
  }

  // Render section to upload files
  const renderAttachmentFileUploader = () => {
    return (
      <div className="file-uploader">
        <AttachmentDragBrowseArea
          isDragOver={isDragOver}
          onFileDrop={onDrop}
          onInputClick={onInputClick}
          disabled={disabled}
        />
        <input
          className="company-attachment-input"
          type="file"
          ref={uploaderRef}
          onChange={onChange}
          accept={grcAttachmentsExtensions?.join(',')}
          multiple={true}
        />
        <AttachmentOverlay
          isDragOver={isDragOver}
          onFileDrop={onDrop}
          disabled={disabled}
        />
      </div>
    )
  }
  //  Function to render attachments
  const renderAttachments = () => {
    const safeFiles = getSafeArray(uploadedFiles);
    return safeFiles.map((file, index) => {
      return (
        <Col
          className="pb-3"
          lg={6}
        >
          <CompanyAttachmentPreview
            company_public_id={company_public_id}
            key={index}
            file={file}
          />
        </Col>

      );
    })
  }

  const renderEmptyStateContainer = (children) => {
    return (
      <tr className='custom-table-empty-container'>
        <td>
          {children}
        </td>
      </tr>
    );
  };

  //  Function to render top section
  const renderTableTopBar = () => {
    return (
      <div className="d-flex">
        <div className="searchable-table-topbar">
          <SearchBar
            useSimpleContainer
            customClassName={`search-box attachments-search-bar`}
            placeholder="Search an attachment"
            value={filters?.text_to_search}
            callback={(value) => changeFilters("text_to_search", value)}
          />
        </div>
      </div>
    );
  };

  const renderAttachmentsBulkActionMenu = () => {
    const selectedAttachments = allSelected ? elements.map((element) => element.id) : selected;
    selectedAttachments.filter((element) => !excluded?.includes(element));
    return (
      <CompanyAttachmentsBulkActions
        selected={selectedAttachments}
      />
    );
  };

  //  Function to render attachments
  const renderAttachmentsTable = () => {
    const safeFiles = getSafeArray(filesResponse?.rows).map(value => ({...value, companyPublicId: company_public_id}));
    return (
      <>
        <CustomTable
          tableClassName="attachments-table"
          headerClassName="attachments-table-header"
          rowClassName="attachments-table-row"
          columnsTemplate={attachmentsTableTemplate}
          data={safeFiles || []}
          isScrollable={true}
          requestMore={requestMore}
          changeSort={changeSort}
          sort={mapObjSortToArray(filters?.sort) || []}
          allSelected={allSelected}
          selected={selected}
          excluded={excluded}
          handleSelectedEvent={handleSelectedEvent}
          loading={filesLoading}
          emptyConfig={{
            message: `Attachments will appear here`,
            messageClassName: 'empty-message',
            emptyStateContainer: (children) => renderEmptyStateContainer(children)
          }}
          paginationType={tablePaginationTypes.PAGES}
        />
      </>
    );
  }

  return (
      <Col
        className='company-attachments-section align-self-lg-stretch mb-3'
        lg={lg}
      >
        <IconCard
          title={`Attachments (${filesResponse?.rows?.length || 0})`}
          titleClassName="attachments-title"
          iconClassName="bx bx-folder-open rotate-attachment-icon text-white"
          iconWrapperClassName="bg-active-purple"
          cardWrapperClassName={"company-attachments-card h-100 user-profile"}
        >
          <div className="topbar-container">{renderTableTopBar()}</div>
          <LoadingContent
            isLoading={filesLoading}
            errorMessage={getApiErrorMessage(filesError)}
            errorButtonCallback={refetchFiles}
          >
            <div className={getContainerClassName()}>
              {renderAttachmentFileUploader()}
              {renderAttachmentsBulkActionMenu()}
              {renderAttachmentsTable()}
            </div>
          </LoadingContent>
        </IconCard>
      </Col>
  )
}

export default CompanyAttachments
