import React, { useEffect } from 'react';
import AddRemoveTagsModal from '../../tags/AddRemoveTagsModal';
import API from '../../../api';
import { setAlert } from '../../../actions/alerts';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import useCustomMutation from '../../../hooks/useCustomMutation';

const GroupTagsModal = (props) => {
  //  Parsing props
  const {
    groups = [],
    closeModal = () => { },
    countLabel = '',
  } = props;

  //  Initializing API
  const { GroupAPI } = API;

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();

  //  Hook for calling tag add
  const {
    data: addResponse,
    error: addError,
    isLoading: addLoading,
    mutate: addTags,
  } = useCustomMutation(
    (params) => GroupAPI.handlers.addTagsToGroups(params),
    GroupAPI.invalidators.addTagsToGroups,
  );

  //  Hook for calling tag remove
  const {
    data: removeResponse,
    error: removeError,
    isLoading: removeLoading,
    mutate: removeTags,
  } = useCustomMutation(
    (params) => GroupAPI.handlers.removeTagsFromGroups(params),
    GroupAPI.invalidators.removeTagsFromGroups,
  );

  //  Watching add response
  useEffect(() => {
    if (!addResponse) { return; }
    dispatch(setAlert('Tags added', 'success'));
    closeModal();
  }, [addResponse]);

  //  Watching remove response
  useEffect(() => {
    if (!removeResponse) { return; }
    dispatch(setAlert('Tags removed', 'success'));
    closeModal();
  }, [removeResponse]);

  //  Watching add error
  useEffect(() => {
    if (!addError) { return; }
    dispatch(setAlert('Error adding tags', 'danger'));
  }, [addError]);

  //  Watching remove error
  useEffect(() => {
    if (!removeError) { return; }
    dispatch(setAlert('Error removing tags', 'danger'));
  }, [removeError]);

  //  Function to handle tags add
  const onTagsAdd = (tags = []) => {
    const titles = tags.map(tag => tag?.label);
    const params = { group_ids: groups, titles };
    addTags(params);
  };

  //  Function to handle tags removal
  const onTagsRemove = (tags) => {
    const tag_ids = tags.map(tag => tag?.value);
    const params = { group_ids: groups, tag_ids };
    removeTags(params);
  }

  //  Rendering
  return (
    <AddRemoveTagsModal
      title={`Bulk Tag (${countLabel})`}
      showModal={true}
      toggleModal={closeModal}
      addTagsCallback={onTagsAdd}
      removeTagsCallback={onTagsRemove}
      processing={removeLoading || addLoading}
      isDisabled={removeLoading || addLoading}
    />
  );
};

export default GroupTagsModal;
