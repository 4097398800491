import {
	SET_START_DATE,
	SET_END_DATE,
	SET_TIMEZONE,
	SET_SOLUTION_ID,
	SET_DATE_INFO,
	SET_RANGE_VALUE,
} from './types';

export const setStartDate = (startDate) => ({
	type: SET_START_DATE,
	payload: startDate
});

export const setEndDate = (endDate) => ({
	type: SET_END_DATE,
	payload: endDate
});

export const setTimezone = (timezone) => ({
	type: SET_TIMEZONE,
	payload: timezone
});

export const setSolutionId = (solutionId) => ({
	type: SET_SOLUTION_ID,
	payload: solutionId
});

export const setDateInfo = (start_date, end_date, timezone) => {
	return {
		type: SET_DATE_INFO,
		payload: { start_date, end_date, timezone },
	}
};

export const setRangeValue = (value) => {
	return {
		type: SET_RANGE_VALUE,
		payload: value,
	}
}
