import React from 'react';
import { connect } from 'react-redux';
import Dashboard from '../components/dashboard/Dashboard';
import CydekickList from '../components/team-manager/CydekickList';

const TeamManager = () => {
    return (
        <Dashboard
            title="Cydekicks"
            containerClassName="dashboard mx-2 px-0 py-4 text-normal-style page-content contained-view-width"
        >
            <CydekickList />
        </Dashboard>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TeamManager);
