import { infiniteTableLimit } from './common';

//  Initial filters
export const initialFilters = {
  company_id: [],
  partner_id: [],
  solution_id: [],
  status: [],
  severity: [],
  exploitable: null,
  vpr_score_floor: null,
  vpr_score_ceil: null,
  classification: null,
}

//  Initial params
export const initialParams = {
  offset: 0,
  sort: {},
  text_to_search: '',
  limit: infiniteTableLimit,
  filters: initialFilters,
  text_to_search: '',
};

//  Input types for partner filters (values should match the value son backend)
export const inputTypes = {
  DROPDOWN: 'DROPDOWN',
  NUMERIC: 'NUMERIC',
};

export const groupDetailsQueryKey = 'selectedGroup';

export const groupTabIds = {
  GROUP_GENERAL_TAB: "GROUP_GENERAL_TAB",
  GROUP_MEMBERS_TAB: "GROUP_MEMBERS_TAB",
  GROUP_DEVICES_TAB: "GROUP_DEVICES_TAB",
  GROUP_SUBTASKS_TAB: "GROUP_SUBTASKS_TAB",
};