import React from 'react';
import { Button } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { setAlert } from '../../../actions/alerts';
import { alertTypes } from '../../../constants/alerts';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import amplitude from 'amplitude-js';

const CopyEmbedCode = (props) => {
    const {
        code,
        className,
        showCode = true
    } = props;

    //Component Hooks
    const { dispatch } = useMemoizedDispatch();

    //  Getting router location
    const location = useLocation();

    const onCopyCodeClick = () => {
        navigator.clipboard.writeText(code);
        if (location?.pathname === '/profile/company'){
            amplitude.getInstance().logEvent('My Account View - Company Profile - Copy Code');
        }
        dispatch(setAlert("Code copied", alertTypes.success))
    }

    const renderCopyCodeButton = () => {
        return (
            <Button
                color="light"
                className="btn-copy-code"
                onClick={onCopyCodeClick}
            >
                <i className='bx bx-copy'></i>
                Copy embed code
            </Button>
        )
    }
    return (
        <div className={`copy-embed-code ${className || ''}`}>
            {showCode ? <div className="embed-code-container mb-2">{code}</div> : null}
            {renderCopyCodeButton()}
        </div>
    )
}

export default CopyEmbedCode;
