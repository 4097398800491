import { createSelector } from 'reselect';

const getCategoryId = state => state.assessment.selectedCategoryId;

const makeCategoryColor = () => {
  return createSelector(
    [
      getCategoryId
    ],
    (categoryId) => {
        switch(categoryId){
            case 1: 
                return 'secondary';
            case 2:
                return 'info';
            case 3:
                return 'tertiary';
            default:
                return 'primary'
        }
    }
  )}
export default makeCategoryColor;
    
