import React from 'react';

const Arrow = (props) => {
    const {
        isIncreasing,
        className
    } = props;
    return (
        <div className={`d-inline-block ${className}`}>
            {isIncreasing ? <i className='bx bx-up-arrow-alt text-success'/>:<i className='bx bx-down-arrow-alt text-critical'/>}
        </div>
    )
}

export default Arrow;