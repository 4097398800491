import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Card, CardBody, Button } from 'reactstrap';
import FreemiumInformationWrapper from '../containers/FreemiumInformationWrapper';

const VerificationSuccessMessage = (props) => {
  //  Component hooks
  const location = useLocation();
  const history = useHistory();

  //  Watching token changes (just render view if there is a token linked)
  useEffect(() => {
    if (location?.state?.token) { return; }
    history.push('/');
  }, [location?.state?.token])

  //  Function to handle click
  const onLoginClicked = () => {
    history.replace('/login');
  };

  //  Function to get message
  const getMessage = () => {
    if (location?.state?.is_owner) {
      return 'Your account is now verified';
    }
    return 'Account is now verified';
  }

  //  Render login button
  const renderLoginButton = () => {
    if (!location?.state?.is_owner) { return null; }
    return (
      <Button
        className="resend-button"
        onClick={() => onLoginClicked()}
      >
        <div className="resend-label">
          Login
        </div>
      </Button>
    );
  }

  //  Rendering
  return (
    <FreemiumInformationWrapper logoOnly>
      <Card
        color='white'
        className='success-message-container'
      >
        <CardBody className='success-message-wrapper'>
          <div className="message-title">Success!</div>
          <div className="message-content">{getMessage()}</div>
          {renderLoginButton()}
        </CardBody>
      </Card>
    </FreemiumInformationWrapper>
  );
};

export default VerificationSuccessMessage;
