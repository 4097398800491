import React from 'react'

const TableSampleDataReminder = (props) => {
    const {
        message,
        lastScan,
        className
    } = props;
    return !lastScan ? (
        <div className={`table-demo-reminder ${className || ''}`}>
            {message}
        </div>
    ): null
}

export default TableSampleDataReminder
