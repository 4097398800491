import _ from 'lodash';
import {
    CREATE_ISSUE_SUCCESS,
    ISSUE_TOTALS_SUCCESS,
    ON_IMPERSONATION_SUCCESS,
    SET_MEMBER_VIEW,
    ON_RELOAD_ISSUES_ROLLUP,
    LOGOUT_SUCCESS,
    ON_DELETE_MODAL_CONFIG_UPDATE,
    ON_DELETE_ISSUES_SUCCESS,
    SET_ISSUES_FILTER,
    SET_ISSUES_FILTER_GROUP,
    SET_ISSUES_SORT,
    APPLY_ADVANCED_FILTERS,
    CLEAR_ALL_ISSUES_FILTERS,
    ISSUE_MODAL_OPTIONS_SUCCESS,
    RELOAD_ISSUES,
    ON_SHOW_GRC_DETAILS,
    UPDATE_INDEX_DETAILS_MODAL,
    TASKS_UPDATE_CURRENT_PAGE
} from '../actions/types';
import filterTypes from '../constants/filterTypes';
import { issueTypes } from '../constants/issues';
import { getIssuesFilterGroupState, getIssuesFiltersState } from '../utils/filterHelpers';
import { getIssuesConfig, getIssueTotalsPayload } from '../utils/issuesHelpers';

const filtersInitialState = {
    severity: null,
    status: null,
    company_id: null,
    assigned_to: null,
    tags: [],
    time_since_creation: null,
    open_only: false,
    priority: null,
    due_date: null,
    overdue: null,
};
const sortFieldInitialState = { fieldName: null, asc: true };
const initialState = {
    filters: filtersInitialState,
    activeFilters: [],
    searchTerm: '',
    sortField: sortFieldInitialState,
    pageCount: 1,
    currentPage: 0,
    modalOptions: {
        companies: [],
        users: { cydekicks: [] },
        solutions: [],
        statuses: [],
        criticalities: [],
    },
    total: 0,
    totalsBy: {
        criticality: [],
        status: []
    },
    shouldReload: false,
    issueId: null,
    shouldReloadRollup: false,
    deleteModalConfig: {
        show: false,
        issue_ids: [],
        success: false,
    },
    showDetailsModal: false,
    indexDetailsModal: null,
    isNewPage: false
}

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_ISSUES_FILTER: {
            if(payload?.issueType !== issueTypes.GRC) return state;
            const  filtersState = state.filters;
            const activeFiltersState = state.activeFilters;
            const { filters, activeFilters, searchTerm } = getIssuesFiltersState(filtersState, activeFiltersState, payload);
            const isSearchFilter = (payload?.type === filterTypes.search);
            return {
                ...state,
                filters,
                activeFilters: [...activeFilters],
                searchTerm: isSearchFilter ? searchTerm : state?.searchTerm
            }
        }
        case SET_ISSUES_FILTER_GROUP : {
            if(payload?.issueType !== issueTypes.GRC) return state;
            const  filtersState = state.filters;
            const activeFiltersState = state.activeFilters;
            const { filters, activeFilters } = getIssuesFilterGroupState(
                filtersState,
                activeFiltersState,
                payload,
                filtersInitialState
            );
            return {
                ...state,
                filters,
                activeFilters
            }
        }

        case SET_ISSUES_SORT: {
            if(payload?.issueType !== issueTypes.GRC) return state;
            return {
                ...state,
                sortField: payload?.value
            };
        }

        case APPLY_ADVANCED_FILTERS: {
            if(payload?.issueType !== issueTypes.GRC) return state;
            return {
                ...state,
                activeFilters: payload.activeFilters,
                filters: payload.filters
            }
        }

        case CLEAR_ALL_ISSUES_FILTERS: {
            if(payload?.isMemberView) {
                // Member view and not a GRC Issue
                if(payload?.issueType !== issueTypes.GRC) return state;

                // Member view and a GRC Issue
                return {
                    ...state,
                    filters: {
                        ...filtersInitialState,
                        solution_id: state?.filters?.solution_id,
                        company_id: state?.filters?.company_id
                    },
                    activeFilters: [],
                    searchTerm: ''
                }
            }

            // Normal view and not a GRC Issue
            if(payload?.issueType !== issueTypes.GRC) {
                return {
                    ...state,
                    filters: {
                        ...state.filters,
                        company_id: null
                    },
                };
            };

            // Normal view and a GRC Issue
            return {
                ...state,
                filters: {
                    ...filtersInitialState,
                    solution_id: state?.filters?.solution_id,
                },
                activeFilters: [],
                searchTerm: ''
            };
        }

        case CREATE_ISSUE_SUCCESS: {
            if(payload?.issueType !== issueTypes.GRC) return state;
            const companyFilterName = 'company_id';
            const activeFilters = state.activeFilters || [];
            const companyActiveFilter = activeFilters.find((activeFilter) => activeFilter.filterName === companyFilterName)
            return {
                ...state,
                shouldReload: true,
                shouldReloadRollup: true,
                filters: {
                    ...filtersInitialState,
                    company_id: state?.filters?.company_id,
                    solution_id: state?.filters?.solution_id
                },
                activeFilters: !payload?.isMemberView && companyActiveFilter ? [companyActiveFilter] : [],
                searchTerm: '',
                sortField: sortFieldInitialState
            }
        }

        case ISSUE_MODAL_OPTIONS_SUCCESS: {
            if(payload.issueType !== issueTypes.GRC) return state;
            const { modalOptions } = getIssuesConfig(payload?.data, payload?.userType);
            return {
                ...state,
                modalOptions
            }
        }

        case ISSUE_TOTALS_SUCCESS: {
            if(payload?.issueType !== issueTypes.GRC) return state;
            const { totalsBy } = getIssueTotalsPayload(state, payload?.data, payload?.userType)
            return {
                ...state,
                totalsBy
            }
        }

        case ON_IMPERSONATION_SUCCESS: {
            const filterName = 'company_id';
            const value = payload?.impersonatedCompany?.id;
            const label = payload?.impersonatedCompany?.name;
            const type = filterTypes.single;
            const cleanActiveState = _.pullAllBy(state.activeFilters, [{ filterName }], 'filterName');
            const activeStateWithoutDeviceTypes = cleanActiveState.filter((active) => active.filterName !== 'device_types');
            return {
                ...state,
                activeFilters: _.isNil(value) ? (
                    [...activeStateWithoutDeviceTypes]
                ) : [...activeStateWithoutDeviceTypes, { label, type, filterName, value: null }],
                filters: {
                    ...state.filters,
                    device_types: [], //    Clearing device types when changing customer
                    [filterName]: value,
                }
            }
        }

        case SET_MEMBER_VIEW: {
            const { enabled, companyName, companyId } = payload;
            const filterName = 'company_id';
            const type = filterTypes.single;
            const cleanActiveState = _.pullAllBy(state.activeFilters, [{ filterName }], 'filterName');
            return {
                ...state,
                activeFilters: (enabled || _.isNil(companyId)) ? (
                    [...cleanActiveState]
                ) : (
                    [...cleanActiveState, { label: companyName, type, filterName, value: null }]
                )
            }
        }

        case LOGOUT_SUCCESS: {
            return initialState;
        }

        case RELOAD_ISSUES: {
            if(payload?.issueType !== issueTypes.GRC) return state
            return {
                ...state,
                shouldReload: payload?.shouldReload
            }
        }

        case ON_RELOAD_ISSUES_ROLLUP: {
            if(issueTypes.GRC !== payload?.issueType) return state
            return {
                ...state,
                shouldReloadRollup: payload?.shouldReload,
            };
        }

        case ON_DELETE_MODAL_CONFIG_UPDATE: {
            if (payload?.issueType !== issueTypes.GRC && payload?.issueType !== issueTypes.VULNERABILITIES) return state;
            return {
                ...state,
                deleteModalConfig: {
                    show: payload?.show,
                    issue_ids: payload?.issue_ids,
                    success: payload?.success,
                },
            };
        }

        case ON_DELETE_ISSUES_SUCCESS: {
            if (payload?.issueType !== issueTypes.GRC && payload?.issueType !== issueTypes.VULNERABILITIES) return state;
            return {
                ...state,
                deleteModalConfig: {
                    show: false,
                    issue_ids: [],
                    success: true,
                }
            }
        }

        case ON_SHOW_GRC_DETAILS: {
            return {
                ...state,
                showDetailsModal: payload,
                indexDetailsModal: null,
                isNewPage: false
            };
        }
        case UPDATE_INDEX_DETAILS_MODAL: {
            return {
                ...state,
                indexDetailsModal: payload.index,
                isNewPage: payload.isNewPage
            };
        }
        case TASKS_UPDATE_CURRENT_PAGE: {
            return {
              ...state,
              currentPage: payload
            };
        }
        default:
            return state;
    }
}
