import filterTypes from "../constants/filterTypes";
import {
    CLEAR_ISSUES,
    SET_ISSUES_FILTER,
    SET_ISSUES_SORT,
    CLEAR_ALL_ISSUES_FILTERS,
    ISSUE_TOTALS_SUCCESS,
    COMPANY_DEMO_UPDATE,
    ISSUES_DEMO_SUCCESS,
    ISSUES_DEMO_FAILURE,
    ISSUES_FREE_SCAN_FAILURE,
    ISSUES_FREE_SCAN_SUCCESS,
    COMPANY_SCAN_UPDATE,
    ISSUE_SUCCESS,
    ISSUE_FAILURE,
    ISSUE_MODAL_OPTIONS_SUCCESS,
    ISSUE_MODAL_OPTIONS_FAILURE,
    ON_DELETE_MODAL_CONFIG_UPDATE,
    ON_DELETE_ISSUES_SUCCESS,
    SET_ISSUES_FILTER_GROUP,
    ON_RELOAD_ISSUES_ROLLUP,
    RELOAD_ISSUES,
    APPLY_ADVANCED_FILTERS,
    ON_SHOW_GRC_DETAILS,
    ISSUE_REQUEST,
    UPDATE_INDEX_DETAILS_MODAL,
    ISSUES_UPDATE_CURRENT_PAGE,
    TASKS_UPDATE_CURRENT_PAGE
} from "./types";
import { successActionCreator } from './action-creators/success'
import { errorActionCreator } from './action-creators/errors';
import { invalidateQueriesByKeys } from '../utils/queryHelpers';
import { issueTypes } from "../constants/issues";
import { isIssueVulType } from "../utils/issuesHelpers";

export const getIssue = (id) => {
    return async (dispatch, getState, { API, queryClient }) => {
        try {
            const { IssueAPI } = API;
            dispatch(successActionCreator(ISSUE_REQUEST));
            const response = await IssueAPI.handlers.getIssue(id);
            dispatch(successActionCreator(ISSUE_SUCCESS, response?.data));
        } catch (error) {
            const message = 'Error getting issue.';
            dispatch(errorActionCreator(ISSUE_FAILURE, { message }))
        }
    }
}

export const clearIssues = () => {
    return {
        type: CLEAR_ISSUES
    }
}

export const getIssueModalOptions = (id, issueType = issueTypes.VULNERABILITIES, userType) => {
    return async (dispatch, getState, { API, queryClient }) => {
        try {
            const { IssueAPI, GrcIssueAPI } = API;
            const response = isIssueVulType(issueType) ? await IssueAPI.handlers.getIssueModalOptions(id) : await GrcIssueAPI.handlers.getGrcIssuesConfig();
            dispatch(onIssuesConfigSuccess(response?.data, issueType, userType));
        } catch (error) {
            dispatch(errorActionCreator(ISSUE_MODAL_OPTIONS_FAILURE, { message: error?.response?.data?.errorMessage }))
        }
    }
}

export const loadIssueDemo = () => {
    return async (dispatch, getState, { API, queryClient }) => {
        try {
            const { CompanyAPI } = API;
            const response = await CompanyAPI.handlers.loadIssueDemo();
            invalidateQueriesByKeys(CompanyAPI.invalidators.loadIssueDemo, queryClient);
            dispatch(successActionCreator(COMPANY_DEMO_UPDATE, response?.data));
            dispatch(successActionCreator(ISSUES_DEMO_SUCCESS, true));
        } catch (error) {
            const message = 'Something went wrong';
            dispatch(errorActionCreator(ISSUES_DEMO_FAILURE, { message }));
        }
    };
}

export const setIssuesFilter = (filterName, value, label, type = filterTypes.single, filterOption, hideActiveFilter, issueType = issueTypes.VULNERABILITIES) => {
    return {
        type: SET_ISSUES_FILTER,
        payload: { filterName, value, label, type, filterOption, hideActiveFilter, issueType }
    }
}

export const setIssuesMultipleFilterGroup = (filterName, values, activeValues, reset = true, issueType = issueTypes.VULNERABILITIES) => {
    return {
        type: SET_ISSUES_FILTER_GROUP,
        payload: { filterName, values, activeValues, reset, issueType }
    }
}

export const clearIssuesFilters = (isMemberView = false, issueType = issueTypes.VULNERABILITIES) => {
    return {
        type: CLEAR_ALL_ISSUES_FILTERS,
        payload: {
            isMemberView,
            issueType
        }
    }
}

export const setIssuesSort = (value, issueType = issueTypes.VULNERABILITIES) => {
    return {
        type: SET_ISSUES_SORT,
        payload: {
            value,
            issueType
        }
    }
}

export const requestFreeScan = () => {
    return async (dispatch, getState, { API, queryClient }) => {
        try {
            const { CompanyAPI } = API;
            const response = await CompanyAPI.handlers.requestFreeScan();
            invalidateQueriesByKeys(CompanyAPI.invalidators.requestFreeScan, queryClient);
            dispatch(successActionCreator(COMPANY_SCAN_UPDATE, response?.data));
            dispatch(successActionCreator(ISSUES_FREE_SCAN_SUCCESS, true));
        } catch (error) {
            const message = 'Error requesting free scan.';
            dispatch(errorActionCreator(ISSUES_FREE_SCAN_FAILURE, { message }));
        }
    }
}

export const onIssueTotalsSuccess = (data, issueType = issueTypes.VULNERABILITIES, userType) => {
    return {
        type: ISSUE_TOTALS_SUCCESS,
        payload: {
            data,
            issueType,
            userType,
        }
    };
}

export const onDeleteModalConfigUpdate = (show = false, issue_ids = [], success = false, issueType = issueTypes.VULNERABILITIES) => {
    return {
        type: ON_DELETE_MODAL_CONFIG_UPDATE,
        payload: { show, issue_ids, success, issueType },
    };
};

export const onDeleteIssuesSuccess = (issueType = issueTypes.VULNERABILITIES) => {
    return {
        type: ON_DELETE_ISSUES_SUCCESS,
        payload: { issueType }
    }
};

export const onReloadIssuesRollup = (shouldReload, issueType = issueTypes.VULNERABILITIES) => {
    return {
        type: ON_RELOAD_ISSUES_ROLLUP,
        payload: {
            shouldReload,
            issueType
        }
    }
}

export const onIssuesConfigSuccess = (data, issueType = issueTypes.VULNERABILITIES, userType) => {
    return {
        type: ISSUE_MODAL_OPTIONS_SUCCESS,
        payload: {
            data,
            issueType,
            userType
        }
    }
}

export const onReloadIssues = (shouldReload, issueType = issueTypes.VULNERABILITIES) => {
    return {
        type: RELOAD_ISSUES,
        payload: {
            shouldReload,
            issueType
        }
    }
}

export const applyIssuesAdvancedFilters = (filters = {}, activeFilters = {}, issueType = issueTypes.VULNERABILITIES) => {
    return {
        type: APPLY_ADVANCED_FILTERS,
        payload: {
            filters,
            activeFilters,
            issueType
        }
    }
}

export const onShowGRCDetails = (show = false) => {
    return {
        type: ON_SHOW_GRC_DETAILS,
        payload: show,
    }
};


export const onUpdateIndexDetailsModal = (index, isNewPage = false) => {
    return {
        type: UPDATE_INDEX_DETAILS_MODAL,
        payload: {
            index,
            isNewPage
        },
    }
};

export const onUpdateCurrentPage = (page, issueType = issueTypes.VULNERABILITIES) => {
    return {
        type: isIssueVulType(issueType) ? ISSUES_UPDATE_CURRENT_PAGE : TASKS_UPDATE_CURRENT_PAGE,
        payload: page,
    }
};
