import React from 'react'

const HookFormField = (props) => {
  const {
    name,
    label,
    ariaLabel,
    className = '',
    wrapperClassName = '',
    labelCustomClassName,
    type,
    style,
    ariaRequired,
    register,
    isInvalid,
    errorMsgClassName,
    errorIconClassName,
    requirements,
    errorMsg
  } = props;

  return (
    <div className={wrapperClassName}>
      {label ? <label htmlFor={name} className={labelCustomClassName}>{label}</label> : null}
      <div className="hook-input-container">
        <input name={name} aria-label={ariaLabel} aria-required={ariaRequired} id={name} type={type} className={`${className} ${isInvalid ? 'is-invalid':''}`} style={style}
          {...register(name, requirements)}>
        </input>
        <i className={errorIconClassName}></i>
      </div>
      <div className={errorMsgClassName}>{errorMsg}
      </div>
    </div >
  )
}

export default HookFormField