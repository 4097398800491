import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import InfoTooltip from '../dashboard/tooltips/InfoTooltip';
import BasicDoughnutChartRisk from './customDonugthChart';
//import NowThenButton from '../../components/ReportCard/NowThenButton';
import { useSelector } from 'react-redux';
import companyIdSelector from '../../selectors/companyIdSelector';
import { riskStatementStatusData } from '../../actions/businessRisk';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import { SET_SHOULD_RELOAD_BUSINESS_RISK } from '../../actions/types';
import { successActionCreator } from '../../actions/action-creators/success'
import makeErrorMessage from '../../selectors/errorsSelector';
import makeLoadingState from '../../selectors/loadingSelector';
import LoadingContent from '../layout/LoadingContent';
import { riskCriticalityConfigColor } from '../../constants/criticality';
import companyInfoSelector from '../../selectors/companyInfoSelector';

const RiskStatementStatus = (props) => {

    const {
        cardColor = "violet"
    } = props;

    //Component state
    const [showNowState, setShowNowState] = useState(true);

    //Selectors
    const getErrorMessage = makeErrorMessage(['RISK_STATEMENT_STATUS']);
    const getLoadingState = makeLoadingState(['RISK_STATEMENT_STATUS']);

    //  Getting needed info from redux store
    const company_id = useSelector((state) => companyIdSelector(state));
    const companyCode = useSelector(state => companyInfoSelector(state, 'code'));
    //const cysoSubscribed = useSelector((state) => state?.businessRisk.riskStatusData?.cyso_subscribed);
    const cysoSubscribed = true;
    const riskStatusData = useSelector((state) => state.businessRisk.riskStatusData);
    const shouldReload = useSelector((state) => state.businessRisk.shouldReload);
    const errorMessage = useSelector((state) => getErrorMessage(state));
    const isLoading = useSelector((state) => getLoadingState(state));

    //  Component's Hooks
    const { dispatch } = useMemoizedDispatch();

    const fetchRiskStatement = () => {
        dispatch(riskStatementStatusData(company_id))
    }

    useEffect(() => {
        fetchRiskStatement();
    }, [company_id]);

    useEffect(() => {
        if(shouldReload) {
            fetchRiskStatement();
            dispatch(successActionCreator(SET_SHOULD_RELOAD_BUSINESS_RISK, false));
        }
    },[shouldReload])


    const renderDonut = () => {
        const data = showNowState ? riskStatusData?.current : riskStatusData?.previous;

        return (
            <BasicDoughnutChartRisk
                key={showNowState ? 'current-risk' : 'previous-risk'}
                data={data?.residual_risk_scores}
                hasVcyso={cysoSubscribed}
                colorsConfig={riskCriticalityConfigColor}
                total={data?.total}
                completed={riskStatusData?.completed}
                replaceOnTooltip={new RegExp(`${companyCode}\-|S`, 'g')}
            />
        )
    }

    const renderTitleTooltip = () => {
        return (
            <>
                <div className="title-busines-risk d-flex w-100 justify-content-between">
                    <InfoTooltip
                        label="Risk Statement Status"
                        wrapperClassName="dashboard-widget-title"
                        id="risk-statement-status-tooltip"
                        showTooltip={false}
                    />

                    {/* <div className="bussines-risk-container">
                        {cysoSubscribed && riskStatusData?.previous && (
                            <NowThenButton
                                showNowState={showNowState}
                                callback={() => {
                                    setShowNowState(!showNowState)
                                }}
                            />
                        )}
                    </div> */}
                </div>

            </>
        )
    };

    return (
        <div className="risk-statement-status-container align-self-start align-self-lg-stretch mb-3 mb-lg-0">
            <Card className="h-100" color={cardColor}>
                <CardBody className="risk-statement-status d-flex flex-column">
                    {renderTitleTooltip()}
                    <LoadingContent
                        errorMessage={errorMessage}
                        isLoading={isLoading}
                        iconType="solidIcon"
                        errorStyle={{ minHeight: "225px" }}
                        loadingStyle={{ minHeight: "225px" }}
                        errorButtonCallback={fetchRiskStatement}
                    >
                        <div className="risk-doughnut-wrapper d-flex w-100 w-lg-auto align-items-center justify-content-center justify-content-lg-start my-auto ml-0 mr-3">
                            {renderDonut()}
                        </div>
                    </LoadingContent>
                </CardBody>
            </Card>
        </div>
    )
}

export default RiskStatementStatus;
