import React, { useEffect } from 'react';
import { Row} from 'reactstrap';
import { trackPage } from '../../../utils/gaTracker';
import TableSampleDataReminder from './TableSampleDataReminder';

const TableDemoPreview = (props) => {
    const {
        className,
        image,
        imageAltText,
        trackPageName,
    } = props;

    useEffect(() => {
        if (trackPageName) {
            trackPage(trackPageName);
        }
    }, []);

    return (
        <Row className={`position-relative ${className || ''}`}>
            <div className="overlay" />
            <div>
                <TableSampleDataReminder
                    message="This is sample data, upgrade your plan to have your device list uploaded and monitored on Cyvatar."
                />
                <img
                    className="table-demo-image img-fluid"
                    src={image}
                    alt={imageAltText || 'table preview'}
                />
            </div>
        </Row>
    )
}
export default TableDemoPreview;
