import React from 'react'
import { Redirect } from 'react-router-dom'
import { defaultIndexPath } from '../constants/navigation';

const IndexRoute = () => {
    return (
        <Redirect to={defaultIndexPath}/>
    )
}

export default IndexRoute;
