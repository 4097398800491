export const ruleSetsStatus = {
  DRAFT: 0,
  ACTIVE: 1,
  INACTIVE: 2,
};

export const automationActionsTypes = {
  MODIFY: 'MODIFY',
  DELETE: 'DELETE',
}

export const automationActions = [
  {
    tooltip: 'Modify rule set',
    className: 'automation-action bx bx-wrench',
    actionType: automationActionsTypes.MODIFY
  },
  {
    tooltip: 'Delete rule set',
    className: 'automation-action bx bx-x',
    actionType: automationActionsTypes.DELETE
  }
];

export const automationRulesLimit = 10;

export const automationPages = {
  AUTOMATION: 'AUTOMATION',
  RULE_LOG: 'RULE_LOG',
  LOG_DETAIL: 'LOG_DETAIL',
  RULE_SET_DETAIL: 'RULE_SET_DETAIL',
};

export const ruleLogStatus = {
  ERROR: 0,
  SUCCESS: 1,
  PROGRESS: 2,
};

export const newRuleSetPayload = {
  name: '',
  all_members: false,
  is_published: false,
  companies: [],
  rules: [],
  tags: [],
  tags_searchable: [],
};

export const emptyActionToCreate = {
  id: null,
  name: '',
  schedule: null,
  repeat: null,
  run_now: false,
  action_id: null,
  status: ruleSetsStatus.DRAFT,
};

export const emptyRuleToCreate = {
  partner: null,
  actions: [emptyActionToCreate],
};

export const scheduleTabs = [
  { id: 0, label: 'Schedule' }
];

export const scheduleComponentTypes = {
  CALENDAR: 1,
  TIME: 2,
  TIMEZONE: 3,
};

export const repeatComponentTypes = {
  REPEAT: 1,
  INTERVAL: 2,
  UNTIL: 3,
};

export const frequencyOptions = [
  { value: 0, label: 'Yearly' },
  { value: 1, label: 'Monthly' },
  { value: 2, label: 'Weekly' },
  { value: 3, label: 'Daily' },
];
