import React, { useState } from 'react';
import useCustomMutation from '../../../../hooks/useCustomMutation';
import API from '../../../../api';
import useCanAbility from '../../../../hooks/useCanAbility';
import {
  MODIFY_ACTION,
  PRIVILEGE_SOURCES,
} from '../../../../constants/privileges';
import CallbackSelectField from '../../../layout/fields/SelectFieldCallback';
import { useSelector } from 'react-redux';
import { issueTypes, RESOLVED_STATUS } from '../../../../constants/issues';
import { getSafeArray } from '../../../../utils/helpers';
import issuesSimpleOptionsSelector from '../../../../selectors/IssuesSimpleOptionsSelector';
import Avatar from '../../../layout/avatars/Avatar';
import GroupDetailsBulkNotes from './GroupDetailsBulkNotes';
import useHandleApiResponse from '../../../../hooks/useHandleApiResponse';
import useCustomQuery from '../../../../hooks/useCustomQuery';
import GroupsRemediationNote from '../GroupsRemediationNote';
import PartnerDetails from '../../../issue-partners/details/PartnerDetails';
import GroupDetailsBulkTags from './GroupDetailsBulkTags';
import useShowModal from '../../../../hooks/useShowModal';
import PartnerActionsModal from '../../../issue-partners/actions/PartnerActionsModal';
import PARTNERS from '../../../../constants/partners';
import useManualActions from '../../../../hooks/useManualActions';
import { getObsidianState } from '../../../../utils/partnerActionHelpers';
import { obsidianStates } from '../../../../utils/partnerHelpers';
import partnerActionsSelector from '../../../../selectors/partnerActionsSelector';
import useMemoizedDispatch from '../../../../hooks/useMemoizedDispatch';
import { successActionCreator } from '../../../../actions/action-creators/success';
import { ADD_COMMENT_REQUEST } from '../../../../actions/types';
import { v4 as uuidv4 } from 'uuid';
import { groupTabIds } from '../../../../constants/groups';

const GroupDetailsBulkActions = (props) => {
  const {
    group,
    criticalityClassName,
    showBulkTagsButton = true,
    bulkTagsType = groupTabIds.GROUP_GENERAL_TAB,
    selectedDevices = [],
  } = props;
  const { partner_name: partnerName } = group;
  const [openModal, setOpenModal] = useState(false);
  const { callPartnerAction } = useManualActions({ group });
  const partnerActions = useSelector(state => partnerActionsSelector(state, group?.partner_id));
  const { dispatch } = useMemoizedDispatch();
  const user = useSelector((state) => state.auth.user);

  //  Initializing API
  const { GroupAPI, UserAPI } = API;

  //  Watching redux store
  const statuses = useSelector((state) =>
    issuesSimpleOptionsSelector(
      state,
      issueTypes.VULNERABILITIES,
      'statuses',
      true
    )
  );
  const criticalities = useSelector((state) => issuesSimpleOptionsSelector(state, issueTypes.VULNERABILITIES, 'criticalities', true));

  // Component Hooks
  const {
    data: updateData,
    error: updateError,
    isLoading: updateLoading,
    mutate: updateGroup,
  } = useCustomMutation(
    (params) => GroupAPI.handlers.updateGroup(params),
    GroupAPI.invalidators.updateGroup
  );
  const {
    data: assgineesResponse,
    error: assigneesError,
    isLoading: loading,
  } = useCustomQuery(
    [UserAPI.keys.getIssuesAssignees, { selectedCompany: null, searchTerm: '' }],
    ({ queryKey }) => UserAPI.handlers.getIssuesAssignees(queryKey[1]),
  );
  const ability = useCanAbility();
  const {
    showModal: showRemediation,
    toggleModal: toggleRemediationModal
  } = useShowModal();
  useHandleApiResponse({
    successMessage: "Issues updated",
    data: updateData,
    error: updateError,
  })
  useHandleApiResponse({
    data: assgineesResponse,
    error: assigneesError
  })

  // Component variables
  const canModifyIssues = ability.can(
    MODIFY_ACTION,
    PRIVILEGE_SOURCES.ISSUES.GENERAL
  );

  // Function to bulk update issues
  const updateGroupIssues = (keyName, value) => {
    if (keyName === 'status' && value === 4) {
      if (partnerName === PARTNERS.OBSIDIAN) {
        return setOpenModal(true);
      }
      toggleRemediationModal();
      return;
    }

    if (partnerName === PARTNERS.OBSIDIAN)
      callPartnerAction({
        state: getObsidianState(value),
        action: 'change_alert_status'
      });

    const body = {};
    body[keyName] = value;
    const queryParams = {
      public_id: group?.public_id,
      body,
    };

    updateGroup(queryParams);
  };

  // Map elements to options
  const mapToOptions = (elements) => {
    return getSafeArray(elements).reduce((options, element) => {
      if (element.hidden) {
        return options;
      } else {
        return [
          ...options,
          {
            label: element?.label,
            value: element?.id,
          },
        ];
      }
    }, []);
  };

  // Get selected option
  const getSelectedOption = (options, value) => {
    return getSafeArray(options).find(option => option?.value === value);
  }

  //  Function to render user option
  const renderUserOption = (user, profileImage = null) => {
    const { full_name } = user;
    return (
      <div className="d-inline-flex align-items-center">
        <Avatar
          user={user}
          profileImage={profileImage}
          customClassName="user-option"
        />
        {full_name}
      </div>
    );
  };

  // Map assignees options
  const mapAssigneesToOptions = () => {
    if (!assgineesResponse) { return [] }
    const { assignees = [] } = assgineesResponse;
    return assignees.map((element) => {
      return { label: renderUserOption(element), value: element.id };
    });
  }

  //  Rendering remediation modal
  const renderRemediationModal = () => {
    if (!showRemediation) { return null; }
    return (
      <GroupsRemediationNote
        fromDetails={true}
        groups={[group?.public_id]}
        closeModal={() => toggleRemediationModal(false)}
      />
    );
  };

  // Render status
  const renderStatusField = () => {
    const isDisabled = !canModifyIssues;
    return (
      <CallbackSelectField
        name="status"
        label="Batch status"
        placeholder="Unassigned"
        value={null}
        options={mapToOptions(statuses)}
        callback={(option) => updateGroupIssues('status', option?.value)}
        disabled={isDisabled}
      />
    );
  };

  // Render criticality
  const renderCriticalityField = () => {
    const canEditCriticality = ability.can(
      MODIFY_ACTION,
      PRIVILEGE_SOURCES.ISSUES.CRITICALITY_DROPDOWN
    );
    const isDisabled = !canModifyIssues || !canEditCriticality;
    const criticalityOptions = mapToOptions(criticalities);
    return (
      <CallbackSelectField
        name="criticality"
        label="Batch criticality"
        placeholder="Assign criticality"
        value={getSelectedOption(criticalityOptions, group?.severity)}
        options={criticalityOptions}
        customClassName={criticalityClassName}
        callback={(option) => updateGroupIssues('severity', option?.value)}
        disabled={isDisabled}
      />
    );
  };

  // Render assignee field
  const renderAssigneeField = () => {
    const isDisabled = !canModifyIssues;
    const assigneesOptions = mapAssigneesToOptions();
    return (
      <CallbackSelectField
        name="assignee"
        label="Assignee"
        placeholder="Cydekicks"
        value={null}
        options={assigneesOptions}
        callback={(option) => updateGroupIssues('assigned_to', option?.value)}
        disabled={isDisabled}
      />
    );
  };

  // Render solution field
  const renderSolutionField = () => {
    const solutionOption = {
      value: null,
      label: group?.solution_name
    }
    return (
      <CallbackSelectField
        name="solution"
        label="Solution"
        placeholder="Pick solution"
        value={solutionOption}
        options={[solutionOption]}
        disabled={true}
        callback={null}
      />
    );
  };

  const handleUpdateComment = (params) => {
    dispatch(successActionCreator(ADD_COMMENT_REQUEST));
    const updateBody = {
      public_id: group?.public_id,
      body: {
        status: RESOLVED_STATUS,
        remediation_note: {
          updated_at: new Date(),
          user: {
            id: user.id,
            full_name: user.name,
            profile_image: user.profileImage,
          },
          content: {
            blocks: [{
              data: {},
              depth: 0,
              entityRanges: [],
              key: uuidv4(),
              text: params?.comment,
              type: "unstyled",
              inlineStyleRanges: [],
            }],
            entityMap: {}
          },
          is_remediation: true,
        }
      }
    };
    updateGroup(updateBody);
  }

  const renderModalActions = () => {
    if (partnerName !== PARTNERS.OBSIDIAN || !partnerActions) return;

    const { issues } = partnerActions;
    const getModalAction = issues?.find(issue => issue.key === "change_alert_status");
    return (
      <PartnerActionsModal
        action={getModalAction}
        callPartnerAction={callPartnerAction}
        closeModal={() => setOpenModal(false)}
        open={openModal}
        extraParams={{ state: obsidianStates.CLOSED }}
        extraSubmitFunction={(params) => handleUpdateComment(params)}
        showConfirmCheckbox={true}
        isIssue={true}
      />
    );
  }

  // Render item
  const renderItem = (label, value) => {
    return (
      <div className="info-group">
        <div className="label-container">
          <label>{label}</label>
        </div>
        <div className="info-container info-text text-light-gray">
          {value}
        </div>
      </div>
    )
  }

  // Render source
  const renderSource = () => {
    return renderItem("Source", group?.partner_name || 'Manual')
  }

  // Render partner actions
  const renderAction = () => {
    if (group.partner_name === PARTNERS.OBSIDIAN) return null;

    return <PartnerDetails group={group} />
  }

  // Render bulk notes
  const renderBulkNotes = () => {
    return renderItem("Bulk notes", (
      <GroupDetailsBulkNotes
        issuesCount={(group?.open_issues + group?.closed_issues) || 0}
        groupPublicId={group?.public_id}
        fromDetails={true}
      />
    ))
  };

  //  Rendering bulk tags
  const renderBulkTags = () => {
    if (!showBulkTagsButton) return;
    return renderItem(bulkTagsType === groupTabIds.GROUP_DEVICES_TAB ? 'Devices Tags' : 'Tags', (
      <GroupDetailsBulkTags
        group_id={group?.id}
        issues_count={(group?.open_issues + group?.closed_issues) || 0}
        bulkTagsType={bulkTagsType}
        selectedDevices={selectedDevices}
      />
    ))
  };

  return (
    <div className='group-details-bulk-actions'>
      <div className="issue-information-title">Bulk Actions</div>
      {renderStatusField()}
      {renderCriticalityField()}
      {renderAssigneeField()}
      {renderSolutionField()}
      {renderBulkNotes()}
      {renderBulkTags()}
      {renderSource()}
      {renderAction()}
      {renderRemediationModal()}
      {renderModalActions()}
    </div>
  );
};

export default GroupDetailsBulkActions;
