import React from 'react';
import { Row, Col } from 'reactstrap';

const HalfScreenImageContainer = ({ image, imageMaxHeight = "619px", columnNumber, customClassName }) => {

    return (
        <Col 
            className="d-none d-md-block"
            md={columnNumber.md}
            lg={columnNumber.lg}
        >
            <Row className={`${customClassName ? customClassName : 'justify-content-end px-4 '}`}>
                <img  
                    src={image} 
                    alt="" 
                    className="img-fluid" 
                    style={{ maxHeight: imageMaxHeight }}
            />
            </Row>

        </Col>
    )
}

export default HalfScreenImageContainer;
