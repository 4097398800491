import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UpdatePasswordForm from '../components/auth/forms/UpdatePasswordForm';
import { changeLayout } from '../actions/layout';
import ConfirmPassword from '../components/auth/ConfirmPassword';

 const ChangePassword = (props) => {
    const {
          changeLayout,
          isValid,
          checkupFinished
    } = props;



    useEffect(()=>{
        if(checkupFinished){
			changeLayout('vertical');
		}else{
			changeLayout('horizontal');
		}
    },[changeLayout, checkupFinished]);

    return (
        <div>
            { isValid ? (
                <div className="update-password bg-gradient-dark-purple-v3 h-minus-header vw-100">
                    <UpdatePasswordForm />            
                </div>
            ):(
                <ConfirmPassword />
            )}
        </div>
    );
}


const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            isValid: state.auth.resetPassword,
            checkupFinished: state.answers.showReportCard
        }
    }
    return mapStateToProps;
}

const mapDispatchToProps = {
    changeLayout
};

export default withRouter(connect(makeMapStateToProps, mapDispatchToProps)(ChangePassword));

