import React from 'react';
import RiskManagerChart from '../../../../vcyso/RiskManagerChart';
import RiskStatementStatus from '../../../../vcyso/RiskStatementStatus';
import DashboardRow from '../../../common/dashboard/DashboardRow';
import { Row } from 'reactstrap';

const RiskManagerSection = () => {
    return (
        <DashboardRow
            title="Risk Manager"
            linkText="See all"
            linkUrl="/dashboard/risk-manager"
            rowClassName="risk-manager-section vcyso-container mb-2"
        >
            <div className='business-risk-container dashboard mb-0'>
                <Row className="no-gutters">
                    <RiskStatementStatus />
                    <RiskManagerChart showTreatmentFilters={false}/>
                </Row>
            </div>
        </DashboardRow>
    )
}

export default RiskManagerSection
