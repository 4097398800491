import React from 'react'
import CustomSpinner from './CustomSpinner'
import ErrorMessage from './ErrorMessage'

const LoadingContent = (props) => {
    const {
        isLoading,
        errorMessage,
        children,
        theme = 'dark',
        iconType= "icon",
        showErrorButton = true,
        errorButton,
        errorButtonCallback,
        errorClassName='',
        loadingClassName='',
        errorStyle,
        loadingStyle,
    } = props;

    const tryAgainButton = (
        <button
            className="btn btn-light btn-try-again"
            onClick={errorButtonCallback}
        >
            Try again
        </button>
    );
    return (
        <React.Fragment>
            {errorMessage && (
                <ErrorMessage
                    customStyle={errorStyle}
                    text={errorMessage}
                    theme={theme}
                    type={iconType}
                    button={showErrorButton ? (errorButton || tryAgainButton) : null}
                    wrapperClassName={errorClassName}
                />
            )}
            {isLoading && !errorMessage && (
                <CustomSpinner customStyle={loadingStyle} customClassName={loadingClassName} />
            )}
            {!isLoading && !errorMessage && (
                <React.Fragment>
                    {children}
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default LoadingContent;
