import React from "react";
import SidebarContent from "./SidebarContent";

const Sidebar = (props) => {
  //  Parsing props
  const { type } = props;

  //  Rendering
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          <React.Fragment>
            <SidebarContent type={type} />
          </React.Fragment>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
