import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SimpleDropdown from '../../../layout/SimpleDropdown';
import { dateTypeFilters } from '../../../../constants/app-v4/timeFilter';
import { Row, Col, Button } from 'reactstrap';
import CustomDatepicker from '../../../calendar/CustomDatepicker';
import { setDateInfo, setRangeValue } from '../../../../actions/dashboards';
import useMemoizedDispatch from '../../../../hooks/useMemoizedDispatch';
import moment from 'moment-timezone';
import { setAlert } from '../../../../actions/alerts';
import { getTimeFilterByValue, getSafeTimeForPicker } from '../../../../utils/filterHelpers';
import companyInfoSelector from '../../../../selectors/companyInfoSelector';
import { isValidFunction } from '../../../../utils/helpers';

const DashboardTimeFilter = (props) => {

  const {
    salesReloadDataMutator
  } = props;

  //  Watching redux store
  const range_value = useSelector((state) => state?.dashboards?.range_value);
  const start_date = useSelector((state) => state?.dashboards?.start_date);
  const end_date = useSelector((state) => state?.dashboards?.end_date);
  //  Watching redux store
  const isSales = useSelector((state) => companyInfoSelector(state, 'isSales', false));

  //  Component state
  const [selectedRange, setSelectedRange] = useState(getTimeFilterByValue(range_value, dateTypeFilters));
  const [showCalendar, setShowCalendar] = useState(false);
  const [startDatePicker, setStartDatePicker] = useState(getSafeTimeForPicker(start_date));
  const [endDatePicker, setEndDatePicker] = useState(getSafeTimeForPicker(end_date));

  //  Memoized dispatch
  const { dispatch } = useMemoizedDispatch();

  //  Watching range value changes on redux store (for peristence)
  useEffect(() => {
    //  Do not change local state if has the same value from redux
    if (selectedRange === null) { return; }
    if (range_value === selectedRange?.value) { return; }

    //  Changing new range, hydrated from persistence
    const newRange = getTimeFilterByValue(range_value);
    setSelectedRange(newRange);
  }, [range_value]);

  //  Watching selected start date on redux
  useEffect(() => {
    if (!start_date) { return; }

    //  If redux store has a start date and local state is null, change the local state
    if (!startDatePicker) {
      setStartDatePicker(getSafeTimeForPicker(start_date));
      return;
    }

    //  Do not change if both dates are the same
    if (moment(startDatePicker).valueOf() === moment(start_date).valueOf()) { return; }

    setStartDatePicker(getSafeTimeForPicker(start_date));
  }, [start_date]);

  //  Watching selected end date on redux
  useEffect(() => {
    if (!end_date) { return; }

    //  If redux store has a start date and local state is null, change the local state
    if (!endDatePicker) {
      setEndDatePicker(getSafeTimeForPicker(end_date));
      return;
    }

    //  Do not change if both dates are the same
    if (moment(endDatePicker).valueOf() === moment(end_date).valueOf()) { return; }

    setEndDatePicker(getSafeTimeForPicker(end_date));
  }, [end_date])

  //  Watching selected ranges changes
  useEffect(() => {
    if (selectedRange === null) { return; }

    //  Setting range value on redux store (for persistence purposes)
    dispatch(setRangeValue(selectedRange?.value));

    //  Deciding if we need to show calendar
    setShowCalendar(selectedRange?.value === 0);

    //  Selecting a custom range
    if (selectedRange?.value === 0) { return; }

    //  Setting new date ranges
    const end = new Date();
    const start = moment(end).subtract(selectedRange?.value, 'days').toDate();
    updateDates(start, end);
  }, [selectedRange?.value]);

  //  Function to handle dropdown change
  const onDropdownChange = (option) => {
    if (!option) { return; }
    setSelectedRange(option);
  };

  // Function to update dates on redux
  const updateDates = (startDate = startDatePicker, endDate = endDatePicker) => {
    //  Making sure both dates exist
    if (startDate === null || endDate === null) {
      dispatch(setAlert('Both dates are required. Please check and try again.', 'danger'));
      return;
    }

    //  Comparing ranges limits
    const dayDifference = moment(endDate).diff(moment(startDate), 'days');
    const minRange = parseInt(process.env.REACT_APP_TIME_FILTERS_MIN_DAY || 7);
    const maxRange = parseInt(process.env.REACT_APP_TIME_FILTERS_MAX_DAY || 90);

    //  Verifying min range
    if (dayDifference < minRange) {
      dispatch(setAlert(`Range should be greater than ${minRange} days.`, 'danger'));
      return;
    }

    //  Verifying max range
    if (dayDifference > maxRange) {
      dispatch(setAlert(`Range should not be greater than ${maxRange} days.`, 'danger'));
      return;
    }

    dispatch(setDateInfo(startDate, endDate, moment.tz.guess()));
  };

  // Render calendars
  const renderCalendars = () => {
    if (!showCalendar) return;

    return (
      <>
        <Col xs={12} sm={8} md='auto' className="calendars-container">
          <div className="dropdown-parent-container">
            <div className="dropdown-container">
              <CustomDatepicker
                value={startDatePicker}
                setValue={setStartDatePicker}
                minDate={null}
                maxDate={moment(new Date()).subtract(1, 'days').toDate()}
              />
            </div>
          </div>
          <p className="to-label">to</p>
          <div className="dropdown-parent-container">
            <div className="dropdown-container">
              <CustomDatepicker
                value={endDatePicker}
                setValue={setEndDatePicker}
                minDate={startDatePicker}
                maxDate={new Date()}
                disabled={startDatePicker === null}
              />
            </div>
          </div>
        </Col>
        <Col xs={12} sm={2} md='auto'>
          <button
            className="btn btn-light text-dark btn-time-filter"
            onClick={() => updateDates(startDatePicker, endDatePicker)}
          >
            Apply
          </button>
        </Col>

      </>
    )
  }

  //  Rendering
  return (
    <Row className="dashboard-time-filter no-gutters px-3 w-100">
      <Col xs={12} sm={showCalendar ? 2 : 4} lg={2}>
        <div className="frequency-container">
          <SimpleDropdown
            extraClassName={`dropdown-filter action-dropdown`}
            placeholder=""
            elements={dateTypeFilters}
            onChangeCallback={onDropdownChange}
            selectedElement={selectedRange}
          />
        </div>
      </Col>
      {renderCalendars()}
      {!isSales ? null : <Button
        className={`btn-large h-100 btn-outline-light-gray random-data-button`}
        outline
        onClick={isValidFunction(salesReloadDataMutator) ? salesReloadDataMutator : null}
      >
        Generate data
      </Button>}
    </Row>
  )
}

export default DashboardTimeFilter
