import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Form } from 'react-final-form';
import FormCard from '../../components/layout/FormCard';
import RightAlignedFormContainer from '../../containers/RightAlignedFormContainer';
import CustomFormField from '../../components/CustomForm/CustomFormField';
import { invitedMemberRegisterForm } from '../../constants/formTemplates';
import { validateFieldsByFormTemplate, existEmptyValuesOnForm } from '../../utils/validations';
import { setAlert } from '../../actions/alerts';
import { triggerRegisterSuccess } from '../../actions/auth';
import API from '../../api';
import { buildPayloadFromFormTemplate, getApiErrorMessage } from '../../utils/helpers';
import useCustomMutation from '../../hooks/useCustomMutation';
import useCustomQuery from '../../hooks/useCustomQuery';
import MainScrollableContainer from '../layout/MainScrollableContainer';
import { tokenInvitesLogos } from '../../constants/logosTemplates';

//  Component
const InvitedMemberRegister = (props) => {
  //  Initializing APIs
  const { UserAPI } = API;

  //  Memoized dispatch
  const defaultDispatch = useDispatch();
  const dispatch = useCallback(defaultDispatch, []);

  //  Component state
  const [tokenData, setTokenData] = useState({ email: null, token: null });
  const [hasRetryToken, setHasRetryToken] = useState(false);

  //  Hooks initialization
  const { token: token_invite } = useParams();
  const history = useHistory();

  //  Hook for verifying token
  const {
    data: tokenResponse,
    error: tokenError,
    refetch: refetchToken,
  } = useCustomQuery(
    [UserAPI.keys.getUserInvite, { token_invite }],
    ({ queryKey }) => UserAPI.handlers.getUserInvite(queryKey[1]),
  );

  //  Hook for creating the user

  const {
    data: userResponse,
    error: userError,
    isLoading: userLoading,
    mutate: createUser,
  } = useCustomMutation(
    (params) => UserAPI.handlers.acceptMemberInvite(params),
    UserAPI.invalidators.acceptMemberInvite
  );

  //  Watching token error
  useEffect(() => {
    if (!tokenError) { return; }
    const { status } = tokenError;
    //  Retrying if the status is unauthorized (EDGE CASE)
    if (!hasRetryToken && status === 401) {
      setHasRetryToken(true);
      refetchToken();
      return;
    }
    //  Displaying error state
    dispatch(setAlert(getApiErrorMessage(tokenError), 'important'));
    history.push('/');
  }, [tokenError]);

  //  Watching token response
  useEffect(() => {
    if (!tokenResponse) { return; }
    const { data = {} } = tokenResponse;
    const { email, token } = data;
    setTokenData({ email, token });
  }, [tokenResponse]);

  //  Watching user success
  useEffect(() => {
    if (!userResponse) { return; }
    const { user } = userResponse;
    if (!user) { return; }
    dispatch(triggerRegisterSuccess({ history, user }));
  }, [userResponse]);

  //  Watching user error
  useEffect(() => {
    if (!userError) { return; }
    const { data } = userError;
    const { errorMessage } = data || { errorMessage: 'Error inviting users' };
    dispatch(setAlert(errorMessage, 'danger'));
  }, [userError]);

  //  Function for subimitting
  const onSubmit = (formValues) => {
    //  Pre submit validations
    const hasEmptyValues = existEmptyValuesOnForm(formValues, invitedMemberRegisterForm.length);
    if (hasEmptyValues) {
      dispatch(setAlert('One or more fields have an error. Please check and try again', 'danger'));
      return;
    }
    //  Submitting
    const { token } = tokenData;
    if (!token) { dispatch(setAlert('Invalid token', 'danger')); return; }
    const payload = buildPayloadFromFormTemplate(invitedMemberRegisterForm, formValues);
    const userToCreate = {
      first_name: payload.first_name,
      last_name: payload.last_name,
      password: payload.password
    };
    createUser({ token, userToCreate });
  };

  //  Function to validate form fields
  const validateForm = (formValues) => {
    return validateFieldsByFormTemplate(invitedMemberRegisterForm, formValues);
  };

  //  Function to render form
  const renderForm = (params) => {
    const { handleSubmit, invalid } = params;

    return (
      <form
        onSubmit={(event) => {
          if (invalid) { dispatch(setAlert('One or more fields have an error. Please check and try again', 'danger')); }
          handleSubmit(event);
        }}
        className="invited-member-form"
      >
        <div className="row-container">
          <div className="half-width-field left-field">
            <CustomFormField fieldTemplate={invitedMemberRegisterForm[0]} />
          </div>
          <div className="half-width-field">
            <CustomFormField fieldTemplate={invitedMemberRegisterForm[1]} />
          </div>
        </div>
        <div className="label-row">
          <div className="label">Company Email</div>
          <div className="email-label">{tokenData.email}</div>
        </div>
        <CustomFormField fieldTemplate={invitedMemberRegisterForm[2]} />
        <div className="row-container">
          <Button
            className="btn btn-submit btn-light text-dark font-weight-bold"
            type="submit"
            disabled={invalid || userLoading}
          >
            {userLoading ? 'Processing' : 'Activate'}
          </Button>
        </div>
      </form>
    );
  };

  //  Rendering
  return (
    <MainScrollableContainer>
      <RightAlignedFormContainer
        logos={tokenInvitesLogos}
        leftComponents={null}
      >
        <FormCard title="Activate your Cyvatar Business Account">
          <Form
            onSubmit={onSubmit}
            render={renderForm}
            validate={validateForm}
            shouldValidate
          />
        </FormCard>
      </RightAlignedFormContainer>
    </MainScrollableContainer>
  )
};

export default InvitedMemberRegister;
