export const mapFormFieldsFromRows = (rows = []) => {
    const fields = [];

    rows.forEach((row) => {
      row.forEach((field) => {
          fields.push(field)
      })
    })

    return fields;
}

//  Function to mutate form state when a member row has been removed
export const mutateFormStateWhenAddingMembersRows = (args, state, callbacks, fields) => {
    const { formState } = state;
    const { values, errors } = formState;

    //  Verifying if we need to change the state
    const amountOfKeys = Object.keys(values).length;
    if (amountOfKeys === 0) { return; }
    if (amountOfKeys <= (fields.length)) { return; }

    //  Removing the extra field values and errors
    Object.keys(values).forEach((key) => {
        const matchingField = fields.find(({ name }) => name === key);
        if (matchingField) { return; }
        delete values[key];
        delete errors[key];
    });
};

export const mapUserParamsFromFormValues = (formValues, length, includeAdmin, includeNames) => {
    if(!formValues) return {};
    const paramKeys = includeNames ? ['first_name', 'last_name', 'email'] : ['email'];
    const admin_user = {};
    const invited_users = [];

    for (let i = 0; i < length; i++) {
        if(i === 0 && includeAdmin) {
            paramKeys.forEach((key) => {
                admin_user[key] = formValues[`${key}${i}`]
            })
        } else {
            const user = {};
            paramKeys.forEach((key) => {
                user[key] = formValues[`${key}${i}`]
            })
            invited_users.push(user)
        }
    }
    return {
        admin_user,
        invited_users
    }
}

export const mapPartnerParamsFromFormValues = (formValues = {}, keyPreffix = 'partner') => {
    const partnersToSetup = []
    Object.keys(formValues).forEach(key => {
        if (key.startsWith(keyPreffix)) {
            const option = formValues[key];
            if (option && option.value && !partnersToSetup.includes(option.value)) {
                partnersToSetup.push(option.value);
            }
        };
    });
    return partnersToSetup;
}