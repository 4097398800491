import React from 'react';

const ExternalLink = (props) => {
    const {
        label,
        baseClassName="btn btn-light",
        extraClassName="",
        target="_blank",
        link
    } = props;

    return (
        <a
            className={`${baseClassName} ${extraClassName}`}
            target={target}
            rel="noopener noreferrer"
            href={link}
        >
            {label}
        </a>
    );
};

export default ExternalLink;
