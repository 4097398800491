import React, { useState } from 'react';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { Input, Button } from 'reactstrap';
import { } from '../../actions/types';
import { trackEvent } from "../../utils/gaTracker";
import { cancelMembershipRequest } from "../../actions/auth";
import BasicModal from "../layout/BasicModal";
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import amplitude from 'amplitude-js';

const CancelMembership = () => {

  //Component State
  const [showCancelModal, setCancelModal] = useState(false);
  const [showConfirmation, setConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');

  //Component Hooks
  const { dispatch } = useMemoizedDispatch();

  //  Getting router location and history
  const location = useLocation();
  const history = useHistory()

  const toggleModal = () => {
    trackEvent('cancel-membership-modal', 'membership', `cancel-membership-${!showCancelModal ? 'close':'open'}`);
    setCancelModal(!showCancelModal);
    setConfirmation(false);
    setConfirmationMessage('');
  }

  const handleMessageChange = (e) => {
    let message = e?.target?.value || '';
    setConfirmationMessage(message.toUpperCase());
  }

  const handleConfirmCancelClick = () => {
    setConfirmation(true);
    trackEvent('cancel-membership-confirmation', 'membership', `cancel-membership-confirmation`);
    amplitude.getInstance().logEvent('My Account View - Notification Settings - Cancel Membership');
    dispatch(cancelMembershipRequest(history, location?.pathname));
  }

  const confirmationUIMessage = <div>We are sorry to see you go. To re-enable your membership please contact support.</div>;
  const farewellMessage = <div>We’ll miss you. <br /> Are you sure you want to cancel your membership? <br/> All your information and personal data will be deleted after 30 days.</div>;
  const confirmationText = 'CANCEL MY ACCOUNT';

  const renderConfirmMessage = () => {
    return (
      <div className='d-flex flex-column align-items-center confirm-message'>
        <div className='label'>Type CANCEL MY ACCOUNT to confirm</div>
        <Input
          type="text"
          className="form-control input"
          placeholder={confirmationText}
          value={confirmationMessage}
          onChange={handleMessageChange}
        />
      </div>
    )
  }

  const renderCancelCTA = () => {
    return (
      <div className="d-flex justify-content-center">
        {showConfirmation ?
          (<Button className='action-btn' color='light' onClick={toggleModal} > Ok </Button>) :
          (<React.Fragment>
            <Button color="danger" className="mr-2" onClick={handleConfirmCancelClick} disabled={confirmationMessage !== confirmationText} >Yes, cancel it</Button>
            <Button color="light-gray" outline onClick={toggleModal}> No, I’m staying </Button>
          </React.Fragment>)
        }
      </div>
    );
  }

  const renderModal = () => {
    const title = showConfirmation ? 'Farewell friend' : 'Are you sure?';
    return (
      <BasicModal
        title={title}
        showModal={showCancelModal}
        toggleModal={() => toggleModal()}
        customClassName='cancel-membership'
      >
        <div className='message d-flex justify-content-center text-center'>
          {showConfirmation ? confirmationUIMessage : farewellMessage }
        </div>
        {!showConfirmation && renderConfirmMessage()}
        {renderCancelCTA()}
      </BasicModal>
    )
  }

  const renderCancelMembershipButton = () => {
    return (
      <Button
        color="light-gray"
        className="btn-cancel btn-cancel-membership ml-2 px-3 py-2"
        outline
        onClick={() => { setCancelModal(!showCancelModal) }}
      >
        Cancel membership
      </Button>
    )
  }

  return (
    <>
      {renderCancelMembershipButton()}
      {showCancelModal && renderModal()}
    </>
  )
}

export default CancelMembership;
