import React from 'react';
import { getFormattedDate } from '../../../../utils/helpers';
import IssueTextField from '../../../issue-manager/IssueTextField';
import LoadingContent from '../../../layout/LoadingContent';

const GroupGeneralInformation = (props) => {
  const { isLoading, errorMessage, group = {} } = props;

  // Render data item
  const renderItem = (label, value) => {
    return (
      <div className="mb-2">
        <div className="issue-information-label">{label}</div>
        <div className="issue-information-value">{value}</div>
      </div>
    );
  };

  // Render description
  const renderDescription = () => {
    const defaultText = 'No description written for this issue';
    return (
      <IssueTextField
        fieldName="description"
        issueCode={null}
        label="Description"
        text={group?.description}
        defaultText={defaultText}
        textClassName="issue-details-description"
        isEditable={false}
        textAreaClassName="issue-description-text-area"
      />
    );
  };

  // Render issue information
  const renderIssueInformation = () => {
    const {
      created_at,
      updated_at,
      affected_companies,
      open_issues,
      closed_issues,
    } = group;
    return (
      <div className="issue-details-information">
        <div className="issue-information-title">Issue Information</div>
        {renderItem('Members affected', affected_companies || 0)}
        {renderItem('Open issues amount', open_issues || 0)}
        {renderItem('Closed issues amount', closed_issues || 0)}
        {renderItem(
          'Last update',
          updated_at ? getFormattedDate(updated_at, false) : '-'
        )}
        {renderItem(
          'Creation date',
          created_at ? getFormattedDate(created_at, false) : '-'
        )}
      </div>
    );
  };

  // Render device information
  const renderDeviceInformation = () => {
    const { affected_devices, affected_devices_percentage } = group;
    return (
      <div className="d-flex justify-content-end">
        <div className="issue-device-information">
          <div className="issue-information-title">Devices Information</div>
          {renderItem('Devices amount', affected_devices || 0)}
          {renderItem(
            'Devices percentage',
            `${affected_devices_percentage || 0}%`
          )}
        </div>
      </div>
    );
  };

  return (
    <LoadingContent
      isLoading={isLoading}
      errorMessage={errorMessage ? 'Error getting group' : null}
      iconType="solidIcon"
      errorStyle={{ minHeight: '150px', paddingTop: '16px' }}
      loadingStyle={{ minHeight: '150px', paddingTop: '200px' }}
    >
      <div className="issue-devices-info-container d-flex">
        {renderIssueInformation()}
        {renderDeviceInformation()}
      </div>
      {renderDescription()}
    </LoadingContent>
  );
};

export default GroupGeneralInformation;
