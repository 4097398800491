import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { changeLayout } from '../actions/layout';
import { Row, Col } from 'reactstrap';
import PublicHeader from '../components/layout/VerticalLayout/headers/PublicHeader'
import useMemoizedDispatch from '../hooks/useMemoizedDispatch';
import API from '../api';
import LoadingContent from '../components/layout/LoadingContent';
import errorMessages from '../constants/errorMessages';
import CyvatarCertificateInformation from '../components/badges/CyvatarCertificateInformation';
import { certificateUnauthenticatedData } from '../constants/pagesData';
import InformationCard from '../components/layout/cards/InformationCard';
import CyvatarBadgeCertificate from '../components/badges/CyvatarBadgeCertificate';
import { trackPage } from '../utils/gaTracker';
import { Helmet } from 'react-helmet';
import { getGeneralFormatDate } from '../utils/dateFormatter';
import useCustomQuery from '../hooks/useCustomQuery';

const PublicBadgeCertificate = () => {
    //  Initialzing APIs
    const { BadgeAPI } = API;

    //Getting params
    const { public_id } = useParams();

    //  Calling API
    const {
        data: badgeResponse,
        error: badgeError,
        isLoading: badgeLoading,
        refetch: fetchBadge,
    } = useCustomQuery(
        [BadgeAPI.keys.getBadge, public_id],
        ({ queryKey }) => BadgeAPI.handlers.getBadge(queryKey[1])
    );

    //Component Hooks
    const { dispatch } = useMemoizedDispatch();

    //Getting needed info from redux store
    const companyPublicId = useSelector(state => state.auth?.company?.id);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const isLoading = useSelector(state => state.auth.loading);

    useEffect(() => {
        dispatch(changeLayout('horizontal'));
        trackPage('cyvatar-public-badge');
    }, [changeLayout, trackPage]);

    //Add meta tags
    const renderMetaTags = () => {
        const title = `${badgeResponse?.certificate?.badge_name} certificate for ${badgeResponse?.company_name}`;
        const description = `This Cyvatar Certificate validates that ${badgeResponse?.company_name} is a Cybersecurity as a service platform member since ${getGeneralFormatDate(badgeResponse?.certificate?.created_at)} and is currently secured`;
        return (
            <>
                <Helmet>
                    <meta property="og:title" content={title} />
                    {/* <meta property="og:image" content={badgeResponse?.certificate?.image}/> */}
                    <meta property="og:description" content={description} />
                </Helmet>
            </>
        )
    }

    const renderBadgeCompanyName = () => {
        return (
            <div className="badge-company-name mb-3">
                {`${badgeResponse?.company_name} /  `}
                <span className="text-capitalize">{badgeResponse?.certificate?.certificate_name}</span>
            </div>
        )
    }

    const renderCertificate = () => {
        return (
            <Col lg={8} >
                <CyvatarBadgeCertificate
                    badge={badgeResponse?.certificate}
                    companyName={badgeResponse?.company_name}
                />
            </Col>
        )

    }

    const renderBadgeInformation = () => {
        return (
            <Col lg={4} className="pt-3">
                <CyvatarCertificateInformation
                    isUnlocked={true}
                    showEmbedCode={isAuthenticated && (companyPublicId === badgeResponse?.reference_id)}
                    companyName={badgeResponse?.company_name}
                    badge={badgeResponse?.certificate}
                    eventSection="public-badge"
                />
                {renderUnauthenticatedCard()}
            </Col>
        )
    }

    const renderUnauthenticatedCard = () => {
        if (isLoading || isAuthenticated) return null;
        const { messages, buttonLabel, title } = certificateUnauthenticatedData;
        return (
            <InformationCard
                title={title}
                content={messages}
                buttonLabel={buttonLabel}
                eventSection="public-badge"
                eventName="effortless-security"
                className="mt-5"
                customLink={process.env.REACT_APP_CYVATAR_WEBSITE}
            />
        )
    }

    return (
        <div className="main-scrollable-container pb-3">
            <PublicHeader />
            <div className="badge-certificate-container mx-auto px-3">
                <LoadingContent
                    isLoading={badgeLoading}
                    errorMessage={badgeError ? (badgeError?.data?.errorMessage || errorMessages.defaultGet) : ''}
                    iconType="solidIcon"
                    errorStyle={{ minHeight: "300px", paddingTop: "100px" }}
                    loadingStyle={{ minHeight: "300px", paddingTop: "100px" }}
                    errorButtonCallback={fetchBadge}
                >
                    {renderBadgeCompanyName()}
                    <Row>
                        {renderCertificate()}
                        {renderBadgeInformation()}
                    </Row>
                    {renderMetaTags()}
                </LoadingContent>
            </div>
        </div>
    )
}

export default PublicBadgeCertificate;
