import moment from 'moment-timezone';
import { timeUnits } from '../constants/tendencyChart';
import {
  findPointBetweenInterval,
  moveDateByType,
  adjustLabelsOnPoints,
  getRangeByFilterType,
} from './timeChartHelpers';

export const initializeTrendChartFilters = (totalPointsConfig) => {
  const dateRange = getRangeByFilterType(timeUnits.week, new Date(), false, totalPointsConfig);
  return {
      company_id: null,
      filter_type: timeUnits.week,
      time: moment.tz.guess(),
      ...dateRange,
  };
};

const normalizeTrendElement = (element, defaultPoints, filters) => {
  //  Parsing params
  const { filter_type: type } = filters;
  const { data, ...restOfElement } = element;
  const newData = [];
  //  Combining default points and points from api
  defaultPoints.forEach((point) => {
    const { start_date, end_date } = point;
    const matchingDataElement = data.find((item) => findPointBetweenInterval(item.start_date, start_date, end_date));
    if (matchingDataElement) {

      const {
        activities,
        count,
        label
      } = matchingDataElement;

      
      const newPoint = {
        ...matchingDataElement,
        end_date: new Date(end_date),
        start_date: new Date(start_date),
        activities: parseInt(activities || 0),
        count: parseInt(count || 0),
        label: parseInt(label),
      }
      newData.push(newPoint);
      return;
    }
    newData.push(point);
  });

  //  Setting labels to the dummy points based on the real data
  const parsedData = adjustLabelsOnPoints(newData);

  //  Returning combined points
  return {
    ...restOfElement,
    data: parsedData,
  };
}

//  Function to fill the data solutions
export const normalizeTrendData = (data = [], defaultPoints, filters) => {
  //  Combining all data with the default expected points
  const mappedElements = [];
  data.forEach((element) => {
    const normalizedElement = normalizeTrendElement(element, defaultPoints, filters);
    mappedElements.push(normalizedElement);
  });

  return mappedElements;
};

export const isTimeIntervalDataEmpty = (isEmpty, series) => {
  let isIntervalEmptyFlag = null;
  if (!isEmpty && series){
    if(series?.length === 0) { // if series is empty meaning = no points in this time range but in others yes
        isIntervalEmptyFlag = true;
    } else { // if series is not empty, check that it actually does not have data in the time range
        series?.map((value) => {
            value?.data?.map((point) => {
                if(point.y !== null){ // validate it is actually empty data, if it has data = goes inside
                    isIntervalEmptyFlag = false;
                }
            });
        });
    }
    // if null means no data in this specific time range
    // if not null, uses the value determined on the above if-else statement ^
    return isIntervalEmptyFlag === null ? true : isIntervalEmptyFlag;
  }
  return isIntervalEmptyFlag;
};