import React, { useState, useEffect, useCallback } from 'react';
import useCustomQuery from '../../hooks/useCustomQuery';
import API from '../../api';
import PARTNERS from '../../constants/partners';
import CustomTable from '../CustomTable/CustomTable';
import { obisidanEventsTableTemplate } from '../../constants/app-v4/obsidianEventsTableTemplate';
import { tablePaginationTypes } from '../../constants/common';

// TODO: adapt the component in case any partner requires information directly from the partner. At the moment only Obsidian works, divide in files
const IssueDetailsFromPartner = ({ issue }) => {
  const { PartnerAPI } = API;
  const [issueDetails, setIssueDetails] = useState(null);
  const [showDetails, setShowDetails] = useState([]);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const {
    data: issueDetailsResponse,
    error: issueError,
    isLoading: issueDetailsLoading,
  } = useCustomQuery(
    [PartnerAPI.keys.getPartnerIssue, issue.code],
    ({ queryKey }) => PartnerAPI.handlers.getPartnerIssue(queryKey[1]),
  );

  useEffect(() => {
    if (!issueDetailsResponse) return;

    setIssueDetails(issueDetailsResponse);
    setShowDetails(issueDetailsResponse.events?.map(() => false));
  }, [issueDetailsResponse]);

  if (issue?.partner?.name !== PARTNERS.OBSIDIAN) return null;

  const renderPropertyDetail = (key, value) => {
    return (
      <div className='obisidian-detail-property'>
        <p>{key}</p>
        <p>{value}</p>
      </div>
    )
  }

  const renderEventDetail = () => {
    return (
      issueDetails?.events?.map((event, index) => {
        const { location, actors } = event;
        const { country, city, subdivision } = location;
        return (
          showDetails[index] && (
            <div
              key={index}
              style={{ top: showDetails[index] }}
              className='obsidian-event-modal-detail'
            >
              <h1>{event.obsidianEventName.description}</h1>
              <div className="obsidian-divider" />
              <div className='obsidian-detail-container'>
                <div>
                  {renderPropertyDetail('Location', `${country}, ${city}, ${subdivision}`)}
                  {renderPropertyDetail('Agent', event.userAgent.raw)}
                </div>
                <div>
                  {renderPropertyDetail('Actor', actors?.map((e) => e.name).join(', '))}
                  {renderPropertyDetail('Service', event.service.name)}
                </div>
              </div>
              <div className="obsidian-divider" />
              {renderPropertyDetail('Event', event.humanReadableDescription.plain)}
              <div className="obsidian-divider" />
            </div>
          )
        )
      })
    );
  }

  const handleOnHoverEnter = (_item, index, ref) => {
    const rowSize = 43;
    const itemYPosition = ref.current.getBoundingClientRect().y + rowSize;
    const newShowDetails = issueDetails.events?.map(
      (_, indexMap) => index === indexMap ? itemYPosition : false
    );
    setShowDetails(newShowDetails);
    forceUpdate();
  }

  const handleOnHoverTableLeave = () => {
    setShowDetails(issueDetails?.events?.map(() => false));
  }

  return (
    <div className='obsidian-events-container'>
      <h1>Events detected</h1>
      <div style={{ overflow: 'scroll' }} onMouseLeave={handleOnHoverTableLeave}>
        <div className="custom-table-container obsidian-events-table-container" >
          <CustomTable
            tableClassName="custom-table pointer table-hover custom-sticky-table obsidian-events-table"
            headerClassName="custom-table-header"
            rowClassName="custom-table-row"
            columnsTemplate={obisidanEventsTableTemplate}
            data={issueDetails?.events || []}
            isScrollable={true}
            clickableRow={true}
            loading={issueDetailsLoading}
            emptyConfig={{
              message: `Events will appear here`,
              messageClassName: 'empty-message',
              displayTable: true,
            }}
            onHoverEnter={handleOnHoverEnter}
            error={issueError}
          />
        </div>
        {renderEventDetail()}
      </div>
    </div>
  );
};

export default IssueDetailsFromPartner;
