export const notificationSettingsIds = {
  GENERAL_EMAIL_NOTIFICATIONS: 'GENERAL_EMAIL_NOTIFICATIONS',
  GENERAL_DASHBOARD_NOTIFICATIONS: 'GENERAL_DASHBOARD_NOTIFICATIONS',
  ISSUE_RESOLUTION: 'ISSUE_RESOLUTION',
  ISSUE_ASSIGNMENT: 'ISSUE_ASSIGNMENT',
  ISSUE_UPDATE: 'ISSUE_UPDATE',
  ISSUE_BULK_ASSIGNMENT: 'ISSUE_BULK_ASSIGNMENT',
  TASK_RESOLUTION: 'TASK_RESOLUTION',
  TASK_ASSIGNMENT: 'TASK_ASSIGNMENT',
  TASK_UPDATE: 'TASK_UPDATE',
  TASK_BULK_ASSIGNMENT: 'TASK_BULK_ASSIGNMENT',
  RESOLUTION_NOTES: 'RESOLUTION_NOTES',
  //Notes_mentions: 'MENTION_IN_NOTES',
  BULK_NOTES: 'BULK_NOTES',
}