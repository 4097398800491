import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, Spinner } from 'reactstrap';
import API from '../../../api';
import { getIssueCardTitle } from '../../../constants/notifications';
import useScrollingQuery from '../../../hooks/useScrollingQuery';
import { getFormattedDate, userHasRoles } from '../../../utils/helpers';
import LoadingContent from '../../layout/LoadingContent';

export default function IssuesNotification({ setShowSidebar }) {
  const { NotificationAPI } = API
  const history = useHistory();
  const [goingToIssues, setGoingToIssues] = useState(false);
  const [error, setError] = useState('');
  const user = useSelector((state) => state.auth.user);

  const getNotifications = async (filters) => {
    const response = await NotificationAPI.handlers.getUserNotifications(filters.offset, 10);
    return response;
  };

  const {
    elements,
    error: notificationError,
    loading: notificationLoading,
    hasNextPage,
    fetchNextPage,
    totalElements
  } = useScrollingQuery({
    baseKey: NotificationAPI.keys.getUserNotifications,
    key: [NotificationAPI.keys.getUserNotifications, 0, 10],
    queryFunction: getNotifications,
    parseElements: (array = []) => array
  })

  useEffect(() => {
    if (!notificationError) return;
    setError('Error getting notifications.');
  }, [notificationError]);

  const renderIssueCode = (issue) => {
    if (issue.issue_code) return <p>{issue.issue_code}</p>

    return issue.body.issue_codes?.map((issueCode, index) => {
      const extraIssues = issue.body?.extra_issues;
      return (
        <p key={index}>
          {issueCode}
          {index >= 4 && extraIssues > 0 &&
            <span>+{extraIssues} issues</span>
          }
        </p>
      )
    })
  }

  const getCardIcon = (type) => {
    if (type.includes('ISSUE')) {
      return 'bx-user-plus'
    }

    return 'bx-file'
  }

  const handleGoToNotification = async (issue) => {
    setGoingToIssues(true);
    const updateNotificationResponse = await NotificationAPI.handlers.readNotification(issue.id);

    if (updateNotificationResponse.status != 200) {
      return setError('Error updating notifications.');
    }

    setGoingToIssues(false);
    const issueType = issue.notification_type_name;
    const isClientAdmin = userHasRoles(user, ['client_admin']);
    const issueRoute = `${isClientAdmin ? '/dashboard' : ''}/issues?`;
    const taskRoute = '/dashboard/task-manager?task';
    const notificationRoute = issueType.includes('ISSUE') ? issueRoute : taskRoute;
    const oneIssueRoute = `${notificationRoute}&details&selectedIssue=${issue.issue_code}`;
    const multyIssueRoute = `${notificationRoute}&filter=${issue.filter_public_id}`;
    setShowSidebar(false)
    history.push(issue.issue_code ? oneIssueRoute : multyIssueRoute);
  }

  const onScroll = async ({ currentTarget }) => {
    const { scrollTop, offsetHeight, scrollHeight } = currentTarget;
    if (scrollTop + offsetHeight >= scrollHeight - 20) {
      if (!hasNextPage) return;
      fetchNextPage();
    }
  };

  return (
    <LoadingContent errorMessage={error} showErrorButton={false}>
      <div className='notification-body-list-container' onScroll={onScroll}>
        {!goingToIssues && elements?.map((issue, index) => (
          <Card className="issues-notification-card" key={index}>
            <div className='issues-icon-container'>
              <i className={`bx ${getCardIcon(issue.notification_type_name)}`} />
            </div>
            <div className='issues-body'>
              <h1>{getIssueCardTitle(issue)}</h1>
              <div className='issues-list-container'>
                {renderIssueCode(issue)}
              </div>
              <div className='issues-card-footer'>
                <p>on {getFormattedDate(issue.created_at)}</p>
                <p onClick={() => handleGoToNotification(issue)}>
                  Go to issues
                </p>
              </div>
            </div>
          </Card>
        ))}
        {totalElements === 0 &&
          <Card className="issues-notification-card">
            <p>No notifications to show</p>
          </Card>
        }
        {notificationLoading || goingToIssues &&
          <Spinner className="custom-spinner" animation="border" variant="success" />
        }
      </div>
    </LoadingContent>
  );
};