export const infiniteDropdownLimit = 20;
export const infiniteTableLimit = 20;

export const customTableEvents = {
  CHANGE_SELECT_ALL: 'CHANGE_SELECT_ALL',
  SELECT_ELEMENT: 'SELECT_ELEMENT',
  UNSELECT_ELEMENT: 'UNSELECT_ELEMENT',
};

export const rollUpWidgetsIds = {
  STATUS: 'STATUS',
  CRITICALITY: 'CRITICALITY',
};

export const assessmentHandlerTypes = {
  FUNCTION: 'FUNCTION',
  REDIRECT: 'REDIRECT',
  NONE: 'NONE',
};

export const currencyMap = {
  usd: '$',
};

export const viewEntries = {
  MYCYSO: 'MYCYSO',
  HOME: 'HOME',
};

export const scanStatusMap = {
  PREMIUM: 'PREMIUM',
  NOT_REQUESTED_SCAN: 'NOT_REQUESTED_SCAN',
  FIRST_SCAN_IN_PROGRESS: 'FIRST_SCAN_IN_PROGRESS',
  AUTHORIZED_WITH_SCAN_IN_PROGRESS: 'AUTHORIZED_WITH_SCAN_IN_PROGRESS',
  AUTHORIZED_WITH_NO_SCAN_IN_PROGRESS: 'AUTHORIZED_WITH_NO_SCAN_IN_PROGRESS',
};

export const issuesStickyDivsIds = [
  'issue-manager-rollup',
  'issue-manager-active-filters',
  'issue-manager-toolbar',
  'issue-table-bulk-menu',
  'issue-table-headers',
];

export const commonTableStickyDivsIds = [
  'common-table-rollup',
  'common-table-middle-section',
  'common-table-toolbar',
  'common-table-bulk-menu',
  'common-table-headers',
];

export const devicesTableStickyDivsIds = [
  'devices-table-middle-section',
  'devices-table-toolbar',
  'devices-table-bulk-menu',
  'devices-table-headers',
];

export const redirectPromptActionTypes = {
  TAKE_ASSESSMENT: 'TAKE_ASSESSMENT',
  REQUEST_SCAN: 'REQUEST_SCAN',
};

export const grcAttachmentsExtensions = [
  '.doc',
  '.docx',
  '.odt',
  '.txt',
  '.pdf',
  '.html',
  '.ppt',
  '.pptx',
  '.xls',
  '.xlsx',
  '.csv',
  '.jpeg',
  '.jpg',
  '.png',
  '.zip',
  '.rar'
];

//  Change in this object should be applied to the same object on API
export const extensionsTypeMap = new Map([
  ['.doc', ['application/msword']],
  ['.docx', ['application/vnd.openxmlformats-officedocument.wordprocessingml.document']],
  ['.odt', ['application/vnd.oasis.opendocument.text']],
  ['.txt', ['text/plain']],
  ['.pdf', ['application/pdf']],
  ['.html', ['text/html']],
  ['.ppt', ['application/vnd.ms-powerpoint']],
  ['.pptx', ['application/vnd.openxmlformats-officedocument.presentationml.presentation']],
  ['.xls', ['application/vnd.ms-excel']],
  ['.xlsx', ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']],
  ['.csv', ['text/csv']],
  ['.jpeg', ['image/jpeg']],
  ['.jpg', ['image/jpeg']],
  ['.png', ['image/png']],
  ['.zip', ['application/zip', 'application/octet-stream', 'application/x-zip-compressed', 'multipart/x-zip']],
  ['.rar', ['application/vnd.rar']],
]);

export const extensionClassNameMap = new Map([
  ['.doc', 'bx bxs-file-doc'],
  ['.docx', 'bx bxs-file-doc'],
  ['.odt', 'bx bxs-file-doc'],
  ['.txt', 'bx bxs-file-txt'],
  ['.pdf', 'bx bxs-file-pdf'],
  ['.html', 'bx bxs-file-html'],
  ['.ppt', 'bx bxs-slideshow'],
  ['.pptx', 'bx bxs-slideshow'],
  ['.xls', 'bx bxs-spreadsheet'],
  ['.xlsx', 'bx bxs-spreadsheet'],
  ['.csv', 'bx bxs-spreadsheet'],
  ['.zip', 'bx bxs-folder'],
  ['.rar', 'bx bxs-folder'],
]);

export const attachmentsMaxAmount = 5;
export const attachmentsMaxSize = 25; // Size in MB

export const solutionStatusMap = {
  ACTIVE: 1,
  FAILED: 0,
};

export const expectedSolutionStatus = 3;

export const tablePaginationTypes = {
  INFINITE_SCROLLING: 1,
  PAGES: 2,
};

export const recycleBinEntities = {
  devices: 'devices',
  issues: 'issues',
  tasks: 'tasks',
};
export const partnerStatusConfigById = {
  0: { label: "Error", color: "critical", tooltip: "Check logs" },
  1: { label: "Running", color: "great" },
  2: { label: "Warning", color: "warning" },
  3: { label: "Not Subscribed yet…", color: "gray" },
  4: { label: "Setting up", color: "light-gray" },
  5: { label: "Waiting for scan", color: "light" },
};

export const connectionStatusConfigById = {
  0: { id: 0, label: "Error", color: "critical", tooltip: "Check logs" },
  1: { id: 1, label: "Running", color: "great" },
  2: { id: 2, label: "Running", color: "great" }, // warning
  3: { id: 3, label: "Not Subscribed yet…", color: "gray" },
  4: { id: 4, label: "Setting up", color: "light-gray" },
  5: { id: 5, label: "Setting up", color: "light-gray" }, // waiting for scan
};

export const connectionStatusFilters = [
  { label: 'Error', value: 'error' },
  { label: 'Running', value: 'running' },
  { label: 'Setting up', value: 'setting_up' },
];

export const connectionStateFilters = [
  {
    label: 'Enabled',
    value: true,
  },
  {
    label: 'Disabled',
    value: false,
  },
];

export const CONNECTION_ENABLE_ACTION = "enable";
export const CONNECTION_DISABLE_ACTION = "disable";
export const CONNECTION_FORCE_SYNC_ACTION = "sync";
export const CONNECTION_DELETE_ACTION = "delete";

export const connectionQuickActionsConfig = {
  [CONNECTION_DISABLE_ACTION]: {
    label: "Disable",
    bulkMessage: 'Disable Solutions',
    iconClassName: "bx bx-unlink",
    actionName: CONNECTION_DISABLE_ACTION,
    actionColorClassName: 'critical',
    objectName: 'Connection',
  },
  [CONNECTION_ENABLE_ACTION]: {
    label: "Enable",
    bulkMessage: 'Enable Solutions',
    iconClassName: "bx bx-link",
    actionName: CONNECTION_ENABLE_ACTION,
    actionColorClassName: 'light',
    objectName: 'Connection',
  },
  [CONNECTION_FORCE_SYNC_ACTION]: {
    label: "Force Sync",
    bulkMessage: 'Force sync',
    iconClassName: "bx bx-loader",
    actionName: CONNECTION_FORCE_SYNC_ACTION,
    actionColorClassName: 'light',
    objectName: 'Sync',
  },
  [CONNECTION_DELETE_ACTION]: {
    label: "Delete Configuration",
    bulkMessage: 'Delete configuration',
    iconClassName: "bx bx-trash",
    actionName: CONNECTION_DELETE_ACTION,
    actionColorClassName: 'critical',
    objectName: 'Configuration',
  },
};

export const USER_TYPES = {
  CYDEKICK_TEAM: 'CYDEKICK_TEAM',
  MEMBER: 'MEMBER'
}
export const ISSUE_COMMENT_SECTION_TYPE = 'ISSUE_COMMENT_SECTION_TYPE';
export const DEFAULT_COMMENT_SECTION_TYPE = 'DEFAULT_COMMENT_SECTION_TYPE';

export const MANUAL_ACTIONS_COMPONENTS = {
  DROPDOWN: 'dropdown',
  BUTTON: 'button',
  MODAL: 'modal'
}

export const MANUAL_ACTIONS_MODAL_COMPONENTS = {
  CHECKBOXVERTICAL: 'checkboxVertical',
  CHECKBOXHORIZONTAL: 'checkboxHorizontal',
  TEXTBOX: 'textBox',
  TEXTFIELD: 'textField',
  SWITCH: 'switch',
  DATETIME: 'datetime'
}

export const MANUAL_ACTIONS_TARGETS = {
  DEVICE: "DEVICE",
  GROUP: "GROUP",
  ISSUE: "ISSUE",
  SETTINGS: "SETTINGS",
}
export const issuesFiltersInitialState = {
  severity: null,
  status: null,
  company_id: null,
  assigned_to: null,
  solution_id: null,
  tags: [],
  time_since_creation: null,
  open_only: false,
  device_types: [],
  source: [],
  //  Partner filters
  exploit_available: null,
  vpr_score_floor: null,
  vpr_score_ceil: null,
  classification: null,
};
export const issuesSortFieldInitialState = { fieldName: null, asc: true };

export const selectedIssueInitialState = {
  id: null,
  code: null,
  companyId: '',
  name: '',
  description: '',
  os_version: '',
  attachments: '',
  status: '',
  criticality: '',
  assignee: '',
  solution: '',
  updatedAt: '',
  createdAt: '',
  etl: '',
  parent: {},
  remediationPlan: '',
  remediationPlanNotes: [],
  comments: {
    list: []
  },
  subtasks: [],
  device: {},
  partner: {},
  tags: [],
  resolution_date: null,
  partner_fields: {}
};

export const issuesInitialState = {
  filters: issuesFiltersInitialState,
  activeFilters: [],
  searchTerm: '',
  sortField: issuesSortFieldInitialState,
  list: [],
  selectedIssue: selectedIssueInitialState,
  pageCount: 1,
  currentPage: 0,
  modalOptions: {
    companies: [],
    users: { cydekicks: [] },
    solutions: [],
    statuses: [],
    criticalities: []
  },
  total: 0,
  totalsBy: {
    criticality: [],
    status: []
  },
  shouldReload: false,
  manualActionFinished: false,
  issueId: null,
  reloadTagsList: false,
  shouldReloadRollup: false,
  deleteModalConfig: {
    show: false,
    issue_ids: [],
    success: false,
  },
}

export const scanStatusConfigById = {
  0: { id: 0, label: "Error", color: "critical" },
  1: { id: 1, label: "Complete", color: "great" },
  2: { id: 2, label: "Scheduled", color: "light" },
  3: { id: 3, label: "Running", color: "light-violet" },
  4: { id: 4, label: "On Demand", color: "light-gray" },
};

export const memberDomainLimit = 25;
