import {
    ASSESSMENT_SUCCESS,
    ASSESSMENT_FAILURE,
    SELECT_SECTION,
    SELECT_CATEGORY,
    SELECT_QUESTION,
    SET_CATEGORY_INDEX,
    SET_SECTION_INDEX,
    SET_QUESTION_INDEX,
    SET_CATEGORIES_IDS,
    SET_NEXT_QUESTION,
    SET_PREVIOUS_QUESTION,
    SET_FIRST_QUESTION,
    RESET_ASSESSMENT_NAVIGATION,
    EDIT_COMPLIANCE_LIST_SUCCESS,
    ASSESSMENT_REQUEST,
} from './types';
import { successActionCreator } from './action-creators/success'
import { setAlert } from './alerts';
import errorMessages from '../constants/errorMessages';
import { invalidateQueriesByKeys } from '../utils/queryHelpers';

export const fetchAssessment = (type = null) => {
    return async (dispatch, getState, { API, queryClient }) => {
        try {
            dispatch({ type: ASSESSMENT_REQUEST });
            const { AssessmentAPI } = API;
            const response = await AssessmentAPI.handlers.fetchAssessment(type);
            dispatch(assessmentSuccess(response?.data));
        } catch (error) {
            dispatch(assessmentError(error));
            console.log(error);
        }
    };
};

export const assessmentSuccess = (assessment) => {
    return {
        type: ASSESSMENT_SUCCESS,
        payload: assessment
    }
}

export const assessmentError = (message) => {
    return {
        type: ASSESSMENT_FAILURE,
        payload: message
    }
}

export const selectCategory = (id) => {
    return {
        type: SELECT_CATEGORY,
        payload: id
    }
}

export const selectSection = (id) => {
    return {
        type: SELECT_SECTION,
        payload: id
    }
}

export const selectQuestion = (id) => {
    return {
        type: SELECT_QUESTION,
        payload: id
    }
}


export const setCategoryIndex = (index) => {
    return {
        type: SET_CATEGORY_INDEX,
        payload: index
    }
}

export const setSectionIndex = (index) => {
    return {
        type: SET_SECTION_INDEX,
        payload: index
    }
}

export const setQuestionIndex = (index) => {
    return {
        type: SET_QUESTION_INDEX,
        payload: index
    }
}

export const setCategoriesIds = (categoriesIds) => {
    return {
        type: SET_CATEGORIES_IDS,
        payload: categoriesIds
    }
}

export const setNextQuestion = () => {
    return {
        type: SET_NEXT_QUESTION
    }
}

export const setPreviousQuestion = () => {
    return {
        type: SET_PREVIOUS_QUESTION
    }
}

export const setFirstQuestion = () => {
    return {
        type: SET_FIRST_QUESTION
    }
}

export const resetAssessmentNavigation = () => {
    return {
        type: RESET_ASSESSMENT_NAVIGATION
    }
}

export const editComplianceList = (complianceId, add = true, companyId = null) => {
    return async (dispatch, getState, { API, queryClient }) => {
        try {
            const { AssessmentAPI } = API;
            // Type variable does not exist
            const response = await AssessmentAPI.handlers.editComplianceList(null);
            invalidateQueriesByKeys(AssessmentAPI.invalidators.editComplianceList, queryClient);
            dispatch(successActionCreator(EDIT_COMPLIANCE_LIST_SUCCESS, response?.data));
        } catch (error) {
            dispatch(setAlert(error.response.data.message || errorMessages.defaultPost))
            console.log(error);
        }
    };
};