import cyvatarAxios from '../axios';
import {
  GET_USER_INVITE,
  GET_ISSUES_ASSIGNEES,
  FETCH_CYDEKICKS,
  GET_GRC_ISSUES_LIST,
  GET_GRC_ISSUE,
  GET_ISSUE,
  GET_ISSUES_LIST,
  VALIDATE_VERIFICATION_TOKEN,
  GET_IN_APP_NOTIFICATION_SETTINGS,
  GET_COMPANY_USERS,
} from '../ApiKeys';

//  Requests
const postReferUser = async (formValues) => {
  const body = JSON.stringify(formValues);
  const apiResponse = await cyvatarAxios.post('api/user/refer_user', body);
  const { data } = apiResponse;
  return data;
};

const postCancelUserAccount = () => {
  return cyvatarAxios.post('api/user/cancel-account');
};

const getUserInvite = (params) => {
  return cyvatarAxios.get('/api/user/invite', { params });
};

const getIssuesAssignees = async (params) => {
  const response = await cyvatarAxios.get('/api/user/issues-assignees', { params });
  const { data } = response;
  return data;
};

const fetchCydekicks = (config) => {
  return cyvatarAxios.get('/api/user/cydekick', { params: config });
};

const createCydekickInvite = async (formValues) => {
  const email = formValues.email;
  delete formValues.email;
  const body = JSON.stringify(formValues);
  const response = await cyvatarAxios.post(`/api/user/invite/${email}`, body);
  return response?.data;
};

const inviteTeamMembers = async (payload) => {
  const response = await cyvatarAxios.post('/api/user/invite_members', payload);
  return response?.data;
};

const acceptMemberInvite = async (payload) => {
  const response = await cyvatarAxios.post('/api/user/accept_member_invite', payload);
  return response?.data;
}

const changeMemberRole = async (payload) => {
  const response = await cyvatarAxios.put('/api/user/change_role', payload);
  return response?.data;
};

const disableTeamMember = async (payload) => {
  const response = await cyvatarAxios.put('/api/user/disable_team_member', payload);
  return response?.data;
};

const removeTeamMember = async (payload) => {
  const response = await cyvatarAxios.put('/api/user/remove_team_member', payload);
  return response?.data;
};

//  Impersonation
const impersonateCompany = async (company_id) => {
  const response = await cyvatarAxios.post('/api/user/impersonate', { company_id });
  return response?.data;
};

const validateVerificationToken = async (token) => {
  const response = await cyvatarAxios.get('/api/user/verification-token', { params: { token } })
  const { data } = response;
  return data;
};

//  Params = { token, password }
const verifyOrAuthorizeAccount = async (params) => {
  const response = await cyvatarAxios.post('/api/user/verify-account', params);
  const { data } = response;
  return data;
};

const sendDashboardFeedback = async (payload) => {
  const apiResponse = await cyvatarAxios.post(`/api/user/dashboard-feedback/`, payload);
  const { data } = apiResponse;
  return data;
}

const putInAppNotificationSettings = async (payload) => {
  const response = await cyvatarAxios.put('/api/user/notification-settings', payload);
  return response?.data;
};

const getInAppNotificationSettings = async (params) => {
  const response = await cyvatarAxios.get('/api/user/notification-settings',{params});
  return response?.data;
};

const executeUserAction = async ( params ) => {
  const response = await cyvatarAxios.patch(`/api/user/action`, params);
  const { data } = response;
  return data;
};

const setExternalUserPassword = async ({token, password}) => {
  const response = await cyvatarAxios.post(`api/user/password/${token}/set`,{password});
  return response?.data;
};

//  Building API object
export const UserAPI = {
  handlers: {
    postReferUser,
    postCancelUserAccount,
    getUserInvite,
    getIssuesAssignees,
    fetchCydekicks,
    createCydekickInvite,
    inviteTeamMembers,
    acceptMemberInvite,
    impersonateCompany,
    changeMemberRole,
    disableTeamMember,
    removeTeamMember,
    validateVerificationToken,
    verifyOrAuthorizeAccount,
    sendDashboardFeedback,
    putInAppNotificationSettings,
    getInAppNotificationSettings,
    executeUserAction,
    setExternalUserPassword,
  },
  keys: {
    getUserInvite: GET_USER_INVITE,
    getIssuesAssignees: GET_ISSUES_ASSIGNEES,
    fetchCydekicks: FETCH_CYDEKICKS,
    validateVerificationToken: VALIDATE_VERIFICATION_TOKEN,
    getInAppNotificationSettings: GET_IN_APP_NOTIFICATION_SETTINGS,
  },
  invalidators: {
    postReferUser: null,
    postCancelUserAccount: null,
    createCydekickInvite: null,
    inviteTeamMembers: null,
    acceptMemberInvite: null,
    impersonateCompany: null,
    changeMemberRole: [FETCH_CYDEKICKS, GET_ISSUES_ASSIGNEES, GET_GRC_ISSUES_LIST, GET_GRC_ISSUE, GET_ISSUE, GET_ISSUES_LIST],
    disableTeamMember: [FETCH_CYDEKICKS, GET_ISSUES_ASSIGNEES, GET_GRC_ISSUES_LIST, GET_GRC_ISSUE, GET_ISSUE, GET_ISSUES_LIST],
    removeTeamMember: [FETCH_CYDEKICKS, GET_ISSUES_ASSIGNEES, GET_GRC_ISSUES_LIST, GET_GRC_ISSUE, GET_ISSUE, GET_ISSUES_LIST],
    verifyOrAuthorizeAccount: null,
    putInAppNotificationSettings: [GET_IN_APP_NOTIFICATION_SETTINGS],
    executeUserAction: [GET_COMPANY_USERS],
    setExternalUserPassword: null,
  },
};
