export const riskTreatmentIcons = {
    ACCEPT: 'bx-check-circle',
    TRANSFER: 'bx-transfer-alt',
    MITIGATE: 'bx-block',
    AVOID: 'bx-run'
}

//Content for tooltips that have the same initial and resulting position
export const riskTreatmentBubbleTooltips = {
    ACCEPT: 'This is your accepted Risk',
    TRANSFER: 'You transferred this Risk',
    MITIGATE: "We're doing great here",
    AVOID: 'Avoided Risk'
}

export const INITIAL_RISK_ID = "INITIAL_RISK_ID";