import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import useCustomQuery from '../../../../hooks/useCustomQuery';
import { getApiErrorMessage } from '../../../../utils/helpers';
import TrendChart from './TrendChart';
import companyIdSelector from '../../../../selectors/companyIdSelector';
import useTimeFilters from '../../../../hooks/useTimeFilters';

const TrendChartWrapper = (props) => {
    const {
        title = '',
        objects = 'issues',
        emptyTitle = '',
        emptySubtitle = '',
        isSummary = false, // Flag to know if is the simple version of the chart (useful to hide some components)
        pointsColorConfig = {}, // Color used on graph points
        labelColorConfig = {}, // Color used on tooltips
        pointsCountConfig = {},
        apiKey,
        apiFunction, // Must recieve a filter object
        useLocalTimeFilter = true,
    } = props;

    //  Watching redux store
    const company_id = useSelector(state => companyIdSelector(state));
    const solution_id = useSelector((state) => state?.issues?.filters?.solution_id);

    // Component state
    const { timeFilters, mutateTimeFilters } = useTimeFilters(useLocalTimeFilter, pointsCountConfig);

    //  Calling API for data
    const {
        data: trendData,
        error: trendError,
        isLoading: trendLoading,
        refetch: refetchTrend,
    } = useCustomQuery(
        [apiKey, { ...timeFilters, company_id, solution_id }],
        ({ queryKey }) => apiFunction(queryKey[1]),
    );


    return (
        <TrendChart
            company_id={company_id}
            solution_id={solution_id}
            isSummary={isSummary}
            isLoading={trendLoading}
            errorMessage={getApiErrorMessage(trendError)}
            retryRequest={refetchTrend}
            filters={timeFilters}
            setFilters={mutateTimeFilters}
            trendData={trendData?.points || []}
            isEmpty={trendData?.is_empty_trend || false}
            totalPointsConfig={pointsCountConfig}
            chartTitle={title}
            objects={objects}
            pointsColorConfig={pointsColorConfig}
            labelColorConfig={labelColorConfig}
            emptyTitle={emptyTitle}
            emptySubtitle={emptySubtitle}
            useLocalTimeFilter={useLocalTimeFilter}
        />
    )
}

export default TrendChartWrapper;
