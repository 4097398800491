import React, { useState } from 'react';
import { Button } from 'reactstrap';
import useUpdateIssue from '../../hooks/useUpdateIssue';
import { isEmptyText } from '../../utils/helpers';

const IssueTextField = (props) => {
  const {
    text,
    defaultText,
    label,
    isEditable,
    showLabel = true,
    textClassName,
    fieldName,
    rows = 1,
    maxLength,
    textAreaClassName = '',
    isRequired,
    issueCode,
  } = props;

  // Component state
  const [editMode, setEditMode] = useState(false);
  const [textValue, setTextValue] = useState(text || '');
  const [errorMessage, setErrorMessage] = useState('');

  const updateIssueSuccessCallback = () => {
    setEditMode(!editMode);
    setErrorMessage('')
  }
  // Component Hooks
  const { updateIssueOnDB, updateIssueLoading, } = useUpdateIssue({ successCallback: updateIssueSuccessCallback });


  // On save click
  const onSaveClick = () => {
    const updateObject = {};
    if(isRequired && isEmptyText(textValue)) {
      return;
    }
    updateObject[fieldName] = textValue;
    updateIssueOnDB(issueCode, updateObject, null, `Issue ${fieldName} updated`);
  }

  const onCancelClick = () => {
    setEditMode(!editMode)
    setTextValue(text);
    setErrorMessage('')
  }

  // update text
  const onTextChange = (value) => {
    setTextValue(value);
    if(isRequired && isEmptyText(value)) {
      setErrorMessage('This field is required');
    } else {
      setErrorMessage('')
    }
  }

  // Render text field
  const renderTextField = () => {
    if(!editMode) return null;
    const errorClassName = errorMessage ? 'text-area-error' : '';
    const textClassName = `issue-text-field-text-area form-control text-area-grow ${textAreaClassName} ${errorClassName}`;

    return (
      <div className='w-100'>
        <div className='issue-text-area-container'>
          {errorMessage && <i className='text-field-error-icon bx bx-error'/>}
          <textarea
            name={fieldName}
            rows={rows}
            className={textClassName}
            disabled={false}
            maxLength={maxLength}
            value={textValue}
            onChange={({ target }) => onTextChange(target?.value)}
            wrap="hard"
          />
        </div>
        {errorMessage && (
          <div className='d-flex issue-text-field-error'>
            {errorMessage}
          </div>
        )}
      </div>
    )
  }
  // Render edit button
  const renderEditButton = () => {
    if(!isEditable || editMode) return null;
    const buttonClassName = `btn-issue-text-field btn-small ml-auto`;
    return (
      <Button
        outline
        color="light-gray"
        className={buttonClassName}
        onClick={() => setEditMode(!editMode)}
      >
        <i className='bx bx-pencil mr-2'/>
        Edit
      </Button>
    )
  }

  const renderEditModeButtons = () => {
    if(!editMode) return null;
    return (
      <div>
        <div className="d-inline-flex ml-auto">
        <Button
          outline
          color="light-gray"
          className="btn-issue-text-field btn-small ml-3"
          onClick={onCancelClick}
          disabled={updateIssueLoading}
        >
          Cancel
        </Button>
        <Button
          color="light"
          className="btn-issue-text-field btn-small ml-2"
          onClick={onSaveClick}
          disabled={updateIssueLoading}
        >
          Save
        </Button>
      </div>

      </div>

    )
  }
  return (
    <>
      {showLabel && (
        <div className="issue-information-title">
          {label}
          {renderEditButton()}
          {renderEditModeButtons()}
        </div>
      )}
      <div className={`d-inline-flex ${textClassName}`}>
        {editMode ? renderTextField() : text || defaultText}
        {!showLabel && (
          <>
            {renderEditButton()}
            {renderEditModeButtons()}
          </>
        )}
      </div>
    </>
  )
}

export default IssueTextField;
