import _ from 'lodash';
import { createSelector } from 'reselect';

const getCategories = state => state.assessment.assessment.categories;

const makeTotalQuestionsNumber = () => {
    return createSelector( getCategories,
        (categories) => {
            if(!categories) {
                return {
                    categories: null,
                    total: {
                        sections: 0,
                        questions: 0
                    } 
                };
            }
            const questionsByCategory = categories.map( category => {
                let categoryQuestionsNumber = 0;
                let categorySectionsNumber = 0;
                category.sections.forEach( section => {
                    categorySectionsNumber++;
                    section.questions.forEach( () =>{
                        categoryQuestionsNumber++;
                    })
                });
                return {
                    questions: categoryQuestionsNumber,
                    order: category.order,
                    sections: categorySectionsNumber
                }
            });
    
            return {
                categories: _.mapKeys(questionsByCategory, 'order'),
                total: {
                    sections: _.sumBy(questionsByCategory,'sections'),
                    questions: _.sumBy(questionsByCategory,'questions')
                } 
            }
        }
    )
} 
export default makeTotalQuestionsNumber;
  

