import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import FormCard from '../layout/FormCard';
import CodeInput from '../../components/settings/CodeInput';
import Cookies from "universal-cookie";

// availity-reactstrap-validation
import { AvForm } from 'availity-reactstrap-validation';
import { validateMFALogin, requestMFACode } from '../../actions/auth';

const MFACard = (props) => {
  const {
    mfaConfig,
    validateMFALogin,
    requestMFACode,
    error,
    history,
    location,
  } = props;

  const [code, setCode] = useState("");
  const [showReminder, setShowReminder] = useState(true);
  const [updateCookie, setUpdateCookie] = useState(false);

  useEffect(() => {
    const cookies = new Cookies();
    const oldDate = new Date(cookies.get(mfaConfig.email) || null);
    const newDate = new Date();
    setShowReminder(newDate >= oldDate)
  }, [])

  function handleValidSubmit(event, values) {
    const formValues = {
      code: code,
      mfa_token: mfaConfig.token,
      remind_device: updateCookie,
      email: mfaConfig.email
    }
    validateMFALogin(formValues, history, location);
  }

  function handleCodeChange(newCode) {
    setCode(newCode);
  }

  function handleReSendCode() {
    const formValues = {
      mfa_token: mfaConfig?.token
    }
    requestMFACode(formValues, history, location)
  }

  return (
    <FormCard
      title="Verify Login"
      error={error}
      customClassName="mfa-verification-card"
    >
      <div>
        <AvForm className="form-horizontal" onValidSubmit={(e, v) => { handleValidSubmit(e, v) }}>
          <div className="description">
            <p>{`A text message with a 6-digit verification code was sent to ${mfaConfig?.mfa_phone || "(***) ******"}`}</p>
          </div>

          <div className="code-input">
            <p className="code-input-label">Enter the six-digit code sent to your phone</p>
            <CodeInput callback={handleCodeChange} />
          </div>

          <div className="re-send-code">
            <Link
              onClick={handleReSendCode}
              onKeyUp={handleReSendCode}
            >
              Re-send the code
            </Link>
          </div>
          {showReminder && 
            <div className="custom-control custom-checkbox mb-3" onClick={() => { setUpdateCookie(!updateCookie) }}>
              <input type="checkbox" className="custom-control-input" id="updateCookie" checked={updateCookie} />
              <label className="custom-control-label cookie-reminder">Don't ask me again for 30 days on this device.</label>
            </div>
          }
          <div style={{ marginBottom: "15px", marginTop: "30px" }} >
            <button
              className="btn btn-light text-dark btn-block"
              type="submit"
              disabled={code.length !== 6}
            >
              Login
            </button>
          </div>
        </AvForm>
      </div>
    </FormCard>
  );
}

const mapStatetoProps = state => {
  const { error, isAuthenticated, mfaConfig } = state.auth;
  return { error, isAuthenticated, mfaConfig };
}

export default withRouter(connect(mapStatetoProps, { validateMFALogin, requestMFACode })(MFACard));

