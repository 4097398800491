import React, { useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import ScheduleModal from './ScheduleModal';
import { mapScheduleToString } from '../../../utils/automationHelper';

const RuleSetScheduleSummary = (props) => {
  //  Parsing props
  const {
    schedule,
    repeat,
    ruleSetIndex,
    actionIndex,
    run_now = false,
  } = props;

  //  Component state
  const [showModal, setShowModal] = useState(false);

  //  Verifying component state
  const isNullSchedule = useMemo(() => {
    return (schedule === null && repeat === null);
  }, [schedule, repeat]);

  //  Function to trigger schedule modal
  const openScheduleModal = () => {
    setShowModal(true);
  };

  //  Function to close moda
  const closeModal = () => {
    setShowModal(false);
  }

  //  Rendering empty state
  const renderEmptyStateButton = () => {
    if (!isNullSchedule) { return null; }
    return (
      <Button
        className="schedule-button"
        onClick={() => openScheduleModal()}
      >
        Set timeframe
      </Button>
    );
  };

  //  Render schedule summary
  const renderScheduleSummary = () => {
    if (isNullSchedule) { return null; }
    return (
      <div
        className="schedule-label"
        onClick={() => openScheduleModal()}
      >
        {mapScheduleToString(schedule, repeat)}
      </div>
    );
  };

  //  Function to render schedule modal
  const renderScheduleModal = () => {
    if (!showModal) { return null; }
    return (
      <ScheduleModal
        ruleSetIndex={ruleSetIndex}
        actionIndex={actionIndex}
        schedule={schedule}
        repeat={repeat}
        run_now={run_now}
        closeModal={closeModal}
      />
    );
  };

  //  Rendering
  return (
    <div className="schedule-section">
      {isNullSchedule ? renderEmptyStateButton() : renderScheduleSummary()}
      {renderScheduleModal()}
    </div>
  );
};

export default RuleSetScheduleSummary;
