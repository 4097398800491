import { useEffect, useState } from "react";

const useDebounceValue = (params) => {
  //  Parsing params
  const {
    valueToDebounce,
    defaultValue,
    debounceTime = 0, // Value in ms
    callback = () => { }, //  Function to handle extra actions
  } = params;

  //  Hook state
  const [debouncedValue, setDebouncedValue] = useState(defaultValue);

  //  Creatting time out for debouncing
  useEffect(() => {
    const timeOutID = setTimeout(() => {
      setDebouncedValue(valueToDebounce);
    }, debounceTime);
    return () => clearTimeout(timeOutID);
  }, [valueToDebounce]);

  return { debouncedValue };
};

export default useDebounceValue;