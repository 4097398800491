import React, { useEffect } from "react";
import { setAlert } from "../../actions/alerts";
import { alertTypes } from "../../constants/alerts";
import errorMessages from "../../constants/errorMessages";
import { MODIFY_ACTION, PRIVILEGE_SOURCES } from "../../constants/privileges";
import useCanAbility from "../../hooks/useCanAbility";
import useCustomMutation from "../../hooks/useCustomMutation";
import useMemoizedDispatch from "../../hooks/useMemoizedDispatch";
import CustomToggleSwitch from "../layout/CustomToggleSwitch";
import API from "../../api";

const PartnerConnectionStatus = (props) => {
  const { connectionId, isActive } = props;

  const { PartnerAPI } = API;

  // Component Hooks
  const { dispatch } = useMemoizedDispatch();
  const {
    data: executeQuickActionResponse,
    error: executeQuickActionError,
    mutate: executeQuickAction,
    isLoading: executeQuickActionLoading,
  } = useCustomMutation(
    (params) => PartnerAPI.handlers.executeQuickAction(params),
    PartnerAPI.invalidators.executeQuickAction
  );
  const ability = useCanAbility();

  // Component variables
  const canModifyConnections = ability.can(
    MODIFY_ACTION,
    PRIVILEGE_SOURCES.COMPANY_SOLUTIONS.GENERAL
  );

  // Watching error to set alert
  useEffect(() => {
    if (!executeQuickActionError) return;
    // TODO: update when error messages are updated
    const errorMessage = errorMessages.defaultPost;
    dispatch(setAlert(errorMessage, alertTypes.error));
  }, [executeQuickActionError]);

  // Watching quick action response
  useEffect(() => {
    if (!executeQuickActionResponse) {
      return;
    }
    const actionName = isActive ? 'disabled' : 'enabled'
    dispatch(
      setAlert(`Connection ${actionName || "updated"}`, alertTypes.success)
    );
  }, [executeQuickActionResponse]);

  const enablePartner = () => {
    const body = {
      connections: [connectionId],
      action: isActive ? 'disable' : 'enable',
    }
    executeQuickAction(body);
  };

  return (
    <CustomToggleSwitch
      containerClassName="switch-container small-switch"
      spanClassName="connection-slider"
      checkedLabelClassName="checked-toggle-cell"
      unCheckedLabelClassName="unchecked-toggle-cell"
      includeLabel={false}
      checked={isActive}
      setChecked={enablePartner}
      disabled={!canModifyConnections}
    />
  );
};

export default PartnerConnectionStatus;
