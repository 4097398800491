import React from "react";
import { Spinner } from "reactstrap";

const ConnectionSuccesMessage = (props) => {
  const { toggleModal } = props;
  return (
    <div className="connection-success-mesage success-message">
    <div className="title mx-auto"> Success!</div>
    <div className="message mx-auto">Setting up might take a few minutes</div>
    <div className="text-center mt-4">
      <Spinner className="success-message-spinner" />
    </div>
    <div className="btn-container text-center">
      <button
        className="btn btn-light text-dark"
        onClick={() => toggleModal()}
        style={{ minWidth: "115px" }}
      >
        OK
      </button>
    </div>
  </div>
  );
};

export default ConnectionSuccesMessage;
