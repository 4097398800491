import React from 'react';

const ClickableLabel = (props) => {
  //  Parsing props
  const {
    containerClassName = '',
    clickableClassname = '',
    normalTextClassName = '',
    normalText = '',
    clickableText = '',
    onClickCallback,
  } = props;

  //  Function to handle click
  const handleClick = () => {
    if (onClickCallback && typeof (onClickCallback) === 'function') {
      onClickCallback();
    }
  };

  //  Rendering
  return (
    <div className={containerClassName}>
      <div className={normalTextClassName}>{normalText}</div>
      <div
        className={clickableClassname}
        onClick={handleClick}
      >
        {clickableText}
      </div>
    </div>
  );
};

export default ClickableLabel;
