import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Button } from 'reactstrap';
import LoadingContent from '../layout/LoadingContent';
import assessmentIcon from '../../assets/images/icons/assessment-placeholder.svg';
import ComplianceLinks from '../dashboard/compliance/ComplianceLinks';
import InfoTooltip from '../dashboard/tooltips/InfoTooltip';
import { getAssessmentFeedbackMessage } from '../../utils/assessmentHelpers';
import { trackEvent } from '../../utils/gaTracker';
import { createExecutionAnswers } from '../../actions/answers';
import _ from 'lodash';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import GradientProgressBar from '../layout/GradientProgressBar';
import ComplianceScoreCard from '../ReportCard/ComplianceScoreCard';
import amplitude from 'amplitude-js';


const AssessmentStatusCard = (props) => {

    const {
        isLoading,
        errorMessage,
        assessmentData,
        retryRequest,
        togGradingScale,
    } = props;

    // Component state
    const [progress, setProgress] = useState(0);

    // Component Hooks
    const history = useHistory();
    const { dispatch } = useMemoizedDispatch();

    // Watching answers count and question count changes
    useEffect(() => {
        if (!assessmentData) return;
        const { cis_answers_count, cis_question_count } = assessmentData;
        const assessmentProgress = Math.floor((cis_answers_count / cis_question_count) * 100);
        setProgress(assessmentProgress);
    }, [assessmentData?.cis_question_count, assessmentData?.cis_answers_count])

    // Render Assessment Link
    const renderAssessmentLink = (label, className, hasProgress) => {
        const eventName = hasProgress ? 'continue_assessment' : 'start_assessment';
        const sectionEvent = "assessment";
        return (
            <div className="d-flex mt-auto">
                <Button
                    color="light"
                    className={`btn-home-link mx-auto ${className || ''}`}
                    onClick={() => {
                        if (!_.isNil(assessmentData?.cyber_checkup_execution_id)) {
                            trackEvent(eventName, sectionEvent, eventName);
                            history.push("/dashboard/assessment");
                        } else {
                            trackEvent(eventName, sectionEvent, eventName);
                            // TODO: Check if this is still needed
                            dispatch(createExecutionAnswers(assessmentData?.cis_assessment_id, history));
                        }
                    }}
                >
                    {label}
                </Button>
            </div>
        )
    }

    const renderAssessmentIcon = () => {
        return (
            <div className="d-flex">
                <img
                    src={assessmentIcon}
                    alt="assessment icon"
                    className={`assessment-icon img-fluid mx-auto mb-3`}
                />
            </div>
        )
    }

    const renderAssessmentInfoMessage = () => {
        return (
            <div className="assessment-info-message mx-auto text-center">
                This assessment will help you to understand your Cybersecurity posture.
            </div>
        )
    }

    const renderProgressBar = () => {
        return (
            <div className="assessment-status-progress-bar">
                <div className="progress-message text-center">
                    This assessment will guide your cybersecurity strategy with your business goals.
                </div>
                <div className="progress-container d-flex align-items-center">
                    <div className="progress-bar-container d-inline-block">
                        <GradientProgressBar progress={progress} />
                    </div>
                    <div className="progress-fraction d-inline-block">{`${assessmentData?.cis_answers_count}/${assessmentData?.cis_question_count} answered`}</div>
                </div>
                <div className="progress-feedback-message">
                    {getAssessmentFeedbackMessage(assessmentData?.cis_answers_count, assessmentData?.cis_question_count, false, false)}
                </div>
            </div>
        )
    }

    // Render in progress state
    const renderInProgressState = () => {
        const hasProgress = progress !== 0;
        const linkLabel = hasProgress ? "Continue my assessment" : "Start your assessment"
        const linkClassName = hasProgress ? "continue-link" : "start-link";
        return (
            <div className='my-auto'>
                {renderAssessmentIcon()}
                {hasProgress ? renderProgressBar() : renderAssessmentInfoMessage()}
                {renderAssessmentLink(linkLabel, linkClassName, hasProgress)}
            </div>
        )
    }

    const renderScaleButtons = () => {
        return (
            <>
                <div
                    className="scale-buttons d-inline-flex justify-content-end float-right"
                >
                    <ComplianceLinks
                        togGradingScale={togGradingScale}
                        assessmentData={assessmentData}
                    />
                </div>
            </>
        )
    }

    const getTitle = () => {
        if (progress === 100) {
            return "Cyvatar Score"
        } else if (progress === 0) {
            return "Take your Assessment"
        } else {
            return "Continue Assessment"
        }
    }

    const renderTitleTooltip = () => {
        // const content = progress !== 100 ? 'By finishing the assessment you will reveal insightful information of your company cybersecurity status' : 'The Cyvatar Score measures your current cybersecurity state and helps influence your Security Journey with Cyvatar';
        return (
            <InfoTooltip
                label={'Cyvatar Score'}
                wrapperClassName="dashboard-widget-title"
                showTooltip={false}
            />
        )

    }

    // Render card header: title and top links
    const renderHeader = () => {
        return (
            <div>
                <div className="d-inline-block">
                    {renderTitleTooltip()}
                </div>
                {progress === 100 && renderScaleButtons()}
            </div>
        )
    }

    return progress === 100 ? (
        <ComplianceScoreCard
            isLoading={isLoading}
            errorMessage={errorMessage}
            retryRequest={retryRequest}
            assessmentData={assessmentData}
            togGradingScale={togGradingScale}
            showNowAndThen={false}
            amplitudeBreakdownEvent="Home Page View - See Breakdown"
            amplitudeRetakeEvent="Home Page View - Retake Assessment"
            rightIcon={true}
            extraClassNameLinks='compliance-links-dashboard-assessment'
        />
    ) : (<>
        <Card className="assessment-status-card home-link-card align-self-lg-stretch h-100">
            <CardBody>
                {renderHeader()}
                <LoadingContent
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    errorButtonCallback={retryRequest}
                >
                    {progress !== 100 && renderInProgressState()}
                </LoadingContent>
            </CardBody>
        </Card>
    </>
    )
}

export default AssessmentStatusCard
