import React, { useState } from 'react';
import classnames from 'classnames';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

const TabView = (props) => {
  const {
    tabTitles,
    tabContent,
  } = props;

  const [activeTab, setActiveTab] = useState('0');

  function renderTabTitles() {
    return (
      <React.Fragment>
        { tabTitles?.map((tabTitle, index) => {
          return (
            <NavItem key={`${tabTitle}-${index}`}>
              <NavLink
                style={{ cursor: 'pointer' }}
                className={classnames({
                  active: activeTab === `${index}`,
                })}
                onClick={ () => {setActiveTab(`${index}`)} } 
              >
                {tabTitle}
              </NavLink>
            </NavItem>
          )
        })}
      </React.Fragment>
    );
  }

  function renderTabContent() {
    return (
      <React.Fragment>
        { tabContent?.map((tabContent, index) => {
          return (
            <TabPane key={`tab-${index}`} tabId={`${index}`}>
              {tabContent}
            </TabPane>
          )
        })}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Nav tabs className='nav-view-tabs'> 
        {renderTabTitles()} 
      </Nav>
      <TabContent activeTab={activeTab}>
        {renderTabContent()}
      </TabContent>
    </React.Fragment>
  );
}

export default TabView;