import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NavigationTextRow from '../../layout/NavigationTextRow';
import SearchableInfiniteTable from '../../CustomTable/SearchableInfiniteTable';
import { buildLogsTextRow } from '../../../utils/automationHelper';
import { onLogDetailRequested, onRuleLogRequested } from '../../../actions/automation';
import { setAlert } from '../../../actions/alerts';
import API from '../../../api'
import { ruleLogsTemplate } from '../../../constants/tableTemplates';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import moment from 'moment-timezone';
import { useHistory, useParams } from 'react-router-dom';
import useCustomQuery from '../../../hooks/useCustomQuery';

const RuleLogs = (props) => {
  //  Initializing APIs
  const { RuleAPI } = API;

  //  Watching redux store
  const selectedRuleId = useSelector((state) => state.automation.selectedRuleId);

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const history = useHistory();
  const { rule_id } = useParams();
  const {
    data: ruleDetailResponse,
    error: ruleDetailError,
  } = useCustomQuery(
    [RuleAPI.keys.getRuleDetail, selectedRuleId],
    ({ queryKey }) => RuleAPI.handlers.getRuleDetail(queryKey[1]),
  );

  //  Component state
  const [titleArray, setTitleArray] = useState([]);
  const [subtitleArray, setSubtitleArray] = useState([]);
  const [schedule, setSchedule] = useState(null);

  //  Function to move to automation rules
  const moveToAutomationRules = () => {
    history.push('/automation')
  };

  //  Component did mount
  useEffect(() => {
    const ruleLabel = `Action execution Log[${rule_id}]`;
    const newTitleArray = buildLogsTextRow(['Automation Rules', ruleLabel], [moveToAutomationRules]);
    setTitleArray(newTitleArray);
  }, []);

  //  Watching api response
  useEffect(() => {
    if (!ruleDetailResponse) { return; }
    const { rule: ruleSummary } = ruleDetailResponse;
    if (!ruleSummary) { return; }
    const {
      rule_name,
      action_name,
      partner_name,
      rule_schedule,
    } = ruleSummary;
    const newSubtitleArray = buildLogsTextRow([rule_name, partner_name, action_name]);
    setSubtitleArray(newSubtitleArray);
    //  TODO: change endpoint to retrieve rule set repeat
    setSchedule(rule_schedule ? moment(rule_schedule).format("dddd, MMMM Do YYYY, h:mm:ss a") : rule_schedule);
  }, [ruleDetailResponse]);

  //  Wacthing api error
  useEffect(() => {
    if (!ruleDetailError) { return; }
    dispatch(setAlert('Error getting rule summary', 'danger'));
    moveToAutomationRules();
  }, [ruleDetailError]);

  //Watching rule_id changes
  useEffect(() => {
    if (rule_id !== selectedRuleId) {
      dispatch(onRuleLogRequested(rule_id));
    }
  }, [rule_id])

  //  Function to get rule logs
  const getRuleLogs = async (filters) => {
    const ruleId = selectedRuleId || rule_id;
    if (!ruleId) { throw new Error('Not selected rule found'); }
    return await RuleAPI.handlers.getRuleLogs(ruleId, filters);
  };

  //  Function to handle row clicked
  const handleRowClicked = (ruleLog) => {
    const titles = titleArray.map(({ label }) => label);
    const subtitles = subtitleArray.map(({ label }) => label);
    dispatch(onLogDetailRequested(ruleLog, titles, subtitles));
    history.push(`/automation/rule/${rule_id}/logs/${ruleLog.id}`)
  };

  //  Function to render schedule label
  const renderSchedule = () => {
    if (!schedule) { return null; }
    return (
      <div className="schedule-label-container">
        <div className="bx bx-time schedule-icon" />
        <div className="schedule-label">
          Scheduled for: {schedule}
        </div>
      </div>
    );
  };

  //  Function to render table title
  //  TODO: render exec information when needed
  const renderTableTitle = (totalLogGroups) => {
    return (
      <div className="last-exec-container">
        <div className="bx bx-rocket last-exec-icon" />
        <div className="last-exec-label">
          {`${totalLogGroups} log groups`}
        </div>
      </div>
    );
  };

  //  Function to render the header
  const renderHeader = () => {
    return (
      <div className="rule-logs-header">
        <NavigationTextRow
          elements={titleArray}
          delimiter=">"
          rowExtraClassName="title-row"
        />
        <NavigationTextRow
          elements={subtitleArray}
          delimiter=":"
          rowExtraClassName="subtitle-row"
        />
        {renderSchedule()}
      </div>
    );
  };

  //  Rendering
  return (
    <div className="rule-logs-container">
      {renderHeader()}
      <SearchableInfiniteTable
        tableContainerClassName="rule-logs-table-container"
        tableClassName="rule-logs-table"
        headerClassName="rule-logs-table-header"
        rowClassName="rule-logs-table-row"
        searchbarClassName="rule-logs-search-bar"
        columnsTemplate={ruleLogsTemplate}
        apiFunction={getRuleLogs}
        apiKey={RuleAPI.keys.getRuleLogs}
        extraKeys={[selectedRuleId || rule_id]}
        renderTitle={(total) => renderTableTitle(total)}
        clickableRow={true}
        onRowClicked={handleRowClicked}
        isSearchable={false}
        emptyConfig={{
          title: 'Oops!',
          message: 'There are no logs from the API...',
          titleClassName: 'rule-logs-empty-title',
          messageClassName: 'rule-logs-empty-message',
        }}
      />
    </div>
  );
};

export default RuleLogs;
