import React, { useState, useEffect } from "react";
import TableList from "../layout/TableList";
import API from "../../api";
import { Button } from "reactstrap";
import { useSelector } from "react-redux";
import useCustomQuery from "../../hooks/useCustomQuery";
import { getApiErrorMessage, getSafeArray } from "../../utils/helpers";
import useMemoizedDispatch from "../../hooks/useMemoizedDispatch";
import {
  clearConnectionsFilters,
  setConnectionDefaultFilters,
  setConnectionsFilter,
  setConnectionsSort,
} from "../../actions/connection-status";
import {
  connectionActiveFiltersDisplayInfo,
  connectionsTableTemplate,
  getConnectionTableFiltersTemplate,
} from "../../constants/app-v4/connectionsTableTemplate";
import { initializeConnectionStatusConfig } from "../../utils/connectionStatusHelpers";
import filterTypes from "../../constants/filterTypes";
import ConnectionsConfigModal from "./ConnectionsConfigModal";
import ConnectionsBulkActions from "./ConnectionsBulkActions";
import CustomCheckBox from "../layout/CustomCheckBox";
import { includesSelection, onSelectElementAction } from "../../utils/bulkActionsHelper";
import { connectionStatusConfigById } from "../../constants/common";
import { issueTypes } from "../../constants/issues";
import { getIssueModalOptions } from "../../actions/issues";
import useBulkSelection from "../../hooks/useBulkSelection";
import useUserType from "../../hooks/useUserType";

const ConnectionStatusTable = () => {
  //  Initializing APIs
  const { PartnerAPI } = API;

  // Getting needed info from redux store
  const filters = useSelector((state) => state.connections.filters);
  const activeFilters = useSelector((state) => state.connections.activeFilters);
  const applyDefaultFilters = useSelector((state) => state.connections.applyDefaultFilters)
  const sortField = useSelector((state) => state.connections.sortField);
  const searchTerm = useSelector((state) => state.connections.searchTerm);
  const connectionsState = useSelector((state) => state.connections);
  const leftSideBarType = useSelector((state) => state.layout.leftSideBarType);
  const currentPage = useSelector((state) => state.connections.currentPage);
  const solutions = useSelector((state) => state.issues.modalOptions.solutions);

  // Component state
  const [perPage] = useState(100);
  const [showConfigModal, setShowConfigModal] = useState(false);
  const [connectionsConfig, setConnectionsConfig] = useState(
    initializeConnectionStatusConfig(
      currentPage + 1,
      perPage,
      searchTerm,
      sortField,
      filters
    )
  );

  // Component Hooks
  const { dispatch } = useMemoizedDispatch();
  const userType = useUserType();
  const {
    data: connectionsResponse,
    error: connectionsError,
    isLoading: connectionsLoading,
    refetch: refetchConnectionStatus,
  } = useCustomQuery(
    [PartnerAPI.keys.getConnectionStatusList, connectionsConfig],
    ({ queryKey }) => PartnerAPI.handlers.getConnectionStatusList(queryKey[1])
  );

  const {
    ids: connectionIds,
    selectedIds: selectedConnections,
    setSelectedIds: setSelectedConnections,
    allSelected: allConnectionsSelected
  } = useBulkSelection({
    elements: connectionsResponse?.data?.data,
    filters,
    sortField,
    searchTerm,
  })

  // Component variables
  const connectionRowPrefix = "connection-row-";
  const initialFilters = {
    status: null,
    solution_id: null,
    partner_id: null,
    company_id: null,
  };

  // Watch solution changees
  useEffect(() => {
    if (solutions.length === 0) dispatch(getIssueModalOptions(null, issueTypes.VULNERABILITIES, userType));
  }, [solutions]);


  // Set default filters
  useEffect(() => {
    if(applyDefaultFilters) {
      // Set error filter
      const errorStatus = connectionStatusConfigById[0];
      setFilters('status', 'error', errorStatus?.label, filterTypes.single, null)
      // Set active filter
      setFilters('active', true, 'Enabled', filterTypes.single, null)
      dispatch(setConnectionDefaultFilters(false));
    }
  }, [applyDefaultFilters])


  // On table data request
  const onTableFetch = (payload = {}, shouldRefetch = false) => {
    const { page, per_page, text_to_search, sort, filters } = payload;
    const config = initializeConnectionStatusConfig(
      page,
      per_page,
      text_to_search,
      {},
      filters
    );
    setConnectionsConfig({
      ...config,
      sort,
    });

    if (shouldRefetch) {
      refetchConnectionStatus();
    }
  };

  const toggleConfigModal = () => {
    setShowConfigModal(!showConfigModal);
  };

  // Get connections data for table
  const getConnectionsData = () => {
    const connectionData = connectionsResponse?.data;
    const list = getSafeArray(connectionData?.data);
    return {
      ...connectionsState,
      list,
      pageCount: connectionData?.pagination?.page_count,
      total: connectionData?.pagination?.total || 0,
    };
  };

  // Set table sort
  const setSort = (value) => {
    dispatch(setConnectionsSort(value));
  };

  // Set table filters
  const setFilters = (filterName, value, label, type, filterOption) => {
    let activeFilterLabel = filterName === 'status' ? `${label} Status` : label;
    dispatch(
      setConnectionsFilter(filterName, value, activeFilterLabel, type, filterOption, false)
    );
  };

  // Clear all table filters
  const clearFilters = () => {
    dispatch(clearConnectionsFilters());
  };


  // Render select all checkbox
  const renderSelectAllComponent = () => {
    return (
      <CustomCheckBox
        isSelected={allConnectionsSelected}
        targetId="select-all-connections-checkbox"
        callback={(isSelected) => {
          if(isSelected) {
            setSelectedConnections([])
          } else {
            setSelectedConnections(connectionIds)
          }
        }}
      />
    );
  };

  // On connection selected
  const onConnectionCheckboxSelect = (id, isSelected) => {
    const updatedList = onSelectElementAction(id, isSelected, selectedConnections);
    setSelectedConnections(updatedList);
  };

  // Render connection row selection checkbox
  const renderConnectionCheckBox = ({ id }) => {
    const isConnectionSelected = includesSelection(id, selectedConnections);
    return (
      <CustomCheckBox
        isSelected={isConnectionSelected}
        targetId={`connection-checkbox-${id}`}
        callback={(isSelected) => {
          onConnectionCheckboxSelect(id, isSelected);
        }}
      />
    );
  };

  // returns table template
  const getConnectionManagementTemplate = () => {
    return [
      {
        displayName: null,
        fieldName: null,
        sortFieldName: null,
        headerClassNameWrapper: null,
        renderCustomHeader: renderSelectAllComponent,
        renderCustomComponent: renderConnectionCheckBox,
      },
      ...connectionsTableTemplate,
    ];
  };

  // Get table filters list
  const getFiltersList = () => {
    return getConnectionTableFiltersTemplate(
      initialFilters,
      filters,
      activeFilters,
      solutions
    );
  };

  // Render empty table content
  const renderCustomEmptyComponent = () => {
    return (
      <div className="connections-empty-table">
        <div className="connections-empty-table-title">
          No connections found for now...
        </div>
      </div>
    );
  };

  // Render add configuration button
  const renderAddConfigurationButton = () => {
    return (
      <Button
        color="light"
        className="d-flex align-items-center btn-large"
        onClick={toggleConfigModal}
      >
        <i className="bx bx-plus-circle mr-2" />
        Add new configuration
      </Button>
    );
  };

  // Render connections config modal
  const renderConnectionsConfigModal = () => {
    if (!showConfigModal) return null;
    return (
      <ConnectionsConfigModal
        showModal={showConfigModal}
        toggleModal={toggleConfigModal}
      />
    );
  };

  // On row right click
  const onConnectionRightClick = (elementId) => {
    if (elementId?.includes(connectionRowPrefix)) {
      const id = parseInt(elementId?.replace(connectionRowPrefix, ""));
      const isSelected = selectedConnections?.includes(id);
      if (!isSelected) {
        setSelectedConnections([id]);
      }
    }
  };

  // Render bulk actions menu
  const renderBulkActionsMenu = (params) => {
    const { tableRef, bulkMenuId, bulkMenuRef, bulkMenuStyle, scrollableRef } =
      params;
    return (
      <ConnectionsBulkActions
        selectedConnections={selectedConnections}
        setSelectedConnections={setSelectedConnections}
        onRightClick={onConnectionRightClick}
        bulkMenuId={bulkMenuId}
        bulkMenuRef={bulkMenuRef}
        parentRef={tableRef}
        scrollableRef={scrollableRef}
        bulkMenuStyle={bulkMenuStyle}
      />
    );
  };

  // Render connections table
  const renderTable = () => {
    return (
      <TableList
        fetchList={onTableFetch}
        reloadList={refetchConnectionStatus}
        perPage={perPage}
        data={getConnectionsData()}
        columns={getConnectionManagementTemplate()}
        customClassName="issues-list"
        containerClassName="view-container connections-table-wrapper px-0 bg-dark"
        tableCardClassName="mx-2"
        setFilter={setFilters}
        clearAllFilters={clearFilters}
        setSort={setSort}
        activeFiltersDisplayInfo={connectionActiveFiltersDisplayInfo}
        isLoading={connectionsLoading}
        errorMessage={getApiErrorMessage(connectionsError)}
        searchPlaceholder="Search error"
        rowPrefix={connectionRowPrefix}
        renderBulkMenu={renderBulkActionsMenu}
        selectedElements={selectedConnections}
        allElementsSelected={allConnectionsSelected}
        filtersComponentList={getFiltersList()}
        renderButton={renderAddConfigurationButton}
        sidebarCollapsed={leftSideBarType === "condensed"}
        accumulatedHeightOffset={-0.5}
        renderCustomEmptyComponent={renderCustomEmptyComponent}
      />
    );
  };
  return (
    <div>
      {renderTable()}
      {renderConnectionsConfigModal()}
    </div>
  );
};

export default ConnectionStatusTable;
