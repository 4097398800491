import AWS from 'aws-sdk';
import { decryptWithAES } from '../utils/helpers';

class awsController {
  constructor() {
    //  Customizing AWS config
    AWS.config.update({
      region: process.env.REACT_APP_AWS_REGION,
      accessKeyId: decryptWithAES(process.env.REACT_APP_AAK, process.env.REACT_APP_APF),
      secretAccessKey: decryptWithAES(process.env.REACT_APP_ASK, process.env.REACT_APP_APF),
      correctClockSkew: true,
    });

    //  Services
    this.s3 = new AWS.S3();
  }

  createFileOnBucket = async (fileKey, bucketName, file) => {
    //  Building params
    const params = {
      Bucket: bucketName,
      Key: fileKey,
      Body: file,
    };

    //  Uploading file to AWS
    const response = await this.s3.putObject(params).on('retry', (response) => {
      //  Adding handler to retry requests in case of request time too skewed
      if (response?.error?.name === 'RequestTimeTooSkewed') {
        console.log('[AWS] - Retrying time too skewed error');
        const serverTime = Date.parse(response?.httpResponse?.headers['date']);
        const timeNow = new Date().getTime();
        AWS.config.systemClockOffset = Math.abs(timeNow - serverTime);
        response.error.retryable = true;
      }
    }).promise().catch((error) => {
      console.log('[AWS] - error creating file: ', error);
      return { error: true, file: file.name };
    });
    return response?.error ? response : { file, error: false };
  }

  createMultipleFilesOnBucket = async (path, bucket, files = []) => {
    const response = await Promise.all(files?.map(async (file) => {
      const key = `${path}/${file?.name}`.split(' ').join('').replace(/\+/g, '-');
      return await this.createFileOnBucket(key, bucket, file);
    }));
    return response;
  }
}

export default new awsController();
