import React from 'react'
import { Button } from 'reactstrap';

const ScoreDistributionSolutionButtons = (props) => {

  const {
    purchasedSolutions = [],
    availableSolutions = [],
    handleButtonSelect = () => {},
    selectedSolutions = [],
    clearSelections = () => {},
  } = props;

  const renderSolutions = (solutions) => {
    return solutions?.map((value) => {
      return (
        <Button
          className="mx-1 mt-2 solution-button"
          color="secondary"
          key={value}
          type="button"
          outline
          active={selectedSolutions.includes(value)}
          onClick={() => handleButtonSelect(value)}
        >
          {value.replace("aaS", "")}
        </Button>
      );
    });
  };

  return (
    <div className="solutions-content">
      <div className="purchased-solutions-container">
        <h1>Your solutions</h1>
        <div className="buttons-container">
          {renderSolutions(purchasedSolutions)}
        </div>
      </div>
      <div className='divider-container'>
        <span className="divider" />
      </div>
      <div className="available-solutions-container">
        <h1>Solutions you can purchase</h1>
        <div className="buttons-container">
          {renderSolutions(availableSolutions)}
        </div>
      </div>
      <div className='action-buttons-container'>
        <Button 
          color="link" 
          onClick={clearSelections}
        >
          Clear Selection
        </Button>
      </div>
    </div>
  );
}

export default ScoreDistributionSolutionButtons