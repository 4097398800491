import React from 'react'
import { useSelector } from 'react-redux';
import ConnectionManagement from './ConnectionManagement';
import ConnectionQuickActions from './ConnectionQuickActions';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import companyIdSelector from '../../selectors/companyIdSelector';
import { SHOULD_RELOAD_IMPERSONATION } from '../../actions/types';
import { successActionCreator } from '../../actions/action-creators/success';

const ConnectionActions = (props) => {
  const {
    connectionId,
    partnerId,
    partnerName,
    companyId,
    companyName,
    currentConfig,
    fields,
    isActive
  } = props;

  // Component Hooks
  const { dispatch } = useMemoizedDispatch();
  //  Watching redux store
  const impersonatedCompanyId = useSelector((state) => companyIdSelector(state));

  const handleSuccessfulAction = () => {
    if (impersonatedCompanyId && impersonatedCompanyId === companyId) {
      dispatch(successActionCreator(SHOULD_RELOAD_IMPERSONATION, true))
    }
  }

  // Render connection management action
  const renderConnectionManagement = () => {
    return (
      <ConnectionManagement
        connectionId={connectionId}
        partnerId={partnerId}
        partnerName={partnerName}
        companyId={companyId}
        companyName={companyName}
        currentConfig={currentConfig}
        fields={fields}
        hasEnabled={false}
      />
    )
  }

  // Render Connection quick actions
  const renderConnectionQuickActions = () => {
    return (
      <ConnectionQuickActions
        connectionId={connectionId}
        partnerName={partnerName}
        companyName={companyName}
        isActive={isActive}
        handleSuccessfulAction={handleSuccessfulAction}
      />
    )

  }
  return (
    <div className='connection-status-actions-container'>
      {renderConnectionManagement()}
      {renderConnectionQuickActions()}
    </div>
  )
}

export default ConnectionActions;
