import cyvatarAxios from '../axios';
import {
  GET_RULE_SETS,
  GET_RULE_DETAIL,
  GET_RULE_LOGS,
  GET_LOG_MESSAGES,
  GET_LOG_GROUP,
  GET_RULE_SET,
  GET_PARTNER_SYNC_LOGS,
} from '../ApiKeys';

const getRuleSets = async (filters = {}) => {
  const filtersToUse = { ...filters };
  const response = await cyvatarAxios.get('/api/rules/list', { params: { filters: filtersToUse } });
  const { data } = response;
  return data;
};

//  Params: { rule_id, status }
const changeRuleStatus = async (params) => {
  const response = await cyvatarAxios.put('/api/rules/change_status', params);
  const { data } = response;
  return data;
};

//  Params: { allSelected, excluded, selected, filters }
const deleteRuleSets = async (params) => {
  const response = await cyvatarAxios.delete('/api/rules/remove', { data: params });
  const { data } = response;
  return data;
};

//  TODO: pending to get last execution information
const getRuleDetail = async (rule_id) => {
  if (rule_id === null) { return null; }
  const response = await cyvatarAxios.get('/api/rules/rule_summary', { params: { rule_id } });
  const { data } = response;
  return data;
};

const getRuleLogs = async (rule_id, filters) => {
  const response = await cyvatarAxios.get(`/api/rules/${rule_id}/log_groups`, { params: { filters } });
  const { data } = response;
  return data;
};

const getLogMessages = async (rule_id, log_id, filters, company = null) => {
  const params = { filters }
  if (company) params.company_id = company;
  const response = await cyvatarAxios.get(`/api/rules/${rule_id || 'daily_sync'}/log_groups/${log_id}/logs`, { params });
  const { data } = response;
  return data;
};

const getLogGroup = async (log_group_id) => {
  if (log_group_id === null) { return null; }
  const response = await cyvatarAxios.get(`/api/rules/log_group/${log_group_id}`);
  const { data } = response;
  return data;
};

//  Function to save rule set
const saveRuleSet = async (payload) => {
  const body = JSON.stringify(payload);
  const response = await cyvatarAxios.post('/api/rules/save', body);
  const { data } = response;
  return data;
};

//  Function to get an individual rule set
const getRuleSet = async (rule_set_id) => {
  if (rule_set_id === null) { return null; }
  const response = await cyvatarAxios.get('/api/rules/rule_set', { params: { rule_set_id } });
  const { data } = response;
  return data;
};

//  Function to get partner sync logs for rules
const getPartnerSyncLogs = async (partner_id, company_id, filters = {}) => {
  const response = await cyvatarAxios.get(`/api/rules/partner_sync/${partner_id}/logs`, { params: { company: company_id, filters } });
  const { data } = response;
  return data;
};

//  Queries to invalidate on rule set changes
const ruleSetChangesInvalidators = [
  GET_RULE_SETS,
  GET_RULE_DETAIL,
  GET_RULE_SET,
];

export const RuleAPI = {
  handlers: {
    getRuleSets,
    changeRuleStatus,
    deleteRuleSets,
    getRuleDetail,
    getRuleLogs,
    getLogMessages,
    getLogGroup,
    saveRuleSet,
    getRuleSet,
    getPartnerSyncLogs,
  },
  keys: {
    getRuleSets: GET_RULE_SETS,
    getRuleDetail: GET_RULE_DETAIL,
    getRuleLogs: GET_RULE_LOGS,
    getLogMessages: GET_LOG_MESSAGES,
    getLogGroup: GET_LOG_GROUP,
    getRuleSet: GET_RULE_SET,
    getPartnerSyncLogs: GET_PARTNER_SYNC_LOGS,
  },
  invalidators: {
    changeRuleStatus: ruleSetChangesInvalidators,
    deleteRuleSets: ruleSetChangesInvalidators,
    saveRuleSet: ruleSetChangesInvalidators,
  },
};
