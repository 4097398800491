import React from "react";
import DashboardRow from "../../common/dashboard/DashboardRow";

import DashboardWidgetContainer from "../../../../components/app-v4/common/dashboard/DashboardWidgetContainer";
import TaskStatusDashboardRollup from "./TaskStatusDashboardRollup";
import AssignedTo from "./AssignedTo";
import ProjectProgressComingSoonWidget from "./ProjectProgressComingSoonWidget";

const ProjectManagementRow = () => {
  return (
    <>
      <DashboardRow
        title="Project Management"
        dashboardWrapperClassName="mb-5"
        linkText="See all"
        linkUrl="/dashboard/task-manager"
      >
        <DashboardWidgetContainer
          containerClassName="dashboard-widget-container"
          hasMessage
          message="The progress of your Cyvatar project"
        >
          <ProjectProgressComingSoonWidget/>
        </DashboardWidgetContainer>
        <DashboardWidgetContainer
          containerClassName="dashboard-widget-container"
          hasMessage
          message="The status of your project tasks"
        >
          <TaskStatusDashboardRollup/>
        </DashboardWidgetContainer>
        <DashboardWidgetContainer
          containerClassName="dashboard-widget-container"
          hasMessage
          message="How many tasks are assigned to us"
        >
          <AssignedTo/>
        </DashboardWidgetContainer>
      </DashboardRow>
    </>
  );
};

export default ProjectManagementRow;
