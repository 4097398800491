import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useMemoizedDispatch from './useMemoizedDispatch';
import { saveAnswersSuccess } from '../actions/answers';
import { setAlert } from '../actions/alerts';
import { successActionCreator } from '../actions/action-creators/success';
import { errorActionCreator } from '../actions/action-creators/errors';
import API from '../api';
import {
  SAVE_COMPLIANCE_ASSESSMENT_SUCCESS,
  SAVE_COMPLIANCE_ASSESSMENT_FAILURE,
} from '../actions/types';
import { assessmentHandlerTypes } from '../constants/common';
import useCustomMutation from './useCustomMutation';
import errorMessages from '../constants/errorMessages';
import { alertTypes } from '../constants/alerts';

const initialSuccessHandler = { type: assessmentHandlerTypes.NONE, handler: null };

const useSaveFullAssessment = () => {
  //  Initializing APIs
  const { AssessmentAPI } = API;

  //  Hook state
  const [successHandler, setSuccessHandler] = useState(initialSuccessHandler);

  //  Needed hooks
  const { dispatch } = useMemoizedDispatch();
  const history = useHistory();

  //  Function to handle success actions
  const handleSuccess = () => {
    if (!successHandler) { return; }
    const { type, handler } = successHandler;

    switch (type) {
      case assessmentHandlerTypes.REDIRECT:
        history.push(handler?.pathname);
        break;
      case assessmentHandlerTypes.FUNCTION:
        if (handler && typeof (handler) === 'function') {
          handler();
        }
        break;
      case assessmentHandlerTypes.NONE:
      default:
        break;
    }
  };

  //  Calling API to save answers
  const {
    data: postAnswersResponse,
    error: postAnswersError,
    mutate: postAnswers,
    isLoading: processing
  } = useCustomMutation(
    (...params) => AssessmentAPI.handlers.postAnswers(...params),
    AssessmentAPI.invalidators.postAnswers,
  );

  //  Watching success response
  useEffect(() => {
    if (!postAnswersResponse) { return; }
    const { data } = postAnswersResponse;
    dispatch(saveAnswersSuccess(data));
    //  Displaying messages
    const message = data?.success === false ? 'Oops! We didn’t catch that. Try again' : 'Assessment answers were updated';
    const messageType = data?.success === false ? 'danger' : 'success';
    dispatch(setAlert(message, messageType));
    //  Triggering success action creator
    dispatch(successActionCreator(SAVE_COMPLIANCE_ASSESSMENT_SUCCESS));
    handleSuccess();
    setSuccessHandler(initialSuccessHandler);
  }, [postAnswersResponse]);

  //  Watching erro response
  useEffect(() => {
    if (!postAnswersError) { return; }
    const errorMessage = postAnswersError.data?.errorMessage || errorMessages.defaultPost;
    dispatch(setAlert(errorMessage, alertTypes.error));
    dispatch(errorActionCreator(SAVE_COMPLIANCE_ASSESSMENT_FAILURE, errorMessage));
    setSuccessHandler(initialSuccessHandler);
  }, [postAnswersError]);

  //  Saving assessment execution
  const saveFullAssessment = (assessmentBody, companyId, callbackObject = null) => {
    setSuccessHandler(callbackObject);
    postAnswers(assessmentBody, companyId);
  }

  return { saveFullAssessment, processing }
};

export default useSaveFullAssessment;
