import React from 'react';
import { Label, Input } from 'reactstrap';
import { ENTER_KEY_NAME } from '../../constants/keyNames';
import { isValidFunction } from '../../utils/helpers';

const CustomRadioInput = (props) => {
  const {
    targetId = "custom-radio-input-id",
    isSelected,
    callback,
    isDisabled,
    label,
    className = '',
  } = props;

  const onClick = () => {
    if(!isDisabled || !isValidFunction(callback)) return;
    callback()
  }

  return (
    <div
      className={`form-check custom-radio custom-radio-blue d-inline-block ${className}`}
      onClick={onClick}
      tabIndex="0"
      onKeyPress={(e) => e?.key === ENTER_KEY_NAME ? onClick(e) : null }
      aria-label="radio"
    >
      <Input
        id={targetId}
        type="radio"
        checked={isSelected}
        readOnly
        disabled={isDisabled}
        aria-hidden="true"
        tabIndex="-1"
      />
      {label && (
        <Label
          className="custom-control-label mb-0"
          htmlFor={targetId}
        >
          {label}
        </Label>
      )}
    </div>
  )
}

export default CustomRadioInput
