import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import FullAssessment from '../components/assessment/Full/FullAssessment';
import ImpersonationEmptyView from '../components/layout/ImpersonationEmptyView';
import { changeLayout } from '../actions/layout';
import { fetchComplianceAssessment } from '../actions/answers';
import companyIdSelector from  '../selectors/companyIdSelector';
import useMemoizedDispatch from '../hooks/useMemoizedDispatch';
import { useLocation, useHistory } from 'react-router-dom';
import { defaultIndexPath } from '../constants/navigation';
import _ from 'lodash';
import useCanAbility from '../hooks/useCanAbility';
import { CREATE_ACTION, PRIVILEGE_SOURCES } from '../constants/privileges';

const FullAssessmentView = (props) => {
  // Hooks
  const { dispatch } = useMemoizedDispatch();
  const location = useLocation();
  const history = useHistory();
  const ability = useCanAbility();

  //  Watching redux store
  const impersonatedCompanyId = useSelector((state) => companyIdSelector(state));
  const activeImpersonation = useSelector((state) => !_.isNil(state.impersonation.companyId));
  const isMemberView = useSelector(state => state.impersonation.isMemberView);

  // Component Variables
  const canImpersonate = ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.MULTIPLE.IMPERSONATE);

  useEffect(() => {
    dispatch(changeLayout('vertical'));
  }, [changeLayout]);

  //  Getting compliance assessment for company id
  useEffect(() => {
    if (!impersonatedCompanyId) { return; }
    dispatch(fetchComplianceAssessment(null, impersonatedCompanyId));
  }, [impersonatedCompanyId]);

  // Check if member view is active
  useEffect(() => {
    if(isMemberView && activeImpersonation && location?.pathname === '/assessment') {
      history.push(`${defaultIndexPath}/assessment`)
    }
  }, [location?.pathname, isMemberView, activeImpersonation])

  //	Rendering empty state for cydekick assessment
  if (canImpersonate && !impersonatedCompanyId) {
    return (
      <ImpersonationEmptyView viewName="Assessment" />
    );
  }

  return (
    <div>
      <FullAssessment
        companyIdToUse={impersonatedCompanyId}
      />
    </div>
  )
};

export default FullAssessmentView;
