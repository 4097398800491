import gradeColors from '../constants/grades';
import _ from 'lodash';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import { actionTypes, subtabsAndActionsMap, subTabsIdentifiers } from '../constants/navigation';
import { planIcons, planSubscription } from '../constants/planInfo';
import errorMessages from '../constants/errorMessages';
import { extensionsTypeMap, extensionClassNameMap } from '../constants/common';
import { timezoneAbbreviations } from './dateFormatter';

export const formatWholeNumber = (value) => {
  return value?.toString()?.replace(/[^0-9]/g, "");
}

export const formatMoney = (amount) => {
  const realMoney = amount > 0 ? amount : 0;
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: 'currency',
    currency: 'USD',
  }).format(realMoney || 0);
};

export const getSafeArray = (array = []) => {
  if (!array || !Array.isArray(array)) { return []; }
  return array;
};

export const abbreviateNumber = (number, type = 'SI') => {

  const symbol = {
    currency: ["", "k", "M", "B", "T", "P", "E"],
    SI: ["", "k", "M", "G", "T", "P", "E"]
  }[type];

  let tier = Math.log10(Math.abs(number)) / 3 | 0;

  if (tier === 0) return number;

  let suffix = symbol[tier];
  let scale = Math.pow(10, tier * 3);
  let scaled = number / scale;
  return Number(scaled.toFixed(1)) + suffix;
}

export const nameToInitials = (fullName) => {
  const namesArray = fullName.trim().split(' ');
  if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`.toUpperCase();
  else return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`.toUpperCase();
}

export const formatDate = (data) => {

  const formattedDate = new Date(data);
  const date = formattedDate.getDate();
  const month = formattedDate.toLocaleString('default', { month: 'short' });
  const year = formattedDate.getFullYear();

  return `${('0' + date).slice(-2)} ${month}, ${year}`;
  //return `${ formattedDate.toLocaleDateString().slice(0,8)} at ${('0'+ formattedDate.getHours()).slice(-2)}:${('0'+ formattedDate.getMinutes()).slice(-2)}`;
}

export const getGMTOffset = () => {
  moment.fn.zoneName = function () {
    var abbr = this.zoneAbbr();
    return timezoneAbbreviations[abbr] || abbr;
  }
  const currentDate = moment.tz(new Date().toString(), moment.tz.guess());
  const offset = currentDate.format('ZZ');
  return ` (GMT ${offset})`;
};

export const findLetterGrade = (grades, percentGrade) => {
  const letterGrade = _.find(grades, (grade) => {
    return parseFloat(grade.min) <= percentGrade && percentGrade <= parseFloat(grade.max)
  });

  const color = gradeColors[letterGrade?.name || 'DEFAULT'];

  return {
    grade: letterGrade,
    color
  }
}

export const isDateInRange = (date, fromDate, toDate) => {
  return (date?.getTime() <= toDate?.getTime()) && (date?.getTime() >= fromDate?.getTime())
}

export function getDaysBetween(startDate = new Date(), endDate = new Date()) {
  // The number of milliseconds in all UTC days (no DST)
  const oneDay = 1000 * 60 * 60 * 24;
  //  Getting safe params
  const safeStartDate = startDate && startDate instanceof Date ? startDate : new Date();
  const safeEndDate = endDate && endDate instanceof Date ? endDate : new Date();
  // A day in UTC always lasts 24 hours (unlike in other time formats)
  const start = Date.UTC(safeEndDate.getFullYear(), safeEndDate.getMonth(), safeEndDate.getDate());
  const end = Date.UTC(safeStartDate.getFullYear(), safeStartDate.getMonth(), safeStartDate.getDate());
  // so it's safe to divide by 24 hours
  return (start - end) / oneDay;
}

export const getDate = (time, includeFullYear) => {
  const formattedDate = new Date(time);
  const date = formattedDate.getDate();
  const month = formattedDate.getMonth() + 1;
  const year = formattedDate.getFullYear();
  return `${('0' + month).slice(-2)}/${('0' + date).slice(-2)}/${includeFullYear ? year : ('' + year).slice(-2)}`;
};

export const removeItemFromArray = (originalArray, indexToRemove) => {
  const cloneArray = [].concat(originalArray);
  cloneArray.splice(indexToRemove, 1);
  return cloneArray;
};


export const removeKeyFromObject = (originalObject = {}, keyToRemove) => {
  const cloneObject = { ...originalObject };
  delete cloneObject[keyToRemove]
  return cloneObject;
};

export const replaceItemOnArray = (array = [], index, newElement = {}) => {
  if (index === -1 || (index >= array.length)) { return array; }
  const cloneArray = [...array];
  cloneArray.splice(index, 1, newElement);
  return cloneArray;
}


export const buildPayloadFromFormTemplate = (formTemplate = [], formValue = {}) => {
  const payload = {};

  formTemplate.forEach((field) => {
    const { name } = field;
    payload[name] = formValue[name];
  });

  return payload;
};

export const getDayDifference = (firstDate, secondDate) => {
  if (!firstDate || !secondDate) { return 0; }
  const date1 = moment(firstDate);
  const date2 = moment(secondDate);

  return date2.diff(date1, 'days');
}

/**
 * It returns true if the user is a member of Cyvatar, and false if they are not
 * @param user - the user object
 * @returns A boolean value.
 */
export const isCyvatarMember = (user) => {
  if (!user) return false;

  const { roles } = user;

  if (!roles) {
    const { ['roles.name']: roleName } = user;
    if (!roleName) { return false; }
    return (roleName.indexOf('squad_') !== -1);
  }

  if (roles.length === 0) return false;

  const role = roles[0];

  if (!role) return false;

  const { name } = role;

  return (name.indexOf('squad_') !== -1);
};

export const userHasRoles = (user, rolesToVerify = []) => {
  if (!user) { return false; }
  const { roles } = user;
  //  Covering case for cydekicks payload
  if (!roles) {
    const { ['roles.name']: roleName } = user;
    if (!roleName) { return false; }
    return rolesToVerify.includes(roleName);
  }
  //  Getting classname from roles
  if (roles.length === 0) { return false; }
  const role = roles[0];
  if (!role) { return false; }
  const { name } = role;
  return rolesToVerify.includes(name);
};

export const getUserAvatarColorClassName = (user) => {
  const isCydekick = isCyvatarMember(user);
  return isCydekick ? 'cydekick-avatar' : 'member-avatar';
};

export const parseCompaniesToOptions = (companies = []) => {
  return companies.map(({ id, name }) => ({ value: id, label: name }));
};

export const parseTimezonesToOptions = (timezones = []) => {
  return timezones.map(({ name }) => ({ value: name, label: name }));
};

export const getAllowedSubtabs = (subtabs = [], userRole, filterAllowed, activeImpersonation) => {
  if (!userRole) { return subtabs; }
  const isCydekick = userRole.indexOf('squad_') !== -1;
  if (!isCydekick) { return subtabs; }
  const shouldBeFiltered = (subtab) => {
    const limitToCustomerFilter = (!filterAllowed || (filterAllowed && subtab?.limitToCustomer === false));
    const impersonationModeFilter = (!activeImpersonation || (activeImpersonation && !subtab?.impersonationDisabled));
    return limitToCustomerFilter && impersonationModeFilter;
  }
  return subtabs.filter(shouldBeFiltered) || [];
};

const getPDFNameByType = (type = actionTypes.DOWNLOAD_PDF, param = '') => {
  //  Returning file name for trust builder
  if (type === actionTypes.DOWNLOAD_PDF) {
    const currentDate = moment().format('YYYY-MM-D');
    const fileName = `cyvatar-trustbuilder-${currentDate}.pdf`;
    return fileName;
  }
  //  Getting file name for policies
  const splittedParam = param.split('/');
  if (!splittedParam) { return 'Policy.pdf'; }
  const policyName = splittedParam[splittedParam.length - 1];
  return policyName && policyName !== '' ? policyName : 'Policy.pdf';
}

export const getAnchorForPDF = (url, type, param) => {
  //  Creating <a> element to mimic onClick
  let link = document.createElement('a');
  link.href = url;
  //  Generating filename
  const fileName = getPDFNameByType(type, param);
  link.download = fileName;
  return link;
};

export const existPathOnList = (pathToFind = '', list = []) => {
  const matchingPath = list.find((path) => pathToFind.includes(path));
  return !!matchingPath;
};

export const isAbleToRequestScan = (requestedScan, lastRequestScan, lastScan, nextScanAvailable) => {
  if (!requestedScan && !lastRequestScan) {
    return true;
  }

  //  TODO: render the label
  if (requestedScan && !lastScan) {
    return false;
  }

  //  Rendering upgrade membership button
  if (((requestedScan && lastScan && !nextScanAvailable) || (!requestedScan && lastRequestScan && !nextScanAvailable))) {
    return false;
  }

  //  Rendering both buttons
  if (((requestedScan && lastScan && nextScanAvailable) || (!requestedScan && lastRequestScan && nextScanAvailable))) {
    return true;
  }
};

export const getSelectedSubtabIndex = (freemiumStatus, selectedIndex) => {
  if (!selectedIndex) { return 0; }
  //return freemiumStatus === 0 ? selectedIndex : 0;
  return selectedIndex
};

export const getFormattedDate = (date, includeTime = true) => {
  const formattedDate = new Date(date);
  const timeString = includeTime ? `, ${formattedDate.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}` : ''
  return `${('0' + (formattedDate.getMonth() + 1)).slice(-2)}/${('0' + formattedDate.getDate()).slice(-2)}/${formattedDate.getFullYear()}${timeString}`
};

export const getFormattedDateYMD = (date, separator) => {
  return date.getFullYear() + separator + ('0' + (date.getMonth() + 1)).slice(-2) + separator + ('0' + date.getDate()).slice(-2);
}

export const getTimeString = (date, hour12 = false) => {
  const formattedDate = new Date(date);
  const timeString = `${formattedDate.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12, })}`
  return timeString;
};

export const mapSolutionPartnerToOption = (_partner) => {
  if (_partner && _partner.solutions) {
    if (Array.isArray(_partner.solutions) && _partner.solutions.length > 0) {
      return {
        value: _partner.solutions[0].id,
        label: _partner.solutions[0].name,
      };
    }
  }

  return null;
};

export const orderAlphaNumeric = (arr) => {
  const extractString = item => item.match(/^[^\d]*/)[0];
  const extractNumber = item => Number(item.match(/\d*$/)[0]);
  return _.orderBy(arr, [extractString, extractNumber], ['asc']);
}

export const omitSingle = (key, { [key]: _, ...obj }) => obj

export const markSelectedOptions = (selectedElements = [], elements = []) => {
  const elementsCopy = [...elements];
  return elementsCopy.map((element) => {
    const matchingElement = selectedElements.find((item) => item.value === element.value);
    return {
      ...element,
      selected: !!matchingElement,
    };
  });
};

//  Function to handle status changes
export const handleStatusFilterChanges = (value, currentFilters, currentActiveFilters = []) => {
  const resolvedStatus = 4;
  const safeStatus = getSafeArray(currentFilters.status);
  const isRemovingFilter = safeStatus.includes(value);
  if (value !== resolvedStatus || isRemovingFilter) { return { newFilters: null, newActiveFilters: null }; }
  //  Handle scenario for selecting resolved status
  const newFilters = { ...currentFilters, severity: null, open_only: false };
  const newActiveFilters = currentActiveFilters.filter((active) => active.filterName !== 'severity');
  return { newFilters, newActiveFilters };
};

//  Function to handle severity changes
export const handleSeverityFiltersChanges = (value, currentFilters, currentActiveFilters = []) => {
  const resolvedStatus = 4;
  const safeSeverity = getSafeArray(currentFilters.severity);
  const safeStatus = getSafeArray(currentFilters.status);
  const isRemoving = safeSeverity.includes(value);
  //  If removing all severities, need to clear the open only flag to false
  if (isRemoving && safeSeverity.length === 1) {
    return {
      newFilters: { ...currentFilters, open_only: false },
      newActiveFilters: currentActiveFilters,
    }
  }
  //  Handling the addition of severity filters (clear resolved filter if needed)
  //  Removing resolved from active filters
  const resolvedIndex = currentActiveFilters.findIndex((active) => {
    return active.filterName === 'status' && active.value === resolvedStatus;
  });
  const newActiveFilters = resolvedIndex === -1 ?
    currentActiveFilters : removeItemFromArray(currentActiveFilters, resolvedIndex);
  const filteredStatus = safeStatus.filter((status) => status !== resolvedStatus);
  //  Removing resolved from filters
  const newFilters = {
    ...currentFilters,
    open_only: true,
    status: filteredStatus?.length === 0 ? null : filteredStatus,
  };
  return { newActiveFilters, newFilters };
};

// Navigation Helpers
export const getParentPath = (path) => {
  if (!path) return path;
  const pathArray = path.slice(1).split('/') || [''];
  return `/${pathArray[0]}`
}

export const getSubtabIdentifierByPath = (path, freemiumStatus) => {
  if (path) {
    const parentPath = getParentPath(path);
    const subtabs = subtabsAndActionsMap.get(parentPath)?.subtabs;
    if (subtabs) {
      const selectedSubtab = subtabs.find((subtab) => subtab.path === path);
      if (selectedSubtab) {
        const index = freemiumStatus === 0 ? selectedSubtab.identifier : selectedSubtab.freemiumIndex;
        return index || 0;
      }
    }
  }
  return 0
};


export const getSolutionFilterTabs = (packages, searchOnly, parentPath) => {
  const label = parentPath === '/dashboard' ? 'All' : 'All Issues';
  const allTab = { identifier: subTabsIdentifiers.myCyso.ALL, label, limitToCustomer: false, freemiumIndex: 0, search: '', value: null, searchOnly: true };
  const solutionFilters = [];

  //  TODO:get this list through an endpoint
  const solutionsWhiteList = ['TVM', 'SEM', 'ITAM'];

  if (packages) {
    packages.forEach((solution, index) => {
      if (solutionsWhiteList.includes(solution.name)) {
        solutionFilters.push({
          identifier: index + 1,
          label: solution?.name,
          limitToCustomer: false,
          freemiumIndex: index + 1,
          search: `?solution=${solution.name?.toLowerCase()}`,
          searchOnly,
          value: solution?.id,
          path: `${parentPath}?solution=${solution.name?.toLowerCase()}`
        })
      }
    })
  }

  return [
    {
      ...allTab,
      searchOnly,
      path: parentPath
    },
    ...solutionFilters
  ]
}

export const getUpdatedSearchParams = (searchString, paramsToExclude, searchStringToAdd) => {

  const queryParams = new URLSearchParams(searchString);
  if (paramsToExclude) {
    paramsToExclude.forEach((excludedParam) => {
      queryParams.delete(excludedParam);
    })
  }
  return queryParams.toString() + searchStringToAdd
}

export const capitalizeWords = (string) => {
  if (!string) return '';
  return string.replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
}

export const getPathTitle = (path) => {
  if (!path) return path;
  const pathArray = path.slice(1).split('/') || [];
  let title = '';
  pathArray.forEach((element, index) => {
    const formatted = element.replace('-', ' ')
    title = `${formatted}${index === 0 ? '' : ' - '}${title}`;
  })
  return capitalizeWords(title)?.replace("Grc", "GRC");
};

export const mapPlansToOptions = (plans = []) => {
  return plans.map((plan) => {
    const { price_id, name } = plan;
    const type = name.slice(0).split(' ')[0] || '';
    const label = name.replace(type, planSubscription[type]);
    return { value: price_id, label };
  })
};

export const mapPlansToTooltips = (plans = []) => {
  return plans.map((plan) => {
    const { description, name } = plan;
    const icon = planIcons[name]
    return {
      icon,
      name: `${name} Subscription`,
      title: `${name} Subscription`,
      info: description
    }
  })

}

export const getSubtabsByPath = (path) => {
  // Get subtabs from exact path if available, if not return parent path subtabs
  const parentPath = getParentPath(path);
  return subtabsAndActionsMap.get(path) || subtabsAndActionsMap.get(parentPath)
};

export const mapDeviceTypesToOptions = (deviceTypes = []) => {
  return deviceTypes?.map((device) => {
    const { type } = device;
    return { value: type, label: type };
  });
};

export const mapIssueSourceTypesToOptions = (sourceTypes = []) => {
  return sourceTypes?.map((source) => {
    const { id, name } = source;
    return { value: id, label: name };
  });
};

//  Function to get answers from local storage
export const getAssessmentAnswersFromLocalStorage = () => {
  const data = {
    questionAnswers: JSON.parse(localStorage.getItem("questions") || '{}'),
    questionsByCategory: JSON.parse(localStorage.getItem("questionsByCategory") || '{}'),
    sectionComments: JSON.parse(localStorage.getItem("comments") || '{}'),
    assessmentComment: localStorage.getItem("assessmentComment") || '',
    assessmentExecutionStatus: localStorage.getItem('assessmentExecutionStatus') || 0,
  };
  return { data };
};

export const changeBodyAttribute = (attribute, value) => {
  if (document.body) document.body.setAttribute(attribute, value);
  return true;
};

export const manageBodyClass = (cssClass, action = "toggle") => {
  switch (action) {
    case "add":
      if (document.body) document.body.classList.add(cssClass);
      break;
    case "remove":
      if (document.body) document.body.classList.remove(cssClass);
      break;
    default:
      if (document.body) document.body.classList.toggle(cssClass);
      break;
  }

  return true;
}

export const getMinutesInMilliSeconds = (minutes = 0) => {
  return 1000 * 60 * minutes;
};

export const initEditCyvalueFilters = (company_id, solution_name = '') => {
  return {
    company_id,
    params: {
      solution_name,
    }
  };
};


export const getApiErrorMessage = (apiError) => {
  return apiError ? (apiError?.data?.errorMessage || errorMessages.defaultGet) : null;
}

export const getNavigationIndex = (currentIndex = 0, total = 0, moveForward = true) => {
  if (total === 0) return 0;
  const lastPosition = total - 1;
  let nextPosition = moveForward ? currentIndex + 1 : currentIndex - 1;

  if (nextPosition < 0) {
    nextPosition = lastPosition;
  } else if (nextPosition > lastPosition) {
    nextPosition = 0;
  }

  return nextPosition;
}

export const getSafeExtensionsArray = (extension = '') => {
  return getSafeArray(extensionsTypeMap.get(extension));
};

export const getAttachmentFileClassName = (type) => {
  let extension = null;
  for (const [key, value] of extensionsTypeMap.entries()) {
    if (value.includes(type)) { extension = key; }
  }

  return extensionClassNameMap.get(extension) || 'bx bxs-file-blank';
};

export const sizeToMB = (size = 0) => {
  return parseFloat(((size / 1024) / 1024).toFixed(4));
};

export const getFilesTotalSize = (files = []) => {
  return files.reduce((total, file) => {
    const sizeInMB = sizeToMB(parseFloat(file?.size));
    return total + sizeInMB;
  }, 0)
};

export const isValidFunction = (element) => {
  return element && typeof (element) === 'function';
};

export const getSupportedMimeTypes = (extensionsMap = new Map()) => {
  let supportedTypes = [];
  for (const [, value] of extensionsMap.entries()) {
    supportedTypes = [...supportedTypes, ...value];
  }
  return supportedTypes;
};

export const getTableText = (text) => {
  return _.isNil(text) ? '-' : text;
}

export const compareObjects = (obj1 = {}, obj2 = {}) => {
  return JSON.stringify(obj1)===JSON.stringify(obj2);
};

export const getDefaultCellAriaLabel = (value, label) => {
  return `${value || 'unknown'}, ${label}`
}

//Function to get msg for screenreader, on focus of a dropdown option
export const onFocus = (element) => {
  const msg = `You are currently focused on option ${element?.focused?.label}.`;
  return msg;
};

export const filterByKey = (list = [], key = 'name', value = '') => {
  if (list === null || list.length === 0) { return null; }
  if (value === '' || value === null) { return list; }
  return list.filter((item) => item[key] === value)
}

export const encryptWithAES = (text, passphrase = '123') => {
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

export const decryptWithAES = (ciphertext, passphrase = '123') => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

export const getListText = (values) => {
  return getSafeArray(values).reduce((text, value, index) => {
    if (index === 0) {
      return value
    } else {
      return text + (`, ${value}`);
    }
  }, '')
}

export const mapObjSortToArray = (sort = {}) => {
  if (!sort || Object.keys(sort).length === 0) { return []; }

  const arraySort = [];
  for (const [key, value] of Object.entries(sort)) {
    arraySort.push([key, value]);
  }

  return arraySort || [];
};

export const mapSolutionsToOptions = (solutions = []) => {
  return getSafeArray(solutions).map((solution) => ({
    value: solution?.id,
    label: solution?.name,
  }))
}

export const isEmptyText = (textValue) => {
  return textValue?.trim() === '' || textValue === null || textValue === undefined
}

export const getItemsPerPageByConfig = (config, key, defaultValue = 20) => {
  const parsedConfig = JSON.parse(config || "{}")
  return parsedConfig[key] || defaultValue;
};

export const mutateSort = (key, value, currentSort = {}) => {
  const sortCopy = { ...currentSort };
  const { [key]: currentValue, ...restOfSort } = sortCopy;

  //  Case in which a value is setted to the sort
  if (value) {
    sortCopy[key] = value;
    return sortCopy;
  }
  //  Case in which we are removing a valur from the sort
  return restOfSort;
};

export const mapIdsToPublicIds = (ids = [], elements = []) => {
  const public_ids = ids.map((id) => {
    return elements.find((element) => element.id === id)?.public_id;
  });
  return public_ids?.filter((public_id) => !!public_id);
};

export const getDeduplicatedArray = (elements = []) => {
  return [...new Set(elements)];
};
