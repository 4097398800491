import React from 'react';
import RiskCodeLabel from '../../components/vcyso/company-risks-table/RiskCodeLabel';
import RiskScoreLabel from '../../components/vcyso/company-risks-table/RiskScoreLabel';
import RiskStatementOwner from '../../components/vcyso/company-risks-table/RiskStatementOwner';
import { formatDate } from '../../utils/helpers';
import RiskControlDescription from '../../components/vcyso/company-risks-table/RiskControlDescription';
import { headerTypes } from '../tableTemplates';
import { cellTypes } from '../tableTemplates';
import RiskManagerDropdown from '../../components/vcyso/company-risks-table/RiskManagerDropdown';
import RiskManagerTableActions from '../../components/vcyso/company-risks-table/RiskManagerTableActions';

//  Template for company risk table
export const companyRiskTemplate = [
    {
      label: 'Risk Number',
      allowSorting: false,
      allowSelect: false,
      headerType: headerTypes.TEXT,
      cellType: cellTypes.CUSTOM,
      headerClassName: 'risk-code-column',
      cellClassName: 'risk-code-cell risk-code-column',
      entry: 'risk_number', // Name of the attribute to extract from objects
      customRender: (params) => <RiskCodeLabel code={params?.value} />
    },
    {
      label: 'Risk Name',
      allowSorting: false,
      allowSelect: false,
      headerType: headerTypes.TEXT,
      cellType: cellTypes.TEXT,
      headerClassName: 'risk-name-column',
      cellClassName: 'risk-name-cell risk-name-column',
      entry: 'risk_name',
    },
    {
      label: 'Impact',
      allowSorting: false,
      allowSelect: false,
      headerType: headerTypes.TEXT,
      cellType: cellTypes.CUSTOM,
      headerClassName: 'risk-manager-dropdown-column',
      cellClassName: 'risk-manager-dropdown-column',
      entry: 'severity_id',
      customRender: (params) => <RiskManagerDropdown companyRiskId={params?.object?.id} fieldName="severity_id" configKeyName="severity" currentValue={params?.value}/>
    },
    {
      label: 'Likelihood',
      allowSorting: false,
      allowSelect: false,
      headerType: headerTypes.TEXT,
      cellType: cellTypes.CUSTOM,
      headerClassName: 'risk-manager-dropdown-column',
      cellClassName: 'risk-manager-dropdown-column',
      entry: 'likelihood_id',
      customRender: (params) => <RiskManagerDropdown companyRiskId={params?.object?.id} fieldName="likelihood_id" configKeyName="likelihood" currentValue={params?.value}/>
    },
    {
      label: 'Initial Risk Score',
      allowSorting: false,
      allowSelect: false,
      headerType: headerTypes.TEXT,
      cellType: cellTypes.CUSTOM,
      headerClassName: 'risk-score-column initial-risk-score-header',
      cellClassName: 'risk-score-column risk-score-cell',
      entry: 'initial_risk_score',
      customRender: (params) => <RiskScoreLabel scoreLabel={params?.value?.label} />
    },
    {
      label: 'Risk Treatment',
      allowSorting: false,
      allowSelect: false,
      headerType: headerTypes.TEXT,
      cellType: cellTypes.CUSTOM,
      headerClassName: 'risk-manager-dropdown-column risk-treatment-header',
      cellClassName: 'risk-manager-dropdown-column',
      entry: 'risk_treatment_id',
      customRender: (params) => <RiskManagerDropdown companyRiskId={params?.object?.id} fieldName="risk_treatment_id" configKeyName="risk_treatment" currentValue={params?.value}/>
    },
    {
      label: 'Control Description',
      allowSorting: false,
      allowSelect: false,
      headerType: headerTypes.TEXT,
      cellType: cellTypes.CUSTOM,
      headerClassName: 'risk-description-column',
      cellClassName: 'risk-description-column risk-score-cell',
      entry: 'control_description',
      customRender: (params) => <RiskControlDescription companyRiskId={params?.object?.id} description={params?.value}/>
    },
    {
      label: 'Impact',
      allowSorting: false,
      allowSelect: false,
      headerType: headerTypes.TEXT,
      cellType: cellTypes.CUSTOM,
      headerClassName: 'risk-manager-dropdown-column',
      cellClassName: 'risk-manager-dropdown-column',
      entry: 'residual_severity_id',
      customRender: (params) => <RiskManagerDropdown companyRiskId={params?.object?.id} fieldName="residual_severity_id" configKeyName="severity" currentValue={params?.value}/>
    },
    {
      label: 'Likelihood',
      allowSorting: false,
      allowSelect: false,
      headerType: headerTypes.TEXT,
      cellType: cellTypes.CUSTOM,
      headerClassName: 'risk-manager-dropdown-column',
      cellClassName: 'risk-manager-dropdown-column',
      entry: 'residual_likelihood_id',
      customRender: (params) => <RiskManagerDropdown companyRiskId={params?.object?.id} fieldName="residual_likelihood_id" configKeyName="likelihood" currentValue={params?.value}/>
    },
    {
      label: 'Residual Score',
      allowSorting: false,
      allowSelect: false,
      headerType: headerTypes.TEXT,
      cellType: cellTypes.CUSTOM,
      headerClassName: 'risk-score-column',
      cellClassName: 'risk-score-column risk-score-cell',
      entry: 'residual_risk_score',
      customRender: (params) => <RiskScoreLabel scoreLabel={params?.value?.label} />
    },
    {
      label: 'Owner',
      allowSorting: false,
      allowSelect: false,
      headerType: headerTypes.TEXT,
      cellType: cellTypes.CUSTOM,
      headerClassName: 'risk-owner-column',
      cellClassName: 'risk-owner-column',
      entry: 'owner',
      customRender: (params) => <RiskStatementOwner params={params} componentId={`owner-${params?.object?.id}`} userName={params?.value?.full_name}/>
    },
    {
      label: 'Last action',
      allowSorting: false,
      allowSelect: false,
      headerType: headerTypes.TEXT,
      cellType: cellTypes.CUSTOM,
      headerClassName: 'risk-last-action-column',
      cellClassName: 'risk-last-action-column',
      entry: 'updated_at',
      customRender: (params) => formatDate(params?.value)
    }
];
