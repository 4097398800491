import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { isValidFunction } from '../../../utils/helpers';

const BarChart = (props) => {
    const {
        xAxisCategories,
        series,
        stacked = false,
        dataColors,
        yLabelFormatter,
        xLabelFormatter,
        annotations,
        distributed=false,
        height=166,
        width='100%',
        yAxisTickAmount,
        tooltip = null,
        optionsChart = null,
        baseConfig = {},
        extraClassName = '',
        eventClick,
        states = {}
    } = props;
    const [options, setOptions] = useState(null);
        
    useEffect(() => {
        if (optionsChart!==null){
            setOptions({...optionsChart,...baseConfig});
            return
        }
        setOptions({
            chart: {
                stacked: stacked,
                zoom: {
                    enabled: false
                },
                background: 'transparent',
                toolbar: {
                    show: false,
                },
                events: {
                    click : (event, chartContext, config) => {
                        if (isValidFunction(eventClick)) {
                            eventClick(config);
                        }
                    },
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '65%',
                    distributed: distributed	
                },
            },
            annotations,
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            
            colors: dataColors,
            xaxis: {
                categories: xAxisCategories,
                tickAmount: 'dataPoints',
                tickPlacement: 'between',
                axisBorder: {
                    show: true,
                    color: '#7549DF',
                    height: 1,
                    width: '100%',
                    offsetX: -3,
                    offsetY: 0,
                },
                axisTicks: {
                    show: true,
                    borderType: 'solid',
                    color: '#7549DF',
                    height: 9,
                    offsetX: 0,
                    offsetY: 0,
                }
            },
            yaxis: {
                labels: {
                    formatter: yLabelFormatter
                },
                tickAmount: yAxisTickAmount,
                max: 5,
                axisBorder: {
                    show: true,
                    color: '#7549DF',
                    offsetX: 0,
                    offsetY: 0,
                    height: '105px'
                },
                axisTicks: {
                    show: true,
                    borderType: 'solid',
                    color: '#7549DF',
                    width: 9,
                    offsetX: 0,
                    offsetY: 0,
                }
            },
            tooltip: tooltip,
            states: states,
        });
    }, [setOptions, series, yLabelFormatter]);
    if(!series || !options) return null; 
    return (
        <div className={extraClassName}>
            <ReactApexChart  
                options={options} 
                series={series} 
                type="bar" 
                width={width}
                height={optionsChart?.chart?.height || height }
            />
        </div>
    );
}

export default BarChart;