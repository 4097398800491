import _ from 'lodash';
import React, { useEffect } from 'react'
import { useRef } from 'react';
import useContextMenu from '../../hooks/useContextMenu';
import useWindowSize from '../../hooks/useWindowSize';

const ContextMenu = (props) => {
    const {
        className="",
        parentRef,
        onElementSelectCallback,
        elementType,
        children,
        minMenuWidth = 0,
        minMenuHeight = 0,
        applyOffset = false,
        xOffset = 0,
        yOffset = 0,
        scrollableRef,
    } = props;

    // Component Hooks
    const { xPos, yPos, showMenu, elementId, xScreenPosition, yScreenPosition } = useContextMenu(parentRef, elementType, applyOffset, xOffset, yOffset, scrollableRef);
    const [screenWidth, screenHeight] = useWindowSize();
    const contextMenuRef = useRef(null);

    const normalizePosition = (positionX, positionY, _xOffset, _yOffset) => {
        const contextMenu = contextMenuRef?.current;

        //if(!contextMenu) return { normalizedX: positionX, normalizedY: positionY };
        const menuWidth = (contextMenu?.clientWidth || minMenuWidth) + _xOffset;
        const menuHeight = (contextMenu?.clientHeight || minMenuHeight) + _yOffset;

        // Check if the element will go out of bounds
        const outOfBoundsOnX = xScreenPosition + menuWidth > screenWidth;
        const outOfBoundsOnY = yScreenPosition - window.pageYOffset + menuHeight > screenHeight;

        let normalizedX = positionX;
        let normalizedY = positionY;

        // Normalize position on X
        if (outOfBoundsOnX) {
            normalizedX = positionX - menuWidth;
        }

        // Normalize position on Y
        if (outOfBoundsOnY) {
            normalizedY = positionY - menuHeight;
        }

        //  Add scroll height in case we are on a scrollable parent
        if (scrollableRef) {
            normalizedY += scrollableRef?.current?.scrollTop;
            const scrollableOutOfBounds = yScreenPosition - window.pageYOffset + menuHeight > scrollableRef?.current?.clientHeight
            if (scrollableOutOfBounds) { normalizedY -= menuHeight; }
        }

        return { normalizedX, normalizedY, outOfBoundsOnX, outOfBoundsOnY };
    }

    useEffect(() => {
        if(onElementSelectCallback && !_.isNil(elementId)) {
            onElementSelectCallback(elementId)
        }
    }, [elementId]);

    const getContextMenuStyle = () => {
        const { normalizedX, normalizedY, outOfBoundsOnX } = normalizePosition(xPos, yPos, 30, 5);
        return {
            style: { top: `${normalizedY}px`, left: `${normalizedX}px` },
            className: outOfBoundsOnX ? 'x-edge' : 'normal-x'
        }
    }

    const renderContextMenu = () => {
        const { style, className: positionClassName } = getContextMenuStyle();
        return (
            <div
                ref={contextMenuRef}
                className={`context-menu ${className} ${positionClassName}`}
                style={style}
            >
                <div className="menu-arrow arrow-right"></div>
                <div className="menu-arrow arrow-left"></div>
                {children}
            </div>
        )
    }

    return showMenu && renderContextMenu()
}

export default ContextMenu;
