import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect, useLocation } from 'react-router-dom';
import LoadingPage from "../components/layout/LoadingPage";
import Cookies from "universal-cookie";

const IncomingLead = (props) => {
  const {
    isAuthenticated
  } = props;

  const location = useLocation();

  const [populateFinished, setPopulateFinished] = useState(false);
  const [redirectToMain, setRedirectToMain] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    if( isAuthenticated === null) { return }
    const cookies = new Cookies();

    if (location?.search && !isAuthenticated) {
      const queryParams = new URLSearchParams(location.search);
      
      const user = {}
      user.first_name = queryParams.get('First+Name') || queryParams.get('First Name') || '';
      user.last_name = queryParams.get('Last+Name') || queryParams.get('Last Name') || '';
      user.email = queryParams.get('email') || '';

      setUserInfo(user);
      setPopulateFinished(true);
    } else if (cookies.get('email') && !isAuthenticated) { 
      setPopulateFinished(true);
    } else { 
      setRedirectToMain(true);
    }
  }, [isAuthenticated]);

  return (
    <div>
      {redirectToMain && <Redirect to="/"/>}
      {populateFinished ? <Redirect to={{ pathname: "/cybercheckup", state: userInfo }}/> : <LoadingPage />}
    </div>
  );
}


const makeMapStateToProps = () => {
  const mapStateToProps = (state) => {
    return {
      isAuthenticated: state.auth.isAuthenticated,
    }
  }
  return mapStateToProps;
}

export default withRouter(connect(makeMapStateToProps)(IncomingLead));

