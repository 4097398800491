import _ from 'lodash';
import { 
    ASSETS_DASHBOARD_SUCCESS
} from '../actions/types';

const initialState = {
    charts: null,
    updateAt: '',
}

export default (state = initialState, action) => {
    const { type, payload } = action ; 
    switch(type){
        case ASSETS_DASHBOARD_SUCCESS:
            return {
                ...state,
                charts: _.mapKeys(payload.charts,"name"),
                updateAt: payload.updateAt
            }
        default:
            return state
    }
}
