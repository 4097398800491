import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { subtabsAndActionsMap } from '../constants/navigation';
import { changeSubtabs, onSubtabSelected } from '../actions/navigation';
import { setIssuesFilter } from '../actions/issues';
import { getSafeArray } from '../utils/helpers';
import useSolutionTabs from "./useSolutionTabs";
import useMemoizedDispatch from './useMemoizedDispatch';

//  Hook to change subtabs depending on the value of canViewCyvatarPro flag
const useSolutionsOrFallbackSubtabs = (canViewCyvatarPro = false) => {
  //  Needed hooks
  const location = useLocation();
  const history = useHistory();
  useSolutionTabs(!canViewCyvatarPro);
  const { dispatch } = useMemoizedDispatch();

  //  Watchig pathname changes
  useEffect(() => {
    if (canViewCyvatarPro || canViewCyvatarPro === null) { return; }

    //  Applying fallback tabs
    const currentTab = subtabsAndActionsMap.get(location?.pathname);
    if (!currentTab) { return; }
    const safeSubtabs = getSafeArray(currentTab?.fallbackSubTabs);
    dispatch(changeSubtabs(safeSubtabs));

    //  Setting selected subtab
    const subtab = safeSubtabs[0];
    if (!subtab) { return; }
    dispatch(onSubtabSelected(subtab));

    //  Deleting query params
    const queryParams = new URLSearchParams(location?.search);
    dispatch(setIssuesFilter('solution_id', null, null));
    queryParams.delete('solution')
    history.replace({ search: queryParams?.toString() })
  }, [location?.pathname, canViewCyvatarPro]);
};

export default useSolutionsOrFallbackSubtabs;
