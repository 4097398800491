import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import FormCard from '../layout/FormCard';
import { renderPasswordField } from "../../utils/renderFormFields";
import { confirmPassword } from '../../actions/auth';

const ConfirmPassword = (props) => {
    const {
        handleSubmit,
        email,
        confirmPassword,
        disableGradient
    } = props;

    const onFormSubmit = (formValues) => {
        const user = { 
            email,
            password: formValues.password
        };
        confirmPassword({user})
    }

    const formCardHeader = (
        <div className="d-inline-flex align-items-center">
            <i className="bx bx-error mr-2" style={{ fontSize: "50px" }}></i>
            Confirm your password to continue.
        </div>
    )

    return (
        <div className={`h-minus-header vw-100 ${disableGradient ? "":"bg-gradient-dark-purple-v3"}`}>
            <Row className="justify-content-around align-items-start align-items-md-center form-card-container flex-grow-1 h-md-100 m-0 p-4">
                <Col 
                    md={12} 
                    lg={7}   
                >
                    <Row className="justify-content-center justify-content-lg-start  align-items-start align-items-sm-center align-items-lg-start flex-grow-1 h-100">
                        <FormCard
                            title="Enter your current password"
                            customClassName="confirm-password-card"
                            header={formCardHeader}
                        >
                            <form 
                                className="form-container form-horizontal"
                                onSubmit={handleSubmit(onFormSubmit)} 
                            >
                                <Field
                                    component={renderPasswordField}
                                    name="password" 
                                    label="Password"
                                    className="form-control" 
                                    placeholder="Password"
                                    type="password"
                                />
                                <button
                                    className="btn btn-block btn-light text-dark font-weight-bold"
                                    type="submit"
                                    style={{ marginTop:"20px"}}
                                >
                                    Confirm Password
                                </button>
                            </form>
                        </FormCard>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

const validate = (formValues) => {
    const errors = {};

    const { 
        password
    } = formValues;

    if(!password){
        errors.password = "Please complete this required field.";
    }

    return errors;
}

const mapStatetoProps = state => {
    return { email: state.auth.user.email }

}

const mapDispatchToProps = {
    confirmPassword
};

const wrappedComponent = reduxForm({
    form: 'confirmPasswordForm',
    validate
})(ConfirmPassword);

export default connect(mapStatetoProps, mapDispatchToProps)(wrappedComponent);
