import { getSafeArray, removeItemFromArray } from "./helpers";

export const getNewFilters = (key, value, isArray = false, filters = {}) => {
  //  Just replacing value if is not an array
  if (!isArray && filters[key] !== value) {
    return { ...filters, [key]: value };
  }

  //  Making changes for array cases
  if (filters[key]?.includes(value)) { return filters; }
  const newValue = [...filters[key], value];
  return { ...filters, [key]: newValue };
};

export const addOptionToActiveFilters = (key, option, activeFilters = []) => {
  //  Not duplicating entries
  const matchingFilter = activeFilters.find((filter) => filter?.key === key && filter?.value === option?.value);
  if (matchingFilter) { return activeFilters; }

  //  Adding new element
  const newFilter = { key, value: option?.value, label: option?.label };
  return [...activeFilters, newFilter];
};

export const removeValueFromFilter = (key, value, filters = {}) => {
  //  Getting current value
  const currentValue = filters[key];
  if (currentValue === null || currentValue === undefined) { return filters; }
  const isArray = currentValue && Array.isArray(currentValue);

  //  Getting new value
  let newValue = null;
  if (isArray) {
    //  Removing element from array
    const indexToRemove = currentValue.findIndex((val) => val === value);
    if (indexToRemove === -1) { return filters; }
    newValue = removeItemFromArray(currentValue, indexToRemove);
  }

  return { ...filters, [key]: newValue };
};

export const removeActiveFiltersByKeys = (keys = [], activeFilters = []) => {
  return activeFilters?.filter((filter) => !keys?.includes(filter?.key));
};

export const getSelectedGroups = (allSelected, selected = [], excluded = [], groups = []) => {
  if (allSelected) {
    const ids = groups?.map((group) => group.id);
    return ids.filter((id) => !excluded?.includes(id));
  }
  return selected;
};

const getAllFiltersFromConfigs = (partner_configs = []) => {
  const safeConfigs = getSafeArray(partner_configs);
  return safeConfigs.reduce((list, config) => {
    const { filters = [] } = config;
    const safeFilters = getSafeArray(filters);
    return [...list, ...safeFilters];
  }, []);
}

export const shouldShowPartnerFilters = (partner_configs = []) => {
  //  Verifying configs
  const safeConfigs = getSafeArray(partner_configs);
  if (safeConfigs.length === 0) { return false; }

  //  Verifying if there are available filters to show
  const allFilters = getAllFiltersFromConfigs(partner_configs);

  return (allFilters?.length > 0);
};

export const getPartnerFilters = (filters = {}, partner_configs = []) => {
  const allFilters = getAllFiltersFromConfigs(partner_configs);
  const attributes = allFilters?.map((filter) => filter?.attribute);
  return getSafeArray(attributes).reduce((partnerFilters, attribute) => {
    return {
      ...partnerFilters,
      [attribute]: filters[attribute],
    }
  }, {});
};

export const getPartnerActiveFilters = (activeFilters = [], partner_configs = []) => {
  const allFilters = getAllFiltersFromConfigs(partner_configs);
  const attributes = allFilters?.map((filter) => filter?.attribute);
  return activeFilters?.filter((filter) => attributes?.includes(filter?.key));
};

export const addPartnerActiveFilter = (key, name, option, activeFilters = []) => {
  const clearedFilters = removeActiveFiltersByKeys([key], activeFilters);
  const newActiveFilter = {
    key,
    value: option?.value,
    label: `${name}: ${option?.label}`
  };
  return [...clearedFilters, newActiveFilter];
};

export const getPartnerIdsFromIssueFilters = (filters = {}) => {
  const { source } = filters;
  const safeSource = getSafeArray(source);
  return safeSource?.map((element) => element?.value);
};
