export const onSelectElement = (element, isSelected, list) => {
    if(!isSelected) {
        return [...list, element];
    } else {
        const index = list?.findIndex( a => a === element);
        if(!list || index < 0) return list;
        list.splice(index, 1);
        return [...list]
    }
}


// Returns true if selected elements length equals elements id length
export const areAllElementsSelected = (elements, selectedElements) => {
    const elementCount = elements?.length
    const selectedCount = selectedElements?.length;
    return elementCount > 0 && selectedCount > 0 && elementCount === selectedCount
}

export const includesSelection = (id, selectedIds) => {
    return selectedIds?.includes(id)
}

export const onSelectElementAction = (element, isSelected, elements) => {
    const list = [...elements]
    if(!isSelected) {
        return [...list, element];
    } else {
        const index = list?.findIndex( a => a === element);
        if(!list || index < 0) return list;
        list.splice(index, 1);
        return [...list]
    }
}
