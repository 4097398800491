import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { UisCheckCircle, UisTimesCircle, UisRocket, UisChartPie } from '@iconscout/react-unicons-solid'
import makeCategoryColor from '../../selectors/assessment/categoryColorSelector';
import { Input, FormGroup, Label, Col, Card } from 'reactstrap';
import { saveAnswer, selectAnswer, deselectAnswer, setComment } from '../../actions/answers'
import { setNextQuestion } from '../../actions/assessment';
import { trackEvent } from '../../utils/gaTracker';
import { toggleAssessmentCommentModal, showNextButtonAction } from '../../actions/layout';
import makeAssessmentStatusSelector from '../../selectors/assessment/assessmentStatusSelector';
import amplitude from 'amplitude-js';
import useCanAbility from '../../hooks/useCanAbility';
import { MODIFY_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';

const Options = (props) => {

    const {
        isAuthenticated,
        options,
        color,
        saveAnswer,
        selectAnswer,
        categoryId,
        sectionId,
        questionId,
        answers: { questionAnswers, assessmentExecutionId, questionComments},
        type,
        setNextQuestion,
        isAssessmentEnd,
        toggleAssessmentCommentModal,
        showNextButtonAction,
        assessment,
        selectedCategoryId,
        selectedSectionId,
        setComment
    } = props;

    const optionsEndRef = useRef();

    // Component state
    //const [answerLater, setAnswerLater] = useState(false);
    const scrollToRef = (ref) => ref.current.scrollIntoView({ behavior: 'smooth' });
    const [lastQuestion, setLastQuestion] = useState(null);
    const [previousQuestion, setPreviousQuestion] = useState(null);
    const [commentValue, setCommentValue] = useState(null);
    const [debounceComment, setDebounceComment] = useState(null);

    // Component Hooks
    const ability = useCanAbility();

    // Component Variables
    const canEditAssessment = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.ASSESSMENT.GENERAL);

    useEffect(() => {
        setLastQuestion(null);
    }, [questionId]);

    useEffect(() => {
        const timerID = setTimeout(() => {
            setDebounceComment(commentValue);
        }, 500);
        return () => clearTimeout(timerID);
    },[commentValue]);

    useEffect(() => {
        if(debounceComment !== null) setComment({ id: questionId , comment: debounceComment, type: 'question'});
    },[debounceComment]);

    const onOptionClick = (optionId) => {
        setLastQuestion(questionId);
        if (questionId !== lastQuestion) {
            showNextButtonAction(false);
            if (isAuthenticated) {
                saveAnswer({
                    assessmentId: assessment.id,
                    assessmentExecutionId,
                    categoryId: selectedCategoryId,
                    sectionId: selectedSectionId,
                    questionId,
                    optionId
                }, isAssessmentEnd);
            } else {
                selectAnswer({ categoryId: selectedCategoryId, sectionId: selectedSectionId, questionId, optionId });
                if (type === 'basic') {
                    setTimeout(function () {
                        if (isAssessmentEnd) {
                            toggleAssessmentCommentModal(true);
                        } else {
                            setNextQuestion();
                        }
                    }, 1000);
                } else {
                    setTimeout(function () {
                        scrollToRef(optionsEndRef);
                    }, 1000);
                }
                if (previousQuestion===null || previousQuestion<questionId){
                    amplitude.getInstance().logEvent(`Cyber Checkup View - Answer Question`,{'Question id': `Q${questionId}`});
                }
            }
            trackEvent("answer_question", "assessment", `answer_question_${isAuthenticated ? "authenticated" : "guest"}`, `q${questionId}_r${optionId}`);
            
        }
        setPreviousQuestion(questionId)
        //setAnswerLater(false);

    }

    // const onCheckboxClick = () => {
    //     setAnswerLater(!answerLater);
    //     if (!answerLater) {
    //         if(isAuthenticated){
    //             saveAnswer({ questionId, optionId: null });
    //         }else{
    //             deselectAnswer(questionId);
    //         }
    //     }
    // }

    const getFullAssessmentOptionClassName = () => {
        const isDisabled = !canEditAssessment;
        return `full-assessment-options d-flex flex-wrap mb-3 ${isDisabled ? 'option-disabled' : ''}`
    }

    const renderOptions = (options, color, answer) => {
        switch(type) {
            case 'full': {
                return (
                    <>
                        <FormGroup
                            className={getFullAssessmentOptionClassName()}
                        >
                            {options.map(({ label, id }, index ) => {
                                const isSelected = answer === id;
                                return (
                                    <Col key={`option-${index}`} xs={6} sm={3} className="align-self-stretch px-2 mb-3">
                                        <Card
                                            className={`assessment-option-card mb-0 ${isSelected ? 'selected-option': ''}`}
                                            onClick={()=>{
                                                selectAnswer({ categoryId, sectionId, questionId, optionId: id });
                                            }}
                                        >
                                        <FormGroup
                                            check
                                            className="custom-radio custom-radio-blue"
                                        >
                                            <Input id={`option-${id}`} type="radio" checked={isSelected} readOnly/>
                                            <Label className="mb-0 font-weight-bold">
                                                {label}
                                            </Label>
                                        </FormGroup>
                                        </Card>
                                    </Col>
                                )
                            })}
                        </FormGroup>
                        {/* <TextArea
                            placeholder="Leave your additional comments here…"
                            rows={1}
                            minRows={1}
                            maxRows={3}
                            className="full-assessment-comment form-control dark text-area-grow"
                            callback={(value)=>{ setCommentValue(value) }}
                            callOnClick={false}
                            value={commentValue !== null ? commentValue : (questionComments && questionComments[questionId] ? questionComments[questionId] : '')}
                        /> */}
                    </>
                )
            }
            default: {
                return (
                    <div className={`assessment-options-container ${type}`}>
                        {options.map(({label, feedback, id}, index)=>{
                            const isSelected = answer === id;
                            const customClassName =  isSelected ? `btn-selected-${ type === 'basic' ? 'basic' : color }`:'';
                            return (
                                <div
                                    key={id}
                                    className={`assessment-option ${type} btn btn-${ type === 'basic' ? 'basic' : color } d-flex flex-wrap justify-content-center
                                                ${customClassName}`
                                            }
                                    onClick={() => onOptionClick(id)}
                                    onKeyPress={() => onOptionClick(id)}
                                    tabIndex="0"
                                    role="button"
                                    aria-label={`${label}${isSelected ? ". "+feedback:''}`}
                                >
                                    <div className="text-center" aria-hidden="true">
                                        <div>
                                            {
                                                {
                                                    0: <UisRocket />,
                                                    1: <UisCheckCircle />,
                                                    2: <UisChartPie />,
                                                    3: <UisTimesCircle />
                                                }[index]
                                            }
                                        </div>
                                        <div
                                            className="option-label d-inline-flex justify-content-center align-items-center"
                                        >
                                            {label}
                                        </div>
                                    </div>
                                    <div className={`${ type === 'basic' ? 'basic' : color }-text option-feedback text-center justify-self-end w-100`} aria-hidden="true">
                                        {isSelected ?feedback:''}
                                    </div>
                                    <div ref={optionsEndRef} className="optionsEnd" aria-hidden="true"></div>
                                </div>
                            )
                        })}
                    </div>
                )

            }
        }

    }

    if(!options){
        return null;
    }

    const answer = questionAnswers ? questionAnswers[questionId] : null;

    return (
        <React.Fragment>
            {renderOptions(options, color, answer)}
            {/* <div className="custom-control custom-checkbox answer-later">
            <input
                type="checkbox"
                className="custom-control-input text-secondary"
                id={`${questionId}`}
                checked={answerLater}
                onChange={()=> onCheckboxClick()}
            />
                <label className="custom-control-label answer-later" htmlFor={`${questionId}`}>I don’t know, answer this later</label>
            </div> */}
        </React.Fragment>
    )
}
const makeMapStateToProps = () => {
    const getCategoryColor = makeCategoryColor();
    const getAssessmentStatus = makeAssessmentStatusSelector();
    const mapStateToProps = (state) => {
      return {
        options:  state.assessment.assessment.options,
        assessment:  state.assessment.assessment,
        answers: state.answers,
        color: getCategoryColor(state),
        isAuthenticated: state.auth.isAuthenticated,
        isAssessmentEnd: getAssessmentStatus(state).isAssessmentEnd,
        selectedCategoryId: state.assessment.selectedCategoryId,
        selectedSectionId: state.assessment.selectedSectionId
      }
    }
    return mapStateToProps;
}
const mapDispatchToProps = {
    saveAnswer,
    selectAnswer,
    deselectAnswer,
    setNextQuestion,
    toggleAssessmentCommentModal,
    showNextButtonAction,
    setComment
}

export default connect(makeMapStateToProps, mapDispatchToProps)(Options)
