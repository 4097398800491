import React from 'react';
import { Button } from 'reactstrap';
import SortButton from '../layout/buttons/SortButton';
import InfiniteDropdown from '../layout/InfiniteDropdown';
import SearchBar from '../layout/filters/SearchBar';
import { parseCompaniesToOptions } from '../../utils/helpers';
import API from '../../api';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Can } from '../../contexts/AbilityContext';
import { CREATE_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';


//  Component
//  TODO: add partners endpoint when ready
const AutomationRulesFilters = (props) => {
  //  Parsing props
  const {
    initialFilters,
    filters,
    updateActiveFilters,
    setFilters,
    changeSort,
  } = props;

  //  Initializing APIs
  const { CompanyAPI, PartnerAPI } = API;

  //  Component hooks
  const history = useHistory();
  const { path } = useRouteMatch();

  //  Function to handle partner and company changes
  const handleFilterChanges = (fieldName, option = {}) => {
    //  Setting filters values
    const newValue = option?.value || null;
    if (newValue === filters[fieldName]) { return; }
    setFilters({ ...filters, offset: 0, [fieldName]: newValue });

    //  Updating active filters
    updateActiveFilters(fieldName, option?.label);
  };

  //  Function to get partners
  const getPartners = async (filters) => {
    const response = await PartnerAPI.handlers.getActivePartners({ company_id: null, filters });
    return { ...response };
  }

  //  Function to handle create automation rule action
  const createAutomationRule = () => {
    history.push(`${path}/rule/create`)
  };

  //  Rendering
  return (
    <div className="automation-rules-filters">
      <SortButton
        containerClassName="date-filter"
        labelClassName="date-filter-text"
        iconClassName="date-filter-icon"
        label="Recently Updated"
        sortField={'updated_at'}
        currentSort={filters?.sort || []}
        changeSort={changeSort}
      />
      <InfiniteDropdown
        emptyLabel="All Members"
        extraClassName="dropdown-filter"
        errorMessage="Error getting members"
        apiFunction={CompanyAPI.handlers.getImpersonationList}
        apiKey={CompanyAPI.keys.getImpersonationList}
        parseElementsToOptions={parseCompaniesToOptions}
        onChange={(option) => handleFilterChanges('company_id', option)}
        restoreToDefault={initialFilters.company_id === filters.company_id}
        selectedElements={[]}
      />
      <InfiniteDropdown
        emptyLabel="All Partners"
        extraClassName="dropdown-filter"
        errorMessage="Error getting partners"
        apiFunction={getPartners}
        apiKey={PartnerAPI.keys.getActivePartners}
        parseElementsToOptions={parseCompaniesToOptions}
        onChange={(option) => handleFilterChanges('partner_id', option)}
        restoreToDefault={initialFilters.partner_id === filters.partner_id}
        selectedElements={[]}
      />
      <SearchBar
        useSimpleContainer
        customClassName="search-box mr-2 mb-2 d-inline-block automation-search-bar"
        placeholder="Search by name, tag or keyword"
        value={filters.searchTerm}
        callback={(value) => handleFilterChanges('searchTerm', { value, label: value })}
        restoreToDefault={initialFilters.searchTerm === filters.searchTerm}
      />
      <Can
        I={CREATE_ACTION}
        a={PRIVILEGE_SOURCES.AUTOMATION.INTEGRATION_RULE}
      >
        <Button
          type="button"
          color="light"
          className="btn waves-effect waves-light create-rule-button"
          onClick={createAutomationRule}
        >
          <i className='bx bx-plus-circle mr-2 align-middle'></i>
          Create automation rule
        </Button>
      </Can>
    </div>
  );
};

export default AutomationRulesFilters;
