import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Select from 'react-select';
import SelectDropdownIndicator from './SelectDropdownIndicator';

const CallbackSelectField = forwardRef((props, ref) => {
    const {
        name,
        label,
        placeholder,
        customClassName,
        error,
        value,
        callback,
        options,
        feedback,
        tooltip,
        disabled,
        displayLabel = true,
    } = props;

    const [newValue, setNewValue ] = useState(null);
    const [savedValue, setSavedValue ] = useState(value);
    const [prevValue, setPrevValue] = useState(null);


    useEffect(() => {
        if (!value) setNewValue(value);
    }, [value]);

    useEffect(() => {
        if (newValue && (newValue?.value !== savedValue?.value)) {
            setPrevValue(savedValue);
            setSavedValue(newValue);
            callback(newValue);
        }
    }, [newValue]);

    useEffect(() => {
        setSavedValue(value)
    }, [value]);

    const DropdownIndicator = (props) => {
        return <SelectDropdownIndicator {...props}/>
    }

    const [isUndo, setIsUndo] = useState(false);
    useEffect(() => {
        if (isUndo && prevValue) {
            setNewValue(prevValue);
            setIsUndo(false);
        }
    }, [isUndo]);

    useImperativeHandle(ref, () => {
        return {
            undoSelection: () => setIsUndo(true),
            selectOption: (id) => setNewValue(id),
        };
    });

    const renderLabel = () => {
        if(!displayLabel) return null;
        return (
            <div className="label-container">
                <label htmlFor={name} className="input-label">{label}</label>
                {tooltip}
            </div>
        )
    }

    return (
        <React.Fragment>
            <div className="form-group" style={{marginBottom:"20px"}} >
            {renderLabel()}
            <div className="select-container">
                <Select
                    value={savedValue}
                    onChange={(value) => setNewValue(value)}
                    options={options}
                    className={`react-select-container ${savedValue? 'has-value': ''} ${customClassName}`}
                    classNamePrefix="react-select"
                    components={{ DropdownIndicator }}
                    placeholder={placeholder}
                    isDisabled={disabled}
                    isSearchable={true}
                />
            </div>
            <div className="feedback">{feedback}</div>
            <div className="invalid-feedback">{error}</div>
        </div>
        </React.Fragment>
    )
});

export default CallbackSelectField;
