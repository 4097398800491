import filterTypes from "./filterTypes"

export const issueTypes = {
    VULNERABILITIES: "VULNERABILITIES",
    GRC: "GRC",
    GROUP_INDIVIDUAL: 'GROUP_INDIVIDUAL',
    ALERT: 'ALERT'
}

export const vulnerabilityIssueChildTypeInfo = {
    parentLabel: 'Issue',
    childLabel: 'Subtask',
    parentColor: 'light',
    childColor: 'bubbles'
}

export const grcIssueChildTypeInfo = {
    parentLabel: 'Issue',
    childLabel: 'Subtask',
    parentColor: 'per-gray',
    childColor: 'unassigned'
}

export const priorityColorsByLabel = {
    'low': 'great',
    'medium': 'good',
    'high': 'warning',
}

export const riskColorsByLabel = {
    'low': 'great',
    'medium': 'good',
    'high': 'warning',
    'critical': 'critical',
}

export const criticalityColorsByLabel = {
    'low': 'great',
    'medium': 'good',
    'high': 'warning',
    'critical': 'critical',
}

//  GRC default issue payload
export const defaultGRCIssue = {
    assigned_to: null,
    name: '',
    description: '',
    severity: null,
    status: null,
    comments: {
        list: []
    },
    priority: null,
    due_date: null,
    parent_issue_id: null,
    files: [],
};

//  GRC overdue filters
export const grcOverdueFilters = [
    { value: null, label: 'All' },
    { value: false, label: 'On Time' },
    { value: true, label: 'Overdue' },
];

export const RESOLVED_STATUS = 4;

export const issueGroupFilterKeysMap = {
    company_id: {
        filterName: 'multiple_company_id',
        type: filterTypes.multiple
    },
    solution_id: {
        filterName: 'multiple_solution_id',
        type: filterTypes.multiple
    },
    partner_id: {
        filterName: 'source',
        type: filterTypes.multipleObjectsType,
    },
}
