export default {
    'ITAM': {
        className: "itam",
        color: '#787BEF',
    },
    'TVM': {
        className: "tvm",
        color: '#FF158A'
    },
    'SEM': {
        className:"sem",
        color: '#4ECCC6'
    },
    'OVERALL': {
        className: 'overall',
        color: '#94FFFF'
    },
    'DEFAULT': {
        className: 'overall',
        color: '#94FFFF'
    },
    'FREEMIUM': {
        className: 'freemium',
        color: '#605E92',
    }
};
