import _ from 'lodash';
import { createSelector } from 'reselect';

const getCategories = state => state.assessment.assessment.categories;

const makeCategoriesIds = () => {
    return createSelector( 
        getCategories,
        categories => {
            if(!categories) return
            let questionOrder = 1;
            const categoriesIds = categories.map( category => {
                const orderedSections = _.sortBy(category.sections,'order');
                const sectionIds = orderedSections.map( section => {
                    const questionIds = _.orderBy(section.questions, 'order').map( question => {
                        return { 
                            id: question.id,
                            order: questionOrder++
                        }
                    })
                    return {
                        id: section.id,
                        questionIds
                    }
                });
                return {
                    id: category.id,
                    sectionIds
                }
            });
            return Object.values(categoriesIds);
        }
    );
}
export default makeCategoriesIds;
