import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { logout } from '../../actions/auth';

const Logout = (props) => {

    useEffect(() => {
        props.logout(props.history);
      });

    return (
            <></>
          );
    }

export default withRouter(
    connect(
        null,
        { logout }
    )(Logout)
);
