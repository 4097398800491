import React from 'react';
import errorImage from '../../assets/images/warning-iso.png'
const ErrorMessage = (props) => {
    const {text, type = 'icon', button, customStyle, theme = 'light', wrapperClassName } = props;
    const iconTypes = {
        image: (
            <img
                src={errorImage}
                width="193px"
                className="img-fluid"
                style={{ marginLeft: "49.5px"}}
                alt="warning"
            />
        ),
        icon: <i className='bx bx-error text-critical error-icon' />,
        solidIcon: 	<i className="error-icon mdi mdi-alert text-light-gray"  />
    }
    return (
        <div
            className={`error-message-container d-inline-flex justify-content-center flex-column align-items-center w-100 ${wrapperClassName}`}
            style={customStyle}
        >
            <div className="error-message inline-flex justify-content-center">
                    <div className="d-flex align-items-center justify-content-center icon-container">
                        {iconTypes[type]}
                    </div>
                <div className={`error-title mb-2 ${theme === 'dark' ? 'text-white' :'text-critical'} ${type === "solidIcon" ? 'd-none': ''}`}>
                    Oops!
                </div>
                <div className={`error-text mb-4 mx-auto ${theme === 'dark' ? 'text-white' : 'dark'}`} tabIndex="0">{text}</div>
                <div className="d-flex justify-content-center">
                    {button}
                </div>
            </div>
        </div>
    )
}
export default ErrorMessage;
