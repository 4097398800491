import { companyRiskLimit } from "../constants/risk_manager";

const parseCompanyRisk = (companyRisk) => {
    //  Getting needed information
    const {
      id,
      risk_statement,
      severity_id,
      likelihood_id,
      initial_risk_score,
      risk_treatment_id,
      comment,
      residual_severity_id,
      residual_likelihood_id,
      residual_risk_score,
      updated_at,
    } = companyRisk;

    //  Returned parsed company risk
    return {
      id,
      company_risk_id: id,
      risk_number: risk_statement?.code,
      risk_name: risk_statement?.label,
      severity_id,
      likelihood_id,
      initial_risk_score,
      risk_treatment_id,
      control_description: comment,
      residual_severity_id,
      residual_likelihood_id,
      residual_risk_score,
      owner: risk_statement?.user,
      updated_at,
    }
}

export const parseCompanyRisks = (company_risks = []) => {
    return company_risks.map((risk) => parseCompanyRisk(risk));
};

export const getCompanyRiskListInitialFilters = (company_id = null) => {
    return {
        company_id,
        offset: 0,
        limit: companyRiskLimit,
    };
}
