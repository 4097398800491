import React from 'react'
import { Button } from 'reactstrap';
import DropdownButton from './buttons/DropdownButton';

const BulkActionsMenu = (props) => {
    const {
        options,
        className = "",
        show,
        bulkMenuId = '',
        bulkMenuRef,
        bulkMenuStyle = {},
        isSticky = false,
    } = props;

    const renderIcon = (iconClassName) => {
        if(!iconClassName) return;
        return (
            <i className={`dropdown-button-icon mr-2 ${iconClassName}`}/>
        )
    }

    const renderOptions = () => {
        if(!options) return;

        return options.map((button, index) => {
            return button.options ? (
                <DropdownButton
                    key={`dropdown-button-option-${index}`}
                    label={button.label}
                    className="btn-bulk-actions-menu mr-2"
                    iconClassName={button.iconClassName}
                    options={button.options}
                />
            ): (
                <Button
                    key={`button-option-${index}`}
                    className="btn-bulk-actions-menu mr-2"
                    onClick={() => {
                        if(button.callback) {
                            button.callback()
                        }
                    }}
                >
                    {renderIcon(button?.iconClassName)}
                    {button?.label}
                </Button>
            )
        })
    }

    const getComponentClassName = () => {
        let componentClassName = 'bulk-actions-menu';
        if (isSticky) { componentClassName += ' higher-sticky-element light-background-element'; }
        return `${componentClassName} ${className}`;
    };

    return show && (
        <div
            id={bulkMenuId}
            ref={bulkMenuRef}
            className={getComponentClassName()}
            style={bulkMenuStyle}
        >
            <span className="bulk-actions-title mr-2">Bulk actions </span>
            {renderOptions()}
        </div>
    )
}

export default BulkActionsMenu;
