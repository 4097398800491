import React, { useState } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import ContextMenuOption from '../../context-menu/ContextMenuOption';

const DropdownButton = (props) => {
    const {
        label,
        className="",
        iconClassName,
        options
    } = props;

    // Component State
    const [isOpen, setisOpen] = useState(false);

    // Toggle Dropdown
    const toggle = () => setisOpen(!isOpen);

    const renderIcon = () => {
        return (
            <i className={`dropdown-button-icon mr-2 ${iconClassName}`}/>
        )
    }

    const renderDropdownOptions = () => {
        if(!options) return;
        return (
            <DropdownMenu
                className="custom-dropdown-menu"
            >
                {options.map((option, index) => {
                    return (
                        <ContextMenuOption
                            key={`dropdown-button-option-${index}`}
                            label={option?.label}
                            callback={() => {
                                if(option.callback) {
                                    option.callback()
                                }
                                toggle();
                            }}
                            className={`custom-dropdown-menu-option ${option.className}`}
                            isDisabled={option?.isDisabled}
                        />
                    )
                })}
            </DropdownMenu>
        )
    }

    return (
        <Dropdown
            isOpen={isOpen}
            toggle={toggle}
            className={`dropdown-button ${className} ${isOpen? 'selected': ''}`}
            tabIndex="0"
        >
            <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                aria-expanded={isOpen}
            >
                {iconClassName && renderIcon()}
                {label}
            </DropdownToggle>
            {renderDropdownOptions()}
        </Dropdown>
    )
}

export default DropdownButton;
