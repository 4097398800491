import {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { setAlert } from '../actions/alerts';
import { attachmentsMaxSize, extensionsTypeMap } from '../constants/common';
import {
  getFilesTotalSize,
  getSafeArray,
  getSupportedMimeTypes,
} from '../utils/helpers';
import { validateFiles } from '../utils/attachmentsHelpers';
import useMemoizedDispatch from './useMemoizedDispatch';
import useCustomMutation from './useCustomMutation';
import useDragOver from './useDragOver';
import awsController from '../controllers/aws';

const useFilesUploader = (params) => {
  //  Parsing params
  const {
    bucketName = '',
    directoryPath = '',
    files = [],
    handler: uploadFilesToDB,
    invalidators = [],
    extraConfig = {}, // Extra config attached to mutation
  } = params;

  //  Hook refs
  const uploaderRef = useRef(null);

  //  Hook variables
  const supportedMimeTypes = useMemo(() => getSupportedMimeTypes(extensionsTypeMap), []);

  //  Hook state
  const [uploadedFiles, setUploadedFiles] = useState(files);

  //  Function to handle files drop
  const onFileDrop = useCallback((event) => {
    const droppedFiles = event?.dataTransfer?.files;
    const eventParam = { target: { files: droppedFiles } };
    onChange(eventParam);
  }, [uploadedFiles]);

  //  Function to uploadFiles
  const uploadMultipleFiles = useCallback(async (filesToUpload) => {
    //  Uploading files to AWS bucket
    const awsResponse = await awsController.createMultipleFilesOnBucket(directoryPath, bucketName, filesToUpload);
    const safeResponse = getSafeArray(awsResponse);
    if (safeResponse.length === 0) { throw new Error('No files were uploaded.') }

    //  Storing files on local database
    const successFiles = safeResponse.filter((element) => !element?.error);
    const filesPayloads = successFiles.map((element) => {
      const { file } = element;
      return {
        name: file?.name,
        type: file?.type,
        size: file?.size,
      }
    });
    if (!filesPayloads.length === 0) { throw new Error('No files were uploaded.'); }
    const dbResponse = await uploadFilesToDB(filesPayloads);
    return dbResponse;
  }, [directoryPath, bucketName]);

  //  Hooks
  const { dispatch } = useMemoizedDispatch();
  const { handleDrop } = useDragOver({
    onDrop: onFileDrop,
    dropDependency: uploadedFiles,
  });
  const {
    mutate: uploadFiles,
    data,
    error,
  } = useCustomMutation(uploadMultipleFiles, invalidators, extraConfig);

  //  Watching API success response
  useEffect(() => {
    if (!data) { return; }
    if (!uploaderRef || !uploaderRef.current) { return; }
    uploaderRef.current.value = '';
  }, [data, uploaderRef]);

  //  Watching API error response
  useEffect(() => {
    if (!error) { return; }
    if (!uploaderRef || !uploaderRef.current) { return; }
    uploaderRef.current.value = '';
  }, [error, uploaderRef]);

  //  Watching files changes
  useEffect(() => {
    setUploadedFiles(files);
  }, [files?.length]);

  //  Function to handle change
  const onChange = useCallback((e) => {
    //  Gettin files from event
    const { target } = e;
    const files = target?.files;
    const safeFiles = [];
    for (const key of Object.keys(files || {})) {
      safeFiles.push(files[key]);
    }
    if (safeFiles.length == 0) { return; }

    //  Validating max size of files (performance issues)
    const totalSize = getFilesTotalSize(safeFiles);
    const limitExceeded = totalSize > attachmentsMaxSize;
    if (limitExceeded) {
      dispatch(setAlert(`Attachments max size is ${attachmentsMaxSize} MB`, 'danger'));
      uploaderRef.current.value = '';
      return;
    }

    //  Validating file extensions
    const areValidFiles = validateFiles(safeFiles, supportedMimeTypes);
    if (!areValidFiles) {
      dispatch(setAlert('Batch contains unsupported files.', 'danger'));
      uploaderRef.current.value = '';
      return;
    }

    //  Creating loading files to add
    const loadingFiles = safeFiles.map((file) => ({ name: file?.name, isLoading: true }));
    setUploadedFiles([...loadingFiles, ...uploadedFiles]);

    //  Uploading files
    uploadFiles(safeFiles);
    uploaderRef.current.value = '';
  }, [uploadedFiles, directoryPath, bucketName]);

  //  Function to remove loading elements from state
  const removeLoadingFiles = () => {
    const newFiles = uploadedFiles.filter((file) => !file.isLoading);
    setUploadedFiles(newFiles);
  }

  //  Exporting assets
  return {
    uploadedFiles,
    data,
    error,
    uploaderRef,
    onChange,
    handleDrop,
    removeLoadingFiles,
  };
};

export default useFilesUploader;
