import React from 'react';
import { useSelector } from 'react-redux';
import API from '../../../api';
import { LIST_ACTION, PRIVILEGE_SOURCES } from '../../../constants/privileges';
import useCanAbility from '../../../hooks/useCanAbility';
import useCustomMutation from '../../../hooks/useCustomMutation';
import useHandleApiResponse from '../../../hooks/useHandleApiResponse';
import useUserManagerActions from '../../../hooks/useUserManagerActions';
import companyIdSelector from '../../../selectors/companyIdSelector';
import CustomToggleSwitch from '../../layout/CustomToggleSwitch';
import UserManagerActionsModals from './UserManagerActionsModals';

const UserStatusSwitch = (props) => {

  // User manager table status switch
  const {
    user,
    id = user?.id,
    isChecked = user?.active,
  } = props;

  // Initializing API
  const { UserAPI } = API;

  //  Watching redux store
  const companyId = useSelector(state => companyIdSelector(state));
  const currentUser = useSelector((state) => state.auth.user);

  // Component Hooks
  const ability = useCanAbility();
  const canUserManage = ability.can(LIST_ACTION, PRIVILEGE_SOURCES.PROFILE.USER_MANAGEMENT);

  const {
    showDisableModal,
    toggleDisableModal
  } = useUserManagerActions({
    currentUser : currentUser,
    ids : [id],
  });

  const {
    data: userActionResponse,
    error: userActionError,
    isLoading: userActionLoading,
    mutate: callUserAction,
  } = useCustomMutation(
    (params) => UserAPI.handlers.executeUserAction(params),
    UserAPI.invalidators.executeUserAction,
  );

  useHandleApiResponse({
    data: userActionResponse,
    errorData: userActionError,
    successMessage: `User enabled`,
  });

  // Function to enable or disable user
  const handleChange = () => {
    if (isChecked) {
      toggleDisableModal();
      return;
    }
    const payload = {
      action: 'enable',
      users: [id],
      company: companyId
    };
    callUserAction(payload);
  }

  return (
    <>
      <CustomToggleSwitch
        containerClassName="switch-container small-switch"
        spanClassName="automation-slider"
        checkedLabelClassName="checked-toggle-cell"
        unCheckedLabelClassName="unchecked-toggle-cell"
        includeLabel={false}
        checked={isChecked}
        setChecked={handleChange}
        disabled={!canUserManage || id === currentUser?.id}
      />
      <UserManagerActionsModals
        company_id={companyId}
        users={[user] || []}
        selectedIds={[id]}
        showDisableModal={showDisableModal}
        toggleDisableModal={toggleDisableModal}
      />
    </>
  );
}

export default UserStatusSwitch;