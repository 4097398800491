export const SMALL_NOTIFICATIONS_MAX_NUMBER = 5;
export const LARGE_NOTIFICATIONS_MAX_NUMBER = 10;

export const inAppNotificationsItems = {
  TASK: 'TASK',
  ISSUE: 'ISSUE',
  NOTE: 'NOTE',
};

export const inAppNotificationsTypes = {
  RESOLUTION: 'RESOLUTION',
  ASSIGNMENT: 'ASSIGNMENT',
  UPDATE: 'UPDATE',
  RESOLUTION_NOTES: 'RESOLUTION_NOTES',
  BULK_NOTES: 'BULK_NOTES',
};

export const getIssueCardTitle = (issue) => {
  const TITLES = {
    ISSUE_ASSIGNMENT: 'Issues assigned to you by: $creatorName$',
    TASK_ASSIGNMENT: 'Tasks assigned to you by: $creatorName$',
    TASK_RESOLUTION: '$creatorName$ marked as resolved these tasks',
    ISSUE_RESOLUTION: '$creatorName$ marked as resolved these issues',
    ISSUE_UPDATE: '$creatorName$ bulked modifications for the issues',
    TASK_UPDATE: '$creatorName$ bulked modifications for the tasks',
    ISSUE_NOTE: '$creatorName$ bulked modifications for the issues',
    TASK_NOTE: 'Updates were made for the following tasks'
  }
  const title = TITLES[issue.notification_type_name];
  const defaultTitle = TITLES['ISSUE_ASSIGNMENT'];

  return (title || defaultTitle).replace('$creatorName$', issue.creator_name);
}
