import React, { useMemo } from 'react';

const ActiveFilters = (props) => {
  //  Parsing props
  const {
    currentFilters = {},
    initialFilters = {},
    setFilters = {},
    setActiveFilters,
    updateActiveFilters,
    activeFilters = [],
    title = 'Listing Elements',
    totalMessage = '0 Elements',
  } = props;

  //  Component variables
  const hasActiveFilters = useMemo(() => activeFilters.length > 0, [activeFilters]);

  //  Function to delete one filter
  const removeFilter = (fieldName) => {
    const initialValue = initialFilters[fieldName];
    setFilters({ ...currentFilters, offset: 0, [fieldName]: initialValue });
    updateActiveFilters(fieldName, null);
  }

  //  Function to clear all filters
  const clearAllFilters = () => {
    setFilters(initialFilters);
    setActiveFilters([]);
  }

  //  Function to render title
  const renderTitle = () => {
    return (
      <div className="active-filters-title">
        {`${title} ${hasActiveFilters ? 'filtered by: ' : ''}`}
      </div>
    );
  };

  //  Function to render filter list
  const renderFilterList = () => {
    const filters = activeFilters.map((filter) => {
      return (
        <div
          className="filter-item"
          onClick={() => removeFilter(filter.fieldName)}
        >
          <div className="inner-container">
            < div className="bx bx-x" />
            <div className="filter-value">
              {filter.value}
            </div>
          </div >
        </div >
      );
    });

    if (filters.length === 0) { return filters; }

    const clearItem = (
      <div
        className="clear-item"
        onClick={() => clearAllFilters()}
      >
        <div className="inner-container">
          <div className="bx bx-x" />
          <div className="filter-value">
            Clear all
          </div>
        </div>
      </div>
    );

    return [...filters, clearItem];
  };

  //  Rendering
  return (
    <div className="active-filters-container">
      {renderTitle()}
      <div className="filter-list-container">
        {renderFilterList()}
      </div>
      <div className="total-label">
        {totalMessage}
      </div>
    </div>
  );
};

export default ActiveFilters;
