import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useCustomQuery from './useCustomQuery';

const useTrustBuilderRequest = (props) => {
  //  Parsing hook props
  const {
    apiFunction,
    apiKey,
    company_id,
    lazyQuery = false,
  } = props;

  //  Getting token from router
  const { token } = useParams();

  //  Hook state
  const [hasVcyso, setHasVcyso] = useState(false);
  const [enabled, setEnabled] = useState(!lazyQuery);
  const [queryParams, setQueryParams] = useState({ company_id, token });

  //  Calling API for trust builder content
  const {
    data: trustBuilderContentResponse,
    error: trustBuilderContentError,
    isLoading: trustBuilderContentLoading,
    refetch: retryTrustBuilderRequest,
  } = useCustomQuery(
    [apiKey, queryParams],
    ({ queryKey }) => apiFunction(queryKey[1]),
    { enabled }
  );

  //  Watching trust builder response
  useEffect(() => {
    if (!trustBuilderContentResponse) { return; }
    const { preview = false } = trustBuilderContentResponse;
    setHasVcyso(!preview);
  }, [trustBuilderContentResponse]);

  //  Function to make request
  const makeTrustBuilderRequest = (params) => {
    setQueryParams(params);
    setEnabled(true);
  };

  //  Returning assets
  return {
    trustBuilderContentResponse,
    trustBuilderContentError,
    trustBuilderContentLoading,
    token,
    hasVcyso,
    retryTrustBuilderRequest,
    makeTrustBuilderRequest
  }
};

export default useTrustBuilderRequest;
