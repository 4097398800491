import React from 'react';
import DashboardRow from '../../common/dashboard/DashboardRow';
import DashboardWidgetContainer from '../../common/dashboard/DashboardWidgetContainer';
import InstallsBySolution from './InstallsBySolution';
import DeviceStatus from './DeviceStatus';
import CurrentProtectionStatus from './CurrentProtectionStatus';

const DeviceManagementRow = (props) => {
  //  Rendering
  return (
    <DashboardRow
      title="Device Management"
      linkText="See all"
      linkUrl="/dashboard/devices"
    >
      <CurrentProtectionStatus />
      <DeviceStatus />
      <InstallsBySolution />
    </DashboardRow>
  );
};

export default DeviceManagementRow;
