import React, { useState } from "react";
import { removeItemFromArray } from "../../utils/helpers";
import CustomAccordionItem from "./CustomAccordionItem";

const CustomAccordion = (props) => {

  const {
    stayOpen = true,
    defaultOpen = [], // Contains the indexes of the items of the accordion that will be open by default
    items = [], // The actual items to render
    customContainerClassName = "",
    customCardClassName = "",
  } = props;

  // State to manage the opening and closing cards of the accordion
  const [activeIndexes, setActiveIndexes] = useState(defaultOpen);

  const renderItems = () => {
    return items.map((value, index) => {
      const isExpanded = activeIndexes.includes(index);
      return (
        <CustomAccordionItem
          label={value?.label}
          name={value?.name}
          component={value?.component}
          index={index}
          onButtonCollapseClick={onButtonCollapseClick}
          isExpanded={isExpanded}
          customCardClassName={customCardClassName}
        />
      );
    });
  };

  // Function to add and remove cards from the 'active' state
  const onButtonCollapseClick = (index) => {
    if (activeIndexes.includes(index)) {
      const positionInArray = activeIndexes.indexOf(index);
      setActiveIndexes(removeItemFromArray(activeIndexes,positionInArray));
    } else {
      setActiveIndexes([...activeIndexes,index]);
    }
  };

  return (
    <div
      className={`custom-accordion-container ${customContainerClassName}`}
      id="accordionExample"
    >
      {renderItems()}
    </div>
  );
};

export default CustomAccordion;
