import React from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import LoadingContent from '../layout/LoadingContent';
import opportunitiesIcon from '../../assets/images/icons/opportunities.svg';
import myCysoIssuesIcon from '../../assets/images/redesign/mycyso-issues.svg';
import { useHistory } from 'react-router-dom';
import LetterGradeCircle from '../dashboard/LetterGradeCircle';


const AssessmentOpportunities = (props) => {

    const {
        isLoading,
        errorMessage,
        retryRequest,
        assessmentData
    } = props;

    // Component Hooks
    const history = useHistory();

    const renderIcon = (src, alt, className) => {
        return (
            <img
                src={src}
                alt={alt}
                className={`home-link-card-icon img-fluid mx-auto mb-3 ${className || ''}`}
            />
        )
    }

    // Render locked status when assessment hasn't been finished
    const renderLockedStatus = () => {
        const message = "See real time opportunities to Improve and stop wondering what to do, get actionable solutions to keep your company safe"
        return (
            <>
                {renderIcon(opportunitiesIcon, 'opportunities-icon', '')}
                <div className="locked-message mx-auto" >{message}</div>

            </>
        )

    }

    // Render Go to Issues Link
    const renderIssuesLink = () => {
        return (
            <div className="d-flex mt-auto">
                <Button
                    color="light"
                    className="btn-large mx-auto"
                    onClick={()=> {
                        history.push('/dashboard/issues')
                    }}
                >
                    Go to issues
                </Button>
            </div>
        )
    }

    // Render status when all controls are above accepted guideline and there no opportunities
    const renderAllCoveredStatus = () => {
       return (
            <>
                {renderIcon(myCysoIssuesIcon, 'my-cyso-icon', 'all-covered-icon')}
                <div className="all-covered-message mx-auto mb-3" >
                    <div className="text-center">We got  you covered</div>
                    <div className="text-center">Sit back, relax while we maintain your system and score</div>
                </div>
                {renderIssuesLink()}
            </>
        )
    }

    // Render Opportunities
    const renderOpportunities = () => {
        if(!assessmentData?.opportunities) return;
        return (
            <div className="assessment-opportunities-container">
                {assessmentData.opportunities.map((opportunity, index) => {
                    const { score_grade, code, control_label } = opportunity;
                    return (
                        <div
                            key={`assessment-opp-${index}`}
                            className="opportunity-element d-flex w-100"
                        >
                            <div className="d-inline-block mr-2">
                                <LetterGradeCircle grade={score_grade || 'D'}/>
                            </div>
                            <div className="d-inline-block">{`${code} ${control_label}`}</div>
                        </div>
                    )
                })}
            </div>
        )
    }

    // Render status when assessment has been finished
    const renderUnlockedStatus = () => {
        const allCovered = assessmentData?.opportunities?.length === 0;
        return (
            <>
             {allCovered ? renderAllCoveredStatus() : renderOpportunities()}
            </>
        )
    }

    return (
        <Card className="home-link-card assessment-opportunities-card align-self-lg-stretch h-100">
            <CardBody>
                <div className="home-link-title">
                    Opportunities to Improve
                </div>
                <LoadingContent
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    errorButtonCallback={retryRequest}
                >
                    {assessmentData?.cis_completed ? renderUnlockedStatus() : renderLockedStatus()}
                </LoadingContent>
            </CardBody>
        </Card>
    )
}

export default AssessmentOpportunities;
