import { recycleBinEntities } from '../constants/common';
import API from '../api';
import useCustomQuery from './useCustomQuery';

const useRecycleBinApiCall = (props = {}) => {
  //  Parsing params
  const {
    entity = recycleBinEntities.devices,
    params = {},
  } = props;

  //  Initializing APIs
  const { IssueAPI, GrcIssueAPI, DeviceAPI } = API;

  //  Function to get API main key
  const getAPIKey = () => {
    if (entity === recycleBinEntities.devices) {
      return DeviceAPI.keys.getArchivedDevices
    }

    if (entity === recycleBinEntities.issues) {
      return IssueAPI.keys.getArchivedIssues;
    }

    if (entity === recycleBinEntities.tasks) {
      return GrcIssueAPI.keys.getArchivedTasks;
    }

    return DeviceAPI.keys.getArchivedDevices
  }

  //  Function to get API function to use
  const getArchivedElements = async (params) => {
    if (entity === recycleBinEntities.devices) {
      return await DeviceAPI.handlers.getArchivedDevices(params);
    }

    if (entity === recycleBinEntities.issues) {
      return await IssueAPI.handlers.getArchivedIssues(params);
    }

    if (entity === recycleBinEntities.tasks) {
      return await GrcIssueAPI.handlers.getArchivedTasks(params);
    }

    return await DeviceAPI.handlers.getArchivedDevices(params);
  }

  //  Exporting assets
  return useCustomQuery(
    [getAPIKey(), params],
    ({ queryKey }) => getArchivedElements(queryKey[1]),
  );
};

export default useRecycleBinApiCall;
