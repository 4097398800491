import React from 'react';
import { Link } from 'react-router-dom';
import ErrorMessage from '../components/layout/ErrorMessage';

const PageNotFound = () => {

    const homeButton = (
        <Link 
            className="btn btn-light"
            to="/"
        >
            Take me home
        </Link>
    )
    return (
        <div className="w-100 h-minus-header bg-dirty-white bg-assessment">
            <ErrorMessage
                text="This page doesn’t exist"
                button={homeButton}
                customStyle={{ paddingTop: "10%" }}
                type='image'
            />
        </div>
    )
}

export default PageNotFound;
