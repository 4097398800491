import React, { useState } from 'react';

const HookFormPasswordField = (props) => {

  const {
    name,
    label,
    ariaLabel,
    className = '',
    wrapperClassName = '',
    labelCustomClassName,
    type,
    style,
    ariaRequired,
    register,
    isInvalid,
    errorMsgClassName,
    errorIconClassName,
    requirements,
    errorMsg,
  } = props;

  const [passwordFieldType, setPasswordFieldType] = useState(type);

  const handleClickShowPassword = () => {
    if (passwordFieldType === 'text') {
      setPasswordFieldType('password');
    } else {
      setPasswordFieldType('text');
    }
  };

  const renderShowPasswordIcon = () => {
    return (
      <i
        className={`bx bxs-${passwordFieldType === 'text' ? "hide" : "show"} form-show-icon`}
        onClick={() => handleClickShowPassword()}
      ></i>
    );
  };

  return (
    <div className={wrapperClassName}>
      {label ? <label htmlFor={name} className={labelCustomClassName}>{label}</label> : null}
      <div className="hook-input-container">
        <input name={name} aria-label={ariaLabel} aria-required={ariaRequired} id={name} type={passwordFieldType} className={`${className} ${isInvalid ? 'is-invalid':''}`} style={style}
          {...register(name, requirements)}>
        </input>
        <i className={errorIconClassName}></i>
        {renderShowPasswordIcon()}
      </div>
      <div className={errorMsgClassName}>{errorMsg}
      </div>
    </div >
  )
}

export default HookFormPasswordField;