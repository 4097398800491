import React, { useCallback } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { createExecutionAnswers } from '../../../actions/answers';
import { UilRedo } from '@iconscout/react-unicons'
import CollapsibleIconButton from '../../layout/CollapsibleIconButton';
import { trackEvent } from '../../../utils/gaTracker';
import InfoTooltip from '../tooltips/InfoTooltip';
import amplitude from 'amplitude-js';

const ComplianceLinks = (props) => {
    const {
        history,
        togGradingScale,
        showGradingScaleLink = true,
        showAssessmentLink = true,
        assessmentData,
        rightIcon,
        isGradingScaleATooltip = false,
    } = props;

    const defaultDispatch = useDispatch();
    const dispatch = useCallback(defaultDispatch, []);

    const onTakeAssessmentClick = () => {
        if (!_.isNil(assessmentData?.cyber_checkup_execution_id)) {
            amplitude.getInstance().logEvent('Home Page View - Retake Assessment');
            trackEvent("retake_assessment", "assessment", "retake_assessment");
            history.push('/assessment')
        } else {
            trackEvent("start_assessment", "assessment", "freemium_start_assessment");
            dispatch(createExecutionAnswers(assessmentData?.cis_assessment_id, history));
        }
    }

    /* This is a function that is called when the user clicks on the "See Grading Scale" button */
    const openGradingScale = () => {
        togGradingScale();
        amplitude.getInstance().logEvent('Home Page View - See Grading Scale');
    }

    const renderLinks = (links) => {
        return links.map(({ label, callback, iconComponent, className, tooltipIsClickable }, index) => {
            if (tooltipIsClickable) {
                return (
                    <React.Fragment key={`btn-compliance-link-${index}`}>
                        <div className='clickable-info-tooltip-button btn-compliance-link ml-0 mb-2'>
                            <InfoTooltip
                                label=""
                                showLabel={false}
                                info={label}
                                id='score-grading-scale-tooltip'
                                callback={callback}
                            />
                        </div>
                    </React.Fragment>
                );
            }
            return (
                <React.Fragment key={`btn-compliance-link-${index}`}>
                    <CollapsibleIconButton
                        className={`btn-compliance-link link-info ${className} ${rightIcon && 'mb-2'}`}
                        callback={callback}
                        icon={iconComponent}
                        label={label}
                        rightIcon={rightIcon}
                    />
                </React.Fragment>
            )
        })

    }

    return renderLinks([
        ...showAssessmentLink ? [{
            iconComponent: (<UilRedo />),
            label: `${assessmentData?.cis_completed ? 'Retake' : 'Continue'} Assessment`,
            className: "ml-0 retake-assessment-link",
            callback: onTakeAssessmentClick
        }] : [],
        ...showGradingScaleLink ? [{
            iconComponent: (<i className='bx bx-info-circle'></i>),
            label: "See grading scale",
            callback: openGradingScale,
            tooltipIsClickable: isGradingScaleATooltip
        }] : [],
    ]);
}
export default withRouter(ComplianceLinks);
