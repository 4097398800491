import React from 'react';
import { setIdsToDelete, setIdsToRestore } from '../../../actions/recycleBin';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import useCanHardDeleteElements from '../../../hooks/useCanHardDeleteElements';

const RecycleBinActionsCell = (props) => {
  //  Parsing props
  const { element } = props;

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const { canDelete } = useCanHardDeleteElements({ entity: element?.type });

  //  Function to handle restore
  const handleRestore = () => {
    dispatch(setIdsToRestore([element?.id]));
  };

  //  Function to handle delete
  const handleDelete = () => {
    dispatch(setIdsToDelete([element?.id]));
  };

  //  Function to render delete icon
  const renderDeleteIcon = () => {
    if (!canDelete) { return null; }
    return (
      <div
        className="action-icon bx bx-trash"
        onClick={() => handleDelete()}
      />
    );
  }

  //  Rendering
  return (
    <div className="actions-row">
      <div
        className="action-icon bx bx-rotate-right"
        onClick={() => handleRestore()}
      />
      {renderDeleteIcon()}
    </div>
  );
};

export default RecycleBinActionsCell;
