import React from 'react';
import { Button } from 'reactstrap';
import { abbreviateNumber } from '../../../utils/helpers';
import filterTypes from '../../../constants/filterTypes';
import defaultIconImg from '../../../assets/images/icons/default-partner-icon.svg'

const IconFilterButton = (props) => {
    const {
        currentValue,
        activeValue,
        callback,
        customStyle,
        className="",
        label,
        icon,
        count = null,
        filterType = filterTypes.single,
        defaultIcon = defaultIconImg
    } = props;

    const isActive = () => {
        return filterType === filterTypes.multiple ? currentValue?.includes(activeValue): currentValue
    }

    const onClick = () => {
        const value = filterType === filterTypes.single && currentValue ? null : activeValue
        callback(value, label)
    }

    const getCountLabel = () => {
        if(count === null) return null;
        return (
            <div className="d-inline-block">
               {`(${abbreviateNumber(count)})`}
            </div>
        )
    }

    const addDefaultSrc = (ev) => {
        ev.target.src = defaultIcon
    }

    const renderIcon = () => {
        if(!icon) return null;
        return (
            <div className="filter-icon-container d-inline-block mr-2">
                <img
                    className="img-fluid"
                    src={icon}
                    alt={label}
                    onError={addDefaultSrc}
                />
            </div>
        )
    }

    return (
        <Button
            color="light-gray"
            className={`btn-filter btn-filter-icon ${className}`}
            style={customStyle}
            active={isActive()}
            outline
            onClick={onClick}
        >
            {renderIcon()}
            <div className="filter-icon-label d-inline-block">{label}</div>
            {getCountLabel()}
        </Button>
    )
}

export default IconFilterButton;
