import React, {useEffect, useState} from 'react';
import { Button } from 'reactstrap';
import BasicModal from '../../layout/BasicModal';
import { scheduleComponentTypes } from '../../../constants/automation';
import CustomDatepicker from '../../calendar/CustomDatepicker';
import { mapScheduleToDateWithCustomTimezone } from '../../../utils/automationHelper';
import CustomTimepicker from '../../calendar/CustomTimepicker';
import InfiniteDropdown from '../../layout/InfiniteDropdown';
import { parseTimezonesToOptions } from '../../../utils/helpers';
import { CompanyAPI } from '../../../api/services/CompanyAPI';
import moment from 'moment-timezone';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import { setAlert } from '../../../actions/alerts';
import useCustomMutation from '../../../hooks/useCustomMutation';
import useHandleApiResponse from '../../../hooks/useHandleApiResponse';

const ScheduleScanModal = (props) => {
  //  Parsing props
  const {
    isMasterScan = false,
    domainPublicIds,
    showModal,
    toggleModal,
    companyId
  } = props;

  const { dispatch } = useMemoizedDispatch();

  //  Component state
  const [date, setDate] = useState(mapScheduleToDateWithCustomTimezone());
  const [dateAndHour, setDateAndHour] = useState(null);

  const {
    data: addScheduledScanData,
    error: addScheduledScanError,
    isLoading: addScheduledScanLoading,
    mutate: addScheduledScan,
  } = useCustomMutation(
    (params) => CompanyAPI.handlers.addScheduledScan(params),
    CompanyAPI.invalidators.addScheduledScan
  );


  //  Watching date changes
  useEffect(() => {
    const { date: newDate, hour, timezone } = date;
    if (!newDate || !hour) { setDateAndHour(null); return; }
    const dateToSet = moment(newDate, moment.tz.guess()).format('YYYY-MM-DD');
    const hourToSet = moment(hour, 'hh:mm A').format('HH:mm')
    const newSchedule = moment(`${dateToSet} ${hourToSet}`).toDate().toISOString();
    setDateAndHour(newSchedule);
  }, [date]);

  useHandleApiResponse({
    data: addScheduledScanData,
    errorData: addScheduledScanError,
    successCallback: () => {
      if(!addScheduledScanData) return ;
      toggleModal();
      dispatch(setAlert(`Scan${domainPublicIds?.length > 1 ? 's' :''} scheduled successfully`, 'success'));
    },
    errorCallback: () => {
      if(!addScheduledScanError) return ;
      //dispatch(setAlert('Error scheduling scan', 'danger'));
    }
  });

  //  Function to handle calendar changes
  const handleCalendarChanges = (value) => {
    setDate({ ...date, date: value });
  };

  //  Function to handle time changes
  const handleTimeChanges = (value) => {
    setDate({ ...date, hour: value });
  };

  //  Function to handle time changes
  const handleTimezoneChanges = (value) => {
    setDate({ ...date, timezone: value.label });
  };

  // Function to verify if there are no fields empty (date, hour, timezone)
  const verifySchedule = () => {
    if (date.date && date.hour && date.timezone) return true;
    return false;
  };


  //  Function to handle saving a scan
  const handleSave = () => {    
    //Verify we have a date, hour and timezone for scheduling the scan
    if (!verifySchedule()) {
      dispatch(setAlert('One or more fields are missing', 'danger'));
      return;
    };

    //  Constructing the payload
    const data = {
      date: dateAndHour,
      timezone: date.timezone,
      company_id: companyId,
      domain_id: domainPublicIds,
      is_master_scan: isMasterScan
    }
    addScheduledScan(data);
  };

  // Function to create the payload the timezones endpoint needs
  const getTimezones = async (filters) => {
    const response = await CompanyAPI.handlers.getScanTimezones({
      text: filters?.searchText,
      offset: filters?.offset,
      limit: filters?.limit
    });
    return response;
  };

  //  Function to handle dropdown change
  const onTimezoneDropdownChange = (element) => {
    if (!element) {
      return;
    }
    handleTimezoneChanges(element);
  };

  //  Function to render footer
  const renderButtons = () => {
    return (
      <div className="d-flex justify-content-end">
        <Button
          className="btn-large mr-2"
          outline
          color="light-gray"
          onClick={() => toggleModal(false)}
        >
          Close
        </Button>
        <Button
          className="btn-large"
          color="light"
          onClick={() => handleSave()}
          disabled={addScheduledScanLoading || !verifySchedule()}
        >
          Request scan
        </Button>
      </div>
    );
  };

  const renderFieldComponent = (type) => {
    //Render the calendar component
    if (scheduleComponentTypes.CALENDAR === type) {
      return (
        <CustomDatepicker
          //maxDate
          value={date?.date}
          setValue={handleCalendarChanges}
        />
      );
    }
    //Render the time picker component
    if (scheduleComponentTypes.TIME === type) {
      return (
        <CustomTimepicker
          value={date?.hour}
          setValue={handleTimeChanges}
        />
      );
    }
    //  Rendering timezone dropdown
    if (type === scheduleComponentTypes.TIMEZONE) {
      return (
        <div className="content-label">
          <InfiniteDropdown
            emptyLabel='Timezones'
            errorMessage='Error getting timezones'
            apiFunction={getTimezones}
            apiKey={CompanyAPI.keys.getScanTimezones}
            parseElementsToOptions={parseTimezonesToOptions}
            onChange={onTimezoneDropdownChange}
            isMultiSelect={false}
            selectedElements={[]}
            customFilters={{ sort: ["name", "ASC"] }}
            customMenuPosition="absolute"
            ariaLabel="Select a timezone"
            menuHeight={130}
          />
        </div>
      );
    }
  };

  //Function to render the 3 components for scheduling scans
  const renderLabelComponent = (label, type) => {
    return (
      <div className={`${label}-container field-component-container`}>
        <div className='label'>{label}</div>
        {renderFieldComponent(type)}
      </div>
    );
  };

  //  Function to render content
  const renderContent = () => {
    return (
      <>
        {renderLabelComponent('Date',scheduleComponentTypes.CALENDAR)}
        {renderLabelComponent('Time',scheduleComponentTypes.TIME)}
        {renderLabelComponent('Timezone',scheduleComponentTypes.TIMEZONE)}
      </>
    );
  }

  //  Rendering
  return (
    <BasicModal
      header="Scan schedule"
      showModal={showModal}
      toggleModal={() => toggleModal(false)}
      customClassName="schedule-scan-modal"
      size=''
    >
      <div className="schedule-scan-container">
        <div className="schedule-scan-body">
          <div className="schedule-scan-content">
            {renderContent()}
          </div>
        </div>
      </div>
      <div className='footer'>
          {renderButtons()}
        </div>
    </BasicModal>
  );
};

export default ScheduleScanModal;

