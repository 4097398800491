import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Card, CardBody, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import FreemiumInformationWrapper from '../containers/FreemiumInformationWrapper';
import API from '../api';
import { changeLayout } from '../actions/layout';
import { setAlert } from '../actions/alerts';
import { registerVerificationMessage, registerAuthorzationMessage } from '../constants/pagesData';
import useMemoizedDispatch from '../hooks/useMemoizedDispatch';
import useCustomMutation from '../hooks/useCustomMutation';

const RegisterSuccessMessage = (props) => {
  //  Iniatializing API
  const { AuthAPI } = API;

  //  Watching redux store
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  //  Component state
  const [content, setContent] = useState(null);

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const history = useHistory();
  const location = useLocation();
  const {
    data: resendData,
    error: resendError,
    mutate: resendVerification,
    isLoading: resendLoading,
  } = useCustomMutation(
    (params) => AuthAPI.handlers.resendVerification(params),
    AuthAPI.invalidators.resendVerification,
  );

  //  Watching API success
  useEffect(() => {
    if (!resendData) { return; }
    dispatch(setAlert('Verification sent successfully.', 'success'));
  }, [resendData]);

  //  Watching API error
  useEffect(() => {
    if (!resendError) { return; }
    dispatch(setAlert('Error resending verification.', 'danger'));
  }, [resendError]);

  //  Watching is owner flag changes
  useEffect(() => {
    setContent(location?.state?.is_owner ? registerVerificationMessage : registerAuthorzationMessage);
  }, [location?.state?.is_owner]);

  //  Watching isAuthenticated changes to redirect to app
  useEffect(() => {
    if (!isAuthenticated) { return; }
    history.push('/');
  }, [isAuthenticated]);

  //  Watching public id changes
  useEffect(() => {
    if (location?.state?.public_id) { return; }
    history.push('/');
  }, [location?.state?.public_id]);

  //  Triggering layout changes
  useEffect(() => {
    dispatch(changeLayout('horizontal'));
  }, [changeLayout]);

  //  Function to handle resend
  const onResendClicked = () => {
    if (!location?.state?.is_owner) { return; }
    resendVerification(location?.state?.public_id);
  };

  //  Function to get title
  const getTitle = (title) => {
    if (!location?.state?.is_owner) { return title; }
    const textToReplace = location?.state?.email || 'your email';
    return title?.replace('{email}', textToReplace);
  }

  //  Function to render content div
  const renderContent = (value, className) => {
    if (!value) { return null; }
    return (
      <div className={className}>
        {value}
      </div>
    );
  };

  //  Function to render resend button
  const renderResendButton = () => {
    if (!location?.state?.is_owner) { return null; }
    return (
      <Button
        className="resend-button"
        onClick={() => onResendClicked()}
        disabled={resendLoading}
      >
        <div className="bx bx-refresh resend-icon" />
        <div className="resend-label">
          What email? Send it again please
        </div>
      </Button>
    );
  };

  //  Rendering
  return (
    <FreemiumInformationWrapper logoOnly>
      <Card
        color='white'
        className='success-message-container'
      >
        <CardBody className='success-message-wrapper'>
          {renderContent(getTitle(content?.title), 'message-title')}
          {renderContent(content?.body, 'message-content')}
          {renderResendButton()}
          {renderContent(content?.footer, 'message-footer-message')}
        </CardBody>
      </Card>
    </FreemiumInformationWrapper>
  );
};

export default RegisterSuccessMessage;
