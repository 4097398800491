import React from 'react'
import { useSelector } from 'react-redux';
import useDomainActions from '../../../hooks/useDomainActions';
import companyIdSelector from '../../../selectors/companyIdSelector';
import IconTooltip from '../../dashboard/tooltips/IconTooltip';
import DomainActionsModals from './DomainActionsModals';

const DomainActions = (props) => {
  const {
    domainPublicId,
    isMaster,
    startTime,
  } = props;

  //  Watching redux store
  const companyId = useSelector(state => companyIdSelector(state));

  //  Component hooks
  const {
    actions,
    showScheduleModal,
    showDeleteDomainModal,
    toggleDeleteDomainModal,
    toggleScheduleModal,
    updateDomainCount,
  } = useDomainActions({
    company_id: companyId,
    startTime,
    isMaster,
  })

  // Function to render actions
  const renderActions = () => {
    if (actions.length < 1) return null;
    return (
      <div className='domain-actions d-flex'>
        {actions.map((action, index) => {
          const {
            tooltipText,
            iconClassName,
            callback,
            disabled,
            ifDisabledTooltipText
          } = action;
          return (
            <div
              key={`domain-action-key-${index}`}
            >
              <IconTooltip
                targetId={`domain-action-${domainPublicId}-${index}`}
                iconClassName={disabled ? `${iconClassName} disabled` : iconClassName}
                callback={disabled ? null : callback}
                tooltipText={disabled ? ifDisabledTooltipText : tooltipText}
              />
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <>
      <DomainActionsModals
        company_id={companyId}
        public_ids={[domainPublicId]}
        showScheduleModal={showScheduleModal}
        showDeleteModal={showDeleteDomainModal}
        toggleScheduleModal={toggleScheduleModal}
        toggleDeleteModal={toggleDeleteDomainModal}
        updateDomainCount={updateDomainCount}
      />
      {renderActions()}
    </>
  )
}

export default DomainActions
