import _ from 'lodash';
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { setIssuesFilter } from '../actions/issues';
import { changeSubtabs, onSubtabSelected } from '../actions/navigation';
import { CREATE_ACTION, PRIVILEGE_SOURCES } from '../constants/privileges';
import companyIdSelector from '../selectors/companyIdSelector';
import packagesSelector from '../selectors/packagesSelector';
import { getSolutionFilterTabs } from '../utils/helpers';
import useCanAbility from './useCanAbility';
import useMemoizedDispatch from './useMemoizedDispatch';

const useSolutionTabs = (preventExecution = false) => {

    // Getting needeed info from redux store
    const packages = useSelector(state => packagesSelector(state));
    const authLoading = useSelector(state => state.auth?.loading)
    const impersonationLoading = useSelector(state => state.impersonation?.loading)
    const subtabs = useSelector(state => state.navigation?.subtabs);
    const selectedSubtab = useSelector(state => state.navigation?.selectedSubtab);
    const companyId = useSelector(state => companyIdSelector(state));

    // Hooks
    const { dispatch } = useMemoizedDispatch();
    const location = useLocation();
    const history = useHistory();
    const ability = useCanAbility();

    //  Hook variables
    const isGRCType = useMemo(() => {
        return location?.pathname?.includes('/task-manager')
    }, [location?.pathname]);
    const canImpersonate = ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.MULTIPLE.IMPERSONATE);

    // Watching packages changes
    useEffect(() => {
        if (preventExecution) { return; }

        const solutionParamName = 'solution';
        const queryParams = new URLSearchParams(location.search)
        const parentPath = location?.pathname?.replace('/task-manager','/issues')
        const subtabs = getSolutionFilterTabs(packages, !isGRCType, parentPath);
        const subtab = subtabs?.find((subtab) => subtab?.label?.toLowerCase() === queryParams?.get(solutionParamName));

        // If no subtab was found delete solution search params
        if (!subtab && ((!authLoading && !canImpersonate) || (canImpersonate && !impersonationLoading))) {
            queryParams.delete(solutionParamName)
            history.replace({ search: queryParams?.toString() })
        }
        dispatch(changeSubtabs(subtabs))
    }, [packages, isGRCType, preventExecution])

    // Watching query and companyId changes
    useEffect(() => {
        if (preventExecution) { return; }

        const queryParams = new URLSearchParams(location.search)
        const solutionParamName = 'solution';
        // TODO**: handle case when impersonation mode isn't active for Cydekicks
        if (!subtabs) {
            // Reset issues filter
            dispatch(setIssuesFilter('solution_id', null, null));
            queryParams.delete(solutionParamName)
            history.replace({ search: queryParams?.toString() })
            return;
        }
        // If a solution isn't selected, then select the All tab
        const selectedSolution = queryParams.get(solutionParamName) || 'all';
        const subtab = subtabs?.find((subtab) => subtab?.label?.toLowerCase() === selectedSolution) || subtabs[0];

        // Update issues filter

        // Set solution filter
        dispatch(setIssuesFilter('solution_id', subtab?.value, subtab?.label, null, null, true))

        if (isGRCType) {
            dispatch(onSubtabSelected({ path: location?.pathname }));
            return;
        }

        // Check that the selected subtab isn't the same one as the new one
        if (subtab && subtab?.label === selectedSubtab?.label && subtab?.identifier === selectedSubtab?.identifier) { return; }

        //  Select new subtab
        dispatch(onSubtabSelected(subtab));
    }, [location?.search, subtabs, companyId, isGRCType, preventExecution])
}

export default useSolutionTabs;
