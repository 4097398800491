import React from 'react';
import { Row } from 'reactstrap';
//import InformationExpandableBar from './InformationExpandableBar';
import RiskStatementStatus from './RiskStatementStatus';
// import { businessRiskData, trustBuilderUnsubscribedBarBusiness } from '../../constants/pagesData';
// import { SCHEDULE_CYSO_LINK } from '../../constants/links';
// import { trackEvent } from '../../utils/gaTracker';
import ViewWrapper from '../layout/ViewWrapper';
import MainScrollableContainer from '../layout/MainScrollableContainer';
import CompanyRisksTable from './company-risks-table/CompanyRisksTable';
import RiskManagerChart from './RiskManagerChart';

const BusinessRisk = (props) => {

    // const {
    //     hasVcyso
    // } = props;

    // const informationToRender = hasVcyso ? businessRiskData : trustBuilderUnsubscribedBarBusiness;
    // const { title, descriptions, customButtonLabelBusiness, } = informationToRender;
    // const [ riskTitle ] = useState(true)

    // const onScheduleBusinessRisk = () => {
    //     const event = "schedule-cyso-info-bar";
    //     trackEvent(event, 'cyso', event);
    //     window.open(SCHEDULE_CYSO_LINK);
    // }

    // const renderInformationBar = () => {
    //     const customButton = hasVcyso ? (
    //         <Button color="light" onClick={onScheduleBusinessRisk}>
    //             {customButtonLabelBusiness}
    //         </Button>
    //     ) : null;
    //     return (
    //         <div className="mb-3">
    //             <InformationExpandableBar
    //                 riskTitle={riskTitle}
    //                 title={title}
    //                 content={descriptions}
    //                 isFreemium={!hasVcyso}
    //                 customButton={customButton}
    //             />
    //         </div>
    //     )
    // }
    const renderContent = () => {
        return (
            <MainScrollableContainer
                additionalClassName="vcyso-container"
            >
                <div className="business-risk-container dashboard">
                    {/* {renderInformationBar()} */}
                    <Row className="negative-mx-2">
                        <RiskStatementStatus cardColor="dark"/>
                        <RiskManagerChart cardColor="dark"/>
                    </Row>
                    <Row className="negative-mx-2">
                        <CompanyRisksTable />
                    </Row>
                </div>
            </MainScrollableContainer>
        )
    }

    return (
        <ViewWrapper
            layoutType="vertical"
            viewName="Risk Manager"
            hasImpersonationMode={true}
        >
            {renderContent()}
        </ViewWrapper>
    )
}
export default BusinessRisk;
