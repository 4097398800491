import React, { useEffect } from 'react';
import API from '../api';
import { setAlert } from '../actions/alerts';
import { setConfirmationModal } from '../actions/layout';
import useMemoizedDispatch from './useMemoizedDispatch';
import useCustomMutation from "./useCustomMutation";

const useBulkUpdateGroups = (props) => {
  //  Parsing props
  const {
    messageAction = 'assigned',
    messagePlaceholder = '',
    onSuccess = () => { },
  } = props;

  //  Initializing API
  const { GroupAPI } = API;

  //  Helper hooks
  const { dispatch } = useMemoizedDispatch();
  const {
    data: updateData,
    error: updateError,
    isLoading: updateLoading,
    mutate: bulkUpdate,
  } = useCustomMutation(
    (params) => GroupAPI.handlers.bulkUpdateGroups(params),
    GroupAPI.invalidators.bulkUpdateGroups,
  );

  //  Function to get success message
  const getConfirmationMessage = (group_count = 0, issue_count = 0) => {
    return (
      <span>
        {`${group_count} groups, ${issue_count} issues ${messageAction} `}
        {messagePlaceholder && <>{`to `}<span className="font-weight-bolder">{messagePlaceholder}</span></>}
      </span>
    )
  };

  //  Watching success response
  useEffect(() => {
    if (!updateData) { return; }
    dispatch(setConfirmationModal({
      show: true,
      message: getConfirmationMessage(updateData?.updated_groups, updateData?.updated_issues),
    }));
    onSuccess();
  }, [updateData]);

  //  Watching error response
  useEffect(() => {
    if (!updateError) { return; }
    dispatch(setAlert('Error bulk updating groups.', 'danger'));
  }, [updateError]);

  //  Returning assets
  return {
    updateLoading,
    bulkUpdate,
  };
};

export default useBulkUpdateGroups;
