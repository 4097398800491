import React from 'react';
import { useSelector } from 'react-redux';
import { issueTypes } from '../../../constants/issues';
import issuesInfoSelector from '../../../selectors/issuesInfoSelector';
import FiltersButtonGroup from '../../layout/filters/FiltersButtonGroup';

const IssuesPriorityFilter = (props) => {
    const {
        filterValue,
        setFilterCallback,
        issueType = issueTypes.VULNERABILITIES,
        wrapperClassName = ""
    } = props;

    const priorities = useSelector(state => issuesInfoSelector(state, 'modalOptions', issueType)?.priorities);

    const mapPriorities = () => {
        return priorities.map(({ label, id }) => {
            return {
                label,
                className: 'light-gray',
                value: id,
            }
        })
    }

    if (!priorities || !Array.isArray(priorities)) return null;
    return (
        <div tabIndex="0" aria-label={`Filter issues by priority`}>
            <FiltersButtonGroup
                filterValue={filterValue}
                data={mapPriorities()}
                callback={setFilterCallback}
                showNumber={false}
                wrapperClassName={wrapperClassName}
            />
        </div>
    )
}

export default IssuesPriorityFilter
