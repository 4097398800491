import React from 'react';
import { Card, CardBody, CardTitle, Row, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { isValidFunction } from '../../../../utils/helpers';
import InfoTooltip from '../../../dashboard/tooltips/InfoTooltip';

const DashboardRow = (props) => {
    const {
        title,
        linkText,
        linkUrl,
        linkOnClick,
        children,
        dashboardWrapperClassName = '',
        dashboardBodyClassName = '',
        rowClassName = '',
        displayInfoIcon = false,
        infoLabel = '',
    } = props;

    // Component hooks
    const history = useHistory();

    // On button click
    const onButtonClick = () => {
        if (isValidFunction(linkOnClick)) {
            linkOnClick();
        }
        if (linkUrl) {
            history.push(linkUrl);
        }
    }

    // Render button
    const renderButton = () => {
        return (
            <Button
                color="link"
                className="link-text p-0"
                onClick={onButtonClick}
            >
                {linkText}
            </Button>
        )
    }
    const renderInfoTooltip = () => {
        return (
            <InfoTooltip
                label=''
                info={infoLabel}
                wrapperClassName="rr-info-icon"
                showTooltip={true}
                callback={onButtonClick}
            />
        )
    }
    return (
        <div className={`dashboard-row ${dashboardWrapperClassName}`}>
            <Card>
                <CardBody className={`dashboard-row-body ${dashboardBodyClassName}`}>
                    <CardTitle className={`${title || linkText ? '' : 'd-none'}`}>
                        <div className='rr-card-title'>
                            {title}
                            {displayInfoIcon && renderInfoTooltip()}
                            {!displayInfoIcon && renderButton()}
                        </div>
                    </CardTitle>
                    <Row className={`no-gutters ${rowClassName}`}>
                        {children}
                    </Row>
                </CardBody>
            </Card>
        </div>
    )
}
export default DashboardRow;
