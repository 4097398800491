import React from 'react';
import CustomToggleSwitch from '../../layout/CustomToggleSwitch'


const ModalActionSwitch = ({ field, handleParams, params, key }) => {

  if (field.key === 'forever' && !params["risk"]) return null;

  return (
    <div className='modal-field switch-modal' key={key}>
      <p className='switch-title'>{field.title}</p>
      <p className='textfield-subtitle'>{field.subTitle}</p>
      <CustomToggleSwitch
        spanClassName="label-slider"
        containerClassName="action-custom-switch"
        checked={params[field.key] ? true : false}
        setChecked={() => handleParams(field.key, params[field.key] ? '' : 'true')}
        includeLabel={true}
        checkedLabelClassName="label-slider-checked"
        unCheckedLabelClassName="label-slider-unchecked"
      />
    </div>
  );
};

export default ModalActionSwitch;
