import React, { useState, useEffect } from 'react';
import TendencyChart from './TendencyChart';
import { normalizeTrendData, isTimeIntervalDataEmpty } from '../../../../utils/trendChartHelpers';
import {
    adjustFilters,
    getPointsForCurrentRange,
    getTimePeriod,
    getPointBetweenCurrentPeriod,
    shouldAdjustInitialRange,
    getMinAmountOfPoints,
} from '../../../../utils/timeChartHelpers';
import { capitalizeWords } from '../../../../utils/helpers';
import { dashboardTrendMinPoints } from '../../../../constants/tendencyChart';
import { isNil } from 'lodash';

const TrendChart = (props) => {
    const {
        company_id,
        solution_id,
        isSummary = false, // Flag to know if is the simple version of the chart (useful to hide some components)
        trendData = [],
        isEmpty = false,
        objects = 'issues',
        emptyTitle = '',
        emptySubtitle = '',
        isLoading,
        errorMessage,
        retryRequest,
        filters,
        setFilters,
        totalPointsConfig,
        chartTitle,
        pointsColorConfig = {}, // Color used on graph points
        labelColorConfig = {}, // Color used on tooltips
        useLocalTimeFilter = true,
    } = props;

    // Component state
    const [retryEnabled, setRetryEnabled] = useState(false);
    const [series, setSeries] = useState(false);
    const [currentPeriodValue, setCurrentPeriodValue] = useState(undefined);
    const [isIntervalDataEmptyFlag,setIsIntervalDataEmptyFlag] = useState(false);

    //  Watching API response for cyvalue
    useEffect(() => {
        if (!trendData) { return; }
        //  Getting default points to render on the chart
        let pointsToRender = getPointsForCurrentRange(filters, true);

        //  Evaluating points to see if we need to adjust time ranges (edge case)
        const shouldRetryRequest = shouldAdjustInitialRange(trendData, pointsToRender, retryEnabled);

        //  Adjusting filters
        if (shouldRetryRequest && useLocalTimeFilter) {
            setRetryEnabled(false);
            const adjustedFilters = adjustFilters(filters, totalPointsConfig);
            setFilters(adjustedFilters?.filter_type, adjustedFilters?.start_date, adjustedFilters?.end_date);
            return;
        }
        setRetryEnabled(false);
        const normalizedTrend = normalizeTrendData(trendData, pointsToRender, filters);
        const series = buildSeries(normalizedTrend);
        setSeries(series);
    }, [trendData]);

    //  Watching company_id changes to reset filters range
    useEffect(() => {
        setRetryEnabled(true);
    }, [company_id, solution_id]);

    useEffect(() => {
        const flag = isTimeIntervalDataEmpty(isEmpty,series);
        setIsIntervalDataEmptyFlag(flag);
    },[series])

    //  Function to change filters
    const changeFilters = (newFilters, enableRetry = false) => {
        setRetryEnabled(enableRetry);
        setFilters(newFilters?.filter_type, newFilters?.start_date, newFilters?.end_date);
    }

    //  Function to build normal point payload
    const buildNormalPointPayload = (dataPoint, seriesName, convertToUnix) => {
        if (!dataPoint) { return; }
        const { count, label, activities, start_date, end_date } = dataPoint
        return {
            y: parseInt(count) || null,
            x: parseInt(label),
            activities: activities || 0,
            start_date: convertToUnix ? start_date.getTime() : start_date,
            end_date: convertToUnix ? end_date.getTime() : end_date,
            series_name: seriesName,
        };
    };

    // Function to build series
    const buildSeries = (data = []) => {
        if (!data || !Array.isArray(data)) { return null; }
        let currentPoint = null;
        return data.map((element) => {
            const data = [];
            if (element?.data) {
                element.data.forEach((dataPoint) => {
                    const point = buildNormalPointPayload(dataPoint, element?.name, true);
                    data.push(point);
                    // Only look if current point has not been found yet
                    if (isNil(currentPoint)) {
                        const newPoint = getPointBetweenCurrentPeriod(point);
                        if (!isNil(newPoint)) {
                            currentPoint = newPoint
                        }
                    }
                })
            }
            setCurrentPeriodValue(currentPoint?.x)
            return {
                name: element?.name,
                data,
            }

        })
    }

    // Function to get Array of colors for series
    const getSeriesColorConfig = () => {
        const defaultColor = "#fff";
        if (!series || !Array.isArray(series)) return [defaultColor];
        return series.map(series => {
            const { name } = series;
            return pointsColorConfig[name?.toUpperCase()] || defaultColor
        })
    }

    const renderPointTooltip = function (opts) {
        const { series, seriesIndex, dataPointIndex, ctx } = opts;
        //const activities = ctx.w.config.series[seriesIndex].data[dataPointIndex].activities;
        const seriesName = ctx.w.config.series[seriesIndex].name;
        const count = series[seriesIndex][dataPointIndex];
        const pointClassName = labelColorConfig[seriesName?.toLowerCase()];
        return (
            `<div class="criticality-data-point-tooltip">
                <div class="title">
                    ${seriesName}
                    <div class="criticality-circle bg-${pointClassName} rounded-circle"> </div>
                </div>
                <div> ${capitalizeWords(objects)}: ${count || 0}</div>
            </div>`
        );
    }

    return (
        <TendencyChart
            width={isSummary ? '125%' : '550px'}
            height={isSummary ? '175px' : '200px'}
            isSummary={isSummary}
            isLoading={isLoading}
            errorMessage={errorMessage}
            retryRequest={retryRequest}
            filters={filters}
            setFilters={changeFilters}
            series={series}
            selectedTimeUnit={getTimePeriod(filters.filter_type)}
            colorsConfig={getSeriesColorConfig()}
            totalPointsConfig={totalPointsConfig}
            renderCustomTooltip={renderPointTooltip}
            currentPeriodValue={currentPeriodValue}
            chartTitle={chartTitle}
            showEmptyState={isEmpty}
            showTimeIntervalDataEmpty={isIntervalDataEmptyFlag === null ? false : isIntervalDataEmptyFlag}
            emptyTitle={emptyTitle}
            emptySubtitle={emptySubtitle}
        />
    )
}

export default TrendChart;
