import React, { useState } from "react";
import { parseCompaniesToOptions } from "../../utils/helpers";
import { Col } from "reactstrap";
import BasicModal from "../layout/BasicModal";
import API from "../../api";
import InfiniteDropdown from "../layout/InfiniteDropdown";
import NewConnectionForm from "./NewConnectionForm";
import ConnectionSuccesMessage from "./ConnectionSuccesMessage";

const ConnectionsConfigModal = (props) => {
  const {
    showModal,
    toggleModal,
    companyId = null,
    partnerId = null,
    companyName = '',
    partnerName = '',
    isNewConnection = true,
  } = props;

  //  Initializing APIs
  const { CompanyAPI, PartnerAPI } = API;

  // Component state
  const [selectedPartnerName, setSelectedPartnerName] = useState(partnerName);
  const [selectedCompanyId, setSelectedCompanyId] = useState(companyId);
  const [selectedPartnerId, setSelectedPartnerId] = useState(partnerId);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  //  Function to get pending partners
  const getPendingPartners = async (filters) => {
    const response = await PartnerAPI.handlers.getPendingPartnersByCompany({
      company_id: selectedCompanyId,
      filters,
    });
    return { ...response };
  };

  // handle company selection
  const handleCompanySelection = (option) => {
    setSelectedPartnerId(null);
    setSelectedCompanyId(option?.value);
  }

  // Render connection partner and company title
  const renderConnectionTitle = () => {
    if(isNewConnection) return null;
    const partnerLabel = partnerName|| 'Partner';
    const companyLabel = companyName || 'Company';
    const label = `Add ${partnerLabel} for ${companyLabel}`
    return label
  }

  // Render header title
  const renderHeaderTitle = () => {
    return isNewConnection ? "Add new configuration" : renderConnectionTitle();
  }

  // Render company dropdown
  const renderCompanyDropdown = () => {
    return (
      <Col md="6">
        <div className="dropdown-title">Company</div>
        <InfiniteDropdown
          emptyLabel="Select company"
          extraClassName="dashboard-dropdown-filter member-filter"
          errorMessage="Error getting members"
          apiFunction={CompanyAPI.handlers.getImpersonationList}
          apiKey={CompanyAPI.keys.getImpersonationList}
          parseElementsToOptions={parseCompaniesToOptions}
          onChange={handleCompanySelection}
          restoreToDefault={selectedCompanyId === null}
          selectedElements={[]}
        />
      </Col>
    );
  };

  // Handle partner selected option
  const handlePartnerSelection = (option) => {
    setSelectedPartnerName(option?.label);
    setSelectedPartnerId(option?.value)
  }

  // Render partner dropdown
  const renderPartnerDropdown = () => {
    return (
      <Col md="6">
        <div className="dropdown-title">Partner</div>
        <InfiniteDropdown
          emptyLabel="Partner name"
          extraClassName="dashboard-dropdown-filter partner-filter"
          errorMessage="Error getting partners"
          apiFunction={getPendingPartners}
          extraApiKeys={[selectedCompanyId]}
          apiKey={PartnerAPI.keys.getPendingPartnersByCompany}
          parseElementsToOptions={parseCompaniesToOptions}
          onChange={(option) => handlePartnerSelection(option)}
          restoreToDefault={selectedPartnerId === null}
          selectedElements={[]}
          isDisabled={!selectedCompanyId}
        />
      </Col>
    );
  };

  // Render config dropdowns
  const renderDropdowns = () => {
    if(!isNewConnection) return null;
    return (
      <div className="dropdowns-container d-flex row">
        {renderCompanyDropdown()}
        {renderPartnerDropdown()}
      </div>
    );
  };

  // Handle set partner successful response
  const handleSetPartnerSuccess = () => {
    setShowSuccessMessage(true);
  }

  // Render fields
  const renderFields = () => {
    return (
      <NewConnectionForm
        companyId={selectedCompanyId}
        partnerId={selectedPartnerId}
        partnerName={selectedPartnerName}
        handleSuccess={handleSetPartnerSuccess}
      />
    )
  };

  return (
    <BasicModal
      header={renderHeaderTitle()}
      subtitle={!showSuccessMessage && isNewConnection ? `Get API details from ${selectedPartnerName || ""}`: ''}
      showModal={showModal}
      toggleModal={toggleModal}
      customClassName="connections-config-modal"
    >
      {showSuccessMessage ? (
        <ConnectionSuccesMessage
          toggleModal={toggleModal}
        />
      ) : (
        <>
          {renderDropdowns()}
          {renderFields()}
        </>
      )}
    </BasicModal>
  );
};

export default ConnectionsConfigModal;
