import React from 'react';
import { connect } from 'react-redux';
import { UncontrolledAlert } from 'reactstrap';

const renderAlerts = (alerts) => {
    return alerts.map( alert => {
        switch(alert.alertType) {
            case 'important': {
                return (
                    <UncontrolledAlert 
                        key={alert.id}
                        color={'blue'}
                        className={`important-alert mb-0 ${alert.customClassName}`}
                    >
                        <div className="alert-icon d-inline-flex">
                            <i className="bx bx-error"></i>
                        </div>
                        <div className="content">
                            {alert.msg}
                        </div>
                    </UncontrolledAlert>
                )
            }
            case 'freemium': {
                return (
                    <UncontrolledAlert 
                        key={alert.id}
                        color={'blue'}
                        className={`important-alert mb-0 ${alert.customClassName}`}
                    >
                        <div className="content">
                            {alert.msg}
                        </div>
                    </UncontrolledAlert>
                )
            }
            case 'success': {
                return (
                    <UncontrolledAlert
                        key={alert.id}
                        color={alert.alertType}
                        style={{ zIndex: 1 }}
                    >
                        <i className='bx bx-check-circle'></i>
                        {alert.msg}
                    </UncontrolledAlert>
                )
            }
            default: {
                return (
                    <UncontrolledAlert
                        key={alert.id}
                        color={alert.alertType}
                        style={{ zIndex: 1 }}
                        className="alert-basic mb-0"
                    >
                        {alert.msg}
                    </UncontrolledAlert>
                )
            }
        } 
    });
}

const Alert = ({alerts}) => {
    if( alerts !== null && alerts.length > 0){
        return <div>{renderAlerts(alerts)}</div>;
    }else{
        return null;
    }   
}

const mapStateToProps = (state) => {
    return { alerts: Object.values(state.alerts)}
}
export default connect(mapStateToProps, {} )(Alert);