import React from 'react';
import CustomLottie from '../../common/CustomLottie';
import riskReconSearch from '../../../assets/lotties/riskrecon-search.json';

const RiskScoreEmpty = () => {
  return (
    <div className='risk-recon-overlay'>
      <div className='rr-overlay-container'>
        <CustomLottie
          loop
          autoplay
          lottieHeight={200}
          lottieWidth={200}
          lottieFile={riskReconSearch}
          extraSettings={{ className: 'risk-recon-search' }}
        />
        <div className='rr-overlay-text-container'>
          <span className='rr-overlay-title'>RiskRecon is scanning your domains</span>
          <span className='rr-overlay-subtitle'>
            RiskRecon is currently searching for cybersecurity ratings and insight that make it easy to understand and act on your risks. Come back later to review the results.
          </span>
        </div>
      </div>
    </div>
  );
};

export default RiskScoreEmpty;