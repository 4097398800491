import moment from 'moment';
import { timeFilters } from '../constants/filterTypes';

export const timezoneAbbreviations = {
  AST: 'Atlantic Stantard Time',
  EST: 'Eastern Standard Time',
  EDT: 'Eastern Daylight Time',
  CST: 'Central Standard Time',
  CDT: 'Central Daylight Time',
  MST: 'Mountain Standar Time',
  MDT: 'Mountain Daylight Time',
  PST: 'Pacific Standard Time',
  PDT: 'Pacific Daylight Time',
};

export const timepickerModes = {
  TWENTY_FOUR_HOUR: '24 Hour',
  TWELVE_HOUR: '12 Hour'
}


export const monthDateFormat = (date) => {
  return moment(date).format("ll");
}

export const getWeekRange = (date) => {
  const baseDate = date || new Date();
  const startDate = moment(baseDate).startOf('week');
  const endDate = moment(baseDate).endOf('week');

  return `${startDate.format('MM/DD/YY')} - ${endDate.format('MM/DD/YY')}`;
}

const getOrdinalDateSuffix = (dayNumber) => {
  switch (dayNumber % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}

export const getGeneralFormatDate = (rawDate, isOrdinalForm = false) => {

  const formattedDate = new Date(rawDate);
  const date = formattedDate.getDate();
  const month = formattedDate.toLocaleString('default', { month: 'short' });
  const year = formattedDate.getFullYear();
  const ordinalForm = isOrdinalForm ? getOrdinalDateSuffix(date) : '';

  return ` ${month} ${date}${ordinalForm}, ${year}`;
}

export const convertToMilliseconds = (value = 0, unit) => {

  const millisecondsPerSecond = 1000;
  const secondsPerMinute = 60;
  const minutesPerHour = 60;
  const hoursPerDay = 24;

  switch (unit) {
    case timeFilters.DAYS:
      return value * hoursPerDay * minutesPerHour * secondsPerMinute * millisecondsPerSecond;
    case timeFilters.HOURS:
      return value * minutesPerHour * secondsPerMinute * millisecondsPerSecond;
    case timeFilters.MINUTES:
      return value * secondsPerMinute * millisecondsPerSecond;
    default:
      return value
  }

}

const getTimeOptions = (initialIndex = 0, timeRanges, hourRanges, dayPeriod) => {
  const options = [];
  let currentIndex = initialIndex;
  hourRanges.forEach((hour) => {
    timeRanges.forEach((minutes) => {
      const newOption = { value: currentIndex, label: `${hour}:${minutes} ${dayPeriod}` }
      options.push(newOption);
      currentIndex++;
    });
  });
  return options;
};

export const generateTimePickerOptions = (mode = timepickerModes.TWELVE_HOUR) => {
  switch (mode) {
    case timepickerModes.TWELVE_HOUR: {
      const hourRanges = ['12', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11']
      const timeRanges = ['00', '15', '30', '45'];
      const amOptions = getTimeOptions(0, timeRanges, hourRanges, 'am');
      const pmOptions = getTimeOptions(amOptions.length, timeRanges, hourRanges, 'pm');
      return [...amOptions, ...pmOptions];
    }
    case timepickerModes.TWENTY_FOUR_HOUR: {
      const hourRanges = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', 
      '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
      const timeRanges = ['00', '15', '30', '45'];
      const options = getTimeOptions(0, timeRanges, hourRanges, '');
      return [...options];
    }
    default:
      return null;
  }
};

export const replaceHypehenDateDividers = (dateString) => {
  return dateString?.replace("-", "/")
}

export const isSameDate = (firstDate, secondDate) => {
  return firstDate?.getTime() === secondDate?.getTime()
}
