import React from 'react';
import { Card,  CardBody, Button }from 'reactstrap';
import { PrismicRichText } from '@prismicio/react'
import { getGeneralFormatDate } from '../../../utils/dateFormatter';
import ExternalLink from '../../layout/ExternalLink';

const NotificationBox = (props) => {
  const {
    title,
    date,
    description,
    image = {},
    videoLink = {},
    externalLink = {},
    renderLandingModal,
  } = props;
  const getDescriptionSerializer = () => {
    return {
      paragraph: ({ children }) => <div className="notification-box-description">{children}</div>,
      image:({ node }) => <img className="img-fluid mb-2" src={node?.url}/>,
    }
  }
  // Function to render notification's date
  const renderDate = () => {
    if(!date) return null;
    const dateString = date?.replace(/-/g, '\/');
    return (
      <div className="notification-box-date">
          {getGeneralFormatDate(dateString, true)?.replace(",","")}
      </div>
    )
  }

  const renderExternalLink = () => {
    if(!externalLink?.url) return null;
    const { url } = externalLink;
    return (
      <ExternalLink
        label="Learn more"
        link={ url }
        extraClassName="btn-small mt-3 ml-2"
      />
    )

  }

  const renderVideoLink = () => {
    if(!videoLink?.[0]?.text) return null;
    return (
      <Button
        color="light-gray"
        className="notifications-video-link btn-small mt-3 ml-2"
        outline
        onClick={renderLandingModal}
      >
        View video
      </Button>
    )

  }

  const renderButtons = () => {
    return (
      <div className='float-right'>
        {renderVideoLink()}
        {renderExternalLink()}
      </div>
    )
  }

  // Function to render Title
  const renderTitle = () => {
    return (
      <div className="notification-box-title">
        <PrismicRichText field={title} />
      </div>
    )
  }

  // Function to render notification's info: title, date and description
  const renderInfo = () => {
    const widthClassName = image?.url ? 'with-media' : '';
    return (
      <div
        className={`notification-box-info ${widthClassName}`}
      >
        {renderDate()}
        <PrismicRichText field={description} components={getDescriptionSerializer()}/>
      </div>
    )
  }

  const renderImage = () => {
    if(!image?.url) return null;
    const { url, alt } = image;
    return (
      <div className="notification-box-image">
        <img
          src={url}
          alt={alt || title}
          className='img-fluid'
        />
      </div>
    )
  }
  return (
    <Card className="notification-box-card">
      <CardBody className="notification-box">
        {renderTitle()}
        <div className='notification-box-content'>
          {renderImage()}
          {renderInfo()}
        </div>
        {renderButtons()}
      </CardBody>
    </Card>
  );
};

export default NotificationBox;
