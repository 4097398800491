import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Progress } from 'reactstrap';
import { UisAngleRight } from '@iconscout/react-unicons-solid';
import { resetAssessmentNavigation } from '../../../actions/assessment';
import makeTotalAnswersByCategory from '../../../selectors/assessment/totalAnswersByCategorySelector';
import makeTotalQuestionsNumber from '../../../selectors/assessment/totalQuestionsNumberSelector';
import HalfScreenImageContainer from '../../layout/HalfScreenImageContainer';
import image from "../../../assets/images/isometric-images-1.png";
import { trackEvent } from "../../../utils/gaTracker";

const BasicAssessmentOverview = (props) => {
    const [percentage, setPercentage] = useState(0);
    const { 
        resetAssessmentNavigation,
        totalQuestions, 
        totalAnswers,
        history
    } = props;

    useEffect(()=> {
        setPercentage(Math.floor((totalAnswers.total/totalQuestions.total.questions)*100))
    }, [setPercentage, totalAnswers, totalQuestions]);

    const onPickupAssessmentClick = () => {
        resetAssessmentNavigation();
        trackEvent("pick_up_assessment", "assessment", "pick_up_assessment");
        history.push('/cybercheckup')
    }

    return (
        <div className="assessment-basic-overview normal-text-style d-flex align-content-center">
            <Row className="justify-content-center justify-content-md-end align-content-center pl-5 pr-4">
                <Col sm={8} md={5} lg={5} className="">
                    <Row className="text-white align-content-center h-100">
                        <div className="d-inline-block" aria-label="Done with your coffee break? Your results are brewing! Let's continue to assess how secure your company is. Only a few more sips of coffee needed.">
                            <div className="title mb-4" aria-hidden="true">
                                Done with your
                                <div className="subtitle text-light">coffee break?</div>
                            </div>
                            <div className="text-white" aria-hidden="true">
                                <div className="pb-1 main-message">Your results are brewing!</div>
                                <div className="message mb-4">Let's continue to assess how secure your company is. Only a few more sips of coffee needed.</div>
                            </div>
                            {percentage !== 0 ? (
                                <div className="progress-bar-container" aria-label={`${percentage}% completed`}>
                                    <div className="progress-label" aria-hidden="true">
                                        <div className={`d-inline-block text-light font-weight-bold ml-1 float-right`}>
                                            {percentage}% completed
                                            <UisAngleRight size={20} />
                                        </div>
                                    </div>
                                    <Progress color="primary" value={percentage} aria-hidden="true"></Progress>
                                </div>
                            ) : (
                                    null
                                )}
                        </div>
                        <div className="d-inline-block">
                            <button onClick={onPickupAssessmentClick} className="btn btn-assessment btn-light text-dark p-2 p-md-3 mb-2" >{percentage === 0 ? 'Try it' : 'Continue Assessment'}</button>
                        </div>
                    </Row>
                    
                </Col>
                <HalfScreenImageContainer columnNumber={{ lg: 6, md:6 }} image={image} />                
            </Row> 
        </div>
    )
}

const makeMapStateToProps = () => {
    const getTotalQuestions = makeTotalQuestionsNumber();
    const getTotalAnswers = makeTotalAnswersByCategory();
    const mapStateToProps = (state) => {
      return {
        totalQuestions: getTotalQuestions(state),
        totalAnswers: getTotalAnswers(state),
      }
    };
    return mapStateToProps;
};

const mapDispatchToProps = {
    resetAssessmentNavigation   
};

export default withRouter(connect(
    makeMapStateToProps,
    mapDispatchToProps
)(BasicAssessmentOverview));
