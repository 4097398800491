import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getAnchorForPDF } from '../utils/helpers';
import { triggerTopbarAction } from '../actions/navigation';
import useMemoizedDispatch from './useMemoizedDispatch';
import useCustomMutation from './useCustomMutation';

const usePostPDFGenerator = (props) => {
  //  Parsing props
  const {
    invalidators,
    apiFunction,
    actionType,
    queryParam,
  } = props;

  //  Watching action triggered from topbar
  const type = useSelector((state) => state.navigation.action.type);
  const params = useSelector((state) => state.navigation.action.params);

  //  Hook for memoized dispatch
  const { dispatch } = useMemoizedDispatch();

  //  Calling API for generating PDF
  const {
    data: response,
    error,
    isLoading: loading,
    mutate: generatePDF
  } = useCustomMutation(
    (params) => apiFunction(params),
    invalidators,
  );

  useEffect(() => {
    if((type && type === actionType && (queryParam || params))){
      generatePDF();
    }
  }, [type, actionType, queryParam, params]);

  //  Watching response changes
  useEffect(() => {
    if (!response) { return; }
    //  Creating blob for downloading PDF
    const file = new Blob([response], { type: 'application/pdf' });
    const url = URL.createObjectURL(file);
    const anchor = getAnchorForPDF(url, type, params);
    //  Downloading file
    anchor.dispatchEvent(new MouseEvent('click'));
    dispatch(triggerTopbarAction(null, null));
  }, [response]);

  return { response, error, loading };
};

export default usePostPDFGenerator;
