import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Options from './Options';
import { selectQuestion } from '../../actions/assessment';
import { trackPage } from '../../utils/gaTracker';
import assessmentTypes from '../../constants/assessmentTypes';
import makePickupQuestionSelector from '../../selectors/assessment/pickupQuestionSelector';
import makeTotalAnswersByCategory from '../../selectors/assessment/totalAnswersByCategorySelector';

const Question = ({ question, id, selectedQuestionId, selectQuestion, type, info, sectionId, categoryId, pickupQuestion, assessmentProgress }) => {
    const questionRef = useRef(null);
    const infoQuestionRef = useRef(null);
    const scrollToRef = (ref) => ref.current.scrollIntoView({ behavior: 'smooth' })

    useEffect(() => {
        if(assessmentProgress?.total !== 0 && type === assessmentTypes.full && pickupQuestion?.questionId === id){
            setTimeout(function () {
                const questionElement = questionRef.current;
                if(questionElement){
                    //temporarily moves element in order to scroll to the expected position
                    const pos = questionElement.style.position;
                    const top = questionElement.style.top;
                    questionElement.style.position = 'relative';
                    questionElement.style.top = '-86px';
                    questionElement.scrollIntoView({behavior: 'smooth', block: 'start'});
                    scrollToRef(questionRef);
                    questionElement.style.top = top;
                    questionElement.style.position = pos;
                    selectQuestion(null);
                }
            }, 200); 
        }
        trackPage(`question_${id}`);
        
    }, [selectedQuestionId, id, selectQuestion, type])

    useEffect(() => {
        if(type !== assessmentTypes.full && infoQuestionRef.current) infoQuestionRef.current.focus();
    }, [question]);

    return (
        <div ref={questionRef}  className={`assessment-question-container ${type}`}>
            <div ref={infoQuestionRef} className={`question ${type} mx-auto`} tabIndex="0" >
                <div className="question-span text-center w-100">{question}</div> 
            </div>
            <div className="question-info text-secondary mx-auto"> <span className="question-info-span w-100">{info}</span> </div>
            <Options options="options" questionId={id} type={type} categoryId={categoryId} sectionId={sectionId}/>
            <div ></div>
        </div>
    )
}

const makeMapStateToProps = () => {
    const getPickupQuestion = makePickupQuestionSelector();
    const getAssessmentProgress = makeTotalAnswersByCategory();
    
    const mapStateToProps = (state) => {
      return {
        selectedQuestionId: state.assessment.selectedQuestionId,
        pickupQuestion: getPickupQuestion(state),
        assessmentProgress: getAssessmentProgress(state)
      }
    }
    return mapStateToProps
}

const mapDispatchToProps = {
    selectQuestion
}
export default connect(makeMapStateToProps,mapDispatchToProps)(Question)