import React, { useState, useRef, useEffect } from "react";
import { Row, Button } from "reactstrap";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import companyIdSelector from "../selectors/companyIdSelector";
import DevicesList from "../components/devices/DevicesList";
import Dashboard from "../components/dashboard/Dashboard";
import MainScrollableContainer from "../components/layout/MainScrollableContainer";
import ActionsSection from "../components/solution-settings/ActionsSection";
import PartnerActionModal from "../components/solution-settings/PartnerActionModal";
import API from "../api";
import useCustomQuery from "../hooks/useCustomQuery";
import LoadingContent from "../components/layout/LoadingContent";
import useMemoizedDispatch from "../hooks/useMemoizedDispatch";
import { setPartnerIdAndName } from "../actions/partners";

const SolutionSettings = (props) => {
  // Redux
  const companyId = useSelector((state) => companyIdSelector(state));
  const partnerId = useSelector((state) => state.partners.partnerId);
  const partnerName = useSelector((state) => state.partners.partnerName);
  const scrollableRef = useRef(null);

  // Component states
  const [showActionModal, setShowActionModal] = useState(false);
  const [partnerActions, setPartnerActions] = useState(null);
  const [action, setAction] = useState(null);

  // Hooks
  const location = useLocation();
  const { dispatch } = useMemoizedDispatch();

  // params handling
  const partnerIdParamName = "partnerId";
  const queryParams = new URLSearchParams(location.search);
  const urlPartnerId = queryParams?.get(partnerIdParamName);

  //  Initializing APIs
  const { PartnerAPI } = API;

  // Call api to get partner actions
  const {
    data: partnerActionsResponse,
    error: partnerActionsError,
    isLoading: partnerActionsLoading,
    isFetching: partnerActionsFetching,
    refetch: refetchPartnerActions,
  } = useCustomQuery(
    [PartnerAPI.keys.getPartnerActions, { partnerId }],
    ({ queryKey }) => PartnerAPI.handlers.getPartnerActions(queryKey[1]),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );

  // Listening for api response
  useEffect(() => {
    if (!partnerActionsResponse) return;
    const { settings } = partnerActionsResponse;
    if (!settings || settings.length == 0) {
      return;
    }
    setPartnerActions(settings);
  }, [partnerActionsResponse]);

  // Listening for api error
  useEffect(() => {
    if (!partnerActionsError) return;
  }, [partnerActionsError]);

  useEffect(() => {
    if (!urlPartnerId) {
      return;
    }
    dispatch(setPartnerIdAndName(urlPartnerId, partnerName || null));
  }, [urlPartnerId]);

  useEffect(() => {
    // On unmount
    return () => {
      dispatch(setPartnerIdAndName(null, null));
    };
  }, []);

  const renderPartnerActionSection = () => {
    if (!partnerActions) {
      return (
        <LoadingContent
          isLoading={partnerActionsLoading}
          errorMessage={
            partnerActionsError
              ? "Oops! Something went wrong. Please try again."
              : null
          }
          iconType="solidIcon"
          errorStyle={{ minHeight: "225px" }}
          loadingStyle={{ minHeight: "225px" }}
          errorButtonCallback={() => {
            refetchPartnerActions();
          }}
          showErrorButton={true}
        />
      );
    }
    return (
      <ActionsSection
        actions={partnerActions}
        onClickHandler={displayModalWithAction}
      ></ActionsSection>
    );
  };

  // Rendering content on page
  const renderContent = () => {
    return (
      <Row className="no-gutters">
        <MainScrollableContainer
          additionalClassName="devices-listing view-container"
          scrollableRef={scrollableRef}
        >
          <div className="solution-settings-title-container">
            <div className="solution-settings-title">
              {`${partnerName || ""} Settings`}
            </div>
          </div>
          {renderPartnerActionSection()}
          <div className="solution-settings-title-container">
            <div className="solution-settings-title">Devices Table</div>
          </div>
          <DevicesList
            companyId={companyId}
            partnerId={partnerId}
            scrollableRef={scrollableRef}
          />
        </MainScrollableContainer>
      </Row>
    );
  };

  const displayModalWithAction = (showActionModal, action) => {
    setShowActionModal(showActionModal);
    setAction(action);
  };

  useEffect(() => {
    if (showActionModal) {
      renderPartnerActionModal();
    }
  }, [showActionModal]);

  const closeModal = () => {
    setShowActionModal(false);
  };

  //  Function to render issues details modal
  const renderPartnerActionModal = () => {
    if (!showActionModal) {
      return null;
    }
    return (
      <PartnerActionModal action={action} onCloseModal={closeModal} /> // TODO: Add action to modal
    );
  };

  //  Rendering
  return (
    <Dashboard
      title="Devices"
      containerClassName="dashboard mx-2 px-0 py-4 text-normal-style page-content contained-view-width"
    >
      {renderContent()}
      {renderPartnerActionModal()}
    </Dashboard>
  );
};

export default SolutionSettings;
