import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import cleanWhiteCyvatarLogo from '../../assets/images/logos/clean-cyvatar-logo-white.png';
import useHookValidationByParams from '../../hooks/useHookValidationByParams';
import { validatePassword } from '../../utils/validations';
import FormCard from '../layout/FormCard';
import LoadingContent from '../layout/LoadingContent';
import API from '../../api';
import useCustomMutation from '../../hooks/useCustomMutation';
import { changeLayout } from '../../actions/layout';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import { setAlert } from '../../actions/alerts';
import HookFormPasswordField from '../hookform/HookFormPasswordField';

const PeakCommerceVerification = () => {
  //  Initializing APIs
  const { UserAPI } = API;

  //  Component state
  const [token, setToken] = useState(null);

  //  Component hooks
  const history = useHistory();
  const location = useLocation();
  const { dispatch } = useMemoizedDispatch();

  const {
    data: setPasswordData,
    error: setPasswordError,
    mutate: setPassword,
    isLoading: setPasswordLoading,
  } = useCustomMutation(
    (params) => UserAPI.handlers.setExternalUserPassword(params),
    UserAPI.invalidators.setExternalUserPassword,
    { retry: 0 }
  );

  //  Watching token changes (just render view if there is a token in state)
  useEffect(() => {
    if (!location?.state?.token) {
      history.push('/login');
      return;
    }
    setToken(location?.state?.token);
  }, [location?.state?.token])

  //  Watching set data
  useEffect(() => {
    if (!setPasswordData) return;
    dispatch(setAlert('Password set successfully', 'success'));
    history.push('/login');
  }, [setPasswordData]);

  //  Watching set error
  useEffect(() => {
    if (!setPasswordError) return;
    dispatch(setAlert('Error setting new password', 'danger'));
  }, [setPasswordError]);

  useEffect(() => {
    changeLayout('horizontal');
  }, [changeLayout]);

  // React hook-form hook
  const { register, watch, handleSubmit, formState: { errors, touchedFields, dirtyFields } } = useForm({
    defaultValues: { new_password: '', confirm_password: '' }
  });

  // Function to validate fields (validates both fields the same way since both inputs are passwords)
  const validate = (inputData) => {
    let error = '';
    if (validatePassword(inputData)) {
      error = validatePassword(inputData);
    }
    return error;
  }

  // Gets the params needed for the state changes of the fields used in the useHookValidationByParams Hook
  const getHookParams = (nameValue) => {
    return ({
      name: nameValue,
      required: { state: true, message: "Please complete this required field" },
      validate,
      watch,
      errors,
      touchedFields,
      dirtyFields
    });
  }

  // Data needed for the inputs (error messages, classnames), one per field
  const newPasswordValidationData = useHookValidationByParams('new_password',getHookParams);
  const confirmPasswordValidationData = useHookValidationByParams('confirm_password',getHookParams);

  // Function ran when form is submitted
  const onSubmitForm = handleSubmit((data, e) => {
    if (data.confirm_password === data.new_password) {
      const params = {
        password: data.new_password,
        token
      }; 
      setPassword(params);
    } else {
      dispatch(setAlert('Passwords do not match', 'danger'));
    }
  });

  // Renders the password fields
  const renderPasswordField = (label, name, validationData) => {
    const {
      isInvalid,
      errorMsgClassName,
      errorIconClassName,
      requirements,
      errorMsg
    } = validationData;

    return (
      <HookFormPasswordField
        name={name}
        label={label}
        labelCustomClassName="password-input-label"
        ariaLabel="Password edit text"
        className="form-control hook-form-control dark"
        wrapperClassName="form-group"
        type="password"
        style={{ marginBottom: "5px" }}
        ariaRequired="true"
        register={register}
        isInvalid={isInvalid}
        errorMsgClassName={errorMsgClassName}
        errorIconClassName={errorIconClassName}
        requirements={requirements}
        errorMsg={errorMsg}
      />
    );
  }

  const renderPasswordRequirements = () => {
    return (
      <div className='password-requirements'>
        <p>Your password must be at least 12 characters long, include:</p>
        <ul>
          <li>1 uppercase and lowercase letter</li>
          <li>1 number</li>
          <li>1 special character</li>
        </ul>
      </div>
    );
  };

  const renderPasswordFields = () => {
    return (
      <>
        <div className='subtitle'>Please update your password</div>
        {renderPasswordField('New Password', 'new_password',newPasswordValidationData)}
        {renderPasswordRequirements()}
        {renderPasswordField('Confirm New Password','confirm_password',confirmPasswordValidationData)}
      </>
    );
  };

  const renderSubmitSection = () => {
    return (
      <>
        <div>
          <button
            className="btn btn-light text-dark"
            type="submit"
            disabled={setPasswordLoading}
          >
            Continue
          </button>
        </div>

        <div
          className="text-center not-a-client-yet"
          style={{ margin: "26px 0px 0px 0px" }}
          tabIndex="-1"
        >
          <p aria-hidden="true" className="text-white">
            Not a client yet?
            <Link
              to="/my-cyvatar"
              className="focus-visible-dark not-a-client-yet-link"
              aria-label="Not a client yet? Sign up here!"
              aria-hidden="false"
            >
              Sign up here!
            </Link>
          </p>
        </div>
      </>
    );
  };

  return (
    <React.Fragment>
      <div className='peak-commerce-verification lava-lamp-background'>
        <Row className="new-password-content">
          <Col lg={7} className='column'>
            <div className="new-password-form-background">
              <div className='new-password-form-container'>
                <img
                  src={cleanWhiteCyvatarLogo}
                  className="cyvatar-clean-white-logo"
                />
                <FormCard
                  title={'Create new password'}
                  customClassName="peakcommerce-verification-form"
                >
                  <LoadingContent
                    isLoading={false}
                    loadingStyle={{ minHeight: "200px" }}
                  >
                    <form
                      className="form-horizontal"
                      onSubmit={onSubmitForm}
                    >
                      <div className="peakcommerce-verification-form-content">
                        {renderPasswordFields()}
                        {renderSubmitSection()}
                      </div>
                    </form>
                  </LoadingContent>
                </FormCard>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default PeakCommerceVerification;