import React, { useState, useEffect, createRef } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { FormGroup, Button } from "reactstrap";
import {
  setPartnerSuccess,
  setPartnerError,
  enableFurtimSuccess,
  enableFurtimError,
} from "../../actions/partners";
import useCustomQuery from "../../hooks/useCustomQuery";
import LoadingContent from "../layout/LoadingContent";
import _ from "lodash";
import { setAlert } from "../../actions/alerts";
import API from "../../api";
import useMemoizedDispatch from "../../hooks/useMemoizedDispatch";
import useCustomMutation from "../../hooks/useCustomMutation";
import { onImpersonationSuccess } from "../../actions/impersonation";
import { alertTypes } from "../../constants/alerts";
import ConnectionAutomaticSetup from "../Members/ConnectionAutomaticSetup";
import ConnectionFileInput from "../Members/ConnectionFileInput";
import { getApiErrorMessage, getSafeArray, isValidFunction } from "../../utils/helpers";
import errorMessages from "../../constants/errorMessages";
import { useSelector } from "react-redux";
import companyIdSelector from "../../selectors/companyIdSelector";

const NewConnectionForm = (props) => {
  const {
    partnerId,
    partnerName,
    companyId,
    handleSuccess
  } = props;

  //  Initializing APIs
  const { PartnerAPI, UserAPI } = API;

  // Needed info from redux store
  const impersonatedCompanyId = useSelector(state => companyIdSelector(state))

  // Component Ref
  const buttonRef = createRef();
  const formRef = createRef();

  // Component state
  const [autoSetup, setAutoSetup] = useState(false);
  const [invalidMessages, setInvalidMessages] = useState([]);
  const [clientSlug, setClientSlug] = useState(null);
  const [confirmClientSlug, setConfirmClientSlug] = useState(false);
  const [partnerData, setPartnerData] = useState(null);

  // Components Hooks
  const { dispatch } = useMemoizedDispatch();
  // Calling API to get partner fields config
  const {
    data: partnerConfigResponse,
    error: partnerConfigError,
    isLoading: partnerConfigLoading,
    refetch: refetchPartnerFields,
  } = useCustomQuery(
    [PartnerAPI.keys.getPartnerFields, { partner_id: partnerId, company_id: companyId }],
    ({ queryKey }) => PartnerAPI.handlers.getPartnerFields(queryKey[1])
  );

  //  Calling API to set partner
  const {
    data: setPartnerResponse,
    error: partnerError,
    isLoading: setPartnerLoading,
    mutate: callSetPartner,
  } = useCustomMutation(
    (params) => PartnerAPI.handlers.setPartner(params),
    PartnerAPI.invalidators.setPartner
  );

  //  Calling API to enable furtim
  const {
    data: furtimResponse,
    error: furtimError,
    isLoading: furtimLoading,
    mutate: callEnableFurtim,
  } = useCustomMutation(
    (params) => PartnerAPI.handlers.enableFurtim(params),
    PartnerAPI.invalidators.enableFurtim
  );

  //  Post to obtain company with updated packages
  const {
    data: impersonateResponse,
    error: impersonateError,
    mutate: impersonateCompany,
  } = useCustomMutation(
    (params) => UserAPI.handlers.impersonateCompany(params),
    UserAPI.invalidators.impersonateCompany
  );

  // Watching partner fields config response
  useEffect(() => {
    const data = getSafeArray(partnerConfigResponse?.partners).find(
      (partner) => partner?.id === partnerId
    );
    if (data) setPartnerData(data);
  }, [partnerConfigResponse]);

  //  Watching set partner API response
  useEffect(() => {
    if (!setPartnerResponse) {
      return;
    }
    dispatch(setPartnerSuccess(setPartnerResponse));

    if(companyId === impersonatedCompanyId) {
      // Impersonate company again to update the company's solutions
      impersonateCompany(companyId);
    } else {
      // Show success message
      if(isValidFunction(handleSuccess)) {
        handleSuccess();
      }
    }
  }, [setPartnerResponse]);

  //  Watching API error
  useEffect(() => {
    if (!partnerError) {
      return;
    }
    dispatch(setAlert(errorMessages.defaultPost, alertTypes.error));
    dispatch(setPartnerError(partnerError));
  }, [partnerError]);

  //  Watching API response
  useEffect(() => {
    if (!furtimResponse) {
      return;
    }
    dispatch(enableFurtimSuccess(furtimResponse));
    // Show success message
    if(isValidFunction(handleSuccess)) {
      handleSuccess();
    }
  }, [furtimResponse]);

  //  Watching API error
  useEffect(() => {
    if (!furtimError) {
      return;
    }
    dispatch(setAlert(errorMessages.defaultPost, alertTypes.error));
    dispatch(enableFurtimError(furtimError));
  }, [furtimError]);

  //  Watching impersonate response
  useEffect(() => {
    if (!impersonateResponse) {
      return;
    }
    dispatch(onImpersonationSuccess(impersonateResponse));
    // Show success message for setting partner
    if(isValidFunction(handleSuccess)) {
      handleSuccess();
    }
  }, [impersonateResponse]);

  //  Watching impersonate error
  useEffect(() => {
    if (!impersonateError) {
      return;
    }
    // Show success message for setting partner
    if(isValidFunction(handleSuccess)) {
      handleSuccess();
    }
    const impersonationErrorMessage = "Error impersonating company, please select the company again";
    dispatch(setAlert(impersonationErrorMessage, alertTypes.error));
    dispatch(onImpersonationSuccess(null));
  }, [impersonateError]);

  // Handle auto setup flag
  const handleAutoSetup = () => {
    if (formRef.current) formRef.current.reset();
    setAutoSetup(!autoSetup);
  };

  // Handle form valid submit
  const handleValidSubmit = (events, values) => {
    let file;
    if (invalidMessages.length > 0) {
      const missingFieldsMessage = "One or more fields have an error. Please check and try again";
      dispatch(setAlert(missingFieldsMessage, alertTypes.error));
      return false;
    }

    if (buttonRef.current) {
      file = buttonRef.current.state.file;
    }

    if (file) {
      if (clientSlug && !confirmClientSlug) {
        const alertMessage = "Please confirm the client_slug match the company name";
        dispatch(setAlert(alertMessage, alertTypes.error));
        return false;
      }

      let formData = new FormData();
      formData.append("file", file);
      formData.append("company_id", companyId);
      formData.append("partner_id", partnerId);
      //  Enabling furtim
      callEnableFurtim(formData);
    } else if (!_.isEmpty(values) || _.isEmpty(partnerData?.fields)) {
      const payload = {
        company_id: companyId,
        partner_id: partnerId,
        config_values: {
          ...values,
          auto_setup: autoSetup,
        },
      };
      callSetPartner(payload);
    }
  };

  const renderAutomaticSetup = (fieldName, index) => {
    return (
      <ConnectionAutomaticSetup
        fieldName={fieldName}
        index={index}
        autoSetup={autoSetup}
        handleAutoSetup={handleAutoSetup}
      />
    );
  };

  // Render file input
  const renderFileInput = (fieldName, index) => {
    return (
      <ConnectionFileInput
        partnerName={partnerName}
        fieldName={fieldName}
        index={index}
        buttonRef={buttonRef}
        clientSlug={clientSlug}
        setClientSlug={setClientSlug}
        confirmClientSlug={confirmClientSlug}
        setConfirmClientSlug={setConfirmClientSlug}
        invalidMessages={invalidMessages}
        setInvalidMessages={setInvalidMessages}
      />
    );
  };

  const renderInputs = () => {
    if(!partnerId) return null;
    const currentConfig = partnerData?.config_values;
    const fields = partnerData?.fields || {};
    return Object.keys(fields).map((field_name, index) => {
      const inputField = fields[field_name];

      if (inputField.type === "file") {
        return renderFileInput(field_name, index);
      }

      if (inputField.type === "automatic_setup") {
        return currentConfig ? <></> : renderAutomaticSetup(field_name, index);
      }

      const inputDefaultValue = currentConfig ? currentConfig[field_name] : inputField.default ? inputField.default : "";

      return (
        <AvField
          className={`${autoSetup ? "input-disabled" : "light-input-dark-bg"}`}
          key={`${field_name}-${index}`}
          name={field_name}
          label={inputField.label}
          type="text"
          errorMessage={`Enter ${inputField.label}`}
          validate={{ required: { value: !autoSetup } }}
          defaultValue={inputDefaultValue}
          disabled={autoSetup}
        />
      );
    });
  };

  const renderEnableButton = () => {
    const isProcessing = setPartnerLoading || furtimLoading;
    const label = isProcessing ? "Processing" : `Enable ${partnerName || "Partner"}`;
    return (
      <div className="d-flex justify-content-center button-container mt-4">
        <Button
          type="submit"
          color="light"
          className="btn-large mr-1"
          disabled={isProcessing || !companyId || !partnerId}
        >
          {label}
        </Button>
      </div>
    );
  };

  return (
    <LoadingContent
      isLoading={partnerConfigLoading}
      errorMessage={getApiErrorMessage(partnerConfigError)}
      retryRequest={refetchPartnerFields}

    >
      <AvForm
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
        ref={formRef}
      >
        <FormGroup>
          {renderInputs()}
          {renderEnableButton()}
        </FormGroup>
      </AvForm>
    </LoadingContent>
  );
};

export default NewConnectionForm;
