import React from 'react';

const TextHeader = (props) => {
  //  Parsing props
  const { label = '' } = props;
  
  //  Rendering
  return (
    <div className="text-header">
      {label}
    </div>
  );
};

export default TextHeader;
