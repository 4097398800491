import cyvatarAxios from '../axios';
import {
  FETCH_ASSESSMENT,
  FETCH_COMPLIANCES,
  FETCH_ANSWERS,
  GET_ASSESSMENT_STATUS,
  FETCH_RESULTS,
  GET_COMPLIANCE,
  GET_COMPLIANCE_STATUS,
  FETCH_COMPLIANCE_SCORE,
  GET_ACTIVITY_REPORT,
  GET_SOLUTIONS_AND_CONTROLS,
  GET_OPPORTUNITIES_TO_IMPROVE,
} from '../ApiKeys';

const fetchAssessment = (complianceId) => {
  return cyvatarAxios.get(`/api/assessment${complianceId ? `/${complianceId}` : ''}`)
};

const fetchCompliances = () => {
  return cyvatarAxios.get('/api/assessment/compliances');
};

const editComplianceList = ({ add, complianceId, companyId }) => {
  if (add) {
    //TODO: endpoint for adding compliance
  } else {
    //TODO: endpoint for removing compliance and deleting answers
  }
};

const postAnswer = (answer) => {
  const {
    assessmentId,
    assessmentExecutionId,
    categoryId,
    sectionId,
    questionId,
    optionId
  } = answer;

  const body = JSON.stringify({
    assessment_id: assessmentId,
    category_id: categoryId,
    section_id: sectionId,
    question_id: questionId,
    option_id: optionId,
  });

  return cyvatarAxios.post(`/api/assessment_execution/${assessmentExecutionId}/answer`, body);
};

const postAssessmentComment = (assessmentComment) => {
  const { comment, assessmentExecutionId } = assessmentComment;
  const body = JSON.stringify({ comment });
  return cyvatarAxios.post(`/api/assessment_execution/${assessmentExecutionId}/comment`, body);
};

const postAnswers = (assessmentExecution, companyId = null) => {
  const body = JSON.stringify(assessmentExecution);
  return cyvatarAxios.post(`/api/assessment_execution${companyId ? `/${companyId}` : ''}`, body);
}

const fetchAnswers = (assessmentId, companyId = null) => {
  return cyvatarAxios.get(`/api/assessment_execution/${assessmentId}${companyId ? `/${companyId}` : ''}`);
};

const resetAssessment = () => {
  return cyvatarAxios.delete('/api/assessment_execution');
};

const postAssessmentExecutionStatus = (assessment_execution_id) => {
  return cyvatarAxios.post(`/api/assessment_execution/${assessment_execution_id}/complete`);
};

const getAssessmentStatus = async (companyId) => {
  const apiResponse = await cyvatarAxios.get(`api/assessment_execution/status/${companyId}`);
  const { data } = apiResponse;
  return data;
};

//  Array of queries to invalidate on assessment answers
const assessmentAnswersInvalidators = [
  FETCH_RESULTS,
  GET_COMPLIANCE,
  GET_COMPLIANCE_STATUS,
  FETCH_COMPLIANCE_SCORE,
  GET_ACTIVITY_REPORT,
  FETCH_ASSESSMENT,
  FETCH_COMPLIANCES,
  FETCH_ANSWERS,
  GET_ASSESSMENT_STATUS,
  GET_SOLUTIONS_AND_CONTROLS,
  GET_OPPORTUNITIES_TO_IMPROVE
];

export const AssessmentAPI = {
  handlers: {
    fetchAssessment,
    fetchCompliances,
    editComplianceList,
    postAnswer,
    postAssessmentComment,
    postAnswers,
    fetchAnswers,
    resetAssessment,
    postAssessmentExecutionStatus,
    getAssessmentStatus,
  },
  keys: {
    fetchAssessment: FETCH_ASSESSMENT,
    fetchCompliances: FETCH_COMPLIANCES,
    fetchAnswers: FETCH_ANSWERS,
    getAssessmentStatus: GET_ASSESSMENT_STATUS
  },
  invalidators: {
    editComplianceList: [
      GET_COMPLIANCE,
      GET_COMPLIANCE_STATUS,
      FETCH_COMPLIANCE_SCORE,
      FETCH_COMPLIANCES,
    ],
    postAnswer: assessmentAnswersInvalidators,
    postAssessmentComment: [
      FETCH_RESULTS,
      FETCH_ASSESSMENT,
      FETCH_ANSWERS,
    ],
    postAnswers: assessmentAnswersInvalidators,
    resetAssessment: [
      FETCH_RESULTS,
      FETCH_ASSESSMENT,
      FETCH_ANSWERS,
    ],
    postAssessmentExecutionStatus: assessmentAnswersInvalidators,
  }
};
