import _ from 'lodash';

export const parseTaggingsList = (taggings = []) => {
    return taggings.map((tagging) => {
        return tagging?.global_tag
    })
}

export const removeTagById = (tagId, tagsList) => {
    const tagIndex = tagsList?.findIndex(tag => tag?.id === tagId);
    if (!tagsList || tagIndex < 0) return tagsList;
    tagsList.splice(tagIndex, 1);
    return tagsList
}

export const parseTagsToOptions = (tags = [], companyId, isCydekick) => {
    const isGlobal = _.isNil(companyId) && isCydekick;
    return tags.map((element) => {
        const tag = isGlobal ? element : element.global_tag;
        return { label: tag?.title, value: tag?.id, is_tagged: element?.is_tagged ? true : false }
    });
};

export const filterOutAddedTags = (tags, filteredTags) => {
    return tags.map((tag) => {
        const filteredTag = filteredTags.find((a) => a.value === tag?.value);
        return {
            ...tag,
            is_filtered: filteredTag ? true : false,
            filter_option: filteredTag?.filterOption
        }
    })
};

export const mapSimpleTagsToOptions = (tags = []) => {
    return tags.map(({ id: value, title: label }) => ({ value, label }));
};
