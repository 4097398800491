import { SET_IDS_TO_DELETE, SET_IDS_TO_RESTORE } from './types';

export const setIdsToDelete = (ids = []) => {
  return {
    type: SET_IDS_TO_DELETE,
    payload: ids,
  };
};

export const setIdsToRestore = (ids = []) => {
  return {
    type: SET_IDS_TO_RESTORE,
    payload: ids,
  };
};
