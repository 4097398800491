import cyvatarAxios from '../axios';
import {
  LIST_PARTNERS,
  LIST_PARTNERS_DEFAULTS,
  FETCH_PARTNERS,
  GET_FURTIM_DASHBOARD,
  FETCH_ASSETS_DASHBOARD,
  GET_ACTIVE_PARTNERS,
  GET_DEVICES_LIST,
  GET_DEVICES_COUNT,
  GET_ISSUE,
  GET_ISSUES_LIST,
  GET_ROLLUP_DATA,
  LIST_COMPANY,
  GET_CYVALUE_TABS,
  GET_CYVALUE_DATA,
  GET_CYVALUE_BY_WEEK,
  GET_ACTIVITY_REPORT,
  GET_COMPLIANCE_STATUS,
  GET_COMPLIANCE,
  FETCH_COMPLIANCE_SCORE,
  GET_SOLUTIONS_AND_CONTROLS,
  GET_OPPORTUNITIES_TO_IMPROVE,
  GET_DEVICE_BY_PARTNER_COUNT,
  GET_PARTNER_ACTIONS,
  GET_PARTNER_ACTIONS_LIST_OPTIONS,
  GET_ISSUE_SOURCES,
  GET_CONNECTION_STATUS_LIST,
  GET_PARTNER_FIELDS,
  GET_PENDING_PARTNERS,
  GET_SOLUTION_STATUS,
  GET_ARCHIVED_DEVICES,
  GET_ARCHIVED_ISSUES,
  LIST_PARTNER_MANUAL_ACTIONS,
  GET_SOLUTIONS_BY_COMPANY_ID,
  GET_PARTNER_ISSUE,
  GET_ICARM_MAINTENANCE_SOLUTIONS,
  GET_ICARM_ONBOARDING_SOLUTIONS
} from "../ApiKeys";

const listPartners = (payload) => {
  return cyvatarAxios.get(`/api/partners/list`, { params: payload });
};

const addExclusionRule = async (payload) => {
  const body = JSON.stringify(payload);
  const response = await cyvatarAxios.post(`/api/partners/exclude_issue`, body);
  return response?.data;
};

const removeExclusionRule = async (payload) => {
  const response = await cyvatarAxios.delete(`/api/partners/remove_exclusion`, { data: payload });
  return response?.data;
};

const fetchPartners = (company_id, payload) => {
  return cyvatarAxios.get(`/api/partners/${company_id}`, { params: payload });
};

const getConnectionStatusList = (payload) => {
  return cyvatarAxios.get(`/api/partners/connections`, { params: payload });
};

const setPartner = async (body) => {
  const response = await cyvatarAxios.post(`/api/company/set_partner`, body);
  return response?.data;
}

const enableFurtim = async (form) => {
  const response = await cyvatarAxios.post(`/api/partners/furtim/enable`, form);
  return response?.data;
};

const getFurtimDashboard = () => {
  return cyvatarAxios.get('/api/furtim/dashboard')
};

const fetchAssetsDashboard = () => {
  return cyvatarAxios.get('/api/axonius/dashboard');
};

const callPartnerAction = async (body) => {
  const response = await cyvatarAxios.post(
    '/api/partners/manual-actions',
    body,
  );
  const { data } = response;
  return data;
};

const resolveIssueOnPartner = async (body) => {
  const response = await cyvatarAxios.post(
    '/api/partners/resolve-issue',
    body,
  );
  const { data } = response;
  return data;
};

//  Params: { company_id, filters }
const getActivePartners = async (params) => {
  const response = await cyvatarAxios.get('/api/partners/list_active', { params });
  const { data } = response;
  return data;
};

//  Params: { company_id, filters }
const getPendingPartnersByCompany = async (params) => {
  const response = await cyvatarAxios.get('/api/partners/list-pending', { params });
  const { data } = response;
  return data;
};

// Get partner defaults
const listPartnersDefaults = async (payload) => {
  const { data: { partners_info } } = await cyvatarAxios.get(`/api/partners/defaults`, { params: payload });
  return partners_info;
};

const getPartnerActions = async (params) => {
  const response = await cyvatarAxios.get(`/api/partner_actions/${params?.partnerId}/list`);
  const { data } = response;
  return data;
}

const getPartnerIssue = async (code) => {
  const response = await cyvatarAxios.get(`/api/partner_actions/issue`, { params: { code } });
  const { data } = response;
  return data;
}

const getPartnerActionsListOptions = async (params) => {
  const response = await cyvatarAxios.get('/api/partner_actions/list_options', { params });
  const { data } = response;
  return data;
}

const executeQuickAction = async (payload) => {
  const response = await cyvatarAxios.patch('/api/partners/connections/action', payload);
  return response?.data;
};

const getPartnerFields = async (params) => {
  if (!params?.company_id || !params?.partner_id) return null;
  const response = await cyvatarAxios.get('/api/partners/partner-fields', { params });
  const { data } = response;
  return data;
}

const getPartnerManualActions = async () => {
  const response = await cyvatarAxios.get('/api/partners/manual_actions');
  const { data } = response;
  return data;
}

//  Queries to invalidate on set partner
const setPartnerInvalidators = [
  LIST_COMPANY,
  GET_CYVALUE_TABS,
  GET_CYVALUE_DATA,
  GET_CYVALUE_BY_WEEK,
  GET_ACTIVITY_REPORT,
  GET_COMPLIANCE_STATUS,
  GET_COMPLIANCE,
  FETCH_COMPLIANCE_SCORE,
  LIST_PARTNERS,
  LIST_PARTNERS_DEFAULTS,
  FETCH_PARTNERS,
  GET_ISSUES_LIST,
  GET_ISSUE,
  GET_ROLLUP_DATA,
  GET_DEVICES_COUNT,
  GET_DEVICES_LIST,
  GET_SOLUTIONS_AND_CONTROLS,
  GET_OPPORTUNITIES_TO_IMPROVE,
  GET_ACTIVE_PARTNERS,
  GET_DEVICE_BY_PARTNER_COUNT,
  GET_PARTNER_ACTIONS,
  GET_PARTNER_ACTIONS_LIST_OPTIONS,
  GET_ISSUE_SOURCES,
  GET_CONNECTION_STATUS_LIST,
  GET_SOLUTION_STATUS,
  GET_SOLUTIONS_BY_COMPANY_ID,
  GET_PARTNER_ISSUE,
  GET_ICARM_MAINTENANCE_SOLUTIONS,
  GET_ICARM_ONBOARDING_SOLUTIONS,
];

export const PartnerAPI = {
  handlers: {
    listPartners,
    listPartnersDefaults,
    addExclusionRule,
    removeExclusionRule,
    fetchPartners,
    setPartner,
    enableFurtim,
    getFurtimDashboard,
    fetchAssetsDashboard,
    callPartnerAction,
    resolveIssueOnPartner,
    getActivePartners,
    getPartnerActions,
    getPartnerActionsListOptions: getPartnerActionsListOptions,
    getConnectionStatusList,
    executeQuickAction,
    getPartnerFields,
    getPendingPartnersByCompany,
    getPartnerManualActions,
    getPartnerIssue
  },
  keys: {
    listPartners: LIST_PARTNERS,
    listPartnersDefaults: LIST_PARTNERS_DEFAULTS,
    fetchPartners: FETCH_PARTNERS,
    getFurtimDashboard: GET_FURTIM_DASHBOARD,
    fetchAssetsDashboard: FETCH_ASSETS_DASHBOARD,
    getActivePartners: GET_ACTIVE_PARTNERS,
    getPartnerActions: GET_PARTNER_ACTIONS,
    getPartnerActionsListOptions: GET_PARTNER_ACTIONS_LIST_OPTIONS,
    getConnectionStatusList: GET_CONNECTION_STATUS_LIST,
    getPartnerFields: GET_PARTNER_FIELDS,
    getPendingPartnersByCompany: GET_PENDING_PARTNERS,
    getPartnerManualActions: LIST_PARTNER_MANUAL_ACTIONS,
    getPartnerIssue: GET_PARTNER_ISSUE
  },
  invalidators: {
    addExclusionRule: [
      GET_DEVICES_LIST,
      GET_DEVICES_COUNT,
      GET_ISSUE,
      GET_ISSUES_LIST,
      GET_ROLLUP_DATA,
      GET_DEVICE_BY_PARTNER_COUNT,
      GET_CYVALUE_DATA,
    ],
    removeExclusionRule: [
      GET_DEVICES_LIST,
      GET_DEVICES_COUNT,
      GET_ISSUE,
      GET_ISSUES_LIST,
      GET_ROLLUP_DATA,
      GET_DEVICE_BY_PARTNER_COUNT,
      GET_CYVALUE_DATA,
    ],
    setPartner: setPartnerInvalidators,
    enableFurtim: [GET_FURTIM_DASHBOARD, ...setPartnerInvalidators],
    callPartnerAction: setPartnerInvalidators,
    resolveIssueOnPartner: [
      GET_ISSUE,
      GET_ISSUES_LIST,
      GET_ROLLUP_DATA,
      GET_CYVALUE_DATA,
      GET_CYVALUE_BY_WEEK,
      GET_ACTIVITY_REPORT,
      GET_COMPLIANCE_STATUS,
      GET_COMPLIANCE,
      FETCH_COMPLIANCE_SCORE,
    ],
    executeQuickAction: [
      ...setPartnerInvalidators,
      GET_ARCHIVED_DEVICES,
      GET_ARCHIVED_ISSUES,
    ],
    getPendingPartnersByCompany: setPartnerInvalidators,
  },
};
