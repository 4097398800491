import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { setRiskTreatmentFilter } from '../../../actions/businessRisk';
import { riskTreatmentIcons } from '../../../constants/riskTreatment';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import BasicTooltip from '../../dashboard/tooltips/BasicTooltip';

const RiskTreatmentFilter = (props) => {

    const {
        riskTreatment,
        className,
        enabled=true
    } = props;

    //  Component's Hooks
    const { dispatch } = useMemoizedDispatch();

    const componentId = `risk-treatment-filter-${riskTreatment?.id}`;

    //  Getting needed info from redux store
    const selectedRiskTreatment = useSelector((state) => state.businessRisk.riskTreatmentFilter);

    const onRiskTreatmentFilterClick = () => {
        if(!enabled) return;
        if(riskTreatment?.id === selectedRiskTreatment) {
            //Clear filter
            dispatch(setRiskTreatmentFilter(null))
        } else {
            dispatch(setRiskTreatmentFilter(riskTreatment?.id))
        }
    }

    const renderDescriptionTooltip = () => {
        return (
            <BasicTooltip
                className="risk-treatment-description-tooltip"
                target={componentId}
                content={riskTreatment?.description}
            />
        )
    }

    return (
        <Card
            className={`risk-treatment-filter mb-0 ${className || ""} ${selectedRiskTreatment === riskTreatment.id ? 'active-filter' : ''}`}
            onClick={onRiskTreatmentFilterClick}
            id={componentId}
        >
            <CardBody className="d-flex align-items-center p-1">
                <div className={`risk-treatment-circle rounded-circle mr-2 bg-${riskTreatment?.label?.toLowerCase()}`}>
                    <i className={`bx ${riskTreatmentIcons[riskTreatment?.label]}`}/>
                </div>
                {riskTreatment?.label?.toLowerCase()}
                {riskTreatment?.description && renderDescriptionTooltip()}
            </CardBody>
        </Card>
    )
}

export default RiskTreatmentFilter;
