import _ from 'lodash';
import React, { useState } from 'react'
import BulkActionsMenu from '../layout/BulkActionsMenu';
import DevicesAddRemoveTagsModal from './DevicesAddRemoveTags';
import DevicesContextMenu from './DevicesContextMenu';
import { setAlert } from '../../actions/alerts';
import { onDeleteDeviceChange, onMergeDevicesChange } from '../../actions/devices';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';

const DevicesBulkActions = (props) => {
    //  Parsing params
    const {
        selectedDevices,
        parentRef,
        onRightClick,
        scrollableRef,
        bulkMenuId = '',
        bulkMenuRef,
        bulkMenuStyle = {},
    } = props;

    // Component state
    const [showTagsModal, setShowTagsModal] = useState(false);

    //  Component hooks
    const { dispatch } = useMemoizedDispatch();

    const toggleTagsModal = (show) => {
        const showModal = !_.isNil(show) ? show : !showTagsModal
        setShowTagsModal(showModal)
    }

    //  Function to trigger device delete
    const onDeleteDeviceClicked = () => {
        dispatch(onDeleteDeviceChange(true, selectedDevices || [], false));
    }

    //  Function to trigger device merge
    const onDevicesMergeClicked = () => {
        if (selectedDevices?.length < 2) {
            dispatch(setAlert('Need to select more than one device to merge. ', 'danger'));
            return;
        }
        dispatch(onMergeDevicesChange(true, selectedDevices || [], false));
    };

    const bulkActionOptions = [
        {
            label: "Tags",
            iconClassName: "bx bx-bookmark",
            callback: toggleTagsModal,
        },
        /*{
            label: "Merge",
            iconClassName: "bx bx-wrench",
            callback: onDevicesMergeClicked,

        },*/
        {
            label: 'Delete',
            iconClassName: 'bx bx-trash',
            callback: onDeleteDeviceClicked,
        }
    ]

    return (
        <>
            <DevicesContextMenu
                parentRef={parentRef}
                onRightClick={onRightClick}
                toggleTagsModal={toggleTagsModal}
                scrollableRef={scrollableRef}
                onDeleteDevices={onDeleteDeviceClicked}
                onMergeDevices={onDevicesMergeClicked}
            />
            <DevicesAddRemoveTagsModal
                showModal={showTagsModal}
                toggleModal={toggleTagsModal}
                selectedDevices={selectedDevices}
            />
            <BulkActionsMenu
                options={bulkActionOptions}
                show={selectedDevices?.length > 0}
                bulkMenuId={bulkMenuId}
                bulkMenuRef={bulkMenuRef}
                bulkMenuStyle={bulkMenuStyle}
                isSticky={true}
                className={"pb-2"}
            />
        </>
    )
}

export default DevicesBulkActions;
