import cyvatarAxios from '../axios';
import {
  DISABLE_MFA_REQUEST,
  VALIDATE_RESET_PASSWORD_TOKEN,
  AUTH_USER,
} from '../ApiKeys';
import setAuthToken from '../../utils/axios/setAuthToken';

//  Requests
const login = (formValues) => {
  const body = JSON.stringify(formValues);
  return cyvatarAxios.post('/api/user/mfa-login', body);
};

const validatePassword = (formValues) => {
  const body = JSON.stringify(formValues);
  return cyvatarAxios.post('/api/user/login', body);
};

const validateMFACode = (formValues) => {
  const body = JSON.stringify(formValues);
  return cyvatarAxios.post('/api/user/validate_code', body);
};

const requestMFACode = (formValues) => {
  const body = JSON.stringify(formValues);
  return cyvatarAxios.post('/api/user/send_code', body);
};

const disableMFARequest = () => {
  return cyvatarAxios.get(`api/user/disable_mfa`);
};

const register = (formValues) => {
  const body = JSON.stringify(formValues);
  return cyvatarAxios.post('/api/user', body);
};

const sendResetPasswordToken = (email) => {
  const body = JSON.stringify({ email });
  return cyvatarAxios.post('api/user/reset_password', body);
};

const validateResetPasswordToken = (token) => {
  return cyvatarAxios.get(`api/user/reset_password/${token}`);
};

const resetPassword = (formValues, token) => {
  const body = JSON.stringify(formValues);
  return cyvatarAxios.post(`api/user/reset_password/${token}`, body);
};

const updatePassword = (formValues) => {
  const body = JSON.stringify(formValues);
  return cyvatarAxios.post('api/user/change_password', body);
};

const logout = () => {
  return cyvatarAxios.post('api/user/logout');
};

const authUser = () => {
  setAuthToken(localStorage.token)
  return cyvatarAxios.get('/api/user');
};

const resendVerification = async (public_id) => {
  const response = await cyvatarAxios.post('/api/user/resend-verification', { public_id });
  const { data } = response;
  return data;
}

//  Building API object
export const AuthAPI = {
  handlers: {
    login,
    validatePassword,
    validateMFACode,
    requestMFACode,
    disableMFARequest,
    register,
    sendResetPasswordToken,
    validateResetPasswordToken,
    resetPassword,
    updatePassword,
    logout,
    authUser,
    resendVerification,
  },
  keys: {
    disableMFARequest: DISABLE_MFA_REQUEST,
    validateResetPasswordToken: VALIDATE_RESET_PASSWORD_TOKEN,
    authUser: AUTH_USER,
  },
  invalidators: {
    login: null,
    validatePassword: null,
    validateMFACode: null,
    requestMFACode: null,
    register: null,
    sendResetPasswordToken: null,
    resetPassword: null,
    updatePassword: null,
    logout: [], // Need to invalidate everything
    resendVerification: null,
  }
};
