import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { automationActions, automationActionsTypes } from '../../constants/automation';
import { requestDeleteRules } from '../../actions/automation';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import { useHistory, useRouteMatch } from 'react-router-dom';

const AutomationActionsCell = (props) => {
  //  Parsing props
  const { ruleSet } = props;

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const history = useHistory();
  const { path } = useRouteMatch()

  //  Function to modify rule set
  const modifyRuleSet = () => {
    history.push(`${path}/rule/${ruleSet?.id}`)
  };

  //  Function to delete rule set
  const deleteRuleSet = () => {
    const payload = { selected: [ruleSet.id], excluded: [], allSelected: false };
    dispatch(requestDeleteRules(payload));
  };

  //  Function to handle actions
  const handleAction = (type) => {
    if (type === automationActionsTypes.MODIFY) { modifyRuleSet(); }
    if (type === automationActionsTypes.DELETE) { deleteRuleSet(); }
  }

  //  Function to render actions
  const renderActions = () => {
    return automationActions.map((action, index) => {
      const { className, actionType, tooltip } = action;
      return (
        <>
          <div
            id={`automation-action-${index}`}
            key={index}
            className={className}
            onClick={() => { handleAction(actionType); }}
          >
            <UncontrolledTooltip target={`automation-action-${index}`} key={`tooltip-${index}`}>
              {tooltip}
            </UncontrolledTooltip>
          </div>
        </>
      );
    });
  }

  //  Rendering
  return (
    <div className="automation-actions-container">
      {renderActions()}
    </div>
  );
};

export default AutomationActionsCell;
