import React from "react";
import { Modal, Row, Col } from 'reactstrap';
import { useSelector } from "react-redux";
import moment from 'moment-timezone';
import logo from '../../../../assets/images/logos/cyvatar-logo.svg';
import useMemoizedDispatch from "../../../../hooks/useMemoizedDispatch";
import { successActionCreator } from "../../../../actions/action-creators/success";
import { SET_OPEN_OVERVIEW } from "../../../../actions/types";
import LoadingContent from "../../../layout/LoadingContent";

const OverviewModal = (props) => {
  const {
    showModal = true,
    children,
    options,
    updateOption,
    title = '',
    filter,
    isLoading = false,
    error = false,
    retryRequest
  } = props


  //  Watching redux store
  const range_value = useSelector((state) => state?.dashboards?.range_value);
  const start_date = useSelector((state) => state?.dashboards?.start_date);
  const end_date = useSelector((state) => state?.dashboards?.end_date);

  //  Component's Hooks
  const { dispatch } = useMemoizedDispatch();

  const toggleModal = () => {
    dispatch(successActionCreator(SET_OPEN_OVERVIEW,''));
  }


  const renderSideBar = () =>{
    return(
      <Col className="sidebar" md={3}>
        <img
          src={logo}
          alt="cyvatar"
          className="img-fluid"
          style={{ width: '60%'}}
        />
        <p className='title'>{title}</p>
        <p className='subtitle'>Overview</p>
        {renderFilters()}
      </Col>
    )
  }

  const getTimeLabel = () => {
    if (range_value>0) {return `Last ${range_value} days` }
    return `${moment(start_date).format("DD/MM/YYYY")} to ${moment(end_date).format("DD/MM/YYYY")}`
  }

  const renderDateLabel = () =>{
    return(
      <div className="section-container">
        <p className="overview-date-label">
          {getTimeLabel()}{filter?.extraLabel}
        </p>
      </div>
    )
  }

  const renderFilters = () => {
    if (!options || options.length === 0) {return null;}
    return(
      <ul className='filter-list'>
          {options.map((item)=>(
          <li
            className={`filter-item ${item?.value===filter?.value && 'active'}`}
            onClick={()=>updateOption(item)}
            >{item.label}</li>
          ))
          }
        </ul>
    )
  }

  const renderBody = () => {
    return(
      <div className="section-container">
        <LoadingContent
          isLoading={isLoading}
          errorMessage={
            error ? "Oops! our fault, reload again." : null
          }
          iconType="solidIcon"
          errorStyle={{ minHeight: "150px", paddingTop: "16px" }}
          loadingStyle={{ minHeight: "150px", paddingTop: "16px" }}
          errorButtonCallback={retryRequest}
          >
          {children}
        </LoadingContent>
      </div>
    )
  }

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      centered={true}
      size="xl"
      className="dashboard-overview-modal"
      autoFocus="false"
    >
      <Row className="content pt-0 h-100 mx-0">
        {renderSideBar()}
        <Col className="px-3 pb-4 overview-main-content" md={9}>
        <div className="close-btn">
          <button
            type="button"
            onClick={toggleModal}
            className="close text-white pt-2 pr-0"
            style={{ fontSize: "30px"}}
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
          {renderDateLabel()}
          {renderBody()}
        </Col>
      </Row>
    </Modal>
  )
}

export default OverviewModal
