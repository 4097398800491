import React from 'react';
import { Button } from 'reactstrap';

const CustomerNameCell = (props) => {
  //  Parsing props
  const {
    row,
    onCellClick,
  } = props;

/**
 * If the row is not verified, return the unverified class and label. If the row is not active, return
 * the inactive class and label
 * @returns An object with a className and label property.
 */
  const getMemberTypeLabel = () => {
    let memberType = '';
    if (!row?.verified) memberType = { className: 'unverified', label: 'Unverified' };
    if (!row?.active) memberType = { className: 'inactive', label: 'Inactive' };
    return memberType;
  };

  //  Function to render member type label
  const renderMemberTypeLabel = () => {
    if (!row?.active || !row?.verified) {
      const { className, label } = getMemberTypeLabel();
      return (
        <div className={`member-type-container ${className}`}>
          {label}
        </div>
      );
    }

    return (
      <div className="member-type-container">
        <div className="bx bx-check-circle active-icon" />
      </div>
    );
  }

  //  Rendering
  return (
    <div className="member-name-cell">
      <Button
        color="link"
        className="setup-link"
        tabIndex="-1"
        aria-label="Edit Profile"
        onClick={() => onCellClick(row?.id, '/profile')}
      >
        <span className="info-link">
          {row?.name}
        </span>
      </Button>
      {renderMemberTypeLabel()}
    </div>

  );
};

export default CustomerNameCell;
