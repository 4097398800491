import React from 'react';
import {Spinner} from 'reactstrap';

export const CustomSpinner = ({text, customStyle, customClassName}) => {
    return (
        <div 
            className={`d-inline-flex flex-column justify-content-center 
                align-items-center text-center w-100 ${customClassName}`}
            style={customStyle}
        >
            <div>

            <Spinner className="custom-spinner" animation="border" variant="success" />
            </div>

            <div className="spinner-text normal-text-style">{text}</div>
        </div>  
    )
}

export default CustomSpinner;
