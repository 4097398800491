import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { onSubtabSelected } from '../../../actions/navigation';
import { trackEvent } from '../../../utils/gaTracker';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import { useHistory } from 'react-router-dom';
import useCanAbility from '../../../hooks/useCanAbility';
import { ENTER_KEY_NAME } from '../../../constants/keyNames';

const SubTab = (props) => {
  //  Parsing props
  const { tab } = props;

  //  Memoized dipsatch
  const { dispatch } = useMemoizedDispatch();

  //  Watching redux store
  const selectedSubtab = useSelector((state) => state.navigation.selectedSubtab);

  //  Component state
  const [selected, setSelected] = useState(false);

  // Component Hooks
  const history = useHistory();
  const ability = useCanAbility();

  //  Function to handle tab click
  const onTabClick = () => {
    if (tab?.identifier === selectedSubtab?.identifier && tab?.label === selectedSubtab?.label) { return; }
    trackEvent('topbar-tab-clicked', 'topbar', (tab?.label || '').toLowerCase().split(' ').join('-'));
    if (tab?.searchOnly) {
      history.replace({ search: tab?.search })
    } else if (tab?.path) {
      history.push(tab?.path)
    }
    dispatch(onSubtabSelected(tab));
  };

  //  Watching props changes
  useEffect(() => {
    if (!tab || !selectedSubtab) { return; }
    setSelected(tab.identifier === selectedSubtab.identifier);
  }, [tab, selectedSubtab]);

  //  Watching tab selected changes
  useEffect(() => {
    if (!selected) { return; }
    if (!tab?.manualRouting) { return; }
    if (!tab?.path) { return; }
    history.push(tab?.path)
  }, [selected, tab?.path, tab?.manuelRouting]);

  const canAccessRoute = tab?.access && tab?.resource ? ability.can(tab?.access, tab?.resource) : true;

  //  Rendering
  return canAccessRoute ? (
      <div
      className={`subtab${selected ? ' selected' : ''}`}
      onClick={() => onTabClick()}
      aria-label={`${tab?.label}`}
      tabIndex="0"
      role="button"
      onKeyUp={(e) => e?.key === ENTER_KEY_NAME ? onTabClick() : null}
    >
      {tab?.label || ''}
    </div>
  ) : null;
};

export default SubTab;
