import React, { useState, useEffect } from "react";
import PartnerActionField from "./PartnerActionField";
import { useSelector } from "react-redux";
import { checkRequiredKeys } from "../../utils/partnerActionHelpers"
import { components } from "react-select";

const PartnerActionComponent = (props) => {
  // Props and vars 
  const { component } = props;
  const { required_keys } = component;

  // Redux
  const partnerActionValues = useSelector((state) => state.partners.partnerActionValues);

  const renderTitle = () => {
    if (!component) { return null };
    const { title } = component;
    if (!title || title == '') { return null };
    return (
      <div className="partner-action-component-title">
        {title}
      </div>
    );
  }

  const renderDescription = () => {
    if (!component) { return null };
    const { description } = component;
    if (!description || description == '') { return null };
    return (
      <div className="component-description">
        {description}
      </div>
    );
  }

  const renderFields = () => {
    if (!component) { return null };
    const { fields } = component;
    if (!fields) { return null };
    return fields.map((field) => {
      // TODO: Check required keys first before displaying field
      return(
        <PartnerActionField field={field}></PartnerActionField>
      )
    });
  }

  const renderComponent = () => {   
    if (!checkRequiredKeys(component, partnerActionValues)) {
      return null;
    } 
    return (
      <div className="partner-action-component">
        {renderTitle()}
        {renderDescription()}
        <div className="partner-action-field-container">{renderFields()}</div>
      </div>
    );
  }
  // Rendering
  return (
    <React.Fragment>
      {renderComponent()}
    </React.Fragment>
  );
};

export default PartnerActionComponent;
