import { useMemo } from 'react';
import API from '../api';
import useCustomMutation from './useCustomMutation';

const useUpsertGRCIssue = (code) => {
  //  Initializing API
  const { GrcIssueAPI } = API;

  //  API function key
  const key = useMemo(() => code ? 'updateIssue' : 'createIssue', [code]);

  // Initializing mutation
  const { mutate, ...restOfAssets } = useCustomMutation(
    GrcIssueAPI.handlers?.[key],
    GrcIssueAPI.invalidators?.[key],
  );

  //  Function to call API
  const upsertIssue = (issue) => {
    const params = { issue };
    if (code) { params.code = code };
    mutate(params);
  };

  return {
    ...restOfAssets,
    upsertIssue,
  };
};

export default useUpsertGRCIssue;
