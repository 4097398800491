import React from 'react';
import { CardBody, Card } from 'reactstrap';

const FormCard = (props) => {
    const {
        title = '',
        header,
        customClassName = '',
        error,
        children,
        customTitleClass = '',
        customWidth,
    } = props;
    return (
        <div className={`form-card-container ${customClassName}`}>
            <div className="form-card-header d-inline-block text-light">{header}</div>
            <Card className="form-card text-dark overflow-hidden" color="white" style={{width: customWidth}}>
                <CardBody className="pt-0 pb-0">
                    {error ? (
                        <div className="error-feedback px-2 py-1">
                            {error}
                        </div>
                    ) : null}
                    {title!=='' && (
                        <div className={`form-card-title ${customTitleClass || ''}`}>
                            <div className="d-inline-block purple-bottom-border">
                                {title}
                            </div>
                        </div>
                    )} 
                    {children}
                </CardBody>
            </Card>
        </div>
    );
}

export default FormCard;
