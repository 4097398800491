import _ from 'lodash';
import React, { createRef } from 'react'
import { useSelector } from 'react-redux';
import { CREATE_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';
import useCanAbility from '../../hooks/useCanAbility';

const MainScrollableContainer = (props) => {
    const {
        additionalClassName = '',
        children,
        scrollableRef = createRef(),
        onScroll
    } = props;

    // Component Hooks
    const ability = useCanAbility();

    // Getting needed info from redux store
    const showBackLink = useSelector(state => state.navigation.backLink?.show);
    const activeImpersonation = useSelector((state) => !_.isNil(state.impersonation.companyId));
    const open_overview = useSelector(state => state.dashboards?.open_overview);
    const isOpenModal = useSelector((state) => (state.layout.isOpenModal));



    // Get container className
    const getClassName = () => {
        const canImpersonate = ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.MULTIPLE.IMPERSONATE)
        return canImpersonate || activeImpersonation || showBackLink ? 'secondary-navbar-added' : '';
    }

    const showScrollbar = () => {
        return open_overview || isOpenModal ? 'hide-scroll' : '';
    }

    return (
        <div
            className={`main-scrollable-container ${additionalClassName} ${getClassName()} ${showScrollbar()}`}
            ref={scrollableRef}
            onScroll={onScroll}
        >
            {children}
        </div>
    )
}

export default MainScrollableContainer
