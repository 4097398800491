import React from 'react';
import grades from '../../constants/grades';

const LetterGradeCircle = (props) => {
    const {
        className,
        grade,
        size = "20px"
    } = props;
    const gradeColor = grades[grade || 'DEFAULT']?.className;
    return (
        <div
            style={{ width: size, height: size}}
            className={`grade-circle-component rounded-circle d-inline-flex align-items-center justify-content-center bg-${gradeColor} ${className}`}
        >
            <div className="grade-text d-inline-block">{grade}</div>
        </div>
    )
}

export default LetterGradeCircle;