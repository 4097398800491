import React, { useEffect, useMemo, useRef, useState } from 'react';
import CustomToggleSwitch from '../layout/CustomToggleSwitch';
import { ruleSetsStatus } from '../../constants/automation';
import API from '../../api';
import { setAlert } from '../../actions/alerts';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useCustomMutation from '../../hooks/useCustomMutation';
import useCanAbility from '../../hooks/useCanAbility';
import { MODIFY_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';

const AutomationStatusCheckbox = (props) => {
  //  Parsing props
  const {
    rule,
    status,
  } = props;

  //  Initializing APIs
  const { RuleAPI } = API;

  //  Component refs
  const didMountRef = useRef(null);

  // Component Hooks
  const ability = useCanAbility();

  //  Component variables
  const isDraft = useMemo(() => {
    return (status === ruleSetsStatus.DRAFT);
  }, [status, ruleSetsStatus]);
  const canModifyRule = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.AUTOMATION.INTEGRATION_RULE)

  //  Component state
  const [prevStatus, setPrevStatus] = useState(status);
  const [checked, setChecked] = useState(status === ruleSetsStatus.ACTIVE);

  //  Function to trigger error state
  const onError = () => {
    dispatch(setAlert('Error updating rule status', 'danger'));
    setChecked(!checked);
  };

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const {
    data: changeStatusResponse,
    error: changeStatusError,
    mutate: changeStatus,
  } = useCustomMutation(
    (params) => RuleAPI.handlers.changeRuleStatus(params),
    RuleAPI.invalidators.changeRuleStatus
  );

  //  Watching status changes
  useEffect(() => {
    setChecked(prevStatus === ruleSetsStatus.ACTIVE);
  }, [prevStatus]);

  //  Watching checked state changes
  useEffect(() => {
    if (!didMountRef?.current) { return; }
    const newStatus = ruleSetsStatus[checked ? 'ACTIVE' : 'INACTIVE'];
    const rule_id = rule?.id;
    if (!rule_id || newStatus === prevStatus) { return; }
    changeStatus({ rule_id, status: newStatus });
  }, [checked]);

  //  Watching API response
  useEffect(() => {
    if (!changeStatusResponse) { return; }
    const { message, rule } = changeStatusResponse;
    if (message !== 'Success' || !rule) { onError(); return; }
    const { status: rule_status } = rule;
    setPrevStatus(rule_status);
  }, [changeStatusResponse]);

  //  Watching API error
  useEffect(() => {
    if (!changeStatusError) { return; }
    onError();
  }, [changeStatusError]);

  //  Component did mount
  useEffect(() => {
    didMountRef.current = true;
  }, []);

  //  Render content
  const renderContent = () => {
    //  Rendering draft component
    if (isDraft) {
      return (
        <div className="draft-text">
          Draft
        </div>
      );
    }

    //  Rendering toggle switch
    return (
      <CustomToggleSwitch
        containerClassName="switch-container"
        spanClassName="automation-slider"
        checkedLabelClassName="checked-toggle-cell"
        unCheckedLabelClassName="unchecked-toggle-cell"
        includeLabel={true}
        checked={checked}
        setChecked={setChecked}
        disabled={!canModifyRule}
      />
    );
  };

  //  Rendering
  return (
    <div>
      {renderContent()}
    </div>
  );
};

export default AutomationStatusCheckbox;
