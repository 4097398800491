import { convertToRaw } from 'draft-js';
import _ from 'lodash';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Button, ModalFooter } from 'reactstrap';
import { setAlert } from '../../actions/alerts';
import API from '../../api';
import { alertTypes } from '../../constants/alerts';
import errorMessages from '../../constants/errorMessages';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import BasicModal from '../layout/BasicModal';
import RemediationNoteEditor from './RemediationNoteEditor';
import CustomCheckBox from '../layout/CustomCheckBox';
import { setConfirmationModal } from '../../actions/layout';
import useCustomMutation from '../../hooks/useCustomMutation';
import { onReloadIssues, onReloadIssuesRollup } from '../../actions/issues';
import { issueTypes } from '../../constants/issues';

const IssuesNoteModal = (props) => {
    const {
        toggleModal,
        showModal,
        selectedIssues,
        issueType = issueTypes.VULNERABILITIES,
        isDisabled,
    } = props;

    //  Initializing API
    const { IssueAPI } = API;

    // Component state
    const [editorState, setEditorState] = useState(null);
    const [isRemediationNote, setIsRemediationNote] = useState(false);

    // Getting needed info from redux store
    const user = useSelector((state) => state.auth.user);

    // Component Hooks
    const { dispatch } = useMemoizedDispatch();

    const getIssuesCountLabel = () => {
        const count = selectedIssues.length || 0;
        const itemType = issueType === issueTypes.GRC ? 'task' : 'issue';
        const itemLabel = count !== 1 ? `${itemType}s` : `${itemType}`;
        return `${count} ${itemLabel}`
    }

    const getSelectedIssuesCountLabel = () => {
        const count = selectedIssues.length || 0;
        const itemType = issueType === issueTypes.GRC ? 'task' : 'issue';
        const itemLabel = count !== 1 ? `selected ${itemType}s` : `selected ${itemType}`;
        return `${count} ${itemLabel}`
    }

    // updates several issues
    const {
        data: updateIssuesResponse,
        error: updateIssuesError,
        isLoading: loadingUpdateIssues,
        mutate: updateIssues,
    } = useCustomMutation(
        (params) => IssueAPI.handlers.updateIssuesBulk(params),
        IssueAPI.invalidators.updateIssuesBulk,
    );

    // Function to get confirmation message when an issue is resolved by a remediation note
    const getResolvedConfirmationMessage = () => {
        return (
            <span>
                {`${getIssuesCountLabel()} moved to `}
                <span className="font-weight-bolder">Resolved</span>
            </span>
        )
    }

    // Watch api response
    useEffect(() => {
        if (!updateIssuesResponse) return;

        // When an issue is resolved by a remediation note
        if (isRemediationNote) {
            // Reload issues list
            dispatch(onReloadIssues(true, issueType));

            // Reload rollup widgets
            dispatch(onReloadIssuesRollup(true, issueType));

            // Set Confirmation modal
            dispatch(setConfirmationModal({
                show: true,
                message: getResolvedConfirmationMessage()
            }));
        } else {
            // Reload issues list
            dispatch(onReloadIssues(true, issueType));
            
            // Normal note
            dispatch(setAlert(`Note added to ${getIssuesCountLabel()}`, alertTypes.success));
        }
        toggleModal(false)
    }, [updateIssuesResponse])

    // Watch error
    useEffect(() => {
        if (!updateIssuesError) return;
        const errorMessage = updateIssuesError?.data?.errorMessage || errorMessages.defaultPost;
        dispatch(setAlert(errorMessage, alertTypes.error));
    }, [updateIssuesError])

    // watch show modal to reset state
    useEffect(() => {
        if (!showModal) {
            setEditorState(null);
            setIsRemediationNote(false);
        }
    }, [showModal])

    const getTitle = () => {
        return `Add a Note for the ${getSelectedIssuesCountLabel()}`
    }

    const renderRemediationNoteCheckbox = () => {
        return (
            <div className="mr-2">
                <CustomCheckBox
                    isSelected={isRemediationNote}
                    targetId="remediation-note-checkbox"
                    callback={() => {
                        setIsRemediationNote(!isRemediationNote)
                    }}
                    label="This is a remediation note"
                    isDisabled={loadingUpdateIssues}
                />

            </div>
        )
    }

    const renderTextEditor = () => {
        return (
            <>
                <RemediationNoteEditor
                    editorState={editorState}
                    setEditorState={setEditorState}
                    user={user}
                    placeholder="Add text"
                />
            </>
        )
    }

    const renderMessage = () => {
        const itemType = issueType === issueTypes.GRC ? 'tasks' : 'issues';
        const message = `This note will be applied and visible to the selected ${itemType}.`;
        return (
            <div className="remediation-message">
                {message}
            </div>
        )
    }

    const getNote = () => {
        const raw = convertToRaw(editorState.getCurrentContent());
        return {
            updated_at: new Date(),
            user: {
                id: user.id,
                full_name: user.name,
                profile_image: user.profileImage,
            },
            content: raw,
            is_remediation: isRemediationNote,
        }
    }

    // On post note click
    const onPostNote = () => {
        if (!editorState) {
            dispatch(setAlert('Please enter a note', alertTypes.error))
            return;
        }

        // Get note from editor
        const note = getNote()
        const grc_only = issueType === issueTypes.GRC;
        const params = { issue_ids: selectedIssues, updates: {}, grc_only }

        if (isRemediationNote) {
            params.updates.status = 4;
            params.remediation_note = note;
        } else {
            params.updates.comment = note;
        }

        updateIssues(params)
    }

    const renderFooter = () => {
        return (
            <ModalFooter className="mx-0">
                <div className="d-flex justify-content-end align-items-center" >
                    {renderRemediationNoteCheckbox()}
                    <Button
                        className={`btn btn-light text-dark font-weight-bold`}
                        type="submit"
                        onClick={onPostNote}
                        disabled={loadingUpdateIssues || isDisabled}
                    >
                        {!loadingUpdateIssues ? 'Post note' : 'Processing...'}
                    </Button>
                </div>
            </ModalFooter>
        )
    }

    return (
        <BasicModal
            header={getTitle()}
            headerClassName={`modal-header-status`}
            showModal={showModal}
            toggleModal={toggleModal}
            customClassName="issues-remediation-note-modal"
            scrollable={true}
            customFooter={renderFooter()}
        >
            {renderMessage()}
            {renderTextEditor()}
        </BasicModal>
    )
}

export default IssuesNoteModal
