import React from 'react';
import { UilStepForward,UilPrevious } from '@iconscout/react-unicons';
import { isValidFunction } from '../../utils/helpers';
import { Button } from 'reactstrap';
const IssueNavigationButton = (props) => {
  const {
    moveForward,
    elementsMaxIndex,
    currentElementIndex,
    currentPage,
    maxPage,
    isDisabled,
    callback,
    iconSize = 32,
  } = props;

  const renderIcon = () => {
    return moveForward ? (
      <>
        {`Next `}<UilStepForward size={iconSize}/>
      </>
    ) : (
      <>
        <UilPrevious size={iconSize}/>{` Previous`}
      </>
    )
  }

  const isNavigationDisabled = () => {
    return moveForward ? (
      (currentElementIndex === elementsMaxIndex ) && (currentPage === maxPage)
    ) : (
      currentElementIndex === 0 && currentPage === 0
    )
  }
  const onClick = () => {
    if (isValidFunction(callback)) {
      callback()
    }
  }
  return (
    <Button
      color="link"
      className={`btn-issue-navigation d-flex align-items-center ${moveForward ? 'btn-issue-next' : 'btn-issue-previous'}`}
      disabled={isDisabled || isNavigationDisabled()}
      onClick={onClick}
    >
      {renderIcon()}
    </Button>
  )
}

export default IssueNavigationButton
