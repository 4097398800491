import { createSelector } from 'reselect';

const getCategoriesIds = state => state.assessment.categoriesIds;
const getNavigationIndices = state => state.assessment.navigation;

const makeAssessmentStatusSelector = () => {
    return createSelector( [ getCategoriesIds, getNavigationIndices ],
        (categoriesIds, navigationIndices) => {

            const { categoryIndex, sectionIndex, questionIndex } = navigationIndices;

            const isValidCategory = categoriesIds && categoriesIds[categoryIndex];
            const isValidSection = isValidCategory && categoriesIds[categoryIndex].sectionIds[sectionIndex];
            const isValidQuestion = isValidCategory && isValidSection && categoriesIds[categoryIndex].sectionIds[sectionIndex].questionIds[questionIndex];

            const currentQuestionOrder = ( isValidCategory && isValidSection && isValidQuestion) ? (
                categoriesIds[categoryIndex].sectionIds[sectionIndex].questionIds[questionIndex].order
            ) : 1;

            const isLastCategory = categoriesIds && categoryIndex === categoriesIds.length-1;
            const isLastSection = isValidCategory && (sectionIndex === categoriesIds[categoryIndex].sectionIds.length-1);
            const isLastQuestion = isValidSection && (questionIndex === categoriesIds[categoryIndex].sectionIds[sectionIndex].questionIds.length-1);
            const isAssessmentBeginning = (categoryIndex === 0 && sectionIndex === 0 && questionIndex === 0);
            const isAssessmentEnd = ( isLastCategory && isLastSection && isLastQuestion);
    
            return {
                isAssessmentBeginning,
                isAssessmentEnd,
                currentQuestionOrder
            };
        }
    )
} 
export default makeAssessmentStatusSelector;

  

