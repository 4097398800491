import React from 'react';
import { useSelector } from 'react-redux';
import CyvatarLogo from '../CyvatarLogo';
import { Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import ProfileMenuDropdown from '../../ProfileMenuDropdown';

const PublicHeader = (props) => {
    const {
        className
    } = props;

    //  Getting needed info from redux store
    const isLoading = useSelector(state => state.auth.loading);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

    const renderAuthenticatedHeader = () => {

        return (
            <div className="d-inline-block ml-auto">
                <ProfileMenuDropdown showFullName={true} />
            </div>
        )

    }

    const renderUnauthenticatedHeader = () => {
        if (isLoading) return null;
        return (
            <div className="d-inline-block ml-auto">
                <Link
                    className="link-white mr-3"
                    to="/login"
                >
                    Login
                </Link>
                <Link
                    className="btn btn-light px-3 py-2"
                    to="/freemium"
                >
                    Get Started for Free
                </Link>
            </div>
        )
    }

    return (
        <Row
            className={`public-header fixed-topbar isShown d-flex justify-content-center no-gutters ${className || ''}`}
        >
            <header id="cyvatar-topbar">
                <div className="navbar-header">
                    <CyvatarLogo redirectToHome={false} />
                    {isAuthenticated ? renderAuthenticatedHeader() : renderUnauthenticatedHeader()}
                </div>
            </header>
        </Row>
    )
}

export default PublicHeader;
