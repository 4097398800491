/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE";
export const SHOW_COMMENT_BUTTON = "SHOW_COMMENT_BUTTON";
export const SHOW_NEXT_BUTTON = "SHOW_NEXT_BUTTON";
export const ON_SHOW_ISSUE_EXPANDED_VIEW = 'ON_SHOW_ISSUE_EXPANDED_VIEW';
export const SET_CONFIRMATION_MODAL = "SET_CONFIRMATION_MODAL";
export const SET_IS_OPEN_MODAL = "SET_IS_OPEN_MODAL";


// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME";
export const SHOW_TAKE_ASSESSMENT = 'SHOW_TAKE_ASSESSMENT';

// show sidebar
export const SHOW_SIDEBAR = "SHOW_SIDEBAR";
export const TOGGLE_LEFTMENU = "TOGGLE_LEFTMENU";

/* RIGHT SIDEBAR */
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR";

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER";

//Modals
export const TOGGLE_REGISTER_MODAL = "TOGGLE_REGISTER_MODAL";
export const CHANGE_REGISTER_MODAL = "CHANGE_REGISTER_MODAL";
export const TOGGLE_ASSSESSMENT_COMMMENTS_MODAL = "TOGGLE_ASSSESSMENT_COMMMENTS_MODAL";
export const TOGGLE_UPDATE_COMPANY_MODAL = "TOGGLE_UPDATE_COMPANY_MODAL";

/* ALERTS */
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REMOVE_ALL_ALERTS = 'REMOVE_ALL_ALERTS'

/* AUTH */
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
//
export const REGISTER_FREEMIUM_SUCCESS = 'REGISTER_FREEMIUM_SUCCESS';
export const REGISTER_FREEMIUM_FAILURE = 'REGISTER_FREEMIUM_FAILURE';

export const CANCEL_MEMBERSHIP_SUCCESS = 'CANCEL_MEMBERSHIP_SUCCESS';
export const CANCEL_ACCOUNT_SUCCESS = 'CANCEL_ACCOUNT_SUCCESS';

//
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const MFA_CODE_SUCCESS = 'MFA_CODE_SUCCESS';
export const MFA_DISABLE_SUCCESS = 'MFA_DISABLE_SUCCESS';
export const MFA_VERIFICATION_SUCCESS = 'MFA_VERIFICATION_SUCCESS';
export const USER_AUTH_SUCCESS = 'USER_AUTH_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SEND_RESET_PASSWORD_TOKEN_SUCCESS = 'SEND_RESET_PASSWORD_TOKEN_SUCCESS';
export const CLEAR_RESET_PASSWORD = 'CLEAR_RESET_PASSWORD';
export const VALIDATE_RESET_PASSWORD_TOKEN_REQUEST = 'VALIDATE_RESET_PASSWORD_TOKEN_REQUEST';
export const VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS = 'VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS';
export const VALIDATE_RESET_PASSWORD_TOKEN_FAILURE = 'VALIDATE_RESET_PASSWORD_TOKEN_FAILURE';
export const CONFIRM_PASSWORD_SUCCESS = 'CONFIRM_PASSWORD_SUCCESS';
export const CLEAN_AUTH_ERROR_MESSAGE = "CLEAN_AUTH_ERROR_MESSAGE";
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAILURE = 'CREATE_COMPANY_FAILURE';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_NOTIFICATION_SETTINGS_SUCCESS = 'UPDATE_NOTIFICATION_SETTINGS_SUCCESS';
export const UPDATE_ROI_COMPANY_SUCCESS = 'UPDATE_ROI_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAILURE = 'UPDATE_COMPANY_FAILURE';
export const COMPANY_REQUEST = 'COMPANY_REQUEST';
export const COMPANY_SUCCESS = 'COMPANY_SUCCESS';
export const COMPANY_FAILURE = 'COMPANY_FAILURE';
export const COMPANY_DEMO_UPDATE = 'COMPANY_DEMO_UPDATE';
export const COMPANY_SCAN_UPDATE = 'COMPANY_SCAN_UPDATE';
export const SHOW_FREEMIUM_ALERT = 'SHOW_FREEMIUM_ALERT';

/* ASSESSMENT */
export const ASSESSMENT_SUCCESS = 'ASSESSMENT_SUCCESS';
export const ASSESSMENT_FAILURE = 'ASSESSMENT_FAILURE';
export const COMPLIANCE_ASSESSMENT_SUCCESS = 'COMPLIANCE_ASSESSMENT_SUCCESS';
export const COMPLIANCE_ASSESSMENT_FAILURE = 'COMPLIANCE_ASSESSMENT_FAILURE';
export const EDIT_COMPLIANCE_LIST_SUCCESS = 'EDIT_COMPLIANCE_LIST_SUCCESS';
export const SET_COMPLIANCES = 'SET_COMPLIANCES';
export const SELECT_SECTION = 'SELECT_SECTION';
export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const SELECT_QUESTION = 'SELECT_QUESTION';
export const SELECT_SECTION_SUCCESS = 'SELECT_SECTION_SUCCESS';
export const SELECT_CATEGORY_SUCCESS = 'SELECT_CATEGORY_SUCCESS';
export const SET_CATEGORIES_IDS = 'SET_CATEGORIES_IDS';
export const ASSESSMENT_REQUEST = 'ASSESSMENT_REQUEST';

/* ASSESSMENT NAVIGATION */
export const SET_SECTION_INDEX = 'SET_SECTION_INDEX';
export const SET_CATEGORY_INDEX = 'SET_CATEGORY_INDEX';
export const SET_QUESTION_INDEX = 'SET_QUESTION_INDEX';
export const SET_NEXT_QUESTION = 'SET_NEXT_QUESTION';
export const SET_PREVIOUS_QUESTION = 'SET_PREVIOUS_QUESTION';
export const SET_FIRST_QUESTION = 'SET_FIRST_QUESTION';
export const RESET_ASSESSMENT_NAVIGATION = 'RESET_ASSESSMENT_NAVIGATION';

/* ASSESSMENT ANSWERS */
export const SELECT_ANSWER = 'SELECT_ANSWER';
export const DESELECT_ANSWER = 'DESELECT_ANSWER';
export const SET_COMMENT = 'SET_COMMENT';
export const SET_ASSESSMENT_COMMENT = 'SET_ASSESSMENT_COMMENT';
export const ANSWERS_SUCCESS = 'ANSWERS_SUCCESS';
export const SHARED_ANSWERS_REQUEST = 'SHARED_ANSWERS_REQUEST';
export const SHARED_ANSWERS_SUCCESS = 'SHARED_ANSWERS_SUCCESS';
export const SECTION_ANSWERS_REQUEST = 'SECTION_ANSWERS_REQUEST';
export const SECTION_ANSWERS_SUCCESS = 'SECTION_ANSWERS_SUCCESS';
export const SAVE_ANSWERS_SUCCESS = 'SAVE_ANSWERS_SUCCESS';
export const ANSWERS_FAILURE = 'ANSWERS_FAILURE';
export const ON_CLEAN_ANSWERS = 'ON_CLEAN_ANSWERS';
export const ANSWERS_REQUEST = 'ANSWERS_REQUEST';
//
export const CREATE_EXECUTION_SUCCESS = 'CREATE_EXECUTION_SUCCESS';
//
export const CLEAR_ANSWERS = 'CLEAR_ANSWERS';
export const RESET_ASSESSMENT_ANSWERS = 'RESET_ASSESSMENT_ANSWERS';
export const RESET_ASSESSMENT_ANSWERS_SUCCESS = 'RESET_ASSESSMENT_ANSWERS_SUCCESS';
export const ASSESSMENT_EXECUTION_SUCCESS = 'ASSESSMENT_EXECUTION_SUCCESS';
export const ASSESSMENT_EXECUTION_FAILURE = 'ASSESSMENT_EXECUTION_FAILURE';
export const SAVE_COMPLIANCE_ASSESSMENT_FAILURE = 'SAVE_COMPLIANCE_ASSESSMENT_FAILURE';
export const SAVE_COMPLIANCE_ASSESSMENT_SUCCESS = 'SAVE_COMPLIANCE_ASSESSMENT_SUCCESS';

/* DASHBOARD */
export const SET_SHOW_REPORT_CARD = "SET_SHOW_REPORT_CARD";
/* DASHBOARD - ASSETS */
export const ASSETS_DASHBOARD_SUCCESS = "ASSETS_DASHBOARD_SUCCESS";
export const ASSETS_DASHBOARD_FAILURE = "ASSETS_DASHBOARD_FAILURE";

/* DASHBOARD - FURTIM */
export const FURTIM_OVERALL_SCORE_REQUEST = "FURTIM_OVERALL_SCORE_REQUEST";
export const FURTIM_OVERALL_SCORE_SUCCESS = "FURTIM_OVERALL_SCORE_SUCCESS";
export const FURTIM_OVERALL_SCORE_FAILURE = "FURTIM_OVERALL_SCORE_FAILURE";
export const COMPLIANCE_SCORE_SUCCESS = "COMPLIANCE_SCORE_SUCCESS";
export const COMPLIANCE_SCORE_FAILURE = "COMPLIANCE_SCORE_FAILURE";
export const CLEAR_SCORES = "CLEAR_SCORES";
export const COMPLIANCE_SCORE_REQUEST = 'COMPLIANCE_SCORE_REQUEST';

/* CALCULATOR */
export const CALCULATE_ROI_SUCCESS = "CALCULATE_ROI_SUCCESS";
export const CALCULATE_ROI_FAILURE = "CALCULATE_ROI_FAILURE";
export const CALCULATE_ROI_RESET = "CALCULATE_ROI_RESET";

/* CUSTOMER-MANAGEMENT */
export const CUSTOMER_LIST_SUCCESS = "CUSTOMER_LIST_SUCCESS";
export const CUSTOMER_LIST_FAILURE = "CUSTOMER_LIST_FAILURE";
export const RELOAD_CUSTOMERS = "RELOAD_CUSTOMERS";
export const CUSTOMER_PROFILE_SUCCESS = "CUSTOMER_PROFILE_SUCCESS";
export const CUSTOMER_PROFILE_FAILURE = "CUSTOMER_PROFILE_FAILURE";
export const CUSTOMER_CLEAR = "CUSTOMER_CLEAR";
export const SET_CUSTOMERS_FILTER = "SET_CUSTOMERS_FILTER";
export const SET_CUSTOMERS_SORT = "SET_CUSTOMERS_SORT";
export const CLEAR_ALL_CUSTOMERS_FILTERS = "CLEAR_ALL_CUSTOMERS_FILTERS";
export const CUSTOMER_LIST_REQUEST = 'CUSTOMER_LIST_REQUEST';
export const ON_SCAN_LOADING_CHANGE = 'ON_SCAN_LOADING_CHANGE';

/* PARTNERS */
export const PARTNERS_LIST_SUCCESS = "PARTNERS_LIST_SUCCESS";
export const PARTNERS_LIST_FAILURE = "PARTNERS_LIST_FAILURE";
export const PARTNERS_SET_SUCCESS = "PARTNERS_SET_SUCCESS";
export const PARTNERS_SET_FAILURE = "PARTNERS_SET_FAILURE";
export const PARTNERS_UPDATE_EXCLUSIONS = "PARTNERS_UPDATE_EXCLUSIONS";
export const FURTIM_ENABLE_SUCCESS = "FURTIM_ENABLE_SUCCESS";
export const FURTIM_ENABLE_FAILURE = "FURTIM_ENABLE_FAILURE";
export const PARTNERS_CLEAR = "PARTNERS_CLEAR";
export const PARTNERS_RELOAD = "PARTNERS_RELOAD";
export const SET_PARTNERS_FILTER = "SET_PARTNERS_FILTER";
export const SET_PARTNERS_SORT = "SET_PARTNERS_SORT";
export const CLEAR_ALL_PARTNERS_FILTERS = "CLEAR_ALL_PARTNERS_FILTERS";
export const PARTNERS_LIST_REQUEST = 'PARTNERS_LIST_REQUEST';
export const SET_PARTNER_ID_AND_NAME = "SET_PARTNER_ID_AND_NAME";
export const SET_PARTNER_ACTION_VALUE = "SET_PARTNER_ACTION_VALUE";
export const CLEAR_PARTNER_ACTION_VALUES = "CLEAR_PARTNER_ACTION_VALUES";
export const SET_PARTNER_ACTION_CLEANUP_MAP = "SET_PARTNER_ACTION_CLEANUP_MAP";
export const SET_MULTIPLE_PARTNER_ACTION_VALUES = "SET_MULTIPLE_PARTNER_ACTION_VALUES";
export const PARTNERS_MANUAL_ACTION_LIST = "PARTNERS_MANUAL_ACTION_LIST";

/* ISSUES */
export const SET_ISSUES_FILTER = "SET_ISSUES_FILTER";
export const SET_ISSUES_FILTER_GROUP = "SET_ISSUES_FILTER_GROUP";
export const SET_ISSUES_SORT = "SET_ISSUES_SORT";
export const CLEAR_ALL_ISSUES_FILTERS = "CLEAR_ALL_ISSUES_FILTERS";
export const ISSUES_LIST_SUCCESS = 'ISSUES_LIST_SUCCESS';
export const ISSUES_LIST_FAILURE = 'ISSUES_LIST_FAILURE';
export const ISSUES_DEMO_SUCCESS = 'ISSUES_DEMO_SUCCESS';
export const ISSUES_DEMO_FAILURE = 'ISSUES_DEMO_FAILURE';
export const APPLY_ADVANCED_FILTERS = 'APPLY_ADVANCED_FILTERS';
export const ON_RELOAD_ISSUES_ROLLUP = 'ON_RELOAD_ISSUES_ROLLUP';
//
export const ISSUES_FREE_SCAN_SUCCESS = 'ISSUES_FREE_SCAN_SUCCESS';
export const ISSUES_FREE_SCAN_FAILURE = 'ISSUES_FREE_SCAN_FAILURE';
//
export const ISSUE_REQUEST = 'ISSUE_REQUEST';
export const ISSUE_SUCCESS = 'ISSUE_SUCCESS';
export const ISSUE_FAILURE = 'ISSUE_FAILURE';
export const CREATE_ISSUE_SUCCESS = 'CREATE_ISSUE_SUCCESS';
export const CREATE_ISSUE_FAILURE = 'CREATE_ISSUE_FAILURE';
export const UPDATE_ISSUE_SUCCESS = 'UPDATE_ISSUE_SUCCESS';
export const UPDATE_ISSUE_FAILURE = 'UPDATE_ISSUE_FAILURE';
export const UPDATE_REMEDIATION_PLAN_NOTE_SUCCESS = 'UPDATE_REMEDIATION_PLAN_NOTE_SUCCESS';
export const UPDATE_REMEDIATION_PLAN_NOTE_FAILURE = 'UPDATE_REMEDIATION_PLAN_NOTE_FAILURE';
export const CLEAR_ISSUES = 'CLEAR_ISSUES';
export const RESET_MODAL_OPTIONS_USERS = 'RESET_MODAL_OPTIONS_USERS';
export const ISSUE_MODAL_OPTIONS_SUCCESS = 'ISSUE_MODAL_OPTIONS_SUCCESS';
export const ISSUE_STATUSES_SUCCESS = 'ISSUE_STATUSES_SUCCESS';
export const ISSUE_MODAL_OPTIONS_FAILURE = 'ISSUE_MODAL_OPTIONS_FAILURE';
export const ISSUE_TOTALS_SUCCESS = 'ISSUE_TOTALS_SUCCESS';
export const ISSUE_TOTALS_FAILURE = 'ISSUE_TOTALS_FAILURE';
export const RELOAD_ISSUES = 'RELOAD_ISSUES';
export const RELOAD_ISSUES_TAGS_LIST = 'RELOAD_ISSUES_TAGS_LIST';
export const CLEAR_SELECTED_ISSUE = "CLEAR_SELECTED_ISSUE";
export const ON_DELETE_MODAL_CONFIG_UPDATE = 'ON_DELETE_MODAL_CONFIG_UPDATE';
export const ON_DELETE_ISSUES_SUCCESS = 'ON_DELETE_ISSUES_SUCCESS';
export const ON_PARTNER_MANUAL_ACTION_FINISH = "ON_PARTNER_MANUAL_ACTION_FINISH";
export const ISSUES_UPDATE_CURRENT_PAGE = "ISSUES_UPDATE_CURRENT_PAGE";


//Issue Tags
export const ISSUE_ADD_TAG_SUCCESS = 'ISSUE_ADD_TAG_SUCCESS';
export const ISSUE_ADD_TAG_FAILURE = 'ISSUE_ADD_TAG_FAILURE';
export const ISSUE_REMOVE_TAG_SUCCESS = 'ISSUE_REMOVE_TAG_SUCCESS';
export const ISSUE_REMOVE_TAG_FAILURE = 'ISSUE_REMOVE_TAG_FAILURE';

/* DEVICES */
export const DEVICES_LIST_SUCCESS = 'DEVICES_LIST_SUCCESS';
export const DEVICES_LIST_FAILURE = 'DEVICES_LIST_FAILURE';
export const DEVICES_COUNT_SUCCESS = 'DEVICES_COUNT_SUCCESS';
export const DEVICES_COUNT_FAILURE = 'DEVICES_COUNT_FAILURE';
export const DEVICE_UPDATE_SUCCESS = 'DEVICE_UPDATE_SUCCESS';
export const CLEAR_DEVICES = 'CLEAR_DEVICES';
export const UPDATE_DEVICES_COUNT_SUCCESS = 'UPDATE_DEVICES_COUNT_SUCCESS';
export const UPDATE_DEVICES_COUNT_FAILURE = 'UPDATE_DEVICES_COUNT_FAILURE';
export const RELOAD_DEVICES_LIST = 'RELOAD_DEVICES_LIST';
export const ON_DELETE_DEVICE_CHANGE = 'ON_DELETE_DEVICE_CHANGE';
export const SET_DEVICES_FILTER = 'SET_DEVICES_FILTER';
export const SET_DEVICES_FILTER_GROUP = 'SET_DEVICES_FILTER_GROUP';
export const CLEAR_ALL_DEVICES_FILTERS = "CLEAR_ALL_DEVICES_FILTERS";
export const ON_MERGE_DEVICES_CHANGE = 'ON_MERGE_DEVICES_CHANGE';

/* CYDEKICKS */
export const CYDEKICKS_REQUEST = 'CYDEKICKS_REQUEST';
export const CYDEKICKS_SUCCESS = 'CYDEKICKS_SUCCESS';
export const RELOAD_CYDEKICKS = 'RELOAD_CYDEKICKS';
export const CYDEKICKS_FAILURE = 'CYDEKICKS_FAILURE';
export const CYDEKICK_INVITE_SUCCESS = 'CYDEKICK_INVITE_SUCCESS';
export const CYDEKICK_INVITE_FAILURE = 'CYDEKICK_INVITE_FAILURE';
export const CYDEKICKS_CLEAR = 'CYDEKICKS_CLEAR';
export const SET_CYDEKICKS_FILTER = "SET_CYDEKICKS_FILTER";
export const SET_CYDEKICKS_SORT = "SET_CYDEKICKS_SORT";
export const CLEAR_ALL_CYDEKICKS_FILTERS = "CLEAR_ALL_CYDEKICKS_FILTERS";

/* COMMENTS */
export const ADD_COMMENT_REQUEST = 'ADD_COMMENT_REQUEST';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAILURE = 'ADD_COMMENT_FAILURE';
export const REMOVE_NEW_COMMENT_FLAG = 'REMOVE_NEW_COMMENT_FLAG';

//  IMPERSONATION
export const ON_IMPERSONATION_SUCCESS = 'ON_IMPERSONATION_SUCCESS';
export const ON_IMPERSONATION_LOADING = 'ON_IMPERSONATION_LOADING';
export const SET_MEMBER_VIEW = 'SET_MEMBER_VIEW';
export const SHOULD_RELOAD_IMPERSONATION = 'SHOULD_RELOAD_IMPERSONATION'

//  NAVIGATION
export const ON_SIDEBAR_TAB_SELECTED = 'ON_SIDEBAR_TAB_SELECTED';
export const ON_SUBTAB_SELECTED = 'ON_SUBTAB_SELECTED';
export const ON_TOPBAR_ACTION_TRIGGERED = 'ON_TOPBAR_ACTION_TRIGGERED';
export const CHANGE_SUBTABS = "CHANGE_SUBTABS"
export const SET_BACK_LINK_LABEL = "SET_BACK_LINK_LABEL";

/* CYSO BUSINESS RISK */
export const SET_HOVERED_RISK_BUBBLE = 'SET_HOVERED_RISK_BUBBLE';
export const SET_SELECTED_RISK_STATEMENT = 'SET_SELECTED_RISK_STATEMENT';
export const SET_RISK_TREATMENT_FILTER = 'SET_RISK_TREATMENT_FILTER';
export const RISK_STATEMENTS_SUCCESS = 'RISK_STATEMENTS_SUCCESS';
export const RISK_STATEMENTS_FAILURE = 'RISK_STATEMENTS_FAILURE';
export const RISK_STATEMENTS_UPDATE_SUCCESS = 'RISK_STATEMENTS_UPDATE_SUCCESS';
export const RISK_STATEMENTS_UPDATE_FAILURE = 'RISK_STATEMENTS_UPDATE_FAILURE';
export const BUSINESS_RISK_CONFIG_SUCCESS = 'BUSINESS_RISK_CONFIG_SUCCESS';
export const BUSINESS_RISK_CONFIG_FAILURE = 'BUSINESS_RISK_CONFIG_FAILURE';
export const SET_SHOULD_RELOAD_BUSINESS_RISK = 'SET_SHOULD_RELOAD_BUSINESS_RISK';

// OPPORTUNITIES TO IMPROVE PAGE
export const OPPORTUNITIES_TO_IMPROVE_SUCCESS = 'OPPORTUNITIES_TO_IMPROVE_SUCCESS';
export const OPPORTUNITIES_TO_IMPROVE_FAILURE = 'OPPORTUNITIES_TO_IMPROVE_FAILURE';

//  Journey path changes
export const ON_JOURNEY_PATH_CHANGE_SUCCESS = 'ON_JOURNEY_PATH_CHANGE_SUCCESS';

// DATA TO GET INFO OF RISK STATEMENT STATUS
export const RISK_STATEMENT_STATUS_SUCCESS = "RISK_STATEMENT_STATUS_SUCCESS";
export const RISK_STATEMENT_STATUS_FAILURE = "RISK_STATEMENT_STATUS_FAILURE";

//  Automation
export const ON_REQUEST_RULES_DELETE = 'ON_REQUEST_RULES_DELETE';
export const ON_REQUEST_CANCEL_RULES_DELETE = 'ON_REQUEST_CANCEL_RULES_DELETE';
export const CHANGE_AUTOMATION_PAGE = 'CHANGE_AUTOMATION_PAGE';
export const ON_RULE_LOG_REQUESTED = 'ON_RULE_LOG_REQUESTED';
export const ON_LOG_DETAIL_REQUESTED = 'ON_LOG_DETAIL_REQUESTED';
export const ON_RULE_SETS_MOUNTED = 'ON_RULE_SETS_MOUNTED';
export const ON_LOG_DETAIL_UNMOUNTED = 'ON_LOG_DETAIL_UNMOUNTED';
export const ON_UPSERT_RULE_SET = 'ON_UPSERT_RULE_SET';
export const ON_AUTOMATION_UNMOUNT = 'ON_AUTOMATION_UNMOUNT';

//  Rule set
export const ON_RULE_SET_LOADED = 'ON_RULE_SET_LOADED';
export const ON_TAGS_ARRAY_CHANGES = 'ON_TAGS_ARRAYS_CHANGES';
export const ON_COMPANY_ARRAY_CHANGES = 'ON_COMPANY_ARRAY_CHANGES'
export const ON_RULE_PARTNER_CHANGE = 'ON_RULE_PARTNER_CHANGE';
export const ON_RULE_ACTION_CHANGE = 'ON_RULE_ACTION_CHANGE';
export const ON_RULE_SCHEDULE_CHANGE = 'ON_RULE_SCHEDULE_CHANGE';
export const ON_ADD_ACTION = 'ON_ADD_ACTION';
export const ON_ADD_PARTNER = 'ON_ADD_PARTNER';
export const ON_REMOVE_ACTION = 'ON_REMOVE_ACTION';
export const ON_SAVE_RULE_SET_SUCCESS = 'ON_SAVE_RULE_SET_SUCCESS';
export const ON_RULE_SET_DETAIL_UNMOUNT = 'ON_RULE_SET_DETAIL_UNMOUNT';
export const SET_RULE_TITLES = "SET_RULE_TITLES";
export const SET_LOG_DETAIL = "SET_LOG_DETAIL";

//  GRC Actions
export const ON_SHOW_GRC_DETAILS = 'ON_SHOW_GRC_DETAILS';
export const UPDATE_INDEX_DETAILS_MODAL = 'UPDATE_INDEX_DETAILS_MODAL';
export const TASKS_UPDATE_CURRENT_PAGE = "TASKS_UPDATE_CURRENT_PAGE";

// Connection Status
export const SET_CONNECTIONS_FILTER = 'SET_CONNECTIONS_FILTER';
export const SET_CONNECTIONS_SORT = 'SET_CONNECTIONS_SORT';
export const CLEAR_ALL_CONNECTIONS_FILTERS = 'CLEAR_ALL_CONNECTIONS_FILTERS'
export const SET_CONNECTION_DEFAULT_FILTERS = 'SET_CONNECTION_DEFAULT_FILTERS';

// App v4 - Dashboards
export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const SET_TIMEZONE = 'SET_TIMEZONE';
export const SET_SOLUTION_ID = 'SET_SOLUTION_ID';
export const SET_DATE_INFO = 'SET_DATE_INFO';
export const SET_RANGE_VALUE = 'SET_RANGE_VALUE';
export const SET_OPEN_OVERVIEW = 'SET_OPEN_OVERVIEW';

//  Recycle Bin
export const SET_IDS_TO_DELETE = 'SET_IDS_TO_DELETE';
export const SET_IDS_TO_RESTORE = 'SET_IDS_TO_RESTORE';

//  Groups Manager
export const ON_GROUPS_FILTERS_CHANGE = 'ON_GROUPS_FILTERS_CHANGE';
export const SET_GROUP_PAGINATION = 'SET_GROUP_PAGINATION';

// Domains
export const UPDATE_DOMAIN_COUNT = 'UPDATE_DOMAIN_COUNT';