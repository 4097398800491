import React from 'react';
import { Button } from 'reactstrap';

const CollapsibleIconButton = (props) => {
    const {
        label,
        icon,
        className,
        callback,
        rightIcon = false
    } = props;
    return (
        <Button
            color="link"
            className={`collapsible-icon-button ${className}`}
            onClick={callback}
            onKeyUp={callback}
            aria-label={label}
        >
            {!rightIcon && icon} <div className={`collapsible-label ${rightIcon && 'mx-2'}`}>{label}</div> {rightIcon && icon}
        </Button>
    )
}

export default CollapsibleIconButton