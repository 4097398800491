import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { ERROR_SOLID_ICON } from '../../../../../constants/errorStyles';
import { issueTypes } from '../../../../../constants/issues';
import { arrangeCriticalityData, emptyIcarmIssuesBoxesTemplate, extractCriticalityValuebySolution, getIcarmSolutionRollupData, getNewIcarmStep, getTotalIssuesResolved } from '../../../../../utils/dashboardHelpers';
import BasicDoughnutChart from '../../../../dashboard/charts/BasicDoughnutChart';
import LoadingContent from '../../../../layout/LoadingContent';

const IcarmProgression = (props) => {

  const {
    isLoading,
    errorMessage,
    solutionsData,
    retryRequests,
    lg = 6,
    lastNumberDays,
    emptyState
  } = props;

  const [solutions, setSolutions] = useState([]);

  // Component Variables
  const cardLoadingStyle = { minHeight: "266px", paddingTop: "41px" };

  useEffect(() => {
    if (!solutionsData) return;
    setSolutions(solutionsData);
  },[solutionsData]);

  const totalIssuesBoxes = [{
    color: '#f46a6a',
    value: extractCriticalityValuebySolution(solutions,'Critical') || 0,
    label: 'Critical',
    className: 'critical'
  },{
    color: '#f1a04c',
    value: extractCriticalityValuebySolution(solutions,'High') || 0,
    label: 'High',
    className: 'high'
  },{
    color: '#f5d302',
    value: extractCriticalityValuebySolution(solutions,'Medium') || 0,
    label: 'Medium',
    className: 'medium'
  },{
    color: '#34c38f',
    value: extractCriticalityValuebySolution(solutions,'Low') || 0,
    label: 'Low',
    className: 'low'
  }];

  const getRollupsIssueBoxesData = (data) => {
    return [{
      color: '#f46a6a',
      value: data?.issues_resolved?.Critical || 0,
      label: 'Critical',
      className: 'critical'
    },{
      color: '#f1a04c',
      value: data?.issues_resolved?.High || 0,
      label: 'High',
      className: 'high'
    },{
      color: '#f5d302',
      value: data?.issues_resolved?.Medium || 0,
      label: 'Medium',
      className: 'medium'
    },{
      color: '#34c38f',
      value: data?.issues_resolved?.Low || 0,
      label: 'Low',
      className: 'low'
    }];
  };

  const emptyDataRollupData = () => {
    const componentId = "icarm-progression-empty-solution-rollup";
    const solutionRollupData = getIcarmSolutionRollupData(800, 200, 500, 500);
    const arrangedData = arrangeCriticalityData(solutionRollupData, '',issueTypes.VULNERABILITIES, 'Resolved issues', componentId);
    arrangedData.colorsConfig = {
      Critical: "#382A71",
      High: "#382A71",
      Medium: "#382A71",
      Low: "#382A71",
    };
    const title = `Solution`;
    return {
        ...arrangedData,
        enableTooltip : false,
        title
    }
  };

  const arrangeSolutionData = (data) => {
    const componentId = "icarm-progression-solution-rollup";
    const solutionRollupData = getIcarmSolutionRollupData(data?.issues_resolved?.Critical, data?.issues_resolved?.High, data?.issues_resolved?.Medium, data?.issues_resolved?.Low);
    const arrangedData = arrangeCriticalityData(solutionRollupData, '',issueTypes.VULNERABILITIES, 'Resolved issues', componentId);
    const title = data?.name || `TVM`;
    return {
        ...arrangedData,
        title
    }
  }

  const renderRollupHeader = (solutionColor, name, steps = []) => {
    let step = steps?.find((step) => step?.completion_date === null);
    step = getNewIcarmStep(step);
    return (
      <div className='solution-rollup-header'>
        <p className='solution-name' style={{color: solutionColor}}>{name.replace("aaS", "")}</p>
        {step?.name ? <p className='solution-state' style={{color: solutionColor, borderColor: solutionColor}}>
          {step?.name}
        </p> : null}
      </div>
    );
  };

  const renderSolutionRollups = () => {
    const sortedSolutions = solutions.sort((a, b) =>
    a?.name.localeCompare(b?.name));
    return sortedSolutions?.map((solution) => {
      const config = arrangeSolutionData(solution);
      config.steps = solution?.steps;
      return renderRollup(config, getRollupsIssueBoxesData(solution), solution?.theme?.color, solution?.name);
    })
  };

  const renderEmptySolutionRollups = () => {
    const solutionsAmount = solutions?.length;
    const numberOfEmptyRollups = 4 - solutionsAmount;
    const emptyRollups = []
    for (let index = 0; index < numberOfEmptyRollups; index++) {
      emptyRollups.push(renderRollup(emptyDataRollupData(), emptyIcarmIssuesBoxesTemplate));
    }
    return emptyRollups;
  };

  const issuesAmount = (amount, subtitle) => {
    return (
      <div className='issues-amount'>
        <p className='subtitle'>{subtitle}</p>
        <p className='amount'>{amount}</p>
      </div>
    );
  };

  const issuesByCriticalityBoxes = (values) => {
    return values.map((value) => {
      return issueByCriticalityBox(value.className, value.label, value.value);
    });
  };

  const issueByCriticalityBox = (className, label, amount) => {
    return (
      <div className={`issue-type-amount-box ${className}`}>
        {`${amount} ` + `${label}`}
      </div>
    );
  };

  const renderRollup = (config, issuesBoxes, color = '#fff', name = 'Solution') => {
    const {
      data = [],
      colorsConfig = {},
      total = 200,
      unitLabel = 'Resolved Issues',
      componentId,
      steps,
      titleTooltip,
      title,
      subtitle
    } = config;

    return (
      <Col lg={3} md={6} sm={6}>
        <div className={`solution-resolved-issues-rollup-container ${componentId}`}>
          {renderRollupHeader(color, name, steps)}
          <BasicDoughnutChart
            data={data}
            showLegend={false}
            colorsConfig={colorsConfig}
            totalLabelUnit={unitLabel}
            total={total}
            zeroLabel={'Nothing to see'}
            legendWidth = {0}
            customEmptyLabelClassName={'icarm-progression-rollup-empty-state'}
            enableTooltip={config?.enableTooltip}
          />
          <div className='solution-rollup-footer'>
            <div className='issue-by-criticality-breakdown grid-alignment'>
              {issuesByCriticalityBoxes(issuesBoxes)}
            </div>
          </div>
        </div>
      </Col>
    );
  };

  const renderTotalIssues = () => {
    return (
      <>
        <h1 className="total-issues-breakdown-title">Total issues resolved</h1>
        {lastNumberDays > 0 ? (
          <p className="total-issues-breakdown-time-filter">{`Last ${lastNumberDays} days`}</p>
        ) : <p className="total-issues-breakdown-time-filter">{`Custom range`}</p>}

        <div className="issue-by-criticality-breakdown column-alignment">
          {issuesByCriticalityBoxes(totalIssuesBoxes)}
        </div>

        <div className="issues-resolved-card-container">
          <div className="issues-resolved-card">
            {issuesAmount(getTotalIssuesResolved(solutions), "Issues resolved")}
            {lastNumberDays > 0 ? <p className='time-range'>{`Last ${lastNumberDays} days`}</p> : <p className='time-range'>{`Custom range`}</p>}
          </div>
        </div>
      </>
    );
  };

  const renderIcarmProgression = () => {
    return (
      <>
        <div className='solution-rollups'>
          <Col lg={2} md={3} sm={3} className='total-issues-breakdown'>
            <Col lg={12} sm={12}>
              {renderTotalIssues()}
            </Col>
          </Col>
          <Col lg={10} md={9} sm={9}>
            <Row>
              {renderSolutionRollups()}
              {renderEmptySolutionRollups()}
            </Row>
          </Col>
        </div>
      </>
    );
  };

  return (
    <Col lg={lg} className="px-2">
      <LoadingContent
        isLoading={isLoading}
        errorMessage={errorMessage}
        iconType={ERROR_SOLID_ICON}
        errorStyle={cardLoadingStyle}
        loadingStyle={cardLoadingStyle}
        errorButtonCallback={retryRequests}
      >
        <div className="icarm-progression">{renderIcarmProgression()}</div>
      </LoadingContent>
    </Col>
  );
}

export default IcarmProgression;