import React, { useEffect, useState } from 'react';
import SimpleDropdown from '../layout/SimpleDropdown';
import { mapPlansToOptions } from '../../utils/helpers';
import { setAlert } from '../../actions/alerts';
import API from '../../api';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useCustomMutation from '../../hooks/useCustomMutation';
import useCanAbility from '../../hooks/useCanAbility';
import { MODIFY_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';

//  Component
const CompanyPlanSelector = (props) => {
  //  Parsing props
  const {
    company_id,
    price_id,
    plans = [],
  } = props;

  //  Initializing APIs
  const { PlanAPI } = API;

  //  Component state
  const [selectedOption, setSelectedOption] = useState(null);
  const [prevSelectedOption, setPrevSelectedOption] = useState(selectedOption);
  const [planOptions, setPlanOptions] = useState([]);

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const {
    data: changePlanResponse,
    error: changePlanError,
    isLoading: changePlanLoading,
    mutate: changePlan,
  } = useCustomMutation(
    (params) => PlanAPI.handlers.changePlanForCompany(params),
    PlanAPI.invalidators.changePlanForCompany
  );
  const ability = useCanAbility();

  // Component variables
  const canModifyPlan = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.SUBSCRIPTION.GENERAL);

  //  Watching API response
  useEffect(() => {
    if (!changePlanResponse) { return; }
    const { success } = changePlanResponse;
    if (!success) {
      dispatch(setAlert('Error changing plan. ', 'danger'));
      setSelectedOption(prevSelectedOption);
      return;
    }
  }, [changePlanResponse]);

  //  Watching API error
  useEffect(() => {
    if (!changePlanError) { return; }
    dispatch(setAlert('Error changing plan. ', 'danger'));
    setSelectedOption(prevSelectedOption);
  }, [changePlanError]);

  //  Watching plans changes
  useEffect(() => {
    setPlanOptions(mapPlansToOptions(plans));
  }, [plans]);

  //  Watching plans and prices id changes
  useEffect(() => {
    if (!price_id || planOptions?.length === 0) { setSelectedOption(null); return; }
    const matchingPlan = planOptions?.find((plan) => plan?.value === price_id);
    setSelectedOption(matchingPlan || null);
  }, [price_id, planOptions]);

  //  Function to handle dropdown change
  const onDropdownChange = (option) => {
    setPrevSelectedOption(selectedOption);
    setSelectedOption(option);
    //  Calling API
    changePlan({ company_id, price_id: option?.value });
  };

  //  Rendering
  return (
    <SimpleDropdown
      placeholder="Select Plan"
      elements={planOptions}
      customWidth
      onChangeCallback={onDropdownChange}
      selectedElement={selectedOption}
      disabled={changePlanLoading || !canModifyPlan}
    />
  );
};

export default CompanyPlanSelector;
