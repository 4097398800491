import React, { useEffect } from 'react';
import {
  useRouteMatch,
  Switch,
  Route,
} from 'react-router-dom';
import GroupsManager from '../views/GroupsManager';
import IssueManager from '../views/IssueManager';
import PageNotFound from '../views/PageNotFound';
import { changeLayout } from '../actions/layout';
import useMemoizedDispatch from '../hooks/useMemoizedDispatch';

const GroupsAndIssuesRoutes = (props) => {
  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const { path } = useRouteMatch();

  //  Component did mount
  useEffect(() => {
    dispatch(changeLayout('vertical'));
  }, []);

  //  Rendering
  return (
    <Switch>
      <Route exact path={`${path}/:group_id/issues`}>
        <IssueManager />
      </Route>
      <Route exact path={path}>
        <GroupsManager />
      </Route>
      <Route component={PageNotFound} />
    </Switch>
  );
};

export default GroupsAndIssuesRoutes;
