import React from 'react'

const PendingAdminInviteAlert = () => {
    //  Rendering
    return (
        <div className="pending-invite-alert content-row">
            <div className="message ">
                <span className="important">Hey there!</span>
                <br></br>
                Please ask this member to confirm the email provided, in order to avoid bugs or functionality issues.
            </div>
        </div>
    )
}

export default PendingAdminInviteAlert;
