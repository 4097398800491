import _ from 'lodash';
import {
    CUSTOMER_LIST_SUCCESS,
    CUSTOMER_LIST_FAILURE,
    CUSTOMER_PROFILE_SUCCESS,
    CUSTOMER_PROFILE_FAILURE,
    CUSTOMER_CLEAR,
    SET_CUSTOMERS_FILTER,
    SET_CUSTOMERS_SORT,
    CLEAR_ALL_CUSTOMERS_FILTERS,
    RELOAD_CUSTOMERS,
    UPDATE_COMPANY_SUCCESS,
    LOGOUT_SUCCESS,
    ON_SCAN_LOADING_CHANGE,
    CALCULATE_ROI_SUCCESS,
    UPDATE_DOMAIN_COUNT
} from "../actions/types";
import filterTypes from '../constants/filterTypes';

const filtersInitialState = {

};
const sortFieldInitialState = { name: null, asc: true };
const initialState = {
    filters: filtersInitialState,
    activeFilters: [],
    searchTerm: '',
    sortField: sortFieldInitialState,
    list: [],
    pageCount: 1,
    total: 0,
    shouldReload: false,
    scanLoading: false,
}

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case CUSTOMER_LIST_SUCCESS: {
            return {
                ...state,
                list: payload.data || [],
                pageCount: payload.pagination?.page_count || 1,
                total: payload.pagination?.total || 0
            }
        }
        case SET_CUSTOMERS_FILTER: {
            const { filterName, type, value, label } = payload;
            switch (type) {
                case filterTypes.multiple: {
                    return {
                        ...state,
                        activeFilters: state.filters[filterName]?.includes(value) ? (
                            [..._.pullAllWith(state.activeFilters, [{ label, type, filterName, value }], _.isEqual)]
                        ) : [...state.activeFilters, { label, type, filterName, value }],
                        filters: {
                            ...state.filters,
                            [filterName]: state.filters[filterName]?.includes(value) ? (
                                _.pull(state.filters[filterName], value)
                            ) : [...state.filters[filterName], value]
                        }
                    }
                }

                case filterTypes.search: {
                    const cleanActiveState = _.pullAllBy(state.activeFilters, [{ filterName }], 'filterName');
                    return {
                        ...state,
                        activeFilters: _.isEmpty(value) ? (
                            [...cleanActiveState]
                        ) : [...cleanActiveState, { label, type, filterName, value: '' }],
                        searchTerm: value
                    }
                }

                case filterTypes.single:
                default: {
                    const cleanActiveState = _.pullAllBy(state.activeFilters, [{ filterName }], 'filterName');
                    return {
                        ...state,
                        activeFilters: _.isNil(value) ? (
                            [...cleanActiveState]
                        ) : [...cleanActiveState, { label, type, filterName, value: null }],
                        filters: {
                            ...state.filters,
                            [filterName]: value
                        }
                    }
                }
            }
        }

        case SET_CUSTOMERS_SORT: {
            return {
                ...state,
                sortField: payload
            };
        }

        case CLEAR_ALL_CUSTOMERS_FILTERS: {
            return {
                ...state,
                filters: filtersInitialState,
                activeFilters: [],
                searchTerm: ''
            };
        }

        case RELOAD_CUSTOMERS: {
            return {
                ...state,
                shouldReload: payload
            }
        }
        case CUSTOMER_PROFILE_SUCCESS: {
            return {
                ...state,
                currentCustomer: payload
            }
        }
        case UPDATE_COMPANY_SUCCESS: {
            return {
                ...state,
                currentCustomer: {
                    ...state.currentCustomer,
                    ...payload
                }
            }
        }
        case CALCULATE_ROI_SUCCESS: {
            return {
                ...state,
                currentCustomer: {
                    ...state.currentCustomer,
                    annual_revenue: payload?.annualRevenue,
                    number_employees: payload?.employeesCount
                }
            }
        }
        case CUSTOMER_CLEAR:
        case CUSTOMER_PROFILE_FAILURE:
        case CUSTOMER_LIST_FAILURE:
        case LOGOUT_SUCCESS: {
            return initialState
        }
        case ON_SCAN_LOADING_CHANGE: {
            return {
                ...state,
                scanLoading: payload,
            }
        }
        case UPDATE_DOMAIN_COUNT : {
            return {
              ...state,
              currentCustomer: {
                ...state.currentCustomer,
                domains_count: payload.domains_count,
              },
            };
        }
        default:
            return state
    }
}
