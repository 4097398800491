import React from 'react'
import { Col, Card } from 'reactstrap';
import CardBody from 'reactstrap/lib/CardBody';
import ButtonSolutionTooltip from './tooltips/ButtonSolutionTooltip';

const SolutionsBox = (props) => {
    const {
        //grade,
        className="",
        //gradeWrapper="",
        message,
        solutions,
        linksColor="light",
        linksPrefixId="solution-box-links",
        emptyContent,
        showGradesMessage=true,
        iconClassName,
        showTooltipContactButton = true,
        showAbbreviation = true,
        tooltipClassName = ""
    } = props;
    const renderSolutions = () => {
        return solutions.map((solution, index) => {
            return (
                <div key={`${linksPrefixId}-${index}`} className="d-inline-block">
                    <ButtonSolutionTooltip
                        id={`${linksPrefixId}-${index}`}
                        solution={solution}
                        color={linksColor}
                        showContactButton={showTooltipContactButton}
                        showAbbreviation={showAbbreviation}
                        tooltipClassName={tooltipClassName}
                    />
                </div>
            )
        })
    }
    return (
        <Col sm={12} md={6} className="align-self-start align-self-md-stretch" style={{ paddingLeft: "16px", paddingRight: "16px" }}>
            <Card className="h-100 mb-2 mb-md-0">
                <CardBody className={`solutions-box pl-4 py-1 pr-1 ${className}`}>
                    <div className="d-flex row flex-nowrap no-gutters">
                        {/* {showGradesMessage && <div className="d-inline-block"><LetterGradeCircle grade={grade} className={gradeWrapper}/></div>} */}
                        {showGradesMessage && <div className="solution-box-icon mr-2 d-inline-block text-light"><i className={iconClassName}/></div>}
                        <div className="d-inline-block">
                            {showGradesMessage && <div>{message}</div>}
                            <div className="d-flex flex-wrap">
                                {solutions?.length > 0 ? renderSolutions() : emptyContent}
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Col>
    )
}

export default SolutionsBox;
