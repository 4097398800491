import React from 'react';
import { useHistory } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';

const ActionLink = (props) => {
  //  Pasing props
  const {
    id,
    pathname,
    state,
    label,
    iconName,
    customAction,
  } = props;

  //  Getting router history
  const history = useHistory();

  //  Function for icon click
  const onIconClicked = () => {
    if (customAction) {
      customAction();
      return;
    }
    history.push({ pathname, state })
  };

  //  Rendering
  return (
    <React.Fragment>
      <a
        key={id}
        id={id}
        className="setup-link mr-4"
        tabIndex="-1"
        aria-label={label}
        onClick={() => onIconClicked()}
      >
        <i className={`bx ${iconName} bx-sm`}></i>
      </a>
      <UncontrolledTooltip placement="bottom" target={id} key={`tooltip-${id}`}>
        {label}
      </UncontrolledTooltip >
    </React.Fragment>
  )
}

export default ActionLink
