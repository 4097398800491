import moment from 'moment-timezone';
import { isCyvatarMember } from '../utils/helpers';

//  Function to get company freemium validations fields
const getCompanyValidationsField = (companytoUse, fieldToGet) => {
  const { showDemoData, lastRequestScan, lastScan } = companytoUse;
  let nextScanAvailable = false;
  let daysRemaining = null;

  if (lastRequestScan) {
    let nextScanDate = new Date(lastRequestScan);
    nextScanDate.setDate(nextScanDate.getDate() + 31);
    nextScanAvailable = nextScanDate < new Date();

    const currentDate = moment(new Date());
    const scanDate = moment(new Date(nextScanDate));
    const difference = currentDate.diff(scanDate, 'days');

    if (difference < 0) {
      daysRemaining = difference * -1;
    }
  }

  const freemiumValidations = {
    lastScanIsValid: true,
    nextScanAvailable,
    daysRemaining,
    renderSampleWidget: !showDemoData && !lastScan,
    renderSampleData: showDemoData || lastScan,
    hasConnection: companytoUse.hasConnection
  };
  return freemiumValidations[fieldToGet];
};

const companyInfoSelector = (state, fieldToGet, isFreemiumValidation = false) => {
  const user = state.auth?.user;
  const isSquadMember = isCyvatarMember(user);
  const companytoUse = isSquadMember ? state.impersonation : state.auth?.company;
  if (isFreemiumValidation) { return getCompanyValidationsField(companytoUse, fieldToGet); }
  return companytoUse?.[fieldToGet];
};

export default companyInfoSelector;
