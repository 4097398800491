import _ from 'lodash';
import { createSelector } from 'reselect';

const getCategories = state => state.assessment.assessment.categories;
const getAnswers = state => state.answers.questionAnswers;

//Returns either first unanswered question or last question

const makePickupQuestionSelector = () => {
    return createSelector( 
        [getCategories, getAnswers],
        (categories ,answers) => {
            if(!categories || !answers) return
            const pickupQuestion = {
                categoryId: null,
                sectionId: null,
                questionId: null,   
            };

            _.orderBy(categories,'order').some( category => {
                return _.orderBy(category.sections,'order').some( section => {
                    return _.orderBy(section.questions, 'order').some(question => {
                        pickupQuestion.categoryId = category.id;
                        pickupQuestion.sectionId = section.id;
                        pickupQuestion.questionId = question.id;
                        if(!answers[question.id]){
                            return true;
                        }
                        return false;
                    });
                });
            });
            return pickupQuestion;
        }
    );
}
export default makePickupQuestionSelector;
