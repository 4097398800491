import React from 'react'
import celebrationEmoji from '../../assets/images/emoji/celebration_emoji.svg';
import { mapPlansToTooltips } from '../../utils/helpers';
import SolutionsBox from './SolutionsBox';

const NextPlanUpgradeBox = (props) => {
    const {
        isCompliant,
        plans,
        canUpgrade
    } = props;

    // Celebration message when the highest subscription package has been purchased
    const renderNextSubscriptionEmptyState = (isCompliant) => {
        if(!isCompliant || canUpgrade) return null;
        const celebrationMessage = "Congratulations! Your company is now compliant on cybersecurity.";
        return (
            <div className="compliant-celebration d-flex align-items-center">
                <img className="celebration-emoji img-fuid mr-2" src={celebrationEmoji} />
                {celebrationMessage}
            </div>
        )
    }

    const renderContent =() => {
        const showCelebration = isCompliant && !canUpgrade;
        const message = `Your ${canUpgrade ? "next step in your" : ''} subscription is:`
        const planArray = showCelebration && !canUpgrade? [] : mapPlansToTooltips(plans)
        return (
            <SolutionsBox
                message={message}
                solutions={planArray}
                linksPrefixId="next-plan-links"
                emptyContent={renderNextSubscriptionEmptyState(isCompliant)}
                showGradesMessage={!showCelebration}
                iconClassName="bx bx-pin"
                linksColor="perfume"
                showAbbreviation={false}
                tooltipClassName="plan-tooltip"
            />
        )
    }

    return renderContent();
}

export default NextPlanUpgradeBox;
