import _ from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import { setAlert } from "../../actions/alerts";
import API from "../../api";
import { alertTypes } from "../../constants/alerts";
import errorMessages from "../../constants/errorMessages";
import useMemoizedDispatch from "../../hooks/useMemoizedDispatch";
import BulkActionsMenu from "../layout/BulkActionsMenu";
import useCustomMutation from "../../hooks/useCustomMutation";
import useCanAbility from "../../hooks/useCanAbility";
import { MODIFY_ACTION, PRIVILEGE_SOURCES } from "../../constants/privileges";
import {
  connectionQuickActionsConfig,
  CONNECTION_DELETE_ACTION,
  CONNECTION_DISABLE_ACTION,
  CONNECTION_ENABLE_ACTION,
} from "../../constants/common";
import ConnectionsTableContextMenu from "./ConnectionsTableContextMenu.js";
import ConnectionBulkActionModal from "./ConnectionBulkActionModal";
import { getSafeArray, isValidFunction } from "../../utils/helpers";
import { getQuickActionSuccessMessage } from "../../utils/connectionStatusHelpers";
import { useSelector } from "react-redux";
import companyIdSelector from "../../selectors/companyIdSelector";
import { onImpersonationSuccess } from "../../actions/impersonation";

const ConnectionsBulkActions = (props) => {
  const {
    selectedConnections,
    parentRef,
    onRightClick,
    bulkMenuId = "",
    bulkMenuRef,
    bulkMenuStyle = {},
    scrollableRef,
    setSelectedConnections,
  } = props;

  //  Initializing APIs
  const { PartnerAPI, UserAPI } = API;

  // Getting needed info from redux store
  const impersonatedCompanyId = useSelector((state) => companyIdSelector(state));

  // Component state
  const [selectedActionName, setSelectedActionName] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  // Component Hooks
  const { dispatch } = useMemoizedDispatch();
  const ability = useCanAbility();
  const {
    data: executeQuickActionResponse,
    error: executeQuickActionError,
    mutate: executeQuickAction,
    isLoading: executeQuickActionLoading,
  } = useCustomMutation(
    (params) => PartnerAPI.handlers.executeQuickAction(params),
    PartnerAPI.invalidators.executeQuickAction
  );
  const {
    data: impersonateResponse,
    error: impersonateError,
    mutate: impersonateCompany,
  } = useCustomMutation(
    (params) => UserAPI.handlers.impersonateCompany(params),
    UserAPI.invalidators.impersonateCompany
  );

  // Component variables
  const canModifyConnections = ability.can(
    MODIFY_ACTION,
    PRIVILEGE_SOURCES.COMPANY_SOLUTIONS.GENERAL
  );

  // Watch execute quick actions response
  useEffect(() => {
    if (!executeQuickActionResponse) return;
    const isDeleteAction = selectedActionName === CONNECTION_DELETE_ACTION;
    const message = getQuickActionSuccessMessage(
      selectedActionName,
      selectedConnections?.length
    );
    dispatch(setAlert(message, alertTypes.success));
    if (
      isDeleteAction &&
      getSafeArray(selectedConnections.includes(impersonatedCompanyId))
    ) {
      impersonateCompany(impersonatedCompanyId);
    } else {
      toggleBulkActionsConfirmationModal(false);
    }
    // Reset selected connections after any action
    if (isValidFunction(setSelectedConnections)) {
      setSelectedConnections([]);
    }
  }, [executeQuickActionResponse]);

  //  Watching impersonate response
  useEffect(() => {
    if (!impersonateResponse) {
      return;
    }
    dispatch(onImpersonationSuccess(impersonateResponse));
    toggleBulkActionsConfirmationModal(false);
  }, [impersonateResponse]);

  //  Watching impersonate error
  useEffect(() => {
    if (!impersonateError) {
      return;
    }
    const impersonationErrorMessage =
      "Error impersonating company, please select the company again";
    dispatch(setAlert(impersonationErrorMessage, alertTypes.error));
    dispatch(onImpersonationSuccess(null));
    toggleBulkActionsConfirmationModal(false);
  }, [impersonateError]);

  // Watch execute quick actions error
  useEffect(() => {
    if (!executeQuickActionError) return;
    const errorMessage =
      executeQuickActionError?.response?.data?.errorMessage ||
      errorMessages.defaultPost;
    dispatch(setAlert(errorMessage, alertTypes.error));
  }, [executeQuickActionError]);

  // Toggle bulk actions confirmation modal
  const toggleBulkActionsConfirmationModal = (isOpen) => {
    if (isOpen === undefined) {
      setShowConfirmModal(!showConfirmModal);
    } else {
      setShowConfirmModal(isOpen);
    }
  };

  // Bulk actions confirm action
  const onActionCallback = () => {
    if (!selectedActionName || selectedConnections?.length < 1) return null;
    const body = {
      connections: selectedConnections,
      action: selectedActionName,
    };
    executeQuickAction(body);
  };

  // Toggle modal and set selected action name
  const handleActionModal = (actionName) => {
    setSelectedActionName(actionName);
    toggleBulkActionsConfirmationModal();
  };

  // Get bulk actions options
  const getBulkActionsOptions = () => {
    return Object.values(connectionQuickActionsConfig).map((action) => {
      const { label, actionName, iconClassName } = action;
      const isDisableOrEnableAction =
        actionName === CONNECTION_DISABLE_ACTION ||
        actionName === CONNECTION_ENABLE_ACTION;
      const connectionLabel = isDisableOrEnableAction ? " connection" : "";
      return {
        label: `${label}${connectionLabel}`,
        iconClassName,
        callback: () => handleActionModal(actionName),
      };
    });
  };

  // render bulk actions confirmation modal
  const renderBulkConfirmationModal = () => {
    if (!showConfirmModal) return null;
    return (
      <ConnectionBulkActionModal
        showModal={showConfirmModal}
        toggleModal={toggleBulkActionsConfirmationModal}
        actionName={selectedActionName}
        elementsCount={selectedConnections?.length}
        confirmDisabled={!canModifyConnections || executeQuickActionLoading}
        confirmCallback={onActionCallback}
      />
    );
  };

  return (
    <>
      {renderBulkConfirmationModal()}
      <ConnectionsTableContextMenu
        parentRef={parentRef}
        onRightClick={onRightClick}
        onActionCallback={handleActionModal}
        scrollableRef={scrollableRef}
        isDisabled={!canModifyConnections}
      />
      <BulkActionsMenu
        options={getBulkActionsOptions()}
        show={selectedConnections?.length > 0}
        bulkMenuId={bulkMenuId}
        bulkMenuRef={bulkMenuRef}
        bulkMenuStyle={bulkMenuStyle}
        isSticky={true}
        className={"pl-3"}
        isDisabled={!canModifyConnections}
      />
    </>
  );
};

export default ConnectionsBulkActions;
