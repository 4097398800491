import cyvatarAxios from '../axios';
import { GET_COMPANY_BADGES, GET_BADGE } from '../ApiKeys';

const getCompanyBadges = async (company_id) => {
  const response = await cyvatarAxios.get(`/api/badge/by_company/${company_id}`);
  const { data } = response;
  return data;
};

const getBadge = async (certificate_public_id) => {
  const response = await cyvatarAxios.get(`/api/badge/certificate/${certificate_public_id}`);
  const { data } = response;
  return data;
};

// params { "name": text, "description": text, "image": text }
const createBadge = async (params) => {
  const body = JSON.stringify(params);
  const response = await cyvatarAxios.post('/api/badge/create', body);
  const { data } = response;
  return { data };
};

// params { "field": text, "value": text }
const updateBadge = async (badge_public_id, params) => {
  const body = JSON.stringify(params);
  const response = await cyvatarAxios.put(`/api/badge/update/${badge_public_id}`, body);
  const { data } = response;
  return { data };
};

// params { "company_id": int, "badge_id": int }
const assignBadge = async (params) => {
  const body = JSON.stringify(params);
  const response = await cyvatarAxios.post('/api/badge/reward_company', body);
  const { data } = response;
  return { data };
};

export const BadgeAPI = {
  handlers: {
    getCompanyBadges,
    getBadge,
    createBadge,
    updateBadge,
    assignBadge,
  },
  keys: {
    getCompanyBadges: GET_COMPANY_BADGES,
    getBadge: GET_BADGE,
  },
  invalidators: {
    createBadge: [GET_COMPANY_BADGES, GET_BADGE],
    updateBadge: [GET_COMPANY_BADGES, GET_BADGE],
    assignBadge: [GET_COMPANY_BADGES, GET_BADGE],
  }
};
