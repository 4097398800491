import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { PRIVILEGE_SOURCES, READ_ACTION } from '../../constants/privileges';
import useCanAbility from '../../hooks/useCanAbility';
import IssueManager from '../../views/IssueManager';

const IssueRoutes = () => {

    // Hooks
    const location = useLocation();
    const ability = useCanAbility();

    return ability.can(READ_ACTION, PRIVILEGE_SOURCES.SIDEBAR.ISSUES_TAB) ? (
        <IssueManager />
    ) : (
        <Redirect to={`/dashboard${location?.pathname}`}/>
    )
}

export default IssueRoutes;
