import React, { useEffect, useRef, useState } from 'react';
import { Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Dashboard from '../components/dashboard/Dashboard';
import DevicesList from '../components/devices/DevicesList';
import CompanyDevicesDetails from '../components/devices/CompanyDevicesDetails';
import ImpersonationEmptyView from '../components/layout/ImpersonationEmptyView';
import useSelectedSubtab from '../hooks/useSelectedSubtab';
import companyIdSelector from '../selectors/companyIdSelector';
import { changeLayout } from '../actions/layout';
import { scanStatusMap, redirectPromptActionTypes } from '../constants/common';
import useMemoizedDispatch from '../hooks/useMemoizedDispatch';
import companyInfoSelector from '../selectors/companyInfoSelector';
import MainScrollableContainer from '../components/layout/MainScrollableContainer';
import useScanValidation from '../hooks/useScanValidation';
import { CREATE_ACTION, PRIVILEGE_SOURCES, READ_ACTION } from '../constants/privileges';
import useCanAbility from '../hooks/useCanAbility';
import { setDevicesMultipleFilterGroup } from '../actions/devices';

const Devices = () => {
    //  Component hooks
    const { selectedIdentifier, identifiers } = useSelectedSubtab({ entry: 'devices' });
    const { dispatch } = useMemoizedDispatch();
    const history = useHistory();
    const location = useLocation();
    const { isAuthorized, scanStatus } = useScanValidation();
    const ability = useCanAbility();

    //  Watching redux store
    const companyId = useSelector((state) => companyIdSelector(state));
    //const renderSampleWidget = useSelector((state) => companyInfoSelector(state, 'renderSampleWidget', true));
    const renderSampleData = useSelector((state) => companyInfoSelector(state, 'renderSampleData', true));
    const freemiumStatus = useSelector((state) => companyInfoSelector(state, 'freemium', false));
    const activeImpersonation = useSelector((state) => !!state.impersonation.companyId);
    const isMemberView = useSelector(state => state.impersonation.isMemberView);

    // Component state
    const [selectedPartner, setSelectedPartner] = useState(null);

    //  Component refs
    const scrollableRef = useRef(null);

    //	Watching state changes
    useEffect(() => {
        dispatch(changeLayout('vertical'));
    }, [changeLayout]);

    // Watching company id changes
    useEffect(() => {
        if(freemiumStatus !== 0 && !renderSampleData) {
            // Reset partner filter
            dispatch(setDevicesMultipleFilterGroup('partner_id', [], [], false))
            setSelectedPartner({ count: 0, name: null });
        }
    },[companyId])

    //  Validating view authorization
    useEffect(() => {
        // Users that can impersonate won't be redirected
        const canImpersonate = ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.MULTIPLE.IMPERSONATE)
        if (isAuthorized || isAuthorized === null || canImpersonate) { return; }
        const message = scanStatus === scanStatusMap.NOT_REQUESTED_SCAN ? 'Request a scan to unlock your Device List' : '';
        const title = 'Device List';
        const action_type = redirectPromptActionTypes.REQUEST_SCAN;
        const state = { message, title, action_type, redirected: true };
        history.replace({ pathname: '/dashboard', state });
    }, [isAuthorized]);

    // Check if member view is active
    useEffect(() => {
        const canAccessDevices = ability.can(READ_ACTION, PRIVILEGE_SOURCES.SIDEBAR.DEVICES_TAB);
        if (location?.pathname === '/devices' && (!canAccessDevices || (isMemberView && activeImpersonation) )) {
            history.push('/dashboard/devices');
        }
    }, [location?.pathname, isMemberView, activeImpersonation]);

    /* const renderScanAndDemoWidgets = () => {
        return (
            <Row className="scan-demo-row no-gutters">
                {(freemiumStatus !== 0 && renderSampleWidget) &&
                    <Col sm={12} lg={6} className="align-self-start align-self-lg-stretch mb-3">
                        <IssueDevicesDemoWidget
                            type='sample'
                            welcomeTitle="Welcome to the device list"
                            welcomeMessage="This is the place where all your devices will be uploaded and monitored."
                        />
                    </Col>
                }
                {(freemiumStatus !== 0) &&
                    <Col sm={12} lg={6} className="align-self-start align-self-lg-stretch mb-3">
                        <IssueDevicesDemoWidget type='scan' />
                    </Col>
                }
            </Row>
        )
    } */

    //  Function to render content
    const renderContent = () => {
        if (!identifiers) { return null; }
        if (identifiers.DEVICES === selectedIdentifier) {
            return (
                <MainScrollableContainer
                    additionalClassName="devices-listing"
                    scrollableRef={scrollableRef}
                >
                    {/* {renderScanAndDemoWidgets()} */}
                    {(freemiumStatus === 0 || renderSampleData) && (
                        <CompanyDevicesDetails
                            deviceByPartnerCount={selectedPartner?.count}
                            setSelectedPartner={setSelectedPartner}
                        />
                    )}
                    <Row className="no-gutters">
                        <DevicesList
                            companyId={companyId}
                            scrollableRef={scrollableRef}
                            partnerName={selectedPartner?.name}
                        />
                    </Row>
                </MainScrollableContainer>
            );
        }
        return null;
    };

    //	Rendering empty state for cydekick devices
    if (ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.MULTIPLE.IMPERSONATE) && !companyId) {
        return (
            <ImpersonationEmptyView viewName="Devices" />
        );
    }

    //  Rendering
    return (
        <Dashboard
            title="Devices"
            containerClassName="dashboard mx-2 px-0 py-4 text-normal-style page-content contained-view-width"
        >
            {renderContent()}
        </Dashboard>
    )
};

export default Devices;
