import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import companyIdSelector from '../selectors/companyIdSelector';
import API from '../api';
import { getApiErrorMessage } from '../utils/helpers';
import useCustomQuery from './useCustomQuery';
import solutionsStyle from '../constants/solutionsStyle';

const useIcarmDataV2 = (solutionId = '') => {
  //  Initializing APIs
  const { DashboardAPI } = API;

  // Getting needed info from redux store
  const company_id = useSelector(state => companyIdSelector(state));
  const selected_solution_id = useSelector((state) => state?.issues?.filters?.solution_id) || null;

  //  Watching time filters changes
  const [ timeFilters, mutateTimeFilters ] = useState({});

  // Hook state
  const [onboardingSolutions, setOnboardingSolutions] = useState(null);
  const [solutionFilterChanged, setSolutionFilterChanged] = useState(false);
  const [maintenanceSolutions, setMaintenanceSolutions] = useState(null);

  // Hooks
  //  Calling API for data
    const {
      data: onboardingData,
      error: onboardingError,
      isLoading: onboardingLoading,
      refetch: refetchOnboarding,
  } = useCustomQuery(
      [DashboardAPI.keys.getIcarmOnboardingSolutions, { ...timeFilters, company_id, solution_id: (solutionId!=='') ? solutionId : selected_solution_id }],
      ({ queryKey }) => DashboardAPI.handlers.getIcarmOnboardingSolutions(queryKey[1]),
  );

  //  Calling API for data

  const {
    data: maintenanceSolutionsData,
    error: maintenanceSolutionsError,
    isLoading: maintenanceSolutionsLoading,
    refetch: refetchMaintenanceSolutions,
  } = useCustomQuery(
    [DashboardAPI.keys.getIcarmMaintenanceSolutions, { ...timeFilters, company_id, solution_id: (solutionId!=='') ? solutionId : selected_solution_id }],
    ({ queryKey }) => DashboardAPI.handlers.getIcarmMaintenanceSolutions(queryKey[1]),
  );


  //  Watching API response for cyvalue
  useEffect(() => {
    if (!onboardingData) { return; }
    if (onboardingData.solutions && Array.isArray(onboardingData.solutions)) {
      const solutions = onboardingData.solutions.map((solution) => {
        const solutionName = solution?.name.replace('aaS', '');
        const selectedSolutionName = !solutionName || solutionName === 'Overall' ? 'DEFAULT' : solutionName;
        const solutionColorTheme = solutionsStyle[selectedSolutionName]
        return {
            ...solution,
            theme: solutionColorTheme,
        }
      })
      onboardingData.solutions = solutions;
    }
    //const normalizedData = normalizeCyvalueData(onboardingData, [], timeFilters);
    setOnboardingSolutions(onboardingData);
  }, [onboardingData]);

  useEffect(() => {
    if (!maintenanceSolutionsData) return;
    if (maintenanceSolutionsData.solutions && Array.isArray(maintenanceSolutionsData.solutions)) {
      const solutions = maintenanceSolutionsData.solutions.map((solution) => {
        const solutionName = solution?.name.replace('aaS', '');
        const selectedSolutionName = !solutionName || solutionName === 'Overall' ? 'DEFAULT' : solutionName;
        const solutionColorTheme = solutionsStyle[selectedSolutionName]
        return {
            ...solution,
            theme: solutionColorTheme,
        }
      })
      maintenanceSolutionsData.solutions = solutions;
    }
    setMaintenanceSolutions(maintenanceSolutionsData?.solutions);
  }, [maintenanceSolutionsData]);

  //  Function to change filters
  const changeFilters = (newFilters, enableRetry = false) => {
    mutateTimeFilters(newFilters);
  }

  //  Watching selected tab changes
  useEffect(() => {
      setSolutionFilterChanged(true);
  }, [selected_solution_id, company_id]);

  //watching loading state to reset solutionFilterChanged flag
  useEffect(() => {
    if (!onboardingLoading) {
        setSolutionFilterChanged(false);
    }
  }, [onboardingLoading])

  return {
    onboardingLoading: onboardingLoading && solutionFilterChanged,
    maintenanceLoading: maintenanceSolutionsLoading,
    onboardingErrorMessage: getApiErrorMessage(onboardingError),
    maintenanceErrorMessage: getApiErrorMessage(maintenanceSolutionsError),
    refetchOnboarding: refetchOnboarding,
    refetchMaintenance: refetchMaintenanceSolutions,
    filters: timeFilters,
    changeFilters,
    onboardingSolutions,
    maintenanceSolutions
    //themeColor: currentThemeColor,
}

};

export default useIcarmDataV2;