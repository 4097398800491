import React from 'react';
import { Button } from 'reactstrap';
import BasicModal from '../../../layout/BasicModal';

const UserManagerActionModal = (props) => {

  // Multi-purpose modal for user manager actions
  const {
    showModal,
    toggleModal,
    modalConfig = {},
  } = props;

  return (
    <BasicModal
      showModal={showModal}
      toggleModal={toggleModal}
      title={modalConfig.title}
      subtitle={modalConfig.subtitle}
      customClassName={modalConfig.customClassName}
    >
      <div className='modal-custom-content-container'>
        {modalConfig?.content}
      </div>
      <div className={`row-container ${modalConfig?.buttonClassName}`}>
        <Button
          color="light-gray left-modal-button"
          outline
          onClick={() => toggleModal(false)}
        >
          Cancel
        </Button>
        <Button
          className="btn btn-submit text-dark font-weight-bold"
          onClick={modalConfig?.confirmCallback}
          disabled={modalConfig?.isLoading || modalConfig?.disableButton}
          color={modalConfig?.confirmButtonClassName}
        >
          {modalConfig?.actionButtonLabel || 'Confirm'}
        </Button>
      </div>
    </BasicModal>
  );
}

export default UserManagerActionModal;