import {
    CLEAR_DEVICES,
    DEVICE_UPDATE_SUCCESS,
    DEVICES_COUNT_SUCCESS,
    DEVICES_COUNT_FAILURE,
    ON_DELETE_DEVICE_CHANGE,
    SET_DEVICES_FILTER,
    CLEAR_ALL_DEVICES_FILTERS,
    ON_MERGE_DEVICES_CHANGE,
    SET_DEVICES_FILTER_GROUP,
} from "./types";
import { errorActionCreator } from './action-creators/errors';
import { successActionCreator } from './action-creators/success';
import filterTypes from "../constants/filterTypes";

export const getDevicesCount = (companyId) => {
    return async (dispatch, getState, { API, queryClient }) => {
        try {
            const { DeviceAPI } = API;
            const response = await DeviceAPI.handlers.getDevicesCount(companyId);
            dispatch(successActionCreator(DEVICES_COUNT_SUCCESS, response?.data));
        } catch (error) {
            const message = 'Error getting devices count.';
            dispatch(errorActionCreator(DEVICES_COUNT_FAILURE, { message }));
        }
    };
}

export const clearDevices = () => {
    return {
        type: CLEAR_DEVICES
    }
}

export const updateDeviceSuccess = (payload) => {
    return {
        type: DEVICE_UPDATE_SUCCESS,
        payload: payload
    }
}

export const onDeleteDeviceChange = (show = false, device_ids = [], success = false) => {
    return {
        type: ON_DELETE_DEVICE_CHANGE,
        payload: { show, device_ids, success },
    }
}
export const setDevicesFilter = (filterName, value, label, type = filterTypes.single, filterOption, hideActiveFilter) => {
    return {
        type: SET_DEVICES_FILTER,
        payload: { filterName, value, label, type, filterOption, hideActiveFilter }
    }
}

export const clearDevicesFilters = (payload) => {
    return {
        type: CLEAR_ALL_DEVICES_FILTERS,
        payload
    }
};

export const setDevicesMultipleFilterGroup = (filterName, values, activeValues, reset = true) => {
    return {
        type: SET_DEVICES_FILTER_GROUP,
        payload: { filterName, values, activeValues, reset }
    }
}

export const onMergeDevicesChange = (show = false, devices = [], success = false) => {
    return {
        type: ON_MERGE_DEVICES_CHANGE,
        payload: { show, devices, success },
    }
};
