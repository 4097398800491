import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { setIssuesFilter } from '../../actions/issues';
import { rollUpWidgetsIds } from '../../constants/common';
import filterTypes from '../../constants/filterTypes';
import {
    issueTypes,
    criticalityColorsByLabel,
} from '../../constants/issues';
import { issuesCriticalityConfigColor } from '../../constants/criticality';
import { tendencyChartPointsByTimeUnit } from '../../constants/tendencyChart';
import API from '../../api';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import companyIdSelector from '../../selectors/companyIdSelector';
import RollupWidget from '../dashboard/cydekick-results/RollupWidget';
import TrendChartWrapper from '../dashboard/charts/tendency-chart/TrendChartWrapper';

const GrcIssuesCharts = (props) => {
    //  Parsing params
    const {
        ref,
        collapsed = false,
        componentId = 'grc-charts-id',
        componentRef,
        customStyle
    } = props;

    //  Initializing API
    const { GrcIssueAPI } = API;

    // Getting needed info from redux store
    const companyId = useSelector((state) => companyIdSelector(state));
    const selectedSubTabValue = useSelector((state) => state?.navigation?.selectedSubtab?.value);

    // Component Hooks
    const { dispatch } = useMemoizedDispatch();

    // Handle Rollup Legend Click
    const handleRollupClick = (filter, widgetType) => {
        const filterLabel = widgetType === rollUpWidgetsIds.STATUS ? 'status' : 'severity';
        const label = widgetType === rollUpWidgetsIds.STATUS ? filter?.label : `Open, ${filter?.label}`
        dispatch(setIssuesFilter(filterLabel, parseInt(filter?.id), label, filterTypes.multiple, null, false, issueTypes.GRC));
    }

    // Function to render Rollup widgets
    const renderRollupWidgets = () => {
        return (
            <RollupWidget
                sm={6}
                lg={6}
                sumAll={true}
                allowAllCompanies={true}
                company_id={companyId}
                containerClassName={`grc-rollup-container ${collapsed ? 'expanded-rollup' : ''}`}
                cardClassName="issue-rollup-card h-100"
                widgetOrder={[rollUpWidgetsIds.CRITICALITY, rollUpWidgetsIds.STATUS]}
                handleRollupClick={handleRollupClick}
                solution_id={selectedSubTabValue}
                collapsed={collapsed}
                issueType={issueTypes.GRC}
                ariaLabel="Issues breakdown by criticality and Rollup"
            />
        )
    }

    // Function to render Criticality History Chart
    const renderGRCCriticalityChart = () => {
        if (collapsed) return null;
        return (
            <Col className="mb-2">
                <Card className="grc-trend-chart-card mb-0">
                    <CardBody>
                        <TrendChartWrapper
                            isSummary={false}
                            title="Task Trend by Criticality"
                            objects="tasks"
                            emptyTitle='All quiet in the CySO front'
                            emptySubtitle='No task info to report so far'
                            pointsColorConfig={issuesCriticalityConfigColor}
                            labelColorConfig={criticalityColorsByLabel}
                            pointsCountConfig={tendencyChartPointsByTimeUnit}
                            apiKey={GrcIssueAPI.keys.getGrcTrendData}
                            apiFunction={GrcIssueAPI.handlers.getGrcTrendData}
                            useLocalTimeFilter={true}
                        />
                    </CardBody>
                </Card>
            </Col>
        )
    };

    // Function to render
    return (
        <div
            id={componentId}
            ref={componentRef}
            style={customStyle}
            className="sticky-element bg-dark"
        >
            <div className="w-100 d-flex flex-wrap">
                {renderRollupWidgets()}
                {renderGRCCriticalityChart()}
            </div>
        </div>
    )
}

export default GrcIssuesCharts
