import React from 'react';

const EmptyState = (props) => {
  //  Parsing props
  const {
    title,
    message = '',
    titleClassName = '',
    messageClassName = '',
    customImage,
  } = props;

  //  Function to render title
  const renderTitle = () => {
    if (!title) { return; }
    return (
      <div className={titleClassName}>
        {title}
      </div>
    );
  }

  //  Rendering
  return (
    <div className="custom-table-empty">
      {customImage ? <img className="empty-icon-sm" alt="Empty state image" src={customImage}/> : null}
      {renderTitle()}
      <div className={messageClassName}>
        {message}
      </div>
    </div>
  );
};

export default EmptyState;
