export const inputTypes = {
    email: 'email',
    text: 'text',
    password: 'password',
    select: 'select',
    passwordLogin: 'password_login',
    domain:'domain',
    //  TO DO: add types as needed
};

//  Password regexps

//For validating password length
const passwordSizeRegexp = /^(?=.{12,})/;
//For validating mixed-case OR digit in password (not in use anymore)
const passwordFormatRegexp = /^(?=.*(\d|[A-Z]))(?=.*[a-z])(?=.*[a-zA-Z]).{12,}$/;
//For validating mixed-case letters in password (at least one uppercase and 1 lowercase)
const passwordMixedCaseRegexp = /^(?=.*([a-z]))(?=.*([A-Z])).{12,}$/;
//For validating at least 1 digit in password
const passwordDigitRegexp = /^(?=.*(\d)).{12,}$/;
//For validating at least 1 special charac in password
const passwordSpecialCharacs = /^(?=.*([!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])).{12,}$/;

export const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email) {
        return 'Please complete this required field.';
    } else if (!re.test(String(email).toLowerCase())) {
        return 'Please enter a valid email address.';
    } else {
        return null;
    }
}

export const validateCydekickEmail = (email) => {
    const re = /^[A-Za-z0-9._+-]+@(cyvatar.ai|helloiconic.com)$/;
    if (!email) {
        return 'Please complete this required field.';
    } else if (!re.test(email)) {
        return 'Please enter a valid email address for cydekicks.';
    } else {
        return null;
    }
}

export const validateEmptyTextField = (text) => {
    if (!text) {
        return 'Please complete this required field.';
    } else if (text.trim().length === 0) {
        return 'Please complete this required field.';
    } else {
        return null;
    }
}

export const validateNumberField = (number) => {
    switch (number) {
        case !number:
            return 'Please complete this required field.';
        case number:
            return
        default:
            return null

    }
}


export const validatePassword = (password) => {
    if (!password) {
        return 'Please complete this required field.';
    } else if (!(passwordSizeRegexp.test(password))) {
        return 'Your password must be at least 12 characters long.';
    } else if (!(passwordMixedCaseRegexp.test(password))) {
        return 'Your password must at least include 1 mixed-case letter.';
    } else if (!(passwordDigitRegexp.test(password))) {
        return 'Your password must at least include 1 number.';
    } else if (!(passwordSpecialCharacs.test(password))) {
        return 'Your password must at least include 1 special character.';
    } else {
        return null;
    }
}

export const validateFreemiumPassword = (password) => {
    if (!password) {
        return 'Please complete this required field.';
    } else if (!(passwordSizeRegexp.test(password))) {
        return 'Your password must be at least 8 characters long.';
    } else if (!(passwordFormatRegexp.test(password))) {
        return 'Your password must at least include 1 mixed-case letter or 1 number.';
    } else {
        return true;
    }
}

export const validateLoginPassword = (password) => {
    if (!password) {
        return 'Please complete this required field.';
    } else {
        return null;
    }
}

export const validateConfirmPassword = (confirmPassword, password) => {
    if (!confirmPassword) {
        return 'Please complete this required field.';
    } else if (confirmPassword !== password) {
        return "Your passwords don't match";
    } else {
        return null;
    }
}

//  Function that check all the fields in a form looking for errors
export const existErrorsOnForm = (errors) => {
    if (!errors) { return true; }
    const matchingError = Object.keys(errors).find((key) => errors[key]);
    return !!matchingError;
};

//  Function to validate if form has empty values
export const existEmptyValuesOnForm = (formData, expectedAmount = 0) => {
    //  Verifying if we have the expected amount of fields
    if (!formData) { return true; }
    const values = Object.values(formData) || [];
    if (values.length < expectedAmount) { return true; }
    //  Verifying if one value is invalid
    const invalidValue = values.find((value) => (value === '' || !value));
    return !!invalidValue;
}

//  Function to validate names
export const validateNames = (name, value = '') => {
    if (name !== 'first_name' && name !== 'last_name') { return null; }
    const validName = new RegExp('^[A-Za-z]+$');
    if (!value || !validName.test(value)) { return 'Please enter a valid name.'; }
    return null;
};

export const validateSelectedValue = (option) => {
    if (option && option.value) return null;
    return 'Please select a valid option.';
}

//  Function to validate fields by type
export const validateFieldByType = (value = '', type = 'text', name = '') => {
    let error = null;

    switch (type) {
        case inputTypes.text:
            error = validateEmptyTextField(value) || validateNames(name, value);
            break;
        case inputTypes.password:
            error = validatePassword(value);
            break;
        case inputTypes.email:
            error = validateEmail(value);
            break;
        case inputTypes.select:
            error = validateSelectedValue(value);
            break;
        case inputTypes.passwordLogin:
            error = validateLoginPassword(value);
            break;
        case inputTypes.domain:
            error = validateDomain(value);
            break
        default:
            error = null;
            break;
    }

    return error;
};

export const validateFieldsByFormTemplate = (formTemplate, formValues) => {
    const errors = {};
    formTemplate.forEach((field) => {
      const { type, name } = field;
      const value = formValues[name];
      const validation = validateFieldByType(value, type, name);
      if (!validation) { return; }
      errors[name] = validation
    });

    return errors;
};

export const validateCompanyName = (name = '') => {
    if (!name) { return 'Empty company name not allowed'; }
    if (name?.split(' ')?.join('')?.length < 3) { return 'Company name should contain at least 3 characters.'; }
    return null;
};

export const validateDomain = (domain) => {
    if(!domain) {
        return 'Please complete this required field.';
    } else {
        return null;
    }
}
