import React from "react";
import { CSVReader } from "react-papaparse";
import { setAlert } from "../../actions/alerts";
import CSVSchemaValidator from "../../config/CSVSchemaValidator";
import useMemoizedDispatch from "../../hooks/useMemoizedDispatch";
import { getSafeArray } from "../../utils/helpers";

const ConnectionFileInput = (props) => {
  const {
    fieldName,
    index = 0,
    buttonRef,
    clientSlug,
    setClientSlug,
    confirmClientSlug,
    setConfirmClientSlug,
    invalidMessages,
    setInvalidMessages,
    partnerName,
  } = props;

  // Component hooks
  const { dispatch } = useMemoizedDispatch();

  const csvSchemaValidator = new CSVSchemaValidator();

  // Handle file load
  const handleOnFileLoad = (data, file) => {
    const slug = data[0].data.client_slug;
    setClientSlug(slug);
    csvSchemaValidator.updatePartnerConfig(partnerName);
    const result = csvSchemaValidator.validate(data);
    setInvalidMessages(result);
  };

  // Handle open dialog
  const handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  // Handle error
  const handleOnError = (err, file, inputElem, reason) => {
    dispatch(setAlert(reason, "danger"));
  };

  // Render CSV reader
  const renderCSVReader = () => {
    return (
      <CSVReader
        ref={buttonRef}
        className="csv-reader"
        onFileLoad={handleOnFileLoad}
        onError={handleOnError}
        noClick
        noDrag
        config={{
          delimiter: ",",
          header: true,
          dynamicTyping: true,
        }}
      >
        {({ file }) => (
          <div className="row file-input">
            <div className="col-7 d-flex flex-column">
              <label className="file-input-label" htmlFor="customFile">
                CSV file
              </label>
              <label id="customFileLabel" className="file-input-name">
                {file?.name || "Upload CSV file…"}
              </label>
            </div>
            <div className="col">
              <label
                type="button"
                onClick={handleOpenDialog}
                className="file-input-button"
              >
                Browse file
              </label>
            </div>
          </div>
        )}
      </CSVReader>
    );
  };
  // Render error messages
  const renderErrors = () => {
    return (
      <div className="csv-errors w-100">
        {getSafeArray(invalidMessages).map((message, index) => (
          <div key={`error-${index}`}>{message}</div>
        ))}
      </div>
    );
  };

  const renderConfirmation = () => {
    return clientSlug && invalidMessages.length === 0 ? (
      <div className="custom-control custom-checkbox mb-3">
        <input
          id="confirmClientSlug"
          type="checkbox"
          className="custom-control-input"
          onChange={() => {
            setConfirmClientSlug(!confirmClientSlug);
          }}
          checked={confirmClientSlug}
        />
        <label htmlFor="confirmClientSlug" className="custom-control-label">
          I'm sure the CSV file belongs to "{clientSlug}"
        </label>
      </div>
    ) : null;
  };
  return (
    <div key={`${fieldName}-${index}`}>
      {renderCSVReader()}
      {renderErrors()}
      {renderConfirmation()}
    </div>
  );
};

export default ConnectionFileInput;
