import React, {useEffect} from "react";
import { useSelector } from 'react-redux';
import { Card, CardBody, Col } from "reactstrap";
import { criticalityColors, chartColumnNoGridConfig} from '../../../../constants/dashboard';
import { arrangeCriticalityData } from "../../../../utils/dashboardHelpers";
import { rollUpWidgetsIds } from '../../../../constants/common';
import API from '../../../../api';
import companyIdSelector from '../../../../selectors/companyIdSelector';
import useMemoizedDispatch from '../../../../hooks/useMemoizedDispatch';
import useCustomQuery from "../../../../hooks/useCustomQuery";
import { issueTypes } from "../../../../constants/issues";
import issuesInfoSelector from "../../../../selectors/issuesInfoSelector";
import {
  onIssueTotalsSuccess,
  onReloadIssuesRollup,
} from "../../../../actions/issues";
import InfoTooltip from "../../../dashboard/tooltips/InfoTooltip";
import LoadingContent from "../../../layout/LoadingContent";
import BarChart from "../../../dashboard/charts/BarChart";
import { abbreviateNumber } from "../../../../utils/helpers";
import { useHistory } from 'react-router-dom';
import useUserType from "../../../../hooks/useUserType";


const IssuesByCriticalityBar = (props) => {

  const {
    containerClassName = '',
    height= 190,
    onLegendClicked = null,
    titleOffsetX = 30,
    titleOffsetY = 135,
  } = props;

  //  Initializing APIs
  const { IssueAPI } = API;

  // Getting needed info from redux store
  const companyId = useSelector(state => companyIdSelector(state));
  const selectedSubTabValue = useSelector((state) => state?.navigation?.selectedSubtab?.value);

  //  Getting needed info from redux store
  const shouldReloadRollup = useSelector(state => issuesInfoSelector(state, 'shouldReloadRollup' ,rollUpWidgetsIds.CRITICALITY));
  const selectedSubtabSearch = useSelector((state) => state?.navigation?.selectedSubtab?.search);

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const history = useHistory();
  const userType = useUserType();


  //  Calling API for data
  const {
    data: chartData,
    error: chartDataError,
    isLoading: chartDataLoading,
    refetch: refetchRollUpData,
  } = useCustomQuery(
    [IssueAPI.keys.getRollupData, {
      company_id: companyId,
      params: {
          open_only: true,
          solution_id: selectedSubTabValue,
          grc_only: issueTypes.GRC === rollUpWidgetsIds.CRITICALITY
      }
  }, true],
  ({ queryKey }) => IssueAPI.handlers.getRollupData(queryKey[1], queryKey[2])
  );

  //  Watching chart data
  useEffect(() => {
    if (!chartData) { return; }
    dispatch(onIssueTotalsSuccess(chartData, rollUpWidgetsIds.CRITICALITY, userType));
  }, [chartData]);

  const retryRequest = () => {
    if (!companyId) { return; }
    refetchRollUpData();
  }

  //  Function to handle chart click
  const onChartClick = () => {
    const issuesPath = `/dashboard/issues/${selectedSubtabSearch}`;
    history.push(issuesPath);
  };

  const formatData = (data,total) => {
    return data.map((item,index) => {
      return {
        x: item.label,
        y: (total!==0) ? item.value : index + 1,
        percentage: item.value / total * 100
    }
    })
  }

  // Function to render bar chart
  const renderBarChart = (props) => {

    const {
      data,
      total,
    } = props;

    return (
      <BarChart
        baseConfig={chartColumnNoGridConfig}
        optionsChart={
          {
            chart:{
              height: height,
              toolbar: {
                show: false,
              },
              events: {
                click: onChartClick,
                legendClick: (context, index) => {
                  if (!onLegendClicked || typeof (onLegendClicked) !== 'function') { return; }
                  const element = data[index];
                  onLegendClicked(element);
                }
              }
            },
            legend: {
              position: 'right',
              horizontalAlign: 'left',
              offsetY: 5,
              offsetX: -18,
              formatter: function(seriesName, opts) {
                const count = (total===0) ? 0 : abbreviateNumber(opts.w.globals.series[0][opts.seriesIndex]);
                return [count,seriesName]
              }
            },
            tooltip: {
              enabled: total !== 0,
              float: true,
              custom: function (opts) {
                const { dataPointIndex, w } = opts;
                return (`
                  <div class="apexcharts-doughnut-tooltip apexcharts-basic-tooltip arrow-down">
                    <div class="series-label">${w.globals.labels[dataPointIndex]}</div>
                    <div>${parseInt(w.config.series[0].data[dataPointIndex].percentage || 0)}% of all issues</div>
                  </div>
                `);
              }
            },
            colors: total > 0 ? criticalityColors : ['#fff'],
            plotOptions: {
              bar: {
                columnWidth: '50%',
                distributed: true,
              }
            },
            title: {
              text: `This week: ${total} Open Issues`,
              floating: true,
              offsetY: titleOffsetY + 12,
              offsetX: titleOffsetX,
              align: 'left',
              style: {
                color: 'white',
                marginBottom:'-10px',
              },
            },
          }
        }
        series={[{
          data: formatData(data,total)
        }]}
      />
    );
  };

  const renderChart = (props, widgetType) => {
    const {
      data,
      colorsConfig,
      total,
      unitLabel,
      componentId,
    } = props;

    const zeroLabel = "Request a Scan to load your issues";
    const chartParams = {
      data,
      colorsConfig,
      unitLabel,
      total,
      zeroLabel,
      widgetType,
    };

    return (
      <div className={`issues-by-criticality-container ${containerClassName}`}>
        <Col
          key={`issues-by-criticality-bar-widget`}
          sm={12}
          lg={12}
          className={`rollup-widget-container align-self-start align-self-lg-stretch mb-2`}
        >
          <Card className="h-100 mb-3 mb-md-0 task-criticality-rollup">
            <CardBody className="rollup-widget d-flex flex-column">
              <div className="d-flex align-items-center">
                <InfoTooltip
                  label="Open Issues by Criticality"
                  wrapperClassName="dashboard-widget-title"
                  showTooltip={false}
                />
              </div>
              <div className="d-flex flex-grow-1 align-items-center doughnut-chart-container">
                <LoadingContent
                  isLoading={chartDataLoading}
                  errorMessage={
                    chartDataError ? "Oops! our fault, reload again." : null
                  }
                  iconType="solidIcon"
                  errorStyle={{ minHeight: "150px", paddingTop: "16px" }}
                  loadingStyle={{ minHeight: "150px", paddingTop: "16px" }}
                  errorButtonCallback={retryRequest}
                >
                  <div className="chart-bar-container">
                    {renderBarChart(chartParams)}
                    {(total===0) && <div className='chart-emtpy-state-background overlay'/>}
                  </div>
                </LoadingContent>
              </div>
            </CardBody>
          </Card>
        </Col>
      </div>
    );
  };

  //  Watching should reload flag changes
  useEffect(() => {
    if (!shouldReloadRollup) {
      return;
    }
    refetchRollUpData();
    dispatch(onReloadIssuesRollup(false, issueTypes.GRC));
  }, [shouldReloadRollup]);

  //  Function to render the required rollup widget
  const renderRequiredWidget = () => {
    const widgetConfig = arrangeCriticalityData(chartData, true);
    return renderChart(widgetConfig, rollUpWidgetsIds.CRITICALITY);
  };


  //  Rendering
  return (
    <>
      {renderRequiredWidget()}
    </>
  );
};

export default IssuesByCriticalityBar;
