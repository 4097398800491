import React from 'react';
import NavigationButtonsByIndex from '../../layout/buttons/NavigationButtonsByIndex';
import ExternalLink from '../../layout/ExternalLink';
import NavigationDots from './NavigationDots';
import { PrismicRichText } from '@prismicio/react'

const LandingNotificationInfoSection = (props) => {
    const {
        title,
        description,
        index,
        externalLink,
        selectCallback,
        notificationsCount,
    } = props;

    const renderCustomDescriptionComponent = ({ children }) => {
        return <div className="landing-notification-description-component mb-2">{children}</div>
    }

    const renderCustomDescriptionImageComponent = ({ node }) => {
        return <img className="img-fluid w-25 mb-2" src={node?.url}/>
    }

    // Format rich text components in description
    const getDescriptionSerializer = () => {
        const components = {};
        // Set elements to modify
        const textElements = [
          'heading1',
          'heading2',
          'heading3',
          'heading4',
          'heading5',
          'heading6',
          'paragraph'
        ];
        textElements.forEach((element) => {
          components[element] = renderCustomDescriptionComponent;
        })
        components.image = renderCustomDescriptionImageComponent;

        return components
    }

    const renderCustomTitleComponent = ({ children }) => {
        return <div className="landing-notification-title">{children}</div>
    }


    // Format rich text components in title
    const getTitleSerializer = () => {
        const components = {};
        // Set elements to modify
        const textElements = [
          'heading1',
          'heading2',
          'heading3',
          'heading4',
          'heading5',
          'heading6',
          'paragraph'
        ];
        textElements.forEach((element) => {
          components[element] = renderCustomTitleComponent;
        })
        components.image = renderCustomDescriptionImageComponent;

        return components
    }


    // Function to render notification's title
    const renderTitle = () => {
        const components = getTitleSerializer()
        return (
            <PrismicRichText field={title} components={components}/>
        )
    }

    // Function to render notification's description
    const renderDescription = () => {
        const components = getDescriptionSerializer()
        return (
            <div className="landing-notification-description-container">
                <div className="description-rich-text">
                    <PrismicRichText field={description} components={components}/>
                </div>
            </div>
        )
    }

    // Function to render notification navigation dots
    const renderNavigationDots = () => {
        return (
            <NavigationDots
                count={notificationsCount}
                currentIndex={index}
                selectCallback={selectCallback}
            />
        )
    }

    // Function to render external link
    const renderExternalLink = () => {
        if(!externalLink?.url) return null;
        return (
            <ExternalLink
                label="See what's new"
                link={externalLink?.url}
                baseClassName="link-light py-2 px-3 underline-link"
                extraClassName="btn-small"
            />
        )
    }

    const renderNavigationButtons = () => {
        // Only needed if there's more than 1 notification
        if(notificationsCount < 2) return null;
        return (
            <NavigationButtonsByIndex
                currentIndex={index}
                selectCallback={selectCallback}
                total={notificationsCount}
            />
        )

    }

    // Function to render links and navigation buttons
    const renderButtons = () => {
        return (
            <div className="landing-notification-buttons d-flex ml-auto">
                {renderExternalLink()}
                {renderNavigationButtons()}
            </div>
        )
    }

    // Function to render links and navigation buttons
    const renderNavigationAndLinks = () => {
        return (
            <div className="mt-auto d-inline-flex align-items-center ">
                {renderNavigationDots()}
                {renderButtons()}
            </div>
        )
    }
    return (
        <div className='landing-notification-info'>
            {renderTitle()}
            {renderDescription()}
            {renderNavigationAndLinks()}
        </div>
    );
};

export default LandingNotificationInfoSection;
