import React from "react";
import {
  connectionQuickActionsConfig,
} from "../../constants/common";
import ContextMenu from "../context-menu/ContextMenu";
import ContextMenuOption from "../context-menu/ContextMenuOption";
import { isValidFunction } from "../../utils/helpers";

const ConnectionsTableContextMenu = (props) => {
  const { parentRef, onRightClick, onActionCallback, scrollableRef } = props;

  // on select callback
  const onSelectCallback = (element) => {
    onRightClick(element);
  };

  // handle action callback
  const handleActionCallback = (actionName) => {
    if (isValidFunction(onActionCallback)) {
      onActionCallback(actionName);
    }
  };
  const getLabel = (label, iconClassName) => {
    return (
      <>
        <i className={`${iconClassName} mr-2`} />
        {label}
      </>
    );
  };

  // Render menu options
  const renderOptions = () => {
    return Object.values(connectionQuickActionsConfig).map((action, index) => {
      const { label, actionName, iconClassName } = action;
      return (
        <div key={`menu-option-${index}`}>
          <ContextMenuOption
            label={getLabel(label, iconClassName)}
            callback={() => handleActionCallback(actionName)}
          />
        </div>
      );
    });
  };

  return (
    <ContextMenu
      parentRef={parentRef}
      elementType="tr"
      onElementSelectCallback={onSelectCallback}
      minMenuWidth={150}
      minMenuHeight={60}
      applyOffset={true}
      yOffset={25}
      scrollableRef={scrollableRef}
    >
      {renderOptions()}
    </ContextMenu>
  );
};

export default ConnectionsTableContextMenu;
