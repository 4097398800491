import React from 'react';
import { Button } from 'reactstrap';

const FilterButton = (props) => {
    const {
        currentValue,
        activeValue,
        setValueCallback,
        customStyle,
        className="",
        label
    } = props;

    const onFilterClick = () => {
        if (currentValue) {
            setValueCallback(null)
        } else {
            setValueCallback(activeValue)
        }
    }
    
    return (
        <Button
            color="light-gray"
            className={`btn-filter ${className}`}
            style={customStyle}
            active={currentValue}
            outline
            onClick={onFilterClick}
        >
            {label}
        </Button>
    )
}

export default FilterButton;