import _ from 'lodash';
import { createSelector } from 'reselect';

const getConfig = state => state.businessRisk.config;
const getStatementResponses = state => state.businessRisk.riskStatementsResponses;

const getPickupStatement = (statements, firstUnansweredStatement) => {
    if(!firstUnansweredStatement) {
        //If there are no unanswered statements then it starts at the first one
        return statements[0];
    } else {
        return firstUnansweredStatement;
    }
}

const getResultingRiskScore = (initial_risk_score, resulting_risk_score, risk_treatment) => {
    switch(risk_treatment?.label) {
        case "MITIGATE":
            return {
                position: 2,
                label: "LOW"
            }
        case "AVOID":
            return {
                position: null,
                label: 'N/A'
            }
        case "TRANSFER":
        case "ACCEPT":
            return initial_risk_score
        default:
            return resulting_risk_score
    }

}

const makeRiskStatmentsResponseState = () => {
    return createSelector( [ getConfig, getStatementResponses ],
        (config, responses) => {
            if(!config?.statements) return null;
            let firstUnansweredStatement = null;
            let answeredStatementsCount = 0;

            const formattedStatements =  _.orderBy(config.statements,'order').map((statement, index) => {
                const response = responses?.find(({ risk_statement_id }) => risk_statement_id === statement.id)
                const likelihood = config?.likelihood?.find(({ id }) => id === response?.likelihood_id);
                const severity = config?.severity?.find(({ id }) => id === response?.severity_id);
                const risk_treatment = config?.risk_treatment?.find(({ id }) => id === response?.risk_treatment_id);
                //const resulting_risk_score = getResultingRiskScore(response?.initial_risk_score, response?.resulting_risk_score, risk_treatment)
                const residual_risk_score = response?.residual_risk_score;
                const answered = !_.isNil(response?.residual_risk_score) //|| risk_treatment?.label === "AVOID";
                const formattedStatement = {
                    index,
                    ...statement,
                    solution: risk_treatment?.label === "AVOID" ? 'N/A' : statement?.solution,
                    likelihood,
                    severity,
                    risk_treatment,
                    initial_risk_score: response?.initial_risk_score,
                    residual_risk_score: response?.residual_risk_score,
                    comment: response?.comment,
                    answered,
                    has_same_position: response?.initial_risk_score?.position === response?.residual_risk_score?.position
                };

                if(!firstUnansweredStatement && !answered) {
                    firstUnansweredStatement = formattedStatement;
                }

                if(answered || response?.initial_risk_score) {
                    answeredStatementsCount++;
                }

                return formattedStatement;
            })

            return {
                pickupStatement: responses ? getPickupStatement(formattedStatements, firstUnansweredStatement) : null,//Only set if responses are loaded
                statements: formattedStatements,
                hasProgress: answeredStatementsCount > 0,
                //isFinished: answeredStatementsCount === config?.statements?.length
            }
        }
    )
}
export default makeRiskStatmentsResponseState;
