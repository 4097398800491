import React, { useEffect, useState } from 'react';
import FrequencyDropdown from './FrequencyDropdown';
import IntervalInput from './IntervalInput';
import CustomDatepicker from '../../calendar/CustomDatepicker';
import CustomizedCheckbox from '../../layout/CustomizedCheckbox';
import { mapRepeatToState, mapRepeatStateToRRule } from '../../../utils/automationHelper';
import { repeatComponentTypes } from '../../../constants/automation';

const RepeatEdit = (props) => {
  //  Parsing props
  const { schedule, repeat, changeRepeat } = props;

  //  Component state
  const [repeatState, setRepeatState] = useState(mapRepeatToState(repeat));
  const [enableUntil, setEnableUntil] = useState(!repeatState?.until)

  //  Watching enable until changes
  useEffect(() => {
    if (!enableUntil || !repeatState?.until) { return; }
    setRepeatState({ ...repeatState, until: null });
  }, [enableUntil]);

  //  Watching repeat state changes
  useEffect(() => {
    //  Creating rrule to set
    const rrule = mapRepeatStateToRRule(repeatState);
    changeRepeat(rrule);
  }, [repeatState]);

  //  Watching schedule changes
  useEffect(() => {
    const minDate = schedule ? new Date(schedule) : new Date();
    if (!repeatState?.until) { return; }
    //  If the min date es greater than until, reset value
    const untilUnix = repeatState.until.getTime();
    const minDateUnix = minDate.getTime();
    if (minDateUnix >= untilUnix) { setRepeatState({ ...repeatState, until: null }) }
  }, [schedule]);

  //  Function to handle state changes
  const handleStateChange = (entry, value) => {
    setRepeatState({ ...repeatState, [entry]: value });
  }

  //  Function to render component
  const renderComponent = (type) => {
    if (type === repeatComponentTypes.REPEAT) {
      return (
        <FrequencyDropdown
          freq={repeatState?.freq}
          changeFreq={(value) => handleStateChange('freq', value)}
        />
      );
    }

    if (type === repeatComponentTypes.INTERVAL) {
      return (
        <IntervalInput
          freq={repeatState?.freq}
          interval={repeatState?.interval}
          changeInterval={(value) => handleStateChange('interval', value)}
        />
      );
    }

    if (type === repeatComponentTypes.UNTIL) {
      return (
        <CustomDatepicker
          disabled={enableUntil}
          value={repeatState?.until}
          minDate={schedule ? new Date(schedule) : new Date()}
          setValue={(value) => handleStateChange('until', value)}
        />
      );
    }
    return null;
  };

  //  Function to render label component container
  const renderLabelComponentContainer = (label, size, lefMargined, type) => {
    let className = 'label-component-container';
    className += ` ${size}-container`;
    if (lefMargined) { className += ' regular-left-margin'; }

    return (
      <div className={className}>
        <div className="label">{label}</div>
        {renderComponent(type)}
      </div>
    );
  };

  //  Function to render checkbox row
  const renderCheckboxRow = () => {
    return (
      <div className="checkbox-row until-checkbox">
        <CustomizedCheckbox
          checked={enableUntil}
          onChangeCallback={setEnableUntil}
        />
        <div className="checkbox-label">Doesn't End</div>
      </div>
    );
  }

  //  Rendering
  return (
    <>
      <div className="edit-repeat-row">
        {renderLabelComponentContainer('Repeats', 'medium', false, repeatComponentTypes.REPEAT)}
        {renderLabelComponentContainer('Repeat Every', 'medium', true, repeatComponentTypes.INTERVAL)}
        {renderLabelComponentContainer('End', 'medium', true, repeatComponentTypes.UNTIL)}
      </div>
      {renderCheckboxRow()}
    </>
  );
};

export default RepeatEdit;
