import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import GrcIssuesDetails from '../GrcIssuesDetails';
import { issueTypes } from '../../../constants/issues';
import { getSafeArray } from '../../../utils/helpers';
import issuesSimpleOptionsSelector from '../../../selectors/IssuesSimpleOptionsSelector';
import IssuesSubtaskButton from '../../issue-detail/IssuesSubtaskButton';

const GrcSubTasks = (props) => {
  //  Parsing props
  const {
    parent_issue,
    subTasks = [],
  } = props;

  //  Watching redux store
  const statuses = useSelector((state) => issuesSimpleOptionsSelector(state, issueTypes.GRC, 'statuses', true));

  //  Component state
  const [showSubtask, setShowSubtask] = useState(false);
  const [subTaskCode, setSubTaskCode] = useState(null);

  //  Function to toggle modal
  const toggleModal = () => {
    setShowSubtask(!showSubtask);
  };

  //  Function to close modal
  const closeModal = () => {
    setShowSubtask(false);
    setSubTaskCode(null);
  };

  //  Function to render subtasks
  const renderSubtasks = () => {
    const safeSubtasks = getSafeArray(subTasks);
    return safeSubtasks.map((subtask) => {
      const {
        code,
        name,
        status,
        assigned_user,
        severity,
        created_at
      } = subtask;

      return (
        <IssuesSubtaskButton
          code={code}
          name={name}
          createdAt={created_at}
          status={status}
          criticality={severity}
          assignee={assigned_user}
          callback={() => setSubTaskCode(code)}
          parentIsTask={true}
        />
      );
    });
  };

  //  Function to render add button
  const renderAddSubTask = () => {
    return (
      <div
        className="grc-subtask-add-container"
        onClick={() => toggleModal()}
      >
        <i className="grc-subtask-add-icon bx bx-plus-medical" />
      </div>
    );
  };

  //  Function to render subtask modal
  const renderSubtaskModal = () => {
    if (!showSubtask && !subTaskCode) { return null; }
    return (
      <GrcIssuesDetails
        code={subTaskCode}
        parent_issue={parent_issue}
        closeSubTaskModal={() => closeModal()}
        showNavigationButtons={false}
      />
    );
  };

  //  Rendering
  return (
    <>
      <div className="grc-subtasks-container">
        <div className="grc-subtasks-title">Subtasks</div>
        <div className="grc-subtasks-list">
          {renderSubtasks()}
        </div>
        {renderAddSubTask()}
      </div>
      {renderSubtaskModal()}
    </>
  );
};

export default GrcSubTasks;
