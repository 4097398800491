import React from 'react';
import { GroupAPI } from '../../../../api/services/GroupAPI';
import { issuesGroupMemberTableTemplate } from '../../../../constants/app-v4/issuesGroupsTableTemplate';
import GroupDetailsTable from './GroupDetailsTable';
import EmptyBoxIcon from '../../../../assets/images/empty-state-box.svg';

const GroupMembers = (props) => {

  const {
    group = {}
  } = props;

  //  Function to get affected members of the group
  const getIssueGroupMembers = async (filters) => {
    const sort = filters?.sort?.length > 0 ? Object.fromEntries([filters?.sort]) : null;
    const payload = {
      group_id: group?.id,
      text_to_search: filters?.searchTerm,
      offset: filters?.offset,
      limit: filters?.limit,
      sort: sort,
    }
    return await GroupAPI.handlers.getIssuesGroupMembers(payload);
  };

  //  Function to renderTableTitle
  const renderTableTitle = () => {
    return (
      <div className="issues-group-table-title">
        Members affected
      </div>
    );
  };

  return (
    <GroupDetailsTable
      tableClassName="issues-group-table group-members-table"
      headerClassName="issues-group-table-header"
      rowClassName="issues-group-table-row"
      columnsTemplate={issuesGroupMemberTableTemplate}
      renderTitle={renderTableTitle}
      searchbarClassName={"issues-group-searchbar"}
      searchbarPlaceholder="Search member"
      apiFunction={getIssueGroupMembers}
      apiKey={GroupAPI.keys.getIssuesGroupMembers}
      extraKeys={[group?.id]}
      emptyStateConfig={{
        title: "Hey, nothing came up",
        message: "Your search had no results, please refine your search and try again",
        titleClassName: "issues-group-empty-title",
        messageClassName: "issues-group-empty-message",
        customImage: EmptyBoxIcon
      }}
    />
  )
}

export default GroupMembers
