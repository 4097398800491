import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { alertTypes } from '../../constants/alerts';
import {  tagsTitleSize } from '../../constants/tags';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useValueDebounce from '../../hooks/useValueDebounce';
import { filterOutAddedTags } from '../../utils/tagsHelper';
import useTagsQuery from '../../hooks/useTagsQuery';
import useCanAbility from '../../hooks/useCanAbility';
import { LIST_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';

const TagsBulkAddComponent = (props) => {
    const {
        callback,
        issueId,
        deviceId,
        placeholder = "Add Tag",
        companyId,
        menuPosition,
        addedTags = []
    } = props;

    // Component state
    const [updatedTags, setUpdatedTags] = useState([]);
    const [tagsOptions, setTagOptions] = useState([])
    const [selectedTag, setSelectedTag] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [maxlength] = useState(tagsTitleSize)
    const [alert, setAlert] = useState(false)

    // Component Hooks
    const { dispatch } = useMemoizedDispatch();
    const { debouncedValue: textToSearch } = useValueDebounce({
        debounceTime: 400,
        defaultValue: null,
        valueToDebounce: searchText?.trim(),
    });
    const ability = useCanAbility();

    //  Getting tags list
    const {
        tags,
        error: tagsListError,
        loading,
        getNextPage,
        clearQueryData,
    } = useTagsQuery({
        company_id: companyId,
        device_id: deviceId,
        issue_id: issueId,
        textToSearch,
        isCydekick: ability.can(LIST_ACTION, PRIVILEGE_SOURCES.MEMBERS.GENERAL),
    });

    // Handle clear clicked
    const clearState = () => {
        setSelectedTag(null);
        setSearchText('');
        clearQueryData();
    };

    //  Watching tags error
    useEffect(() => {
        if (!tagsListError) { return; }
        dispatch(setAlert('Error selecting tag', alertTypes.error));
        setSelectedTag(null);
        clearQueryData();
    }, [tagsListError]);

    // Watch tags and searchText changes
    useEffect(() => {
        const isAdded = updatedTags.find(tag => tag.label === textToSearch?.toLowerCase())
        const isSeachTermEmpty = textToSearch === null || textToSearch === '';
        setTagOptions([
            ...!isSeachTermEmpty && !isAdded && !loading ? [{ value: null, label: textToSearch?.toLowerCase()?.trim(), isNew: true }] : [],
            ...updatedTags
        ])
    }, [updatedTags, textToSearch])

    // Watch changes to tags, and addedTags to add is_filtered flag
    useEffect(() => {
        const newTags = [...addedTags?.filter(tag => tag?.recent), ...tags]
        const filtered = filterOutAddedTags(newTags, addedTags)
        setUpdatedTags(filtered)
    }, [addedTags, tags])

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <i className="fa fa-search" aria-hidden="true" />
            </components.DropdownIndicator>
        );
    };
    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: "#241563",
            borderColor: alert ? "red" : "#241563",
            borderRadius: "#241563",
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                borderColor: "#241563"
            }
        }),
        option: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: data?.is_filtered ? '#361f93!important' : 'transparent',
                color: data?.is_filtered ? '#fff' : '#a6b0cf'
            };
        },
        menu: base => ({
            ...base,
            borderRadius: 0,
            marginTop: 0,
            Color: '#352B6D',
            zIndex: 9999
        }),
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menuList: base => ({
            ...base,
            padding: 0,
            background: "#241563",
        }),
        valueContainer: base => ({
            ...base,
            paddingLeft: 35
        }),
    };

    const shouldSetMaxLengthAlert = (element) => {
        if (element && element.length >= maxlength) {
            setAlert(true);
            setTimeout(() => {
                setAlert(false)
            }, 5000);
            return true
        }
        return false
    }

    const renderErrorMessage = () => {
        return alert && (
            <p style={{ color: "red", fontSize: '14px' }}>{`Tags can’t have more than ${tagsTitleSize} characters`}</p>
        )
    }

    const renderPlusIcon = () => {
        return (
            <i className="plus-circle-icon bx bx-plus-circle" />
        )
    }

    //  Function to handle change on select
    const handleDropdownChange = (tag) => {
        if (tag?.is_filtered) return;
        callback(tag)
        clearState();
    };

    //  Funciton to handle chanes on input (search text case)
    const handleInputChange = (searchTerm) => {
        if (shouldSetMaxLengthAlert(searchTerm?.trim())) return;
        setSearchText(searchTerm);
    };

    //  Function to handle on scroll bottom
    const onScrollBottom = () => {
        getNextPage();
    };

    return (
        <div className="search-box" >
            <div
                className="business-context-tags-dropdown-container"
            >
                {renderPlusIcon()}
                <Select
                    inputValue={searchText}
                    value={selectedTag}
                    options={tagsOptions}
                    onChange={handleDropdownChange}
                    onInputChange={handleInputChange}
                    onMenuScrollToBottom={onScrollBottom}
                    isLoading={loading}
                    isSearchable={true}
                    menuPosition={menuPosition}
                    components={{ DropdownIndicator }}
                    placeholder={placeholder}
                    noOptionsMessage={() => null}
                    styles={customStyles}
                    className="react-select-container business-context-tags-dropdown add-tag-component"
                    classNamePrefix="react-select"
                    formatOptionLabel={({ label, isNew }) => {
                        if (!isNew) { return label; }
                        return `${label} (New)`
                    }}
                />
            </div>
            {renderErrorMessage()}
        </div>
    )
}
export default TagsBulkAddComponent;
