import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SettingOption from "./SettingOption";

const PersonalInfo = (props) => {
  const {
    mfaConfig
  } = props;

  return (
    <div className="settings">
      <div className="title">
        Account access and Security
        <p className="title-description"> Settings to help you keep your account secure.</p>
      </div>
      <SettingOption
        title="Change password"
        description="Choose a unique password to protect your account."
        action="Password     ********"
        route="/change-password"
        amplitudeEvent="My Account View - Security Settings - Change Password"
      />
      <SettingOption
        title="Multi-factor authentication"
        description="Multi-factor authentication"
        action="Two-step verification"
        route={!mfaConfig?.mfa_type ? "/mfa-config" : "/mfa-sms"}
        showSwitch={mfaConfig?.mfa_type !== 0}
        switchValue={mfaConfig?.mfa_type === 1 || mfaConfig?.mfa_type === 2}
        amplitudeEvent="My Account View - Security Settings - MFA-Change"
      />
    </div>
  );
}


const makeMapStateToProps = () => {
  const mapStateToProps = (state) => {
    const { mfaConfig } = state.auth;
    return {
      mfaConfig
    }
  }
  return mapStateToProps;
}

const mapDispatchToProps = {};

export default withRouter(connect(makeMapStateToProps, mapDispatchToProps)(PersonalInfo));

