import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Header from "./headers/Header"
import Sidebar from "./Sidebar";
import LoadingPage from "../LoadingPage";
import { changeSidebarType, changePreloader } from "../../../actions/layout";
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import SecondaryNavbar from "../navigation/SecondaryNavbar";


const Layout = (props) => {
  //  Parsing props
  const { children } = props;

  //  Getting location from router
  const location = useLocation();

  //  Memoized dispatch
  const { dispatch } = useMemoizedDispatch();

  //  Watching redux state
  const show = useSelector((state) => state.layout.preloader?.show);
  const text = useSelector((state) => state.layout.preloader?.text);
  const autoHide = useSelector((state) => state.layout.preloader?.autoHide);
  const leftSideBarType = useSelector((state) => state.layout.leftSideBarType);
  const layoutType = useSelector((state) => state.layout.layoutType);
  const user = useSelector((state) => state.auth.user);

  const [isMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));

  useEffect(() => {
    if (!leftSideBarType) { return; }
    if (layoutType === 'horizontal') {
      dispatch(changeSidebarType('default'));
    } else {
      dispatch(changeSidebarType(leftSideBarType));
    }
  }, [changeSidebarType, leftSideBarType, layoutType]);

  useEffect(() => {
    toggleMenuCallback();
  }, []);

  useEffect(() => {
    const displayType = show ? 'block' : 'none';
    document.getElementById('preloader').style.display = displayType;
    if (!show || !autoHide) { return; }
    setTimeout(() => {
      document.getElementById('preloader').style.display = "none";
      dispatch(changePreloader(false, ""));
    }, 2500);
  }, [show, changePreloader, autoHide]);

  const toggleMenuCallback = () => {
    if (leftSideBarType !== 'default' && leftSideBarType !== 'condensed') { return; }
    const type = leftSideBarType === 'default' ? 'condensed' : 'default';
    dispatch(changeSidebarType(type, isMobile));
  };

  //  TODO: change this logic
  const isMemberRegisterPath = /^\/cydekicks\/join\//.test(location.pathname);
  const isLoginPath = /^\/login$/.test(location.pathname);
  const isFreemiumLogin = /^\/freemium$/.test(location.pathname);
  const isResetPasswordPath = /^\/reset-password$/.test(location.pathname);
  const isExportPath = /^(?:\/export\b)(?:\/[\w\-]+){2}$/.test(location.pathname);
  const isBadgePath = /^\/badge(?:\/[\w\-]+)$/.test(location.pathname);
  const isAttachmentsPath = location?.pathname?.includes('attachments');
  const isRegisterSuccessPath = location.pathname === '/register-success';
  const isMemberInvitePath = location?.pathname?.includes('/member/join');
  const isVerificationPath = location?.pathname?.includes('/member/verification') || location?.pathname?.includes('/member/authorization') || location?.pathname?.includes('/verification-success');
  const isExternalUserVerificationPath = location?.pathname?.includes('/member/new-password')

  const showHeader = (!isLoginPath && !isMemberRegisterPath && !isResetPasswordPath && !isFreemiumLogin && !isExportPath && !isBadgePath && !isRegisterSuccessPath && !isMemberInvitePath && !isVerificationPath && !isAttachmentsPath && !isExternalUserVerificationPath);
  
  const showFixedHeader = location.pathname !== "/welcome";
  const fullBackground = (showHeader && !showFixedHeader) || !showHeader;
  const hasGradientBackGround = !isExportPath && !isBadgePath && !isExternalUserVerificationPath;

  const freemiumBackgroundPaths = [
    '/register-success',
    '/verification-success',
  ];

  //  Function to get background
  const getBackgroundClassName = () => {
    let bgClassName = '';
    if (layoutType === 'vertical') { bgClassName += 'main-content min-vh-100 '; }
    bgClassName += 'bg-dark ';
    if (isBadgePath) {
      bgClassName += 'bg-violet min-vh-100';
      return bgClassName;
    }
    if (freemiumBackgroundPaths.includes(location?.pathname)) {
      bgClassName += 'light-purple-background main-scrollable-container';
      return bgClassName;
    }
    if (fullBackground && hasGradientBackGround) { bgClassName += 'purple-gradient-img-bg main-scrollable-container'; }
    return bgClassName;
  }

  const renderSecondaryNavbar = () => {
    return (
      <SecondaryNavbar
        label={''}
      />
    )
  }

  //  Rendering
  return (
    <React.Fragment>
      <div id="preloader">
        <LoadingPage
          text={text}
          customClassName="bg-assessment"
        />
      </div>
      <div id="layout-wrapper">
        {showHeader && (
          <Header
            toggleMenuCallback={toggleMenuCallback}
            layoutType={layoutType}
            showFixedHeader={showFixedHeader}
          />
        )}
        {layoutType === 'vertical' && (
          <Sidebar
            type={leftSideBarType}
            isMobile={isMobile}
          />
        )}
        <div
          style={{ paddingTop: fullBackground ? "0px" : "70px" }}
          className={getBackgroundClassName()}
          tabIndex="0"
          aria-label={`Cyvatar ${user?.name} ${location?.pathname?.substring(1)}.`}
          role="main"
        >
          {!fullBackground && renderSecondaryNavbar()}
          <div>
            {children}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
