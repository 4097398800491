import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import AppLauncherWidget from "../dashboard/AppLauncherWidget";
import _ from "lodash";
import { trackEvent } from "../../../../utils/gaTracker";
import useMemoizedDispatch from "../../../../hooks/useMemoizedDispatch";
import { createExecutionAnswers } from "../../../../actions/answers";

import API from '../../../../api';
import { useSelector } from 'react-redux';
import companyIdSelector from '../../../../selectors/companyIdSelector';
import useCustomQuery from '../../../../hooks/useCustomQuery';

const AppLauncherAssessmentWidget = (props) => {

  //  Initializing APIs
  const { DashboardAPI } = API;

  // Getting needed info from redux store
  const companyId = useSelector(state => companyIdSelector(state));

  //  Calling API for compliance status
  const {
      data: complianceResponse,
      error: complianceError,
      isLoading: complianceLoading,
      refetch: refetchComplianceStatus,
  } = useCustomQuery(
      [DashboardAPI.keys.getComplianceStatus, { company_id: companyId }],
      ({ queryKey }) => DashboardAPI.handlers.getComplianceStatus(queryKey[1]),
  );

  const {
    buttonMessage = "",
    widgetData,
    icon,
    linkPath = "",
    comingSoon = false,
  } = props;

  // Component state
  const [progress, setProgress] = useState(0);

  // Component Hooks
  const history = useHistory();
  const { dispatch } = useMemoizedDispatch();

  // Watching answers count and question count changes
  useEffect(() => {
    if (!complianceResponse?.assessment) return;
    const { cis_answers_count, cis_question_count } = complianceResponse?.assessment;
    const assessmentProgress = Math.floor(
      (cis_answers_count / cis_question_count) * 100
    );
    setProgress(assessmentProgress);
  }, [complianceResponse?.assessment?.cis_question_count, complianceResponse?.assessment?.cis_answers_count]);

  const hasProgress = progress !== 0;
  const isFinished = progress === 100;
  const linkLabel = isFinished
    ? "Retake my assessment"
    : hasProgress
    ? "Continue my assessment"
    : "Start my assessment";

  // Render Assessment Link
  const renderAssessmentLink = (label, hasProgress) => {
    const eventName = hasProgress ? "continue_assessment" : "start_assessment";
    const sectionEvent = "assessment";
    return (
      <Button
        type="button"
        color="light"
        className="mb-0 float-right applauncher-btn"
        onClick={() => {
          if (!_.isNil(complianceResponse?.assessment?.cyber_checkup_execution_id)) {
            trackEvent(eventName, sectionEvent, eventName);
            history.push("/dashboard/assessment");
          } else {
            trackEvent(eventName, sectionEvent, eventName);
            // TODO: Check if this is still needed
            dispatch(
              createExecutionAnswers(complianceResponse?.assessment?.cis_assessment_id, history)
            );
          }
        }}
      >
        {label}
      </Button>
    );
  };

  const buttonComponent = renderAssessmentLink(linkLabel, hasProgress);

  return (
    <>
      <AppLauncherWidget
        buttonMessage={buttonMessage}
        widgetData={widgetData}
        icon={icon}
        linkPath={linkPath}
        comingSoon={comingSoon}
        buttonComponent={buttonComponent}
        error={complianceError}
        loading={complianceLoading}
        refetch={refetchComplianceStatus}
      />
    </>
  );
};

export default AppLauncherAssessmentWidget;
