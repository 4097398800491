export default {
    // TODO: Add partner names
    ARMIS: "ARMIS",
    FURTIM: "Furtim",
    TENABLE: "Tenable",
    FREEMIUM: "Freemium",
    TENABLE_API: "TenableApi",
    TENABLE_FREEMIUM: "TenableFreemium",
    AUTOMOX: "Automox",
    CYSO: "CySO",
    SENTINEL_ONE: "SentinelOne",
    OBSIDIAN: 'Obsidian'
};
