import React from 'react';
import { Button, Modal } from 'reactstrap';
import { isValidFunction } from '../../utils/helpers';
import DismissButton from './buttons/DismissButton';

//  Component
const ConfirmActionModal = (props) => {
    const {
        title,
        subtitle,
        confirmLabel,
        cancelLabel="Cancel",
        confirmCallback,
        cancelCallback,
        disableConfirm,
        confirmButtonColor="critical",
        children
    } = props;


    // Function to call when canceling action
    const onCancelClick = () => {
        const isValid = isValidFunction(cancelCallback);
        if(isValid) {
            cancelCallback()
         }
    };

    // Render dismisss button
    const renderDismissButton = () => {
        return (
            <DismissButton
                callback={onCancelClick}
                className="btn-small"
            />
        )

    }

    // Render title and dismiss button
    const renderHeaderSection = () => {
        return (
            <div className="confirm-modal-header d-flex">
                <div className="modal-title">{title}</div>
                {renderDismissButton()}
            </div>
        )
    }

    // Render modal's subtitle
    const renderSubtitle = () => {
        if(!subtitle) return null;
        return (
            <div className="modal-subtitle">
                {subtitle}
            </div>
        )
    }

    // Render cancel button to close modal
    const renderCancelButton = () => {
        return (
            <Button
                color="light-gray"
                outline
                onClick={onCancelClick}
                className="btn-small"
            >
                {cancelLabel}
            </Button>
        )
    }

    // Function to call when confirming action
    const onConfirmClick = () => {
        const isValid = isValidFunction(confirmCallback);
        if(isValid) {
            confirmCallback()
        }
    }

    const renderConfirmButton = () => {
        return (
            <Button
                color={confirmButtonColor}
                disabled={disableConfirm}
                onClick={onConfirmClick}
                className="btn-small ml-2"
            >
                {confirmLabel}
            </Button>
        )
    }


    //  Rendering
    return (
        <Modal
            isOpen={true}
            toggle={onCancelClick}
            className="confirm-action-modal"
            centered={true}
            size="xl"
        >
            {renderHeaderSection()}
            {renderSubtitle()}
            {children}
            <div className="buttons-container d-flex justify-content-end">
                {renderCancelButton()}
                {renderConfirmButton()}
            </div>
        </Modal>
    );
};

export default ConfirmActionModal;
