import React from 'react';
import {
  cyvalueFilters,
  filterTypes,
} from '../../../constants/cyValueData';
import {
  moveDateByType,
  getRangeByFilterType
} from '../../../utils/timeChartHelpers';

const CyValueDateFilters = (props) => {
  //  Parsing props
  const {
    filters,
    setFilters,
    hasOverallScore,
    hasNewSolution,
    hasZeroScore,
    isProjectionRendered = false,
    projectionDirection,
  } = props;
  const { start_date, end_date, filter_type = filterTypes.week } = filters;

  //  Function to change filters and ranges
  const onFilterClicked = (type) => {
    const range = getRangeByFilterType(type, new Date());
    setFilters({ ...filters, filter_type: type, ...range }, true);
  };

  //  Function for direction clicked
  const onDirectionClicked = (direction) => {
    const newStartDate = moveDateByType(start_date, 1, filter_type, direction);
    const newEndDate = moveDateByType(end_date, 1, filter_type, direction);
    setFilters({ ...filters, start_date: newStartDate, end_date: newEndDate }, false);
  };

  //  Function to render filters
  const renderFilters = () => {
    return cyvalueFilters.map((filter, index) => {
      const { type, label } = filter;
      return (
        <div
          key={index}
          className={`cyvalue-filter${type === filter_type ? ' focused' : ''}`}
          onClick={() => onFilterClicked(type)}
        >
          {label}
        </div>
      );
    });
  };

  //  Function to render reset date range filter
  const renderReset = () => {
    return (
      <div
        className={`bx bx-reset cyvalue-reset-range`}
        onClick={() => onFilterClicked(filter_type)}
      />
    );

  }

  //  Function to render arrows
  const renderArrow = (direction) => {
    const className = `bx bxs-${direction}-arrow-circle align-middle cyvalue-arrow`
    return (
      <div
        className={className}
        onClick={() => onDirectionClicked(direction)}
      />
    );
  };

  //  Function to renderLabel
  const renderLabel = (direction = 'right') => {
    //if (isProjectionRendered || projectionDirection !== direction) { return null; }
    return (
      <div
        className="projection-labels-container"
        style={{ marginRight: projectionDirection === 'left' ? '10px' : '0%' }}
      >
        <div
          className="projection-label"
          style={{ textAlign: direction === 'left' ? 'right' : 'left' }}
        >
          {(isProjectionRendered || projectionDirection !== direction) ? null : `Navigate ${direction} to see projection`}
        </div>
      </div>
    )
  };

  if (!hasOverallScore && !hasNewSolution && !hasZeroScore) { return null; }

  //  Rendering
  return (
    <div className="cyvalue-filters">
      {/* {renderLabel('left')}
      {renderArrow('left')} */}
      {renderFilters()}
      {/* {renderReset()}
      {renderArrow('right')}
      {renderLabel('right')} */}
    </div>
  );
};

export default CyValueDateFilters;
