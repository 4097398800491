export default {
    single: 'single',
    multiple: 'multiple',
    search: 'search',
    select: 'select',
    multipleWithOptions: 'multiple-with-options',
    deviceTypes: 'device-types',
    multipleObjectsType: 'multiple-objects-type',
};

export const filterOptions = {
    FILTER_BY: 'FILTER_BY',
    FILTER_OUT: 'FILTER_OUT',
}

export const timeFilters = {
    DAYS: 'DAYS',
    HOURS: 'HOURS',
    MINUTES: 'MINUTES'
}
