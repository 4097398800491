import React, { useState } from 'react'

const PasswordField = (props) => {
    const {
        input,
        placeholder,
        name,
        error,
        touched
    } = props;

    const [showPassword, setShowPassword] = useState(false);
    return (
        
        <div className="password-field">
            <button 
                className="btn btn-show btn-link"
                onClick={() => setShowPassword(!showPassword)}
                type="button"
            >
                {!showPassword ?  <i className='bx bx-hide' ></i> : <i className='bx bx-show' ></i>} 
            </button>
            <input
                {...input}
                type={!showPassword ?  "password" : "text"} 
                placeholder={placeholder}
                name={input?.name || 'password'}
                autoComplete="off"
                style={{marginBottom:"5px"}}
                className={`form-control ${touched && error ? "is-invalid": ''}`}
            />
            <div className="invalid-feedback">{error}</div>
        </div>
    )
}

export default PasswordField
