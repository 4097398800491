import React from 'react';
import IssueCodeCopy from './IssueCodeCopy';

const IssueCode = (props) => {
  //  Parsing props
  const {
    code,
    overdue = false,
  } = props;

  //  Function to render overdue state
  const renderOverdueState = () => {
    if (!overdue) { return null; }
    return (
      <div className="bx bx-time-five overdue-icon" />
    );
  };

  //  Rendering
  return (
    <div className="d-flex align-items-center grc-code-column">
      <div className="td-multiline-ellipsis" aria-label={`Task ${code}, Task ID`}>
        {code}
      </div>
      {renderOverdueState()}
      <IssueCodeCopy
        code={code}
        showCode={false}
        itemLabel="Task ID"
      />
    </div>
  );
};

export default IssueCode;
