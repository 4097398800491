import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Button } from 'reactstrap';
import RightAlignedFormContainer from '../../containers/RightAlignedFormContainer';
import FormCard from '../layout/FormCard';
import LoadingContent from '../layout/LoadingContent';
import CustomFormField from '../CustomForm/CustomFormField';
import API from '../../api';
import { setAlert } from '../../actions/alerts';
import { tokenInvitesLogos } from '../../constants/logosTemplates';
import { passwordFieldLoginTemplate } from '../../constants/formTemplates';
import { validateFieldsByFormTemplate } from '../../utils/validations';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useCustomQuery from '../../hooks/useCustomQuery';
import useCustomMutation from '../../hooks/useCustomMutation';

const MemberVerification = (props) => {
  //  Initializing API
  const { UserAPI, AuthAPI } = API;

  //  Component hooks
  const history = useHistory();
  const { token } = useParams();
  const { dispatch } = useMemoizedDispatch();
  const {
    data: tokenData,
    error: tokenError,
    isLoading: tokenLoading,
  } = useCustomQuery( // Query to verify if token is still active
    [UserAPI.keys.validateVerificationToken, token],
    ({ queryKey }) => UserAPI.handlers.validateVerificationToken(queryKey[1]),
    { retry: 0 },
  );
  const {
    data: verifyData,
    error: verifyError,
    isLoading: verifyLoading,
    mutate: verifyOrAuthorize,
  } = useCustomMutation( // Mutation to accept verification / authorization
    (params) => UserAPI.handlers.verifyOrAuthorizeAccount(params),
    UserAPI.invalidators.verifyOrAuthorizeAccount,
    { retry: 0 },
  );
  const {
    data: resendData,
    error: resendError,
    isLoading: resendLoading,
    mutate: resendVerification,
  } = useCustomMutation( // Mutation to resend token invite
    (params) => AuthAPI.handlers.resendVerification(params),
    AuthAPI.invalidators.resendVerification,
    { retry: 0 },
  );

  //  Watching token error
  useEffect(() => {
    if (!tokenError) { return; }
    const message = tokenError?.response?.data?.message || 'Verification token has expired.'
    dispatch(setAlert(message, 'danger'));
    history.push('/');
  }, [tokenError]);

  //  Watching verification error
  useEffect(() => {
    if (!verifyError) { return; }
    const message = verifyError?.response?.data?.errorMessage || 'Error verifying account.'
    dispatch(setAlert(message, 'danger'));
  }, [verifyError]);

  //  Watching resend error
  useEffect(() => {
    if (!resendError) { return; }
    const message = resendError?.response?.data?.errorMessage || 'Error resending verification.'
    dispatch(setAlert(message, 'danger'));
  }, [resendError]);

  //  Watching resend notification sucess
  useEffect(() => {
    if (!resendData) { return; }
    dispatch(setAlert('Verification sent successfully.', 'success'));
    //  Sending to register success view
    history.replace({
      pathname: '/register-success',
      state: {
        is_owner: tokenData?.is_verification,
        email: tokenData?.email,
        public_id: tokenData?.public_id,
      }
    });
  }, [resendData]);

  //  Watching verification success
  useEffect(() => {
    if (!verifyData) return;
    if (!verifyData?.external_source) {
      history.replace({
        pathname: '/verification-success',
        state: {
          is_owner: tokenData?.is_verification,
          token: tokenData?.invite_token,
        },
      });
    } else {
      history.replace({
        pathname: '/member/new-password',
        state: {
          token: tokenData?.invite_token,
        },
      });
    }
  }, [verifyData]);

  useEffect(() => {
    if (!tokenData || tokenData?.expired_token) return;
    const payload = {
      token: tokenData?.invite_token,
    }
    verifyOrAuthorize(payload);
  }, [tokenData]);

  //  Function to get component title
  const getTitle = () => {
    if (tokenLoading) { return ''; }
    if (tokenData?.is_verification) { return 'Account Verification'; }
    return 'Account Authorization';
  };

  //  Function to get call to action
  const getCTA = () => {
    if (verifyLoading) { return 'Processing'; }
    if (tokenData?.is_verification) { return 'Verify Account'; }
    return 'Authorize Account';
  };

  //  Function to handle resend verification
  const handleResendClick = () => {
    resendVerification(tokenData?.public_id);
  };

  //  Function to validate form
  const validateForm = (formValues) => {
    return validateFieldsByFormTemplate([passwordFieldLoginTemplate], formValues);
  };

  //  Function to handle submit
  const onSubmit = (formValues) => {
    const payload = {
      token: tokenData?.invite_token,
      password: formValues?.password,
    }
    verifyOrAuthorize(payload);
  };

  //  Function to render message
  const renderMessage = () => {
    //  Handling error message for expired token
    if (tokenData?.expired_token) {
      return (
        <div className="member-verification-email-container">
          <div className="member-email-label">Verification for</div>
          <div className="member-email-error">{tokenData?.email}</div>
          <div className="member-email-label">has expired!</div>
        </div>
      );
    }

    //  Handling message for active tokens
    if (tokenData?.is_verification) {
      return (
        <div className="member-verification-email-container">
          <div className="member-email-label">Email:</div>
          <div className="member-email">{tokenData?.email}</div>
        </div>
      );
    }

    return ;

    {/*
      return (
        <div className="member-verification-email-container">
          <div className="member-email-label">Do you allow</div>
          <div className="member-email-regular">{tokenData?.email}</div>
          <div className="member-email-label">to join your company?</div>
        </div>
      );
    */}
  };

  //  Function to render form
  const renderForm = (params) => {
    const { handleSubmit, invalid } = params;

    return (
      <form
        onSubmit={(event) => {
          if (invalid) { dispatch(setAlert('One or more fields have an error. Please check and try again', 'danger')); }
          handleSubmit(event);
        }}
        className="invited-member-form"
      >
        <CustomFormField fieldTemplate={passwordFieldLoginTemplate} />
        <div className="row-container">
          <Button
            className="btn btn-submit btn-light text-dark font-weight-bold verify-button"
            type="submit"
            disabled={invalid || verifyLoading}
          >
            {getCTA()}
          </Button>
        </div>
      </form>
    );
  };

  //  Function to render content
  const renderContent = () => {
    //  Rendering expired token button
    if (tokenData?.expired_token) {
      return (
        <div className="invited-member-form">
          <div className="row-container">
            <Button
              className="btn btn-submit btn-light text-dark font-weight-bold verify-button"
              onClick={() => handleResendClick()}
              disabled={!tokenData?.public_id || resendLoading}
            >
              Resend verification
            </Button>
          </div>
        </div>
      );
    }

    return;
    //  Rendering active token content
    {/*return (
      <Form
        onSubmit={onSubmit}
        render={renderForm}
        validate={validateForm}
        shouldValidate
      />
    );*/}
  };

  //  Rendering
  return (
    <RightAlignedFormContainer
      logos={tokenInvitesLogos}
      leftComponents={null}
    >
      <FormCard
        title={getTitle()}
        customClassName="member-verification-container"
      >
        <LoadingContent
          isLoading={tokenLoading || verifyLoading}
          loadingStyle={{ minHeight: "200px" }}
        >
          <div className="member-verification-content">
            {renderMessage()}
            {renderContent()}
          </div>
        </LoadingContent>
      </FormCard>
    </RightAlignedFormContainer>
  );
};

export default MemberVerification;
