import React, { useEffect } from 'react';
import { changeLayout } from '../../actions/layout';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';

const Dashboard = (props) => {
    //  Parsing props
    const { children, containerClassName } = props;

    //  Component hooks
    const { dispatch } = useMemoizedDispatch();

    useEffect(() => {
        dispatch(changeLayout('vertical'));
    }, [changeLayout]);

    return (
        <React.Fragment>
            <div className={containerClassName || "dashboard mx-2 px-0 py-4 text-normal-style"}>
                {children}
            </div>
        </React.Fragment>
    )
}

export default Dashboard
