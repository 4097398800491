import React, { useEffect } from "react";
import { changeLayout } from "../actions/layout";
import { onAutomationUnmount } from "../actions/automation";
import useMemoizedDispatch from "../hooks/useMemoizedDispatch";
import LogDetail from "../components/automation/Logs/LogDetail";
import SolutionLogs from "../components/solution-logs/SolutionLogs";
import SolutionLogDetail from "../components/solution-logs/SolutionLogDetail";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import PageNotFound from "../views/PageNotFound";

const SolutionLogsContainer = (props) => {
  //  Watching redux store
  const { path } = useRouteMatch();
  //  Component hooks
  const { dispatch } = useMemoizedDispatch();

  //  Component did mount
  useEffect(() => {
    dispatch(changeLayout("vertical"));
    //return () => dispatch(onAutomationUnmount());
  }, []);

  //  Function to render content
  const renderContent = () => {
    return (
      <Switch>
        <Route exact path={`${path}/:log_id`}>
          <SolutionLogDetail />
        </Route>
        <Route exact path={path}>
          <SolutionLogs />
        </Route>
        <Route component={PageNotFound} />
      </Switch>
    );
  };

  //  Rendering
  return <div className="automation-container">{renderContent()}</div>;
};

export default SolutionLogsContainer;
