import React from 'react';

const CircleProgressBar = ({ score = 0, maxScore = 10, grade = 'A', radius=19 }) => {
  const markerAngle = (score / maxScore) * 360 + 90;

  const calculateOffset = (score, maxScore) => {
    const max = Math.PI * (2 * radius);
    return ((maxScore - score) / maxScore) * max;
  };

  const calculateDashArray = (score, maxScore) => {
    return Math.PI * (2 * radius);
  }

  return (
    <svg viewBox="0 0 42 42" className='circular-progress-bar'>
      <circle className="circular-progress-base-ring" cx="21" cy="21" r={radius} />
      <circle
        className={`circular-progress-active-${grade.toLocaleLowerCase()}`}
        cx="21"
        cy="21"
        r={radius}
        strokeDasharray={calculateDashArray()}
        strokeDashoffset={calculateOffset(score, maxScore)}
        transform="rotate(90 21 21)"
      />
      <text className='risk-recon-rating-grade' x="21" y="18" dominantBaseline="middle">
        {grade.toUpperCase()}
      </text>
      <text className="risk-recon-rating-score" x="21" y="30" dominantBaseline="middle">
        {score} / {maxScore}
      </text>
      <g className="circular-progress-marker" transform={`translate(21, 21) rotate(${markerAngle})`}>
        <circle cx={radius} cy="0" r="1.65" />
      </g>
    </svg>
  );
};

export default CircleProgressBar;
