import React from 'react';
import TagsPreview from "../../components/tags/TagsPreview";
import { parseTaggingsList } from "../../utils/tagsHelper";
import { cellTypes, headerTypes } from "../tableTemplates";

//  Template for issues groups modal's member table
export const issuesGroupMemberTableTemplate = [
  {
    label: 'Member',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell small-cell',
    cellClassName: 'regular-cell small-cell ellipsed-cell',
    entry: 'name',
  },
  {
    label: 'Exposure',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: 'header-cell header-text-cell small-cell',
    cellClassName: 'regular-cell small-cell ellipsed-cell',
    entry: 'exposure',
    customRender: (member) => (<div className="selectable-text-cell">{`${member?.exposure} days`}</div>),
    customParams: { objectName: 'member', valueName: 'exposure' },
  },
  {
    label: 'Devices Affected',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: 'header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell ellipsed-cell',
    entry: 'device_count',
    customRender: (member) => (<div className="selectable-text-cell">{`${member?.device_count} devices`}</div>),
    customParams: { objectName: 'member', valueName: 'device_count' },
  },
  {
    label: 'Actions',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    entry: 'public_id',
    headerClassName: 'header-cell header-text-cell medium-cell',
    cellClassName: 'regular-cell medium-cell',
    customRender: () => <></>,
    customParams: { objectName: 'member', valueName: 'public_id' },
  },
];


//  Template for issues groups modal's devices table
export const issuesGroupDevicesTableTemplate = [
  {
    label: 'Device name',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell small-cell',
    cellClassName: 'regular-cell small-cell ellipsed-cell',
    entry: 'name',
  },
  {
    label: 'Member',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell small-cell',
    cellClassName: 'regular-cell small-cell ellipsed-cell',
    entry: 'company_name',
  },
  {
    label: 'MAC Adress',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell small-cell',
    cellClassName: 'regular-cell small-cell ellipsed-cell',
    entry: 'mac_address',
  },
  {
    label: 'IP Address',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell small-cell',
    cellClassName: 'regular-cell small-cell ellipsed-cell',
    entry: 'ip_address',
  },
  {
    label: 'Devices Tag',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: 'header-cell header-text-cell medium-cell',
    cellClassName: 'regular-cell medium-cell',
    entry: 'taggings',
    customRender: (device) => getTagsCustomRender(device),
  },
  {
    label: 'Actions',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: 'header-cell header-text-cell medium-cell',
    cellClassName: 'regular-cell medium-cell',
    entry: 'public_id',
    customRender: (domain) => <></>,
    customParams: { objectName: 'domain', valueName: 'public_id' },
  },
];

const getTagsCustomRender = (device) => {
  const target = `group-devices-tag-tooltip-${device?.object?.public_id}`;
  const tagsList = parseTaggingsList(device?.object?.taggings);
  return (
    <div className="tags-container d-flex align-items-center justify-content-between">
      <TagsPreview className="mr-1" 
        tagsList={tagsList} 
        targetId={target} />
    </div>
  );
};