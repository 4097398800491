import {
  ON_IMPERSONATION_SUCCESS,
  COMPANY_SCAN_UPDATE,
  LOGOUT_SUCCESS,
  SET_ISSUES_FILTER,
  CLEAR_ALL_ISSUES_FILTERS,
  ON_IMPERSONATION_LOADING,
  SET_MEMBER_VIEW,
  DEVICES_LIST_SUCCESS,
  SHOULD_RELOAD_IMPERSONATION,
  UPDATE_DOMAIN_COUNT
} from '../actions/types';
import { issueTypes } from '../constants/issues';

const initialState = {
  loading: true,
  companyId: null,
  freemium: null,
  showDemoData: null,
  requestedScan: null,
  lastRequestScan: null,
  lastScan: null,
  name: null,
  expected_devices: 0,
  devices: 0,
  packages: [],
  isMemberView: false,
  pendingAdmin: false,
  code: null,
  shouldReload: false,
  domainsCount: 1,
};

const impersonationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ON_IMPERSONATION_SUCCESS:
      if (!payload) return { ...state, ...initialState, }

      const { impersonatedCompany, packages, pending_admin } = payload;
      const isMemberView = !impersonatedCompany ? false : state.isMemberView;
      const company_has_partners = impersonatedCompany?.company_has_partners;
      const greaterThanZero = company_has_partners?.length > 0;
      const hasConnection = company_has_partners?.some(e => e.status === 1);

      return {
        ...state,
        companyId: impersonatedCompany?.id,
        freemium: impersonatedCompany?.freemium,
        showDemoData: impersonatedCompany?.show_demo_data,
        requestedScan: impersonatedCompany?.request_scan,
        lastRequestScan: impersonatedCompany?.request_scan_date,
        lastScan: impersonatedCompany?.scan_date,
        name: impersonatedCompany?.name,
        expected_devices: impersonatedCompany?.expected_devices,
        devices: impersonatedCompany?.devices_count,
        packages: packages || [],
        loading: false,
        isMemberView: isMemberView,
        pendingAdmin: pending_admin,
        code: impersonatedCompany?.code,
        domainsCount: parseInt(impersonatedCompany?.domains_count || "1"), // FIXME: number should update after adding,
        hasConnection: greaterThanZero && hasConnection
      };
    case COMPANY_SCAN_UPDATE:
      //  Auth reducer listening to this event as well
      //  If does not update here, will update on auth
      if (!state?.companyId) { return state; }
      return {
        ...state,
        requestedScan: payload?.request_scan,
        lastRequestScan: payload?.request_scan_date,
      }
    case SET_ISSUES_FILTER: {
      const { filterName, value } = payload;
      if (filterName === 'company_id' && value === null) {
        return {
          ...initialState,
          loading: false
        }
      } else {
        return state
      }
    }
    case CLEAR_ALL_ISSUES_FILTERS: {
      if (payload?.isMemberView) return state;
      return {
        ...initialState,
        packages: state?.packages || [],
      };
    }
    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialState,
      };
    case ON_IMPERSONATION_LOADING: {
      return {
        ...state,
        loading: payload
      }
    }
    case SET_MEMBER_VIEW: {
      return {
        ...state,
        isMemberView: payload?.enabled,
      }
    }
    case DEVICES_LIST_SUCCESS: {
      const deviceInfo = payload?.count;
      return {
        ...state,
        ...deviceInfo,
      }
    }
    case SHOULD_RELOAD_IMPERSONATION: {
      return {
        ...state,
        shouldReload: payload
      }
    }
    case UPDATE_DOMAIN_COUNT: {
      return {
        ...state,
        domainsCount: payload.domains_count,
      }
    }
    default:
      return state;
  }
};

export default impersonationReducer;
