import React, { useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Avatar from '../layout/avatars/Avatar';

const RemediationNoteEditor = (props) => {
    const {
        user: authUser,
        isCollapsed,
        minHeight="157px",
        maxHeight="93px",
        editorState,
        setEditorState,
        placeholder
    } = props;

    // Component State
    const [editorClassName,setEditorClassName]=useState('editor');

    // Icons for editor toolbar
    const bold = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAABnUlEQVRoQ+2YwVXCQBCG/7ESSnA6IBWQPbIepAStQEqRg3qEEkIFhArUDrCBjG8Pec+HxOwkG7J5Llcmk/n+f2YZljDxD028fiSAsR1MDiQHeirw/1rodXeQnqKVBJQQbJaGi5659MdoAICfNRcieLwzXHYFUbdQYABX90kEWVeIGAB6QcQC4CAKm3OmbaWYAECCTDvYwQBszq253raHWQWsiPB0UWnBxhpeaVxofel5sqYh9gGoc71sD+sGiNLmzNEDOCeE8H6pUI0Q7vlRHJg8wB8t9GlznkXbQk553OBeBOvohlijWlPsqMdobwDC3i54rs0TbIi1L/4VLzDW8E6bJx4A4EQCXhr+0EDEBNBpH4oNQL0PBQPw/QUNvQ9dHSD0PjQaQKh1IgHULeE7A60tRDjaBd/6HqVXdyD0PhQMwFextjjtPhQXQId9KCaALxHMtfdDsQB0Kj7oX8q23m78nrCXCg9a5et84zhAOKKCu+R91t4DnQuhBuis9EAPJoCBhPVOmxzwlmqgwOTAQMJ6p00OeEs1UODkHfgGskXvMdrMP5MAAAAASUVORK5CYII=";
    const underline = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAB40lEQVRoQ+1XwXHCQAxc0UBKiFNBuAqSDvA9MY9AB04H6SCkgsADeNp0kFRwUEEoITz5oIwZ2wOGgOUL8TDc/Tyj02p3Jd2YcOGHLrx+OAJ1O+gccA5YKnB9LTSODW+LFvjKSgTbfGJwW8Bix9jmcwRcC1nOlGsh10KuhYRPue3edu9AQQG3hS5vC03NDIz7zElmqI5WM+EobcJHkWkSweR3CfOgpZqSXOIWmkQmZkIrB2E8B1r1JaBZ7DgyIQiv2Tcxpm2tfEkuMYEiKIBF4Ks7CWhOIDYLALc2YogJTCLjMeFrp+AKLhwQAsS4a2uVkCp9xASSzOPIDEB42kaRzMJe7yeJGMNAq27pytPASgRSF5LBvdkhQQg7LfV2rIhDygNYrhheT6vvfyGQuuCDEB0AXDChjzU+s+2UKI4GHogRAvD27jB0oFUsLT6Jr+RABjSKTJcI71WAszvM6HW0GlTNYUVgy4mkgJ12KlHQEoxuVeXz1VsC6GTIZiaAl+Jg/3qRMVwBYZWeL+a0dmA7YUrEJ+CRG/DyF5swpzUWDHwQEEtX5TEF/5TASavOEOAInEFUUcrrcaD4KymSqUJw2f+M0g44AkIXnANCwWoLLz0DtVV4AtgRqNsZ54BzwFKBi2+hH87D+jFoUEwsAAAAAElFTkSuQmCC";
    const ordered = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAB40lEQVRoQ+1Xy3HCMBB9yyU50gGkglgdhAqwj5hD6CChgjgVhFSAL8BRpgLoQJRAKshQQNiMAM84M4A9tgFpIl3tXe17b38iWH7I8vjhANxaQaeAU6AiAy6FKhJY2fz/KDCT6okJC2a89wMRVaauJgeFFJhIFRHhBUDTSgDTRDEx5kzongKg/6mJ1D9uQl+cJbmQAmOpmveAdy6FjAagKcmrAQfgRP7VkkLa90QqjxoY8RZxPxDxJfK9jM9CNVDG8bVsHIBrMX3qnkIKzKRqM3NLOyGir14g1rcOPL0/F8CueAkLPYVTI2aIfiBWJoAoAmBADQywxWgXMEEem8bGzwE9je8IYwA+GEEYiCSrgNEADsHrNPLAGIaB2KuROcYCyAZPhIh/eHmskI0FcFil37Jsm7RSFylir5HpQAcga1NaaS4AE1rluRgcgFsrlKvA7jVG+GCgTcB6y/g0ZQrv52rOmUmVpG9hIrwC+A598WDNHEgfM/tFDhKETdgVnlUAdLDTROkNtAXCKOyKoXUAdiCkikF4JkanF4ilFavEdK5WYLSY0SGCnsi+beu0fszHYDwC2IARHVvm8prBpb7ndqFLXVyXXwegLibL+nEKlGWuLjunQF1MlvXjFCjLXF121ivwCzGJ1zHEFPfuAAAAAElFTkSuQmCC";
    const unordered = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAvElEQVRoQ+2WsRGAMAzEkpHYhJoBqdkkI0ELoXCh83HmRAvvxJID9Fb86sX332zga4Ma0AAk4AhBgDiuAYwQFvi/gf0Y5x3Sti6Ppuf7EOgrPq83PxAasAGoRAMQYHo8PAPpO4AL2AAEiOMawAhhgdCAHzJI2A9Z+RGCE5AeDw9x+g7gAjYAAeK4BjBCWCA0UP41agNwRPyVgADT4+EhTt8BXMAGIEAc1wBGCAtoAALEcQ1ghLCABiBAHL8Avn9gMVl8qlwAAAAASUVORK5CYII=";
    const link = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAADgUlEQVRoQ+1ZPVLbQBT+1ilSxilTBU4QNjkAcAKkdMgFcS4Qp6bAzGTSklwgQGHRReIEgQMkyw0imtSmpIhfZm3JSPKudmVWIsxYM6709Pb73v9bMzzyhz1y/FgReGgPrjywrAfC77826Qnb6ABdAhL8pSR4+/qyrr5WPXAWiTUwHBDwTgN0zIAYhMNdnyc2ZFojMDoXHxjhiw0oKUOEfs/nJyb5VgicxeK4wupajAw42fV4v4pE4wQqwF+DEBMgw6YLBg/AyzJYkycaJaABf0OEgSo8RpF4x9g0zJ7liTDCui4nGiNQAX6r5/MrXViMIrHBGC4KJAingc+Vid8IgWXBZ6RSTxznSI4Djz9XkXZO4L7gM5BhLGQZnecEI2zv+lx6pvA4JXAcie7TDi5AeJU7Rcb8QtiE8c8+ofOi5/HPKsuGkTgBw172TpfMTgnIw0oktOCBzrdpvQf2VSRGkRgyhoMcgcOez4eNeiBTnpKIaTKtNoWElZbPwM/BKUg8mAeqmo4K/Ex+8j7w3uSTFmEsfgNYy/S1kgOuwCuq0E3g8a7TKiTDpO/zsWlWke/rWD7tAz8gu/NdjLntA2mp3LglbJtI3Bs8AKeduFTnr6pIuAAPwsfA59optlYZVTYphstgh2+VQ8kReG3ozJPbJoalTJ0O2xZ4icvKA/8reCsCTYGfVqdzIceOzUIUVEyetctoGIkBGI5KHxrHg6L8YpMqdey72akm+EoPyAWcGGQ3zD/OwC+QmOBKN/NX5ak2B8qzCADn4PMkTP1ER0JPIBaU/0g1ztapNrbVrq6cksBZJLaIQbbz7LkOPD4frOqOB3VB1ZFXElhIXsLXwOeDvOIwFvsAPpkSNp1tjm4J/rJhUjsHbJeJIonFalMazCrHjjpWz8su7YFMyYzE5E95nldOlYBzErY5kAQeX7e1kga83B+Ns43tGZlcVRWSs/78gsl0Q5YpbBN8ZSNT9IExEbYtLqWKy4g8pQHLGz2g6cRjMAyCHX5adnV4LvYwu30urn4Ngq/0gHyp2E0z3AkDLsCQgLBGgNwHCn1iKtgweCOBacMqXTBZJ1kL4K0IGDyh5mNYA62NYCFotdBIPdOcAIbpPX7h+js950be9zNgaPv3kAU+o4g1gbwmOStNZjEvf0kHSFQXr8bTHQgsRcDBuc5UrAg4M+WSilYeWNJwzj77B+VFN0+SnbbCAAAAAElFTkSuQmCC";
    //const embed = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAABb0lEQVRoQ+2Xwc3CMAyFnU1ghG7wswE9VlxgMrigHssGsEFHgE2CesivqiJN7GdIQeYKtt/nZyfE0Zd/3JfrJwMo7aA5YA6AHbARAhsIh5sDcAvBBOZA2/V/TV3dJI1EYkM9yIFz1++do6MjOjXb6sCBaC/90RPtvafDrq5OnNjxb8UAQfyoE9kQQXyIRSBEAFPx/xCeNqlxGsbGO7pOOy6FYAPExHMEaOQQ7YBmYa1c2Q5oFRyPjkbOLACNQrFTBs2dBEAL5ByPSI1ZACRxjnCNcYoCfFJ8AJHUfAkgScTtuNZO/CbA0J0SLkhq/u4SI4vF3QdJ51l/JZACKRg0d/Iie6cTqPhBWzaA9mJriGcDaEFoiRcBzEE4T+umrh5zc992/co7uhd70MR2AnnQcGKn4KwdmAaHUZAIQGJVHvUhyTASqbGJjRQSy7oHUmd5ye+hESop3BxYQvfF98BSxBvAEpywU6i0C+aAOQB24AlRREJAJBTm7QAAAABJRU5ErkJggg==";


    const shouldHidePlaceholder = (editorState) => {
        const contentState = editorState.getCurrentContent();
        return (
            contentState.hasText() ||
            contentState
                .getBlockMap()
                .first()
                .getType() !== 'unstyled'
        );
    }

    const onEditorStateChange = (state) => {
        setEditorState(state);
        if(shouldHidePlaceholder(state)){
            setEditorClassName(`${editorClassName} editor--hide-placeholder`);
        }else{
            setEditorClassName(editorClassName.replace('editor--hide-placeholder',''));
        }
    };

    return (
        <div className="editor-container">
            <Avatar user={authUser} customClassName="text-editor-avatar"/>
            <Editor
                toolbarClassName="editor-toolbar"
                wrapperClassName="editor-wrapper"
                editorClassName={editorClassName}
                placeholder={placeholder || "Enter remediation note"}
                editorState={editorState}
                stripPastedStyles={true}
                onEditorStateChange={onEditorStateChange}
                editorStyle={{ height: isCollapsed? minHeight : maxHeight}}
                toolbar={{
                    options: ['inline', 'list','link'],
                    classname:"inline-btn",
                    inline: {
                        inDropdown: false,
                        options: ['bold','underline'],
                        bold: { icon: bold ,className: 'dark-option' },
                        underline: { icon: underline ,className: 'dark-option' }
                    },
                    list: {
                        inDropdown: false,
                        options: ['unordered', 'ordered'],
                        ordered: { icon: ordered ,className: 'dark-option' },
                        unordered: { icon: unordered ,className: 'dark-option' }
                    },
                    link: {
                        inDropdown: false,
                        options: ['link'],
                        link: { icon: link ,className: 'dark-option' },
                        popupClassName: "dark-popup",

                    }
                }}
            />
        </div>
    )
}

export default RemediationNoteEditor
