import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { setHoveredRiskBubble, selectRiskStatement } from '../../../actions/businessRisk';
import { riskTreatmentIcons, riskTreatmentBubbleTooltips, INITIAL_RISK_ID } from '../../../constants/riskTreatment';
import { Tooltip } from 'reactstrap';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import { scrollToComponent } from '../../../utils/scrollHelpers';

const RiskBubble = (props) => {
    const {
        riskStatement,
        controllerRef
    } = props;

    //  Component's Hooks
    const { dispatch } = useMemoizedDispatch();

    //  Getting needed info from redux store
    const hoveredStatement = useSelector((state) => state.businessRisk.hoveredStatement);
    const riskTreatmentFilter = useSelector((state) => state.businessRisk.riskTreatmentFilter);


    const bubbleComponentId =`risk-bubble-${riskStatement?.code}`;

    const getBubbleClassName = () => {
        const bubbleTreatment = riskStatement?.is_initial && riskStatement?.show_initial ? INITIAL_RISK_ID : riskStatement?.risk_treatment?.id;
        const bubbleBackground = `bg-${riskStatement?.is_initial? 'initial-risk': riskStatement?.risk_treatment?.label?.toLowerCase()}`
        const bubbleOpacity = (!_.isNil(hoveredStatement) && hoveredStatement !== riskStatement?.id) || (!_.isNil(riskTreatmentFilter) && riskTreatmentFilter !== bubbleTreatment) ? 'fade-out-bubble' : '';
        const hoveredBubble =  hoveredStatement === riskStatement?.id ? 'hovered-bubble' : '';
        const hiddenBubble = riskStatement?.is_initial && !riskStatement?.show_initial && (riskStatement?.id !== hoveredStatement || riskStatement?.has_same_position ) ? 'hidden-bubble': '';
        return `risk-bubble rounded-circle ${bubbleBackground} ${bubbleOpacity} ${hoveredBubble} ${hiddenBubble}`;
    }

    const onBubbleClick = function() {
        dispatch(selectRiskStatement(riskStatement?.id))
        if(controllerRef) {
            scrollToComponent(controllerRef)
        }
    }

    const renderRiskTreatment = () => {
        return (
            <div className="risk-treatment-circle rounded-circle">
                <div className="overlay">
                    <i className={`bx ${riskTreatmentIcons[riskStatement?.risk_treatment?.label]}`}/>
                </div>
            </div>
        )
    }

    const renderInitialRiskTooltip = () => {
        return (
            <Tooltip
                target={bubbleComponentId}
                className="custom-basic-tooltip risk-treatment-description-tooltip"
                arrowClassName="custom-basic-tooltip-arrow"
                isOpen={hoveredStatement === riskStatement?.id}
                placement="bottom"
            >
                {riskTreatmentBubbleTooltips[riskStatement?.risk_treatment?.label]}
            </Tooltip>
        )
    }

    return (
        <div
            id={bubbleComponentId}
            className={getBubbleClassName()}
            onClick={onBubbleClick}
            onMouseEnter={()=> dispatch(setHoveredRiskBubble(riskStatement?.id))}
            onMouseLeave={()=> dispatch(setHoveredRiskBubble(null))}
        >
            <div className="overlay risk-bubble-overlay rounded-circle">
                <svg viewBox="0 0 100 100">
                    <text x="50%" y="54%" textAnchor="middle" dominantBaseline="middle" >{(riskStatement?.code)?.slice(2)}</text>
                </svg>
                {!riskStatement?.is_initial && renderRiskTreatment()}
                {riskStatement?.has_same_position && renderInitialRiskTooltip()}
            </div>
        </div>
    )
}

export default RiskBubble
