import React, { useState } from 'react';
import { setAlert } from '../../../actions/alerts';

import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import { addCompanyDomainsModalData } from '../../../constants/pagesData';
import BasicModal from '../../layout/BasicModal';
import { buildDomainFieldObject, domainTemplate } from '../../../constants/formTemplates';
import { Form, Field } from 'react-final-form';
import { renderInputV2 } from '../../../utils/renderFormFields';
import { existEmptyValuesOnForm, validateFieldsByFormTemplate } from '../../../utils/validations';
import { getDeduplicatedArray, getSafeArray, removeItemFromArray, removeKeyFromObject } from '../../../utils/helpers';
import { memberDomainLimit } from '../../../constants/common';
import { alertTypes } from '../../../constants/alerts';
import errorMessages from '../../../constants/errorMessages';
import API from '../../../api';
import useCustomMutation from '../../../hooks/useCustomMutation';
import useHandleApiResponse from '../../../hooks/useHandleApiResponse';
import { OnChange } from 'react-final-form-listeners';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import IconTooltip from '../../dashboard/tooltips/IconTooltip';
import useDomainActions from '../../../hooks/useDomainActions';

const DomainAdditionModal = (props) => {
  //  Parsing props
  const {
    closeModal,
    companyId,
    domainsCount = 1,
  } = props;

  // Initializing api
  const { CompanyAPI } = API;

  //  Component state
  const [lastFieldIndex, setLastFieldIndex] = useState(0);
  const [fieldsToRender, setFieldsToRender] = useState([
    buildDomainFieldObject(lastFieldIndex, domainTemplate),
  ]);
  const [newDomains, setNewDomains] = useState({})
  const [verifiedDomains, setVerifiedDomains] = useState([])
  const [domainsValidated, setDomainsValidated] = useState(false);

  // Component Hooks
  const {
    data: addDomainsData,
    error: addDomainsError,
    isLoading: addDomainsLoading,
    mutate: addDomains,
  } = useCustomMutation(
    (params) => CompanyAPI.handlers.addCompanyDomains(params),
    CompanyAPI.invalidators.addCompanyDomains
  );

  const {
    data: validateDomainData,
    error: validateDomainError,
    isLoading: validateDomainLoading,
    mutate: validateDomain,
  } = useCustomMutation(
    (params) => CompanyAPI.handlers.validateCompanyDomains(params),
    [],
    { retry: 0 }
  );

  //  Component hooks
  const {
    updateDomainCount,
  } = useDomainActions({
    company_id: companyId,
    startTime: null,
    isMaster: false,
  })

  const { dispatch } = useMemoizedDispatch();

  // Function to get max number of new domains
  const getMaxNumberofDomains = () => {
    return memberDomainLimit - domainsCount
  }

  // Handling adding domains and response
  const handleAddDomainResponse = () => {
    if (!addDomainsData) return;
    // Domains were added successfuly

    // Dispatch to update domains_count on Redux
    dispatch(updateDomainCount(addDomainsData?.domains_count));
    closeModal(false);
    dispatch(setAlert(`Domain${addDomainsData?.data.length > 1 ? 's' : ''} added`, alertTypes.success));
  }
  // Hnadling the add domain endpoint response
  useHandleApiResponse({
    data: addDomainsData,
    errorData: addDomainsError,
    successCallback: () => handleAddDomainResponse(),
  });

  // Handling validating domains and response
  const handleValidateDomainResponse = () => {
    if (!validateDomainData) return;

    //If there are no invalid domains
    if (!validateDomainData?.invalid_domains) {
      setDomainsValidated(true);
    }

    // Update validated domains list (either valid or invalid)
    const verifiedSubDomains = getSafeArray(validateDomainData?.data)?.reduce((subdomains, subdomain) => {
      if (subdomain?.is_valid)
        return [
          ...subdomains,
          {
            name: subdomain?.name,
            message: 'Everything ok',
            isValid: true,
          }
        ];

      return [
        ...subdomains,
        {
          name: subdomain?.name,
          message: subdomain?.errorMessage || `We can’t run scans in this domain, please try a different domain`,
          isValid: false,
        }
      ]
    }, [])
    setVerifiedDomains(verifiedSubDomains)
  }
  // Handling the validate domains endpoint response
  useHandleApiResponse({
    data: validateDomainData,
    errorData: validateDomainError,
    successCallback: () => handleValidateDomainResponse(),
  });

  // Get payload for adding domains
  const getAddDomainPayload = (formValues) => {
    const domains = Object.values(formValues);
    return {
      domains,
      company_id: companyId
    }
  }

  //  Function to handle validate submit
  const onValidateSubmit = (formValues) => {
    //  Validating data before submitting
    const formHasEmptyValues = existEmptyValuesOnForm(
      formValues,
      fieldsToRender.length
    );
    const emails = Object.values(formValues) || [];
    if (formHasEmptyValues || emails.length === 0) {
      dispatch(setAlert(errorMessages.MISSING_FIELDS, alertTypes.error));
      return;
    }

    let payload = getAddDomainPayload(formValues);
    // Needed for adding and removing fields, also for editing the domains after validating
    setNewDomains(formValues);
    // Removing any duplicated domains on the form values
    deduplicateDomainsPayload(payload);
    // Call validation endpoint
    validateDomain(payload);
  };

  //  Function to add domains after validation
  const onAddSubmit = () => {
    let payload = getAddDomainPayload(newDomains);
    // Removing any duplicated domains on the form values
    deduplicateDomainsPayload(payload);
    // Call add domains endpoint
    addDomains(payload);
  }

  // Function for deduplicating domains for validation and addition, doesn't need to return anything since it's passed by reference
  const deduplicateDomainsPayload = (payload) => {
    payload.domains = getDeduplicatedArray(payload.domains);
  };

  //  Function to add new field to the DOM
  const addField = () => {
    if (fieldsToRender?.length >= getMaxNumberofDomains()) {
      const maxDomainsErrorMessage = 'You have reached the maximum number of domains';
      dispatch(setAlert(maxDomainsErrorMessage, alertTypes.error));
      return;
    }
    const newLastFieldIndex = lastFieldIndex + 1;
    const newField = buildDomainFieldObject(newLastFieldIndex, domainTemplate);
    setFieldsToRender([...fieldsToRender, newField]);
    setLastFieldIndex(newLastFieldIndex);
  };

  // Function to check if the domains are valid or invalid and get correct info message
  const isInvalidDomain = (fieldName) => {
    //const key = domainTemplate?.name?.replace('{index}', inputIndex)
    const domain = newDomains[fieldName]
    if (!domain) return { apiMessage: null, apiIsInvalid: null };
    const verifiedDomain = verifiedDomains.find((verifiedDomain) => verifiedDomain?.name === domain)

    if (verifiedDomain === undefined) return { apiMessage: null, apiIsInvalid: null };

    if (verifiedDomain?.isValid) {
      return { apiMessage: verifiedDomain?.message, apiIsInvalid: false };
    } else if (!verifiedDomain?.isValid) {
      return { apiMessage: verifiedDomain?.message, apiIsInvalid: true };
    }
  }

  // Function to check if form has invalid domains
  const hasInvalidDomains = () => {
    return Object.values(newDomains).find(newDomain => verifiedDomains.find(verifiedDomain => newDomain === verifiedDomain?.name && !verifiedDomain?.isValid))
  }

  //  Function to remove field from DOM
  const removeField = (index, name) => {
    const newFieldsToRender = removeItemFromArray(fieldsToRender, index);
    const newForm = removeKeyFromObject(newDomains, name);
    setFieldsToRender(newFieldsToRender);
    setNewDomains(newForm)
  };

  //  Function to remove list item from DOM
  const removeListItem = (name) => {
    const listItemToRemove = fieldsToRender.find((field) => field?.name === name)
    const listItemPosition = fieldsToRender.findIndex((field) => {
      return (field?.name === listItemToRemove?.name);
    })
    const newFieldsToRender = removeItemFromArray(fieldsToRender, listItemPosition);
    const newForm = removeKeyFromObject(newDomains, name);
    setFieldsToRender(newFieldsToRender);
    setNewDomains(newForm)
  };

  //  Function to render the remove field icon
  const renderRemoveFieldIcon = (index, name) => {
    if (fieldsToRender.length <= 1) { return null; }
    return (
      <i
        className="bx bx-minus-circle mr-2 align-middle remove-field-button"
        onClick={() => removeField(index, name)}
      />
    );
  };

  //  Function to render all the fields
  const renderFields = () => {
    if (!fieldsToRender) { return null; }
    return fieldsToRender.map((field, index) => {
      const {
        label,
        placeholder,
        className,
        type,
        name,
      } = field;
      const text = newDomains[name] ? newDomains[name] : '';
      return (
        <div key={`domain-field-${index}`} className="field-container">
          <div className="label-container">
            <div>{`${label}`}</div>
            {renderRemoveFieldIcon(index, name)}
          </div>
          <Field
            key={index}
            render={(params) => renderInputV2(params, isInvalidDomain(name))}
            name={name}
            placeholder={placeholder}
            className={className}
            type={type}
            defaultValue={text}
          />
          <OnChange name={name}>
            {(value) => {
              setNewDomains({
                ...newDomains,
                [name]: value
              })
            }}
          </OnChange>
        </div>
      );
    });
  };

  //  Function to mutate form state when a field has been removed
  const mutateFormState = (args, state, callbacks) => {
    const { formState } = state;
    const { values, errors } = formState;
    //  Verifying if we need to change the state
    const amountOfKeys = Object.keys(values).length;
    if (amountOfKeys === 0) {
      return;
    }
    if (amountOfKeys <= fieldsToRender.length) {
      return;
    }
    //  Removing the extra field values and errors
    Object.keys(values).forEach((key) => {
      const matchingField = fieldsToRender.find(({ name }) => name === key);
      if (matchingField) {
        return;
      }
      delete values[key];
      delete errors[key];
    });
  };

  // Function to handle submit
  const onFormSubmit = (event, invalid, handleSubmit) => {
    if (invalid) {
      dispatch(setAlert(errorMessages.MISSING_FIELDS, alertTypes.error));
    }
    handleSubmit(event);
  };

  //  Function to render form
  const renderForm = (params) => {
    //  Parsing params
    const { handleSubmit, invalid, form } = params;
    form.mutators.mutateFormState();
    //  Returning form
    return (
      <form
        className="domain-addition-modal-container"
        onSubmit={(event) => onFormSubmit(event, invalid, handleSubmit)}
      >
        <div className="fields-container">{renderFields()}</div>
        <div className="w-100 d-flex align-items-center justify-content-end">
          <Button
            className="btn btn-add-domain d-flex align"
            color="link"
            onClick={() => addField()}
          >
            <i className="bx bx-plus underline-link mr-2 align-middle remove-field-button" />
            Add other domain
          </Button>
        </div>
        <div className="button-container">
          <Button
            className="btn-close-modal btn-large border-0 pt-0"
            type="submit"
            color="light-gray"
            outline
            onClick={closeModal}
          >
            Close
          </Button>
          <Button
            color="light"
            className="btn-large text-dark pt-0"
            type="submit"
            disabled={invalid || hasInvalidDomains() || validateDomainLoading || (getMaxNumberofDomains() === 0)}
          >
            Verify
          </Button>
        </div>
      </form>
    );
  };

  //  Function to validate form fields
  const validateForm = (formValues) => {
    return validateFieldsByFormTemplate(fieldsToRender, formValues);
  };

  // Function to render form
  const renderAddDomainForm = () => {
    return (
      <>
        <div className='domain-instructions'>
          {`You can add up to ${getMaxNumberofDomains()} domains, they all have to be verified.`}
        </div>
        <Form
          onSubmit={onValidateSubmit}
          render={renderForm}
          validate={validateForm}
          mutators={{ mutateFormState }}
          shouldValidate
          validateOnBlur
        />
      </>
    )
  }

  // Function to render save domains view
  const renderSaveDomainsView = () => {
    // TODO: add view
    const domains = Object.entries(newDomains);
    return (
      <>
        <ListGroup className="valid-domains-list">
          {domains.map((value, index) => {
            return (
              <ListGroupItem key={`valid-domains-listitem-${index}`} className="valid-domains-listitem">
                {value[1]}
                <div
                  className="tooltip-icon"
                  key={`domain-addition-action-${index}`}
                >
                  <IconTooltip
                    targetId={`domain-addition-action-${index}`}
                    iconClassName="bx bx-trash"
                    callback={() => removeListItem(value[0])}
                  //tooltipText={`Delete`}
                  />
                </div>
              </ListGroupItem>
            );
          })}
        </ListGroup>
        <div className="valid-domains-footer">
          <div className='edit-info-message'>Note: Clicking on the Edit button will redirect you to the previous form</div>
          <div className="button-container">
            <Button
              className="btn-close-modal btn-large border-0 pt-0"
              type="submit"
              color="light-gray"
              outline
              onClick={() => { setDomainsValidated(false) }}
            >
              Edit
            </Button>
            <Button
              color="light"
              className="btn-large text-dark pt-0"
              type="submit"
              disabled={addDomainsLoading}
              onClick={() => { onAddSubmit() }}
            >
              Save
            </Button>
          </div>
        </div>
      </>
    );
  }

  //  Rendering
  return (
    <BasicModal
      toggleModal={closeModal}
      header={addCompanyDomainsModalData.title}
      customClassName="domain-addition-modal confirmation-modal"
      headerClassName="domain-addition-modal-header header-confirmation-modal"
      showModal
    >
      {!domainsValidated ? renderAddDomainForm() : renderSaveDomainsView()}
    </BasicModal>
  );
}

export default DomainAdditionModal
