import React from 'react';
import AutomationRules from './AutomationRules';
import RuleLogs from './Logs/RuleLogs';
import LogDetail from './Logs/LogDetail';
import RuleSetDetail from './RuleSetDetail';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import PageNotFound from '../../views/PageNotFound';

const AutomationContainer = (props) => {
  //  Watching redux store
  const { path } = useRouteMatch();

  //  Function to render content
  const renderContent = () => {
    return (
      <Switch>
        <Route exact path={`${path}/rule/:rule_id/logs/:log_id`}>
          <LogDetail />
        </Route>
        <Route exact path={`${path}/rule/:rule_id/logs`}>
          <RuleLogs />
        </Route>
        <Route exact path={`${path}/rule/:rule_set_id`}>
          <RuleSetDetail />
        </Route>
        <Route exact path={path}>
          <AutomationRules />
        </Route>
        <Route component={PageNotFound} />
      </Switch>
    );
  };

  //  Rendering
  return renderContent();
};

export default AutomationContainer;
