import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import API from '../../../api';
import { companyRiskTemplate } from '../../../constants/app-v4/riskTableTemplate';
import useCustomInfiniteQuery from '../../../hooks/useCustomInfiniteQuery';
import companyIdSelector from '../../../selectors/companyIdSelector';
import { parseCompanyRisks, getCompanyRiskListInitialFilters } from '../../../utils/companyRisksHelper';
import { getNextPageParam } from '../../../utils/queryHelpers';
import CustomTable from '../../CustomTable/CustomTable';
import { Button } from 'reactstrap';
import RiskCreation from './RiskCreation';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import { getApiErrorMessage } from '../../../utils/helpers';
import { setAlert } from '../../../actions/alerts';
import { alertTypes } from '../../../constants/alerts';

const CompanyRisksTable = () => {
    //  Initializing APIs
    const { VcysoAPI } = API;

    // Getting needed info from redux store
    const companyId = useSelector(state => companyIdSelector(state))

    const initialFilters = getCompanyRiskListInitialFilters(companyId)

    //  Component state
    const [companyRisks, setCompanyRisks] = useState([]);
    const [companyRisksTotal, setCompanyRisksTotal] = useState(0);
    const [filters, setFilters] = useState(initialFilters);
    const [requestMore, setRequestMore] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);

    // Components Hooks
    const { dispatch } = useMemoizedDispatch();

    //  API Hooks
    const {
        data: pagesData,
        isLoading: companyRisksLoading,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        error: companyRisksError,
    } = useCustomInfiniteQuery(
        [VcysoAPI.keys.getCompanyRisksList, filters],
        ({ queryKey, pageParam = 0 }) => VcysoAPI.handlers.getCompanyRisksList({ ...queryKey[1], offset: pageParam }),
        {
            getNextPageParam: (lastPage, allPages) => getNextPageParam(lastPage, allPages, 'risks', 'total'),
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        }
    );

    //  Watching filter changes
    useEffect(() => {
        if (filters?.offset === 0) {
            setCompanyRisks([]);
        }
    }, [filters?.offset]);

    //  Watching request more flag
    useEffect(() => {
        if (!requestMore) { return; }
        setRequestMore(false);
        if (!hasNextPage || companyRisksLoading || isFetchingNextPage) { return; }
        fetchNextPage();
    }, [requestMore]);

    //  Watching company risks list response
    useEffect(() => {
        if (!pagesData) { return; }
        const { pages = [] } = pagesData;
        let totalElements = 0;
        //  Getting and parsing all elements
        const newElements = pages.reduce((list, currentPage) => {
        const { risks, total = 0 } = currentPage;
        totalElements = total;
        const parsedRows = parseCompanyRisks(risks);
        return [...list, ...parsedRows];
        }, []);

        //  Setting new company risks
        setCompanyRisksTotal(totalElements);
        setCompanyRisks(newElements);
    }, [pagesData]);

    // Watch api fetch error
    useEffect(() => {
        if(!companyRisksError) return;
        const errorMessage = getApiErrorMessage(companyRisksError);
        dispatch(setAlert(errorMessage, alertTypes.error))
    }, [companyRisksError]);


    // Watch company id changes
    useEffect(() => {
        setFilters({ ...filters, offset: 0, company_id: companyId });
    }, [companyId])

     //  Function to handle scroll
    const requestMoreCompanyRisks = () => {
        setRequestMore(true);
    };

    const toggleEditModal = () => {
        setShowCreateModal(!showCreateModal)
    }

    const renderTable = () => {
        return (
            <div className="risk-manager-table-wrapper">
                <CustomTable
                    tableClassName="risk-manager-table"
                    headerClassName="risk-manager-table-header"
                    rowClassName="risk-manager-table-row"
                    columnsTemplate={companyRiskTemplate}
                    data={companyRisks}
                    isScrollable={true}
                    requestMore={requestMoreCompanyRisks}
                    loading={companyRisksLoading || isFetchingNextPage}
                    emptyConfig={{
                        message: 'The created risks will appear here',
                        messageClassName: 'empty-message'
                    }}
                />
            </div>
        )
    }


    const renderCreate = () => {
        return (
            <div className='risk-manager-create-section'>
                <p className='title'>Risk Statements Distribution</p>
                <p className='count'>{`${companyRisksTotal} Risks Created`}</p>
                <Button
                    className="create-risk btn-light btn-large ml-auto"
                    type="submit"
                    onClick={()=>setShowCreateModal(true)}
                >
                     <i className='bx bx-plus btn-icon' />
                    Create a Risk
                </Button>
            </div>
        )
    }

    return (
        <div className='d-flex w-100 risk-manager-container'>
            <RiskCreation showCreateModal={showCreateModal} toggleModal={toggleEditModal}/>
            {renderCreate()}
            {renderTable()}
        </div>
    )
}

export default CompanyRisksTable;
