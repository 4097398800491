import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import InformationExpandableBar from './InformationExpandableBar';
import { trustBuilderFreemiumBar, trustBuilderSubscribedBar } from '../../constants/pagesData';
import VcysoSolutions from './VcysoSolutionsControls';
import API from '../../api';
import PoliciesGrid from './PoliciesGrid';
import TrustBuilderSection from './TrustBuilderSection';
import LoadingContent from '../layout/LoadingContent';
import TrustBuilderHeader from './TrustBuilderHeader';
import { _contentState } from './vcyso-utils/utils'
import { ScoreDistributionWidget } from './ScoreDistributionWidget';
import VcysoEditor from './VcysoEditor';
import { actionTypes } from '../../constants/navigation';
import { setAlert } from '../../actions/alerts';
import { triggerTopbarAction } from '../../actions/navigation';
import { trackPage } from '../../utils/gaTracker';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useWindowSize from '../../hooks/useWindowSize';
import usePostPDFGenerator from '../../hooks/usePostPDFGenerator';
import useCustomQuery from '../../hooks/useCustomQuery';

const TrustBuilder = (props) => {
  //  Parsing props
  const {
    exportContent = false,
    companyId,
    token,
    trustBuilderContentResponse,
    trustBuilderContentError,
    trustBuilderContentLoading,
    retryTrustBuilderRequest,
    hasVcyso,
  } = props;

  //  Initializing APIs
  const { DashboardAPI, VcysoAPI } = API;

  //  Memoized dispatch
  const { dispatch } = useMemoizedDispatch();

  //  Component state
  const [screenWidth, screenHeight] = useWindowSize();
  const [isInitialState, setIsInitialState] = useState(false);
  const [companyName, setCompanyName] = useState('');

  //  Component did mount
  useEffect(() => {
    if (exportContent) { return; }
    trackPage('cyso-trust-builder');
  }, []);

  //  Calling API for controls and solutions
  const {
    data: solutionsResponse,
    error: solutionsError,
    isLoading: solutionsLoading,
    refetch: refetchSolutionsAndControls,
  } = useCustomQuery(
    [VcysoAPI.keys.getSolutionsAndControls, { company_id: companyId, token }],
    ({ queryKey }) => VcysoAPI.handlers.getSolutionsAndControls(queryKey[1]),
  );

  //  Calling API for compliance
  const {
    data: complianceResponse,
    error: complianceError,
    isLoading: complianceLoading,
    refetch: refetchCompliance,
  } = useCustomQuery(
    [DashboardAPI.keys.getCompliance, { company_id: companyId, token }],
    ({ queryKey }) => DashboardAPI.handlers.getCompliance(queryKey[1]),
  );

  //  Watching trust builder content response
  useEffect(() => {
    if (!trustBuilderContentResponse) { return; }
    const { name: company_name } = trustBuilderContentResponse;
    setCompanyName(company_name || '');
  }, [trustBuilderContentResponse]);

  //  Hook for getting PDF
  const { response: pdfResponse, error: pdfError } = usePostPDFGenerator({
    queryParam: companyId,
    invalidators: VcysoAPI.invalidators.generateTrustBuilderPDF,
    apiFunction: VcysoAPI.handlers.generateTrustBuilderPDF,
    actionType: actionTypes.DOWNLOAD_PDF,
  });

  //  Watching PDF error
  useEffect(() => {
    if (!pdfError) { return; }
    dispatch(triggerTopbarAction(null, null));
    dispatch(setAlert('Error generating PDF', 'danger'));
  }, [pdfError]);

  //  Callback to retry request
  const retryRequests = () => {
    const company_id = companyId;
    if (solutionsError) {
      refetchSolutionsAndControls();
    }

    if (trustBuilderContentError) {
      retryTrustBuilderRequest({ company_id, token });
    }

    if (complianceError) {
      refetchCompliance();
    }
  }

  useEffect(() => {
    setIsInitialState(_.isNil(complianceResponse?.distribution?.previous));
  }, [setIsInitialState, complianceResponse?.distribution])

  //  Function to render information bar
  const renderInfoBar = () => {
    const informationToRender = !hasVcyso ? trustBuilderFreemiumBar : trustBuilderSubscribedBar;
    const { title, descriptions, hasContactUs } = informationToRender;
    return (
      <InformationExpandableBar
        title={title}
        content={descriptions}
        isFreemium={!hasVcyso}
        hasHelpMessage={hasContactUs}
      />
    );
  };

  //Function to render Trust Builder Header
  const renderTrustBuilderHeader = () => {
    return (
      <>
        <TrustBuilderHeader companyName={companyName} />
        <LoadingContent
          isLoading={trustBuilderContentLoading ? "Oops! our fault, reload again." : ""}
          errorMessage={trustBuilderContentError ? "Oops! our fault, reload again." : ""}
          iconType="solidIcon"
          errorStyle={{ minHeight: "225px" }}
          loadingStyle={{ minHeight: "225px" }}
          errorButtonCallback={retryRequests}
        >
          <VcysoEditor
            defaultData={trustBuilderContentResponse?.default}
            currentData={trustBuilderContentResponse?.current}
            fieldName="header_description"
            preview={!hasVcyso}
            exportContent={exportContent}
            showSubscriptionLink={true}
            companyId={companyId}
          />
        </LoadingContent>
      </>
    );
  }

  //  Function to render Solutions and Controls
  const renderSolutionsAndControls = () => {

    const isReady = !solutionsLoading && !solutionsError;
    const showCompliant = !isReady || (!_.isEmpty(solutionsResponse?.solutions?.compliant) || !_.isEmpty(solutionsResponse?.controls?.compliant));
    const showRoadmap = !isReady || (!_.isEmpty(solutionsResponse?.solutions?.no_compliant) || !_.isEmpty(solutionsResponse?.controls?.no_compliant));

    return (
      <>
        {showCompliant && (
          <VcysoSolutions
            title="Compliant on the following CIS controls and solutions"
            sectionName="Compliance"
            isLoading={solutionsLoading}
            errorMessage={solutionsError ? "Oops! our fault, reload again." : null}
            controls={solutionsResponse?.controls?.compliant}
            solutions={solutionsResponse?.solutions?.compliant}
            retryRequests={retryRequests}
            preview={!hasVcyso}
            iconsFolder={solutionsResponse?.folder}
          />
        )}
        {showRoadmap && (
          <VcysoSolutions
            title="Roadmap"
            sectionName="Roadmap"
            isLoading={solutionsLoading}
            errorMessage={solutionsError ? "Oops! our fault, reload again." : null}
            controls={solutionsResponse?.controls?.no_compliant}
            solutions={solutionsResponse?.solutions?.no_compliant}
            retryRequests={retryRequests}
            preview={!hasVcyso}
          />
        )}
      </>
    );
  };

  const renderTrustBuilderPolicy = () => {
    const preview = !hasVcyso && !trustBuilderContentLoading && !trustBuilderContentError;
    const dummyPolicyText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed volutpat odio orci, ac posuere velit vehicula at. Nunc malesuada vel ex nec commodo. Maecenas semper sapien a tempor malesuada. Mauris posuere purus non augue tincidunt consequat. Nunc eget pulvinar lectus. Integer consequat aliquet tempus. Maecenas urna sapien, varius sit .`
    return (
      <TrustBuilderSection
        title="Policies"
        preview={preview}
        sectionName="Policies"
      >
        <LoadingContent
          isLoading={(trustBuilderContentLoading) ? "Oops! our fault, reload again." : ""}
          errorMessage={(trustBuilderContentError) ? "Oops! our fault, reload again." : ""}
          iconType="solidIcon"
          errorStyle={{ minHeight: "225px" }}
          loadingStyle={{ minHeight: "225px" }}
          errorButtonCallback={retryRequests}
        >
          <div className="mb-3">
            <VcysoEditor
              defaultData={trustBuilderContentResponse?.default}
              currentData={trustBuilderContentResponse?.current}
              fieldName="policies_text"
              preview={!hasVcyso}
              exportContent={exportContent}
              companyId={companyId}
              dummyText={dummyPolicyText}
            />
          </div>
          <PoliciesGrid
            companyId={companyId}
            token={token}
            preview={!hasVcyso}
          />
        </LoadingContent>
      </TrustBuilderSection>
    )
  };

  //Render Compliance Score Distribution section

  const renderComplianceScoreDistribution = () => {
    const preview = !hasVcyso && !complianceError && !complianceLoading && !trustBuilderContentLoading && !trustBuilderContentError;
    const complianceDummyText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed volutpat odio orci, ac posuere velit vehicula at. Nunc malesuada vel ex nec commodo. Maecenas semper sapien a tempor malesuada. Mauris posuere purus non augue tincidunt consequat. Nunc eget pulvinar lectus. Integer consequat aliquet tempus. Maecenas urna sapien, varius sit amet ultricies vel, molestie eu turpis. Quisque tempor elementum lectus, vitae laoreet mauris tincidunt ac. Maecenas quam ex, feugiat in sollicitudin in, sollicitudin eget risus. Quisque in elit id purus dictum eleifend. Sed arcu mi, iaculis non eros sit amet, tempor lobortis enim. Ut mi odio, suscipit eu .`
    return (
      <TrustBuilderSection
        title="Compliance Score Distribution"
        preview={preview}
        sectionName="Score Distribution"
      >
        <LoadingContent
          isLoading={(complianceLoading || trustBuilderContentLoading) ? "Oops! our fault, reload again." : ""}
          errorMessage={(complianceError || trustBuilderContentError) ? "Oops! our fault, reload again." : ""}
          iconType="solidIcon"
          errorStyle={{ minHeight: "225px" }}
          loadingStyle={{ minHeight: "225px" }}
          errorButtonCallback={retryRequests}
        >
          <div style={{ marginBottom: "40px" }}>
            <div className="mb-3">
              <VcysoEditor
                defaultData={trustBuilderContentResponse?.default}
                currentData={trustBuilderContentResponse?.current}
                fieldName="cis_upper_text"
                preview={!hasVcyso}
                exportContent={exportContent}
                companyId={companyId}
                dummyText={complianceDummyText}
              />
            </div>

            {complianceResponse && (
              <div className="px-3">
                <ScoreDistributionWidget
                  compliance={complianceResponse}
                  isInitialState={isInitialState}
                  screenWidth={screenWidth}
                  showNowState={true}//always true for vCySO
                  screenWidth={screenWidth}
                  isLoading={complianceLoading}
                  errrorMessage={complianceError}
                />
              </div>
            )}

            <VcysoEditor
              defaultData={trustBuilderContentResponse?.default}
              currentData={trustBuilderContentResponse?.current}
              fieldName="cis_bottom_text"
              preview={!hasVcyso}
              exportContent={exportContent}
              companyId={companyId}
              dummyText={complianceDummyText}
            />
          </div>
        </LoadingContent>
      </TrustBuilderSection>
    )
  }

  //Render Additional Comments
  const renderAdditionalComments = () => {
    if (exportContent && !trustBuilderContentResponse?.current?.additional_comment) return null;
    return (
      <TrustBuilderSection
        title="Additional Comments"
      >
        <LoadingContent
          isLoading={trustBuilderContentLoading ? "Oops! our fault, reload again." : ""}
          errorMessage={trustBuilderContentError ? "Oops! our fault, reload again." : ""}
          iconType="solidIcon"
          errorStyle={{ minHeight: "225px" }}
          loadingStyle={{ minHeight: "225px" }}
          errorButtonCallback={retryRequests}
        >
          <VcysoEditor
            defaultData={trustBuilderContentResponse?.default}
            currentData={trustBuilderContentResponse?.current}
            fieldName="additional_comment"
            preview={!hasVcyso}
            exportContent={exportContent}
            companyId={companyId}
            placeholder={"Any additional comments we might have?"}
          />
        </LoadingContent>
      </TrustBuilderSection>
    )
  }

  //Render preview label for export
  const renderExportPreview = () => {
    return (
      <>
        <div className="preview-note text-center">This is a preview of this document. Subscribe to CySO to get the full version.</div>
        <div className="export-preview-watermark" />
      </>
    )
  }

  const renderSectionDivider = () => {
    return <hr className='style-line' />;
  }

  //  Rendering
  return (
    <div className="vcyso-container">
      <div className="trust-builder-container">
        {(exportContent && !hasVcyso) && renderExportPreview()}
        {!exportContent && renderInfoBar()}
        {renderTrustBuilderHeader()}
        {renderSectionDivider()}
        {renderComplianceScoreDistribution()}
        {renderSolutionsAndControls()}
        {renderSectionDivider()}
        {renderTrustBuilderPolicy()}
        {renderAdditionalComments()}
      </div>
    </div>
  )
};

export default TrustBuilder;
