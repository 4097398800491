import FreemiumIcon from '../assets/images/freemium-icon.svg';
import BronzeIcon from '../assets/images/bronze-icon.svg'
import SilverIcon from '../assets/images/silver-icon.svg'
import GoldIcon from '../assets/images/gold-icon.svg'

export const planIcons = {
  Freemium: FreemiumIcon,
  Bronze: BronzeIcon,
  Silver: SilverIcon,
  Gold: GoldIcon,
}

export const planTitles = {
  Freemium: 'Freemium',
  Bronze: 'Cybersecurity Foundations',
  Silver: 'Cybersecurity Prevention',
  Gold: 'Cyber Prevention & Cloud',
}


export const planSubscription = {
  Freemium: 'Freemium',
  Bronze: 'Foundations',
  Silver: 'Prevention',
  Gold: 'Prevention & Cloud',
}