import React from 'react';

const NavigationTextRow = (props) => {
  //  Parsing props
  const {
    elements = [],
    delimiter = '>',
    rowExtraClassName = '', // Used to set the font of the row
  } = props;

  //  Function to render elements
  const renderElements = () => {
    return elements.map((item, index) => {
      const { label, callback } = item;
      const isActive = !!callback;
      return (
        <>
          <div
            className={`navigation-text-item${isActive ? ' active' : ''}`}
            onClick={() => callback && callback()}
          >
            {label}
          </div>
          {index < (elements.length - 1) && <div className="navigation-text-item">{delimiter}</div>}
        </>
      )
    })
  };

  //  Rendering
  return (
    <div className={`navigation-text-container ${rowExtraClassName}`}>
      {renderElements()}
    </div>
  );
};

export default NavigationTextRow;
