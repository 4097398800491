import React from 'react';
import ScheduleScanModal from './ScheduleScanModal';
import DeleteDomainModal from './DeleteDomainModal';

const DomainActionsModals = (props) => {
  //  Parsing props
  const {
    company_id,
    showScheduleModal = false,
    showDeleteModal = false,
    public_ids = [],
    toggleScheduleModal,
    toggleDeleteModal,
    updateDomainCount,
  } = props;

  const renderScheduleModal = () => {
    if (!showScheduleModal) { return null; }
    return (
      <ScheduleScanModal
        domainPublicIds={public_ids}
        showModal={showScheduleModal}
        toggleModal={toggleScheduleModal}
        companyId={company_id}
      />
    );
  };

  const renderDeleteModal = () => {
    if (!showDeleteModal) { return null; }
    return (
      <DeleteDomainModal
        domainPublicIds={public_ids}
        showModal={showDeleteModal}
        toggleModal={toggleDeleteModal}
        companyId={company_id}
        onSuccessDispatch={updateDomainCount}
      />
    );
  };

  //  Rendering
  return (
    <>
      {renderScheduleModal()}
      {renderDeleteModal()}
    </>
  );
};

export default DomainActionsModals;
