import React from 'react';
import { useSelector } from 'react-redux';
import DashboardWidgetContainer from '../../common/dashboard/DashboardWidgetContainer';
import API from '../../../../api';
import companyIdSelector from '../../../../selectors/companyIdSelector';
import useCustomQuery from '../../../../hooks/useCustomQuery';
import { chartColumnBasicConfig } from '../../../../constants/dashboard';
import { useHistory } from 'react-router-dom';
import { arrangeCurrentProtectionData, formatData } from '../../../../utils/dashboardHelpers';
import DashboardBarChart from './DashboardBarChart';

const CurrentProtectionStatus = () => {
  //  Initializing API
  const { DeviceAPI } = API;

  //  Watching redux store
  const company_id = useSelector((state) => companyIdSelector(state));
  const solution_id = useSelector((state) => state?.issues?.filters?.solution_id);

  //  Component hooks
  const history = useHistory();

  //  Calling API
  const {
    data: currentProtectionData,
    error: currentProtectionError,
    isLoading: currentProtectionLoading,
    refetch: retryRequest,
  } = useCustomQuery(
    [DeviceAPI.keys.getCurrentProtection, company_id ],
    ({ queryKey }) => DeviceAPI.handlers.getCurrentProtection(queryKey[1]),
  );


  // Function to render tooltip
  const renderTooltip = (opts) => {
    const { dataPointIndex, w, seriesIndex, series } = opts;
      return (`
        <div class="apexcharts-doughnut-tooltip apexcharts-basic-tooltip arrow-down">
        <div class="series-label">${w.globals.labels[dataPointIndex]}</div>
        <div class="series-label">${w.config.series[seriesIndex]?.name}</div>
        <div>${parseInt(series[seriesIndex][dataPointIndex] || 0)}%</div>
        </div>
    `);
  };

    // Function to render tooltip
  const getSeries = (data, total) => {
    if (total.patches===0 && total.scans===0){
      return ([{data: [{x:0, y:0}]}])
    }
    return(
  [{name:'Scans', data: formatData(data.scans,total.scans,solution_id)},
  {name:'Patches', data: formatData(data.patches,total.patches,solution_id)},]
    )
  };


  const renderChart = (props) => {
    const {
      data,
      total,
      xAxisCategories
    } = props;

    return (
      <DashboardBarChart
        title="Current Protection Status"
        chartLoading={currentProtectionLoading}
        chartError={currentProtectionError}
        retryRequest={retryRequest}
        optionsChart={chartColumnBasicConfig(xAxisCategories,renderTooltip)}
        series={getSeries(data,total)}
        renderEmpty={total.patches===0 && total.scans===0}
        widgetId="current-protection-status"
        barClassName='current-protection-status'
      />
    );
  };

  //  Function to render the required rollup widget
  const renderRequiredWidget = () => {
    const widgetConfig = arrangeCurrentProtectionData(currentProtectionData);
    return renderChart(widgetConfig);
  };

  //  Rendering
  return (
    <DashboardWidgetContainer
      hasMessage
      message="The percentage of your devices in each solution"
      containerClassName="dashboard-widget-container"
    >
        {renderRequiredWidget()}
    </DashboardWidgetContainer>
  );
};

export default CurrentProtectionStatus;
