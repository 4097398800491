import React, { useState } from 'react';
import { ModalFooter, Button } from 'reactstrap';
import BasicModal from '../../../layout/BasicModal';
import PartnerFilters from './PartnerFilters';
import {
  getPartnerFilters,
  removeActiveFiltersByKeys,
  getPartnerActiveFilters,
  addPartnerActiveFilter,
} from '../../../../utils/groupHelpers';

const PartnerFiltersModal = (props) => {
  //  Parsing props
  const {
    initialFilters = {},
    filters = {},
    partner_configs = [],
    activeFilters = [],
    setFilters = () => { },
    toggleModal = () => { },
    setActiveFilters = () => { }
  } = props;

  //  Component state
  const [localFilters, setLocalFilters] = useState(getPartnerFilters(filters, partner_configs));
  const [localActiveFilters, setLocalActiveFilters] = useState(getPartnerActiveFilters(activeFilters, partner_configs));

  //  Function to handle local filters changes
  const onLocalFilterChange = (attribute, label, option) => {
    //  Setting filters
    setLocalFilters({ ...localFilters, [attribute]: option?.value });

    const newActiveFilters = option?.value !== null ? addPartnerActiveFilter(attribute, label, option, localActiveFilters) :
      removeActiveFiltersByKeys([attribute], localActiveFilters);

    //  Setting active filters
    setLocalActiveFilters(newActiveFilters);
  };

  //  Function to handle click
  const onClearAllClick = () => {
    //  Building new filters and changing filters
    const partnerInitialFilters = getPartnerFilters(initialFilters, partner_configs);
    const newFilters = { ...filters, ...partnerInitialFilters };
    setFilters(newFilters, true);

    //  Modifying active filters
    const keys = Object.keys(partnerInitialFilters);
    const newActiveFilters = removeActiveFiltersByKeys(keys, activeFilters);
    setActiveFilters(newActiveFilters);

    toggleModal(false);
  };

  //  Function to handle apply filter
  const onApplyFiltersClick = () => {
    //  Building new filters and changing filters
    const newFilters = { ...filters, ...localFilters };
    setFilters(newFilters, true);

    //  Changing active filters
    const keys = Object.keys(localFilters);
    const clearedActiveFilters = removeActiveFiltersByKeys(keys, activeFilters);
    setActiveFilters([...clearedActiveFilters, ...localActiveFilters]);

    toggleModal(false);
  };

  //  Function to render custom modal footer
  const renderCustomFooter = () => {
    return (
      <ModalFooter className="m-0">
        <div className="text-center" >
          <Button
            color="link"
            className="text-white font-weight-bold px-2"
            onClick={onClearAllClick}
            style={{ marginRight: "11px" }}
          >
            Clear Partner Filters
          </Button>
          <Button
            className="btn btn-light text-dark font-weight-bold"
            onClick={onApplyFiltersClick}
          >
            Apply Filters
          </Button>
        </div>
      </ModalFooter>
    );
  };

  //  Rendering
  return (
    <BasicModal
      title="Partners filters"
      customClassName="issues-advanced-filtering"
      showModal={true}
      toggleModal={() => toggleModal(false)}
      scrollable={true}
      customFooter={renderCustomFooter()}
    >
      <PartnerFilters
        filters={localFilters}
        partner_configs={partner_configs}
        onFilterChange={onLocalFilterChange}
      />
    </BasicModal>
  );
};

export default PartnerFiltersModal;
