export const dashboardOverviewsIds = {
  ICARM_DISTRIBUTION: 'ICARM_DISTRIBUTION',
  TOP_FIVE_ISSUES: 'TOP_FIVE_ISSUES'
};

export const severityItems = [
  {label:'critical_count', value:100},
  {label:'high_count', value:200},
  {label:'medium_count', value:300},
  {label:'low_count', value:400}
]
