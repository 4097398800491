import React, {useState} from 'react';
import { connect } from 'react-redux';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { Row, Col } from "reactstrap";
import amplitude from 'amplitude-js';

const SettingOption = (props) => {
  const {
    title,
    description,
    action,
    route,
    showSwitch,
    switchValue,
    amplitudeEvent
  } = props;

  const [currentValue, setSwitchValue] = useState(switchValue);
  const [disabled, setDisabled] = useState(false);

  if (disabled) {
    return <Redirect to={{ pathname: "/mfa-sms", state: { disabled: true } }} />
  }

  return (
    <div className="option-container">
      <div className="option-title" role="text" aria-label={`${title}\n${description}`}>
        {title}
        <p className="option-title-description" aria-hidden="true">{description}</p>
      </div>
      <Row>
        <Col md={8} sm={8} xs={7}>
          <p className="option-action">{action}</p>
        </Col>
        <Col md={4} sm={4} xs={5}>
          <div className="d-flex flex-row">
            <div className="option-action-link">
              <Link
                to={route}
                onClick={()=>{
                  if(amplitudeEvent) amplitude.getInstance().logEvent(amplitudeEvent) 
                }}
              >Change</Link>
            </div>
            {showSwitch &&
              <Col md={2} sm={4}>
                <div className="custom-control custom-switch custom-switch-lg mb-3" style={{ marginTop: "5px" }}
                  onClick={() => {
                    setSwitchValue(!currentValue);
                    setDisabled(true);
                  }}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`switch-${route}`}
                    checked={currentValue}
                    readOnly
                  />
                  <label
                    className="custom-control-label enable-label"
                    htmlFor={`switch-${route}`}
                  >
                    {currentValue ? "On" : "Off"}
                  </label>
                </div>
              </Col>
            }
          </div>
        </Col>
      </Row>
    </div>
  );
}

const mapStatetoProps = state => {
  const { error, isAuthenticated } = state.auth;
  return { error, isAuthenticated };
}

export default withRouter(connect(mapStatetoProps)(SettingOption));

