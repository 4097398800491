import { convertToRaw } from 'draft-js';
import React, { useState } from 'react';
import { Button, ModalFooter } from 'reactstrap';
import { setAlert } from '../../../../actions/alerts';
import { alertTypes } from '../../../../constants/alerts';
import useCustomMutation from '../../../../hooks/useCustomMutation';
import useShowModal from '../../../../hooks/useShowModal';
import RemediationNoteEditor from '../../../issue-manager/RemediationNoteEditor';
import BasicModal from '../../../layout/BasicModal';
import CustomCheckBox from '../../../layout/CustomCheckBox';
import API from '../../../../api';
import useMemoizedDispatch from '../../../../hooks/useMemoizedDispatch';
import { useSelector } from 'react-redux';
import useHandleApiResponse from '../../../../hooks/useHandleApiResponse';

const GroupDetailsBulkNotes = (props) => {
  const { issuesCount, groupPublicId, isDisabled } = props;

  //  Initializing API
  const { GroupAPI } = API;

  // Getting needed info from redux store
  const user = useSelector((state) => state.auth.user);

  // Component state
  const [editorState, setEditorState] = useState(null);
  const [isRemediationNote, setIsRemediationNote] = useState(false);

  // Component hooks
  const { dispatch } = useMemoizedDispatch();
  const { showModal: showNotesModal, toggleModal: toggleNotesModal } = useShowModal();
  const {
    data: updateData,
    error: updateError,
    isLoading: updateLoading,
    mutate: updateGroup,
  } = useCustomMutation(
    (params) => GroupAPI.handlers.updateGroup(params),
    GroupAPI.invalidators.updateGroup
  );

  // Handle closing modal
  const onCloseModal = () => {
    setEditorState(null);
    setIsRemediationNote(false);
    toggleNotesModal(false);
  }
  const onSuccessCallBack = () => {
    onCloseModal();
  }
  useHandleApiResponse({
    data: updateData,
    errorData: updateError,
    successMessage: "Issues updated",
    successCallback: onSuccessCallBack
  });

  // Get issues count label
  const getSelectedIssuesCountLabel = () => {
    const count = issuesCount || 0;
    const itemType = 'issue';
    const itemLabel =
      count !== 1 ? `selected ${itemType}s` : `selected ${itemType}`;
    return `${count} ${itemLabel}`;
  };

  // Function to get title
  const getTitle = () => {
    return `Add a Note for the ${getSelectedIssuesCountLabel()}`;
  };

  // Render instructions message
  const renderMessage = () => {
    const itemType = 'issues';
    const message = `This note will be applied and visible to the selected ${itemType}.`;
    return <div className="remediation-message">{message}</div>;
  };

  // Render check box for adding remediation notes
  const renderRemediationNoteCheckbox = () => {
    return (
      <div className="mr-2">
        <CustomCheckBox
          isSelected={isRemediationNote}
          targetId="remediation-note-checkbox"
          callback={() => {
            setIsRemediationNote(!isRemediationNote);
          }}
          label="This is a remediation note"
          isDisabled={updateLoading}
        />
      </div>
    );
  };

  // Render text editor
  const renderTextEditor = () => {
    return (
      <>
        <RemediationNoteEditor
          editorState={editorState}
          setEditorState={setEditorState}
          user={user}
          placeholder=" "
        />
      </>
    );
  };

  // Render modal footer
  const renderFooter = () => {
    return (
      <ModalFooter className="mx-0">
        <div className="d-flex justify-content-end align-items-center">
          {renderRemediationNoteCheckbox()}
          <Button
            className={`btn btn-light text-dark font-weight-bold`}
            type="submit"
            onClick={onPostNote}
            disabled={updateLoading || isDisabled}
          >
            {!updateLoading ? 'Post note' : 'Processing...'}
          </Button>
        </div>
      </ModalFooter>
    );
  };

  // Get note from editor
  const getNote = () => {
    const raw = convertToRaw(editorState.getCurrentContent());
    return {
      updated_at: new Date(),
      user: {
        id: user.id,
        full_name: user.name,
        profile_image: user.profileImage,
      },
      content: raw,
      is_remediation: isRemediationNote,
    };
  };


  // On post note click
  const onPostNote = () => {
    if (!editorState) {
      dispatch(setAlert('Please enter a note', alertTypes.error));
      return;
    }

    // Get note from editor
    const note = getNote();
    const queryParams = { public_id: groupPublicId, body: {} };
    if (isRemediationNote) {
      queryParams.body.status = 4;
      queryParams.body.remediation_note = note;
    } else {
      queryParams.body.comments = note;
    }
    updateGroup(queryParams);
  };

  // Render notes modal
  const renderNotesModal = () => {
    if (!showNotesModal) return null;
    return (
      <BasicModal
        header={getTitle()}
        headerClassName={`modal-header-status`}
        showModal={showNotesModal}
        toggleModal={onCloseModal}
        customClassName="issues-remediation-note-modal"
        scrollable={true}
        customFooter={renderFooter()}
      >
        {renderMessage()}
        {renderTextEditor()}
      </BasicModal>
    );
  };

  return (
    <>
      {renderNotesModal()}
      <Button
        className="btn-add-bulk-notes btn-small d-flex align-items-center"
        color="light"
        onClick={toggleNotesModal}
      >
        <i className="bx bx-plus-circle mr-2" />
        Add a bulk note
      </Button>
    </>
  );
};

export default GroupDetailsBulkNotes;
