import React from 'react';
import moment from 'moment-timezone';
import GrcSimpleSelect from './GrcSimpleSelect';
import IssuesAssigneeFilter from '../../issue-manager/issue-filters/IssuesAssigneesFilter';
import CustomDatepicker from '../../calendar/CustomDatepicker';
import GrcIssuesPrioritySelect from './GrcIssuesPrioritySelect';
import CompanyInfiniteDropdown from '../../layout/CompanyInfiniteDropdown';
import { getIssueCriticalityClassName, getIssueStatusValueByUserType } from '../../../utils/issuesHelpers';
import { getValidationKeyError } from '../../../utils/joiValidations';
import useCanAbility from '../../../hooks/useCanAbility';
import { PRIVILEGE_SOURCES, READ_ACTION } from '../../../constants/privileges';
import { useSelector } from 'react-redux';
import useUserType from '../../../hooks/useUserType';

const GrcIssueSelectAttributes = (props) => {
  //  Parsing props
  const {
    issue,
    changeIssue,
    isUpdating = false,
    changeIssueCompany,
    isSubTask = false,
    validationError,
  } = props;

  // Components Hooks
  const ability = useCanAbility();
  const userType = useUserType();

  // Getting needed info from redux store
  const isMemberView = useSelector(state => state.impersonation.isMemberView);

  //  Function to handle company change
  const handleCompanyChange = (option) => {
    changeIssueCompany(option);
  };

  const selectChange = (issueKey, value) => {
    changeIssue(issueKey, value)
  }

  //  Function to render simple dropdpown
  const renderSimpleDropdown = (label, placeholder, configEntry, issueKey, extraClassName = '') => {
    const error = getValidationKeyError(validationError, issueKey);
    const selectedId = issueKey === 'status' ? getIssueStatusValueByUserType(issue?.[issueKey], userType) : issue?.[issueKey];
    return (
      <GrcSimpleSelect
        label={label}
        placeholder={placeholder}
        configEntry={configEntry}
        selectedId={selectedId}
        extraClassName={extraClassName}
        changeIssue={(value) => selectChange(issueKey, value)}
        error={error}
      />
    );
  };

  //  Function to render custom datepicker
  const renderDatePicker = () => {
    const currentDate = issue?.due_date ? new Date(issue?.due_date) : null;
    return (
      <div className="dropdown-parent-container">
        <div className="dropdown-container">
          <div className="dropdown-label">Due Date</div>
          <CustomDatepicker
            value={currentDate}
            setValue={(value) => changeIssue('due_date', value)}
          />
        </div>
      </div>
    );
  }

  //  Function to render assignee filter
  const renderAssigneeFilter = () => {
    return (
      <div className="dropdown-parent-container">
        <div className="dropdown-container">
          <div className="dropdown-label">Assignee</div>
          <IssuesAssigneeFilter
            selectedCompany={issue?.company_id}
            wrapperClassName="dropdown-select"
            filterValue={issue?.assigned_to}
            placeholder="Assignee"
            showAllMembersOption={false}
            showClearButton={false}
            setFilterCallback={(option) => changeIssue('assigned_to', option?.value)}
          />
        </div>
      </div>
    );
  };

  //  Function to render priority select
  const renderPrioritySelect = () => {
    const error = getValidationKeyError(validationError, 'priority');
    return (
      <div className="dropdown-parent-container">
        <div className="dropdown-container">
          <div className="dropdown-label">Priority</div>
          <GrcIssuesPrioritySelect
            selectedPriority={issue?.priority}
            extraClassName="dropdown-select"
            onChange={(option) => changeIssue('priority', option?.value)}
            error={error}
          />
        </div>
      </div>
    );
  };

  //  Function to render information item
  const renderInfoItem = (label, value) => {
    if (!value) { return null; }
    return (
      <div className="dropdown-parent-container">
        <div className="dropdown-container">
          <div className="dropdown-label">{label}</div>
          <div className="info-container">{value}</div>
        </div>
      </div>
    );
  };

  //  Function to render info section
  const renderInfoSection = () => {
    if (!isUpdating) { return null; }
    //  Getting lastUpdated
    const lastUpdated = issue?.updated_at ? moment(issue?.updated_at).format('MM/DD/YYYY, h:mm a') : null;

    //  Rendering
    return (
      <>
        {renderInfoItem('Last update', lastUpdated)}
      </>
    );
  };

  // Function to render error message
  const renderError = (error) => {
    if(!error) return null;
    return (
      <div className="grc-input-error mt-2">
          {error?.message}
      </div>
    )
  }

  //  Function to render company infinite dropdown
  const renderCompanyDropdown = () => {
    const canViewCompanyDropdown = ability.can(READ_ACTION, PRIVILEGE_SOURCES.ISSUES.COMPANY_DROPDOWN)
    if (isUpdating || !canViewCompanyDropdown || isMemberView || isSubTask) { return null; }
    const error = getValidationKeyError(validationError, 'company_id');
    return (
      <div className="dropdown-parent-container">
        <div className="dropdown-container">
          <div className="dropdown-label">Company</div>
          <div className='grc-details-company-container'>
            <CompanyInfiniteDropdown
              selectedValue={issue?.company_id}
              selectedLabel={issue?.company_name}
              placeholder='Select company'
              className='member-filter filter-dropdown w-100'
              onSelectCallback={handleCompanyChange}
              showClearButton={false}
            />
            {renderError(error)}
          </div>
        </div>
      </div>
    );
  }

  //  Rendering
  return (
    <div className="grc-dropdowns-container">
      {renderCompanyDropdown()}
      {renderSimpleDropdown('Status', 'Status', 'statuses', 'status')}
      {renderSimpleDropdown('Criticality', 'Criticality', 'criticalities', 'severity', getIssueCriticalityClassName(issue?.severity))}
      {renderPrioritySelect()}
      {renderAssigneeFilter()}
      {renderDatePicker()}
      {renderInfoSection()}
    </div>
  );
};

export default GrcIssueSelectAttributes;
