import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Progress, Row } from 'reactstrap';
import CustomSpinner from '../../layout/CustomSpinner';
import ErrorMessage from '../../layout/ErrorMessage';
import Question from '../Question';
import makeTotalQuestionsNumber from '../../../selectors/assessment/totalQuestionsNumberSelector';
import makeLoadingState from '../../../selectors/loadingSelector';
import makeErrorMessage from '../../../selectors/errorsSelector';
import makeSelectedQuestion from '../../../selectors/assessment/selectedQuestionSelector';
import makeAssessmentStatusSelector from '../../../selectors/assessment/assessmentStatusSelector';
import { toggleAssessmentCommentModal, showCommentButtonAction, showNextButtonAction } from '../../../actions/layout';
import { saveAnswers, fetchAssessmentExecution } from '../../../actions/answers';
import {
    setNextQuestion,
    setPreviousQuestion
} from '../../../actions/assessment';
import { trackEvent } from "../../../utils/gaTracker";
import AssessmentComment from '../AssessmentComment';
import "boxicons";
import MainScrollableContainer from '../../layout/MainScrollableContainer';

const BasicAssessment = (props) => {
    const {
        isAuthenticated,
        isLoading,
        errorMessage,
        selectedQuestion,
        totalQuestions,
        setNextQuestion,
        setPreviousQuestion,
        toggleAssessmentCommentModal,
        showCommentButtonAction,
        showNextButtonAction,
        history,
        assessmentStatus: { isAssessmentBeginning, isAssessmentEnd, currentQuestionOrder },
        showCommentButton,
        showNextButton,
        fetchAssessmentExecution,
        assessmentCompleted
    } = props;

    const totalQuestionsNumber = totalQuestions.total.questions;
    const [displayNextButton, setShowNextButton] = useState(false);

    useEffect(() => {
        if (assessmentCompleted || showNextButton && selectedQuestion && selectedQuestion.isAnswered) {
            setShowNextButton(true);
        } else if (!(selectedQuestion && selectedQuestion.isAnswered)) {
            setShowNextButton(false);
        }
    }, [selectedQuestion, showNextButton])

    const onNextQuestionClick = () => {
        trackEvent("next_question", "assessment", `next_question_from_${selectedQuestion ? selectedQuestion.id : ''}`);
        setNextQuestion();
        showNextButtonAction(false);
    }

    const onPreviousQuestionClick = () => {
        if (isAssessmentEnd) showCommentButtonAction(false);
        if (!isAssessmentBeginning) {
            trackEvent("previous_question", "assessment", `previous_question_from_${selectedQuestion ? selectedQuestion.id : ''}`);
            setPreviousQuestion();
        } else {
            history.push('/welcome')
        }
        showNextButtonAction(true);
    }

    const onTryAgainButtonClick = () => {
        fetchAssessmentExecution(isAuthenticated);
    }

    const tryAgainButton = (
        <button
            className="btn btn-light"
            onClick={onTryAgainButtonClick}
        >
            Try again
        </button>
    );

    const onReadyClick = () => {
        toggleAssessmentCommentModal(true)
    }

    return (
        <React.Fragment>
            <AssessmentComment />
            <MainScrollableContainer
                additionalClassName="assessment basic bg-assessment"
            >
                <div className="assessment-progress-bar w-100">
                    <Progress value={Math.floor((currentQuestionOrder) / (totalQuestionsNumber ? totalQuestionsNumber : 10) * 100)} />
                </div>

                <div className="total-progress d-inline-flex mb-2">
                    {isAssessmentBeginning || totalQuestionsNumber === 0 ? 'Start the Ignition' : `Question ${currentQuestionOrder}/${totalQuestionsNumber}`}
                </div>

                <div className="basic-assessment-container mx-auto" >
                    {errorMessage ? (
                        <ErrorMessage
                            text={errorMessage}
                            button={tryAgainButton}
                            customStyle={{ paddingTop: "15%" }}
                        />
                    ) : (
                        <React.Fragment>
                            {isLoading ? (
                                <div>
                                    <CustomSpinner
                                        customStyle={{ minHeight: "435px" }}
                                    />
                                </div>
                            ) : (
                                <React.Fragment>
                                    <Row>
                                        <div className="arrow-container">
                                            <button
                                                className="btn btn-link text-tertiary mr-auto"
                                                onClick={onPreviousQuestionClick}
                                                name="previous"
                                                aria-label={isAssessmentBeginning ? "Go Back" : "Previous Question"}
                                            >
                                                {/* <i className='bx bx-arrow-back' style={{ fontSize:"50px" }}  ></i> */}
                                                <box-icon name="arrow-back" size="50px" color="#B344B3" />
                                            </button>
                                        </div>

                                        <div className="question-image-container mx-auto" aria-hidden="true">
                                            {selectedQuestion ? (
                                                <img
                                                    alt="question-icon"
                                                    src={selectedQuestion.image}
                                                />
                                            ) : null}
                                        </div>
                                        <div className="arrow-container">
                                            {displayNextButton && !isAssessmentEnd ? (
                                                <button
                                                    className="btn btn-link text-tertiary ml-auto"
                                                    onClick={onNextQuestionClick}
                                                    name="next"
                                                    aria-label="Next Question"
                                                >
                                                    {/* <i className='bx bx-arrow-back flip-icon' style={{ fontSize:"50px" }}  ></i> */}
                                                    <box-icon name="arrow-back" size="50px" color="#B344B3" rotate="180" />
                                                </button>
                                            ) : null}
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="question-container">
                                            {selectedQuestion ? (
                                                <Question
                                                    id={selectedQuestion.id}
                                                    question={selectedQuestion.value}
                                                    type="basic"
                                                    info={selectedQuestion.info}
                                                //categoryId={categoriesIds[categoryIndex].id}
                                                //sectionId={categoriesIds[categoryIndex].sectionIds[sectionIndex].id}
                                                />
                                            ) : null}
                                        </div>
                                    </Row>
                                    <Row className="justify-content-end align-self-end">
                                        {/* <button
                                        className="btn btn-assessment btn-outline-dark d-flex align-items-center mb-2 ml-2"
                                        name="take-break"
                                        onClick={onTakeABreakClick}
                                    >
                                        <i className='bx bx-save mr-1' style={{ fontSize:"18px" }}  ></i>
                                        Take a break<span className="ml-1"></span>
                                    </button> */}

                                        {isAssessmentEnd && showCommentButton ? (
                                            <button
                                                className="btn btn-light text-dark d-flex align-items-center  mb-2 ml-2"
                                                name="ready"
                                                style={{ fontWeight: "600" }}
                                                onClick={onReadyClick}
                                            >
                                                <i className='bx bxs-right-arrow mr-2' style={{ fontSize: "18px" }} ></i>
                                                I'm Ready<span className="ml-1 float-right"></span>
                                            </button>

                                        ) : null}
                                    </Row>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </div>
            </MainScrollableContainer>
        </React.Fragment>
    )
}

const makeMapStateToProps = () => {
    const getTotalQuestions = makeTotalQuestionsNumber();
    const getSelectedQuestion = makeSelectedQuestion();
    const getAssessmentStatus = makeAssessmentStatusSelector();
    const getLoadingState = makeLoadingState(['ASSESSMENT', 'ANSWERS']);
    const getErrorMessage = makeErrorMessage(['ASSESSMENT', 'ANSWERS']);
    const mapStateToProps = (state) => {
        return {
            isAuthenticated: state.auth.isAuthenticated,
            isLoading: getLoadingState(state) || state.assessment.isLoading,
            errorMessage: getErrorMessage(state),
            selectedQuestion: getSelectedQuestion(state),
            totalQuestions: getTotalQuestions(state),
            assessmentStatus: getAssessmentStatus(state),
            assessmentId: state.assessment.assessment.id,
            showCommentButton: state.layout.showCommentButton,
            showNextButton: state.layout.showNextButton,
            assessmentCompleted: state.answers.showReportCard
        }
    }
    return mapStateToProps
}

const mapDispatchToProps = {
    saveAnswers,
    setNextQuestion,
    setPreviousQuestion,
    toggleAssessmentCommentModal,
    showCommentButtonAction,
    showNextButtonAction,
    fetchAssessmentExecution
}

export default withRouter(connect(
    makeMapStateToProps,
    mapDispatchToProps
)(BasicAssessment));

