import { ON_IMPERSONATION_LOADING, ON_IMPERSONATION_SUCCESS, SET_MEMBER_VIEW } from './types';

export const onImpersonationSuccess = (impersonationPayload) => {
  return {
    type: ON_IMPERSONATION_SUCCESS,
    payload: impersonationPayload,
  };
};

export const onImpersonationLoading = (loading) => {
  return {
    type: ON_IMPERSONATION_LOADING,
    payload: loading,
  };
};

export const setMemberView = (enabled,  companyId, companyName) => {
  return {
    type: SET_MEMBER_VIEW,
    payload: {
      enabled,
      companyName,
      companyId
    }
  }
}
