import React from 'react';
import { formatDate } from '../../../../../utils/helpers';
import CustomProgressBar from '../../../../layout/CustomProgressBar';

const IcarmSolutionProgressBar = (props) => {

  const {
    solution = {},
    progress = 0,
    formattedInstallationDate,
    daysRemainingUntilGuarantee,
    daysSoFar,
    cyvalueReachedDays, // Amount of days that cyvalue took to be achieved
    cyvalueDate, // Cyvalue reached date or Projection date
    lastNumberDays
  } = props;

  // Icon and message for the goal marker of the progress bar
  const goalMarkerIconMessage = () => {
    let progressBarWidth = getProgressWidthPercentage();
    progressBarWidth = progressBarWidth >= 75 ? 75 : progressBarWidth <= 32 ? 32 : progressBarWidth;
    const message = () => {
      if (solution?.cyvalue_reached_date === null) {
        if (daysSoFar <= 90) return [`Day ${daysSoFar || 1}`,'','on-track'];
        return [`Cyvalue goal missed`,'bx bx-x-circle','goal-missed'];
      } else {
        return [`Cyvalue achieved`,'bx bx-trophy','goal-achieved'];
      }
    }

    const values = message();
    return (<>
      <i style={{ left: progressBarWidth + "%" }} className={`goal-icon bx ${values[1]} ${values[2]}`}>
        <p style={{ left: progressBarWidth + "%" }} className={`goal-icon-message`}>{values[0]}</p>
      </i>
    </>);
  }

  // The labels of the total issues by solution card
  const renderIssuesAmount = (amount, subtitle) => {
    return (
      <div className='issues-amount'>
        <p className='subtitle'>{subtitle}</p>
        <p className='amount'>{amount}</p>
      </div>
    );
  };

  // Label below the bar to show if the solution is in onboarding or in maintenance
  const renderSolutionStateMessage = () => {
    if (solution?.cyvalue_reached_date === null) {
      return (
        <>
          <p>Day 1</p>
          <div className='in-onboarding'>
            <i className="bx bx-loader-circle"></i>
            <p>In onboarding</p>
          </div>
          {daysSoFar > 90 ? <p>{`Day ${daysSoFar}`}</p> : <p>{`Day 90`}</p>}
        </>
      );
    } else {
      return (
        <>
          <p>Day 1</p>
          <div className='cyvalue-reached'>
            <i className="bx bx-medal"></i>
            <p>{`Cyvalue Badge since ${cyvalueDate}`}</p>
          </div>
          <p>{`Cyvalue reached on Day ${cyvalueReachedDays || 1}`}</p>
        </>
      );
    }
  }

  // Label on the top right corner of the bar
  const getCyvalueDateLabel = () => {
    if (solution?.cyvalue_reached_date !== null) {
      return `Cyvalue date ${formatDate(cyvalueDate)}`;
    } else {
      return `Estimated Cyvalue date ${formatDate(cyvalueDate)}`
    }
  }

  // Used for determining the width of the 'progress' on top of the background bar
  const getProgressWidthPercentage = () => {
    let percentage;
    if (solution?.cyvalue_reached_date === null) {
      if (daysRemainingUntilGuarantee < 0) { // If projection date was missed
        percentage = (90 / (daysSoFar === 0 ? 1 : daysSoFar)) * 100;
      } else if (daysRemainingUntilGuarantee >= 0 && daysSoFar <= 90) {
        percentage = ((daysSoFar === 0 ? 1 : daysSoFar) / 90) * 100;
      }
    } else {
      percentage = (cyvalueReachedDays / (daysSoFar === 0 ? 1 : daysSoFar) ) * 100;
    }
    // needs to be verified
    return percentage < 5 ? 5 : percentage;
  };

  // Gets the color of the main bar, the background bar, to show if the solution is on time, overdue or on track
  const getBackgroundBarColor = () => {
    if (solution?.cyvalue_reached_date !== null) {
      return "#34c38f";
    } else if (solution?.cyvalue_reached_date === null && daysRemainingUntilGuarantee <= 0) {
      return '#f46a6a';
    } else {
      return '';
    }
  };

  // Determines what icon and color the solution state displays (on-time or overdue)
  const getSolutionState = () => {
    if (solution?.cyvalue_reached_date !== null) {
      return ['on-time','On time'];
    } else if (solution?.cyvalue_reached_date === null && daysRemainingUntilGuarantee <= 0) {
      return ['overdue','Overdue']
    } else {
      return ['on-time','On time'];
    }
  };

  const solutionStateConfig = getSolutionState();

  const renderSolutionInfoHeader = () => {
    return (
      <div className="solution-info-container">
        <div className="solution-info">
          <p
            className="solution-name"
            style={{ color: solution?.theme?.color }}
          >{`${solution?.name.replace("aaS", "")} Status`}</p>
          <div
            className="solution-state"
            //style={{ backgroundColor: solution?.theme?.color }}
          >
            {
              <div className={solutionStateConfig[0]}>
                <i className="bx bxs-timer"></i>
                <p>{solutionStateConfig[1]}</p>
              </div>
            }
          </div>
        </div>

        <p className="days-in-roadmap">{getCyvalueDateLabel()}</p>
      </div>
    );
  };

  const renderProgressBar = () => {
    return (
      <>
        <CustomProgressBar
          customClassName={"icarm-progress-bar"}
          themeColor={solution?.theme?.color}
          progressWidthPercentage={getProgressWidthPercentage()}
          maxProgressWidthPercentage={100}
          // Initial marker
          initialMarkerIcon={
            <>
              <i className="bx bx-badge-check"></i>
              <p className="initial-icon-message">{`Subscribed ${formattedInstallationDate}`}</p>
            </>
          }
          // Goal marker
          goalMarkerIcon={goalMarkerIconMessage()}
          showGoalMarker={true}
          backgroundBarColor={getBackgroundBarColor()}
        />
        <div className="solution-bar-state-message">
          {renderSolutionStateMessage()}
        </div>
      </>
    );
  };

  const renderTotalIssuesCard = () => {
    return (
      <div className="issues-resolved-card-container">
        <div className="issues-resolved-card">
          {renderIssuesAmount(solution?.issues_resolved, "Issues resolved")}
          {lastNumberDays > 0 ? (
            <p className='time-range'>{`Last ${lastNumberDays} days`}</p>
          ) : (
            <p className='time-range'>{`Custom range`}</p>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="icarm-solution-progress">
        <div className="solution-progress-bar">
          {renderSolutionInfoHeader()}
          {renderProgressBar()}
        </div>
          {renderTotalIssuesCard()}
      </div>
    </>
  );
}

export default IcarmSolutionProgressBar;