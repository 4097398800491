import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import DashboardWidgetContainer from '../../common/dashboard/DashboardWidgetContainer';
import TrendChartWrapper from '../../../dashboard/charts/tendency-chart/TrendChartWrapper';
import API from '../../../../api';
import {
  devicePointColorConfig,
  deviceTooltipLabelColorConfig,
  tendencyChartPointsByTimeUnit,
} from '../../../../constants/tendencyChart'

const InstallsBySolution = (props) => {
  //  Initializing API
  const { DeviceAPI } = API;

  //  Rendering
  return (
    <DashboardWidgetContainer
      hasMessage
      message="Your solution installs over time"
      containerClassName="dashboard-widget-container"
    >
      <div>
        <Card className="grc-trend-chart-card summary mb-0">
          <CardBody>
            <TrendChartWrapper
              isSummary={true}
              title="Installs by Solution"
              objects="devices"
              pointsColorConfig={devicePointColorConfig}
              labelColorConfig={deviceTooltipLabelColorConfig}
              pointsCountConfig={tendencyChartPointsByTimeUnit}
              apiKey={DeviceAPI.keys.getDevicesBySolution}
              apiFunction={DeviceAPI.handlers.getDevicesBySolution}
              emptyTitle='All quiet so far :)'
              emptySubtitle=''
              useLocalTimeFilter={false}
            />
          </CardBody>
        </Card>
      </div>
    </DashboardWidgetContainer>
  );
};

export default InstallsBySolution;
