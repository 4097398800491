

export const statusesConfig = [
    {
        label: "Unassigned",
        id: 0,
        order: 2,
        color: "#E6D5FB"
    },
    {
        label: "To Do",
        id: 1,
        order: 3,
        color: "#CDAAF8"
    },
    {
        label: "In Progress",
        id: 2,
        order: 1,
        color: "#FFFFFF"
    },
    {
        label: "Member Review",
        id: 3,
        order: 6,
        color: "#9A55F1"
    },
    {
        label: "Resolved",
        id: 4,
        order: 4,
        color: "#B380F4"
    },
    {
        label: "Re Opened",
        id: 7,
        order: 5,
        color: "#9C75FB"
    },
    {
        label: "Remediation Plan",
        id: 9,
        order: 8,
        color: "#fff"
    },
    {
        label: "Pending Review",
        id: 10,
        cydekickLabel: 'Vendor Review',
        memberLabel: 'To Do',
        memberValue: 1,
        order: 7,
        color: "#812BED",
    },
]
