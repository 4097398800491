import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Button, FormGroup, InputGroup, Label, Col } from "reactstrap";
import { Form, Field } from 'react-final-form'
import BasicModal from '../layout/BasicModal';
import { renderInput } from '../../utils/renderFormFields';
import { validateCydekickEmail } from '../../utils/validations';
import { setAlert } from '../../actions/alerts';
import { successActionCreator } from '../../actions/action-creators/success';
import { errorActionCreator } from '../../actions/action-creators/errors';
import { CYDEKICK_INVITE_SUCCESS, CYDEKICK_INVITE_FAILURE } from '../../actions/types';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import API from '../../api';
import useCustomMutation from '../../hooks/useCustomMutation';

const CydekickInvite = (props) => {
  //  Parsing params
  const {
    showModal,
    toggleModal,
    errorMessage,
    isLoading,
  } = props;

  //  Initializing APIs
  const { UserAPI } = API;

  //  Component state
  const [roleOption, setRoleOption] = useState('');
  const [roleName, setRoleName] = useState(null);

  //  Component hooks
  const {
    data: inviteResponse,
    error: inviteError,
    mutate: inviteCydekick,
  } = useCustomMutation(
    (params) => UserAPI.handlers.createCydekickInvite(params),
    UserAPI.invalidators.createCydekickInvite
  );
  const { dispatch } = useMemoizedDispatch();

  //  Watching API response
  useEffect(() => {
    if (!inviteResponse) { return; }
    dispatch(successActionCreator(CYDEKICK_INVITE_SUCCESS, inviteResponse?.data));
    const message = (
      <div className="d-inline-block">
        New cydekick invite sent
      </div>
    );
    dispatch(setAlert(message, 'success', true, 3000));
    closeModal();
  }, [inviteResponse]);

  //  Watching API error
  useEffect(() => {
    if (!inviteError) { return; }
    const message = inviteError?.response?.data?.errorMessage || 'Error sending invite';
    dispatch(errorActionCreator(CYDEKICK_INVITE_FAILURE, { message }));
    dispatch(setAlert(message, 'danger'));
    closeModal();
  }, [inviteError]);

  const closeModal = () => {
    toggleModal();
    setRoleOption(null);
  }

  const validate = (formValues) => {
    const errors = {};
    const {
      email,
    } = formValues;

    if (validateCydekickEmail(email)) {
      errors.email = validateCydekickEmail(email);
    }

    if (!roleOption) {
      errors.role = 'Please select a role for the cydekick.';
    }

    return errors;
  }

  const inviteCydekickPayload = (values) => {
    const {
      email,
    } = values;

    const formValues = {
      email,
      role_name: roleName,
    }

    return formValues;
  }

  //  Function to handle submit
  const onSubmit = (valueToSubmit) => {
    if (!roleName) { dispatch('Need to select a role. ', 'danger'); return; }
    const payload = inviteCydekickPayload(valueToSubmit);
    inviteCydekick(payload);
  }

  return (
    <BasicModal
      header="Invite team members to Cyvatar"
      headerClassName=""
      showModal={showModal}
      toggleModal={() => closeModal()}
      customClassName="team-manager-modal"
    >
      {!isLoading && !errorMessage && (
        <Form
          onSubmit={onSubmit}
          validate={validate}
          shouldValidate={true}
          render={({ handleSubmit, submitting, invalid, errors, values, form }) => (
            <form onSubmit={event => {
              if (invalid) {
                if (_.size(errors) == 1 && errors.role) {
                  dispatch(setAlert(errors.role, 'danger'));
                } else {
                  dispatch(setAlert('One or more fields have an error. Please check and try again', 'danger'));
                }
              }
              handleSubmit(event);
            }}>
              <div className="d-block w-100 text-center" >
                <FormGroup className="row">
                  <Label className="col-sm-3 col-form-label">Invite as</Label>
                  <InputGroup className="col-sm-9">
                    <Col sm={4}>
                      <Button
                        type="button"
                        color="light-gray"
                        className="btn-filter mb-2"
                        block outline
                        active={roleOption == 'squad_cydekick'}
                        onClick={() => {
                          setRoleName('squad_cydekick');
                          setRoleOption('squad_cydekick');
                        }}>
                        Cydekick
                      </Button>
                    </Col>
                    <Col sm={4}>
                      <Button
                        type="button"
                        color="light-gray"
                        className="btn-filter mb-2"
                        block outline
                        active={roleOption == 'squad_sales'}
                        onClick={() => {
                          setRoleName('squad_sales');
                          setRoleOption('squad_sales');
                        }}>
                        Sales
                      </Button>
                    </Col>
                    <Col sm={4}>
                      <Button
                        type="button"
                        color="light-gray"
                        className="btn-filter mb-2"
                        block outline
                        active={roleOption == 'squad_admin'}
                        onClick={() => {
                          setRoleName('squad_admin');
                          setRoleOption('squad_admin');
                        }}>
                        Admin
                      </Button>
                    </Col>
                  </InputGroup>
                </FormGroup>
                <Field
                  render={renderInput}
                  name="email"
                  label="Email"
                  placeholder="Email"
                  className="form-control"
                  type="email" />
                <Button
                  className={`btn btn-submit btn-light text-dark font-weight-bold ${submitting ? 'disabled' : ''}`}
                  type="submit"
                  disabled={submitting} >
                  {submitting ? 'Processing...' : 'Send invite'}
                </Button>
              </div>
            </form>
          )}
        />
      )}
    </BasicModal>
  );
}

export default CydekickInvite;

