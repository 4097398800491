import React, { useEffect, useState } from "react";
import API from "../../../api";
import { setAlert } from "../../../actions/alerts";
import { alertTypes } from "../../../constants/alerts";
import useMemoizedDispatch from "../../../hooks/useMemoizedDispatch";
import AttachmentPreview from "../../grc/grcDetails/AttachmentPreview";
import useCustomMutation from "../../../hooks/useCustomMutation";
import LoadingContent from "../../layout/LoadingContent";
import CustomActionModal from "../../layout/CustomActionModal";
import { isValidFunction } from "../../../utils/helpers";

const CompanyAttachmentsTableActions = (props) => {
  const { key, file } = props;

  //  Initializing API
  const { CompanyAPI } = API;

  //  Component state
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Component Hooks
  const { dispatch } = useMemoizedDispatch();
  const {
    data: deleteData,
    error: deleteError,
    mutate: deleteFile,
    isLoading: deleteLoading,
  } = useCustomMutation(
    (params) => CompanyAPI.handlers.deleteCompanyAttachment(params),
    CompanyAPI.invalidators.deleteCompanyAttachment
  );

  const {
    data: trackDownloadData,
    error: trackDownloadError,
    mutate: trackDownloadFile,
    isLoading: trackDownloadLoading,
  } = useCustomMutation(
    (params) => CompanyAPI.handlers.updateAttachmentDownload(params),
    CompanyAPI.invalidators.updateAttachmentDownload
  );

  //  Watching delete success
  useEffect(() => {
    if (!deleteData) {
      return;
    }
    dispatch(setAlert("File deleted successfully", alertTypes.success));
  }, [deleteData]);

  //  Watching delete error
  useEffect(() => {
    if (!deleteError) {
      return;
    }
    dispatch(setAlert("Error deleting file", alertTypes.error));
  }, [deleteError]);

  //  Function to handle delete click
  const onShowDelete = () => {
    setShowDeleteModal(true);
  };

  //  Function to delete file
  const onDelete = () => {
    deleteFile(file?.id);
    setShowDeleteModal(false);
  };

  const onDownload = () => {
    const body = {
      'attachment': file?.id
    };
    trackDownloadFile(body);
  };

  const openUrl = () => {
    const url = `/profile/company/${file?.companyPublicId}/attachments/${file?.id}`;
    const newWin = window.open(url);
    newWin.focus();
    onDownload();
  }

  //  Function to render header
  const renderHeader = () => {
    return (
      <div className="file-header">
        <div className="file-options">
          <span
            className="download-icon"

            onClick={() => openUrl()}
            name={`/profile/company/${file?.companyPublicId}/attachments/${file?.id}`}
          >
            <div
              className="bx bx-download download-icon"
            />
          </span>
          <div
            className="bx bxs-trash trash-icon"
            onClick={onShowDelete}
          />
        </div>
      </div>
    );
  };

  //  Function to render delete modal
  const renderDeleteModal = () => {
    if (!showDeleteModal) { return null; }
    return (
      <CustomActionModal
        closeModal={() => setShowDeleteModal(false)}
        title='Delete Attachment'
        subtitle={`Are you sure you want to PERMANENTLY delete ${file?.name}?`}
        disabled={deleteLoading}
        onActionClick={() => onDelete()}
        actionText="Delete"
        actionClassName="btn btn-submit btn-red text-dark font-weight-bold delete-rule-button"
      />
    );
  }

  return (
    <>
      <div className="file-item">
        <div className="file-content">
          <LoadingContent
            isLoading={file?.isLoading || deleteLoading}
            loadingStyle={{ width: '225px', height: '152px' }}
            loadingClassName="attachment-preview-spinner"
          >
            {renderHeader()}
          </LoadingContent>
        </div>
      </div>
      {renderDeleteModal()}
    </>
  );
};

export default CompanyAttachmentsTableActions;
