export const deviceStatusTooltipConfig = {
  title: 'Device Status',
  titleTooltip: "set",
  componentId: "devices-rollup-dashboard"
};

export const deviceStatusColorConfig = {
  Critical: "#FF5E54",
  High: "#F1A04C",
  Medium: "#F5D302",
  Low: "#0EB082",
};

export const deviceStatusContentStyle = {
  marginTop: "20px",
  maxHeight: "130px",
  marginBottom: "25px",
};
