import React from "react";
import ConnectionStatusTable from "../components/connection-status/ConnectionStatusTable";
import ViewWrapper from "../components/layout/ViewWrapper";

const ConnectionStatusView = () => {
  return (
    <ViewWrapper
      layoutType="vertical"
      hasImpersonationMode={false}
    >
      <ConnectionStatusTable />
    </ViewWrapper>
  );
};

export default ConnectionStatusView;
