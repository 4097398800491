import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { Button } from 'reactstrap';
import Avatar from '../../layout/avatars/Avatar';
import API from '../../../api';
import { setAlert } from '../../../actions/alerts';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import useValueDebounce from '../../../hooks/useValueDebounce';
import SelectDropdownIndicator from '../../layout/fields/SelectDropdownIndicator';
import useCustomQuery from '../../../hooks/useCustomQuery';
import {onFocus} from '../../../utils/helpers.js'

//  Component
const IssuesAssigneeFilter = (props) => {
  //  Parsing props
  const {
    filterValue,
    selectedCompany,
    placeholder = "Select cydekick",
    wrapperClassName,
    setFilterCallback,
    showAllMembersOption = true,
    showClearButton = false,
    excludedUserIds = [],
  } = props;

  //  Initializing APIs
  const { UserAPI } = API;

  const allAssigneesOption = { label: 'All assignees', value: null }; //option for all assignees

  //Component state
  const [currentAssignees, setCurrentAssignees] = useState([]);
  const [filterOptions, setFilterOptions] = useState([...showAllMembersOption ? [allAssigneesOption] : []]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  //  Component hooks
  const { debouncedValue: textToSearch } = useValueDebounce({
    debounceTime: 1000,
    defaultValue: null,
    valueToDebounce: searchTerm,
  });
  const {
    data: assgineesResponse,
    error: assigneesError,
    isLoading: loading,
  } = useCustomQuery(
    [UserAPI.keys.getIssuesAssignees, { selectedCompany, searchTerm: textToSearch }],
    ({ queryKey }) => UserAPI.handlers.getIssuesAssignees(queryKey[1]),
  );
  const { dispatch } = useMemoizedDispatch();

  //  Function to render user option
  const renderUserOption = (user, profileImage = null) => {
    const { full_name } = user;
    return (
      <div className="d-inline-flex align-items-center">
        <Avatar user={user} profileImage={profileImage} customClassName="user-option filter-option" />
        <div className="assignee-name">{full_name}</div>
      </div>
    );
  }

  // Function to get users options
  const mapUsersToOptions = (array = []) => {
    return array.map((element) => {
      return { label: element?.full_name, value: element.id };
    });
  };

  // Function to find select option by filterValue
  const findSelectedOption = (options) => {
    const isSelected = !_.isNil(filterValue) && options.length > 0;
    const selectedOption = isSelected ? _.find(options, { value: filterValue }) : null;
    return selectedOption
  }

  //  Watching filterValue changes
  useEffect(() => {
    const option = findSelectedOption(filterOptions)
    setSelectedOption(option);
  }, [filterValue, filterOptions]);

  const shouldIncludeAssignee = (assignee) => {
    if(!excludedUserIds || !Array.isArray(excludedUserIds)) return true;
    const excludedIndex = excludedUserIds.findIndex( id => id === assignee.id);
    return excludedIndex < 0;
  }

  //  Watching assignees response
  useEffect(() => {
    if (!assgineesResponse) { return; }
    const { assignees = [] } = assgineesResponse;
    const filteredAssignees = assignees.filter(shouldIncludeAssignee)
    setCurrentAssignees(filteredAssignees);
    const assigneesOptions = mapUsersToOptions(filteredAssignees);
    setFilterOptions([
      ...showAllMembersOption ? [allAssigneesOption] : [],
      ...assigneesOptions
    ]);
    const option = findSelectedOption(assigneesOptions);
    if (!option && (option !== selectedOption)) setFilterCallback(allAssigneesOption);
  }, [assgineesResponse]);

  //  Watching assignees errors
  useEffect(() => {
    if (!assigneesError) { return; }
    dispatch(setAlert('Error getting issues assignees', 'danger'));
  }, [assigneesError]);

  //  Function to handle change
  const handleDropdownChange = (newOption) => {
    setSelectedOption(newOption?.value ? newOption : null);
    setFilterCallback(newOption);
  };

  // Dropdown Indicator
  const DropdownIndicator = (props) => {
    return <SelectDropdownIndicator {...props} />
  }

  // Clear state
  const clearState = () => {
    setFilterCallback(allAssigneesOption);
  }

  //  Function to render clear button
  const renderClearButton = () => {
    const isSelected = !_.isNil(filterValue) && filterOptions.length > 0;
    if (!isSelected) { return null; }
    return (
      <Button
        className="close dropdown-clear"
        onClick={() => { clearState(); }}
        aria-label="Close dropdown"
        role="button"
      >
        <i className='bx bx-x' />
      </Button>
    );
  };

  return (
    <div className={`filter-dropdown ${wrapperClassName}`}>
      <div className="select-container">
        <Select
          ariaLiveMessages={{
            onFocus,
          }}
          aria-live="polite"
          inputValue={searchTerm}
          value={selectedOption}
          options={filterOptions}
          onChange={handleDropdownChange}
          onInputChange={setSearchTerm}
          className={`react-select-container ${selectedOption?.value ? 'has-value' : ''}`}
          classNamePrefix="react-select"
          components={{ DropdownIndicator }}
          placeholder={placeholder}
          isDisabled={false}
          isLoading={loading}
          isSearchable={true}
          menuPosition="fixed"
          menuPortalTarget={document.body}
          menuShouldBlockScroll={true}
          styles={{
            menuPortal: provided => ({ ...provided, zIndex: 9999 }),
            menu: provided => ({ ...provided, zIndex: 9999 })
          }}
          formatOptionLabel={({ label, value }) => {
            if (!value) { return label; }
            const matchingUser = currentAssignees.find((assignee) => assignee.id === value);
            if (!matchingUser) { return label; }
            return renderUserOption(matchingUser);
          }}
          aria-label={filterOptions?.length === 0 ? 'Assigned to combobox. Current combobox has no options' : 'Assigned to, filter and select cydekicks.'}
        />
      </div>
      {showClearButton && renderClearButton()}
    </div>
  )
}

export default IssuesAssigneeFilter;
