import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

const BasicTooltip = (props) => {
    const {
        placement = "bottom",
        className,
        target,
        content
    } = props;
    return (
        <UncontrolledTooltip
            className={`custom-basic-tooltip ${className || ''}`}
            arrowClassName={`custom-basic-tooltip-arrow ${className || ''}`}
            placement={placement}
            target={target}
        >
            {content}
        </UncontrolledTooltip >
    )
}

export default BasicTooltip;