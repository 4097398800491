import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import API from '../api';
import errorMessages from '../constants/errorMessages';
import companyIdSelector from "../selectors/companyIdSelector";
import useCustomQuery from './useCustomQuery';

const useAssessmentValidation = () => {
    //  Initializing API
    const { AssessmentAPI } = API;
    // Getting needed info from redux store
    const companyId = useSelector(state => companyIdSelector(state));

    // State
    const [isCompleted, setIsCompleted] = useState(null);

    //  Calling API for assessment status
    const {
        data: assessmentStatusResponse,
        isLoading,
        error,
        refetch,
    } = useCustomQuery(
        [AssessmentAPI.keys.getAssessmentStatus, companyId],
        ({ queryKey }) => AssessmentAPI.handlers.getAssessmentStatus(queryKey[1]),
    );

    useEffect(() => {
        if (assessmentStatusResponse) {
            setIsCompleted(assessmentStatusResponse?.completed)
        }
    }, [assessmentStatusResponse]);

    return {
        isCompleted,
        isLoading,
        errorMessage: error ? error?.data?.message || errorMessages.defaultGet : null,
        retryRequest: refetch,
    }
}

export default useAssessmentValidation
