import cyvatarAxios from '../axios';
import {
  GET_SOLUTIONS_AND_CONTROLS,
  GET_POLICIES,
  GET_TRUST_BUILDER_CONTENT,
  GET_EXPORT_TOKEN_VALIDATION,
  GET_POLICY_PDF,
  GET_BUSINESS_RISK_CONFIG,
  GET_RISK_STATEMENTS,
  RISK_STATEMENT_STATUS_DATA,
  GET_OPPORTUNITIES_TO_IMPROVE,
  GET_COMPANY_RISKS_LIST,
} from '../ApiKeys';

//Control and Solutions  Params include { company_id }
const getSolutionsAndControls = async (params) => {
  const apiResponse = await cyvatarAxios.get('/api/vcyso/trust_builder/controls-solutions', { params });
  const { data } = apiResponse;
  return data;
};

//Policies Params include { company_id }
const getPolicies = async (params) => {
  const apiResponse = await cyvatarAxios.get('/api/vcyso/policy/getPolicies', { params });
  const { data } = apiResponse;
  return data;
};

//get Trust builder content params { company_id }
const getTrustBuilderContent = async (params) => {
  if (!params?.company_id) { return null; }
  const apiResponse = await cyvatarAxios.get('/api/vcyso/trust_builder', { params });
  const { data } = apiResponse;
  return data;
};

//update Trust builder content body { field, content, company_id}
const updateTrustBuilderContent = async (values) => {
  const body = JSON.stringify(values);
  const apiResponse = await cyvatarAxios.put('/api/vcyso/trust_builder/save', body);
  const { data } = apiResponse;
  return data;
};

//validate token to export
const getExportTokenValidation = async (token) => {
  if (!token) { return null; }
  const apiResponse = await cyvatarAxios.get(`/api/vcyso/trust_builder/export/${token}`);
  const { data } = apiResponse;
  return data;
};

//  Function to generate PDF
const generateTrustBuilderPDF = async (company_id) => {
  const response = await cyvatarAxios.post('/api/vcyso/trust_builder/generate-pdf', { company_id }, { responseType: 'blob' });
  const { data } = response;
  return data;
};

//  Function to get policy PDF
const getPolicyPDF = async (policy_template) => {
  const response = await cyvatarAxios.get(
    '/api/vcyso/policy/getPolicyPDF',
    {
      params: { policy_template },
      responseType: 'blob',
    },
  );
  const { data } = response;
  return data;
};

const getBusinessRiskConfig = () => {
  return cyvatarAxios.get('/api/vcyso/business_risk/config');
};

const getRiskStatements = (company_id) => {
  return cyvatarAxios.get(`/api/vcyso/business_risk/company/${company_id}`);
};

const updateRiskStatement = (params) => {
  const { statement_id, field, value, company_id } = params;
  const values = { [field]: value };
  const body = JSON.stringify(values);
  return cyvatarAxios.patch(`api/vcyso/business_risk/company/${company_id}/statement/${statement_id}`, body);
};

const riskStatementStatusData = async (company_id) => {
  return cyvatarAxios.get(`api/vcyso/business_risk/company/${company_id}/status`)
};

const getOpportunitiesToImprove = (companyId) => {
  return cyvatarAxios.get(`api/vcyso/business_risk/company/${companyId}/opportunities`)
};

//  Params = { policy_id, company_id }
const trackPolicyDownload = async (params) => {
  const response = await cyvatarAxios.put('/api/vcyso/policy/track-policy-download', params);
  const { data } = response;
  return data;
};

// Get company risk list
const getCompanyRisksList = async (filters = {}) => {
  const filtersToUse = { ...filters };
  const response = await cyvatarAxios.get('/api/vcyso/business_risk/list', { params: { filters: filtersToUse } });
  const { data } = response;
  return data;
};

// Create company risk
const createCompanyRisk = async (payload) => {
  const apiResponse = await cyvatarAxios.post(`/api/vcyso/business_risk/create`, payload);
  const { data } = apiResponse;
  return data;
}

// Update company risk
const updateCompanyRisk = async (payload) => {
  const apiResponse = await cyvatarAxios.patch(`/api/vcyso/business_risk/update`, payload);
  const { data } = apiResponse;
  return data;
}

export const VcysoAPI = {
  handlers: {
    getSolutionsAndControls,
    getPolicies,
    getTrustBuilderContent,
    updateTrustBuilderContent,
    getExportTokenValidation,
    generateTrustBuilderPDF,
    getPolicyPDF,
    getBusinessRiskConfig,
    getRiskStatements,
    updateRiskStatement,
    riskStatementStatusData,
    getOpportunitiesToImprove,
    trackPolicyDownload,
    getCompanyRisksList,
    createCompanyRisk,
    updateCompanyRisk
  },
  keys: {
    getSolutionsAndControls: GET_SOLUTIONS_AND_CONTROLS,
    getPolicies: GET_POLICIES,
    getTrustBuilderContent: GET_TRUST_BUILDER_CONTENT,
    getExportTokenValidation: GET_EXPORT_TOKEN_VALIDATION,
    getPolicyPDF: GET_POLICY_PDF,
    getBusinessRiskConfig: GET_BUSINESS_RISK_CONFIG,
    getRiskStatements: GET_RISK_STATEMENTS,
    riskStatementStatusData: RISK_STATEMENT_STATUS_DATA,
    getOpportunitiesToImprove: GET_OPPORTUNITIES_TO_IMPROVE,
    getCompanyRisksList: GET_COMPANY_RISKS_LIST,
  },
  invalidators: {
    updateTrustBuilderContent: [
      GET_SOLUTIONS_AND_CONTROLS,
      GET_TRUST_BUILDER_CONTENT,
      GET_OPPORTUNITIES_TO_IMPROVE,
    ],
    generateTrustBuilderPDF: null,
    updateRiskStatement: [
      GET_BUSINESS_RISK_CONFIG,
      GET_RISK_STATEMENTS,
      RISK_STATEMENT_STATUS_DATA,
      GET_OPPORTUNITIES_TO_IMPROVE,
      GET_COMPANY_RISKS_LIST,
    ],
    trackPolicyDownload: [],
    createCompanyRisk: [
      GET_BUSINESS_RISK_CONFIG,
      GET_RISK_STATEMENTS,
      RISK_STATEMENT_STATUS_DATA,
      GET_COMPANY_RISKS_LIST,
    ],
    updateCompanyRisk: [
      GET_BUSINESS_RISK_CONFIG,
      GET_RISK_STATEMENTS,
      RISK_STATEMENT_STATUS_DATA,
      GET_COMPANY_RISKS_LIST,
    ]
  }
};
