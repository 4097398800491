import React, { useState } from 'react';
import Arrow from '../../assets/images/icons/vcyso-arrow.svg';
import HelpMessage from './HelpMessage';
import { trackEvent } from '../../utils/gaTracker';

const InformationExpandableBar = (props) => {
  //  Parsing props
  const {
    title,
    isFreemium,
    content,
    hasHelpMessage,
    customButton,

    riskTitle
  } = props;

  //  Component state
  const [isExpanded, setIsExpanded] = useState(true);

  //  Function for icon clicked event
  const onIconClicked = () => {
    setIsExpanded(!isExpanded);
  };

  //  Rendering bar header
  const renderHeader = () => {
    return (
      <div className="information-bar-header">
        <div className="header-title left-content">{title}</div>
        <div className="right-content">
          <div className="expand-icon" onClick={() => onIconClicked()}>
            <img alt="" src={Arrow} className={isExpanded ? '' : 'collapsed-icon'} />
          </div>
        </div>
      </div>
    );
  };

  //  Function to render content
  const renderContent = () => {
    return content.map((item) => {
      return (
        <>
        
        { riskTitle ? (
          <div className="businessRiskTitle">{item}</div>
        ): ( <div className="description-container">{item}</div>) }

        </>
      );
    })
  };

  //  On sales button clicked
  const onSalesButtonClicked = () => {
    trackEvent('subscribe-cyso-info-bar', 'cyso','subscribe-cyso-info-bar');
    window.open(process.env.REACT_APP_CONTACT_SALES_URL);
  };

  //  Render sales button
  const renderSalesButton = () => {
    if (!isFreemium) { return null; }
    return (
      <div className="sales-button">
        <a
          className="btn btn-light"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => onSalesButtonClicked()}
        >
          Subscribe CySO
        </a>
      </div>
    );
  };

  //  Rendering help message
  const renderHelpMessage = () => {
    if (!hasHelpMessage) { return null; }
    return (
      <HelpMessage
        normalMessage="Need any help?"
        linkMessage="contact us here."
        linkUrl={process.env.REACT_APP_HELP_LINK}
        containerClassName="help-container"
      />
    );
  };

  //Render custom button
  const renderCustomButton = () => {
    if(!customButton) return null;
    return (
      <div className="information-bar-custom-button">
        {customButton}
      </div>
    )
  }

  //  Rendering bar body
  const renderBody = () => {
    if (!isExpanded) { return null; }
    return (
      <div className="information-bar-body">
        <div className="descriptions">
          {renderContent()}
          {renderHelpMessage()}
        </div>
        <div className="right-content">
          {renderSalesButton()}
          {renderCustomButton()}
        </div>
      </div>
    );
  }

  //  Rendering
  return (
    <div className="information-bar">
      {renderHeader()}
      {renderBody()}
    </div>
  );
};

export default InformationExpandableBar;
