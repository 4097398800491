import { issueTypes } from '../constants/issues';
import { getSafeArray } from '../utils/helpers';
import { getIssuesStoreEntryByIssueType } from '../utils/issuesHelpers';

const issuesSimpleOptionsSelector = (state, type = issueTypes.VULNERABILITIES, entry = 'statuses', excludeHidden = false) => {
  //  Getting options array
  const storeEntry = getIssuesStoreEntryByIssueType(type);
  const options = state?.[storeEntry]?.modalOptions?.[entry];
  let safeOptions = getSafeArray(options);

  //  Excluding hidden elements
  if (excludeHidden) {
    safeOptions = safeOptions?.filter((element) => !element.hidden);
  }

  return safeOptions;
};

export default issuesSimpleOptionsSelector;
