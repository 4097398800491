import {
    CYDEKICKS_REQUEST,
    CYDEKICKS_SUCCESS,
    CYDEKICKS_FAILURE,
    CYDEKICKS_CLEAR,
    SET_CYDEKICKS_SORT,
    CLEAR_ALL_CYDEKICKS_FILTERS,
    SET_CYDEKICKS_FILTER,
    RELOAD_CYDEKICKS,
    LOGOUT_SUCCESS
} from '../actions/types';
import _ from 'lodash';
import filterTypes from '../constants/filterTypes';

const filtersInitialState = {

};
const sortFieldInitialState = { name: null, asc: true };
const initialState = {
    filters: filtersInitialState,
    activeFilters: [],
    searchTerm: '',
    sortField: sortFieldInitialState,
    list: [],
    pageCount: 1,
    total: 0,
    shouldReload: false
}

const cydekicksReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case CYDEKICKS_REQUEST:
            return { ...state, loading: true };
        case CYDEKICKS_SUCCESS:
            return {
                ...state,
                list: payload.items || [],
                pageCount: payload.pagination?.page_count || 1,
                total: payload.pagination?.total || 0,
                loading: false
            };
        case CYDEKICKS_FAILURE:
            return { ...state, message: payload, loading: false };
        case CYDEKICKS_CLEAR:
        case LOGOUT_SUCCESS:
            return initialState;
        case SET_CYDEKICKS_FILTER: {
            const { filterName, type, value, label } = payload;
            switch (type) {
                case filterTypes.multiple: {
                    return {
                        ...state,
                        activeFilters: state.filters[filterName]?.includes(value) ? (
                            [..._.pullAllWith(state.activeFilters, [{ label, type, filterName, value }], _.isEqual)]
                        ) : [...state.activeFilters, { label, type, filterName, value }],
                        filters: {
                            ...state.filters,
                            [filterName]: state.filters[filterName]?.includes(value) ? (
                                _.pull(state.filters[filterName], value)
                            ) : [...state.filters[filterName], value]
                        }
                    }
                }

                case filterTypes.search: {
                    const cleanActiveState = _.pullAllBy(state.activeFilters, [{ filterName }], 'filterName');
                    return {
                        ...state,
                        activeFilters: _.isEmpty(value) ? (
                            [...cleanActiveState]
                        ) : [...cleanActiveState, { label, type, filterName, value: '' }],
                        searchTerm: value
                    }
                }

                case filterTypes.single:
                default: {
                    const cleanActiveState = _.pullAllBy(state.activeFilters, [{ filterName }], 'filterName');
                    return {
                        ...state,
                        activeFilters: _.isNil(value) ? (
                            [...cleanActiveState]
                        ) : [...cleanActiveState, { label, type, filterName, value: null }],
                        filters: {
                            ...state.filters,
                            [filterName]: value
                        }
                    }
                }
            }
        }

        case SET_CYDEKICKS_SORT: {
            return {
                ...state,
                sortField: payload
            };
        }

        case CLEAR_ALL_CYDEKICKS_FILTERS: {
            return {
                ...state,
                filters: filtersInitialState,
                activeFilters: [],
                searchTerm: ''
            };
        }

        case RELOAD_CYDEKICKS: {
            return {
                ...state,
                shouldReload: payload
            }
        }

        default:
            return state;
    };
};

export default cydekicksReducer;