import React from 'react';
import { withRouter } from 'react-router-dom';
import InAppNotificationSettings from '../profile/InAppNotificationSettings';

const ProfileSettings = (props) => {

  const {
    title = ''
  } = props;

  return (
    <div className='profile-settings-container'>
      <InAppNotificationSettings title={'In-app notification settings'}/>
    </div>
  )
}

export default ProfileSettings;