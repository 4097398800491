import React from 'react';
import { setIdsToDelete, setIdsToRestore } from '../../../actions/recycleBin';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import useCanHardDeleteElements from '../../../hooks/useCanHardDeleteElements';

const RecycleBinBulkMenu = (props) => {
  //  Parsing props
  const {
    entity,
    selected = [],
  } = props;

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const { canDelete } = useCanHardDeleteElements({ entity });

  //  Handling restore
  const onRestore = () => {
    if (!selected.length === 0) { return; }
    dispatch(setIdsToRestore(selected));
  }

  //  Handling delete
  const onDelete = () => {
    if (!selected.length === 0) { return; }
    dispatch(setIdsToDelete(selected));
  };

  //  Function to render icon
  const renderAction = (className, onClick) => {
    return (
      <div
        className="bulk-menu-icon-container"
        onClick={onClick}
      >
        <div className={`bulk-menu-icon ${className}`} />
      </div>
    );
  };

  //  Rendering
  return (
    <div className="recycle-bin-bulk-menu">
      <div className="bulk-menu-label">Bulk actions</div>
      <div className="bulk-menu-icons">
        {renderAction('bx bx-rotate-right', onRestore)}
        {canDelete && renderAction('bx bx-trash', onDelete)}
      </div>
    </div>
  );
};

export default RecycleBinBulkMenu;
