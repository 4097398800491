import {
	SET_START_DATE,
	SET_END_DATE,
	SET_TIMEZONE,
	SET_SOLUTION_ID,
	SET_DATE_INFO,
	SET_RANGE_VALUE,
	SET_OPEN_OVERVIEW
} from "../actions/types";

const initialState = {
	start_date: new Date(),
	end_date: new Date(),
	timezone: '',
	solution_id: null,
	range_value: 90, // 90 days range is the default
	open_overview: '',
};

export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_TIMEZONE:
			return {
				...state,
				timezone: action.payload
			};
		case SET_START_DATE:
			return {
				...state,
				start_date: action.payload
			};
		case SET_END_DATE:
			return {
				...state,
				end_date: action.payload
			};
		case SET_SOLUTION_ID:
			return {
				...state,
				solution_id: action.payload
			};
		case SET_DATE_INFO:
			return {
				...state,
				start_date: payload?.start_date,
				end_date: payload?.end_date,
				timezone: payload?.timezone,
			}
		case SET_RANGE_VALUE:
			return {
				...state,
				range_value: payload,
			}
		case SET_OPEN_OVERVIEW:
			return{
				...state,
				open_overview: payload,
			}
		default:
			return state;
	}
};


