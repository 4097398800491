import React from 'react'
import { useSelector } from 'react-redux';
import { setIssuesFilter } from '../../../actions/issues';
import { rollUpWidgetsIds } from '../../../constants/common';
import filterTypes from '../../../constants/filterTypes';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import issuesInfoSelector from '../../../selectors/issuesInfoSelector';
import { getSafeArray } from '../../../utils/helpers';
import { getStatusFilterByIssuesType } from '../../../utils/issuesHelpers';
import IssuesCriticalityFilters from '../../issue-manager/issue-filters/IssuesCriticalityFilters';
import IssuesStatusFilters from '../../issue-manager/issue-filters/IssuesStatusFilters';

const RollupFilterButtonGroup = (props) => {
    const {
        widgetType,
        issueType
    } = props;

    // Getting needed info from redux store
    const criticalityFilter = useSelector(state => issuesInfoSelector(state, 'filters' ,issueType))?.severity;
    const statusFilter = useSelector(state => issuesInfoSelector(state, 'filters' ,issueType))?.status;

    // Component Hooks
    const { dispatch } = useMemoizedDispatch();

    const setFilterByField = (fieldName, option) => {
        dispatch(setIssuesFilter(fieldName, option?.value, option?.label, filterTypes.multiple, null, false, issueType))
    }

    // Render criticality filters
    const renderCriticalityFilters = () => {
        return (
            <IssuesCriticalityFilters
                filterValue={getSafeArray(criticalityFilter)}
                setFilterCallback={(option) => setFilterByField('severity', option)}
                wrapperClassName="d-inline-block mr-2 rollup-criticality-filter"
                showNumber={true}
                useCriticalityClassName={true}
                issueType={issueType}
            />
        )

    }

    // Render issue status filters
    const renderStatusFilters = () => {
        return (
            <IssuesStatusFilters
                filterValue={getSafeArray(statusFilter)}
                setFilterCallback={(option) => setFilterByField('status', option)}
                showNumber={true}
                wrapperClassName="d-inline-block mr-2 rollup-status-filters"
                optionsFilterFunction={element => getStatusFilterByIssuesType(element, issueType)}
                useStatusClassName={true}
                issueType={issueType}
            />
        )
    }

    return widgetType !== rollUpWidgetsIds.STATUS ? renderCriticalityFilters() : renderStatusFilters();
}

export default RollupFilterButtonGroup
