import React, { useState, useEffect, useCallback } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import useUnload from '../../hooks/useUnload';
import UnsavedChangesModal from './UnsavedChangesModal';

const UnsavedChangesGuard = (props) => {

    const {
        unsavedChanges,
        saveCallback,
        leaveCallback
    } = props;

    const history = useHistory();

    const [showModal, setShowModal] = useState(false);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const [nextLocation, setNextLocation] = useState(null);

    useEffect(() => {
        if (confirmedNavigation && nextLocation) {
            history.push(nextLocation);
        }
    }, [confirmedNavigation])

    const toggleModal = () => {
        setShowModal(!showModal)
    }

    const onSaveClick = () => {
        const callback = () => onLeaveClick();
        saveCallback(callback);
    }

    const onLeaveClick = () => {
        setConfirmedNavigation(true);
        setShowModal(false);
        if (leaveCallback) leaveCallback()
    }

    const handleUnsavedChanges = (location) => {
        if (!confirmedNavigation && unsavedChanges) {
            setShowModal(true);
            setNextLocation(location);
            return false
        }
        return true
    }

    useUnload(e => {
        if (unsavedChanges) {
            e.preventDefault();
            e.returnValue = '';
        }
    });
    return (
        <>
            <UnsavedChangesModal
                saveCallback={onSaveClick}
                leaveCallback={onLeaveClick}
                showModal={showModal}
                toggleModal={toggleModal}
            />
            <Prompt
                when={unsavedChanges}
                message={handleUnsavedChanges}
            />
        </>
    )
}

export default UnsavedChangesGuard;