import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { issueTypes } from '../../../constants/issues';
import { statusesConfig } from '../../../constants/status';
import issuesInfoSelector from '../../../selectors/issuesInfoSelector';
import FiltersButtonGroup from '../../layout/filters/FiltersButtonGroup';

const IssuesStatusFilters = (props) => {
    const {
        filterValue,
        setFilterCallback,
        wrapperClassName,
        showNumber = true,
        optionsFilterFunction,
        useStatusClassName = false,
        issueType = issueTypes.VULNERABILITIES,
    } = props;

    const defaultOptionsFilter = ['hidden', false];

    //  Getting needed info from redux store
    const statuses = useSelector(state => issuesInfoSelector(state, 'modalOptions', issueType)?.statuses);
    const totalsByStatus = useSelector(state => issuesInfoSelector(state, 'totalsBy', issueType)?.status)

    const mapStatusFilters = () => {
        const statusArray  = showNumber ? totalsByStatus : statuses;
        const filteredStatuses =  _.filter(statusArray, optionsFilterFunction || defaultOptionsFilter ).map(({ label, id, total }) => {
            const className = label?.replace(" ", "-")?.toLowerCase();
            return {
                label,
                className: useStatusClassName ? className : 'light-gray',
                value: id,
                number: total || 0,
                order: statusesConfig?.findIndex(element => element?.id === id)
            }
        })

        return _.orderBy(filteredStatuses, 'order')
    }
    return (
        <div tabIndex="0" aria-label={`Filter issues by current status`}>
            <FiltersButtonGroup
                filterValue={filterValue}
                data={mapStatusFilters()}
                callback={setFilterCallback}
                showNumber={showNumber}
                wrapperClassName={wrapperClassName}
            />
        </div>
    )
}

export default IssuesStatusFilters;
