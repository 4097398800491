import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { isValidFunction } from "../utils/helpers";

const useElementModalNavigation = (params) => {
  const {
    issueType,
    currentPageIndex,
    maxPageCount,
    updateCurrentPageCallback,
    updateElementCallback
  } = params;

  // Hook state
  const [currentPage, setCurrentPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);

  // Hook component
  const location = useLocation();

  useEffect(() => {
    setCurrentPage(currentPageIndex);
  }, [issueType, currentPageIndex]);

  useEffect(() => {
    setMaxPage(maxPageCount);
  }, [issueType, maxPageCount]);

  const onCallback = (page) => {
    if(isValidFunction(updateCurrentPageCallback)) {
      updateCurrentPageCallback(page, issueType)
    }
  }
  // Function to update the index of the issue and the page
  const updateIndex = (isNext = true) => {
    let newIndex = isNext ? location?.state?.index + 1 : location?.state?.index - 1;
    let newPage = false;

    if (isNext && location?.state?.index === location?.state?.maxIndex){
      onCallback(currentPage + 1)
      newIndex = 0;
      newPage = true;
    } else if (location?.state?.index === 0 && !isNext) {
      onCallback(currentPage - 1)
      newIndex = -1;
      newPage = true;
    }
    if(isValidFunction(updateElementCallback)) {
      updateElementCallback(newIndex, newPage)
    }
  }

  return {
    currentPage,
    maxPage,
    updateIndex,
    currentElementIndex: location?.state?.index,
    maxIndex: location?.state?.maxIndex,
  }
}

export default useElementModalNavigation;
