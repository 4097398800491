import React from 'react';
import { freemiumLogos } from '../../constants/logosTemplates';
import { freemiumAccountAdvantages } from '../../constants/pagesData';

const FreemiumLoginInformation = (props) => {
  //  Parsing props
  const { 
    logoOnly = false,
    customClassName = ''
  } = props;

  //  Function to render logos
  const renderLogos = () => {
    if (!freemiumLogos || !logoOnly) { return null; }
    return freemiumLogos.map((item, index) => {
      const {
        containerClassName,
        src,
        alt,
        className,
        style,
        onClick,
      } = item;
      return (
        <div
          className={containerClassName}
          key={index}
          onClick={onClick ? () => onClick() : null}
        >
          <img
            className={`${className}${logoOnly ? ' freemium-big-icon' : ''}`}
            src={src}
            alt={alt}
            style={style}
          />
        </div>
      );
    })
  };

  //  Rendering advantages list
  const renderAdvantages = () => {
    return freemiumAccountAdvantages.map((advantage, index) => {
      const { normalText, hightlightedText } = advantage;
      return (
        <div className="freemium-advantage" key={index}>
          <div className="bx bx-check freemium-advantage-icon" />
          <div className="freemium-advantage-text">{normalText}</div>
          {hightlightedText && <div className="freemium-advantage-highlight">{hightlightedText}</div>}
        </div>
      );
    })
  };

  //  Rendering freemium content
  const renderFreemiumAdvantagesSection = () => {
    if (logoOnly) { return null; }
    return (
      <div className="freemium-advantages">
        {renderAdvantages()}
      </div>
    );
  };

  //  Rendering header
  const renderHeader = () => {
    if (logoOnly) { return null; }
    return (
      <div className="freemium-information-header">
        <div className="freemium-header-title">Get started absolutely free!</div>
        <div className="freemium-header-subtitle">Free forever. No credit card needed.</div>
      </div>
    );
  };

  //  Rendering Title
  const renderTitle = () => {
    if (logoOnly) { return null; }
    return (
      <div className="freemium-information-title">
        <div className="freemium-title">Sign Up for MyCyvatar!</div>
        <div className="freemium-subtitle">
          {`The All-in-One Cybersecurity Management Platform for Startups & SMBs`}
        </div>
        <div className="separator" />
      </div>
    );
  };

  //  Rendering
  return (
    <div className={`freemium-information ${customClassName}`}>
      {renderLogos()}
      {renderTitle()}
      {renderHeader()}
      {renderFreemiumAdvantagesSection()}
    </div>
  );
};

export default FreemiumLoginInformation;
