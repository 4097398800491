import React from 'react';
import { Field } from 'react-final-form';
import { selectPartnerTemplate, buildSelectObjectFromTemplate } from '../../constants/formTemplates';
import { renderCustomSelect } from '../../utils/renderFormFields';
import { removeItemFromArray } from '../../utils/helpers';


const PartnerAddOnComponent = (props) => {
  const {
    title,
    lastFieldIndex,
    setLastFieldIndex,
    rowsToRender,
    setRowsToRender,
    partners,
  } = props;

  const options = partners.map(_partner => { return { label: _partner.name, value: _partner.partner_id } });

  const addRow = () => {
    const newLastFieldIndex = lastFieldIndex + 1;
    const newRow = buildSelectObjectFromTemplate(newLastFieldIndex, selectPartnerTemplate, null, "Select partner", "add-on-partner select-partner-wrapper");
    setRowsToRender([...rowsToRender, newRow]);
    setLastFieldIndex(newLastFieldIndex);
  }

  //  Function to remove field from DOM
  const removeRow = (index) => {
    const newFieldsToRender = removeItemFromArray(rowsToRender, index);
    setRowsToRender(newFieldsToRender);
  };

  //  Function to render the remove field icon
  const renderRemoveRowIcon = (index) => {
    if (rowsToRender.length < 1) { return null; }
    return (
      <i
        className="bx bx-minus-circle align-middle remove-field-button"
        onClick={() => removeRow(index)}
      />
    );
  };

  // Function to render field
  const renderField = (field, index) => {
    const {
      name,
      label,
      placeholder,
      className,
      type,
      wrapperClassName
    } = field;

    return (
      <div key={`field-${index}-${lastFieldIndex}`} className={`d-flex flex-column ${wrapperClassName}`}>
        <div className="field-label">{label}</div>
        <Field
          key={`${index}-${lastFieldIndex}`}
          render={renderCustomSelect}
          name={name}
          placeholder={placeholder}
          className={className}
          options={options}
          type={type}
          scrollable={true}
        />
      </div>
    );
  };

  const renderRows = () => {
    if (!rowsToRender) { return null; }
    return rowsToRender.map((row, rowIndex) => {
      return (
        <div key={rowIndex} className="row-fields-container">
          <div className="icon-container">
            {renderRemoveRowIcon(rowIndex)}
          </div>
          {renderField(row, rowIndex)}
        </div>
      )
    })
  }

  const renderAddPartner = () => {
    if (rowsToRender.length === partners.length) { return null; }
    return (
      <div className="btn btn-add-email" onClick={() => addRow()}>
        <i className='bx bx-plus-circle mr-2 align-middle remove-field-button' />
        {rowsToRender.length >= 1 ? 'Include another add on' : 'Include add on'}
      </div>
    );
  }

  return (
    <div className="add-new-partner-component">
      <div className="section-title">{title}</div>
      <div>
        {renderRows()}
      </div>
      {renderAddPartner()}
    </div>
  )
}

export default PartnerAddOnComponent
