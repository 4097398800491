import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NavigationTextRow from '../layout/NavigationTextRow';
import SearchableInfiniteTable from '../CustomTable/SearchableInfiniteTable';
import { buildLogsTextRow } from '../../utils/automationHelper';
import { onLogDetailRequested, onRuleLogRequested } from '../../actions/automation';
import { setAlert } from '../../actions/alerts';
import API from '../../api'
import { ruleLogsTemplate } from '../../constants/tableTemplates';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import moment from 'moment-timezone';
import { useHistory, useParams } from 'react-router-dom';
import useCustomQuery from '../../hooks/useCustomQuery';
import companyIdSelector from "../../selectors/companyIdSelector";
import companyInfoSelector from "../../selectors/companyInfoSelector";


const SolutionLogs = (props) => {
  //  Initializing APIs
  const { RuleAPI } = API;

  //  Watching redux store
  const companyId = useSelector((state) => companyIdSelector(state));
  const partnerId = useSelector((state) => state.partners.partnerId);
  const partnerName = useSelector((state) => state.partners.partnerName);
  const companyName = useSelector((state) =>
    companyInfoSelector(state, "name")
  );

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const history = useHistory();
  const { data: syncLogsResponse, error: syncLogsError } = useCustomQuery(
    [RuleAPI.keys.getPartnerSyncLogs, partnerId, companyId],
    ({ queryKey }) =>
      RuleAPI.handlers.getPartnerSyncLogs(queryKey[1], queryKey[2])
  );

  //  Component state
  const [titleArray, setTitleArray] = useState([]);
  const [subtitleArray, setSubtitleArray] = useState([]);
  const [schedule, setSchedule] = useState(null);

  //  Function to move to automation rules
  const moveToManageSolutions = () => {
    history.push("/manage-solutions");
  };

  //  Component did mount
  useEffect(() => {
    const newTitleArray = buildLogsTextRow([companyName, partnerName], [moveToManageSolutions]);
    setTitleArray(newTitleArray);
  }, []);

  //  Watching api response
  useEffect(() => {
    if (!syncLogsResponse) {
      return;
    }
    const { count, rows } = syncLogsResponse;
    const newSubtitleArray = buildLogsTextRow([
      companyName,
      `${partnerName} logs`,
    ]);
    setSubtitleArray(newSubtitleArray);
    if (count > 0) {
      const latestSync = rows[0];
      const { end_time } = latestSync;
      setSchedule(end_time ? moment(end_time).format("dddd, MMMM Do YYYY, h:mm:ss a") : end_time);
    }
  }, [syncLogsResponse]);

  //  Wacthing api error
  useEffect(() => {
    if (!syncLogsError) {
      return;
    }
    dispatch(setAlert("Error getting partner sync logs.", "danger"));
  }, [syncLogsError]);

  //  Function to get rule logs
  const getPartnerSyncLogs = async (filters) => {
    if (!partnerId || !companyId) return null;
    return await RuleAPI.handlers.getPartnerSyncLogs(
      partnerId,
      companyId,
      filters
    );
  };

  //  Function to handle row clicked
  const handleRowClicked = (obj) => {
    if (!obj) {
      return null;
    }
    const { id: log_group_id } = obj;
    history.push(`/solution-logs/${log_group_id}`);
  };

  //  Function to render schedule label
  const renderSchedule = () => {
    if (!schedule) {
      return null;
    }
    return (
      <div className="schedule-label-container">
        <div className="bx bx-time schedule-icon" />
        <div className="schedule-label">Updated on {schedule}</div>
      </div>
    );
  };

  //  Function to render table title
  //  TODO: render exec information when needed
  const renderTableTitle = (totalLogGroups) => {
    return (
      <div className="last-exec-container">
        <div className="bx bx-rocket last-exec-icon" />
        <div className="last-exec-label">{`${totalLogGroups} log groups`}</div>
      </div>
    );
  };

  //  Function to render the header
  const renderHeader = () => {
    return (
      <div className="rule-logs-header">
        <NavigationTextRow
          elements={titleArray}
          delimiter=">"
          rowExtraClassName="title-row"
        />
        <NavigationTextRow
          elements={subtitleArray}
          delimiter=":"
          rowExtraClassName="subtitle-row"
        />
        {renderSchedule()}
      </div>
    );
  };

  //  Rendering
  return (
    <div className="rule-logs-container">
      {renderHeader()}
      <SearchableInfiniteTable
        tableContainerClassName="rule-logs-table-container"
        tableClassName="rule-logs-table"
        headerClassName="rule-logs-table-header"
        rowClassName="rule-logs-table-row"
        searchbarClassName="rule-logs-search-bar"
        columnsTemplate={ruleLogsTemplate}
        apiFunction={getPartnerSyncLogs}
        apiKey={RuleAPI.keys.getPartnerSyncLogs}
        extraKeys={[companyId, partnerId]}
        renderTitle={(total) => renderTableTitle(total)}
        clickableRow={true}
        onRowClicked={handleRowClicked}
        isSearchable={false}
        emptyConfig={{
          title: "Oops!",
          message: "There are no logs from the API...",
          titleClassName: "rule-logs-empty-title",
          messageClassName: "rule-logs-empty-message",
        }}
      />
    </div>
  );
};

export default SolutionLogs;
