import React from 'react';

const CustomToggleSwitch = (props) => {
  //  Parsing props
  const {
    containerClassName = '',
    inputClassName = '',
    spanClassName = '',
    checkedLabelClassName = '',
    unCheckedLabelClassName = '',
    includeLabel = false,
    checked = false,
    setChecked,
    disabled
  } = props;

  //  Function to render label
  const renderLabel = () => {
    if (!includeLabel) { return null; }
    return (
      <span className={checked ? checkedLabelClassName : unCheckedLabelClassName}>
        {checked ? 'On' : 'Off'}
      </span>
    );
  };

  //  Rendering
  return (
    <label className={`switch ${containerClassName}`}>
      <input
        className={`switch-input ${inputClassName}`}
        type="checkbox"
        checked={checked}
        onClick={() => setChecked(!checked)}
        disabled={disabled}
        readOnly
      />
      <div className={`slider round ${disabled ? 'pe-none': ''} ${spanClassName}`}>
        {renderLabel()}
      </div>
    </label>
  );
};

export default CustomToggleSwitch;
