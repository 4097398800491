import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { alertTypes } from '../../constants/alerts';
import { tagsTitleSize } from '../../constants/tags';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useValueDebounce from '../../hooks/useValueDebounce';
import useTagsQuery from '../../hooks/useTagsQuery';

const TagsAddComponent = (props) => {
    const {
        callback,
        issueId,
        deviceId,
        placeholder = "Add Tag",
        companyId,
        menuPosition,
        reloadTagsList,
        setReloadTagsList,
        disabled,
    } = props;

    // Component state
    const [filteredTags, setFilteredTags] = useState([])
    const [tagsOptions, setTagOptions] = useState([])
    const [selectedTag, setSelectedTag] = useState(null);
    const [searchText, setSearchText] = useState(undefined);
    const [maxlength] = useState(tagsTitleSize)
    const [alert, setAlert] = useState(false)

    // Component Hooks
    const { dispatch } = useMemoizedDispatch();
    const { debouncedValue: textToSearch } = useValueDebounce({
        debounceTime: 400,
        defaultValue: undefined,
        valueToDebounce: searchText?.trim(),
    });

    //  Getting tags list
    const {
        tags,
        error: tagsListError,
        loading,
        getNextPage,
        clearQueryData,
    } = useTagsQuery({
        company_id: companyId,
        issue_id: issueId,
        device_id: deviceId,
        textToSearch,
    });

    // Handle clear clicked
    const clearState = () => {
        setSelectedTag(null);
        setSearchText('');
        clearQueryData();
    };

    //  Watching tags error
    useEffect(() => {
        if (!tagsListError) { return; }
        dispatch(setAlert('Error selecting tag', alertTypes.error));
        setSelectedTag(null);
        clearQueryData();
    }, [tagsListError]);

    // Watch tags and textToSearch changes
    useEffect(() => {
        const isAdded = tags.find(tag => tag.label === textToSearch?.toLowerCase())
        const isSeachTermEmpty = textToSearch === null || textToSearch === '' || textToSearch === undefined;
        setTagOptions([
            ...!isSeachTermEmpty && !isAdded && !loading ? [{ value: null, label: textToSearch, isNew: true }] : [],
            ...tags
        ])
    }, [tags, textToSearch])

    useEffect(() => {
        if (reloadTagsList) {
            clearState();
            if (setReloadTagsList) setReloadTagsList(false)
        }
    }, [reloadTagsList])

    useEffect(() => {
        setFilteredTags(tagsOptions.filter(tag => !tag?.is_tagged))
    }, [tagsOptions])

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <i className="fa fa-search" aria-hidden="true" />
            </components.DropdownIndicator>
        );
    };
    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: "#241563",
            borderColor: alert ? "red" : "#241563",
            borderRadius: "#241563",
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                borderColor: "#241563"
            }
        }),
        menu: base => ({
            ...base,
            borderRadius: 0,
            marginTop: 0,
            Color: '#352B6D',
            zIndex: 9999
        }),
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menuList: base => ({
            ...base,
            padding: 0,
            background: "#241563",
        }),
        valueContainer: base => ({
            ...base,
            paddingLeft: 35
        }),
    };

    const shouldSetMaxLengthAlert = (element) => {
        if (element && element.length >= maxlength) {
            setAlert(true);
            setTimeout(() => {
                setAlert(false)
            }, 5000);
            return true
        }
        return false
    }

    const renderErrorMessage = () => {
        return alert && (
            <p style={{ color: "red", fontSize: '14px' }}>{`Tags can’t have more than ${tagsTitleSize} characters`}</p>
        )
    }

    const renderPlusIcon = () => {
        const disabledClassName = disabled ? "opacity-50" : "";
        const iconClasName=`plus-circle-icon bx bx-plus-circle ${disabledClassName}`
        return (
            <i className={iconClasName} />
        )
    }

    //  Function to handle change on select
    const handleDropdownChange = (tag) => {
        callback({ title: tag?.label })
        setSelectedTag(tag);
    };

    //  Funciton to handle chanes on input (search text case)
    const handleInputChange = (searchTerm) => {
        if (shouldSetMaxLengthAlert(searchTerm?.trim())) return;
        setSearchText(searchTerm);
    };

    const handleCallback = (value) => {
        if (!value || !callback) return;
        const params = { title: value }
        callback(params)
    }

    // Function to handle enter event
    const handleKeyPress = (event) => {
        if (event?.key === 'Enter') {
            const tagTitle = event.target.value
            handleCallback(tagTitle);
            event.preventDefault();
        }
    }

    //  Function to handle on scroll bottom
    const onScrollBottom = () => {
        getNextPage();
    };

    return (
        <div className="search-box" >
            <div
                className="business-context-tags-dropdown-container"
                onKeyPress={handleKeyPress}
            >
                {renderPlusIcon()}
                <Select
                    inputValue={searchText}
                    value={selectedTag}
                    options={filteredTags}
                    onChange={handleDropdownChange}
                    onInputChange={handleInputChange}
                    onMenuScrollToBottom={onScrollBottom}
                    isLoading={loading}
                    isSearchable={true}
                    menuPosition={menuPosition}
                    components={{ DropdownIndicator }}
                    placeholder={placeholder}
                    noOptionsMessage={() => null}
                    styles={customStyles}
                    className="react-select-container business-context-tags-dropdown add-tag-component"
                    classNamePrefix="react-select"
                    formatOptionLabel={({ label, isNew }) => {
                        if (!isNew) { return label; }
                        return `${label} (New)`
                    }}
                    isDisabled={disabled}
                />
            </div>
            {renderErrorMessage()}
        </div>
    )
}
export default TagsAddComponent;
