import {
    CYDEKICKS_FAILURE,
    CYDEKICKS_REQUEST,
    CYDEKICKS_SUCCESS,
    CYDEKICKS_CLEAR,
    SET_CYDEKICKS_SORT,
    SET_CYDEKICKS_FILTER,
    CLEAR_ALL_CYDEKICKS_FILTERS,
    RELOAD_CYDEKICKS,
} from './types';
import filterTypes from "../constants/filterTypes";

export const fetchCydekicks = (config) => {
    return async (dispatch, getState, { API, queryClient }) => {
        try {
            const { UserAPI } = API;
            //  Need this dispatch to handle loading state
            dispatch({ type: CYDEKICKS_REQUEST });
            const result = await UserAPI.handlers.fetchCydekicks(config);
            dispatch(fetchCydekicksSuccess(result?.data));
        } catch (error) {
            dispatch(fetchCydekicksError(error));
        }
    };
}

export const reloadCydekicks = (data) => ({
    type: RELOAD_CYDEKICKS,
    payload: data,
});

export const fetchCydekicksSuccess = (data) => ({
    type: CYDEKICKS_SUCCESS,
    payload: data,
});

export const fetchCydekicksError = (message) => ({
    type: CYDEKICKS_FAILURE,
    payload: message
});

export const clearCydekicks = () => {
    return {
        type: CYDEKICKS_CLEAR,
    }
}

export const setCydekicksFilter = (filterName, value, label, type = filterTypes.single) => {
    return {
        type: SET_CYDEKICKS_FILTER,
        payload: { filterName, value, label, type }
    }
}

export const clearCydekicksFilters = () => {
    return { type: CLEAR_ALL_CYDEKICKS_FILTERS }
}

export const setCydekicksSort = (value) => {
    return {
        type: SET_CYDEKICKS_SORT,
        payload: value
    }
}