import React, { useState } from 'react';
import CustomTableHeader from './CustomTableHeader';
import CustomTableRow from './CustomTableRow';
import InfiniteScrollingLoading from './InfintiteScrollingLoading';
import EmptyState from './EmptyState';
import LoadingContent from '../layout/LoadingContent';
import { tablePaginationTypes } from '../../constants/common';
import { isValidFunction } from '../../utils/helpers';

const CustomTable = (props) => {
  //  Parsing props
  const {
    tableClassName = '',
    headerClassName = '',
    rowClassName = '',
    columnsTemplate = [],
    data = [],
    isSubtable = false,
    isScrollable = false,
    requestMore,
    changeSort,
    sort = [],
    allSelected = false,
    selected = [],
    excluded = [],
    handleSelectedEvent,
    loading = false,
    emptyConfig = {}, // { title, message, titleClassName, messageClassName }
    clickableRow = false,
    onRowClicked,
    expandableRow = false,
    paginationType = tablePaginationTypes.INFINITE_SCROLLING,
    error,
    allowMultipleSort = false,
    handleCellAction,
    onHoverEnter,
    onHoverTableLeave = null
  } = props;

  //  Componen state
  const [expandAll, setExpandAll] = useState(false);

  //  Function to change expand all state
  const changeExpandAll = () => {
    setExpandAll(!expandAll);
  };

  //  Function to render empty state
  const renderEmptyState = () => {
    if (isSubtable || loading) { return null; }
    const {
      title,
      message,
      titleClassName,
      messageClassName,
      customImage,
      emptyStateContainer
    } = emptyConfig;

    const emptyState =
      <EmptyState
        title={title}
        message={message}
        titleClassName={titleClassName}
        messageClassName={messageClassName}
        customImage={customImage}
      />

    if (isValidFunction(emptyStateContainer)) {
      return emptyStateContainer(emptyState);
    }

    return (emptyState);
  };

  //  Function to render data
  const renderData = () => {
    if (data.length === 0 && !emptyConfig.displayTable) return renderEmptyState();
    return data.map((item, index) => {
      return (
        <CustomTableRow
          key={`custom-table-row-${index}`}
          index={index}
          columnsTemplate={columnsTemplate}
          rowClassName={rowClassName}
          item={item}
          allSelected={allSelected}
          selected={selected}
          excluded={excluded}
          handleSelectedEvent={handleSelectedEvent}
          clickableRow={clickableRow}
          onRowClicked={onRowClicked}
          expandableRow={expandableRow}
          expandAll={expandAll}
          handleCellAction={handleCellAction}
          onHoverEnter={onHoverEnter}
        />
      );
    });
  };

  //  Listener for on scroll
  const onScroll = ({ currentTarget }) => {
    if (paginationType !== tablePaginationTypes.INFINITE_SCROLLING) { return; }
    const { scrollTop, offsetHeight, scrollHeight } = currentTarget;
    if (scrollTop + offsetHeight >= scrollHeight) {
      if (requestMore) { requestMore(); }
    }
  };

  //  Function to render loading
  const renderLoading = () => {
    if (!loading || paginationType !== tablePaginationTypes.INFINITE_SCROLLING) { return null; }
    return <InfiniteScrollingLoading />;
  };

  const handleOnMouseLeave = () => {
    if (!onHoverTableLeave) return
    onHoverTableLeave();
  }

  //  Rendering
  return isSubtable ?
    <div className={tableClassName}>
      {renderData()}
    </div>
    : (
      <>
        <table
          className={tableClassName}
          onMouseLeave={handleOnMouseLeave}
        >
          <thead>
            <CustomTableHeader
              headerClassName={headerClassName}
              columnsTemplate={columnsTemplate}
              changeSort={changeSort}
              sort={sort}
              allSelected={allSelected}
              handleSelectedEvent={handleSelectedEvent}
              expandableRow={expandableRow}
              expandAll={expandAll}
              changeExpandAll={changeExpandAll}
              allowMultipleSort={allowMultipleSort}
            />
          </thead>
          <tbody
            className={isScrollable ? ' custom-scrollable-table' : ''}
            onScroll={onScroll}
          >
            <LoadingContent
              isLoading={paginationType !== tablePaginationTypes.INFINITE_SCROLLING && !isSubtable && loading}
              iconType="solidIcon"
              errorStyle={{ minHeight: "50px", paddingTop: "100px" }}
              loadingStyle={{ minHeight: "50px", paddingTop: "100px" }}
              errorMessage={paginationType !== tablePaginationTypes.INFINITE_SCROLLING && !isSubtable && error ?
                "Oops! our fault, reload again." : null}
              showErrorButton={false}
            >

              {renderData()}
            </LoadingContent>
          </tbody>
        </table>
        {data.length === 0 && emptyConfig.displayTable && renderEmptyState()}
        {renderLoading()}
      </>
    );
};

export default CustomTable;
