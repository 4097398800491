import Login from '../components/auth/Login';
import FreemiumLogin from '../components/auth/FreemiumLogin';
import Logout from '../components/auth/Logout';
import Assessment from '../views/Assessment';
import AssessmentWelcomePage from '../views/AssessmentWelcome';
import ChangePassword from '../views/ChangePassword';
import Settings from '../views/Settings';
import MFAConfig from '../views/MFAConfig';
import MFASMS from "../views/MFASMS";
import ResetPassword from '../views/ResetPassword';
import Profile from '../views/Profile';
import IncomingLead from "../views/IncomingLead";
import TeamManager from '../views/TeamManager';
import MemberRegister from '../views/MemberRegister'
import Members from "../views/Members";
import CustomerSolutions from "../views/CustomerSolutions";
import Devices from '../views/Devices';
import IndexRoute from './IndexRoute';
import InvitedMemberRegister from '../components/auth/InvitedMemberRegister';
import ExportContent from '../views/ExportContent';
import PublicBadgeCertificate from '../views/PublicBadgeCertificate';
import Automation from '../views/Automation';
import Solutions from '../views/Solutions';
import HomeRoutes from './nested-routes/HomeRoutes';
import MyCysoRoutes from './nested-routes/MyCysoRoutes';
import IssueRoutes from './nested-routes/IssueRoutes';
import AssessmentRoutes from './nested-routes/AssessmentRoutes';
import RegisterSuccessMessage from '../views/RegisterSuccessMessage';
import MemberVerification from '../components/auth/MemberVerification';
import VerificationSuccessMessage from '../views/VerificationSuccessMessage';
import { LIST_ACTION, PRIVILEGE_SOURCES, READ_ACTION } from '../constants/privileges';
import SolutionSettings from '../views/SolutionSettings';
import AppLauncherView from '../views/AppLauncherView';
import SolutionLogsContainer from '../views/SolutionLogsContainer';
import RecycleBin from '../views/RecycleBin';
import ConnectionStatusView from '../views/ConnectionStatusView';
import GroupsAndIssuesRoutes from './GroupsAndIssuesRoutes';
import CompanyAttachment from '../views/CompanyAttachment';
import PeakCommerceVerification from '../components/auth/PeakCommerceVerification';

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/freemium", component: FreemiumLogin },
  { path: "/my-cyvatar", component: FreemiumLogin },
  { path: "/welcome", component: AssessmentWelcomePage },
  { path: "/cybercheckup", component: Assessment },
  { path: "/reset-password", component: ResetPassword },
  { path: "/reset-password/:token", component: ResetPassword },
  { path: "/incoming-lead", component: IncomingLead },
  { path: "/cydekicks/join/:token", component: MemberRegister, },
  { path: "/members/join/:token", component: InvitedMemberRegister, },
  { path: "/export/:type/:token", component: ExportContent },
  { path: "/badge/:public_id", component: PublicBadgeCertificate },
  { path: '/register-success', component: RegisterSuccessMessage },
  { path: '/member/verification/:token', component: MemberVerification },
  { path: '/member/authorization/:token', component: MemberVerification },
  { path: '/member/new-password', component: PeakCommerceVerification },
  { path: '/verification-success', component: VerificationSuccessMessage },
  { path: '/profile/company/:company_public_id/attachments/:attachment_id', component: CompanyAttachment },
  { path: '/profile/company/:company_public_id/:issue_public_id/attachments/:attachment_id', component: CompanyAttachment },
];

const privateRoutes = [
  { path: "/logout", component: Logout },
  {
    path: "/dashboard",
    component: HomeRoutes,
    requireMFA: true,
    hasSubroutes: true,
    access: READ_ACTION,
    resource: PRIVILEGE_SOURCES.HOME.GENERAL,
  },
  {
    path: "/team",
    component: TeamManager,
    access: LIST_ACTION,
    resource: PRIVILEGE_SOURCES.CYVATAR_TEAM.GENERAL,
  },
  {
    path: "/issues",
    component: IssueRoutes,
    access: LIST_ACTION,
    resource: PRIVILEGE_SOURCES.ISSUES.GENERAL,
    requireMFA: true,
  },
  {
    path: "/members",
    component: Members,
    access: LIST_ACTION,
    resource: PRIVILEGE_SOURCES.MEMBERS.GENERAL,
  },
  {
    path: "/manage-solutions",
    component: CustomerSolutions,
    access: LIST_ACTION,
    resource: PRIVILEGE_SOURCES.COMPANY_SOLUTIONS.GENERAL,
  },
  { path: "/change-password", component: ChangePassword },
  {
    path: "/settings",
    component: Settings,
  },
  { path: "/mfa-config", component: MFAConfig },
  { path: "/mfa-sms", component: MFASMS },
  {
    path: "/profile",
    component: Profile,
    access: READ_ACTION,
    resource: PRIVILEGE_SOURCES.PROFILE.GENERAL,
    hasSubroutes: true,
  },
  {
    path: "/assessment",
    component: AssessmentRoutes,
    access: READ_ACTION,
    resource: PRIVILEGE_SOURCES.ASSESSMENT.GENERAL,
  },
  {
    path: "/devices",
    component: Devices,
    requireMFA: true,
    access: LIST_ACTION,
    resource: PRIVILEGE_SOURCES.DEVICES.GENERAL,
  },
  { path: "/", component: IndexRoute },
  {
    path: "/automation",
    component: Automation,
    requireMFA: true,
    access: LIST_ACTION,
    resource: PRIVILEGE_SOURCES.AUTOMATION.INTEGRATION_RULE,
    hasSubroutes: true,
  },
  {
    path: "/solutions",
    component: Solutions,
    access: LIST_ACTION,
    resource: PRIVILEGE_SOURCES.PARTNERS.GENERAL,
  },
  {
    path: "/solution-settings",
    component: SolutionSettings,
    access: null,
    resource: null,
  },
  // TODO: add privileges
  {
    path: "/app-launcher",
    component: AppLauncherView,
    requireMFA: true,
    access: null,
    resource: null,
  },
  {
    path: "/solution-logs",
    component: SolutionLogsContainer,
    access: LIST_ACTION,
    resource: PRIVILEGE_SOURCES.COMPANY_SOLUTIONS.GENERAL,
    hasSubroutes: true,
  },
  {
    path: '/recycle-bin',
    component: RecycleBin,
    access: LIST_ACTION,
    resource: PRIVILEGE_SOURCES.ISSUES.GENERAL,
    hasSubroutes: true,
  },
  {
    path: "/connection-management",
    component: ConnectionStatusView,
    access: LIST_ACTION,
    resource: PRIVILEGE_SOURCES.COMPANY_SOLUTIONS.GENERAL,
  },
  {
    path: '/groups-manager',
    component: GroupsAndIssuesRoutes,
    access: READ_ACTION,
    resource: PRIVILEGE_SOURCES.ISSUES.GROUPS,
    hasSubroutes: true,
  }
];

export { publicRoutes, privateRoutes };
