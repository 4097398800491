import React from 'react';
import { getValidationKeyError } from '../../../utils/joiValidations';

const GrcIssuesBasicDetails = (props) => {
  //  Parsing props
  const {
    name,
    description,
    onFieldChange,
    validationError
  } = props;

  const renderError = (error) => {
    if(!error) return null;
    return (
      <div className="grc-input-error">
          {error?.message}
      </div>
    )
  }

  //  Function to render input container
  const renderInputContainer = (label, placeholder, key, value, rows, customClassName = '') => {
    const error = getValidationKeyError(validationError, key)
    return (
      <div className='input-container'>
        <div className='input-label'>{label}</div>
        <textarea
          className={`input-item ${customClassName}`}
          type="text"
          value={value || ''}
          placeholder={placeholder}
          onChange={({ target }) => onFieldChange(key, target?.value)}
          rows={rows}
        />
        {renderError(error)}
      </div>
    );
  };

  //  Rendering
  return (
    <div className='grc-modal-basic-details'>
      {renderInputContainer('Task Name', 'Name the task...', 'name', name, 1)}
      {renderInputContainer('Description', 'Add description...', 'description', description, 5, 'description-textarea')}
    </div>
  );
};

export default GrcIssuesBasicDetails;
