import { v4 as uuidv4 } from 'uuid';
import { alertTypes } from '../constants/alerts';
import { SET_ALERT, REMOVE_ALERT, REMOVE_ALL_ALERTS } from "./types"

export const setAlert = (msg, alertType = alertTypes.important, autoHide = true, timeout = 5000, customClassName, customId = null) => {
    return (dispatch) => {
        const id = customId || uuidv4();
        
        //  Autohiding if needed
        if (autoHide) {
            setTimeout(() => {
                dispatch(removeAlert(id));
            }, timeout);
        }

        //  Dispatching action
        dispatch({
            type: SET_ALERT,
            payload: { msg, alertType, id, autoHide, timeout, customClassName }
        });
    }
}

export const removeAlert = (id) => {
    return { type: REMOVE_ALERT, payload: id }
}

export const removeAllAlerts = () => {
    return { type: REMOVE_ALL_ALERTS }
}