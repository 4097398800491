import React from 'react';
import { Field } from 'react-final-form';
import { emailFieldTemplate, buildFieldObjectFromTemplate, firstNameTemplate, lastNameTemplate } from '../../constants/formTemplates';
import { renderInput } from '../../utils/renderFormFields';
import { removeItemFromArray } from '../../utils/helpers';


const AddNewMembersComponent = (props) => {
    const {
        title,
        lastFieldIndex,
        setLastFieldIndex,
        rowsToRender,
        setRowsToRender,
        includeAdmin = false,
        includeName = false
    } = props;

    //  Function to add new field to the DOM
    const addRow = () => {
        const newLastFieldIndex = lastFieldIndex + 1;
        const newRow = [
            ...includeName ? [buildFieldObjectFromTemplate(newLastFieldIndex, firstNameTemplate, null, null, "invite-members-field-wrapper name-field-wrapper")] :  [],
            ...includeName ? [buildFieldObjectFromTemplate(newLastFieldIndex, lastNameTemplate, null, null, "invite-members-field-wrapper name-field-wrapper")] : [],
            buildFieldObjectFromTemplate(newLastFieldIndex, emailFieldTemplate, null, "Enter email", "invite-members-field-wrapper email-field-wrapper"),
        ]
        setRowsToRender([...rowsToRender, newRow]);
        setLastFieldIndex(newLastFieldIndex);
    };

    //  Function to remove field from DOM
    const removeRow = (index) => {
        const newFieldsToRender = removeItemFromArray(rowsToRender, index);
        setRowsToRender(newFieldsToRender);
    };

    //  Function to render the remove field icon
    const renderRemoveRowIcon = (index) => {
        if (rowsToRender.length <= 1 || (includeAdmin && index === 0)) { return null; }
        return (
            <i
                className="bx bx-minus-circle align-middle remove-field-button"
                onClick={() => removeRow(index)}
            />
        );
    };

    //  Function to render the admin icon
    const renderAdminIcon = (index) => {
        if (!includeAdmin || index !== 0) { return null; }
        return (
            <i
                className="admin-crown-icon bx bxs-crown align-middle"
            />
        );
    };

    // Function to render field
    const renderField = (field, index, rowIndex) => {
        const {
            label,
            placeholder,
            className,
            type,
            name,
            wrapperClassName
        } = field;

        return (
            <div key={`field-${index}`} className={`d-flex flex-column ${wrapperClassName}`}>
                <div className="field-label">{label}</div>
                <Field
                    key={`${rowIndex}-${index}`}
                    render={renderInput}
                    name={name}
                    placeholder={placeholder}
                    className={className}
                    customClassName={`${name}${rowIndex}-${index}`}
                    type={type}
                    scrollable={true}
                />
            </div>
        );
    };

    //  Function to render all the rows
    const renderRows = () => {
        if (!rowsToRender) { return null; }

        return rowsToRender.map((row, rowIndex) => {
            return (
                <div key={rowIndex} className="row-fields-container">
                    <div className="icon-container">
                        {renderRemoveRowIcon(rowIndex)}
                        {renderAdminIcon(rowIndex)}
                    </div>
                    {row.map((field,fieldIndex) => renderField(field, fieldIndex, rowIndex))}
                </div>
            )
        })
    }

    const renderAddMember = () => {
        if(rowsToRender.length > 4) return null;
        return (
            <div
                className="btn btn-add-email"
                onClick={() => addRow()}
            >
                <i className='bx bx-plus-circle mr-2 align-middle remove-field-button' />
                Add another member as viewer
            </div>
        )
    }

    return (
        <div className="add-new-members-component">
            <div className="section-title">{title}</div>
            <div>
                {renderRows()}
            </div>
            {renderAddMember()}
        </div>
    )
}

export default AddNewMembersComponent
