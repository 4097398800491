import React from 'react';
import { Link } from 'react-router-dom';
import { trackEvent } from './gaTracker';

export const getCreateIssueSuccessMessage = (data) => {
  return (
    <div className="d-inline-block">
      New issue created
      {' '}
      <Link
        to={`/issues?details&selectedIssue=${data?.code}`}
        className="link-dark underline-link"
      >
        {data?.code}
      </Link>
    </div>
  );
};

export const getDuplicatedDomainErrorMessage = () => {
  return (
    <React.Fragment>
      <div className="message">
        Someone at your organization already took this assessment.
        <br />
        Please select a different email or{' '}
        <button
          className="btn btn-link underline-link p-0"
          onClick={() => {
            trackEvent("contact_us", "user", "contact_us");
            window.open(process.env.REACT_APP_CONTACT_SALES_URL, "_blank");
          }}
        >
          contact sales
        </button>
      </div>
      <div className="align-self-end">
        <button
          className="btn btn-contact-us text-dark mb-1 mt-auto ml-auto px-md-3 py-md-2 px-2 py-1"
          onClick={() => {
            trackEvent("contact_us", "user", "contact_us");
            window.open(process.env.REACT_APP_CONTACT_SALES_URL, "_blank");
          }}
        >
          Contact Us
        </button>
      </div>
    </React.Fragment>
  );
};
