import React from 'react';
import { Button } from 'reactstrap';
import { timeUnits, timeUnitsArray } from '../../../../constants/tendencyChart';
import { moveDateByType, getRangeByFilterType } from '../../../../utils/timeChartHelpers';

const TendencyChartControls = (props) => {
    const {
        isSummary = false, // Flag to know if is the simple version of the chart (useful to hide some components)
        units = timeUnitsArray,
        filters = {},
        setFilters,
        totalPointsConfig,
        tabIndex
    } = props;
    const {
        start_date,
        end_date,
        filter_type = timeUnits.week
    } = filters;


    const onUnitClicked = (type) => {
        if(!setFilters || typeof(setFilters) !== 'function') return;
        const range = getRangeByFilterType(type, new Date(), false, totalPointsConfig);
        setFilters({ ...filters, filter_type: type, ...range }, true);
    }

    const onArrowClicked = (direction) => {
        if(!setFilters || typeof(setFilters) !== 'function') return;
        const newStartDate = moveDateByType(start_date, 1, filter_type, direction);
        const newEndDate = moveDateByType(end_date, 1, filter_type, direction);
        setFilters({ ...filters, start_date: newStartDate, end_date: newEndDate }, false);
    }

    // Function to render buttons to select type of time unit
    const renderUnits = () => {
        if(!units || !Array.isArray(units)) return null;
        return units.map((unit, index) => {
            const { type, label } = unit;
            return (
              <Button
                color="light-gray"
                key={index}
                className={`tendency-chart-button${type === filter_type ? ' selected' : ''}`}
                onClick={() => onUnitClicked(type)}
                outline
                aria-label={`${label} button for the control of the chart time unit`}
                tabIndex={tabIndex}
              >
                {label}
              </Button>
            );
          });
    }

    //  Function to render button to reset date range
    const renderReset = () => {
        if (isSummary) { return null; }
        return (
            <Button
                color="link"
                className={`text-light-gray bx bx-reset btn-reset-range p-0`}
                onClick={() => onUnitClicked(filter_type)}
                aria-label={`Reset chart`}
                tabIndex={tabIndex}
            />
        );
    }

    //  Function to render arrows
    const renderArrow = (direction) => {
        if (isSummary) { return null; }
        const className = `btn-chart-arrow bx bxs-${direction}-arrow-circle align-middle direction-${direction}`
        return (
            <Button
                color="link"
                className={className}
                onClick={() => onArrowClicked(direction)}
                tabIndex="-1"
            />
        );
    };

    //  Rendering
    return (
        <div className="tendency-chart-controls">
            {renderArrow('left')}
            {renderUnits()}
            {renderReset()}
            {renderArrow('right')}
        </div>
    );
}

export default TendencyChartControls;
