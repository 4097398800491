import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import IconCard from '../../layout/cards/IconCard';
import { Col, Row } from 'reactstrap';
import API from '../../../api';
import LoadingContent from '../../layout/LoadingContent';
import companyIdSelector from '../../../selectors/companyIdSelector';
import CyvatarBadge from '../../badges/CyvatarBadge';
import _ from 'lodash';
import CyvatarCertificateInformation from '../../badges/CyvatarCertificateInformation';
import errorMessages from '../../../constants/errorMessages';
import useCustomQuery from '../../../hooks/useCustomQuery';

const Accomplishments = (props) => {

    const {
        lg = 4
    } = props;

    //  Initializing APIs
    const { BadgeAPI } = API;

    //  Getting needed info from redux store
    const companyId = useSelector((state) => companyIdSelector(state, true));

    //  Calling API
    const {
        data: badgesListResponse,
        error: badgesListError,
        isLoading: badgesListLoading,
        refetch,
    } = useCustomQuery(
        [BadgeAPI.keys.getCompanyBadges, companyId],
        ({ queryKey }) => BadgeAPI.handlers.getCompanyBadges(queryKey[1]),
    );

    //Component state
    const [cyvatarBadge, setCyvatarBage] = useState(null);

    // Constant to check if account is Freemium
    const isFreemium = useMemo(() => {
        return badgesListResponse?.freemium != 0;
    }, [badgesListResponse?.freemium]);

    //Lookup for cyvatar badge
    useEffect(() => {
        if (!badgesListResponse || !badgesListResponse.badges) return;
        const badge = badgesListResponse.badges.find(badge => badge.action = "CYV_MEMBER")
        setCyvatarBage(badge);
    }, [badgesListResponse]);

    const renderCyvatarBadge = () => {
        return (
            <Col md={2}>
                <CyvatarBadge
                    image={cyvatarBadge?.image}
                    isUnlocked={!isFreemium}
                    width="80%"
                />
            </Col>
        )
    }

    const renderCyvatarCertificateInformation = () => {
        return (
            <Col md={9}>
                <CyvatarCertificateInformation
                    isUnlocked={!isFreemium}
                    badge={cyvatarBadge}
                    companyName={badgesListResponse?.company_name}
                    eventSection="accomplishments"
                    showEmbedCode={true}
                    showCode={false}
                />
            </Col>
        )
    }

    return (
        <Col lg={lg} className="align-self-lg-stretch mb-3">
            <IconCard
                cardWrapperClassName="accomplishments-card h-100 user-profile"
                title="Achievements"
                titleClassName="text-dirty-white"
                iconClassName="bx bx-medal text-white"
                iconWrapperClassName="bg-active-purple"
            >
                <LoadingContent
                    isLoading={badgesListLoading}
                    errorMessage={badgesListError ? (badgesListError?.data?.errorMessage || errorMessages.defaultGet) : ''}
                    errorButtonCallback={refetch}
                    iconType="solidIcon"
                    errorStyle={{ minHeight: "266px", paddingTop: "41px" }}
                    loadingStyle={{ minHeight: "266px", paddingTop: "41px" }}
                >
                    <Row className='accomplishments-content'>
                        {renderCyvatarBadge()}
                        {renderCyvatarCertificateInformation()}
                    </Row>
                </LoadingContent>
            </IconCard>
        </Col>
    )
}
export default Accomplishments;
