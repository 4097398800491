import React, { useEffect, useState } from 'react';
import SearchBar from '../layout/filters/SearchBar';
import CustomTable from './CustomTable';
import { infiniteTableLimit } from '../../constants/common'
import { setAlert } from '../../actions/alerts';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useScrollingQuery from '../../hooks/useScrollingQuery';

const initialFilters = {
  limit: infiniteTableLimit,
  searchTerm: '',
  sort: [],
};

const SearchableInfiniteTable = (props) => {
  //  Parsing props
  const {
    tableContainerClassName = '',
    tableClassName = '',
    headerClassName = '',
    rowClassName = '',
    searchbarClassName = '',
    searchbarPlaceholder = 'Search',
    columnsTemplate = [],
    emptyConfig = {},
    apiFunction, // Function to call the API
    apiKey, // Key for api call
    extraKeys = [],
    renderTitle, // Children are the extra elements displated at the right side of the search
    dataParser = (array = []) => array, // Function to parse data if needed
    clickableRow = false,
    onRowClicked,
    expandableRow = false,
    isSearchable = true,
    allSelected = false,
    selected = [],
    excluded = [],
    handleSelectedEvent = () => {},
    setElements = () => {},
  } = props;

  //  Component state
  const [requestMore, setRequestMore] = useState(false);
  const [filters, setFilters] = useState(initialFilters);

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const {
    totalElements,
    elements,
    error: elementsError,
    loading: elementsLoading,
    hasNextPage,
    fetchNextPage,
  } = useScrollingQuery({
    baseKey: apiKey,
    key: [apiKey, filters, ...extraKeys],
    queryFunction: apiFunction,
    parseElements: dataParser
  });

  //  Watching api response, settings the table elements for the parent component is not needed in most of the app, only on device table in issues groups (so far).
  useEffect(() => {
    if (!elements) { return; }
    setElements(elements);
  }, [elements]);

  //  Watching api error
  useEffect(() => {
    if (!elementsError) { return; }
    dispatch(setAlert('Error getting elements. ', 'danger'));
  }, [elementsError]);

  //  Watching request more changes
  useEffect(() => {
    if (!requestMore) { return; }
    setRequestMore(false);
    if (!hasNextPage || elementsLoading) { return; }
    fetchNextPage();
  }, [requestMore]);

  //  Function to change filters
  const changeFilters = (fieldName, value) => {
    if (filters[fieldName] === value) { return; }
    setFilters({ ...filters, [fieldName]: value });
  };

  //  Function to change sort
  const changeSort = (fieldName, sorting) => {
    //  Updating sort
    const newSort = [fieldName, sorting];
    changeFilters('sort', newSort);
  };

  //  Function to request more elements
  const requestMoreElements = () => {
    setRequestMore(true);
  };

  //  Function to render table top bar
  const renderTableTopBar = () => {
    return (
      <div className="searchable-table-topbar">
        {renderTitle(totalElements)}
        {
          isSearchable &&
          <SearchBar
            useSimpleContainer
            customClassName={`search-box mr-2 mb-2 d-inline-block ${searchbarClassName}`}
            placeholder={searchbarPlaceholder}
            value={filters?.searchTerm}
            callback={(value) => changeFilters('searchTerm', value)}
          />
        }
      </div>
    );
  };

  //  Rendering
  return (
    <>
      {renderTableTopBar()}
      <div className={tableContainerClassName}>
        <CustomTable
          tableClassName={tableClassName}
          headerClassName={headerClassName}
          rowClassName={rowClassName}
          columnsTemplate={columnsTemplate}
          data={elements}
          isScrollable={true}
          requestMore={requestMoreElements}
          changeSort={changeSort}
          sort={filters?.sort || []}
          loading={elementsLoading}
          emptyConfig={emptyConfig}
          clickableRow={clickableRow}
          onRowClicked={onRowClicked}
          expandableRow={expandableRow}
          allSelected={allSelected}
          selected={selected}
          excluded={excluded}
          handleSelectedEvent={handleSelectedEvent}
        />
      </div>
    </>
  );
};

export default SearchableInfiniteTable;
