import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { scanStatusMap } from '../constants/common';
import companyInfoSelector from '../selectors/companyInfoSelector';

const useScanValidation = () => {
  //  Hook state
  const [isAuthorized, setIsAuthorized] = useState(null);
  const [scanStatus, setScanStatus] = useState(null);

  //  Watching redux store
  const isFreemium = useSelector((state) => companyInfoSelector(state, 'freemium', false) !== 0);
  const lastScanDate = useSelector((state) => companyInfoSelector(state, 'lastScan', false));
  const lastRequestScanDate = useSelector((state) => companyInfoSelector(state, 'lastRequestScan', false));

  //  Function to know if scan date is after request scan date
  const hasPendingRequest = (requestStringDate, scanStringDate) => {
    return moment(requestStringDate).isAfter(moment(scanStringDate));
  }

  //  Watching redux store changes
  useEffect(() => {
    //  Not freemium account
    if (!isFreemium) {
      setIsAuthorized(true);
      setScanStatus(scanStatusMap.PREMIUM);
      return;
    }

    //  Scenario when user has a scan date
    if (lastScanDate) {
      setIsAuthorized(true);

      //  Has scan and also a request scan
      if (lastRequestScanDate) {
        const isPending = hasPendingRequest(lastRequestScanDate, lastScanDate);
        const status = scanStatusMap[isPending ? 'AUTHORIZED_WITH_SCAN_IN_PROGRESS' : 'AUTHORIZED_WITH_NO_SCAN_IN_PROGRESS'];
        setScanStatus(status);
        return;
      }

      //  Does not have a request scan in progress
      setScanStatus(scanStatusMap.AUTHORIZED_WITH_NO_SCAN_IN_PROGRESS);
      return;
    }

    //  Cases without any scan success
    setIsAuthorized(false);
    setScanStatus(lastRequestScanDate ? scanStatusMap.FIRST_SCAN_IN_PROGRESS : scanStatusMap.NOT_REQUESTED_SCAN);
  }, [isFreemium, lastScanDate, lastRequestScanDate]);

  return {
    isAuthorized,
    scanStatus,
  };
};

export default useScanValidation;
