import { useEffect, useState } from 'react';
import {
  UPDATE_ISSUE_SUCCESS,
  UPDATE_ISSUE_FAILURE,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_FAILURE,
} from '../actions/types';
import API from '../api';
import { setAlert } from '../actions/alerts';
import { successActionCreator } from '../actions/action-creators/success';
import { errorActionCreator } from '../actions/action-creators/errors';
import useMemoizedDispatch from './useMemoizedDispatch';
import useCustomMutation from './useCustomMutation';

//  Hook
const useUpdateIssue = (props = {}) => {
  //  Parsing params
  const {
    successCallback = null,
    errorCallback = null
  } = props;

  //  Initializing APIs
  const { IssueAPI } = API;

  //  Hook state
  const [message, setMessage] = useState(null);
  const [field, setField] = useState(null);

  //  Function to clear component state
  const clearHookState = () => {
    setMessage(null);
    setField(null);
  };

  //  Needed hooks
  const {
    data: updateIssueResponse,
    error: updateIssueError,
    isLoading: updateIssueLoading,
    mutate: callUpdateIssue,
  } = useCustomMutation(
    (params) => IssueAPI.handlers.updateIssue(params),
    IssueAPI.invalidators.updateIssue
  );
  const { dispatch } = useMemoizedDispatch();

  //  Watching API response
  useEffect(() => {
    if (!updateIssueResponse) { return; }
    if (message) { dispatch(setAlert(message, 'success', true, 3000)); }
    dispatch(successActionCreator(UPDATE_ISSUE_SUCCESS, updateIssueResponse));
    if (field === 'comment' || field === null) { dispatch(successActionCreator(ADD_COMMENT_SUCCESS)); }
    clearHookState();
    if (successCallback && typeof (successCallback) === 'function') { successCallback(); }
  }, [updateIssueResponse]);

  //  Watching API error
  useEffect(() => {
    if (!updateIssueError) { return; }
    if (field === 'comment' || field === null) { dispatch(successActionCreator(ADD_COMMENT_FAILURE)); }
    const genericErrorMessage = 'Oops! We didn’t catch that. Try again';
    const errorMessage = field === 'comment' ? genericErrorMessage : (updateIssueError?.response?.data?.errorMessage || genericErrorMessage);
    dispatch(setAlert(errorMessage, 'danger'));
    dispatch(errorActionCreator(UPDATE_ISSUE_FAILURE, { message: errorMessage }));
    clearHookState();
    if (errorCallback && typeof (errorCallback) === 'function') { errorCallback(); }
  }, [updateIssueError]);

  //  Function to call API
  const updateIssueOnDB = (code, formValues, fieldToUpdate, messageToShow = null) => {
    if (updateIssueLoading) { dispatch(setAlert('Currently updating issue. ', 'danger')); return; }
    setMessage(messageToShow);
    setField(fieldToUpdate);
    const payload = {
      code,
      formValues,
      field: fieldToUpdate,
      message: messageToShow,
    };
    callUpdateIssue(payload);
  };

  return {
    updateIssueOnDB,
    updateIssueLoading,
    updateIssueError,
  };
};

export default useUpdateIssue;
