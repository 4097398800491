import React from "react";

const AttachmentOverlay = (props) => {
  const {
    instructionMessage = "Drop an attachment",
    isDragOver,
    onFileDrop,
    disabled,
  } = props;

  const getOverlayClassName = () => {
    const displayClassName = isDragOver && !disabled ? "d-flex" : "d-none";
    return `attachment-overlay ${displayClassName}`;
  };
  return (
    <div
      className={getOverlayClassName()}
      onDrop={onFileDrop}
    >
      <div className="instructions-message">{instructionMessage}</div>
    </div>
  );
};

export default AttachmentOverlay;
