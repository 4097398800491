import React, { useState } from 'react';
import ReferUserModal from './ReferUserModal';
import { useLocation } from "react-router-dom";
import {getPathTitle} from '../../utils/helpers'
import amplitude from 'amplitude-js';

const ReferUserLink = () => {
    
    //  Component state
    const [showModal, setShowModal] = useState(false);

    //  Getting router location
    const location = useLocation();

    const toggleModal = () => {
        setShowModal(!showModal);
    }

    //  Function to handle referral link click
    const onReferralLinkClicked = () => {
        amplitude.getInstance().logEvent(`${getPathTitle(location?.pathname)} View - become a Cyde Hustle`);
        toggleModal();
    };

    //TODO: open new tab on success
    //window.open(process.env.REACT_APP_REFERRER_URL, '_blank');

    return (
        <>
            <ReferUserModal
                showModal={showModal}
                toggleModal={toggleModal}
            /> 
            <div className="referrer-link-container">
                <div className="referrer-link-header">Loving us? Refer a friend</div>
                <div
                    className="referrer-link"
                    onClick={() => onReferralLinkClicked()}
                >
                    Become a CydeHustle
                </div>
            </div>
        </>
    );
}

export default ReferUserLink;