import React from 'react';
import { Button } from 'reactstrap';
import BasicModal from './BasicModal';

const CustomActionModal = (props) => {
  //  Parsing props
  const {
    closeModal,
    title = '',
    subtitle = '',
    onActionClick,
    disabled = false,
    actionText = '',
    actionClassName = '',
  } = props;

  //  Rendering
  return (
    <BasicModal
      showModal={true}
      toggleModal={closeModal}
      title={title}
      subtitle={subtitle}
      //title="Delete File"
      //subtitle={`Are you sure you want to PERMANENTLY delete ${file?.name}?`}
      customClassName='logout-modal'
    >
      <div className="row-container logout-modal">
        <Button
          color="light-gray left-modal-button"
          outline
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          disabled={disabled}
          className={actionClassName}
          onClick={() => onActionClick()}
        >
          {actionText}
        </Button>
      </div>
    </BasicModal>
  );
};

export default CustomActionModal;
