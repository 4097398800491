import React from 'react';

const CyvatarBadge = (props) => {
    const {
        image,
        width="100%",
        altText="cyvatar-badge",
        isUnlocked
    } = props;
    return (
        <div className={`cyvatar-badge-wrapper text-center ${!isUnlocked ? 'preview-filter': ''}`}>
            <img
                src={image}
                className="img-fluid"
                alt={altText}
                width={width}
            />
        </div>
    )
}
export default CyvatarBadge;
