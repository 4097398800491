import React from 'react';
import Lottie from 'react-lottie';

const CustomLottie = (props) => {
  //  Parsing props
  const {
    loop = false,
    autoplay = false,
    lottieHeight = 400,
    lottieWidth = 400,
    lottieFile = {},
    extraSettings = {},
    disablePauseOnClick = true
  } = props;

  //  Rendering
  return (
    <Lottie
      height={lottieHeight}
      width={lottieWidth}
      isClickToPauseDisabled={disablePauseOnClick}
      options={
        {
          loop,
          autoplay,
          animationData: lottieFile,
          rendererSettings: extraSettings,
        }
      }
    />
  );
};

export default CustomLottie;
