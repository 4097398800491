import React from 'react'
import { useSelector } from 'react-redux';
import useElementModalNavigation from '../../../../hooks/useElementModalNavigation';
import IssueCodeCopy from '../../../issue-manager/IssueCodeCopy';
import IssueNavigationButton from '../../../issue-manager/IssueNavigationButton'
import IssuesListButton from './IssuesListButton';

const GroupHeader = (props) => {
  const {
    onCloseModal,
    issuesCount,
    groupId,
    showGroupId = true,
    groupExternalId,
    changeSelectedGroup,
    changeCurrentPage,
  } = props;

  const currentPageIndex = useSelector(state => state.groups.currentPage);
  const pageCount = useSelector(state => state.groups.pageCount);

  // Function to update selected page
  const updateCurrentPageCallback = (newPage) => {
    changeCurrentPage(newPage);
  };

  //  Function to update current group
  const updateCurrentGroup = (newIndex = 0, newPage = false) => {
    if (newPage) { return; }
    changeSelectedGroup(newIndex);
  }

  // Component hooks
  const {
    currentPage,
    maxPage,
    currentElementIndex,
    maxIndex,
    updateIndex,
  } = useElementModalNavigation({
    issueType: null,
    currentPageIndex: currentPageIndex,
    maxPageCount: pageCount,
    updateCurrentPageCallback,
    updateElementCallback: updateCurrentGroup,
  });


  // Function to render button of next and previous issue
  const renderHeaderButtons = () => {
    return (
      <div className="d-flex flex-grow-1 btn-header">
        <IssueNavigationButton
          moveForward={false}
          currentElementIndex={currentElementIndex}
          elementsMaxIndex={maxIndex}
          currentPage={currentPage}
          maxPage={maxPage}
          callback={() => updateIndex(false)}
        />
        <IssueNavigationButton
          moveForward={true}
          currentElementIndex={currentElementIndex}
          elementsMaxIndex={maxIndex}
          currentPage={currentPage}
          maxPage={maxPage}
          callback={() => updateIndex(true)}
        />
      </div>
    )
  }

  // Render action icons
  const renderHeaderIcons = () => {
    return (
      <div className="details-header-icons">
        <button
          type="button"
          onClick={onCloseModal}
          className="close close-icon"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    )
  }

  // Function to render issue name
  const renderGroupId = () => {
    return (
      <div className='d-flex w-100 justify-content-start'>
        <div className="details-header-title">
          <div className='issue-details-name-header mr-3'>
            {groupExternalId}
          </div>
        </div>
        <IssuesListButton
          groupId={groupId}
          issuesCount={issuesCount}
        />
        <IssueCodeCopy
          code={groupExternalId}
          showCode={false}
          itemLabel="Group ID"
        />
      </div>
    )
  }

  return (
    <div className="details-header">
      <div className='group-buttons-row d-flex w-100'>
        {renderHeaderButtons()}
        {renderHeaderIcons()}
      </div>
      {showGroupId ? renderGroupId() : null}
    </div>
  )
}

export default GroupHeader;
