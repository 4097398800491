import { useEffect } from 'react';
import API from '../api';
import { setAlert } from '../actions/alerts';
import { getSafeArray } from '../utils/helpers';
import useMemoizedDispatch from './useMemoizedDispatch';
import useCustomQuery from "./useCustomQuery";

const useGroupsLibrary = (partner_ids = []) => {
  //  Initializing API
  const { GroupAPI } = API;

  //  Hooks
  const { dispatch } = useMemoizedDispatch();

  //  Making query
  const {
    data: libraryData,
    error: libraryError,
  } = useCustomQuery(
    [GroupAPI.keys.getLibrary, partner_ids],
    ({ queryKey }) => GroupAPI.handlers.getLibrary(queryKey[1]),
  );

  //  Watching api errors
  useEffect(() => {
    if (!libraryError) { return; }
    dispatch(setAlert('Error getting groups library', 'danger'));
  }, [libraryError]);

  //  Exporting filters
  return {
    partner_configs: getSafeArray(libraryData),
  };
};

export default useGroupsLibrary;
