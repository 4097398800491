import filterTypes from "../constants/filterTypes"
import { CLEAR_ALL_CONNECTIONS_FILTERS, SET_CONNECTIONS_FILTER, SET_CONNECTIONS_SORT, SET_CONNECTION_DEFAULT_FILTERS } from "./types"


export const setConnectionsFilter = (filterName, value, label, type = filterTypes.single, filterOption, hideActiveFilter) => {
  return {
      type: SET_CONNECTIONS_FILTER,
      payload: { filterName, value, label, type, filterOption, hideActiveFilter }
  }
}

export const setConnectionsSort = (value) => {
  return {
      type: SET_CONNECTIONS_SORT,
      payload: {
          value,
      }
  }
}

export const clearConnectionsFilters = () => {
  return {
      type: CLEAR_ALL_CONNECTIONS_FILTERS,
  }
}

export const setConnectionDefaultFilters = (payload) => {
  return {
    type:  SET_CONNECTION_DEFAULT_FILTERS,
    payload
  }
}
