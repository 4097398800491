import {
  ON_REQUEST_RULES_DELETE,
  ON_REQUEST_CANCEL_RULES_DELETE,
  ON_RULE_LOG_REQUESTED,
  ON_LOG_DETAIL_REQUESTED,
  ON_RULE_SETS_MOUNTED,
  ON_LOG_DETAIL_UNMOUNTED,
  ON_UPSERT_RULE_SET,
  ON_AUTOMATION_UNMOUNT,
  SET_RULE_TITLES,
  SET_LOG_DETAIL,
} from './types';

export const requestDeleteRules = (payload) => {
  return {
    type: ON_REQUEST_RULES_DELETE,
    payload,
  };
};

export const cancelRulesDelete = () => {
  return {
    type: ON_REQUEST_CANCEL_RULES_DELETE,
    payload: {},
  };
};

export const onRuleLogRequested = (rule_id) => {
  return {
    type: ON_RULE_LOG_REQUESTED,
    payload: { rule_id },
  };
};

export const onLogDetailRequested = (ruleLog, titleArray, subtitleArray) => {
  return {
    type: ON_LOG_DETAIL_REQUESTED,
    payload: { ruleLog, titleArray, subtitleArray },
  };
};

export const onRuleSetsMounted = () => {
  return {
    type: ON_RULE_SETS_MOUNTED,
    payload: null,
  };
};

export const onLogDetailUnmounted = () => {
  return {
    type: ON_LOG_DETAIL_UNMOUNTED,
    payload: null,
  };
};

export const onUpsertRuleSet = (ruleSetId) => {
  return {
    type: ON_UPSERT_RULE_SET,
    payload: { ruleSetId },
  };
};

export const onAutomationUnmount = () => {
  return {
    type: ON_AUTOMATION_UNMOUNT,
    payload: null,
  };
};

export const setRuleTitles = (titleArray, subtitleArray) => {
  return {
    type: SET_RULE_TITLES,
    payload: { titleArray, subtitleArray }
  }
}

export const setLogDetail= (logGroup) => {
  return {
    type: SET_LOG_DETAIL,
    payload: logGroup
  }
}
