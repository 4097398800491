import {
    ASSESSMENT_SUCCESS,
    ASSESSMENT_FAILURE,
    SELECT_CATEGORY,
    SELECT_SECTION,
    SELECT_QUESTION,
    SET_CATEGORY_INDEX,
    SET_SECTION_INDEX,
    SET_QUESTION_INDEX,
    SET_NEXT_QUESTION,
    SET_PREVIOUS_QUESTION,
    SET_CATEGORIES_IDS,
    SET_FIRST_QUESTION,
    RESET_ASSESSMENT_NAVIGATION,
    EDIT_COMPLIANCE_LIST_SUCCESS,
    SET_COMPLIANCES,
    ON_CLEAN_ANSWERS,
    LOGOUT_SUCCESS,
    ASSESSMENT_REQUEST,
} from '../actions/types';
const initialState = {
    error: "",
    assessment: {
        id: null,
        grades: {},
        categories: []
    },
    selectedCategoryId: null,
    selectedSectionId: null,
    selectedQuestionId: null,
    categoriesIds: null,
    navigation: {
        categoryIndex: -1,
        sectionIndex: -1,
        questionIndex: -1
    },
    compliances: []
}
export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ASSESSMENT_SUCCESS:
            return {
                ...state,
                assessment: {
                    ...state.assessment,
                    ...payload
                },
                isLoading: false,
            }
        case ASSESSMENT_FAILURE:
            return { ...state, error: payload, isLoading: false };
        case SELECT_SECTION:
            return { ...state, selectedSectionId: payload }
        case SELECT_CATEGORY:
            return { ...state, selectedCategoryId: payload }
        case SELECT_QUESTION:
            return { ...state, selectedQuestionId: payload }
        case SET_CATEGORY_INDEX:
            return {
                ...state,
                navigation: {
                    ...state.navigation,
                    categoryIndex: payload
                }
            }
        case SET_SECTION_INDEX:
            return {
                ...state,
                navigation: {
                    ...state.navigation,
                    sectionIndex: payload
                }
            }
        case SET_QUESTION_INDEX:
            return {
                ...state,
                navigation: {
                    ...state.navigation,
                    questionIndex: payload
                }
            }
        case SET_NEXT_QUESTION: {
            const { categoryIndex, sectionIndex, questionIndex } = state.navigation;
            const categoriesIds = state.categoriesIds;
            if (!categoriesIds) return state;
            const isLastCategory = categoryIndex === categoriesIds.length - 1;
            const isLastSection = categoriesIds[categoryIndex] && (sectionIndex === categoriesIds[categoryIndex].sectionIds.length - 1);
            const isLastQuestion = categoriesIds[categoryIndex] && categoriesIds[categoryIndex].sectionIds[sectionIndex] && (questionIndex === categoriesIds[categoryIndex].sectionIds[sectionIndex].questionIds.length - 1);
            const isAssessmentEnd = (isLastCategory && isLastSection && isLastQuestion);
            if (isAssessmentEnd) {
                return state;
            }
            if (!isLastQuestion) {
                return {
                    ...state,
                    navigation: {
                        ...state.navigation,
                        questionIndex: questionIndex + 1
                    }
                }
            } else {
                if (!isLastSection) {
                    return {
                        ...state,
                        navigation: {
                            ...state.navigation,
                            sectionIndex: sectionIndex + 1,
                            questionIndex: 0
                        }
                    }
                } else {
                    return {
                        ...state,
                        navigation: {
                            ...state.navigation,
                            categoryIndex: categoryIndex + 1,
                            sectionIndex: 0,
                            questionIndex: 0
                        }
                    }
                }
            }
        }
        case SET_PREVIOUS_QUESTION: {
            const { categoryIndex, sectionIndex, questionIndex } = state.navigation;
            const categoriesIds = state.categoriesIds;

            if (!categoriesIds) return state;

            const isAssessmentBeginning = (categoryIndex === 0 && sectionIndex === 0 && questionIndex === 0);

            if (isAssessmentBeginning) {
                return state;
            }

            if (questionIndex !== 0) {
                return {
                    ...state,
                    navigation: {
                        ...state.navigation,
                        questionIndex: questionIndex - 1
                    }
                }
            } else {
                if (sectionIndex !== 0) {
                    return {
                        ...state,
                        navigation: {
                            ...state.navigation,
                            questionIndex: categoriesIds[categoryIndex].sectionIds[sectionIndex - 1].questionIds.length - 1,
                            sectionIndex: sectionIndex - 1
                        }
                    }

                } else {
                    if (categoryIndex !== 0) {
                        const catIndex = categoryIndex - 1;
                        const sectIndex = categoriesIds[catIndex].sectionIds.length - 1;
                        const questIndex = categoriesIds[catIndex].sectionIds[sectIndex].questionIds.length - 1
                        return {
                            ...state,
                            navigation: {
                                ...state.navigation,
                                questionIndex: questIndex,
                                sectionIndex: sectIndex,
                                categoryIndex: catIndex
                            }
                        }
                    }
                }

            }
        }
            break;
        case SET_FIRST_QUESTION: return {
            ...state,
            navigation: {
                ...state.navigation,
                questionIndex: 0,
                sectionIndex: 0,
                categoryIndex: 0
            }
        }
        case RESET_ASSESSMENT_NAVIGATION:
        case LOGOUT_SUCCESS:
            return {
                ...state,
                selectedCategoryId: null,
                selectedSectionId: null,
                selectedQuestionId: null,
                navigation: {
                    ...state.navigation,
                    questionIndex: -1,
                    sectionIndex: -1,
                    categoryIndex: -1
                }
            }
        case SET_CATEGORIES_IDS:
            return {
                ...state,
                categoriesIds: payload
            };
        case SET_COMPLIANCES:
        case EDIT_COMPLIANCE_LIST_SUCCESS: {
            return {
                ...state,
                compliances: payload || []
            }
        }
        case ON_CLEAN_ANSWERS: {
            return {
                ...state,
                selectedSectionId: null,
                selectedCategoryId: null,
                selectedQuestionId: null,
                navigation: {
                    ...state.navigation,
                    categoryIndex: -1,
                    sectionIndex: -1,
                    questionIndex: -1,
                }
            };
        }
        case ASSESSMENT_REQUEST: {
            return { ...state, isLoading: true }
        }
        default:
            return state;
    }
}