import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { initializeIssuesConfig } from '../../utils/queryHelpers';
import CustomCheckBox from '../layout/CustomCheckBox';
import CriticalityBadge from '../dashboard/CriticalityBadge';
import TableList from '../layout/TableList';
import useCustomQuery from '../../hooks/useCustomQuery';
import API from '../../api';
import {
    clearIssuesFilters,
    onDeleteModalConfigUpdate,
    onReloadIssuesRollup,
    setIssuesFilter,
    setIssuesSort,
    onShowGRCDetails,
    onUpdateCurrentPage
} from '../../actions/issues';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import {
    getPriorityById,
    getStatusLabelById,
    renderIssuesAssignees,
} from '../../utils/issuesHelpers';
import { renderIssueDate } from '../../utils/issuesHelpers';
import { issueTypes } from '../../constants/issues';
import IssueName from '../issue-manager/IssueName';
import { onSelectElement } from '../../utils/bulkActionsHelper';
import IssuesListBulkActions from '../issue-manager/IssuesListBulkActions';
import filterTypes from '../../constants/filterTypes';
import PriorityArrow from '../dashboard/PriorityArrow';
import IssuesAssigneeFilter from '../issue-manager/issue-filters/IssuesAssigneesFilter';
import { Button } from 'reactstrap';
import IssuesAdvancedFiltering from '../issue-manager/IssuesAdvancedFiltering';
import companyIdSelector from '../../selectors/companyIdSelector';
import GrcIssuesCharts from './GrcIssuesCharts';
import { useHistory, useLocation } from 'react-router-dom';
import { getApiErrorMessage, getUpdatedSearchParams } from '../../utils/helpers';
import { onReloadIssues } from '../../actions/issues';
import DeleteIssuesModal from '../layout/DeleteIssuesModal';
import IssueCode from './../issue-manager/IssueCode';
import amplitude from 'amplitude-js';

import useCanAbility from '../../hooks/useCanAbility';
import { CREATE_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';
import useUserType from '../../hooks/useUserType';

const GrcIssuesList = (props) => {
    const {
        showIssueDetails
    } = props;
    //  Initializing APIs
    const { GrcIssueAPI } = API;

    const issueRowPrefix = 'issue-grc-row-'

    // Getting needed info from redux store
    const filters = useSelector((state) => state.grcIssues.filters);
    const sortField = useSelector((state) => state.grcIssues.sortField);
    const searchTerm = useSelector(state => state.grcIssues.searchTerm);
    const deleteIssuesSuccess = useSelector((state) => state.grcIssues.deleteModalConfig?.success);
    const grcIssuesState = useSelector((state) => state.grcIssues);
    const isMemberView = useSelector(state => state.impersonation.isMemberView);
    const assignedToFilter = useSelector((state) => state.grcIssues?.filters?.assigned_to);
    const selectedCompany = useSelector((state) => companyIdSelector(state));
    const leftSideBarType = useSelector((state) => (state.layout.leftSideBarType));
    const showDeleteIssuesModal = useSelector((state) => state.grcIssues?.deleteModalConfig?.show);
    const priorities = useSelector((state) => state.grcIssues.modalOptions?.priorities);
    const showDetailsModal = useSelector((state) => state.grcIssues?.showDetailsModal);
    const indexDetailsModal = useSelector((state) => state.grcIssues?.indexDetailsModal);
    const isNewPage = useSelector((state) => state.grcIssues?.isNewPage);
    const currentPage = useSelector((state) => state.grcIssues.currentPage);

    // Component state
    const [perPage] = useState(20);
    const [selectedIssues, setSelectedIssues] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [issuesIds, setIssuesIds] = useState([]);
    const [showAdvanceFilteringModal, setShowAdvanceFilteringModal] = useState(false);
    const userType = useUserType();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
    const notificationFilter = queryParams.get("filter");
    const [issuesConfig, setIssuesConfig] = useState(
        initializeIssuesConfig(
            currentPage + 1,
            perPage,
            searchTerm,
            sortField,
            filters,
            userType,
            notificationFilter
        )
    );
    const [rollupCollapsed, setRollupCollapsed] = useState(false);

    // Component Hooks
    const {
        data: issuesResponse,
        error: issuesError,
        isLoading: issuesLoading,
        refetch: refetchIssues,
    } = useCustomQuery(
        [GrcIssueAPI.keys.getGrcIssuesList, issuesConfig],
        ({ queryKey }) => GrcIssueAPI.handlers.getGrcIssuesList(queryKey[1])
    );
    const { dispatch } = useMemoizedDispatch();
    const history = useHistory();
    const ability = useCanAbility();

    const activeFiltersDisplayInfo = {
        display: true,
        labels: {
            noFilters: 'Listing all tasks',
            filteringBy: 'Listing tasks filtered by:',
            total: {
                singular: 'task',
                plural: 'tasks'
            }
        }
    }


    useEffect(() => {
        if (indexDetailsModal === null || isNewPage) return;
        handleRowClick(issuesResponse?.data?.[indexDetailsModal], indexDetailsModal)
    }, [indexDetailsModal, isNewPage])



    // watch changes in issues to update array of issueIds
    useEffect(() => {
        const issues = issuesResponse?.data;
        if (!issues) return;
        const ids = issues.map((issue) => (issue.id))
        setIssuesIds(ids)

        if (isNewPage && showDetailsModal) {
            const newIndex = (indexDetailsModal === 0) ? indexDetailsModal : issuesResponse?.data?.length - 1;
            handleRowClick(issuesResponse?.data?.[newIndex], newIndex)
        }
    }, [issuesResponse?.data])


    // watch selectAll changes to update selectedIssues array
    useEffect(() => {
        if (selectAll) {
            setSelectedIssues(issuesIds)
        } else {
            setSelectedIssues([])
        }
    }, [selectAll]);

    //  Watching delete issues success flag
    useEffect(() => {
        if (!deleteIssuesSuccess || showIssueDetails) { return; }
        dispatch(onDeleteModalConfigUpdate(false, [], false, issueTypes.GRC));
        dispatch(onReloadIssues(true, issueTypes.GRC));
        dispatch(onReloadIssuesRollup(true, issueTypes.GRC));
        setSelectedIssues([]);
        setSelectAll(false);
    }, [deleteIssuesSuccess, showIssueDetails]);


    // Component for selecting all elements in the table
    const renderSelectAllComponent = () => {
        return (
            <CustomCheckBox
                isSelected={selectAll}
                targetId="select-all-issues-checkbox"
                callback={(isSelected) => {
                    setSelectAll(!isSelected)
                }}
            />
        )
    }

    const onIssueCheckboxSelect = (id, isSelected) => {
        const updatedList = onSelectElement(id, isSelected, selectedIssues);
        setSelectedIssues(updatedList)
    }

    const renderIssueCheckBox = (issue) => {
        const { code, id: issueId } = issue;
        const isIssueSelected = selectedIssues?.includes(issueId)
        return (
            <CustomCheckBox
                isSelected={isIssueSelected}
                targetId={`issue-checkbox-${code}`}
                callback={(isSelected) => {
                    onIssueCheckboxSelect(issueId, isSelected)
                }}
            />
        )
    }

    const renderIssueName = (issue) => {
        const {
            name,
            parent_issue_id,
            code,
            os_version
        } = issue;

        return (
            <IssueName
                name={name}
                parentIssueId={parent_issue_id}
                code={code}
                osVersion={os_version}
                issueType={issueTypes.GRC}
                customClassName="grc-issue-name"
                displayType={false}
            />
        )
    }

    const renderPriority = (issue) => {
        const priority = getPriorityById(issue?.priority, priorities)
        return (
            <PriorityArrow
                priority={priority}
                className="d-flex justify-content-center"
                showTooltip={true}
                targetId={`priority-arrow-${issue?.id}`}
            />
        )
    }

    const renderCriticalityComponent = (issue) => {
        return (
            <CriticalityBadge severity={issue?.severity} />
        )
    }

    const renderStatusCell = (issue) => {
        const issueStatus = getStatusLabelById(issue?.status, userType);
        return (
            <div
                className="td-issues-status"
                aria-label={`${issueStatus}, Task status`}
            >
                {issueStatus}
            </div>
        )
    }

    const renderAssignee = (issue) => {
        const assignedTo = issue?.assigned_to && issue?.assigned_user && renderIssuesAssignees([issue?.assigned_user]);
        return (
            <div aria-label={`Assigned to: ${assignedTo ? issue?.assigned_user?.full_name : 'unassigned'}`}>
                {assignedTo}
            </div>
        )
    }

    //  Function to render issue code
    const renderIssueCode = (issue) => {
        const { code, overdue = false } = issue;
        return (
            <IssueCode
                code={code}
                overdue={overdue}
            />
        );
    };

    const toggleCreateIssueModal = () => {
        if (!showDetailsModal){
            amplitude.getInstance().logEvent('My CySO View - Task Manager Issues List - Create Issue');
        }
       dispatch(onShowGRCDetails(!showDetailsModal));
    };

    const renderCreateIssueButton = () => {
        const canCreateIssues = ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.ISSUES.GENERAL) || isMemberView;
        if (!canCreateIssues) return null;
        return (
            <Button
                type="button"
                color="light"
                className="btn-create-issue mb-2 float-right"
                onClick={toggleCreateIssueModal}
            >
                <i className='bx bx-plus-circle mr-2 align-middle'></i>
                Create new task
            </Button>
        )
    }

    const setSort = (value) => {
        dispatch(setIssuesSort(value, issueTypes.GRC))
    }

    const setFilters = (filterName, value, label, type, filterOption) => {
        dispatch(setIssuesFilter(filterName, value, label, type, filterOption, false, issueTypes.GRC));
    }

    const clearFilters = () => {
        dispatch(clearIssuesFilters(isMemberView, issueTypes.GRC))
    }

    const onReloadGrcList = (shouldReload) => {
        dispatch(onReloadIssues(shouldReload, issueTypes.GRC));
    }

    // Table Columns
    const columns = [
        {
            displayName: null,
            fieldName: null,
            sortFieldName: null,
            headerClassNameWrapper: null,
            renderCustomHeader: renderSelectAllComponent,
            renderCustomComponent: renderIssueCheckBox,
        },
        {
            displayName: "Task",
            fieldName: 'code',
            sortFieldName: 'code',
            headerClassNameWrapper: 'th-grc-code',
            renderCustomComponent: renderIssueCode,
        },
        {
            displayName: 'Task name',
            fieldName: 'name',
            sortFieldName: 'name',
            headerClassNameWrapper: 'th-grc-issue-name',
            renderCustomComponent: renderIssueName
        },
        {
            displayName: "Priority",
            fieldName: 'priority',
            sortFieldName: 'priority',
            renderCustomComponent: renderPriority,
        },
        {
            displayName: "Criticality",
            fieldName: 'severity',
            sortFieldName: 'severity',
            renderCustomComponent: renderCriticalityComponent,
        },
        {
            displayName: "Status",
            fieldName: 'status',
            sortFieldName: 'status',
            headerClassNameWrapper: 'th-status',
            renderCustomComponent: renderStatusCell
        },
        {
            displayName: "Assigned to",
            fieldName: 'assigned_to',
            sortFieldName: 'assigned_to',
            headerClassNameWrapper: 'th-assignee',
            renderCustomComponent: renderAssignee
        },
        {
            displayName: 'Last action',
            fieldName: 'updated_at',
            sortFieldName: 'updated_at',
            renderCustomComponent: (issue) => renderIssueDate('updated_at', issue)
        },
        {
            displayName: 'Created on',
            fieldName: 'created_at',
            sortFieldName: 'created_at',
            renderCustomComponent: (issue) => renderIssueDate('created_at', issue)
        },
    ];

    const renderAssigneeFilter = () => {
        return (
            <IssuesAssigneeFilter
                selectedCompany={selectedCompany}
                filterValue={assignedToFilter}
                placeholder="Assigned to"
                setFilterCallback={(option) => setFilters('assigned_to', option.value, option.label, filterTypes.single)}
                wrapperClassName="assignee-filter"
                issueType={issueTypes.GRC}
            />
        )
    }

    const toggleAdvancedFilteringModal = () => {
        setShowAdvanceFilteringModal(!showAdvanceFilteringModal);
    }

    const renderAdvancedFilteringModal = () => {
        if (!showAdvanceFilteringModal) { return null; }
        return (
            <IssuesAdvancedFiltering
                showModal={showAdvanceFilteringModal}
                toggleModal={toggleAdvancedFilteringModal}
                issueType={issueTypes.GRC}
            />
        )
    }

    const renderMoreFiltersButton = () => {
        return (
            <Button
                color="light-gray"
                className="btn-filter d-inline-flex align-items-center"
                outline
                onClick={toggleAdvancedFilteringModal}
                style={{ marginRight: "11px", marginBottom: "4px" }}
            >
                <i className='bx bx-filter-alt mr-2' />
                More Filters
            </Button>
        )
    }

    const getFiltersList = () => {
        return [
            {
                renderCustomFilter: renderAssigneeFilter
            },
            {
                renderCustomFilter: renderMoreFiltersButton
            }
        ]
    }

    const onTableFetch = (payload = {}, shouldRefetch = false) => {
        //  Updating issue config
        const { page, per_page, text_to_search, sort, filters } = payload;
        const config = initializeIssuesConfig(page, per_page, text_to_search, {}, filters, userType, notificationFilter);
        setIssuesConfig({
            ...config,
            sort
        });

        //  Clearing selected state on every issue config change
        setSelectAll(false);
        setSelectedIssues([]);

        if (shouldRefetch) {
            refetchIssues();
        }
    }

    const onUpdatePage = (page) => {
        dispatch(onUpdateCurrentPage(page, issueTypes.GRC))
    }

    const getIssuesData = () => {
        const issues = issuesResponse;
        return {
            ...grcIssuesState,
            list: issues?.data,
            pageCount: issues?.pagination?.page_count,
            total: issues?.pagination?.total || 0,
        }
    }

    const onIssueRightClick = (elementId) => {
        if (elementId?.includes(issueRowPrefix)) {
            const id = parseInt(elementId?.replace(issueRowPrefix, ''));
            const isSelected = selectedIssues?.includes(id);
            if (!isSelected) {
                setSelectedIssues([id])
            }
        }
    }

    const renderBulkActionsMenu = (params) => {
        const {
            tableRef,
            bulkMenuId,
            bulkMenuRef,
            bulkMenuStyle,
            scrollableRef
        } = params;
        return (
            <IssuesListBulkActions
                selectedIssues={selectedIssues}
                onRightClick={onIssueRightClick}
                bulkMenuId={bulkMenuId}
                bulkMenuRef={bulkMenuRef}
                parentRef={tableRef}
                scrollableRef={scrollableRef}
                bulkMenuStyle={bulkMenuStyle}
                issueType={issueTypes.GRC}
            />
        )
    }

    const renderCharts = (params) => {
        const {
            componentId,
            componentRef,
            componentStyle
        } = params;
        return (
            <GrcIssuesCharts
                componentId={componentId}
                componentRef={componentRef}
                customStyle={componentStyle}
                collapsed={rollupCollapsed}
            />
        )

    }

    //  Function to render delete issues modal
    const renderDeleteIssuesModal = () => {
        if (!showDeleteIssuesModal) { return null; }
        return <DeleteIssuesModal issueType={issueTypes.GRC} />;
    };

    // Function to handle scroll changes
    const handleScroll = (params) => {
        if (!params) return;
        const {
            topComponentHeight,
            scrollableRef
        } = params;
        const scrollPosition = scrollableRef?.current?.scrollTop;
        const height = parseInt(topComponentHeight?.replace("px", ""));
        if (!rollupCollapsed && scrollPosition - height > 0) {
            setRollupCollapsed(true);
        } else if (rollupCollapsed && scrollPosition - height < 0) {
            setRollupCollapsed(false);
        }
    }

    // Function to handle row click
    const handleRowClick = (issue, index) => {
        if (!issue || issue === null) return;
        const newParams = `&details&task&selectedIssue=${issue?.code}`;
        amplitude.getInstance().logEvent('My CySO View - Task Manager Issues List - Open Issue')

        //  Clearing selected on issues click
        setSelectAll(false);
        setSelectedIssues([]);

        amplitude.getInstance().logEvent('My CySO View - Task Manager Issues List - Open Issue');

        history.push({
            search: getUpdatedSearchParams(
                location?.search,
                ['details', 'selectedIssue', 'task'],
                newParams,
            ),
            state: {
                index,
                maxIndex: issuesResponse?.data.length - 1
            }
        })
    }

    const renderCustomEmptyComponent = () => {
        return (
            <div className='grc-empty-table' tabIndex="0">
                <div className='grc-empty-table-title'>We’re on a roll!</div>
                <div className='grc-empty-table-message'>No tasks found for now...</div>
                <div className='grc-empty-table-message'>have some tea.</div>
            </div>
        )
    }

    return (
        <>
            {renderAdvancedFilteringModal()}
            {renderDeleteIssuesModal()}
            <TableList
                fetchList={onTableFetch}
                reloadList={onReloadGrcList}
                data={getIssuesData()}
                columns={columns}
                customClassName="issues-list"
                containerClassName="view-container issues-table-container px-0 bg-dark"
                tableCardClassName="mx-2"
                setFilter={setFilters}
                clearAllFilters={clearFilters}
                setSort={setSort}
                activeFiltersDisplayInfo={activeFiltersDisplayInfo}
                isLoading={issuesLoading}
                errorMessage={getApiErrorMessage(issuesError)}
                searchPlaceholder="Search for a task"
                renderBulkMenu={renderBulkActionsMenu}
                selectedElements={selectedIssues}
                allElementsSelected={selectAll}
                filtersComponentList={getFiltersList()}
                renderButton={renderCreateIssueButton}
                renderTopComponent={renderCharts}
                topCollapsed={rollupCollapsed}
                sidebarCollapsed={leftSideBarType === 'condensed'}
                accumulatedHeightOffset={-0.5}
                onScroll={handleScroll}
                rowPrefix={issueRowPrefix}
                onRowClick={handleRowClick}
                renderCustomEmptyComponent={renderCustomEmptyComponent}
                filterSectionXOffset={16}
                onUpdatePage={onUpdatePage}
                updatedPage={currentPage}
            />
        </>
    )
}

export default GrcIssuesList;
