// Fake data
import React from 'react';
import moment from 'moment-timezone';
import { getDayDifference, getSafeArray } from '../utils/helpers';
import {
  adjustLabelsOnPoints,
  getDateFilterStepByType,
  moveDateByType,
  getRangeByFilterType,
  isInsideRange,
  findPointBetweenInterval,
} from '../utils/timeChartHelpers';

import solutionsStyle from './solutionsStyle';
import { timeUnits } from './tendencyChart';

const generateElements = (size, cyvalueReached, showProjection) => {
  const elements = [];
  for (let index = 0; index < size; index++) {
    const initialDate = moment().subtract(6, 'weeks').startOf('week');
    const initialScore = 0
    const start_date = initialDate.add((index * 1), 'weeks');
    elements.push({
      score: initialScore + (index * 5),
      start_date: start_date.toDate(),
      label: index,
      activities: 4 + index,
      cyvalue_reached: cyvalueReached ? (index === size - 1) : false,
      start_projection: showProjection ? (index === size - 1) : false,
    })
  }
  return elements;
};

export const tabs = [
  { name: 'TVM', id: 1 },
];

export const getData = (idToFind, amountOfItems = 7, cyvalueReached = false, isNewSolution = false, isFreemium = false, showProjection = false) => {
  const tab = tabs.find(({ id: tabId }) => tabId === idToFind);
  const { name, id } = tab;
  return {
    name,
    id,
    overall_score: cyvalueReached ? 97 : (isNewSolution ? 0 : (isFreemium ? null : 80)),
    activities: 105,
    resolved_issues: 13,
    improvement: 4,
    cyvalue_reached_date: cyvalueReached ? new Date() : null,
    guarantee_date: moment().add(20, 'days').toDate(),
    solutions: isFreemium ? [] : [
      {
        name: 'TVM',
        installation_date: moment().subtract(7, 'weeks').toDate(),
        data: isNewSolution ? [] : generateElements(amountOfItems, cyvalueReached, showProjection),
      }
    ]
  }
};

//  Real data *************************************
export const filterTypes = {
  month: 'MONTH',
  week: 'WEEK',
  ninety_days: 'QUARTER'
};

export const activityAndIssueTypes = {
  activity: 'ACTIVITY',
  issue: 'ISSUE',
};

export const getIcarmMarkerBySolutionAndLetter = (solutionName, stepLetter) => {
  return `${process.env.REACT_APP_PUBLIC_RESOURCES}/icarm-markers-indicators/${solutionName?.toLowerCase()}-icarm-step-${stepLetter?.toLowerCase()}.svg`
}

export const dummyIcarmSteps = [
  {
    info: "",
    name: "Installation",
    status: "Waiting",
  },,
  {
    info: "",
    name: "Configuration",
    status: "Waiting",
  },
  {
    info: "",
    name: "Assessment",
    status: "Waiting",
  },
  {
    info: "",
    name: "Remediation",
    status: "Waiting",
  },
  {
    info: "",
    name: "Maintenance",
    status: "Waiting",
  }
]

export const solutionGoal = 85;

export const cyvalueFilters = [
  { type: filterTypes.week, label: 'Weeks' },
  { type: filterTypes.month, label: 'Months' },
  //TODO: add when 90 days/ quarters filter is supported
  //{ type: filterTypes.ninety_days, label: '90 days' }
];

// Solutions that support cyvalue
export const cyvalueEnabledSolutions = [
  'TVM',
  'ITAM',
  'SEM'
];

export const icarmStepsNames = {
  INSTALLATION: "Installation",
  CONFIGURATION: "Configuration",
  ASSESSMENT: "Assessment",
  REMEDIATION: "Remediation",
  MAINTENANCE: "Maintenance"
}

export const icarmStepStatus = {
  COMPLETED: "Completed",
  IN_PROGRESS: "In Progress",
  WAITING: "Waiting"
}
const getCyvalueFilterDateRange = (start_date, end_date) => {
  if(start_date && end_date) {
    return { start_date, end_date }
  } else {
    return getRangeByFilterType(filterTypes.week, new Date(), false)
  }
}

export const initializeCyValueFilters = (company_id, start_date, end_date) => {
  const dateRange = getCyvalueFilterDateRange(start_date, end_date);
  return {
    company_id,
    solution_id: null,
    filter_type: filterTypes.week,
    time: moment.tz.guess(),
    ...dateRange,
  };
};

const getDefaultNewSolutionData = (type = filterTypes.week) => {
  const step = getDateFilterStepByType(type);
  const start_date = moment().startOf(step).toDate();
  const end_date = moveDateByType(start_date, 1, type, 'right');
  return [{
    score: 0,
    label: 0,
    activities: 0,
    cyvalue_reached: false,
    start_projection: true,
    start_date,
    end_date,
  }]
}

const sortSolutionsByGuaranteeDate = (array)  => {
  const safeArray = getSafeArray(array);
  return safeArray.sort(function(a,b){
      return new Date(a.guarantee_date) - new Date(b.guarantee_date);
  });
}

const filterSolutionCountdown = (solution) => {
  const isOverall =  isOverallSolution(solution?.name);
  const reachedCyvalue = solution?.cyvalue_reached_date;
  return !isOverall && !reachedCyvalue;
}

const getPendingCyvalueSolutions = (solutions) => {
    let daysCount = [];
    if(solutions && Array.isArray(solutions)) {
      // Remove Overall solution Data
      const filteredSolutions = solutions.filter(filterSolutionCountdown);
      const orderedSolutions = sortSolutionsByGuaranteeDate(filteredSolutions);
      daysCount = [
          ...orderedSolutions
      ]
    }
    return daysCount;
}

const normalizeCyvalueSolution = (solution, defaultPoints, filters) => {
  //  Parsing params
  const {
    filter_type: type,
    start_date: filterStartDate,
    end_date: filterEndDate
  } = filters;

  const { data, icarm, ...restOfSolution } = solution;
  const { installation_date } = restOfSolution;
  const icarmData = icarm || {};
  const icarmPointsArray = (icarmData?.steps && Array.isArray(icarmData?.steps)) ? icarmData?.steps : [];
  const newData = [];
  const icarmStepsData = [];
  //  Setting new solution flag
  const isNewSolution = (data.length === 0) && isInsideRange(new Date(installation_date), filterStartDate, filterEndDate);

  //  If is a new solution, create a array of data with one element
  let pointsArray = isNewSolution ? getDefaultNewSolutionData(type) : data;

  //  Combining default points and points from backend
  defaultPoints.forEach((point) => {
    const { start_date, end_date } = point;
    const matchingDataElement = pointsArray.find((item) => findPointBetweenInterval(item.start_date, start_date, end_date));
    // Find icarm steps that are contained in the interval
    const matchingIcarmSteps = icarmPointsArray.filter((item) => findPointBetweenInterval(item.date, start_date, end_date));

    if (matchingDataElement) {
      const { activities, score, label } = matchingDataElement;
      
      newData.push({
        ...matchingDataElement,
        end_date: end_date,
        start_date: start_date,
        activities: parseInt(activities),
        score: parseInt(score),
        label: parseInt(label),
      });
      matchingIcarmSteps.forEach((step) => {
        const { date } = step;
        if(date) {
          icarmStepsData.push({
            ...step,
            label: parseInt(label),
            x: parseInt(label),
            y: 0,
            start_date: date,
          })
        }
      });
      return;
    }
    newData.push(point);
  });

  //  Setting labels to the dummy points based on the real data
  const parsedData = adjustLabelsOnPoints(newData);

  //  Setting guarantee date
  const guarantee_date = moment(restOfSolution.installation_date).add(90, 'days').toDate();

  const solutionTheme = solutionsStyle[restOfSolution?.name?.toUpperCase()]

  //  Returning combined points and newSolutionFlag
  return {
    ...restOfSolution,
    projection_end_date: restOfSolution.projection_end_date ? new Date(restOfSolution.projection_end_date) : moment(restOfSolution.installation_date).add(82, 'days').toDate(),
    guarantee_date: restOfSolution.guarantee_date || guarantee_date,
    isNewSolution,
    data: parsedData,
    theme: solutionTheme,
    icarm: {
      ...icarmData,
      steps: icarmStepsData
    },
  };
};

//  Function to fill the data solutions
export const normalizeCyvalueData = (data, defaultPoints, filters) => {
  const { solutions, ...restOfData } = data;
  let hasNewSolution = false;

  //  Combining all solutions data with the default expected points
  const newSolutions = [];
  solutions.forEach((solution) => {
    const newSolution = normalizeCyvalueSolution(solution, defaultPoints, filters);
    if (newSolution.isNewSolution) { hasNewSolution = true; }
    newSolutions.push(newSolution);
  });

  const pendingCyvalueSolutions = getPendingCyvalueSolutions(newSolutions);

  const { overall_score } = restOfData;
  return {
    ...restOfData,
    overall_score: overall_score ? parseInt(overall_score) : overall_score,
    hasNewSolution,
    solutions: newSolutions,
    pendingCyvalueSolutions,
  };
};

export const getAmountOfTicks = (solutions) => {
  if (!solutions) { return 1; }
  //  TODO: generate lables for different solutions
  const solution = solutions[0];
  if (!solution || !solution.data) { return 14; }
  const length = solution.data.length;
  return length - 1;
};

//  TODO: for the next 2 functions find a way of select the correct range
export const getChartMinValue = (solutions = []) => {
  const firstValues = [];
  //  Getting all the first values on the array
  solutions.forEach((solution) => {
    const { data } = solution;
    if (!data) { return; }
    const firstElement = data[0];
    if (!firstElement) { return; }
    firstValues.push(firstElement.label || 0);
  });
  //  Getting the least amount
  if (firstValues.length === 0) { return 0; }
  const minValue = Math.min(...firstValues);
  return minValue;
};

export const getChartMaxValue = (solutions = []) => {
  //  TODO: change this function when multiple solutions are implemented
  const solution = solutions[0];
  if (!solution || !solution.data) { return 14; }
  const { data } = solution;
  const minValue = getChartMinValue(solutions);
  const maxValue = (minValue + (data.length - 1));
  return maxValue;
  /* const lastValues = [];
  //  Getting all the last values on the array
  solutions.forEach((solution) => {
    const { data } = solution;
    if (!data) { return; }
    const lastElement = data[data.length - 1];
    if (!lastElement) { return; }
    lastValues.push(lastElement.label || 0);
  });
  //  Getting the biggest amount
  if (lastValues.length === 0) { return 14; }
  const maxValue = Math.max(...lastValues);
  console.log('Max: ', maxValue);
  return maxValue;  */
};

export const getSolutionByName = (data) => {
  if (!data) { return null; }
  const { name, solutions } = data;
  const solution = solutions.find(({ name: solutionName }) => name === solutionName);
  return solution;
}

export const findProjectionDirection = (projection_end_date, filters) => {
  const { start_date, end_date } = filters;
  if (moment(projection_end_date).isBefore(start_date, 'day')) { return 'left'; }
  if (moment(projection_end_date).isAfter(end_date, 'day')) { return 'right'; }
  return null;
}

export const getCyvalueReachedText = (cyvalue_reached_date, guarantee_date) => {
  if (!cyvalue_reached_date || !guarantee_date) { return 'Cybersecurity value reached'; }
  const difference = getDayDifference(cyvalue_reached_date, guarantee_date);
  if (difference <= 0) { return 'Cybersecurity value reached'; }
  return `Cybersecurity value reached ${difference} days early`;
};

export const isOverallSolution = (solutionName) => {
  return solutionName === 'Overall'
}

export const cyvaluePointsConfig = {
  [timeUnits.week]: 15,
  [timeUnits.month]: 6,
  [timeUnits.quarter]: 6,
};
