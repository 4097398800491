import React from 'react'
import { useSelector } from 'react-redux';
import useUserManagerActions from '../../../hooks/useUserManagerActions';
import companyIdSelector from '../../../selectors/companyIdSelector';
import IconTooltip from '../../dashboard/tooltips/IconTooltip';
import UserManagerActionsModals from './UserManagerActionsModals';

const UserManagerActions = (props) => {

  const {
    user, // The user from the table row
    isActive,
    isVerified,
  } = props;

  //  Watching redux store
  const companyId = useSelector(state => companyIdSelector(state));
  const currentUser = useSelector((state) => state.auth.user);

  // Hooks
  const {
    actions,
    showDeleteModal,
    toggleDeleteModal,
    showAcceptModal,
    toggleAcceptModal,
    showDiscardModal,
    toggleDiscardModal,
    showResendModal,
    toggleResendModal
  } = useUserManagerActions({
    currentUser : currentUser,
    ids : [user?.id],
    isActive,
    isVerified
  });

  // Function to render actions
  const renderActionIcons = () => {
    if (actions.length < 1) return null;
    return (
      <div className='user-manager-actions d-flex'>
        {actions.map((action, index) => {
          const {
            tooltipText,
            iconClassName,
            callback,
            disabled,
            ifDisabledTooltipText,
            show
          } = action;
          if (show) {
            return (
              <div
                key={`user-manager-action-key-${index}`}
              >
                <IconTooltip
                  targetId={`user-manager-action-${user?.id}-${index}`}
                  iconClassName={iconClassName}
                  callback={disabled ? null : callback}
                  tooltipText={disabled ? ifDisabledTooltipText : tooltipText}
                />
              </div>
            )
          };
        })}
      </div>
    )
  }

  return (
    <>
      <UserManagerActionsModals
        company_id={companyId}
        users={[user] || []}
        selectedIds={[user?.id]}
        showDeleteModal={showDeleteModal}
        showAcceptModal={showAcceptModal}
        showDiscardModal={showDiscardModal}
        showResendModal={showResendModal}
        toggleDeleteModal={toggleDeleteModal}
        toggleAcceptModal={toggleAcceptModal}
        toggleDiscardModal={toggleDiscardModal}
        toggleResendModal={toggleResendModal}
      />
      {renderActionIcons()}
    </>
  )
}

export default UserManagerActions