import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import NowThenButton from '../ReportCard/NowThenButton';
import CardTabs from '../layout/tabs/CardTabs';
import SolutionsBox from './SolutionsBox';
import ComplianceScoreChart from './charts/ComplianceScoreChart';
import InfoTooltip from './tooltips/InfoTooltip';
import useWindowSize from '../../hooks/useWindowSize';
import { orderAlphaNumeric } from '../../utils/helpers';
import LoadingContent from '../layout/LoadingContent';
import NextPlanUpgradeBox from './NextPlanUpgradeBox';
import API from '../../api';
import { useSelector } from 'react-redux';
import companyIdSelector from '../../selectors/companyIdSelector';
import errorMessages from '../../constants/errorMessages';
import useCustomQuery from '../../hooks/useCustomQuery';
import chartIcon from '../../assets/images/icons/chart-empty.svg';
import { unavailableSolutionDistributionScoreInfo } from '../../utils/dashboardHelpers';


const ScoreDistribution = (props) => {
    const [screenWidth, screenHeight] = useWindowSize();
    const [showNowState, setShowNowState] = useState(true);
    const [standardIndex, setStandardIndex] = useState(0);

    const {
        assessmentLoading,
        assessmentErrorMessage,
        assessmentData,
        assessmentRetryRequest,
        justChart = false,
        showNowThen = true,
        height
    } = props;

    //  Initializing API
    const { PlanAPI } = API

    // Component state
    const [isInitialState, setIsInitialState] = useState(false);

    // Getting needed info from redux stote
    const companyId = useSelector(state => companyIdSelector(state));

    //  Calling API for next plan status
    const {
        data: nextPlanResponse,
        error: nextPlanError,
        isLoading: nextPlanLoading,
        refetch: refetchNextPlanUpgrade,
    } = useCustomQuery(
        [PlanAPI.keys.getNextPlanUpgrade, companyId],
        ({ queryKey }) => PlanAPI.handlers.getNextPlanUpgrade(queryKey[1]),
        { enabled: !!companyId },
    );

    // Watch changes in distribution
    useEffect(() => {
        setIsInitialState(_.isNil(assessmentData?.distribution?.previous));
    }, [setIsInitialState, assessmentData?.distribution]);

    const getErrorMessage = () => {
        if (nextPlanError) {
            return nextPlanError.data?.errorMessage || errorMessages.defaultGet
        }

        if (assessmentErrorMessage) {
            return assessmentErrorMessage
        }

        return null;
    }

    const retryRequest = () => {
        if (nextPlanError) {
            refetchNextPlanUpgrade();
        }

        if (assessmentErrorMessage) {
            assessmentRetryRequest()
        }
    }

    // Map solutions
    const mapSolutions = (solutions) => {
        return solutions?.map((solution_key) => {
            if (!assessmentData?.config?.solutions_info) return {}
            const solution = assessmentData?.config?.solutions_info[solution_key]
            return {
                title: solution?.title,
                name: solution_key,
                icon: `${process.env.REACT_APP_PUBLIC_RESOURCES}/solutions/mobile/${solution_key?.toLowerCase()}-base.png`,
                info: solution?.info
            }
        })
    }

    // Render CIS Score Chart
    const renderComplianceTab = () => {
        const solutionsInfo = {...assessmentData?.config?.solutions_info, ...unavailableSolutionDistributionScoreInfo()}
        return (
            <div className="mt-2">
                <ComplianceScoreChart
                    standardIndex={standardIndex}
                    nowSeries={assessmentData?.distribution?.current}
                    solutionsInfo={solutionsInfo}
                    opotunitiesSeries={assessmentData?.opportunities}
                    isInitialState={isInitialState || !showNowThen}
                    showNowThen={showNowThen}
                    thenSeries={assessmentData?.distribution?.previous}
                    showNowState={isInitialState || !showNowThen ? true : showNowState}
                    xAxisCategories={orderAlphaNumeric(_.map(assessmentData?.config?.controls, 'code'))}
                    baseline={assessmentData?.config?.baseline?.score}
                    isMobile={screenWidth < 577}
                    screenWidth={screenWidth}
                    height={height}
                />
            </div>
        );
    }

    const renderNoPurchasedSolutionsState = () => {
        return (
            <div>
                <a
                    className="btn btn-link link-light btn-solution-link underline-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={process.env.REACT_APP_CONTACT_SALES_URL}
                >
                    Contact Sales
                </a>
            </div>
        )

    }

    // Renders solutions that have been purchased
    const renderPurchasedSolutionsBox = () => {
        const purchasedSolutions = assessmentData?.grow?.celebrations;
        const message = purchasedSolutions?.length > 0 ? "Great job! you have these solutions" : "Not sure what your next step is?"
        return (
            <SolutionsBox
                message={message}
                solutions={mapSolutions(purchasedSolutions)}
                emptyContent={renderNoPurchasedSolutionsState()}
                linksPrefixId="current-solutions-box-links"
                iconClassName="bx bx-user-pin"
                showTooltipContactButton={false}
            />
        )
    }

    // Renders information about the next subscription to purchase
    const renderNextPlanUpgradeBox = () => {
        // Company is compliant when assessment is finished and all controls are below baseline
        const isCompliant = !assessmentLoading && assessmentData?.assessment_completed && assessmentData?.config?.baseline?.controls_below === 0;
        return (
            <NextPlanUpgradeBox
                isCompliant={isCompliant}
                plans={nextPlanResponse?.plan ? [nextPlanResponse?.plan] : []}
                canUpgrade={nextPlanResponse?.can_upgrade}
            />
        )
    }

    // Renders count of controls that are below the accepted baseline
    const renderControlsBelowBaselineCount = () => {
        const baseline = assessmentData?.config?.baseline;
        return (
            <div className="below-baseline-count">
                Number of controls below accepted baseline standard
                <span className="ml-1">
                    {baseline?.controls_below} of {baseline?.controls_total}
                </span>
            </div>
        )
    }


    // Renders button that switches between the Now and Then state
    const renderNowAndThenButton = () => {
        return (
            <div className="score-distribution-buttons">
                {!isInitialState && showNowThen && (
                    <NowThenButton
                        showNowState={showNowState}
                        callback={() => {
                            setShowNowState(!showNowState)
                        }}
                    />
                )}
            </div>
        )
    }

    const renderComplianceCardTabs = () => {
        const tabs = [
            {
                //name: "CIS",
                content: renderComplianceTab(),
            }
        ]

        // Card tabs alignment
        const aligmentType = screenWidth < 577 ? 'left' : 'center';

        return (
            <CardTabs
                alignment={aligmentType}
                tabs={tabs}
                callback={(tab) => setStandardIndex(tab)}
            />
        )
    }

    const renderEmptyStateMessage = () => {
        if(assessmentData?.assessment_completed) return null;
        return (
            <>
                <div className='chart-emtpy-state-background overlay'/>
                <div className='chart-emtpy-state-content'>
                    <img
                        src={chartIcon}
                        alt="assessment icon"
                        className={`img-fluid mx-auto mb-3`}
                    />
                    <p className="chart-empty-state-text">
                        Want to know where to begin to improve your cybersecurity? Complete your assessment and see what you're capable of.
                    </p>
                </div>
            </>
        ) 
    }

    const renderDistributionGraph = () => {
        return (
            <Card className={`mb-0 ${!assessmentData?.assessment_completed && `blur-2`}`}>
                <CardBody className={`graph-card ${justChart && `graph-dashboard`}`}>
                    { !justChart &&  (
                        <InfoTooltip
                            label="Score Distribution"
                            info=""
                            wrapperClassName="dashboard-widget-title"
                            showTooltip={false}
                        />
                    )}
                    {renderNowAndThenButton()}
                    {renderComplianceCardTabs()}
                </CardBody>
            </Card>
        )
    }

    return (
        <Col sm={12} lg={12} >
            <Card className="h-100 mb-0">
                <CardBody className="score-distribution">
                    {renderEmptyStateMessage()}
                    <LoadingContent
                        isLoading={assessmentLoading || nextPlanLoading}
                        errorMessage={getErrorMessage()}
                        errorButtonCallback={retryRequest}
                    >
                        { !justChart && (
                            <Row style={{ marginLeft: "-16px", marginRight: "-16px" }}>
                                {renderPurchasedSolutionsBox()}
                                {renderNextPlanUpgradeBox()}
                            </Row>
                        )}
                        {!justChart && renderControlsBelowBaselineCount()}
                        {<Button
                            className="action-btn"
                            color="light"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={process.env.REACT_APP_BUY_SOLUTIONS_NOW_URL}
                            >
                            Buy Solutions Now
                        </Button>}
                        {renderDistributionGraph()}
                    </LoadingContent>
                </CardBody>
            </Card>
        </Col>
    )
}

export default ScoreDistribution;
