import _ from 'lodash';
import filterTypes from "../constants/filterTypes"
import { getSafeArray, handleSeverityFiltersChanges, handleStatusFilterChanges, removeItemFromArray } from './helpers';

export const safelyMutateFilters = (value, filters = []) => {
    const safeFilters = getSafeArray(filters);
    const isRemoving = safeFilters?.includes(value);
    //  Appending new element
    if (!isRemoving) { return [...safeFilters, value]; }
    //  Removing element
    const newFilters = safeFilters.filter((item) => item !== value);
    return newFilters.length === 0 ? null : newFilters;
};

export const createMultipleFiltersGroupPayload = (elements = [], filterName, isOpen) => {
    const values = []
    const activeValues = []
    elements.forEach((element) => {
        //  Parsing value to int if possible, if not staying with normal value
        const valueToUse = !_.isNil(parseInt(element?.id)) ?  parseInt(element?.id) : element?.id;

        activeValues.push({
            label: isOpen ? `Open, ${element?.label}`: element?.label,
            type: filterTypes.multiple,
            filterName,
            value: valueToUse
        })
        values.push(valueToUse)
    })

    return {
        values,
        activeValues
    }
}
const removeActiveMultipleFilterFromState = (activeFiltersState = [], filter) => {
    const oldActiveFiltersState = [...activeFiltersState];
    const cleanState = _.pullAllWith(oldActiveFiltersState, [filter], _.isEqual)
    return cleanState
}

export const getMultipleFilterState = (filtersState, activeFiltersState, { filterName, value, label }) => {
    const numericValue = parseInt(value);
    const type =  filterTypes.multiple;
    const isFilterIncluded = filtersState[filterName]?.includes(numericValue);
    const filterData = { filterName, value: numericValue, label, type }
    return {
        activeFilters: isFilterIncluded ?
            removeActiveMultipleFilterFromState(activeFiltersState, filterData)
        : [
                ...activeFiltersState,
                filterData
        ],
        filters: {
            ...filtersState,
            [filterName]: safelyMutateFilters(numericValue, filtersState[filterName]),
        }
    }
}

const isFilterAdded = (a, b) => (a.value === b.value);

const isActiveFilterAdded = (a, b) => (a.filterName === b.filterName && a.value === b.value);

export const getMultipleWithOptionsFilterState = (filtersState = {}, activeFiltersState = [], { filterName, value, label, filterOption }) => {
    const type = filterTypes.multipleWithOptions;
    const addedFilter = filtersState[filterName]?.find(filter => filter.value === value);
    const shouldRemove = addedFilter?.filterOption === filterOption;
    const oldActiveFiltersState = [...activeFiltersState];
    const oldFiltersState = {...filtersState};
    const cleanActiveFilterState = addedFilter ? [..._.pullAllWith(oldActiveFiltersState, [{ label, type, filterName, value }], isActiveFilterAdded)] : activeFiltersState;
    const cleanFilterState = addedFilter ? [..._.pullAllWith(oldFiltersState[filterName], [{ value }], isFilterAdded)] : filtersState[filterName]
    const safeCleanFilter = getSafeArray(cleanFilterState);

    return {
        activeFilters: shouldRemove ? cleanActiveFilterState : (
            [
                ...cleanActiveFilterState,
                { label, type, filterName, value, filterOption }
            ]
        ),
        filters: {
            ...filtersState,
            [filterName]: shouldRemove ? safeCleanFilter: [...safeCleanFilter, { value, filterOption }]
        }
    }

}

export const getSearchFilterState = (filtersState = {}, activeFiltersState = [], { filterName, value, label }) => {
    const type = filterTypes.search;
    const oldActiveFiltersState = [...activeFiltersState];
    const cleanActiveState = _.pullAllBy(oldActiveFiltersState, [{ filterName }], 'filterName');
    return {
        filters: filtersState,
        activeFilters: _.isEmpty(value) ? (
            [...cleanActiveState]
        ) : [...cleanActiveState, { label, type, filterName, value: '' }],
        searchTerm: value
    }

}

export const getDeviceFilterState = (filtersState, activeFiltersState,  { filterName, value, label }) => {
    const type = filterTypes.deviceTypes;
    const isAdding = !filtersState[filterName].find((filter) => filter.value === value);
    //  Adding filter to the state
    if (isAdding) {
        const newFilter = { value, label };
        const newActive = {
            ...newFilter,
            filterName,
            type,
        };
        return {
            activeFilters: [...activeFiltersState, newActive],
            filters: {
                ...filtersState,
                [filterName]: [...filtersState[filterName], newFilter],
            },
        };
    }

    //  Removing filters from active
    const matchingActiveIndex = activeFiltersState?.findIndex((active) => {
        return (active?.filterName === filterName && active?.value === value);
    });
    const newActiveArray = matchingActiveIndex === -1 ? activeFiltersState :
        removeItemFromArray(activeFiltersState, matchingActiveIndex);
    //  Removing filters from filters
    const matchingFilterIndex = filtersState[filterName]?.findIndex((currentFilter) => {
        return (currentFilter.value === value);
    });
    const newFilters = matchingFilterIndex === -1 ? filtersState[filterName] :
        removeItemFromArray(filtersState[filterName], matchingFilterIndex);
    return {
        activeFilters: newActiveArray,
        filters: {
            ...filtersState,
            [filterName]: newFilters
        }
    }
}

export const getMultipleObjectsFilterState = (filtersState, activeFiltersState,  { filterName, value, label }) => {
    const type = filterTypes.multipleObjectsType;
    const isAdding = !filtersState[filterName].find((filter) => filter.value === value);
    //  Adding filter to the state
    if (isAdding) {
        const newFilter = { value, label };
        const newActive = {
            ...newFilter,
            filterName,
            type,
        };
        return {
            activeFilters: [...activeFiltersState, newActive],
            filters: {
                ...filtersState,
                [filterName]: [...filtersState[filterName], newFilter],
            },
        };
    }

    //  Removing filters from active
    const matchingActiveIndex = activeFiltersState?.findIndex((active) => {
        return (active?.filterName === filterName && active?.value === value);
    });
    const newActiveArray = matchingActiveIndex === -1 ? activeFiltersState :
        removeItemFromArray(activeFiltersState, matchingActiveIndex);
    //  Removing filters from filters
    const matchingFilterIndex = filtersState[filterName]?.findIndex((currentFilter) => {
        return (currentFilter.value === value);
    });
    const newFilters = matchingFilterIndex === -1 ? filtersState[filterName] :
        removeItemFromArray(filtersState[filterName], matchingFilterIndex);
    return {
        activeFilters: newActiveArray,
        filters: {
            ...filtersState,
            [filterName]: newFilters
        }
    }
}

export const getSingleFilterState = (filtersState = {}, activeFiltersState = [], { filterName, value, label, hideActiveFilter }) => {
    const type = filterTypes.single;
    const oldActiveFiltersState = [...activeFiltersState];
    const cleanActiveState = _.pullAllBy(oldActiveFiltersState, [{ filterName }], 'filterName');
    const shouldRemove = _.isNil(value) || hideActiveFilter
    const activeFilters =  shouldRemove ? (
        cleanActiveState
    ) : [...cleanActiveState, { label, type, filterName, value: null }]
    return {
        activeFilters,
        filters: {
            ...filtersState,
            [filterName]: value
        }
    }
}
export const getFilterState = (filtersState, activeFiltersState, { filterName, value, label, filterOption, type = filterTypes.single, hideActiveFilter }) => {
    switch (type) {
        case filterTypes.multiple: {
            return getMultipleFilterState(filtersState, activeFiltersState, { filterName, value, label })
        }
        case filterTypes.multipleWithOptions: {
            return getMultipleWithOptionsFilterState(filtersState, activeFiltersState,{ filterName, value, label, filterOption } )
        }
        case filterTypes.search: {
            return getSearchFilterState(filtersState, activeFiltersState, { filterName, value, label })
        }
        case filterTypes.single:
        default: {
            return getSingleFilterState(filtersState, activeFiltersState, { filterName, value, label, hideActiveFilter })
        }
    }
}

export const getIssuesFiltersState = (filtersState, activeFiltersState, { filterName, value, label, filterOption, type = filterTypes.single, hideActiveFilter }) => {
    switch(type) {
        case filterTypes.multiple: {
            let newFilters = null;
            let newActiveFilters = null;
            //  Need to clear the unresolved status filter because severity just filters by open issues
            if (filterName === 'severity') {
                const {
                    newActiveFilters: edditedActive,
                    newFilters: edditedFilters,
                } = handleSeverityFiltersChanges(parseInt(value), filtersState, activeFiltersState);
                newFilters = edditedFilters;
                newActiveFilters = edditedActive;
            }
            //  Need to clear all the severities in case of filtering by resolved status
            //  Since severities just allow to filter open issues
            if (filterName === 'status' && (value === '4' || value === 4)) {
                const {
                    newActiveFilters: modifiedActive,
                    newFilters: modifiedFilters
                } = handleStatusFilterChanges(parseInt(value), filtersState, activeFiltersState);
                newFilters = modifiedFilters;
                newActiveFilters = modifiedActive;
            }
            // Setting new filters to use to defaults
            if (!newFilters) { newFilters = filtersState; }
            if (!newActiveFilters) { newActiveFilters = activeFiltersState; }
            return getMultipleFilterState(newFilters, newActiveFilters, { filterName, value, label, filterOption } )
        }
        case filterTypes.multipleWithOptions: {
            return getMultipleWithOptionsFilterState(filtersState, activeFiltersState,{ filterName, value, label, filterOption } )
        }
        case filterTypes.deviceTypes: {
            return getDeviceFilterState(filtersState, activeFiltersState,  { filterName, value, label })
        }
        case filterTypes.multipleObjectsType: {
            return getMultipleObjectsFilterState(filtersState, activeFiltersState,  { filterName, value, label })
        }
        case filterTypes.search: {
            return getSearchFilterState(filtersState, activeFiltersState, { filterName, value, label })
        }
        case filterTypes.single:
        default: {
            return getSingleFilterState(filtersState, activeFiltersState, { filterName, value, label, hideActiveFilter })
        }
    }
}

export const getIssuesFilterGroupState = (filtersState = {}, activeFiltersState = [], { filterName, values, activeValues, reset }, filtersInitialState = {}) =>  {
    const newFiltersState = reset ? filtersInitialState : filtersState;
    const currentActiveFilterState = [...activeFiltersState];
    let newActiveFiltersState = [];
    const openOnly = filterName === "severity";

    if(reset) {
        const companyFilterName = 'company_id';
        const companyActiveFilter = currentActiveFilterState.find((activeFilter) => activeFilter.filterName === companyFilterName)
        if(companyActiveFilter) {
            newActiveFiltersState.push(companyActiveFilter);
        }
    } else {
        newActiveFiltersState = _.pullAllWith(currentActiveFilterState, [{ filterName }], (filter) => filter.filterName === filterName)
    }

    return {
        filters: {
            ...newFiltersState,
            company_id: filtersState?.company_id,
            solution_id: filtersState?.solution_id,
            [filterName]: values,
            open_only: openOnly,
        },
        activeFilters: [
            ...newActiveFiltersState,
            ...activeValues
        ],
    }
}

export const getFilterGroupState = (filtersState = {}, activeFiltersState = [], { filterName, values, activeValues, reset }, filtersInitialState = {}) =>  {
    const newFiltersState = reset ? filtersInitialState : filtersState;
    const currentActiveFilterState = [...activeFiltersState];
    let newActiveFiltersState = [];

    if(!reset) {
        newActiveFiltersState = _.pullAllWith(currentActiveFilterState, [{ filterName }], (filter) => filter.filterName === filterName)
    }

    return {
        filters: {
            ...newFiltersState,
            [filterName]: values,
        },
        activeFilters: [
            ...newActiveFiltersState,
            ...activeValues
        ],
    }
}

//  Function to get time filter by value
export const getTimeFilterByValue = (value = 0, filters = []) => {
    const safeFilters = getSafeArray(filters);
    return safeFilters.find((element) => element.value === value);;
};

export const getSafeTimeForPicker = (date) => {
    if (!date) { return null; }
    if (date instanceof String) { return new Date(date); }
    return new Date(date);
}
