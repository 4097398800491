import React from 'react';
import TagComponent from './TagComponent';
import TagsTooltip from './TagsTooltip';

const TagsPreview = (props) => {

    const {
        tagsList,
        className,
        targetId,
        showTooltip=true
    } = props;

    const renderTags = () => {
        if(!tagsList) return null;
        let xOffset = 0;
        const hasTwoElementsOrMore = tagsList.length > 1;
        const previewList = tagsList.slice(0,3);
        if(hasTwoElementsOrMore) {
            previewList.push({
                id: 'tag-count',
                title:  `(${tagsList.length})`,
                className: 'tags-count'
            })
        }
        return previewList.map((tag, index) => {
            const style = hasTwoElementsOrMore ? { left: xOffset + '%', width: "40px" } : { position: "relative" } ;
            xOffset += 15;
            return (
                    <TagComponent
                        key={`tag-preview-${index}`}
                        id={tag.id}
                        label={tag.title}
                        style={style}
                        className={tag.className}
                        showRemoveButton={false}
                    />
            )
        })
    }

    const renderTooltip = () => {
        if(!showTooltip || !tagsList || !(tagsList.length > 0)) return null;
        const previewList = tagsList.slice(0,8);
        const remainingCount = tagsList?.length - previewList?.length;
        if(remainingCount > 0) {
            previewList.push({ id: 'tags-remaining-count', title: `(+${remainingCount}...)`})
        }
        return (

            <TagsTooltip
                targetId={targetId}
                tagsList={previewList}
            />
        )
    }

    return (
        <div
            className={`tags-preview-list d-inline-block text-center ${className || ''}`}
            id={targetId}
        >
            {renderTags()}
            {renderTooltip()}
        </div>
    )
}

export default TagsPreview;
