import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { MANUAL_ACTIONS_MODAL_COMPONENTS } from '../../../constants/common';
import BasicModal from '../../layout/BasicModal';
import CustomCheckBox from '../../layout/CustomCheckBox';
import TextArea from '../../profile/TextArea';
import ActionDatePickerModal from './ActionDatePickerModal';
import ModalActionSwitch from './ModalActionSwitch';
import TextFieldModal from './TextFieldModal';

const PartnerActionsModal = ({
  action,
  callPartnerAction,
  open,
  closeModal,
  extraParams = {},
  extraSubmitFunction = null,
  issue = null,
  showConfirmCheckbox = false,
  isIssue = false
}) => {
  const { fields, display, key: actionKey } = action;
  const [params, setParams] = useState({});
  const [confirmCheckboxSelected, setConfirmCheckboxSelected] = useState(false);

  const setInitialParams = () => {
    const newParams = fields?.reduce((acumulatedParams, field) => (
      { ...acumulatedParams, ...{ [field.key]: field.default || '' } }
    ), {})
    setParams(newParams);
  }

  useEffect(() => {
    setInitialParams();
    setConfirmCheckboxSelected(false);
  }, [open])

  const handleParams = (key, value) => {
    const newParams = { ...params, ...{ [key]: value } };

    if (key === 'forever' && value) newParams['date_acceptance'] = '';

    setParams(newParams);
  }

  const renderCheckBoxes = (option, field, key) => {
    return (
      <CustomCheckBox
        key={key}
        isSelected={params[field.key] === option.key}
        label={option.name}
        callback={() => handleParams(field.key, option.key)}
        targetId={option.key}
      />
    );
  }

  const renderCheckBoxVertical = (field, keyField) => {
    return (
      <div className='checkbox-vertical-modal modal-field' key={keyField}>
        {field.options.map(
          (option, key) => renderCheckBoxes(option, field, key)
        )}
      </div>
    );
  }

  const renderCheckBoxHorizontal = (field, keyField) => {
    return (
      <div className='checkbox-horizontal-modal modal-field' key={keyField}>
        <p>{field.title}</p>
        <div className='checkbox-horizontal-modal-options'>
          {field.options.map(
            (option, key) => renderCheckBoxes(option, field, key)
          )}
        </div>
      </div>
    );
  }

  const handleCloseModal = () => {
    setInitialParams();
    closeModal();
    setConfirmCheckboxSelected(false);
  }

  const handleSubmitAction = () => {
    if (extraSubmitFunction) extraSubmitFunction(params);
    callPartnerAction({ ...params, ...{ action: actionKey }, ...extraParams });
    handleCloseModal();
  }

  const renderTextBox = (field, keyField) => {
    return (
      <div className='modal-field textbox-modal' key={keyField}>
        <p>{field.title}</p>
        <TextArea
          className="form-control dark text-area-grow"
          callback={(value) => handleParams(field.key, value)}
          value={params[field.key]}
          callOnClick={false}
        />
      </div>
    );
  }

  const renderTextField = (field, keyField) => {
    return (
      <TextFieldModal
        field={field}
        keyField={keyField}
        handleParams={handleParams}
        params={params}
        issue={issue}
      />
    );
  }

  const renderSwitch = (field, keyField) => {
    return (
      <ModalActionSwitch
        key={keyField}
        field={field}
        handleParams={handleParams}
        params={params}
      />
    );
  }

  const renderDateTimePicker = (field, keyField) => {
    return (
      <ActionDatePickerModal
        key={keyField}
        field={field}
        handleParams={handleParams}
        params={params}
      />
    );
  }

  const validSendButton = () => {
    const requiredFields = fields.filter(field => field.required);
    const allEmpty = Object.values(params).filter(e => e !== '');

    for (let index = 0; index < requiredFields.length; index++) {
      if (params[requiredFields[index].key] === '') return true
    }

    if (!allEmpty.length) return true;

    return false
  }

  const renderFields = () => {
    return (
      <>
        {fields.map((field, keyField) => {
          switch (field.type) {
            case MANUAL_ACTIONS_MODAL_COMPONENTS.CHECKBOXVERTICAL:
              return renderCheckBoxVertical(field, keyField);
            case MANUAL_ACTIONS_MODAL_COMPONENTS.CHECKBOXHORIZONTAL:
              return renderCheckBoxHorizontal(field, keyField);
            case MANUAL_ACTIONS_MODAL_COMPONENTS.TEXTBOX:
              return renderTextBox(field, keyField);
            case MANUAL_ACTIONS_MODAL_COMPONENTS.TEXTFIELD:
              return renderTextField(field, keyField);
            case MANUAL_ACTIONS_MODAL_COMPONENTS.SWITCH:
              return renderSwitch(field, keyField);
            case MANUAL_ACTIONS_MODAL_COMPONENTS.DATETIME:
              return renderDateTimePicker(field, keyField);
            default:
              return null;
          }
        })}
      </>
    );
  }

  const renderConfirmCheckbox = () => {
    if (!showConfirmCheckbox) return null;

    let label;

    if (isIssue) {
      label = `I confirm that I will modify the status of this alert.`;
    } else {
      label = `I confirm to modify the ${display === 'Risk acceptance' ? display : display === 'Set compliance threshold' ? 'Compliance threshold' : null} for this task`;
    }
    
    return (
      <CustomCheckBox
        className="partner-action-modal-checkbox"
        isSelected={confirmCheckboxSelected}
        targetId="action-modal-checkbox"
        callback={(isSelected) => {
          setConfirmCheckboxSelected(!isSelected);
        }}
        label={label}
        isDisabled={false}
      />
    );
  };

  return (
    <BasicModal
      header={display}
      headerClassName={``}
      showModal={open}
      toggleModal={() => closeModal()}
      customClassName={`partner-action-modal`}
    >
      {renderFields()}
      {renderConfirmCheckbox()}
      <div className='footer-buttons-modal'>
        <Button
          type="button"
          color="transparent"
          className="btn-footer-modal btn-outline-light-gray"
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
        <Button
          type="button"
          color="light"
          className="btn-footer-modal "
          onClick={() => handleSubmitAction()}
          disabled={validSendButton() || !confirmCheckboxSelected}
        >
          Save
        </Button>
      </div>
    </BasicModal>
  );
};

export default PartnerActionsModal;
