import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import MeterChart from '../../components/dashboard/charts/MeterChart';
import { createExecutionAnswers } from '../../actions/answers';
import { trackEvent } from '../../utils/gaTracker';
import NowThenButton from './NowThenButton';
import Arrow from '../dashboard/Arrow';
import { Button } from 'reactstrap';
import { formatDate } from '../../utils/helpers';
import companyIdSelector from '../../selectors/companyIdSelector';
import ComplianceLinks from '../dashboard/compliance/ComplianceLinks';
import InfoTooltip from '../dashboard/tooltips/InfoTooltip';
import { useHistory, useLocation, Link } from 'react-router-dom';
import LoadingContent from '../layout/LoadingContent';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import { arrowPositionByGrade } from '../../constants/criticality';
import { defaultIndexPath } from '../../constants/navigation';
import amplitude from 'amplitude-js';


const ComplianceScoreCard = (props) => {

    const {
        isLoading,
        errorMessage,
        retryRequest,
        assessmentData = {},
        togGradingScale,
        showNowAndThen = true,
        amplitudeBreakdownEvent,
        amplitudeRetakeEvent,
        rightIcon,
        extraClassNameLinks = ''
    } = props;

    // Component state
    const [meterType, setMeterType] = useState('score');
    const [checkupProgress, setProgress] = useState(0);
    const [progressLabel, setProgressLabel] = useState('');
    const [showNowState, setShowNowState] = useState(true);
    const [nowScoreArrowPosition, setNowScoreArrowPosition] = useState(4);
    const [thenScoreArrowPosition, setThenScoreArrowPosition] = useState(1);
    const [checkupCompleted, setCheckupCompleted] = useState(false);
    const [initialState, setInitialState] = useState(false);

    // Getting needed info from redux store
    const companyId = useSelector(state => companyIdSelector(state));

    // Component Hook
    const history = useHistory();
    const location = useLocation();
    const { dispatch } = useMemoizedDispatch();

    useEffect(() => {
        const { cyber_checkup_questions, cyber_checkup_answers, cyber_checkup_completed } = assessmentData;
        const nowPosition = arrowPositionByGrade[assessmentData?.score?.current?.total_score_grade];
        const thenPosition = showNowAndThen ? arrowPositionByGrade[assessmentData?.score?.previous?.total_score_grade] : nowPosition;
        const progress = Math.floor((cyber_checkup_answers / cyber_checkup_questions) * 100);
        const meter = cyber_checkup_completed ? 'score' : (cyber_checkup_questions === 0 || progress === 0 ? 'checkup' : 'progress');
        setProgressLabel(`${cyber_checkup_answers}/${cyber_checkup_questions} answered`);
        if (!_.isNil(cyber_checkup_questions)) {
            setMeterType(meter);
        }
        setProgress(progress);
        setNowScoreArrowPosition(nowPosition);
        setCheckupCompleted(cyber_checkup_completed);
        setThenScoreArrowPosition(thenPosition);
        setInitialState(_.isNil(assessmentData?.score?.previous?.total_score_grade));
    }, [assessmentData, setNowScoreArrowPosition, setThenScoreArrowPosition]);


    const onTakeAssessmentClick = () => {
        if (!_.isNil(assessmentData?.cyber_checkup_execution_id)) {
            trackEvent("retake_assessment", "assessment", "retake_assessment");
            if (amplitudeRetakeEvent) amplitude.getInstance().logEvent(amplitudeRetakeEvent);
            history.push('/assessment')
        } else {
            trackEvent("start_assessment", "assessment", "freemium_start_assessment");
            dispatch(createExecutionAnswers(assessmentData?.cis_assessment_id, history));
        }
    }

    const renderScaleButtons = () => {
        return (
            <React.Fragment>
                <div
                    className={`display-inline-flex float-right ${extraClassNameLinks}`}
                >
                    <ComplianceLinks
                        togGradingScale={togGradingScale}
                        assessmentData={assessmentData}
                        rightIcon={rightIcon}
                        isGradingScaleATooltip={true}
                    />
                    {showNowAndThen && !initialState && (
                        <NowThenButton
                            callback={() => setShowNowState(!showNowState)}
                            showNowState={showNowState}
                        />
                    )}
                </div>
            </React.Fragment>
        )
    }

    const renderCardHeader = () => {
        return (
            <div className="score-card-header">
                <div className="title d-inline-flex align-items-center">
                    <InfoTooltip
                        label="Cyvatar Score"
                        info="The Cyvatar Score score measures your current cybersecurity state and helps influence your Security Journey with Cyvatar"
                        id="score-tooltip"
                    />
                </div>
                <div
                    className={`score-card-options ${showNowAndThen ? 'show-now-and-then' : ''} float-right`}
                    style={{ minWidth: (extraClassNameLinks !== '') ? '200px' : '307px' }}
                >
                    {checkupCompleted && renderScaleButtons()}
                </div>
            </div>
        )
    }

    const renderMeterChart = () => {
        const arrowPosition = showNowState ? nowScoreArrowPosition : thenScoreArrowPosition;
        const inactiveArrowPosition = showNowState ? thenScoreArrowPosition : nowScoreArrowPosition;
        return (
            <MeterChart
                meterType={meterType}
                arrowPosition={arrowPosition}
                inactiveArrowPosition={inactiveArrowPosition}
                meterOffCallback={() => onTakeAssessmentClick(false)}
                checkupProgress={checkupProgress}
                progressLabel={progressLabel}
                initialState={!assessmentData?.assessment_completed}
            />
        )
    }

    // Render See Breakdown link to Compliance View
    const renderBreakdownLink = () => {
        const compliancePath = `${defaultIndexPath}/compliance`;
        if (location?.pathname === compliancePath || !showNowAndThen) return null;
        return (
            <Button
                onClick={() => handleBreakdownClick(compliancePath)}
                className="btn btn-light compliance-breakdown-link btn-small d-inline-block ml-auto"
            >
                See Breakdown
            </Button>
        )
    }

    const handleBreakdownClick = (compliancePath) => {
        history.push(compliancePath);
        if (amplitudeBreakdownEvent) amplitude.getInstance().logEvent(amplitudeBreakdownEvent);
    }

    // Render last updated info
    const renderLastUpdateInfo = () => {
        return (
            <div className="d-flex align-items-center" >
                Last updated: {formatDate(assessmentData?.score?.current?.updated_at)}
                <div className="vertical-divider mx-2" />
                Based on your last Assessment
            </div>
        )
    }

    // Render score increment or decrement
    const renderScoreIncrement = () => {
        if (initialState || !showNowAndThen) return null;
        return (
            <div className="d-flex">
                <Arrow isIncreasing={!(parseFloat(assessmentData?.score?.improvement || 0) < 0)} className="compliance-score-arrow" />{parseInt(assessmentData?.score?.improvement * 100 || 0)}% from the beginning
            </div>
        )
    }

    const renderAssessmentStatusInfo = () => {
        if (!checkupCompleted) return null;
        return (
            <div className="state-info">
                {showNowState ? (
                    <>
                        {renderScoreIncrement()}
                        <div className="d-flex align-items-end flex-wrap">
                            {renderLastUpdateInfo()}
                            {renderBreakdownLink()}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="mb-1">Based on your first Assessment</div>
                        <div>First Update: {formatDate(assessmentData?.score?.previous?.updated_at)}</div>
                    </>
                )}
            </div>
        )
    }
    return (
        <Card className="h-100 mb-3 mb-lg-0">
            <CardBody className="compliance-score-card d-flex flex-column justify-content-between">
                {renderCardHeader()}
                <div className="d-flex flex-row flex-wrap justify-content-center align-items-center w-100 my-auto">
                    <LoadingContent
                        isLoading={isLoading}
                        errorMessage={errorMessage}
                        retryRequest={retryRequest}
                    >
                        <div className="d-inline-block flex-col justify-content-center">
                            {renderMeterChart()}
                        </div>
                    </LoadingContent>
                </div>
                {renderAssessmentStatusInfo()}
            </CardBody>
        </Card>
    )
}

export default ComplianceScoreCard;
