import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Badge, Button } from 'reactstrap';
import BasicModal from '../../layout/BasicModal';
import API from '../../../api';
import useCustomMutation from '../../../hooks/useCustomMutation';
import { alertTypes } from '../../../constants/alerts';
import { setAlert } from '../../../actions/alerts';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import { getApiErrorMessage } from '../../../utils/helpers';

const RiskControlDescription = (props) => {

    //  Initializing API
    const { VcysoAPI } = API;
    const {
        companyRiskId,
        description,
    } = props;

    // Component state
    const [showEditModal, setShowEditModal] = useState(false);

    // Components Hooks
    const { dispatch } = useMemoizedDispatch();
    const {
        register,
        handleSubmit,
    } = useForm({
        defaultValues: { description }
    });

    const {
        data: updateRiskResponse,
        error: updateRiskError,
        isLoading: updateRiskLoading,
        mutate: updateRisk,
    } = useCustomMutation(
        (params) => VcysoAPI.handlers.updateCompanyRisk(params),
        VcysoAPI.invalidators.updateCompanyRisk
    );

    const toggleEditModal = () => {
        setShowEditModal(!showEditModal)
    }

    useEffect(() => {
        if(!updateRiskResponse) return;
        dispatch(setAlert("Control description updated", alertTypes.success))
        toggleEditModal();
    }, [updateRiskResponse]);

    useEffect(() => {
        if(!updateRiskError) return;
        const errorMessage = getApiErrorMessage(updateRiskError);
        dispatch(setAlert(errorMessage, alertTypes.error))
    }, [updateRiskError]);

    function handleValidSubmit(event, values) {
        const { description } = values;
        const payload = {
            id: companyRiskId,
            comment: description,
        }
        updateRisk(payload);
    }

    const onSubmitForm = handleSubmit((data, e) => {
        handleValidSubmit(e, data);
    });

    const renderForm = () => {
        return (
            <form
                onSubmit={onSubmitForm}
            >
                <textarea
                    name="description"
                    id="risk-control-description"
                    rows={5}
                    className={`risk-description-textarea form-control dark text-area-grow`}
                    {...register("description")}
                />
                <div className='d-inline-block w-100 text-center'>
                    <Button
                        className="btn-light btn-large"
                        type="submit"
                        disabled={updateRiskLoading}
                    >
                        Add Comment
                    </Button>
                </div>
            </form>
        )
    }

    const renderEditModal = () => {
        if(!showEditModal) return null;
        return (
            <BasicModal
                header="Control description"
                headerClassName={`risk-description-modal-header`}
                showModal={showEditModal}
                toggleModal={toggleEditModal}
                customClassName="risk-control-description-modal"
                scrollable={true}
            >
                <div className="modal-instructions">
                    Add a description for this control
                </div>
                {renderForm()}
            </BasicModal>
        )
    }

    return (
        <>
            {renderEditModal()}
            <Badge
                className="risk-control-description-badge"
                onClick={toggleEditModal}
            >
                {description || 'Comment here'}
            </Badge>
        </>
    )
}

export default RiskControlDescription;
