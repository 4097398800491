import { cleanup } from '@testing-library/react';
import _, { values } from 'lodash';
import {
  PARTNERS_LIST_SUCCESS,
  PARTNERS_LIST_FAILURE,
  PARTNERS_SET_SUCCESS,
  PARTNERS_SET_FAILURE,
  FURTIM_ENABLE_SUCCESS,
  FURTIM_ENABLE_FAILURE,
  PARTNERS_CLEAR,
  SET_PARTNERS_FILTER,
  SET_PARTNERS_SORT,
  CLEAR_ALL_PARTNERS_FILTERS,
  PARTNERS_RELOAD,
  PARTNERS_UPDATE_EXCLUSIONS,
  LOGOUT_SUCCESS,
  SET_PARTNER_ID_AND_NAME,
  SET_PARTNER_ACTION_VALUE,
  CLEAR_PARTNER_ACTION_VALUES,
  SET_PARTNER_ACTION_CLEANUP_MAP,
  SET_MULTIPLE_PARTNER_ACTION_VALUES,
  PARTNERS_MANUAL_ACTION_LIST
} from "../actions/types";
import filterTypes from "../constants/filterTypes";
import { cleanupFieldsFromValuesForKey } from "../utils/partnerActionHelpers";

const filtersInitialState = {};
const sortFieldInitialState = { name: null, asc: true };
const initialState = {
  filters: filtersInitialState,
  activeFilters: [],
  searchTerm: '',
  sortField: sortFieldInitialState,
  list: [],
  pageCount: 1,
  total: 0,
  shouldReload: false,
  processing: false,
  partnerId: null,
  partnerActionValues: {},
  cleanupMap: {},
  actions: {}
}

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PARTNERS_LIST_SUCCESS: {
      return {
        ...state,
        list: payload?.data || [],
        pageCount: payload.pagination?.page_count || 1,
        total: payload.pagination?.total || 0,
      };
    }
    case PARTNERS_CLEAR:
    case PARTNERS_LIST_FAILURE:
    case LOGOUT_SUCCESS: {
      return initialState;
    }
    case PARTNERS_SET_SUCCESS: {
      return {
        ...state,
        shouldReload: true,
        processing: false,
      };
    }
    case PARTNERS_SET_FAILURE: {
      return {
        ...state,
        shouldReload: false,
        processing: false,
      };
    }
    case FURTIM_ENABLE_SUCCESS: {
      return {
        ...state,
        shouldReload: true,
        processing: false,
      };
    }
    case FURTIM_ENABLE_FAILURE: {
      return {
        ...state,
        shouldReload: false,
        processing: false,
      };
    }
    case SET_PARTNERS_FILTER: {
      const { filterName, type, value, label } = payload;
      switch (type) {
        case filterTypes.multiple: {
          return {
            ...state,
            activeFilters: state.filters[filterName]?.includes(value)
              ? [
                  ..._.pullAllWith(
                    state.activeFilters,
                    [{ label, type, filterName, value }],
                    _.isEqual
                  ),
                ]
              : [...state.activeFilters, { label, type, filterName, value }],
            filters: {
              ...state.filters,
              [filterName]: state.filters[filterName]?.includes(value)
                ? _.pull(state.filters[filterName], value)
                : [...state.filters[filterName], value],
            },
          };
        }
        case filterTypes.search: {
          const cleanActiveState = _.pullAllBy(
            state.activeFilters,
            [{ filterName }],
            "filterName"
          );
          return {
            ...state,
            activeFilters: _.isEmpty(value)
              ? [...cleanActiveState]
              : [...cleanActiveState, { label, type, filterName, value: "" }],
            searchTerm: value,
          };
        }
        case filterTypes.single:
        default: {
          const cleanActiveState = _.pullAllBy(
            state.activeFilters,
            [{ filterName }],
            "filterName"
          );
          return {
            ...state,
            activeFilters: _.isNil(value)
              ? [...cleanActiveState]
              : [...cleanActiveState, { label, type, filterName, value: null }],
            filters: {
              ...state.filters,
              [filterName]: value,
            },
          };
        }
      }
    }
    case SET_PARTNERS_SORT: {
      return {
        ...state,
        sortField: payload,
      };
    }
    case CLEAR_ALL_PARTNERS_FILTERS: {
      return {
        ...state,
        filters: filtersInitialState,
        activeFilters: [],
        searchTerm: "",
      };
    }

    case PARTNERS_RELOAD: {
      return {
        ...state,
        shouldReload: payload,
      };
    }
    case PARTNERS_UPDATE_EXCLUSIONS: {
      const partnersClone = [...(state.list || [])];
      const indexToUpdate = partnersClone.findIndex(
        (_partner) => _partner.id === payload.id
      );

      if (indexToUpdate === -1) return state;
      partnersClone.splice(indexToUpdate, 1, payload);

      return {
        ...state,
        list: partnersClone,
      };
    }

    case SET_PARTNER_ID_AND_NAME: {
      return {
        ...state,
        partnerId: payload.partnerId,
        partnerName: payload.partnerName,
      };
    }

    case SET_PARTNER_ACTION_CLEANUP_MAP: {
      return {
        ...state,
        cleanupMap: payload,
      };
    }

    case SET_PARTNER_ACTION_VALUE: {
      const actionValuesCopy = {
        ...state.partnerActionValues,
        [payload.key]: payload.value,
      };

      const { cleanupMap } = state;
      cleanupFieldsFromValuesForKey(payload.key, actionValuesCopy, cleanupMap);

      const cleanCopy = {
        ...initialState.partnerActionValues,
        ...actionValuesCopy,
      };

      return {
        ...state,
        partnerActionValues: cleanCopy,
      };
    }

    case CLEAR_PARTNER_ACTION_VALUES: {
      return {
        ...state,
        partnerActionValues: {},
        cleanupMap: {},
      };
    }

    case SET_MULTIPLE_PARTNER_ACTION_VALUES: {
      const actionValuesCopy = {
        ...state.partnerActionValues,
      };
      for (let key in payload) {
        actionValuesCopy[key] = payload[key];
      }
      return {
        ...state,
        partnerActionValues: actionValuesCopy,
      }
    }
    case PARTNERS_MANUAL_ACTION_LIST: {
      return {
        ...state,
        actions: payload
      }
    }
    default:
      return state;
  }
}
