import _ from "lodash";
import {
  SET_CONNECTIONS_FILTER,
  SET_CONNECTIONS_SORT,
  CLEAR_ALL_CONNECTIONS_FILTERS,
  LOGOUT_SUCCESS,
  SET_CONNECTION_DEFAULT_FILTERS,
} from "../actions/types";
import filterTypes from "../constants/filterTypes";

const filtersInitialState = {
  status: null,
  solution_id: null,
  partner_id: null,
  company_id: null,
};
const sortFieldInitialState = { name: null, asc: true };
const initialState = {
  filters: filtersInitialState,
  activeFilters: [],
  searchTerm: "",
  sortField: sortFieldInitialState,
  applyDefaultFilters: true,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CONNECTIONS_FILTER: {
      const { filterName, type, value, label } = payload;
      switch (type) {
        case filterTypes.multiple: {
          return {
            ...state,
            activeFilters: state.filters[filterName]?.includes(value)
              ? [
                  ..._.pullAllWith(
                    state.activeFilters,
                    [{ label, type, filterName, value }],
                    _.isEqual
                  ),
                ]
              : [...state.activeFilters, { label, type, filterName, value }],
            filters: {
              ...state.filters,
              [filterName]: state.filters[filterName]?.includes(value)
                ? _.pull(state.filters[filterName], value)
                : [...state.filters[filterName], value],
            },
          };
        }

        case filterTypes.search: {
          const cleanActiveState = _.pullAllBy(
            state.activeFilters,
            [{ filterName }],
            "filterName"
          );
          return {
            ...state,
            activeFilters: _.isEmpty(value)
              ? [...cleanActiveState]
              : [...cleanActiveState, { label, type, filterName, value: "" }],
            searchTerm: value,
          };
        }

        case filterTypes.single:
        default: {
          const cleanActiveState = _.pullAllBy(
            state.activeFilters,
            [{ filterName }],
            "filterName"
          );
          return {
            ...state,
            activeFilters: _.isNil(value)
              ? [...cleanActiveState]
              : [...cleanActiveState, { label, type, filterName, value: null }],
            filters: {
              ...state.filters,
              [filterName]: value,
            },
          };
        }
      }
    }

    case SET_CONNECTIONS_SORT: {
      return {
        ...state,
        sortField: payload?.value,
      };
    }

    case CLEAR_ALL_CONNECTIONS_FILTERS: {
      return {
        ...state,
        filters: filtersInitialState,
        activeFilters: [],
        searchTerm: "",
      };
    }

    case SET_CONNECTION_DEFAULT_FILTERS: {
      return {
        ...state,
        applyDefaultFilters: payload
      };
    }
    case LOGOUT_SUCCESS: {
      return initialState;
    }

    default:
      return state;
  }
};
