import React from 'react'
import AvatarTooltip from '../../layout/avatars/AvatarTooltip';
import smallLogo from '../../../assets/images/cyvatar-icon-logo.svg';
import BasicTooltip from '../../dashboard/tooltips/BasicTooltip';

const RiskStatementOwner = (props) => {
    const {
        componentId,
        userName,
    } = props;

    const renderAvatar = () => {
        const user = {
            full_name: userName,
        }
        return (
            <AvatarTooltip
                id={componentId}
                user={user}
                customClassName="risk-statement-owner-avatar"
            />)
    }

    const renderCyvatarLogo = () => {
        return (
            <>
                <img
                    id={componentId}
                    className="img-fluid risk-owner-cyvatar"
                    src={smallLogo}
                />
                <BasicTooltip
                    className={`more-info-tooltip`}
                    placement="bottom"
                    target={componentId}
                    content={`Made by Cyvatar`}
                />
            </>
        )
    }
    return componentId && userName ? renderAvatar() : renderCyvatarLogo()
}

export default RiskStatementOwner
