import React, { useMemo } from 'react';
import { nameToInitials, getUserAvatarColorClassName } from '../../../utils/helpers';

const Avatar = (props) => {
    //  Parsing props
    const { user, customClassName, profileImage } = props;

    //  Component variables
    const nameToUse = useMemo(() => {
        if (!user) { return 'G'; }
        const { full_name, name } = user;
        if (!full_name && !name) { return 'G'; }
        return full_name || name;
    }, [user]);

    //  Style functions
    const customStyle = profileImage ? { backgroundImage: `url(${profileImage})`, backgroundColor: "transparent" } : {};
    const getClassName = () => {
        const colorClassName = getUserAvatarColorClassName(user);
        return `avatar-circle ${colorClassName} ${customClassName || ''}`;
    }

    //  Rendering
    return (
        <div
            className={getClassName()}
            style={customStyle}
        >
            {!profileImage && nameToInitials(nameToUse)}
        </div>
    )
}

export default (Avatar);
