import React from 'react';
import { useSelector } from 'react-redux';
import API from '../../../../api';
import companyIdSelector from '../../../../selectors/companyIdSelector';
import useCustomQuery from '../../../../hooks/useCustomQuery';
import { chartBarBasicConfig } from '../../../../constants/dashboard';
import { arrangeAssignedToData, formatData } from '../../../../utils/dashboardHelpers';
import DashboardBarChart from './DashboardBarChart';

const AssignedTo = () => {
  //  Initializing API
  const { GrcIssueAPI } = API;

  //  Watching redux store
  const company_id = useSelector((state) => companyIdSelector(state));

  //  Calling API
  const {
    data: assignedToData,
    error: assignedToError,
    loading: assignedToLoading,
    refetch: retryRequest,
  } = useCustomQuery(
    [GrcIssueAPI.keys.getAssignedTo, company_id ],
    ({ queryKey }) => GrcIssueAPI.handlers.getAssignedTo(queryKey[1])
  );


  // Function to render tooltip
  const renderTooltip = (opts) => {
    const { dataPointIndex, w, seriesIndex, series } = opts;
      return (`
        <div class="apexcharts-doughnut-tooltip apexcharts-basic-tooltip arrow-down">
        <div class="series-label">${w.globals.labels[dataPointIndex]}</div>
        <div>${parseInt(series[seriesIndex][dataPointIndex] || 0)}</div>
        </div>
    `);
  };


  const renderChart = (props) => {
    const {
      data,
      total,
      xAxisCategories
    } = props;

    return (
      <DashboardBarChart
        title="Assigned To"
        chartLoading={assignedToLoading}
        chartError={assignedToError}
        retryRequest={retryRequest}
        optionsChart={chartBarBasicConfig(xAxisCategories,renderTooltip)}
        series={[{data: formatData(data,total)}]}
        renderEmpty={total===0}
        widgetId="assigned-to"
        barClassName='assigned-to-bar-chart'
      />
    );

  };

  //  Function to render the required widget
  const renderRequiredWidget = () => {
    const widgetConfig = arrangeAssignedToData(assignedToData);
    return renderChart(widgetConfig);
  };


  //  Rendering
  return (
    renderRequiredWidget()
  );
};

export default AssignedTo;
