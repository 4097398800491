import React, { useEffect } from 'react'
import CyvatarProHomeView from '../../views/homepage/CyvatarProHomeView';
import MyCyvatarHomeView from '../../views/homepage/MyCyvatarHomeView';
import useCyvatarProValidation from '../../hooks/useCyvatarProValidation';
import useSolutionsOrFallbackSubtabs from '../../hooks/useSolutionsOrFalbackSubtabs';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import { onShowGRCDetails } from '../../actions/issues';

const DashboardHomeRoutes = () => {
    // Hooks
    const { canViewCyvatarPro } = useCyvatarProValidation()
    useSolutionsOrFallbackSubtabs(canViewCyvatarPro);
    const { dispatch } = useMemoizedDispatch();

    // Closing GRC issue details if is opened
    useEffect(() => {
        dispatch(onShowGRCDetails(false));
    }, []);

    return canViewCyvatarPro ? (
        <CyvatarProHomeView />
    ) : (
        <MyCyvatarHomeView />
    )
}

export default DashboardHomeRoutes;
