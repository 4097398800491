import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { onSidebarTabSelected } from '../actions/navigation';
import useMemoizedDispatch from './useMemoizedDispatch';
import companyInfoSelector from '../selectors/companyInfoSelector';
import { getParentPath } from '../utils/helpers';

const useSelectedTab = (props) => {
  //  Parsing props
  const { pathname, filterAllowed = true } = props;

  //  Memoized dispatch
  const { dispatch } = useMemoizedDispatch();

  //  Watching redux state
  const role = useSelector((state) => state.auth.user?.roles[0]?.name);
  const isSales = useSelector((state) => companyInfoSelector(state, 'isSales', false));
  const freemiumStatus = useSelector((state) => companyInfoSelector(state, 'freemium', false));
  const impersonatedCompanyId = useSelector((state) => state.impersonation.companyId);

  //  Hook state
  const [selected, setSelected] = useState(false);

  //  Getting location from router
  const location = useLocation();

  //  Watching pathname changes
  useEffect(() => {
    // Get parent path
    const parentPath = getParentPath(location?.pathname)
    setSelected(parentPath === pathname);
  }, [location?.pathname, setSelected]);

  //  Dispatching action to set tabs and subtabs
  useEffect(() => {
    if (!selected) { return; }
    const activeImpersonation = !_.isNil(impersonatedCompanyId);
    // Special case for profile
    const shouldFilter = pathname === '/profile' && !_.isNil(impersonatedCompanyId) ? false : true;
    dispatch(onSidebarTabSelected(location?.pathname, role, shouldFilter, freemiumStatus, isSales,  activeImpersonation));
  }, [selected, role, location?.pathname]);

  //  Returning needed assets
  return { selected };
};

export default useSelectedTab;
