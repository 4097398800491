import React, { useEffect, useState } from 'react';
import IcarmSummary from './IcarmSummary';
import DashboardRow from '../../../common/dashboard/DashboardRow';
import { useSelector } from 'react-redux';
import { successActionCreator } from '../../../../../actions/action-creators/success';
import { SET_OPEN_OVERVIEW } from '../../../../../actions/types';
import useMemoizedDispatch from '../../../../../hooks/useMemoizedDispatch';
import IcarmDistribution from '../../overview/IcarmDistribution';
import { dashboardOverviewsIds } from '../../../../../constants/dashboardOverview';
import IcarmProgression from './IcarmProgression';
import { Button, Col } from 'reactstrap';
import DashboardCard from '../../../common/dashboard/DashboardCard';
import useIcarmDataV2 from '../../../../../hooks/useIcarmDataV2';
import EmptyState from '../../../../CustomTable/EmptyState';
import icarmEmptyPreview from '../../../../../assets/images/preview-images/icarm-preview-empty-state.svg';
import { getFormattedDateYMD } from '../../../../../utils/helpers';

const CybersecurityScore = () => {

    const [activeButton, setActiveButton] = useState(0);

    //  Watching redux store
    const selectedSubtabSearch = useSelector((state) => state?.navigation?.selectedSubtab?.search);
    const open_overview = useSelector(state => state.dashboards?.open_overview);

    //  Watching redux store
    const range_value = useSelector((state) => state?.dashboards?.range_value);
    const start_date = useSelector((state) => state?.dashboards?.start_date);
    const end_date = useSelector((state) => state?.dashboards?.end_date);

    const {
        filters,
        onboardingSolutions,
        maintenanceSolutions,
        changeFilters,
        themeColor,
        onboardingLoading,
        maintenanceLoading,
        onboardingErrorMessage,
        maintenanceErrorMessage,
    } = useIcarmDataV2();


    useEffect(() => {
        const startDate = getFormattedDateYMD(new Date(start_date),'-');
        const endDate = getFormattedDateYMD(new Date(end_date),'-')
        const newFilters = {
            start_date : startDate,
            end_date : endDate,
            filter_type: 'WEEK',
        }
        changeFilters(newFilters);
    },[start_date,end_date]);

    //  Component's Hooks
    const { dispatch } = useMemoizedDispatch();

    // Render Functions
    const renderEmptyState = () => {
        return (
            <div className='icarm-empty-state-container'>
                <EmptyState
                    title={'Daily updates on your roadmap'}
                    message={'Subscribe to one of our solutions. Contact our Solutions Advisor Team'}
                    titleClassName={'icarm-empty-state'}
                    messageClassName={'icarm-message-empty-state'}
                />
                <img
                    className="icarm-progressbar-preview"
                    src={icarmEmptyPreview}
                    alt={"icarm progress bar preview"}
                />
            </div>
        );
    };

    // Render Icarm score and status
    const renderIcarmSummaryCard = () => {
        return (
            <IcarmSummary
                isLoading={onboardingLoading}
                errorMessage={onboardingErrorMessage}
                solutionsData={onboardingSolutions}
                filters={filters}
                themeColor={themeColor}
                lastNumberDays={range_value}
                emptyState={renderEmptyState}
                lg={12}
            />
        )
    }

    const renderIcarmMaintenance = () => {
        return (
            <IcarmProgression
                isLoading={maintenanceLoading}
                errorMessage={maintenanceErrorMessage}
                filters={filters}
                changeFilters={changeFilters}
                solutionsData={maintenanceSolutions}
                themeColor={themeColor}
                lastNumberDays={range_value}
                emptyState={renderEmptyState}
                lg={12}
            />
        );
    };

    // Render Icarm Line Chart
    const renderIcarmChartCard = () => {
        return (
            <>
            {/*
            <IcarmDistributionChart
                isLoading={icarmLoading}
                errorMessage={icarmErrorMessage}
                filters={filters}
                changeFilters={changeFilters}
                solutionsData={solutionsData}
                hasOverallScore={hasOverallScore}
                hasZeroScore={hasZeroScore}
                hasSurpassedGoal={hasSurpassedGoal}
                themeColor={themeColor}
            />
            */}
            </>
        )
    }

    const renderIcarmTooltipContent = () => {
        return (
            <div className="icarm-help-info">
              <p className="body">
                This widget is designed to display the current status of a any of your solutions. Additionally, can be  used to monitor the progress of the solution over time. Once a solution has reached the Cyvalue state will be moved to maintenance. The maintenance side of this widget is to check the issues that have been resolved by a particular solution, you can look for deeper information at the issues widget.
              </p>
            </div>
          );
    };

    // Render Overview
    const renderOverview = () => {
        if (open_overview!==dashboardOverviewsIds.ICARM_DISTRIBUTION) {return null;}
        return <IcarmDistribution/>
    }

    const renderIcarmToggleHeaderButtons = () => {
        return (
            <div className='buttons-container'>
                <Button className="toggle-btn onboarding" color="light"
                    type="button"
                    outline
                    active={activeButton === 0}
                    onClick={() => setActiveButton(0)}
                >
                    Onboarding
                </Button>
                <Button className="toggle-btn maintenance" color="secondary"
                    type="button"
                    outline
                    active={activeButton === 1}
                    onClick={() => setActiveButton(1)}
                >
                    Maintenance
                </Button>
            </div>
        );
    };

    const renderNewIcarm = () => {
        return (
            <Col lg={12} className="px-2">
                <DashboardCard
                    title={activeButton === 0 ? 'Onboarding status by solution ' : 'Resolved issues by solution'}
                    customTitleClassName='icarm-card-title'
                    customHeaderComponent={renderIcarmToggleHeaderButtons()}
                    tooltipCustomClassName='icarm-help-icon-tooltip'
                    showInformationIconTooltip={true}
                    tooltipTargetId={'icarm-info-tooltip'}
                    iconTooltipContent={renderIcarmTooltipContent()}
                >
                    {activeButton === 0 ? renderIcarmSummaryCard() : renderIcarmMaintenance()}
                </DashboardCard>
            </Col>
        );
    };

    // Render
    return (
        <DashboardRow
            title="Cybersecurity Score"
            //linkText="See all"
            linkUrl={`/dashboard/${selectedSubtabSearch}`}
            linkOnClick={()=>{
                //dispatch(successActionCreator(SET_OPEN_OVERVIEW,dashboardOverviewsIds.ICARM_DISTRIBUTION));
            }}
            dashboardBodyClassName="px-0 pb-4"
            rowClassName="cyvalue-widget new-100"
        >
            {renderNewIcarm()}
            {renderOverview()}
        </DashboardRow>
    )
}

export default CybersecurityScore
