import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import FormCard from '../layout/FormCard';
import CodeInput from '../../components/settings/CodeInput';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

// availity-reactstrap-validation
import { AvForm } from 'availity-reactstrap-validation';
import { requestMFACode, validateMFACode } from '../../actions/auth';
import startsWith from 'lodash.startswith';
import LoadingContent from '../layout/LoadingContent';
import API from '../../api';
import { setAlert } from '../../actions/alerts';
import { getSafeArray } from '../../utils/helpers';
import useCustomQuery from '../../hooks/useCustomQuery';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';

const SMSCard = (props) => {
  //  Parsing params
  const {
    // mfaConfig,
    validateMFACode,
    requestMFACode,
    error,
    history,
    location,
  } = props;

  //  Initializing API
  const { SettingsAPI } = API;

  //  Component state
  const [code, setCode] = useState("");
  const [phone, setPhone] = useState("");
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [country, setCountry] = useState("");
  const [canSubmit, setCanSubmit] = useState(true);
  const [supportedCodes, setCodeCountryCodes] = useState([]);

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const {
    data: codesData,
    error: codesError,
    isLoading: codeLoading,
  } = useCustomQuery(
    [SettingsAPI.keys.getMFACountryCodes],
    ({ queryKey }) => SettingsAPI.handlers.getMFACountryCodes(),
  );

  //  Watching code response
  useEffect(() => {
    if (!codesData) { return; }
    const { codes = [] } = codesData;
    const safeCodes = getSafeArray(codes);
    const parsedCodes = safeCodes.map(({ code }) => code);
    setCodeCountryCodes(parsedCodes);
  }, [codesData]);

  //  Watching code error
  useEffect(() => {
    if (!codesError) { return; }
    dispatch(setAlert('Error getting country codes.', 'alert'));
    //  Using hardcoded codes in case of error
    let commonCodes = ['us', 'gb', 'ca', 'in', 'ru', 'pk'];
    if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV.toUpperCase() !== 'PROD') {
      commonCodes = [...commonCodes, 'hn', 'gt', 'sv', 'es']
    };
    setCodeCountryCodes(commonCodes);
  }, [codesError]);


  function handleRequestCode(event, values) {
    if (!canSubmit) return;
    const formValues = {
      phone: `+${phone}`,
    }
    requestMFACode(formValues, history, location);
  }

  function handleCodeChange(newCode) {
    setCode(newCode);
  }

  function handleEnableMFA() {
    const formValues = {
      phone: `+${phone}`,
      isFirstTime: true,
      code: code,
    }
    setCanSubmit(false);
    validateMFACode(formValues, history, location);
  }

  function validatePhoneNumber(currentPhone, countries) {
    const validFormat = countries.some((country) => {
      return startsWith(currentPhone, country.dialCode) || startsWith(country.dialCode, currentPhone);
    });
    setIsValidPhoneNumber(validFormat);
    return validFormat;
  }

  function handlePhoneChange(countryInfo, phone) {
    setPhone(phone);
    setCountry(countryInfo ? `${countryInfo.name} ${countryInfo.dialCode}` : '');
  }

  //  Function to render phone input
  const renderPhoneInput = () => {
    return (
      <LoadingContent
        isLoading={codeLoading || supportedCodes.length === 0}
      >
        <PhoneInput
          inputStyle={{ width: '100%', borderColor: '#170b45', boxShadow: 'none' }}
          country={'us'}
          value={phone}
          onlyCountries={supportedCodes}
          onChange={(inputNumber, country) => { handlePhoneChange(country, inputNumber) }}
          isValid={(inputNumber, country, countries) => { return validatePhoneNumber(inputNumber, countries); }}
          required={true}
        />
      </LoadingContent>
    )
  };

  //  Rendering
  return (
    <div className="form-card-container">
      <FormCard
        title="SMS text message"
        error={error}
        customClassName="mfa-sms-card"
      >
        <div>
          <AvForm className="form-horizontal" onValidSubmit={(e, v) => { handleRequestCode(e, v) }}>
            <div className="form-group" style={{ marginBottom: "24px" }} >
              <label>Phone number</label>
              {renderPhoneInput()}
            </div>
            <div style={{ marginBottom: "15px" }} >
              <button
                className="btn btn-light text-dark btn-block"
                type="submit"
                onClick={() => { setCanSubmit(true) }}
                disabled={!isValidPhoneNumber}
              >
                Send Authentication code
              </button>
            </div>

            <div className="code-input">
              <p className="code-input-label">Enter the six-digit code sent to your phone</p>
              <CodeInput callback={handleCodeChange} />
            </div>
            <div className="d-flex align-content-center flex-wrap justify-content-between button-container">
              <div>
                <button
                  className="btn btn-light text-dark btn-block validate-code-button"
                  onClick={handleEnableMFA}
                  disabled={code.length !== 6}
                >
                  Enable
                </button>
              </div>
              <div className="btn btn-outline-light validate-code-button">
                <Link to="/settings">Cancel</Link>
              </div>
            </div>
          </AvForm>
        </div>
      </FormCard>
    </div>
  );
}

const mapStatetoProps = state => {
  const { error, isAuthenticated, mfaConfig } = state.auth;
  return { error, isAuthenticated, mfaConfig };
}

export default withRouter(connect(mapStatetoProps, { requestMFACode, validateMFACode })(SMSCard));

