//  File to set constants related to company
export const companyPageSize = 20;
export const impersonationPathsWhiteList = [
  { pathname: '/dashboard', validateLocation: false },
  { pathname: '/app-launcher', validateLocation: false },
  { pathname: '/assessment', validateLocation: false },
  { pathname: '/cyso', validateLocation: false },
  { pathname: '/devices', validateLocation: false },
  { pathname: '/issues', validateLocation: false },
  { pathname: '/manage-solutions', validateLocation: false },
  { pathname: '/profile', validateLocation: false },
  { pathname: '/subscription', validateLocation: false },
  { pathname: '/recycle-bin', validateLocation: false },
];

export const memberTypes = {
  ALL: null,
  FREEMIUM: 0,
  PAYING: 1,
  INACTIVE: 2,
  UNVERIFIED: 3,
};
