import React from 'react';
import { Button } from 'reactstrap'
import BasicModal from './BasicModal';
import { logout } from '../../actions/auth';
import { assessmentHandlerTypes } from '../../constants/common';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useSaveFullAssessment from '../../hooks/useSaveFullAssessment';

const LogoutModal = (props) => {
  //  Parsing props
  const { toggleModal, answers, assessment, companyId } = props;

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const { saveFullAssessment } = useSaveFullAssessment();

  //  Function to log out
  const logOut = () => {
    if (answers.unsavedChanges) {
      saveAssessment()
    } else {
      dispatch(logout());
    }
  }

  const saveAssessment = () => {
    const assessmentBody = {
      assessment_id: assessment?.id,
      company_id: companyId,
      questionAnswers: answers.questionAnswers,
      questionsByCategory: answers.questionsByCategory,
      questionComments: answers.questionComments,
      sectionComments: answers.sectionComments,
      comment: assessment.assessmentComment,
      completed: answers.assessmentExecutionStatus
    };
    const successCallback = () => dispatch(logout());
    const callbackObject = { handler: successCallback, type: assessmentHandlerTypes.FUNCTION };
    saveFullAssessment(assessmentBody, null, callbackObject);
  }

  //  Rendering
  return (
    <BasicModal
      showModal={true}
      toggleModal={toggleModal}
      title="You’re logging out"
      subtitle={`${answers.unsavedChanges ? 'You have unsaved changes on your assessment. ' : ''} Are you sure you want to log out?`}
      customClassName="logout-modal"
    >
      <div className="row-container logout-modal">
        <Button
          color="light-gray left-modal-button"
          outline
          onClick={toggleModal}
        >
          Cancel
        </Button>
        <Button
          className="btn btn-submit btn-light text-dark font-weight-bold"
          onClick={() => logOut()}
        >
          {answers.unsavedChanges ? 'Save and log out' : 'Yes, Log out'}
        </Button>
      </div>
    </BasicModal>
  );
};

export default LogoutModal;
