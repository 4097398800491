import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { trackEvent } from '../../utils/gaTracker';
import { actionTypes } from '../../constants/navigation';
import { triggerTopbarAction } from '../../actions/navigation';
import API from '../../api';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import companyIdSelector from '../../selectors/companyIdSelector';
import useCustomMutation from '../../hooks/useCustomMutation';
import amplitude from 'amplitude-js';

const VcysoPolicy = (props) => {
  //  Parsing props
  const {
    policy_id,
    name = '',
    description,
    icon,
    available,
    showDownloadSection = false,
    policyUrl,
  } = props;

  //  Initializing API
  const { VcysoAPI } = API;

  //  Memoized dispatch
  const { dispatch } = useMemoizedDispatch();

  //  Watching redux state
  const actionLoading = useSelector((state) => state.navigation.action.loading);
  const actionParams = useSelector((state) => state.navigation.action.params);
  const company_id = useSelector((state) => companyIdSelector(state));

  //  Component state
  const [loading, setLoading] = useState(false);

  //  Component hooks
  const { mutate: trackPolicyDownload, data: trackingSuccess } = useCustomMutation(
    (params) => VcysoAPI.handlers.trackPolicyDownload(params),
    VcysoAPI.invalidators.trackPolicyDownload,
  );

  //  Watching tracking success
  useEffect(() => {
    if (!trackingSuccess) { return; }
    dispatch(triggerTopbarAction(actionTypes.DOWNLOAD_POLICY, policyUrl));
  }, [trackingSuccess]);

  //  Watching redux store changes
  useEffect(() => {
    setLoading((actionLoading && actionParams === policyUrl));
  }, [actionLoading, actionParams]);

  //  Function to download policies
  const onDownloadPolicies = () => {
    trackPolicyDownload({ company_id, policy_id });
    trackEvent('download-pdf-cyso-policy', 'cyso-policies', `download-pdf-cyso-policy-${name}`, name);
    amplitude.getInstance().logEvent("Policies View - Downloads", {"Policy Name": name });
  };

  //  Rendering
  return (
    <div className={`vcyso-policy h-100 ${showDownloadSection ? 'policy-download-option' : ''}`}>
      <div className="text-center mb-2">
        <img src={icon} />
      </div>
      <div className="policy-name mb-2">{name}</div>
      <div className="policy-description">{description}</div>
      {showDownloadSection && (
        <div className="policy-download-section text-center">
          {available ? (
            <Button
              color="light"
              className="btn-download-policy d-flex justify-content-center align-items-center my-1 mx-3"
              onClick={() => onDownloadPolicies()}
              disabled={loading}
            >
              <i className='bx bx-download'></i> {loading ? 'Loading' : 'Download'}
            </Button>
          ) : (
            <div className="d-flex justify-content-center align-items-center py-1 px-3">
              <i className='bx bx-check-shield mr-1' ></i>
              Available for CySO members
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default VcysoPolicy;