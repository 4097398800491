import React from 'react';
import { useSelector } from 'react-redux';
import { MANUAL_ACTIONS_COMPONENTS } from '../../../constants/common';
import { issueTypes, RESOLVED_STATUS } from '../../../constants/issues';
import partnerActionsSelector from '../../../selectors/partnerActionsSelector';
import CallbackSelectField from '../../layout/fields/SelectFieldCallback';
import PartnerActionsButton from './PartnerActionsButton';
import PartnerActionsButtonModal from './PartnerActionsButtonModal';
import useManualActions from '../../../hooks/useManualActions';

const PartnerActions = ({ issue, group, task }) => {
  const partnerID = issue?.partner?.id || group?.partner_id;
  const partnerActions = useSelector(state => partnerActionsSelector(state, partnerID));

  //  Component hooks
  const { callPartnerAction } = useManualActions({ issue, group });

  //  Function render dropdown actions
  const renderDropdownAction = (action) => {
    // Prepare options for CallbackSelectField
    let options = [];
    action.options.forEach((option) => {
      options.push({ label: option, value: option });
    });
    return (
      <CallbackSelectField
        name={action.name}
        label={action.name}
        placeholder="Action"
        value="Action"
        options={[{ options }]}
        callback={(value) => {
          if (value) {
            // Prepare params
            const option = value.value;
            const params = { action: action.name, reference_field: 'id', option };
            callPartnerAction(params);
          }
        }}
      />
    );
  };

  // Function render button actions
  const renderButtonActions = (action) => {
    return (
      <PartnerActionsButton
        action={action}
        onClick={() => {
          const params = { action: action.key, reference_field: 'id' };
          callPartnerAction(params);
        }}
      />
    );
  }

  const renderModalActions = (action) => {
    return (
      <PartnerActionsButtonModal
        issue={issue}
        action={action}
        callPartnerAction={callPartnerAction}
      />
    );
  }

  //  Function to render actions
  const renderActions = () => {
    if (issue && issue.status === RESOLVED_STATUS) return null;

    if (issue?.issue_type === issueTypes.ALERT) return null;

    return partnerActions?.issues?.map((action) => {
      if (task && !action.task) return null;

      const { type } = action;
      switch (type) {
        case MANUAL_ACTIONS_COMPONENTS.DROPDOWN:
          return renderDropdownAction(action);
        case MANUAL_ACTIONS_COMPONENTS.BUTTON:
          return renderButtonActions(action);
        case MANUAL_ACTIONS_COMPONENTS.MODAL:
          return renderModalActions(action);
        default:
          return null
      }
    });
  };

  //  Rendering empty state
  if (!partnerActions?.issues || partnerActions?.issues?.length === 0) { return null; }

  //  Rendering
  return (
    <>
      {renderActions()}
    </>
  );
};

export default PartnerActions;
