import React from 'react';
import SelectableTextCell from './SelectableTextCell';

const CustomTextCell = (props) => {
  //  Parsing props
  const { group, value = '', suffix = '' } = props;

  //  Rendering
  return (
    <SelectableTextCell value={`${value}${suffix}`} />
  );
};

export default CustomTextCell;
