// Import scss
import './assets/scss/theme.scss';
import React, { useEffect } from 'react'
import { Switch, Route, useLocation } from "react-router-dom";
// Import Routes all
import { publicRoutes, privateRoutes } from './routes/allRoutes';
import { authUser } from './actions/auth';
import setAuthToken from './utils/axios/setAuthToken';
import PrivateRoute from './routes/PrivateRoute';
import Layout from './components/layout/Layout';
import PageNotFound from './views/PageNotFound';
import Package from "../package.json";
import { whitelistedRoutes } from './constants/navigation';
import useMemoizedDispatch from './hooks/useMemoizedDispatch';

if (localStorage.token && localStorage.token !== 'undefined') {
  setAuthToken(localStorage.token)
}

const App = (props) => {
  //  Memoized Dispatch
  const { dispatch } = useMemoizedDispatch();

  //  Getting router location
  const location = useLocation();

  //  Authenticating user
  useEffect(() => {
    console.log("app version: ", Package.version || '');
    //  Verifying white listed routes on app
    const { pathname } = location;
    const matchingRoute = whitelistedRoutes.find((whitelistedRoute) => pathname.includes(whitelistedRoute));
    if (matchingRoute) { return; }
    //  Verifying authorization
    dispatch(authUser());
  }, [authUser])

  //  Rendering
  return (
    <React.Fragment>
      <Layout>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Route
              exact
              path={route.path}
              component={route.component}
              key={`public-${idx}`}
            />
          ))}
          {privateRoutes.map((route, idx) => (
            <PrivateRoute
              exact={!route.hasSubroutes}
              path={route.path}
              component={route.component}
              key={`priv-${idx}`}
              access={route.access}
              resource={route.resource}
              requireMFA={route.requireMFA}
            />
          ))}
          <Route component={PageNotFound} />
        </Switch>
      </Layout>
    </React.Fragment>
  );
};

export default App;
