import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import criticalityConfig from "../constants/criticality";
import { issueTypes } from "../constants/issues";
import issuesInfoSelector from "../selectors/issuesInfoSelector";

const useIssueCriticalityClassName = (params) => {
  const {
    criticalityId,
    issueType = issueTypes.VULNERABILITIES,
  } = params;

  // Getting needed info from redux store
  const options = useSelector((state) => issuesInfoSelector(state, 'modalOptions', issueType));

  // Hook state
  const [criticalityClassName, setCriticalityClassName] = useState(null);

  //  Watching criticality to change criticality classname
  useEffect(() => {
    const className = criticalityId ? criticalityConfig[criticalityId]?.className : '';
    setCriticalityClassName(className);
  }, [criticalityId, options.criticalities]);

  return {
    criticalityClassName,
    setCriticalityClassName,
  }
}
export default useIssueCriticalityClassName;
