import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import { Modal, Col, Row } from 'reactstrap';
import LoadingContent from '../../layout/LoadingContent';
import gradeConfig from '../../../constants/grades';
import amplitude from 'amplitude-js';

const GradingScale = (props) => {
    const {
        showModal,
        togModal,
        grades,
        isLoading,
        errorMessage,
        retryRequest,
        amplitudeEvent
    } = props;

    const closeModalRef = useRef();

    useEffect(() => {
        if(amplitudeEvent) amplitude.getInstance().logEvent(amplitudeEvent);
    }, []);

    useEffect(() => {
        if (showModal) {
            document.body.classList.remove("no_padding");
        } else {
            document.body.classList.add("no_padding");
        }
        let focusTimer = setTimeout(() => { if (closeModalRef.current && showModal) closeModalRef.current.focus() }, 1000);
        return () => {
            clearTimeout(focusTimer);
        }
    }, [showModal]);

    return (
        <Modal
            isOpen={showModal}
            toggle={() => { togModal() }}
            centered={true}
            size="xl"
            className="grading-scale"
        >
            <div>
                <div className="modal-header pb-0 px-4">
                    <div className="modal-title mx-auto mb-3 w-100">Grading Scale</div>
                    <button
                        type="button"
                        onClick={() => { togModal() }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        ref={closeModalRef}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="content">
                    <LoadingContent
                        isLoading={isLoading}
                        errorMessage={errorMessage}
                        errorButtonCallback={retryRequest}
                    >
                        {Object.values(_.orderBy(grades, 'max', 'desc')).map((element) => {
                            const { id, grade, title, intro, info } = element;
                            return (
                                <Row key={id} >
                                    <Col md={3} lg={2} >
                                        <div
                                            className={`grade-circle d-inline-flex justify-content-center align-items-center rounded-circle border-dark text-dark bg-${gradeConfig[grade]?.className}`}
                                        >
                                            {grade}
                                        </div>
                                    </Col>
                                    <Col
                                        md={9} lg={10}
                                        className="grading-breakdown d-inline-block normal-text-style"
                                    >
                                        <div className="d-block title mb-2">{title}</div>
                                        <div className="info">
                                            <span className="font-weight-bolder">{intro}</span>{' '}{info}
                                        </div>
                                    </Col>
                                </Row>
                            );
                        })}
                    </LoadingContent>
                </div>
            </div>
        </Modal>
    )
}

export default GradingScale
