import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useCustomQuery from '../../../hooks/useCustomQuery';
import companyIdSelector from '../../../selectors/companyIdSelector';
import SimpleDropdown from '../../layout/SimpleDropdown';
import API from '../../../api';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import { setAlert } from '../../../actions/alerts';
import Avatar from '../../layout/avatars/Avatar';

const UserManagerAssigneesDropdown = (props) => {

  // Dropdown for delete and disable actions in user manager
  const {
    selectedIds,
    setAssignee = () => {},
  } = props;

  //  Initializing API
  const { CompanyAPI } = API;

  // Getting needed info from redux store
  const companyId = useSelector(state => companyIdSelector(state));

  //  Component state
  const [ selectedUser, setSelectedUser ] = useState();
  const [ allUsers, setAllUsers ] = useState();

  const { dispatch } = useMemoizedDispatch();

  //  Hook to make API query
  const {
    data: usersData,
    error: usersError,
    isLoading: usersLoading,
  } = useCustomQuery(
    [CompanyAPI.keys.getCompanyUsers, { filters : {}, company_id: companyId }],
    ({ queryKey }) => CompanyAPI.handlers.getCompanyUsers(queryKey[1]),
  );

  useEffect(() => {
    if (!usersData) return;
    const filteredArray = getFilteredUsers();
    setAllUsers(filteredArray);
  }, [usersData]);

  useEffect(() => {
    if (!usersError) return;
    dispatch(setAlert('Error getting users.', 'danger'));
  }, [usersError]);

  useEffect(() => {
    setAssignee(selectedUser);
  }, [selectedUser]);

  //  Function to handle dropdown change
  const onDropdownChange = (option) => {
    if (!option) { return; }
    setSelectedUser(option);
  };

  //  Function to render dropdown user options
  const renderUserOption = (user) => {
    const { full_name } = user;
    return (
      <div className="d-inline-flex align-items-center">
        <Avatar user={user} customClassName="user-option filter-option" />
        <div className="assignee-name">{full_name}</div>
      </div>
    );
  };

  // Filter out the currently selected, not actives and not verified user(s) from the list of ALL users
  const getFilteredUsers = () => {
    return usersData?.filter( ({id, active, verified}) => !selectedIds?.includes(id) && active && verified);
  };

  return (
    <SimpleDropdown
      extraClassName={`dropdown-filter action-dropdown`}
      placeholder="Members"
      elements={allUsers?.map(({ id, full_name }) => ({ value: id, label: full_name }))}
      onChangeCallback={onDropdownChange}
      selectedElement={selectedUser}
      isLoading={usersLoading}
      formatOptionLabel={({ label, value }) => {
        if (!value) return label;
        const userOption = allUsers.find((user) => user.id === value);
        if (!userOption) return label;
        return renderUserOption(userOption);
      }}
    />
  );
}

export default UserManagerAssigneesDropdown;