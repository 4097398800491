import cyvatarLogo from '../assets/images/logos/cyvatar-beta-logo.svg';
//import homeBannerLogo from '../assets/images/redesign/home-banner-logo.svg';
import homeGRCBannerLogo from '../assets/images/redesign/home-grc-banner-logo.svg'
import myCysoBannerLogo from '../assets/images/redesign/mycyso-banner-logo.svg';
import loginImage from '../assets/images/isometric-images-2.png';
import { viewEntries } from './common';

export const freemiumLogos = [
  {
    src: cyvatarLogo,
    alt: 'cyvatar',
    className: 'freemium-information-logo',
    containerClassName: 'freemium-information-logo-container',
    style: { height: "42px" },
    onClick: () => { window.open(process.env.REACT_APP_CYVATAR_WEBSITE, '_blank'); },
  }
];

//  Template for logos on freemium banner
export const freemiumBannerLogos = {
  [viewEntries.MYCYSO]: myCysoBannerLogo,
  [viewEntries.HOME]: homeGRCBannerLogo,
};

export const tokenInvitesLogos = [
  {
    src: cyvatarLogo,
    alt: 'cyvatar',
    className: 'img-fluid',
    containerClassName: 'justify-content-center',
    style: { height: "42px", marginBottom: "31px", marginTop: '5%', cursor: 'pointer' },
    onClick: () => window.open(process.env.REACT_APP_CYVATAR_WEBSITE, '_blank'),
  },
  {
    src: loginImage,
    alt: '',
    className: 'img-fluid',
    containerClassName: 'justify-content-end d-none d-lg-block',
    style: { maxHeight: "501px" },
  },
];
