import moment from 'moment';
import { connectionQuickActionsConfig, CONNECTION_DELETE_ACTION, CONNECTION_FORCE_SYNC_ACTION } from '../constants/common';

//  Function to initialize connection status config
export const initializeConnectionStatusConfig = (page, per_page, text_to_search, sortField, filters) => {
  return {
    page,
    per_page,
    filters,
    text_to_search,
    sort: sortField.name ? {
      [sortField.name]: sortField.asc ? 'ASC' : 'DESC'
    } : null
  }
};

export const getLatestSyncDate = (latest_sync_date) => {
  const formattedDate = moment(new Date(latest_sync_date)).format('LLL');
  return latest_sync_date && formattedDate ? formattedDate : ' - '
}

const getConnectionSuccessActionLabel = (actionName) => {
  switch(actionName) {
    case CONNECTION_FORCE_SYNC_ACTION: {
      return 'forced'
    }
    case CONNECTION_DELETE_ACTION: {
      return 'deleted'
    }
    default: {
      return 'updated'
    }
  }
}

export const getQuickActionSuccessMessage = (actionName, count) => {
  const objectName =  connectionQuickActionsConfig[actionName]?.objectName || 'Connection';
  const objectLabel = `${objectName}${count > 1 ? 's': ''}`
  const actionLabel = getConnectionSuccessActionLabel(actionName);
  return `${objectLabel} ${actionLabel}`;
}

