import React from 'react';
import { ruleLogStatus } from '../../../constants/automation';

const LogResultCell = (props) => {
  //  Parsing props
  const { result = ruleLogStatus.SUCCESS } = props;

  //  Function to get extra class name
  const getExtraClassName = () => {
    if (result === ruleLogStatus.SUCCESS) { return 'success'; }
    if (result === ruleLogStatus.ERROR) { return 'error'; }
    if (result === ruleLogStatus.PROGRESS) { return 'in-progress'; }
    return '';
  };

  //  Function to get label
  const getLabel = () => {
    if (result === ruleLogStatus.SUCCESS) { return 'Success'; }
    if (result === ruleLogStatus.ERROR) { return 'Error'; }
    if (result === ruleLogStatus.PROGRESS) { return 'In Progress'; }
    return '';
  }
  //  Rendering
  return (
    <div className={`result-cell ${getExtraClassName()}`}>
      <div className="result">{getLabel()}</div>
    </div>
  );
};

export default LogResultCell;
