import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { issueTypes } from '../../../constants/issues';
import issuesInfoSelector from '../../../selectors/issuesInfoSelector';
import FiltersButtonGroup from '../../layout/filters/FiltersButtonGroup';

const IssuesCriticalityFilters = (props) => {
    const {
        filterValue,
        setFilterCallback,
        wrapperClassName,
        showNumber,
        optionsFilterFunction,
        useCriticalityClassName = true,
        issueType = issueTypes.VULNERABILITIES
    } = props;

    const defaultOptionsFilter = ['hidden', false];
    //  Getting needed info from redux store
    const criticalities = useSelector(state => issuesInfoSelector(state, 'modalOptions', issueType)?.criticalities);
    const totalByCriticality = useSelector(state => issuesInfoSelector(state, 'totalsBy', issueType)?.criticality);

    const mapCriticalityFilters = () => {
        const criticalityArray = showNumber ? totalByCriticality : criticalities;
        return _.filter(criticalityArray, optionsFilterFunction || defaultOptionsFilter).map(({ label, className, id, total }) => {
            return {
                label: `${label}`,
                className: useCriticalityClassName ? className : 'light-gray',
                value: id,
                number: total || 0
            }
        })
    }

    const onFilterClick = (filter) => {
        if (!filter) {
            setFilterCallback(filter)
        } else {
            setFilterCallback({
                ...filter,
                label: `Open, ${filter?.label}`
            })
        }
    }
    return (
        <div tabIndex="0" aria-label={`Filter open issues by criticality`}>
            <FiltersButtonGroup
                filterValue={filterValue}
                data={mapCriticalityFilters()}
                callback={onFilterClick}
                showNumber={showNumber}
                wrapperClassName={wrapperClassName}
            />
        </div>
    )
}

export default IssuesCriticalityFilters;
