import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Button } from 'reactstrap';
import API from '../../api';
import filterTypes from '../../constants/filterTypes';
import errorMessages from '../../constants/errorMessages';
import companyIdSelector from '../../selectors/companyIdSelector';
import IconFilterButton from '../layout/filters/IconFilterButton';
import LoadingContent from '../layout/LoadingContent';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import { clearDevicesFilters, setDevicesMultipleFilterGroup } from '../../actions/devices';
import useCustomQuery from '../../hooks/useCustomQuery';
import { formatPartnerName } from '../../utils/partnerHelpers';
import { getSafeArray, isValidFunction } from '../../utils/helpers';
import { useEffect } from 'react';

const DevicesPartnerFilters = (props) => {
    //  Initializing APIs
    const { DeviceAPI } = API;

    const {
        setSelectedPartner,
    } = props;

    // Getting needed info from redux store
    const companyId = useSelector(state => companyIdSelector(state));
    const partnerFilter = useSelector(state => state.devices?.filters?.partner_id)

    // Hooks
    const { dispatch } = useMemoizedDispatch();

    // Call api to get active partners by company
    const {
        data: devicesByPartnerResponse,
        error: devicesByPartnerError,
        isLoading: devicesByPartnerLoading,
        refetch: refetchPartners,
    } = useCustomQuery(
        [DeviceAPI.keys.getDevicesByPartnerCount, companyId],
        ({ queryKey }) => DeviceAPI.handlers.getDevicesByPartnerCount(queryKey[1])
    )

    // Watch devices by partner response to reset filter if needed
    useEffect(() => {
        if(!devicesByPartnerResponse) return;
        const partnersList = getSafeArray(devicesByPartnerResponse?.partners);
        if(partnersList.length < 1) {
            // Reset partner filter
            dispatch(setDevicesMultipleFilterGroup('partner_id', [], [], false))
            setDevicePartner(0, null)
            return;
        }
        const selectedId = partnerFilter?.length > 0 ? partnerFilter[0] : null;
        const selectedPartner = selectedId ? getPartnerById(partnersList, selectedId) : null;

        if(!selectedPartner) {
            // Set first partner if the current partner doesn't match
            const partner = partnersList[0];
            const label = formatPartnerName(partner?.name);
            setPartnerFilter(partner?.id, label)
            setDevicePartner(partner?.devices_count, label)
        } else {
            setDevicePartner(selectedPartner?.devices_count, formatPartnerName(selectedPartner?.name))
        }
    }, [devicesByPartnerResponse?.partners]);

    // Get partner by id
    const getPartnerById = (partnersList, partnerId) => {
        return getSafeArray(partnersList).find((element) => element?.id === partnerId);
    }

    // Set device count and name by partner
    const setDevicePartner = (count, name) => {
        if(isValidFunction(setSelectedPartner)) {
            setSelectedPartner({ count, name })
        }
    }

    // Set partner filter
    const setPartnerFilter = (value, label) => {
        const filterName = 'partner_id';
        dispatch(setDevicesMultipleFilterGroup(filterName, [value], [], false))
    }

    // Handle retry request
    const retryRequest = () => {
        if(devicesByPartnerError) {
            refetchPartners();
        }
    }

    // Get device by partner error message
    const getDevicesByPartnerCountErrorMessage = () => {
        const error = devicesByPartnerError?.data?.errorMessage || errorMessages.defaultGet;
        return devicesByPartnerError ? error : null
    }

    // Handle partner filter click
    const onFilterClick = (value, label) => {
        setPartnerFilter(value, label);
        const partner = getPartnerById(devicesByPartnerResponse?.partners, value)
        setDevicePartner(partner?.devices_count || 0, label)
    }

    // Map partner filters
    const mapPartnerFilters = () => {
        if(!devicesByPartnerResponse?.partners || !Array.isArray(devicesByPartnerResponse?.partners)) return null;
        return devicesByPartnerResponse?.partners.map((partner, index) => {
            const { name, id, devices_count } = partner;
            const iconSrc = `${process.env.REACT_APP_PUBLIC_RESOURCES}/partners/${name?.replace(" ","-")?.toLowerCase()}.svg`;
            const label = formatPartnerName(name);
            return (
                <Col
                    key={`device-partner-filter-${index}`}
                    md={6}
                    lg={4}
                    className="px-1 py-1"
                >
                    <IconFilterButton
                        label={label}
                        activeValue={id}
                        currentValue={getSafeArray(partnerFilter)}
                        className="w-100"
                        callback={onFilterClick}
                        filterType={filterTypes.multiple}
                        count={devices_count}
                        icon={iconSrc}
                    />
                </Col>
            )
        })

    }

    // Handle on clear all filters click
    const onClearAllClick = () => {
        dispatch(clearDevicesFilters());
    }

    // Render clear all filters button
    const renderClearButton = () => {
        if(partnerFilter === null || partnerFilter?.length < 1) return null;
        return (
            <Col
                md={6}
                lg={4}
                className="d-flex align-items-center px-1 py-1"
            >
                <Button
                    className="btn-small btn-outline-light-gray btn-clear-all"
                    outline
                    onClick={onClearAllClick}
                >
                 <span className="mr-2">X</span>Clear Filters
                </Button>
            </Col>

        )
    }

    // Render partner filters
    const renderPartnerFilters = () => {
        return (
            <Row className="partner-filters-row">
                {mapPartnerFilters()}
            </Row>
        )
    }

    return (
        <div
            className="devices-partner-filters-container"
        >
            <LoadingContent
                isLoading={devicesByPartnerLoading}
                errorMessage={getDevicesByPartnerCountErrorMessage()}
                retryRequest={retryRequest}
            >
                {renderPartnerFilters()}
            </LoadingContent>
        </div>
    )
}

export default DevicesPartnerFilters;
