import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'reactstrap';
import ScheduleManager from './ScheduleManager';
import { scheduleTabs } from '../../../constants/automation';
import { setAlert } from '../../../actions/alerts';
import { onRuleScheduleChange } from '../../../actions/ruleSet';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';

const ScheduleModal = (props) => {
  //  Parsing props
  const {
    ruleSetIndex,
    actionIndex,
    schedule,
    repeat,
    run_now = false,
    closeModal,
  } = props;

  //  Component state
  const [tabs, setTabs] = useState(scheduleTabs);
  const [selectedTab, setSelectedTab] = useState(null);
  const [currentRepeat, setCurrentRepeat] = useState(repeat);
  const [currentSchedule, setCurrentSchedule] = useState(schedule);
  const [runNow, setRunNow] = useState(run_now);

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();

  //  Watching tabs changes
  useEffect(() => {
    setSelectedTab(tabs[0]);
  }, [tabs]);

  //  Function to change selected tab
  const changeTab = (newTab) => {
    setSelectedTab(newTab);
  };

  //  Function handle cancel
  const handleCancel = () => {
    closeModal();
  };

  //  Function to handle save 
  const handleSave = () => {
    if (!currentSchedule) { dispatch(setAlert('Need at least a start time. ', 'danger')); return; }
    dispatch(onRuleScheduleChange(ruleSetIndex, actionIndex, currentSchedule, currentRepeat, runNow));
    closeModal();
  };

  //  Function to render title row
  const renderTitleRow = () => {
    return (
      <div className="schedule-title-row">
        <div className="schedule-title">
          Schedule action
        </div>
        <div
          className="bx bx-x close-schedule-icon"
          onClick={() => handleCancel()}
        />
      </div>
    );
  };

  //  Rendering tabs section
  const renderTabs = () => {
    return tabs.map((currentTab) => {
      const { id, label } = currentTab;
      return (
        <div
          key={id}
          className={`schedule-tab${id === selectedTab?.id ? ' active' : ''}`}
          onClick={() => { changeTab(currentTab) }}
        >
          {label}
        </div>
      );
    });
  }

  //  Function to render footer
  const renderFooter = () => {
    return (
      <div className="schedule-footer">
        <Button
          className="cancel-button"
          outline
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
        <Button
          className="save-button"
          onClick={() => handleSave()}
        >
          Schedule
        </Button>
      </div>
    );
  };

  //  Function to render content
  const renderContent = () => {
    if (selectedTab?.id === 0) {
      return (
        <ScheduleManager
          repeat={currentRepeat}
          schedule={currentSchedule}
          runNow={runNow}
          changeRepeat={setCurrentRepeat}
          changeSchedule={setCurrentSchedule}
          setRunNow={setRunNow}
        />
      );
    }
  }

  //  Rendering
  return (
    <Modal
      isOpen={true}
      toggle={closeModal}
      size="xl"
      wrapClassName="modal-wrapper"
    >
      <div className="schedule-container">
        <div className="schedule-body">
          {renderTitleRow()}
          <div className="schedule-content">
            <div className="tabs-container">
              {renderTabs()}
            </div>
            {renderContent()}
          </div>
        </div>
        {renderFooter()}
      </div>
    </Modal>
  );
};

export default ScheduleModal;
