import { 
    FURTIM_OVERALL_SCORE_SUCCESS
} from '../actions/types';

//TODO add to FURTIM reducer

const initialState = {
    score: null
}

export default (state = initialState, action) => {
    const { type, payload } = action ; 
    switch(type){
        case FURTIM_OVERALL_SCORE_SUCCESS:
            return {
                ...state,
                score: payload
            }
        default:
            return state
    }
}
