import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Label, Button, Table, Input } from 'reactstrap';
import _ from 'lodash';

import { UilAngleUp, UilAngleDown } from '@iconscout/react-unicons'
import BasicModal from "../layout/BasicModal";
import Avatar from "../layout/avatars/Avatar";
import Tabs from '../layout/tabs/Tabs';
import TextArea from '../profile/TextArea';
import useCustomMutation from '../../hooks/useCustomMutation';
import { setAlert } from '../../actions/alerts';
import { updatePartnerExclusions } from '../../actions/partners'
import API from '../../api';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useCanAbility from '../../hooks/useCanAbility';
import { MODIFY_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';

const PartnerExclusion = (props) => {
  const {
    showModal,
    toggleModal,
    selectedPartner
  } = props;

  //  Initializing APIs
  const { PartnerAPI } = API;

  // Component Hooks
  const { dispatch } = useMemoizedDispatch();
  const ability = useCanAbility();

  const user = useSelector((state) => state.auth.user);

  const [partner, setCurrentPartner] = useState(selectedPartner);
  const [exclusions, setExclusions] = useState(selectedPartner?.exclusions || []);
  const [newExclusionId, setExclusionId] = useState('');
  const [selectedExclusion, setSelectedExclusion] = useState(null);
  const [sortField, setSort] = useState({ name: "value", asc: true });

  // Calling API Add partner tag
  const {
    data: addExclusionResponse,
    error: exclusionError,
    mutate: addExclusionRule,
  } = useCustomMutation(
    (param) => PartnerAPI.handlers.addExclusionRule(param),
    PartnerAPI.invalidators.addExclusionRule
  );

  // Calling API update partner
  const {
    data: removeExclusionResponse,
    error: removeExclusionError,
    mutate: removeExclusion,
  } = useCustomMutation(
    (params) => PartnerAPI.handlers.removeExclusionRule(params),
    PartnerAPI.invalidators.removeExclusionRule
  );

  // Add exclusion error
  useEffect(() => {
    if (exclusionError) {
      const { data } = exclusionError;
      const { errorMessage } = data || { errorMessage: 'Error trying to add the exclusion' };
      dispatch(setAlert(errorMessage, 'danger'));
    }
  }, [exclusionError]);

  // Add exclusion response
  useEffect(() => {
    if (addExclusionResponse) {
      let updatedExclusion = [...exclusions, addExclusionResponse.exclusion];
      updatedExclusion = sortExclusions(updatedExclusion);
      const message = addExclusionResponse.message || 'New exclusion created';
      setExclusionId('');
      handleUpdatePartnerState(updatedExclusion, message);
    }
  }, [addExclusionResponse]);

  // Remove exclusion error
  useEffect(() => {
    if (removeExclusionError) {
      const { data } = removeExclusionError;
      const { errorMessage } = data || { errorMessage: 'Error trying to remove the exclusion rule.' };
      dispatch(setAlert(errorMessage, 'danger'));
    }
  }, [removeExclusionError]);

  // Remove exclusion response
  useEffect(() => {
    if (removeExclusionResponse) {
      const exclusionRules = exclusions.filter(_e => _e.id != selectedExclusion.id);
      const message = removeExclusionResponse.message || 'Exclusion removed';
      handleUpdatePartnerState(exclusionRules, message);
    }
  }, [removeExclusionResponse]);

  // Update sort order
  useEffect(() => {
    if (sortField) {
      const sorted = sortExclusions(exclusions);
      setExclusions(sorted);
    }
  }, [sortField]);

  const columns = [
    {
      displayName: "Exclusions",
      sortFieldName: 'value',
    },
    {
      displayName: "Created by",
      sortFieldName: 'user',
    },
    { displayName: "" }
  ]

  // Update partner state
  const handleUpdatePartnerState = (exclusionRules, message) => {
    const updatedPartner = { ...partner, exclusions: exclusionRules };

    setExclusions(exclusionRules);
    setSelectedExclusion(null);
    dispatch(updatePartnerExclusions(updatedPartner));
    dispatch(setAlert(message || 'Exclusions updated.', 'success'));
  }

  // API call: add exclusion
  const handleAddExclusionRule = (e) => {
    const exclutionToAdd = {
      user_id: user.id,
      value: newExclusionId,
      partner_id: partner.id
    };
    addExclusionRule(exclutionToAdd);
  }

  // API call: remove exclusion
  const handleRemoveExclusionRule = (exclusion) => {
    setSelectedExclusion(exclusion);
    removeExclusion({ exclusion_id: exclusion.id, partner_id: exclusion.partner_id });
  }

  // Component logic
  const sortExclusions = (exclusionsToSort) => {
    if (!sortField) return exclusionsToSort;

    const sorted = _.sortBy((exclusionsToSort), [sortField.name]);
    if (!sortField.asc) return sorted.reverse();
    return sorted;
  }

  const validateExclusionValue = () => {
    if (newExclusionId === '') return true;
    const index = exclusions.findIndex(_exclusion => _exclusion.value.toLowerCase() === (newExclusionId).toLowerCase());
    return index !== -1
  }

  const closeModal = () => {
    toggleModal(!showModal);
  }

  const renderInfoField = (label, inputValue, key, field) => {
    const value = inputValue;
    return (
      <div className="col info-group" key={key}>
        <Label>{label || "-"}</Label>
        <TextArea
          className={`form-control text-area-grow dark read-only`}
          autoComplete="off"
          rows={1}
          minRows={1}
          maxRows={3}
          value={value || "-"}
          disabled={true}
        />
      </div>
    )
  }

  const renderPartnerInfo = () => {
    return (
      <div className="row">
        {renderInfoField('Partner Name', partner?.name)}
        {renderInfoField('Solution', partner?.solutions[0]?.name)}
        {renderInfoField('Installed for', `${partner?.members || 0} member${partner?.members > 0 ? 's' : ''}`)}
      </div>
    )
  }

  const renderInputTabs = () => {
    const exclusionsSuffix = exclusions.length > 0 ? `(${exclusions.length})` : '';
    const tabs = [{
      name: `Issue Exclusions ${exclusionsSuffix}`,
      content: renderExclusions()
    }];
    return <Tabs tabs={tabs} />;
  }

  const renderExclusions = () => {
    const canModifyPartners = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.PARTNERS.GENERAL);
    return (
      <>
        <div className='exclusion-input-container'>
          <div className="input-row">
            <div>{"Exclude this partner ID issue"}</div>
            <div className={'id-input-container'}>
              <Input
                className={'id-input dark'}
                type="text"
                placeholder={'enter ID'}
                value={newExclusionId}
                onChange={e => setExclusionId(e.target.value)}
                disabled={!canModifyPartners}
              />
            </div>
          </div>
          <div className="action-container">
            <Button
              className={`btn btn-light text-dark font-weight-bold ${validateExclusionValue() === '' ? 'disabled' : ''}`}
              disabled={validateExclusionValue()}
              onClick={handleAddExclusionRule}
            >
              Apply
            </Button>
          </div>
        </div>
        <div className="table-list">
          <div className="table-responsive table-fix-head">
            <Table className={'table-centered table-hover pointer partner-exclusions'} borderless>
              <thead>
                <tr>{renderHeaders()}</tr>
              </thead>
              <tbody>{renderRows()}</tbody>
            </Table>
          </div>
        </div>

      </>
    )
  }

  const renderHeaders = () => {
    return columns.map((column, index) => {
      return (
        <th key={`${column.sortFieldName}-${index}`}>
          <div className={`dashboard ${index === 1 ? 'container-center' : ''}`}>
            {!_.isNil(column.sortFieldName) ? (
              <Button
                color="link"
                className='btn-sort-arrows p-0 d-flex align-items-center'
                onClick={() => {
                  setSort({ name: column.sortFieldName, asc: column.sortFieldName !== sortField?.name ? true : !sortField?.asc })
                }}
              >
                {column.displayName}
                <div className="ml-1">
                  <div className={`sort-arrow ${sortField?.name === column.sortFieldName && sortField?.asc && 'active'}`}>
                    <UilAngleUp />
                  </div>
                  <div className={`sort-arrow ${sortField?.name === column.sortFieldName && !sortField?.asc && 'active'}`}>
                    <UilAngleDown />
                  </div>
                </div>
              </Button>
            ) : column.displayName}
          </div>
        </th>
      )
    })
  }
  const renderRows = () => {
    return (
      <>
        {exclusions.map((_exclusion, index) => {
          return (
            <tr key={`exclusion-${index}`}>
              <td>{`Exclude this partner ID issue ${_exclusion.value}`}</td>
              <td>
                <div className="container-center">
                  <Avatar key={`cydekick-name-${index}`} id={`user-tooltip-${_exclusion.user?.full_name || user?.full_name}`} user={_exclusion?.user || user} />
                </div>
              </td>
              <td>
                <button type="button" onClick={() => { handleRemoveExclusionRule(_exclusion) }} className="close" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </td>
            </tr>
          )
        })}
      </>
    )
  }

  return (
    <BasicModal
      headerClassName={'modal-header-status'}
      showModal={showModal}
      toggleModal={() => closeModal()}
      customClassName="partner-exclusions"
      scrollable={true}
    >
      {partner && renderPartnerInfo()}
      {renderInputTabs()}
    </BasicModal>
  )
}

export default PartnerExclusion;
