import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { setAlert } from '../../../actions/alerts';
import API from '../../../api';
import { customTableEvents, tablePaginationTypes } from '../../../constants/common';
import { userManagerTableTemplate } from '../../../constants/userManager';
import useCustomQuery from '../../../hooks/useCustomQuery';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import useSelectedList from '../../../hooks/useSelectedList';
import useShowModal from '../../../hooks/useShowModal';
import companyIdSelector from '../../../selectors/companyIdSelector';
import { mapObjSortToArray } from '../../../utils/helpers';
import { getSelectedUsers } from '../../../utils/profileHelpers';
import { getSelectedIds } from '../../../utils/recycleBinHelpers';
import CustomTable from '../../CustomTable/CustomTable';
import CompanyMemberInvite from '../CompanyMemberInvite';
import UserManagerBulkMenu from './UserManagerBulkMenu';

//  Initial filters
const initialFilters = {
  sort: null,
  text_to_search: '',
};

const UserManagement = (props) => {

  const {
    title = ''
  } = props;

  //  Initializing API
  const { CompanyAPI } = API;

  // Getting needed info from redux store
  const companyId = useSelector(state => companyIdSelector(state));

  //  Component state
  const [filters, setFilters] = useState(initialFilters);
  const [elements, setElements] = useState([]);

  const { dispatch } = useMemoizedDispatch();

  // Hooks
  const {
    showModal: showAddMemberModal,
    toggleModal: toggleAddMemberModal,
  } = useShowModal();

  const {
    selected,
    excluded,
    allSelected,
    clearSelected,
    eventHandler: handleSelectedEvent,
  } = useSelectedList({
    eventMap: {
      changeAll: customTableEvents.CHANGE_SELECT_ALL,
      add: customTableEvents.SELECT_ELEMENT,
      remove: customTableEvents.UNSELECT_ELEMENT,
    },
  });

  //  Hook to make API query
  const {
    data: usersData,
    error: usersError,
    isLoading: usersLoading,
  } = useCustomQuery(
    [CompanyAPI.keys.getCompanyUsers, { ...filters, company_id: companyId }],
    ({ queryKey }) => CompanyAPI.handlers.getCompanyUsers(queryKey[1]),
  );

  useEffect(() => {
    if (!usersData) return;
    setElements(usersData);
  },[usersData]);

  useEffect(() => {
    if (!usersError) return;
    dispatch(setAlert('Error getting users.', 'danger'));
  }, [usersError]);

  //  Watching company id and text search changes to update selected
  useEffect(() => {
    clearSelected();
  }, [companyId, filters?.text_to_search]);

  //  Function to request more elements
  const requestMore = ({ selected }) => {
    const newOffset = selected * filters?.limit;
    setFilters({ ...filters, offset: newOffset });
  };

  //  Function to change filters
  const changeFilters = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  //  Function to change sort
  const changeSort = (key, value) => {
    setFilters({ ...filters, sort: { [key]: value } });
  };

  //  Function to render add members modal
  const renderAddMembersModal = () => {
    if (!showAddMemberModal) return null;
    return (
      <CompanyMemberInvite
        closeModal={() => toggleAddMemberModal(false)}
        customerId={companyId}
      />
    );
  };

  //  Function to render top section
  const renderTableTopBar = () => {
    return (
      <div className="d-flex top-bar-content">
        <Button
          type="button"
          color="light"
          className="btn-add-new-member float-right"
          onClick={toggleAddMemberModal}
        >
          <i className="bx bx-plus-circle mr-2 align-middle"></i>
          Add new member
        </Button>
      </div>
    );
  };

  //  Function to render bulk actions menu
  const renderBulkActionsMenu = () => {
    //  Getting selected users, ids and rendering
    const users = getSelectedUsers(allSelected, selected, excluded, elements || []);
    const ids = getSelectedIds(allSelected, selected, excluded, elements || []);
    return (
      <UserManagerBulkMenu
        company_id={companyId}
        users={users}
        selected={ids}
      />
    );
  };

  return (
    <div className='user-management-container'>
      {renderTableTopBar()}
      {renderBulkActionsMenu()}
      <CustomTable
        tableClassName="user-management-table"
        headerClassName="user-management-table-header"
        rowClassName="user-management-table-row"
        columnsTemplate={userManagerTableTemplate}
        data={elements || []}
        isScrollable={true}
        requestMore={requestMore}
        changeSort={changeSort}
        sort={mapObjSortToArray(filters?.sort) || []}
        allSelected={allSelected}
        selected={selected}
        excluded={excluded}
        handleSelectedEvent={handleSelectedEvent}
        loading={usersLoading}
        emptyConfig={{
          message: `Users will appear here`,
          messageClassName: 'empty-message',
        }}
        paginationType={tablePaginationTypes.PAGES}
      />
      {renderAddMembersModal()}
    </div>
  )
}

export default UserManagement;