import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { issueGroupFilterKeysMap, issueTypes } from '../../../constants/issues';
import { useSelector } from 'react-redux';
import { getSafeArray, getUpdatedSearchParams } from '../../../utils/helpers';
import { applyIssuesAdvancedFilters } from '../../../actions/issues';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import GroupPartnerActions from '../../issue-partners/actions/GroupPartnerActions';
import { groupDetailsQueryKey } from '../../../constants/groups';

const GroupsActionsCell = (props) => {
  //  Parsing props
  const { group, group_id, rowIndex } = props;

  //  Watching redux store
  const groupsFilters = useSelector((state) => state?.groups?.filters);
  const groupActiveFilters = useSelector((state) => state?.groups?.active_filters);
  const groupsCount = useSelector((state) => state?.groups?.groupsCount);

  //  Components hooks
  const history = useHistory();
  const location = useLocation();
  const { dispatch } = useMemoizedDispatch();

  //  Handlers
  const onExpand = (groupId, index) => {
    if (!groupId) return;
    history.push({
      search: getUpdatedSearchParams(location?.search, ['details', groupDetailsQueryKey], `&details&${groupDetailsQueryKey}=${groupId}`),
      state: { index, maxIndex: groupsCount - 1}
    })
  };

  const getNewMultipleFilterValue = (key) => {
    return getSafeArray(groupsFilters[key])?.length < 1 ? null : groupsFilters[key]
  }

  const convertMultipleObjectFilters = (key) => {
    const safeFilter = getSafeArray(groupsFilters[key]);
    if(safeFilter.length < 1) return [];
    return safeFilter.reduce((elements, value) => {
      const activeFilter = getSafeArray(groupActiveFilters).find((element) => element?.key === key && element?.value === value)
      if(!activeFilter) return elements;
      return [
        ...elements,
        {
          selected: false,
          label: activeFilter?.label,
          value
        }
      ]
    }, [])
  }

  // Apply group filters to individual issues list
  const applyGroupFiltersToList = () => {
    const newFilters = {
      group_id,
      multiple_company_id: getNewMultipleFilterValue('company_id'),
      multiple_solution_id: getNewMultipleFilterValue('solution_id'),
      source: convertMultipleObjectFilters('partner_id')
    };

    const newActiveFilters = getSafeArray(groupActiveFilters).reduce((activeFilters, activeFilter) => {
      const filterConfig = issueGroupFilterKeysMap[activeFilter?.key]
      if(!filterConfig) return activeFilters;
      return [
        ...activeFilters,
        {
          ...filterConfig,
          value: activeFilter?.value,
          label: activeFilter?.label,
        }
      ]
    }, []);
    dispatch(applyIssuesAdvancedFilters(newFilters, newActiveFilters, issueTypes.GROUP_INDIVIDUAL));
  }

  const onList = () => {
    if (!group_id) { return; }
    history.push(`/groups-manager/${group_id}/issues`);
    applyGroupFiltersToList();
  };

  //  Function to render tooltip
  const renderTooltip = (target, message) => {
    return (
      <UncontrolledTooltip
        className={`custom-basic-tooltip`}
        arrowClassName={`custom-basic-tooltip-arrow`}
        placement={'top'}
        target={target}
      >
        {message}
      </UncontrolledTooltip>
    );
  };

  //  Rendering
  return (
    <div className="actions-row">
      {renderTooltip('group-expand-action', 'Expand')}
      {renderTooltip('group-list-action', 'View Issues Group')}
      <div
        id="group-expand-action"
        className='action-icon bx bx-area'
        onClick={() => onExpand(group?.public_id, rowIndex)}
      />
      <div
        id="group-list-action"
        className='action-icon bx bx-package'
        onClick={onList}
      />
      <GroupPartnerActions group={group} group_id={group_id} elementId={'group-actions-action'}/>
    </div>
  );
};

export default GroupsActionsCell;
