//  Sidebar tabs

import {
  LIST_ACTION,
  PRIVILEGE_SOURCES,
  READ_ACTION,
} from "./privileges";

// Limit to customer true means that is just visible for customers, not for cydekicks
export const sidebarTabs = [
  {
    canAttributes: { I: READ_ACTION, a: PRIVILEGE_SOURCES.HOME.GENERAL },
    path: '/dashboard',
    ariaLabel: 'Dashboard',
    label: 'Dashboard',
    iconClassName: 'bx bx-home-circle',
    limitToCustomer: false,
    freemiumInfo: null,
  },
  // TODO: add privilege for app launcher
  {
    canAttributes: { I: READ_ACTION, a: PRIVILEGE_SOURCES.MY_CYSO.GENERAL },
    path: '/app-launcher',
    ariaLabel: 'Free Tools',
    label: 'Free Tools',
    iconClassName: 'bx bx-grid-alt',
    limitToCustomer: false,
    freemiumInfo: null,
  },
];

export const cydekickOnlySideBarTabs = [
  {
    canAttributes: { I: LIST_ACTION, a: PRIVILEGE_SOURCES.COMPANY_SOLUTIONS.GENERAL },
    path: '/connection-management',
    ariaLabel: 'Connection Management',
    label: 'Connection Management',
    iconClassName: 'bx bx-broadcast',
    limitToCustomer: false,
  },
  {
    canAttributes: { I: READ_ACTION, a: PRIVILEGE_SOURCES.SIDEBAR.ISSUES_TAB },
    path: '/issues',
    ariaLabel: 'Issues',
    label: 'Issues',
    iconClassName: 'bx bx-columns',
    limitToCustomer: false,
    freemiumInfo: { className: 'text-dark freemium-tag ml-auto', label: 'Freemium' },
  },
  {
    canAttributes: { I: READ_ACTION, a: PRIVILEGE_SOURCES.ISSUES.GROUPS },
    path: '/groups-manager',
    ariaLabel: 'Issues Groups',
    label: 'Issues Groups',
    iconClassName: 'bx bx-package',
    limitToCustomer: false,
    freemiumInfo: null,
  },
  {
    canAttributes: { I: LIST_ACTION, a: PRIVILEGE_SOURCES.AUTOMATION.INTEGRATION_RULE },
    path: '/automation',
    ariaLabel: 'Automation',
    label: 'Automation',
    iconClassName: 'bx bx-bot',
    limitToCustomer: false,
    freemiumInfo: null,
  },
  {
    canAttributes: { I: LIST_ACTION, a: PRIVILEGE_SOURCES.MEMBERS.GENERAL },
    path: '/members',
    ariaLabel: 'Members',
    label: 'Members',
    iconClassName: 'bx bxs-contact',
    limitToCustomer: false,
    freemiumInfo: null,
  },
  {
    canAttributes: { I: READ_ACTION, a: PRIVILEGE_SOURCES.SIDEBAR.DEVICES_TAB },
    path: '/devices',
    ariaLabel: 'Devices',
    label: 'Devices',
    iconClassName: 'bx bx-devices',
    limitToCustomer: false,
    freemiumInfo: null,
  },
  {
    canAttributes: { I: READ_ACTION, a: PRIVILEGE_SOURCES.SIDEBAR.ASSESSMENT_TAB },
    path: '/assessment',
    ariaLabel: 'Assessment',
    label: 'Assessment',
    iconClassName: 'bx bx-calendar-check',
    limitToCustomer: false,
    freemiumInfo: { className: 'text-dark freemium-tag ml-auto freemium-condensed-tag', label: '!' },
  }, {
    canAttributes: { I: LIST_ACTION, a: PRIVILEGE_SOURCES.PARTNERS.GENERAL },
    path: '/solutions',
    ariaLabel: 'Solutions',
    label: 'Solutions',
    iconClassName: 'bx bx-briefcase-alt-2',
    limitToCustomer: false,
    freemiumInfo: null,
  }, {
    canAttributes: { I: LIST_ACTION, a: PRIVILEGE_SOURCES.CYVATAR_TEAM.GENERAL },
    path: '/team',
    ariaLabel: 'Team',
    label: 'Cyvatar Team',
    iconClassName: 'bx bx-user-pin',
    limitToCustomer: false,
    freemiumInfo: null,
  }
]

export const recycleBinTab = {
  canAttributes: { I: LIST_ACTION, a: PRIVILEGE_SOURCES.ISSUES.GENERAL },
  path: '/recycle-bin',
  ariaLabel: 'Recycle Bin',
  label: 'Recycle Bin',
  iconClassName: 'bx bx-trash',
  limitToCustomer: false,
  freemiumInfo: null,
};

//  Subtabs ids
export const subTabsIdentifiers = {
  home: {
    HOME: 0
  },
  app_launcher: {
    APP_LAUNCHER: 0
  },
  myCyso: {
    ALL: 0
  },
  dashboard: {
    CYDEKICKS_RESULTS: 0,
    COMPLIANCE_JOURNEY: 1,
  },
  vcyso: {
    BUSINESS_RISK: 0,
    TRUST_BUILDER: 1,
    POLICIES: 2,
  },
  issues: {
    ISSUES: 0,
  },
  devices: {
    DEVICES: 0,
  },
  profile: {
    COMPANY_PROFILE: 0,
    SECURITY_SETTINGS: 1,
    NOTIFICATION_SETTINGS: 2,
    SETTINGS: 3,
    USER_MANAGEMENT: 4,
  },
  assessment: {
    ASSESSMENT: 0,
  },
  members: {
    MEMBERS: 0,
  },
  team: {
    TEAM: 0,
  },
  automation: {
    RULES: 0,
    TAG_MANAGER: 1,
  },
  subscription: {
    SUBSCRIPTION_MANAGER: 0,
    BILLING_AND_PAYMENTS: 1,
  },
  businessRisk: {
    BUSINESS_RISK: 0
  },
  policies: {
    POLICIES: 0
  },
  compliance: {
    COMPLIANCE_JOURNEY: 0,
  },
  task_manager: {
    TASK_MANAGER: 0,
  },
  recycle_bin: {
    DEVICES: 0,
    ISSUES: 1,
    TASKS: 2,
  },
  connection_status: {
    CONNECTION_STATUS: 0.
  },
  groups_manager: {
    GROUPS_MANAGER: 0,
  }
};

// ***************************** SUB TABS ***********************************
// Limit to customer true means that is just visible for customers, not for cydekicks
//  Freemium index is the order the tabs should follow for freemium accounts

//  Dashboard subtabs
const dashboardSubtabs = [
  { identifier: subTabsIdentifiers.home.HOME, label: 'Dashboard', limitToCustomer: false, freemiumIndex: 0, path: '/dashboard' },
];

//  App Launcher subtabs
const appLauncherSubtabs = [
  { identifier: subTabsIdentifiers.app_launcher.APP_LAUNCHER, label: 'App Launcher', limitToCustomer: false, freemiumIndex: 0, path: '/app-launcher' },
];

//  vCySO subtabs
const vCySOSubtabs = [
  { identifier: subTabsIdentifiers.vcyso.BUSINESS_RISK, label: 'Risk Manager', limitToCustomer: false, freemiumIndex: 0, path: '/cyso/business-risk' },
  { identifier: subTabsIdentifiers.vcyso.TRUST_BUILDER, label: 'Trust Builder', limitToCustomer: false, freemiumIndex: 1, path: '/cyso/trust-builder' },
  { identifier: subTabsIdentifiers.vcyso.POLICIES, label: 'Policies', limitToCustomer: false, freemiumIndex: 2, path: '/cyso/policies' },
];

//  Devices subtabs
const devicesSubtabs = [
  { identifier: subTabsIdentifiers.devices.DEVICES, label: 'Devices', limitToCustomer: false, freemiumIndex: 0 },
];

//  Profile subtabs
const profileSubtabs = [
  { identifier: subTabsIdentifiers.profile.COMPANY_PROFILE, label: 'Member Profile', limitToCustomer: true, freemiumIndex: 0, path: '/profile/company' },
  { identifier: subTabsIdentifiers.profile.SECURITY_SETTINGS, label: 'Security Settings', limitToCustomer: false, freemiumIndex: 1, impersonationDisabled: true, path: '/profile/security-settings' },
  { identifier: subTabsIdentifiers.profile.NOTIFICATION_SETTINGS, label: 'Notification Settings', limitToCustomer: true, freemiumIndex: 2, impersonationDisabled: true, path: '/profile/notification-settings' },
  { identifier: subTabsIdentifiers.profile.SETTINGS, label: 'Settings', limitToCustomer: false, freemiumIndex: 3, impersonationDisabled: true, path: '/profile/settings' },
  { identifier: subTabsIdentifiers.profile.USER_MANAGEMENT, label: 'User Management', limitToCustomer: true, freemiumIndex: 4, impersonationDisabled: false, path: '/profile/user-management', 
  //access: LIST_ACTION, 
  //resource: PRIVILEGE_SOURCES.PROFILE.USER_MANAGEMENT
  }
];

//  Assessment Subtabs
const assessmentSubtabs = [
  { identifier: subTabsIdentifiers.assessment.ASSESSMENT, label: 'Assessment', limitToCustomer: false, freemiumIndex: 0 },
];

// Risk Manager Subtabs
const riskManagerSubtabs = [
  { identifier: subTabsIdentifiers.businessRisk.BUSINESS_RISK, label: 'Risk Manager', limitToCustomer: false, freemiumIndex: 0 },
]

// Policies Subtabs
const policiesSubtabs = [
  { identifier: subTabsIdentifiers.policies.POLICIES, label: 'Policies', limitToCustomer: false, freemiumIndex: 0 },
]

//  Members Subtabs
const membersSubtabs = [
  { identifier: subTabsIdentifiers.members.MEMBERS, label: 'Members', limitToCustomer: false, freemiumIndex: 0 },
];

//  Team Subtabs
const teamSubtabs = [
  { identifier: subTabsIdentifiers.team.TEAM, label: 'Team', limitToCustomer: false, freemiumIndex: 0 },
];

//  Automation subtabs
const automationSubTabs = [
  { identifier: subTabsIdentifiers.automation.RULES, label: 'Automation Rules', limitToCustomer: false, freemiumIndex: 0 },
  /* { identifier: subTabsIdentifiers.automation.TAG_MANAGER, label: 'Tag Manager', limitToCustomer: false, freemiumIndex: 1 }, */
];

//  Subscription manager subtabs
const subscriptionManagerSubtabs = [
  { identifier: subTabsIdentifiers.subscription.SUBSCRIPTION_MANAGER, label: 'Subscription Manager', limitToCustomer: false, freemiumIndex: 0 },
  /* { identifier: subTabsIdentifiers.subscription.BILLING_AND_PAYMENTS, label: 'Billing and Payments', limitToCustomer: false, freemiumIndex: 1 },*/
];

// Compliance subtabs
const complianceSubtabs = [
  { identifier: subTabsIdentifiers.compliance.COMPLIANCE_JOURNEY, label: 'Compliance Journey', limitToCustomer: false, freemiumIndex: 0 },
];

// Task Manager subtabs
const taskManagerSubtabs = [
  { identifier: subTabsIdentifiers.task_manager.TASK_MANAGER, label: 'Task Manager', limitToCustomer: false, freemiumIndex: 0 },
];

//  Recycle Bin subtabs
const recycleBinSubtabs = [
	{
		identifier: subTabsIdentifiers.recycle_bin.DEVICES,
		label: 'Devices',
		limitToCustomer: false,
		freemiumIndex: 0,
		path: '/recycle-bin?entity=devices',
		//manualRouting: true,
		search: '?entity=devices',
		searchOnly: true,
	},
	{
		identifier: subTabsIdentifiers.recycle_bin.ISSUES,
		label: 'Issues',
		limitToCustomer: false,
		freemiumIndex: 1,
		path: '/recycle-bin?entity=issues',
		//manualRouting: true,
		search: '?entity=issues',
		searchOnly: true,
	},
	{
		identifier: subTabsIdentifiers.recycle_bin.TASKS,
		label: 'Tasks',
		limitToCustomer: false,
		freemiumIndex: 2,
		path: '/recycle-bin?entity=tasks',
		//manualRouting: true,
		search: '?entity=tasks',
		searchOnly: true,
	},
]


//  Connection Status subtabs
const connectionStatusSubtabs = [
  { identifier: subTabsIdentifiers.connection_status.CONNECTION_STATUS, label: 'Subscription Manager', limitToCustomer: false, freemiumIndex: 0 },
];

//  Groups manager subtabs
const groupsManagerSubtabs = [
  { identifier: subTabsIdentifiers.groups_manager.GROUPS_MANAGER, label: 'Groups Manager', limitToCustomer: false, freemiumIndex: 0 },
]

// ***************************** ACTIONS ***********************************
export const actionTypes = {
  DOWNLOAD_PDF: 'DOWNLOAD_PDF',
  DOWNLOAD_POLICY: 'DOWNLOAD_POLICY',
};

const vcysoActions = [
  {
    container: { className: 'btn-download-pdf', color: 'light-gray' },
    icon: { className: 'bx bxs-file-pdf' },
    cta: 'Download',
    actionType: actionTypes.DOWNLOAD_PDF,
    allowedTabs: [subTabsIdentifiers.vcyso.TRUST_BUILDER],
    requiresCompany: true,
  },
];

// ***************************** DROPDOWN OPTIONS ***********************************
const myCysoDropdownOptions = {
  limitToCydekicksView: true,
  show: true
}

const issuesDropdownOptions = {
  limitToCydekicksView: true,
  show: true
}


//  Map for getting tabs subtabps
//  TODO: populate array of actions when supported
export const subtabsAndActionsMap = new Map([
  ['/dashboard/policies', { subtabs: policiesSubtabs, actions: [], showParentLink: true, parentLinkLabel: "Go back to dashboard" }],
  ['/dashboard/risk-manager', { subtabs: riskManagerSubtabs, actions: [], showParentLink: true, parentLinkLabel: "Go back to dashboard" }],
  ['/dashboard/assessment', { subtabs: assessmentSubtabs, actions: [], showParentLink: true, parentLinkLabel: "Go back to dashboard" }],
  ['/dashboard/compliance', { subtabs: complianceSubtabs, actions: [], showParentLink: true, parentLinkLabel: "Go back to dashboard" }],
  ['/dashboard/devices', { subtabs: devicesSubtabs, actions: [], showParentLink: true, parentLinkLabel: "Go back to dashboard" }],
  ['/dashboard/task-manager', { subtabs: taskManagerSubtabs, actions: [], showParentLink: true, parentLinkLabel: "Go back to dashboard" }],
  ['/dashboard', { hasDynamicSubtabs: true, fallbackSubTabs: dashboardSubtabs }],
  ['/app-launcher', { subtabs: appLauncherSubtabs, actions: [], showParentLink: false }],
  // ['/my-cyso', { hasDynamicSubtabs: true, /*dropdownOptions: myCysoDropdownOptions*/ }],
  ['/cyso', { subtabs: vCySOSubtabs, actions: vcysoActions }],
  ['/issues', { hasDynamicSubtabs: true, defaultImpersonation: true/*dropdownOptions: issuesDropdownOptions*/ }],
  ['/members', { subtabs: membersSubtabs, actions: [] }],
  ['/team', { subtabs: teamSubtabs, actions: [] }],
  ['/devices', { subtabs: devicesSubtabs, actions: [] }],
  ['/assessment', { subtabs: assessmentSubtabs, actions: [] }],
  ['/profile', { subtabs: profileSubtabs, actions: [] }],
  ['/automation', { subtabs: automationSubTabs, actions: [] }],
  ['/subscription', { subtabs: subscriptionManagerSubtabs, actions: [] }],
  ['/connection-management', { subtabs: [], actions: [] }],
  ['/recycle-bin', { subtabs: recycleBinSubtabs, actions: [] }],
  ['/groups-manager', { subtabs: groupsManagerSubtabs, actions: [] }],
]);

//  Whitelisted public routes
export const whitelistedRoutes = [
  '/export'
];

//  Public routes
export const publicRoutesPaths = [
  '/cydekicks/join',
  '/members/join/',
  '/register-success',
  '/verification-success',
];

export const defaultIndexPath = '/dashboard';
export const salesDefaultPath = '/members';
export const cydekicksDefaultPath = '/dashboard/issues';
