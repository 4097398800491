import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Progress } from 'reactstrap';
import { resetAssessment } from '../../../actions/answers';
import makeTotalAnswersByCategory from '../../../selectors/assessment/totalAnswersByCategorySelector';
import makeTotalQuestionsNumber from '../../../selectors/assessment/totalQuestionsNumberSelector';
import companyIdSelector from '../../../selectors/companyIdSelector';
import { getAssessmentFeedbackMessage } from '../../../utils/assessmentHelpers';
import { assessmentHandlerTypes } from '../../../constants/common';
import UnsavedChangesGuard from '../UnsavedChangesGuard';
import useSaveFullAssessment from '../../../hooks/useSaveFullAssessment';
import useCanAbility from '../../../hooks/useCanAbility';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import { MODIFY_ACTION, PRIVILEGE_SOURCES } from '../../../constants/privileges';

const AssessmentSaveOptions = (props) => {
    //  Parsing params
    const {
        selectedComplianceTab,
        availableCompliances,
        canImpersonate,
        selectComplianceCallback
    } = props;

    const getAssessmentProgress = makeTotalAnswersByCategory();
    const getQuestionsData = makeTotalQuestionsNumber();

    //  Watching redux state changes
    const company_id = useSelector((state) => companyIdSelector(state));
    const answers = useSelector((state) => (state.answers));
    const assessment = useSelector((state) => (state.assessment?.assessment));
    const assessmentProgress = useSelector((state) => getAssessmentProgress(state));
    const questionsData = useSelector((state) => getQuestionsData(state));
    const leftSideBarType = useSelector((state) => (state.layout.leftSideBarType));
    const isMemberView = useSelector(state => state.impersonation.isMemberView);

    //  Component state
    const [showKeepGoing, setShowKeepGoing] = useState(false);
    const [completed, setCompleted] = useState(false);

    //  Component hooks
    const { saveFullAssessment, processing } = useSaveFullAssessment();
    const ability = useCanAbility();

    //  Memoized dispatch
    const { dispatch } = useMemoizedDispatch();

    // Component variables
    const canEditAssessment = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.ASSESSMENT.GENERAL);

    useEffect(() => {
        const progress = Math.floor((assessmentProgress?.total / questionsData.total?.questions) * 100);
        const isLastSelected = selectedComplianceTab?.index === availableCompliances.length - 1;
        setShowKeepGoing(progress === 100 && !isLastSelected);
        setCompleted(progress === 100);
    }, [assessmentProgress, questionsData, selectedComplianceTab, availableCompliances, setShowKeepGoing]);

    //  Function to get options className
    const getOptionsClassName = (canImpersonate) => {
        let className = 'save-assessment-options d-inline-block';
        if (!canImpersonate || isMemberView) { className += ' extra-padding'; }
        if (showKeepGoing) { className += ' keep-going'; }
        className += ` ${leftSideBarType}-options-width`;
        return className;
    };

    const saveAssessment = (successCallback = null) => {
        const assessmentBody = {
            assessment_id: assessment?.id,
            company_id: company_id,
            questionAnswers: answers.questionAnswers,
            questionsByCategory: answers.questionsByCategory,
            questionComments: answers.questionComments,
            sectionComments: answers.sectionComments,
            comment: assessment.assessmentComment,
            completed: answers.assessmentExecutionStatus || completed
        };
        const handlerType = assessmentHandlerTypes[successCallback ? 'FUNCTION' : 'NONE'];
        const callbackObject = { handler: successCallback, type: handlerType };
        saveFullAssessment(assessmentBody, null, callbackObject);
    }

    const renderProgressBar = () => {
        const progress = Math.floor((assessmentProgress?.total) / (questionsData?.total?.questions) * 100);
        return (
            <div className="assessment-progress-bar w-100">
                <Progress value={progress} />
            </div>
        )
    }

    const renderAssessmentQuestionsProgress = () => {
        return (
            <div className="assessment-question-progress font-weight-bolder my-auto">
                {`${assessmentProgress?.total}/${questionsData?.total?.questions} q’s answered`}
            </div>
        )

    }

    const renderAssessmentProgressLabel = () => {
        let label;
        if(canImpersonate && !isMemberView) {
            label = 'Keep up the good work!'
        } else {
            label = getAssessmentFeedbackMessage(assessmentProgress?.total, questionsData?.total?.questions, answers.unsavedChanges, true)
        }
        return (
            <div className="save-label px-2 my-auto">
                {label}
                {/* { showKeepGoing ? 'Save your progress and continue your next assessment' : `Save your Progress on your ${selectedComplianceTab?.name} assessment`} */}
            </div>
        )
    }

    const onSaveAssessmentClick = () => {
        saveAssessment();
        if(showKeepGoing){
            const nextComplianceTab = availableCompliances[selectedComplianceTab?.index + 1 || 0]
            selectComplianceCallback(nextComplianceTab);
        }
    }

    const renderSaveAssessmentButton = (saveLabel) => {
        if(!canEditAssessment) return null;
        return (
            <Button
                color="light"
                className={`btn-save`}
                onClick={onSaveAssessmentClick}
                disabled={answers.processing}
            >
                {answers.processing ? 'Processing...' : saveLabel}
            </Button>
        )
    }

    const renderButtons = () => {
        const redirect = completed && !canImpersonate && !answers.unsavedChanges;
        const saveLabel = completed ? 'Save Changes' : 'Save Progress';
        return (
            <div className="buttons-wrapper">
                {canImpersonate && !isMemberView && (
                    <Link
                        className="btn btn-outline-light-gray mr-0 mb-2 mr-lg-3 mb-lg-0"
                        to={{
                            pathname: '/dashboard',
                        }}
                    >
                        See member’s dashboard
                    </Link>
                )}
                {redirect ? (
                    <Link
                        to={{
                            pathname: '/dashboard',
                        }}
                        className="btn btn-light btn-save"
                    >
                        See my Cyvatar Score
                    </Link>
                ): renderSaveAssessmentButton(saveLabel)}
            </div>
        )

    }

    return (
        <div className={getOptionsClassName(canImpersonate)}>
            <UnsavedChangesGuard
                unsavedChanges={answers?.unsavedChanges}
                saveCallback={saveAssessment}
                leaveCallback={() => dispatch(resetAssessment())}
            />
            {renderProgressBar()}
            <div className="d-flex justify-content-between py-3 px-4">
                {renderAssessmentQuestionsProgress()}
                {renderAssessmentProgressLabel()}
                {renderButtons()}
            </div>
        </div>
    )
}

export default AssessmentSaveOptions
