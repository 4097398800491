import {
    CLEAR_DEVICES,
    DEVICES_COUNT_SUCCESS,
    DEVICES_LIST_SUCCESS,
    UPDATE_DEVICES_COUNT_SUCCESS,
    DEVICE_UPDATE_SUCCESS,
    RELOAD_DEVICES_LIST,
    LOGOUT_SUCCESS,
    ON_DELETE_DEVICE_CHANGE,
    SET_DEVICES_FILTER,
    CLEAR_ALL_DEVICES_FILTERS,
    ON_MERGE_DEVICES_CHANGE,
    SET_DEVICES_FILTER_GROUP,
} from '../actions/types';
import filterTypes from '../constants/filterTypes';
import { getFilterGroupState, getFilterState } from '../utils/filterHelpers';
import { getSafeArray } from '../utils/helpers';

const filtersInitialState = {
    partner_id: null
};

const initialState = {
    filters: filtersInitialState,
    activeFilters: [],
    searchTerm: '',
    list: [],
    pageCount: 1,
    count: {},
    deleteModalConfig: {
        show: false,
        device_ids: [],
        success: false,
    },
    mergeModalConfig: {
        show: false,
        devices: [],
        success: false,
    }
}
export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {

        case DEVICES_LIST_SUCCESS: {
            return {
                ...state,
                list: payload.data,
                pageCount: payload.pagination?.page_count || 1,
                count: payload?.count || {},
            }
        }

        case DEVICES_COUNT_SUCCESS: {
            return {
                ...state,
                count: { ...payload }
            }
        }

        case UPDATE_DEVICES_COUNT_SUCCESS: {
            return {
                ...state,
                count: {
                    ...state.count,
                    expected_devices: payload.expected_devices
                }
            }
        }

        case CLEAR_DEVICES:
        case LOGOUT_SUCCESS: {
            return initialState;
        }

        case DEVICE_UPDATE_SUCCESS: {
            const editedList = state.list ? [...state.list] : [];
            if (payload?.id) {
                const indexToUpdate = editedList.findIndex(_device => _device.id === payload.id);
                if (indexToUpdate !== -1) editedList[indexToUpdate] = payload;
            }
            return {
                ...state,
                list: editedList
            }
        }

        case RELOAD_DEVICES_LIST: {
            return {
                ...state,
                reloadDevicesList: payload
            }
        }

        case ON_DELETE_DEVICE_CHANGE: {
            return {
                ...state,
                deleteModalConfig: {
                    show: payload?.show,
                    device_ids: payload?.device_ids,
                    success: payload?.success,
                },
            }
        }

        case SET_DEVICES_FILTER: {
            const  filtersState = state.filters;
            const activeFiltersState = state.activeFilters;
            const { filters, activeFilters, searchTerm } = getFilterState(filtersState, activeFiltersState, payload);
            const isSearchFilter = (payload?.type === filterTypes.search);
            return {
                ...state,
                filters,
                activeFilters: [...activeFilters],
                searchTerm: isSearchFilter ? searchTerm : state?.searchTerm
            }
        }

        case CLEAR_ALL_DEVICES_FILTERS: {
            // Reset all except partner_id filter
            const partnerIdFilter = state?.filters?.partner_id;
            return {
                ...state,
                filters: {
                    ...filtersInitialState,
                    partner_id: partnerIdFilter,
                },
                activeFilters: [
                    ...initialState?.activeFilters,
                ],
                searchTerm: initialState?.searchTerm
            }
        }
        case ON_MERGE_DEVICES_CHANGE: {
            //  Getting devices by id
            const devices = (payload?.devices && Array.isArray(payload?.devices)) ? payload?.devices : [];

            //  Mutating state
            return {
                ...state,
                mergeModalConfig: {
                    success: payload?.success,
                    show: payload?.show,
                    devices,
                }
            }
        }
        case SET_DEVICES_FILTER_GROUP : {
            const  filtersState = state.filters;
            const activeFiltersState = state.activeFilters;
            const { filters, activeFilters } = getFilterGroupState(
                filtersState,
                activeFiltersState,
                payload,
                filtersInitialState
            );
            return {
                ...state,
                filters,
                activeFilters
            }
        }

        default:
            return state;
    }
}
