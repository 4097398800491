import React, { useState } from 'react'
import { filterOptions } from '../../constants/filterTypes';
import FiltersButtonGroup from '../layout/filters/FiltersButtonGroup';
import TagsInifiniteDropdown from './TagsInfiniteDropdown';
import TagComponent from './TagComponent'

const TagsFiltering = (props) => {

    const {
        companyId,
        deviceId,
        issueId,
        callback,
        filteredTags,
        className="",
    } = props;

    // Component state
    const [filterOption, setFilterOption] = useState([filterOptions.FILTER_BY]);

    const renderFilterOptions = () => {
        const options = [
            { label: "Filter by", className: "light-gray border border-light-gray border-right-0 rounded-left", value: filterOptions.FILTER_BY },
            { label: "Filter out", className: "light-gray border border-light-gray rounded-right", value: filterOptions.FILTER_OUT }
        ];
        return (
            <div className="btn-filter-options d-inline-block mr-2">
                <FiltersButtonGroup
                    data={options}
                    filterValue={filterOption}
                    showNumber={false}
                    callback={({ value }) => setFilterOption([value])}
                    wrapperClassName="d-inline-block"
                />
            </div>
        )
    }

    const onTagSelect = (tag) => {
        callback({ ...tag, filterOption: filterOption[0] })
    }

    const renderDropdown = () => {
        return (
            <TagsInifiniteDropdown
                companyId={companyId}
                deviceId={deviceId}
                issueId={issueId}
                callback={onTagSelect}
                className="filter-dropdown"
                filteredTags={filteredTags}
                filterOption={filterOption[0]}
                customMenuPosition='absolute'
            />
        )
    }
    const renderTagsList = () => {
        if(!filteredTags) return;
        return (
            <div>
                {filteredTags.map((filter, index) => {
                    const filterOut = filter.filterOption === filterOptions?.FILTER_OUT;

                    return (
                        <TagComponent
                            key={`tag-filter-${index}`}
                            className={`mr-2 mb-2 ${filterOut? 'filter-out-tag' : ''}`}
                            label={filterOut ? `Exclude ${filter.label}` : filter.label }
                            id={filter.value}
                            removeTagCallback={() => callback(filter)}
                        />
                    )
                })}
            </div>
        )
    }
    return (
        <div className={`tags-filtering-component ${className}`} tabIndex="0" aria-label='Tag filtering'>
            <div>
                {renderFilterOptions()}
                {renderDropdown()}
            </div>
            {renderTagsList()}
        </div>
    )
}

export default TagsFiltering;
