import React from 'react';
import { useSelector } from 'react-redux';
import issuesInfoSelector from '../../../selectors/issuesInfoSelector';
import { isValidFunction, mapSolutionsToOptions, removeItemFromArray } from '../../../utils/helpers';
import SimpleMultipleDropdown from '../../layout/SimpleMultipleDropdown';
import TagComponent from '../../tags/TagComponent';

const IssueMultipleSolutionFilter = (props) => {
  const {
    onChange,
    selectedElements,
    issueType,
  } = props;

  // Getting needed info from redux store
  const solutions = useSelector((state) => issuesInfoSelector(state, 'modalOptions', issueType)?.solutions);

  // Handle on change
  const onFilterChange = (option) => {
    if(isValidFunction(onChange)) {
      onChange(option)
    }
  }

  //  Function to render selected elements
  const renderSelectedElements = () => {
    return selectedElements.map((element, index) => {
      return (
        <TagComponent
          key={`source-element-${index}`}
          className="mr-2 mb-2"
          label={element?.label}
          id={element?.value}
          shouldCapitalize={false}
          removeTagCallback={() => onChange(element)}
        />
      );
    });
  };

  return (
    <div className="types-filter issue-types-filter">
      <div className="filter-label">Solutions</div>
      <div className="filters-container">
        <div className="types-dropdown-container">
          <SimpleMultipleDropdown
            emptyLabel="Solutions"
            extraClassName="dropdown-filter"
            elements={mapSolutionsToOptions(solutions)}
            onChange={onFilterChange}
            isMultiSelect={true}
            selectedElements={selectedElements}
          />
        </div>
        <div className="selected-types">{renderSelectedElements()}</div>
      </div>
    </div>
  );
};

export default IssueMultipleSolutionFilter;
