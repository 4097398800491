import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DELETE_ACTION, MODIFY_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';
import useCanAbility from '../../hooks/useCanAbility';
import SetupDropdown from '../common/SetupDropdown';
import ChangeTeamMemberRoleModal from './ChangeTeamMemberRoleModal';
import DeleteTeamMember from './DeleteTeamMember';
import DisableTeamMember from './DisableTeamMember';

const CyvatarTeamActions = (props) => {
    const {
        teamMemberData,
        reloadCallback,
    } = props;

    // Getting needed info from redux store
    const signedUserId = useSelector(state => state.auth.user.id)

    // Component's state
    const [showDisableModal, setShowDisableModal] = useState(false);
    const [showRemoveMemberModal, setShowRemoveMemberModal] = useState(false);
    const [showRoleModal, setShowRoleModal] = useState(false);

    // Component Hooks
    const ability = useCanAbility();

    // Component's variables
    const isSameUser = teamMemberData["roles.USER_MANAGEMENT::USER_ROLES.user_id"] === signedUserId;
    const canModifyMembers = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.CYVATAR_TEAM.GENERAL);

    // Function to toggle disable member modal
    const toggleDisableModal = () => {
        setShowDisableModal(!showDisableModal);
    }

    // Function to toggle remove member modal
    const toggleRemoveMemberModal = () => {
        setShowRemoveMemberModal(!showRemoveMemberModal);
    }

    // Function to toggle modal for changing member's role
    const toggleShowRoleModal = () => {
        setShowRoleModal(!showRoleModal);
    }

    // Dropdown option to change role
    const getChangeRoleOptions = () => {
        if(!canModifyMembers) return [];
        const {
            active
        } = teamMemberData;
        if(!active) return [];
        return [
            {
                label: "Change role",
                callback: toggleShowRoleModal,
                isSelected: false,
                isDisabled: isSameUser
            }
        ]
    }

    // Dropdown option to disable or enable member
    const getDisableOptions = () => {
        if(!canModifyMembers) return [];
        const label = !teamMemberData?.active ? "Enable account" : "Disable"
        return [{
            label,
            callback: toggleDisableModal,
            isSelected: false,
            isDisabled: isSameUser,
        }]
    }

    // Dropdown option to remove member's account
    const getRemoveAccountOptions = () => {
        const canRemoveMembers = ability.can(DELETE_ACTION, PRIVILEGE_SOURCES.CYVATAR_TEAM.GENERAL);
        if(!canRemoveMembers) return [];
        return [
            {
                label: "Remove from Cyvatar...",
                callback: toggleRemoveMemberModal,
                isSelected: false,
                isDisabled: isSameUser,
            }
        ]
    }

    const getOptions = () => {
        return [
            ...getChangeRoleOptions(),
            ...getDisableOptions(),
            ...getRemoveAccountOptions()
        ]

    }

    // Render modal for changing role
    const renderChangeRoleModal = () => {
        if(!showRoleModal || !teamMemberData) return null;
        const {
            full_name,
        } = teamMemberData;
        const userId = teamMemberData["roles.USER_MANAGEMENT::USER_ROLES.user_id"]
        return (
            <ChangeTeamMemberRoleModal
                toggleModal={toggleShowRoleModal}
                memberName={full_name}
                userId={userId}
                currentRole={teamMemberData["roles.name"]}
                reloadCallback={reloadCallback}
            />
        )
    }

    // Render modal for disabling users
    const renderDisableUserModal = () => {
        if(!showDisableModal || !teamMemberData) return null;
        const {
            full_name,
            active
        } = teamMemberData;
        const userId = teamMemberData["roles.USER_MANAGEMENT::USER_ROLES.user_id"]
        return (
            <DisableTeamMember
                toggleModal={toggleDisableModal}
                memberName={full_name}
                userId={userId}
                isActive={active}
                reloadCallback={reloadCallback}
                userRole={teamMemberData["roles.name"]}
            />
        )
    }

    // Render modal for deleting users
    const renderRemoveMemberModal = () => {
        if(!showRemoveMemberModal || !teamMemberData) return null;
        const {
            full_name
        } = teamMemberData;
        const userId = teamMemberData["roles.USER_MANAGEMENT::USER_ROLES.user_id"]
        return (
            <DeleteTeamMember
                toggleModal={toggleRemoveMemberModal}
                memberName={full_name}
                userId={userId}
                reloadCallback={reloadCallback}
                userRole={teamMemberData["roles.name"]}
            />
        )
    }
    return (
        <>
            {renderChangeRoleModal()}
            {renderDisableUserModal()}
            {renderRemoveMemberModal()}
            <SetupDropdown
                options={getOptions()}
            />
        </>
    );
};

export default CyvatarTeamActions;
