//  File to store the keys of the different get methods on the application
//  Auth Keys
export const DISABLE_MFA_REQUEST = 'DISABLE_MFA_REQUEST';
export const VALIDATE_RESET_PASSWORD_TOKEN = 'VALIDATE_RESET_PASSWORD_TOKEN';
export const AUTH_USER = 'AUTH_USER';

//  Company Keys
export const GET_COMPANY = 'GET_COMPANY';
export const LIST_COMPANY = 'LIST_COMPANY';
export const GET_CONFIRM_COMPANY = 'GET_CONFIRM_COMPANY';
export const FETCH_RESULTS = 'FETCH_RESULTS';
export const GET_CYVALUE_TABS = 'GET_CYVALUE_TABS';
export const GET_IMPERSONATION_LIST = 'GET_IMPERSONATION_LIST';
export const LIST_COMPANY_NOTES = 'LIST_COMPANY_NOTES';
export const GET_COMPANY_ATTACHMENTS = 'GET_COMPANY_ATTACHMENTS';
export const GET_DOMAINS_LIST = 'GET_DOMAINS_LIST';
export const GET_SCAN_TIMEZONES = 'GET_SCAN_TIMEZONES';
export const GET_COMPANY_USERS = 'GET_COMPANY_USERS';
export const GET_COMPANY_LOGO = 'GET_COMPANY_LOGO';

//  Badge Keys
export const GET_COMPANY_BADGES = 'GET_COMPANY_BADGES';
export const GET_BADGE = 'GET_BADGE';

//  Dashboard Keys
export const GET_COMPLIANCE = 'GET_COMPLIANCE';
export const GET_COMPLIANCE_STATUS = 'GET_COMPLIANCE_STATUS';
export const FETCH_COMPLIANCE_SCORE = 'FETCH_COMPLIANCE_SCORE';
export const GET_CYVALUE_DATA = 'GET_CYVALUE_DATA';
export const GET_ICARM_MAINTENANCE_SOLUTIONS = 'GET_ICARM_MAINTENANCE_SOLUTIONS';
export const GET_ICARM_ONBOARDING_SOLUTIONS = 'GET_ICARM_ONBOARDING_SOLUTIONS';
export const GET_CYVALUE_BY_WEEK = 'GET_CYVALUE_BY_WEEK';
export const GET_ACTIVITY_REPORT = 'GET_ACTIVITY_REPORT';
export const GET_DEMO_DATA = 'GET_DEMO_DATA';
export const GET_COMPANY_RISK_SCORE = 'GET_COMPANY_RISK_SCORE';

//  Partner Keys
export const LIST_PARTNERS = 'LIST_PARTNERS';
export const LIST_PARTNERS_DEFAULTS = 'LIST_PARTNERS_DEFAULTS';
export const FETCH_PARTNERS = 'FETCH_PARTNERS';
export const GET_FURTIM_DASHBOARD = 'GET_FURTIM_DASHBOARD';
export const FETCH_ASSETS_DASHBOARD = 'FETCH_ASSETS_DASHBOARD';
export const GET_ACTIVE_PARTNERS = 'GET_ACTIVE_PARTNERS';
export const GET_PARTNER_ACTIONS = 'GET_PARTNER_ACTIONS';
export const GET_PARTNER_ACTIONS_LIST_OPTIONS = "GET_PARTNER_ACTIONS_LIST_OPTIONS";
export const GET_CONNECTION_STATUS_LIST  = 'GET_CONNECTION_STATUS_LIST';
export const GET_PARTNER_FIELDS = "GET_PARTNER_FIELDS";
export const GET_PENDING_PARTNERS = "GET_PENDING_PARTNERS";
export const LIST_PARTNER_MANUAL_ACTIONS = "LIST_PARTNER_MANUAL_ACTIONS";
export const GET_PARTNER_ISSUE = "GET_PARTNER_ISSUE";

//  User Keys
export const GET_USER_INVITE = 'GET_USER_INVITE';
export const GET_ISSUES_ASSIGNEES = 'GET_ISSUES_ASSIGNEES';
export const FETCH_CYDEKICKS = 'FETCH_CYDEKICKS';
export const VALIDATE_VERIFICATION_TOKEN = 'VALIDATE_VERIFICATION_TOKEN';

//  Assessment Keys
export const FETCH_ASSESSMENT = 'FETCH_ASSESSMENT';
export const FETCH_COMPLIANCES = 'FETCH_COMPLIANCES';
export const FETCH_ANSWERS = 'FETCH_ANSWERS';
export const GET_ASSESSMENT_STATUS = 'GET_ASSESSMENT_STATUS';

//  Issue Keys
export const GET_ISSUES_LIST = 'GET_ISSUES_LIST';
export const GET_ISSUE = 'GET_ISSUE';
export const GET_ISSUE_MODAL_OPTIONS = 'GET_ISSUE_MODAL_OPTION';
export const GET_ROLLUP_DATA = 'GET_ROLLUP_DATA';
export const GET_ROI = 'GET_ROI'
export const GET_TOP_FIVE_ISSUES = 'GET_TOP_FIVE_ISSUES';
export const GET_TOP_FIVE_ISSUES_OVERVIEW = 'GET_TOP_FIVE_ISSUES_OVERVIEW';
export const GET_VULNERABILITY_TREND = 'GET_VULNERABILITY_TREND';
export const GET_ASSIGNED_TO = 'GET_ASSIGNED_TO';
export const GET_ISSUE_SOURCES = 'GET_ISSUE_SOURCES';

// Grc Issue Keys
export const GET_GRC_ISSUES_CONFIG = 'GET_GRC_ISSUES_CONFIG';
export const GET_GRC_ISSUES_LIST = 'GET_GRC_ISSUES_LIST';
export const GET_GRC_ISSUE = 'GET_GRC_ISSUE';
export const GET_GRC_TREND_DATA = 'GET_GRC_TREND_DATA';

//  Device Keys
export const GET_DEVICES_LIST = 'GET_DEVICES_LIST';
export const GET_DEVICES_COUNT = 'GET_DEVICES_COUNT';
export const GET_DEVICE_TYPES = 'GET_DEVICE_TYPES';
export const GET_DEVICE_BY_PARTNER_COUNT = 'GET_DEVICE_BY_PARTNER_COUNT';
export const GET_DEVICES_INFO = 'GET_DEVICES_INFO';
export const GET_DEVICES_BY_SOLUTION = 'GET_DEVICES_BY_SOLUTION';
export const GET_DEVICE_STATUS = 'GET_DEVICE_STATUS';
export const GET_CURRENT_PROTECTION = 'GET_CURRENT_PROTECTION';

//  Vcyso Keys
export const GET_SOLUTIONS_AND_CONTROLS = 'GET_SOLUTIONS_AND_CONTROLS';
export const GET_POLICIES = 'GET_POLICIES';
export const GET_TRUST_BUILDER_CONTENT = 'GET_TRUST_BUILDER_CONTENT';
export const GET_EXPORT_TOKEN_VALIDATION = 'GET_EXPORT_TOKEN_VALIDATION';
export const GET_POLICY_PDF = 'GET_POLICY_PDF';
export const GET_BUSINESS_RISK_CONFIG = 'GET_BUSINESS_RISK_CONFIG';
export const GET_RISK_STATEMENTS = 'GET_RISK_STATEMENTS';
export const RISK_STATEMENT_STATUS_DATA = 'RISK_STATEMENT_STATUS_DATA';
export const GET_OPPORTUNITIES_TO_IMPROVE = 'GET_OPPORTUNITIES_TO_IMPROVE';
export const GET_COMPANY_RISKS_LIST = 'GET_COMPANY_RISKS_LIST';

//  Solution Keys
export const GET_SOLUTIONS = 'GET_SOLUTIONS';
export const GET_SOLUTION_STATUS = 'GET_SOLUTION_STATUS';
export const GET_SOLUTIONS_BY_COMPANY_ID = 'GET_SOLUTIONS_BY_COMPANY_ID';

//  Tags Keys
export const GET_TAGS_LIST = 'GET_TAGS_LIST';

//  Rules Keys
export const GET_RULE_SETS = 'GET_RULE_SETS';
export const GET_RULE_DETAIL = 'GET_RULE_DETAIL';
export const GET_RULE_LOGS = 'GET_RULE_LOGS';
export const GET_LOG_MESSAGES = 'GET_LOG_MESSAGES';
export const GET_LOG_GROUP = 'GET_LOG_GROUP';
export const GET_RULE_SET = 'GET_RULE_SET';

//  Plan Keys
export const GET_PLAN_SUMMARY = 'GET_PLAN_SUMMARY';
export const GET_PLANS = 'GET_PLANS';
export const LIST_ALL_PLANS = 'LIST_ALL_PLANS';
export const GET_NEXT_PLAN_UPGRADE = 'GET_NEXT_PLAN_UPGRADE';

//  Settings keys
export const GET_MFA_COUNTRY_CODES = 'GET_MFA_COUNTRY_CODES';
export const GET_IN_APP_NOTIFICATION_SETTINGS = 'GET_IN_APP_NOTIFICATION_SETTINGS';

// Partner sync logs keys
export const GET_PARTNER_SYNC_LOGS = "GET_PARTNER_SYNC_LOGS";

//  RECYCLE BIN
export const GET_ARCHIVED_ISSUES = 'GET_ARCHIVED_ISSUES';
export const GET_ARCHIVED_TASKS = 'GET_ARCHIVED_TASKS';
export const GET_ARCHIVED_DEVICES = 'GET_ARCHIVED_DEVICES';

//  GROUPS
export const GET_GROUPS_LIST = 'GET_GROUPS_LIST';
export const GET_GROUPS_LIBRARY = 'GET_GROUPS_LIBRARY';
export const GET_GROUP_DETAILS = 'GET_GROUP_DETAIL';
export const GET_ISSUE_GROUP_MEMBERS = 'GET_ISSUE_GROUP_MEMBERS';
export const GET_ISSUE_GROUP_DEVICES = 'GET_ISSUE_GROUP_DEVICES';

//NOTIFICATIONS
export const GET_USER_NOTIFICATIONS = 'GET_USER_NOTIFICATIONS'