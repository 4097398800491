import {
  CALCULATE_ROI_SUCCESS,
  CALCULATE_ROI_FAILURE,
  CALCULATE_ROI_RESET
} from './types';
import { updateROICompanySuccess } from '../actions/auth';


export const fetchResults = (employees, annualRevenue) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      const { CompanyAPI } = API;
      const response = await CompanyAPI.handlers.fetchResults({ employees, annualRevenue });
      dispatch(fetchResultsSuccess(response?.data, annualRevenue, employees));
      dispatch(updateROICompanySuccess({ employees, annualRevenue }));
    } catch (error) {
      dispatch(fetchResultsError(error));
    }
  };
}

export const fetchResultsSuccess = (results, annualRevenue, employeesCount) => {
  return {
    type: CALCULATE_ROI_SUCCESS,
    payload: {
      results,
      annualRevenue,
      employeesCount
    }
  }
}

export const fetchResultsError = (message) => {
  return {
    type: CALCULATE_ROI_FAILURE,
    payload: message
  }
}

export const resetResults = () => {
  return {
    type: CALCULATE_ROI_RESET
  }
}
