import React, { useEffect } from 'react';
import { issueTypes } from '../../../../constants/issues';
import useIssueCriticalityClassName from '../../../../hooks/useIssueCriticalityClassName';
import BasicModal from '../../../layout/BasicModal';
import API from '../../../../api';
import useCustomQuery from '../../../../hooks/useCustomQuery';
import GroupHeader from './GroupHeader';
import GroupDetailsBulkActions from './GroupDetailsBulkActions';
import LoadingContent from '../../../layout/LoadingContent';
import GroupGeneralInformation from './GroupGeneralInformation';
import { isValidFunction } from '../../../../utils/helpers';
import GroupOverviewDetails from './GroupOverviewDetails';
import CustomCardTabs from '../../../layout/tabs/CustomCardTabs';
import { useState } from 'react';
import GroupDevices from './GroupDevices';
import GroupMembers from './GroupMembers';
import { groupTabIds } from '../../../../constants/groups';
import useSelectedList from '../../../../hooks/useSelectedList';
import { customTableEvents } from '../../../../constants/common';

const GroupDetails = (props) => {
  const {
    publicId,
    toggleModal,
    areGroupsLoading = false,
    removeQueryParams,
    changeSelectedGroup,
    changeCurrentPage,
  } = props;

  //	Initializing API
  const { GroupAPI } = API;

  // Component state
  const [selectedTab, setSelectedTab] = useState(0);

  //	Calling API GET endpoints
  const {
    data: groupData,
    error: groupError,
    isLoading: groupLoading,
  } = useCustomQuery(
    [GroupAPI.keys.getGroupDetails, publicId],
    ({ queryKey }) => GroupAPI.handlers.getGroupDetails(queryKey[1])
  );

  //  Selected list hook
  const {
    selected,
    excluded,
    allSelected,
    eventHandler: handleSelectedEvent,
    setSelected,
    clearSelected,
  } = useSelectedList({
    eventMap: {
      changeAll: customTableEvents.CHANGE_SELECT_ALL,
      add: customTableEvents.SELECT_ELEMENT,
      remove: customTableEvents.UNSELECT_ELEMENT,
    }
  });

  // If the group changes with next or previous buttons on the modal, the selected elements on the table are cleared
  useEffect(() => {
    if (selected.length === 0 && allSelected === false) return;
    clearSelected();
  }, [publicId]);

  // Component Hooks
  const { criticalityClassName } = useIssueCriticalityClassName({
    criticalityId: groupData?.group?.severity,
    issueType: issueTypes.VULNERABILITIES,
  });

  // Function to close modal
  const onCloseModal = () => {
    toggleModal(false);
    removeQueryParams();
  };

  // Render header
  const renderHeader = () => {
    if (areGroupsLoading || groupLoading) {
      return null;
    }
    return (
      <div className='w-100'>
        <GroupHeader
          onCloseModal={onCloseModal}
          showGroupId={false}
          changeSelectedGroup={changeSelectedGroup}
          changeCurrentPage={changeCurrentPage}
        />
        <div>
          <CustomCardTabs
            className="group-details-tabs filter-type"
            callback={setSelectedTab}
            selectedTab={selectedTab}
            tabs={groupDetailsTabs}
            alignment="left"
          />
        </div>
      </div>
    );
  };

  // Render bulk actions
  const renderGroupBulkActions = () => {
    const tab = groupDetailsTabs[selectedTab || 0];
    return (
      <GroupDetailsBulkActions
        group={groupData?.group || {}}
        criticalityClassName={criticalityClassName}
        showBulkTagsButton={tab.id === groupTabIds.GROUP_GENERAL_TAB || tab.id === groupTabIds.GROUP_DEVICES_TAB}
        bulkTagsType={tab.id}
        selectedDevices={selected}
      />
    );
  };

  // Render group general information
  const renderGeneralTab = () => {
    return (
      <GroupGeneralInformation
        isLoading={areGroupsLoading || groupLoading}
        errorMessage={groupError ? 'Error getting group' : null}
        group={groupData?.group}
      />
    );
  };

  // Render group overview details
  const renderGroupOverviewDetails = () => {
    const group = groupData?.group || {};
    const {
      id,
      name,
      severity,
      issue_external_id,
      open_issues,
      closed_issues,
    } = group;
    return (
      <div className="d-flex">
        <GroupOverviewDetails
          groupName={name}
          severityRollup={severity}
          statusRollup={groupData?.status_roll_up}
          groupExternalId={issue_external_id}
          groupId={id}
          issuesCount={open_issues + closed_issues}
        />
      </div>
    );
  };

  const groupDetailsTabs = [
    {
      id: groupTabIds.GROUP_GENERAL_TAB,
      name: 'General information',
      renderContent: renderGeneralTab,
    },
    {
      id: groupTabIds.GROUP_MEMBERS_TAB,
      name: 'Members affected',
      renderContent: () => <GroupMembers group={groupData?.group || {}}/>,
    },
    {
      id: groupTabIds.GROUP_DEVICES_TAB,
      name: 'Devices affected',
      renderContent: () => (
        <GroupDevices
          group={groupData?.group || {}}
          selected={selected}
          excluded={excluded}
          allSelected={allSelected}
          handleSelectedEvent={handleSelectedEvent}
          setSelected={setSelected}
          clearSelected={clearSelected}
        />
      ),
    },
    //{
    //  id: groupTabIds.GROUP_SUBTASKS_TAB,
    //  name: 'Subtasks',
    //},
  ];

  const renderSelectedTabContent = () => {
    const tab = groupDetailsTabs[selectedTab || 0];
    return isValidFunction(tab?.renderContent) ? tab?.renderContent() : null;
  };

  return (
    <BasicModal
      headerClassName={`modal-header-status bg-${
        criticalityClassName || 'light-gray'
      }`}
      customHeaderContent={renderHeader()}
      showModal={true}
      toggleModal={onCloseModal}
      customClassName={'issue-expanded-detail group-details-modal dashboard'}
      backdrop={false}
      scrollable={true}
      wrapClassName={'no-scrollable-modal expanded-detail-container'}
      zIndex={1050}
    >
      <LoadingContent
        isLoading={areGroupsLoading || groupLoading}
        errorMessage={groupError ? 'Error getting group' : null}
        iconType="solidIcon"
        errorStyle={{ minHeight: '150px', paddingTop: '16px' }}
        loadingStyle={{ minHeight: '150px', paddingTop: '200px' }}
      >
        {renderGroupOverviewDetails()}
        <div className="d-flex">
          <div className="left-column">
            {renderSelectedTabContent()}
          </div>
          <div className="right-column">
            {renderGroupBulkActions()}
          </div>
        </div>
      </LoadingContent>
    </BasicModal>
  );
};

export default GroupDetails;
