import React, { useEffect, useState } from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import { useSelector } from 'react-redux';
import InfoTooltip from '../../../dashboard/tooltips/InfoTooltip';
import LoadingContent from '../../../layout/LoadingContent';
import BasicDoughnutChart from '../../../dashboard/charts/BasicDoughnutChart';
import DashboardWidgetContainer from '../../common/dashboard/DashboardWidgetContainer';
import API from '../../../../api';
import { mapDeviceStatusToRollUpData, getRollUpTotal } from '../../../../utils/rollUpHelper';
import {
  deviceStatusColorConfig,
  deviceStatusContentStyle,
  deviceStatusTooltipConfig,
} from '../../../../constants/rollUp';
import companyIdSelector from '../../../../selectors/companyIdSelector';
import useCustomQuery from '../../../../hooks/useCustomQuery';

const DeviceStatus = (props) => {
  //  Initializing API
  const { DeviceAPI } = API;

  //  Watching redux store
  const company_id = useSelector((state) => companyIdSelector(state));
  const solution_id = useSelector((state) => state?.issues?.filters?.solution_id);

  //  Component state
  const [rollUpData, setRollUpData] = useState([]);
  const [rollUpTotal, setRollUpTotal] = useState(0);

  //  Calling API
  const {
    data: deviceStatusData,
    error: deviceStatusError,
    isLoading: deviceStatusLoading,
    refetch: refechDeviceStatus,
  } = useCustomQuery(
    [DeviceAPI.keys.getDeviceStatus, { company_id, solution_id }],
    ({ queryKey }) => DeviceAPI.handlers.getDeviceStatus(queryKey[1]),
  );

  //  Watching data changes
  useEffect(() => {
    if (!deviceStatusData) { return; }
    const newData = mapDeviceStatusToRollUpData(deviceStatusData?.device_status || {});
    setRollUpData(newData);
    setRollUpTotal(getRollUpTotal(newData));
  }, [deviceStatusData]);

  //  Rendering
  return (
    <DashboardWidgetContainer
      hasMessage
      message="Your devices by issue criticality"
      containerClassName="dashboard-widget-container"
    >
      <div className="dashboard-rollup-container">
        <Col
          sm={12}
          lg={12}
          className={`rollup-widget-container align-self-start align-self-lg-stretch mb-2`}
        >
          <Card className="h-100 mb-3 mb-md-0 task-criticality-rollup">
            <CardBody className="rollup-widget d-flex flex-column">
              <div className="d-flex align-items-center">
                <InfoTooltip
                  label={deviceStatusTooltipConfig?.title}
                  info={deviceStatusTooltipConfig?.titleTooltip}
                  wrapperClassName="dashboard-widget-title"
                  id={`rollup-tooltip-${deviceStatusTooltipConfig?.componentId}`}
                  showTooltip={false}
                />
              </div>
              <div className="d-flex flex-grow-1 align-items-center doughnut-chart-container">
                <LoadingContent
                  isLoading={deviceStatusLoading}
                  errorMessage={
                    deviceStatusError ? "Oops! our fault, reload again." : null
                  }
                  iconType="solidIcon"
                  errorStyle={{ minHeight: "150px", paddingTop: "16px" }}
                  loadingStyle={{ minHeight: "150px", paddingTop: "16px" }}
                  errorButtonCallback={refechDeviceStatus}
                >
                  <div style={deviceStatusContentStyle}>
                    <BasicDoughnutChart
                      data={rollUpData}
                      colorsConfig={deviceStatusColorConfig}
                      totalLabelUnit="Devices"
                      total={rollUpTotal}
                      zeroLabel={null}
                      onLegendClicked={(element) => { }}
                      onTotalClicked={(elements) => { }}
                      legendWidth={null}
                    />
                  </div>
                </LoadingContent>
              </div>
            </CardBody>
          </Card>
        </Col>
      </div>
    </DashboardWidgetContainer>
  );
};

export default DeviceStatus;
