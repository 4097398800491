import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertFromRaw, EditorState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import _ from 'lodash';

const CommentFromEditor = (props) => {
    const {
        rawContent,
        text,
        hidden = false,
        placeholder,
        fromText=false
    } = props;

    const [editorState,setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        setEditorState(createEditorState(rawContent, text, fromText))
    }, [rawContent, text, fromText])

    const createEditorState = (rawContent, text, fromText) => {
        if(fromText){
            const content = ContentState.createFromText(text || "");
            return EditorState.createWithContent(content)
        }else {
           return  _.isNil(rawContent) ? EditorState.createEmpty() : EditorState.createWithContent(convertFromRaw(rawContent))
        }
    }

    return (
        <Editor
            wrapperClassName="editor-comment-wrapper"
            editorClassName={`editor-comment ${hidden ? 'hidden-comment': ''}`}
            toolbarHidden={true}
            readOnly={true}
            editorState={editorState}
            placeholder={placeholder}
        />
    )
}
const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        const { user } = state.auth;
      return {
        username: user.name
      }
    }
    return mapStateToProps;
}
export default connect(makeMapStateToProps, null)(CommentFromEditor);
