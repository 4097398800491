import React from 'react';
import moment from "moment-timezone";
import { Badge } from "reactstrap";
import Avatar from "../components/layout/avatars/Avatar";
import UserManagerActions from "../components/profile/userManagement/UserManagerActions";
import UserStatusSwitch from "../components/profile/userManagement/UserStatusSwitch";
import { timezoneAbbreviations } from "../utils/dateFormatter";
import { formatDate, getTimeString } from "../utils/helpers";
import { cellTypes, headerTypes } from "./tableTemplates";

export const roles = {
  client: 'Member',
  client_admin: 'Admin'
};

// Function to render user name
const renderUserName = (row) => {
  return (
    <>
      {row?.full_name && (
        <Avatar
          key={`user-name-${row?.id}`}
          id={`user-tooltip-${row.full_name}`}
          customClassName='user-manager-avatar'
          user={row}
        />
      )}
      {row?.full_name}
    </>
  );
}

const renderRoleComponent = (role_name) => {
  const role = roles?.[role_name];
  return (
    <Badge className={`badge-role-${role?.toLowerCase()}`}>
      {role}
    </Badge>
  );
};

const renderToggleSwitch = (user) => {
  return user?.verified ? (
    <UserStatusSwitch user={user} />
  ) : (
    <p className="requested-text">Requested</p>
  );
};

const renderActions = (user) => {
  return (
    <UserManagerActions 
      user={user} 
      isActive={user?.active}
      isVerified={user?.verified}
    />
  );
};

const getGMTOffset = () => {
  moment.fn.zoneName = function () {
    var abbr = this.zoneAbbr();
    return timezoneAbbreviations [abbr] || abbr;
  }
  const currentDate = moment.tz(new Date().toString(), moment.tz.guess());
  const offset = currentDate.format('ZZ');
  return ` (GMT ${offset})`;
};

//  Template for attachments
export const userManagerTableTemplate = [
  {
    label: 'Member name',
    allowSorting: true,
    allowSelect: true,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.SELECTABLE_CUSTOM,
    headerClassName: 'header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell ellipsed-cell',
    entry: 'full_name',
    customRender: (row) => renderUserName(row),
    customParams: { objectName: 'user', valueName: 'full_name' },
  },
  {
    label: 'Email',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    entry: 'email',
    headerClassName: 'header-cell header-text-cell extra-large-cell',
    cellClassName: 'regular-cell extra-large-cell ellipsed-cell',
  },
  {
    label: 'Role',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    entry: 'role_name',
    headerClassName: 'header-cell header-text-cell small-cell',
    cellClassName: 'regular-cell small-cell custom-text-cell',
    customRender: ({role_name}) => renderRoleComponent(role_name),
    customParams: { objectName: 'user', valueName: 'role_name' },
  },
  {
    label: 'Status',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: 'header-cell header-text-cell small-cell',
    cellClassName: 'regular-cell small-cell',
    entry: 'active',
    customRender: ({user}) => renderToggleSwitch(user),
    customParams: { objectName: 'user', valueName: 'active' },
  },
  {
    label: 'Actions',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    entry: 'public_id',
    headerClassName: 'header-cell header-text-cell medium-cell',
    cellClassName: 'regular-cell medium-cell',
    customRender: ({user}) => renderActions(user),
    customParams: { objectName: 'user', valueName: 'public_id' },
  },
  /**
  {
    label: 'Last login',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: 'header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell bottom-padded-cell custom-text-cell',
    entry: 'created_at',
    customRender: ({created_at}) => formatDate(created_at) + ' at ' + getTimeString(created_at, false) + getGMTOffset(),
    customParams: { objectName: 'user', valueName: 'created_at' },
  },
   */
  {
    label: 'Date added',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: 'header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell bottom-padded-cell custom-text-cell',
    entry: 'created_at',
    customRender: ({created_at}) => formatDate(created_at) + ' at ' + getTimeString(created_at, false) + getGMTOffset(),
    customParams: { objectName: 'user', valueName: 'created_at' },
  },
];