import React from 'react';

const InfiniteScrollingLoading = (props) => {
  //  Parsing props
  const {
    label = 'Loading more',
    iconClassName = 'bx bx-hourglass',
    containerClassName = 'infinite-scrolling-loading',
    labelClassName = 'loading-label'
  } = props;

  //  Rendering
  return (
    <div className={containerClassName}>
      <div className={iconClassName} />
      <div className={labelClassName}>
        {label}
      </div>
    </div>
  );
};

export default InfiniteScrollingLoading;
