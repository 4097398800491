import React from 'react';
import { capitalizeWords } from "./helpers";
import { colorsAssignedTo, colorsSolutions } from "../constants/dashboard";
import { issueTypes } from "../constants/issues";
import { statusesConfig } from '../constants/status';
import { getStatusLabelByUserType } from './issuesHelpers';
import { USER_TYPES } from '../constants/common';


// Arrange data
export const arrangeStatusData = (rollupData, sumAll, widgetTitle,issueType = issueTypes.VULNERABILITIES, userType, componentId) => {
    const data = rollupData?.by_status;
    const statuses = getRollupStatusesByIssueType(issueType);

    const arrangedData = statuses.reduce((statusesData, status) => {
      const {
          statusesArray,
          sum
      } = statusesData;
      const statusId = parseInt(status?.id);
      const relabeledStatus = getStatusLabelByUserType(status, userType);
      const element = data?.[statusId];

      if(!element) return statusesData;

      const elementTotal = parseInt(element?.total || 0);
      if(userType === USER_TYPES.MEMBER && relabeledStatus === 'To Do') {
          const toDoIndex = statusesArray?.findIndex((element) => element?.label === relabeledStatus);
          if(toDoIndex > -1) {
              const total = parseInt(statusesArray[toDoIndex]?.value || 0)
              statusesArray[toDoIndex].value = total + elementTotal
              if(status?.label === 'To Do') {
                  statusesArray[toDoIndex].id = statusId
              }
              return {
                  statusesArray,
                  sum: sum + elementTotal
              }
          }
      }

      return {
          statusesArray: [
              ...statusesArray,
              {
                  id: statusId,
                  label: relabeledStatus,
                  value: elementTotal
              }
          ],
          sum: sum + elementTotal,
      }
  }, { statusesArray: [], sum: 0 })

  const title = widgetTitle ? widgetTitle : `Current Status`;

  return {
    data: arrangedData?.statusesArray,
    total: arrangedData?.sum,
    colorsConfig: getRollupStatusColorConfig(userType),
    title: title,
    unitLabel: `${issueType === issueTypes.GRC ? 'tasks' : 'issues'}`,
    titleTooltip: "set",
    componentId: componentId || "current-status-rollup-dashboard",
  };
};

export const arrangeCriticalityData = (rollupData, widgetTitle,issueType = issueTypes.VULNERABILITIES, unitLabel , componentId ) => {
    const data = rollupData?.by_severity;
    let sum = 0;
    const arrangedData = [];
    if (data) {
      Object.keys(data).forEach((key) => {
        const element = data[key];
        if (element?.hidden) {
          return;
        }
        arrangedData.push({
          label: element?.label,
          value: parseInt(element?.total || 0),
          id: key,
        });
        sum += parseInt(element?.total || 0);
      });
    }

    const title = widgetTitle ? widgetTitle : `Open Issues by Criticality`;

    return {
      data: arrangedData,
      total: sum,
      colorsConfig: {
        Critical: "#FF5E54",
        High: "#F1A04C",
        Medium: "#F5D302",
        Low: "#0EB082",
      },
      title: title,
      subtitle: `${title}:`,
      unitLabel: unitLabel === null || unitLabel === undefined ? `open ${issueType === issueTypes.GRC ? 'tasks' : 'issues'}` : unitLabel,
      titleTooltip: "set",
      componentId: componentId === null || componentId === undefined ? "criticality-rollup-dashboard" : componentId,
    };
};

export const arrangeAssignedToData = (chartData) => {
    const data = chartData?.assignees_distribution;
    let sum = 0;
    const arrangedData = [];
    const xAxisCategories = [];
    if (data) {
        Object.keys(data).forEach((key) => {
            const element = data[key];
            const label = capitalizeWords(key);
            const elemColor = colorsAssignedTo[key.toUpperCase()];
            xAxisCategories.push(label);
            arrangedData.push({
                label: label,
                value: parseInt(element || 0),
                color: elemColor,
            });
            sum += parseInt(element || 0);
        });
    }

    return {
        data: arrangedData,
        total: sum,
        xAxisCategories,
    };
};

export const arrangeCurrentProtectionData = (chartData) => {
  const data = chartData?.current_protection_status;
  let sumPatches = 0;
  let sumScans = 0;
  const patchesData = [];
  const scansData = [];
  const xAxisCategories = [];
  if (data) {
    Object.keys(data).forEach((key) => {
        const element = data[key];
        const label = element?.name.toUpperCase();
        const elemColor = colorsSolutions[element?.name.toUpperCase()];
        xAxisCategories.push(label);
        scansData.push({
            label: label,
            value: element?.scans,
            color: elemColor,
            id: element?.id
        });
        sumScans += parseInt(element?.scans || 0);
        patchesData.push({
            label: label,
            value: element?.show_patches ? element?.patches : null,
            color: elemColor,
            id: element?.id
        });
        sumPatches += parseInt(element?.patches || 0);
    });
  }
  return {
    data: {
      patches: patchesData,
      scans: scansData
    },
    total: {
      patches: sumPatches,
      scans: sumScans
    },
    xAxisCategories
  };
};


// Format data
export const formatData = (data,total,solution_id) => {
  if (total===0) return [];
  return data.map(({label,value,color, id}) => {
      return {
      x: label,
      y: parseInt(value),
      fillColor: colorConfig(id,color,solution_id),
      }
  })
}

const colorConfig = (id, color='#787BEF',solution_id) => {
if (id==null || solution_id===null) return color;
return (solution_id===id ? color : '#74788d')
};


// Empty State
export const renderEmptyStateMessage = (loading, text = '') => {
  if (loading) return;
  return (
      <div className='chart-emtpy-state-background overlay'>
          <div className='empty-title text-center'>
            {text || 'All quiet so far :)'}
          </div>
      </div>
  )
}


export const getRollupStatusColorConfig = (userType) => {
  const colorsConfig = {};
  statusesConfig.forEach((status) => {
    const { label, color } = status;
    const labelToUse = getStatusLabelByUserType(status, userType)
    colorsConfig[labelToUse] = color
  })

  return colorsConfig
}

export const getRollupStatusesByIssueType = (issueType = issueTypes.VULNERABILITIES) => {
  // Sort statuses by order
  const orderedStatuses = statusesConfig.sort((a,b) => {
    return a?.order - b?.order
  });
  const excludedStatusNames = ['Remediation Plan']

  if(issueType === issueTypes.GRC) {
    excludedStatusNames.push('Member Review')
  }

  return  orderedStatuses.reduce((filteredStatuses, status) => {
    const isHidden = excludedStatusNames.includes(status?.label);
    if(isHidden) {
      return filteredStatuses
    } else {
      return [
        ...filteredStatuses,
        status
      ]
    }
  }, [])
}

export const emptyIcarmIssuesBoxesTemplate = [
  {
    color: "#34c38f",
    value: 0,
    label: "Low",
    className: "empty",
  },
  {
    color: "#f5d302",
    value: 0,
    label: "Medium",
    className: "empty",
  },
  {
    color: "#f1a04c",
    value: 0,
    label: "High",
    className: "empty",
  },
  {
    color: "#f46a6a",
    value: 0,
    label: "Critical",
    className: "empty",
  },
];

export const getIcarmSolutionRollupData = (critical, high, medium, low) => {
  return {by_severity : {
    "100": {
        "label": "Critical",
        "total": critical || 0,
        "hidden": false
    },
    "200": {
        "label": "High",
        "total": high || 0,
        "hidden": false
    },
    "300": {
        "label": "Medium",
        "total": medium || 0,
        "hidden": false
    },
    "400": {
        "label": "Low",
        "total": low || 0,
        "hidden": false
    },
  }};
};

export const extractCriticalityValuebySolution = (solutions, criticality) => {
  const sum = solutions?.reduce((result, solution) => result + (solution?.issues_resolved?.[criticality] || 0), 0);
  return sum;
};

export const getTotalIssuesResolved = (solutions) => {
  const lowTotal = extractCriticalityValuebySolution(solutions,'Low');
  const mediumTotal = extractCriticalityValuebySolution(solutions,'Medium');
  const hightotal = extractCriticalityValuebySolution(solutions,'High');
  const criticalTotal = extractCriticalityValuebySolution(solutions,'Critical');
  return lowTotal + mediumTotal + hightotal + criticalTotal;
};

export const getFormattedDateYDM = (date, separator) => {
  return date.getFullYear() + separator + date.getDate() + separator + (date.getMonth()+ 1);
}

export const getNewIcarmStep = (step) => {
  if (step !== 'Maintenance') {
    return 'Onboarding';
  }
  return step;
}

export const unavailableSolutionDistributionScoreInfo = () => {
  return {'' : {
    title: "Not mapped in Cyvatar",
    info: "Unfortunately, we don't have yet a solution mapped out for this control, contact one of our Solution Advisors to provide additional guidance to help you explore possible solutions.",
  }};
};