import {
  CHANGE_SUBTABS,
  ON_SIDEBAR_TAB_SELECTED,
  ON_SUBTAB_SELECTED,
  ON_TOPBAR_ACTION_TRIGGERED,
  SET_BACK_LINK_LABEL
} from './types';

export const onSidebarTabSelected = (currentPath, userRole, filterAllowed, freemiumStatus = 1, isSales, activeImpersonation) => {
  return {
    type: ON_SIDEBAR_TAB_SELECTED,
    payload: { path: currentPath, userRole, filterAllowed, freemiumStatus, isSales, activeImpersonation },
  };
};

export const onSubtabSelected = (tabToSelect) => {
  return {
    type: ON_SUBTAB_SELECTED,
    payload: tabToSelect,
  };
};

export const triggerTopbarAction = (actionType, params) => {
  return {
    type: ON_TOPBAR_ACTION_TRIGGERED,
    payload: { actionType, params },
  };
};

export const changeSubtabs = (subtabs) => {
  return {
    type: CHANGE_SUBTABS,
    payload: subtabs
  };
}

export const setBackLink = (label, show, path, searchParams) => {
  return {
    type: SET_BACK_LINK_LABEL,
    payload: {
      show,
      label,
      path,
      searchParams
    }
  }
}
