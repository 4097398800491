import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Col, Card, CardBody, Table, Button } from "reactstrap";
import LoadingContent from "../layout/LoadingContent";
import makeLoadingState from "../../selectors/loadingSelector";
import makeErrorMessage from "../../selectors/errorsSelector";
import {
  setIssuesFilter,
  setIssuesSort,
  clearIssuesFilters,
  onDeleteModalConfigUpdate,
  onReloadIssuesRollup,
  onReloadIssues,
  onUpdateCurrentPage,
} from "../../actions/issues";
import {
  compareObjects,
  isValidFunction,
  getDefaultCellAriaLabel,
  getSafeArray,
  getItemsPerPageByConfig,
} from "../../utils/helpers";
import { successActionCreator } from "../../actions/action-creators/success";
import { errorActionCreator } from "../../actions/action-creators/errors";
import { ISSUES_LIST_SUCCESS, ISSUES_LIST_FAILURE } from "../../actions/types";
import IssueDemoReminder from "./IssueDemoReminder";
import ActiveFiltersList from "../layout/filters/ActiveFiltersList";
import makeFreemiumValidations from "../../selectors/freemiumStatusSelector";
import IssuesListToolbar from "./IssuesListToolbar";
import CustomCheckBox from "../layout/CustomCheckBox";
import SortButtons from "../layout/buttons/SortButtons";
import { onSelectElement } from "../../utils/bulkActionsHelper";
import API from "../../api";
import IssuesListBulkActions from "./IssuesListBulkActions";
import { issuesStickyDivsIds } from "../../constants/common";
import useMemoizedDispatch from "../../hooks/useMemoizedDispatch";
import { getAccumulatedHeights } from "../../utils/scrollHelpers";
import IssueCreate from "./IssueCreate";
import { initializeIssuesConfig } from "../../utils/queryHelpers";
import useCustomQuery from "../../hooks/useCustomQuery";
import { issueTypes } from "../../constants/issues";
import { ENTER_KEY_NAME } from "../../constants/keyNames";
import useCanAbility from "../../hooks/useCanAbility";
import { CREATE_ACTION, PRIVILEGE_SOURCES } from "../../constants/privileges";
import usePrevious from "../../hooks/usePrevious";
import useUserType from '../../hooks/useUserType';
import { getIssuesTableTemplate } from "../../constants/app-v4/issuesTableTemplate";
import useIssueInfoByGroupParam from "../../hooks/useIssueInfoByGroupParam";
import amplitude from 'amplitude-js';
import { useLocation } from 'react-router-dom';

const IssuesList = (props) => {
  //  Parsing props
  const {
    refs,
    getCurrentAccumulatedHeight,
    scrollableRef,
    showIssueDetails,
    collapsed,
    onIssueClicked,
  } = props;

  //  Initializing APIs
  const { IssueAPI } = API;

  //  Selectors
  const getLoadingState = makeLoadingState(["ISSUES_LIST"]);
  const getErrorMessage = makeErrorMessage(["ISSUES_LIST"]);
  const getFreemiumValidations = makeFreemiumValidations();

  //  Watching redux store
  const isLoading = useSelector((state) => getLoadingState(state));
  const errorMessage = useSelector((state) => getErrorMessage(state));
  const freemiumStatus = useSelector((state) => state.auth.company?.freemium);
  const showDemoData = useSelector((state) => state.auth.company?.showDemoData);
  const { lastScanIsValid } = useSelector((state) => getFreemiumValidations(state));
  const lastScan = useSelector((state) => state.auth.company?.lastScan);
  const {
    filters,
    activeFilters,
    searchTerm,
    sortField,
    issueType,
    shouldReload,
    currentPage,
    issues,
    total,
    deleteModalConfig,
  } = useIssueInfoByGroupParam();

  // Component state
  const [perPage] = useState(getItemsPerPageByConfig(process.env.REACT_APP_ITEMS_PER_PAGE_CONFIG, 'issues', 50));
  const [selectedIssues, setSelectedIssues] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [issuesIds, setIssuesIds] = useState([]);
  const [tableHeights, setTableHeights] = useState([]);
  const [showCreateIssueModal, setShowCreateIssueModal] = useState(false);
  const userType = useUserType();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const notificationFilter = queryParams.get("filter");
  const [issuesConfig, setIssuesConfig] = useState(
    initializeIssuesConfig(
      currentPage + 1,
      perPage,
      searchTerm,
      sortField,
      filters,
      userType,
      notificationFilter
    )
  );
  //  Component variables
  const tableRef = useRef(null);
  const prevFilters = usePrevious(filters);
  const issueRowPrefix = "issue-row-";

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const {
    data: issuesResponse,
    error: issuesError,
    isLoading: issuesLoading,
  } = useCustomQuery(
    [IssueAPI.keys.getIssuesList, issuesConfig],
    ({ queryKey }) => IssueAPI.handlers.getIssuesList(queryKey[1])
  );
  const ability = useCanAbility();

  //  Watching issues list response
  useEffect(() => {
    if (!issuesResponse) return;

    dispatch(successActionCreator(ISSUES_LIST_SUCCESS, issuesResponse?.data));
  }, [issuesResponse]);

  //  Watching issues list error
  useEffect(() => {
    if (!issuesError) {
      return;
    }
    const message = "Error getting issues.";
    dispatch(errorActionCreator(ISSUES_LIST_FAILURE, { message }));
  }, [issuesError]);

  // Getting neede info from redux store
  const isMemberView = useSelector((state) => state.impersonation.isMemberView);

  const fetchIssuesList = (selectedPage = currentPage) => {
    const newIssuesConfig = initializeIssuesConfig(
      selectedPage + 1,
      perPage,
      searchTerm,
      sortField,
      filters,
      userType,
      notificationFilter
    );
    dispatch(onUpdateCurrentPage(selectedPage, issueType));

    setIssuesConfig(newIssuesConfig);
  };

  const onTryAgainButtonClick = () => {
    fetchIssuesList();
  };

  //  Watching DOM changes to update header height
  useEffect(() => {
    setTableHeights(
      getAccumulatedHeights(1, 4, getCurrentAccumulatedHeight, -1)
    );
  }, [
    collapsed,
    getCurrentAccumulatedHeight(4),
    selectAll,
    selectedIssues?.length,
  ]);

  useEffect(() => {
    fetchIssuesList(0);
    setSelectAll(false);
    setSelectedIssues([]);
  }, [perPage, sortField, searchTerm]);

  useEffect(() => {
    fetchIssuesList(compareObjects(prevFilters, filters) ? currentPage : 0);
    setSelectAll(false);
    setSelectedIssues([]);
  }, [filters]);

  useEffect(() => {
    fetchIssuesList(currentPage);
    setSelectAll(false);
    setSelectedIssues([]);
  }, [currentPage]);

  // watch changes in issues to update array of issueIds
  useEffect(() => {
    const ids = issues.map((issue) => issue.id);
    setIssuesIds(ids);
  }, [issues]);

  // watch selectAll changes to update selectedIssues array
  useEffect(() => {
    if (selectAll) {
      setSelectedIssues(issuesIds);
    } else {
      setSelectedIssues([]);
    }
  }, [selectAll]);

  useEffect(() => {
    if (shouldReload) {
      //  Clearing selected state
      setSelectedIssues([]);
      setSelectAll(false);

      //  Getting issue list and resetting flag
      fetchIssuesList();
      dispatch(onReloadIssues(false, issueType));
    }
  }, [shouldReload]);

  //  Watchiing delete issues success flag
  useEffect(() => {
    if (!deleteModalConfig?.success || showIssueDetails) {
      return;
    }
    dispatch(
      onDeleteModalConfigUpdate(false, [], false, issueType)
    );
    dispatch(onReloadIssues(true, issueType));
    dispatch(onReloadIssuesRollup(true, issueType));
    setSelectedIssues([]);
    setSelectAll(false);
  }, [deleteModalConfig?.success, showIssueDetails]);

  // Gets the top postion of div
  const getStickyTopByIndex = (index) => {
    return tableHeights[index - 1];
  };

  const setFilterWithIssueType = (filterName, value, label, type, filterOption) => {
    dispatch(setIssuesFilter(filterName, value, label, type, filterOption, false, issueType))
  }

  const renderActiveFilters = () => {
    let filteredActiveFilters = [...getSafeArray(activeFilters)];
    const isGroupFiltered = issueTypes.GROUP_INDIVIDUAL === issueType;
    if (isGroupFiltered) {
      const index = filteredActiveFilters.findIndex((activeFilter) => activeFilter?.filterName === 'company_id');
      if (index > -1) {
        filteredActiveFilters.splice(index, 1)
      }
    }

    const groupTitle = "Listing issues for group:";
    const normalTitle = filteredActiveFilters?.length > 0 ? "Listing Issues filtered by:" : "Listing all issues";
    const title = isGroupFiltered ? groupTitle : normalTitle;

    return (
      <ActiveFiltersList
        title={title}
        list={filteredActiveFilters}
        total={{ number: total, label: `issue${total !== 1 ? "s" : ""}` }}
        setFilter={setFilterWithIssueType}
        clearAllFilters={() =>
          dispatch(clearIssuesFilters(isMemberView, issueType))
        }
      />
    );
  };

  const onIssueCheckboxSelect = (id, isSelected) => {
    const updatedList = onSelectElement(id, isSelected, selectedIssues);
    setSelectedIssues(updatedList);
  };

  const renderIssueCheckBox = (issueId, code) => {
    const isIssueSelected = selectedIssues?.includes(issueId);
    return (
      <CustomCheckBox
        isSelected={isIssueSelected}
        targetId={`issue-checkbox-${code}`}
        callback={(isSelected) => {
          onIssueCheckboxSelect(issueId, isSelected);
        }}
      />
    );
  };

  // Component for selecting all elements in the table
  const renderSelectAllComponent = () => {
    return (
      <CustomCheckBox
        isSelected={selectAll}
        targetId="select-all-issues-checkbox"
        callback={(isSelected) => {
          setSelectAll(!isSelected);
        }}
      />
    );
  };

  const renderSortButtons = (columnData) => {
    const { sortFieldName, displayName } = columnData;
    return (
      <SortButtons
        fieldName={sortFieldName}
        sortFieldName={sortField?.name}
        isAsc={sortField?.asc}
        label={displayName}
        callback={() => {
          const sortParams = {
            name: sortFieldName,
            asc: sortFieldName !== sortField.name ? true : !sortField.asc,
          };
          dispatch(setIssuesSort(sortParams, issueType));
        }}
      />
    );
  };

  const renderColumnHeaders = () => {
    const stickyDivIndex = 4;
    const top = getStickyTopByIndex(stickyDivIndex);
    return (
      <thead
        id={issuesStickyDivsIds[stickyDivIndex]}
        className="sticky-element bg-dark"
        ref={refs[4]}
        style={{ top }}
      >
        <tr
          className="sticky-element"
          style={{ top }}
          tabIndex="0"
          aria-label="Inside table header"
        >
          {getIssuesColumns().map((columnData, index) => {
            const {
              fieldName,
              displayName,
              headerClassName,
              renderCustomHeader,
            } = columnData;
            return (
              <th
                key={`${fieldName}-${index}`}
                className={`sticky-element ${headerClassName || ''}`}
                style={{ top }}
              >
                {fieldName ? renderSortButtons(columnData) : displayName}
                {isValidFunction(renderCustomHeader) && renderCustomHeader()}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  const onPageChange = ({ selected: selectedPage }) => {
    dispatch(onUpdateCurrentPage(selectedPage, issueType));
    setSelectAll(false);
  };

  const getAriaLabel = (issue, fieldName, displayName, getCustomArialabel) => {
    return isValidFunction(getCustomArialabel)
      ? getCustomArialabel(issue)
      : getDefaultCellAriaLabel(issue[fieldName], displayName);
  };

  const renderCell = (columnData, issue, index) => {
    const {
      fieldName,
      cellClassName,
      renderCustomComponent,
      getCustomAriaLabel,
      displayName,
    } = columnData;

    return (
      <td
        key={`issue-cell-${fieldName}-${index}`}
        tabIndex="0"
        role="cell"
        className={cellClassName || ''}
      >
        <div
          aria-label={getAriaLabel(
            issue,
            fieldName,
            displayName,
            getCustomAriaLabel
          )}
        >
          {isValidFunction(renderCustomComponent)
            ? renderCustomComponent(issue)
            : issue[fieldName]}
        </div>
      </td>
    );
  };

  const getIssuesColumns = () => {
    return [
      {
        displayName: null,
        fieldName: null,
        sortFieldName: null,
        renderCustomHeader: renderSelectAllComponent,
        renderCustomComponent: (issue) =>
          renderIssueCheckBox(issue?.id, issue?.code),
      },
      ...getIssuesTableTemplate(userType),
    ];
  };

  const renderIssues = () => {
    return issues.map((issue, index) => {
      const { code, id } = issue;

      return (
        <tr
          id={`${issueRowPrefix}${id}`}
          key={`${code}-${index}`}
          onClick={() => onIssueClicked(code, index)}
          onKeyPress={(e) =>
            e?.key === ENTER_KEY_NAME ? onIssueClicked(code, index) : null
          }
          tabIndex="0"
          role="row"
          aria-label={`Issue ${code}`}
          aria-hidden="false"
        >
          {getIssuesColumns().map((column, index) =>
            renderCell(column, issue, index)
          )}
        </tr>
      );
    });
  };

  const renderToolbar = () => {
    const stickyDivIndex = 2;
    const top = getStickyTopByIndex(stickyDivIndex);

    return (
      <IssuesListToolbar
        containerRef={refs[stickyDivIndex]}
        containerId={issuesStickyDivsIds[stickyDivIndex]}
        containerExtraClassName="sticky-element issues-manager-toolbar"
        containerStyle={{ top }}
        onPageChange={onPageChange}
        currentPage={currentPage}
      />
    );
  };

  const renderTable = () => {
    const grayoutClassName =
      freemiumStatus !== 0 && showDemoData && lastScan && !lastScanIsValid
        ? "grayout-div"
        : "";
    return (
      <div className={`${grayoutClassName} px-3 bg-violet`}>
        <div
          className="table-responsive issues-list vulnerabilities-issues-list"
          style={{ overflowX: "unset" }}
        >
          <Table
            className="table-centered table-hover pointer issue-manager-table"
            role="table"
            aria-label="Issues"
            tabIndex="-1"
          >
            {renderColumnHeaders()}
            <tbody role="rowgroup">{renderIssues()}</tbody>
          </Table>
        </div>
      </div>
    );
  };

  const onIssueRightClick = (elementId) => {
    if (elementId?.includes(issueRowPrefix)) {
      const id = parseInt(elementId?.replace(issueRowPrefix, ""));
      const isSelected = selectedIssues?.includes(id);
      if (!isSelected) {
        setSelectedIssues([id]);
      }
    }
  };

  const renderBulkupActions = () => {
    const stickyDivIndex = 3;
    const top = getStickyTopByIndex(stickyDivIndex);

    return (
      <IssuesListBulkActions
        selectedIssues={selectedIssues}
        parentRef={tableRef}
        onRightClick={onIssueRightClick}
        bulkMenuId={issuesStickyDivsIds[stickyDivIndex]}
        bulkMenuRef={refs[stickyDivIndex]}
        bulkMenuStyle={{ top }}
        scrollableRef={scrollableRef}
        issueType={issueType}
      />
    );
  };

  const toggleCreateIssueModal = () => {
    if (!showCreateIssueModal) {
      amplitude.getInstance().logEvent(`My Cyso View - Create new issue`);
    }
    setShowCreateIssueModal(!showCreateIssueModal);
  };

  const renderCreateIssueModal = () => {
    return (
      <IssueCreate
        toggleModal={toggleCreateIssueModal}
        showModal={showCreateIssueModal}
        issueType={issueType}
      />
    );
  };

  const renderCreateIssueButton = () => {
    const canCreateIssues =
      ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.ISSUES.GENERAL) ||
      isMemberView;
    if (!canCreateIssues || issueType === issueTypes.GROUP_INDIVIDUAL) return null;
    return (
      <Button
        type="button"
        color="light"
        className="btn-create-issue mb-2 float-right"
        onClick={toggleCreateIssueModal}
      >
        <i className="bx bx-plus-circle mr-2 align-middle"></i>
        Create new issue
      </Button>
    );
  };

  const renderMiddleContainer = () => {
    const stickyDivIndex = 1;
    const top = getStickyTopByIndex(stickyDivIndex);

    return (
      <div
        id={issuesStickyDivsIds[stickyDivIndex]}
        ref={refs[stickyDivIndex]}
        style={{ top }}
        className="table-middle-container d-flex justify-content-end sticky-element bg-dark w-100 pt-2"
      >
        <div className="flex-grow-1">{renderActiveFilters()}</div>
        <div className="middle-buttons-container">
          {renderCreateIssueButton()}
        </div>
      </div>
    );
  };

  return (
    <>
      {renderCreateIssueModal()}
      <div className="w-100" ref={tableRef}>
        {renderMiddleContainer()}
        <Col
          lg={12}
          className="align-self-start"
          style={{ marginBottom: "38px" }}
        >
          <Card className="h-100">
            <CardBody className="p-0">
              {renderToolbar()}
              {renderBulkupActions()}
              <LoadingContent
                isLoading={isLoading || issuesLoading}
                errorMessage={errorMessage}
                errorButtonCallback={onTryAgainButtonClick}
                errorStyle={{ paddingTop: "5%" }}
                loadingStyle={{ minHeight: "303px" }}
              >
                <div>
                  {freemiumStatus !== 0 && showDemoData && (
                    <IssueDemoReminder />
                  )}
                  {renderTable()}
                </div>
              </LoadingContent>
            </CardBody>
          </Card>
        </Col>
      </div>
    </>
  );
};

export default IssuesList;
