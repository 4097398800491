import React from 'react';
import { useSelector } from 'react-redux';
import RuleSetCreationRow from './RuleSetCreationRow';
import { onAddPartner } from '../../../actions/ruleSet';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';

const RuleSetManager = (props) => {
  //  Watching redux store information
  const ruleLength = useSelector((state) => state.ruleSet.rules?.length || 0);

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();

  //  Function to handle partner addition
  const addPartnerToRuleSet = () => {
    dispatch(onAddPartner())
  };

  //  Function to render title
  const renderTitleItem = (iconClassName, label, containerClassName) => {
    return (
      <div className={containerClassName}>
        <div className={iconClassName} />
        <div className="title-label">{label}</div>
      </div>
    );
  };

  //  Function to render rule sets creation rows
  const renderRows = () => {
    let elements = [];

    for (let index = 0; index < ruleLength; index++) {
      elements.push(
        <RuleSetCreationRow
          ruleIndex={index}
          key={index}
        />
      )
    }

    return elements;
  };

  //  Function to render add partner icon
  const renderAddPartner = () => {
    return (
      <div className="creation-row add-action-row">
        <div
          className="add-action-container add-partner-container"
          onClick={() => addPartnerToRuleSet()}
        >
          <div className="bx bx-plus-circle add-action-icon" />
          <div className="add-action-label">Add Partner</div>
        </div>
      </div>
    );
  }

  //  Rendering
  return (
    <div className="rule-set-manager">
      <div className="manager-title">
        {renderTitleItem('bx bxs-bot title-icon', 'Automate', 'title-item left-side-wrapper')}
        {renderTitleItem('bx bxs-time-five title-icon', 'Schedule for', 'title-item right-side-wrapper')}
      </div>
      <div className="manager-body">
        {renderRows()}
        {renderAddPartner()}
      </div>
    </div>
  );
};

export default RuleSetManager;
