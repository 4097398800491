import React from 'react';
import _ from "lodash";
import IssueName from "../../components/issue-manager/IssueName";
import { issueTypes } from "../issues";
import { UncontrolledTooltip } from 'reactstrap';
import { getDeviceEntityIPAddresses } from '../../utils/deviceEntityHelper';
import criticalityConfig from '../criticality';
import { formatDate, getDefaultCellAriaLabel, getSafeArray, getTableText } from '../../utils/helpers';
import CriticalityBadge from '../../components/dashboard/CriticalityBadge';
import IssueStatusBage from '../../components/issue-manager/IssueStatusBage';
import { getStatusLabelById, renderIssuesAssignees } from '../../utils/issuesHelpers';
import IssueExploitable from '../../components/issue-manager/IssueExploitable';
import MultilineTextCell from '../../components/common/MultilineTextCell';
import IssueCodeCopy from '../../components/issue-manager/IssueCodeCopy';

// Render Issue Name
const renderIssueName = (issue) => {
  const { name, parent_issue_id, code, os_version } = issue;
  return (
    <IssueName
      name={name}
      parentIssueId={parent_issue_id}
      code={code}
      osVersion={os_version}
      issueType={issueTypes.VULNERABILITIES}
      showIssueType={false}
    />
  );
};

const renderDeviceInfo = (issue) => {
  const { code, entity, entity_name } = issue;
  //  Getting device info to display
  let entityInfo = entity_name || '';
  const addresses = getDeviceEntityIPAddresses(entity);
  entityInfo += addresses ? `${entityInfo ? ', ' : ''}${addresses}` : '';

  const buttonStyle = {
    maxWidth: "85px",
    maxHeight: "67px",
    fontSize: "13px",
  };
  const buttonClassName = `btn btn-link text-white text-decoration-none p-0 text-truncate mb-0 shadow-none`;
  return (
    <>
      <button
        id={`issue-device-${code}`}
        className={buttonClassName}
        style={buttonStyle}
        tabIndex="-1"
      >
        {entityInfo}
      </button>

      {entityInfo && (
        <UncontrolledTooltip placement="bottom" target={`issue-device-${code}`}>
          {entityInfo}
        </UncontrolledTooltip>
      )}
    </>
  );
};
//  Render Device
const renderEntityCell = (issue) => {
  const { code, parent_issue_id, sub_tasks = [], entity_name } = issue;
  const ariaLabelledBy = entity_name ?` issue-device-${code}` : `deviceInfo-${code}`;

  return (
    <div aria-labelledby={`${ariaLabelledBy} device-info-tag`}>
      <p id="device-info-tag" className="d-none">
        , Device Info
      </p>
      <div id={`deviceInfo-${code}`}>
        {entity_name
          ? renderDeviceInfo(issue)
          : _.isNil(parent_issue_id)
          ? `${sub_tasks?.length || 0} devices affected`
          : ""}
      </div>
    </div>
  );
};

// Get Criticality label
const getCriticalityLabel = (severity) => {
  return criticalityConfig[severity]?.label
}

// Render issue criticality badge
const renderCriticalityBadge = (issue) => {
  return (
    <CriticalityBadge
      severity={issue?.severity}
    />
  )
}

// Render issue status badge
const renderStatusBadge = (issue) => {
  return (
    <IssueStatusBage
      status={issue?.status}
    />
  )
}

// Render assignees
const renderAssignees = (issue) => {
  const { assigned_to, assigned_full_name, assigned_rol } = issue;
  const assigned_user = {
    full_name: assigned_full_name,
    id: assigned_to,
    roles: [{
      name: assigned_rol,
    }]
  }
  const assignees = [
    ...assigned_full_name && assigned_to ? [assigned_user] : []
  ]
  return  renderIssuesAssignees(assignees)
}

const renderExploitable = (issue) => {
  return (
    <IssueExploitable
      isExploitable={issue?.partner_fields?.exploit_available}
      targetId={`exploitable-issue-${issue?.id}`}
    />
  )
}

// Render issue code
const renderIssueCode = (issue) => {
  return (
    <IssueCodeCopy
      code={issue?.code}
      issue={issue}
    />
  )
}

export const getIssuesTableTemplate = (userType) => {
  return [
    {
      displayName: "Issue ID",
      fieldName: "code",
      sortFieldName: "code",
      getCustomAriaLabel: (issue) => `Issue ${getDefaultCellAriaLabel(issue?.code, 'Issue ID')}`,
      cellClassName: 'cell-issue-id',
      headerClassName: 'header-issue-id small-padding-cell',
      renderCustomComponent: renderIssueCode,
    },
    {
      displayName: "Exp.",
      fieldName: "exploitable",
      sortFieldName: "exploitable",
      renderCustomComponent: renderExploitable,
      getCustomAriaLabel: (issue) => getDefaultCellAriaLabel(issue?.partner_fields?.exploit_available, 'Exploitable'),
      headerClassName: 'small-padding-cell',
      cellClassName: 'small-padding-cell'
    },
    {
      displayName: "Issue name",
      fieldName: "name",
      sortFieldName: "name",
      renderCustomComponent: renderIssueName,
    },
    {
      displayName: "Criticality",
      fieldName: "severity",
      sortFieldName: "severity",
      headerClassName: 'small-padding-cell',
      cellClassName: 'small-padding-cell',
      renderCustomComponent: renderCriticalityBadge,
      getCustomAriaLabel: (issue) => getDefaultCellAriaLabel(getCriticalityLabel(issue?.severity), 'Criticality')
    },
    {
      displayName: "Status",
      fieldName: "status",
      sortFieldName: "status_order",
      renderCustomComponent: renderStatusBadge,
      getCustomAriaLabel: (issue) => getDefaultCellAriaLabel(getStatusLabelById(issue?.status, userType), "Issue status"),
      headerClassName: 'small-padding-cell',
      cellClassName: 'small-padding-cell',
    },
    {
      displayName: "Member",
      sortFieldName: "company_name",
      fieldName: "company_name",
      cellClassName: '',
      renderCustomComponent: (issue) => <MultilineTextCell text={issue?.company_name} width="98px" />,
      getCustomAriaLabel: (issue) => getDefaultCellAriaLabel(issue?.company_name, 'Member Name')
    },
    {
      displayName: "Device info",
      sortFieldName: "entity_name",
      fieldName: "entity_name",
      renderCustomComponent: renderEntityCell,
      getCustomAriaLabel: () => '',
      cellClassName: 'cell-device-info small-padding-cell',
      headerClassName: 'small-padding-cell'
    },
    {
      displayName: "Solution",
      fieldName: "solution_name",
      sortFieldName: "solution_name",
      headerClassName: "cell-solution",
      renderCustomComponent: (issue) => {
        const solutionName = issue ? (issue.solution_name || issue['solution.abbreviation']) : '-';
        return getTableText(solutionName);
      },
    },
    {
      displayName: "Partner",
      fieldName: "partner_name",
      sortFieldName: "partner_name",
      headerClassName: "cell-partner-name",
      renderCustomComponent: (issue) => {
        const partnerName = issue ? (issue.partner_name || issue['solution.abbreviation']) : '-';
        return getTableText(partnerName);
      },
    },
    {
      displayName: "Assigned to",
      fieldName: "assigned_to",
      sortFieldName: "assigned_full_name",
      renderCustomComponent: renderAssignees,
      headerClassName: 'small-padding-cell',
      cellClassName: 'text-center small-padding-cell',
      getCustomAriaLabel: ({assigned_to, assigned_full_name}) => `Assigned to: ${assigned_to && assigned_full_name ? assigned_full_name : 'Unassigned'}`
    },
    {
      displayName: "Created on",
      fieldName: "created_at",
      sortFieldName: "created_at",
      renderCustomComponent: (issue) => formatDate(issue?.created_at),
      getCustomAriaLabel: (issue) => `Created on: ${formatDate(issue?.created_at)}`
    },
    {
      displayName: "Last action",
      fieldName: "updated_at",
      sortFieldName: "updated_at",
      renderCustomComponent: (issue) => formatDate(issue?.updated_at),
      getCustomAriaLabel: (issue) => `Last action: ${formatDate(issue?.updated_at)}`
    },
  ];
}
