import _ from 'lodash';
import {
    ISSUES_LIST_SUCCESS,
    ISSUE_SUCCESS,
    CLEAR_ALL_ISSUES_FILTERS,
    CLEAR_ISSUES,
    CREATE_ISSUE_SUCCESS,
    ISSUE_MODAL_OPTIONS_SUCCESS,
    ISSUE_STATUSES_SUCCESS,
    RELOAD_ISSUES,
    CLEAR_SELECTED_ISSUE,
    ISSUE_TOTALS_SUCCESS,
    UPDATE_ISSUE_SUCCESS,
    ISSUES_DEMO_SUCCESS,
    SET_ISSUES_FILTER,
    SET_ISSUES_SORT,
    RESET_MODAL_OPTIONS_USERS,
    ISSUE_REMOVE_TAG_SUCCESS,
    UPDATE_REMEDIATION_PLAN_NOTE_SUCCESS,
    APPLY_ADVANCED_FILTERS,
    ISSUE_ADD_TAG_SUCCESS,
    RELOAD_ISSUES_TAGS_LIST,
    ON_RELOAD_ISSUES_ROLLUP,
    LOGOUT_SUCCESS,
    ON_DELETE_MODAL_CONFIG_UPDATE,
    ON_DELETE_ISSUES_SUCCESS,
    SET_ISSUES_FILTER_GROUP,
    ON_PARTNER_MANUAL_ACTION_FINISH,
    ISSUES_UPDATE_CURRENT_PAGE
} from '../actions/types';
import filterTypes from '../constants/filterTypes';
import { removeTagById } from '../utils/tagsHelper';
import { getIssuesFilterGroupState, getIssuesFiltersState } from '../utils/filterHelpers';
import { issueTypes } from '../constants/issues';
import { getIssuesConfig, getIssueTotalsPayload, formatIssueStatuses, getSelectedIssue, getUpdatedIssue} from '../utils/issuesHelpers';
import { issuesFiltersInitialState, issuesInitialState, issuesSortFieldInitialState, selectedIssueInitialState } from '../constants/common';

const initialState = issuesInitialState;
const filtersInitialState = {
  ...issuesFiltersInitialState,
  multiple_company_id: [],
  multiple_solution_id: [],
  group_id: null,
};
const sortFieldInitialState = issuesSortFieldInitialState;

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_ISSUES_FILTER: {
            if(payload.issueType !== issueTypes.GROUP_INDIVIDUAL) return state;
            const  filtersState = state.filters;
            const activeFiltersState = state.activeFilters;
            const { filters, activeFilters, searchTerm } = getIssuesFiltersState(filtersState, activeFiltersState, payload);
            const isSearchFilter = (payload?.type === filterTypes.search);
            return {
                ...state,
                filters,
                activeFilters: [...activeFilters],
                searchTerm: isSearchFilter ? searchTerm : state?.searchTerm
            }
        }
        case SET_ISSUES_FILTER_GROUP : {
            if(payload.issueType !== issueTypes.GROUP_INDIVIDUAL) return state;
            const  filtersState = state.filters;
            const activeFiltersState = state.activeFilters;
            const { filters, activeFilters } = getIssuesFilterGroupState(
                filtersState,
                activeFiltersState,
                payload,
                filtersInitialState
            );
            return {
                ...state,
                filters,
                activeFilters
            }
        }
        case SET_ISSUES_SORT: {
            if(payload.issueType !== issueTypes.GROUP_INDIVIDUAL) return state;
            return {
                ...state,
                sortField: payload?.value
            };
        }

        case APPLY_ADVANCED_FILTERS: {
            if(payload.issueType !== issueTypes.GROUP_INDIVIDUAL) return state;
            return {
                ...state,
                activeFilters: payload.activeFilters,
                filters: payload.filters
            }
        }

        case CLEAR_ALL_ISSUES_FILTERS: {
            if(payload.issueType !== issueTypes.GROUP_INDIVIDUAL) return state;
            if(payload?.isMemberView) {
                return {
                    ...state,
                    filters: {
                      ...filtersInitialState,
                    },
                    activeFilters: [],
                    searchTerm: ''
                }
            }
            return {
                ...state,
                filters: {
                    ...filtersInitialState,
                    group_id: state?.filters?.group_id
                },
                activeFilters: [],
                searchTerm: ''
            };
        }

        case ISSUES_LIST_SUCCESS: {
            return {
                ...state,
                list: payload.data || [],
                pageCount: payload.pagination?.page_count || 1,
                total: payload.pagination?.total || 0
            }
        }

        case CREATE_ISSUE_SUCCESS: {
            if(payload.issueType !== issueTypes.GROUP_INDIVIDUAL) return state;
            const companyFilterName = 'company_id';
            const activeFilters = state.activeFilters || [];
            const companyActiveFilter = activeFilters.find((activeFilter) => activeFilter.filterName === companyFilterName)
            return {
                ...state,
                shouldReload: true,
                shouldReloadRollup: true,
                filters: {
                    ...filtersInitialState,
                    company_id: state?.filters?.company_id,
                    group_id: state?.filters?.group_id
                },
                activeFilters: !payload?.isMemberView && companyActiveFilter ? [companyActiveFilter] : [],
                searchTerm: '',
                sortField: sortFieldInitialState
            }
        }

        case UPDATE_ISSUE_SUCCESS: {
            return {
                ...state,
                selectedIssue: getUpdatedIssue(state, payload)
            }
        }

        case UPDATE_REMEDIATION_PLAN_NOTE_SUCCESS: {
            return {
                ...state,
                selectedIssue: {
                    ...state.selectedIssue,
                    remediationPlanNotes: [payload, ...state.selectedIssue.remediationPlanNotes],
                }
            }
        }

        case ISSUE_SUCCESS: {
            return {
                ...state,
                selectedIssue: getSelectedIssue(payload)

            }
        }

        case ISSUE_MODAL_OPTIONS_SUCCESS: {
            if(payload.issueType !== issueTypes.GROUP_INDIVIDUAL) return state;
            const { modalOptions } = getIssuesConfig(payload?.data);
            return {
                ...state,
                modalOptions
            }
        }

        case ISSUE_STATUSES_SUCCESS: {
            const statusesArray = Object.keys(payload).map(key => {
                return {
                    ...payload[key] || {},
                    id: key
                }
            })

            const statuses = formatIssueStatuses(statusesArray)

            return {
                ...state,
                modalOptions: {
                    ...state.modalOptions,
                    statuses,
                }
            }
        }

        case ISSUE_TOTALS_SUCCESS: {
            if(payload?.issueType !== issueTypes.GROUP_INDIVIDUAL) return state;
            const { totalsBy } = getIssueTotalsPayload(state, payload?.data);
            return {
                ...state,
                totalsBy
            }
        }

        case ISSUES_DEMO_SUCCESS:{
            return {
                ...state,
                shouldReload: payload
            }
        }

        case RELOAD_ISSUES: {
            if(payload?.issueType !== issueTypes.GROUP_INDIVIDUAL) return state
            return {
                ...state,
                shouldReload: payload?.shouldReload
            }
        }

        case RESET_MODAL_OPTIONS_USERS: {
            return {
                ...state,
                modalOptions: {
                    ...state.modalOptions,
                    users: {
                        ...state.modalOptions.users,
                        company_members: null
                    }
                }
            }
        }

        case CLEAR_SELECTED_ISSUE: {
            return {
                ...state,
                selectedIssue: {
                    ...selectedIssueInitialState
                }
            }
        }

        case ISSUE_ADD_TAG_SUCCESS: {
            const { global_tag, is_resource_updated } = payload;
            if (!is_resource_updated) return state;
            const selectedIssue = state.selectedIssue;
            return {
                ...state,
                selectedIssue: {
                    ...selectedIssue,
                    tags: [
                        ...selectedIssue.tags,
                        global_tag
                    ]
                },
                reloadTagsList: true
            }
        }

        case ISSUE_REMOVE_TAG_SUCCESS: {
            //  Getting removed tag
            const { global_tag } = payload;
            const selectedIssue = state.selectedIssue;
            const tags = removeTagById(global_tag?.id, selectedIssue?.tags) || []
            return {
                ...state,
                selectedIssue: {
                    ...selectedIssue,
                    tags
                },
                reloadTagsList: true
            }
        }

        case RELOAD_ISSUES_TAGS_LIST: {
            return {
                ...state,
                reloadTagsList: payload
            }
        }

        case CLEAR_ISSUES:
        case LOGOUT_SUCCESS: {
            return initialState;
        }

        case ON_RELOAD_ISSUES_ROLLUP: {
            if(issueTypes.GROUP_INDIVIDUAL !== payload?.issueType) return state
            return {
                ...state,
                shouldReloadRollup: payload?.shouldReload,
            };
        }

        case ON_DELETE_MODAL_CONFIG_UPDATE: {
            if (payload?.issueType !== issueTypes.GROUP_INDIVIDUAL) return state;
            return {
                ...state,
                deleteModalConfig: {
                    show: payload?.show,
                    issue_ids: payload?.issue_ids,
                    success: payload?.success,
                },
            };
        }

        case ON_DELETE_ISSUES_SUCCESS: {
            if(payload?.issueType !== issueTypes.GROUP_INDIVIDUAL) return state;
            return {
                ...state,
                deleteModalConfig: {
                    show: false,
                    issue_ids: [],
                    success: true,
                }
            }
        }

        case ON_PARTNER_MANUAL_ACTION_FINISH: {
            return {
              ...state,
              shouldReload: true,
              manualActionFinished: true
            };
        }

        case ISSUES_UPDATE_CURRENT_PAGE: {
            return {
              ...state,
              currentPage: payload
            };
        }

        default:
            return state;
    }
}
