import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Label } from 'reactstrap';
import makeLoadingState from '../../../selectors/loadingSelector';
import makeErrorMessage from '../../../selectors/errorsSelector';
import CardTabs from '../../layout/tabs/CardTabs';
import BasicModal from '../../layout/BasicModal';
import CustomSpinner from '../../layout/CustomSpinner';
import ErrorMessage from '../../layout/ErrorMessage';
import { fetchComplianceAssessment } from '../../../actions/answers';
import { editComplianceList } from '../../../actions/assessment';
import FullAssessmentSection from './FullAssessmentSection';
import InformationExpandableBar from '../../vcyso/InformationExpandableBar';
import { UilFileAlt } from '@iconscout/react-unicons'
import { assessmentBar } from '../../../constants/pagesData';
import AssessmentSaveOptions from './AssessmentSaveOptions';
import { SCHEDULE_CYSO_LINK } from '../../../constants/links';
import { trackEvent } from '../../../utils/gaTracker';
import MainScrollableContainer from '../../layout/MainScrollableContainer';
import useCanAbility from '../../../hooks/useCanAbility';
import { CREATE_ACTION, PRIVILEGE_SOURCES } from '../../../constants/privileges';

const FullAssessment = (props) => {
    //  Parsing props
    const { companyIdToUse } = props;

     //  Memoized dispatch
    const defaultDispatch = useDispatch();
    const dispatch = useCallback(defaultDispatch, []);
    const ability = useCanAbility();

    //  Watching redux state changes
    const getLoadingState = makeLoadingState(['COMPLIANCE_ASSESSMENT']);
	const getErrorMessage = makeErrorMessage(['COMPLIANCE_ASSESSMENT']);

    const isLoading = useSelector(state => getLoadingState(state) || state.assessment.isLoading);
    const errorMessage = useSelector(state => getErrorMessage(state));
    const role = useSelector((state) => state.auth.user?.roles[0]?.name);
    const answers = useSelector((state) => (state.answers));
    const assessment = useSelector((state) => (state.assessment?.assessment));
    const compliances = useSelector((state) => (state.assessment.compliances));

    const initialTab =  { name: "CIS", index: 0 };
    let categoryNumber = 1;
    const [availableCompliances, setAvailableCompliances] = useState([])
    const [selectedComplianceTab, setSelectedComplianceTab] = useState(initialTab);
    const [uncheckedCompliance, setUncheckedCompliance] = useState(null);
    const [showRemoveComplianceModal, setShowRemoveComplianceModal] = useState(false);

    // Component Variables
    const canImpersonate = ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.MULTIPLE.IMPERSONATE);

    useEffect(() =>{
        //TODO: replace when the other compliances are supported
        setAvailableCompliances(_.filter(compliances, ['name','CIS']).map((compliance, index) => {
        //setAvailableCompliances(_.filter(compliances, compliance => compliance.name !== 'Cyber Checkup').map((compliance, index) => {
            return {
                ...compliance,
                index
            }
        }))
    }, [compliances, setAvailableCompliances])

    useEffect(() => {
        if(!_.isNil(selectedComplianceTab.id)){
            getAssessment()
        }
    }, [selectedComplianceTab])

    const toggleModal = () => {
        setShowRemoveComplianceModal(!showRemoveComplianceModal)
    }
    const handleConfirmRemoveCompliance = () => {
        toggleModal();
        editComplianceList(false, uncheckedCompliance.id, companyIdToUse)
    }


    const renderAssessmentHeader = () => {
        return (
            <div className="full-assessment-header d-flex">
                <div className="d-inline-block assessment-icon">
                    <UilFileAlt size={40} className="text-light"/>
                </div>
                <div className="d-inline-block text-white mt-auto">Assessment</div>
            </div>
        )
    }

    const onScheduleCysoClick = () => {
        const event = "schedule-cyso-info-bar";
        trackEvent(event, 'cyso', event);
        window.open(SCHEDULE_CYSO_LINK);
    }

    const renderAssessmentInformation = () => {
        if(canImpersonate) return null;
        const {title, descriptions, customButtonLabel } = assessmentBar;
        return (
            <div className="mb-4">
                <InformationExpandableBar
                    title={title}
                    content={descriptions}
                    customButton={(
                        <Button
                            color="light"
                            onClick={onScheduleCysoClick}
                        >
                            {customButtonLabel}
                        </Button>
                    )}
                />
            </div>
        )
    }
    const renderRemoveComplianceModal = () => {
        return(
          <BasicModal
            title={`Remove your ${uncheckedCompliance?.name} assessment?`}
            showModal={showRemoveComplianceModal}
            toggleModal={toggleModal}
            customClassName='remove-compliance-modal'
          >
            <div className='message d-flex justify-content-center text-center'>
                {`by removing your ${uncheckedCompliance?.name} assessment you'll lose all responses collected. We can't recover them once you delete them.`}           </div>
            <div className="d-flex justify-content-end">
                <Button color="link-water" style={{ marginRight: "40.5px"}} onClick={toggleModal}>Cancel</Button>
                <Button color="critical" onClick={handleConfirmRemoveCompliance} >Yes, remove it</Button>
            </div>
          </BasicModal>
        );
    }

    const renderSections = (sections, categoryId) => {
        return sections.map((section)=>{
            return (
                <FullAssessmentSection
                    key={section?.code}
                    section={section}
                    categoryId={categoryId}
                    sectionComments={answers?.sectionComments}
                />
            )
        })
    }

    const renderCategories = (categories) => {
        categoryNumber = 1;
        return categories.map(({id, name, sections}) => {
            return (
                <div key={`assessment-category-${id}`}className="full-assessment-category">
                    <div className="d-flex justify-content-between">
                        <div className="d-inline-block pr-3 title">{`${assessment.name} Category ${categoryNumber++}: ${name}`}</div>
                        <div className="category-sections d-inline-block">
                            {sections && (
                                `Control ${(sections[0]?.code)?.slice(1)}${sections?.length > 1 ? `-${(sections[sections.length-1]?.code)?.slice(1)}` : ''}`
                            )}
                        </div>
                    </div>
                    <div className="division-line"/>
                    {renderSections(sections, id)}
                 </div>
            );
        });
    };

    const renderEndOfAssessmentTag = () =>  {
        return (
            <div className="end-assessment-tag text-center">
                -End of questionnaire-
            </div>
        )
    }

    const renderComplianceCheckboxes = (complianceStandards) => {
        return complianceStandards.map((standard, index)=>{
           return (
                <div
                    key={`compliance-checkbox-${index}`}
                    className="compliance-checkbox custom-control custom-checkbox custom-checkbox-blue d-inline-block"
                    onClick={ e => e.stopPropagation()}
                >
                    <Input
                        type="checkbox"
                        className="custom-control-input"
                        id={`selected-compliance-${index}`}
                        checked={standard.isSelected}
                        readOnly
                        onClick={()=>{
                            if(!standard.isSelected) {
                                //call action to set as true in BE
                            }else {
                                setUncheckedCompliance(standard);
                                toggleModal();
                            }
                        }}
                    />
                    <Label className="custom-control-label" htmlFor={`selected-compliance-${index}`}>&nbsp;{`${standard.name} compliant`}</Label>
                </div>
           )
        })
    }

    const renderSaveOptions = () => {
        return (
            <AssessmentSaveOptions
                selectedComplianceTab={selectedComplianceTab}
                availableCompliances={availableCompliances}
                canImpersonate={canImpersonate}
                selectComplianceCallback={setSelectedComplianceTab}
            />
        )
    }

    const getAssessment = () => {
        dispatch(fetchComplianceAssessment(selectedComplianceTab.id, companyIdToUse));
    }

    const tryAgainButton = (
        <button
            className="btn btn-light btn-error d-inline-block"
            onClick={() => {
                getAssessment()
            }}
        >
            Try again
        </button>
    );
    return (
        <MainScrollableContainer
            additionalClassName="full-assessment contained-view-width"
        >
            { errorMessage && (
                <ErrorMessage
                    text={errorMessage}
                    button={tryAgainButton}
                    customStyle={{ paddingTop: "5%"}}
                />
            )}
            {isLoading && !errorMessage && (
                <div>
                    <CustomSpinner
                        customStyle={{ minHeight: "303px"}}
                    />
                </div>
            )}
            {!isLoading && !errorMessage && (
                <React.Fragment>
                    {renderAssessmentInformation()}
                    <CardTabs
                        alignment="left"
                        selectedTab={selectedComplianceTab?.index}
                        tabs={availableCompliances.map(compliance => {
                                return {
                                    name: compliance.name,
                                    content: (
                                        <>
                                            {renderCategories(assessment.categories)}
                                            {renderEndOfAssessmentTag()}
                                        </>
                                    ),
                                }
                            })
                        }
                    />
                    {renderSaveOptions()}
                </React.Fragment>
            )}
        </MainScrollableContainer>
    )
}
export default FullAssessment;
