import React from 'react';
import { Card, CardBody } from 'reactstrap';
import API from '../../../../api';
import DashboardWidgetContainer from '../../common/dashboard/DashboardWidgetContainer';
import TrendChartWrapper from '../../../dashboard/charts/tendency-chart/TrendChartWrapper';
import { criticalityColorsByLabel } from '../../../../constants/issues';
import { issuesCriticalityConfigColor } from '../../../../constants/criticality';
import { tendencyChartPointsByTimeUnit } from '../../../../constants/tendencyChart';

const VulnerabilityTrend = (props) => {
  //  Initializing API
  const { IssueAPI } = API;

  const {
    useLocalTimeFilter = false
  } = props;

  //  Rendering
  return (
    <DashboardWidgetContainer
      hasMessage
      message="How your issues are trending over time"
      containerClassName="dashboard-widget-container"
    >
      <div>
        <Card className="grc-trend-chart-card summary mb-0">
          <CardBody>
            <TrendChartWrapper
              isSummary={true}
              title="Trend by Criticality"
              objects="issues"
              pointsColorConfig={issuesCriticalityConfigColor}
              labelColorConfig={criticalityColorsByLabel}
              pointsCountConfig={tendencyChartPointsByTimeUnit}
              apiKey={IssueAPI.keys.getVulnerabilityTrend}
              apiFunction={IssueAPI.handlers.getVulnerabilityTrend}
              emptyTitle='All quiet so far :)'
              emptySubtitle=''
              useLocalTimeFilter={useLocalTimeFilter}
            />
          </CardBody>
        </Card>
      </div>
    </DashboardWidgetContainer>
  );
};

export default VulnerabilityTrend;
