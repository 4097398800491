import { notificationSettingsIds } from '../constants/profile';
import { removeItemFromArray } from './helpers';

export const getSelectedCheckboxesbyName = (selections) => {
  const selectedCheckboxes = selections?.reduce((results, item) => {
    if (item?.status) {
      results.push(notificationSettingsIds[item?.name]);
    }
    return results;
  },[]);
  return selectedCheckboxes || [];
}

export const getCheckboxesState = (selectedCheckboxes, value) => {
  const isSelected = selectedCheckboxes?.includes(value?.id);
  if (!isSelected) {
    return ([...selectedCheckboxes, value?.id]);
  } else {
    const positionInArray = selectedCheckboxes.indexOf(value?.id);
    return (removeItemFromArray(selectedCheckboxes, positionInArray));
  }
};

// Function to get the selected users in user management table
export const getSelectedUsers = (allSelected = false, selected = [], excluded = [], elements = []) => {
  const initialArray = allSelected ? elements : elements?.filter( ({id}) => selected?.includes(id) );
  return initialArray.filter(({id}) => !excluded?.includes(id));
}