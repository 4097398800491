import React from 'react';
import { Button } from 'reactstrap';
import { ruleSetsStatus } from '../../constants/automation';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useCanAbility from '../../hooks/useCanAbility';
import { LIST_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';

const AutomationExecutionReport = (props) => {
  //  Parsing props
  const { rule, errors_count = 0 } = props;

  //  Component hooks
  const history = useHistory();
  const { path } = useRouteMatch();
  const ability = useCanAbility();

  // Component variables
  const canListLogGroup = ability.can(LIST_ACTION, PRIVILEGE_SOURCES.AUTOMATION.LOG_GROUP)

  //  Function to handle check logs actions
  const onCheckLogs = () => {
    history.push(`${path}/rule/${rule?.id || null}/logs`)
  };

  //  Function to render error counts
  const renderErrorCount = () => {
    if (!errors_count) { return null; }
    return (
      <div className="execution-report-label">
        {`${errors_count} errors en last exec`}
      </div>
    );
  }

  //  Rendering
  return rule?.status === ruleSetsStatus.DRAFT ? null : (
    <div className="execution-report-container">
      <Button
        type="button"
        color="light-gray"
        outline
        className="btn-edit-cyvalue execution-report-button"
        onClick={() => { onCheckLogs(); }}
        disabled={!canListLogGroup}
      >
        Check Logs
      </Button>
      {renderErrorCount()}
    </div>
  );
};

export default AutomationExecutionReport;
