import { USER_TYPES } from "../constants/common";
import { CREATE_ACTION, PRIVILEGE_SOURCES } from "../constants/privileges";
import useCanAbility from "./useCanAbility";

const useUserType = () => {
  const ability = useCanAbility();
  const hasPrivilege = ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.MULTIPLE.IMPERSONATE);
  return hasPrivilege ? USER_TYPES.CYDEKICK_TEAM : USER_TYPES.MEMBER;
};

export default useUserType;
