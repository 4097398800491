import React from 'react';
import { Row } from 'reactstrap';

const SolutionCardTooltip = (props) => {

  const {
    solutionData = {},
    customClassName='',
  } = props;

  const renderCard = () => {
    return (
      <div className={customClassName}>
        {solutionData.title !== undefined ? (
          <Row className="align-items-center solution-tooltip-header">
            <div className="solution-tooltip-header-contents">
              <div className="header-title">{solutionData.title}</div>
            </div>
          </Row>
        ) : null}
        <Row className="name">
          <div className='icon' style={{ backgroundColor: solutionData.color}}>{solutionData.icon}</div>
          <div className='control-name'>{solutionData.name}</div>
        </Row>
        {solutionData.info === "" ? null : (
          <Row className="info">
            <p>{solutionData.info}</p>
          </Row>
        )}
      </div>
    );
  };

  return (renderCard());
}

export default SolutionCardTooltip;