import _ from 'lodash';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { setAlert } from '../../actions/alerts';
import { setConfirmationModal } from '../../actions/layout';
import {
    onDeleteModalConfigUpdate,
    onReloadIssuesRollup,
    onReloadIssues
} from '../../actions/issues';
import API from '../../api';
import { alertTypes } from '../../constants/alerts';
import errorMessages from '../../constants/errorMessages';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import BulkActionsMenu from '../layout/BulkActionsMenu';
import IssuesListContextMenu from './issues-context-menu/IssuesListContextMenu';
import IssuesAddRemoveTagsModal from './IssuesAddRemoveTagsModal';
import IssuesAssigneeModal from './IssuesAssigneeModal';
import IssuesNoteModal from './IssuesNoteModal';
import RemediationNoteModal from './RemediationNoteModal';
import useCustomMutation from '../../hooks/useCustomMutation';
import { issueTypes } from '../../constants/issues';
import issuesInfoSelector from '../../selectors/issuesInfoSelector';
import { getStatusFilterByIssuesType } from '../../utils/issuesHelpers'
import useCanAbility from '../../hooks/useCanAbility';
import { MODIFY_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';

const IssuesListBulkActions = (props) => {
    const {
        selectedIssues,
        parentRef,
        onRightClick,
        bulkMenuId = '',
        bulkMenuRef,
        bulkMenuStyle = {},
        scrollableRef,
        issueType = issueTypes.VULNERABILITIES
    } = props;

    //  Initializing APIs
    const { IssueAPI } = API;

    // Component state
    const [showTagsModal, setShowTagsModal] = useState(false);
    const [showAssigneesModal, setShowAssigneesModal] = useState(false);
    const [showRemediationNoteModal, setShowRemediationNoteModal] = useState(false);
    const [selectedStatusLabel, setSelectedStatusLabel] = useState('');
    const [showCommentsModal, setShowCommentsModal] = useState(false);

    // Component Hooks
    const { dispatch } = useMemoizedDispatch();
    const ability = useCanAbility();

    // Getting needed info from redux store
    const statuses = useSelector(state => issuesInfoSelector(state, 'modalOptions', issueType)?.statuses);
    const isMemberView = useSelector(state => state?.impersonation?.isMemberView);

    // Component variables
    const canModifyIssues = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.ISSUES.GENERAL) || isMemberView;

    // Get confirmation message
    const getConfirmationMessage = () => {
        const count = selectedIssues.length;
        const itemType = issueType === issueTypes.GRC ? 'task' : 'issue';
        const itemLabel = count !== 1 ? `${itemType}s` : `${itemType}`;
        return (
            <span>
                {`${count} ${itemLabel} moved `}
                {selectedStatusLabel && <>{`to `}<span className="font-weight-bolder">{selectedStatusLabel}</span></>}
            </span>
        )
    }

    // updates several issues
    const {
        data: updateIssuesResponse,
        error: updateIssuesError,
        mutate: updateIssues,
    } = useCustomMutation(
        (params) => IssueAPI.handlers.updateIssuesBulk(params),
        IssueAPI.invalidators.updateIssuesBulk
    );

    // Watch api response
    useEffect(() => {
        if (!updateIssuesResponse) return;
        dispatch(setConfirmationModal({
            show: true,
            message: getConfirmationMessage()
        }))
        dispatch(onReloadIssues(true, issueType));
        dispatch(onReloadIssuesRollup(true, issueType));
    }, [updateIssuesResponse])

    // Watch error
    useEffect(() => {
        if (!updateIssuesError) return;
        const errorMessage = updateIssuesError?.data?.errorMessage || errorMessages.defaultPost;
        dispatch(setAlert(errorMessage, alertTypes.error));
    }, [updateIssuesError])

    const toggleTagsModal = (show) => {
        const showModal = !_.isNil(show) ? show : !showTagsModal
        setShowTagsModal(showModal)
    }

    const toggleAssigneesModal = (show) => {
        const showModal = !_.isNil(show) ? show : !showAssigneesModal
        setShowAssigneesModal(showModal)
    }

    const toggleRemediationNoteModal = (show) => {
        const showModal = !_.isNil(show) ? show : !showRemediationNoteModal
        setShowRemediationNoteModal(showModal)
    }

    const toggleCommentsModal = (show) => {
        const showModal = !_.isNil(show) ? show : !showCommentsModal
        setShowCommentsModal(showModal)
    }

    const onStatusSelectedCallback = (statusId, label) => {
        if (statusId === 4) {
            toggleRemediationNoteModal(true)
        } else {
            const updates = { status: statusId }
            const grc_only = issueType === issueTypes.GRC;
            const params = { issue_ids: selectedIssues, updates, grc_only }
            updateIssues(params)
            setSelectedStatusLabel(label)
        }
    }

    const isValidStatus = (element) =>{
        return element?.label !== 'Re Opened' && getStatusFilterByIssuesType(element,issueType);
    }

    //gets Status options for menu
    const getStatusOptions = () => {
        if (!statuses) return;
        return statuses?.filter(isValidStatus).map((status) => {
            return {
                label: status?.label,
                callback: () => onStatusSelectedCallback(status?.id, status?.label),
                isDisabled: !canModifyIssues
            }
        })
    }

    //  Function to handle on delete click
    const onDeleteIssuesClick = () => {
        dispatch(onDeleteModalConfigUpdate(true, selectedIssues, false, issueType));
    }

    const bulkActionOptions = [
        {
            label: "Move To",
            iconClassName: "bx bx-transfer",
            options: getStatusOptions()
        },
        {
            label: "Assign",
            iconClassName: "bx bx-download",
            callback: toggleAssigneesModal
        },
        {
            label: "Tags",
            iconClassName: "bx bx-bookmark",
            callback: toggleTagsModal
        },
        {
            label: "Post note",
            iconClassName: "bx bx-note",
            callback: toggleCommentsModal
        },
        {
            label: 'Delete',
            iconClassName: 'bx bx-trash',
            callback: onDeleteIssuesClick,
        },
    ];

    return (
        <>
            <IssuesListContextMenu
                parentRef={parentRef}
                onRightClick={onRightClick}
                toggleTagsModal={toggleTagsModal}
                toggleAssigneesModal={toggleAssigneesModal}
                toggleCommentsModal={toggleCommentsModal}
                onStatusSelectedCallback={onStatusSelectedCallback}
                onDeleteIssuesClick={onDeleteIssuesClick}
                scrollableRef={scrollableRef}
                issueType={issueType}
                isDisabled={!canModifyIssues}
            />
            <IssuesAddRemoveTagsModal
                showModal={showTagsModal}
                toggleModal={toggleTagsModal}
                selectedIssues={selectedIssues}
                issueType={issueType}
                isDisabled={!canModifyIssues}
            />
            <IssuesAssigneeModal
                showModal={showAssigneesModal}
                toggleModal={toggleAssigneesModal}
                selectedIssues={selectedIssues}
                issueType={issueType}
                isDisabled={!canModifyIssues}
            />
            <RemediationNoteModal
                showModal={showRemediationNoteModal}
                toggleModal={toggleRemediationNoteModal}
                selectedIssues={selectedIssues}
                issueType={issueType}
                isDisabled={!canModifyIssues}
            />
            <IssuesNoteModal
                showModal={showCommentsModal}
                toggleModal={toggleCommentsModal}
                selectedIssues={selectedIssues}
                issueType={issueType}
                isDisabled={!canModifyIssues}
            />
            <BulkActionsMenu
                options={bulkActionOptions}
                show={selectedIssues?.length > 0}
                bulkMenuId={bulkMenuId}
                bulkMenuRef={bulkMenuRef}
                bulkMenuStyle={bulkMenuStyle}
                isSticky={true}
                className={"pl-3"}
                isDisabled={!canModifyIssues}
            />
        </>
    )
}

export default IssuesListBulkActions;
