import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Question from '../Question';
import assessmentTypes from '../../../constants/assessmentTypes';
import TextArea from '../../profile/TextArea';
import { setComment } from '../../../actions/answers';

const FullAssessmentSection = (props) => {

    const {
        section, 
        categoryId,
        sectionComments
    } = props;

    //  Memoized dispatch
    const defaultDispatch = useDispatch();
    const dispatch = useCallback(defaultDispatch, []);

    const [commentValue, setCommentValue] = useState(null);
    const [debounceComment, setDebounceComment] = useState(null);

    useEffect(() => {
        const timerID = setTimeout(() => {
            setDebounceComment(commentValue);
        }, 500);
        return () => clearTimeout(timerID);
    },[commentValue]);

    useEffect(() => {
        if(debounceComment !== null) dispatch(setComment({ id: section?.id , comment: debounceComment, type: 'section'}));
    },[debounceComment]);

    const renderSectionComment = () => {
        return (
            <TextArea
                placeholder="Leave your additional comments here…"
                rows={1}
                minRows={1}
                maxRows={3}
                className="full-assessment-comment form-control dark text-area-grow"
                callback={(value)=>{ setCommentValue(value) }}
                callOnClick={false}
                value={commentValue !== null ? commentValue : (sectionComments && sectionComments[section?.id] ? sectionComments[section?.id] : '')}
            />
        )
    }

    const renderQuestions = (questions, categoryId, sectionId) => {
        return questions.map(({ id, value, info }) => {
            return (
                <Question
                    key={id}
                    id={id}
                    categoryId={categoryId}
                    sectionId={sectionId}
                    question={value}
                    info={info}
                    type={assessmentTypes.full}
                />
            );
        });
    };

    if(!section) return null;
    return (
        <div key={section.id} className="full-assessment-section">
            <div className="section-name mb-3">{`Control ${(section?.code)?.slice(1)}: ${section?.name}`}</div>
            {section.info && (
                <div className="section-info">
                    <div className="text">*{section.info}</div>
                </div>
            )}
            <div className="section-questions">
                <div className="full-assessment-question">
                    {renderQuestions(section.questions, categoryId, section.id)}
                </div>
            </div>
            {renderSectionComment()}
        </div>
    )
}
export default FullAssessmentSection;
