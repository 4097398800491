import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

const useMemoizedDispatch = () => {
  const defaultDispatch = useDispatch();
  const dispatch = useCallback(defaultDispatch, []);
  return { dispatch };
};

export default useMemoizedDispatch;
