import { useMutation, useQueryClient } from 'react-query';
import { invalidateQueriesByKeys } from '../utils/queryHelpers';

const useCustomMutation = (apiFunction, invalidators, extraConfig = {}) => {
  //  Query client to invalidate
  const queryClient = useQueryClient();

  //  Returning useMutation response
  return useMutation(
    apiFunction,
    {
      onSuccess: (response) => { invalidateQueriesByKeys(invalidators, queryClient); },
      ...extraConfig
    }
  );
};

export default useCustomMutation;
