import React from 'react';
import { Badge } from 'reactstrap';
import CRITICALITY from '../../../constants/criticality';

const CriticalityCell = (props) => {
  //  Parsing props
  const { issue, criticality } = props;

  //  Component variales
  const label = CRITICALITY[criticality]?.label;
  const className = CRITICALITY[criticality]?.className;

  //  Rendering
  return (
    <td tabIndex="0" role="cell">
      <div aria-label={`${label ? label : 'unknown'}, Criticality`}>
        <Badge id={`criticality-badge-${issue?.code}`} color={`outline-${className}`}>{label}</Badge>
      </div>
    </td>
  );
};

export default CriticalityCell;
