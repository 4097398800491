import React from 'react';
import { useSelector } from 'react-redux';
import API from '../../../api';
import { LIST_ACTION, PRIVILEGE_SOURCES } from '../../../constants/privileges';
import useCanAbility from '../../../hooks/useCanAbility';
import useCustomMutation from '../../../hooks/useCustomMutation';
import useHandleApiResponse from '../../../hooks/useHandleApiResponse';
import useUserManagerActions from '../../../hooks/useUserManagerActions';
import UserManagerActionsModals from './UserManagerActionsModals';


const UserManagerBulkMenu = (props) => {

  const {
    users = [],
    selected,
    company_id
  } = props;

  //  Initializing API
  const { UserAPI } = API;

  // Component Hooks
  const ability = useCanAbility();
  const canUserManage = ability.can(LIST_ACTION, PRIVILEGE_SOURCES.PROFILE.USER_MANAGEMENT);

  const currentUser = useSelector((state) => state.auth.user);

  // Hooks
  const {
    actions,
    showDeleteModal,
    toggleDeleteModal,
    showAcceptModal,
    toggleAcceptModal,
    showDiscardModal,
    toggleDiscardModal,
    showResendModal,
    toggleResendModal,
    showDisableModal,
    toggleDisableModal
  } = useUserManagerActions({
    currentUser : currentUser,
    ids : selected,
  });

  //  Component hooks
  const {
    data: userActionResponse,
    error: userActionError,
    isLoading: userActionLoading,
    mutate: callUserAction,
  } = useCustomMutation(
    (params) => UserAPI.handlers.executeUserAction(params),
    UserAPI.invalidators.executeUserAction,
  );

  useHandleApiResponse({
    data: userActionResponse,
    errorData: userActionError,
    successMessage: `User enabled successfully.`,
  });

  const enableUser = () => {
    const payload = {
      action: 'enable',
      users: selected,
      company: company_id
    };
    callUserAction(payload);
  };

  //  Array of enable disable actions
  // Enable doesn't trigger a modal but disable does
  const enableDisableActions = [
    {
      iconClassName: 'bx bx-check-circle',
      tooltipText: 'Enable',
      callback: enableUser,
      disabled: !canUserManage,
    },
    {
      iconClassName: 'bx bx-x-circle',
      tooltipText: 'Disable',
      callback: toggleDisableModal,
      disabled: !canUserManage || selected?.includes(currentUser?.id),
    }
  ];

  //  Function to render icon
  const renderAction = (className, text, onClick, disabled, index) => {
    return (
      <div
        className="bulk-menu-icon-container"
        onClick={disabled ? null : onClick}
        key={`user-manager-bulk-action-button-${index}`}
      >
        <div className={`bulk-menu-icon ${className}`} />
        <div className="bulk-menu-text">{text}</div>
      </div>
    );
  };

  //  Function to render bulk action buttons
  const renderActions = () => {
    return [...enableDisableActions, ...actions]?.map((action, index) => {
      const { iconClassName, callback, tooltipText, disabled } = action;
      return renderAction(iconClassName, tooltipText, callback, disabled, index);
    });
  };

  //  Function to render bulk actions
  const renderBulkActions = () => {
    if (selected?.length === 0) return null;
    return (
      <div className="user-manager-bulk-menu">
        <div className="bulk-menu-label">Bulk actions</div>
        <div className="bulk-menu-icons">
          {renderActions()}
        </div>
      </div>
    );
  };

  return (
    <div>
      <UserManagerActionsModals
        company_id={company_id}
        users={users || []}
        selectedIds={selected}
        showDeleteModal={showDeleteModal}
        showAcceptModal={showAcceptModal}
        showDiscardModal={showDiscardModal}
        showResendModal={showResendModal}
        toggleDeleteModal={toggleDeleteModal}
        toggleAcceptModal={toggleAcceptModal}
        toggleDiscardModal={toggleDiscardModal}
        toggleResendModal={toggleResendModal}
        showDisableModal={showDisableModal}
        toggleDisableModal={toggleDisableModal}
      />
      {renderBulkActions()}
    </div>
  )
}

export default UserManagerBulkMenu;