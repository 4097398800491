import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import SelectDropdownIndicator from './fields/SelectDropdownIndicator';

const SimpleDropdown = (props) => {
  //  Parsing props
  const {
    extraClassName = '',
    placeholder = 'Select element',
    elements = [],
    onChangeCallback,
    selectedElement,
    renderCustomIndicator,
    disabled = false,
    customWidth = false,
    isLoading = false,
    customMenuPosition = 'fixed',
    ariaLabel = '',
    formatOptionLabel,
  } = props;

  // Styles for customWidth
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      width: 'auto',
    }),
  
    control: (provided, { selectProps: { width }}) => ({
      ...provided,
      width: width
    }),
  }

  //  Component state
  const [search, setSearch] = useState('');

  // Select Dropdown Indicator
  const DropdownIndicator = (props) => {
    if (renderCustomIndicator) { return renderCustomIndicator(props); }
    return <SelectDropdownIndicator {...props} />
  };

  //  Rendering
  return (
    <Select
      inputValue={search}
      value={selectedElement}
      styles={customWidth && customStyles}
      options={elements}
      width={225}
      placeholder={placeholder}
      onChange={onChangeCallback}
      onInputChange={setSearch}
      className={`react-select-container ${extraClassName}`}
      classNamePrefix="react-select"
      components={{ DropdownIndicator }}
      isSearchable={true}
      menuPosition={customMenuPosition}
      isDisabled={disabled}
      isLoading={isLoading}
      aria-label={ariaLabel}
      formatOptionLabel={formatOptionLabel}
    />
  );
};

export default SimpleDropdown;
