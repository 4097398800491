import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import SelectDropdownIndicator from './fields/SelectDropdownIndicator';
import { infiniteDropdownLimit } from '../../constants/common';
import { markSelectedOptions } from '../../utils/helpers';
import { setAlert } from '../../actions/alerts';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useValueDebounce from '../../hooks/useValueDebounce';
import useScrollingQuery from '../../hooks/useScrollingQuery';
import {onFocus} from '../../utils/helpers.js';

//  Initial filters
const initialFilters = {
  limit: infiniteDropdownLimit,
  searchText: null,
  sort: ['name', 'ASC'],
};

//  Component
const InfiniteDropdown = (props) => {
  // Parsing props
  const {
    isDisabled = false,
    extraClassName = '',
    emptyLabel = '',
    errorMessage = 'Error getting elements',
    restoreToDefault = false,
    apiFunction, // Get function, must recieve a filter object as param
    parseElementsToOptions, // Function to convert the api response into options
    onChange, // Callback to call when selected element changes
    //  Props to manage previously selected elements
    isMultiSelect = false,
    selectedElements = [], // IMPORTANT: always send this parameter from parent, even if it is []
    allElementsSelected = false,
    //  Props to manage default all members element
    addAllElementsOption = false,
    allElementsLabel = 'All Elements',
    initialElement = null,
    apiKey,
    extraApiKeys = [],
    customFilters = {},
    customMenuPosition = 'fixed',
    ariaLabel = '',
    addMenuPortalStyle = false,
    menuHeight
  } = props;

  //  Component ref
  const didMountRef = useRef(null);

  //  Variable to add all elements option
  const allElementsOption = { label: allElementsLabel, value: -1 };

  //  Component state
  const [filters, setFilters] = useState({ ...initialFilters, ...customFilters });
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedElement, setSelectedElement] = useState(initialElement);
  const [requestMore, setRequestMore] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const { debouncedValue: textToSearch } = useValueDebounce({
    valueToDebounce: searchTerm,
    defaultValue: null,
    debounceTime: 500,
  });

  //  API call
  const {
    elements,
    error,
    loading,
    fetchNextPage,
    hasNextPage,
    clearQueryData,
  } = useScrollingQuery({
    baseKey: apiKey,
    key: [apiKey, filters, ...extraApiKeys],
    queryFunction: apiFunction,
    parseElements: parseElementsToOptions,
    isDisabled,
    initialElement: addAllElementsOption ? allElementsOption : null,
    valueToExtract: 'rows',
    initialPageParam: 0,
  });

  //  Function to clean component
  const cleanState = () => {
    clearQueryData();
    setFilters({ ...initialFilters, ...customFilters });
    setSearchTerm('')
    setSelectedElement(null);
    setRequestMore(false);
  };

  //  Watching restore to default flag
  useEffect(() => {
    if (!restoreToDefault || !didMountRef.current) { return; }
    cleanState();
  }, [restoreToDefault]);

  //  Watching request more changes
  useEffect(() => {
    if (!requestMore) { return; }
    setRequestMore(false);
    if (!hasNextPage || loading) { return; }
    fetchNextPage();
  }, [requestMore]);

  //  Watching elected element changes
  useEffect(() => {
    if (selectedElement?.selected) { return; }
    onChange(selectedElement);
  }, [selectedElement]);

  //  Watching text to search changes to call API
  useEffect(() => {
    const newValue = textToSearch === '' ? null : textToSearch
    if (newValue === filters?.searchText || !didMountRef.current) { return; }
    setFilters({ ...filters, searchText: newValue });
  }, [textToSearch]);

  //  Watching error response
  useEffect(() => {
    if (!error) { return; }
    dispatch(setAlert(errorMessage, 'danger'));
  }, [error]);

  //  Watching elements changes
  useEffect(() => {
    const markedElements = isMultiSelect ? markSelectedOptions(selectedElements, elements) : elements;
    setFilteredOptions(markedElements);
  }, [elements, selectedElements]);

  //  Watching initial element changes
  useEffect(() => {
    if (initialElement) { return; }
    setSelectedElement(null);
  }, [initialElement]);

  //  Watching all elements selected options
  useEffect(() => {
    if (!addAllElementsOption) { return; }
    if (allElementsSelected && selectedElements.length === 0 && !selectedElement) {
      setSelectedElement(allElementsOption);
    }
  }, [allElementsSelected]);

  //  Component did mount
  useEffect(() => {
    didMountRef.current = true;
  }, []);

  //  Function to handle dropdown change
  const handleDropdownChange = (element) => {
    setSelectedElement(element);
  };

  //  Function to handle on scroll botton
  const onScrollBottom = () => {
    setRequestMore(true); // Need to be with this flag to avoid function memoization issues
  };

  //  Function to get current value
  const getCurrentValue = () => {
    if (!isMultiSelect) { return selectedElement; }
    if (addAllElementsOption && selectedElement?.value === -1) { return selectedElement; }
    return null;
  }

  // Select Dropdown Indicator
  const DropdownIndicator = (props) => {
    return <SelectDropdownIndicator {...props} />
  };

  //  Custom styles
  const customStyle = {
    option: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data?.selected ? '#361f93!important' : 'transparent',
        color: data?.selected ? '#fff' : '#a6b0cf'
      };
    },
    ...addMenuPortalStyle ? {
      menuPortal: base => ({ ...base, zIndex: 9999 }),
      menu: base => ({ ...base, zIndex: 9999 })
    } : {},
    ...menuHeight ? {
      menu: base => ({ ...base, height: menuHeight}),
      menuList: base => ({ ...base, height: menuHeight - 5})
    } : {}
  };

  //  Rendering
  return (
    <Select
      ariaLiveMessages={{
        onFocus,
      }}
      aria-live="polite"
      inputValue={searchTerm}
      value={getCurrentValue()}
      options={filteredOptions}
      onChange={handleDropdownChange}
      onInputChange={setSearchTerm}
      onMenuScrollToBottom={onScrollBottom}
      className={`react-select-container ${extraClassName}`}
      classNamePrefix="react-select"
      placeholder={emptyLabel}
      components={{ DropdownIndicator }}
      isDisabled={isDisabled}
      isLoading={loading}
      isSearchable={true}
      menuPosition={customMenuPosition}
      menuShouldBlockScroll={addMenuPortalStyle ? true : false}
      menuPortalTarget={addMenuPortalStyle ? document.body : undefined}
      styles={customStyle}
      aria-label={filteredOptions?.length === 0 ? `${ariaLabel} combobox. Current combobox has no options` : ariaLabel}
    />
  );
};

export default InfiniteDropdown;
