import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Avatar from '../avatars/Avatar';
import { trackEvent } from '../../../utils/gaTracker';
import useSelectedTab from '../../../hooks/useSelectedTab';
import { getParentPath } from '../../../utils/helpers';
import {ENTER_KEY_NAME} from '../../../constants/keyNames';

const UserInfo = (props) => {
  //  Parsing props
  const { type } = props;

  //  Watching redux store
  const authUser = useSelector((state) => state.auth.user);
  const companyName = useSelector((state) => state.auth.company?.name);

  //  Initializing router history
  const history = useHistory();
  const location = useLocation();

  //  Component variables
  const nameToUse = useMemo(() => {
    if (!authUser) { return 'G'; }
    const { full_name, name } = authUser;
    if (!full_name && !name) { return 'G'; }
    return full_name || name;
  }, [authUser]);

  //  Initializing hook to get selected state
  const { selected } = useSelectedTab({ pathname: '/profile' });

  //  Function handle click
  const onUserInfoClicked = () => {
    const parentPath = getParentPath(location?.pathname);
    if (parentPath === '/profile') { return; }
    trackEvent('sidebar-tab-clicked', 'sidebar-menu', 'profile');
    history.push('/profile');
  };

  //  Function to render user name and company name
  const renderUserData = () => {
    if (type === 'condensed') { return null; }
    return (
      <div className="user-info-data">
        <div className="user-info-user-name">{nameToUse}</div>
        {companyName && <div className="user-info-company-name">{companyName}</div>}
      </div>
    );
  };

  //  Rendering
  return (
    <div className="sidebar-user-info-container">
      <a
        className={`sidebar-user-info${selected ? ' selected' : ''}${type === 'condensed' ? ' condensed' : ''}`}
        tabIndex="0"
        aria-label="User profile"
        onClick={() => onUserInfoClicked()}
        onKeyPress={(e) => e?.key === ENTER_KEY_NAME ? onUserInfoClicked() : null }
      >
        <Avatar
          user={authUser}
          customClassName="header-profile-user initial d-inline-flex user-info-avatar"
        />
        {renderUserData()}
      </a>
    </div>
  );
};

export default UserInfo;
