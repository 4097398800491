import axios from 'axios';
import { store } from '../../store';
import { setAlert } from '../../actions/alerts';
import { authError } from '../../actions/auth';
import { alertTypes } from '../../constants/alerts';

//  Building axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

//  Setting default post headers
axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.patch['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.put['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.delete['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.get['Content-Type'] = 'application/json';

//  Setting interceptors
axiosInstance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  // Check if it's a unauthorized error type
  if (401 === error.response.status) {
    // Redirect to login and show session expire error message
    const token = localStorage.getItem('token')
    if (token && token !== "undefined") {
      // Set Session expired alert
      localStorage.clear()
      const message = "Your session has expired"
      store.dispatch(setAlert(message, alertTypes.important, false))
    }
    store.dispatch(authError(null));
  }
  return Promise.reject(error);
});

export default axiosInstance;