import React, { useEffect } from 'react'
import { Row, Col } from 'reactstrap';
import API from '../../api';
import LoadingContent from '../layout/LoadingContent';
import VcysoPolicy from './VcysoPolicy';
import { actionTypes } from '../../constants/navigation';
import { setAlert } from '../../actions/alerts';
import { triggerTopbarAction } from '../../actions/navigation';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import usePDFGenerator from '../../hooks/usePDFGenerator';
import useCustomQuery from '../../hooks/useCustomQuery';
import useCanAbility from '../../hooks/useCanAbility';
import { PRIVILEGE_SOURCES, READ_ACTION } from '../../constants/privileges';

const PoliciesGrid = (props) => {
    const {
        showDownloadSection = false,
        preview = false,
        companyId,
        token
    } = props;

    //  Initializing APIs
    const { VcysoAPI } = API;

    //  Memoized dispatch
    const { dispatch } = useMemoizedDispatch();
    const ability = useCanAbility();
    const canReadPolicy = ability.can(READ_ACTION, PRIVILEGE_SOURCES.POLICIES.GENERAL)

    //  Hook for downloading PDF
    const { response: pdfResponse, error: pdfError } = usePDFGenerator({
        queryParam: null,
        apiKey: VcysoAPI.keys.getPolicyPDF,
        apiFunction: VcysoAPI.handlers.getPolicyPDF,
        actionType: actionTypes.DOWNLOAD_POLICY,
    });

    //  Calling API for policies
    const {
        data: policiesResponse,
        error: policiesError,
        isLoading: policiesLoading,
        refetch: refetchPolicies,
    } = useCustomQuery(
        [VcysoAPI.keys.getPolicies, { company_id: companyId, token }],
        ({ queryKey }) => VcysoAPI.handlers.getPolicies(queryKey[1]),
    );

    const retryRequests = () => {
        if (policiesError) {
            refetchPolicies();
        }
    }

    //  Watching PDF error
    useEffect(() => {
        if (!pdfError) { return; }
        dispatch(triggerTopbarAction(null, null));
        dispatch(setAlert('Error generating PDF', 'danger'));
    }, [pdfError]);

    const dummyDescription = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed volutpat odio orci, ac posuere velit vehicula at. Nunc malesuada vel `;

    const renderPolicies = () => {
        if (!policiesResponse) return null;
        return policiesResponse.map((element, index) => {
            return (
                <Col key={`vcyso-policy-${index}`} sm={6} md={3} className="policy-wrapper px-2 mb-3">
                    <VcysoPolicy
                        policy_id={element?.policy_id}
                        name={element.policy?.title}
                        icon={element?.policy?.icon}
                        description={preview ? dummyDescription : element.policy?.description}
                        showDownloadSection={showDownloadSection && canReadPolicy}
                        available={element?.availability}
                        policyUrl={element?.generated_policy_url}
                    />
                </Col>
            )
        })
    }
    return (
        <LoadingContent
            isLoading={(policiesLoading) ? "Oops! our fault, reload again." : null}
            errorMessage={(policiesError) ? "Oops! our fault, reload again." : null}
            iconType="solidIcon"
            errorStyle={{ minHeight: "225px" }}
            loadingStyle={{ minHeight: "225px" }}
            errorButtonCallback={retryRequests}
        >
            <Row className="policies-wrapper" style={{ marginLeft: "-8px", marginRight: "-8px" }}>
                {renderPolicies()}
            </Row>
        </LoadingContent>
    )
}

export default PoliciesGrid
