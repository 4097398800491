import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeLayout } from '../actions/layout';
import PersonalInfo from "../components/settings/PersonalInfo";
import Subscriptions from "../components/settings/Subscriptions";
import TabView from "../components/layout/tabs/TabView";
import useCanAbility from '../hooks/useCanAbility';
import { PRIVILEGE_SOURCES, READ_ACTION } from '../constants/privileges';

const Settings = (props) => {
    const {
        changeLayout,
        roles
    } = props;

    // Component Hooks
    const ability = useCanAbility();

    // Component Variables
    const canViewNotificationsSettings = ability.can(READ_ACTION, PRIVILEGE_SOURCES.PROFILE.NOTIFICATIONS_SETTINGS_SUBTAB)


    useEffect(() => {
        changeLayout('vertical');
    }, [changeLayout]);

    const getTabsTitles = () => {
        let titles = ['Security Settings'];
        if (canViewNotificationsSettings) titles.push('Notification Settings');
        return titles;
    }

    const getTabsContent = () => {
        let tabs = [
            <PersonalInfo />
        ];
        if (canViewNotificationsSettings) tabs.push(<Subscriptions />);

        return tabs;
    }

    return (
        <div className="settings-container">
            <div className="title">User settings and preferences</div>
            <TabView
                tabTitles={getTabsTitles()}
                tabContent={getTabsContent()}
            />
        </div>
    );
}


const makeMapStateToProps = () => {
    const mapStateToProps = (state) => {
        return {
            roles: state.auth.user.roles,
        }
    }
    return mapStateToProps;
}

const mapDispatchToProps = {
    changeLayout
};

export default withRouter(connect(makeMapStateToProps, mapDispatchToProps)(Settings));

