import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import BasicModal from '../layout/BasicModal';
import { cancelRulesDelete } from '../../actions/automation';
import { setAlert } from '../../actions/alerts';
import API from '../../api';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useCustomMutation from '../../hooks/useCustomMutation';
import useCanAbility from '../../hooks/useCanAbility';
import { DELETE_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';

//  Component
const DeleteAutomationRulesModal = (props) => {
  //  Parsing props
  const {
    filters = {},
    onSuccessCallback,
  } = props;

  //  Initializing APIs
  const { RuleAPI } = API;

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const ability = useCanAbility();

  // Component variables
  const canDeleteRuleSets = ability.can(DELETE_ACTION, PRIVILEGE_SOURCES.AUTOMATION.RULE_SET)

  //  Calling API to delete rules
  const {
    data: deleteResponse,
    error: deleteError,
    isLoading: deleteLoading,
    mutate: deleteRuleSets,
  } = useCustomMutation(
    (params) => RuleAPI.handlers.deleteRuleSets(params),
    RuleAPI.invalidators.deleteRuleSets,
  );

  //  Watching redux store
  const showDeleteModal = useSelector((state) => state.automation.showDeleteModal);
  const deletePayload = useSelector((state) => state.automation.deletePayload);

  //  Function to close modal
  const toggleModal = () => {
    dispatch(cancelRulesDelete());
  };

  //  Watching api reponse
  useEffect(() => {
    if (!deleteResponse) { return; }
    onSuccessCallback()
    toggleModal();
    dispatch(setAlert('Success deleting rule sets', 'success'));
  }, [deleteResponse]);

  //  Watching api error
  useEffect(() => {
    if (!deleteError) { return; }
    dispatch(setAlert('Error deleting rule sets', 'danger'));
    toggleModal();
  }, [deleteError]);

  //  Function to delete rules
  //TODO: delete rules
  const deleteRules = () => {
    const payloadToDelete = deletePayload || {};
    const deleteParams = { ...payloadToDelete, filters };
    deleteRuleSets(deleteParams);
  };

  //  Rendering
  if (!showDeleteModal) { return null; }

  return (
    <BasicModal
      showModal={true}
      toggleModal={toggleModal}
      title="Delete Rule"
      subtitle="Are you sure you want to PERMANENTLY delete this Rule?"
      customClassName="logout-modal"
    >
      <div className="row-container logout-modal">
        <Button
          color="light-gray left-modal-button"
          outline
          onClick={toggleModal}
        >
          Cancel
        </Button>
        <Button
          className="btn btn-submit btn-critical text-dark font-weight-bold delete-rule-button"
          onClick={() => deleteRules()}
          disabled={deleteLoading || !canDeleteRuleSets}
        >
          Yes, Delete it
        </Button>
      </div>
    </BasicModal>
  );
};

export default DeleteAutomationRulesModal;
