import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, ModalFooter } from 'reactstrap';
import { Can } from '../../contexts/AbilityContext';
import filterTypes from '../../constants/filterTypes';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
//import companyIdSelector from '../../selectors/companyIdSelector';
import BasicModal from '../layout/BasicModal';
import TimeFilters from '../layout/filters/TimeFilters';
import TagsFiltering from '../tags/TagsFiltering';
import IssuesAssigneeFilter from './issue-filters/IssuesAssigneesFilter';
import IssuesCriticalityFilters from './issue-filters/IssuesCriticalityFilters';
import IssuesSolutionFilter from './issue-filters/IssuesSolutionFilter';
import IssuesStatusFilters from './issue-filters/IssuesStatusFilters';
import CompanyInfiniteDropdown from '../layout/CompanyInfiniteDropdown';
import IssuesDeviceTypesFilter from './issue-filters/IssuesDeviceTypesFilter';
import API from '../../api';
import { onImpersonationSuccess } from '../../actions/impersonation';
import { setAlert } from '../../actions/alerts';
import { getSafeArray } from '../../utils/helpers';
import useCustomMutation from '../../hooks/useCustomMutation';
import { issueTypes } from '../../constants/issues';
import issuesInfoSelector from '../../selectors/issuesInfoSelector';
import { getIssuesFiltersState } from '../../utils/filterHelpers';
import IssuesPriorityFilter from './issue-filters/IssuesPriorityFilter';
import { applyIssuesAdvancedFilters } from '../../actions/issues';
import IssueOverdueFilters from './issue-filters/IssueOverdueFilters';
import useCanAbility from '../../hooks/useCanAbility';
import { CREATE_ACTION, PRIVILEGE_SOURCES, READ_ACTION } from '../../constants/privileges';
import { getStatusFilterByIssuesType } from '../../utils/issuesHelpers'
import IssueSourceTypesFilter from './issue-filters/IssueSourceTypesFilter';
import { useParams } from 'react-router-dom';
import { issuesFiltersInitialState } from '../../constants/common';
import { getPartnerFilters, getPartnerIdsFromIssueFilters, shouldShowPartnerFilters } from '../../utils/groupHelpers';
import IssueMultipleSolutionFilter from './issue-filters/IssueMultipleSolutionFilter';
import PartnerFilters from '../app-v4/groups/partner-filters/PartnerFilters';
import useGroupsLibrary from '../../hooks/useGroupsLibrary';
import companyInfoSelector from '../../selectors/companyInfoSelector';

const IssuesAdvancedFiltering = (props) => {
    const {
        showModal,
        toggleModal,
        issueType = issueTypes.VULNERABILITIES,
    } = props;

    //  Initializing APIs
    const { UserAPI } = API;

    // Params
    const { group_id } = useParams();

    const filtersInitialState = {
        ...issuesFiltersInitialState,
        group_id: issueTypes?.GROUP_INDIVIDUAL === issueType ? group_id : null, // Group id is always applied
    };

    const initialState = {
        filters: filtersInitialState,
        activeFilters: []
    }

    //  Getting needed info from redux store
    const filters = useSelector(state => issuesInfoSelector(state, "filters", issueType));
    const activeFilters = useSelector(state => issuesInfoSelector(state, "activeFilters", issueType));
    const isMemberView = useSelector(state => state.impersonation.isMemberView);
	const companyName = useSelector(state => companyInfoSelector(state, 'name', false));

    // Component state
    const [state, setState] = useState(initialState);
    const [selectedMemberName, setSelectedMemberName] = useState(companyName)

    //Component Hooks
    const { dispatch } = useMemoizedDispatch();
    const ability = useCanAbility();


    //Check changes in the filters from the redux store
    useEffect(() => {
        setState({
            filters,
            activeFilters
        })
    }, [filters, activeFilters, showModal, setState]);

    //  Getting groups library
    const {
        partner_configs,
    } = useGroupsLibrary(getPartnerIdsFromIssueFilters(state?.filters));

    //  Post to select company in Impersonation mode
    const {
        data: impersonateResponse,
        error: impersonateError,
        isLoading: impersonateLoading,
        mutate: impersonateCompany,
    } = useCustomMutation(
        (params) => UserAPI.handlers.impersonateCompany(params),
        UserAPI.invalidators.impersonateCompany
    );

    // Watching impersonate response
    useEffect(() => {
        if (!impersonateResponse) { return; }
        dispatch(onImpersonationSuccess(impersonateResponse));
        dispatch(applyIssuesAdvancedFilters(state?.filters, state?.activeFilters, issueType));
        toggleModal();
    }, [impersonateResponse]);

    //  Watching impersonate error
    useEffect(() => {
        if (!impersonateError) { return; }
        dispatch(setAlert('Error selecting company!', 'danger'));
        const params = {
            filterName: 'company_id',
            value: null,
            label: '',
        }
        const filterState = getFilterState(params)
        setState(filterState)
        dispatch(onImpersonationSuccess(null));
    }, [impersonateError]);

    // Return state for filters and active filters,
    // payload: { filterName, value, label, filterOption, type = filterTypes.single }
    const getFilterState = (payload) => {
        return getIssuesFiltersState(state?.filters, state?.activeFilters, payload)
    }

    //  Function to handle partner filters changes
    const onPartnerFilterChange = (attribute, name, option) => {
        const payload = {
            filterName: attribute,
            value: option?.value,
            label: `${name}: ${option?.label}`,
            filterOption: option,
            type: filterTypes?.single,
        };
        const filterState = getFilterState(payload);
        setState(filterState);
    };

    const renderFilterRow = (label, filters) => {
        return (
            <div className="filters-row">
                <div className="filter-label pr-3">{label}</div>
                <div className="filters-container">{filters}</div>
            </div>
        )
    }

    const renderAssigneeFilter = () => {
        const assigneeFilter = (
            <IssuesAssigneeFilter
                selectedCompany={state.filters.company_id}
                filterValue={state.filters.assigned_to}
                setFilterCallback={(option) => {
                    const params = {
                        filterName: 'assigned_to',
                        value: option?.value,
                        label: option?.label,
                    }
                    const filterState = getFilterState(params)
                    setState(filterState)
                }}
                showClearButton={true}
                showAllMembersOption={false}
            />
        )
        return renderFilterRow('Assigned to', assigneeFilter)
    }

    const renderMembersFilter = () => {
        if (isMemberView || issueType === issueTypes.GROUP_INDIVIDUAL) return null;
        const showMemberActiveFilter = issueTypes.VULNERABILITIES !== issueType;
        const isCompanyIdFilter = element => element.filterName === 'company_id'
        const selectedMemberLabel = showMemberActiveFilter ? (state.activeFilters ? state.activeFilters.find(isCompanyIdFilter)?.label : null) : selectedMemberName;
        const memberFilter = (
            <CompanyInfiniteDropdown
                selectedValue={state.filters.company_id}
                selectedLabel={selectedMemberLabel}
                onSelectCallback={(option) => {
                    const params = {
                        filterName: 'company_id',
                        value: option?.value,
                        label: option?.label,
                        hideActiveFilter: !showMemberActiveFilter,
                    }
                    const filterState = getFilterState(params)
                    setState(filterState)
                    setSelectedMemberName(option?.label)
                }}
                placeholder='Select member'
                className='member-filter filter-dropdown'
                customMenuPosition='absolute'
                showClearButton={showMemberActiveFilter}
            />
        )
        return (
            <Can I={CREATE_ACTION} a={PRIVILEGE_SOURCES.MULTIPLE.IMPERSONATE}>
                {renderFilterRow('Members', memberFilter)}
            </Can>
        )

    }

    const renderCriticalityFilters = () => {
        const criticalitiesFilter = (
            <IssuesCriticalityFilters
                filterValue={getSafeArray(state?.filters?.severity)}
                setFilterCallback={(option) => {
                    const params = {
                        filterName: 'severity',
                        value: option?.value,
                        label: option?.label,
                        type: filterTypes.multiple
                    }
                    const filterState = getFilterState(params)
                    setState(filterState)
                }}
                wrapperClassName="d-inline-block mr-2"
                showNumber={false}
                useCriticalityClassName={false}
                issueType={issueType}
            />
        )
        return renderFilterRow('Open by Criticality', criticalitiesFilter)
    }

    const renderStatusFilters = () => {
        const statusesFilter = (
            <IssuesStatusFilters
                filterValue={getSafeArray(state?.filters?.status)}
                setFilterCallback={(option) => {
                    const params = {
                        filterName: 'status',
                        value: option?.value,
                        label: option?.label,
                        type: filterTypes.multiple
                    }
                    const filterState = getFilterState(params)
                    setState(filterState)
                }}
                showNumber={false}
                wrapperClassName="d-inline-block mr-2"
                optionsFilterFunction={element => getStatusFilterByIssuesType(element,issueType)}
                issueType={issueType}
            />
        )
        return renderFilterRow('Current Status', statusesFilter)
    }

    const renderSolutionFilter = () => {
        const solutionFilter = (
            <IssuesSolutionFilter
                filterValue={state.filters.solution_id}
                setFilterCallback={(option) => {
                    const params = {
                        filterName: 'solution_id',
                        value: option?.value,
                        label: option?.label,
                    }
                    const filterState = getFilterState(params)
                    setState(filterState)
                }}
            />
        )
        return renderFilterRow('Solutions', solutionFilter)
    }

    //  Function to update filters
    const changeSolutionFilter = (option) => {
        if (!option) { return; }
        const params = {
            filterName: 'multiple_solution_id',
            value: option?.value,
            label: option?.label,
            type: filterTypes.multiple,
        }
        const filterState = getFilterState(params)
        setState(filterState)
    };

    // Render multiple solutions filter
    const renderMultipleSolutionsFilter = () => {
        if(issueType !== issueTypes.GROUP_INDIVIDUAL) return null;
        return (
            <IssueMultipleSolutionFilter
                onChange={changeSolutionFilter}
                selectedElements={getSafeArray(state?.filters?.multiple_solution_id).reduce((elements, id) => {
                    const activeFilter = state?.activeFilters?.find(element => element.filterName === 'multiple_solution_id' && element?.value === id)
                    if(!activeFilter) return elements;
                    return [
                        ...elements,
                        {
                            filterName: 'multiple_solution_id',
                            value: activeFilter?.value,
                            label: activeFilter?.label,
                        }
                    ]
                }, [])}
                issueType={issueType}
            />
        )
    }

    const renderTagsFilter = () => {
        const tagsFilterName = 'tags'
        const tagsList = state.activeFilters?.filter(filter => filter?.filterName === tagsFilterName);
        const tagsFilter = (
            <TagsFiltering
                filteredTags={tagsList}
                callback={(option) => {
                    const params = {
                        filterName: tagsFilterName,
                        value: option?.value,
                        label: option?.label,
                        type: filterTypes.multipleWithOptions,
                        filterOption: option?.filterOption
                    }
                    const filterState = getFilterState(params)
                    setState(filterState)
                }}
            //companyId={companyId} // TODO: add once impersonation is enabled
            />
        )
        return renderFilterRow('Tags', tagsFilter)
    }

    const renderTimeFilter = () => {
        const { time_since_creation } = state.filters;
        const timeFilter = (
            <TimeFilters
                days={time_since_creation?.days}
                hours={time_since_creation?.hours}
                minutes={time_since_creation?.minutes}
                milliseconds={time_since_creation?.milliseconds}
                value={time_since_creation}
                callback={(value, label) => {

                    const params = {
                        filterName: 'time_since_creation',
                        value: value?.milliseconds ? value : null,
                        label: label,
                        type: filterTypes.single,
                    }
                    const filterState = getFilterState(params)
                    setState(filterState)
                }}
                backwards={true}
            />
        )
        return renderFilterRow('Time elapsed since creation', timeFilter)
    }

    const onClearAllClick = () => {
        setState(initialState)
    }

    const onApplyFiltersClick = () => {
        const newValue = state.filters.company_id;
        const memberFilterChanged = filters.company_id !== newValue
        const canImpersonate = ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.MULTIPLE.IMPERSONATE)
        if (canImpersonate && memberFilterChanged) {
            impersonateCompany(newValue);
        } else {
            dispatch(applyIssuesAdvancedFilters(state?.filters, state?.activeFilters, issueType))
            toggleModal();
        }
    }

    //  Function to change device types
    const changeDeviceTypes = (newDeviceTypes = [], newActiveFilters = state?.activeFilters) => {
        setState({
            ...state,
            activeFilters: newActiveFilters,
            filters: {
                ...state.filters,
                device_types: newDeviceTypes
            }
        });
    };

    //  Function to change issue source types
    const changeIssueSourceTypes = (newSourceTypes, newActiveFilters = state?.activeFilters) => {
        setState({
            ...state,
            activeFilters: newActiveFilters,
            filters: {
                ...state.filters,
                source: newSourceTypes,
            }
        });
    };

    const renderApplyOptions = () => {
        return (
            <ModalFooter className="m-0">
                <div className="text-center" >
                    <Button
                        color="link"
                        className="text-white font-weight-bold px-2"
                        onClick={onClearAllClick}
                        style={{ marginRight: "11px" }}
                    >
                        Clear all
                    </Button>
                    <Button
                        className="btn btn-light text-dark font-weight-bold"
                        onClick={onApplyFiltersClick}
                        disabled={impersonateLoading}
                    >
                        Apply Filters
                    </Button>
                </div>
            </ModalFooter>
        )
    };

    //  Function to render device types filters
    const renderDeviceTypesFilters = () => {
        if(issueType === issueTypes.GRC) { return null };
        const canImpersonate = ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.MULTIPLE.IMPERSONATE)
        return (
            <IssuesDeviceTypesFilter
                isImpersonating={canImpersonate}
                filter_company_id={state.filters.company_id}
                selectedTypes={state?.filters?.device_types}
                activeFilters={state?.activeFilters}
                setSelectedTypes={changeDeviceTypes}
            />
        );
    };

    //  Function to render issue source types filters
    const renderIssueSourceTypesFilters = () => {
        if(issueType === issueTypes.GRC) { return null };
        const canImpersonate = ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.MULTIPLE.IMPERSONATE);
        return (
            <IssueSourceTypesFilter
                isImpersonating={canImpersonate}
                filter_company_id={state.filters.company_id}
                selectedTypes={getSafeArray(state?.filters?.source)}
                activeFilters={state?.activeFilters}
                setSelectedTypes={changeIssueSourceTypes}
            />
        );
    };

    // Function to render priority filters
    const renderPriorityFilters = () => {
        if(issueType !== issueTypes.GRC) { return null };
        const prioritiesFilter = (
            <IssuesPriorityFilter
                filterValue={getSafeArray(state?.filters?.priority)}
                setFilterCallback={(option) => {
                    const params = {
                        filterName: 'priority',
                        value: option?.value,
                        label: `${option?.label} Priority`,
                        type: filterTypes.multiple
                    }
                    const filterState = getFilterState(params)
                    setState(filterState)
                }}
                showNumber={false}
                wrapperClassName="d-inline-block mr-2"
                issueType={issueType}
            />
        )
        return renderFilterRow('Priority', prioritiesFilter)
    }

    const setDueDateFilterCallback = (value, label) => {
        const params = {
            filterName: 'due_date',
            value: value?.milliseconds ? value : null,
            label: label,
            type: filterTypes.single,
            hideActiveFilter: false,
        }
        const filterState = getFilterState(params)
            setState(filterState)
    }

    const renderDueDateFilter = () => {
        if(issueType !== issueTypes.GRC) { return null };
        const { due_date } = state.filters;
        const dueDateTimeFilter = (
            <TimeFilters
                days={due_date?.days}
                hours={due_date?.hours}
                minutes={due_date?.minutes}
                milliseconds={due_date?.milliseconds}
                value={due_date}
                backwards={false}
                callback={setDueDateFilterCallback}
            />
        )
        return renderFilterRow('Time until Due Date', dueDateTimeFilter);
    };

    //  Function to render overdue filters
    const renderOverdueFilters = () => {
        if (issueType !== issueTypes.GRC) { return null; }
        return (
            <IssueOverdueFilters
                filterValue={state?.filters?.overdue}
                filterType={filterTypes.single}
                getFilterState={getFilterState}
                setState={setState}
            />
        );
    };

    //  Function to render partner partner filters
    const renderPartnerFilters = () => {
        if (issueType === issueTypes.GRC || !shouldShowPartnerFilters(partner_configs)) { return null; }
        const partnerFilters = getPartnerFilters(state?.filters, partner_configs);
        return (
            <PartnerFilters
                filters={partnerFilters}
                partner_configs={partner_configs}
                onFilterChange={onPartnerFilterChange}
            />
        );
    };

    return (
        <BasicModal
            title={`Filter ${issueType === issueTypes.GRC ? 'Tasks' : 'Issues'}`}
            customClassName="issues-advanced-filtering"
            showModal={showModal}
            toggleModal={toggleModal}
            scrollable={true}
            customFooter={renderApplyOptions()}
        >
            {renderAssigneeFilter()}
            {renderMembersFilter()}
            {renderOverdueFilters()}
            {renderCriticalityFilters()}
            {renderStatusFilters()}
            {renderPriorityFilters()}
            {renderMultipleSolutionsFilter()}
            {renderTagsFilter()}
            {renderDeviceTypesFilters()}
            {renderIssueSourceTypesFilters()}
            {renderPartnerFilters()}
            {renderDueDateFilter()}
            {renderTimeFilter()}
        </BasicModal>
    )
}

export default IssuesAdvancedFiltering;
