import { useEffect, useState } from 'react';
import { removeItemFromArray } from '../utils/helpers';

const useSelectedList = (params) => {
  //  Parsing params
  const {
    eventMap = {} // Having map for actions {changeAll, add, remove}
  } = params;

  //  Hook state
  const [allSelected, setAllSelected] = useState(false);
  const [excluding, setExcluding] = useState(false);
  const [selected, setSelected] = useState([]);
  const [excluded, setExcluded] = useState([]);

  //  Watching excluded changes
  useEffect(() => {
    if (!excluding || excluded.length > 0) { return; }
    setExcluding(false);
  }, [excluded]);

  //  Function to removeElement from an array
  const removeElementFromArray = (element, array) => {
    const indextoRemove = array.findIndex((item) => item === element);
    if (indextoRemove === -1) { return array; }
    return removeItemFromArray(array, indextoRemove);
  };

  //  Function to add an element to list
  const addSelectedElement = (element) => {
    if (excluding) {
      const newExcluded = removeElementFromArray(element, excluded);
      setExcluded(newExcluded);
      return;
    }
    const newSelected = Array.from(new Set([...selected, element]));
    setSelected(newSelected);
  };

  //  Function to remove selected element
  const removeSelectedElement = (elementToRemove) => {
    if (allSelected) {
      setExcluding(true);
      const newExcluded = Array.from(new Set([...excluded, elementToRemove]));
      setExcluded(newExcluded);
      return;
    }

    const newSelected = removeElementFromArray(elementToRemove, selected);
    setSelected(newSelected);
  };

  //  Select all elements
  const changeSelectAll = (flag = false) => {
    setAllSelected(flag);
    setSelected([]);
    setExcluded([]);
    setExcluding(false);
  };

  //  Function to handle all events
  const eventHandler = (type, params) => {
    switch (type) {
      case eventMap.changeAll:
        changeSelectAll(params);
        break;
      case eventMap.add:
        addSelectedElement(params);
        break;
      case eventMap.remove:
        removeSelectedElement(params);
        break;
    }
  };

  //  Function to clear selected
  const clearSelected = () => {
    setAllSelected(false);
    setExcluding(false);
    setExcluded([]);
    setSelected([]);
  };

  //  Hook output
  return {
    allSelected,
    selected,
    excluded,
    eventHandler,
    clearSelected,
    setSelected,
    setAllSelected
  }
};

export default useSelectedList;
