import React from 'react'
import ContextMenu from '../context-menu/ContextMenu';
import ContextMenuOption from '../context-menu/ContextMenuOption';

const DevicesContextMenu = (props) => {
    const {
        parentRef,
        onRightClick,
        toggleTagsModal,
        scrollableRef,
        onDeleteDevices,
        onMergeDevices,
    } = props;

    const onTagsClick = () => {
        toggleTagsModal()
    }

    const onSelectCallback = (element) => {
        onRightClick(element)
    }

    return (
        <ContextMenu
            parentRef={parentRef}
            elementType="tr"
            onElementSelectCallback={onSelectCallback}
            minMenuWidth={100}
            minMenuHeight={30}
            applyOffset={true}
            yOffset={25}
            scrollableRef={scrollableRef}
        >
            <ContextMenuOption
                label="Tags"
                callback={onTagsClick}
                className="bg-dark"
            />
            {/*<ContextMenuOption
                label="Merge"
                callback={onMergeDevices}
                className="bg-dark"
            />*/}
            <ContextMenuOption
                label="Delete"
                callback={onDeleteDevices}
                className="rounded bg-dark"
            />
        </ContextMenu>
    )
}

export default DevicesContextMenu
