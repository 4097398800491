import Cookies from "universal-cookie";
import { LAST_NOTIFICATION_COOKIE } from "../constants/cookies";

export const setUserCookies = searchParams => {
  if (searchParams) {
    const queryParams = new URLSearchParams(searchParams);
    const cookies = new Cookies();
    const first_name = queryParams.get('First+Name') || queryParams.get('First Name') || '';
    const last_name = queryParams.get('Last+Name') || queryParams.get('Last Name') || '';

    cookies.set('first_name', first_name, { path: '/' });
    cookies.set('last_name', last_name, { path: '/' });
    cookies.set('email', queryParams.get('email') || '', { path: '/' });
    cookies.set('utm_campaign', queryParams.get('utm_campaign') || '', { path: '/' });
    cookies.set('utm_source', queryParams.get('utm_source') || '', { path: '/' });
  }
}

export const clearUserCookies = () => {
  const cookies = new Cookies();
  cookies.set('first_name', '', { path: '/' });
  cookies.set('last_name', '', { path: '/' });
  cookies.set('email', '', { path: '/' });
  cookies.set('utm_campaign', '', { path: '/' });
  cookies.set('utm_source', '', { path: '/' });
};

export const initializeMFACookie = (email) => {
  const cookies = new Cookies();
  const newDate = new Date();
  newDate.setDate(newDate.getDate() + 30);
  cookies.set(email || 'mfa_date', newDate, { path: '/' });
};

export const setLastReadNotificationCookie = (notification_id, date, cookieName, userPublicId) => {
  if(!cookieName || !userPublicId) return;
  const cookies = new Cookies();
  const params = { id: notification_id, date };
  const value = JSON.stringify(params);
  const maxAge = 360 * 24 * 60 * 60;
  const options = { path: '/', maxAge };
  cookies.set(`${cookieName}-${userPublicId}`, value, options)
}

export const getLastReadNotificationCookie = (cookieName, userPublicId) => {
  if(!cookieName) return;
  const cookies = new Cookies();
  const notificationCookie = cookies.get(`${cookieName}-${userPublicId}`);
  if(!notificationCookie) return null;
  return notificationCookie
}
