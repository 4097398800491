import _ from 'lodash';
import React, { useState, useEffect } from 'react'
import { timeFilters } from '../../../constants/filterTypes';
import { convertToMilliseconds } from '../../../utils/dateFormatter';
import TimeField from '../fields/TimeField';

const TimeFilters = (props) => {
    const {
        days,
        hours,
        minutes,
        milliseconds,
        className = "",
        callback,
        backwards = true
    } = props;

    const initialState = {
        days,
        hours,
        minutes,
        milliseconds
    }

    // Prefix for input field name
    const inputFieldPrefix = 'time-filter-field-';

    // Component state
    const [state, setState] = useState(initialState);

    // Get days, hours and minutes in milliseconds
    const getMilliseconds = (days, hours, minutes) => {
        return convertToMilliseconds(days, timeFilters.DAYS) + convertToMilliseconds(hours, timeFilters.HOURS) + convertToMilliseconds(minutes, timeFilters.MINUTES)
    }

    const fields = [
        {
            name: "days",
            label: "days",
            value: days,
            maxDigitLength: 3,
            placeholder: "000",
            className: "three-digits-time-field"
        },
        {
            name: "hours",
            label: "hours",
            value: hours
        },
        {
            name: "minutes",
            label: "minutes",
            value: minutes
        }
    ]

    const formatTimeLabel = (value, unit) => {
        return value ? `${value} ${unit}` : ''
    }

    useEffect(() => {
        const { days, hours, minutes } = state;
        const daysLabel = formatTimeLabel(days, 'days');
        const hoursLabel = formatTimeLabel(hours, 'hours');
        const minutesLabel = formatTimeLabel(minutes, 'minutes');
        const label = `${backwards ? ' Last ' : 'Due in '}${daysLabel} ${hoursLabel} ${minutesLabel}`;
        const milliseconds = getMilliseconds(days, hours, minutes)
        callback({ ...state, milliseconds }, label)
    }, [state])

    const onChangeCallback = ({ count, fieldName, index, hasMaxDigits, isEmpty }) => {
        const newState = {
            ...state,
            [fieldName]: count,
        }
        setState(newState)
        if (hasMaxDigits) {
            if (index < fields?.length) {
                const nextSibling = document.querySelector(
                    `input[name=${inputFieldPrefix}${index + 1}]`
                );
                if (nextSibling !== null) {
                    nextSibling.focus();
                }
            }
        }

        if (isEmpty) {
            const prevSibling = document.querySelector(
                `input[name=${inputFieldPrefix}${index - 1}]`
            );
            if (prevSibling !== null) {
                prevSibling.focus();
            }
        }
    }

    const ariaLabelCreatedSince = () => {
        let ariaLabel = `Filtering by time elapsed since creation: `;
        fields.map((field, index) => {
            ariaLabel = ariaLabel.concat(field?.value ? field.value : "0", " ", field?.label);
            if (index !== fields.length - 1) {
                ariaLabel = ariaLabel.concat(", ");
            };
        });
        return ariaLabel;
    };

    return (
        <div className={`time-filters ${className}`} aria-label={ariaLabelCreatedSince()} tabIndex="0">
            {fields.map((field, index) => {
                const inputName = `${inputFieldPrefix}${index}`
                return (
                    <TimeField
                        key={inputName}
                        index={index}
                        value={field?.value}
                        label={field?.label}
                        inputName={inputName}
                        fieldName={field?.name}
                        placeholder={field?.placeholder}
                        unit={field?.unit}
                        maxDigitLength={field?.maxDigitLength}
                        callback={onChangeCallback}
                        className={`${field?.className} mr-2`}
                    />
                )
            })}
        </div>
    )
}

export default TimeFilters
