import React from 'react';
import Paginate from 'react-paginate';

const CustomPagination = (props) => {
    const {
        currentPage,
        pageCount,
        onPageChange
    } = props;
    return pageCount > 1 && (
        <Paginate
            forcePage={currentPage}
            pageCount={pageCount}
            marginPagesDisplayed={0}
            pageRangeDisplayed={4}
            containerClassName={"pagination pagination-rounded justify-content-end mb-2"}
            onPageChange={onPageChange}
            previousLabel="‹"
            previousClassName="page-link previous"
            nextLabel={"›"}
            nextClassName="page-link next"
            activeClassName="active"
            breakClassName="d-none"
            pageClassName="page-item"
            pageLinkClassName="page-link focus-visible-light"
            disabledClassName="disabled"
        />
    )
}
export default CustomPagination;
