import React, { useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Card, CardBody, Col } from "reactstrap";
import LoadingContent from "../../../layout/LoadingContent";
import InfoTooltip from "../../../dashboard/tooltips/InfoTooltip";
import BasicDoughnutChart from "../../../dashboard/charts/BasicDoughnutChart";
import API from "../../../../api";
import { rollUpWidgetsIds } from "../../../../constants/common";
import {
  onIssueTotalsSuccess,
  onReloadIssuesRollup,
} from "../../../../actions/issues";
import companyInfoSelector from "../../../../selectors/companyInfoSelector";
import useMemoizedDispatch from "../../../../hooks/useMemoizedDispatch";
import useCustomQuery from "../../../../hooks/useCustomQuery";
import { issueTypes } from "../../../../constants/issues";
import issuesInfoSelector from "../../../../selectors/issuesInfoSelector";
import useUserType from "../../../../hooks/useUserType";

const DashboardRollup = (props) => {
  const {
    sm = 6,
    lg = 6,
    sumAll = false,
    allowAllCompanies,
    company_id,
    widgetType = rollUpWidgetsIds.STATUS,
    solution_id = null,
    containerClassName = "",
    extraColClassName = "",
    cardClassName = "",
    handleRollupClick,
    addTotalFilter,
    issueType = issueTypes.GRC,
    callback,
    customEmptyLabel,
    customEmptyLabelClassName
  } = props;

  //  Initializing APIs
  const { IssueAPI } = API;

  //  Getting needed info from redux store
  const freemium = useSelector((state) =>
    companyInfoSelector(state, "freemium", false)
  );
  const shouldReloadRollup = useSelector((state) =>
    issuesInfoSelector(state, "shouldReloadRollup", issueType)
  );

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const userType = useUserType();

  //  Calling API for data
  const {
    data: rollupData,
    error: rollupDataError,
    isLoading: rollupDataLoading,
    refetch: refetchRollUpData,
  } = useCustomQuery(
    [
      IssueAPI.keys.getRollupData,
      {
        company_id,
        params: {
          open_only: true,
          solution_id,
          grc_only: issueTypes.GRC === issueType,
        },
      },
      allowAllCompanies,
    ],
    ({ queryKey }) => IssueAPI.handlers.getRollupData(queryKey[1], queryKey[2])
  );

  //  Watching roll up data
  useEffect(() => {
    if (!rollupData) {
      return;
    }
    dispatch(onIssueTotalsSuccess(rollupData, issueType, userType));
  }, [rollupData]);

  const retryRequest = () => {
    if (!company_id) {
      return;
    }
    refetchRollUpData();
  };

  //  Function to handle legend click
  const onLegendClicked = (element, widgetType) => {
    if (!handleRollupClick || typeof handleRollupClick !== "function") {
      return;
    }
    handleRollupClick(element, widgetType);
  };

  //  Function to handle total label click
  const onTotalClicked = (elements, widgetType) => {
    if (!handleRollupClick || typeof handleRollupClick !== "function") {
      return;
    }
    handleRollupClick(elements, widgetType, true);
  };

  // Function to render doughnut chart
  const renderDoughnutChart = ({
    data,
    colorsConfig,
    unitLabel,
    total,
    widgetType,
  }) => {
    const legendWidth =
      widgetType === rollUpWidgetsIds.STATUS ? 170 : undefined;
    return (
      <BasicDoughnutChart
        data={data}
        colorsConfig={colorsConfig}
        totalLabelUnit={unitLabel}
        total={total}
        zeroLabel={customEmptyLabel}
        onLegendClicked={(element) => onLegendClicked(element, widgetType)}
        onTotalClicked={
          addTotalFilter
            ? (elements) => onTotalClicked(elements, widgetType)
            : null
        }
        legendWidth={legendWidth}
        customEmptyLabelClassName={customEmptyLabelClassName}
      />
    );
  };

  const renderRollup = (props, widgetType) => {
    const {
      data,
      colorsConfig,
      total,
      unitLabel,
      componentId,
      titleTooltip,
      title,
    } = props;

    const chartParams = {
      data,
      colorsConfig,
      unitLabel,
      total,
      widgetType,
    };
    const contentStyle = {
      marginTop: "20px",
      maxHeight: "130px",
      marginBottom: "25px",
    };
    return (
      <div className={containerClassName}>
        <Col
          key={`rollup-widget-${componentId}`}
          sm={sm}
          lg={lg}
          className={`rollup-widget-container align-self-start align-self-lg-stretch mb-2 ${extraColClassName}`}
        >
          <Card className={cardClassName}>
            <CardBody className="rollup-widget d-flex flex-column">
              <div className="d-flex align-items-center">
                <InfoTooltip
                  label={title}
                  info={titleTooltip}
                  wrapperClassName="dashboard-widget-title"
                  id={`rollup-tooltip-${componentId}`}
                  showTooltip={false}
                />
              </div>
              <div className="d-flex flex-grow-1 align-items-center doughnut-chart-container">
                <LoadingContent
                  isLoading={rollupDataLoading}
                  errorMessage={
                    rollupDataError ? "Oops! our fault, reload again." : null
                  }
                  iconType="solidIcon"
                  errorStyle={{ minHeight: "150px", paddingTop: "16px" }}
                  loadingStyle={{ minHeight: "150px", paddingTop: "16px" }}
                  errorButtonCallback={retryRequest}
                >
                  <div style={contentStyle}>
                    {renderDoughnutChart(chartParams)}
                  </div>
                </LoadingContent>
              </div>
            </CardBody>
          </Card>
        </Col>
      </div>
    );
  };

  //  Watching should reload flag changes
  useEffect(() => {
    if (!shouldReloadRollup) {
      return;
    }
    refetchRollUpData();
    dispatch(onReloadIssuesRollup(false, issueType));
  }, [shouldReloadRollup]);

  //  Function to render the required rollup widget
  const renderRequiredWidget = () => {
    const widgetConfig = callback(rollupData, sumAll);
    return renderRollup(widgetConfig, widgetType);
  };

  return (
    <>
      {renderRequiredWidget()}
    </>
  );
};

export default DashboardRollup;
