import React, { useEffect, useMemo } from 'react';
import CustomActionModal from '../../layout/CustomActionModal';
import { setAlert } from '../../../actions/alerts';
import { recycleBinEntities } from '../../../constants/common';
import { issueTypes } from '../../../constants/issues';
import useRecycleBinMutations from '../../../hooks/useRecycleBinMutations';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';

const RecycleBinActionModal = (props) => {
  //  Parsing props
  const {
    entity = recycleBinEntities.devices,
    idsToDelete = [],
    idsToRestore = [],
    closeModal,
  } = props;

  //  Component variables
  const title = useMemo(() => {
    if (idsToDelete.length > 0) {
      return `Permanently delete selected ${entity} (${idsToDelete.length})?`
    }
    return `Restore selected ${entity} (${idsToRestore.length})?`;
  }, [idsToDelete, idsToRestore, entity]);

  const message = useMemo(() => {
    if (idsToDelete.length > 0) {
      return `Once you delete selected ${entity} you won't be able to undo this action`;
    }
    return `This ${entity} will be restored to their previous state`;
  }, [idsToDelete, idsToRestore, entity]);

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const { restore, delete: hardDelete } = useRecycleBinMutations({ entity });

  //  Watching restore response
  useEffect(() => {
    if (!restore?.data) { return; }
    closeModal();
    dispatch(setAlert(`Selected ${entity} restored successfully`, 'success'));
  }, [restore?.data]);

  //  Watching restore errors
  useEffect(() => {
    if (!restore?.error) { return; }
    dispatch(setAlert(`Error restoring ${entity}`, 'danger'));
  }, [restore?.error]);

  //  Watching delete response
  useEffect(() => {
    if (!hardDelete?.data) { return; }
    closeModal();
    dispatch(setAlert(`Selected ${entity} deleted successfully`, 'success'));
  }, [hardDelete?.data]);

  //  Watching delete errors
  useEffect(() => {
    if (!hardDelete?.error) { return; }
    dispatch(setAlert(`Error deleting ${entity}`, 'danger'));
  }, [hardDelete?.error]);

  //  Function to restore
  const restoreElements = () => {
    if (idsToRestore.length === 0) { return; }
    restore?.mutate(idsToRestore);
  };

  //  Function to hard delete
  const hardDeleteElements = () => {
    if (idsToDelete.length === 0) { return; }
    //  Preparing params
    const params = entity === recycleBinEntities.devices ? { device_ids: idsToDelete } : { issue_ids: idsToDelete };
    if (entity !== recycleBinEntities.devices) {
      params.issue_type = entity === recycleBinEntities.issues ? issueTypes.VULNERABILITIES : issueTypes.GRC;
    }

    //  Hard deleting elements
    hardDelete?.mutate(params);
  };

  //  Rendering
  return (
    <CustomActionModal
      closeModal={() => closeModal()}
      title={title}
      subtitle={message}
      actionText={idsToDelete.length > 0 ? 'Yes, Delete' : 'Yes, Restore'}
      actionClassName={`btn btn-submit btn-${idsToDelete.length > 0 ? 'red' : 'light'} text-dark font-weight-bold delete-rule-button`}
      disabled={idsToDelete.length > 0 ? hardDelete.isLoading : restore.isLoading}
      onActionClick={idsToDelete.length > 0 ? hardDeleteElements : restoreElements}
    />
  );
};

export default RecycleBinActionModal;
