import React from 'react'
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import useCanAbility from '../hooks/useCanAbility';
import PageNotFound from '../views/PageNotFound';

const NestedRoutesHandler = (props) => {
    const {
        routes
    } = props;

    // Component Hooks
    const { path: parentPath } = useRouteMatch();
    const ability = useCanAbility();

    // Render Routes
    const renderRoute = (route, index, canAccessRoute) => {
        return canAccessRoute && (
            <Route
                key={`nested-route-${index}`}
                component={route.component}
                path={`${parentPath}${route.path}`}
                exact={!route.hasSubroutes}
            />
        )
    }

    const renderRoutes = () => {
        if(!routes) { return };
        return routes.map((route, index) => {
            const {
                access,
                resource,
                from,
                to,
                isRedirect
            } = route;

            const canAccessRoute = access && resource ? ability.can(access, resource) : true;

            return isRedirect ? (
                <Redirect
                    key={`redirect-${index}`}
                    from={from}
                    to={to}
                />
            ): renderRoute(route, index, canAccessRoute);
        })
    }

    return (
        <>
            <Switch>
                {renderRoutes()}
                <Route component={PageNotFound} />
            </Switch>
        </>
    )
}

export default NestedRoutesHandler;
