export const formatPartnerName = (name) => {
    const partnerNames = {
        tenableapi: "Tenable",
        tenablefreemium: "Tenable Freemium",
    };
    const re = new RegExp(Object.keys(partnerNames).join("|"), "gi");
    return name?.replace(re, (name) => partnerNames[name?.toLowerCase()])
}

export const getActors = (actors) => {
    return actors?.map((actor) => actor.name)?.join(", ");
}

export const obsidianStates = {
    OPEN: 'OPEN',
    IN_PROGRESS: 'IN_PROGRESS',
    CLOSED: 'CLOSED'
}