import React from 'react';
import { Button } from 'reactstrap';
import { isValidFunction } from '../../utils/helpers';

const AddSubtaskButton = (props) => {
  const {
    callback,
    isDisabled,
    className = '',
  } = props;

  const onClick = () => {
    if(isValidFunction(callback)) { callback(); }
  }

  return (
    <Button
      outline
      color="light-gray"
      className={`btn-small ${className}`}
      onClick={onClick}
      disabled={isDisabled}
    >
      <div className='d-flex align-items-center'>
        <i className='bx bx-plus mr-2'/>
        Add subtask
      </div>
    </Button>
  )
}

export default AddSubtaskButton
