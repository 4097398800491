import cyvatarAxios from '../axios';
import {
  GET_DEVICES_COUNT,
  GET_DEVICES_LIST,
  GET_TAGS_LIST,
  GET_DEVICE_TYPES,
  GET_ISSUES_LIST,
  GET_ISSUE,
  GET_ISSUE_MODAL_OPTIONS,
  GET_ROLLUP_DATA,
  GET_COMPLIANCE,
  GET_COMPLIANCE_STATUS,
  FETCH_COMPLIANCE_SCORE,
  GET_CYVALUE_DATA,
  GET_CYVALUE_BY_WEEK,
  GET_ACTIVITY_REPORT,
  GET_DEVICE_BY_PARTNER_COUNT,
  GET_DEVICES_INFO,
  GET_DEVICES_BY_SOLUTION,
  GET_DEVICE_STATUS,
  GET_CURRENT_PROTECTION,
  GET_ARCHIVED_DEVICES,
  GET_ARCHIVED_ISSUES,
  GET_ISSUE_GROUP_DEVICES,
} from '../ApiKeys';

const getDevicesList = ({ companyId, devicesConfig }) => {
  return cyvatarAxios.get(`/api/workbench/devices/${companyId || ''}`, { params: devicesConfig });
};

const getDevicesCount = (companyId) => {
  return cyvatarAxios.get(`api/workbench/devices/count/${companyId || ''}`);
};

const getDevicesByPartnerCount = async (companyId) => {
  const response = await cyvatarAxios.get(`api/workbench/devices/partner_count/${companyId || ''}`);
  return response?.data
};

const putUpdateDeviceField = async ({ public_id, content }) => {
  const body = JSON.stringify(content);
  const response = await cyvatarAxios.put(`api/workbench/devices/update_device${public_id ? `/${public_id}` : ''}`, body);
  return response?.data;
};

//Adds a tag for device
//params: device_id, title, company_id (only required for cydekicks)
const addDeviceTag = async ({ device_id, title, company_id }) => {
  const response = await cyvatarAxios.patch(`api/workbench/devices/${device_id}/tags?title=${title}${company_id ? `&company_id=${company_id}` : ''}`);
  const { data } = response;
  return data;
};

//Deletes a tag for device
//params: device_id, global_tag_id, company_id (only required for cydekicks)
const removeDeviceTag = async ({ device_id, global_tag_id, company_id }) => {
  const response = await cyvatarAxios.delete(`api/workbench/devices/${device_id}/tags/${global_tag_id}${company_id ? `?company_id=${company_id}` : ''}`);
  const { data } = response;
  return data;
};

// Adds several tags for several issues
// params: device_ids: (array of ids), titles: (array of strings)
const addDeviceTagBulk = async ({ device_ids, titles }) => {
  const body = JSON.stringify({ device_ids, titles });
  const response = await cyvatarAxios.patch(`api/workbench/devices/tags/add`, body);
  const { data } = response;
  return data;
};

// Removes several tags for several devices
// params: device_ids: (array of ids), tag_ids: (array of global_tag_id)
const removeDeviceTagBulk = async (params) => {
  const body = JSON.stringify(params);
  const response = await cyvatarAxios.delete(`api/workbench/devices/tags/remove`, { data: body });
  const { data } = response;
  return data;
};

//  Params: { company_id, filters }
const getDeviceTypes = async (params) => {
  const response = await cyvatarAxios.get('api/workbench/devices/device_types', { params });
  const { data } = response;
  return data;
};

//  Function to delete devices (params = { device_ids: [] })
const deleteDevices = async (params) => {
  const body = JSON.stringify(params);
  const response = await cyvatarAxios.delete('/api/workbench/devices/devices_delete', { data: body });
  const { data } = response;
  return data;
};

//  Function to merge devices (params = { device_ids: [] })
const mergeDevices = async (params) => {
  const body = JSON.stringify(params);
  const response = await cyvatarAxios.post('/api/workbench/devices/devices_merge', body);
  const { data } = response;
  return data;
};

//  Function to get devices by id
const getDevicesInfo = async (device_ids = []) => {
  const response = await cyvatarAxios.get('api/workbench/devices/devices_info', { params: { device_ids } });
  const { data } = response;
  return data;
};

//  Function to get devices by solution
const getDevicesBySolution = async (params) => {
  const response = await cyvatarAxios.get('api/workbench/devices/devices_by_solution', { params });
  const { data } = response;
  return data;
};

const getDeviceStatus = async (params) => {
  const response = await cyvatarAxios.get('api/workbench/devices/devices_status', { params });
  const { data } = response;
  return data;
};

const getCurrentProtection = async ( company_id ) => {
  const response = await cyvatarAxios.get('/api/workbench/devices/current_protection', { params: { company_id } });
  const { data } = response;
  return data
};

const getArchivedDevices = async (params) => {
  const apiResponse = await cyvatarAxios.get('/api/workbench/devices/archived_list', { params });
  const { data } = apiResponse;
  return data;
};

const restoreDevices = async (ids = []) => {
  const apiResponse = await cyvatarAxios.post('/api/workbench/devices/restore_devices', { ids });
  const { data } = apiResponse;
  return data;
};

const hardDeleteDevices = async (params) => {
  const apiResponse = await cyvatarAxios.delete('/api/workbench/devices/devices_hard_delete', { data: params });
  const { data } = apiResponse;
  return data;
};

//  Array of queries to invalidate on issues changes
const issuesChangesInvalidators = [
  GET_ISSUES_LIST,
  GET_ISSUE,
  GET_ISSUE_MODAL_OPTIONS,
  GET_ROLLUP_DATA,
  GET_COMPLIANCE,
  GET_COMPLIANCE_STATUS,
  FETCH_COMPLIANCE_SCORE,
  GET_CYVALUE_DATA,
  GET_CYVALUE_BY_WEEK,
  GET_ACTIVITY_REPORT,
  GET_CURRENT_PROTECTION,
];

export const DeviceAPI = {
  handlers: {
    getDevicesList,
    getDevicesCount,
    putUpdateDeviceField,
    addDeviceTag,
    removeDeviceTag,
    addDeviceTagBulk,
    removeDeviceTagBulk,
    getDeviceTypes,
    deleteDevices,
    getDevicesByPartnerCount,
    mergeDevices,
    getDevicesInfo,
    getDevicesBySolution,
    getDeviceStatus,
    getCurrentProtection,
    getArchivedDevices,
    restoreDevices,
    hardDeleteDevices,
  },
  keys: {
    getDevicesList: GET_DEVICES_LIST,
    getDevicesCount: GET_DEVICES_COUNT,
    getDeviceTypes: GET_DEVICE_TYPES,
    getDevicesByPartnerCount: GET_DEVICE_BY_PARTNER_COUNT,
    getDevicesInfo: GET_DEVICES_INFO,
    getDevicesBySolution: GET_DEVICES_BY_SOLUTION,
    getDeviceStatus: GET_DEVICE_STATUS,
    getCurrentProtection: GET_CURRENT_PROTECTION,
    getArchivedDevices: GET_ARCHIVED_DEVICES,
  },
  invalidators: {
    putUpdateDeviceField: [GET_DEVICES_LIST],
    addDeviceTag: [GET_DEVICES_LIST, GET_DEVICES_COUNT, GET_TAGS_LIST],
    removeDeviceTag: [GET_DEVICES_LIST, GET_DEVICES_COUNT, GET_TAGS_LIST],
    addDeviceTagBulk: [GET_DEVICES_LIST, GET_DEVICES_COUNT, GET_TAGS_LIST, GET_ISSUE_GROUP_DEVICES],
    removeDeviceTagBulk: [GET_DEVICES_LIST, GET_DEVICES_COUNT, GET_TAGS_LIST, GET_ISSUE_GROUP_DEVICES],
    deleteDevices: [
      GET_DEVICES_LIST,
      GET_DEVICES_COUNT,
      GET_DEVICE_BY_PARTNER_COUNT,
      ...issuesChangesInvalidators,
      GET_ARCHIVED_DEVICES,
    ],
    mergeDevices: [
      GET_DEVICES_LIST,
      GET_DEVICES_COUNT,
      GET_DEVICE_BY_PARTNER_COUNT,
      ...issuesChangesInvalidators,
    ],
    restoreDevices: [
      ...issuesChangesInvalidators,
      GET_DEVICES_LIST,
      GET_DEVICES_COUNT,
      GET_DEVICE_BY_PARTNER_COUNT,
      GET_ARCHIVED_DEVICES,
    ],
    hardDeleteDevices: [
      GET_ARCHIVED_DEVICES,
      GET_ARCHIVED_ISSUES,
    ]
  }
};
