import React from 'react';
import { Button } from 'reactstrap';
import { isValidFunction } from '../../../utils/helpers';
import BasicTooltip from './BasicTooltip';

const IconTooltip = (props) => {
  const {
    iconClassName='',
    tooltipCustomClassName='',
    tooltipText,
    targetId="icon-tooltip-id",
    callback,
    disabled = false,
  } = props;

  const onIconClick = () => {
    if(isValidFunction(callback)) {
      callback();
    }
  }
  return (
    <div className="icon-tooltip">
      <Button
        color="link"
        onClick={onIconClick}
        disabled={disabled}
      >
        <i id={targetId} className={iconClassName || 'bx bx-info-circle'} />
      </Button>
      {tooltipText && (
        <BasicTooltip
          className={`icon-tooltip-content ${tooltipCustomClassName}`}
          target={targetId}
          content={tooltipText}
        />
      )}
    </div>
  )
}

export default IconTooltip
