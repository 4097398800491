import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import ReactApexChart from "react-apexcharts";
import { abbreviateNumber, orderAlphaNumeric } from '../../utils/helpers';
import clapEmoji from '../../assets/images/emoji/clap_emoji.svg'

const BasicDoughnutChartRisk = (props) => {
    const {
        data,
        total,
        hasVcyso,
        totalLabelUnit,
        height = 189.7,
        width = 333,
        pieOffsetX = -75,
        pieOffsetY = 12,
        customScale = 1.22,
        legendHeight = 93,
        legendWidth = 122,
        colorsConfig = {},
        completed,
        wrapperClassName = "",
        donutSize = "87.5%",
        legendMarkerSize = 10,
        customLegendFormatter,
        showLegendTitle = false,
        replaceOnTooltip = ''
    } = props;

    const [series, setSeries] = useState(null);
    const [options, setOptions] = useState(null);
    const [labels, setLabels] = useState([]);
    const [colors, setColors] = useState(['#FFF']);
    const [riskStatements, setRiskStatements] = useState([]);
    const [allLow, setAllLow] = useState(false);

    const arrangeSeries = () => {
        if (!data) return [];
        const defaultColor = "#FFF";
        const emptyColor = "#382a71";
        const categories = [];
        const values = [];
        const dataPointColors = [];
        const dataPointStatements = [];
        let avoidedRisks = 0;
        let lowRisks = 0;
        data.forEach((element) => {
            // Don't include avoided risks
            if(element?.label === "AVOID" ) return;
            categories.push(element?.label?.toLowerCase());
            values.push(element?.value);
            const elementColor = total > 0 ? colorsConfig[element?.label] : emptyColor;
            dataPointColors.push(elementColor || defaultColor);
            dataPointStatements.push(element?.codes)
            if(element?.label === "LOW") {
                lowRisks = element?.value;
            }
        })
        if (total === 0) {
            categories.push('')
            values.push(100)
            dataPointColors.push(emptyColor)
        }
        if(completed && (avoidedRisks + lowRisks) === total) {
            setAllLow(true)
        }
        setLabels(categories);
        setSeries(values);
        setColors(!_.isNil(dataPointColors) ? dataPointColors : [defaultColor])
        setRiskStatements(dataPointStatements);
    }

    const renderAllLowCelebration = () => {
        if(!allLow) return null;
        return (
            <div className="celebration text-white">
                <img className="img-fluid" src={clapEmoji}/>
                Amazing!
            </div>
        )
    }

    const renderLegendTitle = () => {
        if(!showLegendTitle) return null;
        return (
            <div className="total-legend-title position-absolute">
                {`${abbreviateNumber(total)} ${totalLabelUnit}`}
            </div>
        )
    }

    const renderTotalLabel = () => {
        return (
            <div>
                { hasVcyso && total !== 0? (
                    <div className="risk-doughnut-label text-center">
                        <>
                            <div className="risk-doughnut-number">{abbreviateNumber(total)}</div>
                            {totalLabelUnit}
                            <p className="text-white mb-1">Risk Statements</p>
                            {renderAllLowCelebration()}
                        </>
                    </div>
                ) : (
                    <>
                        <div className="risk-doughnut-label text-center risk-doughnut-no-report-authorized" >
                            <p className="text-title mx-auto">{hasVcyso ? `You don't have any risks yet!`: 'Unlock Risk Manager'}</p>
                            <p className="description mx-auto">{hasVcyso ? '': 'Subcribe to CySO to get these features!'}</p>
                        </div>
                    </>
                )}
            </div>
        )
    }

    const formatCodeList = (codes) => {
        if(!codes) return "";
        let codeList = ""
        const lastIndex = codes.length - 1
        codes.forEach((code, index) => {
            codeList+=`${code.replace(replaceOnTooltip,"")}${index !== lastIndex ? ", " : ""}`
        })
        return codeList;
    }

    const renderStatementsTooltip = function (opts) {
        const { seriesIndex } = opts;
        const codes = riskStatements[seriesIndex];
        if(!codes) return null;
        return (`
        <div class="apexcharts-doughnut-tooltip apexcharts-basic-tooltip arrow-down">
            <div class="code-list d-flex flex-wrap justify-content-center">
                ${formatCodeList(orderAlphaNumeric(codes))}
            </div>
        </div>
      `);
    }

    const getClassName = () => {
        const freemiumClassName = `${total === 0 ? 'unsubscribed' : ''}`
        const zeroClassName = `${total === 0 ? 'total-zero-series' : ''}`
        return `${zeroClassName} ${freemiumClassName}`
    }

    const legendFormatter = function (seriesName, opts) {
        return `${abbreviateNumber(opts.w.globals.series[opts.seriesIndex])} ${seriesName}`
    }

    useEffect(() => {
        arrangeSeries(data);
    }, [data]);

    useEffect(() => {
        setOptions({
            colors: colors,
            labels: labels,
            dataLabels: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    offsetX: pieOffsetX,
                    offsetY: pieOffsetY,
                    donut: {
                        size: donutSize
                    },
                    customScale: customScale,
                    expandOnClick: false,
                }
            },
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: ['#1B0C56'],
                width: 1,
                dashArray: 0,
            },
            legend: {
                position: 'left',
                horizontalAlign: 'bottom',
                fontWeight: 600,
                fontSize: "13px",
                height: legendHeight,
                width: legendWidth,
                floating: true,
                markers: {
                    width: legendMarkerSize,
                    height: legendMarkerSize,
                    strokeWidth: 0,
                    strokeColor: '#fff',
                    radius: 0,
                },
                onItemHover: {
                    highlightDataSeries: false
                },
                formatter: customLegendFormatter || legendFormatter,
            },
            tooltip: {
                enabled: total !== 0,
                custom: renderStatementsTooltip
            },
        })

    }, [colors, labels, riskStatements, allLow])

    return (!series || !options) ? null : (
        <div className={`risk-doughnut text-doughnut-responsive ${getClassName()} ${wrapperClassName}`}>
            {renderTotalLabel()}
            {renderLegendTitle()}
            <ReactApexChart
                options={options}
                series={series}
                type="donut"
                height={height}
                width={width}
            />
        </div>
    )

}
export default BasicDoughnutChartRisk;
