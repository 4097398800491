import _ from 'lodash';
import { createSelector } from 'reselect';

const getOrderedCategories = state => _.sortBy(state.assessment.assessment.categories,'order');
const getAnswers = state => state.answers.questionAnswers;

const makeTotalAnswersByCategory = () => {
    return createSelector( [getOrderedCategories, getAnswers],
        (categories, answers) => {
            if(!categories || !answers) {
                return {
                    categories: null,
                    total: 0
                };
            }
            let total = 0;
            let cyberCheckupAnswers = 0;
            const totalAnswersByCategory = categories.map( category => {
                let categoryTotalAnswers = 0;
                category.sections.forEach( section => {
                    section.questions.forEach( (question) =>{
                        if(answers[question.id]){
                            categoryTotalAnswers++;
                            //Cyber checkup consists only of the first 5 sections of the Basic Category
                            if(category?.name === 'Basic' && (parseInt((section?.code)?.slice(1)) < 6)) {
                                cyberCheckupAnswers++;
                            }
                        } 
                    })
                });
                total += categoryTotalAnswers;
                return {
                    totalAnswers: categoryTotalAnswers,
                    name: category.name,
                    order: category.order,
                    id: category.id,
                };  
            });
                
            return {
                categories: totalAnswersByCategory,
                total,
                cyberCheckupCompleted: cyberCheckupAnswers === 10
            }
        }
    )
} 
export default makeTotalAnswersByCategory;
  

