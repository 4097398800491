//  Function to convert file to blob
export const fileToBlob = async (file = new File()) => {
  if (!file) { return null; }
  const arrayBuffer = await file.arrayBuffer();
  const uintArray = new Uint8Array(arrayBuffer);
  return new Blob(uintArray, { type: file?.type });
};

//  Function to convert file to base64
export const fileToBase64 = (file) => new Promise((resolve, reject) => {
  const fileReader = new FileReader();
  fileReader.readAsDataURL(file);
  fileReader.onload = () => resolve(fileReader.result);
  fileReader.onerror = (error) => reject(error);
});

export const validateFiles = (files = [], supportedMimeTypes = []) => {
  let areValidFiles = true;
  files.forEach((file) => {
    const { type } = file;
    if (!supportedMimeTypes.includes(type) && areValidFiles) {
      areValidFiles = false;
    }
  });
  return areValidFiles;
};