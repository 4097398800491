export const cyvatarTeamRoles = [
    {
        label: 'Cydekick',
        value: 'squad_cydekick'
    },{
        label: 'Sales',
        value: 'squad_sales'
    },
    {
        label: 'Admin',
        value: 'squad_admin'
    },
]
