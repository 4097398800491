import React from 'react';
import { getFormattedDate } from '../../utils/helpers';
import { getIssuesExistenceTime } from '../../utils/issuesHelpers';
import { issueTypes } from '../../constants/issues';

const IssueDetailsInformation = ({ issue }) => {
  const {
    createdAt,
    updatedAt,
    resolutionDate,
    issue_type,
    extra_fields: extraFields
  } = issue;

  // Render data item
  const renderItem = (label, value) => {
    return (
      <div className='mb-2'>
        <div className="issue-information-label">{label}</div>
        <div className='issue-information-value'>{value}</div>
      </div>
    )
  }

  const renderIssueInformation = () => {
    return (
      <>
        {renderItem('Publication date', createdAt ? getFormattedDate(createdAt, false) : '-')}
        {renderItem('Last modification', updatedAt ? getFormattedDate(updatedAt, false) : '-')}
        {renderItem('Existence time', getIssuesExistenceTime(new Date(createdAt), resolutionDate ? new Date(resolutionDate) : null))}
      </>
    );
  }

  const renderClientInformation = () => {
    const { entity } = extraFields;
    const {
      getTypicalUserAgent: agent,
      getTypicalCountry: country,
      getTypicalBrowser: browser,
      getTypicalOS: OS
    } = entity;

    return (
      <>
        {renderItem('Agent', agent.attributes?.map(e => e?.join(' ')).join(', ') || '-')}
        {renderItem('Location', country.attributes?.join(' ') || '-')}
        {renderItem('Browser', browser.attributes?.join(' ') || '-')}
        {renderItem('OS', OS.attributes?.join(' ') || '-')}
      </>
    );
  }

  const renderInformation = () => {
    if (issue_type === issueTypes.ALERT)
      return { title: 'Client information', body: renderClientInformation() };
    return { title: 'Issue information', body: renderIssueInformation() };
  }

  const information = renderInformation();

  return (
    <div className='issue-details-information'>
      <div className="issue-information-title">
        {information.title}
      </div>
      {information.body}
    </div>
  )
}

export default IssueDetailsInformation
