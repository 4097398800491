import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MetisMenu from "metismenujs";
import SidebarTab from './SidebarTab';
import UserInfo from './UserInfo';
import LogoutIcon from '../../../assets/images/icons/logout.svg';
import LogoutModal from '../LogoutModal';
import { cydekickOnlySideBarTabs, sidebarTabs, recycleBinTab } from '../../../constants/navigation';
import { onSidebarTabSelected } from '../../../actions/navigation';
import { trackEvent } from '../../../utils/gaTracker';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import companyInfoSelector from '../../../selectors/companyInfoSelector';
import companyIdSelector from '../../../selectors/companyIdSelector';
import {ENTER_KEY_NAME} from '../../../constants/keyNames';

const SidebarContent = (props) => {
  //  Parsing props
  const { type } = props;

  //  Memoized dispatch
  const { dispatch } = useMemoizedDispatch();

  //  Watching redux store
  const role = useSelector((state) => state.auth.user?.roles[0]?.name);
  const isSales = useSelector((state) => companyInfoSelector(state, 'isSales', false));
  const freemiumStatus = useSelector((state) => companyInfoSelector(state, 'freemium', false));
  const answers = useSelector((state) => state.answers);
  const assessment = useSelector((state) => state.assessment?.assessment);
  const companyId = useSelector((state) => companyIdSelector(state));
  const impersonatedCompanyId = useSelector((state) => state.impersonation.companyId);
  const isMemberView = useSelector(state => state.impersonation.isMemberView);

  // Use ComponentDidMount and ComponentDidUpdate method simultaneously
  const [isCyvatarMember, setIsCyvatarMember] = useState(false);
  const [showLogOutModal, setShowLogOutModal] = useState(false);

  //  Component did mount
  useEffect(() => {
    new MetisMenu("#side-menu");
    const activeImpersonation = !_.isNil(impersonatedCompanyId);
    //  Component unmount
    return () => { dispatch(onSidebarTabSelected('/', role, true, freemiumStatus, isSales, activeImpersonation)); }
  }, []);

  //  Watching role changes
  useEffect(() => {
    if (!role) { return; }
    setIsCyvatarMember(role.indexOf('squad_') !== -1);
  }, [role]);

  //  Function to log out
  const onLogoutClicked = () => {
    trackEvent('sidebar-tab-clicked', 'sidebar-menu', 'log-out');
    setShowLogOutModal(true);
  };

  //  Function to open/close modal
  const toggleModal = () => {
    setShowLogOutModal(!showLogOutModal);
  };

  const renderCydekickOnlyTabs = () => {
    const activeImpersonation = !_.isNil(impersonatedCompanyId);
    if(isMemberView && activeImpersonation) return;
    return cydekickOnlySideBarTabs?.map((tab, index) => {
      return (
        <SidebarTab key={`tab-cyde-${index}`} tab={tab} isCyvatarMember={isCyvatarMember} />
      );
    });
  }

  const renderGeneralTabs = () => {
    return sidebarTabs?.map((tab, index) => {
      return (
        <SidebarTab key={index} tab={tab} isCyvatarMember={isCyvatarMember} />
      );
    });
  };

  //  Function to render tabs
  const renderTabs = () => {
    return (
      <>
        {renderGeneralTabs()}
        {renderCydekickOnlyTabs()}
        {renderRecycleBinTab()}
      </>
    )
  };

  //  Function to render log out button
  const renderLogOutButton = () => {
    return (
      <div className="sidebar-tab-container logout-tab-container">
        <a
          className="waves-effect logout-tab sidebar-tab"
          tabIndex="0"
          aria-label="log out button"
          onClick={() => onLogoutClicked()}
          onKeyPress={(e) => e?.key === ENTER_KEY_NAME ? onLogoutClicked() : null }
        >
          <img alt="" src={LogoutIcon} />
          <span style={{ marginLeft: '12px' }}>Log out</span>
        </a>
      </div>
    );
  };

  //  Function to render recycle bin tab
  const renderRecycleBinTab = () => {
    return (
      <SidebarTab
        tab={recycleBinTab}
        isCyvatarMember={isCyvatarMember}
      />
    );
  };

  //  Rendering
  return (
    <>
      <React.Fragment>
        <div id="sidebar-menu" role="navigation" aria-label="main sidebar menu" tabIndex="0">
          <div className='metismenu-container'>
            <ul className={`metismenu list-unstyled`} id="side-menu">
              {renderTabs()}
            </ul>
          </div>
          <div className='fixed-buttons-container'>
            <UserInfo type={type} />
            {renderLogOutButton()}
          </div>
        </div>
      </React.Fragment>
      {showLogOutModal && (
        <LogoutModal
          toggleModal={toggleModal}
          answers={answers}
          assessment={assessment}
          companyId={companyId}
        />
      )}
    </>
  );
};

export default SidebarContent;
