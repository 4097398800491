import React from 'react'
import { getGeneralFormatDate } from '../../utils/dateFormatter';
import CyvatarBadge from './CyvatarBadge';
import signature from '../../assets/images/corey-signature.svg';

const CyvatarBadgeCertificate = (props) => {
    const {
        badge,
        companyName
    } = props;

    const renderCompanyInfo = () => {
        return (
            <>
                <div className="certification-membership text-center">Certification of Membership</div>
                <div className="certificate-text certified-by text-center">This certifies that:</div>
                <div className="company-name text-center">{companyName}</div>
                <div className="company-name-guide style-line w-100 text-center"></div>
                <div className="certificate-text secured-by text-center">is Secured by Cyvatar and a member of the Cyvatar platform.</div>
            </>
        )
    }

    const renderBadgeInfoItem = (label, value, className, index) => {
        return (
            <div
                key={`certificate-info-item-${index}`}
                className={`certificate-info-item d-inline-flex flex-column ${className || ''}`}
            >
                <div className="certificate-info-item-label">{label}</div>
                <div className="certificate-info-item-value">{value}</div>
            </div>
        )
    }

    const renderSignature = () => {
        return (
            <img
                src={signature}
                className="signature img-fluid"
            />
        )
    }

    const renderBadgeInformation = () => {
        const items = [
            { label: 'Date', value: getGeneralFormatDate(badge?.created_at)},
            { label: 'Signed by', value: renderSignature() }
        ]
        return (
            <>
                <div className="issue-number-container text-center">{`Issue number: `}{badge?.public_id}</div>
                <div className="d-flex flex-wrap flex-md-nowrap justify-content-around justify-content-md-between">
                    {items.map( (item, index) => {
                        return renderBadgeInfoItem(item.label, item.value, item.className, index)
                    })}
                </div>
            </>
        )
    }
    return (
        <div className="cyvatar-badge-certificate">
            <div className="overlay align-items-start">
                <div className="content-container mx-auto">
                    <div className="w-50 mx-auto">
                        <CyvatarBadge
                            image={badge?.image}
                            isUnlocked={true}
                        />
                    </div>
                    {renderCompanyInfo()}
                    {renderBadgeInformation()}
                </div>
            </div>
        </div>
    )
}

export default CyvatarBadgeCertificate;

