import { getAllowedSubtabs, getParentPath, getSelectedSubtabIndex, getSubtabIdentifierByPath, getSubtabsByPath } from '../utils/helpers';
import {
  ON_SIDEBAR_TAB_SELECTED,
  ON_SUBTAB_SELECTED,
  ON_TOPBAR_ACTION_TRIGGERED,
  ON_IMPERSONATION_SUCCESS,
  CHANGE_SUBTABS,
  SET_BACK_LINK_LABEL
} from '../actions/types';

const initialState = {
  subtabs: [],
  actions: [],
  selectedSubtab: {},
  action: { type: null, params: null, loading: false },
  filterAllowed: true,
  currentPath: '',
  backLink: {
    show: false,
    label: '',
    path: null,
    includeSearchParams: false
  }
};

const navigationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ON_SIDEBAR_TAB_SELECTED:
      //  Parsing and validating payload
      if (!payload) { return state; }
      const { path, userRole, filterAllowed = true, freemiumStatus, isSales, activeImpersonation } = payload;
      if (!path) { return state; }
      //  Cleaning state in case the user moves to a page without subtabs
      const subtabsAndActions = getSubtabsByPath(path) ;
      if (!subtabsAndActions) {
        return {
          ...state,
          subtabs: [],
          actions: [],
          selectedSubtab: {},
          filterAllowed: true,
          currentPath: path,
          backLink: {
            show: false,
            label: '',
            path: null,
            includeSearchParams: false
          },
          defaultImpersonation: false,
        };
      }
      //  Getting actions and subtabs for current tab
      const { subtabs, actions, showParentLink, parentLinkLabel, hasDynamicSubtabs, defaultImpersonation } = subtabsAndActions;
      if(!hasDynamicSubtabs) {
        //  Getting just allowed subtabs in case we need to hide some tabs
        let filteredSubtabs = [];
        if (!filterAllowed && !activeImpersonation) {
          // All subtabs are allowed
          filteredSubtabs = subtabs;
        } else {
          // Filter subtabs depending on filterAllowed and activeImpersonation flags
          filteredSubtabs = getAllowedSubtabs(subtabs, userRole, filterAllowed, activeImpersonation);
        }
        //  Reordering tabs if the account is freemium
        if (freemiumStatus !== 0 || (isSales && getParentPath(path) === '/dashboard')) { filteredSubtabs.sort(({ freemiumIndex: a }, { freemiumIndex: b }) => a - b); }

        // Get selected subtab by path
        const subtabIdentifier = getSubtabIdentifierByPath(path, freemiumStatus);

        const isNestedPathSelected = getParentPath(path) !== path

        const showBackLink = showParentLink && isNestedPathSelected;

        //  Setting selected subtab
        const selectedSubtab = filteredSubtabs.find((element) => element?.identifier === subtabIdentifier) || (filteredSubtabs[0] || {});
        return {
          ...state,
          subtabs: filteredSubtabs,
          actions,
          selectedSubtab,
          filterAllowed: !!filterAllowed,
          currentPath: path,
          backLink: {
            show: showBackLink,
            label: parentLinkLabel,
            path: null,
            includeSearchParams: false
          },
          defaultImpersonation,
        };
      }
      return {
        ...state,
          actions,
          filterAllowed: !!filterAllowed,
          currentPath: path,
          defaultImpersonation,
      }

    case ON_SUBTAB_SELECTED:
      if (!payload) { return state; }
      return {
        ...state,
        selectedSubtab: payload,
        currentPath: payload.tab
      };
    case ON_TOPBAR_ACTION_TRIGGERED:
      if (!payload) { return state; }
      const { actionType, params } = payload;
      return {
        ...state,
        action: {
          type: actionType,
          params,
          loading: !!actionType,
        },
      };
    case ON_IMPERSONATION_SUCCESS:
      if (!payload?.impersonatedCompany || !state.subtabs || state.subtabs.length === 0) { return state; }
      const currentPath = state.currentPath
      //  Getting current entry from subtabs map
      const mapEntry = getSubtabsByPath(currentPath);
      if (!mapEntry || mapEntry?.hasDynamicSubtabs) { return state; }
      //  Getting subtabs and changing order for freemium accounts
      const newSubtabs = [].concat(mapEntry.subtabs);
      const { freemium } = payload?.impersonatedCompany;
      if (freemium !== 0) { newSubtabs.sort(({ freemiumIndex: a }, { freemiumIndex: b }) => a - b); }
      // Get selected subtab index by path
      const selectedSubtabIdentifier = getSubtabIdentifierByPath(currentPath, freemium);
      //  Getting selected subtab
      const newSelectedSubtab = newSubtabs.find((element) => element?.identifier === selectedSubtabIdentifier) || (newSubtabs[0] || {});
      //  Modifying redux state
      return {
        ...state,
        subtabs: newSubtabs,
        selectedSubtab: newSelectedSubtab,
      };
    case CHANGE_SUBTABS: {
      return {
        ...state,
        subtabs: payload
      }
    }
    case SET_BACK_LINK_LABEL: {
      return {
        ...state,
        backLink: {
          show: payload?.show,
          label: payload?.label,
          path: payload?.path,
          includeSearchParams: payload?.searchParams
        }
      }
    }
    default:
      return state;
  }

};

export default navigationReducer
