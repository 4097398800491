import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Button } from 'reactstrap';
import BasicModal from '../../layout/BasicModal';
import API from '../../../api';
import useCustomMutation from '../../../hooks/useCustomMutation';
import { alertTypes } from '../../../constants/alerts';
import { setAlert } from '../../../actions/alerts';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import { getApiErrorMessage } from '../../../utils/helpers';
import { useSelector } from 'react-redux';
import companyIdSelector from '../../../selectors/companyIdSelector';


const RiskCreation = (props) => {

    //  Initializing API
    const { VcysoAPI } = API;
    const {
        showCreateModal,
        toggleModal,
    } = props;

    //  Component state
    const [progress, setProgress] = useState(0);
    const [description, setDescription] = useState("");

    const companyId = useSelector(state => companyIdSelector(state))

    // Components Hooks
    const { dispatch } = useMemoizedDispatch();
    const {
        handleSubmit,
    } = useForm({
        defaultValues: { description: '' }
    });

    const {
        data: createRiskResponse,
        error: createRiskError,
        isLoading: createRiskLoading,
        mutate: createRisk,
    } = useCustomMutation(
        (params) => VcysoAPI.handlers.createCompanyRisk(params),
        VcysoAPI.invalidators.createCompanyRisk
    );

    useEffect(() => {
        if(!createRiskResponse) return;
        dispatch(setAlert("Risk created", alertTypes.success))
        toggleModal();
    }, [createRiskResponse]);

    const onClose = ()=>{
        setDescription('');
        toggleModal();
    }

    useEffect(() => {
        if(!createRiskError) return;
        const errorMessage = getApiErrorMessage(createRiskError);
        dispatch(setAlert(errorMessage, alertTypes.error));
        setDescription('');
    }, [createRiskError]);

    function handleValidSubmit() {
        if (description === '') return;
        const payload = {
            label: description,
            company_id: companyId
        }
        createRisk(payload);
    }

    const onSubmitForm = handleSubmit((data, e) => {
        handleValidSubmit();
    });

    const onTextboxChange = (event) => {
        setDescription(event?.target?.value)
        setProgress(event?.target?.value.length);
    };

    const renderForm = () => {
        return (
            <form
                onSubmit={onSubmitForm}
                onReset={onClose}
            >
                <textarea
                    name="description"
                    id="risk-control-description"
                    rows={5}
                    className={`risk-description-textarea form-control dark text-area-grow`}
                    value={description}
                    maxlength={135}
                    onChange={onTextboxChange}
                />
                <div className="modal-instructions float-right mt-0 mb-4">
                    {`${progress}/135 characters`}
                </div>
                <div className='d-inline-block w-100 mt-2'>
                    <Button
                        className="btn-light btn-large float-right ml-2"
                        type="submit"
                        disabled={createRiskLoading || progress===0}
                    >
                        Create
                    </Button>
                    <Button
                        className="btn-large btn btn-outline-light-gray float-right"
                        type="reset"
                        outline
                        disabled={createRiskLoading}
                    >
                        Cancel
                    </Button>
                </div>
            </form>
        )
    }

    const renderCreateModal = () => {
        if(!showCreateModal) return null;
        return (
            <BasicModal
                header="New risk name"
                headerClassName={`risk-description-modal-header`}
                showModal={showCreateModal}
                toggleModal={onClose}
                customClassName="risk-control-description-modal"
                scrollable={true}
            >
                <div className="modal-instructions">
                    A name is required to create this risk
                </div>
                {renderForm()}
            </BasicModal>
        )
    }

    return (
        renderCreateModal()
    )
}

export default RiskCreation;
