import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PartnerActionDropdown from "./PartnerActionDropdown";
import SimpleDropdown from "../layout/SimpleDropdown";
import CustomCheckBox from "../layout/CustomCheckBox";
import { setPartnerActionValue } from "../../actions/partners";
import useMemoizedDispatch from "../../hooks/useMemoizedDispatch";
import {
  checkRequiredKeys,
  fieldTypes,
} from "../../utils/partnerActionHelpers";
import TextArea from "../profile/TextArea";
import CustomToggleSwitch from "../layout/CustomToggleSwitch";
import CustomTimepicker from "../../components/calendar/CustomTimepicker";
import PartnerActionTable from "./PartnerActionTable";
import useValueDebounce from "../../hooks/useValueDebounce";

const PartnerActionField = (props) => {
  // Props
  const { field } = props;
  const {
    type,
    required,
    display_name,
    placeholder,
    key,
    required_keys,
    default: defaultValue,
  } = field;

   // Redux
  const partnerActionValues = useSelector(
    (state) => state.partners.partnerActionValues
  );

  // State
  const [textValue, setTextValue] = useState(partnerActionValues?.[key] || defaultValue);

  const { debouncedValue } = useValueDebounce({
    valueToDebounce: textValue,
    defaultValue,
    debounceTime: 500,
  }); 

  const { dispatch } = useMemoizedDispatch();

  const saveActionValue = (key, value) => {
    dispatch(setPartnerActionValue(key, value));
  };

  // Called on mount
  useEffect(() => {
    if (defaultValue) {
      if (!partnerActionValues.hasOwnProperty(key)) {
        saveActionValue(key, defaultValue);
      }
    }
  }, []);
  // TODO: Talk this with Manuel

  useEffect(() => {
    // if (!debouncedValue) return;
    saveActionValue(field.key, debouncedValue);
  }, [debouncedValue])
  

  // Render field simple titles
  const renderFieldTitle = () => {
    return (
      <div className="field-title">{`${display_name} ${
        required ? "*" : ""
      }`}</div>
    );
  };

  // Function to handle textfield change
  const onTextfieldChange = (event) => {
    setTextValue(event?.target?.value);
    // saveActionValue(field.key, event?.target?.value);
  };

  // Render simple textfields
  const renderTextfield = () => {
    return (
      <div className="field textfield">
        {renderFieldTitle()}
        <input
          value={textValue}
          className="textfield-input"
          type={type == fieldTypes.NUMBERFIELD ? "number" : "text"}
          placeholder={placeholder}
          onChange={onTextfieldChange}
        ></input>
      </div>
    );
  };

  //  Function to handle dropdown change
  const onDropdownChange = (option) => {
    if (!option) {
      return;
    }
    saveActionValue(field.key, option);
  };

  // Render dropdowns with options and with API request
  const renderDropdown = () => {
    const { options, source_url } = field;
    if (!options && !source_url) {
      return null;
    } // Dropdown with incomplete fields

    // Check if has source_url
    // Render PartnerActionDropdown that handles API calls
    if (source_url) {
      return (
        <PartnerActionDropdown
          extraClassName=""
          field={field}
          fieldTitle={renderFieldTitle}
          placeholder={placeholder}
          onDropdownChange={onDropdownChange}
          selectedElement={partnerActionValues?.[field.key]}
        />
      );
    }
    // If not, render simple dropdown if options present
    if (!options) {
      return null;
    }

    const elements = options.map((option) => {
      return { value: option.key, label: option.display_name };
    });

    return (
      <div className="field dropdown">
        {renderFieldTitle()}
        <SimpleDropdown
          extraClassName="partner-field-dropdown"
          placeholder={placeholder}
          elements={elements}
          onChangeCallback={onDropdownChange}
          selectedElement={partnerActionValues?.[field.key]}
          customMenuPosition='absolute'
        />
      </div>
    );
  };

  const onCheckboxChange = (checkboxKey, value) => {
    const actionValues = partnerActionValues[checkboxKey];
    let newValues;
    if (!actionValues) {
      newValues = [value];
    } else {
      if (Array.isArray(partnerActionValues[checkboxKey])) {
        newValues = [...partnerActionValues[checkboxKey]];
        if (newValues.includes(value)) {
          const index = newValues.indexOf(value);
          if (index >= 0) {
            newValues.splice(index, 1);
          }
        } else {
          newValues.push(value);
        }
      }
    }
    saveActionValue(checkboxKey, newValues);
  };

  const isValueSelectedOnCheckbox = (checkboxKey, value) => {
    if (!partnerActionValues[checkboxKey]) return false;
    const valuesArray = partnerActionValues[checkboxKey];
    return valuesArray.includes(value);
  }

  const onRadioChange = (checkboxKey, value) => {
    saveActionValue(checkboxKey, value);
  }

  const isSelectedValueForCheckboxAndRadio = (key, value) => {
    if (type == fieldTypes.RADIO) {
      const currentValue = partnerActionValues[key];
      return currentValue == value;
    }
    return isValueSelectedOnCheckbox(key, value);
  }

  const renderCheckbox = () => {
    const { options } = field;
    if (!options) {
      return null;
    }

    return (
      <div className="field checkbox">
        {renderFieldTitle()}
        <div className="partner-action-checkbox-container">
          {options.map((option, index) => {
            const { key, display_name } = option;
            return (
              <CustomCheckBox
                className="checkbox-option"
                label={display_name}
                isSelected={isSelectedValueForCheckboxAndRadio(field.key, key)}
                targetId={`partner-option-checkbox-${index}`}
                callback={() => {
                  switch (type) {
                    case fieldTypes.CHECKBOX: {
                      onCheckboxChange(field.key, key);
                      break;
                    }
                    case fieldTypes.RADIO: {
                      onRadioChange(field.key, key);
                      break;
                    }
                    default:
                      break;
                  }
                }}
              />
            );
          })}
        </div>
      </div>
    );
  };

  // Function to handle textfield change
  const onTextboxChange = (event) => {
    setTextValue(event?.target?.value);
  };

  const renderTextbox = () => {
    return (
      <div className="field textbox">
        {renderFieldTitle()}
        <textarea
          className="form-control partner-action-textbox"
          type="text"
          value={textValue}
          placeholder={placeholder}
          onChange={onTextboxChange}
          rows={3}
        />
      </div>
    );
  };

  const onSwitchChange = (checked) => {
    saveActionValue(field.key, checked);
  }

  const renderSwitch = () => {
    //  Rendering toggle switch
    return (
      <div className="field partner-switch">
        <div className="partner-switch-title">{`${display_name} ${
          required ? "*" : ""
        }`}</div>
        <CustomToggleSwitch
          containerClassName="switch-container"
          spanClassName="partner-action-slider"
          checkedLabelClassName="checked-toggle-cell"
          unCheckedLabelClassName="unchecked-toggle-cell"
          includeLabel={true}
          checked={partnerActionValues?.[field.key] || false}
          setChecked={onSwitchChange}
        />
      </div>
    );
  }

  const onTimepickerChange = (value) => {
    saveActionValue(field.key, value);
  }

  const renderTimePicker = () => {
    return (
      <div className="field partner-time-picker">
        <CustomTimepicker
          value={partnerActionValues?.[field.key]}
          setValue={onTimepickerChange}
        />
      </div>
    );
  }

  const renderTable = () => {
    return (
        <PartnerActionTable field={field}></PartnerActionTable>
    )
  }

  // Field rendering depeding on type
  const renderField = () => {
    if (!checkRequiredKeys(field, partnerActionValues)) {
      return null;
    }
    switch (type) {
      case fieldTypes.TEXTFIELD:
      case fieldTypes.NUMBERFIELD:
        return renderTextfield();

      case fieldTypes.DROPDOWN:
        return renderDropdown();

      case fieldTypes.RADIO:
      case fieldTypes.CHECKBOX:
        return renderCheckbox();

      case fieldTypes.TEXTBOX:
        return renderTextbox();

      case fieldTypes.SWITCH:
        return renderSwitch();

      case fieldTypes.TIME_PICKER:
        return renderTimePicker();

      case fieldTypes.TABLE:
      case fieldTypes.MULTI_SELECT_LIST:
        return renderTable();

      default:
        return null;
    }
  };

  return <React.Fragment>{renderField()}</React.Fragment>;
};

export default PartnerActionField;
