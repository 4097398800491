import React from 'react';
import { mapScheduleToString } from '../../utils/automationHelper';

const AutomationScheduleCell = (props) => {
  //  Parsing props
  const { rule } = props;
  const { schedule, repeat } = rule;

  //  Rendering
  return (
    <div className="selectable-text-cell">
      <div className="text-value">{mapScheduleToString(schedule, repeat)}</div>
    </div>
  );
};

export default AutomationScheduleCell;
