
import React, { useState, useEffect } from "react";
import { Col, Card, Button } from "reactstrap";
import { getSafeArray } from "../../utils/helpers";

const ActionsSection = (props) => {
  // Obtaining props
  const { actions, partnerId, onClickHandler } = props;

  // Render actions map
  const renderActions = () => {
    if (!actions) { return null; }
    const safeActions = getSafeArray(actions);
    return safeActions.map((action, index) => {
      return (
        <Button
          key={`button-option-${index}`}
          className="btn-partner-actions mr-2"
          onClick={() => {
            onClickHandler(true, action);
          }}
        >
          {action?.display_name}
        </Button>
      );
    });
  };

  const renderBody = () => {
    if (!actions) { return null; }
    return (
      <Col
        lg={12}
        className="action-section align-self-start"
        style={{ marginBottom: "38px" }}
      >
        <Card className="h-100">
          <Col lg={12}>
            <div className="actions-section-title">Actions Section</div>
            <div className="actions-section-content">{renderActions()}</div>
          </Col>
        </Card>
      </Col>
    );
  }

  // Rendering
  return (
    <div className="w-100">
      {renderBody()}
    </div>
  );
}

export default ActionsSection;
