import _ from 'lodash';
import React from 'react';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import defaultIconImg from '../../assets/images/icons/default-partner-icon.svg'
import { formatPartnerName } from '../../utils/partnerHelpers';
import PartnerIconsListTooltip from './PartnerIconsListTooltip';

const PartnersIconList = (props) => {
    const {
        partners,
        wrapperClassName = '',
        id = "partner-icon-list-id",
        previewLength,
        displayName = false
    } = props;

    const addDefaultSrc = (ev) => {
        ev.target.src = defaultIconImg
    }

    const renderPartnerNameTooltip = (name, targetId) => {
        if(displayName) return null;
        return (
            <UncontrolledTooltip
                className={`partners-icons-list-tooltip partner-name-tooltip`}
                placement={'bottom'}
                target={targetId}
                arrowClassName={`partners-icons-list-tooltip-arrow`}
            >
                {name}
            </UncontrolledTooltip>
        )
    }

    const renderPartners = (list) => {
        if(!list || !Array.isArray(list)) return null;
        return _.orderBy(list, 'name').map((partner, index) => {
            const { name } = partner;
            const targetId = `partner-icon-${index}-${partner?.id}`
            const iconSrc = `${process.env.REACT_APP_PUBLIC_RESOURCES}/partners/${name?.replace(/tenablefreemium/i, 'tenableapi')?.replace(" ","-")?.toLowerCase()}.svg`;
            const label = formatPartnerName(name);

            return (
                <div
                    key={`partner-icon-container-${id}-${index}`}
                >
                    <div

                        className="partner-icon-container"
                        id={targetId}
                    >
                        <div className="d-flex justify-content-center align-items-center">
                            <img
                                className="partner-icon-container-img img-fluid"
                                src={iconSrc}
                                alt={label}
                                onError={addDefaultSrc}
                            />
                            {displayName && <div className="d-inline-block ml-2">{label}</div> }
                        </div>

                    </div>
                    {renderPartnerNameTooltip(label, targetId)}
                </div>
            )
        })
    }
    const renderTooltip = (partnersList) => {
        return (
            <PartnerIconsListTooltip
                targetId={id}
                partners={partnersList}
            />
        )
    }

    const renderRemainingCount = (remainingPartners) => {
        if(!remainingPartners || remainingPartners?.length < 1) { return null; }
        return (
            <>
                <div
                    id={id}
                    className="partners-remaining-count"
                >
                    {`(${remainingPartners?.length})`}
                </div>
                {renderTooltip(remainingPartners)}
            </>
        )
    }

    const renderContent = () => {
        if(!partners || !Array.isArray(partners)) return null;
        const maxlength = previewLength || partners?.length;
        const previewList = partners.slice(0, maxlength);
        const tooltipList = partners.slice(maxlength, partners?.length);
        return (
            <>
                {renderPartners(previewList)}
                {renderRemainingCount(tooltipList)}
            </>
        )
    }

    return (
        <div
            key={`partners-icon-wrapper-${id}`}
            className={`partners-icon-list ${wrapperClassName}`}
        >
            {renderContent()}
        </div>
    )
}

export default PartnersIconList;
