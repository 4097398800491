import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useCanAbility from './useCanAbility';
import { PRIVILEGE_SOURCES, READ_ACTION } from '../constants/privileges';
import companyInfoSelector from '../selectors/companyInfoSelector';
import companyIdSelector from '../selectors/companyIdSelector';

const useCyvatarProValidation = () => {
  // Getting needed info from the store
  const freemiumFlag = useSelector(state => companyInfoSelector(state, 'freemium'));
  const impersonatedCompanyId = useSelector((state) => companyIdSelector(state));

  // Hooks
  const ability = useCanAbility();

  //  Hook state
  const [canViewCyvatarPro, setCanViewCyvatarPro] = useState(null); // Initialized as null to validate refresh edge cases on tabs

  //  Watching freemium flag changes
  useEffect(() => {
    setCanViewCyvatarPro(ability.can(READ_ACTION, PRIVILEGE_SOURCES.HOME.GENERAL) && freemiumFlag === 0)
  }, [freemiumFlag]);

  //  Watching impersonated company changes
  useEffect(() => {
    if (impersonatedCompanyId) { return; }
    setCanViewCyvatarPro(false);
  }, [impersonatedCompanyId]);

  //  Exporting assets
  return { canViewCyvatarPro };
};

export default useCyvatarProValidation;
