import React from "react";
import { Card, CardBody } from "reactstrap";
import CircleProgressBar from "./CircleProgressBar";
import InfoTooltip from "../tooltips/InfoTooltip";
import RiskReconLogo from "../../../assets/images/risk-recon-logo.png";

const RiskScore = ({ score, grade, lastUpdate }) => {
  const renderLastUpdated = () => {
    return <p className="risk-recon-score">{`Last updated: ${lastUpdate}`}</p>;
  };

  return (
    <Card className="risk-recon-score-card h-100">
      <CardBody>
        <InfoTooltip
          label={"RiskRecon Score"}
          wrapperClassName="dashboard-widget-title"
          showTooltip={false}
        />
        <div className="score-widget-container">
          <CircleProgressBar score={score} maxScore={10} grade={grade} radius={18} />
          {renderLastUpdated()}
          <img className="risk-recon-logo" src={RiskReconLogo} alt="risk-recon" />
        </div>
      </CardBody>
    </Card>
  );
}

export default RiskScore;
