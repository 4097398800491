import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import SaveDraftModal from '../SaveDraftModal';
import { buildRuleSetGeneralPayload } from '../../../utils/automationHelper';
import { validateRuleSetDraft, validateRuleSetToPublish } from '../../../utils/joiValidations';
import API from '../../../api';
import { setAlert } from '../../../actions/alerts';
import { onSaveRuleSetSuccess } from '../../../actions/ruleSet';
import { requestDeleteRules } from '../../../actions/automation';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import useCustomMutation from '../../../hooks/useCustomMutation';
import { MODIFY_ACTION, PRIVILEGE_SOURCES } from '../../../constants/privileges';
import useCanAbility from '../../../hooks/useCanAbility';

const RuleSetDetailTopBar = (props) => {
  //  Parsing props
  const {
    ruleSetId,
    ruleSetName = '',
    setRuleSetName,
  } = props;

  //  Initializing APIs
  const { RuleAPI } = API;

  //  Component state
  const [readyToPublish, setReadyToPublish] = useState(false);
  const [showSaveDraftModal, setShowSaveDraftModal] = useState(false);

  //  Watching needed info from redux store
  const tags = useSelector((state) => state.ruleSet.tags);
  const companies = useSelector((state) => state.ruleSet.companies);
  const rules = useSelector((state) => state.ruleSet.rules);
  const all_members = useSelector((state) => state.ruleSet.all_members);
  const is_published = useSelector((state) => state.ruleSet.is_published);

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const history = useHistory();
  const ability = useCanAbility();

  //  Calling API to save rule set
  const {
    data: saveResponse,
    error: saveError,
    isLoading: saveLoading,
    mutate: saveRuleSet
  } = useCustomMutation(
    (params) => RuleAPI.handlers.saveRuleSet(params),
    RuleAPI.invalidators.saveRuleSet,
  );

  // Component Variables
  const canModifyRule = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.AUTOMATION.INTEGRATION_RULE);

  //  Watching all payload changes to manage ready to publish flag
  useEffect(() => {
    const testPayload = buildRuleSetGeneralPayload(ruleSetId, ruleSetName, tags, companies, rules, all_members, true, false);
    const { error } = validateRuleSetToPublish(testPayload);
    setReadyToPublish(!error);
  }, [ruleSetName, tags, companies, rules, all_members]);

  //  Watching api response
  useEffect(() => {
    if (!saveResponse) { return; }
    dispatch(setAlert('Rule set saved successfully. ', 'success'));
    dispatch(onSaveRuleSetSuccess());
    history.push('/automation')
  }, [saveResponse]);

  //  Watching error response
  useEffect(() => {
    if (!saveError) { return; }
    dispatch(setAlert('Error saving rule set. ', 'danger'));
  }, [saveError]);

  //  Function to handle text change
  const onNameChange = ({ target }) => {
    setRuleSetName(target?.value);
  };

  //  Function to handle rule set delete
  const onRuleSetDelete = () => {
    if (!ruleSetId) { return; }
    const payload = { selected: [ruleSetId], excluded: [], allSelected: false };
    dispatch(requestDeleteRules(payload));
  };

  //  Function to handle exit
  const handleExit = () => {
    setShowSaveDraftModal(true);
  };

  //  Function to exit details
  const exitDetails = () => {
    history.push("/automation")
  };

  //  Function to handle save action
  //  TODO: handle error messages
  const onSave = (publishing = false) => {
    if ((publishing && !readyToPublish) || saveLoading) {
      dispatch(setAlert('Not ready to publish', 'danger'));
      return
    }
    // Validating payload
    const payload = buildRuleSetGeneralPayload(ruleSetId, ruleSetName, tags, companies, rules, all_members, publishing, true);
    const { error } = publishing ? validateRuleSetToPublish(payload) : validateRuleSetDraft(payload);
    if (error) { dispatch(setAlert(error.message || 'Validation error', 'danger')); return; }
    //  Saving rule set
    saveRuleSet(payload);
  };

  //  Function to render delete icon
  const renderDeleteIcon = () => {
    if (!ruleSetId) { return null; }
    return (
      <div
        className="bx bxs-trash topbar-icon delete-icon"
        onClick={() => onRuleSetDelete()}
      />
    )
  };

  //  Function to get publish button class name
  const getPublishClassName = () => {
    let className = 'publish-button';
    if (!readyToPublish) { className += ' disabled'; }
    return className;
  };

  //  Function to render button
  const renderButton = (label, className, disabled = false, callback) => {
    return (
      <Button
        className={className}
        disabled={disabled}
        onClick={callback}
      >
        {label}
      </Button>
    );
  };

  //  Function to render on exit modal
  const renderOnExitModal = () => {
    if (!showSaveDraftModal) { return null; }
    if(!canModifyRule) {
      exitDetails();
      return null;
    }
    return (
      <SaveDraftModal
        readyToPublish={readyToPublish}
        showDraft={!ruleSetId || !is_published}
        saveDraftCallback={onSave}
        exitCallback={() => exitDetails()}
        toggleModal={() => setShowSaveDraftModal(!showSaveDraftModal)}
      />
    );
  }

  //  Rendering
  return (
    <>
      <div className="rule-set-detail-topbar">
        <input
          className="name-field"
          type="text"
          value={ruleSetName}
          onChange={onNameChange}
          placeholder="Name New Rule"
        />
        <div className="topbar-actions">
          {renderDeleteIcon()}
          {(!ruleSetId || !is_published) && renderButton('Save Draft', 'draft-button', !canModifyRule, () => onSave(false))}
          {renderButton('Save and Publish', getPublishClassName(), !readyToPublish || !canModifyRule, () => onSave(true))}
          <div
            className="bx bx-x exit-icon"
            onClick={() => handleExit()}
          />
        </div>
      </div>
      {renderOnExitModal()}
    </>
  );
};

export default RuleSetDetailTopBar;
