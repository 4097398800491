import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Card, CardBody, Button } from "reactstrap";
import scanIcon from "../../../../assets/images/icons/scan-icon.svg";
import { scanStatusMap } from "../../../../constants/common";
import {
  CREATE_ACTION,
  PRIVILEGE_SOURCES,
} from "../../../../constants/privileges";
import useCanAbility from "../../../../hooks/useCanAbility";
import useRequestScan from "../../../../hooks/useRequestScan";
import useScanValidation from "../../../../hooks/useScanValidation";
import companyIdSelector from "../../../../selectors/companyIdSelector";
import companyInfoSelector from "../../../../selectors/companyInfoSelector";
import { getGeneralFormatDate } from "../../../../utils/dateFormatter";
import { isValidFunction } from "../../../../utils/helpers";
import LoadingContent from "../../../layout/LoadingContent";
import AppLauncherWidget from "../../common/dashboard/AppLauncherWidget";

const FreeScanRequest = (props) => {
  const { isAppLauncherWidget, toggleModal } = props;

  // Getting needed info from redux store
  const isFreemium = useSelector((state) => companyInfoSelector(state, "freemium", false) !== 0);
  const lastScanDate = useSelector((state) => companyInfoSelector(state, "lastScan", false));
  const companyId = useSelector((state) => companyIdSelector(state));

  const amountOfDomains = useSelector((state) => companyInfoSelector(state, "domainsCount", false));
  const hasMultipleDomains = amountOfDomains > 1 ? true : false;

  //  Component hooks
  const { scanStatus, isAuthorized } = useScanValidation();
  const { requestScan, scanLoading, scanError, scanData } = useRequestScan();
  const ability = useCanAbility();
  const history = useHistory();

  useEffect(() => {
    if ((scanError === null || scanError === undefined) && (scanData === null || scanData === undefined)) return;
    if (scanError !== null && scanError !== undefined) return;
    toggleModal(true);
  },[scanData,scanError]);

  // Component variables
  const canRequestScan = ability.can(
    CREATE_ACTION,
    PRIVILEGE_SOURCES.MULTIPLE.REQUEST_SCAN
  );


  const getLastScanMessage = (lastScanDate) => {
    const message =  `Your monthly scan ran on ${getGeneralFormatDate(lastScanDate, true)?.replace(',','')}`
    return lastScanDate && !hasMultipleDomains ? message : hasMultipleDomains ? 'Check the status of your scans on the domain manager.' : "Your scan is complete";
  };

  // Get message depending scan data
  const getMessage = () => {
    if (scanLoading) {
      return "Your scan is loading...";
    }
    switch (scanStatus) {
      case scanStatusMap.PREMIUM:
        return "As a premium member you have recurrent scans";
      case scanStatusMap.NOT_REQUESTED_SCAN:
        return isAppLauncherWidget && hasMultipleDomains
          ? "You have a monthly free scan"
          : hasMultipleDomains
          ? "You have a monthly scan"
          : "You have a free monthly scan, schedule it anytime.";
      case scanStatusMap.FIRST_SCAN_IN_PROGRESS:
        return "Your monthly scan has been requested, once finished you can check your issues in this widget.";
      case scanStatusMap.AUTHORIZED_WITH_NO_SCAN_IN_PROGRESS:
        return getLastScanMessage(lastScanDate);
      case scanStatusMap.AUTHORIZED_WITH_SCAN_IN_PROGRESS:
        return getLastScanMessage(lastScanDate);
      default:
        return "";
    }
  };

  // Get applauncher data
  const getAppLauncherWidgetData = () => {
    return {
      title: getTitle(),
      message: getMessage(),
    };
  };

  // On request scan call
  const onRequestScanClick = () => {
    //requestScan(companyId);
    toggleModal(true);
  };

  const viewDomains = () => {
    history.push("/profile/company/domains");
  };

  const viewDetailsButton = () => {
    return (
      <Button
          color="link"
          className='text-white font-weight-bold px-2'
          onClick={() => {
            viewDomains()
          }}
        >
          {'View Details'}
        </Button>
    );
  };

  const getTitle = () => {
    return "Request free vulnerability scan";
  };

  const renderTitle = () => {
    const title = "Request free vulnerability scan";
    return isAppLauncherWidget ? (
      <div className="row applauncher-widget-header">
        <div className="col-lg-12 col-sm-12">
          <span className="title d-inline-flex align-items-center">
            {title}
          </span>
        </div>
      </div>
    ) : (
      <span className="title d-inline-flex align-items-center">{title}</span>
    );
  };
  // Render action button
  const renderButton = () => {
    const scanNotRequested = scanStatus === scanStatusMap.NOT_REQUESTED_SCAN;
    const buttonLabel = isAuthorized ? "Check my issues" : hasMultipleDomains ? "View details" : scanNotRequested ? "Schedule" : "Scan requested";
    const callback = hasMultipleDomains ? viewDomains : scanNotRequested ? onRequestScanClick : null;
    const linkPath = isAuthorized ? "/dashboard/issues" : "";
    const buttonClassName = isAppLauncherWidget ? "applauncher-btn" : "additional-btn mb-2";

    return (
      <div className={!hasMultipleDomains ? 'free-scan-domain-btns' : 'free-scan-domain-btn'}>
        <Button
          type="button"
          color="light"
          className={`float-right btn-gray-disabled ${buttonClassName}`}
          disabled={!canRequestScan || (!isAuthorized && !scanNotRequested)}
          onClick={() => {
            if (isValidFunction(callback)) callback();
            if (linkPath) history.push(linkPath);
          }}
        >
          {buttonLabel}
        </Button>
        {!hasMultipleDomains ? viewDetailsButton() : null}
      </div>
    );
  };

  // Render additional widget card
  const renderAdditionalWidgetCard = () => {
    return (
      <div className="additional-widget-card-container additional-widget-free-scan">
        <div className="additional-widget-container">
          <Col
            sm={12}
            lg={12}
            className="align-self-start align-self-lg-stretch mb-2"
          >
            <Card className="h-100 mb-3 mb-md-0">
              <CardBody className="d-flex flex-column">
                {renderTitle()}
                <LoadingContent
                  isLoading={scanLoading}
                  iconType="solidIcon"
                  errorStyle={{ minHeight: "50px", paddingTop: "0px" }}
                  loadingStyle={{ minHeight: "50px", paddingTop: "0px" }}
                >
                  <div className="additional-widget-content-container">
                    <div className="additional-widget-icon col-sm-5 col-lg-5">
                      <img src={scanIcon} alt="Scan icon" />
                    </div>
                    <div className="additional-widget-content">
                      <span className="additional-widget-message">
                        {getMessage()}
                      </span>
                      {renderButton()}
                    </div>
                  </div>
                </LoadingContent>
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    );
  };

  if (!isFreemium) return null;

  return isAppLauncherWidget ? (
    <AppLauncherWidget
      widgetData={getAppLauncherWidgetData()}
      icon={scanIcon}
      comingSoon={null}
      buttonComponent={renderButton()}
      loading={scanLoading}
      wrapperClassName="applauncher-free-scan"
    />
  ) : (
    renderAdditionalWidgetCard()
  );
};

export default FreeScanRequest;
