import React from 'react';

export const CustomOption = (props) => {
  const {
    revertToDefaultCallback,
    hidden,
    hideCallback
  } = props;
    return (
        <div className="text-editor-toolbar-buttons-revert rdw-inline-wrapper group-toolbar">
            <div 
              className='button-revert-icon rdw-option-wrapper dark-option'
              onClick={revertToDefaultCallback}
            >
                <i className="bx bx-reset text-light-gray" />
                <div className=''>&nbsp;Revert to default</div>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div 
              className='button-revert-icon  custom-control custom-checkbox rdw-option-wrapper dark-option'
              onClick={hideCallback}
            >
                <input
                    type="checkbox"
                    id="hide-content-option"
                    className="custom-control-input form-check-input"
                    checked={hidden}
                />
                <div className='custom-control-label' htmlFor="hide-content-option"> &nbsp;&nbsp;Don’t show on PDF export</div>
            </div>
        </div>
    );
};


export const CustomOption2 = (props) => {
  const {
    saveCallback,
    cancelCallback,
    processing
  } = props;

  const onSaveClick = () => {
    saveCallback();
  };

  const onCancelClick = () => {
    cancelCallback();
  }
    return (
      <div className="text-editor-toolbar-buttons ">
        <div className='d-flex' >
          <button
            className="btn btn-outline-light-gray float-right mr-3 py-2 px-4"
            onClick={onCancelClick}
            disabled={processing}
          >
            {'Cancel'}
          </button>
          <button
            className={`btn btn-light float-right text-dark py-2 px-3 d-flex `}
            type="submit"
            onClick={onSaveClick}
            disabled={processing}
          >
            <i className='bx bx-save mr-2 my-auto'></i>
            {!processing? 'Save' : 'Processing..'}
          </button>
        </div>
      </div>
    );
  };

