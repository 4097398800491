import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAnchorForPDF } from '../utils/helpers';
import { triggerTopbarAction } from '../actions/navigation';
import useMemoizedDispatch from './useMemoizedDispatch';
import useCustomQuery from './useCustomQuery';

const usePDFGenerator = (props) => {
  //  Parsing props
  const {
    apiFunction,
    apiKey,
    actionType,
    queryParam,
  } = props;

  //  Watching action triggered from topbar
  const type = useSelector((state) => state.navigation.action.type);
  const params = useSelector((state) => state.navigation.action.params);

  //  Hook for memoized dispatch
  const { dispatch } = useMemoizedDispatch();

  //  Calling API for generating PDF
  const {
    data: response,
    error,
    isLoading: loading,
  } = useCustomQuery(
    [apiKey, params || queryParam],
    ({ queryKey }) => apiFunction(queryKey[1]),
    { enabled: !!(type && type === actionType && (queryParam || params)) }
  );

  //  Watching response changes
  useEffect(() => {
    if (!response) { return; }
    //  Creating blob for downloading PDF
    const file = new Blob([response], { type: 'application/pdf' });
    const url = URL.createObjectURL(file);
    const anchor = getAnchorForPDF(url, type, params);
    //  Downloading file
    anchor.dispatchEvent(new MouseEvent('click'));
    dispatch(triggerTopbarAction(null, null));
  }, [response]);

  return { response, error, loading };
};

export default usePDFGenerator;
