import React from 'react'
import { Button, UncontrolledTooltip } from 'reactstrap';
import { setAlert } from '../../actions/alerts';
import { alertTypes } from '../../constants/alerts';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';

const IssueCodeCopy = (props) => {
  const {
    issue = null,
    code,
    className,
    showCode = true,
    itemLabel = "Issue ID",
    showTooltip = false,
  } = props;

  // Component Hooks
  const { dispatch } = useMemoizedDispatch();

  // Handle click on copy icon
  const onCopyClick = (e) => {
    e.stopPropagation()
    navigator.clipboard.writeText(code);
    const message = `${itemLabel} copied to clipboard`;
    dispatch(setAlert(message, alertTypes.success))
  }

  // Render copy code icon
  const renderCopyIcon = () => {
    return (
      <Button
        color="link"
        className="btn-issue-copy-code"
        onClick={onCopyClick}
      >
        <i className='bx bx-copy'></i>
      </Button>
    )
  }

  // Render code tooltip
  const renderTooltip = () => {
    if(!showTooltip) return null;
    return (
      <UncontrolledTooltip
        className={`custom-basic-tooltip`}
        arrowClassName={`custom-basic-tooltip-arrow`}
        placement={'top'}
        target={`issue-code-c-${issue?.id || code}`}
      >
        {code}
      </UncontrolledTooltip>
    )
  }

  return (
    <div
      className={`issue-code-copy ${className || ''}`}
    >
      {showCode && (
        <>
          <span
            id={`issue-code-c-${issue?.id || code}`}
            className="issue-code-copy-text"
          >
            {code}
          </span>
          {renderTooltip()}
        </>
      )}
      {renderCopyIcon()}
    </div>
  )
}

export default IssueCodeCopy
