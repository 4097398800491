import React from "react";
import DashboardWidgetMessage from "./DashboardWidgetMessage";

const DashboardWidgetContainer = (props) => {
  const {
    containerClassName = '',
    children,
    hasMessage = false,
    message = '',
  } = props;

  //  Function to render widget message
  const renderMessage = () => {
    if (!hasMessage || !message) { return null; }
    return (
      <DashboardWidgetMessage
        message={message}
      />
    );
  };

  return (
    <div className={containerClassName}>
      <div className="dashboard-widget-content">
        {children}
      </div>
      {renderMessage()}
    </div>
  );
};

export default DashboardWidgetContainer;