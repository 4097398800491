import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import ClickOutside from "./ClickOutside";


const ResizableTextarea = (props) => {
  const {
    className,
    value,
    rows,
    minRows,
    maxRows,
    placeholder,
    callback,
    maxLength,
    disabled,
    callOnClick = true,
    handleCallback,
    fieldWithNoRow = true,
    inputTagPadding,
    key = null
  } = props;

  const [state, setState] = useState({
    value,
    rows,
    minRows,
    maxRows,
    editing: false
  });
  const ref = useRef();

  const updateSize = () => {
    if (ref.current !== null && ref.current !== undefined) {
      const { maxRows, rows } = state;
      const textareaLineHeight = 20;
      const scrollHeight = ref.current.scrollHeight;
      const currentRows = ~~(scrollHeight / textareaLineHeight);
      if (currentRows >= maxRows) {
        ref.current.rows = maxRows;
      } else {
        ref.current.rows = value === '' ? rows : currentRows;
      }
    }
  }

  if (callOnClick) {
    ClickOutside(ref, () => {
      if (state.editing) {
        setState({
          ...state,
          editing: false
        });
        callback(state.value);
      }
    });
  }

  useEffect(() => {
    setState({
      ...state,
      value: value
    });
    setTimeout(() => {
      updateSize();
    }, 100)
  }, [value, setState])

  // useEffect(() => {
  //   //updateSize();
  // }, [])

  const handleChange = (event) => {
    const textareaLineHeight = 20;
    const { minRows, maxRows } = state;

    const previousRows = event.target.rows;
    event.target.rows = minRows;

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }
    setState({
      ...state,
      value: event.target.value,
      rows: currentRows < maxRows ? currentRows : maxRows,
      editing: true
    });
    if (!callOnClick) {
      callback(event.target.value);
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      if (handleCallback) {
        handleCallback(value)
        event.preventDefault();
      }
      setState({
        ...state,
        value: value
      });
    }
  };
  return (
    <textarea
      key={key}
      style={{ marginLeft: inputTagPadding && '10px' }}
      ref={ref}
      rows={fieldWithNoRow ? 1 : state.rows}
      value={state.value}
      placeholder={placeholder}
      className={className}
      maxLength={maxLength}
      onChange={handleChange}
      disabled={disabled}
      onKeyDown={(e) => handleKeyDown(e)}
    />
  )
}

const makeMapStateToProps = () => {
  const mapStateToProps = (state) => {
    return {}
  }
  return mapStateToProps;
}
const mapDispatchToProps = {};

export default connect(makeMapStateToProps, mapDispatchToProps)(ResizableTextarea);