import { Button } from 'reactstrap';
import React from 'react'
import BasicModal from '../layout/BasicModal'

const UnsavedChangesModal = (props) => {
    const {
        showModal,
        toggleModal,
        saveCallback,
        leaveCallback
    } = props;
    return (
        <BasicModal
            showModal={showModal}
            toggleModal={toggleModal}
            title="Unsaved changes"
            subtitle="You’re leaving this page without saving some changes. Do you want to save your progress?"
            customClassName="unsaved-changes-modal"
        >
            <div className="text-center">
                <Button
                    color="light-gray"
                    className="mr-3"
                    outline
                    onClick={leaveCallback}
                >
                    Leave without saving
                </Button>
                <Button
                    color="light"
                    onClick={saveCallback}
                >
                    Save Progress
                </Button>
            </div>
        </BasicModal>
    )
}

export default UnsavedChangesModal;