import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Can } from '../../../contexts/AbilityContext';
import { trackEvent } from '../../../utils/gaTracker';
import useSelectedTab from '../../../hooks/useSelectedTab';
import {ENTER_KEY_NAME} from '../../../constants/keyNames';
import useCanAbility from '../../../hooks/useCanAbility';
import { PRIVILEGE_SOURCES, READ_ACTION } from '../../../constants/privileges';

const SidebarTab = (props) => {
  //  Parsing props
  const { tab, isCyvatarMember } = props;
  const {
    canAttributes,
    path,
    ariaLabel,
    label = '',
    iconClassName,
    limitToCustomer,
    freemiumInfo,
  } = tab;

  //  Initializing router history
  const history = useHistory();
  const location = useLocation();
  const ability = useCanAbility();

  //  Watching redux store
  const freemiumStatus = useSelector((state) => state.auth.company?.freemium);

  //  Hook for getting selected state
  const { selected } = useSelectedTab({ pathname: path });

  //  Function to render freemium span
  const renderFreemiumInfo = () => {
    if (!freemiumInfo) { return null; }
    const canViewFreemiumTag = ability.can(READ_ACTION, PRIVILEGE_SOURCES.SIDEBAR.FREEMIUM_LABEL);
    if (freemiumStatus !== 0 && canViewFreemiumTag) {
      const { className, label: freemiumLabel } = freemiumInfo;
      return (
        <span className={className}>
          {freemiumLabel}
        </span>
      );
    }
    return null;
  }

  //  On tab clicked
  const onTabClicked = () => {
    if (location?.pathname === path) { return; }
    trackEvent('sidebar-tab-clicked', 'sidebar-menu', label.toLowerCase().split(' ').join('-'));
    history.push(path);
  };

  //  Function to render tab
  const renderTab = () => {
    if (limitToCustomer && isCyvatarMember) { return null; }
    return (
      <li className={`sidebar-tab-container${selected ? ' mm-active' : ''}`}>
        <a
          className={`waves-effect sidebar-tab${selected ? ' active' : ''}`}
          tabIndex="0"
          aria-label={ariaLabel}
          onClick={() => onTabClicked()}
          onKeyPress={(e) => e?.key === ENTER_KEY_NAME ? onTabClicked() : null }
        >
          <i className={iconClassName} />
          <span>{label}</span>
          {renderFreemiumInfo()}
        </a>
      </li>
    );
  };

  //  Function to render wrapped tab on Can component
  const renderWrappedTab = () => {
    const { I, a } = canAttributes;
    return (
      <Can I={I} a={a}>
        {() => renderTab()}
      </Can>
    );
  };

  //  Rendering
  return canAttributes ? renderWrappedTab() : renderTab();
};

export default SidebarTab;
