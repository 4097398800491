import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'reactstrap';
import API from '../../../../api';
import useCustomQuery from '../../../../hooks/useCustomQuery';
import companyIdSelector from '../../../../selectors/companyIdSelector';
import errorMessages from '../../../../constants/errorMessages';
import DashboardRow from '../dashboard/DashboardRow';
import AssessmentStatusCard from '../../../home/AssessmentStatusCard';
import DashboardCard from '../dashboard/DashboardCard';

import ScoreDistribution from '../../../dashboard/ScoreDistribution';

const AssessmentRow = () => {
	//Assesments things
	///
	//  Initializing APIs
	const { DashboardAPI } = API;
	// Getting needed info from redux store
	const companyId = useSelector((state) => companyIdSelector(state));
	// View state
	const [showGradingScale, setShowGradingScale] = useState(false);

	//  Calling API for compliance status
	const {
		data: complianceResponse,
		error: complianceError,
		isLoading: complianceLoading,
		refetch: refetchComplianceStatus,
	} = useCustomQuery(
		[DashboardAPI.keys.getComplianceStatus, { company_id: companyId }],
		({ queryKey }) => DashboardAPI.handlers.getComplianceStatus(queryKey[1])
	);

	const retryRequest = () => {
		refetchComplianceStatus();
	};

	const getErrorMessage = () => {
		const error = complianceError?.data?.message || errorMessages.defaultGet;
		return complianceError ? error : null;
	};
	//  Function to toggle grading scalemodal
	const togGradingScale = () => {
		setShowGradingScale(!showGradingScale);
	};

	const renderScoreDistributionTooltipContent = () => {
    return (
      <div className="score-distribution-help-info">
        <div className="title">How to use this widget</div>
        <p className="body">
          Check how our solutions solve your cybersecurity needs by clicking on
          the solutions name below, also, you can hover over each Control bar to
          view the information about the control and its appropriate Solution.
          If you are not sure how Cyvatar can improve your cybersecurity,
          schedule a demo.
        </p>
      </div>
    );
  };

	const renderAssessment = () => {
		return (
			<DashboardRow title="Self Assessment" dashboardBodyClassName="px-0 pb-4">
				<Col lg={4} className="px-2 pb-2 pb-lg-0">
					<AssessmentStatusCard
						isLoading={complianceLoading}
						errorMessage={getErrorMessage()}
						retryRequest={retryRequest}
						assessmentData={complianceResponse?.assessment}
						togGradingScale={togGradingScale}
					/>
				</Col>
				<Col lg={8} className="px-2 pb-2 pb-lg-0">
					<DashboardCard
						title="Score Distribution"
						customTitleClassName="score-distribution-card-title"
						tooltipCustomClassName='score-distribution-help-icon-tooltip'
						showInformationIconTooltip={true}
						tooltipTargetId={'score-distribution-mycyvatar-dashboard'}
						iconTooltipContent={renderScoreDistributionTooltipContent()}
					>
						<ScoreDistribution
							assessmentData={complianceResponse?.assessment}
							assessmentLoading={complianceLoading}
							assessmentErrorMessage={getErrorMessage()}
							assessmentRetryRequest={retryRequest}
							justChart
							showNowThen={false}
							height={250}
						/>
					</DashboardCard>
				</Col>
			</DashboardRow>
		);
	};

	return <>{renderAssessment()}</>;
};

export default AssessmentRow;
