import React, { useState, useEffect} from "react";
import OverviewModal from './OverviewModal'
import { useSelector } from 'react-redux';
import IcarmSummary from '../dashboard/cybersecurity-score/IcarmSummary';
import useIcarmData from '../../../../hooks/useIcarmData';
import { cyvaluePointsConfig } from '../../../../constants/cyValueData';
import IcarmDistributionChart from '../dashboard/cybersecurity-score/IcarmDistributionChart';
import { isOverallSolution } from '../../../../constants/cyValueData';
import { filterByKey } from "../../../../utils/helpers";

const IcarmDistribution = () => {

  // Component state
  const [solutionSelected, setSolutionSelected] = useState(null);
  const [options, setOptions] = useState([]);

  // Component Hooks
  const {
    filters,
    solutionsData,
    changeFilters,
    hasSurpassedGoal,
    hasOverallScore,
    hasZeroScore,
    isFreemium,
    themeColor,
    icarmLoading,
    icarmErrorMessage,
  } = useIcarmData(cyvaluePointsConfig,solutionSelected?.value);

  const subtabs = useSelector((state) => state.navigation.subtabs);
  const solution_id = useSelector((state) => state?.issues?.filters?.solution_id);

  const formatData = () => {
    if (!subtabs || subtabs.length === 0) { return []; }
    return subtabs.map((item) => {
      return {
        label: item.identifier===0 ? item.label :  `ICARM for ${item.label}`,
        value: item.value,
        extraLabel: item.identifier===0 ? '' :  ` - ${item.label}`,
        name: item.label 
      }
    })
  }

  useEffect(() => {
    setOptions(formatData());
  }, []);

  useEffect(() => {
    setSolutionSelected(filterByKey(options,'value',solution_id)?.[0]);
  }, [options]);

  const renderDistributionChart = () => {
    if (!solutionsData?.solutions || solutionsData?.solutions.length === 0) { return null; }

    return solutionsData.solutions.map((solution) => {

      if (isOverallSolution(solution?.name)) { return null; }
      return (
        <div className='pt-3'>
          <IcarmDistributionChart
            isLoading={icarmLoading}
            errorMessage={icarmErrorMessage}
            filters={filters}
            changeFilters={changeFilters}
            solutionsData={solutionsData}
            hasOverallScore={hasOverallScore}
            hasZeroScore={hasZeroScore}
            hasSurpassedGoal={hasSurpassedGoal}
            themeColor={themeColor}
            lg={12}
            solutionName={solution?.name}
            title={`Threat and Vulnerability Management Scanning - ${solution?.name}`}
          />
        </div>
      )
    })
  }

  return (
    <OverviewModal
      options={options}
      updateOption={(option)=>setSolutionSelected(option)}
      title="ICARM distribution"
      filter={solutionSelected}
    >
      <div className='cyvalue-widget'>
        <IcarmSummary
            isLoading={icarmLoading}
            errorMessage={icarmErrorMessage}
            solutionsData={solutionsData}
            filters={filters}
            hasSurpassedGoal={hasSurpassedGoal}
            hasZeroScore={hasZeroScore}
            hasOverallScore={hasOverallScore}
            isFreemium={isFreemium}
            themeColor={themeColor}
            lg={12}
        />

      {renderDistributionChart()}    
      </div>
    </OverviewModal>
  )
}

export default IcarmDistribution
