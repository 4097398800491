import { useSelector } from 'react-redux';
import issuesInfoSelector from '../selectors/issuesInfoSelector';
import useIssueVulTypeByGroupParam from './useIssueVulTypeByGroupParam';

const useIssueInfoByGroupParam = () => {
  // Component Hooks
  const issueType = useIssueVulTypeByGroupParam();

  // Getting needed info from redux store
  const filters = useSelector((state) => issuesInfoSelector(state, 'filters', issueType));
  const activeFilters = useSelector((state) => issuesInfoSelector(state, 'activeFilters', issueType));
  const searchTerm = useSelector((state) => issuesInfoSelector(state, 'searchTerm', issueType));
  const sortField = useSelector((state) => issuesInfoSelector(state, 'sortField', issueType));
  const shouldReload = useSelector((state) => issuesInfoSelector(state, 'shouldReload', issueType));
  const currentPage = useSelector((state) => issuesInfoSelector(state, 'currentPage', issueType));
  const issues = useSelector((state) => issuesInfoSelector(state, 'list', issueType));
  const total = useSelector((state) => issuesInfoSelector(state, 'total', issueType));
  const deleteModalConfig = useSelector((state) => issuesInfoSelector(state, 'deleteModalConfig', issueType));
  const pageCount = useSelector((state) => issuesInfoSelector(state, 'pageCount', issueType));

  return {
    filters,
    activeFilters,
    searchTerm,
    sortField,
    issueType,
    shouldReload,
    currentPage,
    issues,
    total,
    deleteModalConfig,
    pageCount,
  };
};

export default useIssueInfoByGroupParam;
