import React from 'react'
import { getFormattedDate } from '../../../utils/helpers';
import { getStatusLabelById } from '../../../utils/issuesHelpers';

const IssueCommentHeader = (props) => {
  const {
    username,
    statusName,
    actionDate,
    isRemediation,
    statusId,
    userType,
  } = props;

  // Render issue status
  const renderIssueStatus = () => {
    const statusLabel = getStatusLabelById(statusId, userType) || statusName;
    return statusLabel ? (
      <>
        <span className='font-weight-bold'>{statusLabel}</span>{" | "}
      </>
    ): ''
  }

  // Render username
  const renderUsername = () => {
    return (
      <span
        className='text-light underline-link mr-2'
      >
        {username}
      </span>
    )
  }

  // Render last action date
  const renderLastActionDate = () => {
    return (
      <span className='last-action-date'>
        {`Last action ${getFormattedDate(actionDate, true)}`}
      </span>
    )
  }

  return (
    <div
      className="issue-comment-header"
    >
      {isRemediation && (
        <div className="remediation">Remediation Note</div>
      )}
      {renderIssueStatus()}{renderUsername()}{renderLastActionDate()}
    </div>
  )
}

export default IssueCommentHeader
