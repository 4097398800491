import React from 'react'
import RiskBubble from './RiskBubble';

const RiskMatrixElement = (props) => {
    const {
        element,
        position,
        controllerRef
    } = props;

    const renderBubbles = () => {
        return element.map((statement, index)=> {
            return (
                <RiskBubble 
                    key={`risk-bubble-${position}-${index}`}
                    riskStatement={statement}
                    controllerRef={controllerRef}
                />
            )
        })
    }
    return (
        <div className="risk-matrix-element">
            {renderBubbles()}
        </div>
    )
}

export default RiskMatrixElement
