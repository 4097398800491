import React from 'react';
import { Field } from 'react-final-form';
import { Col } from 'reactstrap';
import { wrapperTypes } from '../../constants/formTemplates';

const CustomFormField = (props) => {
  //  Parsing props
  const { fieldTemplate, defaultValues = {} } = props;
  const {
    name,
    label,
    placeholder,
    className,
    type,
    render,
    customClassName = '',
    defaultValueKey,
    wrapper,
    renderTooltip,
    parse,
    format,
  } = fieldTemplate;

  //  Getting default values
  const getDefaultValue = () => {
    if (!defaultValueKey) { return ''; }
    return defaultValues[defaultValueKey] || ''
  };

  //  Rendering field
  const renderField = () => {
    return (
      <Field
        render={render}
        name={name}
        placeholder={placeholder}
        className={className}
        type={type}
        label={label}
        customClassName={customClassName}
        defaultValue={getDefaultValue()}
        tooltip={renderTooltip ? renderTooltip() : null}
        parse={parse}
        format={format}
      />
    )
  };

  //  Rendering wrapped field
  const renderWrappedField = () => {
    if (wrapper === wrapperTypes.COL) {
      return (
        <Col>
          {renderField()}
        </Col>
      );
    }
    return renderField();
  };

  //  Rendering
  return renderWrappedField();
}

export default CustomFormField;
