import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import CallbackSelectField from '../../layout/fields/SelectFieldCallback';
import API from '../../../api';
import useCustomMutation from '../../../hooks/useCustomMutation';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import { alertTypes } from '../../../constants/alerts';
import { setAlert } from '../../../actions/alerts';
import { getApiErrorMessage } from '../../../utils/helpers';
import { SET_SHOULD_RELOAD_BUSINESS_RISK } from '../../../actions/types';
import { successActionCreator } from '../../../actions/action-creators/success';

const RiskManagerDropdown = (props) => {
    //  Initializing API
    const { VcysoAPI } = API;
    const {
        fieldName,
        configKeyName,
        currentValue,
        placeholder = "Select",
        companyRiskId,
    } = props;

    // Getting needed info from store
    const config = useSelector(state => state.businessRisk?.config);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);

    // Component Hooks
    const { dispatch } = useMemoizedDispatch();
    const {
        data: updateRiskResponse,
        error: updateRiskError,
        isLoading: updateRiskLoading,
        mutate: updateRisk,
    } = useCustomMutation(
        (params) => VcysoAPI.handlers.updateCompanyRisk(params),
        VcysoAPI.invalidators.updateCompanyRisk
    );

    useEffect(() => {
        if(!updateRiskResponse) return;
        dispatch(setAlert("Risk updated", alertTypes.success))
        dispatch(successActionCreator(SET_SHOULD_RELOAD_BUSINESS_RISK, true));
    }, [updateRiskResponse]);

    useEffect(() => {
        if(!updateRiskError) return;
        const errorMessage = getApiErrorMessage(updateRiskError);
        dispatch(setAlert(errorMessage, alertTypes.error))
    }, [updateRiskError]);

    const onChangeCallback = (selected) => {
        const payload = {
            id: companyRiskId,
            [fieldName]: selected?.value
        }
        updateRisk(payload);
    }

    const formatOption = (element) => {
        return { label: element?.label?.toLowerCase(), value: element?.id }
    }

    // Watch configKeyName and config changes to update dropdown options
    useEffect(() => {
        if(config && config[configKeyName]) {
            const value = [
                {
                    options: config[configKeyName].map((element) => {
                        return formatOption(element)
                    })
                }
            ]
            setOptions(value)
        };
    }, [configKeyName, config])

    // Watch current value changes
    useEffect(() => {
        if(currentValue && config && config[configKeyName]) {
            const option = config[configKeyName].find(element => element?.id === currentValue)
            const formattedOption = option ? formatOption(option) : null;
            setSelectedOption(formattedOption);
        }else {
            setSelectedOption(null)
        }
    },[currentValue, config, configKeyName])

    return (
        <div className='risk-manager-dropdown'>
            <CallbackSelectField
                name={fieldName}
                placeholder={placeholder}
                value={selectedOption}
                options={options}
                callback={onChangeCallback}
                displayLabel={false}
                disabled={updateRiskLoading}
            />
        </div>
    )
}

export default RiskManagerDropdown
