import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import AttachmentPreview from './AttachmentPreview';
import API from '../../../api';
import { setAlert } from '../../../actions/alerts';
import useFilesUploader from '../../../hooks/useFilesUploader';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import { getSafeArray } from '../../../utils/helpers';
import { grcAttachmentsExtensions } from '../../../constants/common';

const GRCAttachments = (props) => {
  //  Parsing props
  const {
    company_public_id,
    issue_public_id,
    issue_code,
    files,
    isDragOver,
    clearDraggedState,
    disabled
  } = props;

  //  Initializing API
  const { GrcIssueAPI } = API;

  //  Function to handle file upload
  const handleFileUpload = async (files) => {
    const params = { code: issue_code, files };
    return await GrcIssueAPI.handlers.uploadIssueAttachment(params);
  };

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const queryClient = useQueryClient();
  const {
    uploadedFiles,
    uploaderRef,
    error: uploadError,
    onChange,
    handleDrop,
    removeLoadingFiles,
  } = useFilesUploader({
    directoryPath: `${company_public_id}/${issue_public_id}`,
    bucketName: process.env.REACT_APP_AWS_ATTACHMENTS_BUCKET,
    files,
    handler: handleFileUpload,
    invalidators: GrcIssueAPI.invalidators.uploadIssueAttachment,
    extraConfig: {},
  });

  //  Watching upload error
  useEffect(() => {
    if (!uploadError) { return; }
    const errorMessage = uploadError?.response?.data?.errorMessage || 'Error uploading files';
    removeLoadingFiles();
    queryClient.invalidateQueries(GrcIssueAPI.invalidators.uploadIssueAttachment);
    dispatch(setAlert(errorMessage, 'danger'));
  }, [uploadError]);

  //  Function to handle input click
  const onInputClick = () => {
    uploaderRef?.current?.click();
  };

  //  Function to handle drop
  const onDrop = (event) => {
    if(disabled) return ;
    handleDrop(event);
    clearDraggedState();
  };

  //  Function to render attachments
  const renderAttachments = () => {
    const safeFiles = getSafeArray(uploadedFiles);
    return safeFiles.map((file, index) => {
      return (
        <AttachmentPreview
          key={index}
          file={file}
          company_public_id={`${company_public_id}/${issue_public_id}`}
        />
      );
    })
  }

  const getContainerClassName = () => {
    const disabledClassName = disabled ? 'pe-none opacity-50' : '';
    return `grc-attachments-container ${disabledClassName}`
  }
  //  Rendering
  return (
    <div className={getContainerClassName()}>
      <div className="file-uploader">
        <div className="grc-attachments-title">
          {`Attachments (${files?.length || 0})`}
        </div>
        <div
          className={`grc-attachments-field${isDragOver && !disabled ? ' dragged-over' : ''}`}
          onDrop={onDrop}
        >
          <div className="grc-attachments-field-placeholder">
            Drop files or
          </div>
          <div
            className="grc-attachments-field-browse"
            onClick={onInputClick}
          >
            Browse
          </div>
        </div>
        <input
          className="grc-attachment-input"
          type="file"
          ref={uploaderRef}
          onChange={onChange}
          accept={grcAttachmentsExtensions?.join(',')}
          multiple={true}
        />
      </div>
      <div className="file-list-container">
        <div className="file-list">
          {renderAttachments()}
        </div>
      </div>
    </div>
  );
};

export default GRCAttachments;
