import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { setPartnerIdAndName } from '../../actions/partners';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import IconTooltip from '../dashboard/tooltips/IconTooltip';
import ConnectionsConfigModal from './ConnectionsConfigModal';

const ConnectionManagement = (props) => {
  const {
    connectionId,
    partnerId,
    partnerName,
    companyId,
    companyName,
    hasEnabled,
  } = props;

  // Component state
  const [showModal, setShowModal] = useState(null);

  // Component Hooks
  const history = useHistory();
  const { dispatch } = useMemoizedDispatch();

  // toggle config partner modal
  const toggleModal = () => {
    setShowModal(!showModal);
  }

  // On manage connection click
  const onManageClick = () => {
    if (hasEnabled) {
      dispatch(setPartnerIdAndName(partnerId, partnerName));
      history.push({
        pathname: "/solution-settings",
        search: `?partnerId=${partnerId}`,
      });
    } else {
      toggleModal();
    }
  }

  // render edit connection modal
  const renderEditConnectionModal = () => {
    if(!showModal) return null;
    return (
      <ConnectionsConfigModal
        showModal={showModal}
        toggleModal={toggleModal}
        partnerId={partnerId}
        partnerName={partnerName}
        companyName={companyName}
        companyId={companyId}
        isNewConnection={false}
      />
    )
  }

  return (
    <>
      {renderEditConnectionModal()}
      <IconTooltip
        iconClassName="bx bx-cog bx-xs"
        callback={onManageClick}
        tooltipText="Configuration"
        targetId={`connection-configuration-${connectionId}`}
      />
    </>
  );
}

export default ConnectionManagement
