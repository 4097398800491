import useShowModal from './useShowModal'

const useAttachmentActions = (params) => {
  //  Parsing params
  const {
    company_id,
  } = params;

  // Hooks
  const {
    showModal: showDeleteAttachmentModal,
    toggleModal: toggleDeleteAttachmentModal,
  } = useShowModal();

  //  Hook variables
  const actions = [
    {
      iconClassName: 'bx bx-trash',
      callback: toggleDeleteAttachmentModal,
      text: 'Delete attachments',
    }
  ];

  //  Exporting hooks assets
  return {
    actions,
    showDeleteAttachmentModal,
    toggleDeleteAttachmentModal,
  }
};

export default useAttachmentActions;
