import React from "react";
import {
  additionalWidgetsIds,
  additionalWidgetsTitles,
} from "../../../../constants/dashboard";
import { Card, CardBody, Button, Col } from "reactstrap";
import { useHistory } from 'react-router-dom';

const AdditionalWidget = (props) => {
  const {
    message = "",
    buttonMessage = "",
    widgetID = additionalWidgetsIds.ADD_TEAM_MEMBER,
    icon,
    buttonOnClick,
    linkPath,
  } = props;

  // Component Hooks
  const history = useHistory();

  const getTitle = () => {
    switch (widgetID) {
      case additionalWidgetsIds.ADD_TEAM_MEMBER:
        return additionalWidgetsTitles.ADD_TEAM_MEMBERS;
      case additionalWidgetsIds.POLICIES_LIBRARY:
        return additionalWidgetsTitles.POLICIES_LIBRARY;
      case additionalWidgetsIds.MORE_WIDGETS:
        return additionalWidgetsTitles.MORE_WIDGETS;
      default:
        return "";
    }
  };

  return (
    <div className="additional-widget-card-container">
      <div className="additional-widget-container">
        <Col sm={12} lg={12} className='align-self-start align-self-lg-stretch mb-2'>
          <Card className="h-100 mb-3 mb-md-0">
            <CardBody className='d-flex flex-column'>
              <span className="title d-inline-flex align-items-center">
                {getTitle()}
              </span>
              <div className="additional-widget-content-container">
                <div className="additional-widget-icon col-sm-5 col-lg-5">
                  <img src={icon} alt="icon" />
                </div>
                <div className="additional-widget-content col-sm-6 col-lg-6">
                  <span className="additional-widget-message">{message}</span>
                  <Button
                    type="button"
                    color="light"
                    className="mb-2 float-right additional-btn"
                    onClick={() => {
                      if(buttonOnClick) buttonOnClick()
                      if(linkPath) history.push(linkPath)
                    }}
                  >
                    {buttonMessage}
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </div>
    </div>
  );
};

export default AdditionalWidget;
