import cyvatarAxios from '../axios';
import {
  GET_GROUPS_LIST,
  GET_ISSUES_LIST,
  GET_ISSUE,
  GET_ISSUE_MODAL_OPTIONS,
  GET_ROLLUP_DATA,
  GET_COMPLIANCE,
  GET_COMPLIANCE_STATUS,
  FETCH_COMPLIANCE_SCORE,
  GET_CYVALUE_DATA,
  GET_CYVALUE_BY_WEEK,
  GET_ACTIVITY_REPORT,
  GET_TAGS_LIST,
  GET_ROI,
  GET_TOP_FIVE_ISSUES,
  GET_TOP_FIVE_ISSUES_OVERVIEW,
  GET_VULNERABILITY_TREND,
  GET_GROUPS_LIBRARY,
  GET_GROUP_DETAILS,
  GET_ISSUE_GROUP_MEMBERS,
  GET_ISSUE_GROUP_DEVICES
} from '../ApiKeys';

const getGroupsList = async (params = {}) => {
  const response = await cyvatarAxios.get('/api/workbench/issue_group/list', { params });
  const { data } = response;
  return data;
};

const bulkUpdateGroups = async (queryParams = {}) => {
  const { group_ids, body } = queryParams;
  const params = { group_ids };
  const response = await cyvatarAxios.put('/api/workbench/issue_group/bulk-update', body, { params });
  const { data } = response;
  return data;
};

const addTagsToGroups = async (params) => {
  const { group_ids = [], titles = [] } = params;
  const body = { titles };
  const response = await cyvatarAxios.patch('/api/workbench/issue_group/tags/add', body, { params: { group_ids } });
  const { data } = response;
  return data;
};

const removeTagsFromGroups = async (params) => {
  const { group_ids = [], tag_ids = [] } = params;
  const body = { tag_ids };
  const response = await cyvatarAxios.delete('/api/workbench/issue_group/tags/remove', { params: { group_ids }, data: body });
  const { data } = response;
  return data;
};

const getLibrary = async (partner_ids) => {
  const params = { partner_ids };
  const response = await cyvatarAxios.get('/api/workbench/issue_group/library', { params });
  const { data } = response;
  return data;
}

const getGroupDetails = async (publicId) => {
  if(!publicId) return {};
  const response = await cyvatarAxios.get(`/api/workbench/issue_group/${publicId}`);
  const { data } = response;
  return data;
}

const updateGroup = async (queryParams = {}) => {
  const { public_id, body } = queryParams;
  const response = await cyvatarAxios.put(`/api/workbench/issue_group/${public_id}`, body);
  const { data } = response;
  return data;
};

const getIssuesGroupMembers = async (params) => {
  const apiResponse = await cyvatarAxios.get('api/workbench/issue_group/group-members', { params });
  return apiResponse?.data;
};

const getIssuesGroupDevices = async (params) => {
  const apiResponse = await cyvatarAxios.get('api/workbench/issue_group/group-devices', { params });
  return apiResponse?.data;
};

const issuesChangesInvalidators = [
  GET_ISSUES_LIST,
  GET_ISSUE,
  GET_ISSUE_MODAL_OPTIONS,
  GET_ROLLUP_DATA,
  GET_COMPLIANCE,
  GET_COMPLIANCE_STATUS,
  FETCH_COMPLIANCE_SCORE,
  GET_CYVALUE_DATA,
  GET_CYVALUE_BY_WEEK,
  GET_ACTIVITY_REPORT,
  GET_TAGS_LIST,
  GET_ROI,
  GET_TOP_FIVE_ISSUES,
  GET_TOP_FIVE_ISSUES_OVERVIEW,
  GET_VULNERABILITY_TREND,
  GET_GROUP_DETAILS,
  GET_ISSUE_GROUP_MEMBERS,
  GET_ISSUE_GROUP_DEVICES
]

export const GroupAPI = {
  handlers: {
    getGroupsList,
    bulkUpdateGroups,
    addTagsToGroups,
    removeTagsFromGroups,
    getLibrary,
    getGroupDetails,
    updateGroup,
    getIssuesGroupMembers,
    getIssuesGroupDevices
  },
  keys: {
    getGroupsList: GET_GROUPS_LIST,
    getLibrary: GET_GROUPS_LIBRARY,
    getGroupDetails: GET_GROUP_DETAILS,
    getIssuesGroupMembers: GET_ISSUE_GROUP_MEMBERS,
    getIssuesGroupDevices: GET_ISSUE_GROUP_DEVICES,
  },
  invalidators: {
    bulkUpdateGroups: [...issuesChangesInvalidators, GET_GROUPS_LIST],
    addTagsToGroups: [...issuesChangesInvalidators, GET_GROUPS_LIST],
    removeTagsFromGroups: [...issuesChangesInvalidators, GET_GROUPS_LIST],
    updateGroup: [...issuesChangesInvalidators, GET_GROUPS_LIST],
  },
}
