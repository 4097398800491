import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from 'reactstrap';
import Dashboard from '../components/dashboard/Dashboard';
import AssessmentOpportunities from '../components/home/AssessmentOpportunities';
import ViewWrapper from '../components/layout/ViewWrapper';
import API from '../api';
import companyIdSelector from '../selectors/companyIdSelector';
import { useSelector } from 'react-redux';
import errorMessages from '../constants/errorMessages';
import RedirectPrompt from '../components/layout/RedirectPrompt';
import { useHistory } from 'react-router-dom';
import { defaultIndexPath } from '../constants/navigation';
import MainScrollableContainer from '../components/layout/MainScrollableContainer';
import ScoreDistribution from '../components/dashboard/ScoreDistribution';
import RoiCalculator from '../components/ReportCard/RoiCalculator';
import ComplianceScoreCard from '../components/ReportCard/ComplianceScoreCard';
import GradingScale from '../components/dashboard/compliance/GradingScale';
import companyInfoSelector from '../selectors/companyInfoSelector';
import useAssessmentValidation from '../hooks/useAssessmenentValidation';
import useCustomQuery from '../hooks/useCustomQuery';

const ComplianceView = () => {
    //  Initializing API
    const { DashboardAPI } = API;

    // Getting needed info from redux store
    const companyId = useSelector(state => companyIdSelector(state));
    const isFreemium = useSelector((state) => companyInfoSelector(state, 'freemium', false) !== 0);

    // Hooks
    const history = useHistory();

    // State
    const [showGradingScale, setShowGradingScale] = useState(false);

    //  Calling API for compliance status
    const {
        data: complianceResponse,
        error: complianceError,
        isLoading: complianceLoading,
        refetch: refetchComplianceStatus,
    } = useCustomQuery(
        [DashboardAPI.keys.getComplianceStatus, { company_id: companyId }],
        ({ queryKey }) => DashboardAPI.handlers.getComplianceStatus(queryKey[1]),
        { enabled: !!companyId },
    );

    //  Calling API for checking assessment status
    const {
        isLoading: assessmentValidationLoading,
        errorMessage: assessmentValidationErrorMessage,
        isCompleted: isUnlocked,
        retryRequest: assessmentValidationRetryRequest
    } = useAssessmentValidation();

    // Redirect to homepage if content is locked
    const handleLockedContent = () => {
        if (isUnlocked === null) return;
        history.push({
            state: {
                redirected: true,
                title: `${isFreemium ? 'Freemium ' : ''}Compliance Journey`,
                message: "Complete your assessment to unlock the insights of Compliance Journey"
            },
            pathname: defaultIndexPath
        })
    }

    // Calls api again
    const retryRequest = () => {
        refetchComplianceStatus();
    }

    // Watch isUnlocked flag
    useEffect(() => {
        if (!isUnlocked) {
            handleLockedContent();
        }
    }, [isUnlocked, handleLockedContent])

    //  Function to toggle grading scalemodal
    const togGradingScale = () => {
        setShowGradingScale(!showGradingScale);
    };

    const getErrorMessage = () => {
        const error = complianceError?.data?.message || errorMessages.defaultGet;
        return complianceError ? error : null
    }

    //  Function to render Assessment's Grading scale modal
    const renderGradingScaleModal = () => {
        if (!showGradingScale) { return null; }
        return (
            <GradingScale
                showModal={showGradingScale}
                togModal={togGradingScale}
                grades={complianceResponse?.assessment?.config?.grades}
                isLoading={complianceLoading}
                errorMessage={getErrorMessage()}
                retryRequest={retryRequest}
            />
        );
    };

    // Render Compliance Score Card
    const renderComplianceScoreCard = () => {
        return (
            <Col lg={6} className="pb-3">
                <ComplianceScoreCard
                    isLoading={complianceLoading}
                    errorMessage={getErrorMessage()}
                    retryRequest={retryRequest}
                    assessmentData={complianceResponse?.assessment}
                    togGradingScale={togGradingScale}
                />
            </Col>
        )
    }

    // Render Assessment Opportunities
    const renderAssessmentOpportunitiesCard = () => {
        return (
            <Col lg={6} className="pb-3">
                <AssessmentOpportunities
                    isLoading={complianceLoading}
                    errorMessage={getErrorMessage()}
                    retryRequest={retryRequest}
                    assessmentData={complianceResponse?.assessment}
                />
            </Col>
        )
    }

    const onTakeAssessmentClick = () => {
        history.push(`${defaultIndexPath}/assessment`)
    }

    const renderAssessmentRedirectPrompt = () => {
        return (
            <RedirectPrompt>
                <div className="text-center">
                    <Button
                        color="light"
                        className="btn-large"
                        onClick={onTakeAssessmentClick}
                    >
                        Take your assessment
                    </Button>
                </div>
            </RedirectPrompt>
        )
    }

    const renderScoreDistribution = () => {
        if (complianceResponse?.assessmentData?.distribution?.current?.length > 0) return null;
        return (
            <Row className="no-gutters pb-3 no-padding-bottom">
                <ScoreDistribution
                    assessmentData={complianceResponse?.assessment}
                    assessmentLoading={complianceLoading}
                    assessmentErrorMessage={getErrorMessage()}
                    assessmentRetryRequest={retryRequest}
                />
            </Row>
        )
    }

    const renderContent = () => {
        return (
            <>
                <Row className="no-gutters">
                    {renderComplianceScoreCard()}
                    {renderAssessmentOpportunitiesCard()}
                </Row>
                {renderScoreDistribution()}
                <Row className="no-gutters">
                    <RoiCalculator />
                </Row>
            </>
        )
    }

    return (
        <ViewWrapper
            layoutType="vertical"
            viewName="Compliance"
            hasImpersonationMode={true}
        >
            {renderGradingScaleModal()}
            {renderAssessmentRedirectPrompt()}
            <Dashboard
                containerClassName="dashboard mx-2 px-0 text-normal-style"
            >
                <MainScrollableContainer
                    additionalClassName="homepage-view view-container px-0"
                >
                    {isUnlocked ? renderContent() : null}
                </MainScrollableContainer>
            </Dashboard>
        </ViewWrapper>
    )
}

export default ComplianceView;
