import React from 'react';
import SearchableInfiniteTable from '../../../CustomTable/SearchableInfiniteTable';

const GroupDetailsTable = (props) => {
  const {
    tableClassName = '',
    headerClassName = '',
    rowClassName = '',
    columnsTemplate,
    renderTitle = () => {},
    searchbarClassName = '',
    searchbarPlaceholder = '',
    apiFunction = () => {},
    apiKey,
    extraKeys = [],
    emptyStateConfig = {},
    allSelected = false,
    selected = [],
    excluded = [],
    handleSelectedEvent,
    setElements,
  } = props;

  return (
    <div className="group-details-table">
      <>
        <div className="table-container">
          <SearchableInfiniteTable
            tableClassName={tableClassName}
            headerClassName={headerClassName}
            rowClassName={rowClassName}
            columnsTemplate={columnsTemplate}
            renderTitle={renderTitle}
            searchbarClassName={searchbarClassName}
            searchbarPlaceholder={searchbarPlaceholder}
            apiFunction={apiFunction}
            apiKey={apiKey}
            extraKeys={extraKeys}
            expandableRow={false}
            emptyConfig={emptyStateConfig}
            selected={selected}
            excluded={excluded}
            allSelected={allSelected}
            handleSelectedEvent={handleSelectedEvent}
            setElements={setElements}
          />
        </div>
      </>
    </div>
  );
}

export default GroupDetailsTable
