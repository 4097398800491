import {
  CUSTOMER_LIST_SUCCESS,
  CUSTOMER_LIST_FAILURE,
  CUSTOMER_CLEAR,
  CUSTOMER_PROFILE_SUCCESS,
  CUSTOMER_PROFILE_FAILURE,
  SET_CUSTOMERS_FILTER,
  CLEAR_ALL_CUSTOMERS_FILTERS,
  SET_CUSTOMERS_SORT,
  RELOAD_CUSTOMERS,
  CUSTOMER_LIST_REQUEST,
  ON_SCAN_LOADING_CHANGE
} from './types';
import filterTypes from '../constants/filterTypes';
import errorMessages from '../constants/errorMessages';

export const fetchCustomers = (payload) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      const { CompanyAPI } = API;
      dispatch({ type: CUSTOMER_LIST_REQUEST });
      const response = await CompanyAPI.handlers.listCompany(payload);
      dispatch(fetchCustomersSuccess(response?.data));
    } catch (error) {
      dispatch(fetchCustomersError(error));
    }
  };
}

export const fetchCustomersSuccess = (results) => {
  return {
    type: CUSTOMER_LIST_SUCCESS,
    payload: results
  }
}

export const fetchCustomersError = (message) => {
  return {
    type: CUSTOMER_LIST_FAILURE,
    payload: message
  }
}

export const reloadCustomers = (data) => ({
  type: RELOAD_CUSTOMERS,
  payload: data,
});

export const resetCustomers = () => {
  return {
    type: CUSTOMER_CLEAR,
    payload: null
  }
}

export const fetchCustomerProfile = (payload) => {
  return async (dispatch, getState, { API, queryClient }) => {
    try {
      const { CompanyAPI } = API;
      const response = await CompanyAPI.handlers.getCompany(payload);
      dispatch(fetchCustomerProfileSuccess(response?.data));
    } catch (error) {
      const message = error?.response?.data?.errorMessage || errorMessages.defaultGet;
      dispatch(fetchCustomerProfileError(message));
    }
  };
}

export const fetchCustomerProfileSuccess = (results) => {
  return {
    type: CUSTOMER_PROFILE_SUCCESS,
    payload: results
  }
}

export const fetchCustomerProfileError = (message) => {
  return {
    type: CUSTOMER_PROFILE_FAILURE,
    payload: { message }
  }
}

export const setCustomersFilter = (filterName, value, label, type = filterTypes.single) => {
  return {
    type: SET_CUSTOMERS_FILTER,
    payload: { filterName, value, label, type }
  }
}

export const clearCustomersFilters = () => {
  return { type: CLEAR_ALL_CUSTOMERS_FILTERS }
}

export const setCustomersSort = (value) => {
  return {
    type: SET_CUSTOMERS_SORT,
    payload: value
  }
}

export const onScanLoading = (flag = false) => {
  return {
    type: ON_SCAN_LOADING_CHANGE,
    payload: flag,
  };
}
