import React, { useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import { Link, useHistory } from 'react-router-dom';
import { OnChange } from 'react-final-form-listeners';
import TeamMembers from './TeamMembers';
import makeErrorMessage from '../../selectors/errorsSelector';
import makeLoadingState from '../../selectors/loadingSelector';
import CompanyMemberInvite from '../profile/CompanyMemberInvite';
import { setAlert } from '../../actions/alerts';
import { validateEmptyTextField } from '../../utils/validations';
import { renderInput } from '../../utils/renderFormFields';
import { abbreviateNumber, formatMoney, formatWholeNumber, isValidFunction } from '../../utils/helpers';
import { fetchCustomerProfile } from '../../actions/customerManagement';
import { updateCompanyRequest } from '../../actions/auth';
import EditModeButtons from '../layout/buttons/EditModeButtons'
import { alertTypes } from '../../constants/alerts';
import errorMessages from '../../constants/errorMessages';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import companyIdSelector from '../../selectors/companyIdSelector';
import LoadingContent from '../layout/LoadingContent';
import IconCard from '../layout/cards/IconCard';
import amplitude from 'amplitude-js';
import { Can } from '../../contexts/AbilityContext';
import useCanAbility from '../../hooks/useCanAbility';
import { MODIFY_ACTION, PRIVILEGE_SOURCES, READ_ACTION } from '../../constants/privileges';
import BasicTooltip from '../dashboard/tooltips/BasicTooltip';
import CompanyProfileLogo from './CompanyProfileLogo';

const CompanyBasicProfile = (props) => {

  const {
    lg = 4
  } = props;

  // Component hooks
  const { dispatch } = useMemoizedDispatch();
  const ability = useCanAbility();

  //  Getting router history
  const history = useHistory();

  // Selectors
  const getLoadingState = makeLoadingState(['CUSTOMER_PROFILE']);
  const getErrorMessage = makeErrorMessage(['CUSTOMER_PROFILE']);

  //Component State
  const [editMode, setEditMode] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);

  //  Getting needed info from redux store
  const isLoading = useSelector(state => getLoadingState(state));
  const errorMessage = useSelector(state => getErrorMessage(state));
  const { name, annualRevenue, numberEmployees, id, code } = useSelector(state => state.auth.company);
  const currentCustomer = useSelector(state => state.customers?.currentCustomer);
  const companyId = useSelector(state => companyIdSelector(state));
  const activeImpersonation = useSelector((state) => !_.isNil(state?.impersonation?.companyId));
  const isMemberView = useSelector(state => state.impersonation.isMemberView);

  const onRetryRequest = () => {
    if (companyId) {
      dispatch(fetchCustomerProfile(companyId))
    }
  }

  //  Function for opening and closing add members modal
  const openAddMembersModal = () => setShowAddMemberModal(true);
  const closeAddMembersModal = () => setShowAddMemberModal(false);

  //  Function to redirect to solutions
  const onSolutionsLabelClicked = () => {
    history.push({ pathname: '/manage-solutions' });
  };

  //  Function to render add members modal
  const renderAddMembersModal = () => {
    if (!showAddMemberModal) { return null; };
    return (
      <CompanyMemberInvite
        closeModal={closeAddMembersModal}
        customerId={companyId}
      />
    );
  };

  const renderCompanyField = (params, index) => {
    const {
      name,
      label,
      initialValue,
      placeholder,
      wrapperClassName = "",
      parse,
      format,
      type = "text"
    } = params;

    return (
      <div key={`info-field-${index}`} className={wrapperClassName}>
        <div className="company-item">
          <Field
            render={renderInput}
            customClassName="dark"
            name={name}
            label={label}
            placeholder={placeholder}
            customError={formErrors[name]}
            initialValue={initialValue}
            type={type}
            parse={parse}
            format={format}
          />
          <OnChange name={name}>
            {() => setFormErrors(_.omit(formErrors, [name]))}
          </OnChange>
        </div>
      </div>
    )

  }

  const renderCompanyInfoItem = (params, index) => {
    const {
      label,
      value,
      wrapperClassName = "",
      customComponent,
      iconComponent = null
    } = params;
    const hasIconTooltip = iconComponent !== null && iconComponent !== undefined;

    return (
      <div key={`info-item-${index}`} className={wrapperClassName}>
        <div className="company-item mb-4">
          <label>{label}</label>
          {hasIconTooltip ? iconComponent : null}
          {hasIconTooltip ?
            <BasicTooltip
              content={'You can set up up to 25 domains'}
              target={'domains-help-icon'}
            /> : null
          }
          {isValidFunction(customComponent) ? customComponent(value) : (<div className="info-text">{value}</div>)}
        </div>
      </div>
    )
  }

  const updateCompanySuccessCallback = () => {
    amplitude.getInstance().logEvent('My Account View - Company Profile - Edit Info');
    setEditMode(false);
  };

  //  Function to handle for submit
  const onSubmit = (values) => {
    const payloadToStore = {
      formValues: values,
      companyId: currentCustomer?.public_id || id
    };
    dispatch(updateCompanyRequest(payloadToStore, updateCompanySuccessCallback));
  }

  // Function to render member domains
  const renderMemberDomains = (value) => {
    return (
      <div className="info-text add-domain-btn-container">
        <span className='domain-count'>{value ? value : 1}</span>
        <Link
          to="/profile/company/domains"
          className="btn btn-link add-domain float-right mr-3 py-2 px-4"
        >
          View my domains
        </Link>
      </div>
    //   <Button
    //   color="link"
    //   className="check-free-scan-logs d-flex align-items-center text-decoration-none p-0 mb-2"
    //   //onClick={() => openAddDomainsModal()}
    // >
    //   <span className="underline-link mr-2">View my logs</span>
    // </Button>
    );
  }


  //  Function to render form body
  const renderFormBody = (elements = [], submitting = false) => {
    return (
      <Row className="negative-mx-2 px-2">
        <CompanyProfileLogo companyId={companyId}/>
        <Col lg={8}>
          <Row>
          {elements.map((element, index) => {
            return (!element.isReadOnly && editMode) ? renderCompanyField(element, index) : renderCompanyInfoItem(element, index)
          })}
          <div className="row-break d-flex d-md-none" />
          <Can
            I={MODIFY_ACTION}
            a={PRIVILEGE_SOURCES.MEMBERS.GENERAL}
          >
            <EditModeButtons
              editMode={editMode}
              cancelCallback={() => setEditMode(false)}
              editCallback={() => setEditMode(!editMode)}
              submitting={submitting}
              processingLabel="Saving"
            />
          </Can>
          </Row>
        </Col>
      </Row>
    );
  };

  const renderInformation = () => {
    const currentAnnualRevenue = activeImpersonation ? currentCustomer?.annual_revenue : annualRevenue;
    const currentNumberEmployees = activeImpersonation ? currentCustomer?.number_employees : numberEmployees;
    const isAnnualRevenueEmpty = _.isNil(currentAnnualRevenue);
    const isNumberEmployeesEmpty = _.isNil(currentNumberEmployees);

    const elements = [
      {
        name: "companyName",
        label: "Company name",
        value: currentCustomer?.name || name,
        initialValue: currentCustomer?.name || name,
        wrapperClassName: 'col-sm-12 col-md-4',
        isReadOnly: false
      },
      {
        label: 'Customer code',
        value: currentCustomer?.code || code,
        wrapperClassName: 'col-sm-12 col-md-4',
        isReadOnly: true
      },
      {
        name: "numberEmployees",
        label: "Number of Employees",
        value: !isNumberEmployeesEmpty ? abbreviateNumber(currentNumberEmployees) : '-',
        initialValue: !isNumberEmployeesEmpty ? currentNumberEmployees?.toString() : '',
        wrapperClassName: `col-sm-12 col-md-4 ${isNumberEmployeesEmpty ? 'text-empty-value' : ''}`,
        parse: value => formatWholeNumber(value),
        format: value => formatWholeNumber(value),
        isReadOnly: false
      },
      /*{
        name: "annualRevenue",
        label: "Annual Revenue ($)",
        value: !isAnnualRevenueEmpty ? abbreviateNumber(currentAnnualRevenue, 'currency') : '-',
        initialValue: !isAnnualRevenueEmpty ? currentAnnualRevenue?.toString() : '',
        placeholder: '$',
        wrapperClassName: `col-sm-12 col-md-6 ${isAnnualRevenueEmpty ? 'text-empty-value' : ''}`,
        parse: value => formatWholeNumber(value),
        format: value => formatMoney(formatWholeNumber(value)),
        isReadOnly: false
      }*/,
      {
        name: "expected_devices",
        label: "Expected Devices",
        value: currentCustomer?.expected_devices !== null ? currentCustomer?.expected_devices : '-',
        initialValue: currentCustomer?.expected_devices !== null ? currentCustomer?.expected_devices : '',
        placeholder: '0',
        wrapperClassName: `col-sm-12 col-md-4 ${!currentCustomer?.expected_devices ? 'text-empty-value' : ''}`,
        parse: value => formatWholeNumber(value),
        format: value => formatWholeNumber(value),
        isReadOnly: false
      },
      /*{
        name: "expected_hosts",
        label: "Expected Hosts",
        value: currentCustomer?.expected_hosts !== null ? currentCustomer?.expected_hosts : '-',
        initialValue: currentCustomer?.expected_hosts !== null ? currentCustomer?.expected_hosts : '',
        placeholder: '0',
        wrapperClassName: `col-sm-12 col-md-6 ${!currentCustomer?.expected_hosts? 'text-empty-value' : ''}`,
        parse: value => formatWholeNumber(value),
        format: value => formatWholeNumber(value),
        isReadOnly: false
      }*/,
      /*{
        name: "expected_subnets",
        label: "Expected Subnets",
        value: currentCustomer?.expected_subnets !== null ? currentCustomer?.expected_subnets : '-',
        initialValue: currentCustomer?.expected_subnets !== null ? currentCustomer?.expected_subnets : '',
        placeholder: '0',
        wrapperClassName: `col-sm-12 col-md-6 ${!currentCustomer?.expected_subnets ? 'text-empty-value' : ''}`,
        parse: value => formatWholeNumber(value),
        format: value => formatWholeNumber(value),
        isReadOnly: false
      }*/,
      {
        name: "member_domains",
        label: "Member Domains",
        value: currentCustomer?.domains_count !== null ? currentCustomer?.domains_count : '1',
        initialValue: '',
        wrapperClassName: `col-sm-12 col-md-4 text-empty-value`,
        isReadOnly: true,
        customComponent : value => renderMemberDomains(value),
        iconComponent : (<i id='domains-help-icon' className='bx bx-help-circle tooltip-icon'></i>)
      },
      {
        name: "company_status",
        label: "Company Status",
        value: currentCustomer?.freemium === 0 ? 'Subscribed' : 'Freemium',
        initialValue: '',
        wrapperClassName: `col-sm-12 col-md-4`,
        isReadOnly: true,
      }
    ];

    if (!editMode) {
      return renderFormBody(elements, false)
    }

    return (
      <Form
        onSubmit={onSubmit}
        validate={validate}
        shouldValidate={true}
        render={({ handleSubmit, submitting, invalid }) => (
          <form onSubmit={event => {
            if (invalid) {
              dispatch(setAlert(errorMessages.MISSING_FIELDS, alertTypes.error));
            }
            handleSubmit(event);
          }}>
            {renderFormBody(elements, submitting)}
          </form>
        )}
      />
    )
  }

  //  Function to render solutions label
  const renderSolutionsLabel = () => {
    const canReadSolutions = ability.can(READ_ACTION,PRIVILEGE_SOURCES.PROFILE.COMPANY_SOLUTIONS_LINK);
    if (!canReadSolutions || isMemberView) { return null; }
    const { running_solutions = 0 } = currentCustomer || {};
    const solutionsAmount = parseInt(running_solutions)
    const solutionMessage = solutionsAmount !== 0 ? `${solutionsAmount} installed solution${solutionsAmount > 1 ? 's':''}` : 'Set up solution';
    return (
        <Button
            color="link"
            className="running-solutions-label d-flex align-items-center text-decoration-none p-0 mb-2"
            onClick={() => onSolutionsLabelClicked()}
        >
            <span className="underline-link mr-2">{solutionMessage}</span>
            <i className='bx bx-right-top-arrow-circle'/>
        </Button>
    );
};

  const renderSectionDivider = () => {
    return <hr className='style-line' />;
  }

  const renderTeamMembers = () => {
    return (
      <TeamMembers openAddMembersModal={openAddMembersModal} />
    )
  }

  return (
    <Col lg={lg} className="align-self-lg-stretch mb-3">
      <IconCard
        title="Your company information"
        titleClassName="text-dirty-white"
        iconClassName="bx bx-briefcase-alt-2"
        iconWrapperClassName="bg-active-purple"
        cardWrapperClassName="basic-company-profile user-profile h-100"
      >
        <LoadingContent
          errorMessage={errorMessage}
          errorButtonCallback={onRetryRequest}
          isLoading={isLoading}
          iconType="solidIcon"
          errorStyle={{ minHeight: "266px", paddingTop: "41px" }}
          loadingStyle={{ minHeight: "266px", paddingTop: "41px" }}
        >
          <Row className="negative-mx-2 px-2 justify-content-between">
            <Col lg={12} className="basic-info">
              {renderInformation()}
              {/*renderSolutionsLabel()*/}
              {/*renderSectionDivider()*/}
              {/*renderTeamMembers()*/}
            </Col>
          </Row>
        </LoadingContent>
      </IconCard>
      {renderAddMembersModal()}
    </Col>
  )
}

const validate = (values = {}) => {
  const errors = {};
  const numberFields = ['expected_devices', 'expected_subnets', 'expected_hosts'];

  for (const key in values) {
    //  Text validations
    const textValue = `${values[key]}`;
    const validation = validateEmptyTextField(textValue);
    if (validation) { errors[key] = validation; }
    if (numberFields.includes(key) && !validation) {
      //  Number validations
      if (textValue.length > 10) { errors[key] = 'Number is too long.'; }
    }
  }

  return errors;
}

export default CompanyBasicProfile;
