import cyvatarAxios from '../axios';
import { defaultGRCIssue } from '../../constants/issues';
import {
  GET_GRC_ISSUES_LIST,
  GET_GRC_ISSUES_CONFIG,
  GET_GRC_TREND_DATA,
  GET_GRC_ISSUE,
  GET_ROLLUP_DATA,
  GET_ASSIGNED_TO,
  GET_ARCHIVED_TASKS,
} from '../ApiKeys';

const getGrcIssuesList = async (issuesConfig) => {
  const apiResponse = await cyvatarAxios.get('/api/vcyso/grc/issues', { params: issuesConfig });
  const { data } = apiResponse;
  return data;
};

const getGrcIssuesConfig = () => {
  return cyvatarAxios.get(`/api/vcyso/grc/config`);
};

const getGrcTrendData = async (params) => {
  const apiResponse = await cyvatarAxios.get(`/api/vcyso/grc/issues_trend/`, { params });
  const { data } = apiResponse;
  return data
}
//  Function to get one GRC issue
const getGRCIssue = async (code) => {
  if (!code) { return defaultGRCIssue; }
  const response = await cyvatarAxios.get(`/api/vcyso/grc/issue/${code}`);
  const { data } = response;
  return data;
};

//  Function to create GRC issue
const createIssue = async ({ issue }) => {
  const body = JSON.stringify(issue);
  const response = await cyvatarAxios.post('/api/vcyso/grc/issue', body);
  const { data } = response;
  return data;
};

//  Function to update GRC issue
const updateIssue = async ({ code, issue }) => {
  const body = JSON.stringify(issue);
  const response = await cyvatarAxios.put(`/api/vcyso/grc/issue/${code}`, body);
  const { data } = response;
  return data;
};

//  Function to upload attachment for issue
const uploadIssueAttachment = async ({ code, files = [] }) => {
  const response = await cyvatarAxios.put(`/api/vcyso/grc/issue/attachment/${code}`, { files });
  const { data } = response;
  return data;
};

//  Function to delete issue attachment
const deleteIssueAttachment = async (attachment_id) => {
  const response = await cyvatarAxios.delete('/api/vcyso/grc/issue/attachment/', { data: { attachment_id } });
  const { data } = response;
  return data;
};

const getAssignedTo = async ( company_id ) => {
  const apiResponse = await cyvatarAxios.get('/api/vcyso/grc/issues/assignee_distribution', { params: { company_id } });
  const { data } = apiResponse;
  return data;
};

const getArchivedTasks = async (params) => {
  const apiResponse = await cyvatarAxios.get('/api/vcyso/grc/archived_list', { params });
  const { data } = apiResponse;
  return data;
};

const restoreTasks = async (ids = []) => {
  const apiResponse = await cyvatarAxios.post('/api/vcyso/grc/restore_tasks', { ids });
  const { data } = apiResponse;
  return data;
};

// TODO: add invalidators for list when creating or updating issues
export const GrcIssueAPI = {
  handlers: {
    getGrcIssuesList,
    getGrcIssuesConfig,
    getGRCIssue,
    createIssue,
    updateIssue,
    getGrcTrendData,
    uploadIssueAttachment,
    deleteIssueAttachment,
    getAssignedTo,
    getArchivedTasks,
    restoreTasks,
  },
  keys: {
    getGrcIssuesList: GET_GRC_ISSUES_LIST,
    getGrcIssueConfig: GET_GRC_ISSUES_CONFIG,
    getGRCIssue: GET_GRC_ISSUE,
    getGrcTrendData: GET_GRC_TREND_DATA,
    getAssignedTo: GET_ASSIGNED_TO,
    getArchivedTasks: GET_ARCHIVED_TASKS,
  },
  invalidators: {
    createIssue: [GET_GRC_ISSUES_LIST, GET_GRC_ISSUE, GET_ROLLUP_DATA, GET_GRC_TREND_DATA],
    updateIssue: [GET_GRC_ISSUES_LIST, GET_GRC_ISSUE, GET_ROLLUP_DATA, GET_GRC_TREND_DATA],
    uploadIssueAttachment: [GET_GRC_ISSUE],
    deleteIssueAttachment: [GET_GRC_ISSUE],
    restoreTasks: [GET_GRC_ISSUES_LIST, GET_GRC_ISSUE, GET_ROLLUP_DATA, GET_GRC_TREND_DATA, GET_ARCHIVED_TASKS],
  }
};
