import React from 'react';
import { Button } from 'reactstrap';

const NowThenButton = (props) => {
    const {
        callback,
        showNowState
    } = props;
    return (
        <Button
            color="link"
            onClick={callback}
            className="btn-now-then ml-3"
        >   
            <div className="d-flex flex-column">
                <div className="active-state-label d-inline-block text-light">
                    {showNowState ? 'NOW':'THEN'}
                    <i className="mdi mdi-repeat" />
                </div>
                <div className="inactive-state-label d-inline-block text-light-gray text-right">
                    {showNowState ? 'THEN':'NOW'}
                </div>
            </div>
            
        </Button>
    )
}

export default NowThenButton