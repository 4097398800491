import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import BasicModal from './BasicModal';
import LoadingContent from './LoadingContent';
import API from '../../api';
import { setAlert } from '../../actions/alerts';
import { onMergeDevicesChange } from '../../actions/devices'
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useCustomMutation from '../../hooks/useCustomMutation';
import useCustomQuery from '../../hooks/useCustomQuery';
import useCanAbility from '../../hooks/useCanAbility';
import { MODIFY_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';

const MergeDevicesModal = (props) => {
  //  Initializing API
  const { DeviceAPI } = API;

  //  Watching redux store
  const devices = useSelector((state) => state?.devices?.mergeModalConfig?.devices);

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const ability = useCanAbility();

  // Componet variables
  const canModifyDevices = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.DEVICES.GENERAL);

  //  Function to close modal
  const closeModal = (success = false) => {
    dispatch(onMergeDevicesChange(false, [], success));
  };

  //  Getting devices from API
  const {
    data: devicesData,
    error: devicesError,
    loading: devicesLoading,
    isFetching: devicesFetching,
  } = useCustomQuery(
    [DeviceAPI.keys.getDevicesInfo, devices],
    ({ queryKey }) => DeviceAPI.handlers.getDevicesInfo(queryKey[1])
  );

  //  Watching devices GET error
  useEffect(() => {
    if (!devicesError) { return; }
    dispatch(setAlert('Error getting devices. ', 'danger'));
    closeModal();
  }, [devicesError]);

  //  Calling API
  const {
    data: mergeData,
    error: mergeError,
    isLoading: mergeLoading,
    mutate: callMergeDevices,
  } = useCustomMutation(
    (params) => DeviceAPI.handlers.mergeDevices(params),
    DeviceAPI.invalidators.mergeDevices,
  );

  //  Watching API response
  useEffect(() => {
    if (!mergeData) { return; }
    dispatch(setAlert('Success merging devices. ', 'success'));
    closeModal(true);
  }, [mergeData]);

  //  Watching API error
  useEffect(() => {
    if (!mergeError) { return; }
    dispatch(setAlert('Error merging devices. ', 'danger'));
  }, [mergeError]);

  //  Function to merge devices
  const mergeDevices = () => {
    const list = devicesData?.devices;
    if (!list || !Array.isArray(list)) { return; }
    const device_ids = list?.map(({ id }) => id);
    callMergeDevices({ device_ids });
  };

  //  Function to get mac address list
  const getMacAddressList = () => {
    const list = devicesData?.devices;
    if (!list || !Array.isArray(list)) { return ''; }
    const macAddressList = list?.reduce((addresses, currentDevice) => {
      if (!currentDevice?.mac_address) { return addresses; }
      return [...addresses, currentDevice?.mac_address];
    }, []);
    return macAddressList?.join(', ');
  };

  //  Rendering
  return (
    <BasicModal
      showModal={true}
      toggleModal={() => closeModal(false)}
      title="Merge Devices"
      subtitle={`Are you sure you want to merge the next devices: ${getMacAddressList()}`}
      customClassName='logout-modal'
    >
      <LoadingContent
        isLoading={devicesLoading || devicesFetching || mergeLoading}
      >
        <div className="row-container logout-modal">
          <Button
            color="light-gray left-modal-button"
            outline
            onClick={() => closeModal(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={mergeLoading || !canModifyDevices}
            className="btn btn-submit btn-light text-dark font-weight-bold delete-rule-button"
            onClick={() => mergeDevices()}
          >
            Merge
          </Button>
        </div>
      </LoadingContent>
    </BasicModal>
  );
};

export default MergeDevicesModal;
