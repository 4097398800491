import React from 'react';
import PartnerActionsButton from './PartnerActionsButton';
import PartnerActionsModal from './PartnerActionsModal';

const PartnerActionsButtonModal = ({ action, callPartnerAction, issue = null }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <PartnerActionsButton
        action={action}
        onClick={() => setOpen(true)}
      />
      <PartnerActionsModal
        action={action}
        callPartnerAction={callPartnerAction}
        closeModal={() => setOpen(false)}
        open={open}
        issue={issue}
        showConfirmCheckbox={true}
      />
    </>
  );
};

export default PartnerActionsButtonModal;
