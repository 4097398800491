import React from 'react'
import SecurityPolicies from '../../components/vcyso/SecurityPolicies';
import BusinessRisk from '../../components/vcyso/BusinessRisk';
import FullAssessmentView from '../../views/FullAssessmentView';
import NestedRoutesHandler from '../NestedRoutesHandler';
import ComplianceView from '../../views/ComplianceView';
import Devices from '../../views/Devices';
import { LIST_ACTION, PRIVILEGE_SOURCES, READ_ACTION } from '../../constants/privileges';
import DashboardHomeRoutes from './DashboardHomeRoute';
import GrcIssueManagerView from '../../views/grc/GrcIssueManagerView';
import IssueManager from '../../views/IssueManager';


const HomeRoutes = () => {
    // Home nested routes
    const nestedRoutes = [
        {
            component: FullAssessmentView,
            path: '/assessment',
            access: READ_ACTION,
            resource: PRIVILEGE_SOURCES.ASSESSMENT.GENERAL
        },
        {
            component: SecurityPolicies,
            path: '/policies',
            access: LIST_ACTION,
            resource: PRIVILEGE_SOURCES.POLICIES.GENERAL
        },
        {
            component: BusinessRisk,
            path: '/risk-manager',
            access: READ_ACTION,
            resource: PRIVILEGE_SOURCES.BUSINESS_RISK.GENERAL,
        },
        {
            component: ComplianceView,
            path: '/compliance',
            access: LIST_ACTION,
            resource: PRIVILEGE_SOURCES.ASSESSMENT.GENERAL
        },
        {
            component: DashboardHomeRoutes,
            path: '',
            access: READ_ACTION,
            resource: PRIVILEGE_SOURCES.HOME.GENERAL,
        },
        {
            path: "/devices",
            component: Devices,
            requireMFA: true,
            access: LIST_ACTION,
            resource: PRIVILEGE_SOURCES.DEVICES.GENERAL,
        },
        {
            component: IssueManager,
            path: '/issues',
            hasSubroutes: true,
            access: LIST_ACTION,
            resource: PRIVILEGE_SOURCES.ISSUES.GENERAL,
        },
        {
            component: GrcIssueManagerView,
            path: '/task-manager',
            hasSubroutes: true,
            access: LIST_ACTION,
            resource: PRIVILEGE_SOURCES.ISSUES.GENERAL,
        },
    ]

    return (
        <NestedRoutesHandler
            routes={nestedRoutes}
        />
    )
}

export default HomeRoutes;
