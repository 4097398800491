import React from 'react'
import { Button, Card, Col } from 'reactstrap';

const CustomAccordionItem = (props) => {

  const {
    label = '',
    name = '',
    component,
    index,
    onButtonCollapseClick = () => {},
    isExpanded = false,
    customCardClassName = ''
  } = props;

  return (
    <>
      <Col lg={12}>
          <Card className={`accordion-item-card ${customCardClassName}`}>
            <div className="accordion-item-card-header" 
              id={`accordion-item-card-${index}`}
            >
              <div className="accordion-item-header-title">{label}</div>
              <Button
                className="text-center accordion-btn"
                data-toggle="collapse"
                data-target={`#${name}`}
                aria-expanded={isExpanded}
                aria-controls={name}
                onClick={() => onButtonCollapseClick(index)}
              >
                <i className="bx bxs-chevron-down"></i>
              </Button>
            </div>
  
            <div
              id={name}
              className={`accordion-item-card-body collapse ${isExpanded ? 'show' : ''}`}
              aria-labelledby={`accordion-item-card-${index}`}
              data-parent="#accordionExample"
            >
              <div className="card-body">
                {component}
              </div>
            </div>
          </Card>
        </Col>
    </>
  )
}

export default CustomAccordionItem