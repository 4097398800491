import cyvatarAxios from '../axios';
import { GET_TAGS_LIST } from '../ApiKeys';

//params: filters: { issue_id, device_id, }, company_id
const getTagsList = async ({ filters = {}, company_id }) => {
  const response = await cyvatarAxios.get('/api/tags', { params: { filters, company_id } });
  const { data } = response;
  return data;
};

export const TagAPI = {
  handlers: { getTagsList },
  keys: { getTagsList: GET_TAGS_LIST },
  invalidators: {},
};
