import React from 'react'
import CustomRadioInput from '../layout/CustomRadioInput';

const IssueExploitable = (props) => {
  const {
    isExploitable,
    targetId,
  } = props;
  if(isExploitable === null || isExploitable === undefined) return null;
  return (
    <CustomRadioInput
      targetId={targetId}
      isSelected={isExploitable}
      className="exploitable-custom-radio"
      isDisabled={true}
    />
  )
}

export default IssueExploitable;
