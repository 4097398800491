import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Row, Col } from "reactstrap";

const MFAOption = (props) => {
  const {
    title,
    description,
    image,
    route,
    // callback
  } = props;

  return (
    <div className="option-container">
      <Row>
        <Col md={2} sm={3} xs={4}>
          <img  src={image} className="option-image" alt="mfa-option-icon"/>
        </Col>
        <Col md={7} sm={6} xs={7}>
          <div className="option-title" role="text" aria-label={`${title}\n${description}`}>
            {title}
            <p className="option-title-description" aria-hidden="true">{description}</p>
          </div>
        </Col>
        <Col md={3} sm={3} xs={3} className="d-flex align-items-center">
          <Link 
            className="btn btn-light option-action-link"
            to={route}
          >
            Get Started
          </Link>
        </Col>
      </Row>
    </div>
  );
}

const mapStatetoProps = state => {
  const { error, isAuthenticated } = state.auth;
  return { error, isAuthenticated };
}

export default withRouter(connect(mapStatetoProps)(MFAOption));

