import React from "react";
import BulbIcon from '../../../../assets/images/icons/bulb.svg'

const DashboardWidgetMessage = (props) => {
  const {
    message = ''
  } = props;

  return (
    <div className="dashboard-widget-msg-container d-flex">
      <img src={BulbIcon} alt="bulb-icon" className="bulb-icon" />
      <span className="dashboard-widget-msg">
        {message}
      </span>
    </div>
  );
};

export default DashboardWidgetMessage;
