import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Button, Col } from 'reactstrap';
import { buildBulkNotificationFromTemplate, buildNoteNotificationFromTemplate, buildSingleNotificationFromTemplate, bulkNotificationTemplate, noteNotificationTemplate, singleNotificationTemplate } from '../../constants/formTemplates';
import NotificationSettingsCheckboxCard from './NotificationSettingsCheckboxCard';
import { getCheckboxesState, getSelectedCheckboxesbyName } from '../../utils/profileHelpers';
import CustomCheckBox from '../layout/CustomCheckBox';
import CustomAccordion from '../layout/CustomAccordion';
import { notificationSettingsIds } from '../../constants/profile';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useCustomMutation from '../../hooks/useCustomMutation';
import { setAlert } from '../../actions/alerts';
import { UserAPI } from '../../api/services/UserAPI';
import { inAppNotificationsItems, inAppNotificationsTypes } from '../../constants/notifications';
import useCustomQuery from '../../hooks/useCustomQuery';
import { useSelector } from 'react-redux';

const InAppNotificationSettings = (props) => {

  const {
    title = '',
  } = props;

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();

  const userId = useSelector((state) => state.auth?.user?.id);

  // State to manage selection and deselection of checkboxes
  const [selectedCheckboxesbyId, setSelectedCheckboxesbyId] = useState([]);

  //  Calling API
  const {
    data: updateSettingsResponse,
    error: updateSettingsError,
    isLoading: updateSettingsLoading,
    mutate: updateSettings,
  } = useCustomMutation(
    (params) => UserAPI.handlers.putInAppNotificationSettings(params),
    UserAPI.invalidators.putInAppNotificationSettings
  );

  //  Calling API for compliance status
  const {
    data: settingsResponse,
    error: settingsError,
  } = useCustomQuery(
    [UserAPI.keys.getInAppNotificationSettings, {user_id: userId}],
    ({ queryKey }) => UserAPI.handlers.getInAppNotificationSettings(queryKey[1]),
  );

  // Needed to set the component state of the selected checkboxes when GET is called, sort is needed for later comparisons
  useEffect(() => {
    if (!settingsResponse) return;
    setSelectedCheckboxesbyId(getSelectedCheckboxesbyName(settingsResponse?.settings).sort());
  },[settingsResponse]);

  useEffect(() => {
    if (!settingsError) return;
    const message = settingsError?.errorMessage || 'Error getting settings';
    dispatch(setAlert(message, 'danger'));
  },[settingsError]);

  //  Watching API response
  useEffect(() => {
    if (!updateSettingsResponse) { return; }
    dispatch(setAlert(updateSettingsResponse?.message, 'success'));
  },[updateSettingsResponse]);

  //  Watching API error
  useEffect(() => {
    if (!updateSettingsError) { return; }
    const message = updateSettingsError?.errorMessage || 'Error updating settings';
    dispatch(setAlert(message, 'danger'));
  }, [updateSettingsError]);

  // Function used for getting the actual item/checkboxes of the cards based of templates
  const checkboxesCardToRender = ( type ) => {
    if (type === inAppNotificationsItems.ISSUE || type === inAppNotificationsItems.TASK) {
      const typeFlagLabel = type === 'TASK' ? 'Task' : 'Issue'
      return [
        buildSingleNotificationFromTemplate(
          singleNotificationTemplate, type, inAppNotificationsTypes.RESOLUTION, `${typeFlagLabel} resolution`
        ),
        buildSingleNotificationFromTemplate(
          singleNotificationTemplate, type, inAppNotificationsTypes.ASSIGNMENT, `${typeFlagLabel} assignment`
        ),
        buildSingleNotificationFromTemplate(
          singleNotificationTemplate, type, inAppNotificationsTypes.UPDATE, "Bulk edit"
        ),
        buildBulkNotificationFromTemplate(
          bulkNotificationTemplate, type, inAppNotificationsTypes.ASSIGNMENT, "Bulk assignment"
        ),
      ];
    } else if (type === inAppNotificationsItems.NOTE) {
      return [
        buildNoteNotificationFromTemplate(noteNotificationTemplate, inAppNotificationsTypes.RESOLUTION_NOTES, inAppNotificationsTypes.RESOLUTION_NOTES, 'Resolution notes'
        ),
        //buildNoteNotificationFromTemplate(noteNotificationTemplate, 'Notes_mentions', 'Notes_mentions', 'Mentions in notes'),
        buildNoteNotificationFromTemplate(noteNotificationTemplate, inAppNotificationsTypes.BULK_NOTES, inAppNotificationsTypes.BULK_NOTES, 'Bulk notes'
        ),
      ];
    }
  };

  // Template for the 3 cards of the accordion
  // Label: Card label, Name: used for id and data-target of the accordion card, Component: actual card content to render
  const accordionItemTemplates = [
    {
      label: "Notifications for Issues",
      name: "issues-card",
      component: (
        <NotificationSettingsCheckboxCard
          items={checkboxesCardToRender(inAppNotificationsItems.ISSUE)}
          setSelectedCheckboxes={(newState) => sortAndSetCheckboxesbyId(newState)}
          selectedCheckboxes={selectedCheckboxesbyId}
        />
      ),
    },
    {
      label: "Notifications for Tasks",
      name: "tasks-card",
      component: (
        <NotificationSettingsCheckboxCard
          items={checkboxesCardToRender(inAppNotificationsItems.TASK)}
          setSelectedCheckboxes={(newState) => sortAndSetCheckboxesbyId(newState)}
          selectedCheckboxes={selectedCheckboxesbyId}
        />
      ),
    },
    {
      label: "Notifications for Notes",
      name: "notes-card",
      component: (
        <NotificationSettingsCheckboxCard
          items={checkboxesCardToRender(inAppNotificationsItems.NOTE)}
          setSelectedCheckboxes={(newState) => sortAndSetCheckboxesbyId(newState)}
          selectedCheckboxes={selectedCheckboxesbyId}
        />
      ),
    },
  ];

  // Function for sorting the new state of selectedCheckboxes and then setting it
  // Sorting is needed for later comparison between the current and initial state, to see if there are any chances needed to save
  const sortAndSetCheckboxesbyId = ( newState ) => {
    setSelectedCheckboxesbyId(newState.sort());
  };

  // Function to select and deselect from the state, the main top two checkboxes over the accordion using their ID
  const setNewTopCheckboxesState = ( id ) => {
    const newState = getCheckboxesState(selectedCheckboxesbyId, {id});
    // Using the function to sort the state and then set it for later comparisons
    sortAndSetCheckboxesbyId(newState);
  }

  // Function to return the settings to their initial state, it's run when clicking the cancel button
  const setInitialState = () => {
    // Gets the initially selected checkboxes and then sorts them in alphabetical order for later comparisons
    sortAndSetCheckboxesbyId(getSelectedCheckboxesbyName(settingsResponse?.settings));
  };
  
  // Function for saving the changes in the settings, it's run when clicking the save button
  const onSaveSettings = () => {
    const payload = buildPayload();
    updateSettings(payload);
  };

  // Function to build the payload for saving the new notification settings
  const buildPayload = () => {
    const newSettingsArray = _.cloneDeep(settingsResponse?.settings);
    const newSettings = newSettingsArray.map((value, index) => {
      if (selectedCheckboxesbyId?.includes(value?.name) && value?.status === false) {
        value.status = true;
      } else if (!selectedCheckboxesbyId?.includes(value?.name) && value?.status === true) {
        value.status = false;
      }
      return value;
    });
    return {settings: newSettings};
  };

  // Main two checkboxes over the accordion for email and dashboard notifications
  const renderTopCheckboxes = () => {
    const emailId = notificationSettingsIds.GENERAL_EMAIL_NOTIFICATIONS;
    const dashboardId = notificationSettingsIds.GENERAL_DASHBOARD_NOTIFICATIONS;
    return (
      <>
        <CustomCheckBox
          className="profile-settings-checkbox"
          isSelected={selectedCheckboxesbyId?.includes(emailId)}
          targetId="email-notification-checkbox"
          callback={() => {
            setNewTopCheckboxesState(emailId);
          }}
          label="Send all notifications in an email notification"
          isDisabled={false}
        />
        <CustomCheckBox
          className="profile-settings-checkbox"
          isSelected={selectedCheckboxesbyId?.includes(dashboardId)}
          targetId="dashboard-notification-checkbox"
          callback={() => {
            setNewTopCheckboxesState(dashboardId);
          }}
          label="Send in dashboard notifications"
          isDisabled={false}
        />
      </>
    );
  };

  const renderFooterButtons = () => {
    const isSaveButtonDisabled = _.isEqual(selectedCheckboxesbyId, getSelectedCheckboxesbyName(settingsResponse?.settings).sort());
    return (
      <div className='notification-settings-footer-buttons'>
        <Button
          color="light-gray cancel-button"
          outline
          onClick={() => {setInitialState()}}
        >
          Cancel
        </Button>
        <Button
          className="save-button d-flex align-items-center"
          color="light"
          onClick={onSaveSettings}
          disabled={isSaveButtonDisabled || updateSettingsLoading}
        >
          <i className='bx bx-save'></i>
          Save changes
        </Button>
      </div>
    );
  }

  return (
    <div className="notification-settings-container">
      <div className='title-n-icon-container'>
        <div className='icon-container'>
          <i className='bx bx-bell'></i>
        </div>
        <div className="title">
          {title}
        </div>
      </div>
      <Col lg={6} md={8}>
        <div className='notification-settings-content'>
          <div className="profile-settings-checkbox-container">
            {renderTopCheckboxes()}
          </div>
          <CustomAccordion
            defaultOpen = {[]}
            items={accordionItemTemplates}
            customContainerClassName={'notification-settings-accordion'}
            customCardClassName={'notification-settings-accordion-card'}
          />
          {renderFooterButtons()}
        </div>
      </Col>
    </div>
  );
}

export default InAppNotificationSettings;