import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import InformationCard from '../layout/cards/InformationCard';
import { accomplishmentsFreemiumData } from '../../constants/pagesData';
import { trackEvent } from '../../utils/gaTracker';
import { getGeneralFormatDate } from '../../utils/dateFormatter';
import CopyEmbedCode from '../layout/share/CopyEmbedCode';
import amplitude from 'amplitude-js';

const CyvatarCertificateInformation = (props) => {
    const {
        isUnlocked,
        badge,
        companyName,
        eventSection = 'accomplishments',
        showEmbedCode,
        showCode = true,
    } = props;

    //  Getting router location
    const location = useLocation();

    const renderHeader = (title, subtitle) => {
        return (
            <div className="accomplishments-header">
                <div className="title text-capitalize">{title}</div>
                <div className="subtitle">{subtitle}</div>
            </div>
        )
    }

    const renderGetSecuredCard = () => {
        const { messages, buttonLabel, title} = accomplishmentsFreemiumData;
        return (
            <InformationCard
                title={title}
                content={messages}
                buttonLabel={buttonLabel}
                className="secured-card"
                eventSection={eventSection}
                eventName="get-secured"
            />
        )

    }

    const renderFreemiumState = () => {
        const title="Earn your Membership Certificate";
        const subtitle="Awarded as soon as you subscribe your first solution!"
        return(
            <>
                {renderHeader(title, subtitle)}
                {renderGetSecuredCard()}
            </>
        )
    }

    const renderPublicCertificateLink = () => {
        const label =`https://${window?.location?.host}/badge/${badge?.public_id}`;
        const pathname =`/badge/${badge?.public_id}`;
        return (
            <Link
                className="linkedin-url"
                to={pathname}
                onClick={handleCertificateLink}
                target="_blank"
                rel="noopener noreferrer"
            >
                <Button
                    color="light"
                    className="btn-copy-code"
                >
                    <i className='bx bxl-linkedin-square'/>
                    {showEmbedCode ? 'Share in Linkedin' : 'URL'}
                </Button>
            </Link>
        )
    }

    const handleCertificateLink = () => {
        if (location?.pathname === '/profile/company'){
            amplitude.getInstance().logEvent('My Account View - Company Profile - Share URL');
        }
    }

    const renderEmbedCode = () => {

        const pageUrl =`https://${window?.location?.host}/badge/${badge?.public_id}`;
        const code = `<a href="${pageUrl}" target="_blank" rel="noopener noreferrer" >  <img src="${`${process.env.REACT_APP_API_URL}/api/badge/image/${badge?.public_id}`}" alt="${badge?.badge_name} badge"> <a/>`;

        return (
            <>
                <div className="embed-code-section">
                    {showCode ? <div className="title mb-2">Embed this code on your website</div> : null}
                    <CopyEmbedCode
                        code={code}
                        showCode={showCode}
                    />
                </div>
                {/* TODO: add it when the step by step link is ready */}
                {/* <div className="instructions-message">
                    You can attach the certificate to your personal Linkedin profile following these {renderInstructionsLink()}
                </div> */}
            </>
        )
    }

    const renderInstructionsLink = () => {
        const eventName="step-by-step-instructions";
        return (
            <Button
                color="link"
                className="link-light px-0 pb-1 pt-0 underline-link"
                //TODO: update link
                onClick={() => {
                    window.open()
                    trackEvent(eventName, eventSection, eventName)
                }}
            >
                {` step by step instructions`}
            </Button>
        )
    }

    const renderCyvatarBadgeInformation = () => {
        const title= badge?.certificate_name;
        const subtitle=`Awarded ${getGeneralFormatDate(badge?.created_at)?.replace(',','')}`;
        return(
            <>
                <div className="cyvatar-badge-information">
                    <Row>
                        <Col className='section information-section'>
                            <div className="title text-capitalize">{`${companyName} ${title} Badge`}</div>
                            <div className="badge-info-item">ID: {badge?.public_id}</div>
                            <div className="subtitle">{subtitle}</div>
                        </Col>

                        {eventSection === 'accomplishments' ? <Col className='section share-section'>
                            <p className='text'>
                                Want your customers to know we got your back with the cybersecurity status of your company? copy the embed code to your website. Also, you can brag this with your LinkedIn contacts
                            </p>
                            <div className='buttons'>
                                {showEmbedCode && renderEmbedCode()}
                                {renderPublicCertificateLink()}
                            </div>
                        </Col> : null}
                    </Row>
                </div>
            </>
        )
    }

    return !isUnlocked ? renderFreemiumState() : renderCyvatarBadgeInformation()
}

export default CyvatarCertificateInformation;
