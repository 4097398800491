import { useCallback, useState, useEffect } from 'react';

const useDragOver = (params = {}) => {
  //  Parsing params
  const {
    onDrop,
    dropDependency = null, // Variable which should trigger a change in drop callbacl 
  } = params;

  //  State
  const [isDragOver, setIsDragOver] = useState(false);

  //  Hook variables
  let dragCounter = 0;
  let timeout = null;

  //  Function to clear hook state
  const clearState = () => {
    setIsDragOver(false);
    if (timeout) { clearTimeout(timeout); }
      dragCounter = 0;
  }

  //  On hook unmount
  useEffect(() => {
    return () => {
      if (timeout) { clearTimeout(timeout); }
      dragCounter = 0;
    }
  }, []);

  //  Function to handle drop
  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    if (onDrop && typeof (onDrop) === 'function') {
      onDrop(event);
    }
    clearState();
  }, [dragCounter, dropDependency]);

  //  Function to handle drag over
  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    if (timeout) { clearTimeout(timeout); }
  }, [dragCounter])

  //  Function to handle drag enter
  const handleDragEnter = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    //  Adding drag counter
    dragCounter++;

    //  Clearing timeout to avoid state to be cleared
    setIsDragOver(true);
    if (timeout) { clearTimeout(timeout); }
  }, [dragCounter]);

  //  Function to handle drag leave
  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    //  Substracting drag counter
    dragCounter--;
    if (dragCounter <= 0) {
      clearState();
    }

    //  Setting time out to clear state (edge case of counter not working)
    if (timeout) { clearTimeout(timeout); }
    timeout = setTimeout(() => {
      dragCounter = 0;
      setIsDragOver(false);
    }, 500);
  }, [dragCounter])

  //  Returnging assets
  return {
    isDragOver,
    clearState,
    handleDrop,
    handleDragOver,
    handleDragEnter,
    handleDragLeave,
  }
};

export default useDragOver;
