import React from 'react';
import { Badge } from 'reactstrap';
import criticalityInfo from '../../constants/criticality';

const CriticalityBadge = (props) => {
    const {
        severity,
        className,
    } = props;

    const getCriticalityClassName = () => {
        return `outline-${criticalityInfo[severity]?.className}`
    }
    const criticalityLabel = criticalityInfo[severity]?.label;
    return (
        <div
            className='d-inline-block'
            aria-label={`${criticalityLabel ? criticalityLabel : 'unknown'}, Criticality`}
        >
            <Badge
                color={getCriticalityClassName()}
                className={`criticality-badge ${className}`}
            >
                {criticalityLabel}
            </Badge>
        </div>
    )
}

export default CriticalityBadge;
