import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { isValidFunction } from "../utils/helpers";

const useModalDetailsParams = (params) => {

  const {
    queryKey,
    callback
  } = params;

  // Hook state
  const [showModal, setShowModal] = useState(false);
  const [code, setCode] = useState(null);

  // Hooks
  const location = useLocation();
  const history = useHistory();

  // Function to toggle modal
  const toggleModal = (show) => {
    setShowModal(show || !showModal)
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const code = queryParams.get(queryKey)
    if (queryParams.has('details') && code) {
      toggleModal(true);
      if(isValidFunction(callback)) {
        callback(code);
      }
      setCode(code);
    } else {
      if (showModal) {
        toggleModal(false)
      }
      setCode(null);
    }
  }, [location.search]);

  const removeModalParams = () => {
    //  Clearing url search
    const queryParams = new URLSearchParams(location.search)
    if (queryParams.has('details')) {
      queryParams.delete('details');
      queryParams.delete(queryKey);
      history.replace({
        search: queryParams.toString()
      })
    }
    setCode(null);
  }

  return {
    showModal,
    toggleModal,
    removeModalParams,
    code,
  }
}

export default useModalDetailsParams;
