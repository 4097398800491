import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import _ from 'lodash';
import BasicModal from '../layout/BasicModal';
import Avatar from '../layout/avatars/Avatar';
import ErrorMessage from '../../components/layout/ErrorMessage'
import CustomSpinner from '../../components/layout/CustomSpinner';
import IssueDeviceInfo from '../issue-detail/IssueDeviceInfo';
import IssueParentInfo from '../issue-detail/IssueParentInfo';
import IssueStatusDetails from '../issue-detail/IssueStatusDetails';
import IssuePartnerDetails from '../issue-detail/IssuePartnerDetails';
import CommentsSection from '../layout/comments/CommentsSection';
import Tabs from '../layout/tabs/Tabs';
import IssueHeader from './IssueHeader';
import makeLoadingState from '../../selectors/loadingSelector';
import makeErrorMessage from '../../selectors/errorsSelector';
import { setAlert } from "../../actions/alerts"
import { getIssue, getIssueModalOptions, onDeleteModalConfigUpdate, onReloadIssues, onReloadIssuesRollup } from '../../actions/issues';
import { successActionCreator } from '../../actions/action-creators/success';
import { errorActionCreator } from '../../actions/action-creators/errors';
import {
  CLEAR_SELECTED_ISSUE,
  RESET_MODAL_OPTIONS_USERS,
  UPDATE_REMEDIATION_PLAN_NOTE_SUCCESS,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_FAILURE,
  UPDATE_REMEDIATION_PLAN_NOTE_FAILURE,
} from '../../actions/types';
import { getApiErrorMessage, getSafeArray, mapSolutionPartnerToOption } from '../../utils/helpers';
import API from '../../api';
import { onShowIssueExpandedView } from '../../actions/layout';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useUpdateIssue from '../../hooks/useUpdateIssue';
import useCustomMutation from '../../hooks/useCustomMutation';
import useCanAbility from '../../hooks/useCanAbility';
import { MODIFY_ACTION, PRIVILEGE_SOURCES, READ_ACTION } from '../../constants/privileges';
import useUserType from '../../hooks/useUserType';
import { relabelCommentsPendingReview } from '../../utils/issuesHelpers';
import { ISSUE_COMMENT_SECTION_TYPE } from '../../constants/common';
import CriticalityBadge from '../dashboard/CriticalityBadge';
import IssueStatusBage from './IssueStatusBage';
import AddSubtaskButton from './AddSubtaskButton';
import IssueDetailsInformation from '../issue-detail/IssueDetailsInformation';
import IssueTags from '../issue-detail/IssueTags';
import IssueTextField from './IssueTextField';
import useIssueInfoByGroupParam from '../../hooks/useIssueInfoByGroupParam';
import issuesInfoSelector from '../../selectors/issuesInfoSelector';
import useIssueCriticalityClassName from '../../hooks/useIssueCriticalityClassName';
import IssueDetailsFromPartner from '../issue-detail/IssueDetailsFromPartner';

//  Component
const IssueDetails = (props) => {
  //  Parsing props
  const { toggleModal, expandInitially, updateIssue, comesFromSubtask = false } = props;

  //  Initializing APIs
  const { IssueAPI, PartnerAPI } = API;

  //  Component hooks
  const location = useLocation()
  const history = useHistory();
  const { dispatch } = useMemoizedDispatch();
  const ability = useCanAbility();
  const { mutate: resolveIssues } = useCustomMutation(
    (params) => PartnerAPI.handlers.resolveIssueOnPartner(params),
    PartnerAPI.invalidators.resolveIssueOnPartner
  );
  const { updateIssueOnDB } = useUpdateIssue({});
  const { updateIssueOnDB: resolveIssueOnDB } = useUpdateIssue({});
  const {
    data: planNoteResponse,
    error: planNoteError,
    mutate: savePlanNote
  } = useCustomMutation(
    (params) => IssueAPI.handlers.addRemediationPlanNote(params),
    IssueAPI.invalidators.addRemediationPlanNote
  );
  const userType = useUserType();
  const {
    shouldReload: shouldReloadIssue,
    deleteIssuesSuccess,
    issueType,
  } = useIssueInfoByGroupParam();

  //  Watching redux store
  const getLoadingState = makeLoadingState(['ISSUE_MODAL_OPTIONS', 'ISSUE']);
  const getErrorMessage = makeErrorMessage(['ISSUE_MODAL_OPTIONS', 'ISSUE']);
  const isLoading = useSelector((state) => getLoadingState(state));
  const errorMessage = useSelector((state) => getErrorMessage(state));
  const options = useSelector((state) => issuesInfoSelector(state, 'modalOptions', issueType));
  const issue = useSelector((state) => issuesInfoSelector(state, 'selectedIssue', issueType));
  const user = useSelector((state) => state.auth.user);
  const isMemberView = useSelector(state => state.impersonation?.isMemberView);

  //  Component refs
  const selectStatusRef = useRef();
  const commentRef = useRef();

  //  Component state
  const [shouldReload, setShouldReload] = useState(false);
  const [isResolvingIssue, setIsResolvingIssue] = useState(false);
  const [isSolvedIssue, setIsSolvedIssue] = useState(false);
  const [issueHasOpenSubtasks, setIssueHasOpenSubtasks] = useState(true);
  const [expandedMode, setExpandedMode] = useState(expandInitially);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showSubtask, setShowSubtask] = useState(false);

  const [formValues, setFormValues] = useState({
    status: null,
    solution: null,
    criticality: null,
    assignee: null
  });

  const {
    criticalityClassName,
    setCriticalityClassName,
  } = useIssueCriticalityClassName({
    criticalityId: issue?.criticality,
    issueType,
  })

  const canModifyIssues = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.ISSUES.GENERAL) || isMemberView;
  const canModifyNameDescription = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.ISSUES.NAME_DESCRIPTION_FIELDS);

  //  Function to toggle task modal
  const toggleTaskModal = (reloadIssue = false, fromCreateTask = true) => {
    if (fromCreateTask) {
      // Closing new subtask
      setShowCreateModal(!showCreateModal);
    } else {
      // Closing subtask details
      setShowSubtask(!showSubtask);
    }
    if (reloadIssue && issue?.code) { dispatch(getIssue(issue.code)); }
  };

  //  Function to render user option
  const renderUserOption = (user, profileImage = null) => {
    const { full_name } = user;
    return (
      <div className="d-inline-flex align-items-center">
        <Avatar user={user} profileImage={profileImage} customClassName="user-option" />
        {full_name}
      </div>
    );
  };

  //  Comoponent unmount
  useEffect(() => {
    return () => { dispatch(onShowIssueExpandedView(false)); }
  }, []);

  //  Watching delete issues success
  useEffect(() => {
    if (!deleteIssuesSuccess) { return; }
    dispatch(onDeleteModalConfigUpdate(false, [], false, issueType));
    dispatch(onReloadIssues(true, issueType));
    dispatch(onReloadIssuesRollup(true, issueType));
    closeModal();
  }, [deleteIssuesSuccess]);

  //  Watching add plan note success
  useEffect(() => {
    if (!planNoteResponse) { return; }
    dispatch(successActionCreator(UPDATE_REMEDIATION_PLAN_NOTE_SUCCESS, planNoteResponse));
    dispatch(successActionCreator(ADD_COMMENT_SUCCESS));
  }, [planNoteResponse]);

  //  Watching add plan note error
  useEffect(() => {
    if (!planNoteError) { return; }
    dispatch(successActionCreator(ADD_COMMENT_FAILURE, {}));
    const message = getApiErrorMessage(planNoteError);
    dispatch(setAlert(message, 'danger'));
    dispatch(errorActionCreator(UPDATE_REMEDIATION_PLAN_NOTE_FAILURE, { message }));
  }, [planNoteError]);

  //  Watching issue and options changes to update form values
  useEffect(() => {
    setFormValues({
      criticality: !_.isNil(issue.criticality) ? { label: _.find(options.criticalities, ['id', issue.criticality])?.label, value: issue.criticality } : null,
      status: !_.isNil(issue.status) ? { label: _.find(options.statuses, ['id', issue.status])?.label, value: issue.status } : null,
      solution: issue.solution ? { label: issue.solution.name, value: issue.solution.id } : issue.partner ? mapSolutionPartnerToOption(issue.partner) : null,
      assignee: issue.assignee ? { label: renderUserOption(issue.assignee), value: issue.assignee.id } : null
    });
  }, [issue, options])

  //  Watching should reload issue changes to get issue
  useEffect(() => {
    if (!shouldReloadIssue || !issue?.code) { return; }
    dispatch(getIssue(issue.code));
  }, [issue.code, shouldReloadIssue]);

  //  Watching issue status to change is solved flag
  useEffect(() => {
    setIsSolvedIssue((issue.status && issue.status === 4));
  }, [issue.status]);

  //  Watching issue subtasks to change has open subtasks flag
  useEffect(() => {
    const openSubtasks = !_.isEmpty(issue.subtasks) && _.some(issue.subtasks, (child) => child.status !== 4);
    setIssueHasOpenSubtasks(openSubtasks);
  }, [issue.subtasks]);

  //  Watching flag to add remediation note
  useEffect(() => {
    if (!isResolvingIssue) { return; }
    addRemediationNote();
  }, [isResolvingIssue]);

  // Update assignee options
  useEffect(() => {
    if (issue?.companyId && issue?.companyId != "") { dispatch(getIssueModalOptions(issue?.companyId, issueType, userType)); }
  }, [issue.companyId]);

  //  Watching expanded state changes
  useEffect(() => {
    dispatch(onShowIssueExpandedView(expandedMode));
  }, [expandedMode]);

  //  Function to reset state
  const resetState = () => {
    setFormValues({
      status: null,
      solution: null,
      criticality: null,
      assignee: null
    });
    setIsResolvingIssue(false);
  };

  //  Function to change current issue
  const updateIssuePath = (path) => {
    resetState();
    history.push(path);
  };

  // Function to handle open subtasks error
  const handelOpenSubtasksError = () => {
    commentRef.current && commentRef.current.cancelRemediationNote();
    dispatch(setAlert('One or more subtasks are still open, close them to continue', 'danger'));
  };

  //  Function to add remediation note
  const addRemediationNote = () => {
    commentRef.current && commentRef.current.postNewNote(true);
  };

  // Function to undo selection status
  const undoSelectionStatus = () => {
    setIsResolvingIssue(false);
    selectStatusRef.current && selectStatusRef.current.undoSelection();
  };

  // Function to resolve issue on partner
  const callPartnerResolveIssue = () => {
    // Preparing data for API call
    const companyId = issue.companyId;
    const partnerName = issue?.partner?.name;
    const issues = [issue.partner_unique_id];
    const body = {
      data: {
        company_id: companyId,
        partner_name: partnerName,
        issues
      }
    }
    resolveIssues(body);
  }

  // Function to close modal
  const closeModal = () => {
    //  Cleaning issue on redux state
    dispatch(successActionCreator(CLEAR_SELECTED_ISSUE));

    const canViewCompanyDropdown = ability.can(READ_ACTION, PRIVILEGE_SOURCES.ISSUES.COMPANY_DROPDOWN);
    //  Cleaning options on redux state
    if (canViewCompanyDropdown) { dispatch(successActionCreator(RESET_MODAL_OPTIONS_USERS)); }

    if (shouldReload) {
      dispatch(onReloadIssues(true, issueType));
      dispatch(onReloadIssuesRollup(true, issueType));
      setShouldReload(false);
    }

    //  Clearing url search
    const queryParams = new URLSearchParams(location.search)
    if (queryParams.has('details')) {
      queryParams.delete('details');
      queryParams.delete('selectedIssue');
      queryParams.delete('parent');
      history.replace({
        search: queryParams.toString()
      })
    }

    //  Closing modal
    toggleModal(false);
  };

  //  Function to update comment
  const updateComment = (value, activateRemediation = false) => {
    if (value) {
      const status_id = isResolvingIssue ? 4 : issue.status || 0;
      const status_name = getSafeArray(options.statuses).find((status) => status_id === status.id)?.label
      const payloadToUpdate = {
        comments: {
          list: [
            {
              updated_at: new Date(),
              user: {
                id: user.id,
                full_name: user.name,
                profile_image: user.profileImage,
              },
              status: {
                id: status_id,
                name: status_name,
              },
              content: value,
              is_remediation: isResolvingIssue,
            },
            ...issue.comments?.list,
          ]
        }
      };

      if (!isResolvingIssue) {
        updateIssueOnDB(issue.code, payloadToUpdate, 'comment', null);
      } else {
        const selectResolved = _.find(options.statuses, ['label', 'Resolved']);
        const issuePayload = { status: selectResolved.id, comments: payloadToUpdate.comments }
        resolveIssueOnDB(issue.code, issuePayload, null, 'Status updated');
        // Call issue resolution partner if subtask (dependent on the partner)
        if (issue.parent) {
          callPartnerResolveIssue();
        }

        setIsResolvingIssue(false);
      }
    }
    else {
      if (isResolvingIssue) undoSelectionStatus();
    }

    if (activateRemediation) {
      if (issueHasOpenSubtasks) {
        setIsResolvingIssue(false);
        handelOpenSubtasksError();
      }
      else {
        setIsResolvingIssue(true);
        const selectResolved = _.find(options.statuses, ['label', 'Resolved']);
        const value = { label: selectResolved.label, value: selectResolved.id };
        selectStatusRef.current && selectStatusRef.current.selectOption(value);
      }
    }
  };


  //  Function to add remediation plan note
  const addRemediationPlanNote = (value) => {
    if (!value) { return; }
    savePlanNote({ issue_id: issue.id, formValues: value });
  };

  //  Function to trigger expanded mode
  const toggleExpandedMode = (flag = false) => {
    setExpandedMode(flag);
  }

  //  Function to render try again button
  const tryAgainButton = (
    <button
      className="btn btn-light"
      onClick={() => dispatch(getIssueModalOptions(null, issueType, userType))}
    >
      Try again
    </button>
  );

  //  Function to render title
  const renderModalTitle = () => {
    return (
      <IssueHeader
        issue_id={issue?.id}
        parent={issue?.parent}
        code={issue?.code}
        companyName={issue?.companyName}
        updateIssuePath={updateIssuePath}
        closeModal={closeModal}
        expandedMode={expandedMode}
        setExpandedMode={toggleExpandedMode}
        issue_type={issueType}
        updateIssue={updateIssue}
        showIssuesLink={false}
        showNavigationButtons={!comesFromSubtask}
      />
    );
  };

  //  Function to render error message or loading state
  const renderErrorOrLoading = () => {
    //  Error state
    if (errorMessage) {
      return (
        <ErrorMessage
          text={errorMessage}
          button={errorMessage !== "Issue not found" ? tryAgainButton : null}
          customStyle={{ paddingTop: "5%" }}
        />
      );
    }
    //  Loading state
    if (isLoading) {
      return (
        <div>
          <CustomSpinner
            customStyle={{ minHeight: "303px" }}
          />
        </div>
      );
    }
    return null;
  };

  // Function to render Change log comment section
  const renderChangelog = () => {
    const commentsList = Object.values(issue.comments?.list || []);
    const isDisabled = isSolvedIssue || !canModifyIssues;
    const disabledMessage = isSolvedIssue ? 'Re-open to post new note…' : null;
    return (
      <CommentsSection
        innerRef={commentRef}
        comments={relabelCommentsPendingReview(commentsList, userType)}
        callback={updateComment}
        disabled={isDisabled}
        disabledMessage={disabledMessage}
        commentType={ISSUE_COMMENT_SECTION_TYPE}
      />
    )
  }

  const tabs = [
    {
      name: "Change log",
      content: renderChangelog(),
    }
  ];

  if (issue.remediationPlan || issue.remediationPlanNotes) {
    let remediationPlanNotes = [];
    if (issue.remediationPlan) {
      remediationPlanNotes.push({
        content: { "blocks": [{ "key": "86k4f", "data": {}, "text": issue.remediationPlan, "type": "unstyled", "depth": 0, "entityRanges": [], "inlineStyleRanges": [] }], "entityMap": {} },
        updated_at: issue.updatedAt,
        user: {
          full_name: 'System'
        }
      });
    }
    if (issue.remediationPlanNotes) {
      remediationPlanNotes = remediationPlanNotes.concat(issue.remediationPlanNotes || []);
    }

    tabs.push({
      name: "Remediation Plan",
      content: (
        <CommentsSection
          isRemediationSection={true}
          innerRef={commentRef}
          callback={addRemediationPlanNote}
          comments={remediationPlanNotes}
          disabled={isSolvedIssue || !canModifyIssues}
        />
      )
    });
  }

  // Render issue status and criticality badge
  const renderIssueDetailOverview = () => {
    return (
      <div className="issue-detail-overview">
        <CriticalityBadge
          severity={issue?.criticality}
          className="mr-3"
        />
        <IssueStatusBage
          status={issue?.status}
        />
      </div>
    )
  }

  // Render Issue name
  const renderIssueName = () => {
    return (
      <IssueTextField
        fieldName="name"
        text={issue?.name}
        showLabel={false}
        textClassName="issue-details-name"
        isEditable={!issue?.etl && !isMemberView && canModifyNameDescription}
        rows={1}
        maxLength={75}
        textAreaClassName="issue-name-text-area"
        isRequired={true}
        issueCode={issue?.code}
      />
    )
  }

  const renderDescription = () => {
    const isEditable = !issue?.etl && !isMemberView && canModifyNameDescription;
    const defaultText = `No description written for this issue${isEditable ? ', click on edit, to add a description.' : ''}`
    return (
      <IssueTextField
        fieldName="description"
        issueCode={issue?.code}
        label="Description"
        text={issue?.description}
        defaultText={defaultText}
        textClassName="issue-details-description"
        isEditable={isEditable}
        rows={5}
        textAreaClassName="issue-description-text-area"
      />
    )
  }

  // Render add subtask button
  const renderAddSubtaskButton = () => {
    // Only parents can have subtasks
    if (issue?.parent_issue_id) { return null; }
    return (
      <div>
        <div className="label-container"></div>
        <AddSubtaskButton
          isDisabled={!canModifyIssues}
          callback={toggleTaskModal}
          className="mb-3"
        />
      </div>
    )
  }

  // Render issue and device information
  const renderIssueAndDeviceInformation = () => {
    const hasPartner = issue?.partner?.id;
    const containerClassName = hasPartner ? 'd-flex justify-content-end' : 'd-flex';
    const issueDetailsClassName = `${hasPartner ? 'ml-3' : ''}`;
    return (
      <div className={containerClassName}>
        {hasPartner && (
          <div className="flex-grow-1 w-50">
            <IssueDeviceInfo issue={issue} devicePath={issue?.partner_fields?.path} />
          </div>
        )}
        <div className={`${issueDetailsClassName} w-50`}>
          <IssueDetailsInformation issue={issue} />
        </div>
      </div>
    )
  }

  // Render tags list
  const renderTagsList = () => {
    return (
      <IssueTags
        showAdd={false}
        showTitle={false}
        className="issue-header-tags-list"
        isLimited={true}
        issueType={issueType}
      />
    )
  }

  // Render top section with tags, status, criticality, and issue name
  const renderTopSection = () => {
    return (
      <div className="d-block d-lg-inline-flex w-100 columns-container" >
        <div className="left-column issue-left-column">
          {renderTagsList()}
          {renderIssueName()}
          {renderIssueDetailOverview()}
        </div>
        <div className="right-column" />
      </div>
    )
  }

  const renderIssueDetailsFromPartner = () => {
    return <IssueDetailsFromPartner issue={issue} />
  }

  //  Rendering
  return (
    <BasicModal
      headerClassName={`modal-header-status bg-${criticalityClassName || 'light-gray'}`}
      customHeaderContent={renderModalTitle()}
      showModal={true}
      toggleModal={() => closeModal()}
      customClassName={expandedMode ? 'issue-expanded-detail dashboard' : 'issue-manager-modal'}
      backdrop={!expandedMode}
      scrollable={true}
      wrapClassName={expandedMode ? 'no-scrollable-modal expanded-detail-container' : ''}
      zIndex={expandedMode ? 1002 : 1050}
    >
      {renderErrorOrLoading()}
      {!isLoading && !errorMessage && (
        <div className='issue-modal-body'>
          {renderTopSection()}
          <div className="d-block d-lg-inline-flex w-100 columns-container" >
            <div className="left-column issue-left-column">
              {renderIssueAndDeviceInformation()}
              {renderIssueDetailsFromPartner()}
              {renderDescription()}
              <IssueParentInfo
                issue={issue}
                formValues={formValues}
                resetState={resetState}
                options={options}
                addDisabled={!canModifyIssues}
                showCreateModal={showCreateModal}
                showSubtaskModal={showSubtask}
                toggleModal={toggleTaskModal}
              />
              <Tabs tabs={tabs} />
            </div>
            <div className="right-column">
              {renderAddSubtaskButton()}
              <IssueStatusDetails
                options={options}
                selectStatusRef={selectStatusRef}
                formValues={formValues}
                issue={issue}
                issueHasOpenSubtasks={issueHasOpenSubtasks}
                isSolvedIssue={isSolvedIssue}
                criticalityClassName={criticalityClassName}
                setCriticalityClassname={setCriticalityClassName}
                undoSelectionStatus={undoSelectionStatus}
                handelOpenSubtasksError={handelOpenSubtasksError}
                setIsResolvingIssue={setIsResolvingIssue}
                setShouldReload={setShouldReload}
                issueType={issueType}
                updateComment={updateComment}
              />
              <IssuePartnerDetails issue={issue} />
            </div>
          </div>
        </div>
      )}
    </BasicModal>
  );
};

export default IssueDetails;
