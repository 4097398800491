import React from 'react';
import { Button } from 'reactstrap';
import BasicModal from '../layout/BasicModal';

const SaveDraftModal = (props) => {
  //  Parsing props
  const {
    saveDraftCallback,
    exitCallback,
    toggleModal,
    showDraft = false,
    readyToPublish = false,
  } = props;

  //  Function to handle save
  const handleSave = () => {
    const publish = !showDraft;
    saveDraftCallback(publish);
  };

  //  Function to get button clase name
  const getButtonClassName = () => {
    let className = 'btn btn-submit text-dark font-weight-bold delete-rule-button save-draft-modal-button';
    if (!showDraft && !readyToPublish) { className += ' disabled'; }
    return className;
  };

  //  Rendering
  return (
    <BasicModal
      showModal={true}
      toggleModal={toggleModal}
      title="Unsaved changes"
      subtitle="You’re leaving this page without saving some changes. Do you want to save a Rule Draft?"
      customClassName="logout-modal"
    >
      <div className="row-container logout-modal">
        <Button
          color="light-gray left-modal-button"
          outline
          onClick={() => exitCallback()}
        >
          Leave without saving
        </Button>
        <Button
          className={getButtonClassName()}
          onClick={() => handleSave()}
          disabled={!showDraft && !readyToPublish}
        >
          {showDraft ? 'Save Draft' : 'Save and Publish'}
        </Button>
      </div>
    </BasicModal>
  );
};

export default SaveDraftModal;
