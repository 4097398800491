import React, { useEffect, useRef } from 'react';
import { Row, Modal } from "reactstrap";
import { successActionCreator } from '../../actions/action-creators/success';
import { SET_IS_OPEN_MODAL } from '../../actions/types';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';

const BasicModal = (props) => {
    const {
        showModal,
        toggleModal,
        title,
        subtitle,
        children,
        customClassName,
        header,
        headerClassName="",
        customHeaderContent,
        backdrop = true,
        wrapClassName = '',
        scrollable = true,
        zIndex = 1050,
        customFooter,
        ipAddress,
        size = "xl"
    } = props;
    const closeModalRef = useRef();

  const { dispatch } = useMemoizedDispatch();


    useEffect(() => {
        if (showModal) {
            document.body.classList.remove("no_padding");
        } else {
            document.body.classList.add("no_padding");
        }
        // let focusTimer = setTimeout(() => { if (closeModalRef.current && showModal) closeModalRef.current.focus() }, 1000)
        // return () => {
        //     clearTimeout(focusTimer);
        // }
    }, [showModal]);

    //  Function to render header
    const renderModalHeaderContent = () => {
        if (customHeaderContent) { return customHeaderContent; }
        return (
            <div className="header-content">
                <div className="header-text d-inline-flex">
                    {header} {ipAddress}
                </div>
                <button
                    type="button"
                    onClick={() => { toggleModal() }}
                    className="close focus-visible-light"
                    data-dismiss="modal"
                    //ref={closeModalRef}
                    aria-labelledby="dialog-title dialog-close-btn"
                    aria-label="Inside dialog, close this dialog"
                    role="button"
                    aria-hidden="false"
                    tabIndex="0"
                    >
                    <span aria-hidden="true">&times;</span>
                    <p id="dialog-close-btn" className="d-none">close</p>
                </button>
            </div>
        );
    };

    //  Rendering
    return (
        <Modal
            isOpen={showModal}
            toggle={() => { toggleModal() }}
            centered={true}
            size={size}
            className={`basic-modal ${customClassName}`}
            backdrop={backdrop}
            wrapClassName={wrapClassName}
            scrollable={scrollable}
            zIndex={zIndex}
            role="dialog" aria-modal="true"
            onOpened={()=>dispatch(successActionCreator(SET_IS_OPEN_MODAL,true))}
            onExit={()=>dispatch(successActionCreator(SET_IS_OPEN_MODAL,false))}
        >
            <div aria-label={title ? title : ''} tabIndex="-1">
                <div className={`modal-header ${headerClassName}`}>
                    {renderModalHeaderContent()}
                </div>
                <div className="content">
                    <Row className="basic-content-row justify-content-center h-100 pt-0 no-gutters pb-4">
                        {title && <div id="dialog-title" className="title">{title}</div>}
                        {subtitle && <div className="subtitle">{subtitle}</div>}
                        <div className="w-100">{children}</div>
                    </Row>
                </div>
                {customFooter}
            </div>
        </Modal>

    );
}

export default BasicModal;

