import React, { useEffect, useState, createRef } from 'react';
import BasicModal from "../layout/BasicModal";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { FormGroup, Button } from 'reactstrap';
import CustomCheckBox from '../layout/CustomCheckBox';
import {
  setPartnerSuccess,
  setPartnerError,
  enableFurtimSuccess,
  enableFurtimError,
} from "../../actions/partners";
import _ from 'lodash';
import { CSVReader } from 'react-papaparse';
import { setAlert } from "../../actions/alerts"
import CSVSchemaValidator from '../../config/CSVSchemaValidator';
import Spinner from 'reactstrap/lib/Spinner';
import API from '../../api';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useCustomMutation from '../../hooks/useCustomMutation';
import { onImpersonationSuccess } from '../../actions/impersonation';
import { alertTypes } from '../../constants/alerts';

const ConfigPartner = (props) => {
  const {
    showModal,
    toggleModal,
    modalConfig,
    showSuccessMessage,
  } = props;

  //  Initializing APIs
  const { PartnerAPI, UserAPI } = API;

  //  Component state
  const buttonRef = createRef();
  const formRef = createRef();
  const [invalidMessages, setInvalidMessages] = useState([]);
  const [clientSlug, setClientSlug] = useState(null);
  const [confirmClientSlug, setConfirmClientSlug] = useState(false);
  const [autoSetup, setAutoSetup] = useState(false);
  const csvSchemaValidator = new CSVSchemaValidator();

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();

  //  Calling API to set partner
  const {
    data: setPartnerResponse,
    error: partnerError,
    isLoading: setPartnerLoading,
    mutate: callSetPartner,
  } = useCustomMutation(
    (params) => PartnerAPI.handlers.setPartner(params),
    PartnerAPI.invalidators.setPartner
  );

  //  Calling API to enable furtim
  const {
    data: furtimResponse,
    error: furtimError,
    isLoading: furtimLoading,
    mutate: callEnableFurtim
  } = useCustomMutation(
    (params) => PartnerAPI.handlers.enableFurtim(params),
    PartnerAPI.invalidators.enableFurtim
  );

  //  Post to obtain company with updated packages
  const {
      data: impersonateResponse,
      error:impersonateError,
      mutate: impersonateCompany,
  } = useCustomMutation(
      (params) => UserAPI.handlers.impersonateCompany(params),
      UserAPI.invalidators.impersonateCompany,
  );

  //  Watching API response
  useEffect(() => {
    if (!setPartnerResponse) { return; }
    dispatch(setPartnerSuccess(setPartnerResponse));
    // Impersonate company again to update the company's solutions
    impersonateCompany(modalConfig?.company_id)
  }, [setPartnerResponse]);

  //  Watching API error
  useEffect(() => {
    if (!partnerError) { return; }
    dispatch(setAlert("Oops! We didn’t catch that. Try again", 'danger'));
    dispatch(setPartnerError(partnerError));
  }, [partnerError]);

  //  Watching API response
  useEffect(() => {
    if (!furtimResponse) { return; }
    dispatch(enableFurtimSuccess(furtimResponse));
  }, [furtimResponse]);

  //  Watching API error
  useEffect(() => {
    if (!furtimError) { return; }
    dispatch(setAlert("Oops! We didn’t catch that. Try again", 'danger'));
    dispatch(enableFurtimError(furtimError));
  }, [furtimError])

  //  Watching impersonate response
  useEffect(() => {
    if (!impersonateResponse) { return; }
    dispatch(onImpersonationSuccess(impersonateResponse));
}, [impersonateResponse]);

  //  Watching impersonate error
  useEffect(() => {
    if (!impersonateError) { return; }
    dispatch(setAlert('Error impersonating company, please select the company again', alertTypes.error));
    dispatch(onImpersonationSuccess(null));
  }, [impersonateError]);

  useEffect(() => {
    if (showModal === false) {
      handleRemoveFile();
      setInvalidMessages([]);
      setClientSlug(null);
      setConfirmClientSlug(false);
    }
  }, [showModal]);

  const handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e)
    }
  }

  const handleOnFileLoad = (data, file) => {
    const slug = data[0].data.client_slug;
    setClientSlug(slug);
    csvSchemaValidator.updatePartnerConfig(modalConfig?.partner_name);
    const result = csvSchemaValidator.validate(data);
    setInvalidMessages(result);
  }

  const handleOnError = (err, file, inputElem, reason) => {
    dispatch(setAlert(reason, 'danger'));
  }

  const handleRemoveFile = (e) => {
    if (buttonRef.current) {
      buttonRef.current.removeFile(e)
    }
  }

  const handleAutoSetup = () => {
    if (formRef.current) formRef.current.reset();
    setAutoSetup(!autoSetup);
  }

  const renderFileInput = (field_name, index) => {
    return (
      <div key={`${field_name}-${index}`}>
        <CSVReader
          ref={buttonRef}
          className='csv-reader'
          onFileLoad={handleOnFileLoad}
          onError={handleOnError}
          noClick
          noDrag
          config={
            {
              delimiter: ",",
              header: true,
              dynamicTyping: true,
            }
          }
        >
          {({ file }) => (
            <div className="row file-input">
              <div className="col-7 d-flex flex-column">
                <label className="file-input-label" htmlFor="customFile">CSV file</label>
                <label id="customFileLabel" className="file-input-name">{file?.name || 'Upload CSV file…'}</label>
              </div>
              <div className="col">
                <label
                  type='button'
                  onClick={handleOpenDialog}
                  className='file-input-button'
                >
                  Browse file
                </label>
              </div>
            </div>
          )}
        </CSVReader>
        <div className="csv-errors w-100">
          {invalidMessages.map((message, index) => (
            <div key={`error-${index}`}>{message}</div>
          ))}
        </div>
        {(clientSlug && invalidMessages.length === 0) ? (
          <div className="custom-control custom-checkbox mb-3">
            <input id="confirmClientSlug" type="checkbox" className="custom-control-input"
              onChange={() => { setConfirmClientSlug(!confirmClientSlug) }}
              checked={confirmClientSlug} />
            <label htmlFor="confirmClientSlug" className="custom-control-label" >
              I'm sure the CSV file belongs to "{clientSlug}"
            </label>
          </div>
        ) : null}
      </div>
    );
  }

  const renderAutomaticSetup = (field_name, index) => {
    return (
      <div className='auto-setup-section' key={`${field_name}-${index}`}>
        <CustomCheckBox
          isSelected={!autoSetup}
          className="partner-checkbox"
          targetId="partner-manual-setup"
          callback={handleAutoSetup}
          label="Manual Setup"
        />
        <CustomCheckBox
          isSelected={autoSetup}
          className="partner-checkbox"
          targetId="partner-automatic-setup"
          callback={handleAutoSetup}
          label="Auto Setup"
        />
      </div>
    );
  }

  const renderInputs = () => {
    const currentConfig = modalConfig?.currentConfig?.config_values;
    const fields = modalConfig.fields;
    return Object.keys(fields).map((field_name, index) => {
      const inputField = fields[field_name];
      if (inputField.type === "file") { return (renderFileInput(field_name, index)) }
      if (inputField.type === "automatic_setup") { return currentConfig ? <></> : renderAutomaticSetup(field_name, index) };

      return (
        <AvField
          className={`${autoSetup ? 'input-disabled':'light-input-dark-bg'}`}
          key={`${field_name}-${index}`}
          name={field_name}
          label={inputField.label}
          type="text"
          errorMessage={`Enter ${inputField.label}`}
          validate={{ required: { value: !autoSetup } }}
          defaultValue={currentConfig ? currentConfig[field_name] : inputField.default ? inputField.default : ''}
          disabled={autoSetup}
        />
      )
    });
  }

  const handleValidSubmit = (events, values) => {
    let file;
    if (invalidMessages.length > 0) {
      dispatch(setAlert('One or more fields have an error. Please check and try again', 'danger'));
      return false;
    }

    if (buttonRef.current) {
      file = buttonRef.current.state.file;
    }

    if (file) {
      if (clientSlug && !confirmClientSlug) {
        dispatch(setAlert('Please confirm the client_slug match the company name', 'danger'));
        return false;
      }

      let formData = new FormData();
      formData.append('file', file);
      formData.append('company_id', modalConfig.company_id);
      formData.append('partner_id', modalConfig.partner_id);
      //  Enabling furtim
      callEnableFurtim(formData);
    } else if (!_.isEmpty(values) || _.isEmpty(modalConfig.fields)) {
      const payload = {
        'company_id': modalConfig.company_id,
        'partner_id': modalConfig.partner_id,
        'config_values': {
          ...values,
          auto_setup: autoSetup
        },
      }
      callSetPartner(payload);
    }
  }

  return (
    <BasicModal
      headerClassName={`modal-header-partner`}
      showModal={showModal}
      toggleModal={toggleModal}
      customClassName="config-partner-modal"
    >
      <div className="title">{`Add ${modalConfig.partner_name || 'Partner'} for ${modalConfig.company_name || 'Company'}`}</div>
      {showSuccessMessage ? (
        <div className="success-message">
          <div className="title mx-auto"> Success!</div>
          <div className="message mx-auto">
            Setting up might take a few minutes
          </div>
          <div className="text-center">
            <Spinner className="success-message-spinner" />
          </div>
          <div className="btn-container text-center">
            <button
              className="btn btn-light text-dark"
              onClick={() => toggleModal()}
              style={{ minWidth: "115px" }}
            >
              OK
            </button>
          </div>
        </div>
      ) : (
        <AvForm onValidSubmit={(e, v) => { handleValidSubmit(e, v) }} ref={formRef}>
          <FormGroup>
            {modalConfig?.fields && renderInputs()}
            <div className="d-flex justify-content-center button-container">
              <Button type="submit" color="primary" className="mr-1" disabled={setPartnerLoading || furtimLoading}>
                {setPartnerLoading || furtimLoading ? 'Processing' : `Enable ${modalConfig?.partner_name || 'Partner'}`}
              </Button>
            </div>
          </FormGroup>
        </AvForm>
      )}

    </BasicModal>
  );
}

export default ConfigPartner;
