import { AuthAPI } from './services/AuthAPI';
import { SettingsAPI } from './services/SettingsAPI';
import { UserAPI } from './services/UserAPI';
import { CompanyAPI } from './services/CompanyAPI';
import { BadgeAPI } from './services/BadgeAPI';
import { DashboardAPI } from './services/DashboardAPI';
import { PartnerAPI } from './services/PartnerAPI';
import { AssessmentAPI } from './services/AssessmentAPI';
import { IssueAPI } from './services/IssueAPI';
import { DeviceAPI } from './services/DeviceAPI';
import { VcysoAPI } from './services/VcysoAPI';
import { SolutionAPI } from './services/SolutionAPI';
import { TagAPI } from './services/TagAPI';
import { RuleAPI } from './services/RuleAPI';
import { PlanAPI } from './services/PlanAPI';
import { GrcIssueAPI } from './services/GrcIssueAPI';
import { GroupAPI } from './services/GroupAPI';
import { NotificationAPI } from './services/NotificationAPI';

export default {
  AuthAPI,
  SettingsAPI,
  UserAPI,
  CompanyAPI,
  BadgeAPI,
  DashboardAPI,
  PartnerAPI,
  AssessmentAPI,
  IssueAPI,
  DeviceAPI,
  VcysoAPI,
  SolutionAPI,
  TagAPI,
  RuleAPI,
  PlanAPI,
  GrcIssueAPI,
  GroupAPI,
  NotificationAPI
};
