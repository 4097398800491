import { useEffect } from "react";
import { useState } from "react";
import { areAllElementsSelected } from "../utils/bulkActionsHelper";
import { getSafeArray } from "../utils/helpers";

const useBulkSelection = (params) => {
  const {
    elements,
    filters,
    sortField,
    searchTerm,
  } = params;


  // State
  const [selectedIds, setSelectedIds] = useState([]);
  const [ids, setIds] = useState([]);
  const [allSelected, setAllSelected] = useState(false)

  // Watch ids and selected id changes
  useEffect(() => {
    const all = areAllElementsSelected(ids, selectedIds)
    setAllSelected(all)
  }, [selectedIds, ids])

  // Watch changes in connections response to update array of connectionIds
  useEffect(() => {
    if (!elements || !Array.isArray(elements)) return;
    const ids = elements.map((element) => element?.id);
    setIds(ids);

    // Update selected ids
    const selectedElementIds = getSafeArray(selectedIds).reduce((selectedArray, selectedId) => {
      const matches = ids.find((id) => selectedId === id );
      if(matches) {
        return [
          ...selectedArray,
          selectedId
        ]
      } else {
        return selectedArray
      }
    }, [])
    setSelectedIds(selectedElementIds)
  }, [elements]);

  // Watch filter changes
  useEffect(() => {
    // Reset selected ids after any filter, search term or sort change
    setSelectedIds([])
  }, [filters, sortField, searchTerm])

  return {
    ids,
    setIds,
    selectedIds,
    setSelectedIds,
    allSelected
  }
}

export default useBulkSelection;
