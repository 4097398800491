import useShowModal from './useShowModal'
import { UPDATE_DOMAIN_COUNT } from '../actions/types';

const useDomainActions = (params) => {
  //  Parsing params
  const {
    company_id,
    startTime,
    isMaster = false,
  } = params;

  // Hooks
  const {
    showModal: showScheduleModal,
    toggleModal: toggleScheduleModal,
  } = useShowModal();
  const {
    showModal: showDeleteDomainModal,
    toggleModal: toggleDeleteDomainModal,
  } = useShowModal();

  //  Hook variables
  const actions = [
    {
      iconClassName: 'bx bx-time',
      callback: toggleScheduleModal,
      tooltipText: 'Schedule scans',
      disabled: startTime,
      ifDisabledTooltipText: 'Scan already created'
    },
    {
      iconClassName: 'bx bx-trash',
      callback: toggleDeleteDomainModal,
      tooltipText: 'Delete domains',
      disabled: isMaster,
      ifDisabledTooltipText: 'Main domain cannot be deleted'
    },
  ];

  const updateDomainCount = (payload) => {
    return {
      type: UPDATE_DOMAIN_COUNT,
      payload: { domains_count: payload }
    }
  };

  //  Exporting hooks assets
  return {
    actions,
    showScheduleModal,
    showDeleteDomainModal,
    toggleDeleteDomainModal,
    toggleScheduleModal,
    updateDomainCount,
  }
};

export default useDomainActions;
