import React, { useState, useEffect } from "react";
import DashboardRow from '../../components/app-v4/common/dashboard/DashboardRow';
import DashboardCard from '../../components/app-v4/common/dashboard/DashboardCard';
import ViewWrapper from '../../components/layout/ViewWrapper';
import MainScrollableContainer from '../../components/layout/MainScrollableContainer';
import { Button, Col } from 'reactstrap';
import AssessmentStatusCard from '../../components/home/AssessmentStatusCard';
import API from '../../api';
import companyIdSelector from '../../selectors/companyIdSelector';
import { useSelector } from 'react-redux';
import useCustomQuery from '../../hooks/useCustomQuery';
import errorMessages from '../../constants/errorMessages';
import ScoreDistribution from '../../components/dashboard/ScoreDistribution';
import AdditionalWidget from '../../components/app-v4/my-cyvatar/dashboard/AdditionalWidget'
import AddMembersWidget from '../../components/app-v4/my-cyvatar/dashboard/AddMembersWidget'
import { additionalWidgetsIds } from '../../constants/dashboard';
import FaceIcon from '../../assets/images/icons/face.svg';
import PolicyLibraryIcon from '../../assets/images/icons/policy-library.svg';
import TaskManagerWidgetGroup from "../../components/app-v4/my-cyvatar/dashboard/TaskManagerWidgetGroup";
import GradingScale from "../../components/dashboard/compliance/GradingScale";
import RiskManagerSection from "../../components/app-v4/my-cyvatar/dashboard/risk-manager/RiskManagerSection";
import useMemoizedDispatch from "../../hooks/useMemoizedDispatch";
import { setBackLink } from "../../actions/navigation";
import FreeScanRequest from "../../components/app-v4/my-cyvatar/dashboard/FreeScanRequest";
import IssueManagerRow from "../../components/app-v4/cyvatar-pro/dashboard/IssueManagerRow";
import ScanConfirmationModal from "../../components/app-v4/common/dashboard/ScanConfirmationModal";
import useShowModal from "../../hooks/useShowModal";
import ScheduleScanModal from "../../components/profile/domains/ScheduleScanModal";
import RiskReconRow from "../../components/dashboard/risk-recon/RiskReconRow";

const MyCyvatarHomeView = () => {

    //  Initializing APIs
    const { DashboardAPI } = API;

    // View state
    const [showGradingScale, setShowGradingScale] = useState(false);

    const {
        showModal: showScheduleScanModal,
        toggleModal: toggleScheduleScanModal
    } = useShowModal();

    // Getting needed info from redux store
    const companyId = useSelector(state => companyIdSelector(state));

    // Component hooks
    const { dispatch } = useMemoizedDispatch();

    //  Calling API for compliance status
    const {
        data: complianceResponse,
        error: complianceError,
        isLoading: complianceLoading,
        refetch: refetchComplianceStatus,
    } = useCustomQuery(
        [DashboardAPI.keys.getComplianceStatus, { company_id: companyId }],
        ({ queryKey }) => DashboardAPI.handlers.getComplianceStatus(queryKey[1]),
    );

    // Hide go back link
    useEffect(() => {
        dispatch(setBackLink(null, false, null, false));
    }, []);

    const retryRequest = () => {
        refetchComplianceStatus();
    };

    const getErrorMessage = () => {
        const error = complianceError?.data?.message || errorMessages.defaultGet;
        return complianceError ? error : null
    }

    //  Function to toggle grading scalemodal
    const togGradingScale = () => {
        setShowGradingScale(!showGradingScale);
    };

    const renderFreeScanModal = () => {
        if (!showScheduleScanModal) return null;
        return (
          <ScheduleScanModal
            domainPublicIds={[]}
            showModal={showScheduleScanModal}
            toggleModal={toggleScheduleScanModal}
            companyId={companyId}
            isMasterScan={true}
          />
        );
      };

    //  Function to render Assessment's Grading scale modal
    const renderGradingScaleModal = () => {
        if (!showGradingScale) { return null; }
        return (
            <GradingScale
                showModal={showGradingScale}
                togModal={togGradingScale}
                grades={complianceResponse?.assessment?.config?.grades}
                isLoading={complianceLoading}
                errorMessage={getErrorMessage()}
                retryRequest={retryRequest}
            />
        );
    };

    const renderScoreDistributionTooltipContent = () => {
        return (
          <div className="score-distribution-help-info">
            <div className="title">How to use this widget</div>
            <p className="body">
              Check how our solutions solve your cybersecurity needs by clicking
              on the solutions name below, also, you can hover over each Control
              bar to view the information about the control and its appropriate
              Solution. If you are not sure how Cyvatar can improve your
              cybersecurity, schedule a demo.
            </p>
          </div>
        );
    }

    // Render assessment section
    const renderAssessment = () => {
        return (
            <DashboardRow
                title="Self Assessment"
                dashboardBodyClassName="px-0 pb-4"
            >
            <Col lg={4} className="px-2 pb-2 pb-lg-0">

                <AssessmentStatusCard
                    isLoading={complianceLoading}
                    errorMessage={getErrorMessage()}
                    retryRequest={retryRequest}
                    assessmentData={complianceResponse?.assessment}
                    togGradingScale={togGradingScale}
                />

            </Col>
            <Col lg={8} className="px-2 pb-2 pb-lg-0">
                <DashboardCard
                    title="Score Distribution"
                    customTitleClassName='score-distribution-card-title'
                    tooltipCustomClassName='score-distribution-help-icon-tooltip'
                    showInformationIconTooltip={true}
                    tooltipTargetId={'score-distribution-mycyvatar-dashboard'}
                    iconTooltipContent={renderScoreDistributionTooltipContent()}
                >
                <ScoreDistribution
                    assessmentData={complianceResponse?.assessment}
                    assessmentLoading={complianceLoading}
                    assessmentErrorMessage={getErrorMessage()}
                    assessmentRetryRequest={retryRequest}
                    justChart
                    showNowThen={false}
                    height={250}
                />
                </DashboardCard>
            </Col>

            </DashboardRow>
        )

    }

    // Render task manager section
    const renderAdditionalWidgetsSection = () => {
        return (
            <DashboardRow>
                <FreeScanRequest 
                    toggleModal={toggleScheduleScanModal}
                />
                <AddMembersWidget
                    className = 'additional-widget-container'
                    message = {'Add your colleagues and start collaborating!'}
                    buttonMessage = {'+ Add member'}
                    widgetID = {additionalWidgetsIds.ADD_TEAM_MEMBER}
                    icon = {FaceIcon}
                    companyId = {companyId}
                />
                <AdditionalWidget
                    className = 'additional-widget-container'
                    message = {'Need a security policy? Search our library here.'}
                    buttonMessage = {'View policies'}
                    widgetID = {additionalWidgetsIds.POLICIES_LIBRARY}
                    icon = {PolicyLibraryIcon}
                    linkPath='/dashboard/policies'
                />
                {renderFreeScanModal()}
            </DashboardRow>
        )

    }


    return (
        <ViewWrapper
            layoutType="vertical"
            viewName="My Cyvatar Dashboard"
            hasImpersonationMode={true}
        >
            {renderGradingScaleModal()}
            <MainScrollableContainer
                additionalClassName="contained-view-width"
            >
                <RiskReconRow />
                {renderAssessment()}
                <IssueManagerRow toggleModal={toggleScheduleScanModal}/>
                <TaskManagerWidgetGroup/>
                <RiskManagerSection />
                {renderAdditionalWidgetsSection()}
            </MainScrollableContainer>
        </ViewWrapper>
    )
}

export default MyCyvatarHomeView
