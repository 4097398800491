import React from 'react';
import { setAlert } from '../actions/alerts';
import { successActionCreator } from "../actions/action-creators/success";
import { ON_PARTNER_MANUAL_ACTION_FINISH } from "../actions/types";
import API from '../api';
import useMemoizedDispatch from '../hooks/useMemoizedDispatch';
import useCustomMutation from '../hooks/useCustomMutation';
import { MANUAL_ACTIONS_TARGETS } from '../constants/common';

const useManualActions = ({ issue = null, group = null }) => {
	const { PartnerAPI } = API;
  const target = group ? MANUAL_ACTIONS_TARGETS.GROUP : MANUAL_ACTIONS_TARGETS.ISSUE;

	const {
		data: actionResponse,
		error: actionError,
		mutate: sendPartnerAction,
	} = useCustomMutation(
		(params) => PartnerAPI.handlers.callPartnerAction(params),
		PartnerAPI.invalidators.callPartnerAction
	);
	const { dispatch } = useMemoizedDispatch();

	// Action execution response
	React.useEffect(() => {
		if (!actionResponse) { return; }
		dispatch(setAlert("Action executed on partner.", "success"));
		dispatch(successActionCreator(ON_PARTNER_MANUAL_ACTION_FINISH, true));
	}, [actionResponse, dispatch]);

	// Action execution error
	React.useEffect(() => {
		if (!actionError) { return; }
		dispatch(setAlert("Error executing action on partner.", "danger"));
		dispatch(successActionCreator(ON_PARTNER_MANUAL_ACTION_FINISH, true));
	}, [actionError, dispatch]);

	const callPartnerAction = (params) => {
		// Preparing data for API call
		const data = { target: target };

		const key = params.reference_field || 'id';
		delete params.reference_field;

		const targetRow = issue || group;
		const rows = [targetRow[key]];

		if (group) {
			data.partner_id = group.partner_id;
			params.groups = rows;
		} else {
			data.partner_id = issue.partner.id;
			data.company_id = issue.companyId || issue.company_id;
			params.issues = rows;
		}

		data.params = params;
		sendPartnerAction({ data });
	};


	return {
		actionResponse,
		actionError,
		callPartnerAction
	}
};

export default useManualActions;
