import React from 'react';
import { Link } from 'react-router-dom';
import ProfileMenuDropdown from '../../ProfileMenuDropdown';
import amplitude from 'amplitude-js';


const AssessmentHeader = (props) => {
  //  Parsing props
  const { isAuthenticated } = props;

  //  Rendering
  return (
    <div
      className="float-right d-flex align-items-center normal-text-style"
      style={{ marginLeft: 'auto' }}
    >
      <div className="welcome-links">
        <Link
          to="/cybercheckup"
          style={{ minWidth: "191px " }}
          onClick={() => amplitude.getInstance().logEvent('Take Cyber Checkup - Welcome View - Header')}
          className="btn btn-light py-2 px-3 mr-2 text-dark"
        >
          Take Cyber Checkup
        </Link>
        {!isAuthenticated ? (
          <Link
            to="/login"
            style={{ minWidth: "191px " }}
            className="btn btn-outline-light py-2 px-3"
            onClick={() => amplitude.getInstance().logEvent('Sign In - Welcome View - Header')}
          >
            Login
          </Link>
        ) : (
          <ProfileMenuDropdown type="assessment" />
        )}
      </div>
    </div>
  );
};

export default AssessmentHeader;
