import {
  ON_REQUEST_RULES_DELETE,
  ON_REQUEST_CANCEL_RULES_DELETE,
  ON_RULE_LOG_REQUESTED,
  ON_LOG_DETAIL_REQUESTED,
  ON_RULE_SETS_MOUNTED,
  ON_LOG_DETAIL_UNMOUNTED,
  ON_UPSERT_RULE_SET,
  ON_AUTOMATION_UNMOUNT,
  SET_RULE_TITLES,
  SET_LOG_DETAIL,
} from '../actions/types';

const initialState = {
  showDeleteModal: false,
  deletePayload: null,
  selectedRuleId: null,
  selectedLog: {
    id: null,
    name: null,
    titleArray: null,
    subtitleArray: null,
  },
  selectedRuleSetId: null,
};

const automationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ON_REQUEST_RULES_DELETE:
      return {
        ...state,
        showDeleteModal: true,
        deletePayload: payload,
      };
    case ON_REQUEST_CANCEL_RULES_DELETE:
      return {
        ...state,
        showDeleteModal: false,
        deletePayload: null,
      }
    case ON_RULE_LOG_REQUESTED:
      const { rule_id } = payload;
      return {
        ...state,
        selectedRuleId: rule_id,
      }
    case ON_LOG_DETAIL_REQUESTED:
      const { ruleLog, titleArray, subtitleArray } = payload;
      if (!ruleLog) { return state; }
      const { id, start_time: execution_date } = ruleLog;
      return {
        ...state,
        selectedLog: {
          id,
          name: execution_date,
          titleArray,
          subtitleArray,
        },
      };
    case SET_RULE_TITLES: {
      const { titleArray, subtitleArray } = payload;
      return {
        ...state,
        selectedLog: {
          ...state.selectedLog,
          titleArray,
          subtitleArray,
        },
      };
    }
    case SET_LOG_DETAIL: {
      if(!payload) return state;
      const { id, start_time: execution_date } = payload;
      return {
        ...state,
        selectedLog: {
          ...state.selectedLog,
          id,
          name: execution_date
        },
      };
    }
    case ON_RULE_SETS_MOUNTED:
    case ON_AUTOMATION_UNMOUNT:
      return {
        ...state,
        ...initialState,
      };
    case ON_LOG_DETAIL_UNMOUNTED:
      return {
        ...state,
        selectedLog: initialState.selectedLog,
      };
    case ON_UPSERT_RULE_SET:
      const { ruleSetId } = payload;
      return {
        ...state,
        selectedRuleSetId: ruleSetId,
      };
    default:
      return state;
  }
};

export default automationReducer;
