import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import { nameToInitials, getUserAvatarColorClassName } from '../../../utils/helpers';

const AvatarTooltip = (props) => {
    const { user, customClassName, id, profileImage } = props;

    //  Component variables
    const nameToUse = useMemo(() => {
        if (!user) { return 'G'; }
        const { full_name, name } = user;
        if (!full_name && !name) { return 'G'; }
        return full_name || name;
    }, [user]);

    //  Getting avatar className
    const getClassName = () => {
        const colorClassName = getUserAvatarColorClassName(user);
        return `avatar-circle ${colorClassName} ${customClassName || ''}`;
    }

    return (
        <React.Fragment>
            <button  
                id={id} 
                className={`btn btn-link avatar-tooltip text-decoration-none ${getClassName()}`} 
                style={ profileImage && { backgroundImage: `url(${profileImage})`, backgroundColor: "transparent"}}
                tabIndex = '-1'
            >
                {!profileImage && nameToInitials(nameToUse)}
            </button>
            <UncontrolledTooltip placement="bottom" target={id}>
                {nameToUse}
            </UncontrolledTooltip > 
        </React.Fragment>
    )       
}

export default connect(null, null)(AvatarTooltip);
