import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import BasicModal from '../layout/BasicModal';
import { Button } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import { renderInput } from '../../utils/renderFormFields';
import { validateFieldsByFormTemplate, existEmptyValuesOnForm } from '../../utils/validations';
import { emailFieldTemplate, buildEmailFieldObject } from '../../constants/formTemplates';
import { addCompanyMemberModalData } from '../../constants/pagesData';
import { setAlert } from '../../actions/alerts';
import { removeItemFromArray } from '../../utils/helpers';
import API from '../../api';
import useCustomMutation from '../../hooks/useCustomMutation';
import '../../assets/scss/custom/components/_companyMemberInvite.scss';


const CompanyMemberInvite = (props) => {
  //  Parsing props
  const { closeModal, customerId } = props;

  //  Initializing APIs
  const { UserAPI } = API;

  //  Initializing memoized dispatch
  const defaultDispatch = useDispatch();
  const dispatch = useCallback(defaultDispatch, []);

  //  Hook for calling the api
  const {
    data: response,
    error,
    isLoading: loading,
    mutate: callAPI,
  } = useCustomMutation(
    (params) => UserAPI.handlers.inviteTeamMembers(params),
    UserAPI.invalidators.inviteTeamMembers
  );

  //  Watching response changes
  useEffect(() => {
    if (!response) { return; }
    dispatch(setAlert(response.message, 'success'));
    closeModal();
  }, [response]);

  //  Watching error changes
  useEffect(() => {
    if (!error) { return; }
    const errorMessage = 'Error inviting users';
    /* const { data } = error || { data: { errorMessage: 'Error inviting users' } };
    const { errorMessage } = data; */
    dispatch(setAlert(errorMessage, 'danger'));
  }, [error]);

  //  Component state
  const [lastFieldIndex, setLastFieldIndex] = useState(0);
  const [fieldsToRender, setFieldsToRender] = useState([buildEmailFieldObject(lastFieldIndex, emailFieldTemplate)]);

  //  Function to validate form
  const validateForm = (formValues) => {
    return validateFieldsByFormTemplate(fieldsToRender, formValues);
  };

  //  Function to handle submit
  const onSubmit = (formValues) => {
    //  Validating data before submitting
    const formHasEmptyValues = existEmptyValuesOnForm(formValues, fieldsToRender.length);
    const emails = Object.values(formValues) || [];
    if (formHasEmptyValues || emails.length === 0) {
      dispatch(setAlert('One or more fields have an error. Please check and try again', 'danger'));
      return;
    }
    callAPI({ emails, company_id: customerId });
  };

  //  Function to mutate form state when a field has been removed
  const mutateFormState = (args, state, callbacks) => {
    const { formState } = state;
    const { values, errors } = formState;
    //  Verifying if we need to change the state
    const amountOfKeys = Object.keys(values).length;
    if (amountOfKeys === 0) { return; }
    if (amountOfKeys <= fieldsToRender.length) { return; }
    //  Removing the extra field values and errors
    Object.keys(values).forEach((key) => {
      const matchingField = fieldsToRender.find(({ name }) => name === key);
      if (matchingField) { return; }
      delete values[key];
      delete errors[key];
    });
  };

  //  Function to add new field to the DOM
  const addField = () => {
    const newLastFieldIndex = lastFieldIndex + 1;
    const newField = buildEmailFieldObject(newLastFieldIndex, emailFieldTemplate);
    setFieldsToRender([...fieldsToRender, newField]);
    setLastFieldIndex(newLastFieldIndex);
  };

  //  Function to remove field from DOM
  const removeField = (index) => {
    const newFieldsToRender = removeItemFromArray(fieldsToRender, index);
    setFieldsToRender(newFieldsToRender);
  };

  //  Function to render the remove field icon
  const renderRemoveFieldIcon = (index) => {
    if (fieldsToRender.length <= 1) { return null; }
    return (
      <i
        className="bx bx-minus-circle mr-2 align-middle remove-field-button"
        onClick={() => removeField(index)}
      />
    );
  };

  //  Function to render all the fields
  const renderFields = () => {
    if (!fieldsToRender) { return null; }
    return fieldsToRender.map((field, index) => {
      const {
        label,
        placeholder,
        className,
        type,
        name,
      } = field;
      return (
        <div key={index} className="field-container">
          <div className="label-container">
            <div>{label}</div>
            {renderRemoveFieldIcon(index)}
          </div>
          <Field
            key={index}
            render={renderInput}
            name={name}
            placeholder={placeholder}
            className={className}
            type={type}
          />
        </div>

      );
    });
  };

  //  Function to render form
  const renderForm = (params) => {
    //  Parsing params
    const { handleSubmit, invalid, form } = params;
    form.mutators.mutateFormState();

    //  Returning form
    return (
      <form
        onSubmit={(event) => {
          if (invalid) { dispatch(setAlert('One or more fields have an error. Please check and try again', 'danger')); }
          handleSubmit(event);
        }}
      >
        <div className="company-member-modal-description">
          {addCompanyMemberModalData.description}
        </div>
        <div className="fields-container">
          {renderFields()}
        </div>
        <div
          className="btn btn-add-email"
          onClick={() => addField()}
        >
          <i className='bx bx-plus-circle mr-2 align-middle remove-field-button' />
          Add another
        </div>
        <div className="button-container">
          <Button
            className="btn btn-submit btn-light text-dark font-weight-bold"
            type="submit"
            disabled={invalid || loading}
          >
            {loading ? 'Processing' : 'Send Invitations'}
          </Button>
        </div>
      </form>
    );
  };

  //  Rendering
  return (
    <BasicModal
      toggleModal={closeModal}
      header={addCompanyMemberModalData.title}
      headerClassName="company-member-modal-header"
      customClassName="team-manager-modal"
      showModal
    >
      <Form
        onSubmit={onSubmit}
        render={renderForm}
        validate={validateForm}
        mutators={{ mutateFormState }}
        shouldValidate
        validateOnBlur
      />
    </BasicModal>
  );
};

export default CompanyMemberInvite;
