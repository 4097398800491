import React from 'react';

const VcysoSolution = (props) => {
    const {
        title,
        name,
        info,
        icon
    } = props;
    return (
        <div className="vcyso-solution d-flex p-3">
            <div className="icon-wrapper d-flex align-items-center justify-content-center mr-3">
                <img className="align-self-center" width={90} src={icon} />
            </div>
            <div>
                <div className="solution-title font-weight-bolder text-white mb-1">{title}{` (${name})`}</div>
                <div className="solution-info">{info}</div>
            </div>
        </div>
    )
}

export default VcysoSolution;