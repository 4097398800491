import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import API from '../api';
import {
  COMPANY_SCAN_UPDATE,
  ISSUES_FREE_SCAN_SUCCESS,
  ISSUES_FREE_SCAN_FAILURE
} from '../actions/types';
import { successActionCreator } from '../actions/action-creators/success';
import { errorActionCreator } from '../actions/action-creators/errors';
import { onScanLoading } from '../actions/customerManagement';
import useMemoizedDispatch from './useMemoizedDispatch';
import useCustomMutation from './useCustomMutation';
import { setAlert } from '../actions/alerts';
import errorMessages from '../constants/errorMessages';
import { alertTypes } from '../constants/alerts';

const useRequestScan = () => {
  //  Initializing API
  const { CompanyAPI } = API;

  //  Watching redux store
  const globalScanLoading = useSelector((state) => state.customers.scanLoading);

  //  Initializing hooks
  const { dispatch } = useMemoizedDispatch();

  //  Calling API
  const {
    data: scanData,
    error: scanError,
    isLoading: scanLoading,
    mutate: requestScan,
  } = useCustomMutation(
    (params) => CompanyAPI.handlers.requestFreeScan(params),
    CompanyAPI.invalidators.requestFreeScan,
  );

  //  Watching api response
  useEffect(() => {
    if (!scanData) { return; }
    dispatch(successActionCreator(COMPANY_SCAN_UPDATE, scanData?.data));
    dispatch(successActionCreator(ISSUES_FREE_SCAN_SUCCESS, true));
    dispatch(onScanLoading(false));
    dispatch(setAlert("Scan requested", alertTypes.success))
  }, [scanData]);

  //  Watching API error
  useEffect(() => {
    if (!scanError) { return; }
    const message = 'Error requesting free scan.';
    dispatch(errorActionCreator(ISSUES_FREE_SCAN_FAILURE, { message }))
    dispatch(onScanLoading(false));
    const alertMessage = scanError?.response?.data?.errorMessage || errorMessages.defaultPost;
    dispatch(setAlert(alertMessage, alertTypes.error))
  }, [scanError]);

  const callRequestScan = (companyId) => {
    dispatch(onScanLoading(true));
    requestScan(companyId);
  }

  //  Returning assets
  return {
    requestScan: callRequestScan,
    scanLoading: scanLoading || globalScanLoading,
    scanError: scanError?.response?.data?.errorMessage,
    scanData,
  }
};

export default useRequestScan;
