import React from 'react';
import { formatDate, getGMTOffset, getTimeString } from "../../utils/helpers";
import { cellTypes, headerTypes } from "../tableTemplates";
import { Button } from 'reactstrap';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import { setAlert } from '../../actions/alerts';
import { alertTypes } from '../alerts';

const JsonButton = ({ event }) => {
  const { dispatch } = useMemoizedDispatch();

  const onCopyClick = () => {
    navigator.clipboard.writeText(JSON.stringify(event));
    const message = `Json copied to clipboard`;
    dispatch(setAlert(message, alertTypes.success))
  }

  return (
    <Button color="link" className="btn-issue-copy-code" onClick={onCopyClick}>
      <i style={{ fontSize: 20 }} className='bx bxs-file-json' color="#7D78A1" />
    </Button>
  );
}

export const obisidanEventsTableTemplate = [
  {
    label: 'Date',
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerContentClassName: 'header-cell',
    cellClassName: 'regular-cell td-ellipsis',
    entry: 'datetime',
    customRender: ({ datetime }) => {
      const formattedDate = formatDate(datetime);
      const formattedTime = getTimeString(datetime, false);
      return `${formattedDate} at ${formattedTime}${getGMTOffset()}`;
    },
    customParams: { objectName: 'event', valueName: 'datetime' },
  },
  {
    label: 'Event',
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    entry: 'humanReadableDescription',
    headerContentClassName: 'header-cell',
    cellClassName: 'regular-cell td-ellipsis event-name-cell',
    customRender: ({ humanReadableDescription }) => humanReadableDescription.plain,
    customParams: { objectName: 'event', valueName: 'humanReadableDescription' },
  },
  {
    label: 'Status',
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    entry: 'status',
    headerContentClassName: 'header-cell',
    cellClassName: 'regular-cell',
    customRender: ({ status }) => status || '-',
    customParams: { objectName: 'event', valueName: 'status' },
  },
  {
    label: 'Json file',
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerContentClassName: 'header-cell',
    cellClassName: 'regular-cell td-ellipsis',
    customRender: ({ event }) => <JsonButton event={event} />,
    customParams: { objectName: 'event', valueName: 'jsonFile' },
    entry: 'json',
  }
];