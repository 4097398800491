import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { domainsTableTemplate } from '../../constants/app-v4/domainsTableTemplate';
import {
  customTableEvents,
  tablePaginationTypes,
} from '../../constants/common';
import useCustomQuery from '../../hooks/useCustomQuery';
import useSelectedList from '../../hooks/useSelectedList';
import useShowModal from '../../hooks/useShowModal';
import companyIdSelector from '../../selectors/companyIdSelector';
import CustomTable from '../CustomTable/CustomTable';
import SearchBar from '../layout/filters/SearchBar';
import DomainAdditionModal from './domains/DomainAdditionModal';
import DomainBulkActions from './domains/DomainBulkActions';
import API from '../../api';
import useHandleApiResponse from '../../hooks/useHandleApiResponse';
import { Button } from 'reactstrap';
import { mapObjSortToArray, mapIdsToPublicIds } from '../../utils/helpers';
import NavigationTextRow from '../layout/NavigationTextRow';
import { useHistory } from 'react-router-dom';
import companyInfoSelector from '../../selectors/companyInfoSelector';
import { getSelectedIds } from '../../utils/recycleBinHelpers';

//  Initial filters
const initialFilters = {
  sort: null,
  text_to_search: '',
};

const MemberDomains = () => {

  //  Initializing API
  const { CompanyAPI } = API;

  // Getting needed info from redux store
  const companyId = useSelector(state => companyIdSelector(state));
  const amountOfDomains = useSelector((state) => companyInfoSelector(state, "domainsCount", false));

  //  Component state
  const [filters, setFilters] = useState(initialFilters);
  const [elements, setElements] = useState([]);

  //  Component refs
  const tableContainerRef = useRef();

  //  Component Hooks
const history = useHistory();
  const {
    selected,
    excluded,
    allSelected,
    clearSelected,
    eventHandler: handleSelectedEvent,
  } = useSelectedList({
    eventMap: {
      changeAll: customTableEvents.CHANGE_SELECT_ALL,
      add: customTableEvents.SELECT_ELEMENT,
      remove: customTableEvents.UNSELECT_ELEMENT,
    },
  });
  const {
    showModal: showAddDomainsModal,
    toggleModal: toggleAddDomainsModal
  } = useShowModal();
  //  Hook to make API query
  const {
    data: domainsData,
    error: domainsError,
    isLoading: domainsLoading,
  } = useCustomQuery(
    [CompanyAPI.keys.getDomainsList, { ...filters, company_id: companyId }],
    ({ queryKey }) => CompanyAPI.handlers.getDomainsList(queryKey[1]),
  );
  useHandleApiResponse({
    data: domainsData,
    errorData: domainsError,
    successCallback: () => {
      if (!domainsData) return;
      setElements(domainsData);
    }
  });

  //  Watching company id and text search changes to update selected
  useEffect(() => {
    clearSelected();
  }, [companyId, filters?.text_to_search]);

  //  Function to request more elements
  const requestMore = ({ selected }) => {
    const newOffset = selected * filters?.limit;
    setFilters({ ...filters, offset: newOffset });
  };

  //  Function to change filters
  const changeFilters = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  //  Function to change sort
  const changeSort = (key, value) => {
    setFilters({ ...filters, sort: { [key]: value } });
  };

  //  Function to render title
  const renderTitle = () => {
    return (
      <div className="title-container">
        <div className="title">Listing all domains</div>
        <div className="count">{`${elements?.count || 0} domains`}</div>
      </div>
    );
  };

  //  Function to render bulk actions menu
  const renderBulkActionsMenu = () => {
    //  Getting selected ids and rendering
    const ids = getSelectedIds(allSelected, selected, excluded, elements?.domains || []);
    return (
      <DomainBulkActions
        parentRef={tableContainerRef}
        company_id={companyId}
        selected={mapIdsToPublicIds(ids, elements?.domains || [])}
      />
    );
  };

  //  Function to render top section
  const renderTableTopBar = () => {
    const isDomainAmountFull = amountOfDomains >= process.env.REACT_APP_DOMAINS_MAX_AMOUNT ? true : false;
    return (
      <div className='d-flex'>
        <div className="searchable-table-topbar">
          <SearchBar
            useSimpleContainer
            customClassName={`search-box mr-2 mb-2 d-inline-block rule-logs-search-bar`}
            placeholder="Search"
            value={filters?.text_to_search}
            callback={(value) => changeFilters('text_to_search', value)}
          />
        </div>
        <div
          className='ml-auto w-100 topbar-message-n-button-container'
        >
          {isDomainAmountFull ?
            <div className='domain-count-full'>
              Your domain list its full, you can´t add more domains to your account.
            </div>
            : ''}
          <Button
            color="light"
            onClick={toggleAddDomainsModal}
            className="btn-add-domain btn-large d-flex align-items-center ml-auto"
            disabled={isDomainAmountFull}
          >
            <i className='bx bx-plus mr-2' />
            Add new domain
          </Button>
        </div>
      </div>

    );
  };

  //  Function to render add domains modal
  const renderAddDomainsModal = () => {
    if (!showAddDomainsModal) { return null; };
    return (
      <DomainAdditionModal
        closeModal={() => toggleAddDomainsModal(false)}
        companyId={companyId}
        domainsCount={amountOfDomains}
      />
    );
  };

  const renderMemberLink = () => {
    const titleArray = [
      { label: 'Members', callback: () => history.push('/profile/company') },
      { label: 'My domains' }
    ]
    return (
      <NavigationTextRow
        elements={titleArray}
        delimiter="/"
        rowExtraClassName="domains-member-link"
      />
    )
  }

  return (
    <div className="member-domains-manager">
      {renderMemberLink()}
      {renderAddDomainsModal()}
      {renderTitle()}
      <div className="topbar-container">{renderTableTopBar()}</div>
      {renderBulkActionsMenu()}
      <div className="table-container" ref={tableContainerRef}>
        <CustomTable
          tableClassName="member-domains-table"
          headerClassName="member-domains-table-header"
          rowClassName="member-domains-table-row"
          columnsTemplate={domainsTableTemplate}
          data={elements?.domains || []}
          isScrollable={true}
          requestMore={requestMore}
          changeSort={changeSort}
          sort={mapObjSortToArray(filters?.sort) || []}
          allSelected={allSelected}
          selected={selected}
          excluded={excluded}
          handleSelectedEvent={handleSelectedEvent}
          loading={domainsLoading}
          emptyConfig={{
            message: `Domains will appear here`,
            messageClassName: 'empty-message',
          }}
          paginationType={tablePaginationTypes.PAGES}
        />
      </div>
    </div>
  );
};

export default MemberDomains;
