import React, { useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { getBusinessRiskConfig, getRiskStatements } from '../../actions/businessRisk';
import companyIdSelector from '../../selectors/companyIdSelector';
import makeErrorMessage from '../../selectors/errorsSelector';
import makeLoadingState from '../../selectors/loadingSelector';
import InfoTooltip from '../dashboard/tooltips/InfoTooltip';
import LoadingContent from '../layout/LoadingContent';
import RiskBubbleChart from './risk-chart/RiskBubbleChart';
import { SET_SHOULD_RELOAD_BUSINESS_RISK } from '../../actions/types';
import { successActionCreator } from '../../actions/action-creators/success';

const RiskManagerChart = (props) => {

    const {
        cardColor = "violet",
        showTreatmentFilters
    } = props;

    //Selectors
    const getRiskStatementsErrorMessage = makeErrorMessage(['RISK_STATEMENTS']);
    const getRiskConfigLoadingState = makeLoadingState(['BUSINESS_RISK_CONFIG']);
    const getRiskConfigErrorMessage = makeErrorMessage(['BUSINESS_RISK_CONFIG']);

    //  Getting needed info from redux store
    const riskStatementsErrorMessage = useSelector((state) => getRiskStatementsErrorMessage(state));
    const riskConfigErrorMessage = useSelector((state) => getRiskConfigErrorMessage(state));
    const riskConfigLoading = useSelector((state) => getRiskConfigLoadingState(state));
    const shouldReload = useSelector((state) => state.businessRisk.shouldReload);
    const company_id = useSelector((state) => companyIdSelector(state));

    //  Memoized dispatch
    const defaultDispatch = useDispatch();
    const dispatch = useCallback(defaultDispatch, []);

    //Controller Ref
    const controllerRef = useRef(null);

    useEffect(() => {
        dispatch(getBusinessRiskConfig())
        dispatch(getRiskStatements(company_id))
    }, [company_id]);

    useEffect(() => {
        if(shouldReload) {
            dispatch(getBusinessRiskConfig())
            dispatch(getRiskStatements(company_id))
            dispatch(successActionCreator(SET_SHOULD_RELOAD_BUSINESS_RISK, false));
        }
    },[shouldReload])

    const renderTitleTooltip = () => {
        return (
            <InfoTooltip
                label="Risk Manager"
                wrapperClassName="dashboard-widget-title"
                id="risk-manager-chart-tooltip"
                showTooltip={false}
            />
        )
    };

    const retryRequests = () => {
        if(riskConfigErrorMessage) {
            dispatch(getBusinessRiskConfig())
        }

        if(riskStatementsErrorMessage) {
            dispatch(getRiskStatements(company_id))
        }
    }

    const renderRiskChart = () => {
        return (
            <div>
                <RiskBubbleChart
                    retryRequests={retryRequests}
                    controllerRef={controllerRef}
                    showTreatmentFilters={showTreatmentFilters}
                />
            </div>
        )
    }

    return (
        <div className="pl-1 px-lg-2 d-flex flex-grow-1">
            <Card className="h-100 w-100" color={cardColor}>
                <CardBody className="risk-statement-distribution">
                    {renderTitleTooltip()}
                    <LoadingContent
                        errorMessage={riskConfigErrorMessage || riskStatementsErrorMessage}
                        isLoading={riskConfigLoading}
                        iconType="solidIcon"
                        errorStyle={{ minHeight: "225px" }}
                        loadingStyle={{ minHeight: "225px" }}
                        errorButtonCallback={retryRequests}
                    >
                        {renderRiskChart()}
                    </LoadingContent>
                </CardBody>
            </Card>
        </div>
    )
}
export default RiskManagerChart;
