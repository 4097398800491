import React from 'react';
import _ from 'lodash';
import { Progress } from 'reactstrap';
import offPositionArrow from '../../../assets/images/meters/position-off.png';
import firstPositionArrow from '../../../assets/images/meters/position-1.png';
import secondPostionArrow from '../../../assets/images/meters/position-2.png';
import thirdPositionArrow from '../../../assets/images/meters/position-3.png';
import fourthPositionArrow from '../../../assets/images/meters/position-4.png';
import inactiveFirstPositionArrow from '../../../assets/images/meters/inactive-position-1.png';
import inactiveSecondPositionArrow from '../../../assets/images/meters/inactive-position-2.png';
import inactiveThirdPositionArrow from '../../../assets/images/meters/inactive-position-3.png';
import inactiveFourthPositionArrow from '../../../assets/images/meters/inactive-position-4.png';
import dial from '../../../assets/images/meters/compliance-meter.png';

const MeterChart = ({meterType, arrowPosition, inactiveArrowPosition, meterOffCallback, checkupProgress, progressLabel, initialState }) => {

    const activeScoreInfo = {
        4: {
            arrowImage: fourthPositionArrow,
            backgroundPosition: "left",
            textColor: 'great',
            grade: 'A',
            feedback: "You're off to a great start"
        },
        3: {
            arrowImage: thirdPositionArrow,
            backgroundPosition: "bottom",
            textColor: 'good',
            grade: 'B',
            feedback: "Your company is at moderate risk"
        },
        2: {
            arrowImage: secondPostionArrow,
            backgroundPosition: "bottom",
            textColor: 'warning',
            grade: 'C',
            feedback: "Your company is at an elevated risk"
        },
        1: {
            arrowImage: firstPositionArrow,
            backgroundPosition: "right",
            textColor: 'critical',
            grade: 'D',
            feedback: "Your company is at high risk"
        },
    }[arrowPosition || 1];

    const inactiveScoreInfo = {
        4: {
            arrowImage: inactiveFourthPositionArrow,
            backgroundPosition: "left",
        },
        3: {
            arrowImage: inactiveThirdPositionArrow,
            backgroundPosition: "bottom",
        },
        2: {
            arrowImage: inactiveSecondPositionArrow,
            backgroundPosition: "bottom",
        },
        1: {
            arrowImage: inactiveFirstPositionArrow,
            backgroundPosition: "right",
        },
    }[inactiveArrowPosition || 1];

    const renderGrade = () => {
        return (
            <React.Fragment>
                <div
                    className={`${meterType}-meter`}
                    // style={{ backgroundPosition: activeScoreInfo.backgroundPosition }}
                >
                    {!_.isNil(inactiveArrowPosition) && <img className="arrow-image img-fluid position-absolute" src={inactiveScoreInfo.arrowImage} alt={inactiveScoreInfo.grade}/>}
                    <img className="arrow-image img-fluid position-absolute" src={activeScoreInfo.arrowImage} alt={activeScoreInfo.grade}/>
                    <img className="arrow-image img-fluid" src={dial} alt="compliance-score-dial"/>
                </div>
                <div className={`score-feedback text-${activeScoreInfo.textColor} text-center w-100`}>
                    <span className="grade">{activeScoreInfo.grade}</span> score
                </div>
                {/* <div className={`feedback text-center`}><span className="see-journey-label">*see your journey below</span></div> */}
                {initialState && (
                    <React.Fragment>
                        <div className={`score-off-description d-flex justify-content-center w-100`}>
                            This is a preliminary score, continue filling out your assessment
                        </div>
                        <div className="checkup-button mb-3">
                            <button
                                className={`btn btn-light float-right text-dark py-2 px-3 d-flex`}
                                onClick={meterOffCallback}
                                onKeyUp={meterOffCallback}
                            >
                                Continue your assessment
                            </button>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }

    const renderGradeOff = () => {
        return (
            <React.Fragment>
                <div className={`score-meter off-grade-score-meter mb-0`} style={{ backgroundPosition: activeScoreInfo.backgroundPosition }} >
                    <img className="arrow-image img-fluid position-absolute" src={offPositionArrow} alt='Disable score'/>
                    <img className="arrow-image img-fluid" src={dial} alt="compliance-score-dial"/>
                </div>
                <div className={`score-feedback score-off-feedback text-center w-100`}>
                    <span className="score-off-title">What’s your Cyvatar Score?</span>
                </div>
                <div className="score-off-description mb-2">We’ll ask you some questions to assess your current  cybersecurity status</div>
                <div className={`score-off-description d-flex justify-content-center w-100`}>
                    <div></div>
                    {checkupProgress > 0 && (
                        <div className="score-off-progress-wrapper d-flex">
                            <div className="progress-bar-wrapper mr-2">
                                <Progress value={checkupProgress} className="score-off-progress-bar"/>
                            </div>
                            <div className="d-inline-block score-off-progress">{progressLabel}</div>
                        </div>
                    )}
                </div>
                <div className="checkup-button">
                    <button
                        className={`btn btn-light float-right text-dark py-2 px-3 d-flex`}
                        onClick={meterOffCallback}
                        onKeyUp={meterOffCallback}
                    >
                        {`${ checkupProgress > 0 ? 'Continue' : 'Start'} your Assessment`}
                    </button>
                </div>
            </React.Fragment>
        );
    }

    return (
        <div className="meter-chart d-inline-flex flex-col flex-wrap justify-content-center">
            {meterType === 'score' && renderGrade()}
            {meterType === 'checkup' && renderGradeOff()}
            {meterType === 'progress' && renderGradeOff()}
        </div>
    );
};

export default MeterChart;
