import {
    USER_AUTH_SUCCESS,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    MFA_CODE_SUCCESS,
    MFA_VERIFICATION_SUCCESS,
    MFA_DISABLE_SUCCESS,
    AUTH_ERROR,
    REGISTER_SUCCESS,
    REGISTER_FREEMIUM_SUCCESS,
    SEND_RESET_PASSWORD_TOKEN_SUCCESS,
    CLEAR_RESET_PASSWORD,
    VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS,
    CONFIRM_PASSWORD_SUCCESS,
    CLEAN_AUTH_ERROR_MESSAGE,
    UPDATE_COMPANY_SUCCESS,
    UPDATE_ROI_COMPANY_SUCCESS,
    CREATE_COMPANY_SUCCESS,
    COMPANY_DEMO_UPDATE,
    COMPANY_SCAN_UPDATE,
    UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
    SHOW_FREEMIUM_ALERT,
    CANCEL_ACCOUNT_SUCCESS,
    UPDATE_DOMAIN_COUNT
} from '../actions/types';
import setAuthToken from "../utils/axios/setAuthToken";

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    loading: true,
    user: {
        isFirstTime: false,
        name: '',
        email: '',
        roles: [],
        packages: [],
        active: false
    },
    company: {
        id: '',
        realId: '',
        name: '',
        annualRevenue: null,
        numberEmployees: null,
        email: '',
        note: null,
        code: null,
        freemium: null,
        showDemoData: null,
        requestedScan: null,
        lastRequestScan: null,
        lastScan: null,
        isSales: false,
        teamMembers: [],
        domainsCount: null
    },
    userSettings: {
        receive_notifications: true,
    },
    error: '',
    resetPassword: {
        emailSent: null,
        isValid: false
    },
    mfaConfig: null,
    showFreemiumAlert: false
}

const clearLocalStorage = () => {
    setAuthToken(null)
    localStorage.removeItem('token')
    localStorage.removeItem('questions');
    localStorage.removeItem('questionsByCategory');
    localStorage.removeItem('comments');
    localStorage.removeItem('assessmentComment');
    localStorage.removeItem('assessmentExecutionStatus');
    sessionStorage.clear();
}

export default (state = initialState, action) => {
    const { type, payload } = action ;
    switch(type){
        case LOGIN_SUCCESS:
            localStorage.setItem('token', payload.user.token)
            return {
                ...state,
                //isAuthenticated: true,
                token: payload.user.token,
                loading: false,
                user: {
                    ...state.user,
                    email: payload.user.email,
                    isFirstTime: payload.user.isFirstTime,
                    active: payload.user?.active
                },
                error: '',
                mfaConfig: {
                    ...state.mfaConfig,
                    mfa_phone: payload.mfa_phone || state.mfaConfig?.mfa_phone,
                    mfa_type: payload.mfa_type || state.mfaConfig?.mfa_type,
                    token: null,
                    status: ''
                }
            }
        case MFA_CODE_SUCCESS:
            return {
                ...state,
                error: '',
                mfaConfig: {
                    ...state.mfaConfig,
                    status: payload.status || state.mfaConfig.status,
                    mfa_type: payload.mfa_type || state.mfaConfig.mfa_type,
                    token: payload.token,
                    mfa_phone: payload.mfa_phone || state.mfaConfig.mfa_phone,
                    email: payload.email || state.mfaConfig.email,
                }
            }
        case MFA_VERIFICATION_SUCCESS:
            return {
                ...state,
                error: '',
                mfaConfig: payload
            }
        case MFA_DISABLE_SUCCESS:
            return {
                ...state,
                error: '',
                mfaConfig: payload
            }
        case REGISTER_FREEMIUM_SUCCESS:
        case REGISTER_SUCCESS:
            localStorage.setItem('token', payload.token)
            return {
                ...state,
                //isAuthenticated: true,
                token: payload.token,
                loading: false,
                // company: payload.company,
                user: {
                    ...state.user,
                    email: payload.email,
                    isFirstTime: payload.isFirstTime,
                    roles: payload.roles,
                    packages: payload.packages,
                    active: payload.active
                },
                error: ''
            }
        case USER_AUTH_SUCCESS:
            localStorage.setItem('token', payload.token)
            setAuthToken(payload.token);
            return {
                ...state,
                isAuthenticated: payload.id ? true : false,
                loading: false,
                token: payload.token,
                user: {
                    ...state.user,
                    id: payload.id,
                    publicId: payload.public_id,
                    name: payload.full_name,
                    email: payload.email,
                    roles: payload.roles,
                    packages: payload.packages,
                    active: payload.active
                },
                company: {
                    ...state.company,
                    id: payload.company?.public_id || '',
                    realId: payload.company_id || '',
                    name: payload.company?.name || '',
                    email: payload.company_email ||'',
                    annualRevenue: payload.company?.annual_revenue || null,
                    numberEmployees: payload.company?.number_employees || null,
                    note: payload.company?.note || null,
                    code: payload.company?.code || null,
                    freemium: payload.company?.freemium,
                    showDemoData: payload.company?.show_demo_data,
                    requestedScan: payload.company?.request_scan,
                    lastRequestScan: payload.company?.request_scan_date,
                    lastScan: payload.company?.scan_date,
                    isSales: payload.company?.is_sales_test || false,
                    teamMembers: payload.company?.users || [],
                    domainsCount : payload?.domains_count || null
                },
                userSettings: {
                    ...state.userSettings,
                    receive_notifications: payload?.userSettings?.receive_notifications,
                },
                error: '',
                mfaConfig: {
                    ...state.mfaConfig,
                    mfa_phone: payload.mfa_phone,
                    mfa_type: payload.mfa_type,
                    token: null,
                    status: ''
                }
            }
        case UPDATE_DOMAIN_COUNT:
            return {
                ...state,
                company: {
                    ...state.company,
                    domainsCount : payload.domains_count,
                }
            }
        case AUTH_ERROR:
            clearLocalStorage();
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
                user: {
                    id: '',
                    publicId: '',
                    isFirstTime: false,
                    name: '',
                    email: '',
                    roles: [],
                    packages: [],
                    active: false
                },
                company: {
                    id: '',
                    realId: '',
                    name: '',
                    email: '',
                    annualRevenue: null,
                    numberEmployees: null,
                    freemium: null,
                    showDemoData: null,
                    requestedScan: null,
                    lastRequestScan: null,
                    lastScan: null,
                    teamMembers: []

                },
                userSettings: {
                    receive_notifications: true,
                },
                error: payload,
                showFreemiumAlert: false
            }
        case LOGOUT_SUCCESS:
            clearLocalStorage()
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
                user: {
                    isFirstTime: false,
                    name: '',
                    email: '',
                    roles: [],
                    packages: [],
                    active: false
                },
                company: {
                    id: '',
                    realId: '',
                    name: '',
                    email: '',
                    annualRevenue: null,
                    numberEmployees: null,
                    note: null,
                    code: null,
                    freemium: null,
                    showDemoData: null,
                    requestedScan: null,
                    lastRequestScan: null,
                    lastScan: null,
                    teamMembers: []
                },
                userSettings: {
                    receive_notifications: true,
                },
                error: '',
                showFreemiumAlert: false
            }
        case SEND_RESET_PASSWORD_TOKEN_SUCCESS:
            return {
                ...state,
                resetPassword: {
                    ...state.resetPassword,
                    emailSent: payload.success
                }
            }

        case VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS:
        case CONFIRM_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPassword: {
                    ...state.resetPassword,
                    isValid: payload.success
                },
                mfaConfig: {
                    ...state.mfaConfig,
                    isValid: payload.success
                }
            }

        case CLEAR_RESET_PASSWORD:
            return {
                ...state,
                resetPassword: {
                    emailSent: null,
                    isValid: false
                }
            }
        case CLEAN_AUTH_ERROR_MESSAGE:
            return {
                ...state,
                error: ''
            }
        case UPDATE_ROI_COMPANY_SUCCESS:
            return {
                ...state,
                company: {
                    ...state.company,
                    annualRevenue: payload.annualRevenue,
                    numberEmployees: payload.employees,
                },
            }
        case UPDATE_COMPANY_SUCCESS:
        case CREATE_COMPANY_SUCCESS:
            return {
                ...state,
                company: {
                    ...state.company,
                    id: payload.public_id,
                    realId: payload.id,
                    name: payload.name,
                    email: payload.company_email,
                    annualRevenue: payload.annual_revenue,
                    numberEmployees: payload.number_employees,
                    code: payload.code,
                    note: payload.note,
                    freemium: payload.freemium,
                    showDemoData: payload.show_demo_data,
                    requestedScan: payload.request_scan,
                    lastRequestScan: payload.request_scan_date,
                    lastScan: payload.scan_date,
                    // teamMembers: payload.company?.users || []
                },
            }
        case UPDATE_NOTIFICATION_SETTINGS_SUCCESS:
            return {
                ...state,
                userSettings: {
                    ...state.userSettings,
                    receive_notifications: payload?.receive_notifications
                },
            }
        case COMPANY_DEMO_UPDATE:
            return {
                ...state,
                company: {
                    ...state.company,
                    showDemoData: true,
                    requestedScan: payload.request_scan,
                    lastScan: payload.scan_date
                }
            }
        case COMPANY_SCAN_UPDATE:
            //  Impersonation reducer listening to this event as well
            //  If does not update here, will update on impersonation
            if (state?.company?.realId === '') { return state; }
            return {
                ...state,
                company: {
                    ...state.company,
                    showDemoData: payload.showDemoData,
                    requestedScan: payload.request_scan,
                    lastRequestScan: payload.request_scan_date,
                }
            }
        case SHOW_FREEMIUM_ALERT:
        return {
            ...state,
            showFreemiumAlert: payload
        }
        case CANCEL_ACCOUNT_SUCCESS:
        return {
            ...state,
            user: {
                ...state.user,
                active: false
            }
        }
        default:
            return state
    }
}
