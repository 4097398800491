import React, { useState } from 'react';
import { useEffect } from 'react';
import { Modal } from 'reactstrap';
import { PrismicRichText } from '@prismicio/react'
import LandingNotificationInfoSection from './LandingNotificationInfoSection';
import defaultNotificationImg from '../../../assets/images/notifications-default-image.png';
import DismissButton from '../../layout/buttons/DismissButton';
import VideoPlayer from '../../video/VideoPlayer'

const LandingNotification = (props) => {
  const {
    show,
    toggle,
    backdrop,
    notifications,
  } = props;

  const [selectedNotificationIndex, setSelectedNotificationIndex] = useState(0);
  const [selectedNotification, setSelectedNotification] = useState(null);

  // Check selectedNotificationIndex changes
  useEffect(() => {
    if(!notifications) return;
    const notification = notifications[selectedNotificationIndex];
    if(!notification) {
      if(selectedNotificationIndex !== 0) {
        setSelectedNotificationIndex(0);
      }
    }
    setSelectedNotification(notification)
  },[selectedNotificationIndex, notifications])

  // Function to render information section
  const renderInformationSection = () => {
    if(!selectedNotification) return null
    const { title, description, external_link } = selectedNotification;
    return (
      <LandingNotificationInfoSection
        title={title}
        description={description}
        index={selectedNotificationIndex}
        selectCallback={setSelectedNotificationIndex}
        notificationsCount={notifications?.length}
        externalLink={external_link}
      />
    )
  }

  const renderCustomHeaderComponent = ({ children }) => {
    return <div className="landing-notification-header">{children}</div>
  }
  const getHeaderSerializer = () => {
    const components = {};
    // Set elements to modify
    const elements = [
      'heading1',
      'heading2',
      'heading3',
      'heading4',
      'heading5',
      'heading6',
      'paragraph'
    ];
    elements.map((element) => {
      components[element] = renderCustomHeaderComponent;
    })

    return components
}

  // render video player
  const renderVideoPlayer = () => {

    if(!selectedNotification) return null;
    const {
      video_url
    } = selectedNotification;
    const url = `${process.env.REACT_APP_CLOUDFRONT_URL}/${video_url?.[0]?.text}`
    return (
      <div style={{width:'100%',height:'100%'}}>
        <VideoPlayer url={url}/>
      </div>
    )
  }

  // Add fallback image
  const addDefaultSrc = (e) => {
    if (e.target.src !== defaultNotificationImg) {
      e.target.src = defaultNotificationImg;
    }
  }

  // render image
  const renderImage = () => {
    if(!selectedNotification) return null;
    const {
      image,
      title
    } = selectedNotification;
    return (
      <img
        className='img-fluid'
        width={"100%"}
        src={image?.url || defaultNotificationImg}
        alt={image?.alt || title}
        onError={addDefaultSrc}
      />
    )
  }

  // const renderNavigationArrow = (moveForward, customclassName) => {
  //   // Only needed if there's more than 1 notification
  //   if(notifications.length < 2) return null;
  //   let customVideoClassName = selectedNotification?.video_url?.[0]?.text ? `landing-notification-video-arrow` : '';
  //   return (
  //     <NavigationArrowByIndex
  //       moveForward={moveForward}
  //       currentIndex={selectedNotificationIndex}
  //       total={notifications?.length}
  //       extraClassName={`landing-notification-arrow ${customclassName} ${customVideoClassName}`}
  //       selectCallback={setSelectedNotificationIndex}
  //       style={{top:selectedNotification?.video_url?.[0]?.text ? 100 : 129}}
  //     />
  //   )
  // }

  const renderNotificationHeader = () => {
    if(!selectedNotification?.header) return null;
    const components = getHeaderSerializer();
    return (
        <PrismicRichText field={selectedNotification?.header} components={components}/>
    )
  }


  const renderDismissButton =  () => {
    return (
      <DismissButton
        className="btn-dismiss-landing-modal"
        callback={toggle}
      />
    )
  }

  // Function to render media section: images or videos
  const renderMediaSection = () => {
    return (
      <div className="landing-notifications-media-container overlay w-100">
        {renderNotificationHeader()}
        {selectedNotification?.video_url?.[0]?.text ? renderVideoPlayer() : renderImage()}
        {/* {renderNavigationArrow(false, 'landing-notification-arrow-left')}
        {renderNavigationArrow(true, 'landing-notification-arrow-right')} */}
        {renderDismissButton()}
      </div>
    )
  }

  if(!notifications || !Array.isArray(notifications) || notifications?.length < 1) return null;
  return (
    <Modal
      isOpen={show}
      toggle={toggle}
      centered={true}
      size="xl"
      className="landing-notifications"
      backdrop={backdrop}
      contentClassName="landing-notifications-modal-content"
    >
      {renderMediaSection()}
      {renderInformationSection()}
    </Modal>
  );
};

export default LandingNotification;
