import React from 'react'
import { Badge } from 'reactstrap';
import { scanStatusConfigById } from '../../../constants/common';

const ScanStatusBadge = (props) => {
  const {
    statusId,
    wrapperClassName = '',
  } = props;
  const scanStatus = scanStatusConfigById[statusId];
  return scanStatus ? (
    <div className={wrapperClassName}>
      <Badge
        color={scanStatus.color}
        className="scan-status-badge"
      >
        {scanStatus.label}
      </Badge>
    </div>
  ) : ''
}

export default ScanStatusBadge
