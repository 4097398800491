import React, { useState, useEffect } from 'react';
import { addNewMemberModalData } from '../../constants/pagesData';
import BasicModal from '../layout/BasicModal';
import AddNewMembersComponent from './AddNewMembersComponent';
import PartnerAddOnComponent from '../partners/PartnerAddOn';
import { Form } from 'react-final-form';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import { setAlert } from '../../actions/alerts';
import { alertTypes } from '../../constants/alerts';
import {
    emailFieldTemplate,
    buildFieldObjectFromTemplate,
    newMemberForm,
    wrapperTypes
} from '../../constants/formTemplates';
import { validateFieldsByFormTemplate } from '../../utils/validations';
import CustomForm from '../CustomForm/CustomForm';
import { mapFormFieldsFromRows, mapUserParamsFromFormValues, mutateFormStateWhenAddingMembersRows, mapPartnerParamsFromFormValues } from '../../utils/formHelpers';
import errorMessages from '../../constants/errorMessages';
import { Button } from 'reactstrap';
import CustomCheckBox from '../layout/CustomCheckBox';
import API from '../../api';
import { defaultIndexPath } from '../../constants/navigation';
import { useHistory } from 'react-router-dom';
import { onImpersonationSuccess } from '../../actions/impersonation';
import useCustomMutation from '../../hooks/useCustomMutation';

const NewMemberModal = (props) => {
    const {
        showModal,
        toggleModal,
        reloadCustomers,
        partnerDefaults
    } = props;

    //  Initializing API
    const { CompanyAPI, UserAPI } = API;

    // Component state
    const [continueToAssessment, setContinueToAssessment] = useState(false);
    const [lastFieldIndex, setLastFieldIndex] = useState(0);
    const [lastPartnerFieldIndex, setLastPartnerFieldIndex] = useState(0);
    // Initial member rows
    const memberRows = [
        [
            buildFieldObjectFromTemplate(lastFieldIndex, emailFieldTemplate, null, "Enter email", "invite-members-field-wrapper email-field-wrapper"),
        ]
    ]
    const [rowsToRender, setRowsToRender] = useState(memberRows);
    const [partnersRowsToRender, setPartnerRowsToRender] = useState([]);

    // Component Hooks
    const { dispatch } = useMemoizedDispatch();
    const history = useHistory();

    //  Calling API for creating member
    const {
        data: createNewMemberResponse,
        error: createNewMemberError,
        isLoading: createNewMemberLoading,
        mutate: createNewMember,
    } = useCustomMutation(
        (params) => CompanyAPI.handlers.createCompanyAndInviteUsers(params),
        CompanyAPI.invalidators.createCompanyAndInviteUsers
    );

    //  Post to select company
    const {
        data: impersonateResponse,
        error:impersonateError,
        mutate: impersonateCompany,
    } = useCustomMutation(
        (params) => UserAPI.handlers.impersonateCompany(params),
        UserAPI.invalidators.impersonateCompany,
    );

    // Watch create member response
    useEffect(() => {
        if (!createNewMemberResponse) return;
        dispatch(setAlert("Company created succesfully", alertTypes.success))
        if (continueToAssessment && createNewMemberResponse.company) {
            impersonateCompany(createNewMemberResponse.company.id)
        } else {
            reloadCustomers(true);
            toggleModal();
        }
    }, [createNewMemberResponse]);

    // Watch create member error
    useEffect(() => {
        if (!createNewMemberError) return;
        const errorMessage = createNewMemberError?.data?.errorMessage || errorMessages.defaultPost;
        dispatch(setAlert(errorMessage, alertTypes.error));
    }, [createNewMemberError])

    //  Watching impersonate response
    useEffect(() => {
        if (!impersonateResponse) { return; }
        dispatch(onImpersonationSuccess(impersonateResponse));
        if (continueToAssessment) {
            toggleModal();
            history.push(`${defaultIndexPath}/assessment`);
        }
    }, [impersonateResponse]);

    //  Watching impersonate error
    useEffect(() => {
        if (!impersonateError) { return; }
        dispatch(setAlert('Error impersonating company', alertTypes.error));
        dispatch(onImpersonationSuccess(null));
        toggleModal();
    }, [impersonateError]);

    // Function to get all the form's fields
    const getFormFieldsArray = (onlyFieldsToValidate) => {

        const memberFieldsToValidate = mapFormFieldsFromRows(rowsToRender);
        const partnerFieldsToValidate = partnersRowsToRender;
        return [
            ...!onlyFieldsToValidate ? newMemberForm[wrapperTypes.ROW] : [],
            ...newMemberForm[wrapperTypes.NO_CONTAINER],
            ...memberFieldsToValidate,
            ...partnerFieldsToValidate
        ]
    }

    //  Function to mutate form state when a field has been removed
    const mutateFormState = (args, state, callbacks) => {
        mutateFormStateWhenAddingMembersRows(args, state, callbacks, getFormFieldsArray())
    };

    function validate(formValues) {
        const fieldsToValidate = getFormFieldsArray(true);
        return validateFieldsByFormTemplate(fieldsToValidate, formValues);
    }

    // Function to render continue to Assessment checkbox
    const renderContinueToAssessmentCheckbox = () => {
        return (
            <CustomCheckBox
                isSelected={continueToAssessment}
                className="continue-assessment-checkbox"
                targetId="continue-assessment-checkbox"
                callback={() => {
                    setContinueToAssessment(!continueToAssessment)
                }}
                label="Continue to assessment"
                isDisabled={createNewMemberLoading}
            />
        )
    }

    // Function to render submit options
    const renderSubmitOptions = () => {
        return (
            <div className="new-member-form-submit-options d-flex align-items-center justify-content-end">
                {renderContinueToAssessmentCheckbox()}
                <Button
                    color="light"
                    type="submit"
                    className="btn-create-members btn-large"
                    disabled={createNewMemberLoading}
                >
                    Create Company and invite members
                </Button>
            </div>
        )
    }


    //  Function to handle submit
    const onSubmit = (formValues) => {
        if (!rowsToRender) {
            dispatch(setAlert("Add at least one member", alertTypes.error));
            return;
        }

        const user_params = mapUserParamsFromFormValues(formValues, rowsToRender?.length, true);
        const partners_to_setup = mapPartnerParamsFromFormValues(formValues);

        const company = {
            name: formValues?.company_name,
            company_email: user_params?.admin_user?.email,
            annual_revenue: formValues?.annual_revenue || null,
            number_employees: formValues?.employee_count || null
        }

        createNewMember({
            partners_to_setup,
            company,
            ...user_params
        });
    };

    const renderForm = (params) => {
        //  Parsing params
        const { handleSubmit, invalid, form } = params;

        // Mutate form when member row is removed
        form.mutators.mutateFormState();

        //  Returning form
        return (

            <CustomForm
                formTemplate={newMemberForm}
                onSubmit={(event) => {
                    if (invalid) dispatch(setAlert(errorMessages.MISSING_FIELDS, alertTypes.error));
                    handleSubmit(event);
                }}
            >
                <AddNewMembersComponent
                    title="Company users"
                    lastFieldIndex={lastFieldIndex}
                    setLastFieldIndex={setLastFieldIndex}
                    rowsToRender={rowsToRender}
                    setRowsToRender={setRowsToRender}
                    includeAdmin={true}
                />
                <PartnerAddOnComponent
                    title="New member integration"
                    lastFieldIndex={lastPartnerFieldIndex}
                    setLastFieldIndex={setLastPartnerFieldIndex}
                    rowsToRender={partnersRowsToRender}
                    setRowsToRender={setPartnerRowsToRender}
                    partners={partnerDefaults}
                />
                {renderSubmitOptions()}
            </CustomForm>
        );
    }

    return (
        <BasicModal
            title={addNewMemberModalData.title}
            showModal={showModal}
            toggleModal={toggleModal}
            customClassName='new-members-modal'
        >
            <div className="section-title">Company details</div>
            <Form
                onSubmit={onSubmit}
                render={renderForm}
                validate={validate}
                mutators={{ mutateFormState }}
                shouldValidate
                validateOnBlur
            />
        </BasicModal>
    )
}

export default NewMemberModal
