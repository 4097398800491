import React from 'react';
import BasicTooltip from './BasicTooltip';
import { isValidFunction } from '../../../utils/helpers';

const InfoTooltip = (props) => {
    const { 
        label, 
        showLabel = true,
        showTooltip = true,
        info,
        wrapperClassName,
        tooltipClassName,
        iconClassName,
        id = "",
        placement="bottom",
        callback,
    } = props;

    const renderTooltip = () => {
        const infoIconId = `info-tooltip-${id}`;
        return (
            <div className={`more-info-icon-wrapper d-inline-flex flex-row align-items-center ${iconClassName || ''}`} 
            onClick={() => { if(isValidFunction(callback)) callback() }}>
                <i className="bx bx-info-circle" id={infoIconId}></i>
                <BasicTooltip
                    className={`more-info-tooltip ${tooltipClassName || ''}`}
                    placement={placement}
                    target={infoIconId}
                    content={info}
                />
            </div>
        )
    };

    return (
        <div className={`title d-inline-flex align-items-center ${wrapperClassName || ''}`}>
             {showLabel && <span className="mr-2">{label}</span>} 
             {showTooltip && renderTooltip()}
        </div>
    )
}
export default InfoTooltip;
