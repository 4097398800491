import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session';
import authReducer from './authReducer';
import layoutReducer from './layoutReducer';
import assessmentReducer from './assessmentReducer';
import answersReducer from './answersReducer';
import loadingReducer from './loadingReducer';
import errorsReducer from './errorsReducer';
import assetsDashboardReducer from './assetsDashboardReducer';
import riskScoreReducer from './riskScoreReducer';
import alertReducer from './alertReducer';
import calculatorReducer from './calculatorReducer';
import issuesReducer from './issuesReducer';
import customerManagementReducer from "./customerManagementReducer";
import partnersReducer from "./partnersReducer";
import cydekicksReducer from './cydekicksReducer';
import devicesReducer from './devicesReducer';
import commentsReducer from './commentsReducer';
import scoresReducer from './scoresReducer';
import impersonationReducer from './impersonationReducer';
import navigationReducer from './navigationReducer';
import businessRiskReducer from './businessRiskReducer';
import automationReducer from './automationReducer';
import ruleSetReducer from './ruleSetReducer';
import grcIssuesReducer from './grcIssuesReducer';
import dashboardsReducer from './dashboardsReducer';
import recycleBinReducer from './recycleBinReducer';
import connectionsReducer from './connectionsReducer';
import groupsReducer from './groupsReducer';
import issuesGroupReducer from './issuesGroupReducer';

const issuesPersistConfig = {
    key: 'issues',
    storage: storage,
    blacklist: ['list', 'total', 'totalsBy', 'modalOptions', 'currentPage']
}

const issuesGroupPersistConfig = {
    key: 'issuesGroup',
    storage: storage,
    blacklist: ['list', 'total', 'totalsBy', 'modalOptions', 'currentPage']
}

const grcIssuesPersistConfig = {
    key: 'grcIssues',
    storage: storage,
    blacklist: ['total', 'totalsBy', 'modalOptions', 'currentPage']
}

const partnersPersistConfig = {
    key: 'partners',
    storage,
    whitelist: ['partnerId', 'partnerName'],
}

const dashboardsPersistConfig = {
    key: 'dashboards',
    storage,
    whitelist: ['range_value', 'start_date', 'end_date', 'timezone'],
};

const connectionsPersistConfig = {
    key: 'connections',
    storage: storage,
    whitelist: ['filters', 'activeFilters', 'searchTerm', 'applyDefaultFilters'],
}

const groupsPersistConfig = {
    key: 'groups',
    storage,
    whitelist: ['filters', 'active_filters'],
};

const rootReducer = combineReducers({
    form: formReducer,
    auth: authReducer,
    assessment: assessmentReducer,
    layout: layoutReducer,
    answers: answersReducer,
    loading: loadingReducer,
    errors: errorsReducer,
    assetsDashboard: assetsDashboardReducer,
    riskScore: riskScoreReducer,
    alerts: alertReducer,
    calculator: calculatorReducer,
    issues: persistReducer(issuesPersistConfig, issuesReducer),
    customers: customerManagementReducer,
    partners: persistReducer(partnersPersistConfig, partnersReducer),
    cydekicks: cydekicksReducer,
    devices: devicesReducer,
    comments: commentsReducer,
    scores: scoresReducer,
    impersonation: impersonationReducer,
    navigation: navigationReducer,
    businessRisk: businessRiskReducer,
    automation: automationReducer,
    ruleSet: ruleSetReducer,
    grcIssues: persistReducer(grcIssuesPersistConfig, grcIssuesReducer),
    connections: persistReducer(connectionsPersistConfig, connectionsReducer),
    dashboards: persistReducer(dashboardsPersistConfig, dashboardsReducer),
    recycleBin: recycleBinReducer,
    groups: persistReducer(groupsPersistConfig, groupsReducer),
    issuesGroup: persistReducer(issuesGroupPersistConfig, issuesGroupReducer),
});

export default rootReducer;
