
import _ from 'lodash';
import { removeAlert } from '../actions/alerts';
import { SET_ALERT, REMOVE_ALERT, REMOVE_ALL_ALERTS, LOGOUT_SUCCESS } from "../actions/types";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_ALERT:
            const { id } = payload;
            //  Updating state
            return { ...state, [id]: payload }
        case REMOVE_ALERT:
            return _.omit(state, payload);
        case REMOVE_ALL_ALERTS:
        case LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}