import React, { useState } from 'react';
import { Button, Modal } from 'reactstrap';
import { getListText } from '../../utils/helpers';
import DismissButton from '../layout/buttons/DismissButton';

const AddressesDetailModal = (props) => {
  const {
    title,
    addresses
  } = props;

  // Component state
  const [showModal, setShowModal] = useState(false);

  // Toggle modal
  const toggleModal = () => {
    setShowModal(!showModal)
  }

  // Render dismisss button
  const renderDismissButton = () => {
    return (
      <DismissButton
        callback={toggleModal}
        className="btn-small"
      />
    )

}

  // Render addresses modal
  const renderModal = () => {
    if(!showModal) return null;
    return (
      <Modal
        isOpen={true}
        toggle={toggleModal}
        className="addresses-detail-modal confirm-action-modal"
        centered={true}
        size="xl"
      >
        <div className="confirm-modal-header d-flex">
          <div className="modal-title">{title}</div>
          {renderDismissButton()}
        </div>
        <div className="modal-subtitle">
          {getListText(addresses)}
        </div>
        <div className="buttons-container d-flex justify-content-end">
          <Button
            color="light"
            onClick={toggleModal}
            className="btn-large"
          >
            Close
          </Button>
        </div>
      </Modal>
    )
  }
  return (
   <>
    {renderModal()}
    <div className='addresses-details-text'>
      {addresses?.length > 0 ? `${addresses[0]} (+${addresses?.length - 1}) ` : ''}
      <Button
        color="link"
        className="btn-view-addresses p-0"
        onClick={toggleModal}
      >
        View all
      </Button>
    </div>
   </>
  )
}

export default AddressesDetailModal
