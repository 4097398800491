import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Input, Button } from 'reactstrap';
import { setAlert } from '../../actions/alerts';
import { reloadCustomers } from '../../actions/customerManagement';
import API from '../../api';
import _ from 'lodash';
import BasicModal from "../layout/BasicModal";
import CustomSpinner from '../layout/CustomSpinner';
import useCustomMutation from '../../hooks/useCustomMutation';


const TextConfirmationModal = (props) => {

  const {
    modalConfig: {
      action,
      member,
    },
    showActionModal,
    handleCancelClick,
    reloadCustomers
  } = props;

  const [confirmationMessage, setConfirmationMessage] = useState('');

  const {
    title,
    message,
    complementaryMessage,
    proceedText,
    cancelText,
    confirmationText,
    customClassName,
    mainActionColor
  } = action?.content || {}

  //  Initializing memoized dispatch
  const defaultDispatch = useDispatch();
  const dispatch = useCallback(defaultDispatch, []);

  //  Hook for calling the api
  const {
    data: response,
    error,
    isLoading: loading,
    mutate: callAPI,
  } = useCustomMutation(
    (params) =>  API[action.api].handlers[action.action](params),
    API[action.api].invalidators[action.action]
  );

  //  Watching response changes
  useEffect(() => {
    if (!response) { return; }
    dispatch(setAlert(response.message, 'success'));
    toggleModal();
    reloadCustomers(true);
  }, [response]);

  //  Watching error changes
  useEffect(() => {
    if (!error) { return; }
    const errorMessage = error?.data?.errorMesage || 'Error updating members'
    dispatch(setAlert(errorMessage, 'danger'));
    toggleModal();
  }, [error]);

  const toggleModal = () => {
    handleCancelClick(!showActionModal);
    setConfirmationMessage('');
  }

  const handleMessageChange = (e) => {
    let message = e?.target?.value || '';
    setConfirmationMessage(message.toUpperCase());
  }

  const handleConfirmClick = () => {
    callAPI(action?.body);
  }


  const renderConfirmMessage = () => {
    return (
      <div className='d-flex flex-column align-items-center confirm-message'>
        <div className='label'>{`Type ${confirmationText} to confirm`}</div>
        <Input
          type="text"
          className="form-control input"
          placeholder={confirmationText}
          value={confirmationMessage}
          onChange={handleMessageChange}
        />
      </div>
    )
  }

  const renderCancelCTA = () => {
    return (
      <div className="d-flex justify-content-center">
        <Button color={mainActionColor || "light"} className="mr-2" onClick={handleConfirmClick} disabled={confirmationMessage !== confirmationText} >{proceedText}</Button>
        <Button color="light-gray" outline onClick={toggleModal}>{cancelText}</Button>
      </div>
    );
  }

  const renderMessage = (text) => {
    return <div className='message d-flex justify-content-center text-center'> {text} </div>;
  }

  const renderModal = () => {
    const modalTitle = loading ? '' : title;
    return (
      <BasicModal
        title={modalTitle}
        showModal={showActionModal}
        toggleModal={() => toggleModal()}
        customClassName={customClassName || 'cancel-membership'}
      >
        {!loading && renderMessage(message)}
        {!loading && complementaryMessage && renderMessage(complementaryMessage)}
        {!loading && renderConfirmMessage()}
        {!loading && renderCancelCTA()}
        {loading && <CustomSpinner customStyle={{ minHeight: "200px" }} />}
      </BasicModal>
    )
  }


  return (
    <React.Fragment>
      {action?.content && renderModal()}
    </React.Fragment>
  )
}

export default connect(null, { reloadCustomers })(TextConfirmationModal);
