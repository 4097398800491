import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Dashboard from '../components/dashboard/Dashboard';
import CustomTable from '../components/CustomTable/CustomTable';
import CustomPagination from '../components/layout/CustomPagination';
import GroupsManagerFilters from '../components/app-v4/groups/GroupsManagerFilters';
import GroupsManagerActiveFilters from '../components/app-v4/groups/GroupsManagerActiveFilters';
import GroupsBulkMenu from '../components/app-v4/groups/GroupsBulkMenu';
import PartnerFiltersModal from '../components/app-v4/groups/partner-filters/PartnerFiltersModal';
import API from '../api';
import { groupsTableTemplate } from '../constants/tableTemplates';
import { tablePaginationTypes, customTableEvents } from '../constants/common';
import { groupDetailsQueryKey, initialFilters, initialParams } from '../constants/groups';
import {
  mapObjSortToArray,
  getUpdatedSearchParams,
  getSafeArray,
  mutateSort,
} from '../utils/helpers';
import {
  getNewFilters,
  addOptionToActiveFilters,
  getSelectedGroups,
} from '../utils/groupHelpers';
import { setAlert } from '../actions/alerts';
import { onGroupsFiltersChange, setGroupPagination } from '../actions/groupsActions';
import useMemoizedDispatch from '../hooks/useMemoizedDispatch';
import useCustomQuery from '../hooks/useCustomQuery';
import useSelectedList from '../hooks/useSelectedList';
import useGroupsLibrary from '../hooks/useGroupsLibrary';
import usePartnerManualActions from '../hooks/usePartnerManualActions';
import useModalDetailsParams from '../hooks/useModalDetailsParams';
import GroupDetails from '../components/app-v4/groups/group-details/GroupDetails';

//  Component
const GroupsManager = (props) => {
  //  Initializing API
  const { GroupAPI } = API;

  //  Watching redux store
  const groupsFilters = useSelector((state) => state?.groups?.filters);
  const groupActiveFilters = useSelector((state) => state?.groups?.active_filters);
  const currentPageIndex = useSelector(state => state.groups.currentPage);

  //  Component references
  const tableRef = useRef();

  //  Component state
  const [queryParams, setQueryParams] = useState({ ...initialParams, filters: groupsFilters });
  const [activeFilters, setActiveFilters] = useState(groupActiveFilters || []);
  const [showPartnersFilters, setShowPartnerFilters] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  //  Component hooks
  const history = useHistory();
  const location = useLocation();
  const { dispatch } = useMemoizedDispatch();
  const { partner_configs } = useGroupsLibrary(queryParams?.filters?.partner_id || []);
  const {
    showModal: showGroupDetails,
    toggleModal: toggleGroupDetailsModal,
    removeModalParams: removeGroupDetailsParams,
    code: groupPublicId,
  } = useModalDetailsParams({
    queryKey: groupDetailsQueryKey
  })

  // Get partner manual actions
  usePartnerManualActions();

  //  Hook to make API query
  const {
    data: groupsData,
    error: groupsError,
    isLoading: areGroupsLoading,
  } = useCustomQuery(
    [GroupAPI.keys.getGroupsList, queryParams],
    ({ queryKey }) => GroupAPI.handlers.getGroupsList(queryKey[1]),
  );

  //  Selected behavior hooks
  const {
    selected,
    excluded,
    allSelected,
    eventHandler: handleSelectedEvent,
    clearSelected,
    setSelected,
  } = useSelectedList({
    eventMap: {
      changeAll: customTableEvents.CHANGE_SELECT_ALL,
      add: customTableEvents.SELECT_ELEMENT,
      remove: customTableEvents.UNSELECT_ELEMENT,
    }
  });

  //  Watching API errors
  useEffect(() => {
    if (!groupsError) { return; }
    dispatch(setAlert('Error getting groups', 'danger'));
  }, [groupsError]);

  //  Watching changes to restore selected state
  useEffect(() => {
    clearSelected();
  }, [queryParams?.offset, queryParams?.text_to_search, queryParams?.filters, queryParams?.sort]);

  //  Function to change current selected group
  const changeSelectedGroup = (newIndex) => {
    const newGroup = getSafeArray(groupsData?.groups)[newIndex];
    if (!newGroup) { return; }
    history.push({
      search: getUpdatedSearchParams(location?.search, ['details', groupDetailsQueryKey], `&details&${groupDetailsQueryKey}=${newGroup?.public_id}`),
      state: { index: newIndex, maxIndex: groupsData?.groups?.length - 1 }
    });
  };

  // Watching group data to update group pagination
  useEffect(() => {
    if (!groupsData?.groups) { return; }
    const currentPage = (queryParams?.offset / queryParams?.limit);

    //  Selecting new group
    if (showGroupDetails && currentPage !== currentPageIndex) {
      const newIndex = currentPage > currentPageIndex ? 0 : groupsData?.groups?.length - 1;
      changeSelectedGroup(newIndex);
    };

    dispatch(setGroupPagination({
      groupsCount: groupsData?.groups?.length,
      pageCount: Math.ceil((groupsData?.count || 0) / queryParams?.limit),
      currentPage,
    }))
  }, [groupsData?.groups])

  //  Watching group counts changes
  useEffect(() => {
    if (!groupsData) { return; }
    if (groupsData?.count === null || groupsData?.count === undefined) { return; }
    setTotalCount(groupsData?.count);
  }, [groupsData?.count]);

  //  Component did mount
  useEffect(() => {
    dispatch(onGroupsFiltersChange(queryParams?.filters, activeFilters));
  }, [queryParams?.filters, activeFilters?.length]);

  //  Function to request more
  const requestMore = ({ selected }) => {
    const newOffset = selected * queryParams?.limit;
    setQueryParams({ ...queryParams, offset: newOffset });
  };

  //  Function to change sorting
  const changeSort = (key, value) => {
    const newSort = mutateSort(key, value, queryParams?.sort);
    setQueryParams({ ...queryParams, sort: newSort });
  };

  //  Function to set filter
  const setFilters = (newFilters = {}, changeOffset = true) => {
    const newOffset = changeOffset ? 0 : queryParams?.offset;
    setQueryParams({
      ...queryParams,
      offset: newOffset,
      filters: {
        ...queryParams?.filters,
        ...newFilters,
      },
    });
  };

  //  Function to change filters
  const changeFilters = (key, option, isArray = false) => {
    if (!option) { return; }

    //  Creating and setting new filters
    const newFilters = getNewFilters(key, option?.value, isArray, queryParams?.filters);
    setFilters(newFilters, true);

    //  Setting active filters
    const newActiveFilters = addOptionToActiveFilters(key, option, activeFilters);
    setActiveFilters(newActiveFilters);
  };

  //  Function to change text search
  const changeTextSearch = (value = '') => {
    setQueryParams({ ...queryParams, text_to_search: value });
  };

  //  Function to handle onRightClick
  const onTableRightClick = (group_id) => {
    clearSelected();
    setSelected([parseInt(group_id)]);
  };

  //  Function to toggle partner filters modal
  const togglePartnerFilters = (flag = false) => {
    setShowPartnerFilters(flag);
  };

  //  Function to change current page
  const changeCurrentPage = (newPage) => {
    requestMore({ selected: newPage });
  }

  //  Function to render groups table topbar
  const renderTopbar = () => {
    return (
      <div className="groups-table-topbar">
        <div className="title-container">
          <div className="title">Listing all groups</div>
          <div className="count">{`${totalCount || 0} groups`}</div>
        </div>
        <div className="group-active-filters-container">
          <GroupsManagerActiveFilters
            filters={queryParams?.filters}
            activeFilters={activeFilters}
            initialFilters={initialFilters}
            setFilters={setFilters}
            setActiveFilters={setActiveFilters}
          />
        </div>
        <div className="pagination-container">
          <CustomPagination
            currentPage={(queryParams?.offset / queryParams?.limit)}
            pageCount={Math.ceil((totalCount || 0) / queryParams?.limit)}
            onPageChange={requestMore}
          />
        </div>
      </div>
    );
  };

  //  Function to render row containing bulk menu and partner actions
  const renderDynamicRow = () => {
    return (
      <div className="groups-dynamic-container">
        <GroupsBulkMenu
          parentRef={tableRef}
          onRightClick={onTableRightClick}
          groups={getSelectedGroups(allSelected, selected, excluded, groupsData?.groups)}
          clearSelected={clearSelected}
        />
      </div>
    );
  };

  //  Function to render partner filters modal
  const renderPartnerFiltersModal = () => {
    if (!showPartnersFilters) { return null; }
    return (
      <PartnerFiltersModal
        initialFilters={initialFilters}
        filters={queryParams?.filters}
        activeFilters={activeFilters}
        partner_configs={partner_configs}
        setFilters={setFilters}
        toggleModal={togglePartnerFilters}
        setActiveFilters={setActiveFilters}
      />
    );
  };

  // Render group details modal
  const renderGroupDetailsModal = () => {
    if (!showGroupDetails) { return null; }
    return (
      <GroupDetails
        publicId={groupPublicId}
        toggleModal={toggleGroupDetailsModal}
        removeQueryParams={removeGroupDetailsParams}
        changeSelectedGroup={changeSelectedGroup}
        changeCurrentPage={changeCurrentPage}
        areGroupsLoading={areGroupsLoading}
      />
    );
  };

  //  Rendering
  return (
    <Dashboard
      title="Groups Manager"
      containerClassName="dashboard mx-2 px-0 py-4 text-normal-style page-content contained-view-width"
    >
      <div className="groups-manager-container">
        <GroupsManagerFilters
          filters={queryParams?.filters || {}}
          partner_configs={partner_configs}
          setFilters={changeFilters}
          setTextSearch={changeTextSearch}
          togglePartnerFilters={togglePartnerFilters}
        />
        {renderDynamicRow()}
        {renderTopbar()}
        <div
          className="groups-table-container"
          ref={tableRef}
        >
          <CustomTable
            tableClassName="groups-table"
            headerClassName="groups-table-header"
            rowClassName="groups-table-row"
            columnsTemplate={groupsTableTemplate}
            data={groupsData?.groups || []}
            isScrollable={true}
            requestMore={requestMore}
            changeSort={changeSort}
            sort={mapObjSortToArray(queryParams?.sort) || []}
            loading={areGroupsLoading}
            emptyConfig={{
              message: `Issues Groups will appear here`,
              messageClassName: 'empty-message'
            }}
            paginationType={tablePaginationTypes.PAGES}
            allSelected={allSelected}
            selected={selected}
            excluded={excluded}
            handleSelectedEvent={handleSelectedEvent}
            error={groupsError}
            allowMultipleSort={true}
          />
        </div>
      </div>
      {renderPartnerFiltersModal()}
      {renderGroupDetailsModal()}
    </Dashboard>
  );
};

export default GroupsManager;
