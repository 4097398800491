import React, { useState } from 'react';
import PasswordField from '../components/auth/forms/PasswordField';
import Select, { components } from 'react-select';
import TextArea from '../components/profile/TextArea';


export const renderInput = (props) => {
    const { input, name, label, placeholder, type, meta: { error, touched }, tooltip, feedback, customClassName, baseClassName, ...custom } = props;
    return (
        <div className="form-group" style={{ marginBottom: "20px" }} >
            <div className="label-container">
                <label htmlFor={name} className="input-label">{label}</label>
                {tooltip}
            </div>
            <input
                {...input}
                type={input.type || type}
                placeholder={placeholder}
                name={input?.name || label}
                autoComplete="off"
                style={{ marginBottom: "5px" }}
                className={`${baseClassName || "form-control"} ${customClassName || ""} ${(touched && error) || custom.customError ? "is-invalid" : ''}`}
            //onKeyUp={evt => { if (input.type === "integer") evt.target.value = evt.target.value.replace(/[^0-9]/g,"") }}
            />
            <div className="feedback">{feedback}</div>
            <div className="invalid-feedback">{error || custom.customError}</div>
        </div>
    );
};

export const renderInputV2 = (props, apiVerify) => {
    const { apiMessage, apiIsInvalid } = apiVerify;
    const isApiValid = apiIsInvalid === false;
    const { input, name, label, placeholder, type, meta: { error, touched }, tooltip, feedback, customClassName, baseClassName, ...custom } = props;
    const isInvalid = (touched && error) || custom.customError || apiIsInvalid;
    const inputClassName = `${baseClassName || "form-control"} ${customClassName || ""} ${isInvalid ? "is-invalid" : isApiValid ? 'is-valid' : ''}`
    return (
        <div className="input-field-v2 form-group" style={{ marginBottom: "20px" }} >
            <div className="label-container">
                <label htmlFor={name} className="input-label">{label}</label>
                {tooltip}
            </div>
            <input
                {...input}
                type={input.type || type}
                placeholder={placeholder}
                name={input?.name || label}
                autoComplete="off"
                style={{ marginBottom: "5px" }}
                className={inputClassName}
            />
            {isInvalid && (
                <i className='field-icon invalid bx bx-error'/>
            )}
            {!isInvalid && isApiValid && (
                <i className='field-icon valid bx bx-check'/>
            )}

            <div className="feedback">{feedback}</div>
            {isInvalid && (
                <div className="invalid-feedback">{error || custom.customError || apiMessage}</div>
            )}
            {!isInvalid && isApiValid && (
                <div className="valid-feedback">{apiMessage}</div>
            )}
            
        </div>
    );
};


const renderSelectOptions = (options) => {
    const renderedOptions = [];
    renderedOptions.push(<option key={"empty"} value="" ></option>);
    options.forEach((option) => {
        renderedOptions.push(<option key={option.id} value={option.id} >{option.label}</option>);
    });
    return renderedOptions;
};

export const renderSelectField = ({ input, name, label, meta: { error, touched }, options, tooltip }) => {

    return (
        <div className='form-group' style={{ marginBottom: "20px" }} >
            <div className="label-container">
                <label htmlFor={name} >{label}</label>
                {tooltip}
            </div>
            <select
                {...input}
                style={{ marginBottom: "5px" }}
                className={`form-control select ${touched && error ? "is-invalid" : ''}`}
            >
                {renderSelectOptions(options)}
            </select>
            <div className="invalid-feedback">{error}</div>
        </div>
    )
}


export const renderPasswordField = ({ input, name, label, placeholder, tooltip, meta: { error, touched } }) => {
    return (
        <div className="form-group" style={{ marginBottom: "20px" }} >
            <div className="label-container">
                <label htmlFor={name} className="input-label">{label}</label>
                {tooltip}
            </div>
            <PasswordField
                input={input}
                placeholder={placeholder}
                name={name}
                error={error}
                touched={touched}
            />
        </div>
    );
};


export const renderCustomSelect = (props) => {

    const {
        input,
        name,
        label,
        placeholder,
        meta: { error, touched },
        tooltip,
        feedback,
        customClassName = '',
        selectedValue,
        options,
        handleSelectValue,
        ...custom
    } = props;

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <i className='bx bxs-down-arrow'></i>
            </components.DropdownIndicator>
        );
    };

    return (
        <div className="form-group" style={{ marginBottom: "20px" }} >
            <div className="label-container">
                <label htmlFor={name} className="input-label">{label}</label>
                {tooltip}
            </div>
            <div className="select-container">
                <Select
                    value={selectedValue}
                    onChange={handleSelectValue}
                    options={options}
                    className={`react-select-container ${customClassName}`}
                    classNamePrefix="react-select"
                    components={{ DropdownIndicator }}
                    placeholder={placeholder}
                    isSearchable={true}
                    {...input}
                />
            </div>
            <div className="feedback">{feedback}</div>
            <div className="invalid-feedback">{error || custom.customError}</div>
        </div>

    )
}

export const renderTextArea = (props) => {
    const { input,
        name,
        label,
        placeholder,
        meta: { error, touched },
        tooltip,
        feedback,
        customClassName,
        minRows = 1,
        maxRows = 5,
        maxLength,
        callback,
        callOnClick = false,
        selectedValue,
        disabled,
        onKeyDown,
        handleCallback,
        inputTagPadding,
        neeedsMaxLength,
        ...custom
    } = props;

    return (
        <div className="form-group" style={{ marginBottom: "20px" }} >
            <div className="label-container">
                <label htmlFor={name} className="input-label">{label}</label>
                {tooltip}
            </div>
            <TextArea
                placeholder={placeholder}
                autoComplete="off"
                style={{ marginBottom: "5px" }}
                maxLength={maxLength}
                rows={1}
                minRows={minRows}
                maxRows={maxRows}
                className={`form-control text-area-grow ${customClassName} ${(touched && error) || custom.customError ? "is-invalid" : ''}`}
                callback={callback}
                callOnClick={callOnClick}
                value={input.value}
                disabled={disabled}
                onKeyDown={onKeyDown}
                handleCallback={handleCallback}
                inputTagPadding={inputTagPadding}
                maxLength={neeedsMaxLength ? 50 : null}
            />
            <div className="feedback">{feedback}</div>
            <div className="invalid-feedback">{error || custom.customError}</div>
        </div>
    );
};

