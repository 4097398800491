import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchPartners, reloadPartners, setPartnersSort, setPartnersFilter, clearPartnersFilters } from "../../actions/partners";
import TableList from '../layout/TableList';
import PartnerExclusion from './PartnerExclusion';
import { getIssueModalOptions } from '../../actions/issues';
import makeErrorMessage from '../../selectors/errorsSelector';
import makeLoadingState from '../../selectors/loadingSelector';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import { issueTypes } from '../../constants/issues';
import useCanAbility from '../../hooks/useCanAbility';
import { PRIVILEGE_SOURCES, READ_ACTION } from '../../constants/privileges';
import useUserType from '../../hooks/useUserType';

const PartnerList = (props) => {

  // Component Hooks
  const { dispatch } = useMemoizedDispatch();
  const getLoadingState = makeLoadingState(['PARTNERS_LIST']);
  const getErrorMessage = makeErrorMessage(['PARTNERS_LIST']);
  const location = useLocation();
  const ability = useCanAbility();
  const userType = useUserType();

  //  Watching redux store
  const partners = useSelector((state) => state.partners);
  const solutions = useSelector((state) => state.issues.modalOptions.solutions);
  const isLoading = useSelector((state) => getLoadingState(state));
  const errorMessage = useSelector((state) => getErrorMessage(state));

  // Component variable
  const canReadPartner = ability.can(READ_ACTION, PRIVILEGE_SOURCES.PARTNERS.GENERAL);

  useEffect(() => {
    if (solutions.length === 0) dispatch(getIssueModalOptions(null, issueTypes.VULNERABILITIES, userType));
  }, [solutions]);

  //  Component state
  const [selectedPartner, setSeletedPartner] = useState(null);
  const [showModal, setShowModal] = useState(false);

  //  Function to get view title
  const getPageTitle = () => {
    if (!location?.state?.name) { return 'Listing all partners'; }
    return `${location?.state?.name}'s solutions`;
  };

  const renderSolution = (row) => {
    return <div> {row?.solutions?.length > 0 ? row?.solutions[0].name : '-'} </div>
  }

  const handleShowModal = () => {
    setShowModal(!showModal);
    setSeletedPartner(null);
  }

  const handleOnRowClick = (row) => {
    if(!canReadPartner) return;
    setSeletedPartner(row);
    setShowModal(true);
  }

  const getSolutionsFilter = () => {
    if (solutions.length === 0) return null;
    const solutionsOptions = solutions.map(_solution => { return { label: _solution.name, value: _solution.name } })
    const solutionFilter = {
      fieldName: 'solution_name',
      type: 'select',
      className: 'solution-filter',
      placeholder: 'Solution',
      options: [{ options: solutionsOptions }]
    };
    return [solutionFilter];
  }

  const columns = [
    {
      displayName: "Partner name",
      fieldName: 'name',
      headerClassNameWrapper: 'name-header',
      sortFieldName: 'name',
    },
    {
      displayName: "Solution",
      fieldName: 'solution',
      sortFieldName: 'solution_name',
      headerClassNameWrapper: 'solution-header',
      renderCustomComponent: renderSolution
    },
    {
      displayName: "Running for members",
      fieldName: 'members',
      sortFieldName: 'members',
      headerClassNameWrapper: 'name-header',
    }
  ]

  return (
    <>
      {showModal && <PartnerExclusion showModal={showModal} toggleModal={handleShowModal} selectedPartner={selectedPartner} />}
      <TableList
        fetchList={(params) => dispatch(fetchPartners(params))}
        reloadList={(params) => dispatch(reloadPartners(params))}
        data={partners}
        columns={columns}
        customClassName="customer-partners-list"
        setFilter={(...params) => dispatch(setPartnersFilter(...params))}
        clearAllFilters={() => dispatch(clearPartnersFilters())}
        setSort={(params) => dispatch(setPartnersSort(params))}
        activeFiltersDisplayInfo={{
          display: true,
          labels: {
            noFilters: 'Listing all partners',
            filteringBy: `${getPageTitle()} filtered by:`,
            total: {
              singular: 'partner',
              plural: 'partners'
            }
          }
        }}
        filtersComponentList={getSolutionsFilter()}
        isLoading={isLoading}
        errorMessage={errorMessage}
        searchPlaceholder="Search by name"
        onRowClick={handleOnRowClick}
        readOnlyRow={!canReadPartner}
      />
    </>
  )
};

export default PartnerList;
