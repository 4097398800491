import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import { CustomOption, CustomOption2 } from './vcyso-utils/textEditorComponents'
import { _contentState, bold, underline, ordered, link, unordered } from './vcyso-utils/utils'

export const TextEditorToolbarDown = (props) => {
    const {
        saveCallback,
        cancelCallback,
        content,
        defaultContent,
        processing,
        hidden,
        placeholder
    } = props;

    const [hiddenState, setHiddenState] = useState(hidden);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [editorClassName, setEditorClassName]=useState('editor');

    const createEditorState = (value) => {
        return _.isNil(value) ? EditorState.createEmpty() : EditorState.createWithContent(convertFromRaw(value))
    }

    useEffect(() => {
        setEditorState(createEditorState(content))
    }, [content])

    const onSaveClick = () => {
        if(!hasContent(editorState)){
            saveCallback(defaultContent);
        }else {
            saveCallback(convertToRaw(editorState.getCurrentContent()), hiddenState);
        }
    }

    const onCancelClick = () => {
        //reset to initial state
        setHiddenState(hidden)
        setEditorState(createEditorState(content))
        cancelCallback()
    }

    const onHideClick = () => {
        setHiddenState(!hiddenState)
    }

    const onRevertToDefaultClick = () => {
        setEditorState(createEditorState(defaultContent))
    }

    const hasContent = (editorState) => {
        const contentState = editorState.getCurrentContent();
        return (
            contentState.hasText() ||
            contentState
                .getBlockMap()
                .first()
                .getType() !== 'unstyled'
        );
    }

    const onEditorStateChange = (state) => {
        setEditorState(state);
        if(!hasContent(state)){
            setEditorClassName(`${editorClassName} editor--hide-placeholder`);
        }else{
            setEditorClassName(editorClassName.replace('editor--hide-placeholder',''));
        }
    };

    return (
        <div className=''>
            <div className={``}>
                <div>
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={onEditorStateChange}
                        toolbarClassName="editor-toolbar"
                        wrapperClassName="editor-wrapper"
                        editorClassName="editor-class"
                        placeholder={placeholder}
                        toolbarCustomButtons={[
                            <CustomOption
                                hidden={hiddenState}
                                hideCallback={onHideClick}
                                revertToDefaultCallback={onRevertToDefaultClick}
                            />,
                            <CustomOption2 
                                saveCallback={onSaveClick}
                                cancelCallback={onCancelClick}
                                processing={processing}
                            />
                        ]}
                        toolbar={{
                            options: ['inline', 'list', 'link'],
                            classname: "inline-btn",
                            inline: {
                                inDropdown: false,
                                options: ['bold', 'underline'],
                                bold: { icon: bold, className: 'dark-option' },
                                underline: { icon: underline, className: 'dark-option' }
                            },
                            list: {
                                inDropdown: false,
                                options: ['unordered', 'ordered'],
                                ordered: { icon: ordered, className: 'dark-option' },
                                unordered: { icon: unordered, className: 'dark-option' }
                            },
                            link: {
                                inDropdown: false,
                                options: ['link'],
                                link: { icon: link, className: 'dark-option-link' },
                                popupClassName: "dark-popup",
                        }
                    }}
                    />
                </div>
            </div>
        </div>
    );
};