import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { applyIssuesAdvancedFilters } from '../../../../actions/issues';
import { issueTypes } from '../../../../constants/issues';
import useMemoizedDispatch from '../../../../hooks/useMemoizedDispatch';

const IssuesListButton = (props) => {
  const {
    issuesCount,
    groupId
  } = props;

  // Component Hooks
  const { dispatch } = useMemoizedDispatch();
  const history = useHistory();

  // Apply group filters to individual issues list
  const applyGroupFiltersToList = () => {
    const newFilters = {
      group_id: groupId,
    };
    const newActiveFilters = [];
    dispatch(applyIssuesAdvancedFilters(newFilters, newActiveFilters, issueTypes.GROUP_INDIVIDUAL));
  }
  const onClick = () => {
    if (!groupId) { return; }
    history.push(`/groups-manager/${groupId}/issues`);
    applyGroupFiltersToList();
  };
  return (
    <Button
      color="light"
      className="btn-go-issues-group btn-small d-flex align-items-center"
      onClick={onClick}
    >
      <i className='bx bx-bug mr-1'/>
      {`Go to Issues (${issuesCount})`}
    </Button>
  )
}

export default IssuesListButton;
