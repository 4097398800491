import React from "react";
import CustomCheckBox from "../layout/CustomCheckBox";

const ConnectionAutomaticSetup = (props) => {
  const { fieldName, index = 0, autoSetup, handleAutoSetup } = props;

  return (
    <div className="auto-setup-section" key={`automatic-setup-${fieldName}-${index}`}>
      <CustomCheckBox
        isSelected={!autoSetup}
        className="partner-checkbox"
        targetId="partner-manual-setup"
        callback={handleAutoSetup}
        label="Manual Setup"
      />
      <CustomCheckBox
        isSelected={autoSetup}
        className="partner-checkbox"
        targetId="partner-automatic-setup"
        callback={handleAutoSetup}
        label="Auto Setup"
      />
    </div>
  );
};

export default ConnectionAutomaticSetup;
