import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

const ManageCompanyActions = (props) => {
  const {
    member,
    onClickCallback
  } = props;

  const cancelModal = {
    title: 'Are you sure?',
    message: `Are you sure you want to cancel the ${member.name || 'Company'} membership?`,
    complementaryMessage: 'All the information and personal data will be deleted after 30 days.',
    proceedText: 'Yes, cancel it',
    cancelText: 'No',
    confirmationText: 'CANCEL MEMBERSHIP',
    mainActionColor: 'danger'
  }

  const restoreAccountModal = {
    title: 'Are you sure?',
    message: `Are you sure you want to restore the ${member.name || 'Company'} membership?`,
    proceedText: 'Yes, restore it',
    cancelText: 'No',
    confirmationText: 'RESTORE MEMBERSHIP',
    customClassName: null,
  }


  const membershipActions = [
    { api: 'CompanyAPI', action: 'postCancelMembership', label: 'Cancel Membership', iconName: 'bx-window-close', active: true, body: { company_id: member.id }, content: cancelModal },
    { api: 'CompanyAPI', action: 'postRestoreCompany', label: 'Restore Company', iconName: 'bx-archive-out', active: false, body: { public_id: member.public_id }, content: restoreAccountModal },
    // { action: 'deleteFullCompany', label: 'Delete Company', iconName: 'bx-trash-alt', active: false, body: {}  },
  ];

  const handleOnClick = (action) => {
    if (onClickCallback) onClickCallback({ action });
  }

  const renderMembershipActions = () => {
    return (
      <React.Fragment>
        { membershipActions.filter(actionItem => actionItem.active === member.active).map((action, index) => {
          let id = `button-action-${member.public_id}-${index}`
          return (
            <div key={id} className="setup-div">
              <div
                id={id}
                key={id}
                tabIndex="-1"
                aria-label={action.label}
                onClick={ () => handleOnClick(action) }
              >
                <i className={`bx ${action.iconName} bx-sm`} />
              </div>
              <UncontrolledTooltip placement="bottom" target={id} key={`tooltip-${id}`}>
                {action.label}
              </UncontrolledTooltip >
            </div>
          )
        }
        )}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {renderMembershipActions()}
    </React.Fragment>
  )
}

export default ManageCompanyActions
