import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { useSelector } from 'react-redux';
import { withRouter, Link, useLocation } from 'react-router-dom';
import Avatar from './avatars/Avatar';
import {getPathTitle} from '../../utils/helpers';
import amplitude from 'amplitude-js';

const ProfileMenuDropdown = (props) => {

    const {
        showFullName
    } = props;

    // Component State
    const [menu, setMenu] = useState(false);

    //  Getting router location
    const location = useLocation();

    // Getting needed info from redux store
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const layoutType = useSelector(state => state.layout.layoutType);
    const profileImage = useSelector(state => state.profileImage);
    const user = useSelector(state => state.auth.user);

    const renderAvatar = (className) => {
        return (
            <Avatar
                user={user}
                customClassName={className}
                profileImage={profileImage}
            />
        )
    }

    const renderAvatarDropdown = () =>  {
        return (
            <DropdownToggle className="btn header-item waves-effect d-flex align-items-center" id="page-header-user-dropdown" tag="button">
                {renderAvatar('header-profile-user initial d-inline-flex')}
                {user?.name && layoutType === "vertical" && (
                    <>
                        <span className="d-none d-xl-inline-block ml-2 mr-1">{user?.name.split(' ').slice(0, -1).join(' ')}</span>
                        <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </>
                )}
            </DropdownToggle>
        )
    }

    const renderFullNameDropdown = () => {
        return (
            <DropdownToggle className="btn full-name-dropdown header-item waves-effect" id="page-header-user-dropdown" tag="button">
                {renderAvatar('full-name-dropdown-avatar')}
                <span className="full-name-text d-none d-md-inline-block">{user?.name}</span>
                <i className="bx bxs-down-arrow d-none d-md-inline-block"></i>
            </DropdownToggle>
        );
    }

    const renderAuthenticatedMenu = () => {
        return (
            <>
                <Link
                    to="/settings"
                    className="dropdown-item"
                >
                    <i className="bx bx-cog font-size-16 align-middle mr-2"></i>
                    Settings
                </Link>
                <div className="dropdown-divider"></div>
                <Link
                    to="/logout"
                    className="dropdown-item"
                >
                    <i className="bx bx-power-off font-size-16 align-middle mr-2"></i>
                    <span>{'Logout'}</span>
                </Link>
            </>
        )

    }

    const renderUnauthenticatedMenu = () => {
        return (
            <>
                <Link
                    to="/login"
                    className="dropdown-item"
                    onClick={()=>amplitude.getInstance().logEvent(`Sign in - ${getPathTitle(location?.pathname)} View`)}
                >
                    <span>{'Sign in'}</span>
                </Link>
            </>
        )
    }

    const renderMenu = () => {
        return (
            <DropdownMenu right className="profile-dropdown">
                {isAuthenticated ? renderAuthenticatedMenu() : renderUnauthenticatedMenu()}
            </DropdownMenu>
        )
    }

    return (
        <Dropdown
            isOpen={menu}
            toggle={() => setMenu(!menu)}
            className="d-inline-block"
        >
            {!showFullName ? renderAvatarDropdown() : renderFullNameDropdown()}
            {renderMenu()}
        </Dropdown>
    );
}
export default withRouter(ProfileMenuDropdown);
