import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { ERROR_SOLID_ICON } from '../../../constants/errorStyles';
import { issueTypes } from '../../../constants/issues';
import { isValidFunction } from '../../../utils/helpers';
import LoadingContent from '../../layout/LoadingContent';
import BasicDoughnutChart from '../charts/BasicDoughnutChart';
import InfoTooltip from '../tooltips/InfoTooltip';
import RollupFilterButtonGroup from './RollupFilterButtonGroup';
import { rollUpWidgetsIds } from '../../../constants/common';

const RollupCard = (props) => {

    const {
        componentId,
        title,
        subtitle,
        titleTooltip,
        colorsConfig,
        collapsed,
        cardClassName,
        isLoading,
        errorMessage,
        retryRequest,
        data,
        unitLabel,
        total,
        widgetType,
        issueType = issueTypes.VULNERABILITIES,
        handleRollupClick,
        addTotalFilter,
        customEmptyLabel,
        customEmptyLabelClassName,
    } = props;

    // Component Variables
    const loadingContenStyle = { minHeight: "150px", paddingTop: "16px" };
    const contentStyle = {
        marginTop: "20px",
        maxHeight: "130px",
        marginBottom: collapsed ? "-16px" : "25px"
    }

    const isTask = issueType === issueTypes.GRC;
    const isCriticalityRollup = widgetType === rollUpWidgetsIds.CRITICALITY;
    const isStatusRollup = widgetType === rollUpWidgetsIds.STATUS
    const zeroLabel = isTask ? (isCriticalityRollup ? 'Create some tasks to see more.' : isStatusRollup ? 'Create an actionable to-do.' : null) : null;

    const getChartParams = () => {
        return {
           data,
           colorsConfig,
           unitLabel,
           total,
           zeroLabel,
           widgetType,
           isTask,
        };
    }

    //  Function to handle total label click
    const onFilterClicked = (elements, widgetType, isTotal) => {
        if (!isValidFunction(handleRollupClick)) { return; }
        handleRollupClick(elements, widgetType, isTotal);
    };


    // Render functions

    // Function to render doughnut chart
    const renderDoughnutChart = () => {
        const {
            data,
            colorsConfig,
            unitLabel,
            total,
            zeroLabel,
            widgetType,
            isTask,
        }= getChartParams();

        const vulnerabilityClassName = "no-report";
        const labelClassName =  isTask ? "no-report-short-text" : vulnerabilityClassName;

        return (
            <BasicDoughnutChart
                data={data}
                colorsConfig={colorsConfig}
                totalLabelUnit={unitLabel}
                total={total}
                zeroLabel={customEmptyLabel || zeroLabel}
                onLegendClicked={(element) => onFilterClicked(element, widgetType, false)}
                onTotalClicked={addTotalFilter ? (elements) => onFilterClicked(elements, widgetType, true) : null}
                legendWidth = {172}
                customEmptyLabelClassName={customEmptyLabelClassName || labelClassName}
            />
        )
    }

    // Render card's title and subtitle
    const renderHeader = () => {
        return (
            <div className="d-flex align-items-center">
                <InfoTooltip
                    label={title}
                    info={titleTooltip}
                    wrapperClassName="dashboard-widget-title"
                    id={`rollup-tooltip-${componentId}`}
                    showTooltip={false}
                />
                {collapsed && subtitle ? (
                    <div className="filter-card-subtitle d-inline-block">
                        {subtitle}
                    </div>
                ):null}
            </div>
        )
    }
    return (
        <Card
            className={cardClassName}
            aria-label={`${title}, widget`}
            role="group"
            aria-hidden="false"
            tabIndex="0"
        >
            <CardBody className="rollup-widget d-flex flex-column">
                {renderHeader()}
                <div className='d-flex flex-grow-1 align-items-center'>
                    <LoadingContent
                        isLoading={isLoading}
                        errorMessage={errorMessage}
                        iconType={ERROR_SOLID_ICON}
                        errorStyle={loadingContenStyle}
                        loadingStyle={loadingContenStyle}
                        errorButtonCallback={retryRequest}
                    >
                        <div style={contentStyle}>
                            { !collapsed ? renderDoughnutChart() : (
                                <RollupFilterButtonGroup
                                    widgetType={widgetType}
                                    issueType={issueType}
                                />
                            )}
                        </div>
                    </LoadingContent>
                </div>
            </CardBody>
        </Card>
  )
}

export default RollupCard
