import { isValidFunction } from "../utils/helpers";

const useHookFormValidation = (params) => {
  const {
    name,
    required,
    validate,
    watch,
    errors,
    touchedFields,
    dirtyFields
  } = params;

  const touched = touchedFields[name];
  const dirty = dirtyFields[name];

  const watchInput = watch(name);
  //Checking if validate is a valid function
  const validateMsg = isValidFunction(validate) ? validate(watchInput, name) : '';

  const validateInput = validateMsg === '' ? true : false;

  const isInvalid = (errors[name]?.message || (!validateInput && touched) || (touched && !dirty && required?.state)) ? true : false;

  const displayClassName = isInvalid ? 'd-block' : 'd-none';
  const errorMsgClassName = `invalid-feedback ${displayClassName}`;
  const errorIconClassName = `form-error-icon bx bx-error ${displayClassName}`;

  const requirements = required?.state === true ? { required: required?.message } : { required: required?.state };

  const getErrorMessage = () => {
    if (errors[name]?.message) {
      return errors[name]?.message;
    } else if (!validateInput) {
      return validateMsg;
    } else if (required?.state) {
      return required?.message;
    } else {
      return "";
    }
  }

  return {
    isInvalid,
    errorMsgClassName,
    errorIconClassName,
    requirements,
    errorMsg: getErrorMessage()
  };
};

export default useHookFormValidation;
