import React from 'react';

const HelpMessage = (props) => {
  //  Parsing props
  const {
    normalMessage,
    linkMessage,
    linkUrl,
    containerClassName,
  } = props;

  //  Function to render link
  const renderLink = () => {
    if (!linkMessage || !linkUrl) { return null; }
    return (
      <a
        className="link-text"
        target="_blank"
        rel="noopener noreferrer"
        href={linkUrl}
      >
        {linkMessage}
      </a>
    );
  };

  // Rendering
  return (
    <div className={containerClassName}>
      {normalMessage && <div className="normal-text">{normalMessage}</div>}
      {renderLink()}
    </div>
  );
};

export default HelpMessage;
