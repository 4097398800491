import React from 'react';
import { Button } from 'reactstrap';

const TagComponent = (props) => {
    const {
        className,
        label,
        id,
        removeTagCallback,
        showRemoveButton = true,
        style,
        shouldCapitalize = true,
        isRemoveDisabled,
    } = props;

    const onRemoveTagClick = () => {
        removeTagCallback(id);
    }

    // Render remove button
    const renderRemoveButton = () => {
        if(!showRemoveButton) return null;
        const disabledClassName = isRemoveDisabled ? "opacity-50" : '';
        const buttonClassName = `btn-remove-tag link-dark p-0 ${disabledClassName}`
        return (
            <Button
                color="link"
                onClick={onRemoveTagClick}
                className={buttonClassName}
                disabled={isRemoveDisabled}
            >
                <i className='bx bx-x'/>
            </Button>
        )
    }

    return (
        <div
            className={`tag-component ${className || ''}`}
            style={style}
        >
            <div className={`tag-label ${shouldCapitalize ? 'text-capitalize' : ''}`}>{label}</div>
            {renderRemoveButton()}
        </div>
    )
}

export default TagComponent;
