export const scrollToRef = (ref, behavior='smooth') => ref.current.scrollIntoView({ behavior })

export const scrollToComponent = (ref, topOffset = "-86px", behavior="smooth", block="start", timeout = 200) => {
    setTimeout(function () {
        const element = ref.current;
        if(element){
            //temporarily moves element in order to scroll to the expected position
            const pos = element.style.position;
            const top = element.style.top;
            element.style.position = 'relative';
            element.style.top = topOffset;
            element.scrollIntoView({ behavior, block });
            scrollToRef(ref);
            element.style.top = top;
            element.style.position = pos;
        }
    }, timeout);
}

export const getAccumulatedHeights = (initialIndex, lastIndex, getCurrentAccumulatedHeight, offset = 0) => {
    const heights = [];
    for(let i = initialIndex; i <= lastIndex; i++) {
        heights.push(getCurrentAccumulatedHeight(i, offset))
    }
    return heights;
}
