import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Label, Button } from 'reactstrap';
import BasicModal from "../layout/BasicModal";
import Tabs from '../layout/tabs/Tabs';
import CommentsSection from '../layout/comments/CommentsSection';
import { formatDate, getSafeArray } from '../../utils/helpers';
import { ADD_COMMENT_SUCCESS, RELOAD_DEVICES_LIST } from '../../actions/types';
import { setAlert } from '../../actions/alerts';
import { updateDeviceSuccess, onDeleteDeviceChange } from '../../actions/devices'
import { successActionCreator } from '../../actions/action-creators/success';
import API from '../../api';
import { cloneDeep } from 'lodash';
import DevicesTags from '../tags/DevicesTags';
import { parseTaggingsList } from '../../utils/tagsHelper';
import useCustomMutation from '../../hooks/useCustomMutation';
import PartnersIconList from './PartnersIconList';
import useCanAbility from '../../hooks/useCanAbility';
import { MODIFY_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';
import TimeoutUpdateInput from '../layout/fields/TimeoutUpdateInput';

const DeviceDetails = (props) => {
  const {
    showModal,
    toggleModal,
    selectedDevice
  } = props;

  //  Initializing APIs
  const { DeviceAPI } = API;

  const history = useHistory();

  const defaultDispatch = useDispatch();
  const dispatch = useCallback(defaultDispatch, []);

  const user = useSelector((state) => state.auth.user);
  const statusOptions = useSelector((state) => state.issues.modalOptions);
  const deleteDeviceSuccess = useSelector((state) => state?.devices?.deleteModalConfig?.success);

  // Component state
  const [device, setCurrentDevice] = useState(cloneDeep(selectedDevice));
  const [updatedField, setUpdatedField] = useState('');
  const [reloadDevicesList, setReloadDevicesList] = useState(false);

  // Component Hooks
  const ability = useCanAbility();
  // Calling API update device
  const {
    data: updateDeviceResponse,
    error: updateDeviceError,
    mutate: updateDevice
  } = useCustomMutation(
    (params) => DeviceAPI.handlers.putUpdateDeviceField(params),
    DeviceAPI.invalidators.putUpdateDeviceField
  );


  // Component variables
  const canModifyDevices = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.DEVICES.GENERAL)

  // Add update device error
  useEffect(() => {
    if (updateDeviceError) {
      const { data } = updateDeviceError;
      const { errorMessage } = data || { errorMessage: 'Error trying to update the device info.' };
      dispatch(setAlert(errorMessage, 'danger'));
      setCurrentDevice(cloneDeep(selectedDevice));
    }
  }, [updateDeviceError]);

  // Add update device success
  useEffect(() => {
    // Updating current state
    if (updateDeviceResponse && device) {
      if (updatedField === 'comments') dispatch(successActionCreator(ADD_COMMENT_SUCCESS));
      if (updatedField === 'user_name') dispatch(setAlert('User name updated', 'success'));
      dispatch(updateDeviceSuccess(device));
    }
  }, [updateDeviceResponse]);

  //  Watching delete devices success
  useEffect(() => {
    if (!deleteDeviceSuccess) { return; }
    dispatch(onDeleteDeviceChange(false, [], false));
    closeModal();
  }, [deleteDeviceSuccess]);

  const deviceInfoToDisplay = {
    row_0: {
      name: "Device Name",
      user_name: "User Name",
      network_status: "Network Status"
    },
    row_1: {
      site_name: "Site Name",
      mac_address: "MAC Address",
      ip_address: "IP Address"
    },
    row_2: {
      domain: "NT Domain",
      os_type: "OS Type",
      last_seen: "Last Seen"
    },
  }

  const closeModal = () => {
    if (reloadDevicesList) dispatch(successActionCreator(RELOAD_DEVICES_LIST, true))
    if (toggleModal) toggleModal();
  }

  const changeUserName = (name, field) => {
    if (field !== 'user_name') return;
    updateDevice({
      public_id: device?.public_id,
      content: {
        field: "user_name",
        value: name
      }
    });

    setUpdatedField('user_name');
    setCurrentDevice({ ...device, user_name: name })
  }

  const addDeviceCommentCallback = (comment) => {
    if (!comment) return;

    // comment with owner info
    const newComment = {
      user: {
        id: user?.id,
        full_name: user?.full_name || user?.name || "User"
      },
      content: comment,
      updated_at: new Date(),
      is_remediation: false
    }

    const comments = {
      list: device?.comments?.list ? [...device?.comments?.list, newComment] : [newComment]
    }

    updateDevice({
      public_id: device?.public_id,
      content: {
        field: "comments",
        value: comments
      }
    });

    setUpdatedField('comments');
    setCurrentDevice({ ...device, comments })
  }

  const renderInfoField = (label, inputValue, key, field) => {
    const value = field === 'last_seen' ? (inputValue ? formatDate(inputValue) : '') : inputValue;
    const disabled = field !== 'user_name' || !canModifyDevices;

    return (
      <div className="col info-group" key={key}>
        <Label>{label || "-"}</Label>
        <TimeoutUpdateInput
          type="text"
          className={`form-control text-area-grow dark ${field === 'user_name' ? '' : 'read-only'}`}
          placeholder={'-'}
          value={value}
          field={field}
          callback={changeUserName}
          timeoutTime={500}
          disabled={disabled}
        />
      </div>
    )
  }

  const renderNetworkStatus = (label, value) => {
    return (
      <div className="col info-group">
        <Label>{label || "-"}</Label>
        <div className={`${value?.toLowerCase()}`}>{value || "-"}</div>
      </div>
    )
  }

  const showDeviceIssue = (code) => {
    if (code) history.push(`/dashboard/issues?details&selectedIssue=${code}`);
  }

  //  Function to handle device delete
  const onDeleteClicked = () => {
    dispatch(onDeleteDeviceChange(true, [selectedDevice?.id], false));
  };

  // Device info section
  const renderDeviceDetails = () => {
    const { row_1, row_2 } = deviceInfoToDisplay;
    return (
      <>
        <div className="row">
          {renderInfoField('Device Name', device?.name)}
          {renderInfoField('User Name', device?.user_name, "", 'user_name')}
          {renderNetworkStatus('Network Status', device?.network_status)}
        </div>
        <div className="row">
          {Object.keys(row_1).map((field, index) => {
            return renderInfoField(row_1[field], device ? device[field] : null, `row-1-${field}-${index}`, field);
          })}
        </div>
        <div className="row">
          {Object.keys(row_2).map((field, index) => {
            return renderInfoField(row_2[field], device ? device[field] : null, `row-2-${field}-${index}`, field);
          })}
        </div>
      </>
    )
  }

  const renderTagsSection = () => {
    return (
      <DevicesTags
        deviceId={selectedDevice?.id}
        companyId={selectedDevice?.company_id}
        tagsList={parseTaggingsList(selectedDevice?.taggings)}
        setReloadDevicesList={(value) => setReloadDevicesList(value)}
      />
    )
  }

  const renderIssues = () => {
    return (
      <>
        {device?.issue_entities?.map(_issue => {
          const statusId = parseInt(_issue.status || 0);
          const statusLabel = getSafeArray(statusOptions.statuses).find((status) => status?.id === statusId)?.label
          return (
            <Button
              key={`subtask-${_issue.code}`}
              className="btn btn-block btn-filter btn-outline-light-gray p-2 text-left mt-0 mb-2 d-inline-block text-truncate "
              onClick={() => showDeviceIssue(_issue.code)}
            >
              {`${_issue.code} ${_issue.name} ${_issue.os_version ? `- ${_issue.os_version}` : ''}`}
              <span className='subtask-status'>
                {statusLabel}
              </span>
            </Button>
          )
        })}
      </>
    )
  }

  const renderIssuesSection = () => {
    return (
      <>
        {device?.issue_entities?.length > 0 ?
          <div className="subtasks-section">
            <label className="d-block mb-2">
              Issues attached to this device
            </label>
            <div className="subtasks-container">
              {renderIssues()}
            </div>
          </div>
          : <></>
        }
      </>
    )
  }

  const renderDevicesNotes = () => {
    return (
      <div className="w-100">
        <Tabs
          tabs={[{
            name: 'Device notes',
            content: (
              <CommentsSection
                comments={Object.values(device?.comments?.list || [])}
                callback={addDeviceCommentCallback}
                hideRemediation={true}
                disabled={!canModifyDevices}
              />
            )
          }]}
        />
      </div>
    )
  }

  //  Function to render custom header
  const renderCustomHeader = () => {
    return (
      <div className="details-header">
        <div className="details-header-icons">
          <div
            className="bx bxs-trash trash-icon"
            onClick={() => { onDeleteClicked(); }}
          />
          <button
            type="button"
            onClick={() => { closeModal() }}
            className="close close-icon"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

    );
  };

  const renderPartnersSection = () => {
    const partners = selectedDevice?.device_partners;
    return (
      <div className="mb-2">
        <Label>Solution</Label>
        <div className="mt-2">
          {partners?.length > 0 ? (
            <PartnersIconList
              partners={partners}
              wrapperClassName="devices-details-partners-wrapper w-100 flex-wrap"
              displayName={true}
            />
          ):(
            <div className="form-control dark read-only">-</div>
          )}
        </div>
      </div>
    )
  }

  return (
    <BasicModal
      headerClassName={'modal-header-status'}
      customHeaderContent={renderCustomHeader()}
      showModal={showModal}
      toggleModal={() => closeModal()}
      customClassName="device-detail-modal"
      scrollable={true}
    >
      <div className="container">
        {renderDeviceDetails()}
        {renderPartnersSection()}
        {renderTagsSection()}
        {renderIssuesSection()}
        {renderDevicesNotes()}
      </div>

    </BasicModal>
  )
}

export default DeviceDetails;
