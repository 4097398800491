import React from 'react'
import { Col } from 'reactstrap';
import { isOverallSolution, solutionGoal } from '../../../../../constants/cyValueData';
import { ERROR_SOLID_ICON } from '../../../../../constants/errorStyles';
import solutionsStyle from '../../../../../constants/solutionsStyle';
import { getSafeArray } from '../../../../../utils/helpers';
import { getTimePeriod } from '../../../../../utils/timeChartHelpers';
import SolutionLineChart from '../../../../dashboard/charts/SolutionLineChart';
import LoadingContent from '../../../../layout/LoadingContent'
import DashboardCard from '../../../common/dashboard/DashboardCard'
import { filterByKey } from '../../../../../utils/helpers';

const IcarmDistributionChart = (props) => {
    const {
        isLoading,
        errorMessage,
        solutionsData,
        filters,
        changeFilters,
        hasOverallScore,
        hasZeroScore,
        retryRequests,
        hasSurpassedGoal,
        themeColor,
        lg = 6,
        solutionName,
        title,
    } = props;

    // Component Variables
    const cardLoadingStyle = { minHeight: "266px", paddingTop: "41px" };

    //  Function to get colors for chart
    const getChartColors = (solutions = []) => {
        if (solutions.length === 0) { return [solutionsStyle['FREEMIUM'].color]; }
        // if (solutionsData?.name === 'Overall') {
        //     return solutions.map((solution) => {
        //         return solution?.theme?.color || solutionsStyle['DEFAULT'].color
        //     });
        // }
        if (!themeColor || !themeColor?.color) { return ['transparent']; }
        if (solutionName) { return [filterByKey(solutionsData.solutions,'name',solutionName)?.[0]?.theme?.color]}
        return [themeColor.color];
    };

    const filterOverallSolution = (solution) => {
        return isOverallSolution(solution?.name)
    }

    const getFilteredSolutions = (solutionLabel,solutions) => {
        if (solutionName) {
            return getSafeArray(filterByKey(solutionsData.solutions,'name',solutionName));
        }
        return !isOverallSolution(solutionLabel) ? solutions : getSafeArray(solutions).filter(filterOverallSolution)
    }

    // Render chart
    const renderSolutionsLineChart = () => {
        if (!solutionsData) { return null; }
        const {
            name: solutionLabel,
            solutions,
            overall_score,
            hasNewSolution,
            cyvalue_reached_date
        } = solutionsData;

        const filteredSolutions = getFilteredSolutions(solutionLabel,solutions);
        const colorsConfig = getChartColors(filteredSolutions);
        const timePeriod = getTimePeriod(filters.filter_type);
        const showProjection = overall_score < solutionGoal
        return (
            <div className="solution-line-chart-wrapper h-100">
                <div className="solution-line-chart-inner-wrapper h-100">
                    <SolutionLineChart
                        chartTitle={title || "ICARM Distribution"}
                        colorTitle={title && colorsConfig[0]}
                        data={filteredSolutions}
                        colors={colorsConfig}
                        timePeriod={timePeriod}
                        showProjection={showProjection}
                        hasNewSolution={hasNewSolution}
                        filters={filters}
                        setFilters={changeFilters}
                        hasOverallScore={hasOverallScore}
                        hasZeroScore={hasZeroScore}
                        isLoading={isLoading || !solutionsData}
                        errorMessage={errorMessage}
                        errorButtonCallback={retryRequests}
                        cyvalueReachedDate={cyvalue_reached_date}
                        hasSurpassedGoal={hasSurpassedGoal}
                    />
                </div>
            </div>
        )
    }
    return (
        <Col
            lg={lg}
            className="px-2"
        >
            <DashboardCard>
                <LoadingContent
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    iconType={ERROR_SOLID_ICON}
                    errorStyle={cardLoadingStyle}
                    loadingStyle={cardLoadingStyle}
                    errorButtonCallback={retryRequests}
                >
                    {renderSolutionsLineChart()}
                </LoadingContent>
            </DashboardCard>
        </Col>
    )
}

export default IcarmDistributionChart
