
import { updateUserID, trackEvent } from './gaTracker';
import { clearAnswers } from '../actions/answers';
import {
  loginSuccess,
  authUser,
  authError,
  logoutSuccess,
} from '../actions/auth';
import { setAlert } from '../actions/alerts';
import { errorActionCreator } from '../actions/action-creators/errors';
import { getDuplicatedDomainErrorMessage } from './alertHelpers';

export const authAndLoginUser = (userData, dispatch, history, shouldClearAnswers = true) => {
  if (shouldClearAnswers) { dispatch(clearAnswers()); }
  if (!userData) { return; }
  const { user } = userData;
  updateUserID(user?.public_id || undefined);
  dispatch(loginSuccess(userData));
  dispatch(authUser(history, '/'));
};

const handleDuplicatedEmail = (error, errorType, dispatch) => {
  const message = 'One or more fields have an error. Please check and try again';
  dispatch(setAlert(message, 'danger'));
  if (!errorType) {
    dispatch(authError(error?.response?.data?.errorMessage || message));
    return;
  }
  dispatch(errorActionCreator(errorType, { companyEmail: error?.response?.data?.message }))
};

const handleDefaultRegisterError = (error, errorType, dispatch) => {
  if (error?.response?.data?.errorMessage === 'You need to use a company email.') {
    const eventType = errorType ? 'company_abandonment' : 'register_abandonment';
    trackEvent("personal_email", eventType, "failed_business_email");
  }
  //  Displaing error message
  const errorMessage = error?.response?.data?.message || 'Oops! Something went wrong. Try again please'
  dispatch(setAlert(errorMessage, 'danger'));
  if (!errorType) { return; }
  dispatch(errorActionCreator(errorType, { message: errorMessage }));
};

export const handleRegistersErrors = (error, errorType, dispatch) => {
  const errorCode = error?.response?.data?.code;
  switch (errorCode) {
    case 'DUPLICATED_DOMAIN':
      const message = getDuplicatedDomainErrorMessage();
      dispatch(setAlert(message, 'important', false));
      if (errorType) {
        const errorPayload = { companyEmail: "Someone at your organization already took this assessment. Please select a different email." };
        dispatch(errorActionCreator(errorType, errorPayload));
      }
      break;
    case 'DUPLICATED_EMAIL':
      handleDuplicatedEmail(error, errorType, dispatch);
      break;
    default:
      handleDefaultRegisterError(error, errorType, dispatch);
      break;
  }
};

export const getCompletedAnswers = (assessment_id, completed) => {
  return {
    assessment_id,
    questionAnswers: localStorage.getItem("questions") ? JSON.parse(localStorage.getItem("questions")) : {},
    questionsByCategory: localStorage.getItem("questionsByCategory") ? JSON.parse(localStorage.getItem("questionsByCategory")) : {},
    sectionComments: localStorage.getItem("comments") ? JSON.parse(localStorage.getItem("comments")) : {},
    comment: localStorage.getItem("assessmentComment") ? localStorage.getItem("assessmentComment") : '',
    completed,
  };
};

export const buildUsertoRegister = (payload, completed = false, is_freemium = false) => {
  return {
    user: payload.user,
    company: payload.company,
    completed,
    leadSource: payload.leadSource,
    is_freemium,
  };
};

export const handleUserLogout = (pathname, history, dispatch) => {
  updateUserID(undefined);
  dispatch(logoutSuccess());
  const path = pathname || '/login';
  history.push(path);
}
