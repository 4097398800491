import React, { useEffect } from 'react';
import VideoJS from './Video'

const VideoPlayer = ({url}) => {

  const playerRef = React.useRef(null);
  const videoJsOptions = {
    autoplay: false,
    width:'100%',
    controls: true,
    responsive: true,
    autoHeight: '16:9',
    fluid: true,
    fill: true,
    sources: [
      {
        src: url,
        type: 'video/mp4',
      },
    ],
  };

  useEffect(() => {
    if (!playerRef.current) {
      return;
    }
    playerRef.current.src([{src: url, type: 'video/mp4'}]);
  }, [url]);

  return (
    <div>
      <VideoJS options={videoJsOptions}/>
    </div>
  );
};

export default VideoPlayer;
