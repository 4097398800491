import React, { useEffect, useMemo } from 'react';

const SortButton = (props) => {
  //  Parsing props
  const {
    containerClassName = '',
    labelClassName = '',
    iconClassName = '',
    label = '',
    sortField = '',
    currentSort,
    changeSort,
  } = props;

  //  Component variables
  const isActive = useMemo(() => {
    if (!currentSort) { return false; }
    return (currentSort[0] === sortField);
  }, [currentSort]);

  //  Function to handle button click
  const onClick = () => {
    const newValue = (!currentSort[1] || currentSort[1] === 'ASC') ? 'DESC' : 'ASC';
    changeSort(sortField, newValue, true);
  };

  //  Function to get icon className
  const getIconClassName = () => {
    if (!isActive) { return 'bx-up-arrow-alt'; }
    if (!currentSort[1] || currentSort[1] === 'ASC') { return 'bx-up-arrow-alt'; }
    return 'bx-down-arrow-alt';
  };

  //  Rendering
  return (
    <div
      className={`${containerClassName}${isActive ? ' active' : ''}`}
      onClick={onClick}
    >
      <div className={labelClassName}>{label}</div>
      <div className={`${iconClassName} bx ${getIconClassName()}`} />
    </div>
  );
};

export default SortButton;
