import React, { useState } from 'react';
import { Button }from 'reactstrap';
import SolutionTooltip from './SolutionTooltip';

const ButtonSolutionTooltip = (props) => {
    const {
        id,
        solution,
        className,
        color="light",
        showContactButton = true,
        showAbbreviation = true,
        tooltipClassName = ""
    }= props;
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => {
        setTooltipOpen(!tooltipOpen)
    };

    return (
        <div className={`solution-tooltip-component d-inline-block ${className}`}>
            <Button
                id={`solution-tooltip-${id}`}
                color="link"
                className={`btn-solution-link link-${color} underline-link`}
            >
                {solution.name}
            </Button>
            <SolutionTooltip
                target={`solution-tooltip-${id}`}
                isOpen={tooltipOpen}
                setIsOpen={setTooltipOpen}
                solution={solution}
                showContactButton={showContactButton}
                showAbbreviation={showAbbreviation}
                className={tooltipClassName}
            />
        </div>
    )
}

export default ButtonSolutionTooltip;
