import React from 'react';
import GrcIssueSelectAttributes from './GrcIssueSelectAttributes';
import GrcIssuesBasicDetails from './grcIssuesBasicDetails';
import GrcIssuesComments from './GrcIssuesComments';
import GrcSubTasks from './GrcSubTasks';
import GRCAttachments from './GRCAttachments';
import GRCTags from './GRCTags';
import { getSafeArray, isValidFunction } from '../../../utils/helpers'
import _ from 'lodash';
import useDragOver from '../../../hooks/useDragOver';
import useCanAbility from '../../../hooks/useCanAbility';
import { MODIFY_ACTION, PRIVILEGE_SOURCES } from '../../../constants/privileges';
import IssuePartnerDetails from '../../issue-detail/IssuePartnerDetails';
import GRCDetailFromPartner from './GRCDetailFromPartner';
import PARTNERS from '../../../constants/partners';

const GrcIssueDetailsBody = (props) => {
  //  Parsing props
  const {
    issue,
    setIssue,
    isUpdating = false,
    expandedMode = false,
    isSubTask = false,
    grcConfig,
    validationError,
    clearValidationError
  } = props;

  //  Component hooks
  const {
    isDragOver,
    clearState: clearDraggedState,
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
  } = useDragOver();
  const ability = useCanAbility();

  // Component variables
  const canModifyIssues = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.ISSUES.GENERAL);

  //  Function to change issue
  const changeIssue = (key, value) => {
    if (isValidFunction(clearValidationError)) clearValidationError(key);
    const newIssue = {
      ...issue,
      [key]: value,
    }
    const unassignedStatus = grcConfig?.statuses ? grcConfig.statuses.find((status) => status.label === "Unassigned") : null;
    const unassignedStatusId = unassignedStatus?.id || 0;

    // Remove unassigned or empty status and update it to To Do,  when an assignee is set
    if (key === 'assigned_to' && !_.isNil(value) && (newIssue.status === unassignedStatusId || _.isNil(newIssue.status))) {
      const toDoStatus = grcConfig?.statuses ? grcConfig.statuses.find((status) => status.label === "To Do") : null;
      newIssue.status = toDoStatus?.id || 1;
    }

    // Remove assignee if unassigned status is set
    if (key === 'status' && value === unassignedStatusId) {
      newIssue.assigned_to = null;
    }
    setIssue(newIssue);
  };

  //  Function to change issue company
  const changeIssueCompany = (option) => {
    const newIssue = {
      ...issue,
      company_id: option?.value,
      company_name: option?.label,
      assigned_to: null,
    };
    if (isValidFunction(clearValidationError)) clearValidationError('company_id');
    setIssue(newIssue);
  };

  //  Function to render subtasks
  const renderSubtasks = () => {
    if (!isUpdating || isSubTask) { return null; }
    return (
      <GrcSubTasks
        parent_issue={issue}
        subTasks={issue?.sub_tasks || []}
      />
    );
  };

  //  Function to render tags
  const renderTags = () => {
    if (!isUpdating) { return null; }
    return (
      <GRCTags
        issue={issue}
      />
    );
  };

  //  Function to render attachments
  const renderAttachments = () => {
    if (!isUpdating) { return null; }
    return (
      <GRCAttachments
        company_public_id={issue?.company_public_id}
        issue_public_id={issue?.public_id}
        issue_code={issue?.code}
        files={issue?.files}
        isDragOver={isDragOver}
        clearDraggedState={clearDraggedState}
        disabled={!canModifyIssues}
      />
    );
  };

  // Get status name by id
  const getStatusName = (status_id) => {
    const idToUse = status_id ? status_id : 0
    return getSafeArray(grcConfig.statuses).find((status) => status.id === idToUse)?.label
  }

  const renderGRCDetailFromPartner = () => {
    if (issue?.partner?.name !== PARTNERS.OBSIDIAN) return null;
    return <GRCDetailFromPartner issue={issue} />
  }

  //  Rendering
  return (
    <div
      className="grc-modal-body"
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div
        className="d-block d-lg-inline-flex w-100 columns-container grc-columns"
      >
        <div className="left-column grc-left-column">
          <GrcIssuesBasicDetails
            name={issue?.name}
            description={issue?.description}
            onFieldChange={changeIssue}
            validationError={validationError}
          />
          {renderTags()}
          {renderAttachments()}
          {renderSubtasks()}
          {renderGRCDetailFromPartner()}
        </div>
        <div className="right-column grc-right-column">
          <GrcIssueSelectAttributes
            issue={issue}
            changeIssue={changeIssue}
            isUpdating={isUpdating}
            isSubTask={isSubTask}
            changeIssueCompany={changeIssueCompany}
            validationError={validationError}
          />
          <IssuePartnerDetails issue={issue} task={true}/>
        </div>
      </div>
      <GrcIssuesComments
        comments={getSafeArray(issue?.comments?.list)}
        changeIssue={changeIssue}
        expandedMode={expandedMode}
        statusId={issue?.status ? issue?.status : 0}
        statusName={getStatusName(issue?.status)}
      />
    </div>
  );
};

export default GrcIssueDetailsBody;
