import React, { useState, useEffect } from 'react';
import TagsAddComponent from './TagsAddComponent';
import TagComponent from './TagComponent';
import { getSafeArray } from '../../utils/helpers';
import { Button } from 'reactstrap';
import BasicModal from '../layout/BasicModal';

const TagsSection = (props) => {
    const {
        title="Tags",
        tagsList,
        addTagCallback,
        removeTagCallback,
        error,
        className,
        issueId,
        deviceId,
        companyId,
        dropdownMenuPosition,
        reloadTagsList,
        setReloadTagsList,
        disabled,
        showTitle = true,
        showAdd = true,
        showList = true,
        isLimited = false,
        limit = 3,
        placeholder,
    } = props;

    // Component state
    const [currentTagList, setCurrentTagList] = useState(tagsList);
    const [showTagsModal, setShowTagsModal] = useState(false);

    //watch for error to reset tag list
    useEffect(() => {
        if(error) {
            setCurrentTagList(tagsList)
        }
    }, [error]);

    //watch for tagList changes
    useEffect(() => {
        setCurrentTagList(tagsList)
    }, [tagsList]);

    // Toggle tags modal
    const toggleTagsModal = () => {
        setShowTagsModal(!showTagsModal)
    }

    const renderTagsList = (limitTags) => {
        if(!currentTagList) return null;
        const showViewMore = limitTags && currentTagList?.length > limit;
        const previewTagsList = showViewMore ? [...getSafeArray(currentTagList)].slice(0, limit) : getSafeArray(currentTagList);
        return (
            <div
                className={`tags-list`}
            >
                {previewTagsList?.map((tag, index) => {
                    return (
                        <TagComponent
                            key={`tag-${index}`}
                            id={tag.id}
                            label={tag.title}
                            className="mb-2 mr-2"
                            removeTagCallback={removeTagCallback}
                            isRemoveDisabled={disabled}
                        />
                    )
                })}
                {showViewMore && (
                    <Button
                        outline
                        color="light-gray"
                        className="btn-view-all-tags btn-small"
                        onClick={toggleTagsModal}
                    >
                        <i className="bx bx-show"/>
                        {`View all (+${currentTagList?.length - limit})`}
                    </Button>
                )}
            </div>
        )
    }

    const renderAddTagComponent = () => {
        return (
            <TagsAddComponent
                callback={addTagCallback}
                issueId={issueId}
                deviceId={deviceId}
                companyId={companyId}
                menuPosition={dropdownMenuPosition}
                reloadTagsList={reloadTagsList}
                setReloadTagsList={setReloadTagsList}
                disabled={disabled}
                placeholder={placeholder}
            />
        )
    }

    // Render content
    const renderContent = (showSectionTitle, showTagsList, showAddComponent, limitTags, contentClassName) => {
        return (
            <div className={`business-context-tags-section ${contentClassName|| ''}`} >
                {showSectionTitle && <div className="tags-section-title">{title}</div>}
                <div>
                    {showTagsList && renderTagsList(limitTags)}
                    {showAddComponent && renderAddTagComponent()}
                </div>
            </div>
        )
    }

    // Render tags modal
    const renderTagsModal = () => {
        if(!showTagsModal) return null;
        return (
            <BasicModal
                headerClassName={`modal-header-status modal-header-tags`}
                header="Tags"
                title={
                    <div className="d-inline-block d-md-flex align-items-center">
                    </div>
                }
                showModal={showTagsModal}
                toggleModal={toggleTagsModal}
                customClassName="device-tags-modal view-all-tags-modal"
                scrollable={true}
            >
                <div className={`business-context-tags-section`} >
                <div className="tags-section-title">{title}</div>
                <div>
                    <div className='mb-3'>
                        {renderAddTagComponent()}
                    </div>
                    {renderTagsList(false)}
                </div>
            </div>
            </BasicModal>
        )
    }

    return (
        <>
            {renderTagsModal()}
            <div className={`business-context-tags-section ${className || ''}`} >
                {showTitle && <div className="tags-section-title">{title}</div>}
                <div>
                    {showList && renderTagsList(isLimited)}
                    {showAdd && renderAddTagComponent()}
                </div>
            </div>
        </>
    )
}

export default TagsSection;
