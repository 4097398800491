import React, { useEffect, useMemo, useState } from 'react';
import SimpleDropdown from '../../layout/SimpleDropdown';
import { generatePossibleIntervals } from '../../../utils/automationHelper';

const IntervalInput = (props) => {
  //  Parsing props
  const {
    freq,
    interval,
    changeInterval,
  } = props;

  const intervals = useMemo(() => {
    return generatePossibleIntervals();
  }, []);

  //  Component state
  const [selectedInterval, setSelectedInterval] = useState(null);

  //  Watching interval changes
  useEffect(() => {
    if (!interval) { setSelectedInterval(null); }
    const matchingInterval = intervals.find((item) => item.label === interval);
    setSelectedInterval(matchingInterval);
  }, [interval]);

  //  Function to handle 
  const onChange = (option) => {
    if (!option) { return; }
    changeInterval(option?.label);
  };

  //  Function to get frequency label
  const getFrequencyLabel = () => {
    if (!freq) { return null; }
    let label = '';
    if (freq === 'YEARLY') { label += 'year'; }
    if (freq === 'MONTHLY') { label += 'month'; }
    if (freq === 'WEEKLY') { label += 'week'; }
    if (freq === 'DAILY') { label += 'day'; }
    return `${label}${interval > 1 ? 's' : ''}`
  };

  //  Rendering
  return (
    <div className="interval-container">
      <SimpleDropdown
        extraClassName={`dropdown-filter action-dropdown interval-picker${selectedInterval ? ' active' : ''}`}
        placeholder="Select interval"
        elements={intervals}
        onChangeCallback={onChange}
        selectedElement={selectedInterval}
      />
      <div className="frequency-label">
        {getFrequencyLabel()}
      </div>
    </div>
  );
};

export default IntervalInput;
