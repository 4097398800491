import React from 'react';
import Header from './TableHeaders/Header';

const CustomTableHeader = (props) => {
  //  Parsing props
  const {
    headerClassName = '',
    columnsTemplate = [],
    isSubtemplate = false,
    changeSort,
    sort = [],
    allSelected = false,
    handleSelectedEvent,
    expandableRow = false,
    expandAll = false,
    changeExpandAll,
    allowMultipleSort = false,
  } = props;

  //  Function to render header cells
  const renderHeader = () => {
    return columnsTemplate.map((column, index) => {
      return (
        <Header
          headTemplate={column}
          key={index}
          changeSort={changeSort}
          sort={sort}
          allSelected={allSelected}
          handleSelectedEvent={handleSelectedEvent}
          allowMultipleSort={allowMultipleSort}
        />
      );
    });
  };

  //  Function to render expandable icon
  const renderExpandableIcon = () => {
    if (!expandableRow) { return null; }
    return (
      <div
        className={`bx bxs-${expandAll ? 'down' : 'right'}-arrow expandable-icon`}
        onClick={() => changeExpandAll && changeExpandAll()}
      />
    );
  };

  //  Rendering
  return isSubtemplate ? renderHeader() : (
    <tr className={headerClassName}>
      {renderExpandableIcon()}
      {renderHeader()}
    </tr>
  );
};

export default CustomTableHeader;
