import _ from 'lodash';
import {
    ADD_COMMENT_REQUEST,
    ADD_COMMENT_SUCCESS,
    ADD_COMMENT_FAILURE,
    REMOVE_NEW_COMMENT_FLAG
} from '../actions/types';
const initialState = {
    isProcessing: false,
    newAdded: false
}
export default (state = initialState, action) => {
    switch (action.type) {
        
        case ADD_COMMENT_REQUEST: {
            return {
                ...state,
                isProcessing: true,
            }
        }

        case ADD_COMMENT_SUCCESS: {
            return {
                ...state,
                newAdded: true,
                isProcessing: false
            }
        }

        case ADD_COMMENT_FAILURE: {
            return {
                ...state,
                isProcessing: false
            }
        }

        case REMOVE_NEW_COMMENT_FLAG: {
            return {
                ...state,
                newAdded: false
            }
        }

        default:
            return state;
    }
}
