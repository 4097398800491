import React from 'react';
import TagComponent from '../tags/TagComponent';

const AutomationTagsCell = (props) => {
  //  Parsing props
  const { tags = [] } = props;

  //  Function to render tags
  //  TODO: send proper id
  const renderTags = () => {
    return tags.map((tag, index) => {
      return (
        <TagComponent
          key={`automation-tag-component-${index}`}
          id={index}
          label={tag}
          showRemoveButton={false}
          className="automation-tag"
        />
      );
    });
  };

  //  Rendering
  return (
    <div className="automation-tags-container">
      {renderTags()}
    </div>
  );
};

export default AutomationTagsCell;
