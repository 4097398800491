import React from 'react'
import { useSelector } from 'react-redux';
import { Badge } from 'reactstrap';
import companyInfoSelector from '../../../selectors/companyInfoSelector';

const RiskCodeLabel = (props) => {
  const {
    code
  } = props;

  // Getting needed info from redux store
  const companyCode = useSelector(state => companyInfoSelector(state, 'code'));

  return (
    <div>
      <Badge
          className="risk-code-label"
      >
          {code?.replace(`${companyCode}-`,"")?.replace("S","")}
      </Badge>
    </div>
  )
}

export default RiskCodeLabel
