import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardTitle,
  CardBody,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip,
  Button
} from 'reactstrap';
import { fetchResults } from "../../actions/calculator";
import makeLoadingState from "../../selectors/loadingSelector";
import { formatMoney, formatWholeNumber } from '../../utils/helpers';
import { trackEvent } from "../../utils/gaTracker";
import { monthDateFormat } from "../../utils/dateFormatter";
import 'boxicons';
import Table from 'reactstrap/lib/Table';
import amplitude from 'amplitude-js';
import useCanAbility from '../../hooks/useCanAbility';
import { MODIFY_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import companyIdSelector from '../../selectors/companyIdSelector';
import { fetchCustomerProfile } from '../../actions/customerManagement';

const RoiCalculator = () => {

  // selectors
  const getLoadingState = makeLoadingState(['CALCULATE_ROI']);

  // Getting needed info from redux store
  const isLoading = useSelector(state => getLoadingState(state))
  const currentCustomer = useSelector(state => state.customers?.currentCustomer);
  const { annualRevenue, numberEmployees } = useSelector(state => state.auth.company);
  const results = useSelector(state => state.calculator?.roiResults);
  const activeImpersonation = useSelector((state) => !_.isNil(state?.impersonation?.companyId));

  // Component state
  const [employees, setEmployees] = useState(numberEmployees || '');
  const [revenue, setRevenue] = useState(annualRevenue || '');
  const [showExpanded, setShowExpanded] = useState(false);

  // Component Hook
  const ability = useCanAbility();
  const { dispatch } = useMemoizedDispatch();

  // Component variables
  const canModifyCompany = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.MEMBERS.GENERAL);
  const companyId = useSelector(state => companyIdSelector(state));

  useEffect(() => {
    // Fetch annual revenue and employee count for impersonated company
    if (activeImpersonation && companyId) {
      dispatch(fetchCustomerProfile(companyId));
    }
  }, [companyId, activeImpersonation]);

  useEffect(() => {
    const currentRevenue = activeImpersonation ? currentCustomer?.annual_revenue : annualRevenue;
    const currentEmployee = activeImpersonation ? currentCustomer?.number_employees : numberEmployees;

    const formattedRevenue = formatMoney(currentRevenue);
    setRevenue(formattedRevenue);
    setEmployees(currentEmployee);
    if (!results && !isLoading) {
      dispatch(fetchResults(currentEmployee, currentRevenue));
    }
  }, [annualRevenue, numberEmployees, activeImpersonation, currentCustomer]);

  const changeEmployees = (evt) => {
    const amountEmployees = evt.target && evt.target.value ? formatWholeNumber(`${evt.target.value}`) : '';
    setEmployees(amountEmployees)
  };

  const changeRevenue = (evt) => {
    setRevenue(formatMoney(formatWholeNumber(`${evt.target.value ? evt.target.value : ''}`)));
  };

  const getResults = () => {
    trackEvent("calculate_roi", "calculator", results ? "update_roi" : "calculate_roi");
    amplitude.getInstance().logEvent(`Compliance Journey View - ${results ? "Update your ROI" : "Calculate your ROI"}`); 
    dispatch(fetchResults(employees, formatWholeNumber(revenue)));
    amplitude.getInstance().logEvent(`Compliance Journey View - ${results ? "Update your ROI" : "Calculate your ROI"}`); 
  }

  const toggleExpandedCalculator = () => {
    setShowExpanded(!showExpanded)
  }

  const renderCalculateButton = () => {
    if(results && !canModifyCompany) return null;
    const label = results ? 'Update your CyValue' : 'View your CyValue';
    const disabled = (employees === '' || employees === '0' || revenue === '$0' || revenue === '' || !canModifyCompany);
    return (
        <Button
          type="button"
          color="light"
          className="btn btn-sm btn-calculator"
          onClick={getResults}
          onKeyUp={getResults}
          disabled={disabled}
        >
          {label}
        </Button>
    )

  }

  const renderCalculator = () => {
    return (
      <div className="d-flex align-items-center flex-column input-container">
        <FormGroup className="form-input">
          <Label className="input-label input-label-employees" for="employees-input">Employees</Label>
          <Input
            className="input"
            name="employees"
            id="employees-input"
            placeholder=""
            value={employees}
            onChange={changeEmployees}
            disabled={!canModifyCompany}
          />
        </FormGroup>
        <FormGroup className="form-input">
          <Label className="input-label" for="revenue-input">Annual Revenue</Label>
          <Input
            className="input"
            name="revenue"
            id="revenue-input"
            placeholder="$"
            value={revenue}
            onChange={changeRevenue}
            disabled={!canModifyCompany}
          />
        </FormGroup>

        {renderCalculateButton()}
      </div>
    );
  };

  const renderResultRow = (title, row) => {
    return (
      <tr>
        <td>{title}</td>
        <td>{formatMoney(row?.diy || 0)}</td>
        <td>{formatMoney(row?.cyvatar || 0)}</td>
        <td className="saving-cell">{formatMoney(row?.savings || 0)}</td>
      </tr>
    )
  }

  const renderResults = () => {
    const headers = ['', 'Cost without Cyvatar or DIY', 'Cost with Cyvatar', 'CyValue Savings'];
    return (
      <div className="results-container">
        <div className="d-flex bd-highlight">
          <div className="w-100 bd-highlight results-label">
            {results?.roi ? `You are saving ${results.roi}x ROI by moving to Cyvatar` : 'Enter number of employees and annual revenue to view ROI'}
          </div>
          <div className="flex-shrink-0 bd-highlight last-update-label">
            {results?.lastUpdate && `Last updated: ${monthDateFormat(results?.lastUpdate)}`}
          </div>
        </div>

        <Table className='table-roi' borderless>
          <thead>
            <tr>
              {headers.map((title, index) => {
                return <th key={`header-${index}`} className={index === (headers.length - 1) ? 'saving-cell' : ''}>{title}</th>
              })}
            </tr>
          </thead>
          <tbody>
            {renderResultRow('Monetary risk', results?.expectant_loss)}
            {renderResultRow('Operational costs', results?.operational_cost)}
            {renderResultRow('Annual total', results?.annual_total)}
          </tbody>
        </Table>
      </div>
    )
  }

  const renderTooltip = (content, customClassName) => {
    return (
      <div className={`roi-tooltip d-flex flex-row align-items-center ${customClassName || ''}`}>
        <i className="bx bx-info-circle" id="roi-tooltip"></i>
        <UncontrolledTooltip placement="right" target="roi-tooltip">{content}</UncontrolledTooltip >
      </div>
    )
  }

  const renderChevron = () => {
    return (
      <div className="chevron-expand" onClick={toggleExpandedCalculator} onKeyUp={toggleExpandedCalculator} >
        <i className={`bx bx-chevron-${showExpanded ? 'up' : 'down'}`} />
      </div>
    )
  }

  const renderExpandedRoi = () => {
    return (
      <React.Fragment>
        <CardTitle>
          <div className="d-flex bd-highlight">
            <div className="p-2 w-100 bd-highlight">
              <div className="title p-2 bd-highlight">Your company's business justification for moving to Cyvatar</div>
            </div>
            <div className="p-2 flex-shrink-1 bd-highlight">{renderChevron()}</div>
          </div>
        </CardTitle>
        <CardBody>
          <Row>
            <Col xl={3} lg={4} md={4} sm={12}>{renderCalculator()}</Col>
            <Col xl={9} lg={8} md={8} sm={12}>{renderResults()}</Col>
          </Row>
        </CardBody>
      </React.Fragment>
    )
  }

  const renderCompactedRoi = () => {
    return (
      <CardTitle className="d-flex bd-highlight align-items-center">
        <div className="title p-2 bd-highlight">Calculate the impact of Cyvatar on your business</div>
        <div className='flex-grow-1'>
          <button className="btn btn-light text-dark btn-show-calculator" onClick={toggleExpandedCalculator} onKeyUp={toggleExpandedCalculator}>
            {results ? 'Update your CyValue' : 'Calculate your ROI'}
          </button>
        </div>
        {renderChevron()}
      </CardTitle>
    )
  }

  return (
    <React.Fragment>
      <Col sm={12} lg={12} className="align-self-stretch" >
        <Card className="h-80 roi-calculator">
          {showExpanded && renderExpandedRoi()}
          {!showExpanded && renderCompactedRoi()}
        </Card>
      </Col>
    </React.Fragment>
  )
}



export default withRouter(RoiCalculator);
