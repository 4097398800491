import React, { useState, useEffect } from 'react';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import TagsSection from './TagsSection';
import API from '../../api';
import { setAlert } from '../../actions/alerts';
import { alertTypes } from '../../constants/alerts';
import errorMessages from '../../constants/errorMessages';
import { removeTagById } from '../../utils/tagsHelper';
import useCustomMutation from '../../hooks/useCustomMutation';
import useCanAbility from '../../hooks/useCanAbility';
import { MODIFY_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';
import { useSelector } from 'react-redux';

const DevicesTags = (props) => {
    const {
        deviceId,
        tagsList,
        setReloadDevicesList,
        companyId
    } = props;

    //  Initializing APIs
    const { DeviceAPI } = API;

    //Component Hooks
    const { dispatch } = useMemoizedDispatch();
    const ability = useCanAbility();

    // Getting needed info from redux store
    const isMemberView = useSelector((state) => state?.impersonation?.isMemberView);

    // Component variables
    const canModifyDevices = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.DEVICES.GENERAL) || isMemberView;

    //  Add new tag to a device
    const {
        data: addDeviceTagResponse,
        error: addDeviceTagError,
        mutate: addDeviceTag,
    } = useCustomMutation(
        (params) => DeviceAPI.handlers.addDeviceTag(params),
        DeviceAPI.invalidators.addDeviceTag
    );

    //  Remove tag from a device
    const {
        data: removeDeviceTagResponse,
        error: removeDeviceTagError,
        mutate: removeDeviceTag,
    } = useCustomMutation(
        (params) => DeviceAPI.handlers.removeDeviceTag(params),
        DeviceAPI.invalidators.removeDeviceTag
    );

    //Component State
    const [savedTagsList, setSavedTagsList] = useState(tagsList);
    const [updatedTagsList, setUpdatedTagsList] = useState(tagsList);
    const [reloadTagsList, setReloadTagsList] = useState(false);

    // Watch for changes in tagsList
    useEffect(() => {
        setSavedTagsList(tagsList)
    }, [tagsList])

    // Watch add tag response
    useEffect(() => {
        if (!addDeviceTagResponse) return;
        const { global_tag, is_resource_updated } = addDeviceTagResponse;
        if (!is_resource_updated) return;
        const tags = [...updatedTagsList, global_tag];
        setSavedTagsList(tags);
        setUpdatedTagsList(tags);
        if (setReloadDevicesList) setReloadDevicesList(true);
        dispatch(setAlert("Tag added", alertTypes.success));
        setReloadTagsList(true)
    }, [addDeviceTagResponse])

    // Watch remove tag response
    useEffect(() => {
        if (!removeDeviceTagResponse) return;
        const { global_tag } = removeDeviceTagResponse;
        const tags = removeTagById(global_tag?.id, savedTagsList) || [];
        setSavedTagsList(updatedTagsList);
        if (setReloadDevicesList) setReloadDevicesList(true);
        dispatch(setAlert("Tag removed", alertTypes.success));
        setReloadTagsList(true)
    }, [removeDeviceTagResponse])

    // Watch add or remove error response
    useEffect(() => {
        if (!addDeviceTagError && !removeDeviceTagError) return;
        setUpdatedTagsList(savedTagsList);
        const errorMessage = addDeviceTagError?.errorMessage || removeDeviceTagError?.errorMessage || errorMessages.defaultPost;
        dispatch(setAlert(errorMessage, alertTypes.error));
    }, [addDeviceTagError, removeDeviceTagError])

    const addTag = (params) => {
        const payload = { ...params, device_id: deviceId, company_id: companyId };
        addDeviceTag(payload);
    }

    const removeTag = (global_tag_id) => {
        const tags = removeTagById(global_tag_id, updatedTagsList) || [];
        setUpdatedTagsList(tags)
        removeDeviceTag({ global_tag_id, device_id: deviceId, company_id: companyId })
    };

    return (
        <TagsSection
            tagsList={updatedTagsList}
            addTagCallback={addTag}
            removeTagCallback={removeTag}
            deviceId={deviceId}
            companyId={companyId}
            reloadTagsList={reloadTagsList}
            setReloadTagsList={setReloadTagsList}
            disabled={!canModifyDevices}
        />
    );
};

export default DevicesTags;
