import React, { useMemo } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const CustomDatepicker = (props) => {
  //  Parsing props
  const {
    disabled = false,
    value,
    minDate = new Date(),
    maxDate,
    setValue,
    placeholderText = "Select date",
  } = props;

  //  Rendering
  return (
    <div className={`calendar-container${!value ? ' inactive' : ''}`}>
      <DatePicker
        selected={value}
        onChange={setValue}
        minDate={minDate}
        maxDate={maxDate || null}
        wrapperClassName="date-picker-wrapper"
        popperClassName="datepicker-popper"
        placeholderText={placeholderText}
        disabled={disabled}
      />
      <div className="bx bx-calendar calendar-icon" />
    </div>
  )
};

export default CustomDatepicker;
