import React from 'react';
import { Button } from 'reactstrap';
import { UilAngleUp, UilAngleDown } from '@iconscout/react-unicons'

const SortButtons = (props) => {
    const {
        label,
        className="",
        fieldName,
        sortFieldName,
        isAsc,
        callback = () => {},
    } = props;
    return (
        <Button
            color="link"
            className={`btn-sort-arrows p-0 d-flex align-items-center ${className}`}
            onClick={callback}
        >
            {label}
            <div className="ml-1">
                <div className={`sort-arrow ${sortFieldName === fieldName && isAsc && 'active'}`}>
                    <UilAngleUp />
                </div>
                <div className={`sort-arrow ${sortFieldName === fieldName && !isAsc && 'active'}`}>
                    <UilAngleDown />
                </div>
            </div>
        </Button>
    )
}

export default SortButtons;
