import React, { useEffect, useMemo, useState } from 'react';
import { components } from 'react-select';
import SimpleDropdown from '../layout/SimpleDropdown';
import { generateTimePickerOptions, timepickerModes } from '../../utils/dateFormatter';

const CustomTimepicker = (props) => {
  //  Parsing props
  const { value, setValue, mode = timepickerModes.TWELVE_HOUR } = props;

  //  Component variables
  const options = useMemo(() => {
    return generateTimePickerOptions(mode);
  }, []);

  //  Component state
  const [selectedTime, setSelectedTime] = useState(null);

  //  Watching value changes
  useEffect(() => {
    if (!value) { setSelectedTime(value); }
    const selectedOption = options.find((item) => item.label === value);
    setSelectedTime(selectedOption);
  }, [value]);

  //  Funcion to handle dropdown changes
  const onDropdownChange = (option) => {
    if (!option) { return; }
    setValue(option?.label);
  }

  //  Function to render custom indicator
  const renderCustomIndicator = (indicatorProps) => {
    return (
      <components.DropdownIndicator {...indicatorProps}>
        <i className='bx bx-time-five time-icon'></i>
      </components.DropdownIndicator>
    );
  };

  //  Rendering
  return (
    <div className="time-picker-container">
      <SimpleDropdown
        extraClassName={`dropdown-filter action-dropdown time-picker${selectedTime ? ' active' : ''}`}
        placeholder="Select time"
        elements={options}
        selectedElement={selectedTime}
        onChangeCallback={onDropdownChange}
        renderCustomIndicator={renderCustomIndicator}
      />
    </div>
  );
};

export default CustomTimepicker;
