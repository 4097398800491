import React from 'react';
import DomainActionsModals from './DomainActionsModals';
import DomainContextMenu from './DomainContextMenu';
import API from '../../../api';
import useCustomMutation from '../../../hooks/useCustomMutation';
import useHandleApiResponse from '../../../hooks/useHandleApiResponse';
import useDomainActions from '../../../hooks/useDomainActions';

const DomainBulkActions = (props) => {
  //  Parsing props
  const {
    parentRef,
    company_id,
    selected = [],
  } = props;

  //  Initializing API
  const { CompanyAPI } = API;

  //  Component hooks
  const {
    actions = [],
    showScheduleModal,
    showDeleteDomainModal,
    toggleDeleteDomainModal,
    toggleScheduleModal,
    updateDomainCount,
  } = useDomainActions({
    company_id,
    startTime: null,
    isMaster: false,
  });

  //  Hook to enable disable domains
  const {
    data: enableResponse,
    error: enableError,
    mutate: enableDisableDomains,
  } = useCustomMutation(
    (params) => CompanyAPI.handlers.enableDomainScan(params),
    CompanyAPI.invalidators.enableDomainScan,
  );

  //  Hook to handle API response
  useHandleApiResponse({
    data: enableResponse,
    errorData: enableError,
    successMessage: `Scans status changed`,
  });

  //  Array of enabel disable actions
  const enableDisableActions = [
    {
      iconClassName: 'bx bx-check-circle',
      tooltipText: 'Enable',
      callback: () => onEnableDisableDomains(true)
    },
    {
      iconClassName: 'bx bx-x-circle',
      tooltipText: 'Disable',
      callback: () => onEnableDisableDomains(false)
    }
  ];

  //  Function to enable disable domains
  const onEnableDisableDomains = (status = false) => {
    const params = {
      enabled: status,
      domain_id: selected,
      company_id,
    }
    enableDisableDomains(params);
  }

  //  Function to render icon
  const renderAction = (className, text, onClick) => {
    return (
      <div
        className="bulk-menu-icon-container"
        onClick={onClick}
      >
        <div className={`bulk-menu-icon ${className}`} />
        <div className="bulk-menu-text">{text}</div>
      </div>
    );
  };

  //  Function to render actions
  const renderActions = () => {
    return [...enableDisableActions, ...actions]?.map((action) => {
      const { iconClassName, callback, tooltipText } = action;
      return renderAction(iconClassName, tooltipText, callback);
    });
  };

  //  Function to render bulk actions
  const renderBulkActions = () => {
    if (selected.length === 0) { return null; }
    return (
      <div className="member-domains-bulk-menu">
        <div className="bulk-menu-label">Bulk actions</div>
        <div className="bulk-menu-icons">
          {renderActions()}
        </div>
      </div>
    );
  };

  //  Rendering
  return (
    <>
      <DomainContextMenu
        parentRef={parentRef}
        actions={[...enableDisableActions, ...actions]}
        selected={selected}
      />
      <DomainActionsModals
        company_id={company_id}
        public_ids={selected}
        showScheduleModal={showScheduleModal}
        showDeleteModal={showDeleteDomainModal}
        toggleScheduleModal={toggleScheduleModal}
        toggleDeleteModal={toggleDeleteDomainModal}
        updateDomainCount={updateDomainCount}
      />
      {renderBulkActions()}
    </>
  );
};

export default DomainBulkActions;
