import React from 'react';
import { Tooltip, Button, Row }from 'reactstrap';

const SolutionTooltip = (props) => {
    const {
        solution,
        className = "",
        trigger="legacy",
        target,
        placement="right",
        showContactButton=true,
        isOpen,
        setIsOpen,
        showAbbreviation=true
    }= props;
    const toggle = () => {
        setIsOpen(!isOpen)
    };

    return (
        <Tooltip
            placement={placement}
            target={target}
            className={`solution-tooltip ${className}`}
            autohide={false}
            isOpen={isOpen}
            trigger={trigger}
            toggle={toggle}
        >
            <Row className="align-items-center px-3">
                <img
                    src={solution.icon}
                    alt={solution.name}
                    className="solution-icon img-fluid"
                />
                {showContactButton && (
                    <div>
                        <a
                            className="btn btn-light btn-sales"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={process.env.REACT_APP_CONTACT_SALES_URL}
                        >
                            Contact sales
                        </a>
                    </div>
                )}
                <Button
                    color="link"
                    className="close"
                    onClick={()=>setIsOpen(false)}
                >
                    <i className='bx bx-x'/>
                </Button>
            </Row>
            <Row className="title px-3">
                {`${solution.title} ${showAbbreviation ? `(${solution.name})`: ''}`}
            </Row>
            <Row className="info px-3">
                {solution.info}
            </Row>
        </Tooltip>
    )
}

export default SolutionTooltip;
