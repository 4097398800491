import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import BasicModal from './BasicModal';
import { onDeleteDeviceChange } from '../../actions/devices';
import { setAlert } from '../../actions/alerts';
import API from '../../api';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useCustomMutation from '../../hooks/useCustomMutation';
import useCanAbility from '../../hooks/useCanAbility';
import { DELETE_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';
import { alertTypes } from '../../constants/alerts';

const DeleteDevicesModal = (props) => {
  //  Initializing API
  const { DeviceAPI } = API;

  //  Watching redux store
  const deviceIds = useSelector((state) => state?.devices?.deleteModalConfig?.device_ids);

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const {
    data: deleteDevicesResponse,
    error: deleteDevicesError,
    isLoading: deleteDevicesLoading,
    mutate: callDeleteDevices,
  } = useCustomMutation(
    (params) => DeviceAPI.handlers.deleteDevices(params),
    DeviceAPI.invalidators.deleteDevices,
  );
  const ability = useCanAbility();

  // Components Variables
  const canDeleteDevices = ability.can(DELETE_ACTION, PRIVILEGE_SOURCES.DEVICES.GENERAL);

  //  Watching API success
  useEffect(() => {
    if (!deleteDevicesResponse) { return; }
    dispatch(setAlert('Devices deleted successfully. ', alertTypes.success));
    closeModal(true);
  }, [deleteDevicesResponse]);

  //  Watching API error
  useEffect(() => {
    if (!deleteDevicesError) { return; }
    dispatch(setAlert('Error deleting devices. ', alertTypes.error));
  }, [deleteDevicesError]);

  //  Function to close modal
  const closeModal = (successFlag = false) => {
    dispatch(onDeleteDeviceChange(false, [], successFlag));
  };

  //  Function to delete devices
  const deleteDevices = () => {
    callDeleteDevices({ device_ids: deviceIds });
  };

  //  Rendering
  return (
    <BasicModal
      showModal={true}
      toggleModal={() => closeModal(false)}
      title="Delete Devices"
      subtitle={`Devices in the recycle bin will be deleted after 30 days`}
      customClassName='logout-modal'
    >
      <div className="row-container logout-modal">
        <Button
          color="light-gray left-modal-button"
          outline
          onClick={() => closeModal(false)}
        >
          Cancel
        </Button>
        <Button
          disabled={deleteDevicesLoading || !canDeleteDevices}
          className="btn btn-submit btn-critical text-dark font-weight-bold delete-rule-button"
          onClick={() => deleteDevices()}
        >
          Delete
        </Button>
      </div>
    </BasicModal>
  );
};

export default DeleteDevicesModal;
