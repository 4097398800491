import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import { setMemberView } from '../../../actions/impersonation';
import { publicRoutesPaths } from '../../../constants/navigation';
import { existPathOnList, getParentPath } from '../../../utils/helpers';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import ImpersonationCompanyInfiniteDropdown from '../ImpersonationCompanyInfiniteDropdown';
import ParentBackLink from './ParentBackLink';
import companyInfoSelector from '../../../selectors/companyInfoSelector';
import companyIdSelector from '../../../selectors/companyIdSelector';
import { impersonationPathsWhiteList } from '../../../constants/company';
import useCanAbility from '../../../hooks/useCanAbility';
import { CREATE_ACTION, PRIVILEGE_SOURCES } from '../../../constants/privileges';

const SecondaryNavbar = (props) => {
  //  Parsing params
  const {
    className
  } = props;

  // Component State
  const [impersonateDisabled, setImpersonateDisabled] = useState(false);

  // Getting Needed info from redux store
  const showBackLink = useSelector(state => state.navigation.backLink?.show);
  const backLinkLabel = useSelector(state => state.navigation.backLink?.label);
  const backLinkPath = useSelector(state => state.navigation.backLink?.path);
  const includeSearchParams = useSelector(state => state.navigation?.backLink?.includeSearchParams);
  const activeImpersonation = useSelector((state) => !_.isNil(state.impersonation.companyId));
  const isMemberView = useSelector(state => state.impersonation.isMemberView);
  const companyName = useSelector((state) => companyInfoSelector(state, 'name'));
  const companyId = useSelector(state => companyIdSelector(state));

  // Component Hooks
  const location = useLocation();
  const { dispatch } = useMemoizedDispatch();
  const ability = useCanAbility();

  // Component variable
  const canImpersonate = ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.MULTIPLE.IMPERSONATE)

  //  Watching location changes
  useEffect(() => {
    if (!location || !location.pathname) { return; }
    const { pathname } = location;

    // Getting parent path
    const parentPath = getParentPath(pathname);

    //  Looking for white listed path by parent path
    const matchingPath = impersonationPathsWhiteList.find((item) => item.pathname === parentPath);

    //  If does not exist in white list, disable Impersonate Button
    if (!matchingPath) { setImpersonateDisabled(true); return; }
    const { validateLocation } = matchingPath;
    //  Validate location forces to look for an id passed on the state (edge case for members table action)
    if (!validateLocation) { setImpersonateDisabled(false); return; }
    setImpersonateDisabled(true);
  }, [location]);

  // Function to render link for going to parent route
  const renderBackLink = () => {
    if(!showBackLink) return null;
    return (
      <div className="p-3">
        <ParentBackLink
          label={backLinkLabel}
          className={className}
          customPath={backLinkPath}
          includeSearchParams={includeSearchParams}
        />
      </div>
    )
  }

  const onMemberViewToggle = () => {
    dispatch(setMemberView(!isMemberView, companyId, companyName ))
  }

  // Function to render Company Name for impersonation mode in Member View
  const renderMemberViewCompanyName = () => {
    if(!companyName || !isMemberView || !canImpersonate) return null;
    return (
      <div className="member-view-mode-company-name d-inline-block">
        {`Currently viewing as: `}<span className="font-weight-bolder">{companyName}</span>
      </div>
    )
  }

  // Function to render member view toggle button
  const renderMemberViewToggleButton = () => {
    const impersonationLabel = isMemberView ? 'End Impersonation' : 'Impersonate';
    const label = activeImpersonation ? impersonationLabel : 'View as user';
    const iconClassName = isMemberView ? 'bx bx-hide' : 'bx bx-show'

    return (
      <Button
        color="light-gray"
        className="btn-large btn-member-view-toggle"
        onClick={onMemberViewToggle}
        outline
        disabled={!activeImpersonation || impersonateDisabled}
      >
        <i className={`${iconClassName} mr-2`}/>
        {label}
      </Button>
    )
  }

  //  Function to render company dropdown
  const renderCompanyDropdown = () => {
    const isPublicRoute = existPathOnList(location.pathname, publicRoutesPaths);
    if (!canImpersonate || isPublicRoute || (isMemberView && activeImpersonation)) { return null; }
    return (
      <ImpersonationCompanyInfiniteDropdown />
    );
  };

  const renderImpersonationTools = () => {
    const isPublicRoute = existPathOnList(location.pathname, publicRoutesPaths);
    if(!canImpersonate || isPublicRoute) return null;
    return (
      <>
        <div className="ml-auto d-flex align-items-center p-3">
          {renderMemberViewCompanyName()}
          {renderMemberViewToggleButton()}
          {renderCompanyDropdown()}
        </div>
      </>
    )
  }
  //  Rendering
  return (
    <div className="secondary-navigation contained-view-width">
      {renderBackLink()}
      {renderImpersonationTools()}
    </div>
  );
};

export default SecondaryNavbar;
