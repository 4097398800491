import React, { useState, useRef } from 'react';
import { Button } from 'reactstrap';
import LandingNotification from './landing-notifications/LandingNotification';
import NotificationSidebar from './sidebar-notifications/NotificationSidebar';
import {
    usePrismicDocumentsByType,
} from "@prismicio/react";
import { useEffect } from 'react';
import { getSafeArray } from '../../utils/helpers';
import { getLastReadNotificationCookie, setLastReadNotificationCookie } from '../../utils/manageCookies';
import { LARGE_NOTIFICATIONS_MAX_NUMBER, SMALL_NOTIFICATIONS_MAX_NUMBER } from '../../constants/notifications';
import { LAST_LARGE_NOTIFICATION_COOKIE, LAST_SMALL_NOTIFICATION_COOKIE } from '../../constants/cookies';
import { useSelector } from 'react-redux';

const NotificationButton = () => {

    // Component state
    const [showSidebar, setShowSidebar] = useState(false);
    const [showLandingModal, setShowLandingModal] = useState(false);
    const [hasNotifications, setHasNotifications] = useState(false);
    const [notificationVideo, setNotificationVideo] = useState(null);

    // Getting needed info from redux store
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const userPublicId = useSelector((state) => state.auth.user.publicId);

    // Component's Hooks
    // Prismic Notifications
    const [notificationsResponse, notificationsResponseState] = usePrismicDocumentsByType("notification", {
        orderings: [{ field: "document.last_publication_date" , direction: "desc" }],
    });

    // Component's ref
    const buttonRef = useRef(null)

    // Filter notifications by is_big_modal flag
    const filterByNotificationByType = (notification, isLarge) => {
        if(notification?.data?.is_big_modal && isLarge) return true;
        if(!notification?.data?.is_big_modal && !isLarge) return true;
        return false;
    }
    const checkPendingNotificationsByType = (isLarge) => {
        const cookieName = isLarge ? LAST_LARGE_NOTIFICATION_COOKIE : LAST_SMALL_NOTIFICATION_COOKIE;
        const lastNotificationCookieData = getLastReadNotificationCookie(cookieName, userPublicId);
        const notifications = getSafeArray(notificationsResponse?.results).filter((notification) => filterByNotificationByType(notification, isLarge));
        if(!lastNotificationCookieData || notifications.length === 0) {
            if(notifications.length > 0) {
                return true;
            }
            return false;
        }

        // Compare last notification publication date with the cookie's notification date
        const lastNotification = notifications[0];
        if(!lastNotification?.last_publication_date) return;
        const cookieTime = new Date (lastNotificationCookieData?.date)?.getTime();
        const lastNotificationTime  = new Date (lastNotification?.last_publication_date)?.getTime();
        const pending = (lastNotificationTime > cookieTime);
        return pending;
    }

    // Check notifications' response changes
    useEffect(() => {
        // Check pending large notifications
        const pendingLargeNotifications = checkPendingNotificationsByType(true);
        // Toggle landing modal id true and set cookie
        if(pendingLargeNotifications) {
            toggleLandingNotifications()
        }

        // Check pending small notifications
        const pendingSmallNotifications = checkPendingNotificationsByType(false);
        // Set flag for unread small notifications
        setHasNotifications(pendingSmallNotifications)
    },[notificationsResponse])

    const markReadNotifications = (isLarge) =>{
        const cookieName = isLarge ? LAST_LARGE_NOTIFICATION_COOKIE : LAST_SMALL_NOTIFICATION_COOKIE;
        // Set last notification date cookie
        const notifications = getSafeArray(notificationsResponse?.results);
        const lastNotification = notifications[0];
        if(!lastNotification?.last_publication_date) return;
        setLastReadNotificationCookie(lastNotification?.id, lastNotification?.last_publication_date, cookieName, userPublicId)
        setHasNotifications(false)
    }

    const toggleNotificationsSidebar = () => {
        setShowSidebar(!showSidebar);
    }

    const toggleLandingNotifications = () => {
        if(showLandingModal === false) {
            const isLarge = true;
            markReadNotifications(isLarge)
        }
        setShowLandingModal(!showLandingModal);
        setNotificationVideo(null)
    }
    const onNotificationButtonClick = () => {
        if(hasNotifications && showSidebar === false) {
            const isLarge = false;
            markReadNotifications(isLarge);
        }
        toggleNotificationsSidebar()
    }
    const renderPendingIcon = () => {
        return (
            <div className='pending-notifications-icon rounded-circle'/>
        )
    }
    const renderButton = () => {
        return (
            <div
                ref={buttonRef}
            >
                <Button
                    color="link"
                    className='app-notifications-button'
                    onClick={onNotificationButtonClick}
                >
                    {hasNotifications && renderPendingIcon()}
                    <i className='notification-bell-icon bx bx-bell'></i>
                </Button>
            </div>
        )
    }

    const getNotifications = (isLarge) => {
        const notificationsArray = getSafeArray(notificationsResponse?.results);
        const maxLength = isLarge ? LARGE_NOTIFICATIONS_MAX_NUMBER : SMALL_NOTIFICATIONS_MAX_NUMBER;
        const notifications = []
        notificationsArray.forEach((notification) => {
            const {
                id,
                header,
                title,
                date,
                description,
                image,
                external_link,
                video_url,
                is_big_modal,
                inactive
            } = notification?.data || {};
            // Only add notifications by type
            if((is_big_modal && !isLarge) || (!is_big_modal && isLarge) || (inactive)) return;
            const notificationObject = {
                id,
                header,
                title,
                date,
                description,
                image,
                external_link,
                video_url
            }
            notifications.push(notificationObject)
        })

        if (notificationVideo && isLarge){
            notifications.unshift(notificationVideo);
        }

        return notifications?.slice(0, maxLength)
    }

    // Returns true if notifications query is loading
    const getLoadingState = () => {
        return notificationsResponseState?.state === 'loading';
    }

    // Return error message if query failes
    const getErrorMessage = () => {
        const failed = notificationsResponseState?.state === 'failed';
        return failed ? "Something went wrong" : null
    }

    const renderSidebar = () => {
        if(!showSidebar) return null;
        const isLoading = getLoadingState();
        const errorMessage = getErrorMessage();
        const isLargeNotification = false;
        return (
            <NotificationSidebar
                isLoading={isLoading}
                errorMessage={errorMessage}
                setShowSidebar={setShowSidebar}
                show={showSidebar}
                toggleSidebar={toggleNotificationsSidebar}
                notifications={getNotifications(isLargeNotification)}
                buttonRef={buttonRef}
                renderLandingModal={(notification)=>{
                    setNotificationVideo(notification);
                    toggleNotificationsSidebar();
                    setShowLandingModal(true);
                }}
            />
        )
    }

    const renderLandingModal = () => {
        if(!showLandingModal) return null;
        const isLoading = getLoadingState();
        const errorMessage = getErrorMessage();
        const isLargeNotification = true;
        return (
            <LandingNotification
                isLoading={isLoading}
                errorMessage={errorMessage}
                show={showLandingModal}
                toggle={toggleLandingNotifications}
                notifications={getNotifications(isLargeNotification)}
            />
        )
    }

    // Only available to signed users
    if(!isAuthenticated) return null;

    return (
        <>
            {renderSidebar()}
            {renderButton()}
            {renderLandingModal()}
        </>
    );
};

export default NotificationButton;
