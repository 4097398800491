import { useEffect } from 'react';

const useOutsideClickCallback = (ref, callback, excludedRef) => {

    const shouldExcludeRef = (target) => {
        if(!excludedRef) return false;
        return excludedRef?.current && excludedRef?.current?.contains(target)
    }
    const  handleClickOutside = (event) => {
        if(!callback || typeof(callback) !== 'function') return null;
        if (ref?.current && !ref?.current?.contains(event.target) && !shouldExcludeRef(event.target)) {
            callback();
        }
    }
    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export default useOutsideClickCallback
