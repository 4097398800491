import React from 'react';
import { Button, Row } from 'reactstrap';
import { filterOptions } from '../../../constants/filterTypes';
import { abbreviateNumber } from '../../../utils/helpers';

const ActiveFiltersList = (props) => {
    const {
        title,
        list,
        total,
        setFilter,
        clearAllFilters,
        className = '',
        containerId = '',
        containerClassName = '',
        containerStyle = {},
        containerRef,
    } = props;

    const ariaLabelLogic = (filterName) => {
        switch (filterName) {
            case 'severity':
                return 'criticality';
            case 'company_id':
                return 'company ID';
            case 'text_to_search':
                return 'search';
            case 'time_since_creation':
                return 'Time elapsed since creation';
            case 'assigned_to':
                return 'assignee';
            default:
                return filterName;
        }
    }

    return (
        <div
            id={containerId}
            className={`${containerClassName} full-width-column`}
            style={containerStyle}
            ref={containerRef}
        >
            <Row className={`active-filters no-gutters w-100 d-flex flex-nowrap ${className}`}>
                <div className="active-filters-labels-container d-flex mr-2">
                    <div className="title d-inline-block">{title}</div>
                    <div className="total d-inline-block">
                        <span className="number ml-2">{`${abbreviateNumber(total.number)} ${total.label}`}</span>
                    </div>
                </div>
                <div className={`flex-grow-1 ${list?.length > 0 ? '' : 'd-none d-md-flex'}`}>
                    <div className="filters-list" role="list">
                        {list?.map((element, index) => {
                            const {
                              filterName,
                              value,
                              label,
                              type,
                              filterOption,
                            } = element;
                            const filterOut = filterOption === filterOptions.FILTER_OUT;
                            const filterOptionClassName = filterOut ? 'filter-out' : '';
                            const ariaLabel_filter = `Filtering by ${ariaLabelLogic(filterName)} , ${label} filter`;
                            return (
                                <div
                                    key={`active-issue-filter-${index}`}
                                    className={`filter-box mr-2 text-capitalize ${filterOptionClassName}`}
                                    role="listitem"
                                >
                                    <div className="filter-box-text" tabIndex="0"
                                        aria-hidden="false"
                                        aria-label={ariaLabel_filter}>
                                        {filterOut ? `Exclude ${label}` : label}
                                    </div>
                                    <Button
                                        color="link"
                                        className="remove-filter ml-1"
                                        onClick={() => setFilter(filterName, value, label, type, filterOption)}
                                        aria-label={`close ${label} filter`}
                                    >
                                        <i className='bx bx-x'></i>
                                    </Button>
                                </div>
                            )
                        })}
                        {list?.length > 0 && (
                            <div className="filter-box mr-2 clear-all" role="listitem">
                                <Button
                                    color="link"
                                    className="remove-filter"
                                    onClick={clearAllFilters}
                                    aria-label="Clear all filters"
                                >
                                    Clear all
                                    <i className='bx bx-x ml-2'></i>
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </Row>
        </div>
    )

}
export default ActiveFiltersList;
