import React from 'react'
import { issueTypes } from '../../../constants/issues';
import ContextMenu from '../../context-menu/ContextMenu';
import ContextMenuOption from '../../context-menu/ContextMenuOption';
import IssuesStatusMenu from './IssuesStatusMenu';

const IssuesListContextMenu = (props) => {
    const {
        parentRef,
        onRightClick,
        toggleTagsModal,
        toggleAssigneesModal,
        onStatusSelectedCallback,
        scrollableRef,
        toggleCommentsModal,
        onDeleteIssuesClick,
        issueType = issueTypes.VULNERABILITIES,
        isDisabled,
    } = props;

    const onTagsClick = () => {
        toggleTagsModal()
    }

    const onAssignClick = () => {
        toggleAssigneesModal()
    }

    const onSelectCallback = (element) => {
        onRightClick(element)
    }

    const onNoteClick = () => {
        toggleCommentsModal()
    }

    return (
        <ContextMenu
            parentRef={parentRef}
            elementType="tr"
            onElementSelectCallback={onSelectCallback}
            minMenuWidth={150}
            minMenuHeight={60}
            applyOffset={true}
            yOffset={25}
            scrollableRef={scrollableRef}
        >
            <IssuesStatusMenu
                onStatusSelected={onStatusSelectedCallback}
                issueType={issueType}
                isDisabled={isDisabled}
            />
            <ContextMenuOption
                label="Assign to..."
                callback={onAssignClick}
            />
            <ContextMenuOption
                label="Tags"
                callback={onTagsClick}
            />
            <ContextMenuOption
                label="Post note"
                callback={onNoteClick}
            />
            <ContextMenuOption
                label="Delete"
                callback={onDeleteIssuesClick}
                className="rounded-bottom"
            />
        </ContextMenu>
    )
}

export default IssuesListContextMenu
