import React, { useEffect, useRef, useState } from 'react';
import API from '../../api';
import useCustomMutation from '../../hooks/useCustomMutation';
import useHandleApiResponse from '../../hooks/useHandleApiResponse';
import { fileToBase64 } from '../../utils/attachmentsHelpers';
import useCustomQuery from '../../hooks/useCustomQuery';
import emptyLogo from '../../assets/images/preview-images/empty-company-logo.svg';
import { Col } from 'reactstrap';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import { setAlert } from '../../actions/alerts';
import LoadingContent from '../layout/LoadingContent';

const CompanyProfileLogo = (props) => {

  const {
    companyId,
  } = props;

  // Initializing api
  const { CompanyAPI } = API;

  //Component state
  const [currentImageFile, setCurrentImageFile] = useState(emptyLogo); // The image currently uploaded for the company or the empty image in case of not having one
  const [imageSelected, setImageSelected] = useState(null); // The image selected from the device

  //Component ref
  const hiddenUploadRef = useRef(null);
  const hiddenChangeRef = useRef(null);

  // Component Hooks
  const { dispatch } = useMemoizedDispatch();

  const { // Get logo endpoint
    data: getLogoData,
    error: getLogoError,
    isLoading: getLogoLoading,
  } = useCustomQuery(
    [CompanyAPI.keys.getCompanyProfileLogo, { companyId: companyId }],
    ({ queryKey }) => CompanyAPI.handlers.getCompanyProfileLogo(queryKey[1]),
    {retry: 0}
  );

  useHandleApiResponse({ // Handle get Logo endpoint response
    data: getLogoData,
    errorData: getLogoError,
    successCallback: () => {
      if (getLogoData?.available_image) {
        setCurrentImageFile(getLogoData?.image);
      } else {
        setCurrentImageFile(emptyLogo);
      }
    },
    errorCallback: () => {
      setCurrentImageFile(emptyLogo);
    },
  });

  const { // Endpoint for upload image logo
    data: addLogoData,
    error: addLogoError,
    isLoading: addLogoLoading,
    mutate: addLogo,
  } = useCustomMutation(
    (params) => CompanyAPI.handlers.uploadCompanyProfileLogo(params),
    CompanyAPI.invalidators.uploadCompanyProfileLogo
  );

  useHandleApiResponse({ //Handle upload Logo endpoint response
    data: addLogoData,
    errorData: addLogoError,
    errorCallback: () => {
      setCurrentImageFile(emptyLogo);
    },
  });

  const { // Endpoint for deleting image logo
    data: deleteLogoData,
    error: deleteLogoError,
    isLoading: deleteLogoLoading,
    mutate: deleteLogo,
  } = useCustomMutation(
    (params) => CompanyAPI.handlers.deleteCompanyProfileLogo(params),
    CompanyAPI.invalidators.deleteCompanyProfileLogo
  );

  useHandleApiResponse({ //Handle delete Logo endpoint response
    data: deleteLogoData,
    errorData: deleteLogoError,
    errorCallback: () => {
      setCurrentImageFile(emptyLogo);
    },
  });

  useEffect(() => { // Hook for uploading a new image in base64
    if (imageSelected) {
      fileToBase64(imageSelected).then((result) => {
        const params = {companyId, file : result};
        addLogo(params);
      },(error) => {
        dispatch(setAlert('Please try again', 'danger'));
      });
    }
  }, [imageSelected]);

  // Function to handle upload/change image selection
  const handleClickSelection = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded?.size / 1024 > 800) { // larger than 800kbs
      dispatch(setAlert('Image size is greater than the maximum limit', 'danger'));
      return;
    } else {
      setImageSelected(fileUploaded);
    }
  };

  // Function to render upload/change image buttons
  const renderUploadChangeButton = (ref, iconClassName, label) => {
    return (
      <>
        <button
          className="btn company-logo-button"
          onClick={() => ref.current.click()}
        >
          <i className={`bx ${iconClassName}`}/>
          {label}
        </button>
        <input
          type="file"
          accept="image/png, image/jpg"
          style={{ display: "none" }}
          ref={ref}
          onChange={handleClickSelection}
        />
      </>
    );
  };

  // Function to render and call delete image
  const renderDeleteButton = () => {
    return (
      <>
        <button
          className="btn company-logo-button"
          onClick={() => {
            const params = {companyId};
            deleteLogo(params);
          }}
        >
          <i className="bx bx-trash" />
          Delete
        </button>
      </>
    );
  };

  const renderCompanyLogo = () => {
    return (
      <Col lg={3} className="company-logo-container">
        <LoadingContent
          isLoading={getLogoLoading || addLogoLoading || deleteLogoLoading}
        >
          <img className="company-logo" src={currentImageFile || emptyLogo} key={currentImageFile || emptyLogo}/>
          <div className='buttons-container'>
            {currentImageFile !== emptyLogo ? renderUploadChangeButton(hiddenChangeRef, 'bx-image', 'Change image') : null}
            {currentImageFile !== emptyLogo ? renderDeleteButton() : null}
            {currentImageFile === emptyLogo ? renderUploadChangeButton(hiddenUploadRef,'bx-upload', 'Upload') : null}
          </div>
          <p className='max-image-size'>Max image size 800kbs</p>
        </LoadingContent>
      </Col>
    );
  }

  return (
    <>
      {renderCompanyLogo()}
    </>
  )
}

export default CompanyProfileLogo