import React from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { formatMoney, formatWholeNumber } from '../utils/helpers';
import { renderInput, renderPasswordField } from '../utils/renderFormFields';
import { inputTypes } from '../utils/validations';
import { notificationSettingsIds } from './profile';

export const selectPartnerTemplate = {
  name: 'partner{index}',
  label: 'Partner',
  placeholder: 'Select Partner',
  className: 'form-control',
  type: inputTypes.select,
}

export const emailFieldTemplate = {
  name: 'email{index}',
  label: 'Email',
  placeholder: 'Email',
  className: 'form-control',
  type: inputTypes.email,
};

export const firstNameTemplate = {
  name: 'first_name{index}',
  label: 'Name',
  placeholder: 'Enter first name',
  className: 'form-control',
  type: inputTypes.text,
};


export const lastNameTemplate = {
  name: 'last_name{index}',
  label: 'Last name',
  placeholder: 'Enter last name',
  className: 'form-control',
  type: inputTypes.text,
};

export const domainTemplate = {
  name: 'domain_name{index}',
  label: 'Add new domain',
  className: 'form-control dark',
  type: inputTypes.domain,
};

export const wrapperTypes = {
  COL: 'COL',
  ROW: 'ROW',
  NO_CONTAINER: 'NO_CONTAINER',
};

export const singleNotificationTemplate = {
  name: '{type}_{notification_type}',
  id: '',
  targetId: '{type}_{notification_type}',
  label: '',
  className: 'single-notification-settings-checkbox',
  isSelected: false,
};

export const bulkNotificationTemplate = {
  name: '{type}_BULK_{notification_type}',
  id: '',
  targetId: '{type}_BULK_{notification_type}',
  label: '',
  className: 'single-notification-settings-checkbox',
  isSelected: false,
};

export const noteNotificationTemplate = {
  name: '',
  id: '',
  targetId: '',
  label: '',
  className: 'single-notification-settings-checkbox',
}

export const buildNoteNotificationFromTemplate = (template, name, targetId, label, className) => {
  const newId = notificationSettingsIds[name];
  return({
    ...template,
    name: name || template.name,
    id: newId || template.id,
    targetId: targetId || template.targetId,
    label: label || template.label,
    className: className || template.className,
  });
};

export const buildSingleNotificationFromTemplate = ( template, type, notificationType, label, isSelected ) => {
  const newNameAndTargetId = template.name.replace('{type}',type).replace('{notification_type}',notificationType);
  const newId = notificationSettingsIds[newNameAndTargetId];
  return ({
    ...template,
    name: newNameAndTargetId,
    id: newId || template.id,
    targetId: newNameAndTargetId,
    label: label || template.label,
    isSelected: isSelected || template.isSelected,
  });
};

export const buildBulkNotificationFromTemplate = ( template, type, notificationType, label, isSelected ) => {
  const newNameAndTargetId = template.name.replace('{type}',type).replace('{notification_type}',notificationType);
  const newId = notificationSettingsIds[newNameAndTargetId];
  return ({
    ...template,
    name: newNameAndTargetId,
    id: newId || template.id,
    targetId: newNameAndTargetId,
    label: label || template.label,
    isSelected: isSelected || template.isSelected
  });
};

export const buildSelectObjectFromTemplate = (index, template, label, placeholder, wrapperClassName) => ({
  ...template,
  name: template.name.replace('{index}', index),
  wrapperClassName: wrapperClassName || "",
  placeholder: placeholder || template.placeholder,
  label: label || template.label
});

export const buildFieldObjectFromTemplate = (index, template, label, placeholder, wrapperClassName) => ({
  ...template,
  name: template.name.replace('{index}', index),
  wrapperClassName: wrapperClassName || "",
  placeholder: placeholder || template.placeholder,
  label: label || template.label
});

export const buildEmailFieldObject = (index, template) => ({
  ...template,
  name: template.name.replace('{index}', index),
});

export const buildDomainFieldObject = (index, template) => ({
  ...template,
  name: template.name.replace('{index}', index),
});

//  Fields for invited member register
export const invitedMemberRegisterForm = [
  {
    name: 'first_name',
    label: 'First Name',
    placeholder: 'First Name',
    className: 'form-control',
    type: inputTypes.text,
    render: renderInput,
  },
  {
    name: 'last_name',
    label: 'Last Name',
    placeholder: 'Last Name',
    className: 'form-control',
    type: inputTypes.text,
    render: renderInput,
  },
  {
    name: 'password',
    label: 'Set a secure password',
    placeholder: 'Enter password',
    className: 'form-control',
    type: inputTypes.password,
    render: renderPasswordField,
  },
];

//  Tooltip for password
const renderPasswordTooltip = () => {
  return (
    <Link to="#">
      <i className="bx bx-info-circle info-tooltip" id="password-tooltip"></i>
      <UncontrolledTooltip
        className="password-requirements-tooltip"
        placement="right"
        target="password-tooltip"
      >
        <p>Your password must be at least 12 characters long, include:</p>
        <ul>
          <li>1 uppercase and lowercase letter</li>
          <li>1 number</li>
          <li>1 special character</li>
        </ul>
      </UncontrolledTooltip>
    </Link>
  );
};

//  Fields for freemium login
export const freemiumLoginForm = {
  [wrapperTypes.ROW]: [
    {
      name: "firstName",
      label: "First Name",
      placeholder: "first name",
      customClassName: "form-control freemium-field",
      defaultValueKey: 'defaultFirstName',
      render: renderInput,
      wrapper: wrapperTypes.COL,
      type: inputTypes.text,
    },
    {
      name: "lastName",
      label: "Last Name",
      placeholder: "last name",
      customClassName: "form-control freemium-field",
      defaultValueKey: 'defaultLastName',
      render: renderInput,
      wrapper: wrapperTypes.COL,
      type: inputTypes.text,
    },
  ],
  [wrapperTypes.NO_CONTAINER]: [
    {
      name: "company",
      label: "Company Name",
      placeholder: "enter company name",
      customClassName: "form-control freemium-field",
      defaultValueKey: 'defaultCompany',
      render: renderInput,
      wrapper: null,
      type: inputTypes.text,
    },
    {
      name: "email",
      label: "Work Email",
      placeholder: "enter email",
      customClassName: "form-control freemium-field",
      defaultValueKey: 'defaultEmail',
      render: renderInput,
      wrapper: null,
      type: inputTypes.text,
    },
    {
      name: "password",
      label: "Set a secure password",
      placeholder: "enter password",
      customClassName: "form-control freemium-field",
      defaultValueKey: null,
      render: renderPasswordField,
      renderTooltip: renderPasswordTooltip,
      wrapper: null,
      type: inputTypes.password,
    }
  ]
};

//  Fields for issue details editable part
export const issueDetailsEditableFields = [
  {
    name: 'name',
    label: 'Issue name',
    placeholder: 'Name this issue…',
    maxRows: 1,
    verifyUser: true,
  },
  {
    name: 'description',
    label: 'Description',
    placeholder: 'Add Description…',
    maxRows: 5,
    verifyUser: false,
  }
];


// Fields for reffering an user

export const referUserForm = {
  [wrapperTypes.ROW]: [
    {
      name: 'first_name',
      label: 'First name',
      placeholder: 'Your friend’s first name',
      render: renderInput,
      wrapper: wrapperTypes.COL,
      type: inputTypes.text,
      className: 'form-control',
    },
    {
      name: 'last_name',
      label: 'Last name',
      placeholder: 'Your friend’s last name',
      render: renderInput,
      wrapper: wrapperTypes.COL,
      type: inputTypes.text,
      className: 'form-control',
    },
  ],
  [wrapperTypes.NO_CONTAINER]: [
    {
      name: "company_name",
      label: "Company",
      placeholder: "Your friend’s company name",
      customClassName: "form-control",
      render: renderInput,
      wrapper: null,
      type: inputTypes.text,
    },
    {
      name: "email",
      label: "Email",
      placeholder: "Your friend’s email",
      customClassName: "form-control",
      render: renderInput,
      wrapper: null,
      type: inputTypes.text,
    },
  ]
}

// Fields for new member form
export const newMemberForm = {
  [wrapperTypes.NO_CONTAINER]: [
    {
      name: "company_name",
      label: "Company name",
      placeholder: "Enter company name",
      customClassName: "form-control",
      render: renderInput,
      wrapper: null,
      type: inputTypes.text,
    }
  ],
  [wrapperTypes.ROW]: [
    {
      name: 'employee_count',
      label: 'Employee count',
      placeholder: 'Enter number of employees',
      render: renderInput,
      wrapper: wrapperTypes.COL,
      type: inputTypes.text,
      className: 'form-control',
      parse: value => formatWholeNumber(value),
      format: value => formatWholeNumber(value),
    },
    {
      name: 'annual_revenue',
      label: 'Annual Revenue',
      placeholder: '$',
      render: renderInput,
      wrapper: wrapperTypes.COL,
      type: inputTypes.text,
      className: 'form-control',
      parse: value => formatWholeNumber(value),
      format: value => value ? formatMoney(formatWholeNumber(value)) : "",
    },
  ],
};

export const passwordFieldTemplate = {
  name: 'password',
  label: 'Password',
  placeholder: 'Enter password',
  className: 'form-control',
  type: inputTypes.password,
  render: renderPasswordField,
};

export const passwordFieldLoginTemplate = {
  name: 'password',
  label: 'Password',
  placeholder: 'Enter password',
  className: 'form-control',
  type: inputTypes.passwordLogin,
  render: renderPasswordField,
};
