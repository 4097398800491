import React from 'react';
import { useHistory } from 'react-router-dom';
import LoadingPage from '../components/layout/LoadingPage';
import FreemiumLoginInformation from '../components/auth/FreemiumLoginInformation';

const FreemiumInformationWrapper = (props) => {
  //  Parsing props
  const {
    children,
    readyToRender = true,
    logoOnly = false,
    customClassName = '',
    customInfoClass = '',
  } = props;

  //  Component hooks
  const history = useHistory();

  //  Function to handle back to home click
  const onGoToHomeClicked = () => {
    history.push('/welcome');
  };

  //  Function to render back to home link
  const renderBackToHome = () => {
    return (
      <div className="back-to-home-container">
        <div
          className="back-to-home-inner-container"
          onClick={() => onGoToHomeClicked()}
        >
          <div className="bx bx-home back-to-home-icon" />
          <div className="back-to-home-text">Back to homepage</div>
        </div>
      </div>
    );
  };

  //  Function to render divider arrow
  const renderDivider = () => {
    return (
      <div className="freemium-divider">
        {!logoOnly && <div className="bx bx-right-arrow-alt freemium-divider-icon" />}
      </div>
    );
  };

  //  Function to render page content
  const renderPageContent = () => {
    if (!readyToRender) { return <LoadingPage />; }
    return (
      <>
        <div className={`freemium-login-content ${customClassName}`}>
          <div className="freemium-login-inner-content">
            <FreemiumLoginInformation logoOnly={logoOnly} customClassName={customInfoClass}/>
            {logoOnly && renderDivider()}
            <div className="freemium-form-container">
              {children}
            </div>
          </div>
        </div>
        {logoOnly && renderBackToHome()}
      </>
    );
  };

  //  Rendering
  return (
    <div className="freemium-login-container">
      {renderPageContent()}
    </div>
  );
};

export default FreemiumInformationWrapper;
