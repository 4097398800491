import React from 'react'

const ContextMenuOption = (props) => {
    const {
        className="",
        label,
        callback,
        isDisabled,
    } = props;

    return (
        <div
            className={`context-menu-option ${className} ${isDisabled ? 'is-disabled' : '' }`}
            onClick={() => {
                if(callback) callback()
            }}
        >
            {label}
        </div>
    )
}

export default ContextMenuOption;
