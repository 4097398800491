import React from 'react';
import { components } from 'react-select';


const SelectDropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <i className='bx bxs-down-arrow'></i>
        </components.DropdownIndicator>
    )
}

export default SelectDropdownIndicator;
