import { useEffect } from "react";
import { setAlert } from "../actions/alerts";
import { alertTypes } from "../constants/alerts";
import errorMessages from "../constants/errorMessages";
import { isValidFunction } from "../utils/helpers";
import useMemoizedDispatch from "./useMemoizedDispatch";

const useHandleApiResponse = (params) => {
  const {
    data,
    errorData,
    successMessage,
    successCallback,
    errorCallback
  } = params;

  // Hooks
  const { dispatch } = useMemoizedDispatch();

  useEffect(() => {
    if(!data) return;
    if(successMessage) {
      dispatch(setAlert(successMessage, alertTypes.success));
    }
    if(isValidFunction(successCallback)) {
      successCallback(data);
    }
  }, [data])

  useEffect(() => {
    if(!errorData) return;
    if(isValidFunction(errorCallback)) {
      errorCallback(errorData);
    }
    const errorMessage = errorData?.response?.data?.errorMessage || errorMessages.defaultPost;
    dispatch(setAlert(errorMessage, alertTypes.error));
  }, [errorData])
}

export default useHandleApiResponse;
