import _ from 'lodash';
import {
    SELECT_ANSWER,
    DESELECT_ANSWER,
    ANSWERS_SUCCESS,
    ANSWERS_FAILURE,
    CLEAR_ANSWERS,
    SET_COMMENT,
    SET_ASSESSMENT_COMMENT,
    SHARED_ANSWERS_SUCCESS,
    SAVE_ANSWERS_SUCCESS,
    CREATE_EXECUTION_SUCCESS,
    SAVE_COMPLIANCE_ASSESSMENT_FAILURE,
    SAVE_COMPLIANCE_ASSESSMENT_SUCCESS,
    COMPLIANCE_ASSESSMENT_SUCCESS,
    SET_SHOW_REPORT_CARD,
    RESET_ASSESSMENT_ANSWERS,
    LOGOUT_SUCCESS
} from '../actions/types';
const initialState = {
    questionAnswers: {},
    questionsByCategory: {},
    assessmentExecutionId: null,
    assessmentExecutionStatus: 0,
    questionComments: {},
    sectionComments: {},
    assessmentComment: '',
    loading: true,
    processing: false,
    error: {},
    score: {
        average: null,
        byCategory: {}
    },
    showReportCard: null,
    unsavedChanges: false
}
export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SELECT_ANSWER: {
            const questionAnswers = {
                ...state.questionAnswers,
                [payload.questionId]: payload.optionId
            }

            const questionsByCategory = state.questionsByCategory || {};
            questionsByCategory[payload.categoryId] = questionsByCategory[payload.categoryId] || {};
            questionsByCategory[payload.categoryId][payload.sectionId] = questionsByCategory[payload.categoryId][payload.sectionId] || {};
            questionsByCategory[payload.categoryId][payload.sectionId][payload.questionId] = payload.optionId;


            localStorage.setItem('questions', JSON.stringify(questionAnswers));
            localStorage.setItem('questionsByCategory', JSON.stringify(questionsByCategory));
            return {
                ...state,
                questionsByCategory,
                questionAnswers: {
                    ...state.questionAnswers,
                    [payload.questionId]: payload.optionId
                },
                unsavedChanges: true
            };
        }
        case DESELECT_ANSWER: {
            const questionAnswers = _.omit(state.questionAnswers, [payload.questionId]);

            const questionsByCategory = state.questionsByCategory[payload.categoryId][payload.sectionId];
            delete questionsByCategory[payload.questionId];

            localStorage.setItem('questionsByCategory', JSON.stringify(questionsByCategory));
            localStorage.setItem('questions', JSON.stringify(questionAnswers));
            return {
                ...state,
                questionsByCategory,
                questionAnswers
            };
        }
        case SET_COMMENT: {
            let key = 'sectionComments'
            if (payload.type === 'question') {
                key = 'questionComments';
            }
            const comments = {
                ...state[key],
                [payload.id]: payload.comment
            }
            //localStorage.setItem(key, JSON.stringify(comments));
            return {
                ...state,
                [key]: comments,
                unsavedChanges: true
            };
        }
        case SET_ASSESSMENT_COMMENT: {
            localStorage.setItem('assessmentComment', payload);
            localStorage.setItem('assessmentExecutionStatus', 1);
            return {
                ...state,
                assessmentComment: payload,
                assessmentExecutionStatus: 1,
                showReportCard: null
            };
        }
        case CREATE_EXECUTION_SUCCESS: {
            return {
                ...state,
                questionAnswers: {},
                questionsByCategory: {},
                sectionComments: {},
                assessmentComment: payload?.comment || '',
                assessmentExecutionId: payload?.id,
                assessmentExecutionStatus: payload?.status,
                loading: false,
                score: {
                    average: null,
                    byCategory: {}
                },
                showReportCard: true
            }
        }
        case ANSWERS_SUCCESS:
        case COMPLIANCE_ASSESSMENT_SUCCESS:
        case SHARED_ANSWERS_SUCCESS:
            return {
                ...state,
                questionAnswers: payload ? payload.questionAnswers : {},
                questionsByCategory: payload ? payload.questionsByCategory : {},
                sectionComments: payload ? payload.sectionComments : {},
                questionComments: payload ? payload.questionComments : {},
                assessmentComment: payload && payload.assessment_execution ? payload.assessment_execution.comment : '',
                assessmentExecutionId: payload.assessment_execution ? payload.assessment_execution.id : null,
                assessmentExecutionStatus: payload.assessment_execution ? payload.assessment_execution.status : null,
                loading: false,
                score: {
                    average: payload && payload.score ? payload.score.average : null,
                    byCategory: payload && payload.score ? payload.score.by_category : null
                },
                showReportCard: type === COMPLIANCE_ASSESSMENT_SUCCESS ? null : payload.assessment_execution && payload.assessment_execution.status === 1 ? true : false,
                unsavedChanges: false
            }
        case ANSWERS_FAILURE:
            return {
                ...state,
                assessmentExecutionId: null,
                assessmentExecutionStatus: 0,
                sectionComments: {},
                assessmentComment: '',
                loading: true,
                error: {},
                score: {
                    average: null,
                    byCategory: {}
                },
                showReportCard: null,
                unsavedChanges: false
            };
        case RESET_ASSESSMENT_ANSWERS:
        case CLEAR_ANSWERS:
        case LOGOUT_SUCCESS: {
            if (localStorage.getItem('questions')) {
                localStorage.removeItem('questions');
            }
            if (localStorage.getItem('questionsByCategory')) {
                localStorage.removeItem('questionsByCategory');
            }
            if (localStorage.getItem('comments')) {
                localStorage.removeItem('comments');
            }

            if (localStorage.getItem('assessmentComment')) {
                localStorage.removeItem('assessmentComment');
            }

            if (localStorage.getItem('assessmentExecutionStatus')) {
                localStorage.removeItem('assessmentExecutionStatus');
            }
            return {
                ...state,
                assessmentExecutionId: null,
                assessmentExecutionStatus: 0,
                questionAnswers: {},
                questionsByCategory: {},
                sectionComments: {},
                questionComments: {},
                assessmentComment: '',
                loading: true,
                error: {},
                score: {
                    average: null,
                    byCategory: {}
                },
                processing: false,
                showReportCard: null,
                unsavedChanges: false
            };
        }

        case SAVE_ANSWERS_SUCCESS:
            return {
                ...state,
                showReportCard: null
            }
        case SAVE_COMPLIANCE_ASSESSMENT_SUCCESS: {
            return {
                ...state,
                processing: false,
                showReportCard: null,
                unsavedChanges: false
            }
        }
        case SAVE_COMPLIANCE_ASSESSMENT_FAILURE: {
            return {
                ...state,
                processing: false,
                showReportCard: null
            }
        }

        case SET_SHOW_REPORT_CARD: {
            return {
                ...state,
                showReportCard: payload
            }
        }
        default:
            return state;
    }
}
