import React from 'react'
import { Progress } from 'reactstrap';

const GradientProgressBar = (props) => {
    const {
        progress
    } = props;

    return (
        <div className="gradient-progress-bar w-100">
                <Progress value={progress}/>
        </div>
    )
}

export default GradientProgressBar
