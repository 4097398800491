import React from 'react';
import SimpleDropdown from '../../../layout/SimpleDropdown';
import { inputTypes } from '../../../../constants/groups';

const PartnerFilterRow = (props) => {
  //  Parsing props
  const {
    filters = {},
    partner = {},
    onFilterChange = () => { },
  } = props;

  //  Function to handle number changes
  const onNumberChange = (attribute, name, event) => {
    const { target = {} } = event;
    const { value = '' } = target;

    const regexpPattern = new RegExp('^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$');
    if (value && !regexpPattern.test(value)) { return; }

    //  Converting to float and validating range
    const floatValue = parseFloat(value);
    if (!isNaN(floatValue)) {
      if (floatValue < 0 || floatValue > 10) { return; }
    }

    //  Building option and setting new value
    const option = {
      value: isNaN(floatValue) ? null : floatValue,
      label: floatValue,
    }
    onFilterChange(attribute, name, option);
  };

  //  Function to render field
  const renderField = (field = {}) => {
    const { type, attribute, name } = field;

    //  Rendering dropdown
    if (type === inputTypes.DROPDOWN) {
      const { options = [] } = field;
      return (
        <SimpleDropdown
          extraClassName={`dropdown-select`}
          placeholder={name}
          elements={options}
          onChangeCallback={(option) => onFilterChange(attribute, name, option)}
          selectedElement={options?.find((option) => option?.value === filters[attribute])}
        />
      );
    }

    //  Rendering number inputs
    if (type === inputTypes.NUMERIC) {
      return (
        <input
          id={attribute}
          placeholder=""
          name={attribute}
          type="number"
          className="number-field"
          value={filters[attribute]}
          onChange={(event) => onNumberChange(attribute, name, event)}
        />

      );
    }

    //  Default rendering
    return null;
  }

  //  Function to render all filters
  const renderFilters = () => {
    return partner?.filters?.map((filter, index) => {
      return (
        <div
          key={index}
          className={`filter-container${filter?.type === inputTypes.NUMERIC ? ' numeric-filter' : ''}`}
        >
          <div className="filter-label">{filter?.name}</div>
          {renderField(filter)}
        </div>
      );
    })
  };

  //  Rendering
  return (
    <div className="partner-row">
      <div className="partner-label">{partner?.name}</div>
      <div className="filters-section">
        {renderFilters()}
      </div>
    </div>
  );
};

export default PartnerFilterRow;
