import React from 'react';
import PARTNERS from '../../../constants/partners';
import { getActors } from '../../../utils/partnerHelpers';
import PartnerActions from '../actions/PartnerActions';

const PartnerDetails = ({ issue, group, task }) => {
  const partnerName = issue?.partner?.name || group?.partner_name;
  const extraFields = issue?.extra_fields;


  //  Rendering detailsß
  const renderDetails = () => {
    if (group || !issue) return null;

    switch (partnerName) {
      case PARTNERS.SENTINEL_ONE:
        return renderSentinelInfo();
      case PARTNERS.AUTOMOX:
        return renderAutomoxInfo();
      case PARTNERS.TENABLE_API:
        return renderTenableInfo();
      case PARTNERS.OBSIDIAN:
        return renderObsidianInfo();
      default:
        return null
    }
  };

  const renderLabel = (title, value) => {
    return (
      <div className="info-group">
        <div className="label-container">
          <label>{title}</label>
        </div>
        <div className="info-container info-text text-light-gray">
          {value}
        </div>
      </div>
    );
  }

  // SentinelOne details and partner_fields display
  const renderSentinelInfo = () => {
    const { partner_fields = {}, extra_fields = {} } = issue;
    const { classification } = partner_fields;
    const { threatInfo = {} } = extra_fields;
    const { engines = [], initiatedByDescription, detectionType } = threatInfo;

    return (
      <>
        {renderLabel('Classification', classification || 'N/A')}
        {renderLabel('Engine', engines[0] ? engines[0] : 'N/A')}
        {renderLabel('Initiated By', initiatedByDescription || 'N/A')}
        {renderLabel('Detection Type', detectionType || 'N/A')}
      </>
    )
  }

  // Automox details and partner_fields display
  const renderAutomoxInfo = () => {
    const { partner_fields = {}, extra_fields = {} } = issue;
    const { issue_external_id } = partner_fields;
    const packageId = issue_external_id || extra_fields?.package_id || 'N/A';

    return renderLabel('Package ID', packageId);
  }

  // Automox details and partner_fields display
  const renderTenableInfo = () => {
    const { partner_fields = {}, extra_fields = {} } = issue;
    const { issue_external_id, exploit_available, vpr_score } = partner_fields;
    const pluginID = issue_external_id || extra_fields?.id || 'N/A';

    return renderLabel('Plugin ID', pluginID);
  }

  const renderObsidianInfo = () => {
    return (
      <>
        {renderLabel('Service', extraFields?.service?.name)}
        {extraFields?.taxonomy &&
          renderLabel('Behavior', extraFields?.taxonomy?.behavior)}
        {extraFields?.actors
          && renderLabel('Actors', getActors(extraFields?.actors))}
      </>
    );
  }

  if (!partnerName) return <></>
  // Rendering
  return (
    <div className='partner-details'>
      <div className='partner-name'>{partnerName}</div>
      {renderDetails()}
      <PartnerActions issue={issue} group={group} task={task}/>
    </div>
  )
};

export default PartnerDetails;
