import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'reactstrap';
import useCustomQuery from '../../hooks/useCustomQuery';
import companyIdSelector from '../../selectors/companyIdSelector';
import IconCard from '../layout/cards/IconCard';
import CommentsSection from '../layout/comments/CommentsSection';
import LoadingContent from '../layout/LoadingContent';
import CustomCheckBox from '../layout/CustomCheckBox';
import API from '../../api';
import { ERROR_SOLID_ICON } from '../../constants/errorStyles';
import { getApiErrorMessage, getSafeArray } from '../../utils/helpers';
import useCustomMutation from '../../hooks/useCustomMutation';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import { setAlert } from '../../actions/alerts';
import errorMessages from '../../constants/errorMessages';
import { alertTypes } from '../../constants/alerts';
import { Can } from '../../contexts/AbilityContext';
import useCanAbility from '../../hooks/useCanAbility';
import { CREATE_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';
import { DEFAULT_COMMENT_SECTION_TYPE } from '../../constants/common';

const MemberNotes = (props) => {

  const {
    lg = 4
  } = props;

  //  Initializing APIs
  const { CompanyAPI } = API;

  // Getting needed info from the store
  const companyId = useSelector(state => companyIdSelector(state));
  const userName = useSelector(state => state?.auth?.user?.name);

  //  Component state
  const [state, setState] = useState({ isProcessing: false, newAdded: false });
  const [notes, setNotes] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);

  // Component Hooks
  const { dispatch } = useMemoizedDispatch();
  const ability = useCanAbility();

  //  Calling API for data
  const {
    data: notesData,
    error: notesError,
    isLoading: notesLoading,
    refetch: refetchNotes,
  } = useCustomQuery(
    [CompanyAPI.keys.listCompany, { company_id: companyId }],
    ({ queryKey }) => CompanyAPI.handlers.listCompanyNotes(queryKey[1]),
  );

  //	Calling endpoint to create note
  const {
    data: createNoteResponse,
    error: createNoteError,
    isLoading: createNoteLoading,
    mutate: createNote,
  } = useCustomMutation(
    (params) => CompanyAPI.handlers.createCompanyNote(params),
    CompanyAPI.invalidators.createCompanyNote
  );

  // Component Variables
  const canViewPrivateNotes = ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.MEMBERS.PRIVATE_COMPANY_NOTES);

  // Checking notes changes
  useEffect(() => {
    setNotes(getSafeArray(notesData?.notes))
  }, [notesData])

  // Checking create note response
  useEffect(() => {
    if(!createNoteResponse) return;
    setState({
      ...state,
      newAdded: true,
      isProcessing: false,
    });
    setIsPrivate(false);
    if(!createNoteResponse?.note || typeof(createNoteResponse?.note) != 'object') return;
    setNotes([
      {
        ...createNoteResponse?.note,
        user: {
          full_name: userName,
        }
      },
      ...notes
    ])
  }, [createNoteResponse])

  // Checking creating note errors
  useEffect(() => {
    if(!createNoteError) return;
    setState({
      ...state,
      isProcessing: false,
    })
    dispatch(setAlert(errorMessages.defaultPost, alertTypes.error));
  }, [createNoteError])

  //  Function to handle comment sections changes
  const onPostNoteClick = (content) => {
    if (!content) {
      // Reset private flag
      setIsPrivate(false);
      return;
    }

    const params = {
      content,
      company_id: companyId,
      is_private: isPrivate,
    };
    setState({
      ...state,
      isProcessing: true,
    })
    createNote(params)
  };

  const onPrivateNoteClick = () => {
    setIsPrivate(!isPrivate)
  }

  // Render functions
  const renderIsPrivateCheckbox = () => {
    return (
      <Can I={CREATE_ACTION} a={PRIVILEGE_SOURCES.MEMBERS.PRIVATE_COMPANY_NOTES}>
        <CustomCheckBox
          isSelected={isPrivate}
          className=""
          callback={onPrivateNoteClick}
          label="Private"
          isDisabled={createNoteLoading}
        />
      </Can>
    )
  }

  // Render
  return (
    <Col
      lg={lg}
      className="align-self-lg-stretch mb-3"
    >
        <IconCard
            title="Notes"
            titleClassName="member-notes-title"
            iconClassName="bx bx-notepad text-white"
            iconWrapperClassName="bg-active-purple"
            cardWrapperClassName="cydekick-notes user-profile h-100"
        >
          <LoadingContent
            isLoading={notesLoading}
            errorMessage={getApiErrorMessage(notesError)}
            iconType={ERROR_SOLID_ICON}
            errorButtonCallback={refetchNotes}
            showErrorButton={true}
          >
            <div className="member-notes-section">
              <CommentsSection
                comments={notes}
                nonReduxCommentsState={state}
                callback={onPostNoteClick}
                linkToRedux={false}
                changeNewAdded={(flag) => setState({ ...state, newAdded: flag })}
                hideRemediation={true}
                renderCustomTools={renderIsPrivateCheckbox}
                showPrivateTag={canViewPrivateNotes}
                commentType={DEFAULT_COMMENT_SECTION_TYPE}
              />
            </div>
          </LoadingContent>
        </IconCard>
    </Col>
  )
}

export default MemberNotes;
