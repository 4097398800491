import { SET_IDS_TO_DELETE, SET_IDS_TO_RESTORE } from '../actions/types';

const initialState = {
  idsToDelete: [],
  idsToRestore: [],
};

const recycleBinReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_IDS_TO_DELETE:
      return {
        ...state,
        idsToRestore: [],
        idsToDelete: payload || [],
      }
    case SET_IDS_TO_RESTORE:
      return {
        ...state,
        idsToDelete: [],
        idsToRestore: payload || [],
      }
    default:
      return state;
  }

};

export default recycleBinReducer;
