import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { triggerTopbarAction } from '../../../actions/navigation';
import { setAlert } from '../../../actions/alerts';
import { trackEvent } from '../../../utils/gaTracker';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import companyIdSelector from '../../../selectors/companyIdSelector';

const Action = (props) => {
  //  Parsing props
  const { action } = props;
  const {
    container,
    icon,
    cta,
    actionType,
    requiresCompany,
  } = action;
  const { className: containerClassName, color } = container;
  const { className: iconClassName } = icon;

  //  Memoized dispatch
  const { dispatch } = useMemoizedDispatch();

  //  Watching redux store
  const actionLoading = useSelector((state) => state.navigation.action.loading);
  const type = useSelector((state) => state.navigation.action.type);
  const company_id = useSelector((state) => companyIdSelector(state));

  //  Function to dispatch an action from 
  const triggerAction = () => {
    if (requiresCompany && !company_id) { dispatch(setAlert('Select a company', 'danger')); return; }
    //  TODO: change the params for event tracker when the app supports more than one action
    trackEvent('download-pdf-cyso-trust-builder', 'cyso-trust-builder', 'download-pdf-cyso-trust-builder');
    //  TODO: find a way to get params where actions triggered from topbar requires this
    const params = null;
    dispatch(triggerTopbarAction(actionType, params));
  };

  //  Function to render cta
  const getCTA = () => {
    if (type === actionType && actionLoading) { return 'Loading...'; }
    return cta;
  }

  //  Rendering
  return (
    <Button
      className={containerClassName}
      color={color}
      onClick={() => triggerAction()}
      disabled={type === actionType && actionLoading}
    >
      <i className={iconClassName} />
      {getCTA()}
    </Button>
  );
};

export default Action;
