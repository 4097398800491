import moment from "moment-timezone";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from 'react-redux';
import { timeUnits } from "../constants/tendencyChart";
import { getRangeByFilterType } from "../utils/timeChartHelpers";

const useTimeFilters = (hasLocalTimeFilters = false, pointsConfig = {}) => {
  const dateRanges = useMemo(() => getRangeByFilterType(timeUnits.week, new Date(), false, pointsConfig), []);

  //  Watching redux store
  const reduxStartDate = useSelector((state) => state?.dashboards?.start_date);
  const reduxEndDate = useSelector((state) => state?.dashboards?.end_date);
  const reduxTimezone = useSelector((state) => state?.dashboards?.timezone);

  //  Hook state
  const [dateFilters, setDateFilters] = useState({
    start_date: hasLocalTimeFilters ? dateRanges?.start_date : reduxStartDate,
    end_date: hasLocalTimeFilters ? dateRanges?.end_date : reduxEndDate,
  });
  const [timezone, setTimezone] = useState(hasLocalTimeFilters ? moment.tz.guess() : reduxTimezone);
  const [filter_type, setFilterType] = useState(timeUnits.week);

  useEffect(() => {
    //  Verifying if state should change
    if (hasLocalTimeFilters) { return; }
    if (moment(reduxStartDate).valueOf() === moment(dateFilters?.start_date).valueOf()
      && moment(reduxEndDate).valueOf() === moment(dateFilters?.end_date).valueOf()) {
      return;
    }

    //  Changing date filters if needed
    let newDateFilters = { ...dateFilters };
    if (moment(reduxStartDate).valueOf() !== moment(dateFilters?.start_date).valueOf()) {
      newDateFilters = { ...newDateFilters, start_date: reduxStartDate };
    }

    if (moment(reduxEndDate).valueOf() !== moment(dateFilters?.end_date).valueOf()) {
      newDateFilters = { ...newDateFilters, end_date: reduxEndDate };
    }
    setDateFilters(newDateFilters);
  }, [reduxStartDate, reduxEndDate, hasLocalTimeFilters])

  //  Function to mutate state
  const mutateTimeFilters = (filterType, start_date, end_date) => {
    setFilterType(filterType);
    if (!hasLocalTimeFilters) { return; }
    setDateFilters({ start_date, end_date });
  };

  //  Returning assets
  return {
    timeFilters: {
      start_date: moment(dateFilters?.start_date).startOf(filter_type === timeUnits.week ? 'week' : 'month'),
      end_date: moment(dateFilters?.end_date).endOf(filter_type === timeUnits.week ? 'week' : 'month'),
      time: timezone,
      filter_type,
    },
    mutateTimeFilters,
  };
};

export default useTimeFilters;
