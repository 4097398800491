import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { validateEmail } from "../../../utils/validations";
import { renderInput } from "../../../utils/renderFormFields";
import { clearResetPassword, sendResetPasswordToken } from '../../../actions/auth';


 const SendResetPasswordTokenForm= (props) => {
    const {
        handleSubmit,
        sendResetPasswordToken,
        emailSent,
        clearResetPassword
    } = props;

    const [buttonLabel, setButtonLabel] = useState('Reset');
    const [feedbackText, setFeedbackText] = useState('Enter your email to receive instructions on how to reset your password.');

    const onFormSubmit = (formValues) => {
        clearResetPassword();
        sendResetPasswordToken(formValues.email);
        setButtonLabel('Processing...');
    }

    useEffect(() => {
        if(emailSent){
            setFeedbackText('You should receive a link in a few moments. Please open that link to reset your password.')
        }else {
            setFeedbackText('Enter your email to receive instructions on how to reset your password.')
        }
        setButtonLabel('Reset');
    }, [emailSent, setButtonLabel,setFeedbackText]);

    return (
        <div className="reset-pasword-token-form">
            <div className="feedback">
                {feedbackText}
            </div> 
            <form className="form-container form-horizontal" onSubmit={handleSubmit(onFormSubmit)} >
                
                <div style={{marginBottom:"27px"}} >
                    { emailSent ? (
                        <Link
                            className={`btn btn-light btn-block btn-reset text-dark font-weight-bold`}
                            onClick={() => clearResetPassword()}
                            to="/login"
                        >
                            Back to login
                        </Link>
                    ):(
                        <React.Fragment>
                            <Field
                                component={renderInput}
                                name="email" 
                                label="Email" 
                                className="form-control"
                                placeholder="your email"
                                type="email"
                            />
                            <button
                                className={`btn btn-light btn-block btn-reset text-dark font-weight-bold`}
                                type="submit"
                            >
                                {buttonLabel}
                            </button>
                            <div className="text-center return" style={{marginBottom:"16px"}} tabIndex="-1">
                                <p>
                                    Or return to {' '}
                                    <Link 
                                        className="underline-link" 
                                        to="/login"
                                        onClick={()=> clearResetPassword()}
                                    >
                                        Login
                                    </Link> 
                                </p>
                            </div>
                        </React.Fragment>
                    )}
                    
                </div>
            </form>
        </div>
    );
}

const validate = (formValues) => {
    const errors = {};

    const { 
        email
    } = formValues;

    errors.email = validateEmail(email);

    return errors;
}

const mapStatetoProps = state => {
    return { emailSent: state.auth.resetPassword.emailSent };
}

const mapDispatchToProps = {
    sendResetPasswordToken,
    clearResetPassword
};

const wrappedComponent = reduxForm({
    form: 'sendResetPasswordTokenForm',
    validate
})(SendResetPasswordTokenForm);

export default connect(mapStatetoProps, mapDispatchToProps)(wrappedComponent);

