import React from 'react';
import { Button } from 'reactstrap';
import { getNavigationIndex } from '../../../utils/helpers';

const NavigationButtonsByIndex = (props) => {
    const {
        currentIndex,
        total,
        selectCallback
    } = props;

    const onDirectionClick = (moveForward = true) => {
        if(!selectCallback && typeof(selectCallback) !==  'function') return;
        const nextIndex = getNavigationIndex(currentIndex, total, moveForward);
        selectCallback(nextIndex)
    }

    const renderButton = (label, moveForward) => {
        return  (
            <Button
                color="light-gray"
                className="btn-small ml-2"
                outline
                onClick={() => onDirectionClick(moveForward)}
            >
                {label}
            </Button>
        )
    }

    return (
        <div className="direction-navigation-buttons-container float-right">
            {renderButton('Previous', false)}
            {renderButton('Next', true)}
        </div>
    );
};

export default NavigationButtonsByIndex;
