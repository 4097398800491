import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import CallbackSelectField from '../layout/fields/SelectFieldCallback';
import { Can } from '../../contexts/AbilityContext';
import Avatar from '../layout/avatars/Avatar';
import CRITICALITY from '../../constants/criticality';
import useUpdateIssue from '../../hooks/useUpdateIssue';
import { MODIFY_ACTION, PRIVILEGE_SOURCES, READ_ACTION } from '../../constants/privileges';
import useCanAbility from '../../hooks/useCanAbility';
import IssueTags from './IssueTags';
import { issueTypes } from '../../constants/issues';
import useUserType from '../../hooks/useUserType';
import { getIssueStatusValueByUserType } from '../../utils/issuesHelpers';
import useManualActions from '../../hooks/useManualActions';
import { getObsidianOutcome, getObsidianState } from '../../utils/partnerActionHelpers';
import PartnerActionsModal from '../issue-partners/actions/PartnerActionsModal';
import partnerActionsSelector from '../../selectors/partnerActionsSelector';
import { obsidianStates } from '../../utils/partnerHelpers';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import { successActionCreator } from '../../actions/action-creators/success';
import { ADD_COMMENT_REQUEST } from '../../actions/types';
import { v4 as uuidv4 } from 'uuid';
import { UserAPI } from '../../api/services/UserAPI';
import companyIdSelector from '../../selectors/companyIdSelector';
import useCustomQuery from '../../hooks/useCustomQuery';
import { setAlert } from '../../actions/alerts';

const IssueStatusDetails = (props) => {
  //  Parsing props
  const {
    options,
    selectStatusRef,
    formValues,
    issue,
    issueHasOpenSubtasks,
    isSolvedIssue,
    criticalityClassName,
    setCriticalityClassname,
    undoSelectionStatus,
    handelOpenSubtasksError,
    setIsResolvingIssue,
    setShouldReload,
    issueType = issueTypes.VULNERABILITIES,
    updateComment
  } = props;

  //  Component hooks
  const { updateIssueOnDB } = useUpdateIssue({});
  const { updateIssueOnDB: updateParentIssueOnDb } = useUpdateIssue({});
  const ability = useCanAbility();
  const userType = useUserType();
  const { callPartnerAction } = useManualActions({ issue });
  const { dispatch } = useMemoizedDispatch();

  //  Component state
  const [statuses, setStatuses] = useState([]);
  const [criticalities, setCriticalities] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [solutions, setSolutions] = useState([]);
  const [issueHasClosedParent, setIssueHasClosedParent] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const selectedCompany = useSelector((state) => companyIdSelector(state));

  // Getting needed info from redux store
  const isMemberView = useSelector(state => state.impersonation.isMemberView);
  const partnerActions = useSelector(state => partnerActionsSelector(state, issue?.partner?.id));

  //  Watching issue parents changes to change has closed state
  useEffect(() => {
    const closedParent = !_.isEmpty(issue.parent) && issue.parent.status === 4;
    setIssueHasClosedParent(closedParent);
  }, [issue.parent]);

  //  Function to render user option
  const renderUserOption = (user, profileImage = null) => {
    const { full_name } = user;
    return (
      <div className="d-inline-flex align-items-center">
        <Avatar user={user} profileImage={profileImage} customClassName="user-option" />
        {full_name}
      </div>
    );
  }

  const { data: assigneesResponse, error: assigneesError, } = useCustomQuery(
    [UserAPI.keys.getIssuesAssignees, { selectedCompany }],
    ({ queryKey }) => UserAPI.handlers.getIssuesAssignees(queryKey[1]),
  );

  //  Watching options changes
  useEffect(() => {
    //  Getting solutions options
    const solutionsOptions = options.solutions.map((solution) => {
      return { label: solution.abbreviation, value: solution.id }
    });
    //  Getting statuses options
    const statusesOptions = _.filter(options.statuses, ['hidden', false]).map(({ label, id }) => {
      return { label, value: id }
    });
    //  Getting criticalities options
    const criticalitiesOptions = _.filter(options.criticalities, ['hidden', false]).map(({ label, id }) => {
      return { label, value: id }
    });
    setSolutions([{ options: solutionsOptions }]);
    setStatuses([{ options: statusesOptions }]);
    setCriticalities([{ options: criticalitiesOptions }]);
  }, [options]);

  useEffect(() => {
    if (!assigneesError) return;

    dispatch(setAlert('Error getting issues assignees', 'danger'));
  }, [assigneesError]);

  useEffect(() => {
    if (!assigneesResponse) return;

    const assigneesOptions = assigneesResponse.assignees?.map((element) => {
      return { label: renderUserOption(element), value: element.id };
    });
    setAssignees([{ options: assigneesOptions }]);
  }, [assigneesResponse]);

  //  Function to clean remediation comments
  const getCleanRemediationComments = () => {
    return issue.comments.list.map((comment) => {
      return {
        ...comment,
        is_remediation: false,
      }
    });
  };

  //  Function to handle status callback
  const statusCallback = (value) => {
    if (!value) { return; }
    //  Close status (resolved)
    if (value.value === 4) {
      if (issueHasOpenSubtasks) {
        undoSelectionStatus();
        handelOpenSubtasksError();
      } else {
        setIsResolvingIssue(true);
      }
      if (issue.issue_type === issueTypes.ALERT) setOpenModal(true);
    } else {
      let message = 'Status updated';

      if (issueHasClosedParent) {
        message = 'Status updated and parent re-opened';
        //const cleanComments = getCleanRemediationComments();

        const payload = {
          status: 2,
          //comments: { list: cleanComments }, // FIXME: this was setting child issue comments to parent
        }
        updateParentIssueOnDb(issue.parent.code, payload, null, null)
      }

      const statusPayload = { status: value?.value };
      if (value?.value === 0) { statusPayload.assigned_to = null; }
      if (isSolvedIssue) {
        statusPayload.comments = { list: getCleanRemediationComments() }
      }

      if (issue.issue_type === issueTypes.ALERT)
        callPartnerAction({
          state: getObsidianState(value?.value),
          action: 'change_alert_status'
        });

      updateIssueOnDB(issue.code, statusPayload, null, message);
    }
    setShouldReload(true);
  };

  //  Criticality fallback
  const criticalityCallback = (value) => {
    if (!value) { return; }
    updateIssueOnDB(issue.code, { severity: value?.value }, null, 'Criticality updated');
    setShouldReload(true);
    const criticalityClass = CRITICALITY[value?.value]?.className;
    setCriticalityClassname(criticalityClass);
  };

  //  Assignee callback
  const assigneeCallback = (value) => {
    if (!value) { return; }
    const assigneePayload = { assigned_to: value?.value };
    if (issue.status === 0) { assigneePayload.status = 1; }
    updateIssueOnDB(issue.code, assigneePayload, null, 'Assignee updated');
    setShouldReload(true);
  };

  //  Solution callback
  const solutionCallback = (value) => {
    if (!value) { return; }
    updateIssueOnDB(issue.code, { solution_id: value?.value }, null, 'Solution updated');
  };

  // Component variables
  const canModifyIssues = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.ISSUES.GENERAL);

  // Render Functions

  // Render status field
  const renderStatusField = () => {
    const canReopenIssue = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.ISSUES.REOPEN_STATUS_DROPDOWN)
    const isDisabled = (!canModifyIssues && !isMemberView) || (isSolvedIssue && (!canReopenIssue || isMemberView));
    let selectedStatusOption = formValues?.status;

    if (selectedStatusOption) {
      const selectedStatusValue = getIssueStatusValueByUserType(selectedStatusOption?.value, userType);
      selectedStatusOption.value = selectedStatusValue;
    }

    return (
      <CallbackSelectField
        ref={selectStatusRef}
        name="status"
        label="Status"
        placeholder="Unassigned"
        value={selectedStatusOption}
        options={statuses}
        callback={statusCallback}
        disabled={isDisabled}
      />
    )
  }

  // Render Criticality field
  const renderCriticalityField = () => {
    const canEditCriticality = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.ISSUES.CRITICALITY_DROPDOWN);
    const isDisabled = !canModifyIssues || !canEditCriticality || isMemberView
    return (
      <CallbackSelectField
        name="criticality"
        label="Criticality"
        placeholder="Assign criticality"
        value={formValues.criticality}
        options={criticalities}
        customClassName={criticalityClassName}
        callback={criticalityCallback}
        disabled={isDisabled}
      />
    )
  }

  // Render Assignee field
  const renderAssigneeField = () => {
    const isDisabled = !canModifyIssues && !isMemberView;
    return (
      <CallbackSelectField
        name="assignee"
        label="Assignee"
        placeholder="Cydekicks"
        value={formValues.assignee}
        options={assignees}
        callback={assigneeCallback}
        disabled={isDisabled}
      />
    )
  }

  // Render solution field
  const renderSolutionField = () => {
    const isDisabled = issue?.etl || (!canModifyIssues && !isMemberView);
    return (
      <CallbackSelectField
        name="solution"
        label="Solution"
        placeholder="Pick solution"
        value={formValues.solution}
        options={solutions}
        disabled={isDisabled}
        callback={solutionCallback}
      />
    )
  }

  // Render issue source
  const renderIssueSource = () => {
    return (
      <Can I={READ_ACTION} a={PRIVILEGE_SOURCES.ISSUES.PARTNER_FIELD}>
        <div className="info-group">
          <div className="label-container">
            <label>Source</label>
          </div>
          <div className="info-container info-text text-light-gray">
            {issue.partner?.name || 'Manual'}
          </div>
        </div>
      </Can>
    )
  }

  // Render tags component
  const renderTagsComponent = () => {
    return (
      <IssueTags
        showTitle={false}
        showList={false}
        issueType={issueType}
        placeholder={"Add tags"}
        className="issue-status-details-tags"
      />
    )
  }

  const handleUpdateComment = (params) => {
    dispatch(successActionCreator(ADD_COMMENT_REQUEST));
    const { comment, outcome } = params;
    const commentBody = {
      blocks: [{
        data: {},
        depth: 0,
        entityRanges: [],
        key: uuidv4(),
        text: `${comment}${outcome ? ` - ${getObsidianOutcome[outcome]}` : ''}`,
        type: "unstyled",
        inlineStyleRanges: [],
      }],
      entityMap: {}
    };
    updateComment(commentBody);
  }

  const renderModalActions = () => {
    if (issue.issue_type !== issueTypes.ALERT || !partnerActions) return;

    const { issues } = partnerActions;
    const getModalAction = issues?.find(issue => issue.key === "change_alert_status");
    return (
      <PartnerActionsModal
        action={getModalAction}
        callPartnerAction={callPartnerAction}
        closeModal={() => setOpenModal(false)}
        open={openModal}
        extraParams={{ state: obsidianStates.CLOSED }}
        extraSubmitFunction={(params) => handleUpdateComment(params)}
        showConfirmCheckbox={true}
        isIssue={true}
      />
    );
  }

  //  Rendering
  return (
    <>
      {renderStatusField()}
      {renderCriticalityField()}
      {renderAssigneeField()}
      {renderSolutionField()}
      {renderTagsComponent()}
      {renderIssueSource()}
      {renderModalActions()}
    </>
  );
};

export default IssueStatusDetails;
