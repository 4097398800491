import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { onSubtabSelected } from '../../../actions/navigation';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import companyInfoSelector from '../../../selectors/companyInfoSelector';
import { getSubtabIdentifierByPath } from '../../../utils/helpers';
import SubTab from './SubTab'
import SubTabsDropdown from './SubtabsDropdown';

//  TODO: get tabs properly on redesign feature
const SubTabs = (props) => {
  //  Parsing props
  const { tabs, isDynamic, isDropdown } = props;

  //  Watching redux store
  const selectedSubtab = useSelector((state) => state.navigation.selectedSubtab);
  const freemiumStatus = useSelector((state) => companyInfoSelector(state, 'freemium', false));
  // Component Hooks
  const location = useLocation();
  const { dispatch } = useMemoizedDispatch();

  //  Function to render all tabs
  const renderTabs = () => {
    return tabs?.map((tab) => {
      return (
        <SubTab
          key={`subtab-${tab?.identifier}`}
          tab={tab}
        />
      );
    });
  };

  // Function to render dropdown
  const renderDropdown = () => {
    return (
      <SubTabsDropdown subtabs={tabs} />
    )
  }

  useEffect(() => {
    const subtabIdentifier = getSubtabIdentifierByPath(location?.pathname, freemiumStatus);
    const subtab = tabs.find((element) => element?.identifier === subtabIdentifier) || (tabs[0] || {});
    if (subtab && subtab?.label === selectedSubtab?.label || isDynamic) { return; }
    //case for Recycle-bin
		if (subtab?.searchOnly) {
			return
		}
    dispatch(onSubtabSelected(subtab));
  }, [location])

  //  Rendering
  return (
    <div className="subtabs" role="group">
      {isDropdown ? renderDropdown() : renderTabs()}
    </div>
  );
};

export default SubTabs;
