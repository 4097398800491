import React from 'react';
import ContextMenu from '../../context-menu/ContextMenu';
import ContextMenuOption from '../../context-menu/ContextMenuOption';

const DomainContextMenu = (props) => {
  //  Parsing params
  const {
    parentRef,
    actions = [],
    selected = [],
  } = props;

  //  Function to handle select
  const handleSelect = (element) => { };

  //  Function to render actions
  const renderActions = () => {
    return actions?.map((action) => {
      const { callback, tooltipText } = action;
      return (
        <ContextMenuOption
          label={tooltipText}
          callback={callback}
          className="rounded"
          disabled={selected.length === 0}
        />
      );
    })
  }

  //  Rendering
  return (
    <ContextMenu
      parentRef={parentRef}
      elementType="tr"
      onElementSelectCallback={handleSelect}
      minMenuWidth={100}
      minMenuHeight={60}
    >
      {renderActions()}
    </ContextMenu>
  );
};

export default DomainContextMenu;
