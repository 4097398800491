import React from 'react';
import { Input, Label } from 'reactstrap';
import {ENTER_KEY_NAME} from '../../constants/keyNames';

const CustomCheckBox = (props) => {
    const {
      isSelected,
      targetId = "custom-checkbox-id",
      className = "",
      callback = () => {},
      label,
      isDisabled,
      key = null
    } = props;
    const onClick = (event) => {
        event.stopPropagation()
        event.preventDefault()
        callback(isSelected);
    }
    return (
        <div
            key={key}
            className={`custom-control custom-checkbox pointer d-inline-block ${className}`}
            onClick={onClick}
            tabIndex="0"
            onKeyPress={(e) => e?.key === ENTER_KEY_NAME ? onClick(e) : null }
            aria-label="checkbox"
        >
            <Input
                type="checkbox"
                className="custom-control-input"
                id={targetId}
                checked={isSelected}
                readOnly
                disabled={isDisabled}
                aria-hidden="true"
                tabIndex="-1"
            />
            <Label className="custom-control-label mb-0" htmlFor={targetId}>{label || ''}</Label>
        </div>
    )
}

export default CustomCheckBox;
