import cyvatarAxios from '../axios';
import {
  GET_ISSUES_LIST,
  GET_ISSUE,
  GET_ISSUE_MODAL_OPTIONS,
  GET_ROLLUP_DATA,
  GET_COMPLIANCE,
  GET_COMPLIANCE_STATUS,
  FETCH_COMPLIANCE_SCORE,
  GET_CYVALUE_DATA,
  GET_CYVALUE_BY_WEEK,
  GET_ACTIVITY_REPORT,
  GET_TAGS_LIST,
  GET_GRC_ISSUES_LIST,
  GET_GRC_ISSUE,
  GET_GRC_TREND_DATA,
  GET_ROI,
  GET_VULNERABILITY_TREND,
  GET_TOP_FIVE_ISSUES,
  GET_TOP_FIVE_ISSUES_OVERVIEW,
  GET_ISSUE_SOURCES,
  GET_ARCHIVED_ISSUES,
  GET_ARCHIVED_TASKS,
  GET_DEVICES_LIST,
} from '../ApiKeys';

const getIssuesList = (issuesConfig) => {
  return cyvatarAxios.get('/api/workbench/issue', { params: issuesConfig });
};

const getIssue = (code) => {
  return cyvatarAxios.get(`/api/workbench/issue/${code}`);
};

const createIssue = async (formValues) => {
  const body = JSON.stringify(formValues);
  const response = await cyvatarAxios.post('/api/workbench/issue', body);
  return response?.data;
};

const updateIssue = async ({ code, formValues }) => {
  const body = JSON.stringify(formValues);
  const response = await cyvatarAxios.put(`/api/workbench/issue/${code}`, body);
  return response?.data;
};

// params: issue_ids, updates: { [field_name]: value }, remediation_note
const updateIssuesBulk = async (params) => {
  const body = JSON.stringify(params);
  const response = await cyvatarAxios.patch(`/api/workbench/issue/bulk`, body);
  const { data } = response;
  return data;
};

const addRemediationPlanNote = async ({ issue_id, formValues }) => {
  const body = JSON.stringify(formValues);
  const response = await cyvatarAxios.put(`/api/workbench/issue/${issue_id}/remedetiation_plan_note`, body);
  return response?.data;
};

const getIssueModalOptions = (companyId) => {
  return cyvatarAxios.get(`api/workbench/issue/library/${companyId || ''}`);
};

const getRollupData = async ({ company_id, params }, allowAllCompanies = false) => {
  if (!company_id && !allowAllCompanies) { return null; }
  const apiResponse = await cyvatarAxios.get(`api/workbench/issue/totals/${company_id || ''}`, { params });
  const { data } = apiResponse;
  return data;
};

//Adds a tag for issue
//params: issue_id, title, company_id (only required for cydekicks)
const addIssueTag = async ({ issue_id, title, company_id }) => {
  const response = await cyvatarAxios.patch(`api/workbench/issue/${issue_id}/tags?title=${title}${company_id ? `&company_id=${company_id}` : ''}`);
  return response?.data;
};

//Deletes a tag for issue
//params: issue_id, global_tag_id, company_id (only required for cydekicks)
const removeIssueTag = async ({ issue_id, global_tag_id, company_id }) => {
  const response = await cyvatarAxios.delete(`api/workbench/issue/${issue_id}/tags/${global_tag_id}${company_id ? `?company_id=${company_id}` : ''}`);
  return response?.data;
};

// Adds several tags for several issues
// params: issue_ids: (array of ids), titles: (array of strings)
const addIssueTagBulk = async ({ issue_ids, titles }) => {
  const body = JSON.stringify({ issue_ids, titles });
  const response = await cyvatarAxios.patch(`api/workbench/issue/tags/add`, body);
  const { data } = response;
  return data;
};

// Removes several tags for several issues
// params: issue_ids: (array of ids), tag_ids: (array of global_tag_id)
const removeIssueTagBulk = async (params) => {
  const body = JSON.stringify(params);
  const response = await cyvatarAxios.delete(`api/workbench/issue/tags/remove`, { data: body });
  const { data } = response;
  return data;
};

//  Params: { issue_ids : [] }
const deleteIssues = async (params) => {
  const body = JSON.stringify(params);
  const response = await cyvatarAxios.delete('/api/workbench/issue/issues_delete', { data: body });
  const { data } = response;
  return data;
};

const getROI = async (params) => {
  const response = await cyvatarAxios.get("/api/workbench/issue/calculate/roi", { params });
  const { data } = response;
  return data
}

const getTopFiveIssues = async (params) => {
  const response = await cyvatarAxios.get("/api/workbench/issue/calculate/top_five", { params });
  const { data } = response;
  return data
}

const getTopFiveIssuesOverview = async (params) => {
  const response = await cyvatarAxios.get("/api/workbench/issue/calculate/top_five/overview", { params });
  const { data } = response;
  return data
}

const getVulnerabilityTrend = async (params) => {
  const apiResponse = await cyvatarAxios.get(`/api/workbench/issue/issues_trend/`, { params });
  const { data } = apiResponse;
  return data
};

const getIssueSources = async (params) => {
  const apiResponse = await cyvatarAxios.get('/api/workbench/issue/source_types/', { params });
  const { data } = apiResponse;
  return data
}

const getArchivedIssues = async (params) => {
  const apiResponse = await cyvatarAxios.get('/api/workbench/issue/archived_list', { params });
  const { data } = apiResponse;
  return data;
};

const restoreIssues = async (ids = []) => {
  const apiResponse = await cyvatarAxios.post('/api/workbench/issue/restore_issues', { ids });
  const { data } = apiResponse;
  return data;
};

const hardDeleteIssues = async (params) => {
  const apiResponse = await cyvatarAxios.delete('/api/workbench/issue/issues_hard_delete', { data: params });
  const { data } = apiResponse;
  return data;
};

//  Array of queries to invalidate on issues changes
const issuesChangesInvalidators = [
  GET_ISSUES_LIST,
  GET_ISSUE,
  GET_ISSUE_MODAL_OPTIONS,
  GET_ROLLUP_DATA,
  GET_COMPLIANCE,
  GET_COMPLIANCE_STATUS,
  FETCH_COMPLIANCE_SCORE,
  GET_CYVALUE_DATA,
  GET_CYVALUE_BY_WEEK,
  GET_ACTIVITY_REPORT,
  GET_TAGS_LIST,
  GET_ROI,
  GET_TOP_FIVE_ISSUES,
  GET_TOP_FIVE_ISSUES_OVERVIEW,
  GET_VULNERABILITY_TREND,
  GET_DEVICES_LIST,
]

const grcIssuesChangesInvalidators = [
  GET_GRC_ISSUES_LIST,
  GET_GRC_ISSUE,
]

const sharedIssuesChangesInvalidators = [
  ...issuesChangesInvalidators,
  ...grcIssuesChangesInvalidators,
]

export const IssueAPI = {
  handlers: {
    getIssuesList,
    getIssue,
    createIssue,
    updateIssue,
    updateIssuesBulk,
    addRemediationPlanNote,
    getIssueModalOptions,
    getRollupData,
    addIssueTag,
    removeIssueTag,
    addIssueTagBulk,
    removeIssueTagBulk,
    deleteIssues,
    getROI,
    getTopFiveIssues,
    getTopFiveIssuesOverview,
    getVulnerabilityTrend,
    getIssueSources,
    getArchivedIssues,
    restoreIssues,
    hardDeleteIssues,
  },
  keys: {
    getIssuesList: GET_ISSUES_LIST,
    getIssue: GET_ISSUE,
    getIssueModalOptions: GET_ISSUE_MODAL_OPTIONS,
    getRollupData: GET_ROLLUP_DATA,
    getROI: GET_ROI,
    getTopFiveIssues: GET_TOP_FIVE_ISSUES,
    getTopFiveIssuesOverview: GET_TOP_FIVE_ISSUES_OVERVIEW,
    getVulnerabilityTrend: GET_VULNERABILITY_TREND,
    getIssueSources: GET_ISSUE_SOURCES,
    getArchivedIssues: GET_ARCHIVED_ISSUES,
  },
  invalidators: {
    createIssue: issuesChangesInvalidators,
    updateIssue: issuesChangesInvalidators,
    updateIssuesBulk: [...sharedIssuesChangesInvalidators, GET_GRC_TREND_DATA],
    addRemediationPlanNote: issuesChangesInvalidators,
    addIssueTag: sharedIssuesChangesInvalidators,
    removeIssueTag: sharedIssuesChangesInvalidators,
    addIssueTagBulk: sharedIssuesChangesInvalidators,
    removeIssueTagBulk: sharedIssuesChangesInvalidators,
    deleteIssues: [...sharedIssuesChangesInvalidators, GET_GRC_TREND_DATA, GET_ARCHIVED_ISSUES, GET_ARCHIVED_TASKS],
    restoreIssues: [...sharedIssuesChangesInvalidators, GET_GRC_TREND_DATA, GET_ARCHIVED_ISSUES],
    hardDeleteIssues: [GET_ARCHIVED_ISSUES, GET_ARCHIVED_TASKS],
  }
};
