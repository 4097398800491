import { getSafeArray } from './helpers';

export const getDeviceEntityIPAddresses = (entity) => {
  const interfaces = getSafeArray(entity?.device_entity?.network_interface);
  return interfaces.reduce((addresses, element, index) => {
    const { ipv4, ipv6 } = element;
      const delimeter = index === (interfaces?.length - 1) ? '' : ','
      return `${addresses}${ipv4 ? `${ipv4}${delimeter} ` : (ipv6 ? `${ipv6}${delimeter} ` : '')}`;
  }, '');
};

export const getDeviceEntityMACAddress = (entity) => {
  const interfaces = getSafeArray(entity?.device_entity?.network_interface);
  return interfaces.reduce((addresses, element, index) => {
    const { mac_address } = element;
      const delimeter = index === (interfaces?.length - 1) ? '' : ','
      return `${addresses}${mac_address? `${mac_address}${delimeter} ` :  ''}`;
  }, '');
};
