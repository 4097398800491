import React from 'react';
import { Card, CardBody } from 'reactstrap';
import ErrorMessage from '../../components/layout/ErrorMessage'
import CustomSpinner from '../../components/layout/CustomSpinner';
import DeviceDetails from './DeviceDetails';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import DevicesTagsModal from '../tags/DevicesTagsModal';
import DeleteDevicesModal from '../layout/DeleteDevicesModal';
import TableDemoPreview from '../dashboard/common/TableDemoPreview';
import devicesListPreviewImage from '../../assets/images/preview-images/devices-list-preview.png'
import MergeDevicesModal from '../layout/MergeDevicesModal';
import DevicesBulkActions from './DevicesBulkActions';
import { clearDevicesFilters, setDevicesFilter, } from '../../actions/devices';
import ActiveFiltersList from '../layout/filters/ActiveFiltersList';
import CustomTable from '../CustomTable/CustomTable';
import { devicesTableTemplate } from '../../constants/tableTemplates';
import { mapObjSortToArray, mutateSort } from '../../utils/helpers';
import DevicesToolbar from './DevicesToolbar';
import useDeviceList from '../../hooks/useDeviceList';
import { devicesTableStickyDivsIds } from '../../constants/common';

const DevicesList = (props) => {
    const { scrollableRef, partnerId, partnerName } = props;
    //  Parsing props
    const {
        devicesConfig,
        setDevicesConfig,
        fetchDevicesList,
        setSelectedDevice,
        setTagsDevice,
        setShowDeviceTagsModal,
        onSearchCallback,
        setShowDeviceDetails,
        showDeviceDetails,
        tagsDevice,
        showDeviceTagsModal,
        deviceRowPrefix,
        selectedDevicesIds,
        setSelectedDevicesIds,
        refs,
        divTopPositions,
        activeFilters,
        devicesCount,
        searchTerm,
        currentPage,
        pageCount,
        tableRef,
        freemiumStatus,
        showDemoData,
        devices,
        isLoading,
        deviceListLoading,
        allSelected,
        excluded,
        handleSelectedEvent,
        deviceListError,
        showDeleteDevicesModal,
        showMergeDevicesModal,
        selectedDevice,
        errorMessage,
        hasConnection
    } = useDeviceList(props);

    const { dispatch } = useMemoizedDispatch();

    const changeSort = (key, value) => {
        const newSort = mutateSort(key, value, devicesConfig?.sort);
        setDevicesConfig({ ...devicesConfig, sort: newSort });
    };

    const tryAgainButton = (
        <button className="btn btn-light" onClick={() => fetchDevicesList()}>
            Try again
        </button>
    );

    const onPageChange = ({ selected: selectedPage }) => {
        fetchDevicesList(selectedPage);
    }

    const openDeviceDetail = (device) => {
        setSelectedDevice(device);
        toggleDeviceDetails();
    }

    const openDeviceTagsModal = (device) => {
        setTagsDevice(device);
        setShowDeviceTagsModal(true);
    }

    const toggleDeviceDetails = () => {
        setShowDeviceDetails(!showDeviceDetails);
    }

    const renderDeviceTagsModal = () => {
        return (
            <DevicesTagsModal
                device={tagsDevice}
                showModal={showDeviceTagsModal}
                toggleModal={() => setShowDeviceTagsModal(false)}
            />
        )
    }

    const onDeviceRowRightClick = (elementId) => {
        if (elementId?.includes(deviceRowPrefix)) {
            const id = parseInt(elementId?.replace(deviceRowPrefix, ''));
            const isSelected = selectedDevicesIds?.includes(id);
            if (!isSelected) {
                setSelectedDevicesIds([id])
            }
        }
    }

    const renderActiveFiltersList = () => {
        // TODO: Add flag to determine if should show active filters
        const stickyDivIndex = 0;
        if (partnerId) return null;
        const filterLabel = `Listing ${partnerName ? `${partnerName} ` : ''}devices`
        return (
            <div
                ref={refs[stickyDivIndex]}
                className="sticky-element bg-dark"
                style={{ top: divTopPositions[stickyDivIndex] }}
            >
                <ActiveFiltersList
                    title={activeFilters?.length > 0 ? `${filterLabel} filtered by:` : filterLabel}
                    list={activeFilters}
                    total={{ number: devicesCount, label: `Device${devicesCount !== 1 ? 's' : ''}` }}
                    setFilter={(...params) => dispatch(setDevicesFilter(...params))}
                    clearAllFilters={() => dispatch(clearDevicesFilters())}
                />
            </div>
        )
    }

    const renderDevicesToolbar = () => {
        return (
            <DevicesToolbar
                refs={refs}
                divTopPositions={divTopPositions}
                searchTer={searchTerm}
                onSearchCallback={onSearchCallback}
                currentPage={currentPage}
                pageCount={pageCount}
                onPageChange={onPageChange}
            />
        );
    }

    const renderDevicesBulkActions = () => {
        const stickyDivIndex = 2;
        return (
            <DevicesBulkActions
                parentRef={tableRef}
                scrollableRef={scrollableRef}
                selectedDevices={selectedDevicesIds}
                onRightClick={onDeviceRowRightClick}
                bulkMenuId={devicesTableStickyDivsIds[stickyDivIndex]}
                bulkMenuRef={refs[stickyDivIndex]}
                bulkMenuStyle={{ top: divTopPositions[stickyDivIndex] }}
            />
        )
    };

    //  Function to render con table
    const renderTable = () => {
        //  Rendering demo date state
        if (freemiumStatus !== 0 && showDemoData && !hasConnection) {
            return (
                <TableDemoPreview
                    image={devicesListPreviewImage}
                    imageAltText="Devices List Preview"
                    trackPageName="devices-list-preview"
                />
            );
        }

        return (
            <div className="custom-table-container devices-table-container" ref={tableRef}>
                <CustomTable
                    tableClassName="custom-table pointer table-hover custom-sticky-table"
                    headerClassName="custom-table-header"
                    rowClassName="custom-table-row"
                    columnsTemplate={devicesTableTemplate}
                    data={devices || []}
                    isScrollable={true}
                    changeSort={changeSort}
                    sort={mapObjSortToArray(devicesConfig?.sort) || []}
                    loading={isLoading || deviceListLoading}
                    emptyConfig={{
                        message: `Devices will appear here`,
                        messageClassName: 'empty-message',
                        displayTable: true
                    }}
                    allSelected={allSelected}
                    selected={selectedDevicesIds}
                    excluded={excluded}
                    handleSelectedEvent={handleSelectedEvent}
                    error={deviceListError}
                    allowMultipleSort={true}
                    clickableRow={true}
                    onRowClicked={openDeviceDetail}
                    handleCellAction={(device) => openDeviceTagsModal(device)}
                />
            </div>
        );
    };

    return (
        <div className="devices-list align-self-start w-100" style={{ marginBottom: "38px" }} >
            {renderDeviceTagsModal()}
            {showDeleteDevicesModal && <DeleteDevicesModal />}
            {showMergeDevicesModal && <MergeDevicesModal />}
            {showDeviceDetails && selectedDevice &&
                <DeviceDetails
                    showModal={showDeviceDetails}
                    toggleModal={toggleDeviceDetails}
                    selectedDevice={selectedDevice}
                />
            }

            {renderActiveFiltersList()}

            <Card className="h-100">
                <CardBody className="pt-0">
                    {errorMessage ? (
                        <ErrorMessage
                            text={errorMessage}
                            button={tryAgainButton}
                            customStyle={{ paddingTop: "5%" }}
                        />
                    ) : (
                        <React.Fragment>
                            {renderDevicesToolbar()}
                            {renderDevicesBulkActions()}
                            {isLoading || deviceListLoading ? (
                                <div>
                                    <CustomSpinner customStyle={{ minHeight: "303px" }} />
                                </div>
                            ) : renderTable()}
                        </React.Fragment>
                    )}
                </CardBody>
            </Card>
        </div>
    )
}


export default DevicesList;
