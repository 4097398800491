import React, { useEffect, useRef } from 'react';
import { Row, Modal } from "reactstrap";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { saveAssessmentComment, setAssessmentComment } from '../../actions/answers';
import { showCommentButtonAction } from "../../actions/layout";
import {
    toggleRegisterModal,
    changeRegisterModal,
    toggleAssessmentCommentModal
} from '../../actions/layout';
import { trackEvent } from "../../utils/gaTracker";
import amplitude from 'amplitude-js';

const AssessmentComment = (props) => {
    const {
        isAuthenticated,
        history,
        handleSubmit,
        showModal,
        //disableButton,
        toggleRegisterModal,
        changeRegisterModal,
        toggleAssessmentCommentModal,
        saveAssessmentComment,
        setAssessmentComment,
        assessmentExecutionId,
        assessmentExecutionStatus,
        showCommentButtonAction
    } = props;

    const titleModalRef = useRef(null);

    useEffect(() => {
        if (showModal) {
            document.body.classList.remove("no_padding");
        } else {
            document.body.classList.add("no_padding");
        }
        // setTimeout(() => { if(titleModalRef && titleModalRef.current ) titleModalRef.current.focus() }, 1)
    }, [showModal]);


    const togModal = () => {
        showCommentButtonAction(true);
        toggleAssessmentCommentModal(!showModal);
        trackEvent("close_comment", "assessment", "close_comment");
    }

    // handleValidSubmit
    const onFormSubmit = (formValues) => {
        if (isAuthenticated) {
            const comment = {
                assessmentExecutionId,
                comment: formValues.assessmentComment,
            };
            saveAssessmentComment(comment, assessmentExecutionStatus, history);
            showCommentButtonAction(false);
        } else {
            togModal();
            setAssessmentComment(formValues.assessmentComment)
            const buttonText = "UNLOCK MY CYVATAR SCORE";
            const redirectPath = "/dashboard";
            const title = "You're done!  Now register so you can get your score and recommended roadmap from Cyvatar!";
            const type = 'done';
            changeRegisterModal({ title, buttonText, redirectPath, type })
            toggleRegisterModal(true);
            showCommentButtonAction(false);
            amplitude.getInstance().logEvent('Cyber Checkup View - Finish Assessment');
        }
    }

    return (
        <Modal
            isOpen={showModal}
            toggle={() => { togModal() }}
            centered={true}
            size="xl"
            className="assessment-comment"
            autoFocus="false"
        >
            <div>
                <div className="modal-header pb-0">
                    <button
                        type="button"
                        onClick={() => { togModal() }}
                        className="close text-white pt-3 pr-0"
                        style={{ fontSize: "30px" }}
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="content">
                    <Row className=" pt-0 px-3 pb-4">
                        <div className="title" ref={titleModalRef} tabIndex="0">Anything else we should know?</div>
                        <div className="hints">What brought you to us? Did you have a breach? Do you have a compliance issue?  Are you at risk because you don't have security professionals on staff?</div>
                        <form className="form-horizontal" onSubmit={handleSubmit(onFormSubmit)} >
                            <Field
                                component={renderTextArea}
                                name="assessmentComment"
                                placeholder="Type your comments here!"
                            />
                            <button
                                className="btn btn-light text-dark d-flex align-items-center float-right mb-4 ml-2 "
                                style={{ fontWeight: "600", marginRight: "9px" }}
                                type="submit"
                            >
                                <i className='bx bxs-right-arrow mr-2' style={{ fontSize: "18px" }} ></i>
                                I'm Ready<span className="ml-1 float-right"></span>
                            </button>
                        </form>
                    </Row>
                </div>
            </div>
        </Modal>
    );
}

const renderTextArea = ({ input, name, label, placeholder, type, meta: { error, touched } }) => {
    return (
        <div className="form-group" style={{ marginBottom: "20px" }} >
            <label htmlFor={name} >{label}</label>
            <textarea
                {...input}
                type={type}
                placeholder={placeholder}
                name={name}
                autoComplete="off"
                style={{ marginBottom: "5px" }}
                rows={3}
                className={`form-control ${touched && error ? "is-invalid" : ''}`}
            />
            <div className="invalid-feedback">{error}</div>
        </div>
    );

};


const wrappedComponent = reduxForm({
    form: 'assessementCommentForm',
    enableReinitialize: true
})(AssessmentComment);

const selector = formValueSelector('assessementCommentForm');

const mapStatetoProps = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        showModal: state.layout.showAssessmentComment,
        disableButton: !selector(state, 'assessmentComment') ? true : false,
        assessmentExecutionId: state.answers.assessmentExecutionId,
        initialValues: { assessmentComment: state.answers.assessmentComment },
        assessmentExecutionStatus: state.answers.assessmentExecutionStatus
    };
}

const mapDispatchToProps = {
    toggleRegisterModal,
    changeRegisterModal,
    toggleAssessmentCommentModal,
    saveAssessmentComment,
    setAssessmentComment,
    showCommentButtonAction
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(wrappedComponent));

