import React from 'react';
import API from '../../../api';
import useAttachmentActions from '../../../hooks/useAttachmentActions';
import CompanyAttachmentsActionsModal from './CompanyAttachmentsActionsModal';

const CompanyAttachmentsBulkActions = (props) => {
  //  Parsing props
  const {
    parentRef,
    company_id,
    selected = [],
  } = props;

  //  Initializing API
  const { CompanyAPI } = API;

   //  Component hooks
   const {
    actions = [],
    showDeleteAttachmentModal,
    toggleDeleteAttachmentModal,
  } = useAttachmentActions({
    company_id,
  });

  //  Function to render icon
  const renderAction = (className, text, onClick) => {
    return (
      <div
        className="bulk-menu-icon-container"
        onClick={onClick}
      >
        <div className={`bulk-menu-icon ${className}`} />
        <div className="bulk-menu-text">{text}</div>
      </div>
    );
  };

  //  Function to render actions
  const renderActions = () => {
    return actions?.map((action) => {
      const { iconClassName, callback, text } = action;
      return renderAction(iconClassName, text, callback);
    });
  };

  //  Function to render bulk actions
  const renderBulkActions = () => {
    if (selected.length === 0) { return null; }
    return (
      <div className="attachments-bulk-menu">
        <div className="bulk-menu-label">Bulk actions</div>
        <div className="bulk-menu-icons">
          {renderActions()}
        </div>
      </div>
    );
  };

  //  Rendering
  return (
    <>
      <CompanyAttachmentsActionsModal
        company_id={company_id}
        attachments={selected}
        showDeleteModal={showDeleteAttachmentModal}
        toggleDeleteModal={toggleDeleteAttachmentModal}
      />
      {renderBulkActions()}
    </>
  );
};

export default CompanyAttachmentsBulkActions;
