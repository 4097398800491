import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import GrcIssuesList from '../../components/grc/GrcIssuesList';
import ViewWrapper from '../../components/layout/ViewWrapper';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import { getIssueModalOptions, onShowGRCDetails } from '../../actions/issues';
import companyIdSelector from '../../selectors/companyIdSelector';
import { issueTypes } from '../../constants/issues';
import amplitude from 'amplitude-js';
import useUserType from '../../hooks/useUserType';
import { useLocation } from 'react-router-dom';

const GrcIssueManagerView = () => {

    // Getting needed info from redux store
    const companyId = useSelector((state) => companyIdSelector(state));

    //Component Hooks
    const { dispatch } = useMemoizedDispatch();
    const userType = useUserType();
    const location = useLocation();


    useEffect(() => {
        amplitude.getInstance().logEvent('Task Manager - Issues List');
    }, [])

    useEffect(() => {
        dispatch(getIssueModalOptions(companyId, issueTypes.GRC, userType));
    }, [companyId]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (!queryParams.has('details')) dispatch(onShowGRCDetails(false));
    }, [location.search]);

    const renderGrcIssuesList = () => {
        return (
            <GrcIssuesList
            //   showIssueDetails={showIssueDetails}
            />
        );
    }
    return (
        <ViewWrapper
            layoutType="vertical"
            viewName="Tasks"
            hasImpersonationMode={false}
        >
            {renderGrcIssuesList()}
        </ViewWrapper>
    )
}

export default GrcIssueManagerView;
