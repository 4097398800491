import React, { useState } from 'react';

const CustomizedCheckbox = (props) => {
  //  Parsing props
  const {
    customClassName,
    checked = false,
    onChangeCallback,
  } = props;

  //  Function to get checkbox class name
  const getClassName = () => {
    const baseClassName = customClassName || 'checkbox-container';
    return `${baseClassName}${checked ? ' active bx bx-check' : ''}`
  };

  //  Rendering
  return (
    <div
      className={getClassName()}
      onClick={() => onChangeCallback(!checked)}
    />
  );
};

export default CustomizedCheckbox;
