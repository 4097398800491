import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import BasicModal from '../layout/BasicModal'
import IssueHeader from '../issue-manager/IssueHeader';
import LoadingContent from '../layout/LoadingContent';
import GrcIssueDetailsBody from './grcDetails/GrcIssueDetailsBody';
import ClickableLabel from '../layout/ClickableLabel';
import { onShowGRCDetails, onDeleteModalConfigUpdate, getIssueModalOptions } from '../../actions/issues';
import { setAlert } from '../../actions/alerts';
import { onShowIssueExpandedView } from '../../actions/layout';
import { validateGRCIssue, getValidationError } from '../../utils/joiValidations';
import API from '../../api';
import {
	getIssueCriticalityClassName,
	getGRCCreatePayload,
	getGRCUpdatePayload,
} from '../../utils/issuesHelpers';
import { isValidFunction } from '../../utils/helpers';
import { issueTypes } from '../../constants/issues';
import companyIdSelector from '../../selectors/companyIdSelector';
import companyInfoSelector from '../../selectors/companyInfoSelector';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useURLSearchParams from '../../hooks/useURLSearchParams';
import useCustomQuery from '../../hooks/useCustomQuery';
import useUpsertGRCIssue from '../../hooks/useUpsertGRCIssue';
import useCanAbility from '../../hooks/useCanAbility';
import { MODIFY_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';
import useUserType from '../../hooks/useUserType';
import usePartnerManualActions from '../../hooks/usePartnerManualActions';

const GrcIssuesDetails = (props) => {
	//  Parsing props
	const {
		code,
		parent_issue,
		closeSubTaskModal,
		updateIndex,
		parentIsTask = true,
		showNavigationButtons = true
	} = props;

	//	Initializing API
	const { GrcIssueAPI } = API;

	//	Calling API GET endpoints
	const {
		data: issueData,
		error: issueError,
		isLoading: issueLoading,
	} = useCustomQuery(
		[GrcIssueAPI.keys.getGRCIssue, code],
		({ queryKey }) => GrcIssueAPI.handlers.getGRCIssue(queryKey[1]),
	);

	//	Calling endpoint to upser issue
	const {
		data: upsertData,
		error: upsertError,
		isLoading: upsertLoading,
		upsertIssue,
	} = useUpsertGRCIssue(code);

	const ability = useCanAbility();
	const userType = useUserType();

	//	Watching redux store
	const companyId = useSelector((state) => companyIdSelector(state));
	const companyName = useSelector((state) => companyInfoSelector(state, 'name', false));
	const deleteIssuesSuccess = useSelector((state) => state.grcIssues.deleteModalConfig?.success);
	const criticalitiesSize = useSelector((state) => state?.grcIssues?.modalOptions?.criticalities?.length);
	const grcConfig = useSelector((state) => state?.grcIssues?.modalOptions);
	const isMemberView = useSelector((state) => state?.impersonation?.isMemberView);

	//	Component hooks
	const { dispatch } = useMemoizedDispatch();
	const { removeParamsFromURL, replaceQueryParams } = useURLSearchParams({ paramsEntries: [] });

	//	Component state
	const [expandedMode, setExpandedMode] = useState(true);
	const [issue, setIssue] = useState(issueData);
	const [criticalityClassName, setCriticalityClassName] = useState(getIssueCriticalityClassName(issue?.severity));
	const [validationError, setValidationError] = useState(null);

	//	Component variables
	const isUpdating = useMemo(() => code !== null, [code]);
	const triggeredFromSubtasks = useMemo(() => (!!parent_issue), [parent_issue]);
	const isSubTask = useMemo(() => {
		return (triggeredFromSubtasks || issue?.parent_issue_id);
	}, [triggeredFromSubtasks, issue?.parent_issue_id]);
	const canModifyIssues = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.ISSUES.GENERAL) || isMemberView;

	// Calling partner manual actions
	usePartnerManualActions();
	
	const callCloseSubTaskModal = () => {
		if (isValidFunction(closeSubTaskModal)) closeSubTaskModal();
	}

	//	Function to close modal
	const closeModal = () => {
		//	Closing subtask modal
		if (triggeredFromSubtasks) {
			callCloseSubTaskModal();
			return;
		}

		dispatch(onShowGRCDetails(false));
		const commonParams = ['details', 'selectedIssue', 'task', 'subtask', 'parent'];
		if (parentIsTask) {
			//	Closing normal modal
			removeParamsFromURL(commonParams);
		} else {
			//	Return to parent issue URL
			callCloseSubTaskModal();
			const newParams = `&details&selectedIssue=${issue?.parent?.code}`;
			replaceQueryParams(newParams, commonParams);
		}
	};

	//	Watching issue data responses
	useEffect(() => {
		if (!issueData) { return; }

		//	Getting company id
		let company_id = null;
		if (triggeredFromSubtasks) { company_id = parent_issue?.company_id; };
		if (!triggeredFromSubtasks && isSubTask) { company_id = issueData?.company_id; }
		if (!triggeredFromSubtasks && !isSubTask && isUpdating) { company_id = issueData?.company_id; }
		if (!triggeredFromSubtasks && !isSubTask && !isUpdating) { company_id = companyId; }

		//	Getting parent issue id
		let parent_issue_id = null;
		if (triggeredFromSubtasks) { parent_issue_id = parent_issue?.id; };
		if (!triggeredFromSubtasks && isSubTask) { parent_issue_id = issueData?.parent_issue_id; }
		if (!triggeredFromSubtasks && !isSubTask && isUpdating) { parent_issue_id = issueData?.parent_issue_id; }

		//	Setting original payload
		setIssue({
			...issueData,
			company_id,
			company_name: isUpdating ? issueData.company_name : companyName,
			parent_issue_id,
		});
	}, [issueData, companyId, isSubTask, triggeredFromSubtasks]);

	//	Watching criticalities size changes to request modal config when needed
	useEffect(() => {
		if (criticalitiesSize) { return; }
		dispatch(getIssueModalOptions(null, issueTypes.GRC, userType));
	}, [criticalitiesSize]);

	//	Watching issue criticality changes
	useEffect(() => {
		setCriticalityClassName(getIssueCriticalityClassName(issue?.severity));
	}, [issue?.severity]);

	//	Watching issue upser error
	useEffect(() => {
		if (!upsertError) { return; }
		const action = isUpdating ? 'updating' : 'creating';
		dispatch(setAlert(`Error ${action} task`, 'danger'));
	}, [upsertError]);

	//	Watching upsert response
	useEffect(() => {
		if (!upsertData) { return; }
		const action = isUpdating ? 'updated' : 'created';
		dispatch(setAlert(`Task ${action} successfully`, 'success'));
		if (!isUpdating) { closeModal(); }
	}, [upsertData]);

	//	Watching expanded mode changes
	useEffect(() => {
		dispatch(onShowIssueExpandedView(expandedMode));
	}, [expandedMode, onShowIssueExpandedView]);

	//	Watching issue delete success
	useEffect(() => {
		if (!deleteIssuesSuccess) { return; }
		dispatch(onDeleteModalConfigUpdate(false, [], false, issueTypes.GRC));
		closeModal();
	}, [deleteIssuesSuccess]);

	//	Function to toggle expanded mode
	const toggleExpandedMode = () => {
		setExpandedMode(!expandedMode);
	};

	//	Function to get submit button CTA
	const getButtonCTA = () => {
		if (upsertLoading) { return 'Processing...'; }
		return isUpdating ? 'Update Task' : 'Create Task';
	};

	//	Function to build payload
	const buildPayload = (payload) => {
		if (isUpdating) {
			return getGRCUpdatePayload(payload);
		}
		return getGRCCreatePayload(payload);
	};

	//	Function to handle modal submit
	const handleSubmit = () => {
		//	Preparing payload
		const payload = buildPayload(issue);

		//	Validating payloads
		const validation = validateGRCIssue(payload, isUpdating);
		if (validation?.error) {
			const validationError = getValidationError(validation?.error);
			const errorMessage = validationError?.message;
			setValidationError(validationError)
			dispatch(setAlert(errorMessage, 'danger'));
			return;
		}

		//	Upserting issue
		upsertIssue(payload);
	};

	const clearValidationError = (key) => {
		if (!key || !validationError) return;
		if (validationError?.key == key) {
			setValidationError(null)
		}
	}

	//	Function to link to parent issue
	const linkToParent = () => {
		//	Going back to parent modal
		if (triggeredFromSubtasks) {
			closeModal();
			return;
		}
		if (!issue?.parent) { return; }

		let newParams = `&details&task&selectedIssue=${issue.parent.code}`;
		const paramsToReplace = ['details', 'selectedIssue'];
		if (issue.parent.issue_type !== issueTypes.GRC) {
			newParams = `&details&parent&selectedIssue=${issue.parent.code}`;
			paramsToReplace.push('task');
			callCloseSubTaskModal();
		} else {
			paramsToReplace.push('parent');
		}
		//	Replacing URL
		replaceQueryParams(newParams, paramsToReplace);
	};

	//	Function to render title for create modal
	const renderCreateModalTitle = () => {
		if (isUpdating) { return null; }
		if (isSubTask) {
			return (
				<ClickableLabel
					containerClassName="grc-modal-title-container"
					clickableClassname="grc-title-link"
					normalTextClassName="grc-modal-title"
					normalText="Create subtask for"
					clickableText={parent_issue?.code}
					onClickCallback={closeModal}
				/>
			);
		}
		return 'Create new task';
	};

	//	Function to render modal title
	const renderModalTitle = () => {
		if (!isUpdating) { return null; }
		return (
			<IssueHeader
				issue_id={issue?.id}
				parent={issue?.parent}
				code={issue?.code}
				companyName={issue?.company_name}
				closeModal={closeModal}
				expandedMode={expandedMode}
				setExpandedMode={toggleExpandedMode}
				issue_type={issueTypes.GRC}
				linkToParent={linkToParent}
				overdue={issue?.overdue}
				updateIssue={updateIndex}
				showNavigationButtons={showNavigationButtons}
			/>
		);
	};

	//	Function to render modal footer
	const renderModalFooter = () => {
		const isUpdateDisabled = upsertLoading || !canModifyIssues;
		return (
			<ModalFooter style={{ marginTop: "6px" }}>
				<div className="text-center grc-modal-buttons">
					<Button
						color="link"
						className="text-white font-weight-bold px-2"
						onClick={closeModal}
						style={{ marginRight: "11px" }}
					>
						Cancel
					</Button>
					<Button
						onClick={() => handleSubmit()}
						className={`btn btn-light text-dark font-weight-bold ${isUpdateDisabled ? 'disabled' : ''}`}
						disabled={isUpdateDisabled}
					>
						{getButtonCTA()}
					</Button>
				</div>
			</ModalFooter>
		);
	};

	//  Rendering
	return (
		<BasicModal
			header={renderCreateModalTitle()}
			customHeaderContent={renderModalTitle()}
			headerClassName={`modal-header-status grc-modal-header bg-${criticalityClassName || 'light-gray'}`}
			showModal={true}
			toggleModal={() => closeModal()}
			customClassName={`${expandedMode ? 'issue-expanded-detail dashboard' : 'issue-manager-modal'} issue-create issue`}
			backdrop={!expandedMode}
			wrapClassName={expandedMode ? 'no-scrollable-modal expanded-detail-container' : ''}
			zIndex={expandedMode ? 1002 : 1050}
		>
			<LoadingContent
				isLoading={issueLoading}
				errorMessage={issueError ? `Error getting task ${code}` : null}
				errorStyle={{ minHeight: "225px" }}
				loadingStyle={{ minHeight: "225px" }}
				errorButtonCallback={() => { }}
				showErrorButton={false}
			>
				<GrcIssueDetailsBody
					issue={issue}
					setIssue={setIssue}
					isUpdating={isUpdating}
					expandedMode={expandedMode}
					isSubTask={isSubTask}
					grcConfig={grcConfig}
					validationError={validationError}
					clearValidationError={clearValidationError}
				/>
				{renderModalFooter()}
			</LoadingContent>
		</BasicModal>
	)
}

export default GrcIssuesDetails;
