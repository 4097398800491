import React, { useEffect, useState } from 'react';
import { Row, Col } from "reactstrap";
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import FormCard from '../components/layout/FormCard';
import { Form, Field } from 'react-final-form'
import { setAlert, removeAlert } from "../actions/alerts"
import { validatePassword, validateEmptyTextField } from '../utils/validations';
import { renderInput, renderPasswordField } from '../utils/renderFormFields';
import { logout, register } from '../actions/auth';
import API from '../api';

import logo from "../assets/images/logos/cyvatar-beta-logo.svg";
import loginImage from "../assets/images/isometric-images-2.png";

const MemberRegister = (props) => {
  const {
    setAlert,
    removeAlert,
    logout,
    isAuthenticated,
    loading,
    user,
    history,
    location,
    error,
    register,
  } = props;

  //  Initializing APIs
  const { UserAPI } = API;

  const [activeSessionAlertId, setActiveSessionAlertId] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (!isAuthenticated && !loading) {
      const path = location.pathname.split('/');
      const token_invite = path[path.length - 1];

      UserAPI.handlers.getUserInvite({ token_invite }).catch(error => {
        setAlert(error.response.data.errorMessage, 'important', true);
        history.push('/')
      });
    }
  }, [isAuthenticated, loading]);

  useEffect(() => {
    if (isAuthenticated && !formSubmitted && !activeSessionAlertId) {
      const alertId = uuidv4();
      setAlert(<LogoutAlertComponent />, 'important', false, 5000, '', alertId);
      setActiveSessionAlertId(alertId);
    }
    if (!isAuthenticated && !formSubmitted && activeSessionAlertId) {
      removeAlert(activeSessionAlertId);
    }
  }, [isAuthenticated]);

  if (isAuthenticated && formSubmitted) {
    return <Redirect to='/' />
  }

  const LogoutAlertComponent = () => {
    return (
      <React.Fragment>
        <div>
          You have an active session for {user.email}, please	&nbsp;
          <span className="btn btn-dark" onClick={() => { logout(history, location.pathname) }}> Logout </span>
        </div>
      </React.Fragment>
    );
  };

  const validate = (formValues) => {
    const errors = {};
    const {
      name,
      password,
    } = formValues;

    if (validateEmptyTextField(name)) {
      errors.name = validateEmptyTextField(name);
    }

    if (validatePassword(password)) {
      errors.password = validatePassword(password);
    }

    return errors;
  }

  const setRegisterPayload = (values) => {
    const {
      name,
      password,
    } = values;

    const path = location.pathname.split('/');
    const token_invite = path[path.length - 1];

    const formValues = {
      user: {
        full_name: name.trim(),
        password,
        token_invite,
      },
    };

    setFormSubmitted(true);

    return {
      formValues,
      history,
      location,
    };
  }

  //  Function to handle submit
  const onSubmit = (values) => {
    const builtPayload = setRegisterPayload(values);
    const {
      formValues: valuesToSend,
      history: historyToSend,
      location: locationToSend,
    } = builtPayload;
    register(valuesToSend, historyToSend, locationToSend);
  }

  return (
    <React.Fragment>
      <div className="vh-100 w-100">
        <Row className="justify-content-around align-items-start align-items-md-center form-card-container flex-grow-1 h-md-100 p-4">
          <Col
            className="isometric-images-2-container "
            md={5}
            sm={10}
          >
            <Row
              className="justify-content-center"
            >
              <img
                src={logo}
                alt="cyvatar"
                className="img-fluid"
                style={{ height: "42px", marginBottom: "31px" }}
              />
            </Row>
            <Row className="justify-content-end d-none d-lg-block">
              <img
                src={loginImage} alt=""
                className="img-fluid"
                style={{ maxHeight: "501px" }}
              />
            </Row>
          </Col>
          <Col
            md={12}
            lg={4}
          >
            <Row className="justify-content-center align-items-start align-items-sm-center align-items-lg-start flex-grow-1 h-100">
              <FormCard
                title="Join Cyvatar Team"
              >
                <div>
                  <Form
                    onSubmit={onSubmit}
                    validate={validate}
                    shouldValidate={true}
                    render={({ handleSubmit, submitting, invalid }) => (
                      <form onSubmit={event => {
                        if (invalid) {
                          setAlert('One or more fields have an error. Please check and try again', 'danger');
                        }
                        handleSubmit(event);
                      }}>
                        <Field
                          render={renderInput}
                          name="name"
                          label="Full Name"
                        />

                        <Field
                          render={renderPasswordField}
                          name="password"
                          label="Password"
                        />
                        <div style={{ marginBottom: "15px" }} >
                          <button
                            className={`btn btn-light text-dark btn-block font-weight-bold`}
                            type="submit"
                            disabled={submitting || isAuthenticated}
                          >
                            Register
                          </button>
                        </div>
                      </form>
                    )}
                  />
                </div>
              </FormCard>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

const mapStatetoProps = state => {
  const { isAuthenticated, user, loading } = state.auth;
  return {
    isAuthenticated,
    user,
    loading,
  };
}

export default withRouter(connect(mapStatetoProps, { setAlert, removeAlert, logout, register })(MemberRegister));
