import { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { getNextPageParam } from '../utils/queryHelpers';
import useCustomInfiniteQuery from './useCustomInfiniteQuery';

const useScrollingQuery = (params) => {
  //  Parsing params
  const {
    baseKey, // Api function key
    key, // React query key, usually [apiFunctionKey, params]
    queryFunction, // Api handler
    parseElements, // Function to convert api elements to dropdown options
    isDisabled = false,
    extraConfig = {}, // Object in case we want to override react query config
    initialElement, // Object to append at the beggining of the array
    valueToExtract = 'rows',
    customGetNextPage,
    initialPageParam = 0,
  } = params;

  //  Hook state
  const [elements, setElements] = useState([]);
  const [totalElements, setTotalElements] = useState(0);

  //  Query client
  const queryClient = useQueryClient();

  //  Initializing infinite query hook
  const {
    data,
    error,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useCustomInfiniteQuery(
    key,
    ({ queryKey, pageParam = initialPageParam }) => { return queryFunction({ ...queryKey[1], offset: pageParam }) },
    {
      getNextPageParam: customGetNextPage || ((lastPage, allPages) => getNextPageParam(lastPage, allPages, valueToExtract)),
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: !isDisabled,
      ...extraConfig,
    });

  //  Watching data changes
  useEffect(() => {
    if (!data) { return; }
    const { pages = [] } = data;
    let total = 0;

    const newElements = pages.reduce((list, currentPage) => {
      const { [valueToExtract]: rows = [], count = 0 } = currentPage;
      total = count;
      const parsedRows = parseElements(rows);
      return [...list, ...parsedRows];
    }, initialElement ? [initialElement] : []);

    setTotalElements(total);
    setElements(newElements);
  }, [data]);

  //  Function to clear query data
  const clearQueryData = () => {
    queryClient.invalidateQueries(baseKey);
  };

  //  Hook assets
  return {
    totalElements,
    elements,
    error,
    loading: isLoading || isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    clearQueryData,
  };
};

export default useScrollingQuery;
