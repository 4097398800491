import _ from 'lodash';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Button, ModalFooter } from 'reactstrap';
import { setAlert } from '../../actions/alerts';
import { setConfirmationModal } from '../../actions/layout';
import API from '../../api';
import { alertTypes } from '../../constants/alerts';
import errorMessages from '../../constants/errorMessages';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import BasicModal from '../layout/BasicModal';
import IssuesAssigneeFilter from './issue-filters/IssuesAssigneesFilter';
import useCustomMutation from '../../hooks/useCustomMutation';
import companyIdSelector from '../../selectors/companyIdSelector';
import { onReloadIssues } from '../../actions/issues';
import { issueTypes } from '../../constants/issues';

const IssuesAssigneeModal = (props) => {
    const {
        toggleModal,
        showModal,
        selectedIssues,
        issueType = issueTypes.VULNERABILITIES,
        isDisabled,
    } = props;

    //  Initializing APIs
    const { IssueAPI } = API;

    // Component state
    const [selectedAssignee, setSelectedAssignee] = useState(null);
    const [selectedAssigneeLabel, setSelectedAssigneeLabel] = useState('');


    // Component Hooks
    const { dispatch } = useMemoizedDispatch();

    // Getting needed info from redux store
    const selectedCompany = useSelector((state) => companyIdSelector(state));

    const getIssuesCountLabel = (count) => {
        return `${count} ${issueType === issueTypes.GRC ? 'task' : 'issue'}${parseInt(count) === 1 ? '' : 's'}`
    }

    // Get confirmation message
    const getConfirmationMessage = () => {
        const count = selectedIssues.length;
        return (
            <span>
                {`${getIssuesCountLabel(count)} assigned `}
                {selectedAssigneeLabel && <>{`to `}<span className="font-weight-bolder">{selectedAssigneeLabel}</span></>}
            </span>
        )
    }

    // updates several issues
    const {
        data: updateAssigneeResponse,
        error: updateAssigneeError,
        isLoading: loadingUpdateAssignee,
        mutate: updateAssignee,
    } = useCustomMutation(
        (params) => IssueAPI.handlers.updateIssuesBulk(params),
        IssueAPI.invalidators.updateIssuesBulk,
    );

    // Watch api response
    useEffect(() => {
        if (!updateAssigneeResponse) return;
        dispatch(setConfirmationModal({
            show: true,
            message: getConfirmationMessage()
        }));
        toggleModal(false);
        setSelectedAssignee(null);
        dispatch(onReloadIssues(true, issueType));
    }, [updateAssigneeResponse])

    // Watch error
    useEffect(() => {
        if (!updateAssigneeError) return;
        const errorMessage = updateAssigneeError?.data?.errorMessage || errorMessages.defaultPost;
        dispatch(setAlert(errorMessage, alertTypes.error));
    }, [updateAssigneeError])

    const getTitle = () => {
        const count = selectedIssues?.length || 0
        return `Bulk Assign (${getIssuesCountLabel(count)} selected)`
    }

    const onSelectAssignee = (option) => {
        setSelectedAssignee(option?.value)
        setSelectedAssigneeLabel(option?.label)
    }

    const renderAssigneeDropdown = () => {
        return (
            <div className="mb-4">
                <IssuesAssigneeFilter
                    selectedCompany={selectedCompany}
                    filterValue={selectedAssignee}
                    setFilterCallback={onSelectAssignee}
                    showAllMembersOption={false}
                    wrapperClassName="issues-assignee-bulk-dropdown mx-auto"
                />
            </div>
        )
    }

    const onAssignClick = () => {
        if (_.isNil(selectedAssignee)) {
            dispatch(setAlert("Please select a valid assignee", alertTypes.error));
            return;
        }
        const updates = { assigned_to: selectedAssignee }
        const grc_only = issueType === issueTypes.GRC;
        const params = { issue_ids: selectedIssues, updates, grc_only }
        updateAssignee(params);
    }

    const renderFooter = () => {
        return (
            <ModalFooter>
                <div className="text-center" >
                    <Button
                        className={`btn btn-light text-dark font-weight-bold }`}
                        type="submit"
                        onClick={onAssignClick}
                        disabled={loadingUpdateAssignee || isDisabled}
                    >
                        {!loadingUpdateAssignee ? 'Assign ' : 'Processing...'}
                    </Button>
                </div>
            </ModalFooter>
        )
    }

    return (
        <BasicModal
            header={getTitle()}
            headerClassName={`modal-header-status`}
            showModal={showModal}
            toggleModal={toggleModal}
            customClassName="issues-assignee-modal"
            scrollable={true}
        >
            {renderAssigneeDropdown()}
            {renderFooter()}
        </BasicModal>
    )
}

export default IssuesAssigneeModal
