import React, { useEffect } from 'react'
import { setAlert } from '../../actions/alerts';
import API from '../../api';
import { alertTypes } from '../../constants/alerts';
import errorMessages from '../../constants/errorMessages';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import AddRemoveTagsModal from '../tags/AddRemoveTagsModal'
import useCustomMutation from '../../hooks/useCustomMutation';
import { onReloadIssues } from '../../actions/issues';
import { issueTypes } from '../../constants/issues';

const IssuesAddRemoveTagsModal = (props) => {
    const {
        showModal,
        toggleModal,
        selectedIssues,
        issueType = issueTypes.VULNERABILITIES,
        isDisabled,
    } = props;

    //  Initializing APIs
    const { IssueAPI } = API;

    // Component Hooks
    const { dispatch } = useMemoizedDispatch();

    const getIssuesCountLabel = (count) => {
        return `${count} ${issueType === issueTypes.GRC ? 'task' : 'issue'}${parseInt(count) === 1 ? '' : 's'}`
    }

    //  Add tags to several issues
    const {
        data: addIssueTagBulkResponse,
        error: addIssueTagBulkError,
        isLoading: loadingAddIssueTagBulk,
        mutate: addIssueTagBulk,
    } = useCustomMutation(
        (params) => IssueAPI.handlers.addIssueTagBulk(params),
        IssueAPI.invalidators.addIssueTagBulk
    );

    //  Remove tags from several issues
    const {
        data: removeIssueTagBulkResponse,
        error: removeIssueTagBulkError,
        isLoading: loadingRemoveIssueTagBulk,
        mutate: removeIssueTagBulk,
    } = useCustomMutation(
        (params) => IssueAPI.handlers.removeIssueTagBulk(params),
        IssueAPI.invalidators.removeIssueTagBulk
    );

    // Watch add tag response
    useEffect(() => {
        if (!addIssueTagBulkResponse) return;
        dispatch(setAlert('Tags added', alertTypes.success));
        toggleModal(false);
        dispatch(onReloadIssues(true, issueType));
    }, [addIssueTagBulkResponse])

    // Watch remove tag response
    useEffect(() => {
        if (!removeIssueTagBulkResponse) return;
        dispatch(setAlert('Tags removed', alertTypes.success));
        toggleModal(false);
        dispatch(onReloadIssues(true, issueType));
    }, [removeIssueTagBulkResponse])

    // Watch add or remove error response
    useEffect(() => {
        if (!addIssueTagBulkError && !removeIssueTagBulkError) return;
        const errorMessage = addIssueTagBulkError?.data?.errorMessage || removeIssueTagBulkError?.data?.errorMessage || errorMessages.defaultPost;
        dispatch(setAlert(errorMessage, alertTypes.error));
    }, [addIssueTagBulkError, removeIssueTagBulkError])

    const getTitle = () => {
        const count = selectedIssues?.length || 0
        return `Bulk Tag (${getIssuesCountLabel(count)} selected)`
    }

    const addTagsCallback = (tags) => {
        const tagsTitlesArray = tags.map(tag => tag?.label);
        addIssueTagBulk({ issue_ids: selectedIssues, titles: tagsTitlesArray })
    }

    const removeTagsCallback = (tags) => {
        const tagsIdsArray = tags.map(tag => tag?.value);
        removeIssueTagBulk({ issue_ids: selectedIssues, tag_ids: tagsIdsArray })
    }

    return (
        <AddRemoveTagsModal
            title={getTitle()}
            showModal={showModal}
            toggleModal={toggleModal}
            addTagsCallback={addTagsCallback}
            removeTagsCallback={removeTagsCallback}
            processing={loadingAddIssueTagBulk || loadingRemoveIssueTagBulk}
            isDisabled={isDisabled}
        />
    )
}

export default IssuesAddRemoveTagsModal
