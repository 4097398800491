import {
  ON_RULE_SET_LOADED,
  ON_TAGS_ARRAY_CHANGES,
  ON_COMPANY_ARRAY_CHANGES,
  ON_RULE_PARTNER_CHANGE,
  ON_RULE_ACTION_CHANGE,
  ON_RULE_SCHEDULE_CHANGE,
  ON_ADD_PARTNER,
  ON_ADD_ACTION,
  ON_REMOVE_ACTION,
  ON_SAVE_RULE_SET_SUCCESS,
  ON_RULE_SET_DETAIL_UNMOUNT,
  ON_AUTOMATION_UNMOUNT,
} from '../actions/types';
import { emptyRuleToCreate, emptyActionToCreate, newRuleSetPayload } from '../constants/automation';
import { removeItemFromArray, replaceItemOnArray, parseCompaniesToOptions } from '../utils/helpers';
import { mapSimpleTagsToOptions } from '../utils/tagsHelper';
import { groupRulesAsActionsByPartners } from '../utils/automationHelper';

const initialState = {
  tags: [],
  companies: [],
  rules: [],
  all_members: false,
  is_published: false,
};

//  Function to get selected action and rule
const getRuleAndAction = (ruleIndex, actionIndex, state) => {
  //  Getting selected rule
  const selectedRule = state?.rules[ruleIndex];
  if (!selectedRule) { return { selectedRule: null }; }
  //  Getting action to update
  const { actions } = selectedRule;
  const selectedAction = actions[actionIndex];
  if (!selectedAction) { return { selectedRule, selectedAction: null }; }
  return { selectedRule, selectedAction };
};

//  Function to replace new action on rules
const replaceActionOnRules = (actionIndex, rule_index, selectedRule, newAction, state) => {
  const { actions } = selectedRule;
  //  Replacing array actions
  const newActions = replaceItemOnArray(actions, actionIndex, newAction);
  //  Creating new rule
  const updatedRule = {
    ...selectedRule,
    actions: newActions
  };
  //  Creating updated rules
  const updatedRules = replaceItemOnArray(state?.rules, rule_index, updatedRule);
  return updatedRules;
};

//  Function to get parsed rule set
const parseRuleSet = (dbRuleSet) => {
  if (!dbRuleSet) { return newRuleSetPayload; }
  //  Getting attributes
  const {
    all_members,
    companies: dbCompanies,
    tags: dbTags,
    rules: dbRules,
  } = dbRuleSet
  //  Parsing
  const tags = mapSimpleTagsToOptions(dbTags);
  const companies = parseCompaniesToOptions(dbCompanies);
  const { rules, isPublished: is_published } = groupRulesAsActionsByPartners(dbRules);
  //  Returning
  return {
    is_published,
    all_members,
    tags,
    companies,
    rules,
  }
};

const ruleSetReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ON_RULE_SET_LOADED:
      const { ruleSet } = payload;
      const { tags, companies, all_members, rules, is_published } = parseRuleSet(ruleSet);
      const rulesToCreate = rules?.length === 0 ? [emptyRuleToCreate] : rules;
      return {
        ...state,
        tags,
        companies,
        all_members,
        rules: rulesToCreate,
        is_published,
      };

    case ON_COMPANY_ARRAY_CHANGES:
      const { companies: newCompanies, all_members: newFlag } = payload;
      return {
        ...state,
        companies: newCompanies,
        all_members: newFlag,
      };

    case ON_TAGS_ARRAY_CHANGES:
      const { tags: newTags } = payload;
      return {
        ...state,
        tags: newTags,
      };

    case ON_RULE_PARTNER_CHANGE:
      const { ruleIndex, partnerOption } = payload;
      //  Looking for existing rule
      const currentRule = state.rules[ruleIndex];
      if (!currentRule) { return state; }
      if (currentRule?.partner?.id === partnerOption?.value) { return state; }
      //  Creating new partner
      const newPartner = {
        id: partnerOption?.value,
        name: partnerOption?.label,
        actions: partnerOption?.actions,
      };
      //  Creating new rule (need to clear actions on partner change)
      const newRule = {
        ...currentRule,
        partner: newPartner,
        actions: [emptyActionToCreate],
      };
      //  Generating new rules array
      const newRules = replaceItemOnArray(state?.rules, ruleIndex, newRule);
      //  Mutating state
      return {
        ...state,
        rules: newRules,
      };

    case ON_RULE_ACTION_CHANGE:
      const { ruleIndex: rule_index, actionIndex, actionOption } = payload;
      //  Getting required rule and action
      const { selectedAction, selectedRule } = getRuleAndAction(rule_index, actionIndex, state);
      if (!selectedRule || !selectedAction) { return state; }
      if (selectedAction.id === actionOption?.value) { return state; }
      //  Creating new action
      const newAction = {
        ...selectedAction,
        action_id: actionOption?.value,
        name: actionOption?.label,
      };
      //  Creating updated rules
      const updatedRules = replaceActionOnRules(actionIndex, rule_index, selectedRule, newAction, state);
      return {
        ...state,
        rules: updatedRules,
      }

    case ON_RULE_SCHEDULE_CHANGE:
      const {
        schedule,
        repeat,
        run_now,
        ruleIndex: rulePosition,
        actionIndex: actionPosition,
      } = payload;
      //  Getting action and rule to modify
      const {
        selectedRule: ruleToEdit,
        selectedAction: actionToEdit,
      } = getRuleAndAction(rulePosition, actionPosition, state);
      if (ruleToEdit === null || actionToEdit === null) { return state; }
      if (actionToEdit.schedule === schedule && actionToEdit.repeat === repeat && actionToEdit.run_now === run_now) { return state; }
      //  Creating new action
      const edittedAction = {
        ...actionToEdit,
        schedule,
        repeat,
        run_now,
      };
      //  Updating acion on rule array
      const edittedRules = replaceActionOnRules(actionPosition, rulePosition, ruleToEdit, edittedAction, state);
      return {
        ...state,
        rules: edittedRules,
      }

    case ON_ADD_PARTNER:
      const newPartners = [...state?.rules, emptyRuleToCreate];
      return {
        ...state,
        rules: newPartners,
      };

    case ON_ADD_ACTION:
      const ruleToAddAction = state?.rules[payload];
      if (!ruleToAddAction) { return state; }
      //  Adding new empty action to rule
      const modifiedActions = [...ruleToAddAction?.actions, emptyActionToCreate];
      const modifiedRule = { ...ruleToAddAction, actions: modifiedActions };
      const modifiedRules = replaceItemOnArray(state?.rules, payload, modifiedRule);
      return { ...state, rules: modifiedRules };

    case ON_REMOVE_ACTION:
      const { ruleIndex: rulePos, actionIndex: indexToRemove } = payload;
      //  Getting rule to update
      const ruleToUpdate = state?.rules[rulePos];
      if (!ruleToUpdate) { return state; }
      //  Getting actions and removing element
      const { actions: actionsToUpdate } = ruleToUpdate;
      const actionsWithoutElement = removeItemFromArray(actionsToUpdate, indexToRemove);
      //  Need to remove partner in this case
      if (actionsWithoutElement?.length === 0) {
        const updatedRules = removeItemFromArray(state?.rules, rulePos);
        return {
          ...state,
          rules: updatedRules.length === 0 ? [emptyRuleToCreate] : updatedRules
        }
      }
      //  Just update the rules actions
      const updatedRule = { ...ruleToUpdate, actions: actionsWithoutElement };
      const newRulesToSet = replaceItemOnArray(state?.rules, rulePos, updatedRule);
      return { ...state, rules: newRulesToSet };

    case ON_SAVE_RULE_SET_SUCCESS:
    case ON_RULE_SET_DETAIL_UNMOUNT:
    case ON_AUTOMATION_UNMOUNT:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state;
  }
};

export default ruleSetReducer;
