import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalFooter, Button } from 'reactstrap';
import { convertToRaw } from 'draft-js';
import BasicModal from '../../layout/BasicModal';
import RemediationNoteEditor from '../../issue-manager/RemediationNoteEditor';
import useUpdateGroups from '../../../hooks/useBulkUpdateGroups';
import { RESOLVED_STATUS } from '../../../constants/issues';
import useCustomMutation from '../../../hooks/useCustomMutation';
import useHandleApiResponse from '../../../hooks/useHandleApiResponse';
import API from '../../../api';
import { setAlert } from '../../../actions/alerts';
import { alertTypes } from '../../../constants/alerts';

const GroupsRemediationNote = (props) => {
  //  Parsing props
  const {
    groups = [],
    closeModal = () => { },
    fromDetails = false,
  } = props;

  //  Initializing API
  const { GroupAPI } = API;

  //  Watching redux store
  const user = useSelector((state) => state.auth.user);

  //  Component state
  const [editorState, setEditorState] = useState(null);

  //  Component hooks
  const { dispatch } = useDispatch();
  const { bulkUpdate, updateLoading } = useUpdateGroups({
    messageAction: 'moved to',
    messagePlaceholder: 'Resolved',
    onSuccess: () => { closeModal(); }
  });
  const {
    data: updateGroupData,
    error: updateGroupError,
    isLoading: updateGroupLoading,
    mutate: updateGroup,
  } = useCustomMutation(
    (params) => GroupAPI.handlers.updateGroup(params),
    GroupAPI.invalidators.updateGroup
  );

  useHandleApiResponse({
    data: updateGroupData,
    errorData: updateGroupError,
    successMessage: "Issues updated",
    successCallback: () => {
      setEditorState(null);
      closeModal()
    }
  });

  const getTitle = () => {
    const count = groups?.length || 0
    return fromDetails ? `Add a Note for the selected group` : `Add a Note for the ${count} selected groups`
  };

  const getRemediationNote = () => {
    const raw = convertToRaw(editorState.getCurrentContent());
    return {
      updated_at: new Date(),
      user: {
        id: user.id,
        full_name: user.name,
        profile_image: user.profileImage,
      },
      content: raw,
      is_remediation: true,
    }
  }

  //  Function to handle post note
  const onPostNote = () => {
    if (!editorState) {
      dispatch(setAlert('Please enter a note', alertTypes.error));
      return;
    }
    if(fromDetails) {
      const params = {
        public_id: groups[0],
        body: {
          status: RESOLVED_STATUS,
          remediation_note: getRemediationNote(),
        }
      };
      updateGroup(params);
    } else {
      const params = {
        group_ids: groups,
        body: {
          status: RESOLVED_STATUS,
          remediation_note: getRemediationNote(),
        }
      };
      bulkUpdate(params);
    }
  };

  const renderMessage = () => {
    const groupLabel = fromDetails ? 'this group' : 'these groups'
    const message = `You need to enter a remediation note to resolve ${groupLabel}. This note will apply to all the issues on ${groupLabel}.`;
    return (
      <div className="remediation-message">
        {message}
      </div>
    )
  };

  const renderTextEditor = () => {
    return (
      <>
        <div className="remediation-note-title mb-1">Remediation note</div>
        <RemediationNoteEditor
          editorState={editorState}
          setEditorState={setEditorState}
          user={user}
          isDisabled={false}
        />
      </>
    )
  };

  const renderFooter = () => {
    return (
      <ModalFooter className="mx-0">
        <div className="text-center" >
          <Button
            className={`btn btn-light text-dark font-weight-bold`}
            type="submit"
            onClick={onPostNote}
            disabled={updateLoading}
          >
            {!updateLoading ? 'Post note and Resolve' : 'Processing...'}
          </Button>
        </div>
      </ModalFooter>
    )
  }

  //  Rendering
  return (
    <BasicModal
      header={getTitle()}
      headerClassName={`modal-header-status`}
      showModal={true}
      toggleModal={closeModal}
      customClassName="issues-remediation-note-modal"
      scrollable={true}
      customFooter={renderFooter()}
    >
      {renderMessage()}
      {renderTextEditor()}
    </BasicModal>
  );
};

export default GroupsRemediationNote;
