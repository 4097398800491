import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import LoadingContent from '../components/layout/LoadingContent';
import TrustBuilder from '../components/vcyso/TrustBuilder';
import API from '../api';
import useTrustBuilderRequest from '../hooks/useTrustBuilderRequest';
import useCustomQuery from '../hooks/useCustomQuery';

const ExportContent = () => {
    //  Initializing APIs
    const { VcysoAPI } = API;

    const { token, type } = useParams();

    const {
        data: validationResponse,
        error: validationError,
        isLoading: validationLoading,
    } = useCustomQuery(
        [VcysoAPI.keys.getExportTokenValidation, token],
        ({ queryKey }) => VcysoAPI.handlers.getExportTokenValidation(queryKey[1]),
    );

    //  Hook for making trust builder request
    const {
        trustBuilderContentError,
        trustBuilderContentLoading,
        trustBuilderContentResponse,
        hasVcyso,
        makeTrustBuilderRequest,
        retryTrustBuilderRequest,
    } = useTrustBuilderRequest({
        company_id: undefined,
        apiFunction: VcysoAPI.handlers.getTrustBuilderContent,
        lazyQuery: true,
        apiKey: VcysoAPI.keys.getTrustBuilderContent,
    });

    //  Watching token responses
    useEffect(() => {
        if (!validationResponse) { return; }
        if (_.isNil(validationResponse?.company_id || type !== 'cyso')) { return; }
        makeTrustBuilderRequest({ company_id: validationResponse?.company_id, token });
    }, [validationResponse]);


    return (
        <LoadingContent
            isLoading={validationLoading}
            errorMessage={validationError ? 'Our fault, reload again.' : null}
            errorStyle={{ paddingTop: "50px" }}
            LoadingStyle={{ paddingTop: "50px" }}

        >
            {!_.isNil(validationResponse?.company_id && type === 'cyso') && (
                <div className={`vcyso-container export-content-page mx-auto ${validationResponse?.preview ? 'preview' : ''}`}>
                    <TrustBuilder
                        companyId={validationResponse?.company_id}
                        exportContent={true}
                        token={token}
                        trustBuilderContentError={trustBuilderContentError}
                        trustBuilderContentLoading={trustBuilderContentLoading}
                        trustBuilderContentResponse={trustBuilderContentResponse}
                        retryTrustBuilderRequest={retryTrustBuilderRequest}
                        hasVcyso={hasVcyso}
                    />
                </div>
            )}
        </LoadingContent>
    )
}

export default ExportContent;