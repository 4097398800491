import React from 'react';
import { Col } from 'reactstrap';
import LoadingContent from '../../../../layout/LoadingContent';
import { ERROR_SOLID_ICON } from '../../../../../constants/errorStyles';
import CyValueScore from '../../../../dashboard/cydekick-results/CyValueScore';
import IcarmStepsStatus from '../../../../dashboard/cydekick-results/icarm/IcarmStepsStatus';
import { getSafeArray } from '../../../../../utils/helpers';
import ActivityAndIssueContainer from '../../../../dashboard/cydekick-results/ActivityAndIssueContainer';
import { isOverallSolution, solutionGoal } from '../../../../../constants/cyValueData';
import DashboardCard from '../../../common/dashboard/DashboardCard';
import CyvalueCountDown from './CyvalueCountDown';
import IcarmSolutionsCyvalueProgress from './IcarmSolutionsCyvalueProgress';

const IcarmSummary = (props) => {

    const {
        isLoading,
        errorMessage,
        retryRequests,
        solutionsData,
        filters,
        hasSurpassedGoal,//
        hasOverallScore,//
        hasZeroScore,//
        isFreemium,
        themeColor,//
        lg = 6,
        lastNumberDays,
        emptyState = () => {},
    } = props;

    // Component variables
    const cardLoadingStyle = { minHeight: "225px" };

    // Render Functions
    // Render Cyvalue Score Chart and comparison
    const renderCyvalueScore = () => {
        if(!solutionsData) return null;
        const {
            name,
            overall_score,
            improvement,
            cyvalue_reached_date,
            solution,
        } = solutionsData;
        return (
            <div className="d-flex flex-column justify-content-center mx-auto overall-score-container">
                <CyValueScore
                    solutionName={name}
                    overall_score={overall_score}
                    improvement={improvement}
                    color={themeColor?.color || ['transparent']}
                    hasSurpassedGoal={hasSurpassedGoal}
                    hasScore={hasOverallScore}
                    hasZeroScore={hasZeroScore}
                    goal={solutionGoal}
                    hasNewSolution={solutionsData?.hasNewSolution}
                    guarantee_date={solution?.guarantee_date}
                    cyvalue_reached_date={cyvalue_reached_date}
                    filters={filters}
                />
            </div>
        )
    }

    // Render Icarm step with their respective status
    const renderIcarmStepsStatus = () => {
        if(isFreemium) return null;
        const icarmSteps = getSafeArray(solutionsData?.icarm?.steps)
        return (
          <div className={`icarm-status-wrapper w-100 pb-2 align-self-lg-stretch`}>
            <IcarmStepsStatus
                solutionName={solutionsData?.name}
                steps={icarmSteps}
            />
          </div>
        )
    }

    // Render Cyvalue Countdown
    const renderCyvalueCountdown = () => {
        return (
            <CyvalueCountDown
                solutionName={solutionsData?.name}
                icarmData={solutionsData?.icarm}
                hasSurpassedGoal={hasSurpassedGoal}
                isFreemium={isFreemium}
                themeColor={themeColor}
                pendingCyvalueSolutions={solutionsData?.pendingCyvalueSolutions}
                hasSolutionData={solutionsData?.solutions?.length}
            />
        )
    }

    // Render Current activities status
    const renderCurrentActivitiesStatus = () => {
        const isOverall = isOverallSolution(solutionsData?.name);
        const hasSolutionsWithCyvalue = (!isOverall && hasSurpassedGoal) || (isOverall && solutionsData?.solutions?.length > 0 && solutionsData?.pendingCyvalueSolutions?.length < 1)
        if(isFreemium || !solutionsData || !hasSolutionsWithCyvalue) return null;
        const {
            name: solutionName,
            activities,
            resolved_issues
        } = solutionsData;
        return (
            <ActivityAndIssueContainer
                solutionName={solutionName}
                activities={activities}
                issues={resolved_issues}
                currentThemeColor={themeColor}
                filterType={filters?.filter_type}
            />
        )
    }

    const newIcarm = () => {
      const icarmSolutions = solutionsData?.solutions?.filter((solution) => solution?.name !== 'Overall');
      return (
        <>
          {icarmSolutions?.length > 0 && !isLoading ?
          (<IcarmSolutionsCyvalueProgress
            solutionName={solutionsData?.name}
            icarmDataSteps={solutionsData?.steps}
            hasSurpassedGoal={hasSurpassedGoal}
            isFreemium={isFreemium}
            icarmSolutions={icarmSolutions}
            hasSolutionData={solutionsData?.solutions?.length}
            lastNumberDays={lastNumberDays}
          />) : emptyState()
          }
        </>
      );
    };

    const previousIcarm = () => {
        return (
          <>
            {
              <Col sm={4} xs={12} className="d-flex justify-content-between">
                {renderCyvalueScore()}
              </Col>
            }
            <Col sm={12} xs={12}>
              {
                <div className="d-flex h-100 flex-wrap justify-content-around align-items-center">
                  <div className="d-flex align-items-center">
                    {renderCyvalueCountdown()}
                    {renderCurrentActivitiesStatus()}
                    {renderIcarmStepsStatus()}
                  </div>
                </div>
              }
            </Col>
          </>
        );
    };



    return (
      <Col lg={lg} className="px-2 pb-2 pb-lg-0">
        <LoadingContent
          isLoading={isLoading}
          errorMessage={errorMessage}
          iconType={ERROR_SOLID_ICON}
          errorStyle={cardLoadingStyle}
          loadingStyle={cardLoadingStyle}
          errorButtonCallback={retryRequests}
        >
          <div className="icarm-summary d-flex flex-wrap h-100">
            {newIcarm()}
            {/*previousIcarm()*/}
          </div>
        </LoadingContent>
      </Col>
    );
}

export default IcarmSummary
