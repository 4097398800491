import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import CustomDatepicker from '../../calendar/CustomDatepicker';
import CustomTimepicker from '../../calendar/CustomTimepicker';
import { mapScheduleToDate, getUntilFromRRule } from '../../../utils/automationHelper';
import { scheduleComponentTypes } from '../../../constants/automation';

const ScheduleEdit = (props) => {
  //  Parsing props
  const { repeat, schedule, changeSchedule } = props;

  //  Component state
  const [date, setDate] = useState(mapScheduleToDate(schedule));

  //  Watching repeat changed
  useEffect(() => {
    const until = getUntilFromRRule(repeat);
    if (!until || !date?.date) { return; }
    const untilUnix = until.getTime();
    const startTimeUnix = date?.date.getTime();
    if (untilUnix <= startTimeUnix) { setDate({ ...date, date: null }); }
  }, [repeat])

  //  Watching date changes
  useEffect(() => {
    const { date: newDate, hour, timezone } = date;
    if (!newDate || !hour) { changeSchedule(null); return; }
    const dateToSet = moment(newDate, moment.tz.guess()).format('YYYY-MM-DD');
    const hourToSet = moment(hour, 'hh:mm A').format('HH:mm')
    const newSchedule = moment(`${dateToSet} ${hourToSet}`).toDate().toISOString();
    changeSchedule(newSchedule);
  }, [date]);

  //  Function to handle calendar changes
  const handleCalendarChanges = (value) => {
    setDate({ ...date, date: value });
  };

  //  Function to handle time changes
  const handleTimeChanges = (value) => {
    setDate({ ...date, hour: value });
  };

  //  Function to render component
  const renderComponent = (type) => {
    //  Rendering timezone label
    if (type === scheduleComponentTypes.TIMEZONE) {
      return (
        <div className="content-label">
          {date.timezone}
        </div>
      );
    }

    //  Rendering calendar component
    if (type === scheduleComponentTypes.CALENDAR) {
      return (
        <CustomDatepicker
          maxDate={getUntilFromRRule(repeat)}
          value={date?.date}
          setValue={handleCalendarChanges}
        />
      );
    }

    //  Rendering time picker
    if (type === scheduleComponentTypes.TIME) {
      return (
        <CustomTimepicker
          value={date?.hour}
          setValue={handleTimeChanges}
        />
      );
    }

    return null;
  }

  //  Function to render label component container
  const renderLabelComponentContainer = (label, size, lefMargined, type) => {
    let className = 'label-component-container';
    className += ` ${size}-container`;
    if (lefMargined) { className += ' regular-left-margin'; }

    return (
      <div className={className}>
        <div className="label">{label}</div>
        {renderComponent(type)}
      </div>
    );
  };

  //  Rendering
  return (
    <div className="edit-schedule-row">
      {renderLabelComponentContainer('Date', 'medium', false, scheduleComponentTypes.CALENDAR)}
      {renderLabelComponentContainer('Time', 'medium', true, scheduleComponentTypes.TIME)}
      {renderLabelComponentContainer('Time Zone', 'medium', true, scheduleComponentTypes.TIMEZONE)}
    </div>
  );
};

export default ScheduleEdit;
