import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import LoadingPage from '../components/layout/LoadingPage';
import { cydekicksDefaultPath, defaultIndexPath, salesDefaultPath } from '../constants/navigation';
import { SQUAD_ADMIN_ROLE, SQUAD_CYDEKICK_ROLE, SQUAD_SALES_ROLE } from '../constants/roles';
import useCanAbility from '../hooks/useCanAbility';
import { isCyvatarMember } from '../utils/helpers';
import PageNotFound from '../views/PageNotFound';

const PrivateRoute = (props) => {
    const {
        path,
        requireMFA,
        component: Component,
        location,
        access,
        resource,
        ...rest
    } = props;

    // Getting needed info from redux store
    const { isAuthenticated, loading, user, mfaConfig, company } = useSelector(state => state.auth);

    // Component Hooks
    const ability = useCanAbility();


    /**
     * If the role name is 'sales', return the sales default path, if the role name is 'admin' or
     * 'cydekick', return the cydekicks default path, otherwise return the default index path.
     * @param roleName - The name of the role that the user has.
     * @returns The default path for the role name.
     */
    const getDefaultPathByRoleName = (roleName) => {
        switch (roleName) {
            case SQUAD_SALES_ROLE:
                return salesDefaultPath;
            case SQUAD_ADMIN_ROLE:
            case SQUAD_CYDEKICK_ROLE:
                return cydekicksDefaultPath;
            default:
                return defaultIndexPath;
        }
    }


    /**
     * If the path is '/', redirect them to the welcome page. If is another path, redirect them
     * to the login page
     * @returns A redirect to the login or welcome page if the user is not logged in.
     */
    const renderPublicRoute = () => {
        const redirectRoute = path === '/' ? '/my-cyvatar' : '/login';
        return (
            <Route
                {...rest}
                render={props =>
                    <Redirect
                        to={{
                            pathname: redirectRoute,
                            state: {
                                from: props?.location
                            }
                        }}
                    />
                }
            />
        );
    }

    /**
     * Display the route component if shouldRedicet is false and the user has access to the route.
     * Otherwise return pageNotfound or the default path
     * @param routeProps - The props that are passed to the component from the Route component.
     * @param defaultPath - The path to redirect to if the user is not authenticated.
     * @param shouldRedirect - This is a boolean value that determines whether the user should be
     * redirected to the default path.
     * @param canAccessRoute - This is a boolean value that determines if the user can access the route.
     * @returns Final component to display
     */
    const renderRouteComponent = (routeProps, defaultPath, shouldRedirect, canAccessRoute) => {
        const redirectPathConfig = {
            pathname: defaultPath,
            state: { from: routeProps?.location }
        };

        return (!shouldRedirect && canAccessRoute) ? (
            <Component {...routeProps} />
        ) : (
            !canAccessRoute ? (
                <PageNotFound />
            ) : (
                <Redirect to={redirectPathConfig} />
            )
        )
    }

    // Render Private routes and check privileges
    const renderPrivateRoute = () => {
        // Route to default landing page based on role
        const isCyvatarSquadMember = isCyvatarMember(user);

        // Getting path
        const isIndexPath = path === '/';
        const roleName = user?.roles[0]?.name;
        let defaultPath = isIndexPath ? getDefaultPathByRoleName(roleName) : path;


        // Check if path requires MFA for members with active packages
        if (!isCyvatarSquadMember && requireMFA && company?.freemium === 0) {
            const forceMFAPath = user?.packages?.length > 0 && !mfaConfig?.mfa_type
            defaultPath = forceMFAPath ? '/mfa-config' : defaultPath;
        }

        const shouldRedirect = (defaultPath !== path);
        const canAccessRoute = access && resource ? ability.can(access, resource) : true;

        return (
            <Route
                {...rest}
                render={routeProps => renderRouteComponent(
                    routeProps,
                    defaultPath,
                    shouldRedirect,
                    canAccessRoute
                )}
            />
        );
    }

    return !loading ? (
        !isAuthenticated ? renderPublicRoute() : renderPrivateRoute()
    ) : (
        <LoadingPage />
    );
}

export default PrivateRoute;
