import _ from 'lodash';
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { groupTabIds } from '../../../../constants/groups';
import DevicesAddRemoveTagsModal from '../../../devices/DevicesAddRemoveTags';
import GroupTagsModal from '../GroupTagsModal';

const GroupDetailsBulkTags = (props) => {
  //  Parsing props
  const { 
    group_id, 
    issues_count = 0, 
    bulkTagsType = groupTabIds.GROUP_GENERAL_TAB, 
    selectedDevices = [],
  } = props;

  //  Component state
  const [showGeneralTagsModal, setShowGeneralTagsModal] = useState(false);
  const [showDevicesTagsModal, setShowDevicesTagsModal] = useState(false);

  // Function to toggle the devices tags modal
  const toggleTagsModal = (show) => {
    setShowDevicesTagsModal(!_.isNil(show) ? show : !showDevicesTagsModal);
  }

  //  Function to toggle general (issues group) tags modal
  const toggleModal = (flag = false) => {
    setShowGeneralTagsModal(flag);
  };

  //  Function to handle tag click
  const onTagsClicked = () => {
    if (bulkTagsType === groupTabIds.GROUP_GENERAL_TAB) {
      toggleModal(true);
    } else if (bulkTagsType === groupTabIds.GROUP_DEVICES_TAB) {
      toggleTagsModal(true);
    }
  };

  //  Function to render modal
  const renderTagsModal = () => {
    if (!showGeneralTagsModal && !showDevicesTagsModal) { return null; }
    if (bulkTagsType === groupTabIds.GROUP_GENERAL_TAB) {
      return (
        <GroupTagsModal
          groups={[group_id]}
          countLabel={`${issues_count} issues`}
          closeModal={toggleModal}
        />
      );
    } else if (bulkTagsType === groupTabIds.GROUP_DEVICES_TAB) {
      return (
        <DevicesAddRemoveTagsModal
          showModal={showDevicesTagsModal}
          toggleModal={toggleTagsModal}
          selectedDevices={selectedDevices}
        />
      );
    }
  };

  //  Rendering
  return (
    <>
      <Button
        className="btn-add-bulk-notes btn-small d-flex align-items-center"
        color="light"
        onClick={onTagsClicked}
        disabled={bulkTagsType === groupTabIds.GROUP_DEVICES_TAB && selectedDevices.length === 0}
      >
        <i className="bx bx-plus-circle mr-2" />
        {bulkTagsType === groupTabIds.GROUP_GENERAL_TAB ?  'Bulk add/remove' : bulkTagsType === groupTabIds.GROUP_DEVICES_TAB ? 'Add bulk tags' : null}
      </Button>
      {renderTagsModal()}
    </>
  );
};

export default GroupDetailsBulkTags;
