import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Dashboard from '../components/dashboard/Dashboard';
import CustomerSolutionList from '../components/Members/CustomerSolutionList';
import { onSidebarTabSelected } from '../actions/navigation';
import companyInfoSelector from '../selectors/companyInfoSelector';
import useMemoizedDispatch from '../hooks/useMemoizedDispatch';
import ImpersonationEmptyView from '../components/layout/ImpersonationEmptyView';
import companyIdSelector from '../selectors/companyIdSelector';
import useCanAbility from '../hooks/useCanAbility';
import { CREATE_ACTION, PRIVILEGE_SOURCES } from '../constants/privileges';

const CustomerSolutions = (props) => {
    //  Component hooks
    const location = useLocation();
    const { dispatch } = useMemoizedDispatch();
    const ability = useCanAbility();

    //  Watching redux store
    const userRole = useSelector((state) => state.auth.user?.roles[0]?.name);
    const isSales = useSelector((state) => companyInfoSelector(state, 'isSales', false));
    const freemiumStatus = useSelector((state) => companyInfoSelector(state, 'freemium', false));
    const companyId = useSelector((state) => companyIdSelector(state));
    const impersonatedCompanyId = useSelector((state) => state.impersonation.companyId);

    // Component variables
    const canImpersonate = ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.MULTIPLE.IMPERSONATE);

    //  Setting selected tab to clear the subtabs and the navigation state
    useEffect(() => {
        const activeImpersonation = !_.isNil(impersonatedCompanyId);
        // Special case for profile tabs
        dispatch(onSidebarTabSelected(location.pathname, userRole, true, freemiumStatus, isSales, activeImpersonation));
    }, [location?.pathname]);

    //    Rendering
    return (
        <Dashboard
            title="Members / Member’s Solutions"
        >
            <div className="members">
                {canImpersonate && !companyId ? (
                    <ImpersonationEmptyView viewName="Solutions" />
                ): (
                    <CustomerSolutionList />
                )}
            </div>
        </Dashboard>
    );
}
export default CustomerSolutions;
