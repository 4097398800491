import { viewEntries } from './common';

export const addCompanyMemberModalData = {
  title: 'Invite team members to Cyvatar',
  description: 'People you add will have full access profile. They will be able to see and change any information and add comments.',
};

export const addCompanyDomainsModalData = {
  title: 'Add domains',
};

export const addNewMemberModalData = {
  title: 'Add new Member to Cyvatar',
  addMembersSectionTitle: 'Company users'
};
export const trustBuilderFreemiumBar = {
  title: 'Get the CySO solution to start Building Trust',
  descriptions: [
    'With the Trust Builder you will collaborate with you CySO to generate a Cybersecurity confidence summary that you can share with your customers or board members.',
    'Contact our Sales agents to subscribe CySO.',
  ],
  hasContactUs: false,
};

export const trustBuilderSubscribedBar = {
  title: 'Trust Builder How to',
  descriptions: [
    'Collaborate with your CySO to generate a Cybersecurity confidence summary that you can share with your customers or board members.'
  ],
  hasContactUs: true,
};

export const securityPoliciesFreemiumBar = {
  title: 'Setting up your Security Policies',
  descriptions: [
    'Starting with our Policies services doesn’t mean you have to do it all by yourself. Count on our CySO for guidance. You get the first 3 documents for free! Subscribe to CySO to get all policies in rule.',
  ],
  hasContactUs: false,
};

export const securityPoliciesSubscribedBar = {
  title: 'Setting up your Security Policies',
  descriptions: [
    'Starting with our Policies services doesn’t mean you have to do it all by yourself. Count on our CySO for guidance.',
  ],
  hasContactUs: false,
};

export const assessmentBar = {
  title: 'Welcome to your Assessment!',
  descriptions: [
    `Look at you!  You are an explorer. If you have reached this page by yourself, answer the questions and you will see your results in the dashboard.  However, if you don't want to conquer this alone, our CySO team can work with you to fill it out and add insights to help you build your journey.`,
  ],
  hasContactUs: false,
  customButtonLabel: "Schedule 30 min with CySO"
};

// CHANGES FROM HERE ----------------------------

export const businessRiskData = {
  title: 'Risk Manager How to',
  descriptions: [
    'Along with your CySO you will define the Impact, Likelihood and Treatment for each Risk Statement so we can evaluate the amount of Risk you’re willing to accept on your system.',
    'Ready to set this up?'
  ],
  hasContactUs: false,
  customButtonLabelBusiness: "Schedule 30 min with CySO"
}

export const trustBuilderUnsubscribedBarBusiness = {
  title: 'Risk Manager How to',
  descriptions: [
    'Along with your CySO you will define the Impact, Likelihood and Treatment for each Risk Statement so we can evaluate the amount of Risk you’re willing to accept on your system.',
    'Contact our Sales agents to Subscribe CySO.'
  ],
  customButtonLabelBusiness: "Schedule 30 min with CySO",
  hasContactUs: true,
};

//  Advantages on freemium login
export const freemiumAccountAdvantages = [
  { normalText: 'Free Security Policy templates', hightlightedText: null },
  { normalText: 'Assess your company’s Cybersecurity for free', hightlightedText: null },
  { normalText: 'Free Cybersecurity advice from our Security Professionals', hightlightedText: null },
  { normalText: 'Access to the My Cyvatar Platform', hightlightedText: null },
  { normalText: 'Free task management tool', hightlightedText: null },
];


// Accomplishments Freemium Data
export const accomplishmentsFreemiumData = {
  title: 'Get secure and tell everyone',
  messages: [
    'As soon you start your security journey with us, you’re already secured by Cyvatar.',
    'We will provide you with a membership certificate that you can share with your clients, stakeholders and general public by embeding our badge to your website or posting it on Linkedin!',
  ],
  buttonLabel: "Get secured by Cyvatar",
};

// Certificate Unauthenticated Data
export const certificateUnauthenticatedData = {
  title: 'Looking for Effortless Cybersecurity?',
  messages: [
    'Cyvatar fulfills your needs for a robust cybersecurity program through strategy, execution, and resolution',
    '– all in one beautiful and intuitive platform.',
  ],
  buttonLabel: "Learn more about Cyvatar",
};

// const homeContent = {
//   title: 'Welcome to your platform homepage. ',
//   content: 'From here you can access everything you need to manage your cyber security program with Cyvatar. In freemium you will find various modules that enable you to build, manage and operate your security programme, including our Risk Manager module, Policies and our Assessment capability. Once subscribed, your homepage will become the staging point for managing all of your Cyvatar solutions and security operational capabilities. ',
// }

const homeGrcContent = {
  title: 'Our task manager is now LIVE!',
  content: `Plan, manage and track all your team's tasks in one flexible software.`,
  link: {
    pathname: '/dashboard/task-manager',
    label: 'See GRC tool'
  }
}

// Freemium banners messages
export const freemiumBannerContent = {
  [viewEntries.HOME]: homeGrcContent,
  [viewEntries.MYCYSO]: {
    title: 'MyCyso',
    content: 'Welcome to your Cyvatar CySO. Where Cyvatar members get all their security questions answered, just like you have your very own Chief Security Officer. From here you can quickly and easily see everything that is happening across your Cyvatar subscriptions, from high level executive overviews to full lists of technical issues and resolutions, its all here to provide you with the insights you need. Take a glance at the CyValue module to see when your subscriptions will be up and running, monitor the activity of your Cydekicks and get peace of mind all from your personal CySO.',
  },
};

// Paying Members banner message
export const payingBannerContent = {
  [viewEntries.HOME]: homeGrcContent,
  [viewEntries.MYCYSO]: {
    title: 'MyCyso',
    content: 'Welcome to your Cyvatar CySO. Where Cyvatar members get all their security questions answered, just like you have your very own Chief Security Officer. From here you can quickly and easily see everything that is happening across your Cyvatar subscriptions, from high level executive overviews to full lists of technical issues and resolutions, its all here to provide you with the insights you need. Take a glance at the CyValue module to see when your subscriptions will be up and running, monitor the activity of your Cydekicks and get peace of mind all from your personal CySO.',
  },
};

//  Register success messages
export const registerVerificationMessage = {
  title: 'We sent a verification email to {email}',
  body: 'Please confirm your email to join the road to cybersecurity with Cyvatar.',
  footer: 'Check your spam folder, Just in case :)',
};

export const registerAuthorzationMessage = {
  title: 'We sent a verification email to your administrator',
  body: 'Wait for the admin to accept your request.',
};
