import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Button, Form } from "reactstrap";
import BasicModal from "../layout/BasicModal";
import PartnerActionScreen from "./PartnerActionScreen";
import { clearPartnerActionValues, setPartnerActionCleanupMap, setMultiplePartnerActionValues } from "../../actions/partners";
import useMemoizedDispatch from "../../hooks/useMemoizedDispatch";
import API from "../../api";
import useCustomMutation from "../../hooks/useCustomMutation";
import companyIdSelector from "../../selectors/companyIdSelector";
import { fieldTypes, generateCleanupMap, modalSizes } from "../../utils/partnerActionHelpers";
import { setAlert } from "../../actions/alerts";
import { alertTypes } from "../../constants/alerts";
import { getSafeArray } from "../../utils/helpers"

const PartnerActionModal = (props) => {
  // Obtaining props
  const { action, onCloseModal } = props;
  const expandedMode = false;

  // State
  const [screenIndex, setScreenIndex] = useState(0);
  const { screens, modal_size } = action;

  // Redux
  const partnerActionValues = useSelector((state) => state.partners.partnerActionValues);
  const companyId = useSelector((state) => companyIdSelector(state));
  const partnerId = useSelector((state) => state.partners.partnerId);

  // Hooks
  const { dispatch } = useMemoizedDispatch();

  //  Initializing APIs
  const { PartnerAPI } = API;

  // Preparing cleanup map for action
  const cleanupMap = useMemo(() => {
    return generateCleanupMap(action);
  }, []);

  // Setup default values and default negative bools for switches
  const setupDefaultValues = () => {
    const fieldsArray = getFieldsArray(action);
    console.log('Fields array: ', fieldsArray);
    let defaults = {}
    fieldsArray.forEach((field) => {
      const { key, type, default: defaultValue, required_keys} = field;
      if (!required_keys) {
        if (type == fieldTypes.SWITCH) {
          defaults[key] = false;
        }
        console.log(`${key} has default value: ${defaultValue}`);
        if (defaultValue && defaultValue != '') {
          defaults[key] = defaultValue;
        }
      }
    });
    dispatch(setMultiplePartnerActionValues(defaults));
  }

  useEffect(() => {
    dispatch(setPartnerActionCleanupMap(cleanupMap));
    setupDefaultValues();
    // TODO: Add default values. Switch false values
    return () => {
      dispatch(clearPartnerActionValues()); // This also clears map
    };
  }, []);

  // Call partner action setup
  const {
    data: partnerActionResponse,
    error: partnerActionError,
    isLoading: partnerActionLoading,
    mutate: callPartnerAction,
  } = useCustomMutation(
    (params) => PartnerAPI.handlers.callPartnerAction(params),
    PartnerAPI.invalidators.callPartnerAction
  );

  useEffect(() => {
    if (!partnerActionResponse) { return } // TODO: Return error?

    const { message } = partnerActionResponse;
    dispatch(setAlert(message || "Action executed successfully.", alertTypes.success));
    closeModal();
  }, [partnerActionResponse]);

  useEffect(() => {
    if (!partnerActionError) { return }
    const { errorMessage } = partnerActionError?.response?.data;
    dispatch(setAlert(errorMessage || "An unexpected error occurred. Please try again.", alertTypes.error));
  }, [partnerActionError]);

  // Handling modal closing
  const closeModal = () => {
    onCloseModal();
  };

  const renderScreen = (index) => {
    if (!screens) { return null }
    const screen = screens[index];
    return(
      <PartnerActionScreen screen={screen}></PartnerActionScreen>
    )
  }

  const getFieldsArray = (action) => {
    const fieldsArray = [];

    if (!action) { return null } // Check if action exists

    // Check if action has screens
    if (action.hasOwnProperty('screens')) {
      const { screens } = action;
      if (!Array.isArray(screens)) { return null } // Check if is array, if not return null

      // Iterate through screens
      for (let screenKey in screens) {
        // Check if screen has components
        if (screens[screenKey].hasOwnProperty('components')) {
          const { components } =  screens[screenKey];
          if (!Array.isArray(components)) { continue } // Skip this screen

          // Iterate through components
          for (let componentKey in components) {
            // Check if component has fields
            if (components[componentKey].hasOwnProperty('fields')) {
              const { fields } = components[componentKey];
              if (!Array.isArray(fields)) { continue } // Skip this component

              fields.forEach(field => {
                fieldsArray.push(field);
              });
            }
          }
        }
      }
    }
    return fieldsArray;
  }

  // Updates action values to have key: value format.
  // This currently applies for dropdowns
  const processActionValues = (values = {}) => {
    const fieldsArray = getFieldsArray(action);
    const processedValues = {};
    if (!fieldsArray) { return false };

    for (let index in fieldsArray) {
      const field = fieldsArray[index];
      const { key, display_name } = field;
      let value = values[key];

      if (typeof value != 'boolean') {
        if (!value) { continue }
      }

      if (typeof value === "object" && !Array.isArray(value) && value !== null) {
        const { value: val } = value;
        value = val;
      }
      if (!processedValues.hasOwnProperty(key)) {
        processedValues[key] = value;
      }
    }
    return processedValues;
  }

  // Check if fields are valid
  // Checking: required
  // Pending: field length for text fields and boxes
  const validatePartnerActionValues = (values = {}) => {
    const fieldsArray = getFieldsArray(action);
    if (!fieldsArray) { return false };

    // Defaults that are missing from the current values
    let hasError = false;

    for (let index in fieldsArray) {
      const field = fieldsArray[index];
      const { key, required, required_keys, default: defaultValue, type } = field;

      const value = values[key];
      let shouldCheck = true;

      if (required_keys) {
        for (let reqKey in required_keys) {
          const keysArray = getSafeArray(required_keys[reqKey]);
          const reqValue = values[reqKey];
          if (!keysArray.includes(reqValue)) {
            shouldCheck = false;
          }
        }
      }
      if (shouldCheck) {
        if (required) {
          if (value == undefined || value == "") {
            if (type == fieldTypes.SWITCH || type == fieldTypes.RADIO) {
              values[key] = false;
              continue;
            }
            if (defaultValue != undefined) {
              values[key] = defaultValue;
              continue;
            }
            console.log(`Failed on ${key} with value: ${value}`);
            return { hasError: true };
          }
        }
      }
    }
    return { values, hasError };
  }

  const submitAction = () => {
    const processedValues = processActionValues(partnerActionValues);
    console.log('Processed values: ', processedValues);
    const validationData = validatePartnerActionValues(processedValues);
    const { hasError } = validationData;
    if (hasError) {
      dispatch(setAlert("One or more fields are required.", alertTypes.error));
      return;
    }
    const { values: newValues } = validationData;
    const params = {
      data: {
        target: action.target,
        partner_id: partnerId,
        company_id: companyId,
        params: {
          action: action.key,
          info: newValues,
        },
      },
    };
    callPartnerAction(params);
  }

  const renderButtons = () => {
    // TODO: Render buttons depending on index position
    if (!screens) { return null };
    const screenTotal = screens.length;

    // If more than 1 screen
    if (screenTotal > 1) {
      // Check position
    } else {
      return (
        <Button
          type="submit"
          color="primary"
          className="mr-1"
          onClick={submitAction}
        >
          Submit
        </Button>
      );
    }
  };

  const modalClassName = () => {
    let modalSize = "partner-action-modal";
    switch (modal_size) {
      case modalSizes.SMALL: {
        modalSize = `${modalSize} small`;
        break;
      }
      case modalSizes.MEDIUM: {
        modalSize = `${modalSize} medium`;
        break;
      }
      case modalSizes.LARGE: {
        modalSize = `${modalSize} large`;
        break;
      }
      case modalSizes.FULLSCREEN: {
        modalSize = "issue-expanded-detail dashboard partner-action-expanded-detail";
        break;
      }
      default:
        break;
    }
    return modalSize;
  };

  // Rendering
  return (
    <BasicModal
      header={action.display_name}
      //customHeaderContent={renderModalTitle()}
      //headerClassName={`modal-header-status grc-modal-header bg-${criticalityClassName || 'light-gray'}`}
      showModal={true}
      toggleModal={() => closeModal()}
      customClassName={modalClassName()}
      backdrop={!expandedMode}
      wrapClassName={
        expandedMode ? "" : ""
      }
      zIndex={expandedMode ? 1002 : 1050}
    >
      {renderScreen(screenIndex)}
      {renderButtons()}
    </BasicModal>
  );
  // TODO: Hacer modal como en grcissuesdetail
  // TODO: Dentro del content cargar todo
};

export default PartnerActionModal;
