import React from 'react';
import { Input } from 'reactstrap';
import SimpleDropdown from '../../layout/SimpleDropdown';

const TextFieldModal = ({ handleParams, field, keyField, params, issue }) => {
  const { extra_fields: extraFields } = issue;
  const booleanOptions = [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false' }
  ]

  const renderInput = () => {
    if (extraFields?.benchmark?.value?.__typename === 'ConfigurationControlValueBoolean') {
      return (
        <SimpleDropdown
          extraClassName={`dropdown-select`}
          placeholder=""
          elements={booleanOptions}
          onChangeCallback={(option) => handleParams(field.key, option.value)}
          selectedElement={
            booleanOptions.find((option) => option?.value === params[field.key])
          }
        />
      );
    }

    return (
      <Input
        className="dark"
        type={field.textFieldtype === 'numeric' ? 'number' : 'text'}
        onChange={(value) => handleParams(field.key, value.target.value)}
        value={params[field.key]}
      />
    );
  }

  return (
    <div className='modal-field textfield-modal' key={keyField}>
      <p className='textfield-title'>{field.title}</p>
      <p className='textfield-subtitle'>{field.subTitle}</p>
      <p className='textfield-inputTitle'>{field.textFieldTitle}</p>
      {renderInput()}
      <p className='textfield-label'>{field.label}</p>
    </div>
  );
};

export default TextFieldModal;
