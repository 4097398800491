import React, { useState, useEffect, useCallback } from 'react'
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Register from '../components/auth/Register';
import BasicAssessment from '../components/assessment/Basic/BasicAssessment';
import makeLoadingState from '../selectors/loadingSelector';
import makeErrorMessage from '../selectors/errorsSelector';
import makeCategoriesIds from '../selectors/assessment/categoriesIdsSelector';
import makePickupQuestionSelector from '../selectors/assessment/pickupQuestionSelector';
import { changeLayout } from '../actions/layout';
import { fetchAnswers } from '../actions/answers';
import { setUserCookies } from "../utils/manageCookies";
import {
    fetchAssessment,
    setCategoriesIds,
    setCategoryIndex,
    setSectionIndex,
    setQuestionIndex,
    selectCategory,
    selectSection,
    selectQuestion
} from '../actions/assessment';

const Assessment = (props) => {
    const {
        isAuthenticated,
        assessment,
        changeLayout,
        fetchAnswers,
        history,
        generatedIds,
        setCategoriesIds,
        categoriesIds,
        selectedCategoryId,
        selectedSectionId,
        selectedQuestionId,
        fetchAssessment,
        selectCategory,
        selectSection,
        selectQuestion,
        setCategoryIndex,
        setSectionIndex,
        setQuestionIndex,
        categoryIndex,
        sectionIndex,
        questionIndex,
        pickupQuestion,
        isLoading,
        checkupFinished,
        freemiumStatus,
        location
    } = props;

    const [assessmentType, setAssessmentType] = useState('basic');
    const [shouldSetQuestionId, setShouldSetQuestionId] = useState(assessmentType !== 'multiple');
    const [shouldPickupQuestion, setShouldPickupQuestion] = useState(!selectedCategoryId || !selectedSectionId || (shouldSetQuestionId && !selectedQuestionId));
    const [shouldFindIndices, setShouldFindIndices] = useState(categoryIndex < 0 || sectionIndex < 0 || (shouldSetQuestionId && questionIndex < 0));
    const [answersRequested, setAnswersRequested] = useState(false);

    const assessmentTypes = {
        basic: <BasicAssessment history={history} />
    }

    const findIndices = useCallback((categoryId, sectionId, questionId) => {
        if (!categoriesIds) return;
        const catIndex = _.findIndex(categoriesIds, ['id', categoryId]) !== -1 ? _.findIndex(categoriesIds, ['id', categoryId]) : 0;

        const sectIndex = categoriesIds[catIndex] && _.findIndex(categoriesIds[catIndex].sectionIds, section => section.id === sectionId) > -1 ? (
            _.findIndex(categoriesIds[catIndex].sectionIds, section => section.id === sectionId)
        ) : (
            0
        );

        const questIndex = categoriesIds[catIndex] && categoriesIds[catIndex].sectionIds[sectIndex] && _.findIndex(categoriesIds[catIndex].sectionIds[sectIndex].questionIds, question => question.id === questionId) > -1 ? (
            _.findIndex(categoriesIds[catIndex].sectionIds[sectIndex].questionIds, question => question.id === questionId)
        ) : (
            0
        );

        setCategoryIndex(catIndex);
        setSectionIndex(sectIndex);
        setQuestionIndex(questIndex);
    }, [
        setCategoryIndex,
        setQuestionIndex,
        setSectionIndex,
        categoriesIds
    ]);

    const pickupLastAnsweredQuestion = useCallback(() => {
        if (!pickupQuestion) return;
        const { categoryId, sectionId, questionId } = pickupQuestion;
        findIndices(categoryId, sectionId, questionId);
    }, [pickupQuestion, findIndices]);

    useEffect(() => {
        if (location?.search) setUserCookies(location?.search);
    }, []);

    useEffect(() => {
        if (checkupFinished || freemiumStatus === 2) {
            changeLayout('vertical');
        } else {
            changeLayout('horizontal');
        }
    }, [changeLayout, checkupFinished]);

    useEffect(() => {
        fetchAssessment();
        setAnswersRequested(false);
    }, [fetchAssessment, setAnswersRequested]);

    useEffect(() => {
        if (assessment.id) {
            fetchAnswers(assessment.id, isAuthenticated);
            setAnswersRequested(true);
        }
    }, [fetchAnswers, isAuthenticated, assessment.id, setAnswersRequested]);

    useEffect(() => {
        setAssessmentType(assessment.type ? assessment.type : 'basic')
    }, [assessment, setAssessmentType]);

    useEffect(() => {
        setShouldSetQuestionId(assessmentType !== 'multiple');
    }, [assessmentType, setShouldSetQuestionId]);

    useEffect(() => {
        setCategoriesIds(generatedIds);
    }, [generatedIds, setCategoriesIds]);

    useEffect(() => {
        setShouldPickupQuestion(!selectedCategoryId || !selectedSectionId || (shouldSetQuestionId && !selectedQuestionId));
    }, [
        selectedCategoryId,
        selectedSectionId,
        selectedQuestionId,
        shouldSetQuestionId
    ]);

    useEffect(() => {
        setShouldFindIndices(categoryIndex < 0 || sectionIndex < 0 || (shouldSetQuestionId && questionIndex < 0));
    }, [
        categoryIndex,
        sectionIndex,
        questionIndex,
        shouldSetQuestionId
    ]);


    useEffect(() => {
        if (!shouldFindIndices || isLoading || !answersRequested) {
            return;
        }
        if (shouldPickupQuestion) {
            pickupLastAnsweredQuestion();
        } else {
            findIndices(selectedCategoryId, selectedSectionId, selectedQuestionId);
        }
    }, [
        shouldFindIndices,
        shouldPickupQuestion,
        pickupLastAnsweredQuestion,
        findIndices,
        selectedCategoryId,
        selectedSectionId,
        selectedQuestionId,
        isLoading,
        answersRequested
    ]);

    useEffect(() => {
        if (shouldFindIndices) {
            return;
        }

        if (shouldSetQuestionId) {
            const isValidQuestion = categoriesIds && categoriesIds[categoryIndex] && categoriesIds[categoryIndex].sectionIds[sectionIndex] && categoriesIds[categoryIndex].sectionIds[sectionIndex].questionIds[questionIndex];
            if (isValidQuestion) {
                selectCategory(categoriesIds[categoryIndex].id);
                selectSection(categoriesIds[categoryIndex].sectionIds[sectionIndex].id);
                selectQuestion(categoriesIds[categoryIndex].sectionIds[sectionIndex].questionIds[questionIndex].id);
            }
        } else {
            const isValidSection = categoriesIds[categoryIndex] && categoriesIds[categoryIndex].sectionIds[sectionIndex];
            if (isValidSection) {
                selectCategory(categoriesIds[categoryIndex].id);
                selectSection(categoriesIds[categoryIndex].sectionIds[sectionIndex].id);
            }
        }

    }, [
        shouldFindIndices,
        shouldSetQuestionId,
        selectCategory,
        selectSection,
        selectQuestion,
        categoriesIds,
        questionIndex,
        categoryIndex,
        sectionIndex
    ]);

    return (
        <div className="assessment">
            <Register />
            {assessmentTypes[assessmentType]}
        </div>
    )
}

const makeMapStateToProps = () => {
    const getLoadingState = makeLoadingState(['ASSESSMENT', 'ANSWERS']);
    const getErrorMessage = makeErrorMessage(['ASSESSMENT', 'ANSWERS']);
    const getCategoriesIds = makeCategoriesIds();
    const getPickupQuestion = makePickupQuestionSelector();
    const mapStateToProps = (state) => {
        return {
            isAuthenticated: state.auth.isAuthenticated,
            isLoading: getLoadingState(state) || state.assessment.isLoading,
            errorMessage: getErrorMessage(state),
            answers: state.answers,
            assessment: state.assessment.assessment,
            selectedCategoryId: state.assessment.selectedCategoryId,
            selectedSectionId: state.assessment.selectedSectionId,
            selectedQuestionId: state.assessment.selectedQuestionId,
            categoryIndex: state.assessment.navigation.categoryIndex,
            sectionIndex: state.assessment.navigation.sectionIndex,
            questionIndex: state.assessment.navigation.questionIndex,
            categoriesIds: state.assessment.categoriesIds,
            generatedIds: getCategoriesIds(state),
            pickupQuestion: getPickupQuestion(state),
            checkupFinished: state.answers.showReportCard,
            freemiumStatus: state.auth.company?.freemium
        }
    }
    return mapStateToProps
}
const mapDispatchToProps = {
    fetchAssessment,
    changeLayout,
    fetchAnswers,
    setCategoriesIds,
    setCategoryIndex,
    setSectionIndex,
    setQuestionIndex,
    selectCategory,
    selectSection,
    selectQuestion
}

export default withRouter(connect(
    makeMapStateToProps,
    mapDispatchToProps
)(Assessment));

