import React from 'react';
import { Col, Row } from 'reactstrap';
import Pagination from '../layout/CustomPagination';

import SearchBar from '../layout/filters/SearchBar';

const DevicesToolbar = ({
    refs,
    divTopPositions,
    searchTerm,
    onSearchCallback,
    currentPage,
    pageCount,
    onPageChange
}) => {
    const stickyDivIndex = 1;
    return (
        <div
            ref={refs[stickyDivIndex]}
            className="sticky-element bg-violet pt-3"
            style={{ top: divTopPositions[stickyDivIndex] }}
        >
            <Row className="justify-content-between">
                <Col sm="9">
                    <SearchBar
                        placeholder="Search a device"
                        value={searchTerm}
                        callback={onSearchCallback}
                    />
                </Col>
                <Col sm={3}>
                    <Pagination
                        currentPage={currentPage}
                        pageCount={pageCount}
                        onPageChange={onPageChange}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default DevicesToolbar;