import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NavigationTextRow from '../../layout/NavigationTextRow';
import SearchableInfiniteTable from '../../CustomTable/SearchableInfiniteTable';
import { buildLogsTextRow } from '../../../utils/automationHelper';
import { onLogDetailUnmounted, setLogDetail, setRuleTitles } from '../../../actions/automation';
import API from '../../../api';
import { logMessageTemplate } from '../../../constants/tableTemplates';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import { useHistory, useParams } from 'react-router-dom';
import { setAlert } from '../../../actions/alerts';
import useCustomQuery from '../../../hooks/useCustomQuery';

//  Component
const LogDetail = (props) => {
  //  Initializing APIs
  const { RuleAPI } = API;

  //  Watching redux store
  const selectedRuleId = useSelector((state) => state.automation.selectedRuleId);
  const logId = useSelector((state) => state.automation.selectedLog.id);
  const logName = useSelector((state) => state.automation.selectedLog.name);
  const titleArray = useSelector((state) => state.automation.selectedLog.titleArray);
  const subtitleArray = useSelector((state) => state.automation.selectedLog.subtitleArray);

  //  Component state
  const [logTitleArray, setLogTitleArray] = useState([]);
  const [logSubtitleArray, setLogSubtitleArray] = useState([]);

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const history = useHistory();
  const { rule_id, log_id } = useParams();

  //  Function to get rule detail
  const fetchRuleDetail = async (rule_id, titleArray) => {
    if (titleArray) { return null; }
    return await RuleAPI.handlers.getRuleDetail(rule_id);
  };

  //  Getting rule detail from API
  const {
    data: ruleDetailResponse,
    error: ruleDetailError,
  } = useCustomQuery(
    [RuleAPI.keys.getRuleDetail, rule_id, titleArray],
    ({ queryKey }) => fetchRuleDetail(queryKey[1], queryKey[2]),
  );

  //  Function to get log group
  const fetchLogGroup = async (id, name) => {
    if (name) { return null; }
    return await RuleAPI.handlers.getLogGroup(id);
  }

  const {
    data: logGroupResponse,
    error: logGroupError,
  } = useCustomQuery(
    [RuleAPI.keys.getLogGroup, log_id, logName],
    ({ queryKey }) => fetchLogGroup(queryKey[1], queryKey[2]),
  );

  //  Function to move to automation rules
  const moveToAutomationRules = () => {
    history.push('/automation')
  };

  //  Component did mount
  useEffect(() => {
    return () => { dispatch(onLogDetailUnmounted()); }
  }, []);

  //  Watching title array changes
  useEffect(() => {
    if (!titleArray || !logName) { return; }
    const newArray = [...titleArray, logName];
    const actionsArray = [
      () => history.push('/automation'),
      () => history.push(`/automation/rule/${rule_id}/logs`),
    ];
    const arrayToSet = buildLogsTextRow(newArray, actionsArray);
    setLogTitleArray(arrayToSet);
  }, [titleArray, logName]);

  //  Watching subtitle array changes
  useEffect(() => {
    if (!subtitleArray || !logName) { return; }
    const newArray = [...subtitleArray, logName];
    const arrayToSet = buildLogsTextRow(newArray, []);
    setLogSubtitleArray(arrayToSet);
  }, [subtitleArray, logName]);

  //  Watching rule detail api response
  useEffect(() => {
    if (!ruleDetailResponse) { return; }
    const { rule: ruleSummary } = ruleDetailResponse;
    if (!ruleSummary) { return; }
    const {
      rule_name,
      action_name,
      partner_name,
    } = ruleSummary;

    // Set titleArray
    const ruleLabel = `Action execution Log[${rule_id}]`;
    const newTitleArray = buildLogsTextRow(['Automation Rules', ruleLabel], [moveToAutomationRules]);
    const titles = newTitleArray.map(({ label }) => label);

    // Set subtitle Array
    const newSubtitleArray = buildLogsTextRow([rule_name, partner_name, action_name]);
    const subtitles = newSubtitleArray.map(({ label }) => label);

    dispatch(setRuleTitles(titles, subtitles));
  }, [ruleDetailResponse]);


  //  Watching log group api response
  useEffect(() => {
    if (!logGroupResponse) { return; }
    dispatch(setLogDetail(logGroupResponse.log_group));
  }, [logGroupResponse]);

  //  Wacthing api error
  useEffect(() => {
    if (!ruleDetailError && !logGroupError) { return; }
    dispatch(setAlert('Error getting log details', 'danger'));
    moveToAutomationRules();
  }, [logGroupError, ruleDetailError]);

  //  Function to get log messages
  const getLogMessages = async (filters) => {
    const ruleId = selectedRuleId || rule_id;
    const selectedLogId = logId || log_id;
    const response = await RuleAPI.handlers.getLogMessages(ruleId, selectedLogId, filters);
    return response;
  };

  //  Function to renderTableTitle
  const renderTableTitle = (totalMessages) => {
    return (
      <div className="last-exec-container">
        <div className="bx bx-cog last-exec-icon" />
        <div className="last-exec-label">
          {`${totalMessages} events`}
        </div>
      </div>
    );
  };

  //  Rendering header
  const renderHeader = () => {
    return (
      <div className="rule-logs-header">
        <NavigationTextRow
          elements={logTitleArray}
          delimiter=">"
          rowExtraClassName="title-row"
        />
        <NavigationTextRow
          elements={logSubtitleArray}
          delimiter=":"
          rowExtraClassName="subtitle-row"
        />
      </div>
    );
  };

  //  Rendering
  return (
    <div className="rule-logs-container">
      {renderHeader()}
      <SearchableInfiniteTable
        tableContainerClassName="rule-logs-table-container"
        tableClassName="rule-logs-table"
        headerClassName="rule-logs-table-header"
        rowClassName="rule-logs-table-row"
        searchbarClassName="rule-logs-search-bar"
        columnsTemplate={logMessageTemplate}
        apiFunction={getLogMessages}
        apiKey={RuleAPI.keys.getLogMessages}
        extraKeys={[selectedRuleId || rule_id, logId || log_id]}
        renderTitle={renderTableTitle}
        expandableRow={true}
        emptyConfig={{
          message: 'There are no messages from the API',
          messageClassName: 'empty-message',
        }}
      />
    </div>
  );
};

export default LogDetail;
