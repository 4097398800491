import { recycleBinEntities } from '../constants/common';
import { HARD_DELETE_ACTION, PRIVILEGE_SOURCES } from '../constants/privileges';
import useCanAbility from './useCanAbility';

const useCanHardDeleteElements = (params = {}) => {
  //  Parsing params
  const { entity = recycleBinEntities.devices } = params;

  //  Hooks
  const ability = useCanAbility();

  //  Needed variables
  const canDeleteDevices = ability.can(HARD_DELETE_ACTION, PRIVILEGE_SOURCES.DEVICES.GENERAL);
  const canDeleteIssues = ability.can(HARD_DELETE_ACTION, PRIVILEGE_SOURCES.ISSUES.GENERAL);

  return {
    canDelete: entity === recycleBinEntities.devices ? canDeleteDevices : canDeleteIssues,
  }
};

export default useCanHardDeleteElements;
