import React from 'react';
import IconTooltip from '../../components/dashboard/tooltips/IconTooltip';
import DomainActions from '../../components/profile/domains/DomainActions';
import ScanStatusBadge from '../../components/profile/domains/ScanStatusBadge';
import ScanSwitch from '../../components/profile/domains/ScanSwitch';
import { formatDate, getTimeString } from '../../utils/helpers';
import { cellTypes, headerTypes } from "../tableTemplates";

// Function to render date and time
const getDateAndTime = (date) => {
  return date && !isNaN(new Date(date)) ? `${formatDate(date)} at ${getTimeString(date)}` : '-'
}

// Function to render Domain Name and tooltip
const renderDomainName = (row) => {
  const tooltipText = "Main domain";
  const isMain = row?.domain?.is_master_domain;
  return (
    <div className={`domain-name-container ${isMain ? 'has-icon' : ''}`}>
      <span className='name-container'>{row?.domain?.name}</span>
      {isMain && (
        <IconTooltip
          iconClassName="bx bx-star ml-2"
          tooltipText={tooltipText}
        />
      )}
    </div>
  )
}

//  Template for domains
export const domainsTableTemplate = [
  {
    label: 'Domain',
    allowSorting: true,
    allowSelect: true,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.SELECTABLE_CUSTOM,
    headerClassName: 'header-cell header-text-cell extra-large-cell',
    cellClassName: 'regular-cell extra-large-cell bottom-padded-cell ellipsed-cell',
    entry: 'public_id',
    customRender: (row) => renderDomainName(row),
    customParams: { objectName: 'domain', valueName: 'public_id' },
  },
  {
    label: 'Actions',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    entry: 'public_id',
    headerClassName: 'header-cell header-text-cell medium-cell',
    cellClassName: 'regular-cell medium-cell',
    customRender: (row) => <DomainActions bulkActionDomainIds={null} domainPublicId={row?.public_id} isMaster={row?.domain?.is_master_domain} startTime={row?.domain?.start_time}/>,
    customParams: { objectName: 'domain', valueName: 'public_id' },
  },
  {
    label: 'Schedule',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: 'header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell bottom-padded-cell custom-text-cell',
    entry: 'start_time',
    customRender: (row) => getDateAndTime(row?.start_time),
    customParams: { objectName: 'domain', valueName: 'start_time' },
  },
  {
    label: 'Last scan',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: 'header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell bottom-padded-cell custom-text-cell',
    entry: 'last_scan_date',
    customRender: (row) => getDateAndTime(row?.last_scan_date),
    customParams: { objectName: 'domain', valueName: 'last_scan_date' },
  },
  {
    label: 'Status',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: 'header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell bottom-padded-cell',
    entry: 'last_scan_status',
    customRender: (row) => <ScanStatusBadge statusId={row?.last_scan_status}/>,
    customParams: { objectName: 'domain', valueName: 'last_scan_status' }
  },
  {
    label: 'Error code',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell bottom-padded-cell',
    entry: 'last_scan_message',
  },
  {
    label: 'Scan',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    headerClassName: 'header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell bottom-padded-cell',
    entry: 'enabled',
    customRender: (row) => <ScanSwitch domainPublicId={row?.domain?.public_id} isEnabled={row?.enabled} startTime={row?.domain?.start_time}/>,
    customParams: { objectName: 'domain', valueName: 'enabled' }
  },
];
