import React from 'react';
import Dashboard from '../components/dashboard/Dashboard';
import PartnerList from '../components/partners/Partners';

const Solutions = (props) => {

  return (
    <Dashboard title="Solutions">
      <PartnerList />
    </Dashboard>
  )
};

export default Solutions;
