import React, {useState, useEffect} from "react";
import { useSelector } from 'react-redux';
import { Card, CardBody } from "reactstrap";
import DashboardRow from "../../common/dashboard/DashboardRow";

import API from '../../../../api';
import companyIdSelector from '../../../../selectors/companyIdSelector';
import DashboardRollup from '../../../../components/app-v4/my-cyvatar/dashboard/DashboardRollup'
import DashboardWidgetContainer from '../../../../components/app-v4/common/dashboard/DashboardWidgetContainer'
import { rollUpWidgetsIds } from '../../../../constants/common';
import { issueTypes } from '../../../../constants/issues';
import TrendChartWrapper from '../../../../components/dashboard/charts/tendency-chart/TrendChartWrapper';
import { issuesCriticalityConfigColor } from '../../../../constants/criticality';
import { tendencyChartPointsByTimeUnit } from '../../../../constants/tendencyChart';
import { criticalityColorsByLabel } from '../../../../constants/issues';

import useMemoizedDispatch from '../../../../hooks/useMemoizedDispatch';
import { setIssuesMultipleFilterGroup } from '../../../../actions/issues';
import filterTypes from '../../../../constants/filterTypes';
import { createMultipleFiltersGroupPayload } from '../../../../utils/filterHelpers';
import { Redirect, useLocation } from 'react-router-dom';
import { arrangeStatusData, arrangeCriticalityData } from '../../../../utils/dashboardHelpers';
import useUserType from "../../../../hooks/useUserType";

const TaskManagerWidgetGroup = () => {

  //  Component state
  const [moveToIssues, setMoveToIssues] = useState(false);

  //  Initializing APIs
  const { GrcIssueAPI } = API;

  // Getting needed info from redux store
  const companyId = useSelector(state => companyIdSelector(state));
  const selectedSubTabValue = useSelector((state) => state?.navigation?.selectedSubtab?.value);

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const location = useLocation();
  const userType = useUserType();

  //  Function to handle roll up click
  const onRollUpClick = (filter, widgetType, isTotal = false) => {
    if(!isTotal) {
      // Clicking a specific legend filter on dashboard
      const filterLabel = widgetType === rollUpWidgetsIds.STATUS ? 'status' : 'severity';
      const label = widgetType === rollUpWidgetsIds.STATUS ? filter?.label : `Open, ${filter?.label}`;
      const idAsInt = parseInt(filter?.id);
      const values = [idAsInt];
      const activeValues = [
        {
          filterName: filterLabel,
          value: idAsInt,
          label,
          type: filterTypes.multiple,
        }
      ];
      dispatch(setIssuesMultipleFilterGroup(filterLabel, values, activeValues, true, issueTypes.GRC));
    } else if (filter) {
      // Clicking the total number
      const filterName = widgetType === rollUpWidgetsIds.STATUS ? 'status' : 'severity';
      const addOpenLabel = widgetType === rollUpWidgetsIds.STATUS ? false : true;
      const payload = createMultipleFiltersGroupPayload(filter, filterName, addOpenLabel)
      dispatch(setIssuesMultipleFilterGroup(filterName, payload?.values, payload?.activeValues, true, issueTypes.GRC));
    }

    //  Using this flag to move to issues to avoid APEX charts crashing
    setMoveToIssues(true);
  };

  const callArrangeCriticalityData = (rollupData) => {
    return arrangeCriticalityData(rollupData, 'Open Tasks by Criticality',issueTypes.GRC);
  };

  const callArrangeStatusData = (rollupData, sumAll) => {
    return arrangeStatusData(rollupData, sumAll, false,issueTypes.GRC, userType, null);
  };

  const renderTaskRedirect = () => {
    if(!moveToIssues) return null;
    return (
      <Redirect to="/dashboard/task-manager" />
    )
  }

  return (
    <>
      {renderTaskRedirect()}
      <DashboardRow
        title="Task Manager"
        linkText="See all"
        linkUrl="/dashboard/task-manager"
      >
        <DashboardWidgetContainer
          containerClassName="dashboard-widget-container"
          hasMessage
          message="All your open tasks by criticality"
        >
          <DashboardRollup
            sm={12}
            lg={12}
            sumAll={true}
            allowAllCompanies={true}
            company_id={companyId}
            solution_id={selectedSubTabValue}
            widgetType={rollUpWidgetsIds.CRITICALITY}
            containerClassName={`dashboard-rollup-container`}
            cardClassName="h-100 mb-3 mb-md-0 task-criticality-rollup"
            callback={callArrangeCriticalityData}
            handleRollupClick={onRollUpClick}
            addTotalFilter={!location?.pathname?.includes("/dashboard/task-manager")}
            issueType={issueTypes.GRC}
            customEmptyLabel="Create some tasks to see more."
            customEmptyLabelClassName="no-report-short-text"
          />
        </DashboardWidgetContainer>
        <DashboardWidgetContainer
          containerClassName="dashboard-widget-container"
          hasMessage
          message="The current status of your tasks"
        >
          <DashboardRollup
            sm={12}
            lg={12}
            sumAll={true}
            allowAllCompanies={true}
            company_id={companyId}
            solution_id={selectedSubTabValue}
            widgetType={rollUpWidgetsIds.STATUS}
            containerClassName={`dashboard-rollup-container my-cyvatar-task-status-rollup`}
            cardClassName="h-100 mb-3 mb-md-0"
            callback={callArrangeStatusData}
            handleRollupClick={onRollUpClick}
            addTotalFilter={!location?.pathname?.includes("/dashboard/task-manager")}
            issueType={issueTypes.GRC}
            customEmptyLabel="Create an actionable to-do"
            customEmptyLabelClassName="no-report-short-text"
          />
        </DashboardWidgetContainer>

        <DashboardWidgetContainer
          containerClassName="dashboard-widget-container"
          hasMessage
          message="How your tasks are trending over time"
        >
          <Card className="grc-trend-chart-card summary mb-0">
            <CardBody>
              <TrendChartWrapper
                isSummary={true}
                title="Task Trend by Criticality"
                objects="tasks"
                emptyTitle="All quiet in the CySO front"
                emptySubtitle="Nothing to report so far :)"
                pointsColorConfig={issuesCriticalityConfigColor}
                labelColorConfig={criticalityColorsByLabel}
                pointsCountConfig={tendencyChartPointsByTimeUnit}
                apiKey={GrcIssueAPI.keys.getGrcTrendData}
                apiFunction={GrcIssueAPI.handlers.getGrcTrendData}
                useLocalTimeFilter={true}
              />
            </CardBody>
          </Card>
        </DashboardWidgetContainer>
      </DashboardRow>
    </>
  );
};

export default TaskManagerWidgetGroup;
