import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import InfiniteDropdown from '../../layout/InfiniteDropdown';
import SimpleMultipleDropdown from '../../layout/SimpleMultipleDropdown';
import SearchBar from '../../layout/filters/SearchBar';
import API from '../../../api';
import {
  parseCompaniesToOptions,
  getSafeArray,
  mapSolutionsToOptions,
} from '../../../utils/helpers';
import { getIssueModalOptions } from '../../../actions/issues';
import { issueTypes } from '../../../constants/issues';
import { mapIssuesOptionsToSelectOptions } from '../../../utils/issuesHelpers';
import { shouldShowPartnerFilters } from '../../../utils/groupHelpers';
import issuesSimpleOptionsSelector from '../../../selectors/IssuesSimpleOptionsSelector';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import useUserType from '../../../hooks/useUserType';

const GroupsManagerFilters = (props) => {
  //  Parsing props
  const {
    setFilters,
    setTextSearch,
    filters = {},
    partner_configs = [],
    togglePartnerFilters = () => { },
  } = props;

  //  Watching redux state
  const solutions = useSelector((state) => state?.issues?.modalOptions.solutions);
  const criticalities = useSelector((state) => issuesSimpleOptionsSelector(state, issueTypes.VULNERABILITIES, 'criticalities', true));

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();
  const userType = useUserType();

  //  Initializing API
  const { CompanyAPI, PartnerAPI } = API;

  //  Function to get partners
  const getPartners = async (filters) => {
    const partnerParams = { company_id: null, filters, include_freemium: true }
    const response = await PartnerAPI.handlers.getActivePartners(partnerParams);
    return { ...response };
  };

  //  Component did mount (requesting modal options)
  useEffect(() => {
    dispatch(getIssueModalOptions(null, issueTypes.VULNERABILITIES, userType));
  }, []);

  //  Function to handle filters click
  const onPartnerFiltersClick = () => {
    togglePartnerFilters(true);
  }

  //  Function to render partner filters button
  const renderPartnerFiltersButton = () => {
    if (!shouldShowPartnerFilters(partner_configs)) { return null; }
    return (
      <Button
        className="btn btn-light text-dark partner-filters-button"
        type="submit"
        onClick={onPartnerFiltersClick}
      >
        Partner Filters
      </Button>
    );
  };

  //  Rendering
  return (
    <div className="groups-filters-container">
      <div className="dropdowns-section">
        <SimpleMultipleDropdown
          emptyLabel="Criticality"
          extraClassName="dropdown-filter"
          elements={mapIssuesOptionsToSelectOptions(criticalities)}
          onChange={(option) => setFilters('severity', option, true)}
          isMultiSelect={true}
          selectedElements={getSafeArray(filters?.severity).map((id) => ({ value: id }))}
        />
        <InfiniteDropdown
          emptyLabel="Members"
          extraClassName="dropdown-filter"
          errorMessage="Error getting members"
          apiFunction={CompanyAPI.handlers.getImpersonationList}
          apiKey={CompanyAPI.keys.getImpersonationList}
          parseElementsToOptions={parseCompaniesToOptions}
          onChange={(option) => setFilters('company_id', option, true)}
          isMultiSelect={true}
          selectedElements={getSafeArray(filters?.company_id).map((id) => ({ value: id }))}
        />
        <SimpleMultipleDropdown
          emptyLabel="Solutions"
          extraClassName="dropdown-filter"
          elements={mapSolutionsToOptions(solutions)}
          onChange={(option) => setFilters('solution_id', option, true)}
          isMultiSelect={true}
          selectedElements={getSafeArray(filters?.solution_id).map((id) => ({ value: id }))}
        />
        <InfiniteDropdown
          emptyLabel="Partners"
          extraClassName="dropdown-filter"
          errorMessage="Error getting partners"
          apiFunction={getPartners}
          apiKey={PartnerAPI.keys.getActivePartners}
          parseElementsToOptions={parseCompaniesToOptions}
          onChange={(option) => setFilters('partner_id', option, true)}
          isMultiSelect={true}
          selectedElements={getSafeArray(filters?.partner_id).map((id) => ({ value: id }))}
        />
        {renderPartnerFiltersButton()}
      </div>
      <div className="search-section">
        <SearchBar
          useSimpleContainer
          customClassName={`search-box mr-2 mb-2 d-inline-block rule-logs-search-bar`}
          placeholder="Search"
          value={filters?.text_to_search}
          callback={(value) => setTextSearch(value)}
        />
      </div>
    </div>
  );
};

export default GroupsManagerFilters;
