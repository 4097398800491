import React from 'react';
import CompanyAttachmentsTableActions from "../../components/profile/attachments/CompanyAttachmentsTableActions";
import { formatDate, getGMTOffset, getTimeString } from "../../utils/helpers";
import { cellTypes, headerTypes } from "../tableTemplates";

// Function to render attachment Name
const renderAttachmentName = (row) => {
  return (row?.name);
};

// Function to render attachment type
const renderAttachmentType = (type = ' . ') => {
  const tokens = type.split('.');
  const attachmentType = tokens[tokens?.length - 1];
  return attachmentType.toUpperCase();
}

//  Template for attachments
export const attachmentsTableTemplate = [
  {
    label: 'File name',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell extra-large-cell',
    cellClassName: 'regular-cell extra-large-cell ellipsed-cell',
    entry: 'name',
    customRender: (row) => renderAttachmentName(row),
    customParams: { objectName: 'attachment', valueName: 'name' },
  },
  {
    label: 'File type',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    entry: 'type',
    headerClassName: 'header-cell header-text-cell small-cell',
    cellClassName: 'regular-cell small-cell custom-text-cell',
    customRender: ({attachment}) => renderAttachmentType(attachment?.name),
    customParams: { objectName: 'attachment', valueName: 'type' },
  },
  {
    label: 'Creation date',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    entry: 'created_at',
    headerClassName: 'header-cell header-text-cell large-cell',
    cellClassName: 'regular-cell large-cell custom-text-cell',
    customRender: ({attachment}) => formatDate(attachment?.created_at) + ' at ' + getTimeString(attachment?.created_at, false) + getGMTOffset(),
    customParams: { objectName: 'attachment', valueName: 'created_at' },
  },
  {
    label: 'Uploaded by',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell medium-cell',
    cellClassName: 'regular-cell medium-cell',
    entry: 'user_name',
  },
  {
    label: 'Times downloaded',
    allowSorting: true,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.TEXT,
    headerClassName: 'header-cell header-text-cell medium-cell',
    cellClassName: 'regular-cell medium-cell',
    entry: 'times_downloaded',
  },
  {
    label: 'Actions',
    allowSorting: false,
    allowSelect: false,
    headerType: headerTypes.TEXT,
    cellType: cellTypes.CUSTOM,
    entry: 'public_id',
    headerClassName: 'header-cell header-text-cell small-cell',
    cellClassName: 'regular-cell small-cell',
    customRender: (file) => <CompanyAttachmentsTableActions file={file?.file}/>,
    customParams: { objectName: 'file', valueName: '' },
  },
];