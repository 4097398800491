import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'reactstrap';
import moment from 'moment';
import DashboardRow from '../../app-v4/common/dashboard/DashboardRow';
import RiskScore from './RiskScore';
import API from '../../../api';
import useCustomQuery from '../../../hooks/useCustomQuery';
import companyIdSelector from '../../../selectors/companyIdSelector';
import LoadingContent from '../../layout/LoadingContent';
import { riskReconSecurityDomains, riskReconIndustryTrends } from '../../../constants/riskReconSecurityDomains';
import RiskRating from './RiskRating';
import RiskScoreEmpty from './RiskScoreEmpty';

const RiskReconRow = () => {
  const { DashboardAPI } = API;
  // Getting needed info from redux store
  const companyId = useSelector((state) => companyIdSelector(state));

  // Calling API for risk recon data
  const {
    data: riskReconData,
    error: errorData,
    isLoading: isLoading,
    refetch: refetchRiskReconScore,
  } = useCustomQuery(
    [DashboardAPI.keys.getRiskReconData, { company_id: companyId }],
    ({ queryKey }) => DashboardAPI.handlers.getCompanyRiskScore(queryKey[1])
  );

  useEffect(() => { }, [riskReconData]);

  const retryRequest = () => {
    refetchRiskReconScore();
  }

  const getErrorMessage = () => {
    return errorData ? 'Sorry, we are unable to load your risk score at this time.' : null;
  }

  const renderRiskScore = () => {
    if (!riskReconData) return null;
    const { success, ratings } = riskReconData;
    const score = success ? ratings.rating_numeric : riskReconSecurityDomains.rating_numeric;
    const grade = success ? ratings.rating_v2 : riskReconSecurityDomains.rating_v2;
    const rawLastUpdate = success ? ratings.analysis_ts : new Date();
    const lastUpdate = moment(rawLastUpdate).format("MMM D, YYYY");

    return <RiskScore score={score} grade={grade} lastUpdate={lastUpdate} />;
  }

  const renderRatings = () => {
    if (!riskReconData) return null;
    const { success, ratings, industry_trends: trends } = riskReconData;
    const domainRatings = success ? ratings.security_domain_ratings : riskReconSecurityDomains.security_domain_ratings;
    const industryTrends = success ? trends : riskReconIndustryTrends;
    return <RiskRating domainRatings={domainRatings} industryTrends={industryTrends} />;
  }

  const openContactUs = () => {
    window.open(process.env.REACT_APP_HELP_LINK, '_blank');
  }

  return (
    <div className='risk-recon-row-container'>
      <div className='risk-recon-widget'>
        <DashboardRow
        title="External Risk Exposure"
        dashboardBodyClassName="px-0 pb-4"
        displayInfoIcon={true}
        infoLabel="Learn more about RiskRecon powered by Mastercard!"
        linkOnClick={openContactUs}
      >
          <LoadingContent
            isLoading={isLoading}
            errorMessage={getErrorMessage()}
            errorButtonCallback={retryRequest}
          >
            <Col lg={4} className="px-2 pb-2 pb-lg-0">
              {renderRiskScore()}
            </Col>
            <Col lg={8} className="px-2 pb-2 pb-lg-0">
              {renderRatings()}
            </Col>
            { !riskReconData?.success && <RiskScoreEmpty />}
          </LoadingContent>
        </DashboardRow>
      </div>
    </div>
  );
};

export default RiskReconRow;
