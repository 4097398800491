import _ from 'lodash';
import moment from 'moment-timezone'
import { createSelector } from 'reselect';

const getCompany = state => state.auth.company;

const makeFreemiumValidations = () => {
    return createSelector ( 
        [getCompany],
        (company) => {
          const { showDemoData, requestedScan, lastRequestScan, lastScan } = company;
          let nextScanAvailable = false;
          let daysRemaining = null;
      
          if (lastRequestScan) {
            let nextScanDate = new Date(lastRequestScan);
            nextScanDate.setDate(nextScanDate.getDate() + 31);
            nextScanAvailable = nextScanDate < new Date();
            
            const currentDate = moment(new Date());
            const scanDate = moment(new Date(nextScanDate));
            const difference = currentDate.diff(scanDate, 'days');
      
            if (difference < 0) {
              daysRemaining = difference * -1;
            }
          }

          return {
            lastScanIsValid: true,
            nextScanAvailable,
            daysRemaining,
            renderSampleWidget: !showDemoData && !lastScan,
            renderSampleData: showDemoData || lastScan,
          }
        }
    );
}
export default makeFreemiumValidations;
