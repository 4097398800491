import React from 'react'
import { Card, CardBody } from 'reactstrap'
import IconTooltip from '../../../dashboard/tooltips/IconTooltip';

const DashboardCard = (props) => {
    const {
        title="",
        backgroundColor="violet",
        children,
        onClick,
        style,
        customTitleClassName = 'dashboard-card-title',
        customHeaderComponent,
        showInformationIconTooltip = false,
        tooltipTargetId = '',
        iconTooltipContent,
        tooltipCustomClassName,
    } = props
    
    const renderHeader = () => {
        if(!title) return null;
        return (
            <div className={customTitleClassName}>
              {title}
              <div className='header-component-container'>
                {customHeaderComponent}
                {showInformationIconTooltip ? (
                  <IconTooltip
                    targetId={tooltipTargetId}
                    iconClassName="bx bx-help-circle"
                    tooltipCustomClassName={tooltipCustomClassName}
                    tooltipText={iconTooltipContent}
                  />
                ) : null}
              </div>
            </div>
        )
    }

  const handleOnClick = () => {
    if (!onClick || typeof (onClick) !== 'function') { return; }
    onClick();
  }

  return (
    <Card
        color={backgroundColor}
        className={"dashboard-card"}
        onClick={handleOnClick}
        style={style}
    >
        <CardBody>
            {renderHeader()}
            {children}
        </CardBody>
    </Card>
  )
}

export default DashboardCard
