import React from 'react';
import { Row } from 'reactstrap';
import CustomFormField from './CustomFormField';
import { wrapperTypes } from '../../constants/formTemplates';

const CustomForm = (props) => {
  //  Parsing props
  const {
    formTemplate = {},
    defaultValues = {},
    children, // Buttons, labels, etc
    onSubmit,
  } = props;

  //  Function to render form section
  const renderFormSection = (sectionType, fields, sectionIndex) => {
    //  Getting array of dom fields
    const DOMFields = fields.map((field, index) => {
      return (
        <CustomFormField
          key={`field-${index}`}
          fieldTemplate={field}
          defaultValues={defaultValues}
        />
      );
    });
    //  Wrapping fields
    if (sectionType === wrapperTypes.ROW) {
      return (
        <Row key={`row-field-${sectionIndex}`}>
          {DOMFields}
        </Row>
      );
    }
    //  Returning just fields if no wrapper needed
    return DOMFields;
  };

  //  Function to render body
  const renderFormBody = () => {
    return Object.keys(formTemplate).map((key, index) => {
      const value = formTemplate[key];
      return renderFormSection(key, value, index);
    });
  };

  //  Rendering
  return (
    <form
      onSubmit={(event) => onSubmit(event)}
    >
      {renderFormBody()}
      {children}
    </form>
  );
};

export default CustomForm;
