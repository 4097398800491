import React from 'react';
import { getSafeArray } from '../../../../utils/helpers';
import PartnerFilterRow from './PartnerFilterRow';

const PartnerFilters = (props) => {
  //  Parsing props
  const {
    filters = {},
    partner_configs = [],
    onFilterChange = () => { },
  } = props;

  //  Function to render rows
  const renderRows = () => {
    //  Getting configs with filters only
    const configsWithFilters = partner_configs?.filter((config) => {
      return getSafeArray(config?.filters)?.length > 0;
    });

    //  Rendering rows
    return configsWithFilters?.map((partner, index) => {
      return (
        <PartnerFilterRow
          key={index}
          filters={filters}
          partner={partner}
          onFilterChange={onFilterChange}
        />
      );
    });
  };

  //  Rendering
  return (
    <div className="partner-filters-container">
      {renderRows()}
    </div>
  );
};

export default PartnerFilters;
