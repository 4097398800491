import moment from 'moment-timezone';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { initializeCyValueFilters, normalizeCyvalueData } from '../constants/cyValueData';
import companyIdSelector from '../selectors/companyIdSelector';
import API from '../api';
import { getApiErrorMessage } from '../utils/helpers';
import { adjustFilters, getPointsForCurrentRange, shouldAdjustRanges } from '../utils/timeChartHelpers';
import useCustomQuery from './useCustomQuery';
import { isSameDate } from '../utils/dateFormatter';
import solutionsStyle from '../constants/solutionsStyle';
import useTimeFilters from './useTimeFilters';

const useIcarmData = (pointsConfig = {}, solutionId = '' ) => {
    //  Initializing APIs
    const { DashboardAPI } = API;

    // Getting needed info from redux store
    const company_id = useSelector(state => companyIdSelector(state));
    const selected_solution_id = useSelector((state) => state?.issues?.filters?.solution_id) || null;

    //  Watching time filters changes
    const { timeFilters, mutateTimeFilters } = useTimeFilters(false, pointsConfig);

    // Hook state
    const [solutionsData, setSolutionsData] = useState(null);
    const [currentThemeColor, setCurrentThemeColor] = useState(solutionsStyle['DEFAULT']);
    const [solutionFilterChanged, setSolutionFilterChanged] = useState(false);
    const [retryEnabled, setRetryEnabled] = useState(false);
    const [manualRefresh, setManualRefresh] = useState(false);

    // Hooks
    //  Calling API for data
    const {
        data: cyvalueData,
        error: cyvalueError,
        isLoading: cyvalueLoading,
        refetch: refetchCyvalue,
    } = useCustomQuery(
        [DashboardAPI.keys.getCyValueData, { ...timeFilters, company_id, solution_id: (solutionId!=='') ? solutionId : selected_solution_id }],
        ({ queryKey }) => DashboardAPI.handlers.getCyValueData(queryKey[1]),
    );

    // Component variables

    //  Constant to know if the data requested has overall score
    const hasOverallScore = useMemo(() => {
        if (!solutionsData) { return false; }
        const { overall_score } = solutionsData;
        return (overall_score === null) ? false : true;
    }, [solutionsData]);

    const hasZeroScore = useMemo(() => {
        if (!solutionsData) { return false; }
        const { overall_score } = solutionsData;
        return (overall_score === 0);
    }, [solutionsData]);

    //  Constanst to know if the goal has been surprassed
    const hasSurpassedGoal = useMemo(() => {
        if (!solutionsData || !hasOverallScore) { return false; }
        const { cyvalue_reached_date } = solutionsData;
        return (cyvalue_reached_date !== null);
    }, [solutionsData, hasOverallScore, hasZeroScore]);

    //  Constant to know if we have a freemium acocunt
    const isFreemium = useMemo(() => {
        if (!solutionsData) { return true; }
        return (!hasOverallScore && !hasZeroScore && !solutionsData.hasNewSolution);
    }, [hasOverallScore, hasZeroScore, solutionsData]);

    //  Watching API response for cyvalue
    useEffect(() => {
        if (!cyvalueData) { return; }
        //  Getting default points to render on the chart
        const pointsToRender = getPointsForCurrentRange(timeFilters, false);
        //  Evaluating points to see if we need to adjust time ranges (edge case)
        // const shouldRetryRequest = shouldAdjustRanges(cyvalueData, pointsToRender, retryEnabled);

        //  Adjusting filters
        /*if (shouldRetryRequest) {
            setRetryEnabled(false);
            const adjustedFilters = adjustFilters(filters);
            mutateTimeFilters(adjustedFilters?.filter_type, adjustedFilters?.start_date, adjustedFilters?.end_date);
            // check if adjusted filters range is equal to filters
            const isSameStartDate = isSameDate(filters?.start_date, adjustedFilters?.start_date);
            const isSameEndDate = isSameDate(filters?.end_date, adjustedFilters?.end_date);
            if (!isSameStartDate || !isSameEndDate) {
                return;
            }
        }*/
        setRetryEnabled(false);
        setManualRefresh(false);
        if (cyvalueData.solutions && Array.isArray(cyvalueData.solutions)) {
            const solutions = cyvalueData.solutions.map((solution) => {
                return {
                    ...solution,
                    icarm: cyvalueData?.icarm || {}
                }
            })
            cyvalueData.solutions = solutions;
        }
        const normalizedData = normalizeCyvalueData(cyvalueData, pointsToRender, timeFilters);
        setSolutionsData(normalizedData);

        // Set color theme
        //  Setting theme
        const selectedSolutionName = !cyvalueData?.name || cyvalueData?.name === 'Overall' ? 'DEFAULT' : cyvalueData?.name;
        const solutionColorTheme = solutionsStyle[selectedSolutionName]
        setCurrentThemeColor(solutionColorTheme);
    }, [cyvalueData]);


    //  Function to change filters
    const changeFilters = (newFilters, enableRetry = false) => {
        setRetryEnabled(enableRetry);
        mutateTimeFilters(newFilters?.filter_type, newFilters?.start_date, newFilters?.end_date);
    }

    //  Watching selected tab changes
    useEffect(() => {
        setRetryEnabled(true);
        setSolutionFilterChanged(true);
    }, [selected_solution_id, company_id]);

    // Watch manual refresh changes
    useEffect(() => {
        if (manualRefresh) {
            if (!company_id) { return; }
            refetchCyvalue();
        }
    }, [manualRefresh])

    //watching loading state to reset solutionFilterChanged flag
    useEffect(() => {
        if (!cyvalueLoading) {
            setSolutionFilterChanged(false);
        }
    }, [cyvalueLoading])

    return {
        icarmLoading: cyvalueLoading && solutionFilterChanged,
        icarmErrorMessage: getApiErrorMessage(cyvalueError),
        refetchIcarm: refetchCyvalue,
        filters: timeFilters,
        changeFilters,
        solutionsData,
        hasSurpassedGoal,
        hasZeroScore,
        hasOverallScore,
        isFreemium,
        themeColor: currentThemeColor,
    }
};

export default useIcarmData;
