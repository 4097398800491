import React from 'react';
import { Row, Col } from 'reactstrap';

const RightAlignedFormContainer = (props) => {
  //  Parsing props
  const { logos, leftComponents, children } = props;

  //  Function to render individual logos
  const renderIndividualLogos = () => {
    if (!logos) { return null; }
    return logos.map((item, index) => {
      const {
        containerClassName,
        src,
        alt,
        className,
        style,
        onClick,
      } = item;
      return (
        <Row className={containerClassName} key={index}>
          <img
            className={className}
            src={src}
            alt={alt}
            style={style}
            onClick={onClick ? () => onClick() : null}
          />
        </Row>
      );
    })
  };

  //  Rendering
  return (
    <div className="w-100">
      <Row className="justify-content-around align-items-start align-items-md-center form-card-container flex-grow-1 h-md-100 p-4">
        <Col
          className="isometric-images-2-container "
          md={5}
          sm={10}
        >
          {renderIndividualLogos()}
          {leftComponents}
        </Col>
        <Col md={12} lg={4}>
          <Row className="justify-content-center align-items-start align-items-sm-center align-items-lg-start flex-grow-1 h-100">
            {children}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default RightAlignedFormContainer;
