export const LEFT_ARROW_KEY_CODE = 37;
export const RIGHT_ARROW_KEY_CODE = 39;
export const UP_ARROW_KEY_CODE = 38;
export const DOWN_ARROW_KEY_CODE = 40;

const TAB_KEY_CODE = 9;
const BACKSPACE_KEY_CODE = 8;
const SPACE_KEY_CODE = 32;
const ENTER_KEY_CODE = 13;

export const excludedKeyCodes = [
    TAB_KEY_CODE,
    BACKSPACE_KEY_CODE,
    LEFT_ARROW_KEY_CODE,
    UP_ARROW_KEY_CODE,
    RIGHT_ARROW_KEY_CODE,
    DOWN_ARROW_KEY_CODE,
    SPACE_KEY_CODE,
    ENTER_KEY_CODE
]
