import React from 'react'
import IssueCodeCopy from '../../../issue-manager/IssueCodeCopy';
import GroupRollup from './GroupRollup';
import IssuesListButton from './IssuesListButton';

const GroupOverviewDetails = (props) => {
  const {
    groupName,
    severityRollup,
    statusRollup,
    groupExternalId,
    groupId,
    issuesCount,
  } = props;

  // Function to render issue name
  const renderGroupId = () => {
    return (
      <div className='d-flex w-100 justify-content-start mb-3'>
        <div className="details-header-title">
          <div className='issue-details-name-header mr-3'>
           {`Issues ID ${groupExternalId}`}
          </div>
        </div>
        <IssuesListButton
          groupId={groupId}
          issuesCount={issuesCount}
        />
        <IssueCodeCopy
          code={groupExternalId}
          showCode={false}
          itemLabel="Group ID"
        />
      </div>
    )
  }
   // Function to render issue name
   const renderGroupName = () => {
    return (
      <div className="issue-details-name">
        {groupName}
      </div>
    )
  }

   // Render group status and criticality rollup
   const renderGroupRollup = () => {
    return (
      <GroupRollup
        severity={severityRollup}
        statusRollup={statusRollup}
      />
    )
  }
  return (
    <div className='d-block'>
      <div>
        {renderGroupId()}
      </div>
      {renderGroupName()}
      {renderGroupRollup()}
    </div>
  )
}

export default GroupOverviewDetails;
