import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import makeErrorMessage from '../../selectors/errorsSelector';
import makeLoadingState from '../../selectors/loadingSelector';
import {
    clearCustomersFilters,
    fetchCustomers,
    reloadCustomers,
    setCustomersFilter,
    setCustomersSort
} from '../../actions/customerManagement';
import { setAlert } from '../../actions/alerts';
import { onImpersonationSuccess } from '../../actions/impersonation';
import TableList from '../layout/TableList';
import { formatDate } from '../../utils/helpers';
import ActionLink from '../dashboard/ActionLink';
import ManageCompanyActions from './ManageCompanyActions';
import TextConfirmationModal from './TextConfirmationModal';
import CompanyPlanSelector from './CompanyPlanSelector';
import API from '../../api';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useCustomMutation from '../../hooks/useCustomMutation';
import NewMemberModal from '../auth/NewMemberModal';
import CustomerNameCell from './CustomerNameCell';
import { memberTypes } from '../../constants/company';
import { CREATE_ACTION, MODIFY_ACTION, PRIVILEGE_SOURCES } from '../../constants/privileges';
import useCanAbility from '../../hooks/useCanAbility';

const assessmentStatusClasses = {
    not_started: {
        label: 'Not Started',
        color: '#FFA78C'
    },
    0: {
        label: "In Progress",
        color: "#76E4EF"
    },
    1: {
        label: "Completed",
        color: "#6FCEAF"
    }
}

//  COMPONENT
const CustomersList = (props) => {
    //  Parsing props
    const { plans = [], partnerDefaults = [] } = props;

    //  Initializing APIs
    const { UserAPI } = API;

    //  Watching redux state
    const getLoadingState = makeLoadingState(['CUSTOMER_LIST']);
    const getErrorMessage = makeErrorMessage(['CUSTOMER_LIST']);
    const isLoading = useSelector((state) => getLoadingState(state));
    const errorMessage = useSelector((state) => getErrorMessage(state));
    const customers = useSelector((state) => state.customers);

    //  Component state
    const [showActionModal, setShowActionModal] = useState(false);
    const [modalConfig, setModalConfig] = useState(null);
    const [nextRoute, setNextRoute] = useState(null);
    const [showNewMemberModal, setShowNewMemberModal] = useState(false);

    // Component Hooks
    const { dispatch } = useMemoizedDispatch();
    const history = useHistory();
    //  Post to select company (for assessment purposes)
    const {
        data: impersonateResponse,
        error: impersonateError,
        mutate: impersonateCompany,
    } = useCustomMutation(
        (params) => UserAPI.handlers.impersonateCompany(params),
        UserAPI.invalidators.impersonateCompany
    );
    const ability = useCanAbility();

    // Component variables
    const canManageCompanySolutions = ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.COMPANY_SOLUTIONS.PARTNER);

    //  Watching impersonate response
    useEffect(() => {
        if (!impersonateResponse) { return; }
        dispatch(onImpersonationSuccess(impersonateResponse));
        if (nextRoute) {
            history.push({ pathname: nextRoute })
        }
    }, [impersonateResponse]);

    //  Watching impersonate error
    useEffect(() => {
        if (!impersonateError) { return; }
        dispatch(setAlert('Error getting company assessment'));
        dispatch(onImpersonationSuccess(null));
    }, [impersonateError]);

    const handleCompanyActionsClick = (config) => {
        setModalConfig(config);
        setShowActionModal(!showActionModal);
    }

    const renderModal = () => {
        return (
            <TextConfirmationModal
                modalConfig={modalConfig}
                showActionModal={showActionModal}
                handleCancelClick={setShowActionModal}
            />
        );
    }

    const renderNameComponent = (row) => {
        return (
            <CustomerNameCell
                row={row}
                onCellClick={onRowMemberSelected}
            />
        );
    }

    const renderSetupPartnerLink = (memberId) => {
        const label = "Set up partner";
        return (
            <Button
                color="link"
                className="setup-link"
                tabIndex="-1"
                aria-label={label}
                onClick={() => onRowMemberSelected(memberId, '/manage-solutions')}
            >
                <div className="d-flex align-items-center">
                    <i className='bx bx-plus-circle bx-sm'></i>
                    <span className="label">{label}</span>
                </div>
            </Button>
        )
    }

    const renderMemberPartnersLink = (memberId, installedPartnersCount) => {
        const partnerLabel = `partner${installedPartnersCount === '1' ? '' : 's'}`;
        const label = `${installedPartnersCount} installed ${partnerLabel}`
        return (
            <Button
                color="link"
                className="setup-link"
                tabIndex="-1"
                aria-label="Set up partner"
                onClick={() => onRowMemberSelected(memberId, '/manage-solutions')}
            >
                <span className="info-link">
                    {label}
                </span>
            </Button>
        )
    }

    const renderPartnersComponent = (row) => {
        const shouldRenderSetupIcon = !canManageCompanySolutions || row?.installed_partners !== '0';
        return shouldRenderSetupIcon ? (
            renderMemberPartnersLink(row?.id, row?.installed_partners)
        ) : (
            renderSetupPartnerLink(row?.id)
        );
    }

    //  Function for impersonating a company
    const onRowMemberSelected = (company_id, _nextRoute) => {
        impersonateCompany(company_id);
        setNextRoute(_nextRoute)
    };

    const renderAssessmentComponent = (row) => {
        const assessmentStatus = row && row.assessment_executions && row.assessment_executions.length > 0 ? row.assessment_executions[0].status || 'not_started' : 'not_started';

        return (
            <Button
                color="link"
                tabIndex="-1"
                aria-label="Assessment"
                className="btn-assessment-progress"
                onClick={() => onRowMemberSelected(row.id, '/assessment')}
            >
                <span className='assessment-status-label' style={{ backgroundColor: assessmentStatusClasses[assessmentStatus].color }}>
                    {assessmentStatusClasses[assessmentStatus].label}
                </span>
            </Button>
        )
    }

    const renderOpenIssues = (row) => {
        return row?.open_issues ? `${row.open_issues} open issues` : '-';
    }

    const renderRegistrationDate = (row) => {
        return formatDate(row.created_at);
    }

    const renderActions = (row) => {
        const canModifyCompany = ability.can(MODIFY_ACTION, PRIVILEGE_SOURCES.MEMBERS.GENERAL);
        return (
            <React.Fragment>
                {cydekickActions.map((action, index) => {
                    return (
                        <ActionLink
                            id={`action-${index}`}
                            key={`action-${index}`}
                            pathname={action.pathname}
                            state={{ id: row.id, name: row.name, public_id: row.public_id }}
                            label={action.ariaLabel}
                            iconName={action.iconName}
                            customAction={action.customAction ? () => action.customAction(row.id) : null}
                        />
                    )
                })
                }
                {canModifyCompany && (
                    <ManageCompanyActions
                        member={row}
                        onClickCallback={handleCompanyActionsClick}
                    />
                )}

            </React.Fragment>
        )
    }

    //  Function to render current plan
    const renderCurrentPlan = (row) => {
        return (
            <CompanyPlanSelector
                company_id={row?.id}
                price_id={row?.price_id}
                plans={plans}
            />
        );
    };

    const columns = [
        {
            displayName: "Code",
            fieldName: 'code',
            sortFieldName: 'code',
            headerClassNameWrapper: 'code-header',
        },
        {
            displayName: "Customer name",
            fieldName: 'name',
            sortFieldName: 'name',
            headerClassNameWrapper: 'name-header',
            renderCustomComponent: renderNameComponent
        },
        {
            displayName: 'Current plan',
            field_name: 'price_id',
            sortFieldName: 'price_id',
            headerClassNameWrapper: 'plans-header',
            renderCustomComponent: renderCurrentPlan,
        },
        {
            displayName: "Installed partners",
            fieldName: 'installed_partners',
            sortFieldName: 'installed_partners',
            headerClassNameWrapper: 'running-solutions-header',
            renderCustomComponent: renderPartnersComponent
        },
        {
            displayName: "Assessment",
            fieldName: 'assessment_status',
            sortFieldName: 'assessment_status',
            headerClassNameWrapper: 'assessment-status-header',
            renderCustomComponent: renderAssessmentComponent
        },
        {
            displayName: "Open issues",
            fieldName: 'open_issues',
            sortFieldName: 'open_issues',
            headerClassNameWrapper: 'open-issues-header',
            renderCustomComponent: renderOpenIssues
        },
        {
            displayName: "Registration date",
            fieldName: 'created_at',
            sortFieldName: 'created_at',
            headerClassNameWrapper: 'registration-date-header',
            renderCustomComponent: renderRegistrationDate
        },
        {
            displayName: 'Actions',
            fieldName: null,
            sortFieldName: null,
            headerClassNameWrapper: 'actions-header',
            renderCustomComponent: renderActions
        }
    ];

    const cydekickActions = [
        { pathname: '/profile', ariaLabel: 'Edit Profile', iconName: 'bx-id-card', customAction: (id) => onRowMemberSelected(id, '/profile') },
        { pathname: '/assessment', ariaLabel: 'Edit Full Assessment', iconName: 'bx-calendar-check', customAction: (id) => onRowMemberSelected(id, '/assessment') },
        ...canManageCompanySolutions ? [
            { pathname: '/manage-solutions', ariaLabel: 'Manage Solutions', iconName: 'bx-wrench', customAction: (id) => onRowMemberSelected(id, '/manage-solutions') }
        ] : []
    ]

    // Function to toggle New member modal
    const toggleNewMemberModal = () => {
        setShowNewMemberModal(!showNewMemberModal)
    }

    // Function to render Add new member button
    const renderAddNewMemberButton = () => {
        return (
            <Button
                color="light"
                onClick={toggleNewMemberModal}
            >
                Add new member
            </Button>
        )
    }

    // Function to render New member modal
    const renderNewMemberModal = () => {
        return (
            <NewMemberModal
                showModal={showNewMemberModal}
                toggleModal={toggleNewMemberModal}
                reloadCustomers={(data) => dispatch(reloadCustomers(data))}
                partnerDefaults={partnerDefaults}
            />
        )
    }

    return (
        <React.Fragment>
            <TableList
                fetchList={(payload) => dispatch(fetchCustomers(payload))}
                reloadList={(data) => dispatch(reloadCustomers(data))}
                data={customers}
                columns={columns}
                customClassName="customer-manager-list"
                containerClassName="members"
                setFilter={(filterName, value, label, type) => dispatch(setCustomersFilter(filterName, value, label, type))}
                clearAllFilters={() => dispatch(clearCustomersFilters())}
                setSort={(value) => dispatch(setCustomersSort(value))}
                activeFiltersDisplayInfo={{
                    display: true,
                    labels: {
                        noFilters: 'Listing all members',
                        filteringBy: 'Listing Members filtered by:',
                        total: {
                            singular: 'member',
                            plural: 'members'
                        }
                    }
                }}
                isLoading={isLoading}
                errorMessage={errorMessage}
                searchPlaceholder="Search by name"
                filtersComponentList={[{
                    fieldName: 'member_type',
                    type: 'select',
                    className: 'member-filter',
                    placeholder: 'Member type',
                    options: [
                        {
                            options: [
                                { label: 'Freemium', value: memberTypes.FREEMIUM },
                                { label: 'Paying', value: memberTypes.PAYING },
                                { label: 'Inactive', value: memberTypes.INACTIVE },
                                { label: 'Unverified', value: memberTypes.UNVERIFIED },
                            ]
                        }
                    ]
                }]}
                renderButton={renderAddNewMemberButton}
                filterSectionXOffset={-16}
                middleSectionXOffset={-16}
            />
            { showActionModal && renderModal() }
            {showNewMemberModal && renderNewMemberModal()}
        </React.Fragment>
    )
};

export default CustomersList;
